import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { Action, ActionType, PromotionProps } from './action-types';
import { CommonStateType } from '../types';

export interface PromotionState extends CommonStateType {
  isPromotionsLoaded: boolean;
  promotions: PromotionProps[];
}

const initialState: PromotionState = {
  isSubmitting: false,
  isPromotionsLoaded: false,
  promotions: [],
};

export default produce((state: PromotionState = initialState, action: Action): PromotionState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_PROMOTIONS_REQUEST:
      state.isPromotionsLoaded = false;
      break;
    case ActionType.GET_PROMOTIONS_SUCCESS:
      state.isPromotionsLoaded = true;
      state.promotions = action.result.data.results;
      break;
    case ActionType.GET_PROMOTIONS_FAILURE:
      state.isPromotionsLoaded = false;
      break;
  }

  return state;
});
