import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import TimeAgo from 'react-timeago';
import Skeleton from 'react-loading-skeleton';
import { LineSkeleton, formatTimeByTimezone } from 'src/utils';
import {
  MediaAvatar, MediaBody, MediaLead, MediaInfo, MediaLabel, MediaValue, ContentNavBar, NavLeadOption,
} from 'dwell/views/lead/layout/key_info/style';
import { stageChoice, statusChoice } from 'application/constants/field_choices';
import { selectApplicationDetails, selectApplicationIsLoaded } from 'application/store/application/reducers';
import { selectProperty } from 'dwell/store/property/reducers';
import { BadgeDot, PMSSyncStatus } from 'resident/views/key_info/styles';
import Dropdown from './_dropdown';
import StatusDropdown from './_status_dropdown';

const KeyInfo: FC = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const application = useSelector(selectApplicationDetails);
  const isLoaded = useSelector(selectApplicationIsLoaded);
  const currentProperty = useSelector(selectProperty);
  const {
    last_followup_date: lastFollowupDate, last_activity_date: lastActivityDate, unit, application_stage: stage,
    application_status: status, pms_sync_date: pmsSyncDate, pms_sync_status: pmsSyncStatus, floor_plan: floorPlan,
  } = application;

  const getTimeAgo = date => (date ? <TimeAgo title={formatTimeByTimezone(date).format('YYYY-MM-DD HH:mm')} date={date} live={false} /> : <>&nbsp;</>);

  const syncStatus = useMemo(() => {
    if (!pmsSyncStatus) return <br />;

    let syncStatusIcon = 'ri-indeterminate-circle-line';
    if (pmsSyncStatus === 'SUCCESS') syncStatusIcon = 'ri-checkbox-circle-line';
    if (pmsSyncStatus === 'SYNCING') syncStatusIcon = 'ri-refresh-line';
    if (pmsSyncStatus === 'FAILURE') syncStatusIcon = 'ri-information-line';

    return (
      <PMSSyncStatus status={pmsSyncStatus}>
        <span id="pms_sync">
          <i className={syncStatusIcon} />
          <span>
            {pmsSyncStatus === 'NOT_STARTED' && 'Not Started'}
            {pmsSyncStatus === 'SYNCING' && 'Syncing ...'}
            {['SUCCESS', 'FAILURE'].includes(pmsSyncStatus) && (
              <React.Fragment>
                {pmsSyncStatus === 'SUCCESS' ? 'Synced' : 'Not synced'} ({getTimeAgo(pmsSyncDate)})
                <Tooltip placement="top" isOpen={tooltipOpen} target="pms_sync" toggle={() => setTooltipOpen(!tooltipOpen)}>
                  {formatTimeByTimezone(pmsSyncDate).format('lll')}
                </Tooltip>
              </React.Fragment>
            )}
          </span>
        </span>
      </PMSSyncStatus>
    );
  }, [pmsSyncStatus, pmsSyncDate]);

  const stageLabel = useMemo(() => {
    const value = stageChoice[stage];
    return (
      <div className="d-flex align-items-center">
        <BadgeDot className="bg-success" />
        <span className="ml-1">{value}</span>
      </div>
    );
  }, [stage]);

  const ownerChoices = useMemo(() => currentProperty.users?.reduce((acc, row) => ({ ...acc, [row.id]: `${row.first_name} ${row.last_name}` }), {}) || {}, [currentProperty.users]);
  const columns = [
    { field: 'Last Sync Date', value: syncStatus },
    { field: 'Last Followup Date', value: getTimeAgo(lastFollowupDate) },
    { field: 'Last Activity Date', value: getTimeAgo(lastActivityDate) },
    { field: 'Stage', value: stageLabel },
  ];

  return (
    <React.Fragment>
      <MediaLead>
        {isLoaded ? (
          <MediaAvatar><span><i className="ri-file-list-3-line tx-20" /></span></MediaAvatar>
        ) : (
          <Skeleton circle height={38} width={38} />
        )}
        <MediaBody>
          <div className="d-flex align-items-end">
            <div>
              <MediaLabel>Application</MediaLabel>
              {isLoaded ? <strong>{floorPlan}-{unit}</strong> : <LineSkeleton width={100} height={12} />}
            </div>
          </div>
        </MediaBody>
        {columns.map((c, index) => (
          <MediaInfo key={index}>
            <MediaLabel>{isLoaded ? c.field : <LineSkeleton height={8} width={150} />}</MediaLabel>
            <MediaValue>
              {isLoaded ? <>{c.value || <br />}</> : <LineSkeleton height={9} width={100} />}
            </MediaValue>
          </MediaInfo>
        ))}
      </MediaLead>
      <ContentNavBar>
        <NavLeadOption>
          <Dropdown choices={ownerChoices || {}} field="owner" />
          <StatusDropdown choices={statusChoice} value={status} />
        </NavLeadOption>
      </ContentNavBar>
    </React.Fragment>
  );
};

export default KeyInfo;
