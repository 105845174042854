export default {
  EMAIL_TEMPLATE_TYPES: {
    TOUR_CONFIRMATION: 'TOUR_CONFIRMATION',
    FIRST_FOLLOWUP: 'FIRST_FOLLOWUP',
    SECOND_FOLLOWUP: 'SECOND_FOLLOWUP',
    THIRD_FOLLOWUP: 'THIRD_FOLLOWUP',
    FINAL_FOLLOWUP: 'FINAL_FOLLOWUP',
    RECEIVED_APPLICATION: 'RECEIVED_APPLICATION',
    GENERIC: 'GENERIC',
    LEASE_APPLICATION_FROM_PROPERTY: 'LEASE_APPLICATION_FROM_PROPERTY',
  },
};
