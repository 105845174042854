import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, CallSummary } from './action-types';

export default {
  getCalls: (param: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CALLS_REQUEST,
        ActionType.GET_CALLS_SUCCESS,
        ActionType.GET_CALLS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CALLS, { params: param }),
    },
  }),

  getCallById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CALL_BY_ID_REQUEST,
        ActionType.GET_CALL_BY_ID_SUCCESS,
        ActionType.GET_CALL_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.CALL_DETAILS, id)),
    },
  }),

  updateCallById: (id: number, data: { lead?: { id: number }; is_archived?: boolean, call_category?: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CALL_REQUEST,
        ActionType.UPDATE_CALL_SUCCESS,
        ActionType.UPDATE_CALL_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.CALL_DETAILS, id), data),
    },
  }),

  archiveCalls: (data: { lead: { id: number } }): Action => ({
    CALL_API: {
      types: [
        ActionType.ARCHIVE_CALLS_REQUEST,
        ActionType.ARCHIVE_CALLS_SUCCESS,
        ActionType.ARCHIVE_CALLS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.ARCHIVE_CALLS, data),
    },
  }),

  archiveCall: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.ARCHIVE_CALL_REQUEST,
        ActionType.ARCHIVE_CALL_SUCCESS,
        ActionType.ARCHIVE_CALL_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.CALL_DETAILS, id), { is_archived: true }),
    },
  }),

  unarchiveCall: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UNARCHIVE_CALL_REQUEST,
        ActionType.UNARCHIVE_CALL_SUCCESS,
        ActionType.UNARCHIVE_CALL_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.CALL_DETAILS, id), { is_archived: false }),
    },
  }),

  saveCallSummary: (payload: CallSummary): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_CALL_NOTES_REQUEST,
        ActionType.SAVE_CALL_NOTES_SUCCESS,
        ActionType.SAVE_CALL_NOTES_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CALL_NOTES, payload),
    },
  }),
};
