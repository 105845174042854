import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  GET_UNITS_REQUEST = 'GET_UNITS_REQUEST',
  GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS',
  GET_UNITS_FAILURE = 'GET_UNITS_FAILURE',
}

export interface LeaseUnitFilterParams {
  bath_rooms?: string[],
  bed_rooms?: string[],
  floor_plan?: string[],
  lease?: number,
  move_in_date?: string,
}

export interface LeaseUnitProps {
  id: number,
  available_date: string,
  bath_rooms: number,
  bed_rooms: number,
  effective_rent: number,
  floor_plan: string,
  image?: string,
  is_hold?: boolean,
  market_rent: number,
  move_in_date?: string,
  property: string,
  status: string,
  unit: string,
}

export interface UnitState extends CommonStateType {
  units: LeaseUnitProps[]
}

/*
 * Actions
 */

interface GetUnitsAction extends CommonActionResponse {
  type: ActionType.GET_UNITS_REQUEST
  | ActionType.GET_UNITS_SUCCESS
  | ActionType.GET_UNITS_FAILURE;
  result: { data: { results: LeaseUnitProps[] } };
}

export type Action =
  PromiseActionType
  | GetUnitsAction
  | ResetLeaseAction;
