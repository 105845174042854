import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { OccupantProps } from 'leasing/store/applicant/action-types';
import { ConcessionProps } from 'leasing/store/concession/action-types';
import { GarageProps } from 'leasing/store/garage/action-types';
import { GateKeyProps } from 'leasing/store/gate_key/action-types';
import { OtherFeeItemProps } from 'leasing/store/other_fee_item/action-types';
import { OtherRentItemProps } from 'leasing/store/other_rent_item/action-types';
import { ParkingProps } from 'leasing/store/parking/action-types';
import { StorageProps } from 'leasing/store/storage/action-types';
import { VehicleProps } from 'leasing/store/vehicle/action-types';
import { PetProps } from 'leasing/store/pet/action-types';
import { PaymentReceivedProps } from 'leasing/store/payment/action-types';
import { ScreeningReportProps } from 'leasing/store/screening_report/action-types';
import { OwnerData } from 'src/interfaces';

export enum ActionType {
  COLLAPSE_STEPPER = 'COLLAPSE_STEPPER',

  GET_LEASE_ADDENDA_REQUEST = 'GET_LEASE_ADDENDA_REQUEST',
  GET_LEASE_ADDENDA_SUCCESS = 'GET_LEASE_ADDENDA_SUCCESS',
  GET_LEASE_ADDENDA_FAILURE = 'GET_LEASE_ADDENDA_FAILURE',

  GET_LEASE_BY_ID_REQUEST = 'GET_LEASE_BY_ID_REQUEST',
  GET_LEASE_BY_ID_SUCCESS = 'GET_LEASE_BY_ID_SUCCESS',
  GET_LEASE_BY_ID_FAILURE = 'GET_LEASE_BY_ID_FAILURE',

  RESET_LEASE = 'RESET_LEASE',

  SEND_DOCUMENT_REQUEST = 'SEND_DOCUMENT_REQUEST',
  SEND_DOCUMENT_SUCCESS = 'SEND_DOCUMENT_SUCCESS',
  SEND_DOCUMENT_FAILURE = 'SEND_DOCUMENT_FAILURE',

  UPDATE_APPLICATION_STATUS_REQUEST = 'UPDATE_APPLICATION_STATUS_REQUEST',
  UPDATE_APPLICATION_STATUS_SUCCESS = 'UPDATE_APPLICATION_STATUS_SUCCESS',
  UPDATE_APPLICATION_STATUS_FAILURE = 'UPDATE_APPLICATION_STATUS_FAILURE',

  UPDATE_LEASE_REQUEST = 'UPDATE_LEASE_REQUEST',
  UPDATE_LEASE_SUCCESS = 'UPDATE_LEASE_SUCCESS',
  UPDATE_LEASE_FAILURE = 'UPDATE_LEASE_FAILURE',

  UPDATE_UNIT_RELEASE_HOLD_REQUEST = 'UPDATE_UNIT_RELEASE_HOLD_REQUEST',
  UPDATE_UNIT_RELEASE_HOLD_SUCCESS = 'UPDATE_UNIT_RELEASE_HOLD_SUCCESS',
  UPDATE_UNIT_RELEASE_HOLD_FAILURE = 'UPDATE_UNIT_RELEASE_HOLD_FAILURE',

  GET_LEASE_AVAILABLE_UNITS_REQUEST = 'GET_LEASE_AVAILABLE_UNITS_REQUEST',
  GET_LEASE_AVAILABLE_UNITS_SUCCESS = 'GET_LEASE_AVAILABLE_UNITS_SUCCESS',
  GET_LEASE_AVAILABLE_UNITS_FAILURE = 'GET_LEASE_AVAILABLE_UNITS_FAILURE',

  TRANSFER_UNIT_REQUEST = 'TRANSFER_UNIT_REQUEST',
  TRANSFER_UNIT_SUCCESS = 'TRANSFER_UNIT_SUCCESS',
  TRANSFER_UNIT_FAILURE = 'TRANSFER_UNIT_FAILURE',

  RELEASE_UNIT_REQUEST = 'RELEASE_UNIT_REQUEST',
  RELEASE_UNIT_SUCCESS = 'RELEASE_UNIT_SUCCESS',
  RELEASE_UNIT_FAILURE = 'RELEASE_UNIT_FAILURE',

  BULK_UPDATE_LEASES_REQUEST = 'BULK_UPDATE_LEASES_REQUEST',
  BULK_UPDATE_LEASES_SUCCESS = 'BULK_UPDATE_LEASES_SUCCESS',
  BULK_UPDATE_LEASES_FAILURE = 'BULK_UPDATE_LEASES_FAILURE',

  SET_LEASE_DATA = 'SET_LEASE_DATA',
}

export interface LeaseState extends CommonStateType {
  isLeaseLoaded: boolean,
  isStepperCollapsed: boolean,
  lease: LeaseProps,
  availableUnits: AvailableUnitsProps,
  isUnitsLoaded: boolean,
}

export interface LeaseHistoryProps {
  event_type: string;
  date: string;
  content: string;
}

export interface LeaseProps {
  id?: number,
  addenda?: number[],
  addenda_names?: string[],
  deposit_agreement_addenda_names?: string[],
  applicant_is_employee?: boolean,
  application_fee?: number,
  application_steps?: { [step: string]: string },
  balance_due_payment_methods?: string[],
  base_rent?: number,
  bedbug_description?: string,
  building_number?: string,
  concessions?: ConcessionProps[],
  construction_delivery_date?: string,
  deposit_agreement_addenda?: number[],
  deposit_agreement_comment?: string,
  deposit_alternative_fee?: number,
  deposit_renters_insurance?: number,
  disclosed_history_of_bedbugs?: boolean,
  e_premium_coverage_amount?: number,
  e_premium_payment_methods?: string[],
  electric_account_number?: string,
  emergency_contact_name?: string,
  emergency_contact_phone_number?: string,
  employee_discount?: number,
  external_id?: string,
  fitness_center_key_deposit?: number,
  floor_plan_name?: string,
  floor_plan?: number,
  garage_door_opener_deposit?: number,
  garages?: GarageProps[],
  gate_code_phone_number?: string,
  gate_keys?: GateKeyProps[],
  gate_remote_id_number?: string,
  gate_remote_deposit?: number,
  income_threshold?: number,
  insurer_name?: string,
  insurance_liability_amount?: number,
  insurance_effective_date?: string,
  insurance_policy_number?: string,
  lease_end_date?: string,
  lease_start_date?: string,
  lease_term?: number,
  mailbox_number?: string,
  message_to_applicants?: string,
  monthly_rent?: number,
  move_in_date?: string,
  move_out_date?: string,
  non_refundable_fee?: number,
  occupants?: OccupantProps[],
  option?: number,
  other_fee_items?: OtherFeeItemProps[],
  other_rent_items?: OtherRentItemProps[],
  parking?: ParkingProps[],
  payment_received: PaymentReceivedProps;
  pet_fee?: number,
  pet_non_refundable_deposit?: number,
  pet_refundable_deposit?: number,
  pet_rent?: number,
  require_guarantor?: boolean,
  require_larger_security_deposit?: boolean,
  security_deposit_type?: string,
  security_deposit?: number,
  sent_deposit_agreement?: boolean,
  sent_lease_agreement?: boolean,
  special_provisions?: string,
  status?: string,
  storages?: StorageProps[],
  unit?: number,
  utilities?: string[],
  valet_waste?: number,
  vehicles?: VehicleProps[],
  bicycle_storage_assigned_floor?: number,
  bicycle_description?: string,
  ach_account_type?: string,
  ach_depository_name?: string,
  ach_depository_branch?: string,
  ach_depository_city?: string,
  ach_depository_state?: string,
  ach_depository_zip_code?: number,
  ach_routing_number?: string,
  ach_account_number?: string,
  type?: string;
  deposit_agreement_signed_date?: string;
  lease_signed_date?: string;
  pets?: PetProps[];
  other_fees?: {
    name: string;
  }[];
  non_refundable_administration_fee?: number;
  amount_already_paid?: string;
  status_update_date?: string;
  gas_account_number?: string;
  paid_on_date?: string;
  application_completed_date?: string;
  application_submitted_date?: string;
  payment_method?: string;
  lease_agreement_addenda?: number[];
  addenda_in_text?: string[];
  screening?: ScreeningReportProps;
  histories?: LeaseHistoryProps[],
  is_gate_remote_deposit_included: boolean,
  is_garage_door_opener_deposit_included: boolean,
  is_fitness_center_key_deposit_included: boolean,
  unit_info?: { is_hold: boolean, is_waitlist: boolean, available_on: string },
  parent_lease?: number,
  renew_lease_status?: string,
  renew_lease_created?: string,
  notice?: number,
  term_premium?: number,
  last_unit?: string,
  roommate_change_effective_date?: string,
}

export interface AvailableUnitsProps {
  application_started: { id: number, unit: string }[],
  no_application_started: { id: number, unit: string }[],
}

export interface LeaseUpdateParam {
  id?: number,
  addenda?: number[],
  applicant_is_employee?: boolean,
  application_fee?: number,
  balance_due_payment_methods?: string[],
  base_rent?: number,
  bedbug_description?: string,
  building_number?: string,
  construction_delivery_date?: string,
  deposit_agreement_addenda?: number[],
  deposit_agreement_comment?: string,
  deposit_alternative_fee?: number,
  deposit_renters_insurance?: number,
  disclosed_history_of_bedbugs?: boolean,
  e_premium_coverage_amount?: number,
  e_premium_payment_methods?: string[],
  electric_account_number?: string,
  emergency_contact_name?: string,
  emergency_contact_phone_number?: string,
  employee_discount?: number,
  external_id?: string,
  fitness_center_key_deposit?: number,
  floor_plan_name?: string,
  floor_plan?: number,
  garage_door_opener_deposit?: number,
  gate_code_phone_number?: string,
  gate_remote_id_number?: string,
  gate_remote_deposit?: number,
  income_threshold?: number,
  insurer_name?: string,
  insurance_liability_amount?: number,
  insurance_effective_date?: string,
  insurance_policy_number?: string,
  lease_end_date?: string,
  lease_start_date?: string,
  lease_term?: number,
  mailbox_number?: string,
  message_to_applicants?: string,
  monthly_rent?: number,
  move_in_date?: string,
  move_out_date?: string,
  non_refundable_fee?: number,
  option?: number,
  pet_fee?: number,
  pet_non_refundable_deposit?: number,
  pet_refundable_deposit?: number,
  pet_rent?: number,
  require_guarantor?: boolean,
  require_larger_security_deposit?: boolean,
  security_deposit_type?: string,
  security_deposit?: number,
  sent_deposit_agreement?: boolean,
  sent_lease_agreement?: boolean,
  special_provisions?: string,
  status?: string,
  unit?: number,
  utilities?: string[],
  valet_waste?: number,
  bicycle_storage_assigned_floor?: number,
  bicycle_description?: string,
  type?: string;
  deposit_agreement_signed_date?: string;
  lease_signed_date?: string;
  non_refundable_administration_fee?: number;
  amount_already_paid?: string;
  status_update_date?: string;
  gas_account_number?: string;
  paid_on_date?: string;
  application_completed_date?: string;
  application_submitted_date?: string;
  payment_method?: string;
  lease_agreement_addenda?: number[];
  addenda_in_text?: string[];
  owner?: OwnerData;
}

/*
 * Action
 */

interface CollapseStepperAction {
  type: ActionType.COLLAPSE_STEPPER;
  value: boolean;
}

export interface GetLeaseAddendaAction extends CommonActionResponse {
  type: ActionType.GET_LEASE_ADDENDA_REQUEST
  | ActionType.GET_LEASE_ADDENDA_SUCCESS
  | ActionType.GET_LEASE_ADDENDA_FAILURE;
  result: { data: LeaseProps };
}

export interface GetLeaseByIdAction extends CommonActionResponse {
  type: ActionType.GET_LEASE_BY_ID_REQUEST
  | ActionType.GET_LEASE_BY_ID_SUCCESS
  | ActionType.GET_LEASE_BY_ID_FAILURE;
  result: { data: LeaseProps };
}

export interface ResetLeaseAction {
  type: ActionType.RESET_LEASE;
}

interface SendDocumentAction extends CommonActionResponse {
  type: ActionType.SEND_DOCUMENT_REQUEST
  | ActionType.SEND_DOCUMENT_SUCCESS
  | ActionType.SEND_DOCUMENT_FAILURE;
  result: { data: { type: string } };
}

interface UpdateApplicationStateAction extends CommonActionResponse {
  type: ActionType.UPDATE_APPLICATION_STATUS_REQUEST
  | ActionType.UPDATE_APPLICATION_STATUS_SUCCESS
  | ActionType.UPDATE_APPLICATION_STATUS_FAILURE;
  result: { data: LeaseProps };
}

interface UpdateLeaseAction extends CommonActionResponse {
  type: ActionType.UPDATE_LEASE_REQUEST
  | ActionType.UPDATE_LEASE_SUCCESS
  | ActionType.UPDATE_LEASE_FAILURE;
  result: { data: LeaseProps };
}

interface SetLeaseDataAction {
  type: ActionType.SET_LEASE_DATA;
  field: string,
  value: string | number,
}

export interface GetLeaseAvailableUnitsAction extends CommonActionResponse {
  type: ActionType.GET_LEASE_AVAILABLE_UNITS_REQUEST
  | ActionType.GET_LEASE_AVAILABLE_UNITS_SUCCESS
  | ActionType.GET_LEASE_AVAILABLE_UNITS_FAILURE;
  result: { data: AvailableUnitsProps };
}

export interface TransferUnitAction extends CommonActionResponse {
  type: ActionType.TRANSFER_UNIT_REQUEST
  | ActionType.TRANSFER_UNIT_SUCCESS
  | ActionType.TRANSFER_UNIT_FAILURE;
  result: { data: AvailableUnitsProps };
}

export interface ReleaseUnitAction extends CommonActionResponse {
  type: ActionType.RELEASE_UNIT_REQUEST
  | ActionType.RELEASE_UNIT_SUCCESS
  | ActionType.RELEASE_UNIT_FAILURE;
  result: { data: AvailableUnitsProps };
}

export type Action =
  PromiseActionType
  | CollapseStepperAction
  | GetLeaseAddendaAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | SendDocumentAction
  | UpdateApplicationStateAction
  | UpdateLeaseAction
  | SetLeaseDataAction
  | GetLeaseAvailableUnitsAction
  | TransferUnitAction
  | ReleaseUnitAction;
