import { monthsList } from 'leasing/views/settings/duration_pricing/_utils';

export const tourPlanChoices = [
  { name: 'Virtual Tour', value: 'VIRTUAL_TOUR' },
  { name: 'Guided Virtual Tour', value: 'GUIDED_VIRTUAL_TOUR' },
  { name: 'In-Person Tour', value: 'IN_PERSON' },
  { name: 'Facetime Tour', value: 'FACETIME' },
  { name: 'Self-Guided Tour', value: 'SELF_GUIDED_TOUR' },
];

export const bedroomPlanChoices = [
  { name: 'Studio', value: 'STUDIO' },
  { name: '1 bedroom', value: 'ONE_BEDROOM' },
  { name: '2 bedroom', value: 'TWO_BEDROOM' },
  { name: '3 bedroom', value: 'THREE_BEDROOM' },
  { name: '4 bedroom', value: 'FOUR_BEDROOM' },
];

export const paymentForms = [
  { name: 'Personal Check', value: 'PERSONAL_CHECK' },
  { name: "Cashier's Check", value: 'CASHIER_CHECK' },
  { name: 'Electronic Check', value: 'ELECTRONIC_CHECK' },
  { name: 'Credit Card', value: 'CREDIT_CARD' },
  { name: 'Debit Card', value: 'DEBIT_CARD' },
  { name: 'Online Payment', value: 'ONLINE_PAYMENT' },
  { name: 'Money Order', value: 'MONEY_ORDER' },
];

export const feeModeChoices = [
  { name: 'Percent of rent', value: 'PERCENT' },
  { name: 'Fixed monetary amount', value: 'FIXED' },
];

export const earlyTerminationFeeChoices = [
  { name: 'Percent of rent', value: 'PERCENT' },
  { name: 'Fixed monetary amount', value: 'FIXED' },
  { name: 'Monthly rent times', value: 'MONTHLY_RENT_TIMES' },
];

export const allowableDaysApplicantsMoveIn = [
  { name: '7 days', value: '7_DAYS' },
  { name: '14 days', value: '14_DAYS' },
  { name: '30 days', value: '30_DAYS' },
  { name: '60 days', value: '60_DAYS' },
];

export const appartmentHoldExpiration = [
  { name: 'Manually released', value: 'MANUAL' },
  { name: '24 hours', value: '24_HOURS' },
  { name: '48 hours', value: '48_HOURS' },
  { name: '72 hours', value: '72_HOURS' },
  { name: '1 week', value: 'WEEK' },
  { name: '2 weeks', value: 'TWO_WEEKS' },
];

export const residentParkingType = [
  { name: 'Assigned, covered parking', value: 'COVERED_PARKING' },
  { name: 'Assigned, uncovered parking', value: 'UNCOVERED_PARKING' },
  { name: 'Assigned, covered and uncovered parking', value: 'BOTH_PARKING' },
  { name: 'Unassigned, first come/first serve', value: 'UNASSIGNED_FIRST_COME' },
];

export const guessParkingType = [
  { name: 'In any unassigned space', value: 'ANY_UNASSIGNED_SPACE' },
  { name: 'In spaces marked for guest or visitors', value: 'SPACES_MARKED_FOR_GUEST_OR_VISITORS' },
  { name: 'When the guest vehicle is displaying a guest pass', value: 'GUEST_VEHICLE_DISPLAY_GUEST_PASS' },
];

export const unitOrLeasholder = [
  { name: 'Per Unit', value: 'UNIT' },
  { name: 'Per Leaseholder', value: 'LEASEHOLDER' },
];

export const utiliesOptions = [
  { name: 'Electric', value: 'ELECTRIC' },
  { name: 'Water', value: 'WATER' },
  { name: 'Trash', value: 'TRASH' },
  { name: 'Gas', value: 'GAS' },
  { name: 'Sewer', value: 'SEWER' },
  { name: 'Renters insurance', value: 'RENTERS_INSURANCE' },
  { name: 'Valet trash service', value: 'VALET_TRASH_SERVICE' },
];

export const baseRentType = [
  { name: 'Plus', value: 'PLUS' },
  { name: 'No premium', value: 'NO_PREMIUM' },
  { name: 'Minus', value: 'MINUS' },
];

export const screeingProcessTime = [
  { name: '24 hours', value: '24_HOURS' },
  { name: '48 hours', value: '48_HOURS' },
  { name: '72 hours', value: '72_HOURS' },
];

export const commonBillingMethods = [
  { name: 'Direct Metered ', value: 'DIRECT_METERED' },
  { name: 'Equally Distributed', value: 'EQUALLY_DISTRIBUTED' },
  { name: 'Individually Metered', value: 'INDIVIDUALLY_METERED' },
  { name: 'Individually Submetered', value: 'INDIVIDUALLY_SUBMETERED' },
  { name: 'RUBS', value: 'RUBS' },
  { name: 'Resident’s Direct Responsibility', value: 'RESIDENT_DIRECT_RESPONSIBILITY' },
  { name: 'Number of Occupants', value: 'NUMBER_OF_OCCUPANTS' },
  { name: 'Conservation Fee', value: 'CONSERVATION_FEE' },
];

export const pestControlServiceDay = [
  { name: 'Mondays', value: 'MONDAYS' },
  { name: 'Tuesdays', value: 'TUESDAYS' },
  { name: 'Wednesdays', value: 'WEDNESDAYS' },
  { name: 'Thursdays', value: 'THURSDAYS' },
  { name: 'Fridays', value: 'FRIDAYS' },
  { name: 'Saturdays', value: 'SATURDAYS' },
  { name: 'Sundays', value: 'SUNDAYS' },
];

export const daysToVacatestandardLease = [
  { name: '30 days prior', value: '30' },
  { name: '45 days prior', value: '45' },
  { name: '60 days prior', value: '60' },
  { name: '90 days prior', value: '90' },
];

export const daysToVacateMonthToMonth = [
  { name: '15 days prior', value: '15' },
  { name: '30 days prior', value: '30' },
  { name: '45 days prior', value: '45' },
  { name: '60 days prior', value: '60' },
  { name: '90 days prior', value: '90' },
];

export const renewalPeriod = [
  { name: '15 days', value: '15_DAYS' },
  { name: '30 days', value: '30_DAYS' },
  { name: '45 days', value: '45_DAYS' },
  { name: '60 days', value: '60_DAYS' },
  { name: '75 days', value: '75_DAYS' },
  { name: '90 days', value: '90_DAYS' },
];

export const optionsMap = {
  ANSWER_TOUR_TYPE_OPTIONS: tourPlanChoices,
  ANSWER_BEDROOM_PLANS_OPTIONS: bedroomPlanChoices,
  ANSWER_PAYMENT_METHOD_OPTIONS: paymentForms,
  ANSWER_BILLING_METHOD: commonBillingMethods,
  ANSWER_FEE_MODE_OPTIONS: feeModeChoices,
  ANSWER_EARLY_TERMINATION_FEE: earlyTerminationFeeChoices,
  ANSWER_ALLOWABLE_DAYS_APPLICANTS_MOVE_IN: allowableDaysApplicantsMoveIn,
  ANSWER_APARTMENT_HOLD_EXPIRATION_OPTIONS: appartmentHoldExpiration,
  ANSWER_RESIDENT_PARKING_TYPE_OPTIONS: residentParkingType,
  ANSWER_GUEST_PARKING_TYPE_OPTIONS: guessParkingType,
  ANSWER_UNIT_OR_LEASEHOLDER_OPTIONS: unitOrLeasholder,
  ANSWER_UTILITY_OPTIONS: utiliesOptions,
  ANSWER_BASE_RENT_TYPE_OPTIONS: baseRentType,
  ANSWER_SCREENING_PROCESS_TIME_OPTIONS: screeingProcessTime,
  ANSWER_PEST_CONTROL_SERVICE_DAY_OPTIONS: pestControlServiceDay,
  ANSWER_DAYS_TO_VACATE_STANDARD_LEASE: daysToVacatestandardLease,
  ANSWER_DAYS_TO_VACATE_MONTH_TO_MONTH: daysToVacateMonthToMonth,
  ANSWER_RENEWAL_PERIOD_OPTIONS: renewalPeriod,
};

export const multiSelectionList = [
  'ANSWER_TOUR_TYPE_OPTIONS',
  'ANSWER_BEDROOM_PLANS_OPTIONS',
  'ANSWER_PROPERTIES_OPTIONS',
  'ANSWER_UTILITY_OPTIONS',
  'ANSWER_PAYMENT_METHOD_OPTIONS',
  'ANSWER_ACTIVE_LEASE_TERMS',
  'ANSWER_COMPETITORS_OPTIONS',
];

export const typesWithLoader = ['ANSWER_AGENT_OPTIONS', 'ANSWER_PROPERTIES_OPTIONS', 'ANSWER_COMPETITORS_OPTIONS', 'ANSWER_SUBMARKETS_OPTIONS'];

export const typeMapping = {
  ANSWER_TOUR_TYPE_OPTIONS: tourPlanChoices,
  ANSWER_BEDROOM_PLANS_OPTIONS: bedroomPlanChoices,
  ANSWER_UTILITY_OPTIONS: utiliesOptions,
  ANSWER_PAYMENT_METHOD_OPTIONS: paymentForms,
  ANSWER_ACTIVE_LEASE_TERMS: monthsList,
};
