import { PromiseActionType } from 'dwell/store/types';
import { EmailTemplateProps } from 'dwell/store/email_template/action-types';
import { SMSTemplateProps } from 'dwell/store/sms_template/action-types';

export enum ActionType {
  GET_NURTURE_STEPS_REQUEST = 'GET_NURTURE_STEPS_REQUEST',
  GET_NURTURE_STEPS_SUCCESS = 'GET_NURTURE_STEPS_SUCCESS',
  GET_NURTURE_STEPS_FAILURE = 'GET_NURTURE_STEPS_FAILURE',
  CREATE_NURTURE_STEP_REQUEST = 'CREATE_NURTURE_STEP_REQUEST',
  CREATE_NURTURE_STEP_SUCCESS = 'CREATE_NURTURE_STEP_SUCCESS',
  CREATE_NURTURE_STEP_FAILURE = 'CREATE_NURTURE_STEP_FAILURE',
  UPDATE_NURTURE_STEP_REQUEST = 'UPDATE_NURTURE_STEP_REQUEST',
  UPDATE_NURTURE_STEP_SUCCESS = 'UPDATE_NURTURE_STEP_SUCCESS',
  UPDATE_NURTURE_STEP_FAILURE = 'UPDATE_NURTURE_STEP_FAILURE',
  DELETE_NURTURE_STEP_REQUEST = 'DELETE_NURTURE_STEP_REQUEST',
  DELETE_NURTURE_STEP_SUCCESS = 'DELETE_NURTURE_STEP_SUCCESS',
  DELETE_NURTURE_STEP_FAILURE = 'DELETE_NURTURE_STEP_FAILURE',
}

export interface Attachment {
  id?: number,
  name: string,
  size: number,
  preview: string,
}

export interface NurtureStep {
  id: number,
  days_delay: number,
  email_body: string,
  email_subject: string,
  email_attachments?: Attachment[],
  sms_body: string,
  step_type: string,
  task_type?: string
  email_template?: EmailTemplateProps,
  sms_template?: SMSTemplateProps,
}

interface CreateNurtureStepAction {
  type: ActionType.CREATE_NURTURE_STEP_REQUEST | ActionType.CREATE_NURTURE_STEP_SUCCESS | ActionType.CREATE_NURTURE_STEP_FAILURE;

  result: {
    data: NurtureStep;
  };
  error: {
    response: { status: string };
  };
}

interface GetNurtureStepsAction {
  type: ActionType.GET_NURTURE_STEPS_REQUEST | ActionType.GET_NURTURE_STEPS_SUCCESS | ActionType.GET_NURTURE_STEPS_FAILURE;

  result: {
    data: NurtureStep[];
  };
  error: {
    response: { status: string };
  };
}

interface UpdateNurtureStepAction {
  type: ActionType.UPDATE_NURTURE_STEP_REQUEST | ActionType.UPDATE_NURTURE_STEP_SUCCESS | ActionType.UPDATE_NURTURE_STEP_FAILURE;

  result: {
    data: NurtureStep;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteNurtureStepAction {
  type: ActionType.DELETE_NURTURE_STEP_REQUEST | ActionType.DELETE_NURTURE_STEP_SUCCESS | ActionType.DELETE_NURTURE_STEP_FAILURE;
  id,
}

export type Action =
  | PromiseActionType
  | GetNurtureStepsAction
  | CreateNurtureStepAction
  | UpdateNurtureStepAction
  | DeleteNurtureStepAction;
