import { paths } from 'dwell/constants';
import {
  ActionType,
  Action,
  PageSection,
  PropertyTypes,
  LeadTypes,
  TourTypes,
  UnitTypes,
  ExpandedUnits,
} from './action-types';

export default {
  getProperties: (keyword: string): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROFILE_PROPERTIES_REQUEST,
        ActionType.GET_PROFILE_PROPERTIES_SUCCESS,
        ActionType.GET_PROFILE_PROPERTIES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROFILE_PROPERTIES, { params: { search: keyword, show_all: true } }),
    },
  }),

  getProfileDetails: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROFILE_DETAILS_REQUEST,
        ActionType.GET_PROFILE_DETAILS_SUCCESS,
        ActionType.GET_PROFILE_DETAILS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.PROFILE_DETAILS, id)),
    },
  }),

  createLead: (property: number, lead: LeadTypes): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_LEAD_FROM_PROFILE_REQUEST,
        ActionType.CREATE_LEAD_FROM_PROFILE_SUCCESS,
        ActionType.CREATE_LEAD_FROM_PROFILE_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.LEAD_FROM_PROFILE, property), lead),
    },
  }),

  createTour: (property: number, tour: TourTypes): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_TOUR_FROM_PROFILE_REQUEST,
        ActionType.CREATE_TOUR_FROM_PROFILE_SUCCESS,
        ActionType.CREATE_TOUR_FROM_PROFILE_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.TOUR_FROM_PROFILE, property), tour),
    },
  }),

  setSection: (section: PageSection): Action => ({ type: ActionType.SET_SECTION, section }),
  setPreviousSection: (section: PageSection): Action => ({ type: ActionType.SET_PREVIOUS_SECTION, section }),
  setExpandedUnits: (expandedUnits: ExpandedUnits): Action => ({ type: ActionType.SET_EXPANDED_UNITS, expandedUnits }),
  setActiveUnit: (unit: UnitTypes): Action => ({ type: ActionType.SET_ACTIVE_UNIT, unit }),
  setCurrentProperty: (property: PropertyTypes): Action => ({ type: ActionType.SET_PROPERTY, property }),
  openGalleryFullView: (isOpen: boolean): Action => ({ type: ActionType.OPEN_GALLERY_PREVIEW, isOpen }),
  openProfile: (isOpen: boolean): Action => ({ type: ActionType.OPEN_PROFILE, isOpen }),
  setUnitType: (unitType: string): Action => ({ type: ActionType.SET_UNIT_TYPE, unitType }),
};
