import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, GateKeyProps } from './action-types';

export default {
  createGateKey: (leaseId: number, body: GateKeyProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_GATE_KEY_REQUEST,
        ActionType.CREATE_GATE_KEY_SUCCESS,
        ActionType.CREATE_GATE_KEY_FAILURE,
      ],
      promise: client => client.post(build(api.v1.GATE_KEYS, leaseId), body),
    },
  }),

  deleteGateKey: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_GATE_KEY_REQUEST,
        ActionType.DELETE_GATE_KEY_SUCCESS,
        ActionType.DELETE_GATE_KEY_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.GATE_KEY_DETAILS, leaseId, id)),
    },
  }),

  updateGateKey: (leaseId: number, id: number, params: GateKeyProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_GATE_KEY_REQUEST,
        ActionType.UPDATE_GATE_KEY_SUCCESS,
        ActionType.UPDATE_GATE_KEY_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.GATE_KEY_DETAILS, leaseId, id), params),
    },
  }),
};
