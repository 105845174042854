import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_TARGET_NUMBERS_REQUEST = 'GET_TARGET_NUMBERS_REQUEST',
  GET_TARGET_NUMBERS_SUCCESS = 'GET_TARGET_NUMBERS_SUCCESS',
  GET_TARGET_NUMBERS_FAILURE = 'GET_TARGET_NUMBERS_FAILURE',

  GET_PROPERTY_TARGET_NUMBER_REQUEST = 'GET_PROPERTY_TARGET_NUMBER_REQUEST',
  GET_PROPERTY_TARGET_NUMBER_SUCCESS = 'GET_PROPERTY_TARGET_NUMBER_SUCCESS',
  GET_PROPERTY_TARGET_NUMBER_FAILURE = 'GET_PROPERTY_TARGET_NUMBER_FAILURE',

  CREATE_TARGET_NUMBER_REQUEST = 'CREATE_TARGET_NUMBER_REQUEST',
  CREATE_TARGET_NUMBER_SUCCESS = 'CREATE_TARGET_NUMBER_SUCCESS',
  CREATE_TARGET_NUMBER_FAILURE = 'CREATE_TARGET_NUMBER_FAILURE',

  UPDATE_TARGET_NUMBER_REQUEST = 'UPDATE_TARGET_NUMBER_REQUEST',
  UPDATE_TARGET_NUMBER_SUCCESS = 'UPDATE_TARGET_NUMBER_SUCCESS',
  UPDATE_TARGET_NUMBER_FAILURE = 'UPDATE_TARGET_NUMBER_FAILURE',

  DELETE_TARGET_NUMBER_REQUEST = 'DELETE_TARGET_NUMBER_REQUEST',
  DELETE_TARGET_NUMBER_SUCCESS = 'DELETE_TARGET_NUMBER_SUCCESS',
  DELETE_TARGET_NUMBER_FAILURE = 'DELETE_TARGET_NUMBER_FAILURE',
}

export interface TargetNumberProps {
  id?: number;
  name?: string;
  phone_number?: string;
  has_been_scheduled?: boolean;
}

export interface TargetNumberState {
  isSubmitting: boolean;
  isLoaded: boolean;
  errorMessage: string;
  targetNumbers: TargetNumberProps[];
}

interface GetTargetNumbersAction {
  type: ActionType.GET_TARGET_NUMBERS_REQUEST | ActionType.GET_TARGET_NUMBERS_SUCCESS | ActionType.GET_TARGET_NUMBERS_FAILURE;

  result: {
    data: { results: TargetNumberProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertyTargetNumberAction {
  type: ActionType.GET_PROPERTY_TARGET_NUMBER_REQUEST | ActionType.GET_PROPERTY_TARGET_NUMBER_SUCCESS | ActionType.GET_PROPERTY_TARGET_NUMBER_FAILURE;

  result: {
    data: { results: TargetNumberProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface CreateTargetNumberAction {
  type: ActionType.CREATE_TARGET_NUMBER_REQUEST | ActionType.CREATE_TARGET_NUMBER_SUCCESS | ActionType.CREATE_TARGET_NUMBER_FAILURE;

  result: {
    data: TargetNumberProps;
  };
  error: {
    response: { status: string };
  };
}

interface CreateTargetNumberSiteOnlyAction {
  type: ActionType.CREATE_TARGET_NUMBER_REQUEST | ActionType.CREATE_TARGET_NUMBER_SUCCESS | ActionType.CREATE_TARGET_NUMBER_FAILURE;

  result: {
    data: TargetNumberProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateTargetNumberAction {
  type: ActionType.UPDATE_TARGET_NUMBER_REQUEST | ActionType.UPDATE_TARGET_NUMBER_SUCCESS | ActionType.UPDATE_TARGET_NUMBER_FAILURE;

  result: {
    data: TargetNumberProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateTargetNumberSiteOnlyAction {
  type: ActionType.UPDATE_TARGET_NUMBER_REQUEST | ActionType.UPDATE_TARGET_NUMBER_SUCCESS | ActionType.UPDATE_TARGET_NUMBER_FAILURE;

  result: {
    data: TargetNumberProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteTargetNumberAction {
  type: ActionType.DELETE_TARGET_NUMBER_REQUEST | ActionType.DELETE_TARGET_NUMBER_SUCCESS | ActionType.DELETE_TARGET_NUMBER_FAILURE;

  result: {
    data: TargetNumberProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteTargetNumberSiteOnlyAction {
  type: ActionType.DELETE_TARGET_NUMBER_REQUEST | ActionType.DELETE_TARGET_NUMBER_SUCCESS | ActionType.DELETE_TARGET_NUMBER_FAILURE;

  result: {
    data: TargetNumberProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetTargetNumbersAction
  | GetPropertyTargetNumberAction
  | CreateTargetNumberAction
  | CreateTargetNumberSiteOnlyAction
  | UpdateTargetNumberAction
  | UpdateTargetNumberSiteOnlyAction
  | DeleteTargetNumberAction
  | DeleteTargetNumberSiteOnlyAction;
