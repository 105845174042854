import React, { FC, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectIsTheFirstPropertyCBPlatform } from 'dwell/store/property/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { tasksNav, emailNav } from 'dwell/constants';

import NavItemDropdown from './_nav_item_dropdown';
import NavItem from './_nav_item';

import { NavHeader } from './styles';

interface NavProps {
  selectedProperties: PropertyProps[];
}

const Nav: FC<NavProps> = ({ selectedProperties }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeLink, setActiveLink] = useState<string>(window.location.pathname);
  const currentUser = useSelector(selectCurrentUser);
  const isTheFirstPropertyCBPlatform = useSelector(selectIsTheFirstPropertyCBPlatform);

  const newPipelineEnabled = useMemo(() => selectedProperties.every(i => i.new_pipeline_enabled), [selectedProperties]);
  const isCallScorer = currentUser.is_call_scorer;
  const isChatReviewer = currentUser.is_chat_reviewer;
  const adminCallsAndChats = isCallScorer || isChatReviewer;
  const allowBulkSign = selectedProperties[0]?.allow_bulk_sign || false;

  useEffect(
    () => () => {
      setActiveLink(location.pathname);
    },
    [],
  );

  const setActiveAndPush = (url: string) => {
    if (url.includes('/followups')) {
      setActiveLink(`/${selectedProperties[0].external_id}/followups`);
      history.push(`/${selectedProperties[0].external_id}/followups`);
    } else if (url.includes('/bulk_email')) {
      setActiveLink(`/${selectedProperties[0].external_id}/bulk_email`);
      history.push(`/${selectedProperties[0].external_id}/bulk_email`);
    } else if (url.includes('/bulk_sign')) {
      setActiveLink(`/${selectedProperties[0].external_id}/bulk_sign`);
      history.push(`/${selectedProperties[0].external_id}/bulk_sign`);
    } else {
      setActiveLink(url);
      history.push(url);
    }
  };

  const siteId = isTheFirstPropertyCBPlatform ? selectedProperties[0].external_id : 'properties';
  // eslint-disable-next-line no-nested-ternary
  const name = isTheFirstPropertyCBPlatform ? 'credit-builder/leases' : newPipelineEnabled ? 'pipeline' : 'leads';

  const pipelineNav = (
    <NavItem
      siteId={siteId}
      name={name}
      alterName="pipeline"
      activeLink={activeLink}
      setActiveAndPush={setActiveAndPush}
    />
  );

  return (
    <NavHeader>
      {!adminCallsAndChats && pipelineNav}
      <React.Fragment>
        {!isTheFirstPropertyCBPlatform && (
          <React.Fragment>
            {!adminCallsAndChats && (selectedProperties.length > 1 || !allowBulkSign) && <NavItem siteId="properties" name="tasks" alterName="" activeLink={activeLink} setActiveAndPush={setActiveAndPush} />}
            {!adminCallsAndChats && selectedProperties.length === 1 && allowBulkSign && <NavItemDropdown siteId="properties" type={tasksNav} activeLink={activeLink} setActiveAndPush={setActiveAndPush} />}
            {!adminCallsAndChats && selectedProperties.length === 1 && <NavItemDropdown siteId="properties" type={emailNav} activeLink={activeLink} setActiveAndPush={setActiveAndPush} />}
            {isCallScorer && <NavItem siteId="call-rescores" name="calls" alterName="" activeLink={activeLink} setActiveAndPush={setActiveAndPush} />}
            {!isCallScorer && <NavItem siteId="properties" name="calls" alterName="" activeLink={activeLink} setActiveAndPush={setActiveAndPush} />}
            {isChatReviewer && <NavItem siteId="properties" name="chats" alterName="" activeLink={activeLink} setActiveAndPush={setActiveAndPush} />}
          </React.Fragment>
        )}
        {!adminCallsAndChats && <NavItem siteId="properties" name="reports" alterName="" activeLink={activeLink} setActiveAndPush={setActiveAndPush} />}
      </React.Fragment>
    </NavHeader>
  );
};

export default Nav;
