import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/initialize';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import actions from 'dwell/actions';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { LeadCreationDialog } from 'dwell/components';
import { fieldChoices } from 'dwell/constants';
import 'src/scss/pages/_lead_creation.scss';
import { isEmpty } from 'lodash';
import 'react-dates/lib/css/_datepicker.css';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { PropertyProps } from 'dwell/store/property/action-types';
import { OwnerProps } from 'src/interfaces';
import { StyledModal, ErrorLabel } from 'styles/common';
import focusBarActions from 'dwell/store/focus_bar/action-creators';
import { platformTypes } from 'site/constants';
import { Divider } from './styles';

export interface CallProps {
  source: string;
  prospect_phone_number: string;
  id: number;
  call_result: string;
  property: number;
  known_caller?: boolean;
}

interface LeadCreationModalProps {
  sender?: { firstName: string; lastName: string; email: string; };
  call?: CallProps;
  floorPlans?: { id: number; plan: string }[];
  messageId?: number;
  show: boolean;
  handleClose: (id?: number) => void;
  updateCallLead?: (id: number, data: { lead: number }) => void;
  shouldNotRedirect?: boolean;
  owners?: { id: number; email: string }[];
  initialProperty?: number;
}

const defaultLeadValue = {
  first_name: '',
  last_name: '',
  source: null,
  email: '',
  phone_number: '',
  allow_duplication: false,
  floor_plan: [],
  move_in_date: null,
  owner: null,
  best_contact_method: null,
  unit_type: null,
};

const LeadCreationModal: FC<LeadCreationModalProps> = ({
  sender,
  call = {} as CallProps,
  handleClose,
  messageId,
  updateCallLead,
  show,
  shouldNotRedirect = false,
  initialProperty,
}) => {
  const { push } = useHistory();

  const [isDuplicated, setIsDuplicated] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [fields, setFields] = useState(defaultLeadValue);
  const [focused, setFocused] = useState(false);
  const [errors, setErrors] = useState({ source: false, firstName: false, lastName: false, email: false, phone: false });
  const [owner, setOwner] = useState({} as OwnerProps);
  const [currentProperty, setCurrentProperty] = useState<PropertyProps>({});

  const dispatch = useDispatch();
  const { getPropertyDetails, setSelectedPropertyXname } = actions.property;
  const selectedProperties: PropertyProps[] = useSelector(selectSelectedProperties);

  const prospectSources = useSelector(state => state.property.property.sources);
  const floorPlans = useSelector(state => state.property.property.floor_plans);
  const owners = useSelector(state => state.property.property.users);
  const currentAssignedOwner = useSelector(state => state.assignLeadOwners.currentAssignedOwner);

  const { createLead, getLeadById, getPropertyLeadsNames } = actions.lead;
  const { updateMessageById } = actions.emailMessage;
  const { getCurrentAssignLeadOwner } = actions.assignLeadOwners;
  const { unitType } = fieldChoices.LEAD_FILED_CHOICES;
  let floorPlanChoices = [];
  let convertedSourceChoices = [];
  let convertedProperties = [];
  let ownersChoices = [];
  const contactPreferenceChoices = [
    { value: 'EMAIL', label: 'Email' },
    { value: 'PHONE', label: 'Phone' },
    { value: 'TEXT', label: 'Text' },
  ];
  const unitTypeChoices = Object.keys(unitType).map(item => ({ value: item, label: unitType[item] }));

  useEffect(() => {
    setCurrentProperty({});
  }, [selectedProperties]);

  useEffect(() => {
    if (!currentProperty.id && selectedProperties.length) {
      if (!initialProperty && selectedProperties[0].id) {
        if (!platformTypes.isSiteOnly(selectedProperties[0].platforms)) {
          if (!messageId) dispatch(getPropertyDetails(selectedProperties[0].id));
          setCurrentProperty(selectedProperties[0]);
        }
      } else if (initialProperty && !isEmpty(selectedProperties)) {
        const selectedProperty = selectedProperties.find(p => p.id === initialProperty);
        if (!selectedProperty) {
          dispatch(getPropertyDetails(initialProperty));
        } else {
          setCurrentProperty(selectedProperty);
        }
      }
    }
  }, [selectedProperties, currentProperty, initialProperty]);

  useEffect(() => {
    if (show && currentProperty.id) {
      dispatch(setSelectedPropertyXname(currentProperty));
      dispatch(getPropertyDetails(currentProperty.id));
      dispatch(getCurrentAssignLeadOwner());
    }
  }, [currentProperty.id, show]);

  useEffect(() => {
    if (isEmpty(currentAssignedOwner)) {
      setOwner({ id: null, email: 'Unassigned' });
    } else {
      setOwner(currentAssignedOwner);
    }
  }, [currentAssignedOwner]);

  useEffect(() => {
    if (!show) {
      setFields(defaultLeadValue);
    }
  }, [show]);

  useEffect(() => {
    if (show && !isEmpty(call) && !isEmpty(selectedProperties)) {
      const source = prospectSources.find(s => s.name === call.source);
      setFields({ ...fields, phone_number: call.prospect_phone_number || '', source: !isEmpty(source) ? source.id : '' });
      const callProperty = selectedProperties.find(p => p.id === call.property);
      setCurrentProperty(callProperty);
    }
  }, [call, selectedProperties]);

  useEffect(() => {
    if (show && !isEmpty(sender)) {
      setFields({ ...fields, first_name: sender.firstName || '', last_name: sender.lastName || '', email: sender.email || '' });
    }
  }, [sender]);

  const onClose = () => {
    dispatch(setSelectedPropertyXname({}));
    handleClose();
  };

  const hideModal = (shouldSave = false, shouldClose = false) => {
    setIsDuplicated(false);
    if (shouldClose) {
      onClose();
    } else if (shouldSave) {
      setFields({ ...fields, allow_duplication: true });
    }
  };

  const handleSaveSuccess = (e) => {
    dispatch(getPropertyLeadsNames({ property: currentProperty.id }));
    dispatch(setSelectedPropertyXname({}));
    handleClose(e.result.data.id);
    if (shouldNotRedirect) return;
    if (messageId) {
      dispatch(updateMessageById(messageId, { lead: e.result.data.id })).then(() => {
        dispatch(getLeadById(e.result.data.id));
      });
    } else if (!isEmpty(call)) {
      updateCallLead(call.id, e.result.data.id);
    } else {
      // get lead details to obtain LeadDetailsSerializer fields and
      // have all the information needed like moving_reasons, best_time_contact, etc.
      // Otherwise we won't show "Moving reason" options and will have issues
      // updating some details as we compare first old values vs new values to make
      // the request since LeadCreationSerializer and LeadDetailsSerializer return
      // different information
      dispatch(focusBarActions.resetFocusBar());
      dispatch(getLeadById(e.result.data.id));
      push(`/${currentProperty.external_id}/leads/${e.result.data.id}`);
    }
  };

  const handleSaveFailure = ({ response: { data } }) => {
    if (data.id) {
      setIsDuplicated(true);
      setErrorData(data);
    }
  };

  const validate = () => (
    /*
      case 1:
        phone = ''
        email = ''
        phoneError: true && true => true
        emailError: true && true => true

      case 2:
        phone = '(123) 456 7890'
        email = ''
        phoneError: true && false => false
        emailError: false && true => false

      case 3:
        phone = ''
        email = 'foo@gmail.com'
        phoneError: false && true => false
        emailError: true && false => false

      case 4:
        phone = '(123) 456 7890'
        email = 'foo@gmail.com'
        phoneError: false && false => false
        emailError: false && false => false
    */
    {
      source: !fields.source,
      firstName: !fields.first_name,
      lastName: !fields.last_name,
      phone: !fields.email && !fields.phone_number,
      email: !fields.phone_number && !fields.email,
    }
  );

  const handleSave = () => {
    let hasFollowUp = true;
    if (call.id && call.call_result === 'no-answer') {
      hasFollowUp = false;
    }
    const newErrors = validate();
    setErrors(newErrors);
    if (!Object.values(newErrors).filter(i => i).length) {
      dispatch(createLead({
        ...fields,
        has_followup: hasFollowUp,
        first_name: fields.first_name || '-',
        last_name: fields.last_name || '-',
        owner: owner.id,
      }))
        .then((e) => {
          handleSaveSuccess(e);
        })
        .catch(error => handleSaveFailure(error));
    }
  };

  useEffect(() => {
    if (fields.allow_duplication) {
      handleSave();
    }
  }, [fields.allow_duplication]);

  const handleOnChange = ({ target: { value, id } }) => {
    setFields({ ...fields, [id]: value });
  };

  const handleOwnerChange = ({ target: { value, id } }) => {
    handleOnChange({ target: { value, id } });
    setOwner(owners.find(o => o.id === value) || { id: null, email: 'Unassigned' });
  };

  useEffect(() => {
    if (Object.values(errors).filter(i => i).length) setErrors(validate());
  }, [fields.source, fields.first_name, fields.last_name, fields.email, fields.phone_number]);

  const closeBtn = (
    <button className="close" onClick={onClose}>
      <i className="ri-close-line" />
    </button>
  );

  if (!isEmpty(floorPlans)) {
    floorPlanChoices = floorPlans.map(floorPlan => ({ value: floorPlan.id, label: floorPlan.plan }));
  }

  if (!isEmpty(prospectSources)) {
    convertedSourceChoices = prospectSources.map(source => ({ value: source.id, label: source.name }));
  }

  if (!isEmpty(selectedProperties)) {
    convertedProperties = selectedProperties.map(property => ({ id: property.id, label: property.name, object: property }));
  }

  if (!isEmpty(owners)) {
    ownersChoices = owners
      .sort((a, b) => a.email.localeCompare(b.email))
      .map(o => ({ value: o.id, label: o.email }));
  }

  const formatOptionLabel = ({ label, value }) => (
    <div style={{ display: 'flex' }}>
      <div className="inputCheck">
        <input type="checkbox" defaultChecked={fields.floor_plan && fields.floor_plan.includes(value)} />
      </div>
      <div style={{ color: 'red !important' }}>{label}</div>
    </div>
  );

  return (
    <StyledModal
      isOpen={show}
      toggle={onClose}
      centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <ModalHeader close={closeBtn}>Add New Lead</ModalHeader>
      <ModalBody>
        {isDuplicated && (
          <LeadCreationDialog
            handleClose={hideModal}
            show={isDuplicated}
            newLeadData={{ firstName: fields.first_name, lastName: fields.last_name, email: fields.email, moveInDate: fields.move_in_date }}
            errorData={errorData}
            property={currentProperty}
          />
        )}
        <Row>
          <Col xs="12">
            <Form>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="first_name">First Name <span className="text-danger">*</span></Label>
                    <Input type="text" name="first_name" id="first_name" placeholder="Enter first name" value={fields.first_name || ''} onChange={handleOnChange} invalid={errors.firstName} />
                    <FormFeedback>First Name is required.</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="last_name">Last Name <span className="text-danger">*</span></Label>
                    <Input
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Enter last name"
                      value={fields.last_name || ''}
                      onChange={handleOnChange}
                      invalid={errors.lastName}
                    />
                    <FormFeedback>Last Name is required.</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="email">Email address <span className="text-danger">*</span></Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter email address"
                      value={fields.email || ''}
                      onChange={handleOnChange}
                      invalid={errors.email}
                    />
                    <FormFeedback>Email is required.</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="phone_number">Phone number <span className="text-danger">*</span></Label>
                    <PhoneInput
                      country="us"
                      onlyCountries={['us']}
                      id="phone_number"
                      placeholder="Enter phone number"
                      inputClass="phone-number-input"
                      value={fields.phone_number || ''}
                      onChange={phone => handleOnChange({ target: { id: 'phone_number', value: phone } })}
                      disableDropdown
                      disableCountryCode
                    />
                    <FormFeedback>Phone Number is required.</FormFeedback>
                    {errors.phone && <ErrorLabel>Phone Number is required</ErrorLabel>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="source">Source <span className="text-danger">*</span></Label>
                    <Select
                      defaultValue={convertedSourceChoices.find(i => i.value === fields.source)}
                      options={convertedSourceChoices}
                      classNamePrefix="select"
                      placeholder="Select source"
                      onChange={item => handleOnChange({ target: { id: 'source', value: item.value } })}
                    />
                    <Input invalid={errors.source} hidden />
                    <FormFeedback>Source is required.</FormFeedback>
                  </FormGroup>
                </Col>
                {selectedProperties.length > 1 && !initialProperty && (
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="property">Property <span className="text-danger">*</span></Label>
                      <Select
                        options={convertedProperties}
                        classNamePrefix="select"
                        placeholder="Select property"
                        onChange={item => setCurrentProperty(item.object)}
                      />
                      <Input invalid={errors.source} hidden />
                      <FormFeedback>Property is required.</FormFeedback>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Divider className="w-100"><span>Optional</span></Divider>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label>Contact Preference</Label>
                    <Select
                      defaultValue={contactPreferenceChoices.find(i => i.value === fields.best_contact_method)}
                      options={contactPreferenceChoices}
                      classNamePrefix="select"
                      placeholder="Select preference"
                      onChange={item => handleOnChange({ target: { id: 'best_contact_method', value: item.value } })}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label>Move-in-date</Label>
                    <SingleDatePicker
                      inputIconPosition="after"
                      small
                      block
                      numberOfMonths={1}
                      placeholder="Select date"
                      isOutsideRange={() => false}
                      date={fields.move_in_date ? moment(fields.move_in_date) : null}
                      onDateChange={date => handleOnChange({ target: { id: 'move_in_date', value: date && date.format('YYYY-MM-DD') } })}
                      focused={focused}
                      onFocusChange={e => setFocused(e.focused)}
                      openDirection="down"
                      hideKeyboardShortcutsPanel
                      isDayHighlighted={day => day.isSame(moment(), 'd')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="floorplan">Floorplan(s)</Label>
                    <Select
                      defaultValue={isEmpty(fields.floor_plan) ? null : floorPlanChoices.filter(option => fields.floor_plan.includes(option.value))}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      formatOptionLabel={formatOptionLabel}
                      options={floorPlanChoices}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select floor plan"
                      onChange={selectedItems => handleOnChange({ target: { id: 'floor_plan', value: selectedItems && selectedItems.length ? selectedItems.map(item => item.value) : [] } })}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="owner">Owner</Label>
                    <Select
                      options={[{ value: null, label: 'Unassigned' }].concat(ownersChoices)}
                      classNamePrefix="select"
                      placeholder="Select owner"
                      value={{ value: owner.id, label: owner.email }}
                      onChange={({ value }) => handleOwnerChange({ target: { value, id: 'owner' } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="owner">Unit Type</Label>
                    <Select
                      options={unitTypeChoices}
                      classNamePrefix="select"
                      placeholder="Select unit type"
                      defaultValue={unitTypeChoices.find(i => i.value === fields.unit_type)}
                      onChange={({ value }) => handleOnChange({ target: { value, id: 'unit_type' } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} disabled={Object.keys(errors).some(x => errors[x])}>
          Add Lead
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

export default LeadCreationModal;
