import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { ApplicantProps } from 'leasing/store/applicant/action-types';
import { ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_GUARANTOR_REQUEST = 'CREATE_GUARANTOR_REQUEST',
  CREATE_GUARANTOR_SUCCESS = 'CREATE_GUARANTOR_SUCCESS',
  CREATE_GUARANTOR_FAILURE = 'CREATE_GUARANTOR_FAILURE',

  DELETE_GUARANTOR_REQUEST = 'DELETE_GUARANTOR_REQUEST',
  DELETE_GUARANTOR_SUCCESS = 'DELETE_GUARANTOR_SUCCESS',
  DELETE_GUARANTOR_FAILURE = 'DELETE_GUARANTOR_FAILURE',

  GET_GUARANTOR_BY_ID_REQUEST = 'GET_GUARANTOR_BY_ID_REQUEST',
  GET_GUARANTOR_BY_ID_SUCCESS = 'GET_GUARANTOR_BY_ID_SUCCESS',
  GET_GUARANTOR_BY_ID_FAILURE = 'GET_GUARANTOR_BY_ID_FAILURE',

  GET_GUARANTORS_REQUEST = 'GET_GUARANTORS_REQUEST',
  GET_GUARANTORS_SUCCESS = 'GET_GUARANTORS_SUCCESS',
  GET_GUARANTORS_FAILURE = 'GET_GUARANTORS_FAILURE',

  RESEND_APPLICATION_INVITE_REQUEST = 'RESEND_APPLICATION_INVITE_REQUEST',
  RESEND_APPLICATION_INVITE_SUCCESS = 'RESEND_APPLICATION_INVITE_SUCCESS',
  RESEND_APPLICATION_INVITE_FAILURE = 'RESEND_APPLICATION_INVITE_FAILURE',

  RESET_GUARANTOR = 'RESET_GUARANTOR',

  UPDATE_GUARANTOR_REQUEST = 'UPDATE_GUARANTOR_REQUEST',
  UPDATE_GUARANTOR_SUCCESS = 'UPDATE_GUARANTOR_SUCCESS',
  UPDATE_GUARANTOR_FAILURE = 'UPDATE_GUARANTOR_FAILURE',
}

export interface GuarantorState extends CommonStateType {
  guarantor: ApplicantProps
  guarantors: ApplicantProps[]
  isGuarantorsLoaded: boolean,
}

/*
 * Actions
 */

interface CreateGuarantorAction extends CommonActionResponse {
  type: ActionType.CREATE_GUARANTOR_REQUEST
  | ActionType.CREATE_GUARANTOR_SUCCESS
  | ActionType.CREATE_GUARANTOR_FAILURE;
  result: { data: ApplicantProps };
}

interface DeleteGuarantorAction extends CommonActionResponse {
  type: ActionType.DELETE_GUARANTOR_REQUEST
  | ActionType.DELETE_GUARANTOR_SUCCESS
  | ActionType.DELETE_GUARANTOR_FAILURE;
  result: { data: ApplicantProps };
}

interface GetGuarantorByIdAction extends CommonActionResponse {
  type: ActionType.GET_GUARANTOR_BY_ID_REQUEST
  | ActionType.GET_GUARANTOR_BY_ID_SUCCESS
  | ActionType.GET_GUARANTOR_BY_ID_FAILURE;
  result: { data: ApplicantProps };
}

interface GetGuarantorsAction extends CommonActionResponse {
  type: ActionType.GET_GUARANTORS_REQUEST
  | ActionType.GET_GUARANTORS_SUCCESS
  | ActionType.GET_GUARANTORS_FAILURE;
  result: { data: { results: ApplicantProps[] } };
}

interface ResetGuarantorAction {
  type: ActionType.RESET_GUARANTOR;
}

interface UpdateGuarantorAction extends CommonActionResponse {
  type: ActionType.UPDATE_GUARANTOR_REQUEST
  | ActionType.UPDATE_GUARANTOR_SUCCESS
  | ActionType.UPDATE_GUARANTOR_FAILURE;
  result: { data: ApplicantProps };
}

export type Action =
  PromiseActionType
  | CreateGuarantorAction
  | DeleteGuarantorAction
  | GetGuarantorByIdAction
  | GetGuarantorsAction
  | ResetGuarantorAction
  | ResetLeaseAction
  | UpdateGuarantorAction;
