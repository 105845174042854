import dwellReducers from 'dwell/store/reducers';

export type DwellStateType = {
  apartment: ReturnType<typeof dwellReducers.apartment>,
  assignLeadOwners: ReturnType<typeof dwellReducers.assignLeadOwners>,
  assistBot: ReturnType<typeof dwellReducers.assistBot>,
  authentication: ReturnType<typeof dwellReducers.authentication>,
  businessHours: ReturnType<typeof dwellReducers.businessHours>,
  calendar: ReturnType<typeof dwellReducers.calendar>,
  call: ReturnType<typeof dwellReducers.call>,
  callSchedule: ReturnType<typeof dwellReducers.callSchedule>,
  callScoringQuestions: ReturnType<typeof dwellReducers.callScoringQuestions>,
  chatsEvaluation: ReturnType<typeof dwellReducers.chatsEvaluation>,
  chatTemplate: ReturnType<typeof dwellReducers.chatTemplate>,
  columnsSettings: ReturnType<typeof dwellReducers.columnsSettings>,
  communityDetails: ReturnType<typeof dwellReducers.communityDetails>,
  competitor: ReturnType<typeof dwellReducers.competitor>,
  emailLabel: ReturnType<typeof dwellReducers.emailLabel>,
  emailMessage: ReturnType<typeof dwellReducers.emailMessage>,
  emailPopouts: ReturnType<typeof dwellReducers.emailPopouts>,
  emailTemplate: ReturnType<typeof dwellReducers.emailTemplate>,
  holiday: ReturnType<typeof dwellReducers.holiday>,
  lead: ReturnType<typeof dwellReducers.lead>,
  leadsFilter: ReturnType<typeof dwellReducers.leadsFilter>,
  note: ReturnType<typeof dwellReducers.note>,
  notification: ReturnType<typeof dwellReducers.notification>,
  nylas: ReturnType<typeof dwellReducers.nylas>,
  pmsLogs: ReturnType<typeof dwellReducers.pmsLogs>,
  portfolio: ReturnType<typeof dwellReducers.portfolio>,
  propertiesSelector: ReturnType<typeof dwellReducers.propertiesSelector>,
  property: ReturnType<typeof dwellReducers.property>,
  propertyProfile: ReturnType<typeof dwellReducers.propertyProfile>,
  prospectChat: ReturnType<typeof dwellReducers.prospectChat>,
  prospectSource: ReturnType<typeof dwellReducers.prospectSource>,
  pusher: ReturnType<typeof dwellReducers.pusher>,
  report: ReturnType<typeof dwellReducers.report>,
  rescoreUsers: ReturnType<typeof dwellReducers.rescoreUsers>,
  resetPassword: ReturnType<typeof dwellReducers.resetPassword>,
  roommate: ReturnType<typeof dwellReducers.roommate>,
  scoredCalls: ReturnType<typeof dwellReducers.scoredCalls>,
  smsMessage: ReturnType<typeof dwellReducers.smsMessage>,
  smsTemplate: ReturnType<typeof dwellReducers.smsTemplate>,
  survey: ReturnType<typeof dwellReducers.survey>,
  targetNumber: ReturnType<typeof dwellReducers.targetNumber>,
  task: ReturnType<typeof dwellReducers.task>,
  user: ReturnType<typeof dwellReducers.user>,
  insiderInfo: ReturnType<typeof dwellReducers.insiderInfo>,
};

export default {
  ...dwellReducers,
};
