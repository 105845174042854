import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action } from './action-types';

export default {
  sendResetPasswordEmail: (email: string): Action => ({
    CALL_API: {
      types: [
        ActionType.SEND_PASSWORD_RESET_EMAIL_REQUEST,
        ActionType.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
        ActionType.SEND_PASSWORD_RESET_EMAIL_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.RESET_PASSWORD_SEND_EMAIL), email),
    },
  }),

  resetPassword: (data: { password: string; token: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.RESET_PASSWORD_REQUEST,
        ActionType.RESET_PASSWORD_SUCCESS,
        ActionType.RESET_PASSWORD_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.RESET_PASSWORD), data),
    },
  }),

  resetChangePasswordState: (): Action => ({ type: ActionType.RESET_CHANGE_PASSWORD_STATE }),
};
