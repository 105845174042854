import { api, build } from 'leasing/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  getScreeningIncompleteFields: (leaseId: number, userId?: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SCREENING_INCOMPLETE_FIELDS_REQUEST,
        ActionType.GET_SCREENING_INCOMPLETE_FIELDS_SUCCESS,
        ActionType.GET_SCREENING_INCOMPLETE_FIELDS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.SCREENING_INCOMPLETE_FIELDS, leaseId), { id: userId }),
    },
  }),

  getScreeningReports: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SCREENING_REPORTS_REQUEST,
        ActionType.GET_SCREENING_REPORTS_SUCCESS,
        ActionType.GET_SCREENING_REPORTS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.SCREENING_REPORTS, leaseId), null),
    },
  }),

  initiateScreening: (leaseId: number, userId?: number): Action => ({
    CALL_API: {
      types: [
        ActionType.INITIATE_SCREENING_REQUEST,
        ActionType.INITIATE_SCREENING_SUCCESS,
        ActionType.INITIATE_SCREENING_FAILURE,
      ],
      promise: client => client.post(build(api.v1.INITIATE_SCREENING, leaseId), { id: userId }),
    },
  }),
};
