import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_HISTORICAL_PROPERTY_RENT_REQUEST = 'GET_HISTORICAL_PROPERTY_RENT_REQUEST',
  GET_HISTORICAL_PROPERTY_RENT_SUCCESS = 'GET_HISTORICAL_PROPERTY_RENT_SUCCESS',
  GET_HISTORICAL_PROPERTY_RENT_FAILURE = 'GET_HISTORICAL_PROPERTY_RENT_FAILURE',
  GET_HISTORICAL_PROPERTY_OCCUPANCY_REQUEST = 'GET_HISTORICAL_PROPERTY_OCCUPANCY_REQUEST',
  GET_HISTORICAL_PROPERTY_OCCUPANCY_SUCCESS = 'GET_HISTORICAL_PROPERTY_OCCUPANCY_SUCCESS',
  GET_HISTORICAL_PROPERTY_OCCUPANCY_FAILURE = 'GET_HISTORICAL_PROPERTY_OCCUPANCY_FAILURE',
  GET_HISTORICAL_PROPERTY_CONCESSION_REQUEST = 'GET_HISTORICAL_PROPERTY_CONCESSION_REQUEST',
  GET_HISTORICAL_PROPERTY_CONCESSION_SUCCESS = 'GET_HISTORICAL_PROPERTY_CONCESSION_SUCCESS',
  GET_HISTORICAL_PROPERTY_CONCESSION_FAILURE = 'GET_HISTORICAL_PROPERTY_CONCESSION_FAILURE',
  GET_HISTORICAL_SUBMARKET_RENT_REQUEST = 'GET_HISTORICAL_SUBMARKET_RENT_REQUEST',
  GET_HISTORICAL_SUBMARKET_RENT_SUCCESS = 'GET_HISTORICAL_SUBMARKET_RENT_SUCCESS',
  GET_HISTORICAL_SUBMARKET_RENT_FAILURE = 'GET_HISTORICAL_SUBMARKET_RENT_FAILURE',
  GET_HISTORICAL_SUBMARKET_OCCUPANCY_REQUEST = 'GET_HISTORICAL_SUBMARKET_OCCUPANCY_REQUEST',
  GET_HISTORICAL_SUBMARKET_OCCUPANCY_SUCCESS = 'GET_HISTORICAL_SUBMARKET_OCCUPANCY_SUCCESS',
  GET_HISTORICAL_SUBMARKET_OCCUPANCY_FAILURE = 'GET_HISTORICAL_SUBMARKET_OCCUPANCY_FAILURE',
  GET_HISTORICAL_SUBMARKET_CONCESSION_REQUEST = 'GET_HISTORICAL_SUBMARKET_CONCESSION_REQUEST',
  GET_HISTORICAL_SUBMARKET_CONCESSION_SUCCESS = 'GET_HISTORICAL_SUBMARKET_CONCESSION_SUCCESS',
  GET_HISTORICAL_SUBMARKET_CONCESSION_FAILURE = 'GET_HISTORICAL_SUBMARKET_CONCESSION_FAILURE',
  GET_HISTORICAL_MARKET_RENT_REQUEST = 'GET_HISTORICAL_MARKET_RENT_REQUEST',
  GET_HISTORICAL_MARKET_RENT_SUCCESS = 'GET_HISTORICAL_MARKET_RENT_SUCCESS',
  GET_HISTORICAL_MARKET_RENT_FAILURE = 'GET_HISTORICAL_MARKET_RENT_FAILURE',
  GET_HISTORICAL_MARKET_OCCUPANCY_REQUEST = 'GET_HISTORICAL_MARKET_OCCUPANCY_REQUEST',
  GET_HISTORICAL_MARKET_OCCUPANCY_SUCCESS = 'GET_HISTORICAL_MARKET_OCCUPANCY_SUCCESS',
  GET_HISTORICAL_MARKET_OCCUPANCY_FAILURE = 'GET_HISTORICAL_MARKET_OCCUPANCY_FAILURE',
  GET_HISTORICAL_MARKET_CONCESSION_REQUEST = 'GET_HISTORICAL_MARKET_CONCESSION_REQUEST',
  GET_HISTORICAL_MARKET_CONCESSION_SUCCESS = 'GET_HISTORICAL_MARKET_CONCESSION_SUCCESS',
  GET_HISTORICAL_MARKET_CONCESSION_FAILURE = 'GET_HISTORICAL_MARKET_CONCESSION_FAILURE',
  GET_HISTORICAL_UNDER_OVER_RENT_REQUEST = 'GET_HISTORICAL_UNDER_OVER_RENT_REQUEST',
  GET_HISTORICAL_UNDER_OVER_RENT_SUCCESS = 'GET_HISTORICAL_UNDER_OVER_RENT_SUCCESS',
  GET_HISTORICAL_UNDER_OVER_RENT_FAILURE = 'GET_HISTORICAL_UNDER_OVER_RENT_FAILURE',
}

export interface HistoricalRequestProps {
  period?: string,
  group?: string,
  unit_type?: string,
  show_as?: string,
  rent_as?: string,
}

export interface ChartData {
  start_date?: string;
  date?: string;
  value?: number;
  end_date?: string;
}

interface PrepareDate {
  chart_values: ChartData[];
}

export interface HistoricalChart {
  chart_values: ChartData[];
  net_absorption_unit_change?: number;
  net_absorption_percent_change?: number;
  net_rent?: number;
  net_rent_change?: number;
  net_concession?: number;
  net_concession_rent?: number;
}

interface GetHistoricalPropertyRentAction {
  type: ActionType.GET_HISTORICAL_PROPERTY_RENT_REQUEST | ActionType.GET_HISTORICAL_PROPERTY_RENT_SUCCESS | ActionType.GET_HISTORICAL_PROPERTY_RENT_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalPropertyOccupancyAction {
  type: ActionType.GET_HISTORICAL_PROPERTY_OCCUPANCY_REQUEST | ActionType.GET_HISTORICAL_PROPERTY_OCCUPANCY_SUCCESS | ActionType.GET_HISTORICAL_PROPERTY_OCCUPANCY_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalPropertyConcessionAction {
  type: ActionType.GET_HISTORICAL_PROPERTY_CONCESSION_REQUEST | ActionType.GET_HISTORICAL_PROPERTY_CONCESSION_SUCCESS | ActionType.GET_HISTORICAL_PROPERTY_CONCESSION_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalSubmarketRentAction {
  type: ActionType.GET_HISTORICAL_SUBMARKET_RENT_REQUEST | ActionType.GET_HISTORICAL_SUBMARKET_RENT_SUCCESS | ActionType.GET_HISTORICAL_SUBMARKET_RENT_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalSubmarketOccupancyAction {
  type: ActionType.GET_HISTORICAL_SUBMARKET_OCCUPANCY_REQUEST | ActionType.GET_HISTORICAL_SUBMARKET_OCCUPANCY_SUCCESS | ActionType.GET_HISTORICAL_SUBMARKET_OCCUPANCY_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalSubmarketConcessionAction {
  type: ActionType.GET_HISTORICAL_SUBMARKET_CONCESSION_REQUEST | ActionType.GET_HISTORICAL_SUBMARKET_CONCESSION_SUCCESS | ActionType.GET_HISTORICAL_SUBMARKET_CONCESSION_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalMarketRentAction {
  type: ActionType.GET_HISTORICAL_MARKET_RENT_REQUEST | ActionType.GET_HISTORICAL_MARKET_RENT_SUCCESS | ActionType.GET_HISTORICAL_MARKET_RENT_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalMarketOccupancyAction {
  type: ActionType.GET_HISTORICAL_MARKET_OCCUPANCY_REQUEST | ActionType.GET_HISTORICAL_MARKET_OCCUPANCY_SUCCESS | ActionType.GET_HISTORICAL_MARKET_OCCUPANCY_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalMarketConcessionAction {
  type: ActionType.GET_HISTORICAL_MARKET_CONCESSION_REQUEST | ActionType.GET_HISTORICAL_MARKET_CONCESSION_SUCCESS | ActionType.GET_HISTORICAL_MARKET_CONCESSION_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

interface GetHistoricalUnderOverRentAction {
  type: ActionType.GET_HISTORICAL_UNDER_OVER_RENT_REQUEST | ActionType.GET_HISTORICAL_UNDER_OVER_RENT_SUCCESS | ActionType.GET_HISTORICAL_UNDER_OVER_RENT_FAILURE;
  result: {
    data: PrepareDate;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetHistoricalPropertyRentAction
  | GetHistoricalPropertyOccupancyAction
  | GetHistoricalPropertyConcessionAction
  | GetHistoricalSubmarketRentAction
  | GetHistoricalSubmarketOccupancyAction
  | GetHistoricalSubmarketConcessionAction
  | GetHistoricalMarketRentAction
  | GetHistoricalMarketOccupancyAction
  | GetHistoricalMarketConcessionAction
  | GetHistoricalUnderOverRentAction;
