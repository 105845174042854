import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LineSkeleton } from 'src/utils';
import actions from 'application/store/actions';
import { selectApplicationDetails, selectApplicationIsLoaded } from 'application/store/application/reducers';
import {
  OwnerDropdownMenu, StageDropdown, DropdownLink, CustomDropdownItem,
} from 'dwell/views/lead/layout/key_info/style';

interface DropdownTypes {
  choices: { [key: string]: string },
  field: string,
}

const Dropdown: FC<DropdownTypes> = ({ choices, field }) => {
  const dispatch = useDispatch();
  const [isOpen, open] = useState(false);
  const { updateApplication } = actions.application;
  const application = useSelector(selectApplicationDetails);
  const isLoaded = useSelector(selectApplicationIsLoaded);

  const onSave = (value) => {
    dispatch(updateApplication(application.id, { [field]: value }));
  };

  const value = application[field];

  return (
    <>
      {isLoaded ?
        <StageDropdown isOpen={isOpen} toggle={() => open(!isOpen)}>
          <DropdownLink caret>
            {value ? choices[value] : `Select ${field}`}
          </DropdownLink>
          <OwnerDropdownMenu>
            {Object.keys(choices).map((key, index) => (
              <React.Fragment key={index}>
                <CustomDropdownItem onClick={() => onSave(key)}>
                  {choices[key]}
                </CustomDropdownItem>
              </React.Fragment>))}
          </OwnerDropdownMenu>
        </StageDropdown> : <LineSkeleton width={150} height={38} />}
    </>
  );
};

export default Dropdown;
