import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { Action, ActionType, SubmarketProps } from './action-types';
import { PropertiesBreakdown, SubmarketDetail, RentComps } from '../market/action-types';

export interface SubmarketState extends CommonStateType {
  isSubmitting: boolean;
  errorMessage: string;
  isSubmarketPropertiesLoaded: boolean;
  isSubmarketDetailLoaded: boolean;
  isRentCompsLoaded: boolean;
  isSubmarketBreakdownLoaded: boolean;
  countSubmarketProperties: number;
  countRentComps: number;
  countSubmarketBreakdown: number;
  submarketProperties: PropertiesBreakdown;
  submarketDetail: SubmarketDetail;
  rentComps: RentComps;
  submarketBreakdown: PropertiesBreakdown;
  submarketNames: SubmarketProps[];
}

const initialState: SubmarketState = {
  isSubmitting: false,
  errorMessage: null,
  isSubmarketPropertiesLoaded: false,
  isSubmarketDetailLoaded: false,
  isSubmarketBreakdownLoaded: false,
  isRentCompsLoaded: false,
  countSubmarketProperties: 0,
  countRentComps: 0,
  countSubmarketBreakdown: 0,
  submarketProperties: null,
  submarketDetail: null,
  rentComps: null,
  submarketBreakdown: null,
  submarketNames: [],
};

export default produce((state: SubmarketState = initialState, action: Action): SubmarketState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_SUBMARKET_PROPERTIES_REQUEST:
      state.isSubmarketPropertiesLoaded = false;
      break;

    case ActionType.GET_SUBMARKET_PROPERTIES_SUCCESS:
      state.submarketProperties = action.result.data.results;
      state.isSubmarketPropertiesLoaded = true;
      state.countSubmarketProperties = action.result.data.count;
      break;

    case ActionType.GET_SUBMARKET_PROPERTIES_FAILURE:
      state.isSubmarketPropertiesLoaded = false;
      break;

    case ActionType.GET_SUBMARKET_DETAIL_REQUEST:
      state.isSubmarketDetailLoaded = false;
      break;

    case ActionType.GET_SUBMARKET_DETAIL_SUCCESS:
      state.submarketDetail = action.result.data;
      state.isSubmarketDetailLoaded = true;
      break;

    case ActionType.GET_SUBMARKET_DETAIL_FAILURE:
      state.isSubmarketDetailLoaded = false;
      break;

    case ActionType.GET_SUBMARKET_RENT_COMPS_REQUEST:
      state.isRentCompsLoaded = false;
      break;

    case ActionType.GET_SUBMARKET_RENT_COMPS_SUCCESS:
      state.rentComps = action.result.data.results;
      state.countRentComps = action.result.data.count;
      state.isRentCompsLoaded = true;
      break;

    case ActionType.GET_SUBMARKET_RENT_COMPS_FAILURE:
      state.isRentCompsLoaded = false;
      break;

    case ActionType.GET_SUBMARKET_MTR_BREAKDOWN_REQUEST:
      state.isSubmarketBreakdownLoaded = false;
      break;

    case ActionType.GET_SUBMARKET_MTR_BREAKDOWN_SUCCESS:
      state.submarketBreakdown = action.result.data.results;
      state.countSubmarketBreakdown = action.result.data.count;
      state.isSubmarketBreakdownLoaded = true;
      break;

    case ActionType.GET_SUBMARKET_MTR_BREAKDOWN_FAILURE:
      state.isSubmarketBreakdownLoaded = false;
      break;

    case ActionType.GET_SUBMARKETS_NAMES_SUCCESS:
      state.submarketNames = action.result.data;
      break;
  }

  return state;
});
