import React, { FC, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'dwell/actions';
import { LOGGED_ACCOUNT } from 'site/constants';
import { PageSection } from 'dwell/store/property_profile/action-types';
import AssistBot from './assist_bot';
import { Panel, Body } from './styles';
import MainMenu from './main_menu';
import PropertyInfo from './property_info';
import Amenities from './amenities';
import LeasingRentalCriteria from './leasing_rental_criteria';
import Team from './team';
import AvailableUnits from './available_units';
import SisterCommunities from './sister_communities';
import Gallery from './gallery';
import Neighborhood from './neighborhood';
import AddLead from './add_lead';
import AddTour from './add_tour';
import Header from './header';
import UnitsByFloorPlan from './units_by_floor_plan';

const PropertyProfile: FC = () => {
  const dispatch = useDispatch();
  const { getProfileDetails } = actions.propertyProfile;
  const [command, setCommand] = useState(null);
  const callCountRef = useRef(0);
  const section = useSelector(state => state.propertyProfile.section);
  const isOpen = useSelector(state => state.propertyProfile.isOpen);
  const currentProperty = useSelector(state => state.propertyProfile.currentProperty);
  const isGalleryPreviewOpen = useSelector(state => state.propertyProfile.isGalleryPreviewOpen);
  const comm = [17, 91, 93, 224];

  useEffect(() => {
    if (currentProperty.id) {
      dispatch(getProfileDetails(currentProperty.id));
      const interval = setInterval(() => {
        if (callCountRef.current > 5) {
          clearInterval(interval);
        }
        const loggedAccount = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};
        if (loggedAccount.access) dispatch(getProfileDetails(currentProperty.id));
        callCountRef.current += 1;
      }, 10 * 60 * 1000);
    }
  }, [currentProperty.id]);

  const keyDown = (e) => {
    let shouldPreventDefault = false;
    if (comm.includes(e.keyCode)) {
      setCommand(e.keyCode);
    } else {
      if (!e.target.classList.contains('form-control')) {
        // CMD+K - open property profile
        if (command && e.keyCode === 75) {
          const element = document.querySelector('#profile_toggle') as HTMLAnchorElement;
          if (element) {
            shouldPreventDefault = true;
            element.click();
          }
        } else {
          // Single shortcut key to every sub page
          const element = document.querySelector(`span[data-key="${e.keyCode}"]`) as HTMLSpanElement;
          if (element) {
            shouldPreventDefault = true;
            element.click();
          }
        }
      }

      // Back to previous page
      if (command && e.keyCode === 66) {
        const element = document.querySelector('#back_to_main_menu') as HTMLAnchorElement;
        if (element) {
          shouldPreventDefault = true;
          element.click();
        }
      }
    }

    // Esc - closing property profile
    if (e.keyCode === 27) {
      if (!document.querySelector('#plan_carousel')) {
        const element = document.querySelector('#profile_close') as HTMLAnchorElement;
        if (element) {
          shouldPreventDefault = true;
          element.click();
        }
      }
    }

    if (shouldPreventDefault) e.preventDefault();
  };

  const keyUp = (e) => {
    e.preventDefault();
    if (comm.includes(e.keyCode)) {
      setCommand(null);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  });

  return (
    <>
      {isOpen && (
        <Panel isOpen={isOpen} isGalleryPreviewOpen={isGalleryPreviewOpen}>
          <Header />
          <Body id="ppBody" className="scroll-contents">
            {section === PageSection.MAIN_MENU && <MainMenu />}
            {section === PageSection.ASSIST_BOT && <AssistBot />}
            {section === PageSection.UNITS_BY_FLOOR_PLAN && <UnitsByFloorPlan />}
            {section === PageSection.PROPERTY_INFO && <PropertyInfo />}
            {section === PageSection.LEASING_CRITERIA && <LeasingRentalCriteria />}
            {section === PageSection.AMENITIES && <Amenities />}
            {section === PageSection.AVAILABLE_UNITS && <AvailableUnits />}
            {section === PageSection.TEAM && <Team />}
            {section === PageSection.SISTER_COMMUNITIES && <SisterCommunities />}
            {section === PageSection.NEIGHBORHOOD && <Neighborhood />}
            {section === PageSection.GALLERY && <Gallery />}
            {section === PageSection.ADD_NEW_LEAD && <AddLead />}
            {section === PageSection.ADD_NEW_TOUR && <AddTour />}
          </Body>
        </Panel>
      )}
    </>
  );
};

export default PropertyProfile;
