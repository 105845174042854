import React, { FC } from 'react';
import moment from 'moment';
import { Conversation, AgentQuestion, DirectAnswerTypes } from 'dwell/store/agent_question/action-types';
import { HobbesQuestion, HobbesQuestionBody } from 'dwell/views/chat/single_chat/window/styles';
import { getPropertiesJoinedText, getCustomersJoinedText } from '../utils';

interface HobbesConfirmationMessageProps {
  conversation: Conversation;
}

const ConfirmationMessage: FC<HobbesConfirmationMessageProps> = ({ conversation }) => {
  const { date } = conversation;
  const question = conversation.question as AgentQuestion;
  const { question_text, question_text_agent_side, properties, customers, settings_page, answer, direct_question_answer_expected_type } = question;
  const isCustomerQuestion = ['PAGE_HOBBES_SETTINGS'].includes(settings_page);
  const question_message = question_text_agent_side || question_text;
  let hobbesMessage = '';
  if (properties && properties.length && !isCustomerQuestion) {
    const propertiesText = getPropertiesJoinedText(properties);
    hobbesMessage = `At ${propertiesText}, ${question_message.charAt(0).toLowerCase() + question_message.slice(1)}`;
  } else if (customers && customers.length && isCustomerQuestion) {
    const customersText = getCustomersJoinedText(customers);
    hobbesMessage = `For customer${customers.length > 1 ? 's' : ''} ${customersText}, ${question_message.charAt(0).toLowerCase() + question_message.slice(1)}`;
  } else {
    hobbesMessage = question_message;
  }
  const answerText =
    direct_question_answer_expected_type === DirectAnswerTypes.ANSWER_MONETARY_VALUE
      ? Number(answer).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      : answer;
  const question_link = question.link;

  return (
    <HobbesQuestion>
      <HobbesQuestionBody className="full-white">
        {question_link && (
          <a href={question_link} target="_blank">
            <i className="ri-external-link-line" />
          </a>
        )}
        <p>{hobbesMessage}:</p>
        <br />
        {answerText}
      </HobbesQuestionBody>
      <small>{moment(date).format('MMM DD, hh:mma')}</small>
    </HobbesQuestion>
  );
};

export default ConfirmationMessage;
