import React, { FC } from 'react';
import { PromotionTypes } from 'dwell/store/property_profile/action-types';
import { Badge } from 'dwell/views/property_profile/sister_communities/styles';
import { CardApartment, Content } from './styles';

interface PromotionCardProps {
  promotion: PromotionTypes,
  clipboard?: React.ReactNode,
}

const PromotionCard: FC<PromotionCardProps> = ({ promotion, clipboard }) => {
  const unitTypesMap = { STUDIO: 'Studio', ONE_BEDROOM: '1 Bed', TWO_BEDROOM: '2 Bed', THREE_BEDROOM: '3 Bed', FOUR_BEDROOM: '4 Bed' };

  return (
    <Content>
      <CardApartment className="mb-0">
        <div className="card-body">
          <h6>{promotion.name}</h6>
          <p dangerouslySetInnerHTML={{ __html: promotion.promotion_html }} />
          <hr />
          <h6>Restrictions</h6>
          <p>{promotion.restriction}</p>
          <hr />
          <h6>Applicable Lease Term</h6>
          <p>
            {promotion.lease_duration_modifier === 'More than' && `Must sign a lease of ${promotion.lease_duration} or more months.`}
            {promotion.lease_duration_modifier === 'Less than' && `Must sign a lease of ${promotion.lease_duration} or less months.`}
            {promotion.lease_duration_modifier === 'Exactly' && `Must sign a lease of ${promotion.lease_duration} months.`}
            {promotion.lease_duration_modifier === 'All months' && 'Must sign a lease of all months.'}
          </p>
          <hr />
          <h6>Applicable Unit Types</h6>
          <div className="d-flex align-items-center">
            {promotion.unit_types.map(unit_type => <Badge>{unitTypesMap[unit_type]}</Badge>)}
          </div>
        </div>
        {clipboard}
      </CardApartment>
    </Content>
  );
};

export default PromotionCard;
