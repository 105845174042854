import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { LeaseDocumentProps } from 'leasing/store/lease_document/action-types';

export enum ActionType {
  ADD_MASTER_LEASE_DOCUMENTS_REQUEST = 'ADD_MASTER_LEASE_DOCUMENTS_REQUEST',
  ADD_MASTER_LEASE_DOCUMENTS_SUCCESS = 'ADD_MASTER_LEASE_DOCUMENTS_SUCCESS',
  ADD_MASTER_LEASE_DOCUMENTS_FAILURE = 'ADD_MASTER_LEASE_DOCUMENTS_FAILURE',

  CREATE_ADDENDA_REQUEST = 'CREATE_ADDENDA_REQUEST',
  CREATE_ADDENDA_SUCCESS = 'CREATE_ADDENDA_SUCCESS',
  CREATE_ADDENDA_FAILURE = 'CREATE_ADDENDA_FAILURE',

  DELETE_INCLUDE_IN_ALL_REQUEST = 'DELETE_INCLUDE_IN_ALL_REQUEST',
  DELETE_INCLUDE_IN_ALL_SUCCESS = 'DELETE_INCLUDE_IN_ALL_SUCCESS',
  DELETE_INCLUDE_IN_ALL_FAILURE = 'DELETE_INCLUDE_IN_ALL_FAILURE',

  GET_ADDENDA_REQUEST = 'GET_ADDENDA_REQUEST',
  GET_ADDENDA_SUCCESS = 'GET_ADDENDA_SUCCESS',
  GET_ADDENDA_FAILURE = 'GET_ADDENDA_FAILURE',

  UPDATE_MASTER_LEASE_DOCUMENT_REQUEST = 'UPDATE_MASTER_LEASE_DOCUMENT_REQUEST',
  UPDATE_MASTER_LEASE_DOCUMENT_SUCCESS = 'UPDATE_MASTER_LEASE_DOCUMENT_SUCCESS',
  UPDATE_MASTER_LEASE_DOCUMENT_FAILURE = 'UPDATE_MASTER_LEASE_DOCUMENT_FAILURE',

  GET_ADDENDA_DOCUMENTS_CHOICES_REQUEST = 'GET_ADDENDA_DOCUMENTS_CHOICES_REQUEST',
  GET_ADDENDA_DOCUMENTS_CHOICES_SUCCESS = 'GET_ADDENDA_DOCUMENTS_CHOICES_SUCCESS',
  GET_ADDENDA_DOCUMENTS_CHOICES_FAILURE = 'GET_ADDENDA_DOCUMENTS_CHOICES_FAILURE',
}

export interface AddendaState extends CommonStateType {
  addendaDocuments: Addendum [],
  addendaLoaded: boolean,
  addendaDocumentsChoices: AddendaDocumentChoiceProps[];
}

export interface Addendum {
  id: number,
  document_name: string,
  lease_document: number,
  included_in_all: string[],
  is_master: boolean,
  created: string,
  updated: string,
  property: number,
}

export interface AddendaGroup {
  group: string,
  children: LeaseDocumentProps[]
}

export interface AddendaDocumentChoiceProps {
  id: number;
  name: string;
}

/*
 * Actions
 */

export interface AddMasterLeaseDocumentsAction extends CommonActionResponse {
  type: ActionType.ADD_MASTER_LEASE_DOCUMENTS_REQUEST
  | ActionType.ADD_MASTER_LEASE_DOCUMENTS_SUCCESS
  | ActionType.ADD_MASTER_LEASE_DOCUMENTS_FAILURE;
  result: { data: LeaseDocumentProps[] };
}

interface CreateAddendaAction {
  type: ActionType.CREATE_ADDENDA_REQUEST
  | ActionType.CREATE_ADDENDA_SUCCESS
  | ActionType.CREATE_ADDENDA_FAILURE;
  result: { data: Addendum[] };
}

interface GetAddendaAction extends CommonActionResponse {
  type: ActionType.GET_ADDENDA_REQUEST
  | ActionType.GET_ADDENDA_SUCCESS
  | ActionType.GET_ADDENDA_FAILURE;
  result: { data: Addendum[] };
}

interface UpdateMasterLeaseDocumentAction {
  type: ActionType.UPDATE_MASTER_LEASE_DOCUMENT_REQUEST
  | ActionType.UPDATE_MASTER_LEASE_DOCUMENT_SUCCESS
  | ActionType.UPDATE_MASTER_LEASE_DOCUMENT_FAILURE;
  result: { data: Addendum };
}

export interface GetAddendaDocumentsAction extends CommonActionResponse {
  type: ActionType.GET_ADDENDA_DOCUMENTS_CHOICES_REQUEST
  | ActionType.GET_ADDENDA_DOCUMENTS_CHOICES_SUCCESS
  | ActionType.GET_ADDENDA_DOCUMENTS_CHOICES_FAILURE;
  result: { data: AddendaDocumentChoiceProps[] };
}

export type Action =
  PromiseActionType
  | AddMasterLeaseDocumentsAction
  | CreateAddendaAction
  | GetAddendaAction
  | UpdateMasterLeaseDocumentAction
  | GetAddendaDocumentsAction;
