import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_CHAT_REPORTS_REQUEST = 'GET_CHAT_REPORTS_REQUEST',
  GET_CHAT_REPORTS_SUCCESS = 'GET_CHAT_REPORTS_SUCCESS',
  GET_CHAT_REPORTS_FAILURE = 'GET_CHAT_REPORTS_FAILURE',

  GET_SINGLE_CHAT_REPORT_REQUEST = 'GET_SINGLE_CHAT_REPORT_REQUEST',
  GET_SINGLE_CHAT_REPORT_SUCCESS = 'GET_SINGLE_CHAT_REPORT_SUCCESS',
  GET_SINGLE_CHAT_REPORT_FAILURE = 'GET_SINGLE_CHAT_REPORT_FAILURE',

  GET_CHAT_REPORT_BY_ID_REQUEST = 'GET_CHAT_REPORT_BY_ID_REQUEST',
  GET_CHAT_REPORT_BY_ID_SUCCESS = 'GET_CHAT_REPORT_BY_ID_SUCCESS',
  GET_CHAT_REPORT_BY_ID_FAILURE = 'GET_CHAT_REPORT_BY_ID_FAILURE',

  EXPORT_CHAT_REPORT_BY_ID_REQUEST = 'EXPORT_CHAT_REPORT_BY_ID_REQUEST',
  EXPORT_CHAT_REPORT_BY_ID_SUCCESS = 'EXPORT_CHAT_REPORT_BY_ID_SUCCESS',
  EXPORT_CHAT_REPORT_BY_ID_FAILURE = 'EXPORT_CHAT_REPORT_BY_ID_FAILURE',

  GET_CHAT_REPORT_EVALUATION_BY_ID_REQUEST = 'GET_CHAT_REPORT_EVALUATION_BY_ID_REQUEST',
  GET_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS = 'GET_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS',
  GET_CHAT_REPORT_EVALUATION_BY_ID_FAILURE = 'GET_CHAT_REPORT_EVALUATION_BY_ID_FAILURE',

  UPDATE_EVALUATION_REPORT_REQUEST = 'UPDATE_EVALUATION_REPORT_REQUEST',
  UPDATE_EVALUATION_REPORT_SUCCESS = 'UPDATE_EVALUATION_REPORT_SUCCESS',
  UPDATE_EVALUATION_REPORT_FAILURE = 'UPDATE_EVALUATION_REPORT_FAILURE',

  CHANGE_EVALUATION_REPORT_REQUEST = 'CHANGE_EVALUATION_REPORT_REQUEST',
  CHANGE_EVALUATION_REPORT_SUCCESS = 'CHANGE_EVALUATION_REPORT_SUCCESS',
  CHANGE_EVALUATION_REPORT_FAILURE = 'CHANGE_EVALUATION_REPORT_FAILURE',

  AUTOSAVE_EVALUATION_REPORT_REQUEST = 'AUTOSAVE_EVALUATION_REPORT_REQUEST',
  AUTOSAVE_EVALUATION_REPORT_REQUEST_SUCCESS = 'AUTOSAVE_EVALUATION_REPORT_REQUEST_SUCCESS',
  AUTOSAVE_EVALUATION_REPORT_REQUEST_FAILURE = 'AUTOSAVE_EVALUATION_REPORT_REQUEST_FAILURE',

  GET_CHAT_REPORT_MESSAGE_BY_ID_REQUEST = 'GET_CHAT_REPORT_MESSAGE_BY_ID_REQUEST',
  GET_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS = 'GET_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS',
  GET_CHAT_REPORT_MESSAGE_BY_ID_FAILURE = 'GET_CHAT_REPORT_MESSAGE_BY_ID_FAILURE',

  UPDATE_CHAT_REPORT_MESSAGE_BY_ID_REQUEST = 'UPDATE_CHAT_REPORT_MESSAGE_BY_ID_REQUEST',
  UPDATE_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS = 'UPDATE_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS',
  UPDATE_CHAT_REPORT_MESSAGE_BY_ID_FAILURE = 'UPDATE_CHAT_REPORT_MESSAGE_BY_ID_FAILURE',

  UPDATE_CHAT_REPORT_EVALUATION_BY_ID_REQUEST = 'UPDATE_CHAT_REPORT_EVALUATION_BY_ID_REQUEST',
  UPDATE_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS = 'UPDATE_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS',
  UPDATE_CHAT_REPORT_EVALUATION_BY_ID_FAILURE = 'UPDATE_CHAT_REPORT_EVALUATION_BY_ID_FAILURE',

  UPDATE_RECENT_CHAT_REPORTS_REQUEST = 'UPDATE_RECENT_CHAT_REPORTS_REQUEST',
  UPDATE_RECENT_CHAT_REPORTS_SUCCESS = 'UPDATE_RECENT_CHAT_REPORTS_SUCCESS',
  UPDATE_RECENT_CHAT_REPORTS_FAILURE = 'UPDATE_RECENT_CHAT_REPORTS_FAILURE',
}

export type ChatReportMessageStatus = 'CORRECT' | 'INCORRECT';
export type ChatReportMessageSupportStatus = 'SUPPORTED' | 'NOT_SUPPORTED';
export type CombinedMessageStatus = {
  status: ChatReportMessageStatus,
  support_status: ChatReportMessageSupportStatus
}

export interface CustomHobbesAnswer {
  tag?: string,
  type?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  floor_plans?: any[],
  text?: string,
}

export interface HobbesAnswer {
  text?: string,
  buttons?: {
    title: string,
    payload: string,
  }[],
  custom?: CustomHobbesAnswer,
}

export interface ChatReportConversationMessageWithStatus {
  id: number,
  message?: string,
  type?: 'BOT' | 'PROSPECT',
  date?: string,
  question_result?: string,
  status: ChatReportMessageStatus,
  support_status: ChatReportMessageSupportStatus,
  conversation?: number,
  hobbes_answer?: HobbesAnswer[],
}

export interface ChatReportConversationMessage extends ChatReportConversationMessageWithStatus {
  message: string,
  type: 'BOT' | 'PROSPECT',
}

export type ChatReportStatus = 'PENDING' | 'PROGRESS' | 'COMPLETED';

export interface ChatReportStats {
  id: number,
  session_date: string | Date,
  status: ChatReportStatus,
  conversations?: { total: number, avg: number },
  responses?: { [key: string]: { count: number, percent: number } } & { total: number },
  property: number,
}

export interface ChatReportConversation {
  id: number,
  index?: number,
  reviewed: boolean,
  date?: string | Date,
}

export interface ChatReportConversationData {
  type: string,
  data: ChatReportConversation
}

interface GetChatReportsAction {
  type: ActionType.GET_CHAT_REPORTS_REQUEST | ActionType.GET_CHAT_REPORTS_SUCCESS | ActionType.GET_CHAT_REPORTS_FAILURE;

  result: {
    data: { results: ChatReportStats[] };
  };
  error: {
    response: { status: string };
  };
}

interface GetSingleChatReportAction {
  type: ActionType.GET_SINGLE_CHAT_REPORT_REQUEST | ActionType.GET_SINGLE_CHAT_REPORT_SUCCESS | ActionType.GET_SINGLE_CHAT_REPORT_FAILURE;

  result: {
    data: ChatReportStats;
  };
  error: {
    response: { status: string };
  };
}

interface GetChatReportByIdAction {
  type: ActionType.GET_CHAT_REPORT_BY_ID_REQUEST | ActionType.GET_CHAT_REPORT_BY_ID_SUCCESS | ActionType.GET_CHAT_REPORT_BY_ID_FAILURE;

  result: {
    data: ChatReportConversation;
  };
  error: {
    response: { status: string };
  };
}

interface ExportChatReportByIdAction {
  type: ActionType.EXPORT_CHAT_REPORT_BY_ID_REQUEST | ActionType.EXPORT_CHAT_REPORT_BY_ID_SUCCESS | ActionType.EXPORT_CHAT_REPORT_BY_ID_FAILURE;

  result: {
    data: { results: ChatReportConversation[] };
  };
  error: {
    response: { status: string };
  };
}

interface GetChatReportForEvaluationByIdAction {
  type: ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_REQUEST | ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS | ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_FAILURE;

  result: {
    data: { results: ChatReportConversation[] };
  };
  error: {
    response: { status: string };
  };
}

interface SetChatReportStatusByIdAction {
  type: ActionType.UPDATE_EVALUATION_REPORT_REQUEST | ActionType.UPDATE_EVALUATION_REPORT_SUCCESS | ActionType.UPDATE_EVALUATION_REPORT_FAILURE;

  error: {
    response: { status: string };
  };
}

interface UpdateChatReportStatusByIdAction {
  type: ActionType.CHANGE_EVALUATION_REPORT_REQUEST | ActionType.CHANGE_EVALUATION_REPORT_SUCCESS | ActionType.CHANGE_EVALUATION_REPORT_FAILURE;

  error: {
    response: { status: string };
  };
}

interface AutoSaveChatReportStatusesByIdAction {
  type: ActionType.AUTOSAVE_EVALUATION_REPORT_REQUEST | ActionType.AUTOSAVE_EVALUATION_REPORT_REQUEST_SUCCESS | ActionType.AUTOSAVE_EVALUATION_REPORT_REQUEST_FAILURE;

  error: {
    response: { status: string };
  };
}

interface GetSingleConversationMessagesByIdAction {
  type: ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_REQUEST | ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS | ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_FAILURE;

  result: {
    data: ChatReportConversationMessageWithStatus[];
  };
  error: {
    response: { status: string };
  };
}

interface UpdateMessageStatusesByIdAction {
  type: ActionType.UPDATE_CHAT_REPORT_MESSAGE_BY_ID_REQUEST | ActionType.UPDATE_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS | ActionType.UPDATE_CHAT_REPORT_MESSAGE_BY_ID_FAILURE;

  error: {
    response: { status: string };
  };
}

interface UpdateConversationStatusesByIdAction {
  type: ActionType.UPDATE_CHAT_REPORT_EVALUATION_BY_ID_REQUEST | ActionType.UPDATE_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS | ActionType.UPDATE_CHAT_REPORT_EVALUATION_BY_ID_FAILURE;

  error: {
    response: { status: string };
  };
}

interface UpdateRecentEvaluationReportAction {
  type: ActionType.UPDATE_RECENT_CHAT_REPORTS_REQUEST | ActionType.UPDATE_RECENT_CHAT_REPORTS_SUCCESS | ActionType.UPDATE_RECENT_CHAT_REPORTS_FAILURE;

  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetChatReportsAction
  | GetSingleChatReportAction
  | GetChatReportByIdAction
  | ExportChatReportByIdAction
  | GetChatReportForEvaluationByIdAction
  | SetChatReportStatusByIdAction
  | UpdateChatReportStatusByIdAction
  | AutoSaveChatReportStatusesByIdAction
  | GetSingleConversationMessagesByIdAction
  | UpdateMessageStatusesByIdAction
  | UpdateConversationStatusesByIdAction
  | UpdateRecentEvaluationReportAction;
