import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_CHAT_TEMPLATES_REQUEST = 'GET_CHAT_TEMPLATES_REQUEST',
  GET_CHAT_TEMPLATES_SUCCESS = 'GET_CHAT_TEMPLATES_SUCCESS',
  GET_CHAT_TEMPLATES_FAILURE = 'GET_CHAT_TEMPLATES_FAILURE',

  GET_CHAT_TEMPLATE_BY_ID_REQUEST = 'GET_CHAT_TEMPLATE_BY_ID_REQUEST',
  GET_CHAT_TEMPLATE_BY_ID_SUCCESS = 'GET_CHAT_TEMPLATE_BY_ID_SUCCESS',
  GET_CHAT_TEMPLATE_BY_ID_FAILURE = 'GET_CHAT_TEMPLATE_BY_ID_FAILURE',

  CREATE_CHAT_TEMPLATE_REQUEST = 'CREATE_CHAT_TEMPLATE_REQUEST',
  CREATE_CHAT_TEMPLATE_SUCCESS = 'CREATE_CHAT_TEMPLATE_SUCCESS',
  CREATE_CHAT_TEMPLATE_FAILURE = 'CREATE_CHAT_TEMPLATE_FAILURE',

  UPDATE_CHAT_TEMPLATE_REQUEST = 'UPDATE_CHAT_TEMPLATE_REQUEST',
  UPDATE_CHAT_TEMPLATE_SUCCESS = 'UPDATE_CHAT_TEMPLATE_SUCCESS',
  UPDATE_CHAT_TEMPLATE_FAILURE = 'UPDATE_CHAT_TEMPLATE_FAILURE',

  DELETE_CHAT_TEMPLATE_REQUEST = 'DELETE_CHAT_TEMPLATE_REQUEST',
  DELETE_CHAT_TEMPLATE_SUCCESS = 'DELETE_CHAT_TEMPLATE_SUCCESS',
  DELETE_CHAT_TEMPLATE_FAILURE = 'DELETE_CHAT_TEMPLATE_FAILURE',
}

export interface ChatTemplateProps {
  id?: number;
  text: string;
  type?: string;
  name: string;
  variables: string[];
  property?: number;
}

interface GetChatTemplatesAction {
  type: ActionType.GET_CHAT_TEMPLATES_REQUEST | ActionType.GET_CHAT_TEMPLATES_SUCCESS | ActionType.GET_CHAT_TEMPLATES_FAILURE;

  result: {
    data: { results: ChatTemplateProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface GetChatTemplateByIdAction {
  type: ActionType.GET_CHAT_TEMPLATE_BY_ID_REQUEST | ActionType.GET_CHAT_TEMPLATE_BY_ID_SUCCESS | ActionType.GET_CHAT_TEMPLATE_BY_ID_FAILURE;

  result: {
    data: ChatTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

interface CreateChatTemplateAction {
  type: ActionType.CREATE_CHAT_TEMPLATE_REQUEST | ActionType.CREATE_CHAT_TEMPLATE_SUCCESS | ActionType.CREATE_CHAT_TEMPLATE_FAILURE;

  result: {
    data: ChatTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateChatTemplateByIdAction {
  type: ActionType.UPDATE_CHAT_TEMPLATE_REQUEST | ActionType.UPDATE_CHAT_TEMPLATE_SUCCESS | ActionType.UPDATE_CHAT_TEMPLATE_FAILURE;

  result: {
    data: ChatTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteChatTemplateByIdAction {
  type: ActionType.DELETE_CHAT_TEMPLATE_REQUEST | ActionType.DELETE_CHAT_TEMPLATE_SUCCESS | ActionType.DELETE_CHAT_TEMPLATE_FAILURE;

  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetChatTemplatesAction
  | GetChatTemplateByIdAction
  | CreateChatTemplateAction
  | UpdateChatTemplateByIdAction
  | DeleteChatTemplateByIdAction;
