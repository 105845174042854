import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { selectCallInProgress } from 'dwell/store/voice/reducers';
import CallBodyItem from './call_body_item';
import { CallBodyItemBaseProps } from '.';
import { KeyPadRow } from './styles';
import Key from './key';

const Keypad: FC<CallBodyItemBaseProps> = ({ hide, onClose, useHeader, onKeyPadClick }) => {
  const { call } = useSelector(selectCallInProgress) || {};

  const sendDigit = (digit: string) => {
    if (!isEmpty(call)) {
      call.sendDigits(digit);
    } else if (onKeyPadClick) onKeyPadClick(digit);
  };

  return (
    <CallBodyItem itemTitle="Keypad" hide={hide} useHeader={useHeader} onClose={onClose}>
      <KeyPadRow>
        <Key phoneKey="1" pressed={sendDigit} />
        <Key phoneKey="2" pressed={sendDigit} letters="ABC" />
        <Key phoneKey="3" pressed={sendDigit} letters="DEF" />
      </KeyPadRow>
      <KeyPadRow>
        <Key phoneKey="4" pressed={sendDigit} letters="GHI" />
        <Key phoneKey="5" pressed={sendDigit} letters="JKL" />
        <Key phoneKey="6" pressed={sendDigit} letters="MNO" />
      </KeyPadRow>
      <KeyPadRow>
        <Key phoneKey="7" pressed={sendDigit} letters="PQRS" />
        <Key phoneKey="8" pressed={sendDigit} letters="TUV" />
        <Key phoneKey="9" pressed={sendDigit} letters="WXYZ" />
      </KeyPadRow>
      <KeyPadRow>
        <Key phoneKey="*" pressed={sendDigit} />
        <Key phoneKey="0" pressed={sendDigit} />
        <Key phoneKey="#" pressed={sendDigit} />
      </KeyPadRow>
    </CallBodyItem>
  );
};

export default Keypad;
