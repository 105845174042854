export default {
  RESIDENT_FILED_CHOICES: {
    status: {
      PENDING: 'Pending',
      ACTIVE: 'Active',
      FORMER: 'Former',
    },
    personStatus: {
      LOST: 'Lost',
      PENDING: 'Pending',
      CURRENT: 'Current',
      FORMER: 'Former',
      TEST: 'Test',
    },
    stage: {
      PENDING_MOVE_IN: 'Pending Move In',
      MOVED_IN: 'Moved In',
      RENEW: 'Renew',
      NOTICE_TO_VACATE: 'Notice To Vacate',
      TRANSFER: 'Transfer',
      PENDING_MOVE_OUT: 'Pending Move Out',
      PENDING_RENEWAL: 'Pending Renewal',
      MOVED_OUT: 'Moved Out',
      IN_RENEWAL_PERIOD: 'In Renewal Period',
      LEASE_CHANGE: 'Lease Change',
      PENDING_TRANSFER: 'Pending Transfer',
      PENDING_MTM: 'Pending M-T-M',
      MTM: 'M-T-M',
    },
    otherOptions: {
      SHARE_LEAD: 'Share Lead',
      SEND_APPLICATION: 'Send Application',
    },
    contactMethod: {
      EMAIL: 'Email',
      PHONE: 'Phone',
      TEXT: 'Text',
    },
    contactTime: {
      MORNING: 'Morning',
      AFTERNOON: 'Afternoon',
      EVENING: 'Evening',
    },
    office: {
      PROPERTY: 'Leasing Office',
      VIRTUAL: 'Virtual Office',
    },
    origin: {
      WEB: 'Web',
      MOBILE: 'Mobile',
      PHONE: 'Phone',
      WALK_IN: 'Walk In',
      UNKNOWN: 'Unknown',
    },
    movingReason: {
      EMPLOYMENT: 'Employment',
      FAMILY: 'Family',
      OTHER: 'Other',
    },
    petType: {
      DOG: 'Dog',
      CAT: 'Cat',
      BIRD: 'Bird',
      OTHER: 'Other',
    },
    petSex: {
      FEMALE: 'Female',
      MALE: 'Male',
      OTHER: 'Other',
    },
    washerDryerMethod: {
      IN_UNIT: 'In Unit',
      HOOK_UP_ONLY: 'Hook up only',
      ON_PREMISE: 'On premise',
      NONE: 'None',
    },
    pmsSyncStatus: {
      SUCCESS: 'Success',
      FAILURE: 'Failure',
      NOT_STARTED: 'Not started yet',
    },
    unitType: {
      STUDIO: 'Studio',
      ONE_BEDROOM: '1 Bed',
      TWO_BEDROOM: '2 Bed',
      THREE_BEDROOM: '3 Bed',
      FOUR_BEDROOM: '4 Bed',
    },
    subscriptionStatus: {
      NOT_SET: 'Not Set',
      ACTIVE: 'Active',
      CANCELED: 'Canceled',
    },
  },

  leaseActionSteps: {
    SEND_REFRESH_PROFILE_REQUEST: 'Send Refresh Profile Request',
    COMPLETE_REFRESH_PROFILE: 'Complete Refresh Profile Request',
    RERUN_SCREENINGS: 'Rerun Screenings',
    REGENERATE_LEASE_DOCUMENTS: 'Regenerate Lease Documents',
    SEND_LEASE_DOCUMENTS: 'Send Lease Documents',
    SIGN_LEASE_DOCUMENTS: 'Sign Lease Documents',
    COUNTERSIGN_LEASE_DOCUMENTS: 'Countersign Lease Documents',
    RERUN_CRIMINAL_BACKGROUND_CHECKS: 'Rerun Criminal Background Checks',
    CONFIRM_RENEWAL_DETAILS: 'Confirm Renewal Details',
    GENERATE_RENEWAL_LEASE: 'Generate Renewal Lease',
    SEND_RENEWAL_LEASE: 'Send Renewal Lease',
    SIGN_RENEWAL_LEASE: 'Sign Renewal Lease',
    COUNTERSIGN_RENEWAL_LEASE: 'Countersign Renewal Lease',
    SUBMIT_APPLICATIONS: 'Submit Applications',
    PAY_APPLICATION_FEES: 'Pay Application Fees',
    SEND_LEASE_CHANGE_FEE_REQUEST: 'Send Lease Change Fee Request',
    PAY_LEASE_CHANGE_FEE: 'Pay Lease Change Fee',
    RUN_SCREENINGS: 'Run Screenings',
    MAKE_APPROVAL_DECISION: 'Make Approval Decision',
    COLLECT_NOTICE_DETAILS: 'Collect Notice Details',
    SUBMIT_NOTICE_DETAILS: 'Submit Notice Details',
    CONFIRM_NOTICE_DETAILS: 'Confirm Notice Details',
    GENERATE_NOTICE: 'Generate Notice',
    SEND_NOTICE: 'Send Notice',
    SIGN_NOTICE: 'Sign Notice',
    COUNTERSIGN_NOTICE: 'Countersign Notice',
    SEND_ROOMMATE_ADDENDUM: 'Send Roommate Addendum',
    SIGN_ROOMMATE_ADDENDUM: 'Sign Roommate Addendum',
    COUNTERSIGN_ROOMMATE_ADDENDUM: 'Countersign Roommate Addendum',
  },
};

export const leaseActionMenuChoice = {
  LEASE_CHANGE: {
    title: 'Lease Change',
    text: 'Change lease details associated with resident group.',
    url: '/change',
    hide: false,
  },
  RENEW_LEASE: {
    title: 'Renew Lease',
    text: 'Extend current lease and add renewal details for resident group.',
    url: '',
    hide: false,
  },
  PREPARE_NOTICE: {
    title: 'Prepare Notice',
    text: 'Prepare notice to vacate and add notice details for resident group.',
    url: '/notice',
    hide: false,
  },
  // TODO remove for now since this logic removed from pilot scope and revert in a future
  // TRANSFER_TO_NEW_UNIT: {
  //   title: 'Transfer To New Unit',
  //   text: 'Transfer resident group and associated lease details to a new unit.',
  //   url: '',
  //   hide: false,
  // },
  // TRANSFER_TO_NEW_COMMUNITY: {
  //   title: 'Transfer To New Community',
  //   text: 'Transfer resident group and associated lease details to a new community.',
  //   url: '',
  //   hide: false,
  // },
};

export const renewalActionMenuChoice = {
  UPDATE_RENEWAL_DETAILS: {
    title: 'Update Renewal Details',
    text: 'Update lease details associated with renewal.',
    url: 'update',
    hide: false,
  },
  CANCEL_RENEWAL: {
    title: 'Cancel Renewal',
    text: 'Cancel renewal for resident group.',
    url: 'cancel',
    hide: false,
  },
  RESEND: {
    title: 'Resend Request Link',
    text: 'Resend refresh profile link.',
    url: 'resend',
    hide: false,
  },
};

export const residentGroupActionMenuChoice = {
  ADD_PEOPLE: {
    title: 'Add People',
    text: 'Add people to resident group.',
    url: '/add-people',
    hide: false,
  },
  REMOVE_PEOPLE: {
    title: 'Remove People',
    text: 'Remove people from resident group.',
    url: '/remove-people',
    hide: false,
  },
  FRESH_PROFILE_DETAILS: {
    title: 'Refresh Profile Details',
    text: 'Request people in group to refresh profile details.',
    url: '/refresh-profile',
    hide: false,
  },
};
