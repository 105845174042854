import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_SOURCES_REQUEST = 'GET_SOURCES_REQUEST',
  GET_SOURCES_SUCCESS = 'GET_SOURCES_SUCCESS',
  GET_SOURCES_FAILURE = 'GET_SOURCES_FAILURE',
  UPDATE_SOURCES_REQUEST = 'UPDATE_SOURCES_REQUEST',
  UPDATE_SOURCES_SUCCESS = 'UPDATE_SOURCES_SUCCESS',
  UPDATE_SOURCES_FAILURE = 'UPDATE_SOURCES_FAILURE',
  UPDATE_SPENDS_REQUEST = 'UPDATE_SPENDS_REQUEST',
  UPDATE_SPENDS_SUCCESS = 'UPDATE_SPENDS_SUCCESS',
  UPDATE_SPENDS_FAILURE = 'UPDATE_SPENDS_FAILURE',
}

export interface SourceProps {
  id?: string | number;
  name?: string;
  spends?: { date: string; price: number };
  is_paid?: boolean;
  external_id?: string;
}

interface GetSourcesAction {
  type: ActionType.GET_SOURCES_REQUEST | ActionType.GET_SOURCES_SUCCESS | ActionType.GET_SOURCES_FAILURE;

  result: {
    data: { results: SourceProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateSourceByIdAction {
  type: ActionType.UPDATE_SOURCES_REQUEST | ActionType.UPDATE_SOURCES_SUCCESS | ActionType.UPDATE_SOURCES_FAILURE;

  result: {
    data: SourceProps;
  };
  error: {
    response: { status: string };
  };
}
interface UpdateSpendsAction {
  type: ActionType.UPDATE_SPENDS_REQUEST | ActionType.UPDATE_SPENDS_SUCCESS | ActionType.UPDATE_SPENDS_FAILURE;

  result: {
    data: SourceProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetSourcesAction | UpdateSourceByIdAction | UpdateSpendsAction;
