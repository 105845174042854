import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { LeadDetailHeader as DetailHeader, LeadDetailMain as DetailMain } from 'dwell/views/lead/layout/styles';
import { selectApplicationDetails } from 'application/store/application/reducers';
import KeyInfo from '../key_info';
import Loader from './loader';

interface ApplicationDetailLayoutProps {
  children: React.ReactNode;
}

const ApplicationDetailLayout: FC<ApplicationDetailLayoutProps> = ({ children }) => {
  const { floor_plan: floorPlan, unit } = useSelector(selectApplicationDetails);

  return (
    <DetailMain>
      <Helmet>
        <title>{floorPlan && unit ? `Union | Application ${floorPlan}-${unit}` : 'Union'}</title>
      </Helmet>
      <DetailHeader id="key-header">
        <KeyInfo />
      </DetailHeader>
      {children}
      <Loader />
    </DetailMain>
  );
};

export default ApplicationDetailLayout;
