export default {
  APPLICANT_CREATED: 'Applicant Added',
  APPLICANT_DELETED: 'Applicant Deleted',
  APPLICANT_UPDATED: 'Applicant Updated',
  COUNTERSIGN_LEASE_COMPLETED: 'Lease Signing Completed',
  APPLICATION_INVITE_SENT: 'Applicant Invitation Sent',
  LEASING_USER_ACCOUNT_CREATED: 'Account Confirmation Sent',
  APPLICATION_PAYMENT_MADE: 'Application Payment Made',
  APPLICATION_STARTED: 'Application Started',
  APPLICATION_STATUS_CHANGED: 'Application Decision changed',
  APPLICATION_SUBMITTED: 'Application Submitted',
  APPLICATION_CANCELLED: 'Union - Application Cancelled',
  CO_APPLICANT_CREATED: 'Co-Applicant Added',
  CO_APPLICANT_DELETED: 'Co-Applicant Deleted',
  CO_APPLICANT_INVITE_SENT: 'Co-Applicant Invitation Sent',
  CO_APPLICANT_UPDATED: 'Co-Applicant Updated',
  DEPOSIT_AGREEMENT_SENT: 'Union - Deposit Agreement Sent',
  DEPOSIT_AGREEMENT_SIGNED: 'Deposit Agreement Signed',
  EMAIL_CREATED: 'Email Sent',
  GUARANTOR_CREATED: 'Guarantor Added',
  GUARANTOR_DELETED: 'Guarantor Deleted',
  GUARANTOR_INVITE_SENT: 'Guarantor Invitation Sent',
  GUARANTOR_UPDATED: 'Guarantor Updated',
  INCOME_ADDED: 'Income Added',
  INCOME_REMOVED: 'Income Removed',
  INCOME_UPDATED: 'Income Updated',
  LEAD_CHAT_HOBBES: 'Chat with Hobbes',
  LEAD_CREATED: 'Lead Added',
  LEAD_MERGED: 'Leads Merged',
  LEAD_SHARED: 'Lead Shared',
  LEAD_UPDATED: 'Lead Updated',
  LEASE_CREATED: 'Lease Created',
  LEASE_AGREEMENT_SENT: 'Union - Lease Agreement Sent',
  RENEWAL_LEASE_AGREEMENT_SENT: 'Union - Renewal Lease Agreement Sent',
  LEASE_AGREEMENT_SIGNED: 'Lease Agreement signed',
  LEASE_STEP_CHANGED: 'Lease Step Updated',
  LEASE_UPDATED: 'Lease Updated',
  APPLICATION_UPDATED: 'Application Updated',
  LEASE_MOVE_DATE_ADDED: 'Move-in Date Selected',
  LEASE_MOVE_DATE_UPDATED: 'Move-in Date Updated',
  RESIDENT_UPDATED: 'Resident Updated',
  NOTE_CREATED: 'Note Added',
  NOTE_DELETED: 'Note Deleted',
  NOTE_UPDATED: 'Note Updated',
  PET_ADDED: 'Pet Added',
  PET_REMOVED: 'Pet Removed',
  PET_UPDATED: 'Pet Updated',
  RENTABLE_ITEM_ADDED: 'Rentable Item Added',
  RENTABLE_ITEM_REMOVED: 'Rentable Item Removed',
  ROOMMATE_CREATED: 'Roommate Added',
  ROOMMATE_DELETED: 'Roommate Deleted',
  ROOMMATE_UPDATED: 'Roommate Updated',
  SCREENING_CHECK_DONE: 'Screening Check',
  SMS_CREATED: 'SMS',
  TASK_COMPLETED: 'Task Completed',
  TASK_CREATED: 'Task Added',
  TASK_DELETED: 'Task Deleted',
  TASK_UPDATED: 'Task Updated',
  TASK_ARCHIVED: 'Task Archived',
  TOUR_CANCELLED: 'Tour Canceled',
  TOUR_COMPLETED: 'Tour Completed',
  TOUR_CREATED: 'Tour Created',
  TOUR_UPDATED: 'Tour Updated',
  TOUR_NO_SHOW: 'Tour Marked as No Show',
  TOUR_ARCHIVED: 'Tour Archived',
  UNIT_ADDED: 'Unit Added',
  UNIT_REMOVED: 'Unit Removed',
  VEHICLE_ADDED: 'Vehicle Added',
  VEHICLE_REMOVED: 'Vehicle Removed',
  VEHICLE_UPDATED: 'Vehicle updated',
  PRICE_QUOTE_SENT: 'Price Quote Sent',
  NURTURE_ENROLLMENT: 'Enrolled in Automation',
  NURTURE_UNENROLLMENT: 'Unenrolled in Automation',
  CB_SUBSCRIPTION_UPDATED: 'Credit Builder subscription updated',
  CB_SUBSCRIPTION_REFUNDED: 'Credit Builder subscription refunded',
  TRANSFERRED_TO_APPLICATION: 'Transferred to Application',
  RENEWAL_OFFER_CREATED: 'Union - Renewal Offer Sent',
  RENEWAL_OFFER_UPDATED: 'Union - Updated Renewal Offer Sent',
  PROFILE_REFRESH_REQUEST_SENT: 'Union - Profile Refresh Request Sent',
  NOTICE_TO_VACATE_SENT: 'Union - Notice to Vacate Sent',
};
