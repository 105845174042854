import produce from 'immer';
import { UserProps } from '../user/action-types';
import { Action, ActionType } from './action-types';
import { CommonStateType } from '../types';

export interface RescoreUserState extends CommonStateType {
  users: UserProps[];
  isUsersLoaded: boolean;
}

export const initialState: RescoreUserState = {
  users: [],
  isUsersLoaded: false,
  errorMessage: '',
};

export default produce((state: RescoreUserState = initialState, action: Action): RescoreUserState => {
  switch (action.type) {
    case ActionType.GET_RESCORE_USERS_REQUEST:
      state.isUsersLoaded = false;
      break;
    case ActionType.GET_RESCORE_USERS_SUCCESS:
      state.users = action.result.data;
      state.isUsersLoaded = true;
      break;
    case ActionType.GET_RESCORE_USERS_FAILURE:
      state.isUsersLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.SET_RESCORE_USERS:
      state.isUsersLoaded = true;
      state.users = action.users;
      break;
  }

  return state;
});
