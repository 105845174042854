import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import actions from 'dwell/actions';
import { PageSection } from 'dwell/store/property_profile/action-types';
import { Label, Item } from './styles';

const MainMenu: FC = () => {
  const dispatch = useDispatch();
  const { setSection, setExpandedUnits } = actions.propertyProfile;
  dispatch(setExpandedUnits({}));

  return (
    <div className="pp-home">
      <Label>Answer Assist</Label>
      <Item onClick={() => dispatch(setSection(PageSection.ASSIST_BOT))}>
        <div>Chat with Hobbes to get answers</div>
        <span data-key="71">g</span>
      </Item>
      <div className="mg-b-15" />
      <Label>Property Profile</Label>
      <Item onClick={() => dispatch(setSection(PageSection.PROPERTY_INFO))}>
        <div>Property Info</div>
        <span data-key="65">a</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.LEASING_CRITERIA))}>
        <div>Leasing & Rental Criteria</div>
        <span data-key="83">s</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.AVAILABLE_UNITS))}>
        <div>Available Units</div>
        <span data-key="68">d</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.AMENITIES))}>
        <div>Amenities</div>
        <span data-key="70">f</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.TEAM))}>
        <div>Team</div>
        <span data-key="74">j</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.SISTER_COMMUNITIES))}>
        <div>Sister Communities</div>
        <span data-key="75">k</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.NEIGHBORHOOD))}>
        <div>Neighborhood</div>
        <span data-key="78">n</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.GALLERY))}>
        <div>Gallery</div>
        <span data-key="86">v</span>
      </Item>
      <div className="mg-b-15" />
      <Label>Actions</Label>
      <Item onClick={() => dispatch(setSection(PageSection.ADD_NEW_LEAD))}>
        <div>Add New Lead</div>
        <span data-key="76">l</span>
      </Item>
      <Item onClick={() => dispatch(setSection(PageSection.ADD_NEW_TOUR))}>
        <div>Add New Tour</div>
        <span data-key="84">t</span>
      </Item>
    </div>
  );
};

export default MainMenu;
