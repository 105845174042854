import { get } from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import { Button, Col, Row } from 'reactstrap';

import leadActions from 'dwell/store/lead/action-creators';
import propertyActions from 'dwell/store/property/action-creators';
import taskActions from 'dwell/store/task/action-creators';
import pipelineActions from 'dwell/store/pipeline/action-creators';
import { TOUR_TYPES } from 'dwell/constants';
import Loader from 'dwell/components/loader';
import { TaskModalBody, TaskModalFooter, TaskModalHeader } from 'dwell/components/tasks/styles';
import { selectIsPropertyDataLoaded, selectSelectedProperties, selectProperty } from 'dwell/store/property/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { TaskProps } from 'dwell/store/task/action-types';
import { replaceTimezone } from 'src/utils';
import 'src/scss/pages/_lead_creation.scss';
import { StyledModal } from 'styles/common';

import PropertySelector from './inputs/_property_selector';
import CommonInputs from './inputs/_common_inputs';
import TaskInputs from './inputs/_task_inputs';
import TourInputs from './inputs/_tour_inputs';
import TypeSelector from './inputs/_type_selector';
import { taskFields, tourFields } from './utils';

moment.locale('en');
momentLocalizer();

interface TaskCreationModalProps {
  handleClose: () => void;
  isTour?: boolean;
  show: boolean;
  task?: TaskProps;
  objectId?: number;
  objectType?: string;
  isTaskOnly?: boolean;
}

const TaskCreationModal: FC<TaskCreationModalProps> = ({ isTour, task, show, handleClose, objectId, objectType = 'task', isTaskOnly }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentTask, setCurrentTask] = useState<TaskProps>({} as TaskProps);
  const isPropertyDataLoaded: boolean = useSelector(selectIsPropertyDataLoaded);
  const selectedProperties: PropertyProps[] = useSelector(selectSelectedProperties);
  const property: PropertyProps = useSelector(selectProperty);
  const { searchObject } = pipelineActions;

  const dispatch = useDispatch();
  const submitRef = useRef<HTMLInputElement>(null);
  const { control, handleSubmit, setValue, watch, reset } = useForm();
  const propertyId = get(watch('property'), 'value', null);

  const type = get(watch('type'), 'value', null);
  const isTourTask = isTour || Object.keys(TOUR_TYPES).includes(type);

  useEffect(() => {
    dispatch(searchObject(''));
  }, []);

  const selectValue = (value, field = '') => {
    try {
      if (field === 'tour_confirmation_reminder_enabled' && value == null) {
        return true;
      } else if (field === 'units' && value == null) {
        return [];
      } else if (Array.isArray(value)) {
        return value.map(i => selectValue(i));
      } else if (value instanceof Date) {
        return replaceTimezone(value).format();
      }
      return ('value' in value ? value.value : value);
    } catch {
      return value;
    }
  };

  const onClose = () => {
    dispatch(propertyActions.setSelectedPropertyXname({}));
    dispatch(taskActions.clearAvailableTourTime());
    reset();
    handleClose();
  };

  const onSave = () => {
    if (objectType === 'lead' && objectId) {
      dispatch(leadActions.getLeadById(objectId));
      dispatch(taskActions.getObjectTasks(objectId, objectType)).then(({ result: { data: { results } } }) => {
        if (currentTask.id) setCurrentTask(results.find(t => t.id === currentTask.id));
      });
    }
    if (['lease', 'application'].includes(objectType)) {
      dispatch(taskActions.getObjectTasks(objectId, objectType));
    }
    onClose();
    setIsSubmitting(false);
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const { object } = data;
    const oId = object?.id;
    const payload: TaskProps = {
      description: '',
      owner: null,
      type: '',
      ...(isTourTask
        ? tourFields.reduce((acc, f) => ({ ...acc, [f]: selectValue(data[f], f) }), {})
        : taskFields.reduce((acc, f) => ({ ...acc, [f]: selectValue(data[f], f) }), {})),
    };

    if (oId) {
      payload.lead = null;
      payload.lease = null;
      payload.leasing_user = null;
      payload.application = null;

      if (object.objectType === 'lead') payload.lead = oId;
      if (object.objectType === 'lease') payload.lease = oId;
      if (object.objectType === 'leasing_user') payload.leasing_user = oId;
      if (object.objectType === 'application') payload.application = oId;
    }

    if (!currentTask.id && property && property.self_guided_tour_vendor && property.self_guided_tour_vendor === 'I_APTS' && payload.type === 'SELF_GUIDED_TOUR') {
      payload.status = 'PENDING';
    }

    if (currentTask.id) {
      dispatch(taskActions.updateTaskById(currentTask.id, payload)).then(() => onSave());
    } else {
      dispatch(taskActions.createTask(payload)).then(() => onSave());
    }
  };

  useEffect(() => {
    if (task?.id && show) {
      setCurrentTask(task);

      if (!objectId) {
        // When multiple properties selected and new task creation, then select first property x-name
        dispatch(propertyActions.getPropertyDetails(task.property));
        dispatch(propertyActions.setSelectedPropertyXname(selectedProperties.find(p => p.id === task.property)));
      }
    } else {
      if (objectId) {
        // eslint-disable-next-line
        if (['lead', 'lease', 'application'].includes(objectType)) setCurrentTask({ [objectType]: objectId } as any);
      } else {
        setCurrentTask({} as TaskProps);
      }
      if (!objectId && show) {
        // When multiple properties selected and new task creation, then select first property x-name
        dispatch(propertyActions.setSelectedPropertyXname(get(selectedProperties, '[0]', {})));
      }
    }
  }, [task, show]);

  useEffect(() => {
    if (selectedProperties.length === 1) {
      dispatch(propertyActions.getPropertyDetails(selectedProperties[0].id));
    }
  }, [selectedProperties]);

  return (
    <StyledModal
      aria-labelledby="example-custom-modal-styling-title"
      centered
      className="task-creation-modal"
      isOpen={show}
      toggle={onClose}
    >
      <TaskModalHeader close={<button className="close" onClick={onClose} />}>
        {currentTask.id ? `Edit ${isTourTask ? 'Tour' : 'Task'}` : `Add New ${isTourTask ? 'Tour' : 'Task'}`}
      </TaskModalHeader>

      <TaskModalBody>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="task-form">
          {!currentTask.id && !objectId && selectedProperties.length > 1 && <PropertySelector control={control} />}

          {(objectId || currentTask.property || propertyId || selectedProperties.length === 1) &&
            <TypeSelector control={control} isTour={isTour} setValue={setValue} task={currentTask} type={type} isTaskOnly={isTaskOnly} />}

          {!isPropertyDataLoaded && (
            <Row>
              <Col xs="12" className="mg-t-20">
                <Loader />
              </Col>
            </Row>
          )}

          {(isPropertyDataLoaded && type) && (
            <>
              <CommonInputs
                control={control}
                setValue={setValue}
                task={currentTask}
                watch={watch}
                type={type}
                objectType={objectType}
                disableObjectSelection={!!objectId}
              />

              {!isTourTask
                ? (
                  <TaskInputs
                    control={control}
                    setValue={setValue}
                    task={currentTask}
                    watch={watch}
                    type={type}
                  />
                ) : (
                  <TourInputs
                    control={control}
                    setValue={setValue}
                    task={currentTask}
                    watch={watch}
                    type={type}
                  />
                )
              }
            </>
          )}

          <input hidden ref={submitRef} type="submit" />
        </form>
      </TaskModalBody>

      <TaskModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => submitRef.current.click()}
          disabled={isSubmitting}
        >
          {currentTask.id ? 'Save changes' : `Add ${isTourTask ? 'Tour' : 'Task'}`}
        </Button>
      </TaskModalFooter>
    </StyledModal>
  );
};

export default TaskCreationModal;
