import produce from 'immer';
import { Action, ActionType, SMSTemplateProps } from './action-types';
import { preFillState } from '../utils';

export interface SMSTemplateState {
  isLoaded: boolean,
  smsTemplates: SMSTemplateProps[];
}

export const initialState: SMSTemplateState = {
  isLoaded: false,
  smsTemplates: [],
};

export default produce((state: SMSTemplateState = initialState, action: Action): SMSTemplateState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_SMS_TEMPLATES_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_SMS_TEMPLATES_SUCCESS:
      state.isLoaded = true;
      state.smsTemplates = action.result.data.results;
      break;
    case ActionType.GET_SMS_TEMPLATES_FAILURE:
      state.isLoaded = true;
      break;

    case ActionType.UPDATE_SMS_TEMPLATE_SUCCESS: {
      const updateSMSTemplatesList = state.smsTemplates.map(template => (template.id === action.result.data.id ? action.result.data : template));
      state.smsTemplates = updateSMSTemplatesList;
      break;
    }

    case ActionType.CREATE_SMS_TEMPLATE_SUCCESS: {
      const updateSMSTemplatesList = [...state.smsTemplates];
      updateSMSTemplatesList.push(action.result.data);
      state.smsTemplates = updateSMSTemplatesList;
      break;
    }

    case ActionType.DELETE_SMS_TEMPLATE_SUCCESS: {
      const updateSMSTemplatesList = state.smsTemplates.filter(template => template.id !== action.result.data.id);
      state.smsTemplates = updateSMSTemplatesList;
      break;
    }
  }

  return state;
});
