export default {
  ADDENDA: '/api/v1/leasing/addenda_documents/',
  CREATE_ADDENDA: '/api/v1/leasing/addenda_documents/bulk_create/',
  ADDENDA_DOCUMENTS_CHOICES: '/api/v1/leasing/addenda_documents/addenda_documents_choices/',

  ADDENDA_FIELDS: '/api/v1/leasing/addenda_fields/',

  APPLICANTS: '/api/v1/leasing/:id/applicants/',
  APPLICANT_DETAILS: '/api/v1/leasing/:id/applicants/:id/',
  APPLICANT_EMPLOYERS: '/api/v1/leasing/:id/applicants/:id/employers/',
  APPLICANT_EMPLOYER_DETAILS: '/api/v1/leasing/:id/applicants/:id/employers/:id/',
  SCREENING_STATUS: '/api/v1/leasing/:id/applicants/:id/screening_status/',

  DEPENDENTS: '/api/v1/leasing/:id/dependents/',
  DEPENDENTS_SAVE: '/api/v1/leasing/:id/dependents/bulk_save/',
  DEPENDENT_DETAILS: '/api/v1/leasing/:id/dependents/:id/',

  CONCESSIONS: '/api/v1/leasing/:id/concessions/',
  CONCESSION_DETAILS: '/api/v1/leasing/:id/concessions/:id/',

  BULK_SIGN: '/api/v1/leasing/bulk_sign/',
  DOCUMENTS: '/api/v1/leasing/:id/documents/',
  UPLOAD_DOCUMENTS: '/api/v1/leasing/:id/documents/upload_documents/',
  DOCUMENT_BY_ID: '/api/v1/leasing/:id/documents/:id/',
  SEND_DOCUMENT: '/api/v1/leasing/:id/documents/send_document/',
  SIGN_DOCUMENT: '/api/v1/leasing/:id/documents/:id/countersign/',
  UPDATE_APPLICATION_FORM: '/api/v1/leasing/:id/documents/application_form/',
  GENERATE_AGREEMENT_DOCUMENTS: '/api/v1/leasing/:id/documents/generate_agreement_documents/',

  EMPLOYERS: '/api/v1/leasing/:id/applicants/:id/employers/',
  EMPLOYER_DETAILS: '/api/v1/leasing/:id/employers/:id/',
  EMPLOYERS_SAVE: '/api/v1/leasing/:id/applicants/:id/employers/bulk_save/',

  GARAGES: '/api/v1/leasing/:id/garages/',
  GARAGE_DETAILS: '/api/v1/leasing/:id/garages/:id/',

  GATE_KEYS: '/api/v1/leasing/:id/gate_keys/',
  GATE_KEY_DETAILS: '/api/v1/leasing/:id/gate_keys/:id/',

  GUARANTORS: '/api/v1/leasing/:id/guarantors/',
  GUARANTOR_DETAILS: '/api/v1/leasing/:id/guarantors/:id/',

  LEASE_DETAILS: '/api/v1/leasing/leases/:id/',
  LEASE_AVAILABLE_UNITS: '/api/v1/leasing/leases/available_units/',
  LEASE_TRANSFER_UNIT: '/api/v1/leasing/leases/:id/transfer_unit/',
  LEASE_RELEASE_UNIT: '/api/v1/leasing/leases/:id/release_unit/',
  LEASE_BULK_UPDATE: '/api/v1/leasing/leases/bulk_update/',
  LEASE_RENEW: '/api/v1/leasing/:id/renew/',
  LEASE_CANCEL_RENEWAL: '/api/v1/leasing/:id/renew/cancel_renewal/',
  LEASE_CONFIRM_RENEWAL_DETAILS: '/api/v1/leasing/:id/renew/confirm_details/',
  CONFIRM_INCOME: '/api/v1/leasing/:id/applicants/:id/employers/confirm/',
  RESEND_APPLICATION_INVITE: '/api/v1/leasing/:id/applicants/:id/resend_invite/',
  RESEND_GUARANTOR_INVITE: '/api/v1/leasing/:id/guarantors/:id/resend_invite/',
  START_SCREENING: '/api/v1/leasing/:id/screening_report/start_screening/',
  LEASE_DOCUMENTS: '/api/v1/leasing/lease_documents/',
  LEASE_DOCUMENTS_DELETE_INCLUDE_IN_ALL: '/api/v1/leasing/addenda_documents/bulk_delete/',
  LEASE_DOCUMENTS_MASTER_DOCUMENTS: '/api/v1/leasing/lease_documents/add_master_documents/',
  UPDATE_MASTER_LEASE_DOCUMENT: '/api/v1/leasing/addenda_documents/:id/',
  LEASE_UNITS: '/api/v1/leasing/:id/units/',
  UNIT_RELEASE_HOLD: '/api/v1/leasing/units/:id/unit_release_hold/',
  UNITS: '/api/v1/leasing/units/',
  UNITS_BULK_SAVE: '/api/v1/leasing/units/bulk_save/',

  LOCATION_BY_ZIP_CODE: '/api/v1/leasing/location_by_zip_code/',

  OTHER_INCOMES: '/api/v1/leasing/:id/applicants/:id/other_incomes/',
  OTHER_INCOME_DETAILS: '/api/v1/leasing/:id/applicants/:id/other_incomes/:id/',
  OTHER_INCOMES_SAVE: '/api/v1/leasing/:id/applicants/:id/other_incomes/bulk_save/',

  APPLICANTS_SMS: '/api/v1/leasing/:id/applicants/:id/sms/',
  APPLICANTS_SMS_READALL: '/api/v1/leasing/:id/applicants/:id/sms/read_all/',

  OTHER_FEE_ITEMS: '/api/v1/leasing/:id/other_fee_items/',
  OTHER_FEE_ITEM_DETAILS: '/api/v1/leasing/:id/other_fee_items/:id/',

  OTHER_RENT_ITEMS: '/api/v1/leasing/:id/other_rent_items/',
  OTHER_RENT_ITEM_DETAILS: '/api/v1/leasing/:id/other_rent_items/:id/',

  PARKING: '/api/v1/leasing/:id/parking/',
  PARKING_DETAILS: '/api/v1/leasing/:id/parking/:id/',

  PAYMENT_RECEIVED: '/api/v1/leasing/:id/payment_received/',
  PAYMENT_RECEIVED_DETAILS: '/api/v1/leasing/:id/payment_received/:id/',

  PETS: '/api/v1/leasing/:id/pets/',
  PET_DETAILS: '/api/v1/leasing/:id/pets/:id/',
  LEAD_PETS: '/api/v1/leads/:lead_id/pets/',
  LEAD_PET_DETAILS: '/api/v1/leads/:lead_id/pets/:id/',
  LEAD_PETS_SAVE: '/api/v1/leads/:lead_id/pets/bulk_save/',

  RENT_ITEMS: '/api/v1/leasing/:id/rent_items/',
  RENT_ITEM_DETAILS: '/api/v1/leasing/:id/rent_items/:id/',

  SCREENING_REPORTS: '/api/v1/leasing/:id/screening_reports/',
  SCREENING_RESULT: '/api/v1/leasing/:id/screening_reports/screening_result/',
  INITIATE_SCREENING: '/api/v1/leasing/:id/screening_reports/initiate_screening/',
  RUN_SCREENING: '/api/v1/leasing/:id/screening_reports/run_screening/',
  SCREENING_INCOMPLETE_FIELDS: '/api/v1/leasing/:id/screening_reports/incomplete_fields/',

  STORAGES: '/api/v1/leasing/:id/storages/',
  STORAGE_DETAILS: '/api/v1/leasing/:id/storages/:id/',

  VEHICLES: '/api/v1/leasing/:id/vehicles/',
  VEHICLE_DETAILS: '/api/v1/leasing/:id/vehicles/:id/',
  VEHICLE_YEARS: '/api/v1/leasing/vehicle_category/years/',
  VEHICLE_MODELS: '/api/v1/leasing/vehicle_category/models/',
  VEHICLE_MAKES: '/api/v1/leasing/vehicle_category/makes/',
  LEAD_VEHICLES: '/api/v1/leads/:lead_id/vehicles/',
  LEAD_VEHICLE_DETAILS: '/api/v1/leads/:lead_id/vehicles/:id/',
  LEAD_VEHICLES_SAVE: '/api/v1/leads/:lead_id/vehicles/bulk_save/',

  LEASE_DEFAULTS: '/api/v1/leasing/lease_defaults/',
  PROPERTY_POLICIES: '/api/v1/leasing/property_polices/',
  UTILITY_POLICIES: '/api/v1/leasing/utility_policies/',
  OTHER_FEE_ITEM_TEMPLATES: '/api/v1/leasing/other_fee_items/',
  OTHER_FEE_ITEM_TEMPLATE_DETAILS: '/api/v1/leasing/other_fee_items/:id/',
  OTHER_RENT_ITEM_TEMPLATES: '/api/v1/leasing/other_rent_items/',
  OTHER_RENT_ITEM_TEMPLATE_DETAILS: '/api/v1/leasing/other_rent_items/:id/',
  RENTABLE_ITEMS: '/api/v1/leasing/rentable_items/',
  RENTABLE_ITEM_DETAILS: '/api/v1/leasing/rentable_items/:id/',
  DURATION_PRICING: '/api/v1/leasing/duration_pricing/',
  CLEANING_CHARGES: '/api/v1/leasing/cleaning_charges/',

  NOTICES: '/api/v1/leasing/:lease/notices/',
  NOTICES_DETAILS: '/api/v1/leasing/:lease/notices/:id/',
  NOTICE_VACATE: '/api/v1/leasing/:lease/notices/vacate/',
  COLLECT_NOTICE_DETAILS: '/api/v1/leasing/:lease/notices/collect_notice_details/',

  RENEWAL_MANAGERS: '/api/v1/leasing/:lease/renewal_managers/',
  RENEWAL_MANAGERS_DETAILS: '/api/v1/leasing/:lease/renewal_managers/:id/',
};
