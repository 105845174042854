export default {
  COLUMNS_NAMES: {
    name: {
      name: 'Name',
    },
    stage: {
      name: 'Stage',
    },
    status: {
      name: 'Status',
    },
    source: {
      name: 'Source',
    },
    floor_plan: {
      name: 'Floor plan',
    },
    unit: {
      name: 'Unit',
    },
    move_in_date: {
      name: 'Move-in date',
    },
    next_task: {
      name: 'Next task',
    },
    next_task_date: {
      name: 'Task due',
    },
    type: {
      name: 'Type',
    },
    owner: {
      name: 'Owner',
    },
    property: {
      name: 'Property',
    },
    office: {
      name: 'Office',
    },
    created: {
      name: 'Create date',
    },
    acquisition_date: {
      name: 'Acquisition date',
    },
    days_to_move_in: {
      name: 'Days to move-in',
    },
    last_followup_date: {
      name: 'Last followup date',
    },
    last_activity_date: {
      name: 'Last activity date',
    },
    resident_group: {
      name: 'Resident group',
    },
    available_date: {
      name: 'Available date',
    },
    rent: {
      name: 'Rent',
    },
    lease_start_date: {
      name: 'Lease start date',
    },
    lease_end_date: {
      name: 'Lease end date',
    },
    step: {
      name: 'Step',
    },
    application_group: {
      name: 'Application Group',
    },
    application_stage: {
      name: 'Stage',
    },
    application_status: {
      name: 'Status',
    },
    renewal_offer_status: {
      name: 'Renewal Offer Status',
    },
    renewal_offer_expires: {
      name: 'Renewal Offer Expires',
    },
    renewal_offer_term: {
      name: 'Renewal Offer Term',
    },
  },
};
