import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, PetProps } from './action-types';

export default {
  createPet: (leaseId: number, body: PetProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_PET_REQUEST,
        ActionType.CREATE_PET_SUCCESS,
        ActionType.CREATE_PET_FAILURE,
      ],
      promise: client => client.post(build(api.v1.PETS, leaseId), body),
    },
  }),

  createLeadPets: (leadId: number, body: PetProps[], removeIds = []): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_LEAD_PETS_REQUEST,
        ActionType.CREATE_LEAD_PETS_SUCCESS,
        ActionType.CREATE_LEAD_PETS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEAD_PETS_SAVE, leadId), { pets: body, removeIds }),
    },
  }),

  deletePet: (leaseId: number, petId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_PET_REQUEST,
        ActionType.DELETE_PET_SUCCESS,
        ActionType.DELETE_PET_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.PET_DETAILS, leaseId, petId)),
    },
  }),

  deleteLeadPet: (leadId: number, petId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_LEAD_PET_REQUEST,
        ActionType.DELETE_LEAD_PET_SUCCESS,
        ActionType.DELETE_LEAD_PET_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.LEAD_PET_DETAILS, leadId, petId)),
    },
  }),

  getLeadPets: (leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_PETS_REQUEST,
        ActionType.GET_LEAD_PETS_SUCCESS,
        ActionType.GET_LEAD_PETS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.LEAD_PETS, leadId)),
    },
  }),

  updatePet: (leaseId: number, petId: number, params: PetProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PET_REQUEST,
        ActionType.UPDATE_PET_SUCCESS,
        ActionType.UPDATE_PET_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.PET_DETAILS, leaseId, petId), params),
    },
  }),

  updateLeadPet: (leadId: number, petId: number, body: PetProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_LEAD_PET_REQUEST,
        ActionType.UPDATE_LEAD_PET_SUCCESS,
        ActionType.UPDATE_LEAD_PET_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.LEAD_PET_DETAILS, leadId, petId), body),
    },
  }),
};
