// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ActiveChatsMenuDots,
  ChatsMainMenuDropdown,
  ChatsMainMenuDropdownItem, MenuDropdownIcon,
  VerticalMenuDots,
} from 'dwell/views/chat/multi_chat/styles';
import { Dropdown } from 'reactstrap';
import LeadCreationModal from 'dwell/components/leads/lead_creation_modal';
import LeadLinkingModal from 'dwell/components/leads/lead_linking_modal';
import actions from 'dwell/actions';
import { useHistory } from 'react-router-dom';
import { Prospect as IProspect } from 'dwell/store/chat/action-types';
import focusBarActions from 'dwell/store/focus_bar/action-creators';

interface ProspectSettingProps {
  prospect: IProspect,
  isSingleChat: boolean;
  isSMS: boolean;
}

const Actions = isMute => ({
  ADD_NEW_LEAD: <><MenuDropdownIcon className="ri-user-add-fill" />Create New Lead</>,
  LINK_TO_EXISTING_LEAD: <><MenuDropdownIcon className="ri-user-shared-fill" />Link to Existing Lead</>,
  MUTE: isMute
    ? <><MenuDropdownIcon className="ri-notification-4-fill" />Unmute Chat</>
    : <><MenuDropdownIcon className="ri-notification-off-fill" />Mute Chat</>,
  ARCHIVE: <><MenuDropdownIcon className="ri-archive-drawer-fill" />Archive Chat</>,
  VIEW_PROSPECT: <><MenuDropdownIcon className="ri-user-line" />View prospect</>,
});

const ProspectSetting: FC<ProspectSettingProps> = ({ prospect, isSingleChat, isSMS }) : JSX.Element => {
  const [isShowingCreationModal, setIsShowingCreationModal] = useState(false);
  const [isShowingLinkingModal, setIsShowingLinkingModal] = useState(false);
  const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState(false);

  const activeProperties = useSelector(state => state.prospectChat.activeProperties);

  const dispatch = useDispatch();
  const { updateProspectChatStatus } = actions.prospectChat;

  const { push } = useHistory();

  const runAction = (event, key) => {
    event.stopPropagation();
    const possibilities = Object.keys(Actions(prospect.is_mute));
    switch (key) {
      case possibilities[0]:
        setIsShowingCreationModal(true);
        break;
      case possibilities[1]:
        setIsShowingLinkingModal(true);
        break;
      case possibilities[2]:
        dispatch(updateProspectChatStatus({ prospect: prospect.id, body: { is_mute: !prospect.is_mute } }, activeProperties));
        break;
      case possibilities[3]:
        dispatch(updateProspectChatStatus({ prospect: prospect.id, body: { is_archived: true } }, activeProperties));
        break;
      case possibilities[4]:
        dispatch(focusBarActions.resetFocusBar());
        if (isSMS) {
          push(`/${prospect.property_external_id}/leads/${prospect.id}`);
        } else {
          push(`/${prospect.property_external_id}/leads/${prospect.lead || prospect.guest_card}`);
        }
        break;
      default:
        break;
    }
    setIsMenuDropdownOpen(false);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isMenuDropdownOpen}
        toggle={(e) => {
          e.stopPropagation();
          setIsMenuDropdownOpen(!isMenuDropdownOpen);
        }}
      >
        <ActiveChatsMenuDots
          tag="div"
          data-toggle="dropdown"
          aria-expanded={isMenuDropdownOpen}
        >
          {isSingleChat ? <i className="ri-more-2-fill" /> : <VerticalMenuDots />}
        </ActiveChatsMenuDots>
        <ChatsMainMenuDropdown isSingleChat={isSingleChat} right>
          {!isSMS && Object.keys(Actions(prospect.is_mute))
            .filter(key => ((prospect.lead || prospect.guest_card) ? !['ADD_NEW_LEAD', 'LINK_TO_EXISTING_LEAD'].includes(key) : key !== 'VIEW_PROSPECT'))
            .map(key => <ChatsMainMenuDropdownItem className="dropdown-item" onClick={event => runAction(event, key)} key={key}>{Actions(prospect.is_mute)[key]}</ChatsMainMenuDropdownItem>)}
          {isSMS && <ChatsMainMenuDropdownItem className="dropdown-item" onClick={event => runAction(event, 'VIEW_PROSPECT')}>{Actions(false).VIEW_PROSPECT}</ChatsMainMenuDropdownItem>}
        </ChatsMainMenuDropdown>
      </Dropdown>
      {!isSMS && <LeadLinkingModal
        show={isShowingLinkingModal}
        property={prospect.property}
        handleClose={(lead) => {
          dispatch(updateProspectChatStatus({ prospect: prospect.id, body: { lead } }));
          setIsShowingLinkingModal(false);
        }}
      />}
      {!isSMS && <LeadCreationModal
        show={isShowingCreationModal}
        initialProperty={prospect.property}
        handleClose={(lead) => {
          dispatch(updateProspectChatStatus({ prospect: prospect.id, body: { lead } }));
          setIsShowingCreationModal(false);
        }}
      />}
    </React.Fragment>);
};

export default ProspectSetting;
