import { api } from 'dwell/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  getLeavingReasons: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAVING_REASONS_REQUEST,
        ActionType.GET_LEAVING_REASONS_SUCCESS,
        ActionType.GET_LEAVING_REASONS_FAILURE,
      ],
      promise: client => client.get(api.v1.LEAVING_REASONS),
    },
  }),
};
