import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  ADD_PEOPLES_REQUEST = 'ADD_PEOPLES_REQUEST',
  ADD_PEOPLES_SUCCESS = 'ADD_PEOPLES_SUCCESS',
  ADD_PEOPLES_FAILURE = 'ADD_PEOPLES_FAILURE',

  CANCEL_ADD_PEOPLE_REQUEST = 'CANCEL_ADD_PEOPLE_REQUEST',
  CANCEL_ADD_PEOPLE_SUCCESS = 'CANCEL_ADD_PEOPLE_SUCCESS',
  CANCEL_ADD_PEOPLE_FAILURE = 'CANCEL_ADD_PEOPLE_FAILURE',

  BULK_SAVE_PEOPLE_REQUEST = 'BULK_SAVE_PEOPLE_REQUEST',
  BULK_SAVE_PEOPLE_SUCCESS = 'BULK_SAVE_PEOPLE_SUCCESS',
  BULK_SAVE_PEOPLE_FAILURE = 'BULK_SAVE_PEOPLE_FAILURE',

  LEASE_CHANGE_FEE_REQUEST = 'LEASE_CHANGE_FEE_REQUEST',
  LEASE_CHANGE_FEE_SUCCESS = 'LEASE_CHANGE_FEE_SUCCESS',
  LEASE_CHANGE_FEE_FAILURE = 'LEASE_CHANGE_FEE_FAILURE',

  MAKE_DECISION_REQUEST = 'MAKE_DECISION_REQUEST',
  MAKE_DECISION_SUCCESS = 'MAKE_DECISION_SUCCESS',
  MAKE_DECISION_FAILURE = 'MAKE_DECISION_FAILURE',

  REMOVE_PEOPLES_REQUEST = 'REMOVE_PEOPLES_REQUEST',
  REMOVE_PEOPLES_SUCCESS = 'REMOVE_PEOPLES_SUCCESS',
  REMOVE_PEOPLES_FAILURE = 'REMOVE_PEOPLES_FAILURE',

  REMOVE_RESIDENTS_REQUEST = 'REMOVE_RESIDENTS_REQUEST',
  REMOVE_RESIDENTS_SUCCESS = 'REMOVE_RESIDENTS_SUCCESS',
  REMOVE_RESIDENTS_FAILURE = 'REMOVE_RESIDENTS_FAILURE',

  CANCEL_REMOVE_RESIDENTS_REQUEST = 'CANCEL_REMOVE_RESIDENTS_REQUEST',
  CANCEL_REMOVE_RESIDENTS_SUCCESS = 'CANCEL_REMOVE_RESIDENTS_SUCCESS',
  CANCEL_REMOVE_RESIDENTS_FAILURE = 'CANCEL_REMOVE_RESIDENTS_FAILURE',

  TRANSFER_PEOPLES_REQUEST = 'TRANSFER_PEOPLES_REQUEST',
  TRANSFER_PEOPLES_SUCCESS = 'TRANSFER_PEOPLES_SUCCESS',
  TRANSFER_PEOPLES_FAILURE = 'TRANSFER_PEOPLES_FAILURE',

  UPDATE_PEOPLE_REQUEST = 'UPDATE_PEOPLE_REQUEST',
  UPDATE_PEOPLE_SUCCESS = 'UPDATE_PEOPLE_SUCCESS',
  UPDATE_PEOPLE_FAILURE = 'UPDATE_PEOPLE_FAILURE',

  RESTORE_PEOPLES_REQUEST = 'RESTORE_PEOPLES_REQUEST',
  RESTORE_PEOPLES_SUCCESS = 'RESTORE_PEOPLES_SUCCESS',
  RESTORE_PEOPLES_FAILURE = 'RESTORE_PEOPLES_FAILURE',

  RESEND_INVITES_REQUEST = 'RESEND_INVITES_REQUEST',
  RESEND_INVITES_SUCCESS = 'RESEND_INVITES_SUCCESS',
  RESEND_INVITES_FAILURE = 'RESEND_INVITES_FAILURE',

  UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST',
  UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE',

  REQUEST_PROFILE_REFRESH_REQUEST = 'REQUEST_PROFILE_REFRESH_REQUEST',
  REQUEST_PROFILE_REFRESH_SUCCESS = 'REQUEST_PROFILE_REFRESH_SUCCESS',
  REQUEST_PROFILE_REFRESH_FAILURE = 'REQUEST_PROFILE_REFRESH_FAILURE',

  REQUEST_CANCEL_REFRESH_PROFILE_REQUEST = 'REQUEST_CANCEL_REFRESH_PROFILE_REQUEST',
  REQUEST_CANCEL_REFRESH_PROFILE_SUCCESS = 'REQUEST_CANCEL_REFRESH_PROFILE_SUCCESS',
  REQUEST_CANCEL_REFRESH_PROFILE_FAILURE = 'REQUEST_CANCEL_REFRESH_PROFILE_FAILURE',

  REQUEST_RESEND_LINKS_REQUEST = 'REQUEST_RESEND_LINKS_REQUEST',
  REQUEST_RESEND_LINKS_SUCCESS = 'REQUEST_RESEND_LINKS_SUCCESS',
  REQUEST_RESEND_LINKS_FAILURE = 'REQUEST_RESEND_LINKS_FAILURE',
}

export interface PeoplePayload {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_type: string;
  type: string;
}

export interface EmployerType {
  id?: number;
  employer_name: string;
  position: string;
  supervisor_name: string;
  supervisor_email: string;
  supervisor_phone_number: string;
  industry: string;
  employment_start_date: string;
  zip_or_postal_code: string;
  country: string;
  state: string;
  city_name: string;
  first_street_address: string;
  second_street_address: string;
  supervisor_phone_type: string,
}

export interface EditProfileType {
  personal: {
    first_name: string;
    last_name: string;
    birth_date: string;
    type: string;
    email: string;
    phone_number: string;
    phone_type: string;
    status: string;
    is_main_contact_for_group: boolean;
    has_pets: boolean;
    has_vehicles: boolean;
    has_dependents: boolean;
    identification_type: string;
    driver_licence_photo_front: string;
    driver_licence_photo_front_preview: string;
    is_residence_owned: boolean;
    is_currently_employed: boolean;
  },
  employers: EmployerType[],
  emergency_contact: {
    first_name: string;
    last_name: string;
    relationship: string;
    email: string;
    phone_number: string;
  },
  dependents: {
    first_name: string;
    last_name: string;
    birth_date: string;
  }[],
  incomes: {
    source: string;
    monthly_income: number;
    past_12_months_income: number;
    income_category: string;
    income_status: string;
    documents: string[];
  }[],
  other_incomes: {
    income_category: string;
    monthly_income: number;
    past_12_months_income: number;
    income_status: string;
    documents: string[];
  }[],
}

export interface AddPeoplesAction {
  type: ActionType.ADD_PEOPLES_REQUEST | ActionType.ADD_PEOPLES_SUCCESS | ActionType.ADD_PEOPLES_FAILURE;

  result: {
    data: { success: boolean };
  };
  error: {
    response: { status: string };
  };
}

export interface RemovePeoplesAction {
  type: ActionType.REMOVE_PEOPLES_REQUEST | ActionType.REMOVE_PEOPLES_SUCCESS | ActionType.REMOVE_PEOPLES_FAILURE;

  result: {
    data: { success: boolean };
  };
  error: {
    response: { status: string };
  };
}

export interface TransferPeoplesAction {
  type: ActionType.TRANSFER_PEOPLES_REQUEST | ActionType.TRANSFER_PEOPLES_SUCCESS | ActionType.TRANSFER_PEOPLES_FAILURE;

  result: {
    data: { success: boolean };
  };
  error: {
    response: { status: string };
  };
}

export interface RequestProfileRefreshAction {
  type: ActionType.REQUEST_PROFILE_REFRESH_REQUEST | ActionType.REQUEST_PROFILE_REFRESH_SUCCESS | ActionType.REQUEST_PROFILE_REFRESH_FAILURE;

  result: {
    data: { success: boolean };
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | AddPeoplesAction
  | RemovePeoplesAction
  | TransferPeoplesAction
  | RequestProfileRefreshAction;
