import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { CallBackFunction } from 'src/interfaces';
import { ActionType, Action, AssignLeadOwnerProps, Weekday, Agent, AssignmentRules } from './action-types';

export default {
  getAssignLeadOwners: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ASSIGN_LEAD_OWNERS_REQUEST,
        ActionType.GET_ASSIGN_LEAD_OWNERS_SUCCESS,
        ActionType.GET_ASSIGN_LEAD_OWNERS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.ASSIGN_LEAD_OWNERS),
    },
  }),
  createAssignLeadOwners: (data: AssignLeadOwnerProps, successCB: CallBackFunction = null): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.CREATE_ASSIGN_LEAD_OWNERS_REQUEST,
          ActionType.CREATE_ASSIGN_LEAD_OWNERS_SUCCESS,
          ActionType.CREATE_ASSIGN_LEAD_OWNERS_FAILURE,
        ],
        promise: client => client.post(paths.api.v1.ASSIGN_LEAD_OWNERS, data),
        successCB,
      },
    }),
  updateAssignLeadOwnersById: (id: number, data: AssignLeadOwnerProps): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.UPDATE_ASSIGN_LEAD_OWNERS_REQUEST,
          ActionType.UPDATE_ASSIGN_LEAD_OWNERS_SUCCESS,
          ActionType.UPDATE_ASSIGN_LEAD_OWNERS_FAILURE,
        ],
        promise: client => client.put(build(paths.api.v1.ASSIGN_LEAD_OWNERS_DETAILS, id), data),
      },
    }),

  getCurrentAssignLeadOwner: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CURRENT_ASSIGNED_OWNER_REQUEST,
        ActionType.GET_CURRENT_ASSIGNED_OWNER_SUCCESS,
        ActionType.GET_CURRENT_ASSIGNED_OWNER_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CURRENT_ASSIGN_LEAD_OWNER),
    },
  }),

  getAssignmentRules: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ASSIGNMENT_RULES_REQUEST,
        ActionType.GET_ASSIGNMENT_RULES_SUCCESS,
        ActionType.GET_ASSIGNMENT_RULES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.ASSIGNMENT_RULES.GET_ALL),
    },
  }),

  getAgentsByRole: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_REQUEST,
        ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_SUCCESS,
        ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.ASSIGNMENT_RULES.AGENTS_BY_ROLE),
    },
  }),

  setAssignment: (weekday: Weekday, agents: Agent[]): Action => ({
    type: ActionType.ASSIGNMENT_RULES_SET_ASSIGNMENT,
    payload: { weekday, agents },
  }),

  setExclusion: (weekday: Weekday, agents: Agent[]): Action => ({
    type: ActionType.ASSIGNMENT_RULES_SET_EXCLUSION,
    payload: { weekday, agents },
  }),

  saveAssignmentRules: (data: AssignmentRules): Action => ({
    CALL_API: {
      types: [
        ActionType.ASSIGNMENT_RULES_SAVE_REQUEST,
        ActionType.ASSIGNMENT_RULES_SAVE_SUCCESS,
        ActionType.ASSIGNMENT_RULES_SAVE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.ASSIGNMENT_RULES.SAVE, data),
    },
  }),

};
