import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_GATE_KEY_REQUEST = 'CREATE_GATE_KEY_REQUEST',
  CREATE_GATE_KEY_SUCCESS = 'CREATE_GATE_KEY_SUCCESS',
  CREATE_GATE_KEY_FAILURE = 'CREATE_GATE_KEY_FAILURE',

  DELETE_GATE_KEY_REQUEST = 'DELETE_GATE_KEY_REQUEST',
  DELETE_GATE_KEY_SUCCESS = 'DELETE_GATE_KEY_SUCCESS',
  DELETE_GATE_KEY_FAILURE = 'DELETE_GATE_KEY_FAILURE',

  UPDATE_GATE_KEY_REQUEST = 'UPDATE_GATE_KEY_REQUEST',
  UPDATE_GATE_KEY_SUCCESS = 'UPDATE_GATE_KEY_SUCCESS',
  UPDATE_GATE_KEY_FAILURE = 'UPDATE_GATE_KEY_FAILURE',
}

export interface GateKeyState extends CommonStateType {
  gateKeys: GateKeyProps[]
}

export interface GateKeyProps {
  id?: number,
  id_numbers: string,
  qty: number,
  type: string,
}

/*
 * Actions
 */

interface CreateGateKeyAction extends CommonActionResponse {
  type: ActionType.CREATE_GATE_KEY_REQUEST
  | ActionType.CREATE_GATE_KEY_SUCCESS
  | ActionType.CREATE_GATE_KEY_FAILURE;
  result: { data: GateKeyProps };
}

interface DeleteGateKeyAction extends CommonActionResponse {
  type: ActionType.DELETE_GATE_KEY_REQUEST
  | ActionType.DELETE_GATE_KEY_SUCCESS
  | ActionType.DELETE_GATE_KEY_FAILURE;
  result: { data: GateKeyProps };
}

interface UpdateGateKeyAction extends CommonActionResponse {
  type: ActionType.UPDATE_GATE_KEY_REQUEST
  | ActionType.UPDATE_GATE_KEY_SUCCESS
  | ActionType.UPDATE_GATE_KEY_FAILURE;
  result: { data: GateKeyProps };
}

export type Action =
  PromiseActionType
  | CreateGateKeyAction
  | DeleteGateKeyAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdateGateKeyAction;
