import { paths } from 'dwell/constants';
import { CallBackFunction, ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, UserProps, AllCallRoutings } from './action-types';

export default {
  getCurrentUser: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CURRENT_USER_REQUEST,
        ActionType.GET_CURRENT_USER_SUCCESS,
        ActionType.GET_CURRENT_USER_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CURRENT_USER),
    },
  }),
  updateUser: (id: number, user: UserProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_USER_REQUEST,
        ActionType.UPDATE_USER_SUCCESS,
        ActionType.UPDATE_USER_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.USER_DETAILS, id), user),
      successCB,
    },
  }),
  updateUserAvailableStatus: (id: number, user: UserProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_USER_AVAILABLE_STATUS_REQUEST,
        ActionType.UPDATE_USER_AVAILABLE_STATUS_SUCCESS,
        ActionType.UPDATE_USER_AVAILABLE_STATUS_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.USER_DETAILS, id), user),
    },
  }),
  updateUserLastProperty: (id: number, user: UserProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_USER_LAST_PROPERTY_REQUEST,
        ActionType.UPDATE_USER_LAST_PROPERTY_SUCCESS,
        ActionType.UPDATE_USER_LAST_PROPERTY_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.USER_DETAILS, id), user),
    },
  }),
  updateUserAccessedProperties: (id: number, user: UserProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_USER_ACCESSED_PROPERTIES_REQUEST,
        ActionType.UPDATE_USER_ACCESSED_PROPERTIES_SUCCESS,
        ActionType.UPDATE_USER_ACCESSED_PROPERTIES_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.ACCESSED_PROPERTIES, id), user),
      successCB,
    },
  }),
  getUsers: (props?: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_USERS_REQUEST,
        ActionType.GET_USERS_SUCCESS,
        ActionType.GET_USERS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.USERS, { params: props }),
    },
  }),

  getTeamMates: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_TEAM_MATES_REQUEST,
        ActionType.GET_TEAM_MATES_SUCCESS,
        ActionType.GET_TEAM_MATES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.TEAM_MATES),
    },
  }),

  createUser: (user: UserProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_USER_REQUEST,
        ActionType.CREATE_USER_SUCCESS,
        ActionType.CREATE_USER_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.USERS), user),
    },
  }),

  deleteUser: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_USER_REQUEST,
        ActionType.DELETE_USER_SUCCESS,
        ActionType.DELETE_USER_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.USER_DETAILS, id)),
    },
  }),

  getPropertyUsers: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTY_USERS_REQUEST,
        ActionType.GET_PROPERTY_USERS_SUCCESS,
        ActionType.GET_PROPERTY_USERS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.PROPERTY_USERS, id)),
    },
  }),
  setManuallyUnavailable: (isUnAvailableManually: boolean): Action => ({
    type: ActionType.SET_UNAVAILABLE_MANUALLY,
    isUnAvailableManually,
  }),
  setDoNotDisturb: (lapse: string): Action => ({
    CALL_API: {
      types: [
        ActionType.DO_NOT_DISTURB_REQUEST,
        ActionType.DO_NOT_DISTURB_SUCCESS,
        ActionType.DO_NOT_DISTURB_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.DO_NOT_DISTURB, { do_not_disturb_duration: lapse }),
    },
  }),
  clearDoNotDisturb: (timeExpired = false): Action => ({
    CALL_API: {
      types: [
        ActionType.CLEAR_DO_NOT_DISTURB_REQUEST,
        ActionType.CLEAR_DO_NOT_DISTURB_SUCCESS,
        ActionType.CLEAR_DO_NOT_DISTURB_FAILURE,
      ],
      promise: client => client.delete(paths.api.v1.DO_NOT_DISTURB, { params: { time_expired: timeExpired } }),
    },
  }),
  updateUserSelectedCustomer: (id: number, user: UserProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CURRENT_CUSTOMER_REQUEST,
        ActionType.UPDATE_CURRENT_CUSTOMER_SUCCESS,
        ActionType.UPDATE_CURRENT_CUSTOMER_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.USER_DETAILS, id), user),
    },
  }),
  getUserPropertiesCallRouting: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_USER_PROPERTIES_CALL_ROUTING_REQUEST,
        ActionType.GET_USER_PROPERTIES_CALL_ROUTING_SUCCESS,
        ActionType.GET_USER_PROPERTIES_CALL_ROUTING_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.USERS_CALL_ROUTING, id)),
    },
  }),
  updateUserPropertiesCallRouting: (id: number, callRoutings: AllCallRoutings): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_REQUEST,
        ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_SUCCESS,
        ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.USERS_CALL_ROUTING, id), callRoutings),
    },
  }),
};
