import styled from 'styled-components';
import { Table as BaseTable } from '../styles';

export const Label = styled.p`
  color: ${props => props.theme.colors.secondary};
  // margin-bottom: 10px !important;
`;

export const Badge = styled.div`
  font-size: 12px;
  background-color: #E2E7F4;
  border-radius: 3px;
  border: 1px solid #C1C8DE;
  padding: 4px 8px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;

  & ~ & {
    margin-left: 5px;
  }
`;

export const Table = styled(BaseTable)`
  border: 1px solid #D5DCF4;
`;
