import produce from 'immer';
import { CommonStateType } from 'dwell/store/types';

export type ApplicationState = CommonStateType

export const initialState: ApplicationState = {
  isSubmitting: false,
  isLoaded: false,
};

export default produce((state: ApplicationState = initialState): ApplicationState => state);
