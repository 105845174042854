import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, DependentProps, DependentState } from './action-types';

export const initialState: DependentState = {
  dependents: [] as DependentProps[],
  isLoaded: false,
};

export const selectDependents = (state: { dependent: DependentState }): DependentProps[] => state.dependent.dependents;
export const selectIsDependentsLoaded = (state: { dependent: DependentState }): boolean => state.dependent.isLoaded;

export default produce((state: DependentState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_DEPENDENTS_SUCCESS:
      state.dependents = action.result.data.results;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
