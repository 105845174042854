import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';
import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  CREATE_CONCESSION_REQUEST = 'CREATE_CONCESSION_REQUEST',
  CREATE_CONCESSION_SUCCESS = 'CREATE_CONCESSION_SUCCESS',
  CREATE_CONCESSION_FAILURE = 'CREATE_CONCESSION_FAILURE',

  DELETE_CONCESSION_REQUEST = 'DELETE_CONCESSION_REQUEST',
  DELETE_CONCESSION_SUCCESS = 'DELETE_CONCESSION_SUCCESS',
  DELETE_CONCESSION_FAILURE = 'DELETE_CONCESSION_FAILURE',

  UPDATE_CONCESSION_REQUEST = 'UPDATE_CONCESSION_REQUEST',
  UPDATE_CONCESSION_SUCCESS = 'UPDATE_CONCESSION_SUCCESS',
  UPDATE_CONCESSION_FAILURE = 'UPDATE_CONCESSION_FAILURE',
}

export interface ConcessionState extends CommonStateType {
  concessions: ConcessionProps[]
}

export interface ConcessionProps {
  id: number,
  type: string,
  amount: number,
  reason: string,
  month: number,
  is_concession_deposit_included?: boolean,
}

/*
 * Action
 */

interface CreateConcessionAction extends CommonActionResponse {
  type: ActionType.CREATE_CONCESSION_REQUEST
  | ActionType.CREATE_CONCESSION_SUCCESS
  | ActionType.CREATE_CONCESSION_FAILURE;
  result: { data: ConcessionProps };
}

interface DeleteConcessionAction extends CommonActionResponse {
  type: ActionType.DELETE_CONCESSION_REQUEST
  | ActionType.DELETE_CONCESSION_SUCCESS
  | ActionType.DELETE_CONCESSION_FAILURE;
  result: { data: ConcessionProps };
}

interface UpdateConcessionAction extends CommonActionResponse {
  type: ActionType.UPDATE_CONCESSION_REQUEST
  | ActionType.UPDATE_CONCESSION_SUCCESS
  | ActionType.UPDATE_CONCESSION_FAILURE;
  result: { data: ConcessionProps };
}

export type Action =
  PromiseActionType
  | CreateConcessionAction
  | GetLeaseByIdAction
  | DeleteConcessionAction
  | ResetLeaseAction
  | UpdateConcessionAction;
