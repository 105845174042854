import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { CustomInput, FormGroup, Popover, PopoverBody } from 'reactstrap';

import { InputProps } from '../utils';

const TourFollowup: FC<InputProps> = ({ control, setValue, task }) => {
  const [showPopover, setPopover] = useState(false);

  useEffect(
    () => setValue('tour_confirmation_reminder_enabled', task.tour_confirmation_reminder_enabled),
    [task.tour_confirmation_reminder_enabled],
  );

  return (
    <FormGroup>
      <div className="reminder-checkbox">
        <Controller
          control={control}
          defaultValue
          name="tour_confirmation_reminder_enabled"
          render={({ field: { name, onChange, value } }) => (
            <CustomInput
              checked={value}
              id={name}
              label="Send tour confirmation followup"
              onChange={onChange}
              type="checkbox"
            />
          )}
        />

        <div
          className="ml-2 reminder-checkbox-popover"
          id="tour-popover"
          onMouseEnter={() => setPopover(true)}
          onMouseLeave={() => setPopover(false)}
        >
          <FontAwesomeIcon className="mr-1" icon={faInfoCircle} />
        </div>

        <Popover
          className="reminder-checkbox-popover-info"
          isOpen={showPopover}
          placement="right"
          target="tour-popover"
          toggle={() => setPopover(true)}
        >
          <PopoverBody>
            Union automatically sends a followup email to confirm tour 24 hours prior to scheduled tour time
          </PopoverBody>
        </Popover>
      </div>
    </FormGroup>
  );
};

export default TourFollowup;
