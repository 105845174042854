import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_LEAVING_REASONS_REQUEST = 'GET_LEAVING_REASONS_REQUEST',
  GET_LEAVING_REASONS_SUCCESS = 'GET_LEAVING_REASONS_SUCCESS',
  GET_LEAVING_REASONS_FAILURE = 'GET_LEAVING_REASONS_FAILURE',
}

export interface LeavingReasonProps {
  id: number,
  name: string,
}

export interface LeavingReasonsState extends CommonStateType {
  leavingReasons: LeavingReasonProps[],
}

export interface GetLeavingReasonsAction extends CommonActionResponse {
  type: ActionType.GET_LEAVING_REASONS_REQUEST
  | ActionType.GET_LEAVING_REASONS_SUCCESS
  | ActionType.GET_LEAVING_REASONS_FAILURE,
  result: { data: LeavingReasonProps[] },
}

export type Action =
  PromiseActionType
  | GetLeavingReasonsAction
