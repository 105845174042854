import produce from 'immer';
import { unionBy } from 'lodash';
import { ActionType as PusherActionType, PusherAction } from 'dwell/store/pusher/action-types';
import { OwnerCategory } from 'dwell/views/tasks/category/utils';

import { ActionType as LeadActionType } from '../lead/action-types';
import { ActionType as PropertyActionType, PropertyProps } from '../property/action-types';
import { Action, ActionType, AvailableDateTimeProps, TaskProps } from './action-types';
import { CommonStateType } from '../types';

export interface TaskState extends CommonStateType {
  isCompletedTasksLoaded: boolean;
  tasks: TaskProps[];
  objectTasks: TaskProps[];
  task: TaskProps;
  count: number;
  availableDateTimes: AvailableDateTimeProps[] | string[];
  selectedProperties: PropertyProps[];
  areAvailableDateTimesLoaded: boolean;
  ownerFilter: OwnerCategory;
}

const initialState: TaskState = {
  isCompletedTasksLoaded: true,
  isSubmitting: false,
  errorMessage: null,
  isLoaded: false,
  tasks: [],
  objectTasks: [],
  task: {} as TaskProps,
  count: 0,
  availableDateTimes: [],
  selectedProperties: [],
  areAvailableDateTimesLoaded: true,
  ownerFilter: OwnerCategory.User,
};

export const selectAvailableDateTimes = (state: { task: TaskState }): (AvailableDateTimeProps[] | string[]) =>
  state.task.availableDateTimes;
export const selectAreAvailableDateTimesLoaded = (state: { task: TaskState }): boolean =>
  state.task.areAvailableDateTimesLoaded;
export const selectTasks = (state: { task: TaskState }): TaskProps[] => state.task.tasks;
export const selectOwnerCategory = (state: { task: TaskState }): OwnerCategory => state.task.ownerFilter;

export default produce((state: TaskState = initialState, action: Action): TaskState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'task') {
    return state;
  }
  switch (action.type) {
    case ActionType.BULK_SAVE_TASKS_SUCCESS:
      state.tasks = unionBy(action.result.data, state.tasks, 'id');
      break;

    case ActionType.GET_TASKS_REQUEST:
      if (action.payload.append && state.count) {
        state.isLoaded = true;
      } else {
        state.isLoaded = action.payload.page > 1 && action.payload.paginated;
      }
      if (action.payload.status === 'COMPLETED') {
        state.isCompletedTasksLoaded = false;
      }
      break;
    case ActionType.GET_TASKS_SUCCESS:
      state.isLoaded = true;
      state.tasks = (action.payload.append || action.result.data.previous)
        ? unionBy(state.tasks, action.result.data.results, 'id')
        : action.result.data.results;
      if (action.payload.status === 'COMPLETED') {
        state.isCompletedTasksLoaded = true;
      } else {
        state.count = action.result.data.count;
      }
      break;
    case ActionType.GET_TASKS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case ActionType.GET_OBJECT_TASKS_REQUEST:
      state.isLoaded = false;
      state.objectTasks = [];
      break;
    case ActionType.GET_OBJECT_TASKS_SUCCESS:
      state.isLoaded = true;
      state.objectTasks = action.result.data.results;
      break;
    case ActionType.GET_OBJECT_TASKS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case ActionType.UPDATE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_TASK_SUCCESS: {
      state.isSubmitting = false;
      state.tasks = state.tasks.map(task => (task.id === action.result.data.id ? action.result.data : task));
      state.objectTasks = state.objectTasks.map(task => (task.id === action.result.data.id ? action.result.data : task));
      break;
    }
    case ActionType.UPDATE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.COMPLETE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.COMPLETE_TASK_SUCCESS: {
      state.isSubmitting = false;
      state.tasks = state.tasks.map(task => (task.id === action.result.data.id ? { ...task, ...action.result.data, became_completed: true } : task));
      state.objectTasks = state.objectTasks.map(task => (task.id === action.result.data.id ? action.result.data : task));
      break;
    }
    case ActionType.COMPLETE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.MARK_NO_SHOW_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.MARK_NO_SHOW_TASK_SUCCESS: {
      state.isSubmitting = false;
      state.tasks = state.tasks.filter(task => task.id !== action.result.data.id);
      state.objectTasks = state.objectTasks.filter(task => task.id !== action.result.data.id);
      break;
    }
    case ActionType.MARK_NO_SHOW_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case LeadActionType.CREATE_LEAD_REQUEST:
      state.isSubmitting = true;
      break;
    case LeadActionType.CREATE_LEAD_SUCCESS:
      state.isSubmitting = false;
      break;
    case LeadActionType.CREATE_LEAD_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case LeadActionType.UPDATE_LEAD_SUCCESS: {
      const leadUpdated = action.result.data;
      if (['CLOSED', 'LOST', 'DELETED', 'TEST'].includes(leadUpdated.status) || leadUpdated.stage === 'LEASE_SIGNED') {
        state.objectTasks = [];
      }
      break;
    }

    case ActionType.CREATE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_TASK_SUCCESS: {
      state.isSubmitting = false;
      state.tasks = unionBy([action.result.data], state.tasks, 'id');
      break;
    }
    case ActionType.CREATE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.DELETE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.DELETE_TASK_SUCCESS: {
      state.isSubmitting = false;
      state.tasks = state.tasks.filter(task => task.id !== action.result.data.id);
      state.objectTasks = state.objectTasks.filter(task => task.id !== action.result.data.id);
      break;
    }
    case ActionType.DELETE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = action.row as TaskProps;
      if (state.selectedProperties.map(p => p.id).includes(row.property)) {
        state.tasks = [row].concat(state.tasks.filter(t => t.id !== row.id));
      }
      break;
    }
    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as TaskProps;
      state.tasks = state.tasks.map(t => (t.id === row.id ? {
        ...row,
        became_completed: t.status !== 'COMPLETED' && row.status === 'COMPLETED' ? true : t.became_completed,
      } : t));
      state.objectTasks = state.objectTasks.map(task => (task.id === row.id ? row : task));
      if (row.status === 'NO_SHOW') {
        state.tasks = state.tasks.filter(t => t.id !== row.id);
        state.objectTasks = state.objectTasks.filter(t => t.id !== row.id);
      }
      state.task = state.task.id === row.id ? row : state.task;
      break;
    }
    case PusherActionType.PUSHER_DELETE_RECORD: {
      const row = action.row as TaskProps;
      state.tasks = state.tasks.filter(t => t.id.toString() !== row.id.toString());
      state.objectTasks = state.objectTasks.filter(t => t.id.toString() !== row.id.toString());
      break;
    }

    case ActionType.GET_AVAILABLE_TOUR_TIMES_REQUEST:
      state.areAvailableDateTimesLoaded = false;
      break;
    case ActionType.GET_AVAILABLE_TOUR_TIMES_SUCCESS:
      state.availableDateTimes = action.result.data.times || [];
      state.areAvailableDateTimesLoaded = true;
      break;
    case ActionType.GET_AVAILABLE_TOUR_TIMES_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.areAvailableDateTimesLoaded = true;
      break;

    case ActionType.SET_CURRENT_TASK:
      state.task = action.result.data;
      break;

    case PropertyActionType.SET_SELECTED_PROPERTIES:
      state.selectedProperties = action.properties;
      break;
    case PropertyActionType.GET_SELECTED_PROPERTIES_SUCCESS:
      state.selectedProperties = action.result.data;
      break;
    case PropertyActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_SUCCESS:
      state.selectedProperties = action.result.data;
      break;

    case ActionType.CLEAR_AVAILABLE_TOUR_TIMES:
      state.availableDateTimes = [];
      break;

    case ActionType.SET_OWNER_FILTER:
      state.ownerFilter = action.filter;
      break;
  }

  return state;
});
