import React, { FC, useState, useEffect } from 'react';

import { ProspectQuestion } from 'dwell/views/chat/single_chat/styles';
import { getBotResponse } from 'dwell/views/chat/common/utils';

interface HobbesAssitProps {
  message: string;
  uuid: string;
  setOpenHobbesPanel: React.Dispatch<React.SetStateAction<boolean>>;
  setAnswerToDisplay: React.Dispatch<React.SetStateAction<string[]>>;
  domain: string;
  isHighlightEnabled: boolean;
  lastJoinedIdx?: number;
  lastMessageIdx?: number;
  setIsHobbesAnalyzing: React.Dispatch<React.SetStateAction<boolean>>;
  setHobbesTagAndUnitType: React.Dispatch<React.SetStateAction<{ tag: string, unitType: string }>>;
}

const HobbesAssit: FC<HobbesAssitProps> = ({ message, uuid, domain, setOpenHobbesPanel, setAnswerToDisplay, setIsHobbesAnalyzing, isHighlightEnabled, lastMessageIdx, lastJoinedIdx, setHobbesTagAndUnitType }) => {
  const [sentences, setSentences] = useState([{ highlighted: message, text: message, type: null, status: 'IDLE', answers: [], tag: null, unit_type: null }]);

  const updatePanel = (answers: string[], index: number, tag: string, unit_type: string) => {
    setOpenHobbesPanel(true);
    setAnswerToDisplay(answers);
    setHobbesTagAndUnitType({ tag, unitType: unit_type });
    const newSentences = [...sentences];
    newSentences[index].status = 'OPENED';
    setSentences(newSentences);
  };

  useEffect(() => {
    if (isHighlightEnabled && lastJoinedIdx && lastMessageIdx >= lastJoinedIdx) {
      setIsHobbesAnalyzing(true);
      getBotResponse(message, uuid, domain).then((response) => {
        setIsHobbesAnalyzing(false);
        if (typeof response !== 'string') {
          if (response.response.length) {
            const partialSentences = [];
            response.response.forEach((res, index) => {
              // We receive the position of the question in the original message
              // We need to form the sentences with non question parts of the message and the question
              const question = message.substring(res.start_question, res.end_question);
              const questionSentence = { highlighted: question, text: question, type: 'QUESTION', status: 'IDLE', answers: [res.answer], tag: res.tag, unit_type: res.unit_type };
              // Check if question is the first one or question before the last one
              if (index === 0) {
                const nonQuestion = message.substring(0, res.start_question);
                // Check if there is a non question part before the question
                if (nonQuestion) {
                  const nonQuestionSentence = { highlighted: nonQuestion, text: nonQuestion, type: 'SENTENCE', status: 'IDLE', answers: [], tag: null, unit_type: null };
                  partialSentences.push(nonQuestionSentence);
                }
              } else {
                const nonQuestion = message.substring(response.response[index - 1].end_question, res.start_question);
                // Check if there is a non question part between the questions
                if (nonQuestion) {
                  const nonQuestionSentence = { highlighted: nonQuestion, text: nonQuestion, type: 'SENTENCE', status: 'IDLE', answers: [], tag: null, unit_type: null };
                  partialSentences.push(nonQuestionSentence);
                }
              }
              partialSentences.push(questionSentence);
              // Check if current question is the last one
              if (index === response.response.length - 1) {
                const nonQuestion = message.substring(res.end_question, message.length);
                // Check if there is a non question part after the question
                if (nonQuestion) {
                  const nonQuestionSentence = { highlighted: nonQuestion, text: nonQuestion, type: 'SENTENCE', status: 'IDLE', answers: [], tag: null, unit_type: null };
                  partialSentences.push(nonQuestionSentence);
                }
              }
            });
            setSentences(partialSentences);
          } else {
            setSentences([{ highlighted: message, text: message, type: 'SENTENCE', status: 'WITHOUT_ANSWER', answers: [], tag: null, unit_type: null }]);
          }
        }
      });
    }
  }, [isHighlightEnabled]);

  return (
    <p>
      {sentences.map((s, i) =>
        (s.type === 'QUESTION' ? (
          <ProspectQuestion className="highlighted-question" status={s.status} onClick={() => updatePanel(s.answers, i, s.tag, s.unit_type)} key={`${s.type}-${i}`}>
            {s.highlighted}
          </ProspectQuestion>
        ) : (
          <span key={`${s.type}-${i}`}>{s.highlighted}</span>
        )))}
    </p>
  );
};

export default HobbesAssit;
