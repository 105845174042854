import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, ParkingProps } from './action-types';

export default {
  createParking: (leaseId: number, body: ParkingProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_PARKING_REQUEST,
        ActionType.CREATE_PARKING_SUCCESS,
        ActionType.CREATE_PARKING_FAILURE,
      ],
      promise: client => client.post(build(api.v1.PARKING, leaseId), body),
    },
  }),

  deleteParking: (leaseId: number, parkingId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_PARKING_REQUEST,
        ActionType.DELETE_PARKING_SUCCESS,
        ActionType.DELETE_PARKING_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.PARKING_DETAILS, leaseId, parkingId)),
    },
  }),

  updateParking: (leaseId: number, parkingId: number, params: ParkingProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PARKING_REQUEST,
        ActionType.UPDATE_PARKING_SUCCESS,
        ActionType.UPDATE_PARKING_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.PARKING_DETAILS, leaseId, parkingId), params),
    },
  }),
};
