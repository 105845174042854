import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Nav, NavLink as NavLinkBase } from 'reactstrap';

export const Content = styled.div`
  p { margin-bottom: 0; }
  strong { font-weight: 500; }
`;

export const CardHeader = styled.div`
  background-color: #F0F2F9;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  img {
    width: 85px;
    height: 45px;
    object-fit: cover;
    border-radius: 3px;
  }
`;

export const NavWrapper = styled(PerfectScrollbar)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  &.ps--active-x {
    .nav-line { min-width: inherit; }
  }

  .ps__rail-x {
    height: 3px;
  }

  .ps__rail-x.ps--clicking .ps__thumb-x, .ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x {
    height: 5px;
  }
`;

export const NavLine = styled(Nav)`
  padding: 10px 15px;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: relative;
  // min-width: 100%;

  &::before {
    content: '';
    position: absolute;
    bottom: .5px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.colorbg02};
  }
`;

export const NavLink = styled(NavLinkBase)`
  color: ${props => props.theme.colors.colortx02};
  cursor: pointer;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;

  & ~ & {
    margin-left: 30px;
  }

  ${props => props.active && `
    color: ${props.theme.colors.primary};
    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #0168fa;
    }
  `}
`;

export const CardApartment = styled(Card)`
  border-radius: 4px;
  border-color: #D5DCF4;

  .card-header {
    background-color: #F0F2F9;
    border-bottom-color: #D5DCF4;
    padding: 15px;

    h6 {
      margin-bottom: 4px;
      font-size: 15px;
    }

    .img-preview {
      display: block;
      margin-top: 12px;

      img { border-radius: 3px; }
    }
  }

  .card-body {
    padding: 15px;

    ul {
      margin: 0;
      padding: 0;
      padding-left: 16px;
    }
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.secondary};
`;
