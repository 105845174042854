import styled from 'styled-components';
import { CarouselItem as CommonCarouselItem } from 'reactstrap';
import { CircleDivider as CommonCircleDivider } from 'styles/common';

export const Label = styled.p`
  color: ${props => props.theme.colors.secondary};
`;

export const Link = styled.a``;

export const Preview = styled.div`
  background-color: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow-y: auto;
  padding: 50px;
`;

export const PreviewClose = styled.a`
  position: absolute;
  top: 25px;
  right: 25px;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover, &:focus {
    color: #fff;
  }

  i {
    font-size: 18px;
    line-height: 1;
    margin-right: 3px;
  }
`;

export const PreviewContent = styled.div`
  width: 58%;

  @media (min-height: 800px) {
    width: 72.7%;
  }

  .circle-divider {
    background-color: rgba(#fff, .65);
    margin: 3px 10px 0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }

  .carousel-control-prev { left: -15%; }
  .carousel-control-next { right: -15%; }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: none;

    i {
      font-size: 32px;
      line-height: 1;
    }
  }

  .carousel-item {
    h6 { font-size: 28px; }
    > div { font-size: 20px; }
  }
`;

export const CircleDivider = styled(CommonCircleDivider)`
  background-color: rgba(255,255,255,0.65);
  margin: 3px 10px 0;
`;

export const CarouselItem = styled(CommonCarouselItem)`
  text-align: center;

  img {
    max-height: 70vh;
  }
`;
