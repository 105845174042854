import { PromiseActionType } from 'dwell/store/types';
import { PropertyProps } from 'dwell/store/property/action-types';

export enum ActionType {
  UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST',
  UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE',

  DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST',
  DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE',

  GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE',

  CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST',
  CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE',
}

export interface ClientProps {
  id?: number;
  name?: string;
  status?: string;
  customers?: number[];
  properties?: PropertyProps[];
  customer?: number;
  useExisting?: string; // it's used for onboard FE only
}

interface UpdateClientAction {
  type: ActionType.UPDATE_CLIENT_REQUEST | ActionType.UPDATE_CLIENT_SUCCESS | ActionType.UPDATE_CLIENT_FAILURE;

  result: {
    data: ClientProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteClientAction {
  type: ActionType.DELETE_CLIENT_REQUEST | ActionType.DELETE_CLIENT_SUCCESS | ActionType.DELETE_CLIENT_FAILURE;
  error: {
    response: { status: string };
  };
}

interface GetClientsAction {
  type: ActionType.GET_CLIENTS_REQUEST | ActionType.GET_CLIENTS_SUCCESS | ActionType.GET_CLIENTS_FAILURE;

  result: {
    data: { results: ClientProps[]; count: number; count_all: number; count_active: number; count_inactive: number };
  };
  error: {
    response: { status: string };
  };
}

interface CreateClientAction {
  type: ActionType.CREATE_CLIENT_REQUEST | ActionType.CREATE_CLIENT_SUCCESS | ActionType.CREATE_CLIENT_FAILURE;
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | UpdateClientAction | DeleteClientAction | GetClientsAction | CreateClientAction;
