import { api, build } from 'leasing/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  renewLease: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.RENEW_LEASE_REQUEST,
        ActionType.RENEW_LEASE_SUCCESS,
        ActionType.RENEW_LEASE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEASE_RENEW, leaseId)),
    },
  }),

  cancelRenewal: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.CANCEL_RENEWAL_REQUEST,
        ActionType.CANCEL_RENEWAL_SUCCESS,
        ActionType.CANCEL_RENEWAL_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEASE_CANCEL_RENEWAL, leaseId)),
    },
  }),

  confirmDetails: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.CONFIRM_DETAILS_REQUEST,
        ActionType.CONFIRM_DETAILS_SUCCESS,
        ActionType.CONFIRM_DETAILS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEASE_CONFIRM_RENEWAL_DETAILS, leaseId)),
    },
  }),

};
