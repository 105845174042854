import produce from 'immer';
import { ResidentState } from 'resident/store/lease/reducers';
import { Action, ActionType, NoticeProps, NoticeState } from './action-types';

const initialState: NoticeState = {
  isLoaded: false,
  isSubmitting: false,
  notices: [],
};

export const selectActiveNotice = (state: { leaseDetails: ResidentState }): NoticeProps => state.leaseDetails.leaseDetails.notice;
export const selectNotices = (state: { notice: NoticeState }): NoticeProps[] => state.notice.notices;

export default produce((state: NoticeState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.CREATE_NOTICE_SUCCESS:
      state.notices = [...state.notices, action.result.data];
      break;

    case ActionType.DELETE_NOTICE_SUCCESS:
      state.notices = state.notices.filter(i => i.id !== action.payload);
      break;

    case ActionType.GET_NOTICES_SUCCESS:
      state.notices = action.result.data.results;
      break;

    case ActionType.UPDATE_ACTIVE_NOTICE_REQUEST:
      state.isSubmitting = true;
      break;

    case ActionType.UPDATE_ACTIVE_NOTICE_SUCCESS:
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_ACTIVE_NOTICE_FAILURE:
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_NOTICE_SUCCESS: {
      const { data } = action.result;
      state.notices = state.notices.map(el => (el.id === data.id ? { ...el, ...data } : el));
      break;
    }
  }

  return state;
});
