import { paths } from 'dwell/constants';
import { CallBackFunction } from 'src/interfaces';
import { ActionType, Action, CallsConfiguration } from './action-types';

export default {
  getCallSchedules: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CALL_SCHEDULES_REQUEST,
        ActionType.GET_CALL_SCHEDULES_SUCCESS,
        ActionType.GET_CALL_SCHEDULES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CALL_SCHEDULES, { params: { show_all: true } }),
    },
  }),

  saveCallSchedules: (data: CallsConfiguration, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_CALL_SCHEDULES_REQUEST,
        ActionType.SAVE_CALL_SCHEDULES_SUCCESS,
        ActionType.SAVE_CALL_SCHEDULES_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CALL_SCHEDULES_SAVE, data),
      successCB,
    },
  }),
};
