import { build } from 'dwell/constants/paths';
import { api } from 'application/constants/paths';
import { ActionType, Action, PeoplePayload } from './action-types';

export default {
  addPeoples: (id: number, data: { peoples: PeoplePayload[], is_resident: boolean }): Action => ({
    CALL_API: {
      types: [
        ActionType.ADD_PEOPLES_REQUEST,
        ActionType.ADD_PEOPLES_SUCCESS,
        ActionType.ADD_PEOPLES_FAILURE,
      ],
      promise: client => client.post(build(api.v1.ADD_PEOPLES, id), data),
    },
  }),

  cancelAddPeople: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.CANCEL_ADD_PEOPLE_REQUEST,
        ActionType.CANCEL_ADD_PEOPLE_SUCCESS,
        ActionType.CANCEL_ADD_PEOPLE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.CANCEL_ADD_PEOPLE, id)),
    },
  }),

  bulkSavePeople: (id: number, data: { peoples: PeoplePayload[], isSendInvite: boolean, removeIds: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.BULK_SAVE_PEOPLE_REQUEST,
        ActionType.BULK_SAVE_PEOPLE_SUCCESS,
        ActionType.BULK_SAVE_PEOPLE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.BULK_SAVE_PEOPLE, id), data),
    },
  }),

  leaseChangeFee: (id: number, data: { id: number, skip?: boolean }): Action => ({
    CALL_API: {
      types: [
        ActionType.LEASE_CHANGE_FEE_REQUEST,
        ActionType.LEASE_CHANGE_FEE_SUCCESS,
        ActionType.LEASE_CHANGE_FEE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEASE_CHANGE_FEE, id), data),
    },
  }),

  makeDecision: (id: number, data: { decision: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.MAKE_DECISION_REQUEST,
        ActionType.MAKE_DECISION_SUCCESS,
        ActionType.MAKE_DECISION_FAILURE,
      ],
      promise: client => client.post(build(api.v1.MAKE_DECISION, id), data),
    },
  }),

  removePeoples: (id: number, data: { peoples: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.REMOVE_PEOPLES_REQUEST,
        ActionType.REMOVE_PEOPLES_SUCCESS,
        ActionType.REMOVE_PEOPLES_FAILURE,
      ],
      promise: client => client.post(build(api.v1.REMOVE_PEOPLES, id), data),
    },
  }),

  removeResidents: (id: number, data: { removed_users: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.REMOVE_RESIDENTS_REQUEST,
        ActionType.REMOVE_RESIDENTS_SUCCESS,
        ActionType.REMOVE_RESIDENTS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.REMOVE_RESIDENTS, id), data),
    },
  }),

  cancelRemoveResidents: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.CANCEL_REMOVE_RESIDENTS_REQUEST,
        ActionType.CANCEL_REMOVE_RESIDENTS_SUCCESS,
        ActionType.CANCEL_REMOVE_RESIDENTS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.CANCEL_REMOVE_RESIDENTS, id)),
    },
  }),

  transferPeoples: (id: number, data: { peoples: string[], application: number }): Action => ({
    CALL_API: {
      types: [
        ActionType.TRANSFER_PEOPLES_REQUEST,
        ActionType.TRANSFER_PEOPLES_SUCCESS,
        ActionType.TRANSFER_PEOPLES_FAILURE,
      ],
      promise: client => client.post(build(api.v1.TRANSFER_PEOPLES, id), data),
    },
  }),

  refreshProfile: (id: number, data: { peoples: { id: number, options: string[] }[], isRenewalFlow: boolean, skip?: boolean, }): Action => ({
    CALL_API: {
      types: [
        ActionType.REQUEST_PROFILE_REFRESH_REQUEST,
        ActionType.REQUEST_PROFILE_REFRESH_SUCCESS,
        ActionType.REQUEST_PROFILE_REFRESH_FAILURE,
      ],
      promise: client => client.post(build(api.v1.REFRESH_PROFILE, id), data),
    },
  }),

  cancelRefreshProfile: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.REQUEST_CANCEL_REFRESH_PROFILE_REQUEST,
        ActionType.REQUEST_CANCEL_REFRESH_PROFILE_SUCCESS,
        ActionType.REQUEST_CANCEL_REFRESH_PROFILE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.CANCEL_REFRESH_PROFILE, id)),
    },
  }),

  restorePeoples: (id: number, data: { peoples: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.RESTORE_PEOPLES_REQUEST,
        ActionType.RESTORE_PEOPLES_SUCCESS,
        ActionType.RESTORE_PEOPLES_FAILURE,
      ],
      promise: client => client.post(build(api.v1.RESTORE_PEOPLES, id), data),
    },
  }),

  resendInvites: (id: number, data: PeoplePayload): Action => ({
    CALL_API: {
      types: [
        ActionType.RESEND_INVITES_REQUEST,
        ActionType.RESEND_INVITES_SUCCESS,
        ActionType.RESEND_INVITES_FAILURE,
      ],
      promise: client => client.put(build(api.v1.RESEND_INVITES, id), data),
    },
  }),

  updatePeople: (id: number, data: PeoplePayload[]): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PEOPLE_REQUEST,
        ActionType.UPDATE_PEOPLE_SUCCESS,
        ActionType.UPDATE_PEOPLE_FAILURE,
      ],
      promise: client => client.put(build(api.v1.UPDATE_PEOPLE, id), data),
    },
  }),

  uploadFiles: (leaseId: number, data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.UPLOAD_FILES_REQUEST,
        ActionType.UPLOAD_FILES_SUCCESS,
        ActionType.UPLOAD_FILES_FAILURE,
      ],
      promise: client => client.put(build(api.v1.UPLOAD_FILES, leaseId), data),
    },
  }),

  resendLinks: (id: number, data: { type: string, people_ids?: number[], offer?: number }): Action => ({
    CALL_API: {
      types: [
        ActionType.REQUEST_RESEND_LINKS_REQUEST,
        ActionType.REQUEST_RESEND_LINKS_SUCCESS,
        ActionType.REQUEST_RESEND_LINKS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.RESEND_LINKS, id), data),
    },
  }),
};
