import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SisterCommunitiesTypes } from 'dwell/store/property_profile/action-types';
import SisterCommunitiesCard from './sister_communities_card';

const SisterCommunities: FC = () => {
  const sisterCommunities = useSelector(state => state.propertyProfile.sisterCommunities) as SisterCommunitiesTypes[];

  return <SisterCommunitiesCard sisterCommunities={sisterCommunities} />;
};

export default SisterCommunities;
