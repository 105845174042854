import React, { FC } from 'react';
import { EditModalBtn } from 'dwell/views/lead/overview/styles';
import FileUpload from 'dwell/components/file_upload';
import { LinkButton } from 'styles/common';

interface IncomeDocumentUploadProps {
  id: string,
  preview: string,
  name: string,
  label,
  onChange: (id, value) => void,
}

const IncomeDocumentUpload: FC<IncomeDocumentUploadProps> = ({ id, name, preview, onChange, label }) => (
  <>
    {preview ? (
      <EditModalBtn>{label} - {name}
        <LinkButton href={preview} download={name} className="ml-2">
          <i className="ri-download-2-line" />
        </LinkButton>
        <i
          className="ri-close-line"
          onClick={() => {
            onChange(id, null);
            onChange(`${id}_name`, null);
            onChange(`${id}_preview`, null);
          }}
          style={{ cursor: 'pointer', fontSize: 16 }}
        />
      </EditModalBtn>
    ) : (
      <FileUpload
        onDropAccepted={(file) => {
          onChange(id, file[0]);
          onChange(`${id}_name`, file[0].name);
          onChange(`${id}_preview`, file[0].preview);
        }}
        title="Upload File"
        multiple={false}
        dropzoneContainer={() => <EditModalBtn>{label}</EditModalBtn>}
        dropzoneClassname="file-upload"
      />
    )}
  </>
);

export default IncomeDocumentUpload;
