import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from 'dwell/actions/index';
import corporateActions from 'corporate/store/actions';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectShowApplyButton, selectPreSelectedProperties, selectPostSelectedProperties } from 'dwell/store/properties_selector/reducers';
import { RECENT_PROPERTY_HYPHENS } from 'dwell/constants';

import { PmApply, PmApplyBtn } from 'containers/multi_property_selector/styles';

const ApplyUpdatesButton: FC = () => {
  const currentUser = useSelector(selectCurrentUser);
  const showApplyBtn = useSelector(selectShowApplyButton);
  const selectedProperties = useSelector(selectSelectedProperties);
  const preSelectedProperties = useSelector(selectPreSelectedProperties);
  const postSelectedProperties = useSelector(selectPostSelectedProperties);
  const dispatch = useDispatch();
  const { updateUserAccessedProperties } = actions.user;
  const { setShowApplyButton, setShowSelector, setPreSelectedProperties, setPostSelectedProperties, setPropertyKeyword } = actions.propertiesSelector;
  const { setChatRenderedStatus, clearActiveChats } = actions.prospectChat;
  const { getCustomerInformation } = corporateActions.customer;

  useEffect(() => {
    if (preSelectedProperties.length === 0 && postSelectedProperties.length === selectedProperties.length) {
      dispatch(setShowApplyButton(false));
    } else {
      dispatch(setShowApplyButton(true));
    }
  }, [preSelectedProperties, postSelectedProperties, selectedProperties]);

  const applyUpdatesHandler = () => {
    const combinedArr: PropertyProps[] = postSelectedProperties.concat(preSelectedProperties);
    const ids = combinedArr.map(item => item.id);
    const newState = combinedArr.filter(({ id }, index) => !ids.includes(id, index + 1));
    if (newState.length === 1) {
      localStorage.setItem(RECENT_PROPERTY_HYPHENS, newState[0].external_id);
    } else {
      localStorage.setItem(RECENT_PROPERTY_HYPHENS, 'multiproperty');
    }
    dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: newState }, () => 0)).then(() => {
      dispatch(setPreSelectedProperties([]));
      dispatch(setPostSelectedProperties(newState));
      dispatch(setShowApplyButton(false));
      document.body.classList.remove('scroll_locked');
      dispatch(clearActiveChats());
      dispatch(setChatRenderedStatus(true));
      dispatch(setShowSelector(false));
      dispatch(getCustomerInformation());
      dispatch(setPropertyKeyword(''));
    });
  };

  return (
    <PmApply hide={!showApplyBtn}>
      <PmApplyBtn onClick={applyUpdatesHandler}>
        <span>Apply Updates</span>
      </PmApplyBtn>
    </PmApply>
  );
};

export default ApplyUpdatesButton;
