import { paths } from 'site/constants';
import { CallBackFunction, ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, ClientProps } from './action-types';

export default {
  updateClient: (id: number, clientData: ClientProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CLIENT_REQUEST,
        ActionType.UPDATE_CLIENT_SUCCESS,
        ActionType.UPDATE_CLIENT_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.CLIENTS_ID, id), clientData),
      successCB,
    },
  }),

  deleteClient: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_CLIENT_REQUEST,
        ActionType.DELETE_CLIENT_SUCCESS,
        ActionType.DELETE_CLIENT_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.CLIENTS_ID, id)),
    },
  }),

  getClients: (params: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CLIENTS_REQUEST,
        ActionType.GET_CLIENTS_SUCCESS,
        ActionType.GET_CLIENTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CLIENTS, { params }),
    },
  }),

  createClient: (clientData: ClientProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_CLIENT_REQUEST,
        ActionType.CREATE_CLIENT_SUCCESS,
        ActionType.CREATE_CLIENT_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.CLIENTS), clientData),
    },
  }),
};
