import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from 'dwell/actions';
import { FormStructure, AnswerPayload, AgentQuestion } from 'dwell/store/agent_question/action-types';
import { BinaryForm as BinaryFormContainer, ChatFormButton } from 'dwell/views/chat/single_chat/window/styles';
import { getYesAnswerHtml } from 'dwell/views/chat/agent_question/utils';

interface BinaryFormProps {
  question: AgentQuestion
  submitHandler: (idx: number, isBinary: boolean, form: FormStructure[]) => void,
  resetForm: () => void,
  index: number,
}

const BinaryForm: FC<BinaryFormProps> = ({ submitHandler, index, question, resetForm }) => {
  const dispatch = useDispatch();
  const agentQuestion = useSelector(state => state.agentQuestion);
  const { globalForm } = agentQuestion;
  const formData = globalForm[index];
  const { processAnswers, setGlobalForm } = actions.agentQuestion;

  const sendAnswers = (answers: AnswerPayload[], answersHTML: string) => {
    dispatch(processAnswers({ answers, answers_html: answersHTML }));
  };

  const submitYesNoHandler = (isYesOption: boolean) => {
    const answerText = isYesOption ? formData.alternative_yes_answer || 'Yes' : formData.alternative_no_answer || 'No';
    if ((isYesOption && !formData.invert_current_binary_question_behavior) || (!isYesOption && formData.invert_current_binary_question_behavior)) {
      const newState = [...globalForm];
      newState[index].answer = isYesOption ? 'Yes' : 'No';
      newState[index].conditional_answer = isYesOption;
      dispatch(setGlobalForm(newState));
      submitHandler(index, true, newState);
    } else {
      const question_id = question.id;
      const { questionText } = globalForm[0];
      const answerHTML = getYesAnswerHtml(questionText, answerText);
      const answer: AnswerPayload = {
        question: question_id,
        value: isYesOption ? 'Yes' : 'No',
        action: 'YES',
        is_array: false,
      };
      resetForm();
      sendAnswers([answer], answerHTML);
    }
  };

  return (
    <BinaryFormContainer>
      <ChatFormButton className="btn btn-white" onClick={() => submitYesNoHandler(true)}>
        {formData.alternative_yes_answer || 'Yes'}
      </ChatFormButton>
      <ChatFormButton className="btn btn-white" onClick={() => submitYesNoHandler(false)}>
        {formData.alternative_no_answer || 'No'}
      </ChatFormButton>
    </BinaryFormContainer>
  );
};

export default BinaryForm;
