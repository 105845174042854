import { EmailMessageProps } from 'dwell/store/email_message/action-types';
import { EmailTemplateProps } from '../email_template/action-types';
import { Lead } from '../lead/action-types';
import { PropertyProps } from '../property/action-types';

export type EmailTextFields =
  | 'body'
  | 'subject'
  | 'cc';

export enum ActionType {
  ADD_EMAIL_POPOUT = 'ADD_EMAIL_POPOUT',
  CLOSE_EMAIL_POPOUT = 'CLOSE_EMAIL_POPOUT',
  TOGGLE_MINIMIZE_EMAIL_POPOUT = 'TOGGLE_MINIMIZE_EMAIL_POPOUT',
  ACTIVE_EMAIL_POPOUT_CIRCLED = 'ACTIVE_EMAIL_POPOUT_CIRCLED',
  UPDATE_EMAIL_POPOUT = 'UPDATE_EMAIL_POPOUT',
  REMOVE_EMAIL_POPOUT = 'REMOVE_EMAIL_POPOUT',
  CLEAR_EMAIL_POPOUTS = 'CLEAR_EMAIL_POPOUTS',
  SET_EMAIL_POPOUT_TEXT_VAR = 'SET_EMAIL_POPOUT_TEXT_VAR',
  SET_EMAIL_POPOUT_IS_PREVIEW = 'SET_EMAIL_POPOUT_IS_PREVIEW',
  SET_EMAIL_POPOUT_SHOWING_CC_INPUT = 'SET_EMAIL_POPOUT_SHOWING_CC_INPUT',
  SET_EMAIL_POPOUT_FILES = 'SET_EMAIL_POPOUT_FILES',
  REMOVE_EMAIL_POPOUT_FILE = 'REMOVE_EMAIL_POPOUT_FILE',
  CLEAR_EMAIL_POPOUT_FILES = 'CLEAR_EMAIL_POPOUT_FILES',
  SET_EMAIL_POPOUT_SELECTED_TEMPLATE = 'SET_EMAIL_POPOUT_SELECTED_TEMPLATE',
  SET_EMAIL_POPOUT_SUBJECT_VARIABLES = 'SET_EMAIL_POPOUT_SUBJECT_VARIABLES',
  SET_EMAIL_POPOUT_TEMPLATES = 'SET_EMAIL_POPOUT_TEMPLATES',
  CIRCLE_EMAIL_POPOUT = 'CIRCLE_EMAIL_POPOUT',
}

export interface Attachment {
  name: string,
  size: number,
  preview: string,
}

export interface EmailPopout {
  popoutId: string,
  property: PropertyProps,
  lead?: Lead,
  isReply: boolean,
  body: string,
  subject: string,
  cc: string,
  isPreview: boolean,
  files: Attachment[],
  showingCcInput: boolean,
  subjectVariables: string[],
  emailTemplates: EmailMessageProps[],
  selectedTemplate: EmailTemplateProps,
  emailMessage?: EmailMessageProps, // needed when we are replying another email
  minimized: boolean,
  circled: boolean,
  preloadTemplate?: string,
  isSettedFromPriceQuote?: boolean,
}

interface EmailPopoutById {
  popoutId: string,
}

interface AddEmailPopoutAction extends EmailPopoutById {
  type: ActionType.ADD_EMAIL_POPOUT,
  property: PropertyProps,
  isReply: boolean,
  lead: Lead,
  emailMessage?: EmailMessageProps,
  preloadTemplate?: string,
  isSettedFromPriceQuote?: boolean,
}

interface CloseEmailPopoutAction extends EmailPopoutById {
  type: ActionType.CLOSE_EMAIL_POPOUT,
}

interface ToggleMinimizeAction extends EmailPopoutById {
  type: ActionType.TOGGLE_MINIMIZE_EMAIL_POPOUT,
}

interface SetEmailTextVarAction extends EmailPopoutById {
  type: ActionType.SET_EMAIL_POPOUT_TEXT_VAR,
  varName: EmailTextFields,
  value: string,
}

interface SetIsPreviewAction extends EmailPopoutById {
  type: ActionType.SET_EMAIL_POPOUT_IS_PREVIEW,
  isPreview: boolean,
}

interface SetIsShowingCcInput extends EmailPopoutById {
  type: ActionType.SET_EMAIL_POPOUT_SHOWING_CC_INPUT,
  showingCcInput: boolean,
}

interface SetAttachments extends EmailPopoutById {
  type: ActionType.SET_EMAIL_POPOUT_FILES,
  files: Attachment[],
}

interface RemoveAttachment extends EmailPopoutById {
  type: ActionType.REMOVE_EMAIL_POPOUT_FILE,
  preview: string,
}

interface ClearAttachments extends EmailPopoutById {
  type: ActionType.CLEAR_EMAIL_POPOUT_FILES,
}

interface SetSelectedTemplate extends EmailPopoutById {
  type: ActionType.SET_EMAIL_POPOUT_SELECTED_TEMPLATE,
  selectedTemplate: EmailTemplateProps,
}

interface SetSubjectVariables extends EmailPopoutById {
  type: ActionType.SET_EMAIL_POPOUT_SUBJECT_VARIABLES,
  subjectVariables: string[],
}

interface ActiveCircled extends EmailPopoutById {
  type: ActionType.ACTIVE_EMAIL_POPOUT_CIRCLED
}

interface SetEmailTemplates extends EmailPopoutById {
  type: ActionType.SET_EMAIL_POPOUT_TEMPLATES,
  emailTemplates: EmailTemplateProps[]
}

interface CircleEmailPopoutAction extends EmailPopoutById {
  type: ActionType.CIRCLE_EMAIL_POPOUT,
}

export type Action =
  | AddEmailPopoutAction
  | CloseEmailPopoutAction
  | ToggleMinimizeAction
  | SetEmailTextVarAction
  | SetIsPreviewAction
  | SetIsShowingCcInput
  | SetAttachments
  | RemoveAttachment
  | ClearAttachments
  | SetSelectedTemplate
  | SetSubjectVariables
  | ActiveCircled
  | SetEmailTemplates
  | CircleEmailPopoutAction;
