import { Weekday, DISPLAY_WEEKDAYS, WeekdaysChanges, AssignmentRules, Agent, AgentsByRole } from 'dwell/store/assignment_rules/action-types';
import { PropertyProps } from 'dwell/store/property/action-types';

// Given a numerical weekday (0, 1, 2) we return the corresponding fields from the backend for that day
// e.g. Monday (which is 0) will return { agentsField: 'monday_agents', exclusionField: 'monday_exclusion_pool' }
export const agentsFromWeekday = (weekday: Weekday): { agentsField: string, exclusionField: string} => {
  const agentsSufix = 'agents';
  const exclusionPoolSufix = 'exclusion_pool';
  const dayName = DISPLAY_WEEKDAYS[weekday].toLowerCase();
  return {
    agentsField: `${dayName}_${agentsSufix}`,
    exclusionField: `${dayName}_${exclusionPoolSufix}`,
  };
};

export const defaultWeedaysChanges: WeekdaysChanges = {
  [Weekday.Monday]: { assignments: [], exclusions: [] },
  [Weekday.Tuesday]: { assignments: [], exclusions: [] },
  [Weekday.Wednesday]: { assignments: [], exclusions: [] },
  [Weekday.Thursday]: { assignments: [], exclusions: [] },
  [Weekday.Friday]: { assignments: [], exclusions: [] },
  [Weekday.Saturday]: { assignments: [], exclusions: [] },
  [Weekday.Sunday]: { assignments: [], exclusions: [] },
};

export enum AgentGroups {
  ALL_AGENTS = 'ALL_AGENTS',
  PROPERTY_MANAGERS = 'PROPERTY_MANAGERS',
  ONSITE_AGENTS = 'ONSITE_AGENTS',
  VIRTUAL_AGENTS = 'VIRTUAL_AGENTS',
}

export const GROUP_OPTIONS = [
  { name: 'All Agents', id: AgentGroups.ALL_AGENTS },
  { name: 'Property Managers', id: AgentGroups.PROPERTY_MANAGERS },
  { name: 'Onsite Agents', id: AgentGroups.ONSITE_AGENTS },
  { name: 'Virtual Agents', id: AgentGroups.VIRTUAL_AGENTS },
];

export interface AgentAndGroup {
  id: number|AgentGroups;
  name: string;
}

export const filterGroupOptions = (agentsByRole: AgentsByRole, propertyProps: PropertyProps) => {
  const { is_by_appointment_only: isByAppointmentOnly } = propertyProps;
  let filteredGroups = GROUP_OPTIONS;
  if (isByAppointmentOnly) {
    filteredGroups = GROUP_OPTIONS.filter(group => group.id !== AgentGroups.ONSITE_AGENTS);
  }
  return filteredGroups.filter(group => agentsByRole[group.id.toLowerCase()].length > 1);
};

// When a new agent is selected we check if all the agents in a group are selected as well
// to add the group to the selected agents
export const addGroups = (selectedAgents: AgentAndGroup[], agentsByRole: AgentsByRole, propertyProps: PropertyProps): AgentAndGroup[] => {
  let containsAllAgents = false;
  let groupsAndAgentsToAdd = [];
  const filteredGroups = filterGroupOptions(agentsByRole, propertyProps);
  const groupOptionsInCurrentAgents = selectedAgents.filter(a => filteredGroups.some(g => g.id === a.id));
  filteredGroups.forEach((group) => {
    // Do not add more groups if ALL_AGENTS is already added
    if (containsAllAgents) return;

    const agentsInTheGroup = agentsByRole[group.id.toLowerCase()];
    const selectedAgentsInGroup = selectedAgents.filter(a => !Number.isNaN(a.id as number)).filter(agent => agentsInTheGroup.some(a => a.id === agent.id));

    // Do not consider groups without agents
    if (!agentsInTheGroup.length) return;

    if (agentsInTheGroup.length === selectedAgentsInGroup.length) {
      groupsAndAgentsToAdd = [group, ...groupsAndAgentsToAdd];
      if (group.id === AgentGroups.ALL_AGENTS) containsAllAgents = true;
    } else if (group.id !== AgentGroups.ALL_AGENTS) {
      groupsAndAgentsToAdd = [...groupsAndAgentsToAdd, ...selectedAgentsInGroup];
    }
    // }
  });
  return [...groupOptionsInCurrentAgents, ...groupsAndAgentsToAdd];
};

// Initialize the changes object with the current assignment rules
// First we pull the assignation rules and then we initialize the changes with that information
// If the changes object is initialized with default we would face issues when saving the changes
// as the agent may not touch all the days
export const initializeWeekdaysChanges = (assignmentRules: AssignmentRules): WeekdaysChanges => {
  const changes: WeekdaysChanges = defaultWeedaysChanges;
  // Object.keys on enum not only returns the keys but also the values
  // So we just filter out not number keys
  Object.keys(Weekday).filter(key => !Number.isNaN(parseInt(key, 10))).map(key => parseInt(key, 10)).forEach((weekday) => {
    const { agentsField, exclusionField } = agentsFromWeekday(weekday as Weekday);
    changes[weekday].assignments = assignmentRules[agentsField];
    changes[weekday].exclusions = assignmentRules[exclusionField];
  });
  return changes;
};

// WeekdaysChanges is an object whose keys are numerical weekdays (0, 1, 2, 3)
// so we change those numerical keys to the string keys of the agentsField and exclusionField (monday_agent, monday_exclusion_pool..)
export const changesToPayload = (changes: WeekdaysChanges): AssignmentRules => {
  const payload = {};
  Object.keys(Weekday).filter(key => !Number.isNaN(parseInt(key, 10))).map(key => parseInt(key, 10)).forEach((weekday) => {
    const { agentsField, exclusionField } = agentsFromWeekday(weekday as Weekday);
    payload[agentsField] = changes[weekday].assignments.map((agent: Agent) => agent.id);
    payload[exclusionField] = changes[weekday].exclusions.map((agent: Agent) => agent.id);
  });
  return payload as AssignmentRules;
};

// If any of the assignment rules is empty we return false
// do not consider the exclusion pool as it can be empty
export const validatePayload = (changes: AssignmentRules): boolean => Object.keys(changes).filter(key => !key.includes('exclusion')).every(key => changes[key].length > 0);

export const handleSavingError = (errorString: string): string => {
  const formattedError = errorString.replace('[', '').replace(']', '').replace("'", '');
  const match = formattedError.match(/^(\w+_agents)/);

  if (match) {
    const extractedPart = match[1]; // e.g., "monday_agents"
    const weekday = extractedPart.split('_')[0]; // e.g., "monday"
    const betterDescription = `The ${weekday} agents must have at least one assigned agent.`;
    return betterDescription;
  }

  return errorString;
};
