import styled from 'styled-components';

export const GoBackWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-right: 1px solid #e2e7f4;
  z-index: 10;
  transition: all 0.2s;
`;

export const GoBackButton = styled.button`
  width: 38px;
  height: 38px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 18px;
    line-height: 1;
    text-indent: 0;
    transition: all 0.25s;
  }
`;

export const LeaseOverviewBodyWrapper = styled.div`
  padding: 20px;
  height: calc(100% - 78px);
  position: relative;
  overflow-y: auto;
`;
