import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { SingleDatePicker } from 'react-dates';
import { FormGroup, FormText, Label } from 'reactstrap';

import { InputProps } from '../utils';

const DueDateSelector: FC<InputProps> = ({ control, setValue, task }) => {
  const [focused, setFocused] = useState(false);

  useEffect(() => setValue('due_date', task.due_date), [task.due_date]);

  return (
    <FormGroup>
      <Label htmlFor="due_date">Due date:</Label>
      <Controller
        control={control}
        name="due_date"
        rules={{ required: true }}
        render={({ field: { name, value }, fieldState: { error } }) => (
          <>
            <SingleDatePicker
              block
              date={value ? moment(value) : null}
              hideKeyboardShortcutsPanel
              focused={focused}
              id="due_date"
              inputIconPosition="after"
              isDayHighlighted={day => day.isSame(moment(), 'd')}
              isOutsideRange={() => false}
              numberOfMonths={1}
              onDateChange={date => setValue(name, date && date.format())}
              onFocusChange={({ focused: focus }) => setFocused(focus)}
              openDirection="down"
              placeholder="Select date"
              small
            />
            {error && <FormText color="danger">Please choose a due date</FormText>}
          </>
        )}
      />
    </FormGroup>
  );
};

export default DueDateSelector;
