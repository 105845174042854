import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_PET_REQUEST = 'CREATE_PET_REQUEST',
  CREATE_PET_SUCCESS = 'CREATE_PET_SUCCESS',
  CREATE_PET_FAILURE = 'CREATE_PET_FAILURE',

  DELETE_PET_REQUEST = 'DELETE_PET_REQUEST',
  DELETE_PET_SUCCESS = 'DELETE_PET_SUCCESS',
  DELETE_PET_FAILURE = 'DELETE_PET_FAILURE',

  UPDATE_PET_REQUEST = 'UPDATE_PET_REQUEST',
  UPDATE_PET_SUCCESS = 'UPDATE_PET_SUCCESS',
  UPDATE_PET_FAILURE = 'UPDATE_PET_FAILURE',

  CREATE_LEAD_PETS_REQUEST = 'CREATE_LEAD_PETS_REQUEST',
  CREATE_LEAD_PETS_SUCCESS = 'CREATE_LEAD_PETS_SUCCESS',
  CREATE_LEAD_PETS_FAILURE = 'CREATE_LEAD_PETS_FAILURE',

  DELETE_LEAD_PET_REQUEST = 'DELETE_LEAD_PET_REQUEST',
  DELETE_LEAD_PET_SUCCESS = 'DELETE_LEAD_PET_SUCCESS',
  DELETE_LEAD_PET_FAILURE = 'DELETE_LEAD_PET_FAILURE',

  GET_LEAD_PETS_REQUEST = 'GET_LEAD_PETS_REQUEST',
  GET_LEAD_PETS_SUCCESS = 'GET_LEAD_PETS_SUCCESS',
  GET_LEAD_PETS_FAILURE = 'GET_LEAD_PETS_FAILURE',

  UPDATE_LEAD_PET_REQUEST = 'UPDATE_LEAD_PET_REQUEST',
  UPDATE_LEAD_PET_SUCCESS = 'UPDATE_LEAD_PET_SUCCESS',
  UPDATE_LEAD_PET_FAILURE = 'UPDATE_LEAD_PET_FAILURE'
}

export interface PetErrorProps {
  name?: string,
  pet_type?: string,
  breed?: string,
  weight?: string,
}

export interface PetProps {
  id?: number,
  name: string,
  pet_type: string,
  pet_sex?: string,
  breed: string,
  color: string,
  age: string,
  weight: number,
  height?: number,
  unique_marks?: string,
  is_service_animal?: boolean,
  user?: number,
}

export interface PetState extends CommonStateType {
  pets: PetProps[]
}

/*
 * Action types
 */

interface SinglePetResponse { data: PetProps }

interface CreateLeadPetsAction extends CommonActionResponse {
  type: ActionType.CREATE_LEAD_PETS_REQUEST
  | ActionType.CREATE_LEAD_PETS_SUCCESS
  | ActionType.CREATE_LEAD_PETS_FAILURE;
  result: { data: PetProps[] };
}

interface CreatePetAction extends CommonActionResponse {
  type: ActionType.CREATE_PET_REQUEST
  | ActionType.CREATE_PET_SUCCESS
  | ActionType.CREATE_PET_SUCCESS
  result: SinglePetResponse;
}

interface DeleteLeadPetAction extends CommonActionResponse {
  type: ActionType.DELETE_LEAD_PET_REQUEST
  | ActionType.DELETE_LEAD_PET_SUCCESS
  | ActionType.DELETE_LEAD_PET_FAILURE;
  result: SinglePetResponse;
}

interface DeletePetAction extends CommonActionResponse {
  type: ActionType.DELETE_PET_REQUEST
  | ActionType.DELETE_PET_SUCCESS
  | ActionType.DELETE_LEAD_PET_FAILURE;
  result: SinglePetResponse;
}

interface GetLeadPetsAction extends CommonActionResponse {
  type: ActionType.GET_LEAD_PETS_REQUEST
  | ActionType.GET_LEAD_PETS_SUCCESS
  | ActionType.GET_LEAD_PETS_FAILURE;
  result: { data: { results: PetProps[] } };
}

interface GetLeaseByIdAction extends CommonActionResponse {
  type: LeaseActionType.GET_LEASE_BY_ID_SUCCESS;
  result: { data: { pets: PetProps[] } };
}

interface ResetLeaseAction { type: LeaseActionType.RESET_LEASE }

interface UpdateLeadPetAction extends CommonActionResponse {
  type: ActionType.UPDATE_LEAD_PET_REQUEST
  | ActionType.UPDATE_LEAD_PET_SUCCESS
  | ActionType.UPDATE_LEAD_PET_FAILURE;
  result: SinglePetResponse;
}

interface UpdatePetAction extends CommonActionResponse {
  type: ActionType.UPDATE_PET_REQUEST
  | ActionType.UPDATE_PET_SUCCESS
  | ActionType.UPDATE_PET_FAILURE;
  result: SinglePetResponse;
}

export type Action =
  PromiseActionType
  | CreateLeadPetsAction
  | CreatePetAction
  | DeleteLeadPetAction
  | DeletePetAction
  | GetLeadPetsAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdateLeadPetAction
  | UpdatePetAction;
