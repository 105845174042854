import { api, build } from 'leasing/constants/paths';
import { CallBackFunction } from 'src/interfaces';
import {
  Action,
  ActionType,
  AddendaFieldProps,
  CleaningChargeItem,
  DurationPricingProps,
  LeaseDefaultProps,
  OtherFeeItemTemplateProps,
  OtherRentItemTemplateProps,
  PropertyPolicyProps,
  RentableItemProps,
  UtilityPoliciesProps,
} from './action-types';

export default {
  createOtherFeeItem: (payload: OtherFeeItemTemplateProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST,
        ActionType.CREATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS,
        ActionType.CREATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
      ],
      promise: client => client.post(api.v1.OTHER_FEE_ITEM_TEMPLATES, payload),
    },
  }),

  createOtherRentItem: (payload: OtherRentItemTemplateProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST,
        ActionType.CREATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS,
        ActionType.CREATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
      ],
      promise: client => client.post(api.v1.OTHER_RENT_ITEM_TEMPLATES, payload),
    },
  }),

  createRentableItem: (data: RentableItemProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_RENTABLE_ITEM_REQUEST,
        ActionType.CREATE_RENTABLE_ITEM_SUCCESS,
        ActionType.CREATE_RENTABLE_ITEM_FAILURE,
      ],
      promise: client => client.post(api.v1.RENTABLE_ITEMS, data),
      successCB,
    },
  }),

  deleteOtherRentItem: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_OTHER_RENT_ITEM_TEMPLATE_REQUEST,
        ActionType.DELETE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS,
        ActionType.DELETE_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
      ],
      payload: id,
      promise: client => client.delete(build(api.v1.OTHER_RENT_ITEM_TEMPLATE_DETAILS, id)),
    },
  }),

  deleteOtherFeeItem: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_OTHER_FEE_ITEM_TEMPLATE_REQUEST,
        ActionType.DELETE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS,
        ActionType.DELETE_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
      ],
      payload: id,
      promise: client => client.delete(build(api.v1.OTHER_FEE_ITEM_TEMPLATE_DETAILS, id)),
    },
  }),

  deleteRentableItem: (id: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_RENTABLE_ITEM_REQUEST,
        ActionType.DELETE_RENTABLE_ITEM_SUCCESS,
        ActionType.DELETE_RENTABLE_ITEM_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.RENTABLE_ITEM_DETAILS, id)),
      successCB,
    },
  }),

  getAddendaFields: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ADDENDA_FIELDS_REQUEST,
        ActionType.GET_ADDENDA_FIELDS_SUCCESS,
        ActionType.GET_ADDENDA_FIELDS_FAILURE,
      ],
      promise: client => client.get(api.v1.ADDENDA_FIELDS),
    },
  }),

  getOtherFeeItems: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_OTHER_FEE_ITEM_TEMPLATE_REQUEST,
        ActionType.GET_OTHER_FEE_ITEM_TEMPLATE_SUCCESS,
        ActionType.GET_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
      ],
      promise: client => client.get(api.v1.OTHER_FEE_ITEM_TEMPLATES),
    },
  }),

  getOtherRentItems: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_OTHER_RENT_ITEM_TEMPLATE_REQUEST,
        ActionType.GET_OTHER_RENT_ITEM_TEMPLATE_SUCCESS,
        ActionType.GET_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
      ],
      promise: client => client.get(api.v1.OTHER_RENT_ITEM_TEMPLATES),
    },
  }),

  getRentableItems: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_RENTABLE_ITEMS_REQUEST,
        ActionType.GET_RENTABLE_ITEMS_SUCCESS,
        ActionType.GET_RENTABLE_ITEMS_FAILURE,
      ],
      promise: client => client.get(api.v1.RENTABLE_ITEMS),
    },
  }),

  saveAddendaFields: (data: AddendaFieldProps): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_ADDENDA_FIELDS_REQUEST,
        ActionType.SAVE_ADDENDA_FIELDS_SUCCESS,
        ActionType.SAVE_ADDENDA_FIELDS_FAILURE,
      ],
      promise: client => client.post(api.v1.ADDENDA_FIELDS, data),
    },
  }),

  saveCleaningCharges: (data: { cleaning_items: CleaningChargeItem[], remove_ids: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_CLEANING_CHARGES_REQUEST,
        ActionType.SAVE_CLEANING_CHARGES_SUCCESS,
        ActionType.SAVE_CLEANING_CHARGES_FAILURE,
      ],
      promise: client => client.post(api.v1.CLEANING_CHARGES, data),
    },
  }),

  saveDurationPricing: (data: DurationPricingProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_DURATION_PRICING_REQUEST,
        ActionType.SAVE_DURATION_PRICING_SUCCESS,
        ActionType.SAVE_DURATION_PRICING_FAILURE,
      ],
      promise: client => client.post(api.v1.DURATION_PRICING, data),
      successCB,
    },
  }),

  saveLeaseDefault: (data: LeaseDefaultProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_LEASE_DEFAULT_REQUEST,
        ActionType.SAVE_LEASE_DEFAULT_SUCCESS,
        ActionType.SAVE_LEASE_DEFAULT_FAILURE,
      ],
      promise: client => client.post(api.v1.LEASE_DEFAULTS, data),
      successCB,
    },
  }),

  savePropertyPolicy: (data: PropertyPolicyProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_PROPERTY_POLICY_REQUEST,
        ActionType.SAVE_PROPERTY_POLICY_SUCCESS,
        ActionType.SAVE_PROPERTY_POLICY_FAILURE,
      ],
      promise: client => client.post(api.v1.PROPERTY_POLICIES, data),
      successCB,
    },
  }),

  saveUtilityPolicy: (data: UtilityPoliciesProps): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_UTILITY_POLICY_REQUEST,
        ActionType.SAVE_UTILITY_POLICY_SUCCESS,
        ActionType.SAVE_UTILITY_POLICY_FAILURE,
      ],
      promise: client => client.post(api.v1.UTILITY_POLICIES, data),
    },
  }),

  updateOtherFeeItem: (payload: OtherFeeItemTemplateProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST,
        ActionType.UPDATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS,
        ActionType.UPDATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
      ],
      promise: client => client.put(build(api.v1.OTHER_FEE_ITEM_TEMPLATE_DETAILS, payload.id), payload),
    },
  }),

  updateOtherRentItem: (payload: OtherRentItemTemplateProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST,
        ActionType.UPDATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS,
        ActionType.UPDATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
      ],
      promise: client => client.put(build(api.v1.OTHER_RENT_ITEM_TEMPLATE_DETAILS, payload.id), payload),
    },
  }),

  updateRentableItem: (id: number, params: RentableItemProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_RENTABLE_ITEM_REQUEST,
        ActionType.UPDATE_RENTABLE_ITEM_SUCCESS,
        ActionType.UPDATE_RENTABLE_ITEM_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.RENTABLE_ITEM_DETAILS, id), params),
      successCB,
    },
  }),
};
