import produce from 'immer';
import { Action, ActionType, CallScoringQuestionsProps } from './action-types';
import { CommonStateType } from '../types';

export interface CallScoringQuestionsState extends CommonStateType {
  isSubmitting: boolean;
  errorMessage: string;
  questions: CallScoringQuestionsProps[];
  areQuestionsLoaded: boolean;
}

export const initialState: CallScoringQuestionsState = {
  isSubmitting: false,
  errorMessage: null,
  questions: [],
  areQuestionsLoaded: false,
};

export default produce((state: CallScoringQuestionsState = initialState, action: Action): CallScoringQuestionsState => {
  switch (action.type) {
    case ActionType.GET_CALL_SCORING_QUESTIONS_REQUEST:
      state.isSubmitting = true;
      state.areQuestionsLoaded = false;
      break;
    case ActionType.GET_CALL_SCORING_QUESTIONS_SUCCESS:
      state.isSubmitting = false;
      state.questions = action.result.data.results;
      state.areQuestionsLoaded = true;
      break;
    case ActionType.GET_CALL_SCORING_QUESTIONS_FAILURE:
      state.isSubmitting = false;
      state.areQuestionsLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;
  }

  return state;
});
