import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { PropertyProps } from '../property/action-types';

export enum ActionType {
  GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE',
  UPDATE_USER_AVAILABLE_STATUS_REQUEST = 'UPDATE_USER_AVAILABLE_STATUS_REQUEST',
  UPDATE_USER_AVAILABLE_STATUS_SUCCESS = 'UPDATE_USER_AVAILABLE_STATUS_SUCCESS',
  UPDATE_USER_AVAILABLE_STATUS_FAILURE = 'UPDATE_USER_AVAILABLE_STATUS_FAILURE',
  UPDATE_USER_LAST_PROPERTY_REQUEST = 'UPDATE_USER_LAST_PROPERTY_REQUEST',
  UPDATE_USER_LAST_PROPERTY_SUCCESS = 'UPDATE_USER_LAST_PROPERTY_SUCCESS',
  UPDATE_USER_LAST_PROPERTY_FAILURE = 'UPDATE_USER_LAST_PROPERTY_FAILURE',
  UPDATE_USER_ACCESSED_PROPERTIES_REQUEST = 'UPDATE_USER_ACCESSED_PROPERTIES_REQUEST',
  UPDATE_USER_ACCESSED_PROPERTIES_SUCCESS = 'UPDATE_USER_ACCESSED_PROPERTIES_SUCCESS',
  UPDATE_USER_ACCESSED_PROPERTIES_FAILURE = 'UPDATE_USER_ACCESSED_PROPERTIES_FAILURE',
  GET_USERS_REQUEST = 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',
  GET_TEAM_MATES_REQUEST = 'GET_TEAM_MATES_REQUEST',
  GET_TEAM_MATES_SUCCESS = 'GET_TEAM_MATES_SUCCESS',
  GET_TEAM_MATES_FAILURE = 'GET_TEAM_MATES_FAILURE',
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE = 'CREATE_USER_FAILURE',
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  GET_PROPERTY_USERS_REQUEST = 'GET_PROPERTY_USERS_REQUEST',
  GET_PROPERTY_USERS_SUCCESS = 'GET_PROPERTY_USERS_SUCCESS',
  GET_PROPERTY_USERS_FAILURE = 'GET_PROPERTY_USERS_FAILURE',
  DO_NOT_DISTURB_REQUEST = 'DO_NOT_DISTURB_REQUEST',
  DO_NOT_DISTURB_SUCCESS = 'DO_NOT_DISTURB_SUCCESS',
  DO_NOT_DISTURB_FAILURE = 'DO_NOT_DISTURB_FAILURE',
  CLEAR_DO_NOT_DISTURB_REQUEST = 'CLEAR_DO_NOT_DISTURB_REQUEST',
  CLEAR_DO_NOT_DISTURB_SUCCESS = 'CLEAR_DO_NOT_DISTURB_SUCCESS',
  CLEAR_DO_NOT_DISTURB_FAILURE = 'CLEAR_DO_NOT_DISTURB_FAILURE',
  SET_UNAVAILABLE_MANUALLY = 'SET_UNAVAILABLE_MANUALLY',
  UPDATE_CURRENT_CUSTOMER_REQUEST = 'UPDATE_CURRENT_CUSTOMER_REQUEST',
  UPDATE_CURRENT_CUSTOMER_SUCCESS = 'UPDATE_CURRENT_CUSTOMER_SUCCESS',
  UPDATE_CURRENT_CUSTOMER_FAILURE = 'UPDATE_CURRENT_CUSTOMER_FAILURE',
  GET_USER_PROPERTIES_CALL_ROUTING_REQUEST = 'GET_USER_PROPERTIES_CALL_ROUTING_REQUEST',
  GET_USER_PROPERTIES_CALL_ROUTING_SUCCESS = 'GET_USER_PROPERTIES_CALL_ROUTING_SUCCESS',
  GET_USER_PROPERTIES_CALL_ROUTING_FAILURE = 'GET_USER_PROPERTIES_CALL_ROUTING_FAILURE',
  UPDATE_USER_PROPERTIES_CALL_ROUTING_REQUEST = 'UPDATE_USER_PROPERTIES_CALL_ROUTING_REQUEST',
  UPDATE_USER_PROPERTIES_CALL_ROUTING_SUCCESS = 'UPDATE_USER_PROPERTIES_CALL_ROUTING_SUCCESS',
  UPDATE_USER_PROPERTIES_CALL_ROUTING_FAILURE = 'UPDATE_USER_PROPERTIES_CALL_ROUTING_FAILURE',
}

export interface DoNotDisturbProps {
  start_datetime: string;
  end_datetime: string;
  until_further_notice: boolean;
  lapse: string;
}

export enum AgentType {
  OFFICE = 'On-Site',
  VIRTUAL = 'Virtual',
}
export interface Agent {
  id: number;
  name?: string;
  role: string;
  accept_prospect_calls: boolean;
  accept_resident_calls: boolean;
  phone_number?: string;
  on_site_agents_name?: string[]
}

export enum HideAgentType {
  ALL = 'all',
  SINGLE = 'single',
}

export type CallRoutings = { [key: number]: CallRouting };
export type AllCallRoutings = {
  call_routings: CallRoutings,
  hidden_call_routings: CallRoutings,
  hidden_type?: HideAgentType,
  hide?: boolean
};

interface AgentCallRoutingPropertyDetail {
  property_id: number;
  property_name: string;
  value: CallRoutingOption;
}

interface AgentCallRoutingDay {
  [propertyId: number]: AgentCallRoutingPropertyDetail;
}

export interface WeeklyAgentCallRouting {
  monday: AgentCallRoutingDay;
  tuesday: AgentCallRoutingDay;
  wednesday: AgentCallRoutingDay;
  thursday: AgentCallRoutingDay;
  friday: AgentCallRoutingDay;
  saturday: AgentCallRoutingDay;
  sunday: AgentCallRoutingDay;
}

export enum CallRoutingOption {
  PROSPECTS = 'prospects',
  RESIDENTS = 'residents',
  BOTH = 'both',
  NO_CALLERS = 'no_callers',
}

export interface AgentCallRouting {
  id: number;
  name: string;
  phone_number: string;
  call_routing: CallRoutingOption[];
}

export interface CallRoutingAgents {
  [key: number]: AgentCallRouting;
}

export interface PropertyCallrouting {
  id: number;
  name: string;
}
export interface CallRouting extends PropertyCallrouting{
  agents: CallRoutingAgents;
}

export interface CallRoutingPayload {
  id: number;
  agents: Agent[]
}

export interface UserProps {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  password?: string;
  passwordConfirm?: string;
  email?: string;
  status?: string;
  role?: string;
  clients?: number[];
  properties?: number[];
  client?: string;
  customer?: string;
  customer_admin?: string;
  customer_name?: string;
  avatar?: string;
  is_super_customer?: boolean;
  has_advanced_reports_access?: boolean;
  is_call_scorer?: boolean;
  is_chat_reviewer?: boolean;
  is_team_account?: boolean;
  last_property?: number;
  accessed_properties?: PropertyProps[];
  is_available?: boolean;
  has_property_profile_access?: boolean;
  is_agent_chat_available_today?: boolean;
  property_to_get_questions?: { id: number; name: string };
  do_not_disturb?: DoNotDisturbProps;
  support_portal_enabled?: boolean;
  current_customer?: number;
  browser_phone?: string;
  desktop_phone?: string;
  ai_noise_suppression_enabled?: boolean;
  corporate_site_enabled?: boolean;
  has_voice_setup?: boolean;
}

export interface GetCurrentUserAction {
  type: ActionType.GET_CURRENT_USER_REQUEST | ActionType.GET_CURRENT_USER_SUCCESS | ActionType.GET_CURRENT_USER_FAILURE;

  result: {
    data: UserProps;
  };
  error: {
    response: { status: string };
  };
}

export interface GetUserPropertiesCallRouting {
  type: ActionType.GET_USER_PROPERTIES_CALL_ROUTING_REQUEST | ActionType.GET_USER_PROPERTIES_CALL_ROUTING_SUCCESS | ActionType.GET_USER_PROPERTIES_CALL_ROUTING_FAILURE;

  result: {
    data: AllCallRoutings;
  };
  error: {
    response: { status: string };
  };
}

export interface UpdateUserPropertiesCallRouting {
  type: ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_REQUEST | ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_SUCCESS | ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_FAILURE;

  result: {
    data: AllCallRoutings;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateUserAction {
  type: ActionType.UPDATE_USER_REQUEST | ActionType.UPDATE_USER_SUCCESS | ActionType.UPDATE_USER_FAILURE;

  result: {
    data: UserProps;
  };
  error: {
    response: { status: string };
  };
}
export interface UpdateUserAvailableStatusAction {
  type: ActionType.UPDATE_USER_AVAILABLE_STATUS_REQUEST | ActionType.UPDATE_USER_AVAILABLE_STATUS_SUCCESS | ActionType.UPDATE_USER_AVAILABLE_STATUS_FAILURE;

  result: {
    data: UserProps;
  };
  error: {
    response: { status: string };
  };
}
interface UpdateUserLastPropertyAction {
  type: ActionType.UPDATE_USER_LAST_PROPERTY_REQUEST | ActionType.UPDATE_USER_LAST_PROPERTY_SUCCESS | ActionType.UPDATE_USER_LAST_PROPERTY_FAILURE;

  result: {
    data: UserProps;
  };
  error: {
    response: { status: string };
  };
}
interface UpdateUserAccesedPropertiesAction {
  type: ActionType.UPDATE_USER_ACCESSED_PROPERTIES_REQUEST | ActionType.UPDATE_USER_ACCESSED_PROPERTIES_SUCCESS | ActionType.UPDATE_USER_ACCESSED_PROPERTIES_FAILURE;

  result: {
    data: PropertyProps[];
  };
  error: {
    response: { status: string };
  };
}
interface GetUsersAction {
  type: ActionType.GET_USERS_REQUEST | ActionType.GET_USERS_SUCCESS | ActionType.GET_USERS_FAILURE;

  result: {
    data: { results: UserProps[]; count: number; count_all: number; count_active: number; count_inactive: number };
  };
  error: {
    response: { status: string };
  };
}
interface GetTeamMatesAction {
  type: ActionType.GET_TEAM_MATES_REQUEST | ActionType.GET_TEAM_MATES_SUCCESS | ActionType.GET_TEAM_MATES_FAILURE;

  result: {
    data: UserProps[];
  };
  error: {
    response: { status: string };
  };
}
interface CreateUserAction {
  type: ActionType.CREATE_USER_REQUEST | ActionType.CREATE_USER_SUCCESS | ActionType.CREATE_USER_FAILURE;

  result: {
    data: UserProps;
  };
  error: {
    response: { status: string };
  };
}
interface DeleteUserAction {
  type: ActionType.DELETE_USER_REQUEST | ActionType.DELETE_USER_SUCCESS | ActionType.DELETE_USER_FAILURE;

  result: {
    data: UserProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertyUsersAction {
  type: ActionType.GET_PROPERTY_USERS_REQUEST | ActionType.GET_PROPERTY_USERS_SUCCESS | ActionType.GET_PROPERTY_USERS_FAILURE;

  result: {
    data: { results: UserProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface DoNotDisturbAction {
  type: ActionType.DO_NOT_DISTURB_REQUEST | ActionType.DO_NOT_DISTURB_SUCCESS | ActionType.DO_NOT_DISTURB_FAILURE;
  result: {
    data: DoNotDisturbProps;
  };
  error: {
    response: { status: string };
  }
}

interface ClearDoNotDisturbAction {
  type: ActionType.CLEAR_DO_NOT_DISTURB_REQUEST | ActionType.CLEAR_DO_NOT_DISTURB_SUCCESS | ActionType.CLEAR_DO_NOT_DISTURB_FAILURE;
  error: {
    response: { status: string };
  }
}

interface SetUnavailableManuallyAction {
  type: ActionType.SET_UNAVAILABLE_MANUALLY;
  isUnAvailableManually: boolean;
}

interface UpdateUserSelectedCustomerAction {
  type: ActionType.UPDATE_CURRENT_CUSTOMER_REQUEST | ActionType.UPDATE_CURRENT_CUSTOMER_SUCCESS | ActionType.UPDATE_CURRENT_CUSTOMER_FAILURE;

  result: {
    data: UserProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetCurrentUserAction
  | UpdateUserAction
  | UpdateUserAvailableStatusAction
  | UpdateUserLastPropertyAction
  | UpdateUserAccesedPropertiesAction
  | GetUsersAction
  | GetTeamMatesAction
  | CreateUserAction
  | DeleteUserAction
  | PusherAction
  | GetPropertyUsersAction
  | SetUnavailableManuallyAction
  | DoNotDisturbAction
  | ClearDoNotDisturbAction
  | UpdateUserSelectedCustomerAction
  | GetUserPropertiesCallRouting
  | UpdateUserPropertiesCallRouting;
