import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';

export enum ActionType {
  GET_LEADS_FILTER_REQUEST = 'GET_LEADS_FILTER_REQUEST',
  GET_LEADS_FILTER_SUCCESS = 'GET_LEADS_FILTER_SUCCESS',
  GET_LEADS_FILTER_FAILURE = 'GET_LEADS_FILTER_FAILURE',

  CREATE_LEADS_FILTER_REQUEST = 'CREATE_LEADS_FILTER_REQUEST',
  CREATE_LEADS_FILTER_SUCCESS = 'CREATE_LEADS_FILTER_SUCCESS',
  CREATE_LEADS_FILTER_FAILURE = 'CREATE_LEADS_FILTER_FAILURE',

  GET_LEADS_FILTER_BY_ID_REQUEST = 'GET_LEADS_FILTER_BY_ID_REQUEST',
  GET_LEADS_FILTER_BY_ID_SUCCESS = 'GET_LEADS_FILTER_BY_ID_SUCCESS',
  GET_LEADS_FILTER_BY_ID_FAILURE = 'GET_LEADS_FILTER_BY_ID_FAILURE',

  UPDATE_LEADS_FILTER_REQUEST = 'UPDATE_LEADS_FILTER_REQUEST',
  UPDATE_LEADS_FILTER_SUCCESS = 'UPDATE_LEADS_FILTER_SUCCESS',
  UPDATE_LEADS_FILTER_FAILURE = 'UPDATE_LEADS_FILTER_FAILURE',

  DELETE_LEADS_FILTER_REQUEST = 'DELETE_LEADS_FILTER_REQUEST',
  DELETE_LEADS_FILTER_SUCCESS = 'DELETE_LEADS_FILTER_SUCCESS',
  DELETE_LEADS_FILTER_FAILURE = 'DELETE_LEADS_FILTER_FAILURE',

  GET_ACTIVE_FILTER_REQUEST = 'GET_ACTIVE_FILTER_REQUEST',
  GET_ACTIVE_FILTER_SUCCESS = 'GET_ACTIVE_FILTER_SUCCESS',
  GET_ACTIVE_FILTER_FAILURE = 'GET_ACTIVE_FILTER_FAILURE',

  SET_ACTIVE_FILTER_REQUEST = 'SET_ACTIVE_FILTER_REQUEST',
  SET_ACTIVE_FILTER_SUCCESS = 'SET_ACTIVE_FILTER_SUCCESS',
  SET_ACTIVE_FILTER_FAILURE = 'SET_ACTIVE_FILTER_FAILURE',

  SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER',
}

export interface LeadFilterProps {
  id: number | string;
  name: string;
}

interface GetLeadsFilterAction {
  type: ActionType.GET_LEADS_FILTER_REQUEST | ActionType.GET_LEADS_FILTER_SUCCESS | ActionType.GET_LEADS_FILTER_FAILURE;

  result: {
    data: { results: LeadFilterProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface CreateLeadsFilterAction {
  type: ActionType.CREATE_LEADS_FILTER_REQUEST | ActionType.CREATE_LEADS_FILTER_SUCCESS | ActionType.CREATE_LEADS_FILTER_FAILURE;

  result: {
    data: LeadFilterProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetLeadsFilterByIdAction {
  type: ActionType.GET_LEADS_FILTER_BY_ID_REQUEST | ActionType.GET_LEADS_FILTER_BY_ID_SUCCESS | ActionType.GET_LEADS_FILTER_BY_ID_FAILURE;

  result: {
    data: LeadFilterProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateLeadsFilterAction {
  type: ActionType.UPDATE_LEADS_FILTER_REQUEST | ActionType.UPDATE_LEADS_FILTER_SUCCESS | ActionType.UPDATE_LEADS_FILTER_FAILURE;

  result: {
    data: LeadFilterProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteLeadsFilterAction {
  type: ActionType.DELETE_LEADS_FILTER_REQUEST | ActionType.DELETE_LEADS_FILTER_SUCCESS | ActionType.DELETE_LEADS_FILTER_FAILURE;

  result: {
    data: LeadFilterProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetActiveFilterAction {
  type: ActionType.GET_ACTIVE_FILTER_REQUEST | ActionType.GET_ACTIVE_FILTER_SUCCESS | ActionType.GET_ACTIVE_FILTER_FAILURE;

  result: {
    data: string;
  };
  error: {
    response: { status: string };
  };
}

interface SetActiveFilterAction {
  type: ActionType.SET_ACTIVE_FILTER_REQUEST | ActionType.SET_ACTIVE_FILTER_SUCCESS | ActionType.SET_ACTIVE_FILTER_FAILURE;

  result: {
    data: string;
  };
  error: {
    response: { status: string };
  };
}

interface SetActiveFilterLocallyAction {
  type: ActionType.SET_ACTIVE_FILTER;
  filter: string;
}

export type Action =
  | PromiseActionType
  | GetLeadsFilterAction
  | CreateLeadsFilterAction
  | GetLeadsFilterByIdAction
  | UpdateLeadsFilterAction
  | DeleteLeadsFilterAction
  | GetActiveFilterAction
  | SetActiveFilterAction
  | SetActiveFilterLocallyAction
  | PusherAction;
