import produce from 'immer';
import { unionBy } from 'lodash';
import { ActionType as LogoutActions } from 'dwell/store/authentication/action-types';
import { PropertyState } from 'dwell/store/property/reducers';
import { Action, ActionType, FocusBarItem, FocusBarState } from './action-types';

const initialState: FocusBarState = {
  isLoaded: true,
  from: '',
  index: 0,
  items: [],
  leadQuery: {},
  title: '',
};

const localStorageKey = '__FOCUS_BAR__';

const getIndex = (items: FocusBarItem[], leadId: number): number => {
  const index = items.findIndex(i => i.lead === leadId);
  return index > 0 ? index : 0;
};

const loadData = (): FocusBarState => {
  const state = localStorage.getItem(localStorageKey);
  if (state) return JSON.parse(state);
  return initialState;
};

const storeData = (state: FocusBarState) => localStorage.setItem(localStorageKey, JSON.stringify(state));

export const selectFocusBarData = (state: { focusBar: FocusBarState }): FocusBarState => state.focusBar;
export const selectShowFocusBar = (state: { focusBar: FocusBarState, property: PropertyState }): boolean =>
  /\/leads\/\d+\//.test(window.location.pathname) &&
    state.focusBar.items?.length > 0 &&
    state.focusBar.title?.length > 0 &&
    state.property.property.task_workflow === 'CATEGORY';

export default produce((state: FocusBarState = initialState, action: Action): FocusBarState => {
  switch (action.type) {
    case ActionType.GET_FOCUS_BAR_LEADS_REQUEST:
      state.isLoaded = Boolean(action.payload.append);
      localStorage.removeItem(localStorageKey);
      break;
    case ActionType.GET_FOCUS_BAR_LEADS_SUCCESS:
      state.from = action.payload.from;
      state.isLoaded = true;
      state.items = action.payload.append
        ? unionBy(state.items, action.result.data, 'lead')
        : action.result.data;
      if (!action.payload.append) state.index = getIndex(state.items, action.payload.leadId);
      state.leadQuery = action.result?.config
        ? { ...action.result.config.params, leadId: action.payload.leadId }
        : { leadId: action.payload.leadId };
      state.title = action.payload.title;
      storeData(state);
      break;
    case ActionType.GET_FOCUS_BAR_LEADS_FAILURE:
      state.from = action.payload.from;
      state.isLoaded = true;
      state.items = action.payload.append
        ? unionBy(state.items, action.payload.backupLeads, 'leads')
        : action.payload.backupLeads;
      if (!action.payload.append) state.index = getIndex(state.items, action.payload.leadId);
      state.leadQuery = action.result?.config
        ? { ...action.result.config.params, leadId: action.payload.leadId }
        : { leadId: action.payload.leadId };
      state.title = action.payload.title;
      storeData(state);
      break;
    case ActionType.RELOAD_FOCUS_BAR_DATA:
      state = loadData();
      break;
    case ActionType.RESET_FOCUS_BAR:
    case LogoutActions.LOGIN_SUCCESS:
    case LogoutActions.LOGOUT:
    case LogoutActions.SESSION_TIMEOUT:
      state = initialState;
      localStorage.removeItem(localStorageKey);
      break;
    case ActionType.SET_FOCUS_BAR_INDEX:
      state.index = action.index;
      storeData(state);
      break;
    case ActionType.SET_FOCUS_BAR_ITEMS:
      state.from = action.from;
      state.index = action.index;
      state.items = action.items;
      state.leadQuery = {};
      state.title = action.title;
      storeData(state);
      break;
  }
  return state;
});
