import React, { FC, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, FormGroup, FormText, Input, Label } from 'reactstrap';
import { Controller } from 'react-hook-form';
import cn from 'classnames';
import 'src/scss/pages/_reports.scss';
import {
  PortfolioTabContent,
  PortfolioTabItem,
  PortfolioTabLink,
  PortfolioTabs,
} from 'dwell/views/reports/styles';
import { SearchedObjectType } from 'dwell/store/pipeline/action-types';
import TabContent from './_object_selector_tab_content';
import { DropdownToggle, FilterDropdownMenu, SearchContainer } from '../styles';
import { InputProps } from '../utils';

const ObjectSelector: FC<InputProps> = ({ control, setValue, task, watch, disableObjectSelection }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('individual');
  const [keyword, setKeyword] = useState('');
  const { leads, leases, residents, applications } = useSelector(state => state.pipeline.searchedObjects) as SearchedObjectType;
  const isLoaded = useSelector(state => state.pipeline.isLoaded);
  const currentLead = useSelector(state => state.lead.lead);
  const currentApplication = useSelector(state => state.application.applicationDetails);
  const currentLease = useSelector(state => state.leaseDetails.leaseDetails);

  const object = watch('object');
  const toggleTitle = useMemo(() => {
    if (object?.objectType === 'lead') {
      if (currentLead && currentLead.id === object?.id && currentLead.first_name) {
        return `Prospect - ${currentLead.first_name} ${currentLead.last_name}`;
      }
      return `Prospect - ${leads.find(item => item.id === object?.id)?.name}`;
    }
    if (object?.objectType === 'lease') {
      if (currentLease && currentLease.id === object?.id) {
        return `Lease #${currentLease.id} - Unit ${currentLease.name || 'not assigned yet'}`;
      }
      const findedLease = leases.find(item => item.id === object?.id);
      return `Lease #${findedLease?.id} - Unit ${findedLease?.name || 'not assigned yet'}`;
    }
    if (object?.objectType === 'application') {
      if (currentApplication && currentApplication.id === object?.id) {
        return `Application #${currentApplication.id} - Unit ${currentApplication.name || 'not assigned yet'}`;
      }
      const findedApplication = applications.find(item => item.id === object?.id);
      return `Application #${findedApplication.id} - Unit ${findedApplication?.name || 'not assigned yet'}`;
    }
    if (object?.objectType === 'leasing_user') {
      const r = residents.find(item => item.id === object?.id);
      if (r) return `Resident - ${r.name}`;
      const a = [].find(item => item.id === object?.id);
      if (a) return `Applicant - ${r.name}`;
    }
    if (!isLoaded) return 'Loading...';
    return 'Choose one';
  }, [object, leads, leases, applications, currentLead, currentApplication, currentLease]);

  useEffect(() => {
    setKeyword('');
  }, [dropdownOpen]);

  useEffect(() => {
    let o = null as { id: number, objectType: string };
    if (task.lead) o = { id: task.lead, objectType: 'lead' };
    if (task.lease) o = { id: task.lease, objectType: 'lease' };
    if (task.leasing_user) o = { id: task.leasing_user, objectType: 'leasing_user' };
    if (task.application) o = { id: task.application, objectType: 'application' };
    setValue('object', o);
    if (['lease', 'application'].includes(o?.objectType)) {
      setActiveTab('group');
    } else {
      setActiveTab('individual');
    }
  }, [task.lease, task.lead, task.leasing_user, task.application]);

  const handleClick = (item, objectType) => {
    setValue('object', { ...item, objectType });
    setDropdownOpen(false);
  };

  return (
    <FormGroup>
      <Label htmlFor="object">Linked to:</Label>
      <Controller
        control={control}
        name="object"
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <div>
            <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
              <DropdownToggle $active={dropdownOpen} disabled={disableObjectSelection || !isLoaded} id="object" name="object">
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className={cn({ 'default-value': !object?.id })}>{toggleTitle}</span>
                <div className="indicator-container">
                  <span className="indicator-container-divider" />
                  <div aria-hidden="true" className="indicator-icon-container">
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg">
                      {/* eslint-disable-next-line max-len */}
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                    </svg>
                  </div>
                </div>
              </DropdownToggle>
              <FilterDropdownMenu>
                <div>
                  <PortfolioTabs tabs>
                    {['individual', 'group'].map((item, index) => (
                      <PortfolioTabItem key={index} className="col-6" active={activeTab === item}>
                        <PortfolioTabLink onClick={() => setActiveTab(item)}>{item.replace(/^\w/, c => c.toUpperCase())}</PortfolioTabLink>
                      </PortfolioTabItem>
                    ))}
                  </PortfolioTabs>
                  <PortfolioTabContent activeTab={activeTab}>
                    <SearchContainer>
                      <Input
                        type="text"
                        placeholder=" &#xF002;  Search"
                        style={{ fontFamily: 'Source Sans Pro, FontAwesome' }}
                        value={keyword}
                        onChange={e => setKeyword(e.target.value)}
                      />
                    </SearchContainer>
                    <TabContent
                      value={field.value || {}}
                      onClick={handleClick}
                      keyword={keyword}
                    />
                  </PortfolioTabContent>
                </div>
              </FilterDropdownMenu>
            </Dropdown>
            {error && <FormText color="danger">Please choose a object to be linked</FormText>}
          </div>
        )}
      />
    </FormGroup>
  );
};

export default ObjectSelector;
