export enum ActionType {
  GET_COMMUNITY_DETAILS_REQUEST = 'get_community_details_request',
  GET_COMMUNITY_DETAILS_SUCCESS = 'get_community_details_success',
  GET_COMMUNITY_DETAILS_FAILURE = 'get_community_details_failure',

  UPDATE_COMMUNITY_DETAILS_REQUEST = 'update_community_details_request',
  UPDATE_COMMUNITY_DETAILS_SUCCESS = 'update_community_details_success',
  UPDATE_COMMUNITY_DETAILS_FAILURE = 'update_community_details_failure',
}

export interface Amenity {
  id: number;
  name: string;
  icon?: string;
  description?: string;
  image?: string;
  customer: number;
}

export interface CommunityDetails {
  id?: number,
  cross_street?: string,
  description?: string,
  sister_communities?: number[],
  unit_count?: number,
  year_built?: number,
  parsed_unit_count?: number,
  amenities?: number[],
  phone?: string,
  country?: string,
  state?: string,
  city?: string,
  street?: string,
  postal_code?: string,
}

interface CommunityDetailsAction {
  type: ActionType;
  result: { data: CommunityDetails };
  error: { response: { status: string } };
}

export type Action = CommunityDetailsAction;
