import { CustomBlob } from 'src/interfaces';

export { default as DROPDOWN_MENU_OPTIONS } from './dropdown_options';
export { default as paths } from './paths';

export const getFileType = (file: File | CustomBlob): string => {
  let type = 'document';
  if (file.type.startsWith('image')) {
    type = 'image';
  } else if (file.type.startsWith('video')) {
    type = 'video';
  } else if (file.type.startsWith('audio')) {
    type = 'audio';
  }
  return type;
};

export const getImageName = (image: string): string => {
  if (!image) return '';
  const splitImage = image.split('/');
  return splitImage.length > 1 ? splitImage.pop().split('?')[0] : '';
};

export const getShortLabel = (label: string, maxLength = 30, invertTrim = false): string => {
  if (!label || label.length <= maxLength) return label;
  return !invertTrim ?
    `${label.substring(0, maxLength)}...`
    : `...${label.substring(label.length - maxLength, label.length)}`;
};
