import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, priceQuoteSelection, PriceQuote } from './action-types';

export default {
  getLeadPriceQuotes: (leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_REQUEST,
        ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_SUCCESS,
        ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.LEAD_LEVEL_PRICE_QUOTES, leadId)),
    },
  }),

  createPriceQuote: (data: PriceQuote, leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_PRICE_QUOTE_REQUEST,
        ActionType.CREATE_PRICE_QUOTE_SUCCESS,
        ActionType.CREATE_PRICE_QUOTE_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.LEAD_LEVEL_PRICE_QUOTES, leadId), data),
    },
  }),

  setProtoPriceQuote: (selection: priceQuoteSelection): Action => ({
    type: ActionType.SET_PROTO_PRICE_QUOTE,
    selection,
  }),

  updatePriceQuoteById: (id: number, params: PriceQuote, leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PRICE_QUOTE_REQUEST,
        ActionType.UPDATE_PRICE_QUOTE_SUCCESS,
        ActionType.UPDATE_PRICE_QUOTE_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.LEAD_LEVEL_PRICE_QUOTES_DETAILS, leadId, id), params),
    },
  }),

  clearCurrentPriceQuote: (): Action => ({
    type: ActionType.CLEAR_CURRENT_PRICE_QUOTE,
  }),
};
