import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_COMPARISON_LIST_REQUEST = 'GET_COMPARISON_LIST_REQUEST',
  GET_COMPARISON_LIST_SUCCESS = 'GET_COMPARISON_LIST_SUCCESS',
  GET_COMPARISON_LIST_FAILURE = 'GET_COMPARISON_LIST_FAILURE',
  GET_COMPARISON_BY_ID_REQUEST = 'GET_COMPARISON_BY_ID_REQUEST',
  GET_COMPARISON_BY_ID_SUCCESS = 'GET_COMPARISON_BY_ID_SUCCESS',
  GET_COMPARISON_BY_ID_FAILURE = 'GET_COMPARISON_BY_ID_FAILURE',
  CREATE_COMPARISON_REQUEST = 'CREATE_COMPARISON_REQUEST',
  CREATE_COMPARISON_SUCCESS = 'CREATE_COMPARISON_SUCCESS',
  CREATE_COMPARISON_FAILURE = 'CREATE_COMPARISON_FAILURE',
  GET_HIGHEST_RENT_REQUEST = 'GET_HIGHEST_RENT_REQUEST',
  GET_HIGHEST_RENT_SUCCESS = 'GET_HIGHEST_RENT_SUCCESS',
  GET_HIGHEST_RENT_FAILURE = 'GET_HIGHEST_RENT_FAILURE',
  GET_HIGHEST_OCCUPANCY_REQUEST = 'GET_HIGHEST_OCCUPANCY_REQUEST',
  GET_HIGHEST_OCCUPANCY_SUCCESS = 'GET_HIGHEST_OCCUPANCY_SUCCESS',
  GET_HIGHEST_OCCUPANCY_FAILURE = 'GET_HIGHEST_OCCUPANCY_FAILURE',
}

export interface HighestOccupancy {
  id: number;
  name?: string;
  rank?: number;
  occupancy?: number;
  units_count?: number;
}

export interface HighestRent {
  id: number;
  name?: string;
  rank?: number;
  average_rent?: number;
  average_rent_per_sqft?: number;
  units_count?: number;
}

export interface ComparisonReport {
  id?: number;
  subject_asset_name?: string;
  compared_asset_name?: string;
  subject_asset_type?: string;
  compared_asset_type?: string;
  market?: number;
  submarket?: number;
  subject_property?: number;
  compared_property?: number;
  subject_sub_market?: number;
  compared_sub_market?: number;
  competitor_property?: number;
}

interface GetComparisonListAction {
  type: ActionType.GET_COMPARISON_LIST_REQUEST | ActionType.GET_COMPARISON_LIST_SUCCESS | ActionType.GET_COMPARISON_LIST_FAILURE;

  result: {
    data: { results: ComparisonReport[] };
  };
  error: {
    response: { status: string };
  };
}

interface GetComparisonByIdAction {
  type: ActionType.GET_COMPARISON_BY_ID_REQUEST | ActionType.GET_COMPARISON_BY_ID_SUCCESS | ActionType.GET_COMPARISON_BY_ID_FAILURE;

  result: {
    data: ComparisonReport;
  };
  error: {
    response: { status: string };
  };
}

interface CreateComparisonAction {
  type: ActionType.CREATE_COMPARISON_REQUEST | ActionType.CREATE_COMPARISON_SUCCESS | ActionType.CREATE_COMPARISON_FAILURE;

  result: {
    data: ComparisonReport;
  };
  error: {
    response: { status: string };
  };
}

interface GetHighestAvgRentAction {
  type: ActionType.GET_HIGHEST_RENT_REQUEST | ActionType.GET_HIGHEST_RENT_SUCCESS | ActionType.GET_HIGHEST_RENT_FAILURE;

  result: {
    data: { results: HighestRent; count: number; subject_name: string; subject_rank: number; subject_type: string };
  };
  error: {
    response: { status: string };
  };
}

interface GetHighestOccupancyAction {
  type: ActionType.GET_HIGHEST_OCCUPANCY_REQUEST | ActionType.GET_HIGHEST_OCCUPANCY_SUCCESS | ActionType.GET_HIGHEST_OCCUPANCY_FAILURE;

  result: {
    data: { results: HighestRent; count: number; subject_name: string; subject_rank: number; subject_type: string };
  };
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetComparisonListAction | GetComparisonByIdAction | CreateComparisonAction | GetHighestAvgRentAction | GetHighestOccupancyAction;
