import { setAutoFreeze } from 'immer';
import { get } from 'lodash';

setAutoFreeze(false);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const preFillState = (state, action, allowed: string[]) => {
  if (!allowed.includes(action.type)) return;

  if (action.type.endsWith('REQUEST')) {
    if (action.method === 'GET') {
      state.isLoaded = false;
    } else if (action.method) {
      state.isSubmitting = true;
    }
  } else if (action.type.endsWith('SUCCESS')) {
    if (action.method === 'GET') {
      state.isLoaded = true;
    } else if (action.method) {
      state.isSubmitting = false;
    }
  } else if (action.type.endsWith('FAILURE')) {
    if (action.method === 'GET') {
      state.isLoaded = false;
    } else if (action.method) {
      state.isSubmitting = false;
    }
    state.errorMessage = get(action, 'error.response.status', '');
  }
};

export const isLeadPage = (): boolean => {
  const pathArray = window.location.pathname.split('/');
  return pathArray.length > 2 && pathArray[2] === 'leads';
};

export const isLeadsObject = (id: string): boolean => {
  const pathArray = window.location.pathname.split('/');
  if (!id || !isLeadPage()) return false;
  return pathArray[3] === id.toString();
};
