import { paths } from 'dwell/constants';
import { paths as sitePaths } from 'site/constants';
import { CallBackFunction, ManageRequestProps } from 'src/interfaces';
import { successCallback, failureCallback } from 'site/common';
import { ActionType, Action, PropertyProps } from './action-types';

export default {
  clearAllPropertiesScored: (): Action => ({
    type: ActionType.CLEAR_ALL_PROPERTIES_SCORED,
  }),

  clearPropertiesLazy: (): Action => ({
    type: ActionType.CLEAR_PROPERTY_LAZY,
  }),

  createProperty: (property: PropertyProps): Action => ({
    CALL_API: {
      types: [
        ActionType.PROPERTY_CREATE_REQUEST,
        ActionType.PROPERTY_CREATE_SUCCESS,
        ActionType.PROPERTY_CREATE_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.PROPERTY), property),
    },
  }),

  deleteProperty: (id: number, successCB: CallBackFunction = successCallback, failureCB: CallBackFunction = failureCallback): Action => ({
    CALL_API: {
      types: [
        ActionType.PROPERTY_DELETE_REQUEST,
        ActionType.PROPERTY_DELETE_SUCCESS,
        ActionType.PROPERTY_DELETE_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.PROPERTIES_ID, id)),
      successCB,
      failureCB,
    },
  }),

  getCurrentProperty: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CURRENT_PROPERTY_REQUEST,
        ActionType.GET_CURRENT_PROPERTY_SUCCESS,
        ActionType.GET_CURRENT_PROPERTY_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CURRENT_PROPERTY),
    },
  }),

  getPropertyDetails: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTY_DETAILS_REQUEST,
        ActionType.GET_PROPERTY_DETAILS_SUCCESS,
        ActionType.GET_PROPERTY_DETAILS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.PROPERTY_DETAILS, id)),
    },
  }),

  getProperties: (props: ManageRequestProps, paginated = false): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTY_REQUEST,
        ActionType.GET_PROPERTY_SUCCESS,
        ActionType.GET_PROPERTY_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROPERTY_BASE, { params: props }),
      payload: { paginated, page: props.page },
    },
  }),

  getPropertiesLazy: (props?: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTY_LAZY_REQUEST,
        ActionType.GET_PROPERTY_LAZY_SUCCESS,
        ActionType.GET_PROPERTY_LAZY_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROPERTY_LITE, { params: props }),
    },
  }),

  getPropertiesPagination: (props: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTY_PAGINATION_REQUEST,
        ActionType.GET_PROPERTY_PAGINATION_SUCCESS,
        ActionType.GET_PROPERTY_PAGINATION_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROPERTY, { params: props }),
    },
  }),

  getSelectedProperties: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SELECTED_PROPERTIES_REQUEST,
        ActionType.GET_SELECTED_PROPERTIES_SUCCESS,
        ActionType.GET_SELECTED_PROPERTIES_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.ACCESSED_PROPERTIES, id)),
    },
  }),

  getSelectedPropertiesFloorPlans: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_REQUEST,
        ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_SUCCESS,
        ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.ACCESSED_PROPERTIES_FLOOR_PLANS, id)),
    },
  }),

  setSelectedProperties: (properties: PropertyProps[]): Action => ({
    type: ActionType.SET_SELECTED_PROPERTIES,
    properties,
  }),

  setSelectedProperty: (property: PropertyProps): Action => ({
    type: ActionType.SET_SELECTED_PROPERTY,
    property,
  }),

  setSelectedPropertyXname: (property: PropertyProps): Action => ({
    type: ActionType.SET_SELECTED_PROPERTY_XNAME,
    property,
  }),

  setSelectedPropertySettings: (property: PropertyProps): Action => ({
    type: ActionType.SET_SELECTED_PROPERTY_SETTINGS,
    property,
  }),

  setPropertyAsScored: (id: number): Action => ({
    type: ActionType.SET_SCORED_PROPERTY,
    id: id.toString(),
  }),

  submitCallsScoreState: (): Action => ({
    CALL_API: {
      types: [
        ActionType.SUBMIT_CALLS_SCORE_STATE_REQUEST,
        ActionType.SUBMIT_CALLS_SCORE_STATE_SUCCESS,
        ActionType.SUBMIT_CALLS_SCORE_STATE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.PROPERTY_SCORE_STATE),
    },
  }),

  updateProperty: (id: number, property: PropertyProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PROPERTY_REQUEST,
        ActionType.UPDATE_PROPERTY_SUCCESS,
        ActionType.UPDATE_PROPERTY_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.PROPERTIES_ID, id), property),
    },
  }),

  updatePropertyNylasSettings: (id: number, data: PropertyProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_REQUEST,
        ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_SUCCESS,
        ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.PROPERTY_NYLAS_SYNC_SETTINGS_UPDATE, id), data),
      successCB,
    },
  }),

  updateTourOptions: (id: number, property: PropertyProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_TOUR_OPTIONS_REQUEST,
        ActionType.UPDATE_TOUR_OPTIONS_SUCCESS,
        ActionType.UPDATE_TOUR_OPTIONS_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.PROPERTIES_ID, id), property),
    },
  }),

  resetPropertyNylasSettings: (id: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.RESET_NYLAS_SETTINGS_REQUEST,
        ActionType.RESET_NYLAS_SETTINGS_SUCCESS,
        ActionType.RESET_NYLAS_SETTINGS_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.PROPERTY_RESET_NYLAS_SETTINGS, id)),
      successCB,
    },
  }),

  getCustomerProperties: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CUSTOMER_PROPERTIES_REQUEST,
        ActionType.GET_CUSTOMER_PROPERTIES_SUCCESS,
        ActionType.GET_CUSTOMER_PROPERTIES_FAILURE,
      ],
      promise: client => client.get(paths.build(sitePaths.api.v1.CUSTOMER_PROPERTIES, id)),
    },
  }),

  setPollingEmailSync: (status: boolean): Action => ({
    type: ActionType.SET_POLLING_EMAIL_SYNC,
    status,
  }),

  setIsTokenObtained: (status: boolean): Action => ({
    type: ActionType.SET_IS_TOKEN_OBTAINED,
    status,
  }),

  setIsTokenSetted: (status: boolean): Action => ({
    type: ActionType.SET_IS_TOKEN_SETTED,
    status,
  }),

  reloadProperties: (): Action => ({
    type: ActionType.RELOAD_PROPERTIES,
  }),

  updateCommunityDetailsAmenity: (propertyId: number, amenities: number[]): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_COMMUNITY_DETAILS_AMENITY_REQUEST,
        ActionType.UPDATE_COMMUNITY_DETAILS_AMENITY_SUCCESS,
        ActionType.UPDATE_COMMUNITY_DETAILS_AMENITY_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.COMMUNITY_DETAILS, propertyId), { amenities }),
    },
  }),
};
