import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_COMPETITORS_REQUEST = 'GET_COMPETITORS_REQUEST',
  GET_COMPETITORS_SUCCESS = 'GET_COMPETITORS_SUCCESS',
  GET_COMPETITORS_FAILURE = 'GET_COMPETITORS_FAILURE',

  UPDATE_COMPETITOR_REQUEST = 'UPDATE_COMPETITOR_REQUEST',
  UPDATE_COMPETITOR_SUCCESS = 'UPDATE_COMPETITOR_SUCCESS',
  UPDATE_COMPETITOR_FAILURE = 'UPDATE_COMPETITOR_FAILURE',

  CREATE_COMPETITOR_REQUEST = 'CREATE_COMPETITOR_REQUEST',
  CREATE_COMPETITOR_SUCCESS = 'CREATE_COMPETITOR_SUCCESS',
  CREATE_COMPETITOR_FAILURE = 'CREATE_COMPETITOR_FAILURE',

  DELETE_COMPETITOR_REQUEST = 'DELETE_COMPETITOR_REQUEST',
  DELETE_COMPETITOR_SUCCESS = 'DELETE_COMPETITOR_SUCCESS',
  DELETE_COMPETITOR_FAILURE = 'DELETE_COMPETITOR_FAILURE',
}

export interface CompetitorProps {
  id?: number;
  name?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  phone_number?: string;
  fax_number?: string;
}

interface GetCompetitorsAction {
  type: ActionType.GET_COMPETITORS_REQUEST | ActionType.GET_COMPETITORS_SUCCESS | ActionType.GET_COMPETITORS_FAILURE;

  result: {
    data: { results: CompetitorProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateCompetitorByIdAction {
  type: ActionType.UPDATE_COMPETITOR_REQUEST | ActionType.UPDATE_COMPETITOR_SUCCESS | ActionType.UPDATE_COMPETITOR_FAILURE;

  result: {
    data: CompetitorProps;
  };
  error: {
    response: { status: string };
  };
}

interface CreateCompetitorAction {
  type: ActionType.CREATE_COMPETITOR_REQUEST | ActionType.CREATE_COMPETITOR_SUCCESS | ActionType.CREATE_COMPETITOR_FAILURE;

  result: {
    data: CompetitorProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteCompetitorRateByIdAction {
  type: ActionType.DELETE_COMPETITOR_REQUEST | ActionType.DELETE_COMPETITOR_SUCCESS | ActionType.DELETE_COMPETITOR_FAILURE;

  result: {
    data: CompetitorProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetCompetitorsAction
  | UpdateCompetitorByIdAction
  | CreateCompetitorAction
  | DeleteCompetitorRateByIdAction;
