import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_PAYMENT_RECEIVED_REQUEST = 'CREATE_PAYMENT_RECEIVED_REQUEST',
  CREATE_PAYMENT_RECEIVED_SUCCESS = 'CREATE_PAYMENT_RECEIVED_SUCCESS',
  CREATE_PAYMENT_RECEIVED_FAILURE = 'CREATE_PAYMENT_RECEIVED_FAILURE',

  DELETE_PAYMENT_RECEIVED_REQUEST = 'DELETE_PAYMENT_RECEIVED_REQUEST',
  DELETE_PAYMENT_RECEIVED_SUCCESS = 'DELETE_PAYMENT_RECEIVED_SUCCESS',
  DELETE_PAYMENT_RECEIVED_FAILURE = 'DELETE_PAYMENT_RECEIVED_FAILURE',

  UPDATE_PAYMENT_RECEIVED_REQUEST = 'UPDATE_PAYMENT_RECEIVED_REQUEST',
  UPDATE_PAYMENT_RECEIVED_SUCCESS = 'UPDATE_PAYMENT_RECEIVED_SUCCESS',
  UPDATE_PAYMENT_RECEIVED_FAILURE = 'UPDATE_PAYMENT_RECEIVED_FAILURE',
}

export interface PaymentReceivedState extends CommonStateType {
  payment: PaymentReceivedProps
}

export interface PaymentReceivedProps {
  id: number,
  amount_already_paid: string,
  description: string,
  paid_on: string,
  payment_received_method: string,
}

/*
 * Actions
 */

interface CreatePaymentAction extends CommonActionResponse {
  type: ActionType.CREATE_PAYMENT_RECEIVED_REQUEST
  | ActionType.CREATE_PAYMENT_RECEIVED_SUCCESS
  | ActionType.CREATE_PAYMENT_RECEIVED_FAILURE;
  result: { data: PaymentReceivedProps };
}

interface DeletePaymentAction extends CommonActionResponse {
  type: ActionType.DELETE_PAYMENT_RECEIVED_REQUEST
  | ActionType.DELETE_PAYMENT_RECEIVED_SUCCESS
  | ActionType.DELETE_PAYMENT_RECEIVED_FAILURE;
  result: { data: PaymentReceivedProps };
}

interface UpdatePaymentAction extends CommonActionResponse {
  type: ActionType.UPDATE_PAYMENT_RECEIVED_REQUEST
  | ActionType.UPDATE_PAYMENT_RECEIVED_SUCCESS
  | ActionType.UPDATE_PAYMENT_RECEIVED_FAILURE;
  result: { data: PaymentReceivedProps };
}

export type Action =
  PromiseActionType
  | CreatePaymentAction
  | DeletePaymentAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdatePaymentAction;
