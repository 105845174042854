import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action } from './action-types';

export default {
  createIdleTimeEvent: (userId: number, start: string): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_IDLE_EVENT_REQUEST,
        ActionType.CREATE_IDLE_EVENT_SUCCESS,
        ActionType.CREATE_IDLE_EVENT_FAILURE,
      ],
      payload: { start },
      promise: client => client.post(build(paths.api.v1.USER_IDLE_TIME, userId), { start }),
    },
  }),

  updateIdleTimeEvent: (userId: number, eventId: number, end: string): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_IDLE_EVENT_REQUEST,
        ActionType.CREATE_IDLE_EVENT_SUCCESS,
        ActionType.UPDATE_IDLE_EVENT_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.USER_IDLE_TIME, userId), { id: eventId, end }),
    },
  }),

  updateUserActivity: (userId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_USER_ACTIVITY_REQUEST,
        ActionType.UPDATE_USER_ACTIVITY_SUCCESS,
        ActionType.UPDATE_USER_ACTIVITY_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.USER_ACTIVITY, userId)),
    },
  }),
};
