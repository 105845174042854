import styled, { css } from 'styled-components';
import { Avatar, SimpleButton } from 'styles/common';
import { shadowSharp } from 'styles/mixins';
import { hexToRgb } from 'dwell/constants';
import { Dropdown, DropdownItem } from 'reactstrap';

export const CallItem = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.colorbd02};
  box-shadow: 0px 4px 24px rgba(11, 33, 81, 0.12);
  width: 280px;
  border-top-right-radius: 4px;

  &::before {
    content: 'Call';
    position: absolute;
    top: -19.5px;
    left: -1px;
    padding: 1px 7px;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.colorbd02};
    border-bottom-width: 0;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.colors.darkgray};
    letter-spacing: .5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

export const CallItemHeader = styled.div`
  .avatar { margin-right: 10px; }

  .dropdown-link {
    color: ${props => props.theme.colors.gray};
    font-size: 18px;
  }

  .dropdown-menu {
    border-width: 0;
    padding: 5px;
    @include shadow-dropdown();
  }

  .dropdown-item {
    padding: 5px 8px;
    color: ${props => props.theme.colors.bodyColor};
    display: flex;
    align-items: center;

    i {
      font-size: 18px;
      line-height: 1;
      margin-right: 5px;
    }
  }

  .btn-call {
    margin: 0 10px 10px;
    align-items: center;
    justify-content: center;
    border-width: 1.5px;
    height: 38px;

    &.disabled {
      border-color: $gray-400;
      color: $gray-500;
      cursor: default;
    }

    i {
      font-size: 24px;
      transform: rotate(135deg);
      margin-top: 2px;
    }
  }
`;

export const CallerInfoContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

export const CallerInfoBody = styled.div`
  flex: 1;
  h6 {
    margin-bottom: 1px;
    font-weight: 600;
    color: ${props => props.theme.colors.darkgray};
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: ${props => props.theme.colors.gray};
    display: flex;
    align-items: center;

    span + span::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: ${props => props.theme.colors.gray};
      border-radius: 100%;
      margin: 0 5px 2px;
    }
  }
`;

export const CallButton = styled(SimpleButton)
  .attrs({ className: 'btn-call' })`
  margin: 0 10px 10px;
  align-items: center;
  justify-content: center;
  border-width: 1.5px;
  height: 38px;
  width: 258px;

  &:focus {
    outline: none;
  }

  ${props => props.disabled && css`
    border-color: ${props.theme.colors.gray400};
    color: #a0a9bd;
    cursor: default;
  `}

  i {
    font-size: 24px;
    transform: rotate(135deg);
    margin-top: 2px;
  }
`;

export const HangupButton = styled(CallButton)`
  color: ${props => props.theme.colors.red};
  border-color: ${props => props.theme.colors.red};
  ${props => !props.disabled && css`
    &:hover {
      color: white;
      background-color: ${props.theme.colors.red};
    }
  `}
  ${props => props.disabled && css`
    border-color: ${props.theme.colors.gray400};
    color: #a0a9bd;
    cursor: default;
  `}
`;

export const SaveCallButton = styled(CallButton)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: #0158d4;
  }
`;

export const CallTimeContainer = styled.span`
  font-size: 11px;
  border: 1px solid ${props => props.theme.colors.colorbg03};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.colorbd02};
  font-size: 10px;
  font-weight: 500;
  margin-left: auto;
  margin-right: 5px;
  padding: 2px 6px;
  line-height: 1.3;
`;

export const ToolBar = styled.div`
  border-top: 1px solid ${props => props.theme.colors.colorbg02};
  padding: 8px 10px;
  ${props => (props.hide ? css`display: none;` : 'display: flex;')}
  align-items: center;

  ${props => props.disabled && css`
    .btn,
    .btn:hover,
    .btn:focus,
    .btn.active {
      border-color: ${props.theme.colors.gray400};
      color: #a0a9bd;
      cursor: default;
    }
  `}

  .btn {
    .disabled {
      border-color: ${props => props.theme.colors.gray400};
      color: #a0a9bd;
      cursor: default;
    }
    width: 24px;
    height: 24px;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid #D5DCF4;
    border-radius: 100%;
    flex-shrink: 0;

    + .btn { margin-left: 5px; }

    &:hover, &:focus {
      border-color: #a0a9bd;
    }

    &:focus,
    &:active { box-shadow: none; }

    &.active {
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    }
  }

  .btn-mute {
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    ${props => props.disabled && css`
      border-color: ${props.theme.colors.gray400} !important;
      color: #a0a9bd !important;
      cursor: default !important;
    `}
    &.disabled {
      border-color: ${props => props.theme.colors.gray400} !important;
      color: #23282c !important;
      cursor: default !important;
    }

    .btn:hover, .btn:focus {
      border-color: #a0a9bd;
      color: ${props => props.theme.colors.primary};
    }

    i:last-child { display: none; }

    &.muted {
      border-color: ${props => props.theme.colors.red};
      color: ${props => props.theme.colors.red};

      i:first-child { display: none; }
      i:last-child { display: inline-block; }
    }
  }

  .link {
    color: ${props => props.theme.colors.bodyColor};
    &:hover { text-decoration: none; }
  }
`;

export const CallBody = styled.div.attrs({ className: 'call-item-body' })`
  ${props => props.collapsed && css`
    display: none;
  `}
  border-top: 1px solid ${props => props.theme.colors.colorbg02};
  position: relative;
  padding: 10px;

  textarea {
    height: 230px;
    border-color: ${props => props.theme.colors.colorbg02};
    background-color: #f7f8fc;
    padding: 8px 10px;
    border-radius: 4px;
    resize: none;

    &:focus,
    &:active {
      box-shadow: none;
      border-color: ${props => props.theme.colors.colorbg03};
    }
  }

  .btn { border-radius: 4px; }
`;

export const CallBodyItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  h6 {
    font-weight: 600;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    color: $dark;
  }
`;

export const CallBodyItemBody = styled.div`
  .form-search {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.colorbg02};
    border-radius: 4px;
    margin-bottom: 10px;

    i {
      font-size: 18px;
      font-weight: 700;
      color: #a0a9bd;
    }
  }

  .form-label {
    display: block;
    margin-bottom: 5px;
  }

  .custom-select {
    height: 38px;
    border-radius: 4px;
    padding-left: 5px;
  }
`;

export const CallBodyItemClose = styled(SimpleButton)`
  display: flex;
  padding: 0px;
  align-items: center;
  font-size: 18px;
  outline: 0 !important;
  color: ${props => props.theme.colors.gray400};
  &:hover, &:focus {
    color: #a0a9bd;
  }
`;

export const KeyPadRow = styled.div`
  width: 90%;
  margin: auto;
  margin-bottom: 15px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;

export const Key = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${props => props.theme.colors.colorbg01};
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;

  h6 {
    font-size: 20px;
    font-weight: 500;
    color: ${props => props.theme.colors.darkgray};
    margin-bottom: 0;
  }

  span {
    display: block;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark;
  }

  &:hover {
    background-color: #e1e6f7;
    color: #344563
  }

  &:active {
    border-color: #c1c8de;
    background-color: #fff
  }

  & i {
    font-weight: 700;
    font-size: 24px
  }
`;

export const SummaryItem = styled.div
  .attrs({ className: 'media-cib' })`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .media-icon {
    width: 38px;
    height: 38px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.colorbd02};
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  label {
    display: block;
    margin-bottom: 0;
    color: ${props => props.theme.colors.gray};
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 2px;
    font-weight: normal;
  }

  h6 {
    font-weight: 400;
    margin-bottom: 0;
  }
`;

export const OutboundCallAvatar = styled(Avatar)`
  background-color: ${props => props.bgColor || props.theme.colors.grayduck} !important;

  & img {
      width: 14px !important;
      height: auto !important;
      border-radius: 0;
      opacity: .9
  }
`;

export const DialDeleteButton = styled.button`
    position: absolute;
    top: calc(50% - 7px);
    right: 1px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.gray400};
    opacity: 0;
    visibility: hidden;
    font-weight: 400;
    font-size: 24px;
    line-height: 0;
    text-indent: -1px;
    text-shadow: none;

    &:hover, &:focus {
      color: ${props => props.theme.colors.gray500};
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }

    i {
      font-weight: 400;
      font-size: 24px;
      line-height: 0;
      text-indent: -1px;
      text-shadow: none;
    }
`;

export const DialCallButton = styled.button`
  height: 40px;
  display: block;
  grid-gap: 6px;
  align-items: center;
  justify-content: center;
  fill: ${props => props.theme.colors.white};
  stroke-width: 0;

  div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 5px
  }

  div:first-child svg {
      fill: ${props => props.theme.colors.white};
      stroke-width: 0
  }

  div:last-child {
      display: none
  }

  div:last-child svg {
      stroke-width: 3px
  }

  svg {
      width: 16px;
      height: 16px
  }

  span {
      text-transform: uppercase;
      letter-spacing: .5px;
      font-weight: 500;
      margin-bottom: 3px
  }

  &.btnInitiating div:first-child {
      display: none
  }

  &.btnInitiating div:last-child {
      display: flex
  }

  &.btnInitiating svg {
      animation: rotation 2.75s infinite linear
  }

  @keyframes rotation {
      from {
          transform: rotate(0deg)
      }

      to {
          transform: rotate(359deg)
      }
  }

  &.btnInitiating span {
      margin-bottom: 0
  }

  &.btnOutlinePrimary {
      background: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
  }
`;

export const OutboundCallFinishDiv = styled.div`
  position: relative;
  display: flex;
  grid-gap: 8px;
  padding: 0 10px 10px;

  & .btn {
    flex-shrink: 0;
    border-radius: 5px;
    border-width: 1.5px
  }

  & .alert-warning {
    margin-bottom: 0;
    padding: 0 5px;
    border-radius: 5px;
    border-width: 1px;
    border-color: ${props => props.theme.colors.yellow};
    background-color: rgba(255,193,7,.03);
    display: flex;
    align-items: center;
    grid-gap: 5px
  }

  & .alert-warning i {
    font-size: 30px;
    line-height: 0;
    color: ${props => props.theme.colors.warning};
  }

  & .alert-warning div {
    font-size: 12px;
    line-height: 1.1;
    color: ${props => props.theme.colors.bodyColor};
    padding-right: 5px
  }
`;

export const OutboundCallFinishButton = styled.button`
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  grid-gap: 3px;

  &:hover, &:focus {
    background-color: ${props => props.theme.colors.bodyBg};
    border-color: ${props => props.theme.colors.colorui01};
    color: ${props => props.theme.colors.colorui01};
    box-shadow: none
  }
`;

export const OutboundCallFinishButtonIcon = styled(OutboundCallFinishButton)`
  width: 38px;
  height: 38px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    font-size: 18px;
    line-height: 1;
    text-indent: 0;
    transition: all .25s
  }

  @media(prefers-reduced-motion: reduce) {
    & i {
      transition: none
    }
  }

  &.btn-sm,.ui-datepicker-buttonpane button.&,.btn-group-sm>.&.btn {
    width: 35px;
    height: 35px;
  }

  &.btn-xs {
    width: 32px;
    height: 32px;
  }
`;

export const CIBItemDiv = styled.div`
  border-top: 1px solid ${props => props.theme.colors.colorbg02};
  position: relative;
  padding: 10px;
  height: 430px;

  & .form-control {
    border-radius: 4px;
    height: 38px;
    transition: none
  }

  & .form-control:focus {
    border-color: ${props => props.theme.colors.colorbg03};
    box-shadow: 0 0 0 .5px ${props => props.theme.colors.colorbg03},0 1px 2px 0 rgba(15,23,42,.05)
  }
`;

export const CIBBodyDiv = styled.div`
  & .form-search {
    background-color: ${props => props.theme.colors.light};
    border-radius: 4px;
    margin-bottom: 10px
  }

  & i {
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.theme.colors.gray};
  }

  & .form-label {
    display: block;
    margin-bottom: 5px
  }

  & .custom-select {
    height: 38px;
    border-radius: 4px;
    padding-left: 5px
  }
`;

export const FormSearchDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: ${props => props.theme.templates.heightBase};
  border-radius: ${props => props.theme.borders.radiussm};
  background-color: ${props => props.theme.colors.colorbg01};
  border: 1.5px solid transparent;
  padding-left: 8px;
  margin-bottom: 10px;

  i {
    font-size: 20px;
    color: ${props => props.theme.colors.colortx02};
    margin-right: 7px;
    opacity: .5;
  }

  ${props => props.onfocus && css`
    border-color: ${props.theme.input.borderColor};
    background-color: ${props.theme.colors.white};
    ${shadowSharp({ color: hexToRgb(props.theme.colors.colorbg01) })};

    i {
      opacity: .75;
    }

    .form-control::placeholder {
      opacity: 1;
    }
  `}

  .form-control {
    height: auto;
    border-width: 0;
    border-radius: 0;
    background-color: transparent;
    text-shadow: none;
    margin: 0;

    &:focus { box-shadow: none; }

    &::placeholder {
      color: ${props => props.theme.colors.colortx03};
      text-shadow: none;
      opacity: .75;
    }
  }
`;

export const ContactList = styled.ul`
  list-style: none;
  margin: 0 -10px;
  padding: 0;
  height: calc(100% - 48px);
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
`;

export const ContactLabel = styled.li`
  padding: .65rem 1rem .375rem;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  position: relative;
  font-weight: 500;
  color: #1e293b;

  &::after {
    content: "";
    display: block;
    flex: 1;
    border-bottom: 1px solid ${props => props.theme.colors.colorbg02};
  }

  &:first-child {
    padding-top: 0;
  }
`;

export const ContactLink = styled.a`
  display: block;
  padding: .375rem 1rem;
  color: ${props => props.theme.colors.bodyColor};

  &:hover, &:focus {
    background-color: ${props => props.theme.colors.gray100};
    color: ${props => props.theme.colors.bodyColor};
  }
`;

export const NavCircleMini = styled.nav`
  grid-gap: 3px;

  & .nav-link {
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: ${props => props.theme.colors.bodyColor};
    border: 1px solid ${props => props.theme.colors.colorbg02};
    border-radius: 100%;
    letter-spacing: -0.5px;
  }

  & .nav-link:hover, & .nav-link:focus  {
    border-color: ${props => props.theme.colors.colorui01};
    box-shadow: 0 0 0 .5px ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }

  & .nav-link.active {
    background-color: ${props => props.theme.colors.colorui01};
    border-color: transparent;
    color: ${props => props.theme.colors.white};
    cursor: default;
    outline: none;
  }
`;

export const InputCalledProspect = styled.input`
  &.required {
    border-color: ${props => props.theme.colors.danger} !important;
    background-color: rgba(243,80,92,.03) !important;
  }
  &.required:focus {
    border-color: ${props => props.theme.colors.danger} !important;
    background-color: ${props => props.theme.colors.white} !important;
    box-shadow: 0 0 0 .5px ${props => props.theme.colors.danger} !important;
  }
`;

export const CallWindowSVG = styled.svg`
  width: 24px;
  height: 24px;
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const PropertiesCallDropdown = styled(Dropdown)`
    & {
        position: relative;
    }

    &.show .dropdown-link {
        background-color: #fff;
        border-color: #c1c8de;
        box-shadow: 0 1px 1px rgba(240, 242, 249, .11), 0 2px 2px rgba(240, 242, 249, .11), 0 4px 4px rgba(240, 242, 249, .11), 0 6px 8px rgba(240, 242, 249, .11), 0 8px 16px rgba(240, 242, 249, .11);
    }

    & .dropdown-link {
        display: flex;
        align-items: center;
        height: 38px;
        width: 258px;
        padding-left: 12px;
        padding-right: 28px;
        padding-bottom: 2px;
        border-radius: 5px;
        border: 1px solid #d9def0;
        background-color: #fff;
        color: #4a5e8a;
        position: relative;
        outline: none;
        transition: all .2s;
    }

    @media(prefers-reduced-motion: reduce) {
        & .dropdown-link {
            transition: none;
        }
    }

    & .dropdown-link::after {
        content: "";
        font-family: "remixicon";
        font-size: 11px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: rotate(90deg);
        line-height: 0;
        opacity: .5;
    }

    & .dropdown-link i {
        margin-right: 5px;
        font-size: 16px;
        font-weight: 700;
        line-height: .8;
        color: #0168fa;
    }

    & .dropdown-link span {
        white-space: nowrap;
        font-size: .875rem;
    }

    & .dropdown-link:hover, & .dropdown-link:focus {
        background-color: #fff;
        border-color: #c1c8de;
        box-shadow: 0 1px 1px rgba(225, 230, 247, .25), 0 2px 2px rgba(225, 230, 247, .2), 0 4px 4px rgba(225, 230, 247, .15), 0 8px 8px rgba(225, 230, 247, .1), 0 16px 16px rgba(225, 230, 247, .05);
    }

    & .dropdown-link.required {
        border-color: #f3505c;
        background-color: #fff;
    }

    & .dropdown-menu {
        min-width: 100%;
        border-radius: 5px;
        border-width: 0;
        padding: 8px;
        margin-top: 5px;
        box-shadow: 2px 5px 45px rgba(36,55,130,.12),0 1px 2px rgba(225,230,247,.07),0 2px 4px rgba(225,230,247,.07),0 4px 8px rgba(225,230,247,.07),0 8px 16px rgba(225,230,247,.07),0 16px 32px rgba(225,230,247,.07),0 32px 64px rgba(225,230,247,.07)
    }

    & .dropdown-menu-owner {
        min-width: 180px;
    }

    &.dropdown-basic .dropdown-link {
        display: inline-flex;
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
        padding-right: 25px;
        border-width: 0;
        height: auto;
    }

    &.dropdown-basic .dropdown-link:hover, &.dropdown-basic .dropdown-link:focus {
        box-shadow: none;
    }

    &.dropdown-basic .dropdown-link::after {
        content: "";
        transform: none;
        font-size: 16px;
        right: 8px;
    }

    &.no-click {
        pointer-events: none;
    }
`;

export const PropertiesCallDropdownItem = styled(DropdownItem)`
    & {
        padding: 5px 8px;
        color: #344563;
        display: flex;
        align-items: center;
        border-bottom: transparent;
    }

    @media(prefers-reduced-motion: reduce) {
        & {
            transition: none
        }
    }

    &+.dropdown-item {
        margin-top: 2px
    }

    &:hover, &:focus {
        background-color: #f0f2f9;
        color: #4a5e8a;
        outline: none;
    }

    &.disabled {
        background-color: #f1f5f9;
        color: #929eb9
    }

    &.inactive {
        opacity: .6;
        background-color: rgba(0,0,0,0);
        cursor: default
    }
`;

export const PropertiesPromptDropdown = styled(PropertiesCallDropdown)`
    & .dropdown-link {
        width: 246px;
    }
`;
export const PropertiesPromptDropdownItem = styled(PropertiesCallDropdownItem)``;

export const CallConfirmPrompt = styled.div`
    & {
        position: absolute;
        top: 120px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 10;
        padding: 1rem;
        display: none
    }

    &.show {
        display: block
    }
`;

export const ButtonWhitePrompt = styled.button`
    & {
        background-color: #fff;
        border-color: #c1c8de;
        color: #4a5e8a
    }

    &:hover,&:focus {
        border-color: #a6b0d0;
        color: #0b2151;
    }

    &.active {
        background-color: rgba(1,104,250,.1);
        border-color: rgba(1,104,250,.3);
        color: #0b2151
    }
`;

export const DialedContact = styled.div`
  inset: 0;
  z-index: 5;
  background-color: #f0f2f9;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  cursor: pointer;
  display: flex !important;
  width: 258px !important;
`;

export const AlertCallItem = styled.div`
    border-radius: 3px;
    padding: 8px 10px;
    font-size: 12px;
    margin-bottom: 0;
    color: #1e293b
`;

export const ConfirmAvatar = styled.div`
    width: 24px;
    height: 24px;
    background-color: #929eb9;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
`;
