import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { GetLeadByIdAction } from 'dwell/store/lead/action-types';

export enum ActionType {
  GET_ROOMMATES_REQUEST = 'GET_ROOMMATES_REQUEST',
  GET_ROOMMATES_SUCCESS = 'GET_ROOMMATES_SUCCESS',
  GET_ROOMMATES_FAILURE = 'GET_ROOMMATES_FAILURE',

  GET_ROOMMATE_BY_ID_REQUEST = 'GET_ROOMMATE_BY_ID_REQUEST',
  GET_ROOMMATE_BY_ID_SUCCESS = 'GET_ROOMMATE_BY_ID_SUCCESS',
  GET_ROOMMATE_BY_ID_FAILURE = 'GET_ROOMMATE_BY_ID_FAILURE',

  DELETE_ROOMMATE_REQUEST = 'DELETE_ROOMMATE_REQUEST',
  DELETE_ROOMMATE_SUCCESS = 'DELETE_ROOMMATE_SUCCESS',
  DELETE_ROOMMATE_FAILURE = 'DELETE_ROOMMATE_FAILURE',

  UPDATE_ROOMMATES_REQUEST = 'UPDATE_ROOMMATES_REQUEST',
  UPDATE_ROOMMATES_SUCCESS = 'UPDATE_ROOMMATES_SUCCESS',
  UPDATE_ROOMMATES_FAILURE = 'UPDATE_ROOMMATES_FAILURE',
}

export interface RoommateProps {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  relationship?: number;
  leasing_user?: number;
  lead?: number;
}

export interface RoommateState {
  isSubmitting: boolean;
  errorMessage: string;
  roommates: RoommateProps[];
  roommate: RoommateProps;
}

interface GetRoommatesAction {
  type: ActionType.GET_ROOMMATES_REQUEST | ActionType.GET_ROOMMATES_SUCCESS | ActionType.GET_ROOMMATES_FAILURE;

  result: {
    data: { results: RoommateProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface GetRoommateByIdAction {
  type: ActionType.GET_ROOMMATE_BY_ID_REQUEST | ActionType.GET_ROOMMATE_BY_ID_SUCCESS | ActionType.GET_ROOMMATE_BY_ID_FAILURE;

  result: {
    data: RoommateProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteRoommateByIdAction {
  type: ActionType.DELETE_ROOMMATE_REQUEST | ActionType.DELETE_ROOMMATE_SUCCESS | ActionType.DELETE_ROOMMATE_FAILURE;

  result: {
    data: RoommateProps;
  };
  error: {
    response: { status: string };
  };
}

interface SaveRoommatesAction {
  type: ActionType.UPDATE_ROOMMATES_REQUEST | ActionType.UPDATE_ROOMMATES_SUCCESS | ActionType.UPDATE_ROOMMATES_FAILURE;

  result: {
    data: RoommateProps[];
  };

  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetRoommatesAction
  | GetRoommateByIdAction
  | DeleteRoommateByIdAction
  | SaveRoommatesAction
  | PusherAction
  | GetLeadByIdAction;
