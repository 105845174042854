import { SiteType } from 'corporate/views/edit_site/iframe';
import { paths } from 'corporate/constants';
import { Amenity } from 'dwell/store/community_details/action-types';
import { ActionType, Action, CorporateSite } from './action-types';

export default {
  getCustomerInformation: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CUSTOMER_INFORMATION_REQUEST,
        ActionType.GET_CUSTOMER_INFORMATION_SUCCESS,
        ActionType.GET_CUSTOMER_INFORMATION_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CUSTOMER.INFORMATION),
    },
  }),
  createAmenity: (data: Amenity): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_AMENITY_REQUEST,
        ActionType.CREATE_AMENITY_SUCCESS,
        ActionType.CREATE_AMENITY_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CUSTOMER.CREATE_AMENITY, data),
    },
  }),
  updateAmenity: (data: Amenity): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_AMENITY_REQUEST,
        ActionType.UPDATE_AMENITY_SUCCESS,
        ActionType.UPDATE_AMENITY_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.CUSTOMER.UPDATE_AMENITY, data.id), data),
    },
  }),
  deleteAmenity: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_AMENITY_REQUEST,
        ActionType.DELETE_AMENITY_SUCCESS,
        ActionType.DELETE_AMENITY_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.CUSTOMER.DELETE_AMENITY, id)),
    },
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  setSiteSelected: (item: any, itemType: SiteType): Action => ({
    type: ActionType.SELECT_ITEM,
    item,
    itemType,
  }),
  setCorporateSiteSelected: (item: CorporateSite): Action => ({
    type: ActionType.SELECT_CORPORATE_SITE,
    item,
  }),
};
