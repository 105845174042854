import produce from 'immer';
import moment from 'moment';
import { unionBy } from 'lodash';

import { LeasingStateTypes } from 'leasing/store/types';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { ActionType as ScreeningReportActionType } from 'leasing/store/screening_report/action-types';
import { selectGuarantorsIncome } from '../guarantor/reducers';
import { Action, ActionType, ApplicantProps, ApplicantState } from './action-types';

export const defaultApplicant = {
  type: 'CO_APPLICANT',
  first_name: '',
  last_name: '',
  should_invite: true,
  is_email_sent: false,
  middle_name: '',
  email: '',
  phone_number: '',
  phone_type: 'MOBILE',
  birth_date: '',
  occupants: [],
  identification_type: 'SSN',
  identification_id: '',
  identification_country: 'UNITED_STATES',
  driver_licence_number: '',
  driver_licence_state: '',
  driver_licence_photo_front: '',
  driver_licence_photo_back: '',
  zip_or_postal_code: '',
  country: 'UNITED_STATES',
  first_street_address: '',
  second_street_address: '',
  state: '',
  city_name: '',
  current_residence_move_in_date: null,
  is_residence_owned: false,
  monthly_payment: null,
  landlord_name: '',
  landlord_phone: '',
  is_currently_employed: true,
  visa_number: '',
};

export const initialState: ApplicantState = {
  applicant: {} as ApplicantProps,
  applicants: [] as ApplicantProps[],
  isApplicantsLoaded: false,
};

export const selectAllLeasingUsers = (state: LeasingStateTypes):ApplicantProps[] => state.applicant.applicants.concat(state.guarantor.guarantors.map(item => ({ ...item, is_guarantor: true })));
export const selectApplicants = (state: LeasingStateTypes):ApplicantProps[] => state.applicant.applicants;
export const selectApplicant = (state: LeasingStateTypes):ApplicantProps => state.applicant.applicant;
export const selectIsApplicantsLoaded = (state: LeasingStateTypes): boolean => state.applicant.isApplicantsLoaded;
export const selectHasApplicantStarted = (state: LeasingStateTypes):boolean =>
  state.applicant.applicants.some(applicant => applicant.activities?.find(activity => activity?.step === 'APPLICATION_STARTED'));
export const selectHasApplicantSubmitted = (state: LeasingStateTypes):boolean =>
  state.applicant.applicants.some(applicant => applicant.activities?.find(activity => activity?.step === 'SUBMIT_APPLICATION'));
export const selectShowStepper = (state: LeasingStateTypes):boolean => selectHasApplicantSubmitted(state);
export const selectHasAllSubmittedApplicant = (state: LeasingStateTypes):boolean =>
  !!state.applicant.applicants.length && state.applicant.applicants.every(applicant => applicant.activities?.find(activity => activity?.step === 'SUBMIT_APPLICATION'));
export const selectApplicantsIncome = (state: LeasingStateTypes): number =>
  state.applicant.applicants.reduce((sum, item) => (item.income_verified ? (item.total_income || 0) : 0) + sum, 0);
export const selectTotalIncome = (state: LeasingStateTypes): number => {
  const applicantsIncome = selectApplicantsIncome(state);
  const guarantorsIncome = selectGuarantorsIncome(state);
  return Math.floor(applicantsIncome + guarantorsIncome);
};
export const selectHasPendingApplicant = (state: LeasingStateTypes): boolean => {
  const values = Object.values(state.lease.lease.application_steps?.SUBMIT_APPLICATION || {});
  return !values.length || !values.every(i => i);
};
export const selectInSufficientIncome = (state: LeasingStateTypes): boolean =>
  !selectHasPendingApplicant(state) && selectTotalIncome(state) < Math.floor(state.lease.lease.income_threshold);
export const selectAllIncomesConfirmed = (state: LeasingStateTypes): boolean =>
  state.applicant.applicants.concat(state.guarantor.guarantors).every(item => item.income_verified);
export const selectAllApplicantsHaveIncomes = (state: LeasingStateTypes): boolean => state.applicant.applicants.every(item => !!item.income_count);
export const selectAllGuarantorsHaveIncomes = (state: LeasingStateTypes): boolean => state.guarantor.guarantors.every(item => !!item.income_count);
export const selectAllHaveIncomes = (state: LeasingStateTypes): boolean =>
  selectAllApplicantsHaveIncomes(state) && selectAllGuarantorsHaveIncomes(state);

export default produce((state: ApplicantState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.CREATE_APPLICANT_SUCCESS:
      state.applicant = action.result.data;
      state.applicants = [...state.applicants, action.result.data];
      break;

    case ActionType.DELETE_APPLICANT_SUCCESS:
      state.applicant = {} as ApplicantProps;
      state.applicants = state.applicants.filter(a => a.id !== action.result.data.id);
      break;

    case ActionType.GET_APPLICANTS_REQUEST:
      state.isApplicantsLoaded = false;
      break;

    case ActionType.GET_APPLICANT_BY_ID_SUCCESS:
      state.applicant = action.result.data;
      break;

    case ActionType.GET_APPLICANTS_SUCCESS:
      state.isApplicantsLoaded = true;
      state.applicants = action.result.data.results;
      break;

    case ActionType.GET_SCREENING_STATUS_SUCCESS:
      state.applicants = state.applicants.map((applicant) => {
        if (applicant.id === action.result.data.id) {
          return { ...applicant, screening_started_time: action.result.data.screening_started_time };
        }
        return applicant;
      });
      break;

    case ActionType.RESET_APPLICANT:
      state.applicant = {} as ApplicantProps;
      break;

    case ActionType.UPDATE_APPLICANT_SUCCESS:
      state.applicant = action.result.data;
      state.applicants = unionBy([action.result.data], state.applicants, 'id');
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;

    case ScreeningReportActionType.INITIATE_SCREENING_SUCCESS:
      state.applicants = state.applicants.map((applicant) => {
        if (applicant.id === action.result.data.applicant_id) {
          return { ...applicant, screening_started_time: moment().format() };
        }
        return applicant;
      });
      break;
  }

  return state;
});
