import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE',
  CLEAR_AND_GET_NOTIFICATIONS_REQUEST = 'CLEAR_AND_GET_NOTIFICATIONS_REQUEST',
  CLEAR_AND_GET_NOTIFICATIONS_SUCCESS = 'CLEAR_AND_GET_NOTIFICATIONS_SUCCESS',
  CLEAR_AND_GET_NOTIFICATIONS_FAILURE = 'CLEAR_AND_GET_NOTIFICATIONS_FAILURE',
  READ_ALL_NOTIFICATIONS_REQUEST = 'READ_ALL_NOTIFICATIONS_REQUEST',
  READ_ALL_NOTIFICATIONS_SUCCESS = 'READ_ALL_NOTIFICATIONS_SUCCESS',
  READ_ALL_NOTIFICATIONS_FAILURE = 'READ_ALL_NOTIFICATIONS_FAILURE',
  UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE',
  BULK_CLEAR_NOTIFICATIONS_REQUEST = 'BULK_CLEAR_NOTIFICATIONS_REQUEST',
  BULK_CLEAR_NOTIFICATIONS_SUCCESS = 'BULK_CLEAR_NOTIFICATIONS_SUCCESS',
  BULK_CLEAR_NOTIFICATIONS_FAILURE = 'BULK_CLEAR_NOTIFICATIONS_FAILURE',
}

export interface NotificationProps {
  id: number;
  redirect_url: string;
  lead_owner: string;
  lead_name: string;
  property: { id: number; name: string };
  created: string;
  updated: string;
  content: string;
  is_read: boolean;
  type: string;
  is_display: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object_id: any;
  user: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object_content_type: any;
}

interface GetNotificationsAction {
  type: ActionType.GET_NOTIFICATIONS_REQUEST | ActionType.GET_NOTIFICATIONS_SUCCESS | ActionType.GET_NOTIFICATIONS_FAILURE;

  result: {
    data: { results: NotificationProps[]; count: number; non_readed_count: number };
  };
  error: {
    response: { status: string };
  };
}

interface ReadAllNotificationsAction {
  type: ActionType.READ_ALL_NOTIFICATIONS_REQUEST | ActionType.READ_ALL_NOTIFICATIONS_SUCCESS | ActionType.READ_ALL_NOTIFICATIONS_FAILURE;

  error: {
    response: { status: string };
  };
}

interface UpdateNotificationByIdAction {
  type: ActionType.UPDATE_NOTIFICATION_REQUEST | ActionType.UPDATE_NOTIFICATION_SUCCESS | ActionType.UPDATE_NOTIFICATION_FAILURE;

  result: {
    data: NotificationProps;
  }

  error: {
    response: { status: string };
  };
}

interface BulkClearNotificationsAction {
  type: ActionType.BULK_CLEAR_NOTIFICATIONS_REQUEST | ActionType.BULK_CLEAR_NOTIFICATIONS_SUCCESS | ActionType.BULK_CLEAR_NOTIFICATIONS_FAILURE;

  result: {
    data: { success: boolean; ids: number[] };
  };
  error: {
    response: { status: string };
  };
}

interface ClearAndGetNotificationsAction {
  type: ActionType.CLEAR_AND_GET_NOTIFICATIONS_REQUEST | ActionType.CLEAR_AND_GET_NOTIFICATIONS_SUCCESS | ActionType.CLEAR_AND_GET_NOTIFICATIONS_FAILURE;

  result: {
    data: { results: NotificationProps[]; count: number; non_readed_count: number };
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetNotificationsAction
  | ReadAllNotificationsAction
  | UpdateNotificationByIdAction
  | BulkClearNotificationsAction
  | ClearAndGetNotificationsAction;
