import { build } from 'dwell/constants/paths';
import { api } from 'application/constants/paths';
import { ActionType, Action, UpdateDataType, UpdateApplicationDecisionType, ApplicantProps } from './action-types';

export default {
  getApplicationDetails: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_APPLICATION_DETAILS_REQUEST,
        ActionType.GET_APPLICATION_DETAILS_SUCCESS,
        ActionType.GET_APPLICATION_DETAILS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.APPLICATION_DETAILS, id)),
    },
  }),

  updateApplication: (id: number, data: UpdateDataType): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_APPLICATION_REQUEST,
        ActionType.UPDATE_APPLICATION_SUCCESS,
        ActionType.UPDATE_APPLICATION_FAILURE,
      ],
      promise: client => client.put(build(api.v1.APPLICATION_DETAILS, id), data),
    },
  }),

  updateApplicationDecision: (id: number, data: UpdateApplicationDecisionType): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_APPLICATION_DECISION_REQUEST,
        ActionType.UPDATE_APPLICATION_DECISION_SUCCESS,
        ActionType.UPDATE_APPLICATION_DECISION_FAILURE,
      ],
      promise: client => client.put(build(api.v1.APPLICATION_DECISION, id), data),
    },
  }),

  setApplicant: (applicant: ApplicantProps): Action => ({ type: ActionType.SET_APPLICANT, applicant }),
  setReloadState: (shouldReload: boolean): Action => ({ type: ActionType.SET_RELOAD_STATE, shouldReload }),
  toggleExpandMode: (): Action => ({ type: ActionType.TOGGLE_EXPAND_MODE }),
};
