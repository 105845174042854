import produce from 'immer';
import { sortBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, LeaseUnitProps, UnitState } from './action-types';

export const initialState: UnitState = {
  errorMessage: null,
  units: [] as LeaseUnitProps[],
  isLoaded: false,
};

export const selectUnits = (state : { unit: UnitState }) : LeaseUnitProps[] => state.unit.units;
export const selectUnitChoices = (currentUnitId: number) => (state : { unit: UnitState }) : Record<string, unknown> => {
  const units = state.unit.units.filter(unit => !unit.is_hold || unit.id === currentUnitId);
  return units.reduce((json, i) => ({ ...json, [i.id]: i.unit }), {});
};
export const selectUnitIsLoaded = (state : { unit: UnitState }) : boolean => state.unit.isLoaded;

export default produce((state: UnitState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_UNITS_SUCCESS:
      state.units = sortBy(action.result.data.results, [u => u.status]);
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
