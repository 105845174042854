import { paths } from 'corporate/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, Page, Section, PageSettings } from './action-types';

export default {
  setNavPage: (navPage: 'EDIT_SITE' | 'SITE_SETTINGS' | 'MEDIA_LIBRARY'): Action => ({
    type: ActionType.SET_NAV_PAGE,
    navPage,
  }),
  getSitePageData: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SITE_PAGE_DATA_REQUEST,
        ActionType.GET_SITE_PAGE_DATA_SUCCESS,
        ActionType.GET_SITE_PAGE_DATA_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.EDIT_CORPORATE_SITE.PAGES_AND_SECTIONS),
    },
  }),
  getSubsitePageData: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SUBSITE_PAGE_DATA_REQUEST,
        ActionType.GET_SUBSITE_PAGE_DATA_SUCCESS,
        ActionType.GET_SUBSITE_PAGE_DATA_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.EDIT_SUBSITE.PAGES_AND_SECTIONS),
    },
  }),
  togglePanelSidebar: (): Action => ({
    type: ActionType.TOGGLE_PANEL_SIDEBAR,
  }),
  setActivePage: (pageId: number): Action => ({
    type: ActionType.CORPORATE_SET_PAGE,
    pageId,
  }),
  setToolbarNavTab: (nav: 'PAGES'|'SECTIONS'): Action => ({
    type: ActionType.SET_TOOLBAR_NAV_TAB,
    nav,
  }),
  addNewPage: (name: string): Action => ({
    type: ActionType.ADDING_PAGE,
    name,
  }),
  finishAddingPage: (name: string): Action => ({
    type: ActionType.ADDING_PAGE_FINISHED,
    name,
  }),
  renamePage: (index: number): Action => ({
    type: ActionType.RENAME_PAGE,
    index,
  }),
  hideSubSitePage: (id: number, hide: boolean): Action => ({
    CALL_API: {
      types: [
        ActionType.HIDE_SUBSITE_PAGE_REQUEST,
        ActionType.HIDE_SUBSITE_PAGE_SUCCESS,
        ActionType.HIDE_SUBSITE_PAGE_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.EDIT_PAGE_SETTINGS.SUB_SITE_PAGE_SETTINGS, id), { hide }),
    },
  }),
  hideSitePage: (id: number, hide: boolean): Action => ({
    CALL_API: {
      types: [
        ActionType.HIDE_SITE_PAGE_REQUEST,
        ActionType.HIDE_SITE_PAGE_SUCCESS,
        ActionType.HIDE_SITE_PAGE_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.EDIT_PAGE_SETTINGS.SITE_PAGE_SETTINGS, id), { hide }),
    },
  }),
  finishRenamingPage: (index: number, name: string): Action => ({
    type: ActionType.RENAME_PAGE_FINISHED,
    index,
    name,
  }),
  duplicatePage: (index: number): Action => ({
    type: ActionType.DUPLICATE_PAGE,
    index,
  }),
  deletePage: (index: number): Action => ({
    type: ActionType.DELETE_PAGE,
    index,
  }),
  setEnableSaveChangesButton: (enable: boolean): Action => ({
    type: ActionType.SET_ENABLE_SAVE_CHANGES_BUTTON,
    enable,
  }),
  publishCorporateSite: (pages: Page[], deletedSections: Section[]): Action => ({
    CALL_API: {
      types: [
        ActionType.PUBLISH_CORPORATE_SITE_REQUEST,
        ActionType.PUBLISH_CORPORATE_SITE_SUCCESS,
        ActionType.PUBLISH_CORPORATE_SITE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_CORPORATE_SITE.PUBLISH, { pages, deleted_sections: deletedSections }),
    },
  }),
  togglePageSettingsPanel: (show: boolean): Action => ({
    type: ActionType.TOGGLE_PAGE_SETTINGS_PANEL,
    show,
  }),
  publishSubsite: (pages: Page[], deletedSections: Section[]): Action => ({
    CALL_API: {
      types: [
        ActionType.PUBLISH_SUBSITE_REQUEST,
        ActionType.PUBLISH_SUBSITE_SUCCESS,
        ActionType.PUBLISH_SUBSITE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_SUBSITE.PUBLISH, { pages, deleted_sections: deletedSections }),
    },
  }),
  updateSitePageSettings: (id: number, pageSettings: PageSettings): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SITE_PAGE_SETTINGS_REQUEST,
        ActionType.UPDATE_SITE_PAGE_SETTINGS_SUCCESS,
        ActionType.UPDATE_SITE_PAGE_SETTINGS_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.EDIT_PAGE_SETTINGS.SITE_PAGE_SETTINGS, id), { page_settings: pageSettings }),
    },
  }),
  updateSubSitePageSettings: (id: number, pageSettings: PageSettings): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SUB_SITE_PAGE_SETTINGS_REQUEST,
        ActionType.UPDATE_SUB_SITE_PAGE_SETTINGS_SUCCESS,
        ActionType.UPDATE_SUB_SITE_PAGE_SETTINGS_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.EDIT_PAGE_SETTINGS.SUB_SITE_PAGE_SETTINGS, id), { page_settings: pageSettings }),
    },
  }),
  setShowUnsavedChangesModal: (show: boolean): Action => ({
    type: ActionType.SET_SHOW_UNSAVED_CHANGES_MODAL,
    show,
  }),
};
