import produce from 'immer';

import { Action, ActionType, Portfolio } from './action-types';
import { preFillState } from '../utils';
import { CommonStateType } from '../types';

export interface PortfolioState extends CommonStateType {
  isSubmitting: boolean,
  isLoaded: boolean,
  errorMessage: string,
  portfolios: Portfolio[],
}

const initialState: PortfolioState = {
  isSubmitting: false,
  isLoaded: false,
  errorMessage: null,
  portfolios: [],
};

export default produce((state: PortfolioState = initialState, action: Action): PortfolioState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_PORTFOLIO_SUCCESS: {
      state.portfolios = action.result.data.results;
      state.isLoaded = true;
      break;
    }
  }

  return state;
});
