import { PromiseActionType } from 'dwell/store/types';
import { ExploreMarketsList } from '../explore_markets/action-types';

export enum ActionType {
  GET_WATCHLIST_REQUEST = 'GET_WATCHLIST_REQUEST',
  GET_WATCHLIST_SUCCESS = 'GET_WATCHLIST_SUCCESS',
  GET_WATCHLIST_FAILURE = 'GET_WATCHLIST_FAILURE',
  UPDATE_WATCHLIST_REQUEST = 'UPDATE_WATCHLIST_REQUEST',
  UPDATE_WATCHLIST_SUCCESS = 'UPDATE_WATCHLIST_SUCCESS',
  UPDATE_WATCHLIST_FAILURE = 'UPDATE_WATCHLIST_FAILURE',
}

export interface WatchListUpdate {
  object_type?: string;
  object_id?: number;
  is_stored?: boolean;
}

interface GetWatchlistAction {
  type: ActionType.GET_WATCHLIST_REQUEST | ActionType.GET_WATCHLIST_SUCCESS | ActionType.GET_WATCHLIST_FAILURE;
  result: {
    data: ExploreMarketsList;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateWatchlistAction {
  type: ActionType.UPDATE_WATCHLIST_REQUEST | ActionType.UPDATE_WATCHLIST_SUCCESS | ActionType.UPDATE_WATCHLIST_FAILURE;
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetWatchlistAction | UpdateWatchlistAction;
