import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, GarageProps, GarageState } from './action-types';

export const initialState: GarageState = {
  errorMessage: null,
  garages: [] as GarageProps[],
  isLoaded: false,
};

export const selectGarages = (state: { garage: GarageState }) : GarageProps[] => state.garage.garages;

export default produce((state: GarageState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_GARAGE_SUCCESS:
      state.garages = unionBy([action.result.data], state.garages, 'id');
      break;

    case ActionType.DELETE_GARAGE_SUCCESS:
      state.garages = state.garages.filter(i => i.id !== action.result.data.id);
      break;

    case ActionType.UPDATE_GARAGE_SUCCESS:
      state.garages = unionBy([action.result.data], state.garages, 'id');
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.garages = action.result.data.garages;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
