import { get, isEmpty } from 'lodash';
import React, { FC, useEffect, useState, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, FormText, Label } from 'reactstrap';
import { formatTimeByTimezone, convertToDateWithTimezone, dateFilter, replaceTimezone } from 'src/utils';
import taskActions from 'dwell/store/task/action-creators';
import { selectAvailableDateTimes, selectAreAvailableDateTimesLoaded } from 'dwell/store/task/reducers';
import { StyledDatePicker } from '../styles';

import { InputProps } from '../utils';

const TourDate: FC<InputProps> = ({ control, setValue, task, watch }) => {
  const [currentTaskDate, setCurrentTaskDate] = useState<Date | null>(null);
  const availableDateTimes = useSelector(selectAvailableDateTimes);
  const areAvailableDateTimesLoaded = useSelector(selectAreAvailableDateTimesLoaded);
  const tourType = get(watch('type'), 'value', null);
  const owner = get(watch('owner'), 'value', null);
  const object = watch('object');
  const tourDate = watch('tour_date');
  const units = watch('units');
  const dispatch = useDispatch();
  const firstInputsNotFilled = !task.id && (!owner || !object);

  const formattedDates = useMemo(() => {
    let tempDates = [];
    if (firstInputsNotFilled) return [];
    if (availableDateTimes.length) {
      if (typeof availableDateTimes[0] !== 'string') {
        tempDates = availableDateTimes.map(i => i.times).reduce((a, b) => a.concat(b), []);
        if (tourType === 'SELF_GUIDED_TOUR') tempDates = tempDates.filter(i => i.available);
        tempDates = tempDates.map(i => i.date_time);
        tempDates = tempDates.map(i => convertToDateWithTimezone(i));
        return tempDates;
      }
      return [];
    }
    return [];
  }, [availableDateTimes, firstInputsNotFilled, tourType]);

  const { dates, times } = useMemo(() => {
    let tempTimes: Array<Date> = [];
    if (!formattedDates.length) return { dates: [], times: tempTimes };
    tempTimes = formattedDates.filter((i) => {
      const correctTourDate = replaceTimezone(tourDate);
      const correctItemDate = replaceTimezone(i);
      return dateFilter(correctTourDate, correctItemDate);
    });
    return { dates: formattedDates, times: tempTimes };
  }, [formattedDates, tourDate]);

  const fetchAvailableTourTime = () => {
    dispatch(taskActions.getAvailableTourTime({
      type: tourType,
      date: formatTimeByTimezone().format('YYYY-MM-DD'),
      unit: !isEmpty(units) ? units.map(u => u.value) : [],
    }));
  };

  useEffect(() => {
    if (firstInputsNotFilled) return;
    if (tourType && tourType === 'SELF_GUIDED_TOUR' && !isEmpty(units)) {
      fetchAvailableTourTime();
    } else if (tourType && tourType !== 'SELF_GUIDED_TOUR') {
      fetchAvailableTourTime();
    }
  }, [units, tourType, firstInputsNotFilled]);

  useEffect(() => {
    setValue('tour_date', task.tour_date ? convertToDateWithTimezone(task.tour_date) : null);
    setCurrentTaskDate(task.tour_date ? convertToDateWithTimezone(task.tour_date) : null);
  }, [task.tour_date]);

  useEffect(() => {
    if (!task.id && tourDate && !areAvailableDateTimesLoaded) {
      setValue('tour_date', null);
    }
  }, [tourDate, areAvailableDateTimesLoaded, task.id]);

  useEffect(() => {
    if (tourDate && times.length && !times.map(t => t.getTime()).includes(tourDate.getTime())) {
      setValue('tour_date', times[0]);
    }
  }, [tourDate, times]);

  const validateDate = (value: Date) => {
    if (currentTaskDate && currentTaskDate.getTime() === value.getTime()) {
      return true;
    }
    const date = new Date(value.getTime());
    date.setMilliseconds(0);
    return times.length ? times.map(t => t.getTime()).includes(date.getTime()) : true;
  };

  return (
    <FormGroup>
      <Label htmlFor="tour_date">Tour date:</Label>
      <Controller
        control={control}
        name="tour_date"
        rules={{ required: true, validate: value => validateDate(value) }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <StyledDatePicker
              dateFormat="MMMM d, yyyy h:mm aa"
              id="tour_date"
              includeDates={dates}
              includeTimes={times}
              onChange={onChange}
              placeholderText={areAvailableDateTimesLoaded ? 'Select a date' : 'Loading...'}
              selected={value}
              showTimeSelect
              disabledKeyboardNavigation
              disabled={!areAvailableDateTimesLoaded}
            />
            {error && <FormText color="danger">Please choose a tour date</FormText>}
          </>
        )}
      />
    </FormGroup>
  );
};

export default TourDate;
