export enum ActionType {
  GET_HOLIDAYS_REQUEST = 'GET_HOLIDAYS_REQUEST',
  GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS',
  GET_HOLIDAYS_FAILURE = 'GET_HOLIDAYS_FAILURE',
  UPDATE_HOLIDAY_REQUEST = 'UPDATE_HOLIDAY_REQUEST',
  UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS',
  UPDATE_HOLIDAY_FAILURE = 'UPDATE_HOLIDAY_FAILURE',
}

export interface Holiday {
  id: number;
  name: string;
  status: boolean;
  country: string;
  property: number;
  date: string;
}

interface GetHolidaysAction {
  type: ActionType.GET_HOLIDAYS_REQUEST | ActionType.GET_HOLIDAYS_SUCCESS | ActionType.GET_HOLIDAYS_FAILURE;

  result: {
    data: { results: Holiday[] };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateHolidaysByIdAction {
  type: ActionType.UPDATE_HOLIDAY_REQUEST | ActionType.UPDATE_HOLIDAY_SUCCESS | ActionType.UPDATE_HOLIDAY_FAILURE;

  result: {
    data: Holiday;
  };
  error: {
    response: { status: string };
  };
}

export type Action = GetHolidaysAction | UpdateHolidaysByIdAction;
