import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import actions from 'dwell/actions';
import { AgentQuestion, AnswerPayload } from 'dwell/store/agent_question/action-types';
import {
  AgentResponse, AgentResponseBody, BinaryForm, BotResponseFooter, ChatFormButton, QuestionsForm,
} from 'dwell/views/chat/single_chat/window/styles';

interface ConfirmationFormProps {
  question: AgentQuestion;
}

const ConfirmationForm: FC<ConfirmationFormProps> = ({ question }) => {
  const dispatch = useDispatch();
  const { processAnswers, setGlobalForm, setIsConfirmationFormDisplayed } = actions.agentQuestion;
  const resetForm = () => {
    dispatch(setGlobalForm([]));
    dispatch(setIsConfirmationFormDisplayed(false));
  };

  const sendAnswers = (answers: AnswerPayload[], answersHTML: string) => {
    dispatch(processAnswers({ answers, answers_html: answersHTML }));
  };

  const submit = (action) => {
    const payloads = {
      YES: { question: question.id, value: 'Yes', action: 'YES', is_array: false, answerHtml: 'Yes' },
      NO: { question: question.id, value: 'No', action: 'NO', is_array: false, answerHtml: 'No' },
      IDK: { question: question.id, value: 'IDK', action: 'IDK', is_array: false, answerHtml: 'I don\'t know' },
    };
    resetForm();
    const { answerHtml } = payloads[action];
    delete payloads[action].answerHtml;
    sendAnswers([payloads[action]], answerHtml);
  };

  return (
    <AgentResponse key="answer-form">
      <AgentResponseBody>
        <QuestionsForm>
          <BinaryForm>
            <ChatFormButton key="yes-button" onClick={() => submit('YES')}>
              Yes
            </ChatFormButton>
            <ChatFormButton key="no-button" onClick={() => submit('NO')}>
              No
            </ChatFormButton>
          </BinaryForm>
        </QuestionsForm>
        <BotResponseFooter>
          <ChatFormButton className="btn-white" onClick={() => submit('IDK')}>
            I don't know
          </ChatFormButton>
        </BotResponseFooter>
      </AgentResponseBody>
    </AgentResponse>
  );
};

export default ConfirmationForm;
