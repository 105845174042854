import { SiteType } from 'corporate/views/edit_site/iframe';
import { Amenity } from 'dwell/store/community_details/action-types';
import { PromiseActionType } from 'dwell/store/types';

export interface CorporateSite {
  id: number;
  customer_name: string;
  town: string;
  city: string;
  domain: string;
  amenities: Amenity[];
}

export interface Subsite {
  id: number;
  name: string;
  town: string;
  city: string;
  domain: string;
  external_id: string;
}

export interface Standalone {
  id: number;
  name: string;
  town: string;
  city: string;
  domain: string;
  external_id: string;
}

export interface CustomerInfo {
  corporate_sites: CorporateSite[];
  subsites: Subsite[];
  standalone_sites: Standalone[];
}

export interface SiteSelected {
  site: CorporateSite | Subsite | Standalone;
  siteType: SiteType;
}

export enum ActionType {
  GET_CUSTOMER_INFORMATION_REQUEST = 'GET_CUSTOMER_INFORMATION_REQUEST',
  GET_CUSTOMER_INFORMATION_SUCCESS = 'GET_CUSTOMER_INFORMATION_SUCCESS',
  GET_CUSTOMER_INFORMATION_FAILURE = 'GET_CUSTOMER_INFORMATION_FAILURE',

  CREATE_AMENITY_REQUEST = 'CREATE_AMENITY_REQUEST',
  CREATE_AMENITY_SUCCESS = 'CREATE_AMENITY_SUCCESS',
  CREATE_AMENITY_FAILURE = 'CREATE_AMENITY_FAILURE',

  UPDATE_AMENITY_REQUEST = 'UPDATE_AMENITY_REQUEST',
  UPDATE_AMENITY_SUCCESS = 'UPDATE_AMENITY_SUCCESS',
  UPDATE_AMENITY_FAILURE = 'UPDATE_AMENITY_FAILURE',

  DELETE_AMENITY_REQUEST = 'DELETE_AMENITY_REQUEST',
  DELETE_AMENITY_SUCCESS = 'DELETE_AMENITY_SUCCESS',
  DELETE_AMENITY_FAILURE = 'DELETE_AMENITY_FAILURE',

  SELECT_ITEM = 'SELECT_ITEM',

  SELECT_CORPORATE_SITE = 'SELECT_CORPORATE_SITE',
}

interface GetCustomerInformationAction {
  type: ActionType.GET_CUSTOMER_INFORMATION_REQUEST | ActionType.GET_CUSTOMER_INFORMATION_SUCCESS | ActionType.GET_CUSTOMER_INFORMATION_FAILURE;
  result: {
    data: CustomerInfo
  }
}

interface SelectItemAction {
  type: ActionType.SELECT_ITEM;
  item: CorporateSite | Subsite | Standalone;
  itemType: SiteType;
}

interface SelectCorporateSiteAction {
  type: ActionType.SELECT_CORPORATE_SITE;
  item: CorporateSite;
}

interface CreateAmenityAction {
  type: ActionType.CREATE_AMENITY_REQUEST | ActionType.CREATE_AMENITY_SUCCESS | ActionType.CREATE_AMENITY_FAILURE;
  result: {
    data: Amenity;
  }
}

interface UpdateAmenityAction {
  type: ActionType.UPDATE_AMENITY_REQUEST | ActionType.UPDATE_AMENITY_SUCCESS | ActionType.UPDATE_AMENITY_FAILURE;
  result: {
    data: Amenity;
  }
}

interface DeleteAmenityAction {
  type: ActionType.DELETE_AMENITY_REQUEST | ActionType.DELETE_AMENITY_SUCCESS | ActionType.DELETE_AMENITY_FAILURE;
  result: {
    data: Amenity;
  }
}

export type Action =
  | PromiseActionType
  | GetCustomerInformationAction
  | SelectItemAction
  | SelectCorporateSiteAction
  | CreateAmenityAction
  | UpdateAmenityAction
  | DeleteAmenityAction;
