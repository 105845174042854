import moment from 'moment';
import axios from 'axios';
import { chatAvatarColorClasses } from 'dwell/constants';
import Carousel from 'dwell/components/communications/carousels/_carousel';
import PhotosCarouselDrawer from 'dwell/components/communications/carousels/_photos_carousel_drawer';
import ImageCarousel from 'dwell/components/communications/carousels/_image_carousel';

export const getAvatarColor = (name = '', id: number, isSMS = false): string => {
  let colorClass = chatAvatarColorClasses[id % 8];
  if (!isSMS && name.includes('Prospect #')) {
    colorClass = '';
  }
  return colorClass;
};

export const getInitials = (name: string, isSMS = false): string => {
  if (!(name || '').trim()) return 'U1';
  const splits = name.split(' ').filter(i => !!i);
  if (!isSMS && name.includes('Prospect #')) {
    return name.substr(10, name.length);
  }
  if (splits.length === 1) return splits[0][0].toUpperCase();
  return splits.length > 1 ? `${splits[0][0]}${splits[1][0]}`.toUpperCase() : `${splits[0][0]}`.toUpperCase();
};

export const isSameContact = (activeChats: { id: number; isSMS: boolean }[], id: number, isSMS = false): boolean => !!activeChats.find(i => i.id === id && i.isSMS === isSMS);

export const formatMessageDate = (date: string): string => {
  let formattedDate = null;
  if (date) {
    formattedDate = moment(date);
    formattedDate = moment().diff(formattedDate, 'days') === 0 ? formattedDate.format('hh:mma') : formattedDate.format('MMM D');
  }
  return formattedDate;
};

export const getSearchPlaceholder = (activeFilter: string): string => {
  let searchPlaceholder = '';
  switch (activeFilter) {
    case 'All':
    case 'All Prospects':
      searchPlaceholder = 'Search all prospects';
      break;
    case 'Archive':
    case 'Archive Prospects':
      searchPlaceholder = 'Search archived prospects';
      break;
    case 'Active':
    case 'ACTIVE':
      searchPlaceholder = 'Search active prospects';
      break;
    case 'My':
    case 'MY':
      searchPlaceholder = 'Search my chat prospects';
      break;
    case 'SMS':
      searchPlaceholder = 'Search SMS prospects';
      break;
  }
  return searchPlaceholder;
};

export const getNoContentText = (activeFilter: string): { label: string; text: string } => {
  let label = '';
  let text = '';
  switch (activeFilter) {
    case 'All':
    case 'All Prospects':
      text = 'There are no chats happening on your property websites now.';
      label = 'No Chats';
      break;
    case 'Archive':
    case 'Archive Prospects':
      text = 'There are no archived chats yet.';
      label = 'No Archived Chats';
      break;
    case 'Active':
    case 'ACTIVE':
      text = 'There are no active chats happening on your property websites right now.';
      label = 'No Active Chats';
      break;
    case 'My':
    case 'MY':
      text = "You don't have any chats to manage right now.";
      label = 'No Assigned Chats';
      break;
    case 'SMS':
      text = "You don't have any contacts right now. Ensure that properties have an associated phone number to send SMS";
      label = 'No Chats';
      break;
  }
  return { label, text };
};

export const isMostRecentTab = (tab: string): boolean => {
  const tabs = Object.keys(localStorage)
    .filter(key => key.startsWith('tab'))
    .sort((a, b) => new Date(localStorage[a]).getTime() - new Date(localStorage[b]).getTime());
  return tabs[tabs.length - 1] === tab;
};

export interface dataProps {
  recipient_id: string;
  text?: string;
  custom?: {
    type: string;
    units?: string;
    amenities?: string[];
    locations?: string[];
    communities?: string[];
  };
}

interface PromotionProps {
  name: string;
  restriction: string;
  promotion_html: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  floor_plans: any[];
  unit_types: string[];
}

interface UnitProps {
  id: number;
  unit: string;
  description: string;
  status: string;
  market_rent: number;
  available_date: string;
  floor_level: string;
  building_name: string;
}

export interface FloorPlanProps {
  id: number;
  plan: string;
  units: UnitProps[];
  bedrooms: number;
  bathrooms: number;
  square_footage: number;
  images: string[];
  promotion: PromotionProps;
}

export interface GptDataProps {
  response: {
    question: string;
    answer: string;
    tag: string;
    unit_type: string;
    start_question: number;
    end_question: number;
  }[];
}

export interface MessageProps {
  id: number;
  type?: string;
  message: string;
  date: string;
}

export const getBotResponse = async (question: string, uuid: string, domain: string): Promise<string | GptDataProps> => {
  try {
    const { data } = await axios.post<GptDataProps>(
      `${window.crmApp.config.chatbotHost}/extract_tips`,
      { sender: uuid, message: question, property_name: domain },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    }
    return 'An unexpected error occurred';
  }
};

export const setCarousels = (messageContainer: React.MutableRefObject<HTMLDivElement>): void => {
  if (messageContainer.current) {
    const unitsCarousels = messageContainer.current.querySelectorAll('.hobbes_chat-floorplans-carousel-container');
    if (unitsCarousels.length) {
      unitsCarousels.forEach((carouselContainer) => {
        // eslint-disable-next-line no-new
        new Carousel(carouselContainer as HTMLDivElement, 160, 3);

        const anchors = carouselContainer.querySelectorAll('a');
        if (anchors.length) {
          anchors.forEach((anchor) => {
            anchor.style.pointerEvents = 'none';
            anchor.style.cursor = 'default';
          });
        }

        const cards = carouselContainer.querySelectorAll('.hobbes_card');
        if (cards.length) {
          cards.forEach((card) => {
            const imageCarousel = card.querySelector('.hobbes_image-carousel');
            if (imageCarousel) {
              const images = imageCarousel.querySelectorAll('.hobbes_image-carousel-image');
              if (images.length > 1) {
                // eslint-disable-next-line no-new
                new ImageCarousel(imageCarousel as HTMLDivElement);
              }
            }
          });
        }
      });
    }

    const photoCarousels = messageContainer.current.querySelectorAll('.hobbes_chat-photo-carousel-container') as NodeListOf<HTMLDivElement>;
    if (photoCarousels.length) {
      photoCarousels.forEach((carouselContainer) => {
        const id = carouselContainer.getAttribute('id');
        const idNumber = id.split('-')[3];
        new PhotosCarouselDrawer([], idNumber).activate(carouselContainer);
      });
    }
  }
};

export const defaultStyle = {
  '&multiLine': {
    control: {
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      fontWeight: 'normal',
      fontSize: 13,
      border: 'none',
      overflow: 'auto',
      height: 70,
    },
    highlighter: {
      backgroundColor: 'transparent',
      fontWeight: 'normal',
      fontSize: 13,
      border: 'none',
      overflow: 'auto',
      height: 70,
    },
    input: {
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      fontWeight: 'normal',
      fontSize: 13,
      border: 'none',
      overflow: 'auto',
      height: 70,
    },
  },
};
