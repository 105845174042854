import { CommonActionResponse, PromiseActionType } from 'dwell/store/types';
import { AvailableUnitsProps } from 'leasing/store/lease/action-types';

export enum ActionType {
  RENEW_LEASE_REQUEST = 'RENEW_LEASE_REQUEST',
  RENEW_LEASE_SUCCESS = 'RENEW_LEASE_SUCCESS',
  RENEW_LEASE_FAILURE = 'RENEW_LEASE_FAILURE',

  CANCEL_RENEWAL_REQUEST = 'CANCEL_RENEWAL_REQUEST',
  CANCEL_RENEWAL_SUCCESS = 'CANCEL_RENEWAL_SUCCESS',
  CANCEL_RENEWAL_FAILURE = 'CANCEL_RENEWAL_FAILURE',

  CONFIRM_DETAILS_REQUEST = 'CONFIRM_DETAILS_REQUEST',
  CONFIRM_DETAILS_SUCCESS = 'CONFIRM_DETAILS_SUCCESS',
  CONFIRM_DETAILS_FAILURE = 'CONFIRM_DETAILS_FAILURE',
}

/*
 * Action
 */

export interface RenewLeaseAction extends CommonActionResponse {
  type: ActionType.RENEW_LEASE_REQUEST
  | ActionType.RENEW_LEASE_SUCCESS
  | ActionType.RENEW_LEASE_FAILURE;
  result: { data: { success: boolean, msg?: string } };
}

export interface CancelRenewalAction extends CommonActionResponse {
  type: ActionType.CANCEL_RENEWAL_REQUEST
  | ActionType.CANCEL_RENEWAL_SUCCESS
  | ActionType.CANCEL_RENEWAL_FAILURE;
  result: { data: AvailableUnitsProps };
}

export type Action =
  PromiseActionType
  | RenewLeaseAction
  | CancelRenewalAction;
