import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { isEmpty } from 'lodash';
import actions from 'dwell/actions/index';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import ModalNotification from 'dwell/views/chat/single_chat/_modalNotification';
import newAgentRequestSound from 'src/assets/audio/agent-transfer.mp3';
import { NotificationsWrapper } from 'dwell/views/chat/single_chat/styles';
// import { PropertyProps, UserProps, AgentRequestProps, Prospect, DetailResponse } from 'src/interfaces';
import { isMostRecentTab } from 'dwell/views/chat/common/utils';

const newAgentRequestSoundPlayer = new Audio(newAgentRequestSound);

const ChatNotifications: FC = () => {
  const selectedProperties = useSelector(selectSelectedProperties);
  const currentUser = useSelector(state => state.user.currentUser);
  const prospects = useSelector(state => state.prospectChat.prospects.filter(p => p.should_display_in_chat), shallowEqual);
  const prospectsRequestedAgents = useSelector(state => state.prospectChat.prospectsRequestedAgents);
  const isChatMinimized = useSelector(state => state.prospectChat.isChatMinimized);
  const chatType = useSelector(state => state.prospectChat.chatType);
  const currentTab = useSelector(state => state.prospectChat.currentTab);

  const [showNotification, setShowNotification] = useState({});
  const [showTransferNotification, setShowTransferNotification] = useState({});
  const playAgentRequestSoundInterval = useRef(null);

  const dispatch = useDispatch();

  const {
    joinProspect,
    readAll,
    setChatMinimiseStatus,
    setChatType,
    removeFromProspectsRequestedAgent,
    updateAgentRequest,
    reorderActiveChats,
    dismissNewMessage,
    setCurrentTab,
    setChatRenderedStatus,
    requestToJoinConversation,
  } = actions.prospectChat;
  const { updateUserAvailableStatus } = actions.user;

  const ids = selectedProperties.map(p => p.id);
  const filteredJoinRequests = prospectsRequestedAgents.filter(i => ids.includes(i.property));
  const prospectsCounter = prospects.length;
  const unreadcount = prospects.map(p => p.unread_count).reduce((a, b) => a + b, 0);

  useEffect(() => {
    const tabs = Object.keys(localStorage).filter(key => key.startsWith('tab')).sort((a, b) => a.localeCompare(b));
    const tabNumber = !isEmpty(tabs) ? Number(tabs[tabs.length - 1].replace('tab', '')) + 1 : 1;
    const tabKey = `tab${tabNumber}`;
    localStorage.setItem(tabKey, new Date().toISOString());
    dispatch(setCurrentTab(tabKey));

    const removeTabKey = () => {
      localStorage.removeItem(tabKey);
    };

    window.addEventListener('beforeunload', removeTabKey);
    return () => {
      window.removeEventListener('beforeunload', removeTabKey);
    };
  }, []);

  const clearAgentSound = () => {
    if (playAgentRequestSoundInterval.current) {
      clearInterval(playAgentRequestSoundInterval.current);
      playAgentRequestSoundInterval.current = null;
    }
  };

  useEffect(() => {
    if (!isEmpty(prospects)) {
      let showNotificationResult = { ...showNotification };
      prospects.forEach((prospect) => {
        showNotificationResult = {
          ...showNotificationResult,
          [prospect.external_id]: prospect.active_agent === currentUser.id && prospect.unread_count > 0 && prospect.has_not_seen_new_message,
        };
      });
      setShowNotification(showNotificationResult);

      let showTransferNotificationResult = { ...showTransferNotification };
      prospects.forEach((prospect) => {
        showTransferNotificationResult = {
          ...showTransferNotificationResult,
          [prospect.external_id]: true,
        };
      });
      setShowTransferNotification(showTransferNotificationResult);
    }
  }, [unreadcount]); // is we leave it as before with "prospects" we fall into the infinite rendering loop

  useEffect(() => {
    const prospectIds = prospects.map(p => p.id);
    const isActiveRequests = filteredJoinRequests.some(request => request.is_active && !request.is_declined && prospectIds.includes(request.prospect));
    if (isActiveRequests) {
      if (!playAgentRequestSoundInterval.current && isMostRecentTab(currentTab)) {
        playAgentRequestSoundInterval.current = setInterval(() => {
          newAgentRequestSoundPlayer.play();
        }, 1000);
      }

      setTimeout(() => {
        if (playAgentRequestSoundInterval.current) {
          clearInterval(playAgentRequestSoundInterval.current);
          playAgentRequestSoundInterval.current = null;
        }
      }, 30000);
    } else {
      // if agent request is handled on other tab or user, we should stop audio
      clearAgentSound();
    }
  }, [prospectsCounter, filteredJoinRequests]); // is we leave it as before with "prospects" we fall into the infinite rendering loop

  const viewMessage = (prospect) => {
    const newShowNotification = Object.assign({}, showNotification);
    newShowNotification[prospect.external_id] = false;
    setShowNotification(newShowNotification);
    dispatch(setChatRenderedStatus(true));
    dispatch(setChatMinimiseStatus(false));
    dispatch(setChatType('chat'));
    dispatch(reorderActiveChats({ id: prospect.id, isSMS: false, isSingleChat: true, minimized: false }));
  };

  const joinChat = (prospectId, prospectExternalId, prospectProperty) => {
    dispatch(setChatRenderedStatus(true));
    dispatch(setChatMinimiseStatus(false));
    dispatch(setChatType('chat'));
    clearAgentSound();
    dispatch(updateUserAvailableStatus(currentUser.id, { is_available: true }))
      .then(() => dispatch(requestToJoinConversation(prospectId, currentUser.id, prospectProperty))
        .then(({ result: { data: { success } } }) => {
          if (success) {
            dispatch(joinProspect({ prospect: prospectId, body: { type: 'JOINED', agent: currentUser.id } }, prospectProperty))
              .then(() => {
                const prospect = prospects.find(p => p.id === prospectId);
                if (prospect) dispatch(reorderActiveChats({ id: prospect.id, isSMS: false, isSingleChat: true, minimized: false }));
                dispatch(readAll(prospectId));
                dispatch(removeFromProspectsRequestedAgent(prospectId));
                setShowTransferNotification({ ...showTransferNotification, [prospectExternalId]: false });
              });
          }
        }));
  };

  const decline = (prospectId, prospectExternalId, requestId) => {
    dispatch(updateAgentRequest(requestId, { is_declined: true }));
    setShowTransferNotification({ ...showTransferNotification, [prospectExternalId]: false }); // hide
    clearAgentSound(); // mute
    dispatch(removeFromProspectsRequestedAgent(prospectId)); // remove from requested
  };

  const handleCloseTransferRequest = (prospectExternalId, requestId) => {
    dispatch(updateAgentRequest(requestId, { is_active: false }));
    clearAgentSound(); // mute
    setShowTransferNotification({ ...showTransferNotification, [prospectExternalId]: false });
  };

  const closeNotification = (prospect) => {
    const newShowNotification = Object.assign({}, showNotification);
    newShowNotification[prospect.external_id] = false;
    setShowNotification(newShowNotification);
    dispatch(dismissNewMessage(prospect.id));
  };

  return (
    <NotificationsWrapper>
      <div>
        {prospects.filter(prospect => !prospect.is_archived).map((prospect, index) => (
          <React.Fragment key={index}>
            {showNotification[prospect.external_id] ?
              <ModalNotification
                showingNotification={showNotification[prospect.external_id] && (isChatMinimized || chatType === 'sms')}
                type="NEW_MESSAGE"
                message={prospect.last_prospect_formatted_message}
                messageDate={prospect.last_prospect_message_date}
                handleClose={() => closeNotification(prospect)}
                unreadCount={prospect.unread_count}
                prospectName={prospect.name}
                prospectPropertyName={prospect.property_name}
                prospectId={prospect.external_id}
                viewMessage={() => viewMessage(prospect)}
                timeout={15}
              /> : null}
          </React.Fragment>))}
        {filteredJoinRequests.filter(request => request.is_active && !request.is_declined).map((request, index) => {
          const prospect = prospects.find(p => p.id === request.prospect);
          return (prospect && !isEmpty(prospect) ?
            <ModalNotification
              key={index}
              showingNotification={showTransferNotification[prospect.external_id] && (isChatMinimized || chatType === 'sms')}
              type="NEW_AGENT_REQUEST"
              messageDate={request.created}
              handleClose={() => handleCloseTransferRequest(prospect.external_id, request.id)}
              prospectName={prospect.name}
              prospectPropertyName={prospect.property_name}
              prospectId={prospect.external_id}
              joinChat={() => joinChat(prospect.id, prospect.external_id, prospect.property)}
              decline={() => decline(prospect.id, prospect.external_id, request.id)}
            /> : null);
        })
        }
      </div>
    </NotificationsWrapper>);
};

export default ChatNotifications;
