import { paths } from 'site/constants';
import { api } from 'application/constants/paths';
import { CallBackFunction, ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, CompanyPolicyProps, InsiderQuestion } from './action-types';

export default {
  saveCompanyPolicies: (data: CompanyPolicyProps, customer: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.SAVE_COMPANY_POLICIES_REQUEST,
        ActionType.SAVE_COMPANY_POLICIES_SUCCESS,
        ActionType.SAVE_COMPANY_POLICIES_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.COMPANY_POLICIES, data, { params: { customer } }),
      successCB,
    },
  }),

  getAmenityCategories: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_AMENITY_CATEGORY_REQUEST,
        ActionType.GET_AMENITY_CATEGORY_SUCCESS,
        ActionType.GET_AMENITY_CATEGORY_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.AMENITY_CATEGORIES),
    },
  }),

  getCustomerCompanyPolicies: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CUSTOMER_COMPANY_POLICIES_REQUEST,
        ActionType.GET_CUSTOMER_COMPANY_POLICIES_SUCCESS,
        ActionType.GET_CUSTOMER_COMPANY_POLICIES_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.CUSTOMER_COMPANY_POLICIES, id)),
    },
  }),

  getAnsweredQuestionsPercentage: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ANSWERED_QUESTIONS_PERCENTAGE_REQUEST,
        ActionType.GET_ANSWERED_QUESTIONS_PERCENTAGE_SUCCESS,
        ActionType.GET_ANSWERED_QUESTIONS_PERCENTAGE_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.ANSWERED_QUESTIONS_PERCENTAGE),
    },
  }),

  getInsiderQuestions: (params?: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_INSIDER_QUESTIONS_REQUEST,
        ActionType.GET_INSIDER_QUESTIONS_SUCCESS,
        ActionType.GET_INSIDER_QUESTIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.INSIDER_QUESTIONS, { params }),
    },
  }),

  partialUpdateInsiderQuestions: (id: number, properties: number[]): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_INSIDER_QUESTION_REQUEST,
        ActionType.UPDATE_INSIDER_QUESTION_SUCCESS,
        ActionType.UPDATE_INSIDER_QUESTION_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.UPDATE_INSIDER_QUESTION, id), { properties }),
    },
  }),

  deleteInsiderQuestions: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_INSIDER_QUESTION_REQUEST,
        ActionType.UPDATE_INSIDER_QUESTION_SUCCESS,
        ActionType.UPDATE_INSIDER_QUESTION_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.UPDATE_INSIDER_QUESTION, id)),
    },
  }),

  updateInsiderQuestion: (data: InsiderQuestion): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_INSIDER_QUESTION_REQUEST,
        ActionType.UPDATE_INSIDER_QUESTION_SUCCESS,
        ActionType.UPDATE_INSIDER_QUESTION_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.UPDATE_INSIDER_QUESTION, data.id), data),
    },
  }),

  createInsiderQuestion: (data: InsiderQuestion): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_INSIDER_QUESTION_REQUEST,
        ActionType.CREATE_INSIDER_QUESTION_SUCCESS,
        ActionType.CREATE_INSIDER_QUESTION_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.INSIDER_QUESTIONS, data),
    },
  }),

  getFloorPlansWithUnits: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_FLOOR_PLANS_W_UNITS_REQUEST,
        ActionType.GET_FLOOR_PLANS_W_UNITS_SUCCESS,
        ActionType.GET_FLOOR_PLANS_W_UNITS_SUCCESS,
      ],
      promise: client => client.get(api.v1.FLOOR_PLANS_WITH_UNIT),
    },
  }),

  getInsiderQuestionCategories: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_INSIDER_QUESTION_CATEGORY_REQUEST,
        ActionType.GET_INSIDER_QUESTION_CATEGORY_SUCCESS,
        ActionType.GET_INSIDER_QUESTION_CATEGORY_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.INSIDER_QUESTION_CATEGORIES),
    },
  }),
};
