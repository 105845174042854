import React, { FC, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { isEmpty } from 'lodash';
import actions from 'dwell/actions/index';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { notificationTypes, RECENT_NOTIFICATIONS_OFFSET, INITIAL_NOTIFICATIONS_OFFSET, NOTIFICATIONS_OFFSET } from 'dwell/constants';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import NotificationDetail from './_notificationDetails';
import { NotificationBell, notifDropdownMenuStyles, LinkButton, NotificationEmpty, NotificationList, NotificationsDropdownHeader, NotificationsDropdownTitle, NotificationWrapper, NotificationsNumber, MoreButton } from './styles';

const Notifications: FC = () => {
  const { push } = useHistory();
  const [dropdownOpen, setDropdownState] = useState(false);
  const [notificationToDisplay, setNotificationToDisplay] = useState([]);
  const notificationListRef = useRef(null);
  const [remainingItems, setRemainingItems] = useState(0);
  const [showMore, setShowMore] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState<number>();

  const selectedProperties = useSelector(selectSelectedProperties);
  const allNotificationsCount = useSelector(state => state.notification.allNotificationsCount);
  const nonReadedCount = useSelector(state => state.notification.nonReadedNotificationsCount);
  const notifications = useSelector(state => state.notification.notifications);
  const isLoaded = useSelector(state => state.notification.isLoaded);
  const pushNotification = useSelector(state => state.pusher.pushNotification);
  const currentUserData = useSelector(state => state.user.currentUser);
  const isChatMinimized = useSelector(state => state.prospectChat.isChatMinimized);
  const currentContact = useSelector(state => state.smsMessage.currentContact);
  const dispatch = useDispatch();
  const { setChatRenderedStatus, setChatType, setChatAsActive, setChatMinimiseStatus } = actions.prospectChat;
  const { getNotifications, updateNotificationById, readAllNotifications, clearAndGetNotifications } = actions.notification;

  const handleUpdate = (isUpdated) => {
    if (isUpdated && !isLoaded) {
      dispatch(getNotifications({ offset: 0, limit: RECENT_NOTIFICATIONS_OFFSET }));
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedProperties)) {
      dispatch(clearAndGetNotifications({ offset: 0, limit: INITIAL_NOTIFICATIONS_OFFSET }));
    }
  }, [selectedProperties]);

  const sendNotification = (pushNotif) => {
    const options = {
      body: pushNotif.content.replace(/(<([^>]+)>)/g, ''),
      requireInteraction: true,
    };
    const notification = new Notification(notificationTypes.NOTIFICATION_TYPES[pushNotif.type], options);
    notification.onclick = () => {
      dispatch(updateNotificationById(pushNotif.id, { is_read: true })).then(() => handleUpdate(true));
      window.focus();
      if (pushNotif.type === 'NEW_SMS') {
        dispatch(setChatAsActive({
          id: parseInt(pushNotif.redirect_url.split('/').pop(), 10),
          isSMS: true,
          isSingleChat: true,
          property_external_id: pushNotif.property?.external_id,
        }));
        dispatch(setChatRenderedStatus(true));
        dispatch(setChatMinimiseStatus(false));
        dispatch(setChatType('sms'));
      } else {
        push(pushNotif.redirect_url, pushNotif.type === 'TEAM_MENTION' ? { tab: 'notes' } : null);
        window.location.reload();
      }
    };
  };

  const notificationsScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = notificationListRef.current;
    const hiddenItems = Math.floor((scrollHeight - (scrollTop + clientHeight)) / 76);
    setRemainingItems(hiddenItems);
    if (scrollTop + clientHeight === scrollHeight) {
      setShowMore(false);
    }
    if (notificationToDisplay.length === allNotificationsCount) return;
    if (scrollTop + clientHeight === scrollHeight) {
      dispatch(getNotifications({ offset: notificationToDisplay.length, limit: NOTIFICATIONS_OFFSET })).then(() => setShowMore(true));
    }
  };

  const scrollToBottom = () => {
    const element = notificationListRef.current;
    element.scrollTo({ left: 0, top: element.scrollHeight, behavior: 'smooth' });
    setShowMore(false);
  };

  useEffect(() => {
    const targetElement = notificationListRef.current;
    if (dropdownOpen && targetElement) {
      disableBodyScroll(targetElement);
    } else {
      clearAllBodyScrollLocks();
      setShowMore(true);
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!isEmpty(pushNotification) && typeof Notification !== 'undefined') {
      handleUpdate(true);
      if (pushNotification.type === 'NEW_SMS' && currentUserData.email !== pushNotification.lead_owner) return;
      if (document.visibilityState === 'visible') return;
      if (!('Notification' in window)) return;
      if (Notification.permission === 'granted') {
        sendNotification(pushNotification);
      } else {
        Promise.resolve(Notification.requestPermission()).then((permission) => {
          if (permission === 'granted') {
            sendNotification(pushNotification);
          }
        });
      }
    }
  }, [pushNotification]);

  useEffect(() => {
    setNotificationToDisplay(notifications);
  }, [notifications]);

  useEffect(() => {
    setUnreadNotifications(nonReadedCount);
  }, [nonReadedCount]);

  const markAllNotificationsAsRead = () => {
    dispatch(readAllNotifications()).then(() => setTimeout(() => handleUpdate(true), 500));
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownState(!dropdownOpen)}>
      <NotificationBell onClick={() => setDropdownState(!dropdownOpen)} unread={unreadNotifications}>
        <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
          <i className="ri-notification-3-line" />
        </DropdownToggle>
      </NotificationBell>
      <DropdownMenu
        modifiers={{
          setWidth: {
            enabled: true,
            fn: data => ({
              ...data,
              styles: {
                ...data.styles,
                ...notifDropdownMenuStyles,
              },
            }),
          },
        }}
        right
      >
        <NotificationsDropdownHeader header className="header" tag="div">
          <NotificationsDropdownTitle className="title">
            Notifications
            {!!unreadNotifications && <NotificationsNumber>{unreadNotifications}</NotificationsNumber>}
          </NotificationsDropdownTitle>
          {!!unreadNotifications && <LinkButton onClick={markAllNotificationsAsRead}>Mark All Read</LinkButton>}
        </NotificationsDropdownHeader>
        {!isEmpty(notificationToDisplay) ? (
          <>
            <NotificationList ref={notificationListRef} notifications={notificationToDisplay} onScroll={() => notificationsScroll()}>
              {notificationToDisplay.map(notification => (
                <NotificationWrapper key={notification.id} notification={notification}>
                  <NotificationDetail notification={notification} onUpdate={handleUpdate} notificationsArray={notificationToDisplay} isChatMinimized={isChatMinimized} currentContact={currentContact} />
                </NotificationWrapper>
              ))}
            </NotificationList>
            {showMore && remainingItems > 0 && (
              <MoreButton onClick={scrollToBottom}>
                <i className="ri-arrow-down-line" />
                <span> {remainingItems} more</span>
              </MoreButton>
            )}
          </>
        ) : (
          <NotificationEmpty>
            <i className="ri-checkbox-multiple-fill" />
            <span>No new notifications</span>
          </NotificationEmpty>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notifications;
