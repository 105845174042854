import pageData from './page_data/action-creators';
import client from './client/action-creators';
import customer from './customer/action-creators';
import tooltips from './tooltips/action-creators';
import promotion from './promotion/action-creators';
import hobbes from './hobbes/action-creators';

export default {
  pageData,
  client,
  customer,
  tooltips,
  promotion,
  hobbes,
};
