import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, StorageProps } from './action-types';

export default {
  createStorage: (leaseId: number, body: StorageProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_STORAGE_REQUEST,
        ActionType.CREATE_STORAGE_SUCCESS,
        ActionType.CREATE_STORAGE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.STORAGES, leaseId), body),
    },
  }),

  deleteStorage: (leaseId: number, storageId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_STORAGE_REQUEST,
        ActionType.DELETE_STORAGE_SUCCESS,
        ActionType.DELETE_STORAGE_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.STORAGE_DETAILS, leaseId, storageId)),
    },
  }),

  updateStorage: (leaseId: number, storageId: number, params: StorageProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_STORAGE_REQUEST,
        ActionType.UPDATE_STORAGE_SUCCESS,
        ActionType.UPDATE_STORAGE_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.STORAGE_DETAILS, leaseId, storageId), params),
    },
  }),
};
