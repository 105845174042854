import React, { FC, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { UserPlus } from 'react-feather';
import { LineSkeleton, formatTimeByTimezone } from 'src/utils';
import { fieldChoices, TOUR_TYPES, alphabet } from 'dwell/constants';
import LeadShareModal from 'dwell/components/leads/lead_share_modal';
import TransferToApplication from 'dwell/components/leads/lead_transfer_to_application_modal';
import LeadLostDialog from 'dwell/components/leads/lead_lost_dialog';
import { AttentionModal } from 'dwell/components';
import { UserProps } from 'dwell/store/user/action-types';
import { LostReason } from 'src/interfaces';
import { Lead } from 'dwell/store/lead/action-types';
import actions from 'dwell/actions';
import { selectHasApplicantStarted } from 'leasing/store/applicant/reducers';
import { selectProperty } from 'dwell/store/property/reducers';
import { CustomSearchIcon, FormSearchDiv, LetterLabel } from 'dwell/components/ordered_dropdown/styles';
import {
  ContentNavBar, NavLeadOption, StageDropdown, StageDropdownItem, StageDropdownMenu, DropdownLink, StageDropdownLink,
  UnassignedItem, StatusDropdownMenu, StatusDropdownItem, MoreOptionDropdownMenu,
  MoreOptionDropdown, MoreOptionDropdownLink, MoreOptionDropdownItem, OwnerDropdownMenu,
  CustomFormSearch,
  CustomDropdownItem,
} from './style';
import RequestLogs from './_request_logs';
import UnenrollLeadModal from './_unenroll_lead_modal';

interface NavLeadOptionProps {
  lead: Lead,
  handleSave: (params: {stage?: string, status?: string, lost_reason?: LostReason}) => void,
  availableOwners: UserProps[],
}

const NavLeadOptions: FC<NavLeadOptionProps> = ({ lead, availableOwners, handleSave }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [dropdownOwnersOpen, setDropdownOwnersOpen] = useState(false);
  const [dropdownMoreOptionOpen, setDropdownMoreOptionOpen] = useState(false);
  const [showLeadShareModal, setShowLeadShareModal] = useState(false);
  const [showRequestLogsModal, setShowRequestLogsModal] = useState(false);
  const [showUnenrollLeadModal, setShowUnenrollLeadModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [attentionModalOpen, setAttentionModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [focus, setFocus] = useState(false);

  const { removeLeadChatFromActiveChats } = actions.prospectChat;
  const { addEmailPopout } = actions.emailPopout;
  const dispatch = useDispatch();
  const hasApplicantStarted = useSelector(selectHasApplicantStarted);
  const property = useSelector(selectProperty);
  const activeChats = useSelector(state => state.prospectChat.activeChats);
  const { stage, status, owner } = lead;
  const tasks = useSelector(state => state.task.objectTasks);
  const openTours = tasks.filter(task => Object.keys(TOUR_TYPES).includes(task.type) && task.status !== 'COMPLETED');

  const leadStatus = useMemo(() => {
    const statusChoices = fieldChoices.LEAD_FILED_CHOICES.status;
    if (property.is_using_leasing_application) {
      statusChoices.CLOSED.hide = true;
    }
    return statusChoices;
  }, [property.is_using_leasing_application]);
  const stageChoices = property.is_using_leasing_application ? fieldChoices.LEAD_FILED_CHOICES.leaseStage : fieldChoices.LEAD_FILED_CHOICES.stage;

  const filteredAvailableOwners = useMemo(() => {
    if (!availableOwners.length) return availableOwners;
    if (!keyword) return availableOwners;

    return availableOwners.filter(o => `${o.first_name} ${o.last_name}`.toUpperCase().includes(keyword.toUpperCase()));
  }, [availableOwners, keyword]);

  const orderedAvailableOwners = useMemo(() => {
    if (!filteredAvailableOwners.length) return {};

    const temp = {} as { [key: string]: UserProps[] };
    alphabet.forEach((letter) => {
      const filteredOrderedOwners = filteredAvailableOwners.filter(o => `${o.first_name} ${o.last_name}`.toUpperCase().startsWith(letter));
      if (filteredOrderedOwners.length) temp[letter] = filteredOrderedOwners;
    });
    return temp;
  }, [filteredAvailableOwners]);

  const handleStageSave = (id, value) => {
    if (value === 'LOST' && status !== value) {
      setShowReasonModal(true);
    } else if (id === 'stage' && value === 'TOUR_COMPLETED' && stage !== value && openTours.length) {
      setAttentionModalOpen(true);
    } else {
      if (value === 'TEST') {
        if (activeChats.map(ac => ac.id).includes(lead.id)) {
          dispatch(removeLeadChatFromActiveChats(lead.id));
        }
      }
      handleSave({ [id]: value });
    }
  };

  const handleShare = () => {
    setShowLeadShareModal(true);
  };

  const handleSaveLostReason = (value) => {
    setShowReasonModal(false);
    handleSave({ lost_reason: value, status: 'LOST' });
  };

  const toggleEmailWindow = () => {
    dispatch(addEmailPopout(lead, property, false, { preloadTemplate: 'LEASE_APPLICATION_FROM_PROPERTY' }));
  };

  const handleAccept = () => {
    handleSave({ stage: 'TOUR_COMPLETED' });
    setAttentionModalOpen(false);
  };

  const onSearch = ({ target: { value } }) => {
    setKeyword(value);
  };

  const lead_owner = availableOwners.find(availableOwner => availableOwner.id === owner);
  const setOwner = lead_owner ? `${lead_owner.first_name} ${lead_owner.last_name}` : (<React.Fragment><UserPlus size="16" /> <UnassignedItem>Unassigned</UnassignedItem></React.Fragment>);

  return (
    <ContentNavBar>
      <NavLeadOption>
        {!isEmpty(lead) ?
          <StageDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <StageDropdownLink caret className={stage ? stage.toLowerCase() : 'bg-white'}>
              {stage ? stageChoices[stage] : 'Select stage'}
            </StageDropdownLink>
            <StageDropdownMenu >
              {Object.keys(stageChoices).map((key, index) => (
                <React.Fragment key={index}>
                  <StageDropdownItem
                    onClick={() => handleStageSave('stage', key)}
                    className={key.toLowerCase()}
                  >
                    {stageChoices[key]}
                  </StageDropdownItem>
                </React.Fragment>))}
            </StageDropdownMenu>
          </StageDropdown> : <LineSkeleton width={150} height={38} />}

        {!isEmpty(lead) ?
          <StageDropdown isOpen={dropdownOwnersOpen} toggle={() => setDropdownOwnersOpen(!dropdownOwnersOpen)}>
            <DropdownLink caret>
              {setOwner}
            </DropdownLink>
            <OwnerDropdownMenu>
              <FormSearchDiv className={`position-relative mr-10 ${focus ? 'focus' : ''}`} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
                <CustomSearchIcon />
                <CustomFormSearch
                  name="search"
                  value={keyword ?? ''}
                  onChange={onSearch}
                  placeholder="Search"
                />
              </FormSearchDiv>
              {
                Object.keys(orderedAvailableOwners).length ? Object.keys(orderedAvailableOwners).map((letter, index) => (
                  <div key={index}>
                    <LetterLabel header>{letter}</LetterLabel>
                    {
                      orderedAvailableOwners[letter].map((current_owner, owner_index) => (
                        <CustomDropdownItem
                          key={owner_index}
                          onClick={() => handleStageSave('owner', current_owner.id)}
                          className={current_owner.id === owner ? 'selected' : ''}
                        >
                          {current_owner.first_name} {current_owner.last_name}
                        </CustomDropdownItem>
                      ))
                    }
                  </div>
                )) : <CustomDropdownItem disabled>No results found</CustomDropdownItem>
              }
            </OwnerDropdownMenu>
          </StageDropdown> : <LineSkeleton width={100} height={38} style={{ marginLeft: '8px' }} />}

        {!isEmpty(lead) ?
          <StageDropdown isOpen={dropdownStatusOpen} toggle={() => setDropdownStatusOpen(!dropdownStatusOpen)}>
            <DropdownLink caret>
              {status ? leadStatus[status]?.title : 'Select status'}
            </DropdownLink>
            <StatusDropdownMenu right >
              {Object.keys(leadStatus).filter(el => !leadStatus[el].hide).map((key, index) => (
                <React.Fragment key={`status${index}`}>
                  <StatusDropdownItem onClick={() => handleStageSave('status', key)} selected={status === key} >
                    <strong>
                      {leadStatus[key].title}
                      {status === key && <i className="ri-check-line" />}
                    </strong>
                    <span>
                      {leadStatus[key].text}
                    </span>
                  </StatusDropdownItem>
                </React.Fragment>))}
            </StatusDropdownMenu>
          </StageDropdown> : <LineSkeleton width={80} height={38} style={{ marginLeft: '8px' }} />}

      </NavLeadOption>
      {!isEmpty(lead) ?
        <MoreOptionDropdown isOpen={dropdownMoreOptionOpen} toggle={() => setDropdownMoreOptionOpen(!dropdownMoreOptionOpen)}>
          <MoreOptionDropdownLink tag="span" id="action_dropdown">
            <i className="ri-more-2-fill" />
          </MoreOptionDropdownLink>
          <MoreOptionDropdownMenu right $noLeft>
            <MoreOptionDropdownItem onClick={handleShare} tag="a">
              <i className="ri-share-line" /> Share Lead
            </MoreOptionDropdownItem>
            {lead.active_nurture &&
              <MoreOptionDropdownItem onClick={() => setShowUnenrollLeadModal(true)} tag="a">
                <i /> Unenroll Lead
              </MoreOptionDropdownItem>}
            <MoreOptionDropdownItem onClick={() => setShowRequestLogsModal(true)} tag="a" test-id="open-request-logs">
              <i className="ri-timeline-view" /> Request Logs
            </MoreOptionDropdownItem>
            {property.is_using_leasing_application && !hasApplicantStarted &&
              (!lead.application_sent_time || formatTimeByTimezone().diff(formatTimeByTimezone(lead.application_sent_time), 'hours') > 4) &&
            <MoreOptionDropdownItem onClick={() => toggleEmailWindow()} tag="a" id="send_application">
              <i className="ri-mail-send-line" /> {formatTimeByTimezone().diff(formatTimeByTimezone(lead.application_sent_time), 'hours') > 4 ? 'Resend Application' : 'Send Application'}
            </MoreOptionDropdownItem>}
            {property.is_using_leasing_application && !['LOST', 'TEST'].includes(lead.status) && (
              <MoreOptionDropdownItem onClick={() => setShowTransferModal(true)} tag="a" id="transfer_application">
                <i className="ri-user-shared-line" /> Transfer to Application
              </MoreOptionDropdownItem>
            )}
          </MoreOptionDropdownMenu>
        </MoreOptionDropdown> : <LineSkeleton width={80} height={38} style={{ marginLeft: '8px' }} />}
      <LeadLostDialog
        show={showReasonModal}
        handleClose={() => setShowReasonModal(false)}
        handleSave={(value) => {
          handleSaveLostReason(value);
        }}
      />
      <AttentionModal
        isOpen={attentionModalOpen}
        toggle={() => setAttentionModalOpen(!attentionModalOpen)}
        bodyText="This action will mark all open tour task(s) as complete. Do you wish to continue?"
        handleAccept={handleAccept}
      />

      <LeadShareModal lead={lead} show={showLeadShareModal} handleClose={() => setShowLeadShareModal(false)} />
      <RequestLogs show={showRequestLogsModal} handleClose={() => setShowRequestLogsModal(false)} />
      <UnenrollLeadModal lead={lead} show={showUnenrollLeadModal} handleClose={() => setShowUnenrollLeadModal(false)} />
      {showTransferModal && <TransferToApplication show handleClose={() => setShowTransferModal(false)} />}
    </ContentNavBar>
  );
};

export default NavLeadOptions;
