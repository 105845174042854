import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import unit_types from 'dwell/constants/unit_types';
import { isEmpty, isNull } from 'lodash';
import axios from 'axios';
import actions from 'leasing/store/actions';
import { LeaseUnitFilterParams } from 'leasing/store/types';
import { selectLease } from 'leasing/store/lease/reducers';
import { selectUnits } from 'leasing/store/unit/reducers';
import UnitBlock from './_unit_block';
import { ModalLabel, UnitFilterRow, ViewLink, MultiSelect, UnitScrollContainer, UnitEmptyContainer } from './styles';
import Map from '../../../../static/images/map.png';

interface LeaseUnitModalProps {
  onCancel: () => void,
  onConfirm: (id: number) => void,
  show: boolean,
}

const LeaseUnitModal: FC<LeaseUnitModalProps> = ({ onCancel, onConfirm, show }) => {
  const [filteredBedrooms, setFilteredBedrooms] = useState([]);
  const [filteredBathrooms, setFilteredBathrooms] = useState([]);
  const [moveInDate, setMoveInDate] = useState(null);
  const [floorPlan, setFloorPlan] = useState([]);

  const [isShowingMap, setIsShowingMap] = useState(false);
  const [selectedMap, setSelectedMap] = useState('');
  const [backdrop, setBackdrop] = useState<'static' | boolean>(true);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const filters = useRef(null);

  const dispatch = useDispatch();
  const { getUnits } = actions.unit;
  const units = useSelector(selectUnits);
  const lease = useSelector(selectLease);
  const currentProperty = useSelector(state => state.property.property);
  const lead = useSelector(state => state.lead.lead);
  const node = useRef(null);
  const cancelToken = useRef(null);

  const closeBtn = <button className="close" onClick={() => onCancel()}>&times;</button>;

  useEffect(() => {
    if (lead) {
      setFilteredBathrooms(unit_types.BATHROOM_TYPES.length - 1 >= lead.baths ? [unit_types.BATHROOM_TYPES[lead.baths + 1]] : []);
      setFilteredBedrooms(unit_types.BEDROOM_TYPES.length >= lead.beds ? [unit_types.BEDROOM_TYPES[lead.beds]] : []);
      setMoveInDate(moment().diff(lead.move_in_date) > 0 ? moment().add(1, 'month').date(1).format('YYYY-MM-DD') : lead.move_in_date);
    }
  }, [lead]);

  useEffect(() => {
    if (!isEmpty(lease)) {
      setSelectedUnit(lease.unit);
    }
  }, [lease]);

  useEffect(() => {
    let params = { property: currentProperty.name } as LeaseUnitFilterParams;
    if (!isEmpty(filteredBedrooms)) {
      params = { ...params, bed_rooms: filteredBedrooms };
    }
    if (!isEmpty(filteredBathrooms)) {
      params = { ...params, bath_rooms: filteredBathrooms };
    }
    if (!isEmpty(floorPlan)) {
      params = { ...params, floor_plan: floorPlan };
    }
    if (moveInDate !== null) {
      params = { ...params,
        move_in_date: moveInDate,
      };
    }
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();
    dispatch(getUnits(params, cancelToken.current.token));
  }, [filteredBedrooms, filteredBathrooms, moveInDate, floorPlan]);

  const handleClick = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setIsShowingMap(false);
    }
  };

  const handleShowMap = (map) => {
    setSelectedMap(map);
    setIsShowingMap(true);
  };

  useEffect(() => {
    if (isShowingMap) {
      setBackdrop('static');
    } else {
      setTimeout(() => setBackdrop(true), 300);
    }
  }, [isShowingMap]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const containerMaxHeight = `calc(90vh - ${filters.current ? 278 + filters.current.clientHeight : 278 + 42}px)`;

  return (
    <Modal
      isOpen={show}
      toggle={() => onCancel()}
      centered
      aria-labelledby="example-custom-modal-styling-title"
      className="unit-modal"
      backdrop={backdrop}
      size={isShowingMap ? 'sm' : 'lg'}
      keyboard={!isShowingMap}
      ref={node}
    >
      <div style={{ display: isShowingMap ? 'block' : 'none', overflowY: isShowingMap ? 'auto' : 'hidden' }} ref={node}><img src={selectedMap} alt="" className="img-fluid" style={{ width: '100%' }} /></div>
      <div style={{ display: isShowingMap ? 'none' : 'block' }}>
        <ModalHeader close={closeBtn}>{ `Select A Unit ${selectedUnit ? '-' : ''} ${(currentProperty.units.find(item => item.id === selectedUnit) || { unit: '' }).unit}` }</ModalHeader>
        <ModalBody>
          <ModalLabel>Filter By:</ModalLabel>
          <UnitFilterRow ref={filters}>
            <MultiSelect
              defaulValue="Bedrooms"
              caret
              isMulti
              style={{ marginLeft: '20px' }}
              value={!isEmpty(filteredBedrooms) ? currentProperty.bedroom_types.filter(item => filteredBedrooms.includes(item)).map(item => ({ value: item, label: unit_types.SELECT_UNIT_TYPES[item] })) : 'Bedrooms'}
              options={currentProperty.bedroom_types.map(item => ({ value: item, label: unit_types.SELECT_UNIT_TYPES[item] }))}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              className="basic-multi-select ml-1"
              classNamePrefix="select"
              placeholder="Select bedrooms"
              labelledBy="Select bedrooms"
              onChange={selectedBedroom => setFilteredBedrooms(isNull(selectedBedroom) ? [] : selectedBedroom.map(item => item.value))}
            >
            </MultiSelect>
            <MultiSelect
              defaulValue="Bathrooms"
              caret
              isMulti
              style={{ marginLeft: '20px' }}
              value={!isEmpty(filteredBathrooms) ? currentProperty.bathroom_types.filter(item => filteredBathrooms.includes(item)).map(item => ({ value: item, label: unit_types.SELECT_UNIT_TYPES[item] })) : 'Bathrooms'}
              options={currentProperty.bathroom_types.map(item => ({ value: item, label: unit_types.SELECT_UNIT_TYPES[item] }))}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              className="basic-multi-select ml-2"
              classNamePrefix="select"
              placeholder="Select bathrooms"
              labelledBy="Select bathrooms"
              onChange={selectedBathroom => setFilteredBathrooms(isNull(selectedBathroom) ? [] : selectedBathroom.map(item => item.value))}
            />
            <DatePicker
              isClearable
              showPopperArrow={false}
              className="ml-2 applicant-date-picker"
              calendarClassName="leasing-datepicker"
              minDate={new Date()}
              selected={moveInDate ? new Date(moveInDate) : null}
              onChange={(date: Date) => setMoveInDate(date ? moment(date).format('YYYY-MM-DD') : null)}
              dateFormat="MM/dd/yyyy"
              placeholderText="Move-In Date"
              todayButton="Today"
            />
            <MultiSelect
              caret
              isMulti
              style={{ marginLeft: '20px' }}
              value={!isEmpty(floorPlan) ? currentProperty.floor_plans.filter(items => floorPlan.includes(items.id)).map(items => ({ value: items.id, label: items.plan })) : 'Select floor plan'}
              options={currentProperty.floor_plans.map(items => ({ value: items.id, label: items.plan }))}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              className="basic-multi-select ml-3"
              classNamePrefix="select"
              placeholder="Select floor plan"
              labelledBy="Select floor plan"
              onChange={selectedFloorPlan => setFloorPlan(isNull(selectedFloorPlan) ? [] : selectedFloorPlan.map(item => item.value))}
            />
          </UnitFilterRow>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <ModalLabel>{units.length} matching units</ModalLabel>
            <ViewLink onClick={() => handleShowMap(Map)}>View Community Map</ViewLink>
          </div>
          <UnitScrollContainer $maxHeight={containerMaxHeight}>
            {units.length ?
              <Row>
                {units.map(item => (
                  <Col sm={4} className="mb-3" onClick={() => setSelectedUnit(item.id)}>
                    <UnitBlock unit={item} selected={item.id === selectedUnit} handleShowMap={handleShowMap} />
                  </Col>
                ))}
              </Row> :
              <UnitEmptyContainer className="unit-empty">
                <i className="ri-community-fill" />
                <h6>No units matched your filter criteria</h6>
              </UnitEmptyContainer>}
          </UnitScrollContainer>
        </ModalBody>
        <ModalFooter>
          <Button className="btn" color="secondary" onClick={() => onCancel()}>Cancel</Button>
          <Button
            className="btn"
            color="primary"
            disabled={!selectedUnit || isEmpty(units) || !units.map(u => u.id).includes(selectedUnit)}
            onClick={() => onConfirm(selectedUnit)}
          >Select Unit
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default LeaseUnitModal;
