import styled from 'styled-components';
import { Label } from 'reactstrap';
import Select from 'react-select';

export const ModalLabel = styled(Label)`
    font-size: 13px;
    color: #344563 !important;
`;

export const UnitFilterRow = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
`;

export const UnitScrollContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: ${props => props.$maxHeight};
    height: calc(90vh - 320px);
`;

export const UnitEmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    i {
        font-size: 64px;
        line-height: 1;
        opacity: .3;
        margin-bottom: 10px;
    }

    h6 {
        margin-bottom: 0;
        color: #929eb9;
        font-weight: 400;
    }
`;

export const MultiSelect = styled(Select)`
    width: 100%;
    min-height: 42px;
    .select__control {
        min-height: 42px !important;
        border-color: #d5dcf4;
        border-radius: 6px;
    }
    .select__control--is-focused {
        outline: none;
        border-color: #d5dcf4;
        box-shadow: none !important;
    }
    .select__value-container {
    &:focus {
        outline: none;
    }
    }
    .select__indicator {
      svg {
      fill: #d5dcf4;
      }
    }
    .select__indicator-separator {
        background-color: #d5dcf4;
    }
    .select__multi-value {
      display: flex;
      flex-direction: row-reverse;
      background-color: #0168fa;
      color: #fff;
      border-radius: 3px;
      padding: 1px 10px 1px 2px;
    }
    .select__multi-value__label {
        background-color: #0168fa;
        color: #fff;
        font-size: 13px;
    }
    .select__multi-value__remove {
        background-color: #0168fa;
        color: #fff;
        cursor: pointer;
  &:hover {
      svg {
        color: #333;
      }
  }
  svg {
    opacity: .5;
  }
    }
    .select__option {
        padding: 6px 10px;
        border: none;
        border-radius: 4px;
        margin-bottom: 1px;
    }
    .select__option--is-focused {
        background-color: #0168fa;
        color:white;
    }
    .select__menu-list {
        padding: 4px;
    }
`;

export const ViewLink = styled.a`
    font-size: 13px;
    cursor: pointer;
    color: #0168fa !important;

    &:hover {
        color: #0148ae !important;
    }
`;

export const Unit = styled.div`
    border: 1px solid #d5dcf4;
    border-radius: 4px;
    position: relative;
    transition: all 0.2s;
    display: flex;
    align-items: flex-start;

    &:hover, &:focus {
        cursor: pointer;
        border-color: #c1cbef;
        box-shadow: inset 0 0 0 0.5px #c1cbef;
    }

    &.selected {
        border-color: #0168fa;
        box-shadow: inset 0 0 0 0.5px #0168fa;
    }
`;

export const UnitImg = styled.div`
    width: 170px;
    align-self: center;
    padding: 2px;

    img {
        cursor: zoom-in;
    }
`;

export const UnitImgLabel = styled.label`
    position: absolute;
    top: 5px;
    left: 5px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.default};
    margin-bottom: 0;
    display: block;
    line-height: 1;
    color: #929eb9;
`;

export const UnitBody = styled.div`
    flex-shrink: 0;
    border-left: 1px solid #d5dcf4;
    padding: 15px;
    font-size: 13px;
    color: #4a5e8a;
    flex: 1;

    h6 {
        font-weight: 600;
        margin-bottom: 2px;
        color: #0b2151;
    }

    p {
        margin-bottom: 0;
    }
`;
