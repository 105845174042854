import axios from 'axios';
import { get } from 'lodash';
import { RefreshTokenResponse } from 'src/interfaces';
import { LOGGED_ACCOUNT, paths } from 'dwell/constants';
import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';

const oidcConfig: UserManagerSettings = {
  response_type: 'code',
  automaticSilentRenew: true,
  scope: 'openid profile email',
  revokeAccessTokenOnSignout: true,
  authority: process.env.KEYCLOAK_URL,
  redirect_uri: process.env.REDIRECT_URI,
  client_id: process.env.FE_KEYCLOAK_CLIENT_ID,
  post_logout_redirect_uri: process.env.POST_LOGOUT_REDIRECT_URI,
  userStore: new WebStorageStateStore({ store: window.localStorage }),

};

const userManager = new UserManager(oidcConfig);

userManager.events.addAccessTokenExpiring(async () => {
  const user = await userManager.getUser();
  const loggedAccount = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};
  const client = axios.create({
    baseURL: get(window, 'crmApp.config.crmHost', '/'),
  });

  const backchannelLogout = async (
    refresh_token: string,
    access_token: string,
  ) => {
    await client.post(
      paths.api.v1.BACKCHANNEL_LOGOUT,
      { refresh_token },
      { headers: { Authorization: `Bearer ${access_token}` } },
    );
    window.location.href = '/login';
  };

  try {
    if (user && user.refresh_token && loggedAccount.access) {
      const response = await client.post<RefreshTokenResponse>(
        paths.api.v1.KC_REFRESH_TOKEN,
        { refresh_token: user.refresh_token },
        { headers: { Authorization: `Bearer ${user.access_token}` } },
      );

      if (response.data.access_token) {
        user.access_token = response.data.access_token;
        user.refresh_token = response.data.refresh_token || user.refresh_token;
        user.expires_at = Date.now() + (response.data.expires_in * 1000);
        loggedAccount.access = response.data.access_token;
        localStorage.setItem(LOGGED_ACCOUNT, JSON.stringify(loggedAccount));
        await userManager.storeUser(user);
      } else {
        await backchannelLogout(user.refresh_token, user.access_token);
      }
    } else {
      await userManager.signinSilent();
    }
  } catch (error) {
    await backchannelLogout(user.refresh_token, user.access_token);
  }
});

export default userManager;
