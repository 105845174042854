import page from './page/reducers';
import siteSettings from './site_settings/reducers';
import mediaLibrary from './media_library/reducers';
import corporateCustomer from './customer/reducers';
import section from './section/reducers';

export type CorporateSiteStateType = {
  page: ReturnType<typeof page>,
  corporateCustomer: ReturnType<typeof corporateCustomer>,
  mediaLibrary: ReturnType<typeof mediaLibrary>,
  siteSettings: ReturnType<typeof siteSettings>,
  section: ReturnType<typeof section>,
};

export default {
  page,
  corporateCustomer,
  mediaLibrary,
  siteSettings,
  section,
};
