import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { User } from 'oidc-client';

import actions from 'dwell/actions';
import Loader from 'dwell/components/loader';
import userManager from 'dwell/store/authentication/user_manager';
import {
  paths,
  USER_TO_LOGIN,
  USER_TO_SWITCH,
  LOGGED_ACCOUNT,
  IS_BEEN_LOGGED,
  IS_RECENTLY_LOGGED,
  RECENT_PROPERTY_HYPHENS,
} from 'dwell/constants';

import { SSOLoginResponse } from 'src/interfaces';
import { AuthenticationProps, SSOLoginAction as SSOLoginActionResponse } from 'dwell/store/authentication/action-types';

const Callback: React.FC = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { SSOLogin, backchannelLogout } = actions.authentication;

  const handleOidcCallback = async () => {
    try {
      const loadedUser: User | null = await userManager.signinRedirectCallback();
      if (loadedUser) {
        const response: SSOLoginActionResponse = await dispatch(SSOLogin(loadedUser.access_token));
        const user: SSOLoginResponse = response.result.data;
        const loggedAccount: AuthenticationProps = {
          role: user.role,
          access: loadedUser.access_token,
          is_superuser: user.is_superuser,
        };
        localStorage.setItem(LOGGED_ACCOUNT, JSON.stringify(loggedAccount));
        localStorage.setItem(IS_BEEN_LOGGED, 'true');
        localStorage.setItem(IS_RECENTLY_LOGGED, 'true');
        localStorage.setItem(
          RECENT_PROPERTY_HYPHENS,
          user.last_login_property.name,
        );
        const storedUserToLogin = sessionStorage.getItem(USER_TO_LOGIN);
        const storedUserToSwitch = sessionStorage.getItem(USER_TO_SWITCH);
        if ((storedUserToLogin && storedUserToLogin !== loadedUser.profile.email) ||
          (storedUserToSwitch && storedUserToSwitch !== loadedUser.profile.email)
        ) {
          const message = storedUserToSwitch
            ? 'You are logging in as a different user than the one you intended to switch to.'
            : 'You are trying to login as different user';
          dispatch(backchannelLogout(true, message));
          push('/login');
          return;
        }
        push(user.new_pipeline_enabled_to_all ? paths.client.PIPELINE : paths.client.LEADS.VIEW);
        sessionStorage.clear();
      }
    } catch (error) {
      dispatch(backchannelLogout(true));
      push('/login');
      throw error;
    }
  };

  useEffect(() => {
    handleOidcCallback();
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </div>
  );
};

export default Callback;
