import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Col, FormGroup, FormText, Label, Row } from 'reactstrap';

import { selectSelectedProperties } from 'dwell/store/property/reducers';
import propertyActions from 'dwell/store/property/action-creators';

interface Props {
  control: Control;
}

const PropertySelector: FC<Props> = ({ control }) => {
  const { getPropertyDetails, setSelectedPropertyXname } = propertyActions;

  const properties = useSelector(selectSelectedProperties);
  const options = properties.map(p => ({ label: p.name, value: p.id }));
  const dispatch = useDispatch();

  const handleChange = (id: number) => {
    if (id) {
      const property = properties.find(p => p.id === id);
      dispatch(setSelectedPropertyXname(property));
      dispatch(getPropertyDetails(id));
    } else {
      dispatch(setSelectedPropertyXname({}));
    }
  };

  return (
    <Row className="mt-2">
      <Col xs="12" className="mg-t-10">
        <FormGroup>
          <Label htmlFor="property">Property</Label>
          <Controller
            control={control}
            name="property"
            rules={{ required: true }}
            render={({ field: { onChange, ...params }, fieldState: { error } }) => (
              <>
                <Select
                  {...params}
                  inputId="property"
                  isSearchable
                  onChange={(option) => {
                    onChange(option);
                    handleChange(option.value);
                  }}
                  options={options}
                  placeholder="Choose one"
                />
                {error && <FormText color="danger">Please choose a property</FormText>}
              </>
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default PropertySelector;
