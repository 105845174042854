import * as moment from 'moment';
import produce from 'immer';

import { ActionType as UserActionType, Action as UserAction } from 'dwell/store/user/action-types';
import {
  Action,
  ActionType,
  MarketingReport,
  OverviewReport,
  AgentOfficeReport,
  RawData,
  CreditBuilderReport,
  PPCReport,
  WorkforceReport,
} from './action-types';
import { CommonStateType } from '../types';

export interface ReportState extends CommonStateType {
  isSubmitting: boolean,
  isLoaded: boolean,
  isRawDataLoaded: boolean,
  errorMessage: string,
  PPCReport: PPCReport,
  overviewReports: OverviewReport,
  marketingReports: MarketingReport,
  agentOfficeReports: AgentOfficeReport,
  creditBuilderReports: CreditBuilderReport,
  workforceReport: WorkforceReport,
  customStartDate: moment.Moment,
  customEndDate: moment.Moment,
  startDate: string,
  endDate: string,
  attribution: string,
  agentFilter: number,
  portfolio: number,
  properties: number[],
  reportType: string,
  compareValue: string,
  datePeriod: string,
  showPaidOnly: boolean,
  isPending: boolean,
  propertiesAvailableToRescore: number[],
  needReload: boolean,
  propertiesLabel: string,
  generatedDate: string,
  status: string,
  id: number,
  rawData: RawData,
}

const initialState: ReportState = {
  isSubmitting: false,
  isLoaded: false,
  isRawDataLoaded: false,
  errorMessage: null,
  PPCReport: {
    channels: [],
    graph_data: [],
    keywords: [],
    metrics: {
      clicks: 0,
      conv_rate: 0,
      conversions: 0,
      cost: 0,
      cpa: 0,
      cpc: 0,
      ctr: 0,
      impressions: 0,
    },
    previous_metrics: {
      clicks: 0,
      conv_rate: 0,
      conversions: 0,
      cost: 0,
      cpa: 0,
      cpc: 0,
      ctr: 0,
      impressions: 0,
    },
  },
  overviewReports: {
    id: null,
    overall: {
      lead_to_lease: {
        leads: 0, leases: 0, tours: 0, leased_rate: 0, lead_to_tour: 0, tour_to_lease: 0,
      },
      tours: {
        total_tours: 0,
        tours_data: {},
        total_leases: 0,
        leases_data: {},
      },
      activity: {
        activities: 0, emails: 0, tasks: 0, notes: 0, calls: 0, agent_chats: 0,
      },
      calls: {
        prospect_calls: 0,
        call_answered: { percents: 0, calls: 0 },
        call_missed: { percents: 0, calls: 0 },
        call_busy: { percents: 0, calls: 0 },
        call_failed: { percents: 0, calls: 0 },
        average_call_time: 0,
        average_call_score: 0,
        introduction: 0,
        qualifying: 0,
        amenities: 0,
        closing: 0,
        overall: 0,
        scored_calls: [],
        scoring_questions: [],
        agents: [],
      },
      engagement: {
        average_response_time_business: 0,
        average_response_time_non_business: 0,
        average_sign_lease_time: 0,
        average_followups_number: 0,
        followups_2_hours: [0, 0],
        followups_24_hours: [0, 0],
        followups_48_hours: [0, 0],
        followups_more_48_hours: [0, 0],
        no_followups: [0, 0],
      },
    },
    grouped: {
      lead_to_lease: {},
      tours: {},
      activity: {},
      calls: {},
      engagement: {},
    },
    audition: { leads: [], tours: [], leases: [], responses: [] },
    chart_values: {
      leads: [],
      tours: [],
      leases: [],
      prospect_calls: [],
      average_call_time: [],
      average_call_score: [],
      average_response_time_business: [],
      average_response_time_non_business: [],
      average_sign_lease_time: [],
      average_followups_number: [],
    },
    compare_values: {
      lead_to_lease: {
        leased_rate: 0, leads: 0, lead_to_tour: 0, tours: 0, tour_to_lease: 0, leases: 0,
      },
      activity: {
        activities: 0,
      },
      calls: {
        prospect_calls: 0,
        average_call_time: 0,
      },
      engagement: {
        average_response_time_business: 0,
        average_sign_lease_time: 0,
        average_followups_number: 0,
        followups_2_hours: [0, 0],
        followups_24_hours: [0, 0],
        followups_48_hours: [0, 0],
        followups_more_48_hours: [0, 0],
        no_followups: [0, 0],
      },
      tours: {
        total_tours: 0,
        total_leases: 0,
      },
    },
    source_calls: [],
    generated_date: null,
  } as OverviewReport,
  marketingReports: {
    id: null,
    start_date: null,
    end_date: null,
    overall: {
      lead_source: [], lead_lost: { lost_leads: 0 },
    },
    grouped: {
      lead_source: {}, lead_lost: {},
    },
    compare_values: { lead_lost: 'n/a', lead_source: {} },
    generated_date: null,
  } as MarketingReport,
  agentOfficeReports: {
    id: null,
    start_date: null,
    end_date: null,
    overall: {
      agent: [], office: [],
    },
    grouped: {
      agent: [], office: [],
    },
    compare_values: { agent: {}, office: {} },
    generated_date: null,
  } as AgentOfficeReport,
  creditBuilderReports: {
    id: null,
    start_date: null,
    end_date: null,
    overall: {
      enrollments: [],
      cancellations: [],
      summary: {
        cumulative: {
          enrollments: { renewals: 0, new_residents: 0, current: 0 },
          cancellations: { renewals: 0, new_residents: 0, current: 0 },
          net_enrollments: { renewals: 0, new_residents: 0, current: 0 },
        },
        monthly: {
          enrollments: { renewals: 0, new_residents: 0, current: 0 },
          cancellations: { renewals: 0, new_residents: 0, current: 0 },
          net_enrollments: { renewals: 0, new_residents: 0, current: 0 },
        },
        revenue: {
          cumulative: { property: 0, pmc: 0, total: 0 },
          monthly: { property: 0, pmc: 0, total: 0 },
          cumulative_net: { property: 0, pmc: 0, total: 0 },
          monthly_net: { property: 0, pmc: 0, total: 0 },
        },
      },
    },
    generated_date: null,
  } as CreditBuilderReport,
  workforceReport: {} as WorkforceReport,
  rawData: {} as RawData,
  customStartDate: null,
  customEndDate: null,
  attribution: 'OVERALL',
  agentFilter: null,
  portfolio: null,
  properties: [],
  reportType: 'OVERVIEW_REPORTS',
  compareValue: 'PREVIOUS_PERIOD',
  datePeriod: 'TODAY',
  showPaidOnly: false,
  isPending: false,
  propertiesAvailableToRescore: [],
  needReload: false,
  propertiesLabel: null,
  generatedDate: null,
  id: null,
  startDate: null,
  endDate: null,
  status: null,
};

const updateReportState = (state, action, key) => {
  state.id = action.result.data.id;
  state.generatedDate = action.result.data.generated_date;
  state.startDate = action.result.data.start_date;
  state.endDate = action.result.data.end_date;
  state.status = action.result.data.status;

  if (action.result.data.pending) {
    state.isPending = true;
    state.isLoaded = false;
    state[key] = initialState[key];
  } else {
    state.isPending = false;
    state.isLoaded = true;
    const condition = action.result.data.status === 'FINISHED' &&
      (['workforceReport'].includes(key) || action.result.data.overall);
    state[key] = condition ? action.result.data : initialState[key];
  }
};

export default produce((state: ReportState = initialState, action: Action | UserAction): ReportState => {
  switch (action.type) {
    case ActionType.GET_CORPORATE_PPC_REPORTS_REQUEST:
    case ActionType.GET_STANDALONE_PPC_REPORTS_REQUEST:
      state.isLoaded = false;
      state.isPending = true;
      break;

    case ActionType.GET_CORPORATE_PPC_REPORTS_SUCCESS:
    case ActionType.GET_STANDALONE_PPC_REPORTS_SUCCESS:
      state.PPCReport = action.result.data;
      state.id = action.result.data.id;
      state.generatedDate = action.result.data.generated_date;
      state.startDate = action.result.data.start_date;
      state.endDate = action.result.data.end_date;
      state.status = action.result.data.status;
      state.isLoaded = true;
      state.isPending = false;
      break;

    case ActionType.GET_OVERVIEW_REPORTS_REQUEST:
    case ActionType.GET_MARKETING_REPORTS_REQUEST: {
      state.isLoaded = false;
      break;
    }

    case ActionType.GET_OVERVIEW_REPORTS_SUCCESS: {
      updateReportState(state, action, 'overviewReports');
      break;
    }

    case ActionType.GET_MARKETING_REPORTS_SUCCESS: {
      updateReportState(state, action, 'marketingReports');
      break;
    }

    case ActionType.GET_AGENT_AND_OFFICE_REPORTS_SUCCESS: {
      updateReportState(state, action, 'agentOfficeReports');
      break;
    }

    case ActionType.GET_CREDIT_BUILDER_REPORTS_SUCCESS: {
      updateReportState(state, action, 'creditBuilderReports');
      break;
    }

    case ActionType.GET_WORKFORCE_REPORTS_SUCCESS: {
      updateReportState(state, action, 'workforceReport');
      break;
    }

    case ActionType.GET_RAW_DATA_REPORTS_REQUEST:
    case ActionType.GET_RAW_DATA_REPORTS_FAILURE:
      state.rawData = {} as RawData;
      state.isRawDataLoaded = false;
      break;

    case ActionType.GET_RAW_DATA_REPORTS_SUCCESS: {
      state.rawData = action.result.data;
      state.isRawDataLoaded = true;
      break;
    }

    case ActionType.GET_PROPERTIES_AVAILABLE_TO_RESCORE_SUCCESS: {
      state.propertiesAvailableToRescore = action.result.data;
      break;
    }

    case ActionType.SET_PROPERTY_NOT_AVAILABLE_TO_RESCORE: {
      state.propertiesAvailableToRescore = state.propertiesAvailableToRescore.filter(p => p !== action.id);
      break;
    }

    case ActionType.SET_RELOAD_STATE: {
      state.needReload = action.needReload;
      break;
    }

    case ActionType.CLEAR_REPORTS: {
      state = initialState;
      break;
    }

    case ActionType.SET_DATE_PERIOD: {
      state.datePeriod = action.datePeriod;
      break;
    }

    case ActionType.SET_CUSTOM_DATE: {
      state.customStartDate = action.startDate;
      state.customEndDate = action.endDate;
      break;
    }

    case ActionType.SET_ATTRIBUTION: {
      state.attribution = action.attribution;
      break;
    }

    case ActionType.SET_COMPARE_VALUE: {
      state.compareValue = action.compareValue;
      break;
    }

    case ActionType.SET_QUERY_PROPERTIES: {
      state.properties = action.properties;
      break;
    }

    case UserActionType.GET_CURRENT_USER_SUCCESS:
      state.properties = action.result.data.accessed_properties.map(item => item.id);
      break;

    case UserActionType.UPDATE_USER_ACCESSED_PROPERTIES_SUCCESS:
      state.properties = action.result.data.map(item => item.id);
      break;

    case ActionType.SET_PORTFOLIO: {
      state.portfolio = action.portfolio;
      break;
    }

    case ActionType.SET_REPORT_TYPE: {
      state.reportType = action.reportType;
      break;
    }

    case ActionType.SET_SHOW_PAID_ONLY: {
      state.showPaidOnly = action.showPaidOnly;
      break;
    }

    case ActionType.SET_PROPERTIES_LABEL: {
      state.propertiesLabel = action.label;
      break;
    }

    case ActionType.SET_AGENT_FILTER: {
      state.agentFilter = action.agentFilter;
      break;
    }
  }

  return state;
});

export const selectPPCReport = (state: { report: ReportState }): PPCReport => state.report.PPCReport;
export const selectHaveManyProperties = (state: { report: ReportState }): boolean => !!state.report.portfolio || state.report.properties.length > 1;
export const isLoaded = (state: { report: ReportState }): boolean => state.report.isLoaded;
export const selectReport = (state: { report: ReportState }): ReportState => state.report;
export const selectStatus = (state: { report: ReportState }): string => state.report.status;
export const selectIsReportLoaded = (state: { report: ReportState }): boolean => state.report.isLoaded;
export const selectReportDatePeriod = (state: { report: ReportState }): string => state.report.datePeriod;
export const selectReportStatus = (state: { report: ReportState }): string => state.report.status;
export const selectWorkforceReport = (state: { report: ReportState }): WorkforceReport => state.report.workforceReport;
