import React, { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FormGroup, Input, Label } from 'reactstrap';

import { InputProps } from '../utils';

const Description: FC<InputProps> = ({ control, setValue, task }) => {
  useEffect(() => setValue('description', task.description), [task.description]);

  return (
    <FormGroup>
      <Label htmlFor="description">Description:</Label>
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <Input
            {...field}
            id="description"
            placeholder="Write a description"
            rows={3}
            type="textarea"
          />
        )}
      />
    </FormGroup>
  );
};

export default Description;
