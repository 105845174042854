import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Call } from '@twilio/voice-sdk';

import { selectCurrentUser } from 'dwell/store/user/reducers';
import {
  selectShowingIncomingCallNotification,
  selectDesktopPhoneCallStarted,
  selectDevice,
} from 'dwell/store/voice/reducers';
import { selectIsSessionTimedOut } from 'dwell/store/authentication/reducers';
import { UserProps } from 'dwell/store/user/action-types';
import voiceActions from 'dwell/store/voice/action-creator';

import { getCallParams } from './utils';
import InboundCallNotification from '../notifications/inbound';
import OutboundCallNotification from '../notifications/outbound';

const VoiceCentre: FC = () => {
  const dispatch = useDispatch();

  const showInboundNotification = useSelector(selectShowingIncomingCallNotification);
  const showOutboundNotification = useSelector(selectDesktopPhoneCallStarted);
  const device = useSelector(selectDevice);

  const currentUser = useSelector(selectCurrentUser);
  const isSessionTimedOut = useSelector(selectIsSessionTimedOut);

  const requestNotificationPermission = () => {
    if ('Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  };

  const refreshToken = () => {
    const refresh = true;
    dispatch(voiceActions.getVoiceToken(refresh)).then(() => dispatch(voiceActions.refreshDeviceToken()));
  };

  const getTokenOwnership = (): Record<string, string> => {
    const ownership = localStorage.getItem('token_ownership');
    return ownership ? JSON.parse(ownership) : {};
  };

  const setTokenOwnership = (ownership: Record<string, string>) => {
    localStorage.setItem('token_ownership', JSON.stringify(ownership));
  };

  const isCurrentTabTokenOwner = (userId: number) => {
    const ownership = getTokenOwnership();
    return ownership[userId] === sessionStorage.getItem('tabId');
  };

  const shouldRequestToken = (userId: number) => {
    const ownership = getTokenOwnership();
    return !ownership[userId] || isCurrentTabTokenOwner(userId);
  };

  const tokenIsAlreadySetForUser = (userId: number) => {
    const ownership = getTokenOwnership();
    return ownership[userId];
  };

  const removeTokenOwnershipForUser = (userId: number) => {
    const ownership = getTokenOwnership();
    delete ownership[userId];
    setTokenOwnership(ownership);
  };

  const setHasRequestedToken = (userId: number) => {
    const tabId = sessionStorage.getItem('tabId') || `tab-${Date.now()}`;
    sessionStorage.setItem('tabId', tabId);
    const ownership = getTokenOwnership();
    ownership[userId] = tabId;
    setTokenOwnership(ownership);
  };

  const hasVoiceSetup = (_currentUser: UserProps) => _currentUser && _currentUser.has_voice_setup && !device && shouldRequestToken(_currentUser.id);

  const requestAndSetupDevice = (userId: number, aiNoiseSuppressionEnabled: boolean) => {
    dispatch(voiceActions.getVoiceToken()).then(() => {
      if (aiNoiseSuppressionEnabled) {
        dispatch(voiceActions.createDevice());
      } else {
        dispatch(voiceActions.createDeviceWithoutNoiseCancel());
      }
      setHasRequestedToken(userId);
    });
  };

  const incomingCall = (callReceived: Call) => {
    callReceived.on('cancel', () => dispatch(voiceActions.closeCallNotification()));
    dispatch(voiceActions.setIncomingCall(callReceived));
    dispatch(voiceActions.showIncomingCallNotification());
  };

  const onAcceptCall = (callToAccept: Call) => {
    dispatch(voiceActions.closeCallNotification());
    dispatch(voiceActions.acceptIncomigCall({ ...getCallParams(callToAccept), call: callToAccept }));
    callToAccept.accept();
  };

  const onRejectCall = (callToReject: Call) => {
    dispatch(voiceActions.closeCallNotification());
    callToReject.reject();
  };

  const setAsUnavailable = () => {
    dispatch(voiceActions.setUserUnavailbleToReceiveCalls(currentUser.id));
  };

  const handleTokenRequestOnTabClosure = (_currentUser: UserProps) => {
    if (hasVoiceSetup(currentUser)) requestAndSetupDevice(_currentUser.id, _currentUser.ai_noise_suppression_enabled);
  };

  const handleTabClosed = (userId: number) => {
    if (isCurrentTabTokenOwner(userId)) removeTokenOwnershipForUser(userId);
  };

  useEffect(() => {
    if (device) {
      // Avoid adding multiple listeners for the same event
      if (device.listenerCount('incoming') === 0) {
        device.on('incoming', incomingCall);
      }
      if (device.listenerCount('tokenWillExpire') === 0) device.on('tokenWillExpire', refreshToken);
    }
  }, [device]);

  const onRequestTokenByClicking = () => {
    const removeTokenRequestOnClickListener = () => {
      window.removeEventListener('click', onRequestTokenByClicking);
      sessionStorage.removeItem('isClickVoiceTokenEventListenerSet');
    };

    // We remove the listener because we only want to request the token once by clicking. It means the tab that triggered the click event is the new current active tab.
    removeTokenRequestOnClickListener();
    handleTokenRequestOnTabClosure(currentUser);
  };

  const addTokenRequestOnClickListenerIfNotExists = () => {
    if (!sessionStorage.getItem('isClickVoiceTokenEventListenerSet')) {
      window.addEventListener('click', onRequestTokenByClicking);
      sessionStorage.setItem('isClickVoiceTokenEventListenerSet', 'true');
    }
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hasVoiceSetup(currentUser)) {
      requestAndSetupDevice(currentUser.id, currentUser.ai_noise_suppression_enabled);
    }
    // We set an onclick event of the inactive opened tabs, so when the current active tab is closed, we can request the token in another tab just by clicking - avoiding the need to refresh
    if (!isCurrentTabTokenOwner(currentUser.id) && tokenIsAlreadySetForUser(currentUser.id)) {
      addTokenRequestOnClickListenerIfNotExists();
    }
  }, [currentUser.id]);

  useEffect(() => {
    if (Object.keys(currentUser).length === 0) return (): void => undefined;

    requestNotificationPermission();
    const onTabClosed = () => handleTabClosed(currentUser.id);
    window.addEventListener('beforeunload', onTabClosed);

    return (): void => {
      window.removeEventListener('beforeunload', onTabClosed);
      window.removeEventListener('click', onRequestTokenByClicking);
    };
  }, [currentUser]);

  useEffect(() => {
    if (isSessionTimedOut) setAsUnavailable();
    if (!isSessionTimedOut && hasVoiceSetup(currentUser)) requestAndSetupDevice(currentUser.id, currentUser.ai_noise_suppression_enabled);
  }, [isSessionTimedOut]);

  return (
    <>
      {showInboundNotification && <InboundCallNotification acceptCall={onAcceptCall} rejectCall={onRejectCall} />}
      {showOutboundNotification && <OutboundCallNotification />}
    </>
  );
};

export default VoiceCentre;
