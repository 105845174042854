import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, PaymentReceivedProps } from './action-types';

export default {
  createPaymentReceived: (leaseId: number, body: PaymentReceivedProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_PAYMENT_RECEIVED_REQUEST,
        ActionType.CREATE_PAYMENT_RECEIVED_SUCCESS,
        ActionType.CREATE_PAYMENT_RECEIVED_FAILURE,
      ],
      promise: client => client.post(build(api.v1.PAYMENT_RECEIVED, leaseId), body),
    },
  }),

  deletePaymentReceived: (leaseId: number, paymentId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_PAYMENT_RECEIVED_REQUEST,
        ActionType.DELETE_PAYMENT_RECEIVED_SUCCESS,
        ActionType.DELETE_PAYMENT_RECEIVED_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.PAYMENT_RECEIVED_DETAILS, leaseId, paymentId)),
    },
  }),

  updatePaymentReceived: (leaseId: number, paymentId: number, params: PaymentReceivedProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PAYMENT_RECEIVED_REQUEST,
        ActionType.UPDATE_PAYMENT_RECEIVED_SUCCESS,
        ActionType.UPDATE_PAYMENT_RECEIVED_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.PAYMENT_RECEIVED_DETAILS, leaseId, paymentId), params),
    },
  }),
};
