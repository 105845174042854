import produce from 'immer';
import { pageTypes } from 'site/constants';
import { preFillState } from 'dwell/store/utils';
import { Action, ActionType, HomePageData, GalleryPageData, FloorPlanPageData, AmenitiesPageData, NeighborHoodPageData, VirtualTourPageData, ContactPageData, FooterPageData, DesignPageData, SeoPageData } from './action-types';
import { CommonStateType } from '../types';

export interface PageDataState extends CommonStateType {
  isPageDataLoaded: boolean;
  isFloorPlanLoaded: boolean;
  homePageData: HomePageData;
  floorPlanData: { images: { src: string }[] }[];
  galleryPageData: GalleryPageData;
  floorPlansPageData: FloorPlanPageData;
  amenitiesPageData: AmenitiesPageData;
  neighborhoodPageData: NeighborHoodPageData;
  virtualTourPageData: VirtualTourPageData;
  contactPageData: ContactPageData;
  footerPageData: FooterPageData;
  designPageData: DesignPageData;
  seoPageData: SeoPageData;
  formChanged: boolean;
  submitClicked: boolean;
}

const initialState: PageDataState = {
  isSubmitting: false,
  isPageDataLoaded: false,
  isFloorPlanLoaded: false,
  homePageData: {},
  floorPlanData: [],
  galleryPageData: {},
  floorPlansPageData: {},
  amenitiesPageData: {},
  neighborhoodPageData: {},
  virtualTourPageData: {},
  contactPageData: {},
  footerPageData: {},
  designPageData: {},
  seoPageData: {},
  formChanged: false,
  submitClicked: false,
};

export default produce((state: PageDataState = initialState, action: Action): PageDataState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_PAGE_DATA_REQUEST:
      state.isPageDataLoaded = false;
      break;
    case ActionType.GET_PAGE_DATA_SUCCESS:
      state.isPageDataLoaded = true;
      state[pageTypes[action.sections]] = action.result.data;
      break;
    case ActionType.GET_PAGE_DATA_FAILURE:
      state.isPageDataLoaded = false;
      break;

    case ActionType.UPDATE_PAGE_DATA_SUCCESS:
      state[pageTypes[action.sections]] = action.result.data;
      break;

    case ActionType.SET_PAGE_DATA_CHANGED_STATE:
      state.formChanged = action.changed;
      break;

    case ActionType.SET_SUBMISSION_STATE:
      state.submitClicked = action.clicked;
      break;

    case ActionType.GET_FLOOR_PLANS_REQUEST:
      state.isFloorPlanLoaded = false;
      break;
    case ActionType.GET_FLOOR_PLANS_SUCCESS:
      state.isFloorPlanLoaded = true;
      state.floorPlanData = action.result.data;
      break;
    case ActionType.GET_FLOOR_PLANS_FAILURE:
      state.isFloorPlanLoaded = false;
      break;

    case ActionType.CREATE_FLOOR_PLAN_REQUEST:
      state.isFloorPlanLoaded = false;
      break;
    case ActionType.CREATE_FLOOR_PLAN_SUCCESS:
      state.isFloorPlanLoaded = true;
      state.floorPlanData = [...state.floorPlanData, action.result.data];
      break;
    case ActionType.CREATE_FLOOR_PLAN_FAILURE:
      state.isFloorPlanLoaded = false;
      break;

    case ActionType.RESET_PAGE_DATA:
      return initialState;
  }

  return state;
});
