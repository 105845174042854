import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormFeedback, Input, Label, FormGroup } from 'reactstrap';
import { CustomSwitch, SwitchInput, SwitchLabel } from 'site/components/common';
import { PropertyProps } from 'dwell/store/property/action-types';
import { CustomSelect } from './styles';

interface PropertyFormProps {
  property: PropertyProps;
  errors: PropertyProps;
  onChange: (data: { target: { id: string; value: string | number } }) => void;
  setInitialPropertyData: (data: { target: { id: string; value: string | number } }) => void;
  sourcePropertyId: number;
}

const PropertyForm: FC<PropertyFormProps> = ({ onChange, setInitialPropertyData, property, errors, sourcePropertyId }) => {
  const siteEnabledProperties = useSelector(state => state.customer.siteEnabledProperties);

  const propertiesOptions = siteEnabledProperties?.map((item, key) => (
    <option key={key} value={item.id}>
      {item.name} Page Data
    </option>
  ));

  return (
    <React.Fragment>
      <FormGroup className="mb-20">
        <Label htmlFor="domain">Domain name:</Label>
        <Input type="text" id="domain" aria-describedby="domain" placeholder="http://" value={property.domain} onChange={onChange} invalid={errors.domain} />
        <FormFeedback>{errors.domain}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="name">Property name:</Label>
        <Input type="text" id="name" aria-describedby="name" placeholder="Enter name of property" value={property.name} onChange={onChange} invalid={errors.name} />
        <FormFeedback>{errors.name}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="name">Copy page data from:</Label>
        <CustomSelect type="select" value={sourcePropertyId} id="id" onChange={setInitialPropertyData} invalid={errors.id}>
          <option value="0">Empty</option>
          <option value="-1">Initial Page data</option>
          {propertiesOptions}
        </CustomSelect>
      </FormGroup>
      <CustomSwitch>
        <SwitchInput type="checkbox" id="status" checked={property.status === 'ACTIVE'} onChange={e => onChange({ target: { id: 'status', value: e.target.checked ? 'ACTIVE' : 'INACTIVE' } })} />
        <SwitchLabel htmlFor="status">Active State</SwitchLabel>
      </CustomSwitch>
    </React.Fragment>
  );
};

export default PropertyForm;
