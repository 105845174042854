/* eslint-disable camelcase */
import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { Action, ActionType, Prospect, ChatMessage, AgentRequest, ActiveProspect } from './action-types';
import { CallBackFunction, OffsetPaginationParamTypes } from '../types';

export default {
  getAllProspects: (show_all: boolean, properties: number[], offset?: number, limit?: number, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROSPECTS_REQUEST,
        ActionType.GET_PROSPECTS_SUCCESS,
        ActionType.GET_PROSPECTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROSPECTS, { params: { show_all, properties, offset, limit }, cancelToken: token }),
    },
  }),
  joinProspect: (data: ChatMessage, property: number): Action => ({
    CALL_API: {
      types: [
        ActionType.JOIN_PROSPECT_CHAT_REQUEST,
        ActionType.JOIN_PROSPECT_CHAT_SUCCESS,
        ActionType.JOIN_PROSPECT_CHAT_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.PROSPECT_CONVERSATIONS, data.prospect), data.body, { params: { property } }),
    },
  }),
  requestToJoinConversation: (prospect: number, agent: number, property: number): Action => ({
    CALL_API: {
      types: [
        ActionType.REQUEST_TO_JOIN_CONVERSATION_REQUEST,
        ActionType.REQUEST_TO_JOIN_CONVERSATION_SUCCESS,
        ActionType.REQUEST_TO_JOIN_CONVERSATION_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.REQUET_TO_JOIN_CONVERSATION, prospect), { agent }, { params: { property } }),
    },
  }),
  updateProspectChatStatus: (data: ChatMessage, properties: number[]): Action => ({
    CALL_API: {
      types: [
        ActionType.CHANGE_PROSPECT_CHAT_STATUS_REQUEST,
        ActionType.CHANGE_PROSPECT_CHAT_STATUS_SUCCESS,
        ActionType.CHANGE_PROSPECT_CHAT_STATUS_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.PROSPECT_DETAILS, data.prospect), data.body, { params: { properties } }),
    },
  }),
  readAll: (prospectId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.PROSPECT_CHAT_READ_ALL_REQUEST,
        ActionType.PROSPECT_CHAT_READ_ALL_SUCCESS,
        ActionType.PROSPECT_CHAT_READ_ALL_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.PROSPECT_CONVERSATIONS_READALL, prospectId)),
    },
  }),
  dismissNewMessage: (prospect: Prospect): Action => ({
    CALL_API: {
      types: [
        ActionType.DISMISS_NEW_MESSAGE_REQUEST,
        ActionType.DISMISS_NEW_MESSAGE_SUCCESS,
        ActionType.DISMISS_NEW_MESSAGE_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.PROSPECT_DISMISS_NEW_MESSAGE, prospect)),
    },
  }),
  sendMessageToProspect: (data: ChatMessage, property: number, successCB: CallBackFunction = () => undefined): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.SEND_CHAT_MESSAGE_REQUEST,
          ActionType.SEND_CHAT_MESSAGE_SUCCESS,
          ActionType.SEND_CHAT_MESSAGE_FAILURE,
        ],
        promise: client => client.post(build(paths.api.v1.PROSPECT_CONVERSATIONS, data.prospect), data, { params: { property } }),
        successCB,
        config: {
          property_external_id: data.property_external_id,
        },
      },
    }),
  clearNewMessageAlert: (): Action => ({
    type: ActionType.CLEAR_NEW_MESSAGE_ALERT,
  }),

  clearAgentRequestAlert: (): Action => ({
    type: ActionType.CLEAR_NEW_AGENT_REQUEST_ALERT,
  }),

  setProspectsOffline: (ids: number[]): Action => ({
    type: ActionType.SET_PROSPECTS_OFFLINE,
    ids,
  }),

  removeFromProspectsRequestedAgent: (id: number): Action => ({ type: ActionType.REMOVE_FROM_PROSPECTS_REQUESTED_AGENT, id }),
  updateAgentRequest: (id: number, params: AgentRequest): Action => ({
    CALL_API: {
      types: [
        ActionType.DECLINE_AGENT_REQUEST_REQUEST,
        ActionType.DECLINE_AGENT_REQUEST_SUCCESS,
        ActionType.DECLINE_AGENT_REQUEST_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.AGENT_REQUEST_DETAILS, id), params),
    },
  }),
  setChatType: (data: string): Action =>
    ({
      type: ActionType.SET_CHAT_TYPE,
      data,
    }),
  setChatMinimiseStatus: (data: boolean): Action =>
    ({
      type: ActionType.SET_CHAT_MINIMISE_STATUS,
      data,
    }),
  setChatRenderedStatus: (data: boolean): Action =>
    ({
      type: ActionType.SET_CHAT_IS_RENDERED,
      data,
    }),
  setChatAsActive: (contact: ActiveProspect): Action => ({
    type: ActionType.SET_CHAT_AS_ACTIVE,
    contact,
  }),
  clearActiveChats: (): Action => ({
    type: ActionType.CLEAR_ACTIVE_CHATS,
  }),
  setActiveProperties: (ids: number[]): Action => ({
    type: ActionType.SET_ACTIVE_PROPERTIES,
    ids,
  }),
  getProspectsByProperty: (show_all: boolean, property_id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROSPECTS_BY_PROPERTY_REQUEST,
        ActionType.GET_PROSPECTS_BY_PROPERTY_SUCCESS,
        ActionType.GET_PROSPECTS_BY_PROPERTY_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROSPECTS, { params: { show_all, property_id } }),
    },
  }),
  removeProspectsByProperty: (id: number): Action => ({
    type: ActionType.REMOVE_PROSPECTS_BY_PROPERTY,
    id,
  }),
  removeFromActiveChats: (contact: ActiveProspect): Action => ({
    type: ActionType.REMOVE_FROM_ACTIVE_CHATS,
    contact,
  }),
  removeLeadChatFromActiveChats: (leadId: number): Action => ({
    type: ActionType.REMOVE_LEAD_FROM_ACTIVE_CHATS,
    leadId,
  }),

  getConversations: ({ prospect, params }: { prospect: number, params: OffsetPaginationParamTypes }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CHAT_CONVERSATIONS_REQUEST,
        ActionType.GET_CHAT_CONVERSATIONS_SUCCESS,
        ActionType.GET_CHAT_CONVERSATIONS_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.PROSPECT_CONVERSATIONS, prospect), { params }),
    },
  }),
  reorderActiveChats: (contact: ActiveProspect): Action => ({
    type: ActionType.REORDER_ACTIVE_CHATS,
    contact,
  }),
  minimizeChatWindow: (contact: ActiveProspect): Action => ({
    type: ActionType.MINIMIZE_CHAT_WINDOW,
    contact,
  }),
  setActiveSlide: (activeSlide: number): Action => ({
    type: ActionType.SET_ACTIVE_CHAT_SLIDE,
    activeSlide,
  }),

  setCurrentTab: (tabKey: string): Action => ({
    type: ActionType.SET_CURRENT_TAB,
    tabKey,
  }),

  clearTyping: (): Action => ({
    type: ActionType.CLEAR_TYPING,
  }),

  clearProspects: (): Action => ({
    type: ActionType.CLEAR_PROSPECTS,
  }),

  removeProspectByLeadId: (leadId: number): Action => ({
    type: ActionType.REMOVE_PROSPECT,
    leadId,
  }),

  setDraftToActiveChat: (text: string, id: number): Action => ({
    type: ActionType.SET_DRAFT_MESSAGE,
    id,
    text,
  }),
};
