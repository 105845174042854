import React, { FC } from 'react';
import Dropzone from 'react-dropzone';
import { toast, ToastOptions } from 'react-toastify';
import { get } from 'lodash';
import styled from 'styled-components';
import { MAX_IMG_SIZE, toastError, toastWarning } from 'site/constants';

const UploadContainer = styled.div`
  margin: auto;
  background: url(/static/images/image-add.svg);
  width: 18px;
  height: 18px;
  background-size: cover;
  align-self: center;
`;

const DZContainer = () => <UploadContainer />;

interface ImageUploadProps {
  accept?: string;
  title?: string,
  disabled?: boolean,
  dropzoneClassname?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropzoneContainer?: any,
  onDropAccepted: (file: File) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any,
}

const ImageUpload: FC<ImageUploadProps> = ({ accept, onDropAccepted, disabled, dropzoneContainer: DropzoneContainer, dropzoneClassname, onClick }) => {
  const onDropRejected = (file) => {
    if (get(file, '[0].size', 0) / 1000000 > 10.0) {
      toast.warn('Maximum image size is 10 MB.', toastWarning as ToastOptions);
    } else {
      const acceptedTypes = accept.split(/,\s*/)
        .map((t) => {
          const i = t.indexOf('+');
          if (i > 0) {
            return t.substring(0, i).replace('image/', '');
          }
          return t.replace('image/', '');
        })
        .reduce((acc, t, idx, arr) => {
          if (idx === arr.length - 1) {
            return `${acc} or ${t}`;
          }
          if (idx === 0) {
            return t;
          }
          return `${acc}, ${t}`;
        }, '');
      toast.error(`Incorrect media type. Please upload a ${acceptedTypes} image.`, toastError as ToastOptions);
    }
  };

  return (
    <div onClick={() => onClick()} style={{ cursor: onClick ? 'pointer' : '' }}>
      <Dropzone
        className={dropzoneClassname}
        disabled={disabled}
        disabledClassName="image-upload--disabled"
        accept={accept}
        multiple={false}
        onDropAccepted={onDropAccepted}
        maxSize={MAX_IMG_SIZE}
        onDropRejected={file => onDropRejected(file)}
      >
        {
          (...props) => <DropzoneContainer {...props} />
        }
      </Dropzone>
    </div>
  );
};

ImageUpload.defaultProps = {
  accept: 'image/*',
  disabled: false,
  dropzoneContainer: DZContainer,
  dropzoneClassname: 'd-flex p-2',
};

export default ImageUpload;
