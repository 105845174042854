import { build } from 'dwell/constants/paths';
import { api } from 'resident/constants/paths';
import { ActionType, Action, UpdateDataType } from './action-types';

export default {
  getLeaseDetails: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEASE_DETAILS_REQUEST,
        ActionType.GET_LEASE_DETAILS_SUCCESS,
        ActionType.GET_LEASE_DETAILS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.LEASE_DETAILS, id)),
    },
  }),

  updateLease: (id: number, data: UpdateDataType): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_LEASE_RESIDENT_REQUEST,
        ActionType.UPDATE_LEASE_RESIDENT_SUCCESS,
        ActionType.UPDATE_LEASE_RESIDENT_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.LEASE_DETAILS, id), data),
    },
  }),

  getRenewLeaseDetails: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_RENEW_LEASE_DETAILS_REQUEST,
        ActionType.GET_RENEW_LEASE_DETAILS_SUCCESS,
        ActionType.GET_RENEW_LEASE_DETAILS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.LEASE_DETAILS, id)),
    },
  }),

};
