export enum ActionType {
  GET_EMAIL_LABELS_REQUEST = 'GET_EMAIL_LABELS_REQUEST',
  GET_EMAIL_LABELS_SUCCESS = 'GET_EMAIL_LABELS_SUCCESS',
  GET_EMAIL_LABELS_FAILURE = 'GET_EMAIL_LABELS_FAILURE',
}

interface GetLabelsAction {
  type: ActionType.GET_EMAIL_LABELS_REQUEST | ActionType.GET_EMAIL_LABELS_SUCCESS | ActionType.GET_EMAIL_LABELS_FAILURE;

  result: {
    data: { results: string[] };
  };
  error: {
    response: { status: string };
  };
}

export type Action = | GetLabelsAction;
