import { get } from 'lodash';
import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ManageRequestProps, AvailableTimesParams, Headers } from 'src/interfaces';
import { OwnerCategory } from 'dwell/views/tasks/category/utils';
import { ActionType, Action, TaskProps } from './action-types';

const getTaskDetailPath = (id = null, objectId = null, source = 'task'): string => {
  const url = (id ? build(paths.api.v1.TASK_DETAILS, id) : paths.api.v1.TASKS);
  if (['lead', 'lease', 'application'].includes(source)) return `${url}?${source}=${objectId}`;
  return url;
};

export default {
  getTasks: (params: ManageRequestProps, config?: { append?: boolean, paginated?: boolean }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_TASKS_REQUEST,
        ActionType.GET_TASKS_SUCCESS,
        ActionType.GET_TASKS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.TASKS, { params }),
      payload: { ...config, page: get(params, 'page', 1), status: params.status },
    },
  }),
  getObjectTasks: (objectId: number, source: string, params?: ManageRequestProps, headers?: Headers): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_OBJECT_TASKS_REQUEST,
        ActionType.GET_OBJECT_TASKS_SUCCESS,
        ActionType.GET_OBJECT_TASKS_FAILURE,
      ],
      promise: client => client.get(getTaskDetailPath(null, objectId, source), { params, headers }),
    },
  }),
  deleteTaskById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_TASK_REQUEST,
        ActionType.DELETE_TASK_SUCCESS,
        ActionType.DELETE_TASK_FAILURE,
      ],
      promise: client => client.delete(getTaskDetailPath(id)),
    },
  }),
  updateTaskById: (id: number, params: TaskProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_TASK_REQUEST,
        ActionType.UPDATE_TASK_SUCCESS,
        ActionType.UPDATE_TASK_FAILURE,
      ],
      promise: client => client.put(getTaskDetailPath(id), params),
    },
  }),
  completeTask: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.COMPLETE_TASK_REQUEST,
        ActionType.COMPLETE_TASK_SUCCESS,
        ActionType.COMPLETE_TASK_FAILURE,
      ],
      promise: client => client.patch(getTaskDetailPath(id), { status: 'COMPLETED' }),
    },
  }),
  markNoShowTask: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.MARK_NO_SHOW_TASK_REQUEST,
        ActionType.MARK_NO_SHOW_TASK_SUCCESS,
        ActionType.MARK_NO_SHOW_TASK_FAILURE,
      ],
      promise: client => client.patch(getTaskDetailPath(id), { status: 'NO_SHOW' }),
    },
  }),
  createTask: (data: TaskProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_TASK_REQUEST,
        ActionType.CREATE_TASK_SUCCESS,
        ActionType.CREATE_TASK_FAILURE,
      ],
      promise: client => client.post(getTaskDetailPath(), data),
    },
  }),

  getAvailableTourTime: (param?: AvailableTimesParams): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_AVAILABLE_TOUR_TIMES_REQUEST,
        ActionType.GET_AVAILABLE_TOUR_TIMES_SUCCESS,
        ActionType.GET_AVAILABLE_TOUR_TIMES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.TOUR_AVAILABLE_TIME, { params: param }),
    },
  }),

  bulkEdit: (ids: number[], field: string, value: number | string): Action => ({
    CALL_API: {
      types: [
        ActionType.BULK_SAVE_TASKS_REQUEST,
        ActionType.BULK_SAVE_TASKS_SUCCESS,
        ActionType.BULK_SAVE_TASKS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.TASKS_BULK_SAVE, { ids, field, value }),
    },
  }),

  clearAvailableTourTime: (): Action => ({
    type: ActionType.CLEAR_AVAILABLE_TOUR_TIMES,
  }),
  setOwnerFilter: (filter: OwnerCategory): Action => ({
    type: ActionType.SET_OWNER_FILTER,
    filter,
  }),
};
