import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { multiSelectionList } from 'dwell/views/chat/agent_question/choices';
import actions from 'dwell/actions';
import ObjectSelector from './_object_selector';
import MultiObjectSelector from './_multi_object_selector';

interface OptionalFormProps {
  index: number;
}

const OptionalForm: FC<OptionalFormProps> = ({ index }) => {
  const { setGlobalForm } = actions.agentQuestion;
  const dispatch = useDispatch();
  const { globalForm } = useSelector(state => state.agentQuestion);
  const formData = globalForm[index];

  const multiSelectorChangeHandler = (selectedItems: { label: string; value: string }[]) => {
    const newState = [...globalForm];
    newState[index].multiSelectionAnswer = selectedItems?.map(s => s.label) || [];
    newState[index].multiSelectionValue = selectedItems?.map(s => s.value) || [];
    dispatch(setGlobalForm(newState));
  };

  const selectorChangeHandler = (label: string, value: string) => {
    let answer = null;
    let hidden = null;
    if (label !== 'Select time' && label !== 'Select option') {
      answer = label;
      hidden = value;
    }
    const newState = [...globalForm];
    newState[index].answer = answer;
    newState[index].value = hidden;
    dispatch(setGlobalForm(newState));
  };

  if (!formData.optional_answer_type) return <></>;

  if (multiSelectionList.includes(formData.optional_answer_type)) {
    return (
      <MultiObjectSelector
        type={formData.optional_answer_type}
        answers={formData.multiSelectionValue}
        onChange={selectedItems => multiSelectorChangeHandler(selectedItems)}
      />
    );
  }
  return (
    <ObjectSelector
      type={formData.optional_answer_type}
      text={formData.answer}
      onChange={(label, value) => selectorChangeHandler(label, value)}
      user_defined_options={formData.user_defined_options}
    />
  );
};

export default OptionalForm;
