import React from 'react';
import NumberFormat from 'react-number-format';
import { CustomFormInput } from 'leasing/views/applicants/styles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NumberInput = ({ onChange, ...rest }) => {
  let value = 0;
  return (
    <NumberFormat
      onValueChange={values => value = values.floatValue}
      customInput={CustomFormInput}
      onChange={() => onChange(value || null)}
      inputMode="numeric"
      thousandSeparator
      {...rest}
    />
  );
};

export default NumberInput;
