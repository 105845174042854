import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Col, Modal, ModalHeader, Row, ModalFooter, FormText } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { toast, ToastOptions } from 'react-toastify';

import { getLeadId } from 'dwell/views/lead/layout/utils';
import { toastError, toastOptions } from 'site/constants';
import { CheckBox } from 'leasing/components';
import { selectLead } from 'dwell/store/lead/reducers';
import { OptionContainer } from 'application/views/overview/application_details/transfer_people/styles';
import { SearchedObjectType } from 'dwell/store/pipeline/action-types';
import actions from 'dwell/store/actions';
import { getPropertyId } from 'src/utils';
import { TaskModalBody as ModalBody } from 'dwell/components/tasks/styles';
import { FormLabel } from 'dwell/components/leads/bulk_lead_edit_modal/styles';
import { CancelBtn, SaveBtn } from 'dwell/components/leads/lead_share_modal/styles';

interface Props {
  handleClose: () => void,
  show: boolean,
}

const applicantTypeOptions = [{ value: 'CO_APPLICANT', label: 'Co-Applicant' }, { value: 'GUARANTOR', label: 'Guarantor' }];

const TransferToApplication: FC<Props> = ({ handleClose, show }) => {
  const [applicantType, setApplicantType] = useState(applicantTypeOptions[0].value);
  const [isEmailInvite, setIsEmailInvite] = useState(true);
  const leadId = getLeadId(window.location.pathname);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { formState: { errors }, handleSubmit, control } = useForm();
  const lead = useSelector(selectLead);
  const { applications } = useSelector(state => state.pipeline.searchedObjects) as SearchedObjectType;
  const { searchObject } = actions.pipeline;
  const { transferToApplication } = actions.lead;
  const applicationOptions = useMemo(() => applications.filter(
    a => a.lease_status === null && a.name && a.application_stage !== 'APPLICATION_CANCELED',
  ).map(a => ({ value: a.id, object: a, label: `Application ${a.name}` })), [applications]);
  const closeBtn = <button className="close" onClick={handleClose}>&times;</button>;

  useEffect(() => {
    dispatch(searchObject(''));
  }, []);

  const handleTransfer = (data) => {
    if (data.application) {
      const payload = { applicationId: data.application.value, applicantType, isEmailInvite };
      dispatch(transferToApplication(leadId, payload)).then(() => {
        toast.success('Prospect is transferred to application', toastOptions as ToastOptions);
        push(`/${getPropertyId(window.location.pathname)}/applications/${payload.applicationId}/overview`);
      }).catch((error) => {
        const errorMessage = Object.values(error.response.data).reduce<string[]>((acc: string[], val: string[]) => acc.concat(val), []).pop();
        toast.error(errorMessage, toastError as ToastOptions);
      });
    }
  };

  const OptionComponent = (props) => {
    const object = applicationOptions.find(o => o.value === props.value)?.object;
    const { innerProps, innerRef } = props;
    return (
      <OptionContainer ref={innerRef} {...innerProps} >
        <span>Application for {object.name}</span>
        <br />
        <span>{object.leasing_users.join(' • ')}</span>
      </OptionContainer>
    );
  };

  return (
    <Modal
      centered
      isOpen={show}
      size="xl"
      toggle={handleClose}
    >
      <ModalHeader close={closeBtn}>
        <span>Transfer {lead.first_name} {lead.last_name} to Application</span>
      </ModalHeader>

      <ModalBody>
        <form>
          <Row>
            <Col xs={9} className="pb-2">
              <FormLabel>Application Group</FormLabel>
              <Controller
                control={control}
                name="application"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId="application"
                    isSearchable
                    options={applicationOptions}
                    components={{ Option: OptionComponent }}
                  />
                )}
              />
              {errors.application && errors.application.type === 'required' && (
                <>
                  <FormText color="danger">Application is required</FormText>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={9} className="pb-2">
              <FormLabel>Applicant Type</FormLabel>
              <Select
                defaultValue={applicantTypeOptions[0]}
                isSearchable={false}
                options={applicantTypeOptions}
                onChange={option => setApplicantType(option.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={9} className="pb-2">
              <CheckBox
                id="email-invite-check"
                checked={isEmailInvite}
                label="Email applicant invite to complete their application"
                onChange={() => setIsEmailInvite(!isEmailInvite)}
              />
            </Col>
          </Row>
        </form>
      </ModalBody>
      <ModalFooter>
        <CancelBtn className="btn" color="secondary" onClick={handleClose}>Cancel</CancelBtn>
        <SaveBtn className="btn" color="primary" onClick={handleSubmit(d => handleTransfer(d))}>Transfer To Application</SaveBtn>
      </ModalFooter>
    </Modal>
  );
};

export default TransferToApplication;
