import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_OTHER_FEE_ITEM_REQUEST = 'CREATE_OTHER_FEE_ITEM_REQUEST',
  CREATE_OTHER_FEE_ITEM_SUCCESS = 'CREATE_OTHER_FEE_ITEM_SUCCESS',
  CREATE_OTHER_FEE_ITEM_FAILURE = 'CREATE_OTHER_FEE_ITEM_FAILURE',

  DELETE_OTHER_FEE_ITEM_REQUEST = 'DELETE_OTHER_FEE_ITEM_REQUEST',
  DELETE_OTHER_FEE_ITEM_SUCCESS = 'DELETE_OTHER_FEE_ITEM_SUCCESS',
  DELETE_OTHER_FEE_ITEM_FAILURE = 'DELETE_OTHER_FEE_ITEM_FAILURE',

  UPDATE_OTHER_FEE_ITEM_REQUEST = 'UPDATE_OTHER_FEE_ITEM_REQUEST',
  UPDATE_OTHER_FEE_ITEM_SUCCESS = 'UPDATE_OTHER_FEE_ITEM_SUCCESS',
  UPDATE_OTHER_FEE_ITEM_FAILURE = 'UPDATE_OTHER_FEE_ITEM_FAILURE',
}

export interface OtherFeeItemState extends CommonStateType {
  otherFeeItems: OtherFeeItemProps[],
}

export interface OtherFeeItemProps {
  id?: number,
  name: string,
  fee: number,
}

/*
 * Actions
 */

interface CreateOtherFeeItemAction extends CommonActionResponse {
  type: ActionType.CREATE_OTHER_FEE_ITEM_REQUEST
  | ActionType.CREATE_OTHER_FEE_ITEM_SUCCESS
  | ActionType.CREATE_OTHER_FEE_ITEM_FAILURE,
  result: { data: OtherFeeItemProps },
}

interface DeleteOtherFeeItemAction extends CommonActionResponse {
  payload: number,
  type: ActionType.DELETE_OTHER_FEE_ITEM_REQUEST
  | ActionType.DELETE_OTHER_FEE_ITEM_SUCCESS
  | ActionType.DELETE_OTHER_FEE_ITEM_FAILURE,
  result: { data: OtherFeeItemProps },
}

interface UpdateOtherFeeItemAction extends CommonActionResponse {
  type: ActionType.UPDATE_OTHER_FEE_ITEM_REQUEST
  | ActionType.UPDATE_OTHER_FEE_ITEM_REQUEST
  | ActionType.UPDATE_OTHER_FEE_ITEM_FAILURE,
  result: { data: OtherFeeItemProps },
}

export type Action =
  PromiseActionType
  | CreateOtherFeeItemAction
  | DeleteOtherFeeItemAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdateOtherFeeItemAction;
