import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_ASSIGN_LEAD_OWNERS_REQUEST = 'GET_ASSIGN_LEAD_OWNERS_REQUEST',
  GET_ASSIGN_LEAD_OWNERS_SUCCESS = 'GET_ASSIGN_LEAD_OWNERS_SUCCESS',
  GET_ASSIGN_LEAD_OWNERS_FAILURE = 'GET_ASSIGN_LEAD_OWNERS_FAILURE',

  CREATE_ASSIGN_LEAD_OWNERS_REQUEST = 'CREATE_ASSIGN_LEAD_OWNERS_REQUEST',
  CREATE_ASSIGN_LEAD_OWNERS_SUCCESS = 'CREATE_ASSIGN_LEAD_OWNERS_SUCCESS',
  CREATE_ASSIGN_LEAD_OWNERS_FAILURE = 'CREATE_ASSIGN_LEAD_OWNERS_FAILURE',

  UPDATE_ASSIGN_LEAD_OWNERS_REQUEST = 'UPDATE_ASSIGN_LEAD_OWNERS_REQUEST',
  UPDATE_ASSIGN_LEAD_OWNERS_SUCCESS = 'UPDATE_ASSIGN_LEAD_OWNERS_SUCCESS',
  UPDATE_ASSIGN_LEAD_OWNERS_FAILURE = 'UPDATE_ASSIGN_LEAD_OWNERS_FAILURE',

  GET_CURRENT_ASSIGNED_OWNER_REQUEST = 'GET_CURRENT_ASSIGNED_OWNER_REQUEST',
  GET_CURRENT_ASSIGNED_OWNER_SUCCESS = 'GET_CURRENT_ASSIGNED_OWNER_SUCCESS',
  GET_CURRENT_ASSIGNED_OWNER_FAILURE = 'GET_CURRENT_ASSIGNED_OWNER_FAILURE',

  GET_ASSIGNMENT_RULES_REQUEST = 'GET_ASSIGNMENT_RULES_REQUEST',
  GET_ASSIGNMENT_RULES_SUCCESS = 'GET_ASSIGNMENT_RULES_SUCCESS',
  GET_ASSIGNMENT_RULES_FAILURE = 'GET_ASSIGNMENT_RULES_FAILURE',

  GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_REQUEST = 'GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_REQUEST',
  GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_SUCCESS = 'GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_SUCCESS',
  GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_FAILURE = 'GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_FAILURE',

  ASSIGNMENT_RULES_SET_ASSIGNMENT = 'ASSIGNMENT_RULES_SET_ASSIGNMENT',
  ASSIGNMENT_RULES_SET_EXCLUSION = 'ASSIGNMENT_RULES_SET_EXCLUSION',

  ASSIGNMENT_RULES_SAVE_REQUEST = 'ASSIGNMENT_RULES_SAVE_REQUEST',
  ASSIGNMENT_RULES_SAVE_SUCCESS = 'ASSIGNMENT_RULES_SAVE_SUCCESS',
  ASSIGNMENT_RULES_SAVE_FAILURE = 'ASSIGNMENT_RULES_SAVE_FAILURE',
}

export interface AssignLeadOwnerProps {
  id?: number
  monday: number,
  tuesday: number,
  wednesday: number,
  thursday: number,
  friday: number,
  saturday: number,
  sunday: number,
  is_enabled: boolean,
}

export interface OwnerProps {
  id: number,
  email: string,
}

interface GetAssignLeadOwnersAction {
  type: ActionType.GET_ASSIGN_LEAD_OWNERS_REQUEST
  | ActionType.GET_ASSIGN_LEAD_OWNERS_SUCCESS
  | ActionType.GET_ASSIGN_LEAD_OWNERS_FAILURE;

  result: {
    data: { results: AssignLeadOwnerProps[] }
  };
  error: {
    response: { status: string }
  };
}

interface CreateAssignLeadOwnersAction {
  type: ActionType.CREATE_ASSIGN_LEAD_OWNERS_REQUEST
  | ActionType.CREATE_ASSIGN_LEAD_OWNERS_SUCCESS
  | ActionType.CREATE_ASSIGN_LEAD_OWNERS_FAILURE;

  result: {
    data: AssignLeadOwnerProps
  };
  error: {
    response: { status: string }
  };
}

interface UpdateAssignLeadOwnersAction {
  type: ActionType.UPDATE_ASSIGN_LEAD_OWNERS_REQUEST
  | ActionType.UPDATE_ASSIGN_LEAD_OWNERS_SUCCESS
  | ActionType.UPDATE_ASSIGN_LEAD_OWNERS_FAILURE;

  result: {
    data: AssignLeadOwnerProps
  };
  error: {
    response: { status: string }
  };
}

interface GetCurrentAssignLeadOwnersAction {
  type: ActionType.GET_CURRENT_ASSIGNED_OWNER_REQUEST
  | ActionType.GET_CURRENT_ASSIGNED_OWNER_SUCCESS
  | ActionType.GET_CURRENT_ASSIGNED_OWNER_FAILURE;
  result: {
    data: OwnerProps
  };
  error: {
    response: { status: string }
  };
}

export interface Agent {
  id: number,
  name: string,
}

export interface AssignmentRules {
  monday_agents: Agent[],
  monday_exclusion_pool: Agent[],
  tuesday_agents: Agent[],
  tuesday_exclusion_pool: Agent[],
  wednesday_agents: Agent[],
  wednesday_exclusion_pool: Agent[],
  thursday_agents: Agent[],
  thursday_exclusion_pool: Agent[],
  friday_agents: Agent[],
  friday_exclusion_pool: Agent[],
  saturday_agents: Agent[],
  saturday_exclusion_pool: Agent[],
  sunday_agents: Agent[],
  sunday_exclusion_pool: Agent[],
}

export interface AgentsByRole {
  all_agents: Agent[],
  property_managers: Agent[],
  virtual_agents: Agent[],
  onsite_agents: Agent[],
}

interface GetAssignmentRulesAction {
  type: ActionType.GET_ASSIGNMENT_RULES_REQUEST
  | ActionType.GET_ASSIGNMENT_RULES_SUCCESS
  | ActionType.GET_ASSIGNMENT_RULES_FAILURE;
  result: {
    data: AssignmentRules
  };
  error: {
    response: { status: string }
  };
}

interface GetAgentsAssignmentRulesAction {
  type: ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_REQUEST
  | ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_SUCCESS
  | ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_FAILURE;
  result: {
    data: AgentsByRole
  };
  error: {
    response: { status: string }
  };
}

export enum Weekday {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export const DISPLAY_WEEKDAYS = {
  [Weekday.Monday]: 'Monday',
  [Weekday.Tuesday]: 'Tuesday',
  [Weekday.Wednesday]: 'Wednesday',
  [Weekday.Thursday]: 'Thursday',
  [Weekday.Friday]: 'Friday',
  [Weekday.Saturday]: 'Saturday',
  [Weekday.Sunday]: 'Sunday',
};

export interface AgentChanges {
  assignments: Agent[],
  exclusions: Agent[],
}

export type WeekdaysChanges = Record<Weekday, AgentChanges>;

interface SetAssignmentAction {
  type: ActionType.ASSIGNMENT_RULES_SET_ASSIGNMENT;
  payload: {
    weekday: Weekday,
    agents: Agent[]
  };
}

interface SetExclusionAction {
  type: ActionType.ASSIGNMENT_RULES_SET_EXCLUSION;
  payload: {
    weekday: Weekday,
    agents: Agent[]
  };
}

interface SaveAssignmentRulesAction {
  type: ActionType.ASSIGNMENT_RULES_SAVE_REQUEST
  | ActionType.ASSIGNMENT_RULES_SAVE_SUCCESS
  | ActionType.ASSIGNMENT_RULES_SAVE_FAILURE;
  result: {
    data: AssignmentRules
  };
  error: {
    response: { data: { status: number, error: string } }
  };
}

export type Action =
  | PromiseActionType
  | GetAssignLeadOwnersAction
  | CreateAssignLeadOwnersAction
  | UpdateAssignLeadOwnersAction
  | GetCurrentAssignLeadOwnersAction
  | GetAssignmentRulesAction
  | GetAgentsAssignmentRulesAction
  | SetAssignmentAction
  | SetExclusionAction
  | SaveAssignmentRulesAction;
