import React, { FC } from 'react';

import { processLinkName } from './utils';

import { NavLink } from './styles';

interface NavItemProps {
  name: string;
  alterName: string;
  siteId: string;
  activeLink: string;
  setActiveAndPush: (url: string) => void;
  startsWith?: boolean;
}

const NavItem: FC<NavItemProps> = ({ name, alterName, siteId, activeLink, startsWith, setActiveAndPush }) => {
  const link = `/${siteId}/${name}`;
  const isActive = startsWith ? activeLink.startsWith(link) : activeLink === link;

  return (
    <li className="nav-item">
      <NavLink className={`nav-link ${isActive && 'active'}`} onClick={() => setActiveAndPush(`/${siteId}/${name}`)}>
        {processLinkName(alterName || name)}
      </NavLink>
    </li>
  );
};

export default NavItem;
