import produce from 'immer';
import { OwnerProps } from 'src/interfaces';
import { defaultWeedaysChanges, initializeWeekdaysChanges } from 'dwell/views/settings/assignment_rules/utils';
import { Action, AssignLeadOwnerProps, ActionType, AssignmentRules, AgentsByRole, WeekdaysChanges } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface AssignmentRulesState extends CommonStateType {
  assignLeadOwners: AssignLeadOwnerProps;
  currentAssignedOwner: OwnerProps;
  assignmentRules: AssignmentRules;
  agentsByRole: AgentsByRole,
  changes: WeekdaysChanges;
}

export const initialState: AssignmentRulesState = {
  isSubmitting: false,
  errorMessage: null,
  assignLeadOwners: {} as AssignLeadOwnerProps,
  currentAssignedOwner: {} as OwnerProps,
  assignmentRules: {} as AssignmentRules,
  agentsByRole: {} as AgentsByRole,
  changes: defaultWeedaysChanges,
};

export const selectAssignmentRules = (state: { assignLeadOwners: AssignmentRulesState }): AssignmentRules => state.assignLeadOwners.assignmentRules;
export const selectAgentsByRole = (state: { assignLeadOwners: AssignmentRulesState }): AgentsByRole => state.assignLeadOwners.agentsByRole;
export const selectIsSubmitting = (state: { assignLeadOwners: AssignmentRulesState }): boolean => state.assignLeadOwners.isSubmitting;
export const selectChanges = (state: { assignLeadOwners: AssignmentRulesState }): WeekdaysChanges => state.assignLeadOwners.changes;

export default produce((state: AssignmentRulesState = initialState, action: Action): AssignmentRulesState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_ASSIGN_LEAD_OWNERS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_ASSIGN_LEAD_OWNERS_SUCCESS:
      state.isSubmitting = false;
      [state.assignLeadOwners] = action.result.data.results;
      break;
    case ActionType.GET_ASSIGN_LEAD_OWNERS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_ASSIGN_LEAD_OWNERS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_ASSIGN_LEAD_OWNERS_SUCCESS:
      state.isSubmitting = false;
      state.assignLeadOwners = action.result.data;
      break;
    case ActionType.UPDATE_ASSIGN_LEAD_OWNERS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.CREATE_ASSIGN_LEAD_OWNERS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_ASSIGN_LEAD_OWNERS_SUCCESS:
      state.isSubmitting = false;
      state.assignLeadOwners = action.result.data;
      break;
    case ActionType.CREATE_ASSIGN_LEAD_OWNERS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.GET_CURRENT_ASSIGNED_OWNER_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_CURRENT_ASSIGNED_OWNER_SUCCESS:
      state.isSubmitting = false;
      state.currentAssignedOwner = action.result.data;
      break;
    case ActionType.GET_CURRENT_ASSIGNED_OWNER_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.GET_ASSIGNMENT_RULES_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_ASSIGNMENT_RULES_SUCCESS:
      state.isSubmitting = false;
      state.assignmentRules = action.result.data;
      // Initialize changes with the fetched assignment rules
      // this way we avoid issues on saving days not updated by the agent
      state.changes = initializeWeekdaysChanges(state.assignmentRules);
      break;
    case ActionType.GET_ASSIGNMENT_RULES_FAILURE:
      state.errorMessage = action.error.response.status;
      state.isSubmitting = false;
      break;

    case ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_SUCCESS:
      state.isSubmitting = false;
      state.agentsByRole = action.result.data;
      break;
    case ActionType.GET_AGENTS_BY_ROLES_ASSIGNMENT_RULES_FAILURE:
      state.errorMessage = action.error.response.status;
      state.isSubmitting = false;
      break;

    case ActionType.ASSIGNMENT_RULES_SET_ASSIGNMENT: {
      const field = 'assignments';
      const { weekday, agents } = action.payload;
      state.changes[weekday][field] = agents;
      break;
    }

    case ActionType.ASSIGNMENT_RULES_SET_EXCLUSION: {
      const field = 'exclusions';
      const { weekday, agents } = action.payload;
      state.changes[weekday][field] = agents;
      break;
    }

    case ActionType.ASSIGNMENT_RULES_SAVE_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.ASSIGNMENT_RULES_SAVE_SUCCESS:
      state.isSubmitting = false;
      state.assignmentRules = action.result.data;
      state.changes = defaultWeedaysChanges;
      break;
    case ActionType.ASSIGNMENT_RULES_SAVE_FAILURE:
      state.errorMessage = action.error.response.data.error;
      state.isSubmitting = false;
      break;
  }

  return state;
});
