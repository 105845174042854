class PhotosFilter {
  container: HTMLElement;
  tabNames: string[];
  filteredTabNames: string[];
  constructor(container: HTMLElement, photos: { [key: string]: string[] }, categories: string[]) {
    this.container = container;
    const capitalizedCategories = categories.map(category => category.charAt(0).toUpperCase() + category.slice(1));
    this.tabNames = ['All', ...capitalizedCategories];
    this.filteredTabNames = this.tabNames.filter((tabName) => {
      if (tabName === 'All') {
        return true;
      }
      return Object.keys(photos).includes(tabName.toLowerCase()) && photos[tabName.toLowerCase()].length > 0;
    });
  }

  draw = (): HTMLAnchorElement[] => {
    const tabs = this.tabNames.map((tabName) => {
      const tab = document.createElement('a');
      tab.classList.add('nav-link');
      tab.setAttribute('href', '#');
      if (tabName === 'All') {
        tab.classList.add('active');
      }
      tab.innerText = tabName;
      return tab;
    });
    return tabs;
  };

  getTabsFromContainer = (): NodeListOf<HTMLAnchorElement> => {
    const tabs = this.container.querySelectorAll('.nav-link') as NodeListOf<HTMLAnchorElement>;
    return tabs;
  };
}

export default PhotosFilter;
