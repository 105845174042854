export enum ActionType {
  GET_CALENDARS_REQUEST = 'get_calendars_request',
  GET_CALENDARS_SUCCESS = 'get_calendars_success',
  GET_CALENDARS_FAILURE = 'get_calendars_failure',
}

export interface CalendarProps {
  id: number,
  name: string,
}

interface GetCalendarsAction {
  type: ActionType.GET_CALENDARS_REQUEST
  | ActionType.GET_CALENDARS_SUCCESS
  | ActionType.GET_CALENDARS_FAILURE;

  result: {
    data: { results: CalendarProps[] }
  };
  error: {
    response: { status: string }
  };
}

export type Action = | GetCalendarsAction;
