import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action } from './action-types';

interface ScoredCall {
  [key: string]: string | number | number[];
}

export default {
  getScoredCalls: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SCORED_CALLS_REQUEST,
        ActionType.GET_SCORED_CALLS_SUCCESS,
        ActionType.GET_SCORED_CALLS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.SCORED_CALLS, { params: { show_all: true } }),
    },
  }),

  updateScoredCallById: (id: number, data: ScoredCall): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SCORED_CALL_REQUEST,
        ActionType.UPDATE_SCORED_CALL_SUCCESS,
        ActionType.UPDATE_SCORED_CALL_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.SCORED_CALL_DETAILS, id), data),
    },
  }),

  createScoredCall: (property: number, data: ScoredCall): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_SCORED_CALL_REQUEST,
        ActionType.CREATE_SCORED_CALL_SUCCESS,
        ActionType.CREATE_SCORED_CALL_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.SCORED_CALLS, data, { params: { property } }),
    },
  }),

  requireRescoreCall: (id: number, data: { reason: string, report_id: number }): Action => ({
    CALL_API: {
      types: [
        ActionType.REQUIRE_RESCORE_CALL_REQUEST,
        ActionType.REQUIRE_RESCORE_CALL_SUCCESS,
        ActionType.REQUIRE_RESCORE_CALL_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.REQUIRE_RESCORE_CALL, id), data),
    },
  }),

  getCallRescoresMeta: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CALL_RESCORES_META_REQUEST,
        ActionType.GET_CALL_RESCORES_META_SUCCESS,
        ActionType.GET_CALL_RESCORES_META_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CALL_RESCORES_META),
    },
  }),
};
