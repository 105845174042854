import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, GarageProps } from './action-types';

export default {
  createGarage: (leaseId: number, body: GarageProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_GARAGE_REQUEST,
        ActionType.CREATE_GARAGE_SUCCESS,
        ActionType.CREATE_GARAGE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.GARAGES, leaseId), body),
    },
  }),

  updateGarage: (leaseId: number, garageId: number, params: GarageProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_GARAGE_REQUEST,
        ActionType.UPDATE_GARAGE_SUCCESS,
        ActionType.UPDATE_GARAGE_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.GARAGE_DETAILS, leaseId, garageId), params),
    },
  }),

  deleteGarage: (leaseId: number, garageId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_GARAGE_REQUEST,
        ActionType.DELETE_GARAGE_SUCCESS,
        ActionType.DELETE_GARAGE_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.GARAGE_DETAILS, leaseId, garageId)),
    },
  }),
};
