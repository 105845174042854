import React, { FC, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { CustomDropdown, ObjectSelectButton, TimeSelectMenu, ObjectSelectItem } from '../styles';
import { optionsMap } from '../choices';

interface genericOptions {
  name: string;
  value: string;
}

interface ObjectSelectorProps {
  onChange: (label: string, value: string) => void;
  text: string;
  type: string;
  user_defined_options: genericOptions[];
}

const ObjectSelector: FC<ObjectSelectorProps> = ({ onChange, text, type, user_defined_options }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { properties, users, submarkets, competitors } = useSelector(state => state.agentQuestion.questionOptions);
  const extractChoices = objects => objects.map(p => ({ name: p.name, value: p.id.toString() }));
  const propertyChoices = extractChoices(properties);
  const userChoices = extractChoices(users);
  const submarketChoices = extractChoices(submarkets);
  const competitorChoices = extractChoices(competitors);
  const processedUserDefinedOptions = user_defined_options.map(u => ({ name: u.name, value: u.value || u.name }));

  const generateArrayOfTimes = (step) => {
    const dt = new Date(1970, 0, 1);
    const times = [];
    while (dt.getDate() === 1) {
      times.push({ name: dt.toLocaleTimeString('it-IT'), value: dt.toLocaleTimeString('it-IT') });
      dt.setMinutes(dt.getMinutes() + step);
    }
    return times;
  };

  const options = {
    ...optionsMap,
    ANSWER_USER_DEFINED_OPTIONS: processedUserDefinedOptions,
    ANSWER_AGENT_OPTIONS: userChoices,
    ANSWER_PROPERTIES_OPTIONS: propertyChoices,
    ANSWER_COMPETITORS_OPTIONS: competitorChoices,
    ANSWER_SUBMARKETS_OPTIONS: submarketChoices,
  };

  return (
    <React.Fragment>
      <CustomDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} isEmpty={!text}>
        <ObjectSelectButton isEmpty={!text}>
          {type !== 'ANSWER_TIME_OPTIONS' ? (
            <span style={{ width: '140px', overflow: 'hidden', whiteSpace: 'nowrap', display: 'block', textOverflow: 'ellipsis' }}>{text || 'Select option'}</span>
          ) : (
            <span style={{ width: '140px', overflow: 'hidden', whiteSpace: 'nowrap', display: 'block', textOverflow: 'ellipsis' }}>{text ? moment(text, 'h:mm A').format('h:mm A') : 'Select time'}</span>
          )}
        </ObjectSelectButton>
        <TimeSelectMenu>
          {type !== 'ANSWER_TIME_OPTIONS'
            ? options[type].map((t, i) => (
              <React.Fragment key={i}>
                <ObjectSelectItem onClick={() => onChange(t.name, t.value)}>{t.name}</ObjectSelectItem>
              </React.Fragment>
            ))
            : generateArrayOfTimes(30).map((t, i) => (
              <React.Fragment key={i}>
                <ObjectSelectItem onClick={() => onChange(t.name, t.value)}>{moment(t.name, 'h:mm A').format('h:mm A')}</ObjectSelectItem>
              </React.Fragment>
            ))}
        </TimeSelectMenu>
      </CustomDropdown>
    </React.Fragment>
  );
};

export default ObjectSelector;
