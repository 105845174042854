import { AxiosInstance } from 'axios';
import { ActionType as ProfileActionType } from './property_profile/action-types';
import { ActionType as AssistBotActionType } from './assist_bot/action-types';
import { ActionType as AgentQuestionActionType } from './agent_question/action-types';
import { ActionType as PriceQuoteActionType } from './price_quote/action-types';
import { ActionType as LeadActionType } from './lead/action-types';
import { ActionType as UserActionType } from './user/action-types';
import { ActionType as AuthenticationActionType } from './authentication/action-types';

export type CallBackFunction = (data?: { message: string }) => void | number;

export interface PromiseActionType {
  [action: string]: {
    type?: string,
    types: string[],
    promise: (axios: AxiosInstance) => void,
    sections?: string,
    successCB?: CallBackFunction,
    failureCB?: CallBackFunction,
    unitType?: string,
    id?: number,
    newHoldId?: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any,
    objectId?: number,
    config?: {
      property_external_id?: string,
    },
  }
}

export interface CommonActionResponse {
  error: { status: string };
}

export enum CommonActionType {
  PUSHER_CREATE_RECORD = 'PUSHER_CREATE_RECORD',
  PUSHER_UPDATE_RECORD = 'PUSHER_UPDATE_RECORD',
  PUSHER_DELETE_RECORD = 'PUSHER_DELETE_RECORD'
}

export interface CommonStateType {
  isLoaded?: boolean,
  isSubmitting?: boolean,
  errorMessage?: string,
}

export interface OffsetPaginationParamTypes {
  offset: number,
  limit: number,
  property?: number,
}

export const ActionType = { ...ProfileActionType, ...AssistBotActionType, ...AgentQuestionActionType, ...PriceQuoteActionType, ...LeadActionType, ...UserActionType, ...AuthenticationActionType };
