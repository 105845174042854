import produce from 'immer';
import { isEmpty } from 'lodash';

import { Action, ActionType, ListFilters, MediaParams } from './action-types';

export interface MediaLibraryState {
  toolbarNavTabMedia: 'ALL_MEDIA'|'IMAGES'|'VIDEO'|'AUDIO'|'DOCUMENTS';
  showFormat: 'MASONRY'|'GRID'|'LIST';
  mediaDataLoaded: boolean;
  mediaObjects: MediaParams[];
  labelsDataLoaded: boolean;
  labels: string[];
  checkedFiles: MediaParams[];
  mediaObject: MediaParams | null;
  listFilters: ListFilters;
}

export const initialState: MediaLibraryState = {
  toolbarNavTabMedia: 'ALL_MEDIA',
  showFormat: 'GRID',
  mediaDataLoaded: false,
  mediaObjects: [],
  labelsDataLoaded: false,
  labels: [],
  checkedFiles: [],
  mediaObject: null,
  listFilters: {},
};

export const selectToolbarNavTabMedia = (state: { mediaLibrary: MediaLibraryState }): 'ALL_MEDIA'|'IMAGES'|'VIDEO'|'AUDIO'|'DOCUMENTS' => state.mediaLibrary.toolbarNavTabMedia;
export const selectShowFormat = (state: { mediaLibrary: MediaLibraryState }): 'MASONRY'|'GRID'|'LIST' => state.mediaLibrary.showFormat;
export const selectMediaList = (state: { mediaLibrary: MediaLibraryState }): MediaParams[] => state.mediaLibrary.mediaObjects;
export const selectListFilters = (state: { mediaLibrary: MediaLibraryState }): ListFilters => state.mediaLibrary.listFilters;
export const selectMediaDataLoaded = (state: { mediaLibrary: MediaLibraryState }): boolean => state.mediaLibrary.mediaDataLoaded;
export const selectLabelsList = (state: { mediaLibrary: MediaLibraryState }): string[] => state.mediaLibrary.labels;
export const selectCheckedFiles = (state: { mediaLibrary: MediaLibraryState }): MediaParams[] => state.mediaLibrary.checkedFiles;
export const selectMediaObject = (state: { mediaLibrary: MediaLibraryState }): MediaParams => state.mediaLibrary.mediaObject;

export default produce((state: MediaLibraryState = initialState, action: Action): MediaLibraryState => {
  switch (action.type) {
    case ActionType.SET_TOOLBAR_NAV_TAB_MEDIA:
      state.toolbarNavTabMedia = action.nav;
      break;
    case ActionType.SET_SHOW_FORMAT:
      state.showFormat = action.format;
      break;
    case ActionType.SET_CHECKED_FILES:
      state.checkedFiles = action.checkedFiles;
      break;
    case ActionType.RESET_MEDIA_LIST:
      state.mediaDataLoaded = false;
      state.mediaObjects = action.mediaObjects;
      break;
    case ActionType.SET_LIST_FILTERS: {
      let updatedFilters: ListFilters = { ...state.listFilters };

      const { trash, favorites, recently_added, label } = action.filters;

      if (trash !== undefined) {
        updatedFilters.trash = trash;
        delete updatedFilters.favorites;
        delete updatedFilters.recently_added;
        delete updatedFilters.label;
      } else if (favorites !== undefined) {
        updatedFilters.favorites = favorites;
        delete updatedFilters.trash;
        delete updatedFilters.recently_added;
        delete updatedFilters.label;
      } else if (recently_added !== undefined) {
        updatedFilters.recently_added = recently_added;
        delete updatedFilters.trash;
        delete updatedFilters.favorites;
        delete updatedFilters.label;
      }

      if (label !== undefined) updatedFilters.label = label;

      if (action.filters.type !== undefined) {
        if (action.filters.type === 'uploaded') updatedFilters = { recently_added: true };
        else if (action.filters.type === 'all_media') updatedFilters.type = undefined;
        else updatedFilters.type = action.filters.type;
      }

      state.listFilters = isEmpty(action.filters) ? {} : updatedFilters;
      break;
    }

    // List Media
    case ActionType.LIST_CUSTOMER_MEDIA_REQUEST:
    case ActionType.LIST_PROPERTY_MEDIA_REQUEST:
      state.mediaDataLoaded = false;
      break;
    case ActionType.LIST_CUSTOMER_MEDIA_SUCCESS:
    case ActionType.LIST_PROPERTY_MEDIA_SUCCESS: {
      const mediaObjects = state.mediaObjects
        .filter(p => !action.result.data.results.map(item => item.id).includes(p.id))
        .concat(action.result.data.results);
      state.mediaDataLoaded = true;
      state.mediaObjects = mediaObjects;
      break;
    }
    case ActionType.LIST_CUSTOMER_MEDIA_FAILURE:
    case ActionType.LIST_PROPERTY_MEDIA_FAILURE:
      state.mediaDataLoaded = false;
      state.mediaObjects = [];
      break;

    // List Labels
    case ActionType.LIST_CUSTOMER_MEDIA_LABELS_REQUEST:
    case ActionType.LIST_PROPERTY_MEDIA_LABELS_REQUEST:
      state.labelsDataLoaded = false;
      break;
    case ActionType.LIST_CUSTOMER_MEDIA_LABELS_SUCCESS:
    case ActionType.LIST_PROPERTY_MEDIA_LABELS_SUCCESS:
      state.labelsDataLoaded = true;
      state.labels = action.result.data.labels;
      break;
    case ActionType.LIST_CUSTOMER_MEDIA_LABELS_FAILURE:
    case ActionType.LIST_PROPERTY_MEDIA_LABELS_FAILURE:
      state.labelsDataLoaded = false;
      state.labels = [];
      break;

    // Create Media
    case ActionType.ADD_CUSTOMER_MEDIA_FILE_REQUEST:
    case ActionType.ADD_PROPERTY_MEDIA_FILE_REQUEST:
      state.mediaDataLoaded = false;
      break;
    case ActionType.ADD_CUSTOMER_MEDIA_FILE_SUCCESS:
    case ActionType.ADD_PROPERTY_MEDIA_FILE_SUCCESS:
      state.mediaDataLoaded = true;
      state.mediaObject = action.result.data;
      break;
    case ActionType.ADD_CUSTOMER_MEDIA_FILE_FAILURE:
    case ActionType.ADD_PROPERTY_MEDIA_FILE_FAILURE:
      state.mediaDataLoaded = false;
      state.mediaObject = null;
      break;

    // Update Media
    case ActionType.UPDATE_CUSTOMER_MEDIA_FILE_REQUEST:
    case ActionType.UPDATE_PROPERTY_MEDIA_FILE_REQUEST:
      state.mediaDataLoaded = false;
      break;
    case ActionType.UPDATE_CUSTOMER_MEDIA_FILE_SUCCESS:
    case ActionType.UPDATE_PROPERTY_MEDIA_FILE_SUCCESS:
      state.mediaDataLoaded = true;
      state.mediaObject = action.result.data;
      break;
    case ActionType.UPDATE_CUSTOMER_MEDIA_FILE_FAILURE:
    case ActionType.UPDATE_PROPERTY_MEDIA_FILE_FAILURE:
      state.mediaDataLoaded = false;
      state.mediaObject = null;
      break;
  }
  return state;
});
