import { Call as TwilioCall } from '@twilio/voice-sdk';
import { paths } from 'dwell/constants';
import { PropertyProps } from 'dwell/store/property/action-types';
import { LeadResidentSearch } from 'dwell/store/pipeline/action-types';
import { PromiseActionType } from '../types';
import {
  ActionType,
  Action,
  InboundCallParams,
  OutboundCallParams,
  TransferCallToAgentParams,
  TransferCallToPropertyTeamParams,
  CallFromDesktopPhoneParams,
  AgentPhoneType,
  CallDirectionType,
  LeadCallInfo,
  CallWindowBodyComponent,
  OutboundCallSatus,
  TransferReasonType,
} from './action-types';

export default {
  getCallContacts: (offset?: number, limit?: number): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.GET_CALL_CONTACTS_REQUEST,
        ActionType.GET_CALL_CONTACTS_SUCCESS,
        ActionType.GET_CALL_CONTACTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CALL_CONTACTS, { params: { offset, limit } }),
    },
  }),
  clearCallContacts: (): Action => ({ type: ActionType.CLEAR_CALL_CONTACTS }),
  getAvailableAgents: (propertyId: number, topic: string, direction: string, to?: string): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.GET_AVAILABLE_AGENTS_REQUEST,
        ActionType.GET_AVAILABLE_AGENTS_SUCCESS,
        ActionType.GET_AVAILABLE_AGENTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.AVAILABLE_AGENTS, { params: { property_id: propertyId, topic, direction, to } }),
    },
  }),
  getVoiceToken: (refreshOnly = false): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.GET_VOICE_TOKEN_REQUEST,
        ActionType.GET_VOICE_TOKEN_SUCCESS,
        ActionType.GET_VOICE_TOKEN_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.VOICE_TOKEN, { refresh_only: refreshOnly }),
    },
  }),
  createDevice: (): Action => ({ type: ActionType.CREATE_DEVICE }),
  clearDevice: (): Action => ({ type: ActionType.CLEAR_DEVICE }),
  refreshDeviceToken: (): Action => ({ type: ActionType.REFRESH_DEVICE_TOKEN }),
  setUserUnavailbleToReceiveCalls: (user: number): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_REQUEST,
        ActionType.SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_SUCCESS,
        ActionType.SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.UNAVAILABLE_TO_RECEIVE_CALLS, { user_id: user }),
    },
  }),
  transferToAgent: (params: TransferCallToAgentParams): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.TRANSFER_CALL_TO_AGENT_REQUEST,
        ActionType.TRANSFER_CALL_TO_AGENT_SUCCESS,
        ActionType.TRANSFER_CALL_TO_AGENT_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.TRANSFER_CALL_TO_AGENT, params),
    },
  }),
  transferToPropertyTeam: (params: TransferCallToPropertyTeamParams): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_REQUEST,
        ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_SUCCESS,
        ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.TRANSFER_CALL_TO_PROPERTY_TEAM, params),
    },
  }),
  setTransferReason: (reason: TransferReasonType): Action => ({ type: ActionType.SET_TRANSFER_REASON, reason }),
  setIncomingCall: (call: TwilioCall): Action => ({ type: ActionType.SET_INCOMING_CALL, call }),
  showIncomingCallNotification: (): Action => ({ type: ActionType.SHOW_INCOMING_CALL_NOTIFICATION }),
  setTimeShowingIncomingCallNotification: (time: number): Action => ({ type: ActionType.TIME_SHOWING_INCOMING_CALL_NOTIFICATION, time }),
  closeCallNotification: (): Action => ({ type: ActionType.CLOSE_CALL_NOTIFICATION }),
  collapse: (): Action => ({
    type: ActionType.COLLAPSE_CALL_WINDOW,
  }),
  show: (): Action => ({
    type: ActionType.SHOW_CALL_WINDOW,
  }),
  showDialWindow: (): Action => ({
    type: ActionType.SHOW_DIAL_WINDOW,
  }),
  hideDialWindow: (): Action => ({
    type: ActionType.HIDE_DIAL_WINDOW,
  }),
  acceptIncomigCall: (params: InboundCallParams): Action => ({
    type: ActionType.INCOMING_CALL_ACCEPTED,
    payload: {
      ...params,
      collapsed: false,
      direction: CallDirectionType.INBOUND,
      phoneType: AgentPhoneType.BROWSER_PHONE,
    },
  }),
  clearInProgressCall: (): Action => ({ type: ActionType.CLEAR_IN_PROGRESS_CALL }),
  callContactFromBrowserPhone: (params: OutboundCallParams): Action => ({
    type: ActionType.CALL_CONTACT_FROM_BROWSER_PHONE,
    payload: {
      prospectType: 'Prospect',
      collapsed: false,
      direction: CallDirectionType.OUTBOUND,
      ...params,
    },
  }),
  setCallingFromDesktopProspectName: (prospectName: string): Action => ({
    type: ActionType.SET_CALLING_FROM_DESKTOP_PROSPECT_NAME,
    prospectName,
  }),
  callContactFromDesktopPhone: (payload: CallFromDesktopPhoneParams): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_REQUEST,
        ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_SUCCESS,
        ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CALL_FROM_DESKTOP_PHONE, payload),
    },
  }),
  callContactFromPropertyOfficeLine: (payload: CallFromDesktopPhoneParams): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_REQUEST,
        ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_SUCCESS,
        ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CALL_FROM_PROPERTY_OFFICE_LINE, payload),
    },
  }),
  hangupDesktopCall: (callSid: string, conferenceSid?: string): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.HANGUP_DESKTOP_CALL_REQUEST,
        ActionType.HANGUP_DESKTOP_CALL_SUCCESS,
        ActionType.HANGUP_DESKTOP_CALL_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.HANGUP_DESKTOP_CALL, { call_sid: callSid, conference_sid: conferenceSid }),
    },
  }),
  updateInProgressCall: (leadInfo: LeadCallInfo): Action => ({
    type: ActionType.UPDATE_IN_PROGRESS_CALL,
    leadInfo,
  }),
  setCallWindowBody: (body: CallWindowBodyComponent): Action => ({
    type: ActionType.SET_CALL_WINDOW_BODY_COMPONENT,
    body,
  }),
  toggleMute: (muted: boolean): Action => ({
    type: ActionType.TOGGLE_MUTE,
    muted,
  }),
  setCallFinished: (): Action => ({
    type: ActionType.SET_CALL_FINISHED,
  }),
  setCallTime: (time: number): Action => ({
    type: ActionType.SET_CALL_TIME,
    time,
  }),
  setOutboundCallStatus: (status: OutboundCallSatus): Action => ({
    type: ActionType.SET_OUTBOUND_CALL_STATUS,
    status,
  }),
  setCallNotes: (notes: string): Action => ({
    type: ActionType.SET_CALL_NOTES,
    notes,
  }),
  setHasMicrophoneAccess: (enabled: boolean): Action => ({
    type: ActionType.SET_HAS_MICROPHONE_ACCESS,
    enabled,
  }),
  setShowAskForMicrophoneAccess: (show: boolean): Action => ({
    type: ActionType.SET_SHOW_ASK_FOR_MICROPHONE_ACCESS,
    show,
  }),
  setDialedPhoneNumber: (phone: string): Action => ({
    type: ActionType.SET_DIALED_PHONE_NUMBER,
    phone,
  }),
  setDialedProperty: (property: PropertyProps): Action => ({
    type: ActionType.SET_DIALED_PROPERTY,
    property,
  }),
  setDialedContact: (contact: LeadResidentSearch): Action => ({
    type: ActionType.SET_DIALED_CONTACT,
    contact,
  }),
  clearInProgressCallWithoutNoiseSuppression: (): Action => ({ type: ActionType.CLEAR_IN_PROGRESS_CALL_WITHOUT_NOISE_CANCELLING }),
  createDeviceWithoutNoiseCancel: (): Action => ({ type: ActionType.CREATE_DEVICE_WITHOUT_NOISE_CANCELLING }),
  setOnHold: (conferenceSid: string, onHold: boolean, parentCallSid: string, currentCallSid: string): PromiseActionType => ({
    // We might need to remove conference name for everything if we don't need it.
    CALL_API: {
      types: [
        ActionType.ON_HOLD_REQUEST,
        ActionType.ON_HOLD_SUCCESS,
        ActionType.ON_HOLD_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.SET_CALL_ON_HOLD,
        {
          conferenceSid,
          onHold,
          parentCallSid,
          currentCallSid,
        },
      ),
    },
  }),
  endConference: (conferenceSid: string): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.END_CONFERENCE_REQUEST,
        ActionType.END_CONFERENCE_SUCCESS,
        ActionType.END_CONFERENCE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.END_CONFERENCE, { conference_sid: conferenceSid }),
    },
  }),
};
