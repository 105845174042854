/* eslint-disable jsx-a11y/label-has-for */
import React, { FC } from 'react';
import { capitalize } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { CallDirectionType, TOPIC_VALUE } from 'dwell/store/voice/action-types';
import { selectCallNotes, selectCallTime } from 'dwell/store/voice/reducers';
import voiceActions from 'dwell/store/voice/action-creator';
import { SummaryItem } from './styles';
import CallBodyItem from './call_body_item';

interface SummaryProps {
  callDirection: CallDirectionType,
  callStatus: string,
  sourceName: string,
  topic: string,
  hide: boolean,
}

const Summary: FC<SummaryProps> = ({ callDirection, callStatus, sourceName, topic, hide }) => {
  const dispatch = useDispatch();
  const duration = useSelector(selectCallTime);
  const notes = useSelector(selectCallNotes);

  const { setCallNotes } = voiceActions;

  return (
    <CallBodyItem itemTitle="Call Summary" hide={hide} >
      <SummaryItem>
        <div className="media-icon">
          <i className="ri-arrow-up-down-line" />
        </div>
        <div className="media-body">
          <label>Direction</label>
          <h6>{capitalize(callDirection)}</h6>
        </div>
      </SummaryItem>
      <SummaryItem>
        <div className="media-icon">
          <i className="ri-alarm-fill" />
        </div>
        <div className="media-body">
          <label>Duration</label>
          <h6>{moment.utc(duration * 1000).format('m:ss')}</h6>
        </div>
      </SummaryItem>
      <SummaryItem>
        <div className="media-icon">
          <i className="ri-bar-chart-box-fill" />
        </div>
        <div className="media-body">
          <label>Status</label>
          {/*
            If it was an incoming call then at this point the call is going to be "Answered"
            (you can't see call summary if call was rejected).
            However, if agent started the call we have to follow callStatus value.
          */}
          <h6>{callDirection === 'inbound' ? 'Answered' : callStatus}</h6>
        </div>
      </SummaryItem>
      {callDirection === 'inbound' &&
        <>
          <SummaryItem>
            <div className="media-icon">
              <i className="ri-global-fill" />
            </div>
            <div className="media-body">
              <label>Source</label>
              <h6>{sourceName}</h6>
            </div>
          </SummaryItem>
          <SummaryItem>
            <div className="media-icon">
              <i className="ri-keyboard-box-fill" />
            </div>
            <div className="media-body">
              <label>Topic</label>
              <h6>{TOPIC_VALUE[topic]}</h6>
            </div>
          </SummaryItem>
        </>
      }
      <textarea
        className="form-control"
        placeholder="Add call notes"
        style={{ height: 175 }}
        value={notes}
        onChange={({ target: { value } }) => dispatch(setCallNotes(value))}
      />
    </CallBodyItem>
  );
};

export default Summary;
