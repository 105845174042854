export default {
  BicycleMaximumRentalPeriod: {
    ONE_DAY: '1 day',
    TWO_DAY: '2 days',
    THREE_DAY: '3 days',
    FOUR_DAY: '4 days',
    FIVE_DAY: '5 days',
    SIX_DAY: '6 days',
    SEVEN_DAY: '7 days',
    TWO_WEEK: '2 weeks',
    FOUR_WEEK: '4 weeks',
  },

  BicycleRentPeriod: {
    PER_DAY: 'per day',
    PEW_WEEK: 'per week',
    PER_MONTH: 'per month',
  },

  GroupFitnessVendor: {
    TORCH_FITNESS: 'Torch Fitness',
    UP_N_CO: 'Up&Co',
    OTHER: 'Other',
  },

  InternetServiceVendor: {
    COX: 'Cox',
    CENTURY_LINK: 'Century Link',
    MEREO_NETWORKS: 'Mereo Networks',
    SINGLE_DIGITS: 'Single Digits',
    SMARTARIA: 'Smartaria',
    WHITESKY: 'WhiteSky',
    OTHER: 'Other',
  },

  PackageServiceVendor: {
    AMAZON_LOCKER: 'Amazon Locker',
    FETCH: 'Fetch',
    LUXOR: 'Luxor',
    PARCEL_PENDING: 'Parcel Pending',
    OTHER: 'Other',
  },

  ParkingServicesVendor: {
    ZARK: 'Zark',
    OTHER: 'Other',
  },

  SmartHomeVendor: {
    BUTTERFLY_MX: 'Butterfly MX',
    DORMAKABA: 'Dormakaba',
    DWELLO: 'Dwello',
    MEREO_NETWORKS: 'Mereo Networks',
    SMART_LOCK_HONEYWELL_THERMOSTAT: 'Smart Lock/Honeywell Thermostat',
    VIVINT: 'Vivint',
    OTHER: 'Other',
  },

  TrashServiceVendor: {
    ALLY_WASTE: 'Ally Waste',
    VALET_LIVING: 'Valet Living',
    OTHER: 'Other',
  },

  TVVendor: {
    COX: 'Cox',
    DIRECT_TV: 'Direct TV',
    SMARTARIA: 'Smartaria',
    OTHER: 'Other',
  },
};
