import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { CallBackFunction } from 'src/interfaces';
import { ActionType, Action, ChatTemplateProps } from './action-types';

export default {
  getChatTemplates: (property?: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CHAT_TEMPLATES_REQUEST,
        ActionType.GET_CHAT_TEMPLATES_SUCCESS,
        ActionType.GET_CHAT_TEMPLATES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CHAT_TEMPLATES, { params: { property, show_all: true } }),
    },
  }),
  getChatTemplateById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CHAT_TEMPLATE_BY_ID_REQUEST,
        ActionType.GET_CHAT_TEMPLATE_BY_ID_SUCCESS,
        ActionType.GET_CHAT_TEMPLATE_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.CHAT_TEMPLATE_DETAILS, id)),
    },
  }),
  createChatTemplate: (data: ChatTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_CHAT_TEMPLATE_REQUEST,
        ActionType.CREATE_CHAT_TEMPLATE_SUCCESS,
        ActionType.CREATE_CHAT_TEMPLATE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CHAT_TEMPLATES, data),
      successCB,
    },
  }),
  updateChatTemplateById: (id: number, data: ChatTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CHAT_TEMPLATE_REQUEST,
        ActionType.UPDATE_CHAT_TEMPLATE_SUCCESS,
        ActionType.UPDATE_CHAT_TEMPLATE_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.CHAT_TEMPLATE_DETAILS, id), data),
      successCB,
    },
  }),
  deleteChatTemplateById: (id: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_CHAT_TEMPLATE_REQUEST,
        ActionType.DELETE_CHAT_TEMPLATE_SUCCESS,
        ActionType.DELETE_CHAT_TEMPLATE_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.CHAT_TEMPLATE_DETAILS, id)),
      successCB,
    },
  }),
};
