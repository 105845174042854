import { PromiseActionType } from 'dwell/store/types';
import { GetCurrentUserAction } from '../user/action-types';

export enum ActionType {
  MINIMIZE_AGENT_QUESTION_WINDOW = 'MINIMIZE_AGENT_QUESTION_WINDOW',
  CLOSE_AGENT_QUESTION_WINDOW = 'CLOSE_AGENT_QUESTION_WINDOW',
  ENABLE_AGENT_QUESTION = 'ENABLE_AGENT_QUESTION',

  GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST',
  GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS',
  GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE',

  SET_CHAT_STATE = 'SET_CHAT_STATE',
  SET_FORM_DATA = 'SET_FORM_DATA',

  UPDATE_PROPERTY_TO_GET_QUESTIONS_REQUEST = 'UPDATE_PROPERTY_TO_GET_QUESTIONS_REQUEST',
  UPDATE_PROPERTY_TO_GET_QUESTIONS_SUCCESS = 'UPDATE_PROPERTY_TO_GET_QUESTIONS_SUCCESS',
  UPDATE_PROPERTY_TO_GET_QUESTIONS_FAILURE = 'UPDATE_PROPERTY_TO_GET_QUESTIONS_FAILURE',

  SET_GLOBAL_FORM = 'SET_GLOBAL_FORM',

  PROCESS_ANSWERS_REQUEST = 'PROCESS_ANSWERS_REQUEST',
  PROCESS_ANSWERS_SUCCESS = 'PROCESS_ANSWERS_SUCCESS',
  PROCESS_ANSWERS_FAILURE = 'PROCESS_ANSWERS_FAILURE',

  PULL_QUESTION_REQUEST = 'PULL_QUESTION_REQUEST',
  PULL_QUESTION_SUCCESS = 'PULL_QUESTION_SUCCESS',
  PULL_QUESTION_FAILURE = 'PULL_QUESTION_FAILURE',

  GET_QUESTION_OPTIONS_REQUEST = 'GET_QUESTION_OPTIONS_REQUEST',
  GET_QUESTION_OPTIONS_SUCCESS = 'GET_QUESTION_OPTIONS_SUCCESS',
  GET_QUESTION_OPTIONS_FAILURE = 'GET_QUESTION_OPTIONS_FAILURE',

  SET_IS_FORM_DISPLAYED = 'SET_IS_FORM_DISPLAYED',
  SET_IS_CONFIRMATION_FORM_DISPLAYED = 'SET_IS_CONFIRMATION_FORM_DISPLAYED',
  SET_CURRENT_IDX = 'SET_CURRENT_IDX',
  RESET_CONVERSATION_DIFF = 'RESET_CONVERSATION_DIFF',
  SET_IS_FIRST_OPEN = 'SET_IS_FIRST_OPEN',
}

export enum ConversationType {
  AGENT = 'AGENT',
  BOT_QUESTION = 'BOT_QUESTION',
  BOT_CONFIRMATION = 'BOT_CONFIRMATION',
  BOT_GENERIC_REPLY = 'BOT_GENERIC_REPLY',
}

export enum QuestionStructureTypes {
  QUESTION_STRUCTURE_DIRECT = 'QUESTION_STRUCTURE_DIRECT',
  QUESTION_STRUCTURE_BINARY = 'QUESTION_STRUCTURE_BINARY',
  QUESTION_STRUCTURE_OPTION = 'QUESTION_STRUCTURE_OPTION',
}

export enum DirectAnswerTypes {
  ANSWER_TEXT = 'ANSWER_TEXT',
  ANSWER_LONG_TEXT = 'ANSWER_LONG_TEXT',
  ANSWER_NUMERIC = 'ANSWER_NUMERIC',
  ANSWER_MONETARY_VALUE = 'ANSWER_MONETARY_VALUE',
  ANSWER_PHONE_NUMBER = 'ANSWER_PHONE_NUMBER',
  ANSWER_EMAIL = 'ANSWER_EMAIL',
  ANSWER_URL_WEBSITE = 'ANSWER_URL_WEBSITE',
}

export enum OptionalAnswerTypes {
  ANSWER_TIME_OPTIONS = 'ANSWER_TIME_OPTIONS',
  ANSWER_TOUR_TYPE_OPTIONS = 'ANSWER_TOUR_TYPE_OPTIONS',
  ANSWER_AGENT_OPTIONS = 'ANSWER_AGENT_OPTIONS',
  ANSWER_BEDROOM_PLANS_OPTIONS = 'ANSWER_BEDROOM_PLANS_OPTIONS',
  ANSWER_PAYMENT_METHOD_OPTIONS = 'ANSWER_PAYMENT_METHOD_OPTIONS',
  ANSWER_UTILITY_OPTIONS = 'ANSWER_UTILITY_OPTIONS',
  ANSWER_PROPERTIES_OPTIONS = 'ANSWER_PROPERTIES_OPTIONS',
  ANSWER_COMPETITORS_OPTIONS = 'ANSWER_COMPETITORS_OPTIONS',
  ANSWER_SUBMARKETS_OPTIONS = 'ANSWER_SUBMARKETS_OPTIONS',
  ANSWER_ACTIVE_LEASE_TERMS = 'ANSWER_ACTIVE_LEASE_TERMS',
}

export interface PropertyProps {
  id: number;
  name: string;
  external_id: string;
}

export interface CustomerProps {
  id: number;
  customer_name: string;
}

export interface Conversation {
  id?: number;
  question: AgentQuestion | number;
  type: ConversationType;
  answer_state: string;
  agent: number;
  date: string;
  show_greeting: boolean;
  text: string;
}

interface BaseQuestion {
  id: number;
  field: string;
  subject: string;
  custom_field: string;
  question_text: string;
  direct_question_answer_expected_type: string;
  option_conditional_question_answer_expected_type: string;
  placeholder: string;
  max_value: number;
}

export interface AgentQuestion extends BaseQuestion {
  question_text_agent_side: string;
  type: string;
  main_question?: number | AgentQuestion;
  part_questions?: AgentQuestion[];
  settings_page: string;
  general_type: string;
  priority: string;
  structure_type: string;
  invert_current_binary_question_behavior: boolean;
  alternative_yes_answer?: string;
  alternative_no_answer?: string;
  customers: CustomerProps[];
  properties: PropertyProps[];
  link: string;
  user_defined_options: { name: string; value: string }[];
  status: string;
  answer: string;
}

export interface QuestionFormData {
  question: number;
  general_type: string;
  invert_current_binary_question_behavior: boolean;
  alternative_yes_answer: string;
  alternative_no_answer: string;
  properties: PropertyProps[];
  customers: number[];
  questions_structure: {
    order: number;
    field: string;
    type: string;
    structure_type: string;
    label: string;
    placeholder: string;
    max_value: number;
    direct_answer_type: string;
    optional_answer_type: string;
    user_defined_options: { name: string; value: string }[];
  }[];
}

export interface AnswerPayload {
  question: number;
  value: string | string[];
  action: string;
  is_array: boolean;
}

export interface AnswerAPIPayload {
  answers: AnswerPayload[];
  answers_html?: string;
}

export interface FormStructure {
  order: number;
  field: string;
  structureType: string;
  questionText: string;
  placeholder: string;
  max_value: number;
  direct_answer_type: string;
  optional_answer_type: string;
  user_defined_options: { name: string; value: string }[];
  error: boolean;
  answer: string;
  multiSelectionAnswer: string[];
  conditional_answer?: boolean;
  value: string;
  multiSelectionValue: string[];
  invert_current_binary_question_behavior: boolean;
  alternative_yes_answer: string;
  alternative_no_answer: string;
}

export interface QuestionOption {
  properties: { id: number; name: string }[];
  users: { id: number; name: string }[];
  submarkets: { id: number; name: string }[];
  competitors: { id: number, name: string }[];
}

interface MinimizeAgentQuestionWindowAction {
  type: ActionType.MINIMIZE_AGENT_QUESTION_WINDOW;
  isMinimized: boolean;
}

interface CloseAgentQuestionWindowAction {
  type: ActionType.CLOSE_AGENT_QUESTION_WINDOW;
  closedWindow: boolean;
}

interface manageAgentQuestionAction {
  type: ActionType.ENABLE_AGENT_QUESTION;
  agentQuestionEnabled: boolean;
}

interface SetChatStateAction {
  type: ActionType.SET_CHAT_STATE;
  chatState: string;
}

interface SetFormDataAction {
  type: ActionType.SET_FORM_DATA;
  formData: QuestionFormData;
}

interface UpdatePropertyToGetQuestionsAction {
  type: ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_REQUEST | ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_SUCCESS | ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_FAILURE;

  result: {
    data: { id: number; name: string };
  };
  error: {
    response: { status: string };
  };
}

interface SetGlobalFormAction {
  type: ActionType.SET_GLOBAL_FORM;
  form: FormStructure[];
}

interface ProcessAnswersAction {
  type: ActionType.PROCESS_ANSWERS_REQUEST | ActionType.PROCESS_ANSWERS_SUCCESS | ActionType.PROCESS_ANSWERS_FAILURE;
  result: {
    data: {
      count: number; results: Conversation[];
    };
  };
  error: {
    response: { status: string };
  };
}

interface GetConversationsAction {
  type: ActionType.GET_CONVERSATIONS_REQUEST | ActionType.GET_CONVERSATIONS_SUCCESS | ActionType.GET_CONVERSATIONS_FAILURE;
  result: {
    data: {
      count: number; results: Conversation[];
    };
  };
  error: {
    response: { status: string };
  };
}

interface PullQuestionAction {
  type: ActionType.PULL_QUESTION_REQUEST | ActionType.PULL_QUESTION_SUCCESS | ActionType.PULL_QUESTION_FAILURE;
  result: {
    data: {
      count: number; results: Conversation[];
    };
  };
  error: {
    response: { status: string };
  };
}

interface GetQuestionOptionsAction {
  type: ActionType.GET_QUESTION_OPTIONS_REQUEST | ActionType.GET_QUESTION_OPTIONS_SUCCESS | ActionType.GET_QUESTION_OPTIONS_FAILURE;

  result: {
    data: QuestionOption;
  };
  error: {
    response: { status: string };
  };
}

interface SetIsFormDisplayedAction {
  type: ActionType.SET_IS_FORM_DISPLAYED;
  formState: boolean;
}

interface SetIsConfirmationFormDisplayedAction {
  type: ActionType.SET_IS_CONFIRMATION_FORM_DISPLAYED;
  confirmationFormState: boolean;
}

interface SetCurrentIdxAction {
  type: ActionType.SET_CURRENT_IDX;
  idx: number;
}

interface ResetConversationDiffAction {
  type: ActionType.RESET_CONVERSATION_DIFF;
}

interface SetIsFirstOpenAction {
  type: ActionType.SET_IS_FIRST_OPEN;
}

export type Action =
  | PromiseActionType
  | MinimizeAgentQuestionWindowAction
  | CloseAgentQuestionWindowAction
  | manageAgentQuestionAction
  | SetChatStateAction
  | SetFormDataAction
  | UpdatePropertyToGetQuestionsAction
  | SetGlobalFormAction
  | GetCurrentUserAction
  | ProcessAnswersAction
  | PullQuestionAction
  | GetConversationsAction
  | GetQuestionOptionsAction
  | SetIsFormDisplayedAction
  | SetIsConfirmationFormDisplayedAction
  | SetCurrentIdxAction
  | ResetConversationDiffAction
  | SetIsFirstOpenAction;
