import styled from 'styled-components';
import { Card as BaseCard, CardBody as BaseCardBody } from 'reactstrap';
import { Avatar as BaseAvatar } from 'styles/common';

export const Content = styled.div`
  p { margin-bottom: 0; }
  strong { font-weight: 500; }
`;

export const Card = styled(BaseCard)`
  border-color: ${props => props.theme.input.borderColor};
  border-radius: 4px;
`;

export const CardBody = styled(BaseCardBody)`
  padding: 15px;
`;

export const MediaTeam = styled.div`
  display: flex;
  align-items: center;

  h6 { margin-bottom: 2px; }
  span { display: flex; }

  .avatar { margin-right: 10px; }
  .text-muted { margin: 0 5px; }

  & ~ & {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(213,220,244,0.75);
  }
`;

export const Avatar = styled(BaseAvatar)`
  margin-right: 10px;

  &::before {
    display: block;
    background-color: ${props => props.theme.colors.gray400};
  }

  ${props => props.online && `
    &::before {
      background-color: ${props.theme.colors.green};
    }
  `}

  span {
    font-size: 14px;
    display: flex;
    text-transform: uppercase;
  }
`;
