import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  GET_DEPENDENTS_REQUEST = 'GET_DEPENDENTS_REQUEST',
  GET_DEPENDENTS_SUCCESS = 'GET_DEPENDENTS_SUCCESS',
  GET_DEPENDENTS_FAILURE = 'GET_DEPENDENTS_FAILURE',
}

export interface DependentState extends CommonStateType {
  dependents: DependentProps[],
  isLoaded: boolean,
}

export interface DependentProps {
  first_name?: string,
  last_name?: string,
  birth_date?: string,
}

/*
 * Actions
 */

interface GetDependentsAction extends CommonActionResponse {
  type: ActionType.GET_DEPENDENTS_REQUEST
  | ActionType.GET_DEPENDENTS_SUCCESS
  | ActionType.GET_DEPENDENTS_FAILURE;
  result: { data: { results: DependentProps[] } };
}

export type Action =
  PromiseActionType
  | GetDependentsAction
  | ResetLeaseAction;
