import addenda from './addenda/reducers';
import applicant from './applicant/reducers';
import concession from './concession/reducers';
import dependent from './dependent/reducers';
import document from './document/reducers';
import employer from './employer/reducers';
import garage from './garage/reducers';
import gateKey from './gate_key/reducers';
import guarantor from './guarantor/reducers';
import lease from './lease/reducers';
import leaseDocument from './lease_document/reducers';
import otherFeeItem from './other_fee_item/reducers';
import otherIncomes from './other_income/reducers';
import otherRentItem from './other_rent_item/reducers';
import parking from './parking/reducers';
import payment from './payment/reducers';
import pet from './pet/reducers';
import screeningReport from './screening_report/reducers';
import settings from './settings/reducers';
import storage from './storage/reducers';
import unit from './unit/reducers';
import vehicle from './vehicle/reducers';
import notice from './notice/reducers';
import renewalOffer from './renewal_offer/reducers';

export type LeasingStateType = {
  addenda: ReturnType<typeof addenda>,
  applicant: ReturnType<typeof applicant>,
  concession: ReturnType<typeof concession>,
  dependent: ReturnType<typeof dependent>,
  document: ReturnType<typeof document>,
  employer: ReturnType<typeof employer>,
  garage: ReturnType<typeof garage>,
  gateKey: ReturnType<typeof gateKey>,
  guarantor: ReturnType<typeof guarantor>,
  lease: ReturnType<typeof lease>,
  leaseDocument: ReturnType<typeof leaseDocument>,
  otherFeeItem: ReturnType<typeof otherFeeItem>,
  otherIncomes: ReturnType<typeof otherIncomes>,
  otherRentItem: ReturnType<typeof otherRentItem>,
  parking: ReturnType<typeof parking>,
  payment: ReturnType<typeof payment>,
  pet: ReturnType<typeof pet>,
  screeningReport: ReturnType<typeof screeningReport>,
  settings : ReturnType<typeof settings>,
  storage: ReturnType<typeof storage>,
  unit: ReturnType<typeof unit>,
  vehicle: ReturnType<typeof vehicle>,
  notice: ReturnType<typeof notice>,
  renewalOffer: ReturnType<typeof renewalOffer>,
};

export default {
  addenda,
  applicant,
  concession,
  dependent,
  document,
  employer,
  garage,
  gateKey,
  guarantor,
  lease,
  leaseDocument,
  otherFeeItem,
  otherIncomes,
  otherRentItem,
  parking,
  payment,
  pet,
  screeningReport,
  settings,
  storage,
  unit,
  vehicle,
  notice,
  renewalOffer,
};
