import { Call } from '@twilio/voice-sdk';
import { InboundCallCustomParams, OutboundCallCustomParams } from 'dwell/store/voice/action-types';
import { roleTypes } from 'site/constants';

export const NETWORK_WARNING_GROUP = [
  'high-rtt',
  'low-mos',
  'high-jitter',
  'high-packet-loss',
  'high-packets-lost-fraction',
  'low-bytes-received',
  'low-bytes-sent',
  'ice-connectivity-lost',
];

export const AUDIO_WARNING_GROUP = [
  'constant-audio-input-level',
  'constant-audio-output-level',
  'ringtone-devices-set-failed',
];

export const getOutboundCallParams = (call: Call): OutboundCallCustomParams => {
  const callParams = call && call.customParameters;
  return {
    to: callParams && callParams.get('To') ? callParams.get('To') : '',
    propertyId: callParams && callParams.get('PropertyId') ? parseInt(callParams.get('PropertyId'), 10) : null,
    leadId: callParams && callParams.get('LeadId') ? parseInt(callParams.get('LeadId'), 10) : null,
  };
};

export const getCallParams = (call: Call): InboundCallCustomParams => {
  const callParams = call && call.customParameters;
  return {
    prospectName: callParams ? callParams.get('caller_name') : '',
    prospectType: callParams ? callParams.get('caller_type') : '',
    propertyName: callParams ? callParams.get('property_name') : '',
    propertyId: callParams ? parseInt(callParams.get('property_id'), 10) : null,
    propertyExternalId: callParams ? callParams.get('property_external_id') : '',
    leadId: callParams && callParams.get('lead_id') ? parseInt(callParams.get('lead_id'), 10) : null,
    leaseId: callParams && callParams.get('lease_id') ? parseInt(callParams.get('lease_id'), 10) : null,
    leasingUserId: callParams && callParams.get('leasing_user_id') ? parseInt(callParams.get('leasing_user_id'), 10) : null,
    applicationId: callParams && callParams.get('application_id') ? parseInt(callParams.get('application_id'), 10) : null,
    sourceName: callParams ? callParams.get('source_name') : '',
    topic: callParams ? callParams.get('topic') : '',
    originalCallSid: callParams ? callParams.get('original_call_sid') : '',
    areConferenceFeaturesEnabled: callParams ? callParams.get('enable_conference_features') === 'true' : false,
  };
};

export const dialFormat = (phone: string): string => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  return `+1${cleaned}`;
};

export const formatPhoneNumber = (phone: string): string => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{1,5})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
};

export const getCallResult = (direction: string, status: string, duration: number, countEvents = 0): string => {
  if (status === 'missed-w-voicemail') {
    return 'Missed w/ Voicemail';
  }
  if (['no-answer', 'missed', 'busy'].includes(status)) {
    if (direction === 'INBOUND' || (direction === 'OUTBOUND' && countEvents >= 1)) {
      return 'Missed';
    }
    return 'No Answer';
  }
  if (!duration || duration <= 20) return 'Missed';
  return 'Answered';
};

export const isOnSiteAgent = (role: string): boolean => [roleTypes.GENERIC_ADMIN, roleTypes.PROPERTY_ADMIN].includes(role);
export const isIonAgent = (role: string, browser_phone?: string): boolean => {
  if (role === roleTypes.VIRTUAL_ADMIN) return true;
  if (role === roleTypes.PROPERTY_ADMIN && browser_phone) return true;
  return false;
};
export const userCanUseCallsModule = (role: string): boolean => [roleTypes.PROPERTY_ADMIN, roleTypes.GENERIC_ADMIN, roleTypes.VIRTUAL_ADMIN].includes(role);
