import React, { FC, useMemo } from 'react';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { typeMapping } from 'dwell/views/chat/agent_question/choices';

interface MultiObjectSelectorProps {
  type: string;
  answers: string[];
  onChange: (selectedItems: { label: string; value: string }[]) => void;
}

const CustomSelect = styled(Select)`
  width: 170px !important;
  display: inline-block;
  flex: 1;
  margin-right: 7px;
`;

const MultiObjectSelector: FC<MultiObjectSelectorProps> = ({ answers, type, onChange }) => {
  const { properties } = useSelector(state => state.agentQuestion.questionOptions);
  const choices = useMemo(() => {
    if (!type) return [];
    if (['ANSWER_PROPERTIES_OPTIONS', 'ANSWER_COMPETITORS_OPTIONS'].includes(type)) {
      return properties.map(p => ({ label: p.name, value: p.id.toString() }));
    }
    return typeMapping[type].map(({ name: label, value }) => ({ label, value }));
  }, [type]);

  const defaultValue = isEmpty(answers) ? null : choices.filter(option => answers.includes(option.value));

  return (
    <CustomSelect
      value={defaultValue}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      options={choices}
      placeholder="Select options"
      onChange={selectedItems => onChange(selectedItems)}
      className="basic-multi-select"
      classNamePrefix="select"
      isClearable={false}
    />
  );
};

export default MultiObjectSelector;
