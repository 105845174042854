import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { ExploreMarket } from '../explore_markets/action-types';
import { Action, ActionType, AvailableUnit, PropertyDetail, PropertiesCompetitor, PropertyAlert, Properties } from './action-types';

export interface PropertiesState extends CommonStateType {
  isAvailableUnitsLoaded: boolean;
  isPropertiesDetailLoaded: boolean;
  isPropertiesCompetitorsLoaded: boolean;
  isCompetitorListLoaded: boolean;
  isAlertLoaded: boolean;
  countAvailableUnit: number;
  countCompetitors: number;
  availableUnits: AvailableUnit;
  propertiesDetail: PropertyDetail;
  propertiesCompetitors: PropertiesCompetitor;
  competitorList: ExploreMarket;
  alertSubscriptions: PropertyAlert;
  isSessionLoaded: boolean;
  sessionInfo: AvailableUnit;
  properties: Properties[];
}

const initialState: PropertiesState = {
  isSubmitting: false,
  errorMessage: null,
  isAvailableUnitsLoaded: false,
  isPropertiesDetailLoaded: false,
  isPropertiesCompetitorsLoaded: false,
  isCompetitorListLoaded: false,
  isAlertLoaded: false,
  countAvailableUnit: 0,
  countCompetitors: 0,
  availableUnits: null,
  propertiesDetail: null,
  propertiesCompetitors: null,
  competitorList: null,
  alertSubscriptions: null,
  isSessionLoaded: false,
  sessionInfo: null,
  properties: [],
};

export const selectProperties = (state: { properties: PropertiesState }): Properties[] => state.properties.properties;

export default produce((state: PropertiesState = initialState, action: Action): PropertiesState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_AVAILABLE_UNITS_REQUEST:
      state.isAvailableUnitsLoaded = false;
      break;

    case ActionType.GET_AVAILABLE_UNITS_SUCCESS:
      state.availableUnits = action.result.data.results;
      state.isAvailableUnitsLoaded = true;
      state.countAvailableUnit = action.result.data.count;
      break;

    case ActionType.GET_AVAILABLE_UNITS_FAILURE:
      state.isAvailableUnitsLoaded = false;
      break;

    case ActionType.GET_PROPERTIES_SUCCESS:
      state.properties = action.result.data.results;
      break;

    case ActionType.GET_PROPERTIES_DETAIL_REQUEST:
      state.isPropertiesDetailLoaded = false;
      break;

    case ActionType.GET_PROPERTIES_DETAIL_SUCCESS:
      state.propertiesDetail = action.result.data;
      state.isPropertiesDetailLoaded = true;
      break;

    case ActionType.GET_PROPERTIES_DETAIL_FAILURE:
      state.isPropertiesDetailLoaded = false;
      break;

    case ActionType.GET_PROPERTIES_COMPETITORS_REQUEST:
      state.isPropertiesCompetitorsLoaded = false;
      break;

    case ActionType.GET_PROPERTIES_COMPETITORS_SUCCESS:
      state.propertiesCompetitors = action.result.data.results;
      state.countCompetitors = action.result.data.count;
      state.isPropertiesCompetitorsLoaded = true;
      break;

    case ActionType.GET_PROPERTIES_COMPETITORS_FAILURE:
      state.isPropertiesCompetitorsLoaded = false;
      break;

    case ActionType.ADD_MARKET_ENVIRONMENT_REQUEST:
      state.isSubmitting = true;
      break;

    case ActionType.ADD_MARKET_ENVIRONMENT_SUCCESS:
      state.isSubmitting = false;
      break;

    case ActionType.ADD_MARKET_ENVIRONMENT_FAILURE:
      state.isSubmitting = false;
      break;

    case ActionType.GET_COMPETITOR_SET_REQUEST:
      state.isCompetitorListLoaded = false;
      break;

    case ActionType.GET_COMPETITOR_SET_SUCCESS:
      state.competitorList = action.result.data.results;
      state.isCompetitorListLoaded = true;
      break;

    case ActionType.GET_COMPETITOR_SET_FAILURE:
      state.isCompetitorListLoaded = false;
      break;

    case ActionType.GET_PROPERTIES_ALERT_SUBSCRIPTIONS_REQUEST:
      state.isAlertLoaded = false;
      break;

    case ActionType.GET_PROPERTIES_ALERT_SUBSCRIPTIONS_SUCCESS:
      state.alertSubscriptions = action.result.data;
      state.isAlertLoaded = true;
      break;

    case ActionType.GET_PROPERTIES_ALERT_SUBSCRIPTIONS_FAILURE:
      state.isAlertLoaded = false;
      break;

    case ActionType.GET_SESSION_DETAIL_BY_ID_REQUEST:
      state.isSessionLoaded = false;
      break;

    case ActionType.GET_SESSION_DETAIL_BY_ID_SUCCESS:
      state.sessionInfo = action.result.data;
      state.isSessionLoaded = true;
      break;

    case ActionType.GET_SESSION_DETAIL_BY_ID_FAILURE:
      state.isSessionLoaded = false;
      break;

    case ActionType.SET_AVAILABLE_LOADED:
      state.isAvailableUnitsLoaded = false;
      break;

    case ActionType.SET_COMPETITORS_LOADED:
      state.isPropertiesCompetitorsLoaded = false;
      break;
  }

  return state;
});
