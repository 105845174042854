import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import actions from 'dwell/actions/index';

import { PropertyMenuLinkButton } from 'containers/default_header/styles';

const PropertyItemAll: FC = () => {
  const dispatch = useDispatch();
  const { setShowSelector } = actions.propertiesSelector;

  const clickHandler = () => {
    document.body.classList.add('scroll_locked');
    dispatch(setShowSelector(true));
  };

  return (
    <PropertyMenuLinkButton onClick={clickHandler}>
      <span>All Properties</span>
      <i className="ri-menu-unfold-fill" />
    </PropertyMenuLinkButton>
  );
};

export default PropertyItemAll;
