import styled from 'styled-components';

export const BadgeDot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  display: inline-block;
`;

export const LeaseName = styled.h6`
    margin-bottom: 3px;
    font-weight: 600;
    line-height: 21px;
`;

export const PMSSyncStatus = styled.div`
    display: flex;
    align-items: center;
    color: #98a4c1;
    ${props => props.status === 'SUCCESS' && `color: ${props.theme.colors.green};`}
    ${props => props.status === 'FAILURE' && `color: ${props.theme.colors.red};`}

    span {
        display: flex;
        align-items: center;
        i {
            font-size: 14px;
            margin-right: 2px;
            font-family: 'remixicon' !important;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
        }
  }
`;
