import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import middleware from 'store/middleware';
import competeReducers, { CompeteStateType } from 'compete/store/reducers';
import demoReducers, { DemoStateType } from 'main_page/reducers';
import dwellReducers, { DwellStateType } from 'dwell/reducers';
import leasingReducers, { LeasingStateType } from 'leasing/store/reducers';
import siteReducers, { SiteStateType } from 'site/store/reducers';
import residentReducers, { ResidentStateType } from 'resident/store/reducers';
import applicationReducers, { ApplicationStateType } from 'application/store/reducers';
import corpSiteReducers, { CorporateSiteStateType } from 'corporate/store/reducers';

export type RootState =
  CompeteStateType
  & DemoStateType
  & DwellStateType
  & LeasingStateType
  & SiteStateType
  & ResidentStateType
  & ApplicationStateType
  & CorporateSiteStateType;

const reducers = combineReducers<RootState>({
  ...dwellReducers,
  ...siteReducers,
  ...competeReducers,
  ...demoReducers,
  ...leasingReducers,
  ...residentReducers,
  ...applicationReducers,
  ...corpSiteReducers,
});
const store = createStore<RootState>(reducers, composeWithDevTools(applyMiddleware(thunk, middleware())));
export default store;
