import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { GetLeadByIdAction } from 'dwell/store/lead/action-types';
import { ArchiveMessagesAction } from 'dwell/store/nylas/action-types';
import { CustomBlob } from 'src/interfaces';
import { ApplicationDetailsProps } from 'application/store/application/action-types';

export enum ActionType {
  GET_EMAIL_MESSAGES_REQUEST = 'GET_EMAIL_MESSAGES_REQUEST',
  GET_EMAIL_MESSAGES_SUCCESS = 'GET_EMAIL_MESSAGES_SUCCESS',
  GET_EMAIL_MESSAGES_FAILURE = 'GET_EMAIL_MESSAGES_FAILURE',
  GET_EMAIL_MESSAGE_BY_ID_REQUEST = 'GET_EMAIL_MESSAGE_BY_ID_REQUEST',
  GET_EMAIL_MESSAGE_BY_ID_SUCCESS = 'GET_EMAIL_MESSAGE_BY_ID_SUCCESS',
  GET_EMAIL_MESSAGE_BY_ID_FAILURE = 'GET_EMAIL_MESSAGE_BY_ID_FAILURE',
  MARK_EMAIL_MESSAGE_AS_READ_REQUEST = 'MARK_EMAIL_MESSAGE_AS_READ_REQUEST',
  MARK_EMAIL_MESSAGE_AS_READ_SUCCESS = 'MARK_EMAIL_MESSAGE_AS_READ_SUCCESS',
  MARK_EMAIL_MESSAGE_AS_READ_FAILURE = 'MARK_EMAIL_MESSAGE_AS_READ_FAILURE',
  UPDATE_EMAIL_MESSAGE_REQUEST = 'UPDATE_EMAIL_MESSAGE_REQUEST',
  UPDATE_EMAIL_MESSAGE_SUCCESS = 'UPDATE_EMAIL_MESSAGE_SUCCESS',
  UPDATE_EMAIL_MESSAGE_FAILURE = 'UPDATE_EMAIL_MESSAGE_FAILURE',
  ARCHIVE_EMAIL_MESSAGE_REQUEST = 'ARCHIVE_EMAIL_MESSAGE_REQUEST',
  ARCHIVE_EMAIL_MESSAGE_SUCCESS = 'ARCHIVE_EMAIL_MESSAGE_SUCCESS',
  ARCHIVE_EMAIL_MESSAGE_FAILURE = 'ARCHIVE_EMAIL_MESSAGE_FAILURE',
  GET_LEAD_CONVERSATIONS_REQUEST = 'GET_LEAD_CONVERSATIONS_REQUEST',
  GET_LEAD_CONVERSATIONS_SUCCESS = 'GET_LEAD_CONVERSATIONS_SUCCESS',
  GET_LEAD_CONVERSATIONS_FAILURE = 'GET_LEAD_CONVERSATIONS_FAILURE',
  SEND_BULK_EMAIL_REQUEST = 'SEND_BULK_EMAIL_REQUEST',
  SEND_BULK_EMAIL_SUCCESS = 'SEND_BULK_EMAIL_SUCCESS',
  SEND_BULK_EMAIL_FAILURE = 'SEND_BULK_EMAIL_FAILURE',
  SET_EMAIL_LEAD_ID = 'SET_EMAIL_LEAD_ID',
  SET_EMAIL_BODY = 'SET_EMAIL_BODY',
  SET_EMAIL_SUBJECT = 'SET_EMAIL_SUBJECT',
  SET_SELECTED_EMAIL_TEMPLATE_ID = 'SET_SELECTED_EMAIL_TEMPLATE_ID',
  SET_EMAIL_SUBJECT_VARIABLES = 'SET_EMAIL_SUBJECT_VARIABLES',
  SET_FILES = 'SET_FILES',
  SET_IS_SHOWING_CC = 'SET_IS_SHOWING_CC',
  SET_EMAIL_CC = 'SET_EMAIL_CC',
  CLEAR_EMAIL_CONTENT = 'CLEAR_EMAIL_CONTENT',
}

export interface EmailMessageProps {
  id?: number;
  receiver_name?: string;
  receiver_email?: string;
  sender_name?: string;
  sender_email?: string;
  snippet?: string;
  subject?: string;
  is_unread?: boolean;
  lead?: number;
  date?: string;
  body?: string;
  subjectSnippet?: { subject?: string; snippet?: string };
  attachments?: string[];
  cc?: string;
  popoutId?: string;
  type?: string;
  lease?: number;
  leasing_user?: number;
  property?: number;
  lease_info?: ApplicationDetailsProps,
}

export interface EmailMessageState {
  isSubmitting: boolean;
  errorMessage: string;
  messages: EmailMessageProps[];
  message: EmailMessageProps;
  messagesCount: number;
  isLoaded: boolean;
  isFollowupMessagesLoaded: boolean;
  conversations: EmailMessageProps[];
  leadId: string;
  subject: string;
  cc: string;
  selectedTemplateId: string;
  subjectVariables: string[];
  files: string[];
  isShowingCc: boolean;
  body: string;
}

interface GetMessagesAction {
  type: ActionType.GET_EMAIL_MESSAGES_REQUEST | ActionType.GET_EMAIL_MESSAGES_SUCCESS | ActionType.GET_EMAIL_MESSAGES_FAILURE;

  result: {
    data: { results: EmailMessageProps[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetMessageByIdAction {
  type: ActionType.GET_EMAIL_MESSAGE_BY_ID_REQUEST | ActionType.GET_EMAIL_MESSAGE_BY_ID_SUCCESS | ActionType.GET_EMAIL_MESSAGE_BY_ID_FAILURE;

  result: {
    data: EmailMessageProps;
  };
  error: {
    response: { status: string };
  };
}

interface MarkEmailMessageAsReadAction {
  type: ActionType.MARK_EMAIL_MESSAGE_AS_READ_REQUEST | ActionType.MARK_EMAIL_MESSAGE_AS_READ_SUCCESS | ActionType.MARK_EMAIL_MESSAGE_AS_READ_FAILURE;

  result: {
    data: EmailMessageProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateMessageByIdAction {
  type: ActionType.UPDATE_EMAIL_MESSAGE_REQUEST | ActionType.UPDATE_EMAIL_MESSAGE_SUCCESS | ActionType.UPDATE_EMAIL_MESSAGE_FAILURE;

  result: {
    data: EmailMessageProps;
  };
  error: {
    response: { status: string };
  };
}

interface ArchiveMessageAction {
  type: ActionType.ARCHIVE_EMAIL_MESSAGE_REQUEST | ActionType.ARCHIVE_EMAIL_MESSAGE_SUCCESS | ActionType.ARCHIVE_EMAIL_MESSAGE_FAILURE;

  result: {
    data: EmailMessageProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetEmailConversationsAction {
  type: ActionType.GET_LEAD_CONVERSATIONS_REQUEST | ActionType.GET_LEAD_CONVERSATIONS_SUCCESS | ActionType.GET_LEAD_CONVERSATIONS_FAILURE;

  result: {
    data: { results: EmailMessageProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface SendBulkEmailAction {
  type: ActionType.SEND_BULK_EMAIL_REQUEST | ActionType.SEND_BULK_EMAIL_SUCCESS | ActionType.SEND_BULK_EMAIL_FAILURE;

  error: {
    response: { status: string };
  };
}

interface SetEmailLeadIdAction {
  type: ActionType.SET_EMAIL_LEAD_ID;
  leadId: string;
}

interface SetBodyAction {
  type: ActionType.SET_EMAIL_BODY;
  body: string;
}

interface SetSubjectAction {
  type: ActionType.SET_EMAIL_SUBJECT;
  subject: string;
}

interface SetSelectedTemplateIdAction {
  type: ActionType.SET_SELECTED_EMAIL_TEMPLATE_ID;
  selectedTemplateId: string;
}

interface SetSubjectVariablesAction {
  type: ActionType.SET_EMAIL_SUBJECT_VARIABLES;
  subjectVariables: string[];
}

interface SetFilesAction {
  type: ActionType.SET_FILES;
  files: CustomBlob[] | string[];
}

interface SetIsShowingCcAction {
  type: ActionType.SET_IS_SHOWING_CC;
  isShowingCc: boolean;
}

interface SetCcAction {
  type: ActionType.SET_EMAIL_CC;
  cc: string;
}

interface ClearEmailContentAction {
  type: ActionType.CLEAR_EMAIL_CONTENT;
}

export type Action =
  | PromiseActionType
  | GetMessagesAction
  | GetMessageByIdAction
  | MarkEmailMessageAsReadAction
  | UpdateMessageByIdAction
  | ArchiveMessageAction
  | GetEmailConversationsAction
  | SendBulkEmailAction
  | SetEmailLeadIdAction
  | SetBodyAction
  | SetSubjectAction
  | SetSelectedTemplateIdAction
  | SetSubjectVariablesAction
  | SetFilesAction
  | SetIsShowingCcAction
  | SetCcAction
  | ClearEmailContentAction
  | GetLeadByIdAction
  | PusherAction
  | ArchiveMessagesAction;
