import React, { FC } from 'react';
import moment from 'moment';
import { LeaseUnitProps } from 'leasing/store/types';
import { formatPriceValue } from 'leasing/views/lease/utils';
import { Unit, UnitImg, UnitImgLabel, UnitBody } from './styles';

interface UnitBlockProps {
  unit: LeaseUnitProps,
  selected?: boolean,
  handleShowMap: (map: string) => void,
}

const UnitBlock: FC<UnitBlockProps> = ({ unit, selected = false, handleShowMap }) => (
  <Unit className={selected ? 'selected' : ''}>
    <UnitImg>
      <img src={unit.image} className="img-fluid" alt="unit-map" onClick={() => handleShowMap(unit.image)} />
      <UnitImgLabel>{unit.unit}</UnitImgLabel>
    </UnitImg>
    <UnitBody>
      <h6>#{unit.unit}</h6>
      <p>Bedrooms: <strong>{unit.bed_rooms}</strong></p>
      <p>Bathrooms: <strong>{unit.bath_rooms}</strong></p>
      {unit.status === 'AVAILABLE' && <p>Available: <strong>Now</strong></p>}
      {unit.status === 'NOT_AVAILABLE' && <p>Available: <strong>{unit.available_date ? moment(unit.available_date).format('LL') : '' }</strong></p>}
      <p>Rent: <strong>{formatPriceValue(unit.market_rent)}</strong></p>
      <p>Floor plan: <strong>{unit.floor_plan}</strong></p>
      <p><strong>{unit.property}</strong></p>
    </UnitBody>
  </Unit>
);

export default UnitBlock;
