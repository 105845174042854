import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { noop } from 'lodash';
import { IS_BEEN_LOGGED, RECENT_PROPERTY_HYPHENS, paths, IS_RECENTLY_LOGGED, USER_TO_LOGIN } from 'dwell/constants';
import { platformTypes } from 'site/constants';
import actions from 'dwell/actions';
import userManager from 'dwell/store/authentication/user_manager';
import { SSOCheckAction as CheckSSOActionResponse } from 'dwell/store/authentication/action-types';
import { SSOStatus } from 'src/interfaces';
import {
  LoginWrapper,
  LoginBox,
  LoginLogo,
  LoginTitle,
  LoginText,
  FormGroupLogin,
  FormGroupLabel,
  FormControl,
  LinkForgot,
} from './styles';

interface LoginResponseProps {
  last_login_property: { name: string, platforms: string[] },
  new_pipeline_enabled_to_all: boolean,
  cb_pipeline: boolean,
  id: number,
  workflow: string,
}

const Login: FC = () => {
  const { push } = useHistory();

  const dispatch = useDispatch();
  const isSubmitting: boolean = useSelector(state => state.authentication.isSubmitting);
  const isFormInvalid: boolean = useSelector(state => state.authentication.isFormInvalid);
  const { login, resetLoginState, checkSSO } = actions.authentication;
  const errorMessage: string = useSelector(state => state.authentication.errorMessage);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [focused, setFocused] = useState({ email: false, password: false });
  const [ssoStatus, setSSOStatus] = useState<SSOStatus | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const resetLoginData = () => {
    setEmail('');
    setPassword('');
    setIsLoading(false);
    setSSOStatus(null);
    dispatch(resetLoginState(true));
  };

  useEffect(() => {
    if (errorMessage) {
      dispatch(resetLoginState());
    }
  }, [errorMessage]);

  const handleSSOLogin = async (ssoProvider: string) => {
    sessionStorage.setItem(USER_TO_LOGIN, email);
    await userManager.signinRedirect({
      login_hint: email,
      extraQueryParams: {
        kc_idp_hint: ssoProvider,
        prompt: 'login',
      },
    });
  };

  const handleCheckSSO = async (event) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    dispatch(checkSSO(email))
      .then((response: CheckSSOActionResponse) => {
        if (response.result && response.result.data) {
          const sso: SSOStatus = response.result.data;
          setSSOStatus(sso);
          if (sso.isSSO) handleSSOLogin(sso.ssoProvider);
        }
      })
      .catch(() => {
        noop();
      })
      .finally(() => setIsLoading(false));
  };

  const handleSSOLoginEvent = (event) => {
    event.preventDefault();
    if (ssoStatus && ssoStatus.ssoProvider) {
      handleSSOLogin(ssoStatus.ssoProvider);
    }
  };

  const handleLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }
    localStorage.removeItem('site-id');

    dispatch(login({ email: email.trim(), password: password.trim(), last_login_property: localStorage.getItem(RECENT_PROPERTY_HYPHENS) }))
      .then((response) => {
        if (response.result) {
          const { result: { data } } = response;
          const { last_login_property: { name, platforms }, new_pipeline_enabled_to_all: newPipelineEnabled, cb_pipeline: cbPipeline, workflow } = data as LoginResponseProps;

          localStorage.setItem(IS_BEEN_LOGGED, 'true');
          localStorage.setItem(IS_RECENTLY_LOGGED, 'true');
          localStorage.setItem(RECENT_PROPERTY_HYPHENS, name);

          if (platformTypes.isSiteOnly(platforms)) {
            push(`/${name}/users`);
          } else if (workflow === 'CATEGORY') {
            push(paths.client.TASKS);
          } else if (newPipelineEnabled) {
            push(paths.client.PIPELINE);
          } else if (cbPipeline) {
            push(`/${name}/credit-builder/leases`);
          } else {
            push(paths.client.LEADS.VIEW);
          }
        }
      });
  };

  const handleInputChange = ({ target: { id, value } }) => {
    setFocused({ ...focused, [id]: true });

    if (isFormInvalid) {
      dispatch(resetLoginState());
    }

    if (id === 'email' && !value && isFormInvalid) {
      dispatch(resetLoginState(true));
    }

    if (id === 'password') {
      setPassword(value);
    } else if (id === 'email') {
      setEmail(value);
    }
  };

  const handleFocusChange = ({ target: { id, value } }, isFocused) => {
    setFocused({ ...focused, [id]: isFocused || value });
  };

  const getLogInText = () => {
    if (isFormInvalid) {
      return !ssoStatus || (ssoStatus && ssoStatus.isSSO) ? 'Email is invalid' : 'Email or password are invalid';
    }
    const isLoggedIn = localStorage.getItem(IS_BEEN_LOGGED);
    const welcomeBackText = isLoggedIn ? 'Welcome back! ' : '';
    return `${welcomeBackText}Please sign in to continue`;
  };

  const getSubmitHandler = () => {
    if (!ssoStatus) {
      return handleCheckSSO;
    }
    if (ssoStatus.isSSO) {
      return handleSSOLoginEvent;
    }
    return handleLogin;
  };

  const enterKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      getSubmitHandler()(event);
    }
  };

  const getLoginButtonText = () => {
    if (ssoStatus && !ssoStatus.isSSO) {
      return 'Login';
    }
    return 'Continue';
  };

  return (
    <LoginWrapper>
      <LoginBox>
        <LoginLogo><span /></LoginLogo>
        <hr className="op-0" />
        <LoginTitle>Sign in</LoginTitle>
        <LoginText className={isFormInvalid ? 'error' : ''}>{getLogInText()}</LoginText>
        <hr className="op-0 mg-y-10" />
        <form onSubmit={getSubmitHandler()}>
          <FormGroupLogin className="form-group">
            <FormControl
              type="text"
              id="email"
              className={[focused.email ? 'focused' : 'form-group',
                isFormInvalid ? 'is-invalid' : '',
              ].join(' ')}
              value={email}
              onChange={handleInputChange}
              onBlur={e => handleFocusChange(e, false)}
              onFocus={e => handleFocusChange(e, true)}
            />
            <FormGroupLabel>Enter email address</FormGroupLabel>
            <i className="ri-account-circle-line" />
          </FormGroupLogin>

          {ssoStatus && !ssoStatus.isSSO && (
            <>
              <FormGroupLogin className="form-group">
                <FormControl
                  type="password"
                  id="password"
                  className={[
                    focused.password ? 'focused' : 'form-group',
                    isFormInvalid ? 'is-invalid' : '',
                  ].join(' ')}
                  value={password}
                  onChange={handleInputChange}
                  onKeyPress={enterKeyPress}
                  onBlur={e => handleFocusChange(e, false)}
                  onFocus={e => handleFocusChange(e, true)}
                />
                <FormGroupLabel>Enter password</FormGroupLabel>
                <i className="ri-door-lock-line" />
              </FormGroupLogin>
              <div className="text-right">
                <LinkForgot onClick={() => push(paths.client.SEND_PASSWORD_RESET_EMAIL)}>Forgot password?</LinkForgot>
              </div>
            </>
          )}
          <hr className="op-0" />
          <button className="btn btn-primary btn-block btn-login" disabled={isLoading || isSubmitting || email.length === 0}>
            {getLoginButtonText()}
          </button>
          {ssoStatus && !ssoStatus.isSSO && (
            <button
              type="button"
              className="btn btn-secondary btn-block btn-back"
              onClick={() => resetLoginData()}
            >
              Back
            </button>
          )}
        </form>
      </LoginBox>
    </LoginWrapper>
  );
};

export default Login;
