import React, { FC, useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import {
  Clipboard, ClipboardContainer,
} from './styles';

interface Props {
  index: number;
  onClick: (event: MouseEvent) => void;
}

const ClipboardButton: FC<Props> = ({ index, onClick }) => {
  const [copyClicked, clickCopy] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const handleClick = (e) => {
    clickCopy(true);
    setTooltipOpen(false);
    onClick(e);
  };
  const handleMouseLeave = () => {
    setTooltipOpen(false);
    clickCopy(false);
  };

  useEffect(() => {
    if (copyClicked && !tooltipOpen) {
      setTooltipOpen(true);
    }
  }, [copyClicked, tooltipOpen]);

  return (
    <ClipboardContainer>
      <Clipboard
        id={`copy-${index}`}
        className="link-icon"
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        copyClicked={copyClicked}
      >
        {copyClicked ? <i className="ri-check-double-line" /> : <i className="ri-file-copy-fill" />}
      </Clipboard>
      <Tooltip trigger="hover" placement="top" target={`copy-${index}`} isOpen={tooltipOpen} toggle={toggle}>
        {tooltipOpen && (copyClicked ? 'Copied!' : 'Copy response')}
      </Tooltip>
    </ClipboardContainer>
  );
};

export default ClipboardButton;
