import styled, { css } from 'styled-components';
import { hexToRgb } from 'dwell/constants';
import { shadowSharp } from 'src/styles/mixins';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { blinker, blinkerText } from 'dwell/views/chat/single_chat/contact/styles';
import { MentionsInput } from 'react-mentions';
import { ButtonLikeAnchor, Avatar } from 'styles/common';
import Loader from 'dwell/components/loader';

export const ChatItemContainer = styled.div`
    width: 280px;
    background-color: #fff;
    border-bottom-width: 0;
    box-shadow: 0 -5px 25px rgba(${props => hexToRgb(props.theme.colors.colorbg03)}, .4);
    border-top-right-radius: ${props => props.theme.borders.radiusmd};
    margin-right: 5px;
    position: relative;
    border: 1px solid rgba(${props => hexToRgb(props.theme.colors.colorbg03)}, 0.5);

    &::before {
        content: '${props => (props.isSMS ? 'SMS' : 'CHAT')}';
        position: absolute;
        top: -20px;
        left: -1px;
        background-color: #fff;
        color: ${props => (props.isSMS ? props.theme.colors.teal : props.theme.colors.colorui03)};
        border: 1px solid rgba(${props => hexToRgb(props.theme.colors.colorbg03)}, .5);
        border-bottom-width: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-size: 10px;
        font-weight: 600;
        font-family: ${props => props.theme.fonts.default};
        z-index: 10;
        padding: 2px 8px;
    }

    &.minimize {
        height: auto;

        .qc-item-body,
        .qc-item-footer {
            display: none;
        }
    }

    a {
        outline: none;
    }

    ${props =>
    props.isBlinking &&
      css`
        &:before {
          animation: ${blinker} 7s infinite;
          i {
            animation: ${blinkerText} 7s infinite;
          }
        }
      `}
`;

export const ChatItemV2 = styled(ChatItemContainer)`
  width: 278px;

  &::before {
    color: #21c6b7;
    text-transform: uppercase;
  }
  & .qc-item-header .avatar::before {
    display: none;
  }
  & .qc-item-header .dropdown {
    display: block;
  }
  & .qc-item-header .media-body {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .card-chat-ai {
    border-radius: 4px;
    box-shadow: 0px 4px 2px rgba(11, 33, 81, 0.02);
    bottom: 0;
    left: 10px;
    right: 10px;
  }
  & .card-chat-ai .card-body {
    padding: 10px;
  }
  & .card-chat-ai .card-body + .card-header {
    border-top: 1px solid #d5dcf4;
  }
  & .chat-ai-item {
    border: 1px solid #d5dcf4;
    border-radius: 4px;
    padding: 5px 8px;
  }
  & .chat-ai-item + .chat-ai-item {
    margin-top: 8px;
  }
  & .chat-ai-item::before {
    display: none;
  }
  & .chat-ai-item span {
    background-color: #0168fa;
    margin-right: 8px;
  }
`;

export const AgentQuestionItem = styled(ChatItemV2)`
  &::before {
    content: 'Bot';
    color: #657697;
  }
`;

export const ChatItemHeader = styled.div`
    height: ${props => (props.overflow ? '100px' : '70px')};
    padding: 0 10px;
    border-bottom: 1px solid ${props => props.theme.colors.colorbd02};
    ${props => shadowSharp({ color: hexToRgb(props.theme.colors.colorbd01) })};
    cursor: pointer;
    // border: 1px solid rgba(${props => hexToRgb(props.theme.colors.colorbg03)}, .5);
    border-top-right-radius: ${props => props.theme.borders.radiusmd};

    .media {
        height: 100%;
        align-items: center;
    }

    .avatar {
        width: ${props => props.theme.templates.heightxs};
        height: ${props => props.theme.templates.heightxs};
        background-color: ${props => props.theme.colors.colorbg03};

        i {
            font-style: normal;
            font-size: ${props => props.theme.fontSizes.sm};
            font-family: ${props => props.theme.fonts.numeric};
        }

        &::before {
            display: block;
        }
        &.online::before {
            background-color: ${props => props.theme.colors.green};
        }
    }

    .media-body {
        padding-left: 10px;
        word-break: break-word;

        h6 {
          line-height: 1.3;
          font-weight: ${props => props.theme.fontWeights.semibold};
        }

        span {
            display: block;
            font-size: 12px;
            color: ${props => props.theme.colors.colortx03};
        }

        i {
            font-size: 10px;
            color: ${props => props.theme.colors.colortx03};
            margin-left: 3px;
        }
    }

    .dropdown { margin-right: 2px; }

    .dropdown-menu {
        margin-top: 5px;
        border-color: rgba(${props => hexToRgb(props.theme.colors.colortx02)}, .16);
        border-radius: 5px;
        padding: 5px;
        @include shadow-dreamy(${props => props.theme.colors.colortx03});
    }

    .dropdown-item {
        padding: 6px 12px;
        font-size: ${props => props.theme.fontSizes.sm} !important;
        color: ${props => props.theme.colors.colortx02} !important;
        border-radius: 4px !important;
        display: flex;
        align-items: center;

        &:hover, &:focus {
            background-color: ${props => props.theme.colors.colorbd01};
            color: ${props => props.theme.colors.colortx02};
        }

        i {
            font-size: 16px;
            line-height: 1;
            margin-right: 5px;
            opacity: .75;
            color: ${props => props.theme.colors.colortx02} !important;
        }
    }

    ${props =>
    props.isBlinking &&
      css`
        animation: ${blinker} 7s infinite;

        h6,
        span,
        small,
        p {
          animation: ${blinkerText} 7s infinite;
        }
      `}
`;

export const ChatItem = styled.li`
  padding: 10px;
  display: flex;
  margin-right: 5%;

  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
      margin-right: 0;
      margin-left: 5%;

      .message {
        padding-left: 0;
        padding-right: 8px;
        text-align: right;

        p {
          background-color: ${props.isSMS ? props.theme.colors.teal : props.theme.colors.colorui01};
          color: #fff;
          text-align: left;
        }
      }
    `}

  ${props =>
    props.agentJoined &&
    css`
      margin-right: 0;
      justify-content: center;
    `};
`;

export const ChatItemBody = styled(PerfectScrollbar)`
    height: calc(100% - 116px);
    ${props =>
    props.isSingleChat &&
      css`
        height: 300px;
      `}

    max-height: calc(100vh - ${props => props.theme.templates.headerHeight} + 146px);
    // border: 1px solid rgba(${props => hexToRgb(props.theme.colors.colorbg03)}, .5);
    // border-top-width: 0px;
    // border-bottom-width: 0px;
    position: relative;
    overflow: hidden;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .avatar {
        background-color: ${props => props.theme.colors.colorbg03};
        width: 26px;
        height: 26px;

        &::before {
            display: none;
        }

        i {
            font-size: 10px;
            font-family: ${props => props.theme.fonts.numeric};
            font-style: normal;
        }

        span {
            font-size: 11px;
        }

        img {
            width: 26px;
            height: 26px;
            border-radius: 100%;

            &.bot {
                width: 18px;
                height: 18px;
            }
        }
    }

    p {
        padding: 7px 10px;
        background-color: ${props => props.theme.colors.colorbg01};
        border-radius: ${props => props.theme.borders.radiussm};
        margin-bottom: 2px;
        font-size: ${props => props.theme.fontSizes.sm};
        line-height: 1.4;
        color: ${props => props.theme.colors.colortx02};
        display: inline-block;
    }

    small {
        font-size: 10px;
        color: ${props => props.theme.colors.colortx03};
        display: block;
    }

    .ps__thumb-y {
        width: 2px;
    }

    .ps__rail-y {
        width: 4px;
        &:hover {
            .ps__thumb-y {
                width: 4px;
            }
        }
    }
`;

export const AgentQuestionItemBody = styled(ChatItemBody)`
  max-height: ${props => (props.isForcedQuestion ? '475px' : 'calc(100vh - 400px)')};
  min-height: calc(100vh - 500px);
`;

export const ChatItemMessage = styled.div`
  flex: 1;
  padding-left: 8px;
  word-break: break-word;
`;

export const MessageInput = styled.div`
  background-color: #fff;
  padding: 10px;
  border: 1px solid #e2e7f4;
  border-radius: ${props => props.theme.borders.radius};

  & .send {
    padding: 0;
    color: #98a4c1;
    display: flex;
    min-height: 0;
    position: relative;
  }

  & .send i {
    cursor: pointer;
  }

  & .send:last-child i {
    line-height: 1;
  }
  & .send:last-child::before {
    content: '';
    border-left: 1px solid #e1e6f7;
    display: inline-block;
    margin: 0 5px;
  }
  & .send:last-child {
    color: #0168fa;
  }
`;

export const TypingMessage = styled.div`
  color: #929eb9;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 12px;
`;
export const ChatItemFooter = styled.div`
  padding: ${props => (props.isActive ? '10px' : '0')};
  background-color: rgba(247,248,252,.75);
  border-top: 1px solid #e1e6f7;

  .dropdown {
    margin-left: 5px;
    margin-top: 4px;
  }

  .send {
    font-size: 20px;
    color: ${props => props.theme.colors.colorui01};
  }

  .msg-send {
    margin-top: 3px;
  }

  .dropdown-link {
    font-size: 24px;
  }

  button {
    margin-left: auto;
  }
`;

export const CustomMentionInput = styled(MentionsInput)`
  textarea::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  textarea:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #233457;
  }
  textarea:focus {
    color: #233457;
    background-color: #fff;
    border-color: #7cb2fe;
    outline: 0 !important;
    box-shadow: none;
  }
  textarea::placeholder {
    color: #929eb9;
    opacity: 1;
  }
`;

export const CharsCounter = styled.span`
  font-size: 12px;
  color: ${props => (props.overlimit ? 'red' : '#23282c')};
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ResponseTyping = styled.div`
  height: 34px;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 0px;
  margin-right: 10px;
  box-shadow: 0 1px 1px rgba(225, 230, 247, 0.25), 0 2px 2px rgba(225, 230, 247, 0.2), 0 4px 4px rgba(225, 230, 247, 0.15), 0 8px 8px rgba(225, 230, 247, 0.1), 0 16px 16px rgba(225, 230, 247, 0.05);
`;

export const MiniLoader = styled(Loader)`
  & > .sk-child {
    width: 4px !important;
    height: 4px !important;
  }
`;

export const HiddenChatItem = styled(ChatItem)`
  display: ${props => (props.show ? 'flex' : 'none')} !important;
`;

export const HobbesQuestion = styled.li`
  padding: 10px;
  font-size: 13px;
  &:not(:first-child) {
    padding-top: 0;
  }
  & small {
    font-size: 11px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #a0a9bd;
    display: block;
    margin-top: 3px;
  }
`;

export const HobbesQuestionBody = styled.div`
  display: inline-block;
  max-width: 90%;
  background-color: #e9eaf0;
  border-radius: 3px;
  position: relative;
  padding: 8px 20px 10px 10px;
  & > a {
    position: absolute;
    top: 0;
    right: -16px;
    color: #a0a9bd;
    line-height: 1;
    display: flex;
  }
  & p {
    background-color: transparent;
    padding: 0;
    margin-bottom: 8px;
  }
  & p:last-child {
    margin-bottom: 0;
  }
  &.full-white {
    max-width: none;
    background-color: #fff;
    border: 1px solid #ccced9;

    a {
      top: 2px;
      right: 2px;
    }
  }
`;

export const AgentResponseBody = styled.div`
  padding: 8px 10px 10px;
  font-size: 13px;
  background-color: #c8dfff;
  border-radius: 3px;
  width: 90%;
  & label {
    font-weight: 400 !important;
    display: block !important;
    margin-bottom: 5px !important;
  }
`;

export const AgentFormAnswered = styled.div`
  min-height: 30px;
  height: auto;
  background-color: #fff;
  border: 1px solid #c1c8de;
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 8px;
  display: none;
  &::after {
    content: '\\EB7B';
    font-family: 'remixicon';
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 8px;
    line-height: 0;
    color: #657697;
  }
  &.highlighted {
    display: flex;
    border-width: 0;
    background-color: #657697;
    color: #fff;
    justify-content: center;
  }
  &.highlighted::after {
    position: static;
    color: #fff;
    margin-left: 2px;
    font-size: 14px;
  }
`;

export const AgentFormAnsweredText = styled.span`
  margin: 0;
  padding-right: 30px;
  padding-left: 0;
  hyphens: auto;
  word-break: break-word;
`;

export const PartQuestionAnswered = styled.div`
  margin-top: 10px;
  ${AgentFormAnswered} {
    display: flex;
  }
`;

export const AgentResponse = styled.li`
  padding: 0 10px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & small {
    margin-top: 2px;
    display: none;
  }
  &.answered .form-response {
    display: none;
  }
  &.answered ${AgentFormAnswered}, &.answered .br-item .br-answer,
  .br-item &.answered .br-answer {
    display: flex;
  }
  &.answered .bot-response-footer {
    display: none;
  }
  &.answered small {
    display: inline-block;
  }
  & .btn-white:hover,
  & .btn-white:focus {
    border-color: #63a3fe;
    color: #0168fa;
  }
`;

export const ResponseLabel = styled.label``;

export const FormResponse = styled.div`
  display: flex;
  align-items: center;

  & .form-control {
    flex: 1;
    border-radius: 3px;
    margin-right: 7px;
    font-size: 13px;
    border-color: #c1c8de;
    height: 30px;
    min-height: 0;
    margin-bottom: 0 !important;
  }

  & .form-control:focus {
    box-shadow: none;
    border-color: #7cb2fe;
  }

  & .react-tel-input .phone-number-input {
    background-color: #fff !important;
    padding: 0.375rem 0.75rem;
  }
`;

export const BotResponseFooter = styled.div`
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #c1c8de;
  & .btn-white {
    border-color: #c1c8de;
  }
`;

export const ChatFormButton = styled.button`
  background-color: #fff !important;
  border-color: #d5dcf4;
  color: #4a5e8a;
  width: 100%;
  justify-content: center;
  font-size: 13px;
  max-height: 100px;
  min-height: 30px;
  border-radius: 3px;
  border-color: #c1c8de;
  box-shadow: none;
  outline: none;
  border-style: solid;
  &.active {
    background-color: rgba(1, 104, 250, 0.1);
    border-color: rgba(1, 104, 250, 0.3);
    color: #0b2151;
  }
  &:hover,
  &:focus {
    border-color: #63a3fe;
    color: #0168fa;
    border-style: solid;
  }
`;

export const SubmitBtn = styled(ButtonLikeAnchor)`
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  font-size: 0.875rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  &:hover {
    color: #344563;
    text-decoration: none;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 104, 250, 0.25);
  }
  &.disabled,
  &:disabled {
    opacity: 0.6;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  border: 1px solid #c1c8de;
  background-color: #fff;
  color: #344563;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  min-height: 0;
  flex-shrink: 0;
  transition: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  & i {
    font-size: 18px;
    line-height: 1;
    transition: all 0.25s;
  }
  @media (prefers-reduced-motion: reduce) {
    & i {
      transition: none;
    }
  }

  &.btn-primary {
    color: #fff !important;
    background-color: #0168fa !important;
    border-color: #0168fa !important;
  }
  &.btn-primary:hover {
    color: #fff !important;
    background-color: #0158d4 !important;
    border-color: #0153c7 !important;
  }
  &.btn-primary:focus,
  &.btn-primary.focus {
    color: #fff !important;
    background-color: #0158d4 !important;
    border-color: #0153c7 !important;
    box-shadow: 0 0 0 0.2rem rgba(39, 127, 251, 0.5) !important;
`;

export const BinaryForm = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;
  & .btn {
    flex: 1;
    justify-content: center;
    min-height: 0;
    border-radius: 3px;
    border-color: #c1c8de;
  }
`;

export const QuestionsForm = styled.div`
  margin-top: ${props => (props.isFirstQuestion ? '0px' : '10px')};
`;

export const BotTyping = styled.div`
  padding: 0 10px 10px;
  & .response-typing {
    height: 30px;
    background-color: #e9eaf0;
    border-radius: 3px;
    box-shadow: none;
  }
`;

export const ResponseTypingBot = styled.div`
  height: 34px;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 10px;
  box-shadow: 0 1px 1px rgba(225, 230, 247, 0.25), 0 2px 2px rgba(225, 230, 247, 0.2), 0 4px 4px rgba(225, 230, 247, 0.15), 0 8px 8px rgba(225, 230, 247, 0.1), 0 16px 16px rgba(225, 230, 247, 0.05);
`;

export const MiniLoader2 = styled(MiniLoader)`
  width: 18px;
`;

export const MiniLoader3 = styled(MiniLoader2)`
  margin: 0;
`;

export const DarkBlueAvatar = styled(Avatar)`
  background-color: #15274d !important;
`;

export const TextBox = styled.div`
  width: 80%;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  position: relative;

  & span {
    position: absolute;
    white-space: nowrap;
    transform: translateX(0);
    transition: 1s;
  }

  &:hover span {
    transform: translateX(calc(200px - 100%));
  }
`;

export const ChatTextArea = styled.textarea`
  height: 80px !important;
  max-height: 100px;
`;

export const ButtonLikeAnchor2 = styled(ButtonLikeAnchor)`
  outline: none;
  `;

export const TextAnalyzingContainer = styled.div`
  background-color: #f7f8fc;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  border-top: 1px solid rgba(225,230,247,.75);
`;
