import styled, { keyframes, css } from 'styled-components';

export const CallNotificationWrapper = styled.div
  .attrs({ className: 'call-notify' })`
  position: fixed;
  top: 84px;
  right: 20px;
  width: 360px;
  background-color: #fff;
  border: 1px solid #D5DCF4;
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(11, 33, 81, 0.12);
`;

export const CallNotificationAvatar = styled.div`

`;

export const CallNotificationHeader = styled.div
  .attrs({ className: 'call-notify-header' })`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  border-bottom: 1px solid #D5DCF4;

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #15274d;
    color: #fff;
  }

  h6 {
    margin-bottom: 0;
  }

  .close {
    margin-left: auto;
    color: #657697;
    display: flex;
  }
`;

export const CallNotificationBody = styled.div
  .attrs({ className: 'call-notify-body' })`
  padding: 15px;

  p:last-child {
    display: flex;
    align-items: center;

    span + span::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: #657697;
      border-radius: 100%;
      margin: 0 4px 2px;
    }
  }
`;

const rotation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const CallNotificationFooter = styled.div
  .attrs({ className: 'call-notify-footer' })`
  padding: 0 15px 15px;
  display: flex;

  .btn {
    flex: 1;
    justify-content: center;
    border-radius: 4px;

    + .btn { margin-left: 5px; }
  }

  .btn-connecting {
    i {
      margin-right: 5px;
      font-size: 16px;
      ${css`animation: ${rotation} 2s infinite linear`};
    }
  }
`;
