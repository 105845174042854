import produce from 'immer';
import { Action, ActionType } from './action-types';

export interface ApartmentState {
  isGettingLabels: boolean;
  labels: string[];
}

export const initialState: ApartmentState = {
  isGettingLabels: false,
  labels: [],
};

export default produce((state: ApartmentState = initialState, action: Action): ApartmentState => {
  switch (action.type) {
    case ActionType.GET_EMAIL_LABELS_REQUEST:
      state.isGettingLabels = true;
      break;
    case ActionType.GET_EMAIL_LABELS_SUCCESS:
      state.isGettingLabels = false;
      state.labels = action.result.data.results;
      break;
    case ActionType.GET_EMAIL_LABELS_FAILURE:
      state.isGettingLabels = false;
      break;
  }

  return state;
});
