import Carousel from './_carousel';
import PhotosFilter from './_photos_filter';

class PhotosCarouselDrawer {
  photos: { [key: string]: string[] };
  convId: number;
  id: string;
  listPhotos: { source: string, type: string, idx: number }[];
  currentFilter: string;
  totalPhotosCount: number;
  categories: string[];
  constructor(photos = {}, convId = null) {
    this.photos = photos;
    this.convId = convId;
    const randomId = Math.floor(Math.random() * 1000000);
    this.id = `photos-carousel-${convId ? `${convId}` : `${randomId}`}`;
    this.listPhotos = [];
    this.currentFilter = 'All';
    this.totalPhotosCount = Object.values(this.photos).reduce((acc, val) => acc + val.length, 0);
    this.categories = Object.keys(this.photos);
  }

  static drawSimpleCard = (): HTMLDivElement => {
    const card = document.createElement('div');
    card.classList.add('hobbes_card');
    return card;
  };

  static drawPhotoBody = (): HTMLDivElement => {
    const photoBody = document.createElement('div');
    photoBody.classList.add('proschat-item-body');
    return photoBody;
  };

  static drawPrevBtn = (): HTMLButtonElement => {
    const prevBtn = document.createElement('button');
    prevBtn.classList.add('hobbes_prevBtn');
    prevBtn.classList.add('hobbes_arrow');
    prevBtn.innerHTML = '<i class="ri-arrow-left-s-fill"></i>';
    return prevBtn;
  };

  static drawNextBtn(): HTMLButtonElement {
    const nextBtn = document.createElement('button');
    nextBtn.classList.add('hobbes_nextBtn');
    nextBtn.classList.add('hobbes_arrow');
    nextBtn.innerHTML = '<i class="ri-arrow-right-s-fill"></i>';
    return nextBtn;
  }

  static drawDotsContainer(): HTMLDivElement {
    const dotsContainer = document.createElement('div');
    dotsContainer.classList.add('hobbes_dots');
    return dotsContainer;
  }

  static hideArrows = (container: HTMLDivElement): void => {
    const prevBtn = container.querySelector<HTMLElement>('.hobbes_prevBtn');
    const nextBtn = container.querySelector<HTMLElement>('.hobbes_nextBtn');
    prevBtn.style.visibility = 'hidden';
    nextBtn.style.visibility = 'hidden';
  };

  reRenderCarousel = (): void => {
    const outerCarouselContainer = document.querySelector(`#c-${this.id}`) as HTMLDivElement;
    const mainContainer = outerCarouselContainer.parentElement as HTMLDivElement;
    outerCarouselContainer.innerHTML = '';

    if (this.listPhotos.length > 1) {
      const carouselContainer = this.drawCarouselContainer();
      const carouselControls = this.drawCarouselControls();
      outerCarouselContainer.append(carouselContainer);
      outerCarouselContainer.append(carouselControls);
      PhotosCarouselDrawer.hideArrows(outerCarouselContainer);
      PhotosCarouselDrawer.activateCarousel(mainContainer, `#c-${this.id}`);
    } else if (this.listPhotos.length === 1) {
      const carouselContainer = this.drawCarouselContainerForOnePhoto();
      outerCarouselContainer.append(carouselContainer);
    }
  };

  tabClickHandler = (e: MouseEvent): void => {
    const tab = e.target as HTMLElement;
    const tabs = tab.parentElement.children;
    for (let i = 0; i < tabs.length; i += 1) {
      tabs[i].classList.remove('active');
    }
    tab.classList.add('active');
    const tabName = tab.innerText;
    this.listPhotos = this.filterPhotos(this.photos, tabName);
    this.reRenderCarousel();
  };

  filterPhotos = (photos: { [key: string]: string[] }, filter: string): { source: string, type: string, idx: number }[] => {
    this.currentFilter = filter;
    if (filter === 'All') {
      const entries = Object.entries(photos);
      const filteredPhotos = [];
      entries.forEach((entry) => {
        const [key, value] = entry;
        value.map(photo => filteredPhotos.push({ source: photo, type: key }));
      });
      return filteredPhotos.map((photo, idx) => ({ source: photo.source, type: photo.type, idx }));
    }
    return photos[filter.toLowerCase()].map((photo, idx) => ({ source: photo, type: filter, idx }));
  };

  setInitialPhotos = (): void => {
    this.listPhotos = this.filterPhotos(this.photos, 'All');
  };

  drawFilterControls = (): HTMLElement => {
    const navContainer = document.createElement('nav');
    navContainer.classList.add('nav', 'nav-line', 'pb-1', 'mb-3', 'hobbes_photos-filter');

    const tabs = new PhotosFilter(navContainer, this.photos, this.categories).draw();
    if (this.totalPhotosCount > 1) {
      tabs.forEach((tab) => {
        tab.addEventListener('click', this.tabClickHandler);
        navContainer.append(tab);
      });
    }
    this.setInitialPhotos();
    return navContainer;
  };

  drawImage = (photo: { source: string, type: string, idx: number }): HTMLDivElement => {
    const imageContainer = document.createElement('div');
    imageContainer.classList.add('card-img', 'mb-0');
    const image = document.createElement('img');
    image.classList.add('img-fluid');
    image.setAttribute('src', photo.source);
    image.setAttribute('alt', 'Photo');
    image.setAttribute('data-photo-idx', photo.idx.toString());
    image.setAttribute('data-photo-type', photo.type);
    image.setAttribute('onmousedown', 'return false');
    image.setAttribute('ondragstart', 'return false');
    imageContainer.append(image);
    return imageContainer;
  };

  drawOneCard = (photo: { source: string, type: string, idx: number }): HTMLDivElement => {
    const card = PhotosCarouselDrawer.drawSimpleCard();
    const photoBody = PhotosCarouselDrawer.drawPhotoBody();
    const image = this.drawImage(photo);
    photoBody.append(image);
    card.append(photoBody);
    return card;
  };

  drawCards = (): HTMLDivElement[] => {
    const cards = this.listPhotos.map((photo) => {
      const card = this.drawOneCard(photo);
      return card;
    });
    return cards;
  };

  drawCarousel = (): HTMLDivElement => {
    const carousel = document.createElement('div');
    carousel.classList.add('hobbes_carousel');
    const cards = this.drawCards();
    cards.forEach((card) => {
      carousel.append(card);
    });
    return carousel;
  };

  drawCarouselForOnePhoto = (): HTMLDivElement => {
    const carousel = document.createElement('div');
    carousel.classList.add('hobbes_carousel');
    const card = this.drawOneCard(this.listPhotos[0]);
    carousel.append(card);
    return carousel;
  };

  drawCarouselContainer = (): HTMLDivElement => {
    const carouselContainer = document.createElement('div');
    carouselContainer.classList.add('hobbes_carousel-container');

    const carousel = this.drawCarousel();
    carouselContainer.append(carousel);

    return carouselContainer;
  };

  drawCarouselContainerForOnePhoto = (): HTMLDivElement => {
    const carouselContainer = document.createElement('div');
    carouselContainer.classList.add('hobbes_carousel-container');
    const carousel = this.drawCarouselForOnePhoto();
    carouselContainer.append(carousel);
    return carouselContainer;
  };

  drawCarouselControls = (): HTMLDivElement => {
    const carouselControls = document.createElement('div');
    carouselControls.classList.add('hobbes_carousel-controls');

    const prevBtn = PhotosCarouselDrawer.drawPrevBtn();
    const nextBtn = PhotosCarouselDrawer.drawNextBtn();
    const dotsContainer = PhotosCarouselDrawer.drawDotsContainer();

    carouselControls.append(prevBtn);
    carouselControls.append(dotsContainer);
    carouselControls.append(nextBtn);

    return carouselControls;
  };

  drawPhotosContainer = (): HTMLDivElement => {
    const photosContainer = document.createElement('div');
    photosContainer.classList.add('hobbes_chat-photo-carousel-container');
    photosContainer.setAttribute('id', `p-${this.id}`);
    const outerCarouselContainer = document.createElement('div');
    outerCarouselContainer.setAttribute('id', `c-${this.id}`);
    outerCarouselContainer.classList.add('hobbes_chat-carousel-container');
    const filterControls = this.drawFilterControls();

    if (this.totalPhotosCount > 1) {
      const carouselContainer = this.drawCarouselContainer();
      const carouselControls = this.drawCarouselControls();
      outerCarouselContainer.append(carouselContainer);
      outerCarouselContainer.append(carouselControls);
    } else if (this.totalPhotosCount === 1) {
      const carouselContainer = this.drawCarouselContainerForOnePhoto();
      outerCarouselContainer.append(carouselContainer);
    }

    photosContainer.append(filterControls);
    photosContainer.append(outerCarouselContainer);
    return photosContainer;
  };

  drawSeparator = (): HTMLDivElement => {
    const carouselSeparator = document.createElement('div');
    carouselSeparator.classList.add('mt-20');
    const photosContainer = this.drawPhotosContainer();
    carouselSeparator.append(photosContainer);
    return carouselSeparator;
  };

  static activateCarousel = (container: HTMLDivElement | HTMLLIElement, query: string, timer = 50): void => {
    const carousel = container.querySelector(query) as HTMLDivElement;
    setTimeout(() => {
      if (carousel) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const carouselInstance = new Carousel(carousel, 160, 3);
      }
    }, timer);
  };

  static getPhotosFromContainer = (container: HTMLDivElement): { source: string, type: string, idx: number }[] => {
    const photosImg = container.querySelectorAll('.hobbes_chat-photo-carousel-container .hobbes_card .card-img img') as NodeListOf<HTMLImageElement>;
    const photos = [];
    photosImg.forEach((photo) => {
      photos.push({ source: photo.getAttribute('src'), type: photo.getAttribute('data-photo-type'), idx: Number(photo.getAttribute('data-photo-idx')) });
    });
    return [...photos.sort((a, b) => a.idx - b.idx)];
  };

  activateFilters = (container: HTMLDivElement): void => {
    const tabs = container.querySelectorAll('.hobbes_photos-filter .nav-link');
    tabs.forEach((tab) => {
      tab.addEventListener('click', this.tabClickHandler);
    });
  };

  draw(createConversationFromHTML: (container: HTMLLIElement) => void): void {
    const chatContainer = document.querySelector('.chat .chat-container ul');
    const messageItem = document.createElement('li');
    const separator = this.drawSeparator();
    messageItem.append(separator);
    chatContainer.append(messageItem);
    createConversationFromHTML(messageItem);
    PhotosCarouselDrawer.activateCarousel(messageItem, `#c-${this.id}`);
  }

  activate = (container: HTMLDivElement): void => {
    const carousel = container.querySelector(`#c-${this.id}`) as HTMLDivElement;
    this.listPhotos = PhotosCarouselDrawer.getPhotosFromContainer(carousel);
    const filters = this.listPhotos.map(photo => photo.type).filter((value, index, self) => self.indexOf(value) === index);
    this.photos = filters.reduce((acc, filter) => {
      acc[filter.toLowerCase()] = this.listPhotos.filter(photo => photo.type.toLowerCase() === filter.toLowerCase()).map(photo => photo.source);
      return acc;
    }, {});
    this.totalPhotosCount = this.listPhotos.length;
    this.activateFilters(container);
    PhotosCarouselDrawer.activateCarousel(container, `#c-${this.id}`);
  };
}

export default PhotosCarouselDrawer;
