import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  GET_OTHER_INCOMES_REQUEST = 'GET_OTHER_INCOMES_REQUEST',
  GET_OTHER_INCOMES_SUCCESS = 'GET_OTHER_INCOMES_SUCCESS',
  GET_OTHER_INCOMES_FAILURE = 'GET_OTHER_INCOMES_FAILURE',

  UPDATE_OTHER_INCOMES_REQUEST = 'UPDATE_OTHER_INCOMES_REQUEST',
  UPDATE_OTHER_INCOMES_SUCCESS = 'UPDATE_OTHER_INCOMES_SUCCESS',
  UPDATE_OTHER_INCOMES_FAILURE = 'UPDATE_OTHER_INCOMES_FAILURE',
}

export interface OtherIncomeProps {
  id?: number,
  income_category?: string,
  income_status?: string,
  monthly_income?: number,
  past_12_months_income?: number,
  documents?: string[],
}

export interface OtherIncomeState extends CommonStateType {
  other_incomes: OtherIncomeProps[],
}

export interface OtherIncomeErrorProps {
  employer_name?: string,
  monthly_income?: string,
}

/*
 * Actions
 */

interface GetOtherIncomesAction extends CommonActionResponse {
  type: ActionType.GET_OTHER_INCOMES_REQUEST
  | ActionType.GET_OTHER_INCOMES_SUCCESS
  | ActionType.GET_OTHER_INCOMES_FAILURE;
  result: { data: { results: OtherIncomeProps[] } };
}

interface UpdateOtherIncomesAction extends CommonActionResponse {
  type: ActionType.UPDATE_OTHER_INCOMES_REQUEST
  | ActionType.UPDATE_OTHER_INCOMES_SUCCESS
  | ActionType.UPDATE_OTHER_INCOMES_FAILURE;
  result: { data: OtherIncomeProps[] };
}

export type Action =
  PromiseActionType
  | GetOtherIncomesAction
  | ResetLeaseAction
  | UpdateOtherIncomesAction;
