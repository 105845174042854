import produce from 'immer';
import { ScreeningReportProps, ScreeningReportState } from './action-types';

export const initialState: ScreeningReportState = {
  errorMessage: null,
  isLoaded: false,
  screeningReports: [] as ScreeningReportProps[],
};

export default produce((state: ScreeningReportState = initialState) => state);
