import produce from 'immer';
import { CommonStateType } from 'dwell/store/types';
import { SITE_TYPES } from 'corporate/views/edit_site/edit_panel/component/constants';
import { Action, ActionType, CorporateSite, Subsite, Standalone, SiteSelected } from './action-types';

export interface CorporateCustomerState extends CommonStateType {
  corporateSites: CorporateSite[];
  corporateSite: CorporateSite;
  subsites: Subsite[];
  standaloneSites: Standalone[];
  itemSelected: SiteSelected
}

export const initialState: CorporateCustomerState = {
  corporateSites: null,
  corporateSite: null,
  subsites: [],
  standaloneSites: [],
  isLoaded: false,
  itemSelected: { site: {}, siteType: SITE_TYPES.CORPORATE } as SiteSelected,
};

export const selectCorporateSites = (state: { corporateCustomer: CorporateCustomerState }): CorporateSite[] => state.corporateCustomer.corporateSites;
export const selectCorporateSite = (state: { corporateCustomer: CorporateCustomerState }): CorporateSite => state.corporateCustomer.corporateSite;
export const selectSubsites = (state: { corporateCustomer: CorporateCustomerState }): Subsite[] => state.corporateCustomer.subsites;
export const selectStandaloneSites = (state: { corporateCustomer: CorporateCustomerState }): Standalone[] => state.corporateCustomer.standaloneSites;
export const selectSiteSelected = (state: { corporateCustomer: CorporateCustomerState }): SiteSelected => state.corporateCustomer.itemSelected;
// export const selectCustomerInformation = (state: { corporateCustomer: CorporateCustomerState }): { corporateSite: CorporateSite, subsite: Subsite[], standalone: Standalone[] } =>
//   ({ corporateSite: selectCorporateSite(state), subsite: selectSubsite(state), standalone: selectStandalone(state) });

export default produce((state: CorporateCustomerState = initialState, action: Action): CorporateCustomerState => {
  switch (action.type) {
    case ActionType.GET_CUSTOMER_INFORMATION_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_CUSTOMER_INFORMATION_SUCCESS: {
      const { corporate_sites: corporateSites, subsites, standalone_sites: standaloneSites } = action.result.data;
      state.corporateSites = corporateSites;
      state.subsites = subsites;
      state.standaloneSites = standaloneSites;
      state.isLoaded = true;
      break;
    }
    case ActionType.CREATE_AMENITY_SUCCESS: {
      state.corporateSites.find(site => site.id === state.corporateSite.id).amenities = [...state.corporateSite.amenities, action.result.data];
      state.corporateSite.amenities = [...state.corporateSite.amenities, action.result.data];
      break;
    }
    case ActionType.UPDATE_AMENITY_SUCCESS: {
      state.corporateSite.amenities = state.corporateSite.amenities.map((amenity) => {
        if (amenity.id === action.result.data.id) {
          return action.result.data;
        }
        return amenity;
      });
      state.corporateSites.find(site => site.id === state.corporateSite.id).amenities = state.corporateSite.amenities.map((amenity) => {
        if (amenity.id === action.result.data.id) {
          return action.result.data;
        }
        return amenity;
      });
      break;
    }
    case ActionType.DELETE_AMENITY_SUCCESS: {
      state.corporateSite.amenities = state.corporateSite.amenities.filter(amenity => amenity.id !== action.result.data.id);
      state.corporateSites.find(site => site.id === state.corporateSite.id).amenities = state.corporateSite.amenities.filter(amenity => amenity.id !== action.result.data.id);
      break;
    }
    case ActionType.GET_CUSTOMER_INFORMATION_FAILURE:
      state.isLoaded = true;
      break;

    case ActionType.SELECT_ITEM:
      state.itemSelected = { site: action.item, siteType: action.itemType };
      break;

    case ActionType.SELECT_CORPORATE_SITE: {
      state.corporateSite = action.item;
      break;
    }
  }
  return state;
});
