export default {
  TOPIC_VALUE: {
    GENERAL: 'General',
    LEASING_TEAM: 'Leasing',
    RESIDENT_TEAM: 'Resident Help',
  },
  CATEGORY_VALUE: {
    PROSPECT: 'Prospect',
    RESIDENT: 'Resident',
    OTHER: 'Other',
  },
  ANSWER_BY_VALUE: {
    ANSWER_LOCATION_OFFICE: 'Office',
    ANSWER_LOCATION_COE: 'Virtual',
    ANSWER_LOCATION_MISSED: 'Missed',
  },
  TOPIC_FILTER_CHOICES: {
    ANY_TOPIC: 'Any Topic',
    LEASING_TEAM: 'Leasing',
    GENERAL: 'General',
    RESIDENT_TEAM: 'Resident Help',
  },
  CALL_DIRECTION_CHOICES: {
    ANY_DIRECTION: 'Any Direction',
    INBOUND: 'Inbound',
    INBOUND_TRANSFER: 'Inbound Transfer',
    OUTBOUND: 'Outbound',
    OUTBOUND_TRANSFER: 'Outbound Transfer',
  },
  CALL_RESULT_CHOICES: {
    ANY_RESULT: 'Any Status',
    ANSWERED: 'Answered',
    MISSED: 'Missed',
    MISSED_W_VOICEMAIL: 'Missed w/ Voicemail',
  },
  CALL_CATEGORY_CHOICES: {
    ANY_CATEGORY: 'Any Category',
    PROSPECT: 'Prospect',
    RESIDENT: 'Resident',
  },
  CALL_KNOWN_CHOICES: {
    EVERYONE: 'Everyone',
    KNOWN: 'Known Callers',
    UNKNOWN: 'Unknown Callers',
  },
  TABS: {
    CALLS: 'Calls',
    CALLS_HISTORY: 'Calls History',
  },
  CALLS_TAB: 'CALLS',
  CALLS_HISTORY_TAB: 'CALLS_HISTORY',
  DATE_RANGE_CHOICES: {
    TODAY: 'Today',
    THIS_WEEK: 'This week',
    THIS_MONTH: 'This month',
    THIS_QUARTER: 'This quarter',
    THIS_YEAR: 'This year',

    LAST_WEEK: 'Last week',
    LAST_MONTH: 'Last month',
    LAST_QUARTER: 'Last quarter',
    LAST_YEAR: 'Last year',

    CUSTOM_RANGE: 'Custom range',
  },
};
