import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TabPane } from 'reactstrap';
import 'src/scss/pages/_reports.scss';
import { InnerIcon, TabContainer } from 'dwell/views/reports/styles';
import { selectProperty } from 'dwell/store/property/reducers';
import { SearchedObjectType } from 'dwell/store/pipeline/action-types';
import { ContentItem } from '../styles';

interface TabContentProps {
  value: { id: number, objectType: string };
  onClick: (item: { id: number; name?: string; type?: string, leasing_users?: string[] }, objectType: string) => void;
  keyword: string;
}

interface ItemType {
  id: number;
  property: number;
  name?: string;
  leasing_users?: string[];
}

const TabContent: FC<TabContentProps> = ({ value, onClick, keyword }) => {
  const property = useSelector(selectProperty);
  const { leads, leases, residents, applications } = useSelector(state => state.pipeline.searchedObjects) as SearchedObjectType;

  const filterData = useCallback(
    (data: ItemType[], objectType: string): ItemType[] => data.filter((item) => {
      if (item.property !== property.id) return false;

      const keywordLower = keyword.toLowerCase();
      const nameMatches = item.name?.toLowerCase().includes(keywordLower);
      const idMatches = item.id.toString().includes(keywordLower);

      if (['lease', 'application'].includes(objectType)) {
        return nameMatches || idMatches;
      }
      return nameMatches;
    }),
    [property.id, keyword],
  );

  const getLabel = useCallback((item: ItemType, objectType: string): string => {
    if (['lease', 'application'].includes(objectType)) {
      return `#${item.id} - Unit ${item.name || 'not assigned yet'}`;
    }
    return item.name || '-';
  }, []);

  const renderList = useCallback(
    (data: ItemType[], objectType: string) => {
      const filteredData = filterData(data, objectType);

      if (!filteredData.length) {
        return <ContentItem>Not found</ContentItem>;
      }

      return filteredData.map(item => (
        <ContentItem key={item.id} onClick={() => onClick(item, objectType)}>
          <span>{getLabel(item, objectType)}</span>
          {value.objectType === objectType && value.id === item.id && (
            <InnerIcon className="ri-check-line" />
          )}
        </ContentItem>
      ));
    },
    [filterData, getLabel, onClick, value],
  );

  const tabs = [
    { id: 'individual', label: 'Prospects', data: leads, type: 'lead' },
    { id: 'individual', label: 'Residents', data: residents, type: 'leasing_user' },
    { id: 'group', label: 'Leases', data: leases, type: 'lease' },
    { id: 'group', label: 'Applications', data: applications, type: 'application' },
  ];

  return (
    <TabContainer>
      {['individual', 'group'].map(tabId => (
        <TabPane key={tabId} tabId={tabId}>
          {tabs
            .filter(tab => tab.id === tabId)
            .map(tab => (
              <React.Fragment key={tab.type}>
                <ContentItem $label>
                  <b>{tab.label}</b>
                </ContentItem>
                {renderList(tab.data, tab.type)}
              </React.Fragment>
            ))}
        </TabPane>
      ))}
    </TabContainer>
  );
};

export default TabContent;
