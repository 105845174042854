import { startCase } from 'lodash';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import focusBarActions from 'dwell/store/focus_bar/action-creators';
import { FocusBarItem } from 'dwell/store/focus_bar/action-types';
import { OverviewReport } from 'dwell/store/report/action-types';
import { ReportLink as Link } from './styles';

interface ReportLinkProps {
  lead: {
    id?: number;
    external_id?: string;
    first_name?: string;
    last_name?: string;
    lead_id?: number;
    lead_name?: string;
    property__external_id?: string;
    lease__id?: string;
    is_application?: boolean;
  };
  type: string;
}

const ReportLink: FC<ReportLinkProps> = ({ lead, type }) => {
  const dispatch = useDispatch();
  const overviewReports: OverviewReport = useSelector(state => state.report.overviewReports);

  const onClick = () => {
    let index: number;
    let items: FocusBarItem[] = [];
    let leadId: number;
    let propertyId: string;
    let title = startCase(type);
    switch (type) {
      case 'leads':
        title = 'Lead Reports';
        break;
      case 'leases':
        title = 'Lease Reports';
        break;
      case 'responses':
        title = 'Engagement Reports';
        break;
      case 'tours':
        title = 'Tour Reports';
        break;
    }

    if (type === 'Scored Calls') {
      items = overviewReports.overall.calls.scored_calls
        .filter(i => i.lead_id)
        .sort((rowA, rowB) => Date.parse(rowB.date) - Date.parse(rowA.date))
        .map(i => ({ id: i.id, lead: Number(i.lead_id), property: i.external_id }));
      index = items.findIndex(i => i.id === lead.id);
      leadId = lead.lead_id;
      propertyId = lead.external_id;
    } else {
      items = overviewReports.audition[type].map(l => ({ lead: l.id, property: l.property__external_id }));
      index = items.findIndex(i => i.lead === lead.id);
      leadId = lead.id;
      propertyId = lead.property__external_id;
    }

    if (index >= 0) {
      dispatch(focusBarActions.setBarData(items, index, title, window.location.pathname));
    }
    if (type === 'leases') {
      window.open(`/${propertyId}/${lead.is_application ? 'applications' : 'leases'}/${lead.lease__id}/overview`, '_blank');
    } else {
      window.open(`/${propertyId}/leads/${leadId}`, '_blank');
    }
  };

  const name = type === 'Scored Calls' ? lead.lead_name : `${lead.first_name} ${lead.last_name}`;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link onClick={onClick}>
      {name}
    </Link>
  );
};

export default ReportLink;
