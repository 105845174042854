import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseAction } from 'resident/store/lease/action-types';

export enum ActionType {
  CREATE_NOTICE_REQUEST = 'CREATE_NOTICE_REQUEST',
  CREATE_NOTICE_SUCCESS = 'CREATE_NOTICE_SUCCESS',
  CREATE_NOTICE_FAILURE = 'CREATE_NOTICE_FAILURE',

  DELETE_NOTICE_REQUEST = 'DELETE_NOTICE_REQUEST',
  DELETE_NOTICE_SUCCESS = 'DELETE_NOTICE_SUCCESS',
  DELETE_NOTICE_FAILURE = 'DELETE_NOTICE_FAILURE',

  DELETE_ACTIVE_NOTICE_REQUEST = 'DELETE_ACTIVE_NOTICE_REQUEST',
  DELETE_ACTIVE_NOTICE_SUCCESS = 'DELETE_ACTIVE_NOTICE_SUCCESS',
  DELETE_ACTIVE_NOTICE_FAILURE = 'DELETE_ACTIVE_NOTICE_FAILURE',

  GET_NOTICES_REQUEST = 'GET_NOTICES_REQUEST',
  GET_NOTICES_SUCCESS = 'GET_NOTICES_SUCCESS',
  GET_NOTICES_FAILURE = 'GET_NOTICES_FAILURE',

  START_NOTICE_PROCESS_REQUEST = 'START_NOTICE_PROCESS_REQUEST',
  START_NOTICE_PROCESS_SUCCESS = 'START_NOTICE_PROCESS_SUCCESS',
  START_NOTICE_PROCESS_FAILURE = 'START_NOTICE_PROCESS_FAILURE',

  UPDATE_NOTICE_REQUEST = 'UPDATE_NOTICE_REQUEST',
  UPDATE_NOTICE_SUCCESS = 'UPDATE_NOTICE_SUCCESS',
  UPDATE_NOTICE_FAILURE = 'UPDATE_NOTICE_FAILURE',

  UPDATE_ACTIVE_NOTICE_REQUEST = 'UPDATE_ACTIVE_NOTICE_REQUEST',
  UPDATE_ACTIVE_NOTICE_SUCCESS = 'UPDATE_ACTIVE_NOTICE_SUCCESS',
  UPDATE_ACTIVE_NOTICE_FAILURE = 'UPDATE_ACTIVE_NOTICE_FAILURE',

  COLLECT_NOTICE_DETAILS_REQUEST = 'COLLECT_NOTICE_DETAILS_REQUEST',
  COLLECT_NOTICE_DETAILS_SUCCESS = 'COLLECT_NOTICE_DETAILS_SUCCESS',
  COLLECT_NOTICE_DETAILS_FAILURE = 'COLLECT_NOTICE_DETAILS_FAILURE',
}

export interface NoticeProps {
  id?: number,
  comment?: string,
  format?: 'EMAIL' | 'PHONE' | 'IN_PERSON',
  forwarding_address?: string,
  given_by_name?: string,
  given_by?: number,
  given_date?: string,
  leaving_reason?: number,
  leaving_reason_value?: string,
  pre_inspection_date?: string,
  rent_responsible_through_date?: string,
  type?: string,
  vacate_date?: string,
}

export interface NoticeState extends CommonStateType {
  notices: NoticeProps[],
}

/*
 * Actions
 */

interface CreateNoticeAction extends CommonActionResponse {
  type: ActionType.CREATE_NOTICE_REQUEST
  | ActionType.CREATE_NOTICE_SUCCESS
  | ActionType.CREATE_NOTICE_FAILURE,
  result: { data: NoticeProps },
}

export interface DeleteActiveNoticeAction extends CommonActionResponse {
  type: ActionType.DELETE_ACTIVE_NOTICE_REQUEST
  | ActionType.DELETE_ACTIVE_NOTICE_SUCCESS
  | ActionType.DELETE_ACTIVE_NOTICE_FAILURE,
}

interface DeleteNoticeAction extends CommonActionResponse {
  type: ActionType.DELETE_NOTICE_REQUEST
  | ActionType.DELETE_NOTICE_SUCCESS
  | ActionType.DELETE_NOTICE_FAILURE,
  payload: number,
}

interface GetNoticesAction extends CommonActionResponse {
  type: ActionType.GET_NOTICES_SUCCESS
  | ActionType.GET_NOTICES_SUCCESS
  | ActionType.GET_NOTICES_FAILURE,
  result: { data: { results: NoticeProps[] } },
}

interface StartNoticeProcessAction extends CommonActionResponse {
  type: ActionType.START_NOTICE_PROCESS_REQUEST
  | ActionType.START_NOTICE_PROCESS_SUCCESS
  | ActionType.START_NOTICE_PROCESS_FAILURE,
  result: { data: NoticeProps },
}

interface UpdateNoticeAction extends CommonActionResponse {
  type: ActionType.UPDATE_NOTICE_REQUEST
  | ActionType.UPDATE_NOTICE_SUCCESS
  | ActionType.UPDATE_NOTICE_FAILURE,
  result: { data: NoticeProps },
}

interface UpdateActiveNoticeAction extends CommonActionResponse {
  type: ActionType.UPDATE_ACTIVE_NOTICE_REQUEST
  | ActionType.UPDATE_ACTIVE_NOTICE_SUCCESS
  | ActionType.UPDATE_ACTIVE_NOTICE_FAILURE,
  result: { data: NoticeProps },
}

export type Action =
  PromiseActionType
  | CreateNoticeAction
  | DeleteActiveNoticeAction
  | DeleteNoticeAction
  | GetLeaseAction
  | GetNoticesAction
  | StartNoticeProcessAction
  | UpdateNoticeAction
  | UpdateActiveNoticeAction
