import { api, build } from 'leasing/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  createGuarantor: (leaseId: number, data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_GUARANTOR_REQUEST,
        ActionType.CREATE_GUARANTOR_SUCCESS,
        ActionType.CREATE_GUARANTOR_FAILURE,
      ],
      promise: client => client.post(build(api.v1.GUARANTORS, leaseId), data),
    },
  }),

  deleteGuarantorById: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_GUARANTOR_REQUEST,
        ActionType.DELETE_GUARANTOR_SUCCESS,
        ActionType.DELETE_GUARANTOR_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.GUARANTOR_DETAILS, leaseId, id)),
    },
  }),

  getGuarantorById: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_GUARANTOR_BY_ID_REQUEST,
        ActionType.GET_GUARANTOR_BY_ID_SUCCESS,
        ActionType.GET_GUARANTOR_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(api.v1.GUARANTOR_DETAILS, leaseId, id)),
    },
  }),

  getGuarantors: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_GUARANTORS_REQUEST,
        ActionType.GET_GUARANTORS_SUCCESS,
        ActionType.GET_GUARANTORS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.GUARANTORS, leaseId)),
    },
  }),

  resendGuarantorInvite: (leaseId: number, guarantorId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.RESEND_APPLICATION_INVITE_REQUEST,
        ActionType.RESEND_APPLICATION_INVITE_SUCCESS,
        ActionType.RESEND_APPLICATION_INVITE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.RESEND_GUARANTOR_INVITE, leaseId, guarantorId)),
    },
  }),

  resetGuarantor: (): { type: string } => ({
    type: ActionType.RESET_GUARANTOR,
  }),

  updateGuarantorById: (leaseId: number, id: number, params: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_GUARANTOR_REQUEST,
        ActionType.UPDATE_GUARANTOR_SUCCESS,
        ActionType.UPDATE_GUARANTOR_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.GUARANTOR_DETAILS, leaseId, id), params),
    },
  }),
};
