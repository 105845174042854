import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_EMAIL_TEMPLATES_REQUEST = 'GET_EMAIL_TEMPLATES_REQUEST',
  GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS',
  GET_EMAIL_TEMPLATES_FAILURE = 'GET_EMAIL_TEMPLATES_FAILURE',

  CREATE_EMAIL_TEMPLATE_REQUEST = 'CREATE_EMAIL_TEMPLATE_REQUEST',
  CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS',
  CREATE_EMAIL_TEMPLATE_FAILURE = 'CREATE_EMAIL_TEMPLATE_FAILURE',

  UPDATE_EMAIL_TEMPLATE_REQUEST = 'UPDATE_EMAIL_TEMPLATE_REQUEST',
  UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS',
  UPDATE_EMAIL_TEMPLATE_FAILURE = 'UPDATE_EMAIL_TEMPLATE_FAILURE',

  DELETE_EMAIL_TEMPLATE_REQUEST = 'DELETE_EMAIL_TEMPLATE_REQUEST',
  DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS',
  DELETE_EMAIL_TEMPLATE_FAILURE = 'DELETE_EMAIL_TEMPLATE_FAILURE',
}

export interface EmailTemplateProps {
  id?: number;
  text: string;
  type?: string;
  subject: string;
  name: string;
  variables: string[];
  subject_variables: string[];
  is_system_template?: boolean;
  is_cb_template?: boolean,
  property?: number;
}

interface GetEmailTemplatesAction {
  type: ActionType.GET_EMAIL_TEMPLATES_REQUEST | ActionType.GET_EMAIL_TEMPLATES_SUCCESS | ActionType.GET_EMAIL_TEMPLATES_FAILURE;

  result: {
    data: { results: EmailTemplateProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface CreateEmailTemplateAction {
  type: ActionType.CREATE_EMAIL_TEMPLATE_REQUEST | ActionType.CREATE_EMAIL_TEMPLATE_SUCCESS | ActionType.CREATE_EMAIL_TEMPLATE_FAILURE;

  result: {
    data: EmailTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateEmailTemplateByIdAction {
  type: ActionType.UPDATE_EMAIL_TEMPLATE_REQUEST | ActionType.UPDATE_EMAIL_TEMPLATE_SUCCESS | ActionType.UPDATE_EMAIL_TEMPLATE_FAILURE;

  result: {
    data: EmailTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteEmailTemplateByIdAction {
  type: ActionType.DELETE_EMAIL_TEMPLATE_REQUEST | ActionType.DELETE_EMAIL_TEMPLATE_SUCCESS | ActionType.DELETE_EMAIL_TEMPLATE_FAILURE;

  result: {
    data: EmailTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetEmailTemplatesAction
  | CreateEmailTemplateAction
  | UpdateEmailTemplateByIdAction
  | DeleteEmailTemplateByIdAction;
