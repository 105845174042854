import { PropertyProps } from '../property/action-types';
import { ActionType, Action } from './action-types';

export default {
  setCheckedAllProperties: (checked: number): Action => ({
    type: ActionType.SET_CHECK_ALL_PROPERTIES,
    checked,
  }),

  setShowApplyButton: (showApply: boolean): Action => ({
    type: ActionType.SET_SHOW_APPLY_BUTTON,
    showApply,
  }),

  setPropertiesCount: (count: number): Action => ({
    type: ActionType.SET_PS_PROPERTIES_COUNT,
    count,
  }),

  setShowSelector: (show: boolean): Action => ({
    type: ActionType.SET_SHOW_SELECTOR,
    show,
  }),

  setPreSelectedProperties: (properties: PropertyProps[]): Action => ({
    type: ActionType.SET_PRESELECTED_PROPERTIES,
    properties,
  }),

  setPostSelectedProperties: (properties: PropertyProps[]): Action => ({
    type: ActionType.SET_POSTSELECTED_PROPERTIES,
    properties,
  }),

  setAvailableProperties: (properties: PropertyProps[]): Action => ({
    type: ActionType.SET_AVAILABLE_PROPERTIES,
    properties,
  }),

  setPropertyKeyword: (keyword: string): Action => ({
    type: ActionType.SET_PROPERTY_KEYWORD,
    keyword,
  }),
};
