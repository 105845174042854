import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { CreateLeadAction, UpdateLeadByIdAction } from 'dwell/store/lead/action-types';

export enum ActionType {
  GET_SMS_CONTACTS_REQUEST = 'GET_SMS_CONTACTS_REQUEST',
  GET_SMS_CONTACTS_SUCCESS = 'GET_SMS_CONTACTS_SUCCESS',
  GET_SMS_CONTACTS_FAILURE = 'GET_SMS_CONTACTS_FAILURE',

  GET_SMS_CONVERSATIONS_REQUEST = 'GET_SMS_CONVERSATIONS_REQUEST',
  GET_SMS_CONVERSATIONS_SUCCESS = 'GET_SMS_CONVERSATIONS_SUCCESS',
  GET_SMS_CONVERSATIONS_FAILURE = 'GET_SMS_CONVERSATIONS_FAILURE',

  SEND_SMS_REQUEST = 'SEND_SMS_REQUEST',
  SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS',
  SEND_SMS_FAILURE = 'SEND_SMS_FAILURE',

  READ_ALL_SMS_REQUEST = 'READ_ALL_SMS_REQUEST',
  READ_ALL_SMS_SUCCESS = 'READ_ALL_SMS_SUCCESS',
  READ_ALL_SMS_FAILURE = 'READ_ALL_SMS_FAILURE',

  UPDATE_NOTIFICATION_REDIRECTION = 'UPDATE_NOTIFICATION_REDIRECTION',

  CLEAR_CONTACTS = 'CLEAR_CONTACTS',

  GET_CONTACT_BY_ID_REQUEST = 'GET_CONTACT_BY_ID_REQUEST',
  GET_CONTACT_BY_ID_SUCCESS = 'GET_CONTACT_BY_ID_SUCCESS',
  GET_CONTACT_BY_ID_FAILURE = 'GET_CONTACT_BY_ID_FAILURE',

  REMOVE_CONTACT = 'REMOVE_CONTACT',
}

export interface SMSContact {
  id: number;
  name: string;
  last_message: string;
  last_message_date: string;
  unread_count: number;
  phone_number: string;
  property: number;
  property_can_sms: boolean;
  property_name: string;
  property_external_id: string;
  sms_opt_in_status: string;
}

export interface SMSMessage {
  lead?: number;
  leasing_user?: number,
  lease?: number,
  is_team_message?: boolean;
  message?: string;
  date?: string;
  status?: string;
  is_read?: boolean;
  id?: number;
  params?: { offset?: number; limit?: number; property?: number };
  agent_name?: string;
  agent_avatar?: string;
  lead_name?: string;
  twilio_sid?: string;
  property?: number,
  sender_number?: string,
  receiver_number?: string,
}

interface GetSMSContactsAction {
  type: ActionType.GET_SMS_CONTACTS_REQUEST | ActionType.GET_SMS_CONTACTS_SUCCESS | ActionType.GET_SMS_CONTACTS_FAILURE;

  result: {
    data: { results: SMSContact[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetConversationsAction {
  type: ActionType.GET_SMS_CONVERSATIONS_REQUEST | ActionType.GET_SMS_CONVERSATIONS_SUCCESS | ActionType.GET_SMS_CONVERSATIONS_FAILURE;

  result: {
    data: {
      results: SMSMessage[];
      count: number;
    };
  };
  error: {
    response: { status: string };
  };
}

interface SendSMSAction {
  type: ActionType.SEND_SMS_REQUEST | ActionType.SEND_SMS_SUCCESS | ActionType.SEND_SMS_FAILURE;

  result: {
    data: SMSMessage;
  };
  error: {
    response: { status: string };
  };
}

interface ReadAllAction {
  type: ActionType.READ_ALL_SMS_REQUEST | ActionType.READ_ALL_SMS_SUCCESS | ActionType.READ_ALL_SMS_FAILURE;

  result: {
    data: SMSContact;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateNotificationRedirectionAction {
  type: ActionType.UPDATE_NOTIFICATION_REDIRECTION;

  data: boolean;
}

interface ClearContactsAction {
  type: ActionType.CLEAR_CONTACTS;
}

interface GetSMSContactByIdAction {
  type: ActionType.GET_CONTACT_BY_ID_REQUEST | ActionType.GET_CONTACT_BY_ID_SUCCESS | ActionType.GET_CONTACT_BY_ID_FAILURE;

  result: {
    data: SMSContact;
  };
  error: {
    response: { status: string };
  };
}

interface RemoveContactAction {
  type: ActionType.REMOVE_CONTACT;
  id: number;
}

export type Action =
  | PromiseActionType
  | GetSMSContactsAction
  | GetConversationsAction
  | SendSMSAction
  | ReadAllAction
  | UpdateNotificationRedirectionAction
  | ClearContactsAction
  | GetSMSContactByIdAction
  | RemoveContactAction
  | PusherAction
  | CreateLeadAction
  | UpdateLeadByIdAction;
