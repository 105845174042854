import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { Action, ActionType, CustomerProps } from './action-types';
import { CommonStateType } from '../types';

export interface CustomerState extends CommonStateType {
  isSubmitting: boolean;
  customer: CustomerProps;
  customerDetails: {
    id?: number;
    employee?: { id: number; first_name: string; last_name: string; role: string }[];
  };
  isCustomerLoaded: boolean;
  isCustomersLoaded: boolean;
  customers: CustomerProps[];
  totalCount: number;

  isPaginationSubmitting: boolean;
  isPaginationLoaded: boolean;
  paginationCustomers: CustomerProps[];
  paginationTotalCount: number;
  paginationTotalCountAll: number;
  paginationTotalCountActive: number;
  paginationTotalCountInactive: number;
  siteEnabledProperties: { id: number; name: string }[];
}

const initialState: CustomerState = {
  isSubmitting: false,
  customer: {},
  customerDetails: {},
  isCustomerLoaded: false,
  isCustomersLoaded: false,
  customers: [],
  totalCount: 0,

  isPaginationSubmitting: false,
  isPaginationLoaded: false,
  paginationCustomers: [],
  paginationTotalCount: 0,
  paginationTotalCountAll: 0,
  paginationTotalCountActive: 0,
  paginationTotalCountInactive: 0,
  siteEnabledProperties: [],
};

export const selectCustomer = (state: { customer: CustomerState }): CustomerProps => state.customer.customer;
export const selectCustomerDetails = (state: { customer: CustomerState }): { id?: number; employee?: { id: number; first_name: string; last_name: string; role: string }[] } => state.customer.customerDetails;
export const selectCustomers = (state: { customer: CustomerState }): CustomerProps[] => state.customer.customers;

export const selectIsCustomerLoaded = (state: { customer: CustomerState }): boolean => state.customer.isCustomerLoaded;
export const selectIsCustomersLoaded = (state: { customer: CustomerState }): boolean => state.customer.isCustomersLoaded;
export const selectTotalCount = (state: { customer: CustomerState }): number => state.customer.totalCount;

export const selectIsPaginationSubmitting = (state: { customer: CustomerState }): boolean => state.customer.isPaginationSubmitting;
export const selectIsPaginationLoaded = (state: { customer: CustomerState }): boolean => state.customer.isPaginationLoaded;
export const selectPaginationCustomers = (state: { customer: CustomerState }): CustomerProps[] => state.customer.paginationCustomers;
export const selectPaginationTotalCount = (state: { customer: CustomerState }): number => state.customer.paginationTotalCount;
export const selectPaginationTotalCountAll = (state: { customer: CustomerState }): number => state.customer.paginationTotalCountAll;
export const selectPaginationTotalCountActive = (state: { customer: CustomerState }): number => state.customer.paginationTotalCountActive;
export const selectPaginationTotalCountInactive = (state: { customer: CustomerState }): number => state.customer.paginationTotalCountInactive;

export default produce((state: CustomerState = initialState, action: Action): CustomerState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_CUSTOMERS_REQUEST:
      state.isCustomersLoaded = false;
      break;
    case ActionType.GET_CUSTOMERS_SUCCESS:
      state.isCustomersLoaded = true;
      state.customers = action.result.data.results;
      state.totalCount = action.result.data.count;
      break;
    case ActionType.GET_CUSTOMERS_FAILURE:
      state.isCustomersLoaded = true;
      break;

    case ActionType.GET_CUSTOMERS_PAGINATION_REQUEST:
      state.isPaginationSubmitting = true;
      state.isPaginationLoaded = false;
      break;
    case ActionType.GET_CUSTOMERS_PAGINATION_SUCCESS:
      state.isPaginationSubmitting = false;
      state.isPaginationLoaded = true;
      state.paginationCustomers = action.result.data.results;
      state.paginationTotalCount = action.result.data.count;
      state.paginationTotalCountAll = action.result.data.count_all;
      state.paginationTotalCountActive = action.result.data.count_active;
      state.paginationTotalCountInactive = action.result.data.count_inactive;
      break;
    case ActionType.GET_CUSTOMERS_PAGINATION_FAILURE:
      state.isPaginationSubmitting = false;
      state.isPaginationLoaded = false;
      break;

    case ActionType.CUSTOMER_DETAILS_GET_SUCCESS:
      state.customerDetails = action.result.data;
      break;

    case ActionType.GET_CUSTOMER_SITE_ENABLED_PROPERTIES_SUCCESS:
      state.siteEnabledProperties = action.result.data;
      break;
  }

  return state;
});
