import { paths as leasingPaths } from 'leasing/constants';
import { ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, UnitDescriptionRequestProps } from './action-types';

export default {
  getApartments: (props?: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_APARTMENTS_REQUEST,
        ActionType.GET_APARTMENTS_SUCCESS,
        ActionType.GET_APARTMENTS_FAILURE,
      ],
      promise: client => client.get(leasingPaths.api.v1.UNITS, { params: { ...props, hide_unavailable: false } }),
    },
  }),

  saveApartmentsDescription: (body: UnitDescriptionRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.BULK_SAVE_APARTMENTS_REQUEST,
        ActionType.BULK_SAVE_APARTMENTS_SUCCESS,
        ActionType.BULK_SAVE_APARTMENTS_FAILURE,
      ],
      promise: client => client.post(leasingPaths.api.v1.UNITS_BULK_SAVE, body),
    },
  }),
};
