import styled from 'styled-components';

export const PermsContainer = styled.div`
    position: fixed;
    top: 0;
    z-index: 900;
    width: 100%;
`;

export const Perms = styled.div`
    display: flex;
    background-color: rgba(1, 104, 250, 0.8);
    justify-content: center;
    cursor: pointer;
    width: 100%;
`;

export const PermsMessage = styled.div`
    margin-left: auto;
    color: white;
`;

export const PermsCloseIcon = styled.div`
    margin-left: auto;
    margin-right: 20px;
    color: white;
    padding-left: 40px;
`;
