import { PromiseActionType } from 'dwell/store/types';
import { EmailAttachments } from 'src/interfaces';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { LeadAction } from 'dwell/store/lead/action-types';
import { Event } from 'dwell/store/call/action-types';

export enum ActionType {
  GET_COMMUNICATIONS_REQUEST = 'GET_COMMUNICATIONS_REQUEST',
  GET_COMMUNICATIONS_SUCCESS = 'GET_COMMUNICATIONS_SUCCESS',
  GET_COMMUNICATIONS_FAILURE = 'GET_COMMUNICATIONS_FAILURE',

  RESET_RELOAD_STATE = 'RESET_RELOAD_STATE',
  RESET_COMMUNICATIONS = 'RESET_COMMUNICATIONS',

  CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS',
  UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS',
  DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS',
  CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS',
  UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS',
  DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS',
  COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  SEND_TEXT_TO_LEAD_SUCCESS = 'SEND_TEXT_TO_LEAD_SUCCESS',
  SEND_TEXT_TO_PROSPECT_SUCCESS = 'SEND_TEXT_TO_PROSPECT_SUCCESS',
  DELETE_ROOMMATE_SUCCESS = 'DELETE_ROOMMATE_SUCCESS',
  UPDATE_ROOMMATES_SUCCESS = 'UPDATE_ROOMMATES_SUCCESS',
}

export interface CommunicationObject {
  id: number,
  date?: string,
  agent_name?: string,
  type?: string,
  user_name?: string,
  message?: string,
  call_result?: string,
  sender_name?: string,
  receiver_name?: string,
  formatted_sender_name?: string,
  formatted_receiver_name?: string
  subject?: string,
  body?: string,
  content?: string,
  attachments?: EmailAttachments[],
  transformed_content?: string,
  creator?: string,
  agent_avatar?: string,
  direction?: string,
  duration?: number,
  tour?: {
    type: string,
    owner: string,
    units: string,
    tour_date: string,
    description: string,
    previous?: {
      type: string,
      owner: string,
      units: string,
      tour_date: string,
      description: string,
    }
  },
  is_auto_generated?: boolean,
  text?: string,
  actor?: string,
  source_name?: string,
  transfer_events?: Event[],
}

export interface Communication {
  type: string,
  date: string,
  is_property_communication?: boolean,
  object: CommunicationObject,
}

interface GetCommunicationsAction {
  type: ActionType.GET_COMMUNICATIONS_REQUEST | ActionType.GET_COMMUNICATIONS_SUCCESS | ActionType.GET_COMMUNICATIONS_FAILURE;

  result: {
    data: { results: Communication[]; count: number };
  };
  objectId: number,
  error: {
    response: { status: string };
  };
}

interface ResetReloadStateAction {
  type: ActionType.RESET_RELOAD_STATE;
}

interface ResetCommunicationsStateAction {
  type: ActionType.RESET_COMMUNICATIONS;
}

export type Action =
  | PromiseActionType
  | GetCommunicationsAction
  | ResetReloadStateAction
  | ResetCommunicationsStateAction
  | LeadAction
  | PusherAction;
