import produce from 'immer';
import { sortBy } from 'lodash';
import { CommonStateType } from 'dwell/store/types';
import { LeaseDocumentProps, LeaseDocumentState } from 'leasing/store/lease_document/action-types';
import { selectBusinessHours } from 'dwell/store/business_hours/reducers';
import { selectProperty } from 'dwell/store/property/reducers';
import { selectActiveNotice } from 'leasing/store/notice/reducers';
import { selectAddendaFields, selectCleaningChargesItems, selectLeaseDefault, selectPropertyPolicy, selectUtilityPolicy } from 'leasing/store/settings/reducers';
import {
  selectDepositAgreementDocument,
  selectLeaseAgreementDocument,
  selectNotice,
  selectAllLeaseDocuments,
  selectCreditBuilderAddendum,
  selectExistingResidentCreditBuilderAddendum,
  selectRoommateAddendum,
} from 'leasing/store/lease_document/reducers';
import { Action, ActionType, LeaseDetailsProps, ResidentGroupProps, LeaseActionsProps } from './action-types';

export interface ResidentState extends CommonStateType {
  leaseDetails: LeaseDetailsProps,
  renewLease: LeaseDetailsProps,
  isRenewLoaded: boolean,
}

const residentDefault = {
  floor_plan: null,
  unit: null,
  base_rent: null,
  lease_term: null,
  type: null,
  lease_start_date: null,
  lease_end_date: null,
  move_in_date: null,
  move_out_date: null,
  deposit_agreement_signed_date: null,
  lease_signed_date: null,
  building_number: null,
  security_deposit_type: null,
  security_deposit: null,
  deposit_alternative_fee: null,
  e_premium_payment_methods: [],
  pets: [],
  garages: [],
  storages: [],
  parking: [],
  other_rent_items: [],
  gate_keys: [],
  application_fee: null,
  non_refundable_fee: null,
  amount_already_paid: null,
  status: null,
  status_update_date: null,
  utilities: null,
  electric_account_number: null,
  gas_account_number: null,
  insurer_name: null,
  insurance_policy_number: null,
  insurance_liability_amount: null,
  insurance_effective_date: null,
  deposit_renters_insurance: null,
  valet_waste: null,
  mailbox_number: null,
  construction_delivery_date: null,
  balance_due_payment_methods: [],
  applicant_is_employee: false,
  employee_discount: null,
  disclosed_history_of_bedbugs: false,
  bedbug_description: null,
  deposit_agreement_comment: null,
  special_provisions: null,
  resident_group: [],
  paid_on_date: null,
  concessions: 0,
  application_completed_date: null,
  payment_method: null,
  addenda: [],
  deposit_agreement_addenda: [],
  bicycle_storage_assigned_floor: null,
  bicycle_description: null,
  ach_account_type: null,
  ach_depository_name: null,
  ach_depository_branch: null,
  ach_depository_city: null,
  ach_depository_state: null,
  ach_depository_zip_code: null,
  ach_routing_number: null,
  ach_account_number: null,
  vehicles: [],
};

export const initialState: ResidentState = {
  isSubmitting: false,
  isLoaded: false,
  isRenewLoaded: false,
  leaseDetails: residentDefault as LeaseDetailsProps,
  renewLease: residentDefault as LeaseDetailsProps,
};

export const selectLeaseDetails = (state: { leaseDetails: ResidentState }): LeaseDetailsProps => state.leaseDetails.leaseDetails;
export const selectRenewLease = (state: { leaseDetails: ResidentState }): LeaseDetailsProps => state.leaseDetails.renewLease;
export const selectLeaseIsLoaded = (state: { leaseDetails: ResidentState }): boolean => state.leaseDetails.isLoaded;
export const selectRenewLeaseIsLoaded = (state: { leaseDetails: ResidentState }): boolean => state.leaseDetails.isRenewLoaded;

export const selectResidents = (state: { leaseDetails: ResidentState }): ResidentGroupProps[] => {
  const sortOrder = {
    PRIMARY_APPLICANT: 1,
    CO_APPLICANT: 2,
    GUARANTOR: 3,
    DEPENDENT: 4,
  };
  return sortBy((state.leaseDetails.leaseDetails.resident_group || []), item => sortOrder[item.type]);
};
export const selectCurrentResidents = (state: { leaseDetails: ResidentState }): ResidentGroupProps[] => selectResidents(state).filter(r => !['LOST', 'FORMER'].includes(r.status) && r.type !== 'DEPENDENT');

export const selectLeaseAddendumDocuments = (state: { leaseDetails: ResidentState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.leaseDetails.leaseDetails.addenda || []).includes(i.id));
export const selectDepositAddendumDocuments = (state: { leaseDetails: ResidentState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.leaseDetails.leaseDetails.deposit_agreement_addenda || []).includes(i.id));

export const selectRenewLeaseAddendumDocuments = (state: { leaseDetails: ResidentState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.leaseDetails.renewLease.addenda || []).includes(i.id));
export const selectRenewDepositAddendumDocuments = (state: { leaseDetails: ResidentState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.leaseDetails.renewLease.deposit_agreement_addenda || []).includes(i.id));

export const selectLeaseActions = (type: string) => (state: { leaseDetails: ResidentState }): LeaseActionsProps =>
  state.leaseDetails.leaseDetails.lease_actions.find(i => i.type === type);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLeasingInfo = (lease: LeaseDetailsProps, parentLease: LeaseDetailsProps = null) => {
  const {
    unit, pets, garages, storages, vehicles, parking, other_rent_items: otherRentItems,
    payment_received: payment, concessions, gate_keys: gateKeys,
  } = lease;
  const removedApplicants = (parentLease || lease).resident_group.filter(el => el.is_pending_remove);
  const removedIds = removedApplicants.map(el => el.id);
  const currentApplicants = (parentLease || lease).resident_group
    .filter(el => !['LOST', 'FORMER'].includes(el.status))
    .filter(el => el?.activities.map(i => (i.date ? i.step : null)).includes('SUBMIT_APPLICATION') || el.is_get_from_resman);

  return {
    applicants: currentApplicants.filter(user => ['PRIMARY_APPLICANT', 'CO_APPLICANT'].includes(user.type)),
    guarantors: currentApplicants.filter(user => user.type === 'GUARANTOR' && !user.is_pending_remove),
    removedApplicants,
    unitName: unit,
    lease,
    payment: payment || {},
    pets: removedIds.length ? pets.filter(el => !removedIds.includes(el.user)) : pets,
    garages,
    storages,
    vehicles: removedIds.length ? vehicles.filter(el => !removedIds.includes(el.user)) : vehicles,
    parking,
    otherRentItems,
    concessions,
    gateKeys,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectResidentDetails = state => ({
  ...getLeasingInfo(state.leaseDetails.leaseDetails),
  addendaFields: selectAddendaFields(state),
  leaseDefault: selectLeaseDefault(state),
  propertyPolicy: selectPropertyPolicy(state),
  utilityPolicies: selectUtilityPolicy(state),
  businessHours: selectBusinessHours(state),
  property: selectProperty(state),
  leaseAddendumDocuments: selectLeaseAddendumDocuments(state),
  depositAddendumDocuments: selectDepositAddendumDocuments(state),
  leaseAgreementDocument: selectLeaseAgreementDocument(state),
  depositAgreementDocument: selectDepositAgreementDocument(state),
  allLeaseDocuments: selectAllLeaseDocuments(state),
  cleaningCharges: selectCleaningChargesItems(state),
  notice: selectActiveNotice(state),
  noticeDocument: selectNotice(state),
  creditBuilderAddendum: selectCreditBuilderAddendum(state),
  existingResidentCreditBuilderAddendum: selectExistingResidentCreditBuilderAddendum(state),
  roommateAddendum: selectRoommateAddendum(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectResidentDetailsForRenew = state => ({
  ...getLeasingInfo(state.leaseDetails.renewLease, state.leaseDetails.leaseDetails),
  addendaFields: selectAddendaFields(state),
  leaseDefault: selectLeaseDefault(state),
  propertyPolicy: selectPropertyPolicy(state),
  utilityPolicies: selectUtilityPolicy(state),
  businessHours: selectBusinessHours(state),
  property: selectProperty(state),
  leaseAddendumDocuments: selectRenewLeaseAddendumDocuments(state),
  depositAddendumDocuments: selectRenewDepositAddendumDocuments(state),
  leaseAgreementDocument: selectLeaseAgreementDocument(state),
  depositAgreementDocument: selectDepositAgreementDocument(state),
  cleaningCharges: selectCleaningChargesItems(state),
});

export default produce((state: ResidentState = initialState, action: Action): ResidentState => {
  switch (action.type) {
    case ActionType.GET_LEASE_DETAILS_REQUEST:
      state.isLoaded = false;
      break;

    case ActionType.GET_RENEW_LEASE_DETAILS_REQUEST:
      state.isRenewLoaded = false;
      break;

    case ActionType.GET_LEASE_DETAILS_SUCCESS:
    case ActionType.UPDATE_LEASE_RESIDENT_SUCCESS:
      state.isLoaded = true;
      state.leaseDetails = action.result.data;
      break;

    case ActionType.GET_RENEW_LEASE_DETAILS_SUCCESS:
      state.renewLease = action.result.data;
      state.isRenewLoaded = true;
      break;
  }

  return state;
});
