import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { Action, ActionType, QuestionFormData, FormStructure, AnswerAPIPayload } from './action-types';
import { CallBackFunction, OffsetPaginationParamTypes } from '../types';

export default {
  minimizeAgentQuestionWindow: (isMinimized: boolean): Action => ({
    type: ActionType.MINIMIZE_AGENT_QUESTION_WINDOW,
    isMinimized,
  }),

  closeAgentQuestionWindow: (closedWindow: boolean): Action => ({
    type: ActionType.CLOSE_AGENT_QUESTION_WINDOW,
    closedWindow,
  }),

  manageAgentQuestion: (agentQuestionEnabled: boolean): Action => ({
    type: ActionType.ENABLE_AGENT_QUESTION,
    agentQuestionEnabled,
  }),

  setChatState: (chatState: string): Action => ({
    type: ActionType.SET_CHAT_STATE,
    chatState,
  }),

  setFormData: (formData: QuestionFormData): Action => ({
    type: ActionType.SET_FORM_DATA,
    formData,
  }),

  updatePropertyToGetQuestions: (user: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_REQUEST,
        ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_SUCCESS,
        ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.SET_PROPERTY_TO_GET_QUESTIONS, user)),
    },
  }),

  setGlobalForm: (form: FormStructure[]): Action => ({
    type: ActionType.SET_GLOBAL_FORM,
    form,
  }),

  processAnswers: (data: AnswerAPIPayload, successCB: CallBackFunction = () => undefined): Action => ({
    CALL_API: {
      types: [
        ActionType.PROCESS_ANSWERS_REQUEST,
        ActionType.PROCESS_ANSWERS_SUCCESS,
        ActionType.PROCESS_ANSWERS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.PROCESS_QUESTIONS_ANSWERS, data),
      successCB,
    },
  }),

  pullQuestion: (aq?: string): Action => ({
    CALL_API: {
      types: [
        ActionType.PULL_QUESTION_REQUEST,
        ActionType.PULL_QUESTION_SUCCESS,
        ActionType.PULL_QUESTION_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PULL_QUESTION, { params: { aq } }),
    },
  }),

  getConversations: ({ params }: { params: OffsetPaginationParamTypes }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CONVERSATIONS_REQUEST,
        ActionType.GET_CONVERSATIONS_SUCCESS,
        ActionType.GET_CONVERSATIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.AGENT_CONVERSATIONS, { params }),
    },
  }),

  getQuestionOptions: (params: { question: number }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_QUESTION_OPTIONS_REQUEST,
        ActionType.GET_QUESTION_OPTIONS_SUCCESS,
        ActionType.GET_QUESTION_OPTIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.QUESTION_OPTION, { params }),
    },
  }),

  setIsFormDisplayed: (formState: boolean): Action => ({
    type: ActionType.SET_IS_FORM_DISPLAYED,
    formState,
  }),

  setIsConfirmationFormDisplayed: (confirmationFormState: boolean): Action => ({
    type: ActionType.SET_IS_CONFIRMATION_FORM_DISPLAYED,
    confirmationFormState,
  }),

  setCurrentIdx: (idx: number): Action => ({
    type: ActionType.SET_CURRENT_IDX,
    idx,
  }),

  resetConversationDiff: (): Action => ({
    type: ActionType.RESET_CONVERSATION_DIFF,
  }),

  setIsFirstOpen: (): Action => ({
    type: ActionType.SET_IS_FIRST_OPEN,
  }),
};
