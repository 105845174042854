import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Media } from 'reactstrap';
import { isEmpty } from 'lodash';
import actions from 'dwell/actions/index';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { platformTypes, roleTypes } from 'site/constants';
import { IS_AGENT_QUESTION_TIMER_SETTED } from 'dwell/constants';
import { ChatItemHeader, AgentQuestionItem, DarkBlueAvatar } from 'dwell/views/chat/single_chat/window/styles';
import { ChatHeaderLink, AvatarImg } from 'dwell/views/chat/single_chat/contact/styles';
import { useQuery } from 'src/hooks/useQuery';
import ChatBody from './body';
import { randomIntFromInterval } from './utils';

const AgentChat: FC = () => {
  const [enableAQ, setEnableAQ] = useState(false);
  const agentQuestion = useSelector(state => state.agentQuestion);
  const { isWindowMinimized, propertyToGetQuestions, agentQuestionEnabled, isWindowClosed, isFirstOpenWindow } = agentQuestion;
  const currentUser = useSelector(state => state.user.currentUser);
  const selectedProperties = useSelector(selectSelectedProperties);
  const dispatch = useDispatch();
  const { minimizeAgentQuestionWindow, closeAgentQuestionWindow, pullQuestion, manageAgentQuestion, setIsFirstOpen } = actions.agentQuestion;
  const query = useQuery();
  const forcedAgentQuestion = query.get('aq');

  const allowedAdmins = [roleTypes.PROPERTY_ADMIN, roleTypes.VIRTUAL_ADMIN, roleTypes.GENERIC_ADMIN];
  const propertiesAllowedToChat = selectedProperties.some(p => !platformTypes.isSiteOnly(p.platforms));
  const isHobbesAgentChatEnabled = currentUser?.hobbes_agent_chat_enabled;

  useEffect(() => {
    if (!isEmpty(propertyToGetQuestions) && isHobbesAgentChatEnabled) {
      dispatch(pullQuestion(forcedAgentQuestion));
    }
  }, [propertyToGetQuestions, forcedAgentQuestion, isHobbesAgentChatEnabled, currentUser]);

  useEffect(() => {
    const isTimerSet = localStorage.getItem(IS_AGENT_QUESTION_TIMER_SETTED);
    if (!isTimerSet) {
      const rndInt = randomIntFromInterval(5, 30);
      setTimeout(() => {
        setEnableAQ(true);
        localStorage.setItem(IS_AGENT_QUESTION_TIMER_SETTED, 'true');
      }, 60000 * rndInt);
    } else {
      setEnableAQ(true);
    }
  }, []);

  useEffect(() => {
    if ((allowedAdmins.includes(currentUser.role)
      && propertiesAllowedToChat
      && enableAQ
      && currentUser.is_team_account
      && !isEmpty(propertyToGetQuestions)
      && currentUser.is_agent_chat_available_today
      && isHobbesAgentChatEnabled) || forcedAgentQuestion
    ) {
      dispatch(manageAgentQuestion(true));
    }
  }, [currentUser, enableAQ, propertyToGetQuestions, propertiesAllowedToChat, forcedAgentQuestion]);

  useEffect(() => {
    if (!isEmpty(currentUser) && !currentUser.is_agent_chat_available_today) {
      const isTimerSetted = localStorage.getItem(IS_AGENT_QUESTION_TIMER_SETTED);
      if (isTimerSetted) localStorage.removeItem(IS_AGENT_QUESTION_TIMER_SETTED);
    }
  }, [currentUser]);

  useEffect(() => {
    if (propertyToGetQuestions && agentQuestionEnabled && isWindowClosed && forcedAgentQuestion && !isFirstOpenWindow) {
      dispatch(setIsFirstOpen());
      dispatch(closeAgentQuestionWindow(false));
    }
  }, [propertyToGetQuestions, agentQuestionEnabled, isWindowClosed, forcedAgentQuestion, isFirstOpenWindow]);

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    const { target } = event;
    if (!(target as HTMLDivElement).classList.contains('ri-close-fill')) {
      dispatch(minimizeAgentQuestionWindow(!isWindowMinimized));
    }
  };

  if (!agentQuestionEnabled || isWindowClosed) {
    return <></>;
  }

  return (
    <div className="d-flex align-items-end flex-row-reverse">
      <AgentQuestionItem isSMS={false} isBlinking={false}>
        <ChatItemHeader overflow={false} isBlinking={false} onClick={(event: React.MouseEvent<HTMLElement>) => clickHandler(event)}>
          <Media>
            <DarkBlueAvatar className="avatar bg-dark online" hideOnlineIcon={false} online>
              <AvatarImg src="https://liftlytics.imgix.net/union-main/img/chatbot.png?auto=format&fit=crop&fm=webp" alt="bot-icon" className="bot" />
            </DarkBlueAvatar>
            <div className="media-body">
              <h6 className="mb-0">Hobbes Bot</h6>
              <span>{propertyToGetQuestions?.name || ''}</span>
            </div>
            <ChatHeaderLink onClick={() => dispatch(closeAgentQuestionWindow(true))}>
              <i className="ri-close-fill" />
            </ChatHeaderLink>
          </Media>
        </ChatItemHeader>
        {isWindowMinimized ? null : <ChatBody isForcedQuestion={Boolean(forcedAgentQuestion)} />}
      </AgentQuestionItem>
    </div>
  );
};

export default AgentChat;
