import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, NoticeProps } from './action-types';

export default {
  createNotice: (leaseId: number, payload: NoticeProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_NOTICE_REQUEST,
        ActionType.CREATE_NOTICE_SUCCESS,
        ActionType.CREATE_NOTICE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.NOTICES, leaseId), payload),
    },
  }),

  deleteActiveNotice: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_ACTIVE_NOTICE_REQUEST,
        ActionType.DELETE_ACTIVE_NOTICE_SUCCESS,
        ActionType.DELETE_ACTIVE_NOTICE_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.NOTICE_VACATE, leaseId)),
    },
  }),

  deleteNotice: (leaseId: number, renewalId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_NOTICE_REQUEST,
        ActionType.DELETE_NOTICE_SUCCESS,
        ActionType.DELETE_NOTICE_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.NOTICES_DETAILS, leaseId, renewalId)),
      payload: renewalId,
    },
  }),

  getNotices: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_NOTICES_REQUEST,
        ActionType.GET_NOTICES_SUCCESS,
        ActionType.GET_NOTICES_FAILURE,
      ],
      promise: client => client.get(build(api.v1.NOTICES, leaseId)),
    },
  }),

  startNoticeProcess: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.START_NOTICE_PROCESS_REQUEST,
        ActionType.START_NOTICE_PROCESS_SUCCESS,
        ActionType.START_NOTICE_PROCESS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.NOTICE_VACATE, leaseId)),
    },
  }),

  updateActiveNotice: (leaseId: number, payload: NoticeProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_ACTIVE_NOTICE_REQUEST,
        ActionType.UPDATE_ACTIVE_NOTICE_SUCCESS,
        ActionType.UPDATE_ACTIVE_NOTICE_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.NOTICE_VACATE, leaseId), payload),
    },
  }),

  updateNotice: (leaseId: number, payload: NoticeProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_NOTICE_REQUEST,
        ActionType.UPDATE_NOTICE_SUCCESS,
        ActionType.UPDATE_NOTICE_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.NOTICES_DETAILS, leaseId, payload.id), payload),
    },
  }),

  collectNoticeDetails: (leaseId: number, data: { skip: boolean }): Action => ({
    CALL_API: {
      types: [
        ActionType.COLLECT_NOTICE_DETAILS_REQUEST,
        ActionType.COLLECT_NOTICE_DETAILS_SUCCESS,
        ActionType.COLLECT_NOTICE_DETAILS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.COLLECT_NOTICE_DETAILS, leaseId), data),
    },
  }),
};
