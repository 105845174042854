import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import actions from 'dwell/actions/index';
import { selectSelectedProperties, selectProperties } from 'dwell/store/property/reducers';
import { selectAvailableProperties, selectPreSelectedProperties, selectPostSelectedProperties, selectPropertyKeyword } from 'dwell/store/properties_selector/reducers';
import { CustomerProps } from 'site/store/customer/action-types';

import SelectedPropertiesList from './_selected_properties_list';
import SelectedPropertiesLabel from './_selected_properties_label';
import CustomerSection from './_customer_section';
import AvailablePropertyItem from './_available_property_item';
import ApplyUpdatesButton from './_apply_updates_button';

import { PropertyMenuGroup, PmLabel, PmLabelLabel, PmLabelAnchor, NewPropertyMenuSearch, FormPropertySearch, PropertyMenuBody, PropertyItem } from './styles';

const SELECT_ALL = 1;
const DESELECT_ALL = -1;

interface Props {
  toggleTab: boolean;
  setToggleTab: React.Dispatch<React.SetStateAction<boolean>>;
  propertiesLoading: boolean;
  currentCustomer: CustomerProps;
}

const PropertyMenu: FC<Props> = ({ toggleTab, setToggleTab, propertiesLoading, currentCustomer }) => {
  const dispatch = useDispatch();
  const selectedProperties = useSelector(selectSelectedProperties);
  const properties = useSelector(selectAvailableProperties);
  const allUserProperties = useSelector(selectProperties);
  const preSelection = useSelector(selectPreSelectedProperties);
  const postSelection = useSelector(selectPostSelectedProperties);
  const keyword = useSelector(selectPropertyKeyword);
  const { setShowApplyButton, setAvailableProperties, setPreSelectedProperties, setPostSelectedProperties, setPropertyKeyword } = actions.propertiesSelector;

  const [onFocusState, setOnFocus] = useState<boolean>(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState<boolean>(false);

  const properties_ids = properties.map(property => property.id);
  const preSelection_ids = preSelection.map(property => property.id);
  const allPropertiesPreSelected = properties_ids.every(propertyId => preSelection_ids.includes(propertyId));

  useEffect(() => {
    dispatch(setPostSelectedProperties(selectedProperties));
  }, [selectedProperties]);

  const selectAllHandler = (state: number) => {
    if (state === SELECT_ALL) {
      dispatch(setPreSelectedProperties(properties));
      dispatch(setShowApplyButton(true));
    } else if (state === DESELECT_ALL) {
      dispatch(setPreSelectedProperties([]));
      dispatch(setShowApplyButton(false));
    }
  };

  const onBlurHandler = () => {
    setOnFocus(false);
  };

  const onSearch = ({ target: { value } }) => {
    setIsSearchEmpty(false);
    const ids = selectedProperties.map(p => p.id);
    if (currentCustomer.properties.length === 0 && currentCustomer.customer_name === 'All Customers') {
      let filteredProperties = allUserProperties.filter(({ id }) => !ids.includes(id));
      if (value) {
        filteredProperties = filteredProperties.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
        if (filteredProperties.length === 0) {
          setIsSearchEmpty(true);
        }
      }
      dispatch(setAvailableProperties(filteredProperties));
    } else if (currentCustomer.properties.length === 0 && currentCustomer.customer_name !== 'All Customers') {
      if (value) {
        setIsSearchEmpty(true);
      }
      dispatch(setAvailableProperties([]));
    } else {
      let filteredProperties = allUserProperties.filter(({ id }) => !ids.includes(id));
      filteredProperties = filteredProperties.filter(({ id }) => currentCustomer.properties.includes(id));
      if (value) {
        filteredProperties = filteredProperties.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
        if (filteredProperties.length === 0) {
          setIsSearchEmpty(true);
        }
      }
      dispatch(setAvailableProperties(filteredProperties));
    }
    dispatch(setPropertyKeyword(value));
  };

  useEffect(() => {
    if (!keyword) {
      onSearch({ target: { value: '' } });
    }
  }, [keyword]);

  const getSelectDiselect = () => {
    if (!properties_ids.length && !preSelection_ids.length) {
      return null;
    }
    if (allPropertiesPreSelected) {
      return <PmLabelAnchor onClick={() => selectAllHandler(-1)}>Deselect All</PmLabelAnchor>;
    }
    return <PmLabelAnchor onClick={() => selectAllHandler(1)}>Select All</PmLabelAnchor>;
  };

  return (
    <PropertyMenuGroup step={toggleTab ? -1 : 0}>
      <CustomerSection setToggleTab={setToggleTab} currentCustomerName={currentCustomer.customer_name} />
      <SelectedPropertiesLabel allSelected={selectedProperties.length === postSelection.length} />
      <SelectedPropertiesList />
      <PmLabel>
        <PmLabelLabel>Select Properties</PmLabelLabel>
        {getSelectDiselect()}
      </PmLabel>
      <NewPropertyMenuSearch className={onFocusState && 'onfocus'}>
        <i data-feather="search" />
        <FormPropertySearch
          className="form-control"
          onKeyDown={(e) => {
            if (e.keyCode === 9) e.preventDefault();
          }}
          placeholder="Search property"
          onFocus={() => setOnFocus(true)}
          onBlur={onBlurHandler}
          name="search"
          value={keyword ?? ''}
          onChange={onSearch}
        />
      </NewPropertyMenuSearch>
      <PropertyMenuBody>
        {!propertiesLoading && !isSearchEmpty && properties.map(property => <AvailablePropertyItem key={property.id} property={property} />)}
        {propertiesLoading && !isSearchEmpty && (
          <PropertyItem contentCenter>
            <Spinner size="md" />
          </PropertyItem>
        )}
        {isSearchEmpty && <PropertyItem contentCenter>Sorry, but there's no such property</PropertyItem>}
        <ApplyUpdatesButton />
      </PropertyMenuBody>
    </PropertyMenuGroup>
  );
};

export default PropertyMenu;
