import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import {
  Action,
  ActionType,
  AddendaFieldProps,
  CleaningChargeItem,
  CleaningChargesProps,
  DurationPricingProps,
  LeaseDefaultProps,
  OtherFeeItemTemplateProps,
  OtherRentItemTemplateProps,
  PropertyPolicyProps,
  RentableItemProps,
  SettingState,
  UtilityPoliciesProps,
} from './action-types';

export const initialState: SettingState = {
  errorMessage: null,
  isSubmitting: false,
  addendaFields: {} as AddendaFieldProps,
  cleaningCharges: {} as CleaningChargesProps,
  durationPricing: {} as DurationPricingProps,
  leaseDefault: {} as LeaseDefaultProps,
  otherFeeItems: [],
  otherRentItems: [],
  propertyPolicy: {} as PropertyPolicyProps,
  rentableItems: [],
  utilityPolicies: {} as UtilityPoliciesProps,
};

export const selectAddendaFields = (state: { settings: SettingState }): AddendaFieldProps => state.settings.addendaFields;
export const selectLeaseDefault = (state: { settings: SettingState }): LeaseDefaultProps => state.settings.leaseDefault;
export const selectOtherFeeItems = (state: { settings: SettingState }): OtherFeeItemTemplateProps[] => state.settings.otherFeeItems;
export const selectOtherRentItems = (state: { settings: SettingState }): OtherRentItemTemplateProps[] => state.settings.otherRentItems;
export const selectPropertyPolicy = (state: { settings: SettingState }): PropertyPolicyProps => state.settings.propertyPolicy;
export const selectRentableItems = (state: { settings: SettingState }): RentableItemProps[] => state.settings.rentableItems;
export const selectUtilityPolicy = (state: { settings: SettingState }): UtilityPoliciesProps => state.settings.utilityPolicies;
export const selectCleaningChargesItems = (state: { settings: SettingState }): CleaningChargesProps => state.settings.cleaningCharges;

const prepareCleaningChargesItems = (data: CleaningChargeItem[]): CleaningChargesProps => {
  const cleaningCharges = {
    KITCHEN: [],
    BATHROOM: [],
    AREA: [],
    REPAIR: [],
    PAINTING: [],
  };

  data.forEach((item) => {
    if (cleaningCharges[item.category]) cleaningCharges[item.category].push(item);
  });

  return cleaningCharges;
};

export default produce((state: SettingState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS:
      state.otherFeeItems = [...state.otherFeeItems, action.result.data];
      break;

    case ActionType.CREATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS:
      state.otherRentItems = [...state.otherRentItems, action.result.data];
      break;

    case ActionType.CREATE_RENTABLE_ITEM_SUCCESS:
    case ActionType.UPDATE_RENTABLE_ITEM_SUCCESS:
      state.rentableItems = [action.result.data, ...state.rentableItems];
      break;

    case ActionType.DELETE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS:
      state.otherFeeItems = state.otherFeeItems.filter(i => i.id !== action.payload);
      break;

    case ActionType.DELETE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS:
      state.otherRentItems = state.otherRentItems.filter(i => i.id !== action.payload);
      break;

    case ActionType.DELETE_RENTABLE_ITEM_SUCCESS:
      state.rentableItems = state.rentableItems.filter(i => i.id !== action.result.data.id);
      break;

    case ActionType.GET_ADDENDA_FIELDS_SUCCESS:
      state.addendaFields = action.result.data;
      break;

    case ActionType.GET_PROPERTY_DETAILS_SUCCESS:
      state.durationPricing = action.result.data.duration_pricing;
      state.leaseDefault = action.result.data.lease_default;
      state.propertyPolicy = action.result.data.polices;
      state.rentableItems = action.result.data.rentable_items;
      state.utilityPolicies = action.result.data.utility_policies || {};
      state.cleaningCharges = prepareCleaningChargesItems(action.result.data.cleaning_charges || []);
      break;

    case ActionType.GET_OTHER_FEE_ITEM_TEMPLATE_SUCCESS:
      state.otherFeeItems = action.result.data.results;
      break;

    case ActionType.GET_OTHER_RENT_ITEM_TEMPLATE_SUCCESS:
      state.otherRentItems = action.result.data.results;
      break;

    case ActionType.GET_RENTABLE_ITEMS_SUCCESS:
      state.rentableItems = action.result.data.results;
      break;

    case ActionType.SAVE_ADDENDA_FIELDS_SUCCESS:
      state.addendaFields = action.result.data;
      break;

    case ActionType.SAVE_CLEANING_CHARGES_SUCCESS:
      state.cleaningCharges = prepareCleaningChargesItems(action.result.data || []);
      break;

    case ActionType.SAVE_DURATION_PRICING_SUCCESS:
      state.durationPricing = action.result.data;
      break;

    case ActionType.SAVE_LEASE_DEFAULT_SUCCESS:
      state.leaseDefault = action.result.data;
      break;

    case ActionType.SAVE_PROPERTY_POLICY_SUCCESS:
      state.propertyPolicy = action.result.data;
      break;

    case ActionType.SAVE_UTILITY_POLICY_SUCCESS:
      state.utilityPolicies = action.result.data;
      break;

    case ActionType.UPDATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS:
      state.otherFeeItems = unionBy([action.result.data], state.otherFeeItems, 'id');
      break;

    case ActionType.UPDATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS:
      state.otherRentItems = unionBy([action.result.data], state.otherRentItems, 'id');
      break;
  }

  return state;
});
