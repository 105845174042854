/* eslint-disable @typescript-eslint/no-explicit-any */
class Carousel {
  carousel: HTMLElement;
  cards: Element[];
  dotsContainer: HTMLDivElement;
  prevBtn: HTMLButtonElement;
  nextBtn: HTMLButtonElement;
  width: number;
  gap: number;
  currentSlide: number;
  dragging: boolean;
  startX: number;
  startTranslateX: number;
  scrollDelta: number;
  scrollThreshold: number;
  constructor(container: HTMLDivElement, width = 200, gap = 8) {
    this.carousel = container.querySelector('.hobbes_carousel');
    this.cards = Array.from(container.getElementsByClassName('hobbes_card'));
    this.dotsContainer = container.querySelector('.hobbes_dots');
    this.prevBtn = container.querySelector('.hobbes_prevBtn');
    this.nextBtn = container.querySelector('.hobbes_nextBtn');
    this.width = width;
    this.gap = gap;

    this.currentSlide = 0;
    this.dragging = false;
    this.startX = 0;
    this.startTranslateX = 0;
    this.scrollDelta = 0; // Add this line to store the cumulative scroll delta
    this.scrollThreshold = 100; // Change this to adjust the sensitivity

    this.init();
  }

  init(): void {
    if (!this.carousel) return;

    this.cards.forEach((_, index) => {
      const dot = document.createElement('div');
      dot.classList.add('hobbes_dot');
      dot.addEventListener('click', () => {
        this.currentSlide = index;
        this.updateCarousel();
      });
      this.dotsContainer.appendChild(dot);
    });

    this.prevBtn.addEventListener('click', () => {
      this.currentSlide = Math.max(this.currentSlide - 1, 0);
      this.updateCarousel();
    });

    this.nextBtn.addEventListener('click', () => {
      this.currentSlide = Math.min(this.currentSlide + 1, this.cards.length - 1);
      this.updateCarousel();
    });

    this.carousel.addEventListener('mousedown', this.onMouseDown.bind(this));
    this.carousel.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.carousel.addEventListener('mouseup', this.onMouseUp.bind(this));
    this.carousel.addEventListener('mouseleave', this.onMouseUp.bind(this));
    this.carousel.addEventListener('touchstart', this.onMouseDown.bind(this));
    this.carousel.addEventListener('touchmove', this.onMouseMove.bind(this));
    this.carousel.addEventListener('touchend', this.onMouseUp.bind(this));
    this.carousel.addEventListener('wheel', this.onWheelMove.bind(this));

    // Discomment this line to enable auto-sliding
    // this.slideInterval = setInterval(this.moveSlides.bind(this), 3000);

    this.updateCarousel();
  }

  updateCarousel(): void {
    this.carousel.style.transform = `translateX(-${this.currentSlide * (this.width + this.gap)}px)`;

    this.dotsContainer.querySelectorAll('.hobbes_dot').forEach((dot, index) => {
      // eslint-disable-next-line no-param-reassign
      if (index === this.currentSlide) {
        dot.classList.add('dot-active');
      } else {
        dot.classList.remove('dot-active');
      }
    });

    this.prevBtn.style.visibility = this.currentSlide === 0 ? 'hidden' : 'visible';
    this.nextBtn.style.visibility = this.currentSlide === this.cards.length - 1 ? 'hidden' : 'visible';
  }

  // Discomment this function to enable auto-sliding
  // moveSlides() {
  //   this.currentSlide = (this.currentSlide + 1) % this.cards.length;
  //   this.updateCarousel();
  // }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onMouseDown(event: any): void {
    this.dragging = true;
    this.startX = event.clientX || event.touches[0].clientX;
    this.startTranslateX = this.currentSlide * (this.width + this.gap);
    this.carousel.style.transition = 'none';
    this.carousel.style.cursor = 'grabbing';
    this.carousel.style.userSelect = 'none';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onMouseMove(event: any): void {
    if (!this.dragging) return;
    const x = event.clientX || event.touches[0].clientX;
    const dx = x - this.startX;
    const translateX = this.startTranslateX - dx;
    this.currentSlide = Math.round(translateX / (this.width + this.gap));
    this.currentSlide = Math.max(0, Math.min(this.currentSlide, this.cards.length - 1));
    this.carousel.style.transform = `translateX(-${translateX}px)`;
  }

  onMouseUp(): void {
    this.dragging = false;
    this.carousel.style.transition = '';
    this.updateCarousel();
    this.carousel.style.cursor = 'grab';
    this.carousel.style.userSelect = '';
  }

  onWheelMove(event: WheelEvent): void {
    const scrollAmount = Math.abs(event.deltaY) + Math.abs(event.deltaX);

    // Check for trackpad
    if (event.deltaMode === 0) {
      this.scrollDelta += scrollAmount;

      if (this.scrollDelta >= this.scrollThreshold) {
        this.changeSlide(event);
        this.scrollDelta = 0; // Reset the scroll delta after changing the slide
      }
    } else {
      // For mouse wheel
      this.changeSlide(event);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  changeSlide(event: WheelEvent): void {
    if (event.deltaY > 0 || event.deltaX < 0) {
      this.currentSlide = Math.min(this.currentSlide + 1, this.cards.length - 1);
    } else {
      this.currentSlide = Math.max(this.currentSlide - 1, 0);
    }
    this.updateCarousel();
  }
}

export default Carousel;
