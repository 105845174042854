import { Dropdown, DropdownToggle } from 'reactstrap';

import { SelectMenu, SelectItem } from 'styles/common';
import styled from 'styled-components';

export const CustomDropdown = styled(Dropdown)`
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  flex: 1;
  margin-right: 7px;
  border-radius: 3px;
  height: 30px;
`;

export const CustomSelection = styled(DropdownToggle)`
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #233457;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%2315274d' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px !important;
  border: 1px solid #d9def0;
  border-radius: 6px;
  appearance: none;
  &:focus {
    border-color: #7cb2fe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 104, 250, 0.25);
  }
`;

export const ObjectSelectButton = styled(CustomSelection)`
  flex: 1;
  margin-right: 7px;
  border-radius: 3px;
  padding: 3px 8px;
  height: 30px;
  font-size: 13px;
  border-color: #c1c8de;
`;

export const TimeSelectMenu = styled(SelectMenu)`
  width: 100%;
  border-radius: 4px;
`;

export const ObjectSelectItem = styled(SelectItem)`
  &.dropdown-item {
    width: unset !important;
  }
`;
