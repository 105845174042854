import styled, { css, keyframes } from 'styled-components';
import { ButtonDropdown } from 'reactstrap';

import { PrimaryButton } from 'styles/common';
import { ChatList } from 'dwell/views/chat/single_chat/contact/styles';

export const AvailableAgentsContainer = styled(ChatList)`
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.colorbg02};
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const Agent = styled.div
  .attrs({ className: 'ci-team-item' })`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;

  &:first-child { padding-top: 0; }
  &:last-child { padding-bottom: 0; }

  + .ci-team-item {
    border-top: 1px solid ${props => props.theme.colors.colorbg01};
  }

  .avatar { margin-right: 10px; }
`;

export const AgentBody = styled.div`
  flex: 1;
  h6 {
    margin-bottom: 1px;
    font-weight: 600;
    color: $dark;
  }

  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${props => props.theme.colors.gray};

    span + span::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: ${props => props.theme.colors.gray};
      border-radius: 100%;
      margin: 0 4px 2px;
    }
  }
`;

export const Radio = styled.div`
  width: 16px;
  height: 16px;
  border: 1.5px solid ${props => props.theme.colors.colorbg03};
  border-radius: 100%;
  position: relative;

  &::before {
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.colorui01};
    position: absolute;
    top: 2px;
    left: 2px;
    display: none;
  }

  ${props =>
    props.selected &&
    css`
      {
        border-color: ${props.theme.colors.colorui01};
        &::before { display: block; }
      }
    `}
`;

export const TransferButton = styled(PrimaryButton)`
  width: 100%;
  justify-content: center;
  ${props => props.disabled && css`
    background-color: ${props.theme.colors.colortx03} !important;
    cursor: default !important;
    border-color: ${props.theme.colors.colortx03} !important;
  `}
`;

const rotation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const TransferLoading = styled.div`
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    font-size: 48px;
    color: ${props => props.theme.colors.colorui01};
    margin-bottom: 10px;
    animation: ${rotation} 2s infinite linear;
  }

  p { margin-bottom: 0; }
`;

export const TransferSuccess = styled.div`
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    font-size: 48px;
    color: ${props => props.theme.colors.success};
    margin-top: auto;
    margin-bottom: 10px;
  }

  p {
    padding: 0 20px;
    margin-bottom: 0;
    text-align: center;
  }

  .btn {
    align-self: stretch;
    justify-content: center;
    margin-top: auto;
  }
`;

export const TransferReasonDropdown = styled(ButtonDropdown)`
  width: 100%;
  margin-top: auto;
  .btn-secondary {
    background-color: white;
    border-radius: 5px !important;
    &:focus, &:active, &:hover {
      background-color: ${props => props.theme.colors.white} !important;
      border-color: ${props => props.theme.colors.colorbg03} !important;
      box-shadow: 0 1px 1px rgba(225,230,247,0.25),
        0 2px 2px rgba(225,230,247,0.2),
        0 4px 4px rgba(225,230,247,0.15),
        0 8px 8px rgba(225,230,247,0.1),
        0 16px 16px rgba(225,230,247,0.05);
    }
  }
  .dropdown-item {
    display: inline;
    padding: 7px 10px;
    border-radius: 3px;
    outline: none;
    &:hover {
      background-color: #f0f2f9;
      color: #4a5e8a;
    }
  }
`;
