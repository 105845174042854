import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { Call as TwilioCall } from '@twilio/voice-sdk';

import { LeadResidentSearch, SearchedObjectType } from 'dwell/store/pipeline/action-types';
import { CallWindowBodyComponent } from 'dwell/store/voice/action-types';
import { UserProps } from 'dwell/store/user/action-types';
import {
  selectCallNotes,
  selectDialedProperty,
  selectTransferEventId,
  selectTransferReason,
} from 'dwell/store/voice/reducers';
import callModelAction from 'dwell/store/call/action-creators';
import voiceActions from 'dwell/store/voice/action-creator';
import actions from 'dwell/actions';
import { dialFormat, formatPhoneNumber, getCallParams } from 'dwell/views/calls/communications/voice_centre/utils';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { ProspectSourceTypes } from 'dwell/store/property_profile/action-types';
import { selectProperty } from 'dwell/store/property/reducers';
import { CallSummary } from 'dwell/store/call/action-types';
import {
  SaveCallButton,
  OutboundCallFinishDiv,
  OutboundCallFinishButton,
  HangupButton,
  OutboundCallFinishButtonIcon,
  CIBItemDiv,
  CIBBodyDiv,
  NavCircleMini,
  FormSearchDiv,
  ContactList,
  ContactLabel, ContactLink, InputCalledProspect, CallWindowSVG,
} from './styles';

interface EndCallActionButtonProps {
  callFinished: boolean;
  callTransferred: boolean;
  dialedPhone: string;
  handleHangup: () => void;
  leadId: number;
  isDesktopPhone: boolean;
  call: TwilioCall;
  isInboundCall: boolean;
  desktopCallSid: string;
  leasingUserId: number;
  applicationId: number;
  leaseId: number;
  hasConferenceStarted: boolean;
}

interface ResidentLead {
  id: number,
  name: string,
  property: number,
  email: string,
  phone_number: string,
  lease_lead_id?: number,
  secondary_phone_number?: string,
  type: 'lead' | 'resident' | 'currentResident'
}

interface GroupedResidentsLeads {
  [initial: string]: ResidentLead[];
}

const EndCallActionButton: React.FC<EndCallActionButtonProps> = ({
  callFinished,
  callTransferred,
  dialedPhone,
  handleHangup,
  leadId,
  isDesktopPhone,
  call,
  isInboundCall,
  desktopCallSid,
  leaseId,
  applicationId,
  leasingUserId,
  hasConferenceStarted,
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const {
    setCallWindowBody,
    clearInProgressCall,
    clearInProgressCallWithoutNoiseSuppression,
    setDialedPhoneNumber,
    setDialedContact,
  } = voiceActions;
  const { saveCallSummary } = callModelAction;
  const { createLead } = actions.propertyProfile;
  const { updateLeadById } = actions.lead;

  const { residents, leads, current_residents } = useSelector(state => state.pipeline.searchedObjects) as SearchedObjectType;
  const currentUserData = useSelector(state => state.user.currentUser) as UserProps;
  const dialedProperty = useSelector(selectDialedProperty);
  const currentUser = useSelector(selectCurrentUser);
  const transferReason = useSelector(selectTransferReason);
  const notes = useSelector(selectCallNotes);
  const transferEventId = useSelector(selectTransferEventId);
  const propertyDetails = useSelector(selectProperty);

  const [renderProspectForm, setRenderProspectForm] = useState(false);
  const [renderResidentForm, setRenderResidentForm] = useState(false);
  const [renderOtherForm, setRenderOtherForm] = useState(false);
  const [renderBackSaveCall, setRenderBackSaveCall] = useState(false);
  const [activeBathButton, setActiveBathButton] = useState<string>();
  const [activeBedButton, setActiveBedButton] = useState<string>();
  const [filteredResidentsLeads, setFilteredResidentsLeads] = useState([]);
  const [residentSearch, setResidentSearch] = useState(undefined);
  const [groupedResidentsLeads, setGroupedResidentsLeads] = useState<GroupedResidentsLeads>({});
  const [defaultLeadValue, setDefaultLeadValue] = useState({
    first_name: '',
    last_name: '',
    email: '',
    beds: null,
    baths: null,
    has_followup: true,
  });
  const [selectedResidentLead, setSelectedResidentLead] = useState<ResidentLead>();
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [callerType, setCallerType] = useState(undefined);
  const [loadingProperties, setLoadingProperties] = useState(true);

  useEffect(() => {
    setLoadingProperties(true);
    const allResidents = [...residents, ...current_residents];
    const residentsWithTypes = allResidents.map((resident) => {
      const leadResident = resident as LeadResidentSearch;
      if (leadResident.lease_lead_id !== undefined) {
        return {
          ...resident,
          lease_lead_id: undefined,
          id: leadResident.lease_lead_id,
          type: 'resident',
        };
      }
      return {
        ...resident,
        id: resident.id,
        type: 'currentResident',
      };
    });
    const leadsWithTypes = leads.map(lead => ({
      ...lead,
      type: 'lead',
    }));
    const uniqueLeads = leadsWithTypes.filter((lead) => {
      const hasMatchingResident = residentsWithTypes.some(resident => resident?.id === lead.id && resident.name === lead.name);
      return !hasMatchingResident;
    });
    const leadsResidents = residentsWithTypes.concat(uniqueLeads);
    if (residentSearch) {
      const filtered = leadsResidents.filter(item => item.name.toLowerCase().includes(residentSearch?.toLowerCase()));
      setFilteredResidentsLeads(filtered);
    } else {
      setFilteredResidentsLeads(leadsResidents);
    }
  }, [residentSearch, residents, leads]);

  useEffect(() => {
    const grouped = filteredResidentsLeads.reduce((acc, resident_lead) => {
      const lastName = resident_lead.name.split(' ')[1];
      if (lastName) {
        const initial = lastName.charAt(0).toUpperCase();
        acc[initial] = acc[initial] || [];
        acc[initial].push(resident_lead);
      }
      return acc;
    }, {});
    const sortedKeys = Object.keys(grouped).sort();
    const sortedGrouped = sortedKeys.reduce((acc, key) => {
      acc[key] = grouped[key];
      return acc;
    }, {});
    setLoadingProperties(false);
    setGroupedResidentsLeads(sortedGrouped);
  }, [filteredResidentsLeads]);

  const saveCall = (leadCalledId?: number) => {
    const transferReasonValue = transferReason ? transferReason.value : null;

    const additionalField = selectedResidentLead?.id && selectedResidentLead.type === 'currentResident' ?
      { current_resident: selectedResidentLead?.id } : { lead_id: leadId || leadCalledId || null };

    const params: CallSummary = {
      ...additionalField,
      notes,
      lease_id: leaseId || null,
      application_id: applicationId || null,
      leasing_user: leasingUserId || null,
      transfer_reason: transferReasonValue,
      transfer_event_id: transferEventId,
      call_category: callerType,
    };
    if (!isDesktopPhone) {
      let callSid = call.parameters.CallSid;
      if (isInboundCall) {
        // Twilio creates two calls so we have two call sid, in order to transfer
        // incoming calls (client called and agent answer) we have to update
        // the proper call record in Twilio.
        // Otherwise we would finished the call for both parties
        const { originalCallSid } = getCallParams(call);
        callSid = originalCallSid;
      }
      dispatch(saveCallSummary({ ...params, call_sid: callSid }));
    } else {
      dispatch(saveCallSummary({ ...params, call_sid: desktopCallSid }));
    }
    if (currentUser.ai_noise_suppression_enabled) {
      dispatch(clearInProgressCall());
    } else {
      dispatch(clearInProgressCallWithoutNoiseSuppression());
    }
    dispatch(setDialedPhoneNumber(''));
    dispatch(setDialedContact(undefined));
    if (!isEmpty(selectedResidentLead)) {
      const dialedFormatPhone = dialFormat(dialedPhone);
      if (dialFormat(selectedResidentLead.phone_number) !== dialedFormatPhone && dialFormat(selectedResidentLead.secondary_phone_number) !== dialedFormatPhone && selectedResidentLead.type === 'lead') {
        dispatch(updateLeadById(selectedResidentLead.id, { secondary_phone_number: dialedPhone }, dialedProperty.id))
          .then(() => push(`/${dialedProperty.external_id}/leads/${selectedResidentLead.id}`));
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'first_name' && !isEmpty(value)) setFirstNameError(false);
    if (name === 'last_name' && !isEmpty(value)) setLastNameError(false);
    setDefaultLeadValue(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResidentProspectClick = (type: string) => {
    if (type === 'Prospect') {
      setRenderProspectForm(true);
      setRenderBackSaveCall(true);
      setCallerType('PROSPECT');
      dispatch(setCallWindowBody(CallWindowBodyComponent.NOTES));
    } if (type === 'Resident') {
      setFilteredResidentsLeads(filteredResidentsLeads.filter(resident_lead => resident_lead.property === dialedProperty.id));
      dispatch(setCallWindowBody(CallWindowBodyComponent.EMPTY));
      setRenderResidentForm(true);
      setCallerType('RESIDENT');
    } if (type === 'Other') {
      setRenderOtherForm(true);
      setRenderBackSaveCall(true);
      setCallerType('OTHER');
      dispatch(setCallWindowBody(CallWindowBodyComponent.REASON_FOR_CALL));
    }
  };

  const changeCallerInfoName = (name: string) => {
    const callerInfoName = document.getElementById('callerInfoName');
    callerInfoName.textContent = name;
  };

  const handleResidentSelectClick = (selected: ResidentLead) => {
    setSelectedResidentLead(selected);
    changeCallerInfoName(selected.name);
    setRenderResidentForm(false);
    setRenderBackSaveCall(true);
    dispatch(setCallWindowBody(CallWindowBodyComponent.SUMMARY));
  };

  const handleSaveSuccess = (e) => {
    saveCall(e.result.data.id);
    push(`/${dialedProperty.external_id}/leads/${e.result.data.id}`);
  };

  const handleSaveCallClick = () => {
    const getSourceId = (sources: ProspectSourceTypes[]): number => {
      const otherSource = sources.find(source => source.name === 'Other');
      return otherSource ? otherSource.id : sources[0].id;
    };
    if (renderProspectForm) {
      if (isEmpty(defaultLeadValue.first_name)) {
        setFirstNameError(true);
      }
      if (isEmpty(defaultLeadValue.last_name)) {
        setLastNameError(true);
      }
      if (!isEmpty(defaultLeadValue.first_name) || !isEmpty(defaultLeadValue.last_name)) {
        const sourceId = getSourceId(propertyDetails.sources);
        dispatch(createLead(dialedProperty.id, {
          ...defaultLeadValue,
          phone_number: dialedPhone,
          floor_plan: [],
          move_in_date: null,
          owner: currentUserData.id,
          source: sourceId,
          unit_type: null,
        })).then((e) => {
          handleSaveSuccess(e);
        });
      }
    } else if (renderOtherForm) {
      saveCall();
    } else {
      saveCall(selectedResidentLead.id);
    }
  };

  const handleBathButtonClick = (value: string) => {
    if (value === activeBathButton) {
      setActiveBathButton(null);
    } else {
      setActiveBathButton(value);
      setDefaultLeadValue(prevState => ({
        ...prevState,
        baths: Number(value),
      }));
    }
  };

  const handleBedButtonClick = (value: string) => {
    if (value === activeBedButton) {
      setActiveBedButton(null);
    } else {
      setActiveBedButton(value);
      if (value === 'S') value = '0';
      setDefaultLeadValue(prevState => ({
        ...prevState,
        beds: Number(value),
      }));
    }
  };

  const findContactByPhone = () => filteredResidentsLeads.find(contact => dialFormat(contact.phone_number) === dialFormat(dialedPhone) || dialFormat(contact.secondary_phone_number) === dialFormat(dialedPhone));

  const handleBackButtonClick = () => {
    setRenderBackSaveCall(false);
    setRenderProspectForm(false);
    setRenderResidentForm(false);
    setRenderOtherForm(false);
    setCallerType(undefined);
    dispatch(setCallWindowBody(CallWindowBodyComponent.NOTES));
    setSelectedResidentLead(undefined);
    const foundContact = findContactByPhone();
    changeCallerInfoName(foundContact || formatPhoneNumber(dialedPhone));
  };

  const formatName = (residentName: string) => {
    const [firstName, lastName] = residentName.split(' ');
    if (isEmpty(lastName)) return firstName;
    return `${lastName}, ${firstName}`;
  };

  if (callFinished || callTransferred) {
    if (isInboundCall) {
      if (leadId) {
        return (
          <SaveCallButton
            onClick={() => {
              saveCall(leadId);
            }}
            disabled={callTransferred && !transferReason}
          >
            Save Call
          </SaveCallButton>
        );
      }
      return (
        <SaveCallButton
          onClick={() => {
            saveCall();
          }}
          disabled={callTransferred && !transferReason}
        >
          Save Call
        </SaveCallButton>
      );
    } else if (!isInboundCall) {
      if (leadId || (leasingUserId && (leaseId || applicationId))) {
        return (
          <SaveCallButton
            onClick={() => {
              saveCall(leadId);
            }}
            disabled={callTransferred && !transferReason}
          >
            Save Call
          </SaveCallButton>
        );
      } else if (!isEmpty(dialedPhone)) {
        const foundContact = findContactByPhone();
        if (foundContact) {
          return (
            <SaveCallButton
              onClick={() => {
                saveCall(foundContact.id);
              }}
              disabled={callTransferred && !transferReason}
            >
              Save Call
            </SaveCallButton>
          );
        }
      }
      return (
        <React.Fragment>
          <div id="btnResidentProspect">
            <OutboundCallFinishDiv id="btnResidentProspect" className={cn({ 'd-none': renderBackSaveCall || renderResidentForm })}>
              <OutboundCallFinishButton id="btnResident" className="btn justify-content-center flex-fill" onClick={() => handleResidentProspectClick('Resident')}>
                Resident
              </OutboundCallFinishButton>
              <OutboundCallFinishButton id="btnProspect" className="btn justify-content-center flex-fill" onClick={() => handleResidentProspectClick('Prospect')}>
                Prospect
              </OutboundCallFinishButton>
              <OutboundCallFinishButton id="btnOther" className="btn justify-content-center flex-fill" onClick={() => handleResidentProspectClick('Other')}>
                Other
              </OutboundCallFinishButton>
            </OutboundCallFinishDiv>
            <OutboundCallFinishDiv id="btnBackSaveCall" className={cn({ 'd-none': !renderBackSaveCall })}>
              <OutboundCallFinishButtonIcon className="btn" onClick={handleBackButtonClick}>
                <i className="ri-arrow-left-s-fill tx-22-f" />
              </OutboundCallFinishButtonIcon>
              <button onClick={handleSaveCallClick} className="btn btn-primary justify-content-center flex-fill">
                Save Call
              </button>
            </OutboundCallFinishDiv>
            <OutboundCallFinishDiv id="btnSelectResident" className={cn({ 'd-none': !renderResidentForm })}>
              <OutboundCallFinishButtonIcon className="btn" onClick={handleBackButtonClick}>
                <i className="ri-arrow-left-s-fill tx-22-f" />
              </OutboundCallFinishButtonIcon>
              <div className="alert alert-warning flex-fill">
                <i className="ri-information-fill" />
                <div>Please select resident to link to call</div>
              </div>
            </OutboundCallFinishDiv>
          </div>
          <div id="endCallBody">
            {renderProspectForm &&
              <div id="callProspect">
                <CIBItemDiv id="callProspect" className="h-100">
                  <CIBBodyDiv className="h-100">
                    <div className="mg-b-10">
                      <InputCalledProspect
                        type="text"
                        className={cn('form-control', { required: firstNameError })}
                        name="first_name"
                        placeholder="First name"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mg-b-10">
                      <InputCalledProspect
                        type="text"
                        className={cn('form-control', { required: lastNameError })}
                        name="last_name"
                        placeholder="Last name"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mg-b-10">
                      <InputCalledProspect type="text" className="form-control" name="email" placeholder="Email address" onChange={handleInputChange} />
                    </div>
                    <div className="d-flex mg-b-9">
                      <div className="flex-fill">
                        <p className="d-block tx-13 mb-1">Beds</p>
                        <NavCircleMini className="nav" data-role="nav-radio">
                          <button className={cn('nav-link', { active: activeBedButton === 'S' })} onClick={() => handleBedButtonClick('S')}>S</button>
                          <button className={cn('nav-link', { active: activeBedButton === '1' })} onClick={() => handleBedButtonClick('1')}>1</button>
                          <button className={cn('nav-link', { active: activeBedButton === '2' })} onClick={() => handleBedButtonClick('2')}>2</button>
                          <button className={cn('nav-link', { active: activeBedButton === '3' })} onClick={() => handleBedButtonClick('3')}>3</button>
                        </NavCircleMini>
                      </div>
                      <div className="flex-fill ml-2">
                        <p className="d-block tx-13 mb-1">Baths</p>
                        <NavCircleMini className="nav" data-role="nav-radio">
                          <button className={cn('nav-link', { active: activeBathButton === '1' })} onClick={() => handleBathButtonClick('1')} >1</button>
                          <button className={cn('nav-link', { active: activeBathButton === '1.5' })} onClick={() => handleBathButtonClick('1.5')} >1.5</button>
                          <button className={cn('nav-link', { active: activeBathButton === '2' })} onClick={() => handleBathButtonClick('2')} >2</button>
                          <button className={cn('nav-link', { active: activeBathButton === '2.5' })} onClick={() => handleBathButtonClick('2.5')} >2.5</button>
                        </NavCircleMini>
                      </div>
                    </div>
                  </CIBBodyDiv>
                </CIBItemDiv>
              </div>}
            {renderResidentForm &&
              <CIBItemDiv id="callResident">
                <div className="h-100">
                  <FormSearchDiv>
                    <CallWindowSVG
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-lucide="search"
                      className="lucide lucide-search"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <path d="m21 21-4.3-4.3" />
                    </CallWindowSVG>
                    <input
                      id="residentSearch"
                      type="text"
                      className="form-control"
                      placeholder="Search residents"
                      value={residentSearch}
                      onChange={e => setResidentSearch(e.target.value)}
                    />
                  </FormSearchDiv>
                  <>
                    {loadingProperties ? (
                      <div>Loading...</div>
                    ) : (
                      <ContactList id="contactList">
                        {Object.entries(groupedResidentsLeads).map(([initial, listResidentsLeads]) => (
                          <React.Fragment key={initial}>
                            <ContactLabel>{initial}</ContactLabel>
                            {listResidentsLeads.map(resident_lead => (
                              <li key={`${resident_lead.id}-${resident_lead.phone_number}`} className="contact-item">
                                <ContactLink onClick={() => handleResidentSelectClick(resident_lead)}>{formatName(resident_lead.name)}</ContactLink>
                              </li>
                            ))}
                          </React.Fragment>
                        ))}
                      </ContactList>
                    )}
                  </>
                </div>
              </CIBItemDiv>}
            {renderOtherForm &&
              <div id="callOther">
                <CIBItemDiv id="callOther" className="h-100">
                  <CIBBodyDiv className="h-100">
                    <input type="text" className="form-control mg-b-0" placeholder="Title" />
                  </CIBBodyDiv>
                </CIBItemDiv>
              </div>}
          </div>
        </React.Fragment>
      );
    }
  }

  return (
    <HangupButton onClick={handleHangup} hangup disabled={callTransferred || !hasConferenceStarted}>
      <i className="ri-phone-fill" />
    </HangupButton>
  );
};

export default EndCallActionButton;
