import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_ALERTS_SUBSCRIPTIONS_REQUEST = 'GET_ALERTS_SUBSCRIPTIONS_REQUEST',
  GET_ALERTS_SUBSCRIPTIONS_SUCCESS = 'GET_ALERTS_SUBSCRIPTIONS_SUCCESS',
  GET_ALERTS_SUBSCRIPTIONS_FAILURE = 'GET_ALERTS_SUBSCRIPTIONS_FAILURE',
  CREATE_ALERT_REQUEST = 'CREATE_ALERT_REQUEST',
  CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS',
  CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE',
  GET_ALERTS_BY_ID_REQUEST = 'GET_ALERTS_BY_ID_REQUEST',
  GET_ALERTS_BY_ID_SUCCESS = 'GET_ALERTS_BY_ID_SUCCESS',
  GET_ALERTS_BY_ID_FAILURE = 'GET_ALERTS_BY_ID_FAILURE',
  DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST',
  DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS',
  DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE',
  UPDATE_ALERT_REQUEST = 'UPDATE_ALERT_REQUEST',
  UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS',
  UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_FAILURE',
  GET_ALERT_LOG_DETAIL_REQUEST = 'GET_ALERT_LOG_DETAIL_REQUEST',
  GET_ALERT_LOG_DETAIL_SUCCESS = 'GET_ALERT_LOG_DETAIL_SUCCESS',
  GET_ALERT_LOG_DETAIL_FAILURE = 'GET_ALERT_LOG_DETAIL_FAILURE',
  GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_REQUEST = 'GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_REQUEST',
  GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_SUCCESS = 'GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_SUCCESS',
  GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_FAILURE = 'GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_FAILURE',
}

export interface AlertInfo {
  sent_on: string;
  id: number;
  start?: string;
  end?: string;
  baseline?: string;
  condition_subject?: string;
  condition_unit_types?: string[];
}

export interface AlertUnitLog {
  id: number;
  unit_type?: string;
  average_rent?: number;
  average_rent_last_day?: number;
  average_rent_last_week?: number;
  average_rent_last_4_weeks?: number;
  average_rent_per_sqft?: number;
  average_rent_per_sqft_last_day?: number;
  average_rent_per_sqft_last_week?: number;
  average_rent_per_sqft_last_4_weeks?: number;
}

export interface AlertLogs {
  id: number;
  alert_unit_rent_logs?: AlertUnitLog[];
  property?: string;
  occupancy?: number;
  occupancy_last_day?: number;
  occupancy_last_week?: number;
  occupancy_last_4_weeks?: number;
  concession_amount?: number;
  concession_amount_last_day?: number;
  concession_amount_last_week?: number;
  concession_amount_last_4_weeks?: number;
  concession_avg_rent_percent?: number;
  concession_avg_rent_percent_last_day?: number;
  concession_avg_rent_percent_last_week?: number;
  concession_avg_rent_percent_last_4_weeks?: number;
  is_offering_concession?: boolean;
  count?: number;
}

export interface Alert {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id?: any;
  geo?: string[];
  tracked_assets?: number;
  last_sent?: string;
  logs?: AlertInfo[];
  name?: string;
  type?: string;
  baseline?: string;
  condition_subject?: string;
  condition_type?: string;
  condition_expression?: string;
  condition_value?: number;
  condition_unit_types?: string[];
  track_assets_mode?: string;
  status?: string;
  properties?: number[];
  markets?: number[];
  submarkets?: number[];
  trackedAssets?: string;
  condition?: string;
  thresholdType?: string;
  expression?: string;
  thresholdPercent?: number;
  unitTypes: { label: string; value: string }[];
}

interface GetAlertSubscriptionsAction {
  type: ActionType.GET_ALERTS_SUBSCRIPTIONS_REQUEST | ActionType.GET_ALERTS_SUBSCRIPTIONS_SUCCESS | ActionType.GET_ALERTS_SUBSCRIPTIONS_FAILURE;

  result: {
    data: { results: Alert[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface CreateAlertAction {
  type: ActionType.CREATE_ALERT_REQUEST | ActionType.CREATE_ALERT_SUCCESS | ActionType.CREATE_ALERT_FAILURE;
}

interface GetAlertByIdAction {
  type: ActionType.GET_ALERTS_BY_ID_REQUEST | ActionType.GET_ALERTS_BY_ID_SUCCESS | ActionType.GET_ALERTS_BY_ID_FAILURE;

  result: {
    data: Alert;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteAlertAction {
  type: ActionType.DELETE_ALERT_REQUEST | ActionType.DELETE_ALERT_SUCCESS | ActionType.DELETE_ALERT_FAILURE;
}

interface UpdateAlertAction {
  type: ActionType.UPDATE_ALERT_REQUEST | ActionType.UPDATE_ALERT_SUCCESS | ActionType.UPDATE_ALERT_FAILURE;

  result: {
    data: Alert;
  };
  error: {
    response: { status: string };
  };
}

interface GetAlertLogDetailAction {
  type: ActionType.GET_ALERT_LOG_DETAIL_REQUEST | ActionType.GET_ALERT_LOG_DETAIL_SUCCESS | ActionType.GET_ALERT_LOG_DETAIL_FAILURE;

  result: {
    data: AlertLogs;
  };
  error: {
    response: { status: string };
  };
}

interface GetAlertLogDetailByUnitTypeAction {
  type: ActionType.GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_REQUEST | ActionType.GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_SUCCESS | ActionType.GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_FAILURE;

  result: {
    data: { results: AlertLogs, count: number };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unitType: any;
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetAlertSubscriptionsAction
  | CreateAlertAction
  | GetAlertByIdAction
  | DeleteAlertAction
  | UpdateAlertAction
  | GetAlertLogDetailAction
  | GetAlertLogDetailByUnitTypeAction;
