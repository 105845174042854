import { paths } from 'dwell/constants';
import { CallBackFunction } from 'src/interfaces';
import { ActionType, Action } from './action-types';

export default {
  authorize: (property: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_NYLAS_AUTH_REQUEST,
        ActionType.GET_NYLAS_AUTH_SUCCESS,
        ActionType.GET_NYLAS_AUTH_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.NYLAS_AUTH, { params: { property } }),
      successCB,
    },
  }),

  getToken: (property: number, code: string): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_NYLAS_TOKEN_REQUEST,
        ActionType.GET_NYLAS_TOKEN_SUCCESS,
        ActionType.GET_NYLAS_TOKEN_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.NYLAS_AUTH, code, { params: { property } }),
    },
  }),

  sendMessage: (data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.SEND_MESSAGE_REQUEST,
        ActionType.SEND_MESSAGE_SUCCESS,
        ActionType.SEND_MESSAGE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.NYLAS_SEND_MESSAGE, data),
    },
  }),

  archiveMessages: ({ ids } : { ids: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.ARCHIVE_MESSAGES_REQUEST,
        ActionType.ARCHIVE_MESSAGES_SUCCESS,
        ActionType.ARCHIVE_MESSAGES_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.NYLAS_ARCHIVE_MESSAGES, { ids }),
    },
  }),
  setEmailOpenStatus: (dataSetEmail: boolean, isSendApplicationEmail?: boolean): Action => ({
    type: ActionType.SET_EMAIL_OPEN_STATUS,
    data: { dataSetEmail, isSendApplicationEmail },
  }),
};
