import { ResetLeaseAction } from 'leasing/store/lease/action-types';
import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_SCREENING_INCOMPLETE_FIELDS_REQUEST = 'GET_SCREENING_INCOMPLETE_FIELDS_REQUEST',
  GET_SCREENING_INCOMPLETE_FIELDS_SUCCESS = 'GET_SCREENING_INCOMPLETE_FIELDS_SUCCESS',
  GET_SCREENING_INCOMPLETE_FIELDS_FAILURE = 'GET_SCREENING_INCOMPLETE_FIELDS_FAILURE',

  GET_SCREENING_REPORTS_REQUEST = 'GET_SCREENING_REPORTS_REQUEST',
  GET_SCREENING_REPORTS_SUCCESS = 'GET_SCREENING_REPORTS_SUCCESS',
  GET_SCREENING_REPORTS_FAILURE = 'GET_SCREENING_REPORTS_FAILURE',

  INITIATE_SCREENING_REQUEST = 'INITIATE_SCREENING_REQUEST',
  INITIATE_SCREENING_SUCCESS = 'INITIATE_SCREENING_SUCCESS',
  INITIATE_SCREENING_FAILURE = 'INITIATE_SCREENING_FAILURE',
}

export interface ScreeningReportProps {
  id: number,
  credit_decision: string,
  criminal_decision: string,
  criminal: string,
  eviction: string,
  error_message: string,
  report_url: string,
  status: string,
  score: number,
  date: string,
  created: string,
  recommendation: string,
  status_code: string,
  credit_score: number,
  credit_report: string,
  caution_notes: string[],
  created_date?: string,
  refreshed_date?: string,
  credit_refreshed_date?: string,
  criminal_refreshed_date?: string,
  eviction_refreshed_date?: string,
  criminal_due_date?: string,
}

export interface ScreeningReportState extends CommonStateType {
  incompleteFields: string[],
  screeningReports: ScreeningReportProps[],
}

/*
 * Actions
 */

interface GetScreeningIncompleteFieldsAction extends CommonActionResponse {
  type: ActionType.GET_SCREENING_INCOMPLETE_FIELDS_REQUEST
  | ActionType.GET_SCREENING_INCOMPLETE_FIELDS_SUCCESS
  | ActionType.GET_SCREENING_INCOMPLETE_FIELDS_FAILURE;
  result: { data: string[] };
}

interface GetScreeningReportsAction extends CommonActionResponse {
  type: ActionType.GET_SCREENING_REPORTS_REQUEST
  | ActionType.GET_SCREENING_REPORTS_SUCCESS
  | ActionType.GET_SCREENING_INCOMPLETE_FIELDS_FAILURE;
  result: { data: { results: ScreeningReportProps[] } };
}

interface InitiateScreeningAction extends CommonActionResponse {
  type: ActionType.INITIATE_SCREENING_REQUEST
  | ActionType.INITIATE_SCREENING_SUCCESS
  | ActionType.INITIATE_SCREENING_FAILURE;
  result: { data: { results: ScreeningReportProps[] } };
}

export type Action =
  PromiseActionType
  | GetScreeningIncompleteFieldsAction
  | GetScreeningReportsAction
  | InitiateScreeningAction
  | ResetLeaseAction;
