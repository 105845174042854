import styled, { css } from 'styled-components';

export const OptionContainer = styled.div`
  padding: 7px 10px;

  span {
   &:last-child {
      color: ${props => (props.selected ? 'white' : 'gray')};
      margin-top: 5px;
    }
  }

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
    border-color: #cccccc;
    color: #333333;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  ${props => props.selected && css`
    background-color: #3775fe;
    color: white !important;
  `}
`;
