import moment from 'moment';
import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_NURTURE_AUTOMATIONS_REQUEST = 'GET_NURTURE_AUTOMATIONS_REQUEST',
  GET_NURTURE_AUTOMATIONS_SUCCESS = 'GET_NURTURE_AUTOMATIONS_SUCCESS',
  GET_NURTURE_AUTOMATIONS_FAILURE = 'GET_NURTURE_AUTOMATIONS_FAILURE',
  CREATE_NURTURE_AUTOMATIONS_REQUEST = 'CREATE_NURTURE_AUTOMATIONS_REQUEST',
  CREATE_NURTURE_AUTOMATIONS_SUCCESS = 'CREATE_NURTURE_AUTOMATIONS_SUCCESS',
  CREATE_NURTURE_AUTOMATIONS_FAILURE = 'CREATE_NURTURE_AUTOMATIONS_FAILURE',
  UPDATE_NURTURE_AUTOMATIONS_REQUEST = 'UPDATE_NURTURE_AUTOMATIONS_REQUEST',
  UPDATE_NURTURE_AUTOMATIONS_SUCCESS = 'UPDATE_NURTURE_AUTOMATIONS_SUCCESS',
  UPDATE_NURTURE_AUTOMATIONS_FAILURE = 'UPDATE_NURTURE_AUTOMATIONS_FAILURE',
  GET_NURTURE_DETAILS_REQUEST = 'GET_NURTURE_DETAILS_REQUEST',
  GET_NURTURE_DETAILS_SUCCESS = 'GET_NURTURE_DETAILS_SUCCESS',
  GET_NURTURE_DETAILS_FAILURE = 'GET_NURTURE_DETAILS_FAILURE',
  GET_NURTURE_INSIGHTS_REQUEST = 'GET_NURTURE_INSIGHTS_REQUEST',
  GET_NURTURE_INSIGHTS_SUCCESS = 'GET_NURTURE_INSIGHTS_SUCCESS',
  GET_NURTURE_INSIGHTS_FAILURE = 'GET_NURTURE_INSIGHTS_FAILURE',
  SET_NURTURE_FORM_CHANGED = 'SET_NURTURE_FORM_CHANGED',
  SET_INSIGHTS_DATE_PERIOD = 'SET_INSIGHTS_DATE_PERIOD',
  SET_INSIGHTS_CUSTOM_DATE = 'SET_INSIGHTS_CUSTOM_DATE',
}

export interface NurtureList {
  id: number,
  name: string,
  steps: number,
  timeline: number,
  status: string,
  sources: number[],
  total_enrollments: number,
  reply_rate: number,
}

export interface Nurture {
  id: number,
  name: string,
  sources: number[],
  qualifying_stages: string[],
  status: string,
  is_reply_back_email: boolean,
  is_sends_new_email: boolean,
  is_sends_new_text: boolean,
  is_answer_inbound: boolean,
  is_answer_outbound: boolean,
  success_lead_stage: string,
  update_lead_to_lost: boolean,
  delay_time_mark_lead_as_lost: number,
  lost_reason: number,
  weekdays: number[],
  time_send_start: string,
  time_send_end: string,
  send_any_time_of_day: boolean,
  allow_reentry_prospect: boolean,
  reentry_time_period: number,
  thread_emails_as_replies: boolean,
  system_template: string,
}

interface InsightField {
  value: number,
  leads: number[],
}

export interface NurtureLeadInfo {
  id: number,
  created: string
  email: string,
  first_name: string,
  last_name: string,
  property__name: string,
  source__name: string,
  acquisition_date: string,
  last_followup_date: string,
  owner_name: string,
}

export interface NurtureInsights {
  start_date: string,
  end_date: string,
  insights: {
    count_enrollments: number,
    total_enrollments: {
      value: number,
      leads: NurtureLeadInfo[],
    },
    current_enrollments: InsightField,
    reply_rate: InsightField,
    avg_response_time: number,
    best_converting_step: string,
    no_response_rate: InsightField,
    email_sent: number,
    sms_sent: number,
    error_rate: InsightField,
  }
}

interface GetNurtureAutomationsAction {
  type: ActionType.GET_NURTURE_AUTOMATIONS_REQUEST | ActionType.GET_NURTURE_AUTOMATIONS_SUCCESS | ActionType.GET_NURTURE_AUTOMATIONS_FAILURE;

  result: {
    data: { results: NurtureList[], count: number, count_all: number, count_active: number, count_inactive: number, count_draft: number };
  };
  error: {
    response: { status: string };
  };
}

interface CreateNurtureAutomationAction {
  type: ActionType.CREATE_NURTURE_AUTOMATIONS_REQUEST | ActionType.CREATE_NURTURE_AUTOMATIONS_SUCCESS | ActionType.CREATE_NURTURE_AUTOMATIONS_FAILURE;
}

interface GetNurtureDetailsAction {
  type: ActionType.GET_NURTURE_DETAILS_REQUEST | ActionType.GET_NURTURE_DETAILS_SUCCESS | ActionType.GET_NURTURE_DETAILS_FAILURE;

  result: {
    data: Nurture;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateNurtureAutomationAction {
  type: ActionType.UPDATE_NURTURE_AUTOMATIONS_REQUEST | ActionType.UPDATE_NURTURE_AUTOMATIONS_SUCCESS | ActionType.UPDATE_NURTURE_AUTOMATIONS_FAILURE;

  result: {
    data: Nurture;
  };
  error: {
    response: { status: string };
  };
}

interface GetNurtureInsightsAction {
  type: ActionType.GET_NURTURE_INSIGHTS_REQUEST | ActionType.GET_NURTURE_INSIGHTS_SUCCESS | ActionType.GET_NURTURE_INSIGHTS_FAILURE;

  result: {
    data: NurtureInsights;
  };
  error: {
    response: { status: string };
  };
}

interface SetFormChangedAction {
  type: ActionType.SET_NURTURE_FORM_CHANGED;
  changed: boolean;
}

interface SetDatePeriodAction {
  type: ActionType.SET_INSIGHTS_DATE_PERIOD;
  datePeriod: string;
}

interface SetCustomDateAction {
  type: ActionType.SET_INSIGHTS_CUSTOM_DATE;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export type Action =
  | PromiseActionType
  | GetNurtureAutomationsAction
  | CreateNurtureAutomationAction
  | UpdateNurtureAutomationAction
  | SetFormChangedAction
  | GetNurtureDetailsAction
  | SetDatePeriodAction
  | SetCustomDateAction
  | GetNurtureInsightsAction;
