import produce from 'immer';
import { PusherAction, ActionType as PusherActionType } from 'dwell/store/pusher/action-types';
import { ActionType as LeadActionType } from 'dwell/store/lead/action-types';
import { Action, ActionType, CallProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState, isLeadPage, isLeadsObject } from '../utils';

export interface CallState extends CommonStateType {
  calls: CallProps[];
  call: CallProps;
  count: number;
  isArchiving: boolean;
}

export const initialState: CallState = {
  isSubmitting: false,
  errorMessage: null,
  calls: [],
  call: {} as CallProps,
  count: 0,
  isLoaded: true,
  isArchiving: false,
};

export default produce((state: CallState = initialState, action: Action): CallState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'call') {
    return state;
  }
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case LeadActionType.GET_LEAD_BY_ID_SUCCESS:
      state.calls = action.result.data.calls;
      break;

    case ActionType.GET_CALLS_SUCCESS:
      state.calls = action.result.data.results;
      state.count = action.result.data.count;
      break;

    case ActionType.GET_CALL_BY_ID_SUCCESS:
      state.call = action.result.data;
      break;

    case ActionType.UPDATE_CALL_SUCCESS:
      state.call = action.result.data;
      break;

    case ActionType.ARCHIVE_CALL_SUCCESS:
      state.call = action.result.data;
      break;
    case ActionType.UNARCHIVE_CALL_SUCCESS:
      state.call = action.result.data;
      break;

    case ActionType.ARCHIVE_CALLS_REQUEST:
      state.isArchiving = true;
      break;
    case ActionType.ARCHIVE_CALLS_SUCCESS:
      state.isArchiving = false;
      break;
    case ActionType.ARCHIVE_CALLS_FAILURE:
      state.isArchiving = false;
      break;

    case ActionType.SAVE_CALL_NOTES_SUCCESS:
      state.call = action.result.data;
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = action.row as CallProps;
      let newCalls = state.calls;
      if (isLeadPage() && isLeadsObject(row.lead.toString())) {
        newCalls = [row].concat(state.calls.filter(c => c.id !== row.id));
        state.calls = newCalls;
      }
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as CallProps;
      let newCalls = state.calls;
      if (isLeadPage() && isLeadsObject(row.lead.toString())) {
        newCalls = [row].concat(state.calls.filter(c => c.id !== row.id));
        state.calls = newCalls;
      }
      break;
    }

    case PusherActionType.PUSHER_DELETE_RECORD: {
      const row = action.row as CallProps;
      let newCalls = state.calls;
      if (isLeadPage() && isLeadsObject(row.lead.toString())) {
        newCalls = state.calls.filter(c => c.id.toString() !== row.id.toString());
        state.calls = newCalls;
      }
      break;
    }
  }

  return state;
});
