import styled from 'styled-components';
import { Input } from 'reactstrap';
import { DropdownLink, LeaseDropdown, LeaseDropdownItem, LeaseDropdownMenu } from 'dwell/views/lead/overview/styles';
import { CustomRow as Row, CustomFormInput as FormInput, Label, PrimaryButton } from 'leasing/views/applicants/styles';
import { FormSwitch as BaseFormSwitch } from 'dwell/views/chat/multi_chat/styles';

export const LeaseSidebarPanel = styled.div`
    width: 342px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-right: 1px solid #e2e7f4;
    font-size: 13px;
    height: calc(100vh - 360px);
    overflow-y: auto;
    overflow-x: hidden;

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: ${props => props.theme.colors.colorbg03};
        }
    }

    &::-webkit-scrollbar {
        width: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .DateInput_input {
        height: 36px !important;
        color: ${props => props.theme.colors.colortx02};
        font-size: ${props => props.theme.fontSizes.base};
        font-weight: 400;
    }
`;

export const LeaseSidebarTitleWrapper = styled.h6`
    display: flex;
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #0b2151;
    align-items: center;

    &:before {
        content: '';
        position: absolute;
        top: calc(50% - .5px);
        left: 0;
        right: 0;
        border-top: 1px solid #d5dcf4;
    }

    strong {
        font-weight: 500;
        font-size: 13px;
        position: relative;
        z-index: 5;
        background-color: #fff;
    }
`;

export const LeaseSidebarTitle = styled.span`
    position: relative;
    z-index: 5;
    background-color: #fff;

    &:first-child {
        margin-right: auto;
        padding-right: 8px;
    }

    &:not(:first-child) {
        color: #929eb9;
        font-weight: 400;
        font-size: 13px;
        margin-right: 3px;
    }
`;

export const DetailList = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 6px;
`;

export const DetailListItem = styled.li`
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #4a5e8a;
    padding: 8px 0;
    border-width: 0;

    .react-datepicker-popper {
        z-index: 6;
    }

    &:not(:first-child) {
        border-top: 1px solid #eef1f9;
    }

    >span:first-child {
        overflow-wrap: break-word;
        max-width: 50%;
        margin-right: auto;
        text-align: left !important;
    }

    >span:last-of-type {
        color: #0b2151;
        text-align: right;
    }

    .field-label {
        color: ${props => props.theme.colors.colortx02} !important;
        font-size: 13px;
        font-weight: 600;
    }

    > input {
        width: 120px;
        height: 36px;
    }

    i {
        cursor: pointer;
    }

    .SingleDatePickerInput {
        width: 120px! important;
    }

    .SingleDatePicker_picker {
        z-index: 100;
    }

    .btn-group {
        width: 120px;
        height: 100%;
    }

    .dropdown-toggle {
        height: 36px;
    }
`;

export const DetailListLink = styled.a`
    cursor: pointer;
    color: #0168fa !important;

    &:hover {
        color: #0148ae !important;
    }
`;

export const ProratedDaysLabel = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: rgb(40, 89, 67) !important;
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  display: block;
  min-height: 1.3125rem;
  display: flex;
  justify-content: flex-end;

  label {
    font-weight: 400;
    font-size: 12px;
    color: #929eb9;

    &:before {
        border-radius: 0.15rem;
        left: -1rem;
        width: 0.75rem;
        height: 0.75rem;
    }

    &:after {
        left: -1rem;
        width: 0.75rem;
        height: 0.75rem;
    }
  }
`;

export const ApplicantsList = styled.ul`
    width: 120px;
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const ApplicantsListItem = styled.li`
    display: flex;
    align-items: center;

    span {
        display: block;
        margin-right: auto;
    }

    a {
        cursor: pointer;
        color: #929eb9 !important;
        line-height: 1;

        &:hover, &:focus {
            color: #4a5e8a !important;
        }
    }
`;

export const AddFormWrapper = styled.div`
    position: relative;
`;

export const AddForm = styled.div`
    padding: 15px;
    border: 1px solid rgba(193,200,222,0.75);
    border-radius: 6px;
    background-color: #fff;
    position: absolute;
    top: 12px;
    left: -2px;
    width: 322px;
    opacity: 1;
    visibility: visible;
    transform: translateY(-100%);
    box-shadow: 2px 0px 45px rgb(36 55 130 / 12%);
    transition: all 0.2s;
    z-index: 100;

    .is-invalid input, .is-invalid .dropdown-toggle {
        background-image: none !important;
        border: 1px solid #f86c6b !important;
        padding-right: .75rem !important;
    }

    .dropdown-toggle {
        height: 36px;
    }

    .DateInput_input {
        height: 36px !important;
    }
`;

export const AddendaForm = styled(AddForm)`
    width: 50vw;
    height: 90vh;
    padding: 0;
    padding-bottom: 15px;
    top: 40px;
`;

export const AddFormHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const AddendaHeader = styled(AddFormHeader)`
    height: 45px;
    padding: 0 15px;
    margin-bottom: 0;
`;

export const AddFormHeaderClose = styled.a`
    cursor: pointer;
    color: #929eb9 !important;
    font-size: 20px;
    font-weight: 400;
    line-height: .7;
    opacity: .6;
    position: relative;
    left: 2px;

    &:hover, &:focus {
        opacity: 1;
        color: #929eb9 !important;
    }
`;

export const AddFormHeaderTitle = styled.h6`
    margin-bottom: 0;
    font-weight: 500;
    letter-spacing: -.2px;
    color: #0b2151;

    span {
        background-color: #fff;
        padding-right: 5px;
        position: relative;
        font-weight: 400;
        color: #929eb9;
    }
`;

export const AddFormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const AddendaBtn = styled.a`
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-size: .8125rem;
  line-height: 1.5;
  color: #0168fa !important;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid #0168fa;

  &:hover, &:active, &:focus {
    background-color: #ebf2fe;
    border-color: #0168fa !important;
    color: #0168fa !important;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(1,104,250,0.5);
  }
  &:active {
    outline: none;
  }

  i {
    transform: rotate(90deg);
  }
`;

export const AddendaBody = styled.div`
    padding: 15px;
    height: calc(100% - 48px);
    overflow-y: auto;
    padding-top: 0px !important;

    ${AddFormHeaderTitle} {
        padding-bottom: 10px;
        border-bottom: 1px solid #d5dcf4;
        margin-bottom: 15px;

        text-indent: 1px;
        color: #0b2151;
        font-weight: 600;
        position: relative;
    }
`;

export const AddendaSearch = styled.div`
    border-radius: 4px;
    border: 1px solid #d5dcf4;
    background-color: #fff;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 8px;

    i {
        color: #4a5e8a;
        font-size: 20px;
        font-weight: 600;
    }
`;

export const AddendaSearchInput = styled(Input)`
    border-width: 0 !important;
    margin-bottom: 0 !important;

    padding-left: 0;
    height: 36px;
    min-height: 0;
    border-radius: 4px;
    font-size: 13px;
    border-color: #d5dcf4;
    margin-left: 8px;
    padding: 0;
    min-width: 100px;
    background-color: transparent;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: #233457;

    &:focus {
        box-shadow: none;
    }
`;

export const LeasePanelBody = styled.div`
    flex: 1;
    // padding-left: 20px;
    position: relative;
`;

export const LeasePanelBodyHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #4a5e8a;
    padding-bottom: 17px;
    border-bottom: 1px solid #e1e6f7;
    z-index: 2;
    position: relative;
    background: 'white';
    width: 100%;
    justify-content: space-between;

    #zima {
        color: red;
    }
`;

export const FieldsController = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const LeasePanelBodyFields = styled.div`
`;

export const DocumentContainer = styled.iframe`
    border: 1px solid #e1e6f7;
    height: calc(100vh - ${props => (props.$isPreview ? '407' : '360')}px);
    z-index: 1;
    width: 100%;
    display: block;

    &.offscreen {
        height: 100vh;
        left: 0;
        position: fixed;
        top: 100vh;
        visibility: hidden;
        width: 100vw;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: ${props => props.theme.colors.colorbg03};
        }
    }

    &::-webkit-scrollbar {
        width: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`;

export const UnitDropdownLink = styled(DropdownLink)`
    width: 120px;
    height: 36px !important;
`;
export const UnitDropdown = styled(LeaseDropdown)`
    width: 120px;
    margin-left: 0;
`;
export const UnitDropdownMenu = LeaseDropdownMenu;
export const UnitDropdownItem = LeaseDropdownItem;

export const MultiSelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 120px;
`;

export const CustomRow = styled(Row)`
    margin-bottom: 5px;

    label {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #4a5e8a;
    }

    .react-datepicker-wrapper input {
        color: #4a5e8a;
    }
`;
export const CustomFormInput = styled(FormInput)`
    // font-size: 12px;
    height: 36px;
    border-color: ${props => (props.invalid ? 'red' : '#e5e5e5')};
`;
export const CustomLabel = styled(Label)`
    font-size: 12px;
`;
export const DeleteButton = styled(PrimaryButton)`
    width: fit-content;
    border: none;
    box-shadow: none;
    background: none !important;
    color: ${props => props.theme.colors.red}!important;
`;

export const Container = styled.div`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
`;

export const FormSwitch = styled(BaseFormSwitch)`
    background-color: ${props => (props.checked ? props.theme.colors.blue : props.theme.colors.gray400)};
`;
