import produce from 'immer';
import { Action, ActionType, RenewalOfferState, RenewalManagerProps } from './action-types';

const initialState: RenewalOfferState = {
  renewalManagers: [],
};

export const selectRenewalManagers = (state: { renewalOffer: RenewalOfferState }): RenewalManagerProps[] => state.renewalOffer.renewalManagers;

export default produce((state: RenewalOfferState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.CREATE_RENEWAL_MANAGER_SUCCESS:
      state.renewalManagers = [...state.renewalManagers, action.result.data];
      break;

    case ActionType.GET_RENEWAL_MANAGERS_SUCCESS:
      state.renewalManagers = action.result.data.results;
      break;

    case ActionType.UPDATE_RENEWAL_MANAGER_SUCCESS: {
      const { data } = action.result;
      state.renewalManagers = state.renewalManagers.map(el => (el.id === data.id ? { ...el, ...data } : el));
      break;
    }
  }

  return state;
});
