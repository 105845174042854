import { get } from 'lodash';
import { Action, ActionType, CommunityDetails } from './action-types';

interface State {
  communityDetails: CommunityDetails,
  errorMessage: string,
  loading: boolean,
}

const initialState: State = {
  communityDetails: {},
  errorMessage: '',
  loading: false,
};

const actionMap = {
  [ActionType.GET_COMMUNITY_DETAILS_REQUEST]: state => ({ ...state, loading: true }),
  [ActionType.GET_COMMUNITY_DETAILS_SUCCESS]: (state, { result: { data } }) =>
    ({ ...state, loading: false, communityDetails: data }),
  [ActionType.GET_COMMUNITY_DETAILS_FAILURE]: (state, { error }) =>
    ({ ...state, errorMessage: get(error, 'response.status', null), loading: false }),

  [ActionType.UPDATE_COMMUNITY_DETAILS_REQUEST]: state => ({ ...state, loading: true }),
  [ActionType.UPDATE_COMMUNITY_DETAILS_SUCCESS]: (state, { result: { data } }) =>
    ({ ...state, loading: false, communityDetails: data }),
  [ActionType.UPDATE_COMMUNITY_DETAILS_FAILURE]: (state, { error: { response: { status } } }) =>
    ({ ...state, errorMessage: status, loading: false }),
};

export const selectCommunityDetails = (state: { communityDetails: State }): CommunityDetails =>
  state.communityDetails.communityDetails;

export default (state = initialState, action: Action): State => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
