import produce from 'immer';

import { preFillState } from 'dwell/store/utils';
import { LeaseDocumentProps, LeaseDocumentState } from 'leasing/store/types';
import { AddendaState, Addendum, Action, ActionType, AddendaDocumentChoiceProps } from './action-types';

export const initialState: AddendaState = {
  addendaDocuments: [],
  isLoaded: false,
  addendaLoaded: false,
  addendaDocumentsChoices: [],
};

export const selectAddendaIsLoaded = (state : { addenda: AddendaState}) : boolean => state.addenda.addendaLoaded;
export const selectAllAddenda = ((state :{ addenda: AddendaState}) : Addendum [] => state.addenda.addendaDocuments);
export const selectIncludedInAllDocs = ((state : { addenda: AddendaState, leaseDocument: LeaseDocumentState }) : LeaseDocumentProps [] =>
  state.leaseDocument.leaseDocuments.filter(document =>
    (document.type === 'ADDENDUM' && document.status === 'PUBLISHED' && state.addenda.addendaDocuments.find(d => d.lease_document === document.id)))
);
export const selectMasterDocumentsAddenda = ((state : { addenda: AddendaState}) : Addendum [] =>
  state.addenda.addendaDocuments.filter(d => d.is_master));
export const selectMasterDocumentsList = ((state : { addenda: AddendaState, leaseDocument: LeaseDocumentState }) : LeaseDocumentProps [] =>
  state.leaseDocument.leaseDocuments.filter(document => (
    (document.type === 'DEPOSIT_AGREEMENT' || document.type === 'LEASE_AGREEMENT')
    && document.status === 'PUBLISHED'
    && !!state.addenda.addendaDocuments.find(d => d.lease_document === document.id)
  )));
export const selectTotalAddendaDocs = ((state : { addenda: AddendaState, leaseDocument: LeaseDocumentState }) : LeaseDocumentProps [] =>
  state.leaseDocument.leaseDocuments.filter(document => (document.type === 'ADDENDUM' && document.status === 'PUBLISHED')));
export const selectMasterDocsAddenda = ((state : { addenda: AddendaState}) : Addendum [] =>
  state.addenda.addendaDocuments.filter(doc => doc.is_master));

export const selectAddendaDocumentsChoices = (state: { addenda: AddendaState }) : AddendaDocumentChoiceProps[] => state.addenda.addendaDocumentsChoices;

export default produce((state: AddendaState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_ADDENDA_SUCCESS:
      state.addendaDocuments = [
        ...state.addendaDocuments.filter(doc => !action.result.data.find(el => el.id === doc.id)),
        ...action.result.data,
      ];
      break;

    case ActionType.GET_ADDENDA_SUCCESS:
      state.addendaDocuments = action.result.data;
      state.addendaLoaded = true;
      break;

    case ActionType.UPDATE_MASTER_LEASE_DOCUMENT_SUCCESS:
      state.addendaDocuments = [
        ...state.addendaDocuments.filter(doc => !(action.result.data.id === doc.id)),
        action.result.data,
      ];
      break;

    case ActionType.GET_ADDENDA_DOCUMENTS_CHOICES_SUCCESS:
      state.addendaDocumentsChoices = action.result.data;
      break;
  }

  return state;
});
