import produce from 'immer';
import { Action, NotificationProps, ActionType } from './action-types';
import { CommonStateType } from '../types';

export interface NotificationState extends CommonStateType {
  notifications: NotificationProps[];
  notification: NotificationProps;
  allNotificationsCount: number;
  nonReadedNotificationsCount: number;
  isLoaded: boolean;
}

const initialState: NotificationState = {
  isSubmitting: false,
  errorMessage: null,
  notifications: [],
  notification: {} as NotificationProps,
  allNotificationsCount: 0,
  nonReadedNotificationsCount: 0,
  isLoaded: false,
};

export default produce((state: NotificationState = initialState, action: Action): NotificationState => {
  switch (action.type) {
    case ActionType.GET_NOTIFICATIONS_REQUEST:
      state.isSubmitting = true;
      state.isLoaded = false;
      break;
    case ActionType.GET_NOTIFICATIONS_SUCCESS: {
      const notifications = state.notifications
        .filter(p => !action.result.data.results.map(item => item.id).includes(p.id))
        .concat(action.result.data.results)
        .sort((a, b) => Date.parse(b.created) - Date.parse(a.created));

      state.isSubmitting = false;
      state.isLoaded = true;
      state.notifications = notifications.filter(n => n.is_display);
      state.allNotificationsCount = action.result.data.count;
      state.nonReadedNotificationsCount = action.result.data.non_readed_count;
      break;
    }
    case ActionType.GET_NOTIFICATIONS_FAILURE:
      state.isSubmitting = false;
      state.isLoaded = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.CLEAR_AND_GET_NOTIFICATIONS_REQUEST:
      state.isSubmitting = true;
      state.isLoaded = false;
      break;
    case ActionType.CLEAR_AND_GET_NOTIFICATIONS_SUCCESS:
      state.isSubmitting = false;
      state.isLoaded = true;
      state.notifications = action.result.data.results;
      state.allNotificationsCount = action.result.data.count;
      state.nonReadedNotificationsCount = action.result.data.non_readed_count;
      break;
    case ActionType.CLEAR_AND_GET_NOTIFICATIONS_FAILURE:
      state.isSubmitting = false;
      state.isLoaded = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.UPDATE_NOTIFICATION_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_NOTIFICATION_SUCCESS: {
      const { id } = action.result.data;
      const newNotifications = [...state.notifications];
      const idx = newNotifications.findIndex(n => n.id === id);
      if (idx !== -1) {
        newNotifications[idx].is_display = action.result.data.is_display;
        newNotifications[idx].is_read = action.result.data.is_read;
      }
      state.isSubmitting = false;
      state.notification = action.result.data;
      state.notifications = newNotifications;
      break;
    }
    case ActionType.UPDATE_NOTIFICATION_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.BULK_CLEAR_NOTIFICATIONS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.BULK_CLEAR_NOTIFICATIONS_SUCCESS:
      state.isSubmitting = false;
      state.notifications = state.notifications.filter(n => !action.result.data.ids.includes(n.id));
      break;
    case ActionType.BULK_CLEAR_NOTIFICATIONS_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.READ_ALL_NOTIFICATIONS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.READ_ALL_NOTIFICATIONS_SUCCESS:
      state.isSubmitting = false;
      break;
    case ActionType.READ_ALL_NOTIFICATIONS_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;
  }

  return state;
});
