import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { CancelBtn, SaveBtn } from 'dwell/components/leads/lead_share_modal/styles';
import actions from 'dwell/actions';
import { Lead } from 'dwell/store/lead/action-types';
import { getIgnoreCacheHeaders } from 'dwell/views/lead/layout/utils';

interface UnenrollLeadModalProps {
  show: boolean,
  handleClose: () => void,
  lead: Lead,
}

const UnenrollLeadModal: FC<UnenrollLeadModalProps> = ({ show, handleClose, lead }) => {
  const dispatch = useDispatch();
  const { unenrollLead } = actions.lead;
  const { getObjectTasks } = actions.task;
  const { getCommunications } = actions.communication;

  const handleSave = async () => {
    await dispatch(unenrollLead(lead.id));
    await dispatch(getCommunications({ lead: lead.id }));
    await dispatch(getObjectTasks(lead.id, 'lead', undefined, getIgnoreCacheHeaders(lead.id)));
    handleClose();
  };

  const closeBtn = <button className="close" onClick={() => handleClose()}>&times;</button>;

  return (
    <Modal
      isOpen={show}
      centered
      toggle={() => handleClose()}
      size="lg"
      className="lead-share-modal"
    >
      <ModalHeader close={closeBtn}>
        Unenroll Lead
      </ModalHeader>
      <ModalBody>
        <p>
          <strong>{lead.first_name} {lead.last_name}</strong> is currently enrolled in <strong>{lead.active_nurture}</strong>.
          Are you sure you want to manually unenroll them before they complete all steps?
        </p>
      </ModalBody>
      <ModalFooter>
        <CancelBtn className="btn" color="secondary" onClick={() => handleClose()} >Cancel</CancelBtn>
        <SaveBtn className="btn" color="primary" onClick={handleSave}>Yes, unenroll</SaveBtn>
      </ModalFooter>
    </Modal>
  );
};

export default UnenrollLeadModal;
