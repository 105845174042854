import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import actions from 'dwell/actions';
import applicationActions from 'application/store/actions';
import leaseActions from 'leasing/store/actions';
import { selectSelectedProperties, selectProperty, selectProperties } from 'dwell/store/property/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectApplication } from 'application/store/application/reducers';
import { paths } from 'dwell/constants';
import { getPropertyId } from 'src/utils';
import { getApplicationId } from './utils';

const Loader: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const selectedProperties = useSelector(selectSelectedProperties);
  const properties = useSelector(selectProperties);
  const propertyDetails = useSelector(selectProperty);
  const currentUser = useSelector(selectCurrentUser);
  const { applicationDetails: application, shouldReload } = useSelector(selectApplication);
  const { getPropertyDetails, setSelectedProperty } = actions.property;
  const { updateUserAccessedProperties } = actions.user;
  const { getApplicationDetails, setReloadState } = applicationActions.application;
  const { getObjectTasks } = actions.task;
  const { resetLease } = leaseActions.lease;
  const { getAddendaDocumentsChoices } = leaseActions.addenda;

  const load = () => {
    const applicationId = getApplicationId(pathname);
    if (!application.id || (application.id !== applicationId) || shouldReload) {
      dispatch(getApplicationDetails(applicationId)).catch(() => {
        push(paths.client.PIPELINE);
      });
      dispatch(getObjectTasks(applicationId, 'application'));
      dispatch(resetLease());
      dispatch(getAddendaDocumentsChoices());
    }
  };

  useEffect(() => {
    if (propertyDetails.timezone) {
      localStorage.setItem('timezone', propertyDetails.timezone);
    }
  }, [propertyDetails]);

  useEffect(() => {
    const propertyId = getPropertyId();
    if (pathname.split('/')[1] === propertyId) {
      if (!isEmpty(selectedProperties)) {
        if (selectedProperties.map(p => p.external_id).includes(propertyId)) {
          const selectedProperty = selectedProperties.find(p => p.external_id === propertyId);
          dispatch(setSelectedProperty(selectedProperty));
          load();
          if (propertyDetails.external_id === propertyId) return;
          dispatch(getPropertyDetails(selectedProperty.id));
        } else if (properties.length) {
          const nonSelected = properties.find(p => p.external_id === propertyId);
          if (nonSelected) {
            dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: [...selectedProperties, nonSelected] }));
          } else {
            push('/');
          }
        }
      }
    }
  }, [pathname, selectedProperties, properties]);

  useEffect(() => {
    if (shouldReload) {
      load();
      dispatch(setReloadState(false));
    }
  }, [shouldReload]);

  return <></>;
};

export default Loader;
