import produce from 'immer';
import { Action, ActionType, SourceProps } from './action-types';

export interface ProspectSourceState {
  sources: SourceProps[];
}

export const initialState: ProspectSourceState = {
  sources: [],
};

export default produce((state: ProspectSourceState = initialState, action: Action): ProspectSourceState => {
  switch (action.type) {
    case ActionType.GET_SOURCES_SUCCESS:
      state.sources = action.result.data.results;
      break;

    case ActionType.UPDATE_SOURCES_SUCCESS: {
      const updateSourcesList = state.sources.map(source => (source.id === action.result.data.id ? action.result.data : source));
      state.sources = updateSourcesList;
      break;
    }

    case ActionType.UPDATE_SPENDS_SUCCESS: {
      const updatedSourcesList = [...state.sources];
      Object.keys(action.result.data).forEach((key) => {
        const index = updatedSourcesList.findIndex(source => source.id === Number(key));
        updatedSourcesList[index].spends = action.result.data[key];
      });
      state.sources = updatedSourcesList;
      break;
    }
  }

  return state;
});
