import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'dwell/actions';
import { selectCurrentUser } from 'dwell/store/user/reducers';

export const useActivityTracker = (threshold = 300000): void => {
  const { createIdleTimeEvent, updateIdleTimeEvent, updateUserActivity } = actions.idleTime;
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  let eventId: number = null;
  let lastActivity: moment.Moment = null;
  let trackerId: number;

  const activityTimer = () => {
    if (lastActivity === null) {
      lastActivity = moment();
      return;
    }

    const diff = moment().diff(lastActivity);
    if (diff > 60000 && diff < threshold) {
      lastActivity = null;
      dispatch(updateUserActivity(user.id));
    } else if (diff >= threshold) {
      lastActivity = null;
    }
  };

  const inactivityTimer = () => {
    clearTimeout(trackerId);
    const now = moment();

    if (eventId) {
      dispatch(updateIdleTimeEvent(user.id, eventId, now.toISOString()));
      eventId = null;
    } else {
      activityTimer();
    }

    trackerId = setTimeout(() => {
      dispatch(createIdleTimeEvent(user.id, now.toISOString())).then(({ result: { data: { id } } }) => {
        eventId = id;
      });
    }, threshold);
  };

  useEffect(() => {
    if (!user || isEmpty(user)) return () => null;

    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    events.forEach(event => window.addEventListener(event, inactivityTimer));

    inactivityTimer();

    return () => {
      clearTimeout(trackerId);
      events.forEach(event => window.removeEventListener(event, inactivityTimer));
    };
  }, [user]);
};

export default useActivityTracker;
