export default {
  APPLICATION_DETAILS: '/api/v1/leasing/applications/:id/',
  APPLICATION_DECISION: '/api/v1/leasing/applications/:id/decision/',
  ADD_PEOPLES: '/api/v1/leasing/:id/peoples/add_peoples/',
  CANCEL_ADD_PEOPLE: '/api/v1/leasing/:id/peoples/cancel_add_people/',
  BULK_SAVE_PEOPLE: '/api/v1/leasing/:id/peoples/bulk_save/',
  LEASE_CHANGE_FEE: '/api/v1/leasing/:id/peoples/lease_change_fee/',
  MAKE_DECISION: '/api/v1/leasing/:id/peoples/make_decision/',
  RESEND_INVITES: '/api/v1/leasing/:id/peoples/resend_invites/',
  REMOVE_PEOPLES: '/api/v1/leasing/:id/peoples/remove_peoples/',
  TRANSFER_PEOPLES: '/api/v1/leasing/:id/peoples/transfer_peoples/',
  REFRESH_PROFILE: '/api/v1/leasing/:id/peoples/refresh_profile/',
  CANCEL_REFRESH_PROFILE: '/api/v1/leasing/:id/peoples/cancel_refresh_profile/',
  REMOVE_RESIDENTS: '/api/v1/leasing/:id/peoples/remove_residents/',
  CANCEL_REMOVE_RESIDENTS: '/api/v1/leasing/:id/peoples/cancel_remove_residents/',
  RESTORE_PEOPLES: '/api/v1/leasing/:id/peoples/restore_peoples/',
  UPDATE_PEOPLE: '/api/v1/leasing/:id/peoples/update_people/',
  UPLOAD_FILES: '/api/v1/leasing/:id/peoples/upload_files/',
  RESEND_LINKS: '/api/v1/leasing/:id/peoples/resend_links/',
  FLOOR_PLANS_WITH_UNIT: '/api/v1/leasing/portal/floor_plan_with_unit/',
};
