import * as Yup from 'yup';
import { ApplicantFormikProps } from 'leasing/store/types';
import { roleTypes } from 'site/constants';
import regularExpressions from 'site/common/validations/regular_expressions';
import { UserProps } from 'dwell/store/user/action-types';
import { Lead } from 'dwell/store/lead/action-types';

const { CUSTOMER_ADMIN, GENERIC_ADMIN, LIFT_LYTICS_ADMIN, PROPERTY_ADMIN } = roleTypes;

export const getMaskingPhoneNumber = (phone: string) : string => {
  const str = phone?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !str[2] ? str[1] : `(${str[1]}) ${str[2]}${str[3] ? `-${str[3]}` : ''}`;
};

export const getMaskingManyPhoneNumbers = (phones: string) : string => {
  const numbersOnly = phones.replace(/[^\d+]/g, '');
  if (!numbersOnly) return '';
  const arr = numbersOnly.match(/.{1,10}/g);
  return arr.reduce((acc, phone) => {
    if (acc.length) return `${acc}, ${getMaskingPhoneNumber(phone)}`;
    return getMaskingPhoneNumber(phone);
  }, '');
};

export const getMaskingIdentification = (value: string) : string => {
  let val = value?.replace(/\D/g, '');
  val = val?.replace(/^(\d{3})(\d{1,2})/, '$1-$2');
  val = val?.replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3');

  return val?.substring(0, 11);
};

export const permissionTypes = {
  ADDRESS: 'ADDRESS',
  APPLICANT_STEPS: 'APPLICANT_STEPS',
  APPLICATION_STATUS: 'APPLICATION_STATUS',
  DRIVER_LICENCE_NUMBER: 'DRIVER_LICENCE_NUMBER',
  DRIVER_LICENCE_PHOTOS: 'DRIVER_LICENCE_PHOTOS',
  EMAIL: 'EMAIL',
  EMPLOYMENT_N_INCOME: 'EMPLOYMENT_N_INCOME',
  INCOME_DOCUMENTS: 'INCOME_DOCUMENTS',
  PHONE_NUMBER: 'PHONE_NUMBER',
  SCREENING_RESULTS: 'SCREENING_RESULTS',
  SSN: 'SSN',
};

export const permissions = {
  [permissionTypes.ADDRESS]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: false,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: false,
  },
  [permissionTypes.APPLICANT_STEPS]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: true,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: true,
  },
  [permissionTypes.APPLICATION_STATUS]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: true,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: true,
  },
  [permissionTypes.DRIVER_LICENCE_NUMBER]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: false,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: false,
  },
  [permissionTypes.DRIVER_LICENCE_PHOTOS]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: false,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: false,
  },
  [permissionTypes.EMAIL]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: true,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: true,
  },
  [permissionTypes.EMPLOYMENT_N_INCOME]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: true,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: false,
  },
  [permissionTypes.INCOME_DOCUMENTS]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: true,
    [LIFT_LYTICS_ADMIN]: false,
    [CUSTOMER_ADMIN]: false,
  },
  [permissionTypes.PHONE_NUMBER]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: true,
    [LIFT_LYTICS_ADMIN]: true,
    [CUSTOMER_ADMIN]: true,
  },
  [permissionTypes.SCREENING_RESULTS]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: false,
    [LIFT_LYTICS_ADMIN]: false,
    [CUSTOMER_ADMIN]: false,
  },
  [permissionTypes.SSN]: {
    [PROPERTY_ADMIN]: true,
    [GENERIC_ADMIN]: true,
    [LIFT_LYTICS_ADMIN]: false,
    [CUSTOMER_ADMIN]: false,
  },
};

export const hasPerm = (currentUser: UserProps, lead: Lead, perm: string): boolean => {
  if (currentUser.id === lead.owner && GENERIC_ADMIN === currentUser.role) {
    return permissions[perm].PROPERTY_ADMIN;
  }
  return permissions[perm][currentUser.role];
};

const defaultSchema = Yup.object({
  permission: Yup.object().shape({
    driver_licence_photos: Yup.boolean().default(false),
    first_street_address: Yup.boolean().default(false),
  }),

  applicant: Yup.object().shape({
    first_name: Yup.string().nullable(true).required('First name is required.').matches(regularExpressions.name, 'First name is invalid.'),
    last_name: Yup.string().nullable(true).required('Last name is required.').matches(regularExpressions.name, 'Last name is invalid.'),
    email: Yup.string().nullable(true).required('Email address is required.').matches(regularExpressions.email, 'Email address is invalid.'),
    phone_number: Yup.string().nullable(true).required('Phone number is required.').matches(regularExpressions.phoneNumber, 'Phone number is invalid.'),
    birth_date: Yup.string().nullable(true).required('Birth date is required.'),

    ssn_identification_id: Yup.string().nullable(true).when(['showAll', 'identification_type'], {
      is: (showAll, identificationType) => showAll && identificationType === 'SSN',
      then: Yup.string().required('SSN is required.').matches(regularExpressions.SSN, 'SSN is invalid'),
      otherwise: null,
    }),
    itin_identification_id: Yup.string().nullable(true).when(['showAll', 'identification_type'], {
      is: (showAll, identificationType) => showAll && identificationType === 'ITIN',
      then: Yup.string().required('ITIN is required.').matches(regularExpressions.SSN, 'ITIN is invalid'),
      otherwise: null,
    }),
    monthly_payment: Yup.string().nullable(true).when('showAll', {
      is: true,
      then: Yup.string().required('Monthly payment is required.'),
      otherwise: null,
    }),
    country: Yup.string().nullable(true).when(['showAll', 'is_residence_owned'], {
      is: (showAll, isResidenceOwned) => showAll && !isResidenceOwned,
      then: Yup.string().required('Country is required.'),
      otherwise: null,
    }),
    zip_or_postal_code: Yup.string().nullable(true).when(['showAll', 'is_residence_owned'], {
      is: (showAll, isResidenceOwned) => showAll && !isResidenceOwned,
      then: Yup.string().required('Zip/Postal code is required.'),
      otherwise: null,
    }),
    first_street_address: Yup.string().nullable(true).when(['showAll', 'is_residence_owned', 'permission.first_street_address'], {
      is: (showAll, isResidenceOwned, hasPermission) => showAll && !isResidenceOwned && hasPermission,
      then: Yup.string().required('First street address is required.'),
      otherwise: null,
    }),
    state: Yup.string().nullable(true).when(['showAll', 'is_residence_owned'], {
      is: (showAll, isResidenceOwned) => showAll && !isResidenceOwned,
      then: Yup.string().required('State/Province is required.'),
      otherwise: null,
    }),
    city_name: Yup.string().nullable(true).when(['showAll', 'is_residence_owned'], {
      is: (showAll, isResidenceOwned) => showAll && !isResidenceOwned,
      then: Yup.string().required('City is required.'),
      otherwise: null,
    }),
    current_residence_move_in_date: Yup.string().nullable(true).when(['showAll', 'is_residence_owned', 'type'], {
      is: (showAll, isResidenceOwned, type) => showAll && !isResidenceOwned && type !== 'GUARANTOR',
      then: Yup.string().required('Move-in date is required.'),
      otherwise: null,
    }),
    landlord_name: Yup.string().nullable(true).when(['showAll', 'is_residence_owned', 'type'], {
      is: (showAll, isResidenceOwned, type) => showAll && !isResidenceOwned && type !== 'GUARANTOR',
      then: Yup.string().required('Landlord name is required.'),
      otherwise: null,
    }),
    landlord_phone: Yup.string().nullable(true).when(['showAll', 'is_residence_owned', 'type'], {
      is: (showAll, isResidenceOwned, type) => showAll && !isResidenceOwned && type !== 'GUARANTOR',
      then: Yup.string().required('Landlord phone is required.').matches(regularExpressions.phoneNumber, 'Landlord phone number is invalid.'),
      otherwise: null,
    }),
    driver_licence_number: Yup.string().nullable(true).when('showAll', {
      is: true,
      then: Yup.string().required('Driver licence number is required.').max(30, 'Driver license number is invalid. Must be less than or equal to 30 characters'),
      otherwise: null,
    }),
    driver_licence_state: Yup.string().nullable(true).when('showAll', {
      is: true,
      then: Yup.string().required('Driver licence state is required.'),
      otherwise: null,
    }),
    driver_licence_photo_back: Yup.string().nullable(true).when(['showAll', 'permission.driver_licence_photos'], {
      is: (showAll, hasPermission) => showAll && hasPermission,
      then: Yup.string().required('Driver licence photo back is required.'),
      otherwise: null,
    }),
    driver_licence_photo_front: Yup.string().nullable(true).when(['showAll', 'permission.driver_licence_photos'], {
      is: (showAll, hasPermission) => showAll && hasPermission,
      then: Yup.string().required('Driver licence photo front is required.'),
      otherwise: null,
    }),
    emergency_contact: Yup.object().when('type', {
      is: type => type !== 'GUARANTOR',
      then: Yup.object().shape({
        first_name: Yup.string().nullable(true).required('First name is required.').matches(regularExpressions.name, 'First name is invalid.'),
        last_name: Yup.string().nullable(true).required('Last name is required.').matches(regularExpressions.name, 'Last name is invalid.'),
        phone_number: Yup.string().nullable(true).required('Phone number is required.').matches(regularExpressions.phoneNumber, 'Phone number is invalid.'),
        relationship: Yup.string().nullable(true).required('Relationship is required.'),
      }),
      otherwise: null,
    }),
  }),

  employedIncomes: Yup.array().when(['applicant.showAll'], {
    is: true,
    then: Yup.array().of(Yup.object().shape({
      employer_name: Yup.string().nullable(true).required('Employer name is required.'),
      employment_start_date: Yup.string().nullable(true).required('Employer start date is required.'),
      income_category: Yup.string().nullable(true).required('Income category is required.'),
      industry: Yup.string().nullable(true).required('Industry is required.'),
      monthly_income: Yup.string().nullable(true).required('Monthly income is required.'),
      position: Yup.string().nullable(true).required('Position is required.'),
      supervisor_name: Yup.string().nullable(true).required('Supervisor name is required.'),
      supervisor_email: Yup.string().nullable(true).required('Supervisor email is required')
        .matches(regularExpressions.email, 'Email address is invalid.'),
    })),
    otherwise: null,
  }),

  otherIncomes: Yup.array().when(['applicant.showAll'], {
    is: true,
    then: Yup.array().of(Yup.object().shape({
      income_category: Yup.string().nullable(true).required('Income category is required.'),
      monthly_income: Yup.string().nullable(true).required('Monthly income is required.'),
    })),
  }),

  dependents: Yup.array().of(Yup.object().shape({
    first_name: Yup.string().nullable().required('First name is required.').matches(regularExpressions.name, 'First name is invalid'),
    last_name: Yup.string().nullable().required('Last name is required.').matches(regularExpressions.name, 'Last name is invalid'),
    birth_date: Yup.string().nullable().required('Birth date is required.'),
  })),

  pets: Yup.array().of(Yup.object().shape({
    breed: Yup.string().nullable().required('Required'),
    name: Yup.string().nullable().required('Required'),
    pet_type: Yup.string().nullable().required('Required'),
    weight: Yup.number().nullable().required('Required'),
  })),

  vehicles: Yup.array().of(Yup.object().shape({
    license_plate_number: Yup.string().nullable().required('Required'),
    license_plate_state: Yup.string().nullable().required('Required'),
    make: Yup.string().nullable().required('Required'),
    model: Yup.string().nullable().required('Required'),
    year: Yup.string().nullable().required('Required'),
  })),
});

const newUserSchema = Yup.object({
  applicant: Yup.object().shape({
    first_name: Yup.string().required('First name is required.').matches(regularExpressions.name, 'First name is invalid.'),
    last_name: Yup.string().required('Last name is required.').matches(regularExpressions.name, 'Last name is invalid.'),
    email: Yup.string().required('Email address is required.').matches(regularExpressions.email, 'Email address is invalid.'),
    phone_number: Yup.string().nullable(true).required('Phone number is required.').matches(regularExpressions.phoneNumber, 'Phone number is invalid.'),
    birth_date: Yup.string().nullable(true).required('Birth date is required.'),
    should_invite: Yup.boolean().required('Required').oneOf([true], 'Field should be checked'),
  }),
});

export const validationSchema = Yup.lazy((value: ApplicantFormikProps) => {
  if (value.applicant.id) {
    return defaultSchema;
  }
  return newUserSchema;
});
