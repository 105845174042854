import styled, { css } from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { ButtonLikeAnchor, scrollText } from 'styles/common';

export const Header = styled.div`
  ${props =>
    props.isViewSideBar &&
    css`
      margin-left: 64px;
    `}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: ${props => props.theme.colors.white};
  z-index: ${props => (props.isGalleryPreviewOpen ? '700' : '800')};
  display: flex;
  justify-content: flex-end;
  transition: all 0.25s;
  border-bottom: 1px solid ${props => props.theme.colors.colorbg02};
  box-shadow: ${props => (props.profileIsOpened ? 'none' : '0 1px 1px rgba(240, 242, 249, 0.25), 0 2px 2px rgba(240, 242, 249, 0.2), 0 4px 4px rgba(240, 242, 249, 0.15), 0 8px 8px rgba(240, 242, 249, 0.1), 0 16px 16px rgba(240, 242, 249, 0.05)')};
`;

export const HeaderBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const PropertyLogoDiv = styled.div`
  width: 45px;
  margin-right: 15px;
  display: ${props => (props.hide ? 'none !important' : 'block')};
`;

export const PropertyLogoImg = styled.img`
  max-width: 100%;
  height: auto;
`;

export const PropertyGroupUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
`;

export const PropertyGroupUlV2 = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const PropertyLink = styled(ButtonLikeAnchor)`
  max-width: 150px;
  height: 34px;
  padding: 6px 10px 5px;
  display: block;
  border: 1px solid ${props => props.theme.colors.colorbg03};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.colortx01};
  letter-spacing: -0.2px;
  white-space: nowrap;
  line-height: 1.55;

  &:hover {
    color: ${props => props.theme.colors.colortx01};
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.overflow {
    display: block;
    overflow: hidden;
  }

  &.overflow:not(:hover) {
    text-overflow: ellipsis;
  }

  &.overflow:hover span,
  &.overflow:focus span {
    display: inline-block;
    animation: ${scrollText} 8s ease 0s 2 normal;
  }

  & span:first-child {
    display: ${props => (props.minimized ? 'none' : 'inline')};
  }

  & span:last-child {
    display: ${props => (props.minimized ? 'inline' : 'none')};
  }
`;

export const PropertyMenuLink = css`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #f7f8fc !important;
  border: 1px solid #d5dcf4;
  border-radius: 4px;
  color: #344563;
  margin-left: 5px;
  font-size: 13px;
  transition: all 0.25s;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    & {
      padding: 5px 10px;
      width: auto;
      height: auto;
      display: block;
      font-size: 0.875rem;
    }
  }

  &:hover,
  &:focus {
    color: #344563;
    border-color: #b5c1ec;
    box-shadow: 0 1px 1px rgba(213, 220, 244, 0.25), 0 2px 2px rgba(213, 220, 244, 0.2), 0 4px 4px rgba(213, 220, 244, 0.15), 0 8px 8px rgba(213, 220, 244, 0.1), 0 16px 16px rgba(213, 220, 244, 0.05);
  }
  & i {
    line-height: 1;
    font-size: 18px;
  }
  @media (min-width: 992px) {
    & i {
      display: none;
    }
  }
  & span {
    display: none;
  }
  @media (min-width: 992px) {
    & span {
      display: inline-block;
    }
  }
`;

export const PropertyMenuLinkButton = styled(ButtonLikeAnchor)`
  ${PropertyMenuLink}
`;

export const PropertyLinkV2 = styled(ButtonLikeAnchor)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0168fa;
  border: 1px solid #0168fa;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.25s;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    & {
      justify-content: flex-start;
      padding: 5px 10px;
      width: auto;
      max-width: 95px;
      height: auto;
      font-size: 0.875rem;
    }
  }

  &:hover,
  &:focus {
    color: #0168fa;
    border-color: #0168fa;
    box-shadow: 0 1px 1px rgba(213, 220, 244, 0.25), 0 2px 2px rgba(213, 220, 244, 0.2), 0 4px 4px rgba(213, 220, 244, 0.15), 0 8px 8px rgba(213, 220, 244, 0.1), 0 16px 16px rgba(213, 220, 244, 0.05);
  }

  & span:first-child {
    display: none;
  }
  @media (min-width: 992px) {
    & span:first-child {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & span:last-child {
    text-transform: uppercase;
  }
  @media (min-width: 992px) {
    & span:last-child {
      display: none;
    }
  }

  &.overflow:hover span,
  &.overflow:focus span {
    width: auto;
    text-overflow: inherit;
    overflow: inherit;
    animation: ${scrollText} 8s ease 0s 2 normal;
  }
`;

export const PropertyLinkV3 = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #233457;
  border: 1px solid #d5dcf4;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.25s;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    & {
      justify-content: flex-start;
      padding: 5px 10px;
      width: auto;
      max-width: ${props => (props.isOneDisplayed ? '393px' : '120px')};
      height: auto;
      font-size: 0.875rem;
    }
  }
  &:hover,
  &:focus {
    color: #15274d;
    border-color: #c1cbef;
    box-shadow: 0 1px 1px rgba(213, 220, 244, 0.25), 0 2px 2px rgba(213, 220, 244, 0.2), 0 4px 4px rgba(213, 220, 244, 0.15), 0 8px 8px rgba(213, 220, 244, 0.1), 0 16px 16px rgba(213, 220, 244, 0.05);
  }
  & span:first-child {
    display: none;
  }
  @media (min-width: 992px) {
    & span:first-child {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & span:last-child {
    text-transform: uppercase;
  }
  @media (min-width: 992px) {
    & span:last-child {
      display: none;
    }
  }
  &.overflow:hover span,
  &.overflow:focus span {
    width: auto;
    text-overflow: inherit;
    overflow: inherit;
  }
`;

export const PropertyRemove = styled(ButtonLikeAnchor)`
  width: 28px;
  height: 34px;
  border: 1px solid ${props => props.theme.colors.colorbg03};
  margin-left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.bodyColor};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: none;

  & i {
    font-size: 18px;
    opacity: 0.6;
  }

  &:hover {
    color: ${props => props.theme.colors.lightblack};
  }
`;

export const PropertyActionsButton = styled(ButtonLikeAnchor)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0168fa;
  background-color: #fff;
  border: 1px solid #0168fa;
  transition: all 0.25s;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  &:hover,
  &:focus {
    background-color: #f1f7ff;
  }
  & + & {
    border-left-width: 0;
  }
`;

export const PropertyActions = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(231, 241, 255, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  & ${PropertyActionsButton}:first-child {
    flex: 1;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  & ${PropertyActionsButton}:last-child {
    width: 25px;
    font-size: 20px;
    padding-bottom: 1.5px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const PropertyItemMore = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px 5px;
  height: 34px;
  background-color: rgba(1, 104, 250, 0.08) !important;
  border: 1px solid rgba(11, 33, 81, 0.2) !important;
  border-radius: 4px;
  color: ${props => props.theme.colors.colortx02};
  & span {
    font-weight: 500;
  }
  &:focus {
    outline: 0;
  }
`;

export const PropertyItemMoreV2 = styled(DropdownToggle)`
  ${PropertyMenuLink}
  & span:last-child {
    display: inline;
  }
`;

export const PIDropdownMenu = styled(DropdownMenu)`
  max-height: 600px;
  overflow-y: auto;
  border-radius: 4px;
  border-color: ${props => props.theme.input.borderColor};
  margin-top: 12px;
  margin-left: -10px;
  padding: 10px;
  box-shadow: 2px 5px 45px rgba(36, 55, 130, 0.12), 0 1px 2px rgba(225, 230, 247, 0.07), 0 2px 4px rgba(225, 230, 247, 0.07), 0 4px 8px rgba(225, 230, 247, 0.07), 0 8px 16px rgba(225, 230, 247, 0.07), 0 16px 32px rgba(225, 230, 247, 0.07),
    0 32px 64px rgba(225, 230, 247, 0.07);
  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 22px;
    border-bottom: 7px solid ${props => props.theme.colors.white};
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    top: -8.5px;
    left: 20.5px;
    border-bottom: 8.5px solid ${props => props.theme.input.borderColor};
    border-left: 8.5px solid transparent;
    border-right: 8.5px solid transparent;
  }
`;

export const PIDropdownMenuV2 = styled(DropdownMenu)`
  max-height: 600px;
  overflow-y: auto;
  padding: 5px;
  min-width: 200px;
  border-radius: 4px;
  border-width: 0;
  box-shadow: 0 1px 1px rgba(204, 206, 217, 0.25), 0 2px 2px rgba(204, 206, 217, 0.2), 0 4px 4px rgba(204, 206, 217, 0.15), 0 8px 8px rgba(204, 206, 217, 0.1), 0 16px 16px rgba(204, 206, 217, 0.05);
`;

export const DropdownItem = styled.div`
  padding: 7px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.lightblack};

  &:hover,
  &:focus {
    background-color: inherit;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & + & {
    border-top: 1px solid ${props => props.theme.colors.colorbg01};
  }

  span {
    padding-right: 20px;
    cursor: pointer;
  }

  span:hover {
    color: ${props => props.theme.colors.colorui01};
  }

  ${PropertyRemove} {
    width: auto;
    height: auto;
    border: 0;
    display: block;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.2;
    color: ${props => props.theme.colors.colortx03};
    transition: all 0.3s;
  }

  @media (prefers-reduced-motion: reduce) {
    ${PropertyRemove} a {
      transition: none;
    }
  }

  ${PropertyRemove}:hover, ${PropertyRemove}:focus {
    color: ${props => props.theme.colors.colortx02};
  }
`;

export const GenericDropdownItem = css`
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #091534;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  &:hover,
  &:focus {
    color: #050c1e;
    text-decoration: none;
    background-color: #e9eaf0;
  }
  &.active,
  &:active {
    color: #fff;
    text-decoration: none;
    background-color: #0168fa;
  }
  &.disabled,
  &:disabled {
    color: #657697;
    pointer-events: none;
    background-color: transparent;
  }
`;

export const DropdownItemV2 = styled.div`
  ${GenericDropdownItem}
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.875rem;
  color: #15274d;
  border-radius: 3px;
  & > div {
    margin-right: 10px;
  }
  & > div span:last-child {
    display: none;
  }
  & a {
    font-size: 18px;
    line-height: 1;
    color: #657697;
    margin-left: auto;
    display: none;
  }
  & a:hover,
  & a:focus {
    color: #0168fa;
  }
  &:hover .dwp-actions,
  &:focus .dwp-actions {
    opacity: 1;
    visibility: visible;
  }
  & .dwp-actions {
    background-color: transparent;
    left: auto !important;
    right: 5px !important;
  }
`;

const BasePropertyItem = css`
  display: flex;
  align-items: center;

  & + & {
    margin-left: 8px;
  }

  &:last-child ${PropertyLink} {
    background-color: ${props => props.theme.colors.colorbg01};
  }

  &:last-child ${PropertyLink} i {
    font-size: 14px;
    line-height: 1;
    text-shadow: 0 0.5px 0 ${props => props.theme.colors.colortx01};
    margin-right: 6px;
    opacity: 0.6;
  }

  &.removable ${PropertyLink} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.removable ${PropertyRemove} {
    display: flex;
  }

  &:not(.prty-item-all) ${PropertyLink}:hover {
    color: ${props => props.theme.colors.colorui01};
  }

  &.prty-item-all span {
    display: none;
  }

  @media (min-width: 992px) {
    &.prty-item-all span {
      display: inline;
    }
  }

  &.prty-item-all ${PropertyLink} i {
    margin-right: 0;
  }

  @media (min-width: 992px) {
    &.prty-item-all ${PropertyLink} i {
      display: none;
    }
  }
`;

export const PropertyItem = styled.li`
  ${BasePropertyItem}
`;

export const PropertyItemV2 = styled.li`
  overflow: hidden;
  & + & {
    margin-left: 5px;
  }
`;

export const PropertyItemV3 = styled.li`
  position: relative;
  & + & {
    margin-left: 5px;
  }
  &:hover .dwp-actions,
  &:focus .dwp-actions {
    opacity: 1;
    visibility: visible;
  }
  &:hover label,
  &:focus label {
    display: block;
  }
  & label {
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #dfe1e8;
    border-radius: 3px;
    z-index: 1000;
    margin-bottom: 0;
    line-height: 1.3;
    white-space: nowrap;
    padding: 5px 8px;
    font-size: 13px;
    font-wight: 400;
    display: none;
  }
`;

export const PropertyLabel = styled.label``;

export const PIDropdown = styled(Dropdown)`
  ${BasePropertyItem}
  margin-left: 8px;
  margin-right: 8px;
`;

const navPersonalized = css`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;

export const NavDropdown = styled(DropdownMenu)`
  ${navPersonalized}
`;

export const NavUl = styled.ul`
  ${navPersonalized}
`;

export const NavHeader = styled(NavUl)`
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  position: relative;
  & .nav-item + .nav-item {
    margin-left: 40px;
  }
  & .nav-item.show .nav-sub {
    display: flex;
  }
  & .nav-item:first-child {
    margin-right: 5px;
  }
  & .nav-link {
    font-weight: 500;
    color: ${props => props.theme.colors.colortx01};
    letter-spacing: -0.3px;
    position: relative;
    outline: none;
    padding: 0;
  }
  & .nav-link::before {
    content: '';
    position: absolute;
    bottom: -21px;
    left: 0;
    right: 0;
    height: 1.5px;
    background-color: ${props => props.theme.colors.colorui01};
    display: none;
  }
  & .nav-link.active {
    color: ${props => props.theme.colors.colorui01} !important;
  }
  & .nav-link.active::before {
    display: block;
  }
  & .with-sub:after {
    content: '\\EA4E';
    font-family: 'remixicon';
    font-size: 14px;
    font-weight: 400;
    line-height: 0;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-left: 1px;
    opacity: 0.85;
  }
  & .nav-sub {
    flex-direction: column;
    position: absolute;
    background-color: ${props => props.theme.colors.white};
    padding: 8px;
    margin-top: 15px;
    min-width: 180px;
    margin-left: -20px;
    display: none;
    z-index: 100;
    border-radius: 8px;
    box-shadow: 2px 5px 45px rgba(36, 55, 130, 0.12), 0 1px 2px rgba(225, 230, 247, 0.07), 0 2px 4px rgba(225, 230, 247, 0.07), 0 4px 8px rgba(225, 230, 247, 0.07), 0 8px 16px rgba(225, 230, 247, 0.07), 0 16px 32px rgba(225, 230, 247, 0.07),
      0 32px 64px rgba(225, 230, 247, 0.07);
  }
  & .nav-sub-link {
    outline: none;
    padding: 8px 12px;
    color: ${props => props.theme.colors.colortx02};
    border-radius: 5px;
    letter-spacing: -0.3px;
  }
  & .nav-sub-link:hover,
  & .nav-sub-link:focus {
    background-color: ${props => props.theme.colors.bodyBg};
    color: ${props => props.theme.colors.colortx02};
  }
`;

export const NavDot = styled(ButtonLikeAnchor)`
  display: block;
  width: 6px;
  height: 6px;
  background-color: ${props => props.theme.colors.yellow};
  border-radius: 100%;
  margin-top: 1px;
  position: relative;
  z-index: 5;
`;

export const NavTaskToday = styled.li`
  position: absolute;
  top: 15px;
  left: 0;
  transform: translateX(-50%);
  min-width: 290px;
  min-height: 100px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.colorbg02};
  border-radius: 4px;
  padding: 18px 20px 20px;
  box-shadow: 2px 5px 45px rgba(36, 55, 130, 0.12), 0 1px 2px rgba(225, 230, 247, 0.07), 0 2px 4px rgba(225, 230, 247, 0.07), 0 4px 8px rgba(225, 230, 247, 0.07), 0 8px 16px rgba(225, 230, 247, 0.07), 0 16px 32px rgba(225, 230, 247, 0.07),
    0 32px 64px rgba(225, 230, 247, 0.07);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  &.show {
    top: 30px;
    opacity: 1;
    visibility: visible;
  }
`;

export const TaskTodayLabel = styled.label`
  display: block;
  line-height: 1;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 16px;
`;

const NavLinkProperties = css`
  display: block;
  padding: 0.5rem 1rem;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &.disabled {
    color: ${props => props.theme.colors.gray600};
    pointer-events: none;
    cursor: default;
  }
`;

export const NavLink = styled(ButtonLikeAnchor)`
  ${NavLinkProperties}
`;

export const NavLinkToggle = styled(DropdownToggle)`
  ${NavLinkProperties}
  background-color: transparent !important;
  border: none !important;
  text-decoration: none;
  &:hover,
  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: 0 !important;
  }
`;

export const NavSubLink = styled(ButtonLikeAnchor)`
  text-align: left !important;
`;

export const NavUlspace = styled.ul`
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  position: relative;
`;

export const PropertyProfileToggle = styled.a`
  cursor: pointer;
  line-height: 1;
  font-size: 20px;
  margin-right: 12px;
  margin-top: 5px;
  outline: none;
  color: ${props => props.theme.colors.colortx01} !important;
`;

export const PropertyActionsButtonDropdown = styled(ButtonLikeAnchor)`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0168fa;
  background-color: #fff;
  border: 1px solid #0168fa;
  transition: all 0.25s;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  &:hover,
  &:focus {
    background-color: #f1f7ff;
  }
`;

export const PropertyActionsDropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(231, 241, 255, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
  & a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0168fa;
    background-color: #fff;
    border: 1px solid #0168fa;
    transition: all 0.25s;
  }
  @media (prefers-reduced-motion: reduce) {
    & button {
      transition: none;
    }
  }
  & button:hover,
  & a:focus {
    background-color: #f1f7ff;
  }
  & button:first-child {
    flex: none;
    padding: 0 6px;
    font-size: 11px;
    font-weight: 500;
    border-right-width: 0;
    text-transform: uppercase;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  & button:last-child {
    width: 20px;
    height: 25px;
    font-size: 16px;
    padding-bottom: 1.5px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
