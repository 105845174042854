import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from 'dwell/actions/index';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { selectPreSelectedProperties } from 'dwell/store/properties_selector/reducers';
import { selectCurrentUser, selectUserIsCallScorer } from 'dwell/store/user/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { RECENT_PROPERTY_HYPHENS } from 'dwell/constants';
import corporateActions from 'corporate/store/actions';

import { PropertyItem, PropertyItemBody, PMFormCheckbox, PropertyItemLogo, NewBtnOnly, NewPropertyItemLink, PropertyItemLogoImg, NavPropertyItem, Badge } from 'containers/multi_property_selector/styles';
import iframeApi from 'corporate/views/edit_site/iframe';
import { SITE_TYPES } from 'corporate/views/edit_site/edit_panel/component/constants';

interface AvailablePropertyItemProps {
  property: PropertyProps;
}

const AvailablePropertyItem: FC<AvailablePropertyItemProps> = ({ property }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showBtnOnly, setShowBtnOnly] = useState<boolean>(false);

  const currentUser = useSelector(selectCurrentUser);
  const selectedProperties = useSelector(selectSelectedProperties);
  const isCallScorer = useSelector(selectUserIsCallScorer);
  const preSelectedProperties = useSelector(selectPreSelectedProperties);
  const dispatch = useDispatch();
  const { updateUserAccessedProperties } = actions.user;
  const { setShowSelector } = actions.propertiesSelector;
  const { setChatRenderedStatus, clearActiveChats } = actions.prospectChat;
  const { getCustomerInformation, setSiteSelected } = corporateActions.customer;
  const { setPreSelectedProperties, setPropertyKeyword } = actions.propertiesSelector;

  const isPreSelected = preSelectedProperties.some(p => p.id === property.id);

  useEffect(() => {
    if (isPreSelected && !isChecked) {
      setIsChecked(true);
    } else if (!isPreSelected && isChecked) {
      setIsChecked(false);
    }
  }, [isPreSelected]);

  const selectPropertyHandler = (status: boolean) => {
    if (status) {
      if (!preSelectedProperties.includes(property) && !selectedProperties.includes(property)) {
        const newState = [...preSelectedProperties, property];
        dispatch(setPreSelectedProperties(newState));
      }
    } else {
      const newState = preSelectedProperties.filter(item => item.id !== property.id);
      dispatch(setPreSelectedProperties(newState));
    }
  };

  const btnOnlyClickHandler = (siteId: string, propertyId: number) => {
    localStorage.setItem(RECENT_PROPERTY_HYPHENS, siteId);
    dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: [property] }, () => 0)).then(() => {
      dispatch(setPreSelectedProperties([]));
      document.body.classList.remove('scroll_locked');
      dispatch(clearActiveChats());
      dispatch(setChatRenderedStatus(true));
      dispatch(setShowSelector(false));
      dispatch(setPropertyKeyword(''));
      dispatch(getCustomerInformation()).then((data) => {
        const subsites = data.result?.data?.subsites;
        const foundSubsite = subsites?.find(subsite => subsite.id === propertyId);
        if (!foundSubsite) return;
        dispatch(setSiteSelected(foundSubsite, SITE_TYPES.SUBSITE));
        dispatch(corporateActions.editSite.setToolbarNavTab('PAGES'));
        dispatch(corporateActions.section.clearActiveSection());
        if (window.location.pathname.includes('/corporate')) {
          dispatch(corporateActions.editSite.getSubsitePageData()).then(({ result: { data: pages } }) => {
            const homePage = pages.find(page => page.page === 'HOME') || pages[0];
            dispatch(corporateActions.editSite.setActivePage(homePage.id));
            dispatch(corporateActions.section.setSections(pages.find(_page => _page.id === homePage.id).sections));
            iframeApi.navigateToPage(pages.find(_page => _page.id === homePage.id).page, SITE_TYPES.SUBSITE, propertyId);
          });
        }
      });
    });
  };

  return (
    <PropertyItem onMouseEnter={() => setShowBtnOnly(true)} onMouseLeave={() => setShowBtnOnly(false)}>
      <PMFormCheckbox className="mg-r-10" checked={isChecked} onClick={() => selectPropertyHandler(!isChecked)} />
      <PropertyItemLogo onClick={() => selectPropertyHandler(!isChecked)}>
        {property && property.logo ? <PropertyItemLogoImg src={property.logo} alt="PLogo" /> : <i className="ri-home-8-line" />}
        {!isCallScorer && !!property.unread_count && <Badge className="badge">{property.unread_count > 99 ? '99+' : property.unread_count}</Badge>}
      </PropertyItemLogo>
      <PropertyItemBody onClick={() => selectPropertyHandler(!isChecked)}>
        <h6 className={property.name.length > 15 ? 'overflow' : ''}>
          <span>{property.name}</span>
        </h6>
        {property.status === 'ACTIVE' ? <p>{property.town}</p> : <p>{`${property.status}`}</p> }
      </PropertyItemBody>
      <NavPropertyItem show={showBtnOnly}>
        <NewBtnOnly onClick={() => btnOnlyClickHandler(property.external_id, property.id)}>
          Only
        </NewBtnOnly>
        <NewPropertyItemLink id={`site-${property.id}`} href={`http://${property.domain}`} title="Visit site" target="_blank" onClick={e => e.stopPropagation()}>
          <i className="ri-external-link-line" />
        </NewPropertyItemLink>
      </NavPropertyItem>
    </PropertyItem>
  );
};

export default AvailablePropertyItem;
