export const applicationStatus = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  TEST: 'Test',
};

export const personStatus = {
  PENDING: 'Pending',
  CURRENT: 'Current',
  FORMER: 'Former',
};

export const personType = {
  PRIMARY_APPLICANT: 'Primary Applicant',
  CO_APPLICANT: 'Co Applicant',
  GUARANTOR: 'Guarantor',
  DEPENDENT: 'Dependent',
};

export const leaseType = {
  NEW_LEASE: 'New Lease',
  RENEWAL: 'Renewal',
  TRANSFER: 'Transfer',
  LEASE_CHANGE: 'Lease Change',
};

export const paymentMethods = {
  CERTIFIED_CHECK: 'Certified check',
  BANK_CHECK: 'Bank check',
  E_CHECK: 'E-check',
  CREDIT_CARD: 'Credit card',
  DEBIT_CARD: 'Debit card',
};

export const utilities = {
  ELECTRIC: 'Electric',
  WATER: 'Water',
  TRASH: 'Trash',
  GAS: 'Gas',
  SEWER: 'Sewer',
  RENTERS_INSURANCE: 'Renters insurance',
  VALET_TRASH_SERVICE: 'Valet trash service',
};

export const securityDepositTypes = {
  TRADITIONAL: 'Traditional',
  E_PREMIUM: 'ePremium',
  USD: 'USD',
};

export const concessionTypes = {
  CONCESSION: 'Concession',
  VOUCHER: 'Voucher',
};

export const concessionMonths = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const petTypes = {
  CAT: 'Cat',
  DOG: 'Dog',
  BIRD: 'Bird',
  OTHER: 'Other',
};

export const petSexes = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

export const parkingTypes = {
  CARPORT: 'Carport',
  UNCOVERED: 'Uncovered',
};

export const garageTypes = {
  GARAGE: 'Garage',
  DETACHED_GARAGE: 'Detached Garage',
};

export const garageTerms = {
  SAME_AS_LEASE_TERM: 'Same as lease term',
  MONTH_TO_MONTH: 'Month-to-month',
  OTHER: 'Other',
};

export const storageTerms = {
  SAME_AS_LEASE_TERM: 'Same as lease term',
  MONTH_TO_MONTH: 'Month-to-month',
  OTHER: 'Other',
};

export const gateKeyTypes = {
  GATE_ENTRY_KEY_FOB: 'Gate Entry Key Fob',
  GARAGE_DOOR_OPENER: 'Garage Door Opener',
  FITNESS_CENTER_KEY: 'Fitness Center Key',
};

export const leaseTermChoice = Array.from({ length: 24 }).reduce((acc, _, i) => {
  acc[i + 1] = `${i + 1} month${i !== 0 ? 's' : ''}`;
  return acc;
}, {}) as ({ [key: string]: string });

export const decisionChoice = {
  APPROVED: {
    label: 'Approved',
    colorClass: 'text-success ri-checkbox-circle-fill',
    displayText: 'Approved',
    description: 'Application group meet all of your rental criteria requirements',
  },
  CONDITIONALLY_APPROVED: {
    label: 'Conditionally Approved',
    colorClass: 'text-warning ri-error-warning-fill',
    displayText: 'Conditionally Approved',
    description: 'Application group meet most of your rental criteria requirements',
  },
  DENIED: {
    label: 'Declined',
    colorClass: 'text-danger ri-forbid-2-fill',
    displayText: 'Declined',
    description: 'Application group does not meet your rental criteria requirements',
  },
};

export const stageChoice = {
  APPLICATION_STARTED: 'Application Started',
  APPLICATION_SUBMITTED: 'Application Submitted',
  WAITLISTED: 'Waitlisted',
  APPLICATION_CANCELED: 'Application Canceled',
  DEPOSIT_AGREEMENT_SENT: 'Deposit Agreement Sent',
  DEPOSIT_AGREEMENT_SIGNED: 'Deposit Agreement Signed',
  LEASE_SENT: 'Lease Sent',
  LEASE_SIGNED: 'Lease Signed',
};

export const statusChoice = {
  ACTIVE: { title: 'Active', text: 'Active applications impact reporting metrics, surface on the \n Pipeline page and all associated tasks are active for \n completion.' },
  CANCELED: { title: 'Cancel', text: 'Canceled applications impact reporting metrics, do not surface as active on the Pipeline page and all associated tasks are deleted.' },
  TEST: { title: 'Test', text: 'Test applications do not impact reporting, do not surface on the \n Pipeline page and associated tasks are not listed as a \n part of the Tasks page or daily team tasking.' },
};

export const factorsSectionChoice = {
  income: 'Income Report',
  credit: 'Credit Report',
  criminal: 'Criminal Report',
  eviction: 'Eviction Report',
};

export const factorsCriteriaFields = {
  income: 'income_criteria',
  credit: 'credit_criteria',
  criminal: 'criminal_criteria',
  eviction: 'eviction_criteria',
};

export const screeningMenuChoice = {
  SCREEN_GROUP: {
    title: 'Screen Group',
    text: 'Use current resident details to run screening for resident group.',
    url: '/screen-group',
    hide: false,
  },
  RESCREEN_GROUP: {
    title: 'Rescreen Group',
    text: 'Use current resident details to rerun screening for resident group.',
    url: '/rescreen-group',
    hide: false,
  },
};

export const editInviteDetailsChoice = {
  EDIT_INVITE_DETAILS: {
    title: 'Edit Invite Details',
    text: 'Edit details and resend application invite',
  },
};

export const screeningCriminalChoice = {
  YES: 'Yes',
  NO: 'No',
  REFER: 'Refer',
  PENDING: 'Pending',
  SKIPPED: 'Skipped',
};

export const screeningEvictionChoice = {
  YES: 'Yes',
  NO: 'No',
  REFER: 'Refer',
  PENDING: 'Pending',
  SKIPPED: 'Skipped',
};

export const stepDescriptions = {
  APPLICATION_INVITE_SENT: {
    title: 'Application Invite Sent',
    description: 'Invite sent but still needs to create account, submit application, and pay fees.',
  },
  PETS_AND_VEHICLES: {
    title: 'Pets & Vehicles',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  PERSONAL_INFO: {
    title: 'Personal Information',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  PEOPLE: {
    title: 'People',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  INCOME: {
    title: 'Employment & Income',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  OTHER_INCOME: {
    title: 'Other Income',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  INVITE_APPLICANTS: {
    title: 'Invite Applicants',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  INCOMPLETE: {
    title: 'Incomplete Fields',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  PAY: {
    title: 'Pay & Finish',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  REVIEW: {
    title: 'Review Application',
    description: 'Application started but still needs to submit application and pay fees.',
  },
  SIGN: {
    title: 'Sign Application',
    description: 'Application started but still needs to submit application and pay fees.',
  },
};

export const actionMenuChoice = {
  ADD_PEOPLE: {
    title: 'Add People',
    text: 'Add people to application group.',
    url: '/add-people',
    hide: false,
  },
  REMOVE_PEOPLE: {
    title: 'Remove People',
    text: 'Remove people from application group.',
    url: '/remove-people',
    hide: false,
  },
  TRANSFER_PEOPLE: {
    title: 'Transfer People',
    text: 'Transfer people to a new application group.',
    url: '/transfer-people',
    hide: false,
  },
  FRESH_PROFILE_DETAILS: {
    title: 'Refresh Profile Details',
    text: 'Request people in group to refresh profile details.',
    url: '/refresh-profile',
    hide: false,
  },
  RESTORE_PEOPLE: {
    title: 'Restore People',
    text: 'Restore people back to application.',
    url: '/restore-people',
    hide: false,
  },
};

export const leaseActionMenuChoice = {
  LEASE_CHANGE: {
    title: 'Lease Change',
    text: 'Change lease details associated with application group.',
    url: '/change',
    hide: false,
  },
  TRANSFER_UNIT: {
    title: 'Transfer Unit',
    text: 'Transfer application group and associated lease details to a new unit.',
    url: '/transfer-unit',
    hide: false,
  },
  RELEASE_UNIT: {
    title: 'Release Unit',
    text: 'Manually release hold on unit and put the unit back on market.',
    url: '/release-unit',
    hide: false,
  },
};

export const typeChoice = {
  PRIMARY_APPLICANT: 'Primary Applicant',
  CO_APPLICANT: 'Co-Applicant',
  GUARANTOR: 'Guarantor',
  DEPENDENT: 'Dependent',
};

export const incomeStatusChoice = {
  NOT_SET: 'Not Set',
  VERIFIED: 'Verified',
  STATED: 'Stated',
};

export const identificationTypes = {
  SSN: 'SSN',
  ITIN: 'ITIN',
};

export const countries = {
  UNITED_STATES: 'United States',
  CANADA: 'Canada',
};

export const countriesShort = {
  US: 'United States',
  CA: 'Canada',
};

export const USStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

const CanadaStates = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const AllStates = [...Object.keys(USStates), ...Object.keys(CanadaStates)].sort().reduce(
  (acc, key) => ({ ...acc, [key]: key in CanadaStates ? CanadaStates[key] : USStates[key] }),
  {},
);

export const states = {
  US: USStates,
  CA: CanadaStates,
  ALL: AllStates,
  UNITED_STATES: USStates,
  CANADA: CanadaStates,
};

export const relationshipTypes = {
  SPOUSE: 'Spouse',
  FRIEND: 'Friend',
  PARTNER: 'Partner',
  PARENT: 'Parent',
  ADULT_CHILD: 'Adult Child',
  ADULT_RELATIVE: 'Adult Relative',
};

export const phoneTypes = {
  MOBILE: 'Mobile',
  LANDLINE: 'Landline',
};

export const screeningRecommendationChoice = {
  APPROVE: {
    name: 'Approve',
    description: 'This application group meets all of your rental criteria requirements.',
  },
  PENDING: {
    name: 'Pending',
    description: 'Awaiting all screening results to application group.',
  },
  PENDING_APPLICATION: {
    name: 'Pending',
    description: 'Awaiting all application submissions and screening results to application group.',
  },
  APPROVE_WITH_CONDITIONS: {
    name: 'Approve with Conditions',
    description: 'This application group meets some of your rental criteria requirements.',
  },
  DECLINE: {
    name: 'Decline',
    description: 'This application group does not meet your rental criteria requirements and has at least 1 disqualifying factor.',
  },
  REFER: {
    name: 'Refer',
    description: 'This application group does not have sufficient information to make a firm recommendation.',
  },
};

export const screeningStatusChoice = {
  SUCCESS: 'Success',
  PROCESSING: 'Processing',
  PENDING: 'Pending',
  ERROR: 'Error',
};

export const hasFilterChoices = {
  YES: 'Yes',
  NO: 'No',
};

export const declineReasons = {
  POOR_CREDIT: 'Poor Credit',
  INSUFFICIENT_INCOME: 'Insufficient Income',
  CRIMINAL_RECORD: 'Criminal Record',
  EVICTION_RECORD: 'Eviction Record',
};
