import produce from 'immer';
import { Action, ActionType, SurveyProps } from './action-types';

export interface SurveyState {
  surveys: SurveyProps[];
}

export const initialState: SurveyState = {
  surveys: [],
};

export default produce((state: SurveyState = initialState, action: Action): SurveyState => {
  switch (action.type) {
    case ActionType.GET_SURVEYS_SUCCESS:
      state.surveys = action.result.data.results;
      break;

    case ActionType.UPDATE_SURVEYS_SUCCESS:
      state.surveys = action.result.data;
      break;
  }

  return state;
});
