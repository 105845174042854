import { FloorPlanTypes } from 'dwell/store/property_profile/action-types';

interface SlotsType {
  bedrooms: number,
  bathrooms: number,
  available: boolean,
  min_rent: number,
  max_rent: number,
  price_modifier: string,
}

interface UnitsResponse {
  [unit_type: string]: FloorPlanTypes
}

const extractContent = (s) => {
  const span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

const getClipboard = (responseType: string, message: string, noNeedClipboard: boolean): string => {
  let clipboard = '';
  if (noNeedClipboard) {
    clipboard = '';
  } else if (responseType === 'SISTER_COMMUNITIES') {
    let data;
    try {
      data = JSON.parse(message);
    } catch (e) { data = []; }
    data.forEach((location, index) => {
      if (index !== 0) clipboard += '\n';
      clipboard += `Our sister community ${location.name} may be a better fit for you. \n\n` +
        'Property Info:\n' +
        `• Address: ${location.community_address}\n` +
        `• Neighborhood: ${location.neighborhood}\n` +
        `• Distance from ${location.source_property}: ${location.distance_from_property}\n` +
        `• Website: https://${location.domain}\n` +
        `• Phone: ${location.phone_number}\n\n`;
      Object.keys(location.unit_types).forEach((unit_type, ind) => {
        if (ind === 0) clipboard += 'Unit Info:\n';
        const value = location.unit_types[unit_type];
        const label = unit_type === '0' ? 'Studios' : `${unit_type} Beds`;
        clipboard += `• ${label}: staring at ${value.starting_at}\n`;
      });
    });
  } else if (responseType === 'PROMOTION') {
    let data;
    try {
      data = JSON.parse(message);
    } catch (e) { data = {}; }
    clipboard = `${data.name}\n${extractContent(data.promotion_html.replace('<p></p>', '\n'))}`;
    if (data.restriction) {
      clipboard += `\n\nRestrictions\n${data.restriction}`;
    }
    if (data.lease_duration) {
      clipboard += '\n\nApplicable Lease Terms';
      if (data.lease_duration_modifier === 'All months') clipboard += '\nMust sign a lease of all months.';
      if (data.lease_duration_modifier === 'Less than') clipboard += `\nMust sign a lease of ${data.lease_duration} or less months.`;
      if (data.lease_duration_modifier === 'More than') clipboard += `\nMust sign a lease of ${data.lease_duration} or more months.`;
      if (data.lease_duration_modifier === 'Exactly') clipboard += `\nMust sign a lease of ${data.lease_duration} months.`;
    }
    if (data.unit_types) {
      clipboard += '\n\nApplicable Units\n';
      const map = { STUDIO: 'Studio', ONE_BEDROOM: '1 Bed', TWO_BEDROOM: '2 Bed', THREE_BEDROOM: '3 Bed' };
      clipboard += data.unit_types.map(i => map[i]).join(', ');
    }
  } else {
    clipboard = message;
  }
  return clipboard.replace(/<b>/g, '').replace(/<\/b>/g, '')
    .replace('/<i>/g', '').replace(/<\/i>/g, '');
};

const getUnitsResponse = (slots: SlotsType, floorPlans: FloorPlanTypes[]): UnitsResponse => {
  const { bedrooms, bathrooms, available, min_rent, max_rent, price_modifier } = slots;
  const filtered = [];
  floorPlans.forEach((f) => {
    if (bedrooms !== null && f.bedrooms !== bedrooms) return;
    if (bathrooms !== null && f.bathrooms !== bathrooms) return;
    if (available !== null || min_rent !== null || max_rent !== null) {
      let { units } = f;
      if (available !== null) {
        units = units.filter(u => u.status === 'AVAILABLE');
      }
      if (min_rent !== null) {
        units = units.filter(u => u.market_rent >= min_rent);
      }
      if (max_rent !== null) {
        units = units.filter(u => u.market_rent <= max_rent);
      }
      if (units.length) {
        filtered.push({ ...f, units });
      }
    } else {
      filtered.push(f);
    }
  });
  const grouped = {};
  filtered.forEach((plan) => {
    grouped[plan.bedrooms?.toString()] = (grouped[plan.bedrooms?.toString()] || []).concat([plan]);
  });
  if (price_modifier) {
    Object.keys(grouped).forEach((key) => {
      let plans = grouped[key];
      const t = price_modifier === 'cheapest' ? 1 : -1;
      plans = plans.map((plan) => {
        plan.units = plan.units.sort((a, b) => (a.market_rent - b.market_rent) * t);
        return plan;
      });
      grouped[key] = plans;
    });
  }
  return grouped;
};

const getFloorPlansResponse = (floorPlans: FloorPlanTypes[]): UnitsResponse => {
  const grouped = {};
  floorPlans.forEach((plan) => {
    grouped[plan.bedrooms?.toString()] = (grouped[plan.bedrooms?.toString()] || []).concat([plan]);
  });
  return grouped;
};

export {
  getClipboard,
  getUnitsResponse,
  getFloorPlansResponse,
};
