import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getInitials } from 'dwell/views/chat/common/utils';
import actions from 'dwell/actions/index';
import corporateActions from 'corporate/store/actions';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { RECENT_PROPERTY_HYPHENS } from 'dwell/constants';
import iframeApi from 'corporate/views/edit_site/iframe';

import { PropertyItemV3, PropertyLinkV3, PropertyActions, PropertyActionsButton, PropertyLabel } from 'containers/default_header/styles';
import { SITE_TYPES } from 'corporate/views/edit_site/edit_panel/component/constants';

interface HeaderPropertyProps {
  property: PropertyProps;
  isOneDisplayed?: boolean;
}

const HeaderProperty: FC<HeaderPropertyProps> = ({ property, isOneDisplayed }) => {
  const selectedProperties = useSelector(selectSelectedProperties);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { updateUserAccessedProperties } = actions.user;
  const { setChatRenderedStatus, clearActiveChats } = actions.prospectChat;
  const { setShowSelector } = actions.propertiesSelector;
  const { getCustomerInformation, setSiteSelected } = corporateActions.customer;
  const { setPreSelectedProperties, setPropertyKeyword } = actions.propertiesSelector;

  const removePropertyHandler = () => {
    const newState = selectedProperties.filter(item => item.id !== property.id);
    if (newState.length === 1) {
      localStorage.setItem(RECENT_PROPERTY_HYPHENS, newState[0].external_id);
    }
    dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: newState }, () => 0)).then(() => {
      document.body.classList.remove('scroll_locked');
      dispatch(setPreSelectedProperties([]));
      dispatch(setPropertyKeyword(''));
      dispatch(clearActiveChats());
      dispatch(setChatRenderedStatus(true));
    });
  };

  const onlyPropertyHandler = () => {
    localStorage.setItem(RECENT_PROPERTY_HYPHENS, property.external_id);
    dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: [property] }, () => 0)).then(() => {
      document.body.classList.remove('scroll_locked');
      dispatch(setPreSelectedProperties([]));
      dispatch(setPropertyKeyword(''));
      dispatch(clearActiveChats());
      dispatch(setChatRenderedStatus(true));
      dispatch(setShowSelector(false));
      dispatch(getCustomerInformation()).then(({ result: { data: { subsites } } }) => {
        const foundSubsite = subsites.find(subsite => subsite.id === property.id);
        if (!foundSubsite) return;
        dispatch(setSiteSelected(foundSubsite, SITE_TYPES.SUBSITE));
        dispatch(corporateActions.editSite.setToolbarNavTab('PAGES'));
        dispatch(corporateActions.section.clearActiveSection());
        dispatch(corporateActions.editSite.getSubsitePageData()).then(({ result: { data: pages } }) => {
          const homePage = pages.find(page => page.page === 'HOME') || pages[0];
          dispatch(corporateActions.editSite.setActivePage(homePage.id));
          dispatch(corporateActions.section.setSections(pages.find(_page => _page.id === homePage.id).sections));
          iframeApi.navigateToPage(pages.find(_page => _page.id === homePage.id).page, SITE_TYPES.SUBSITE, property.id);
        });
      });
    });
  };

  return (
    <PropertyItemV3>
      <PropertyLinkV3 isOneDisplayed={isOneDisplayed}>
        <span>{property.name}</span>
        <span>{getInitials(property.name)}</span>
      </PropertyLinkV3>
      <PropertyActions className={!isOneDisplayed ? 'dwp-actions' : ''}>
        <PropertyActionsButton key="only-btn" onClick={() => onlyPropertyHandler()}>
          only
        </PropertyActionsButton>
        <PropertyActionsButton key="x-button" onClick={() => removePropertyHandler()}>
          &times;
        </PropertyActionsButton>
      </PropertyActions>
      {!isOneDisplayed && property.name.length > 13 && <PropertyLabel>{property.name}</PropertyLabel>}
      {isOneDisplayed && property.name.length > 50 && <PropertyLabel>{property.name}</PropertyLabel>}
    </PropertyItemV3>
  );
};

export default HeaderProperty;
