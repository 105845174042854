import produce from 'immer';
import { Action, ActionType, CallScheduleProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface CallScheduleState extends CommonStateType {
  callSchedules: CallScheduleProps[];
  ivrMessage: string;
}

export const initialState: CallScheduleState = {
  isSubmitting: false,
  isLoaded: false,
  errorMessage: null,
  callSchedules: [],
  ivrMessage: '',
};

export const selectCallSchedules = (state: { callSchedule: CallScheduleState }): CallScheduleProps[] => state.callSchedule.callSchedules;
export const selectIsLoaded = (state: { callSchedule: CallScheduleState }): boolean => state.callSchedule.isLoaded;
export const selectIvrMessage = (state: { callSchedule: CallScheduleState }): string => state.callSchedule.ivrMessage;

export default produce((state: CallScheduleState = initialState, action: Action): CallScheduleState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_CALL_SCHEDULES_SUCCESS:
      state.callSchedules = action.result.data.call_schedules;
      state.ivrMessage = action.result.data.ivr_message;
      break;

    case ActionType.SAVE_CALL_SCHEDULES_SUCCESS:
      state.callSchedules = action.result.data.call_schedules;
      state.ivrMessage = action.result.data.ivr_message;
      break;
  }

  return state;
});
