import { PromiseActionType } from 'dwell/store/types';
import { UnitProps, FloorPlanProps, MovingReason } from 'src/interfaces';
import { TemplatePlaceholders } from 'src/dwell/components/settings/templates/_utils';
import { ActionType as LeasingActionType } from 'leasing/store/lease/action-types';
import { PropertyPolicyProps, LeaseDefaultProps } from 'leasing/store/types';
import { RequireRescoreCallAction } from '../scored_calls/action-types';
import { UserProps } from '../user/action-types';
import { BusinessHoursProps } from '../business_hours/action-types';
import { CommunityDetails, Amenity } from '../community_details/action-types';

export enum ActionType {
  GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST',
  GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS',
  GET_PROPERTY_FAILURE = 'GET_PROPERTY_FAILURE',
  GET_PROPERTY_PAGINATION_REQUEST = 'GET_PROPERTY_PAGINATION_REQUEST',
  GET_PROPERTY_PAGINATION_SUCCESS = 'GET_PROPERTY_PAGINATION_SUCCESS',
  GET_PROPERTY_PAGINATION_FAILURE = 'GET_PROPERTY_PAGINATION_FAILURE',
  GET_PROPERTY_LAZY_REQUEST = 'GET_PROPERTY_LAZY_REQUEST',
  GET_PROPERTY_LAZY_SUCCESS = 'GET_PROPERTY_LAZY_SUCCESS',
  GET_PROPERTY_LAZY_FAILURE = 'GET_PROPERTY_LAZY_FAILURE',
  CLEAR_PROPERTY_LAZY = 'CLEAR_PROPERTY_LAZY',
  UPDATE_PROPERTY_SYNC_SETTINGS_REQUEST = 'UPDATE_PROPERTY_SYNC_SETTINGS_REQUEST',
  UPDATE_PROPERTY_SYNC_SETTINGS_SUCCESS = 'UPDATE_PROPERTY_SYNC_SETTINGS_SUCCESS',
  UPDATE_PROPERTY_SYNC_SETTINGS_FAILURE = 'UPDATE_PROPERTY_SYNC_SETTINGS_FAILURE',
  SUBMIT_CALLS_SCORE_STATE_REQUEST = 'SUBMIT_CALLS_SCORE_STATE_REQUEST',
  SUBMIT_CALLS_SCORE_STATE_SUCCESS = 'SUBMIT_CALLS_SCORE_STATE_SUCCESS',
  SUBMIT_CALLS_SCORE_STATE_FAILURE = 'SUBMIT_CALLS_SCORE_STATE_FAILURE',
  GET_CURRENT_PROPERTY_REQUEST = 'GET_CURRENT_PROPERTY_REQUEST',
  GET_CURRENT_PROPERTY_SUCCESS = 'GET_CURRENT_PROPERTY_SUCCESS',
  GET_CURRENT_PROPERTY_FAILURE = 'GET_CURRENT_PROPERTY_FAILURE',
  GET_PROPERTY_DETAILS_REQUEST = 'GET_PROPERTY_DETAILS_REQUEST',
  GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS',
  GET_PROPERTY_DETAILS_FAILURE = 'GET_PROPERTY_DETAILS_FAILURE',
  CLEAR_ALL_PROPERTIES_SCORED = 'CLEAR_ALL_PROPERTIES_SCORED',
  SET_SCORED_PROPERTY = 'SET_SCORED_PROPERTY',
  UPDATE_PROPERTY_REQUEST = 'UPDATE_PROPERTY_REQUEST',
  UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS',
  UPDATE_PROPERTY_FAILURE = 'UPDATE_PROPERTY_FAILURE',
  UPDATE_TOUR_OPTIONS_REQUEST = 'UPDATE_TOUR_OPTIONS_REQUEST',
  UPDATE_TOUR_OPTIONS_SUCCESS = 'UPDATE_TOUR_OPTIONS_SUCCESS',
  UPDATE_TOUR_OPTIONS_FAILURE = 'UPDATE_TOUR_OPTIONS_FAILURE',
  PROPERTY_CREATE_REQUEST = 'PROPERTY_CREATE_REQUEST',
  PROPERTY_CREATE_SUCCESS = 'PROPERTY_CREATE_SUCCESS',
  PROPERTY_CREATE_FAILURE = 'PROPERTY_CREATE_FAILURE',
  PROPERTY_DELETE_REQUEST = 'PROPERTY_DELETE_REQUEST',
  PROPERTY_DELETE_SUCCESS = 'PROPERTY_DELETE_SUCCESS',
  PROPERTY_DELETE_FAILURE = 'PROPERTY_DELETE_FAILURE',
  SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES',
  SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY',
  SET_SELECTED_PROPERTY_XNAME = 'SET_SELECTED_PROPERTY_XNAME',
  SET_SELECTED_PROPERTY_SETTINGS = 'SET_SELECTED_PROPERTY_SETTINGS',
  GET_SELECTED_PROPERTIES_REQUEST = 'GET_SELECTED_PROPERTIES_REQUEST',
  GET_SELECTED_PROPERTIES_SUCCESS = 'GET_SELECTED_PROPERTIES_SUCCESS',
  GET_SELECTED_PROPERTIES_FAILURE = 'GET_SELECTED_PROPERTIES_FAILURE',
  GET_SELECTED_PROPERTIES_DETAILS_REQUEST = 'GET_SELECTED_PROPERTIES_DETAILS_REQUEST',
  GET_SELECTED_PROPERTIES_DETAILS_SUCCESS = 'GET_SELECTED_PROPERTIES_DETAILS_SUCCESS',
  GET_SELECTED_PROPERTIES_DETAILS_FAILURE = 'GET_SELECTED_PROPERTIES_DETAILS_FAILURE',
  GET_SELECTED_PROPERTIES_FLOOR_PLANS_REQUEST = 'GET_SELECTED_PROPERTIES_FLOOR_PLANS_REQUEST',
  GET_SELECTED_PROPERTIES_FLOOR_PLANS_SUCCESS = 'GET_SELECTED_PROPERTIES_FLOOR_PLANS_SUCCESS',
  GET_SELECTED_PROPERTIES_FLOOR_PLANS_FAILURE = 'GET_SELECTED_PROPERTIES_FLOOR_PLANS_FAILURE',
  RESET_NYLAS_SETTINGS_REQUEST = 'RESET_NYLAS_SETTINGS_REQUEST',
  RESET_NYLAS_SETTINGS_SUCCESS = 'RESET_NYLAS_SETTINGS_SUCCESS',
  RESET_NYLAS_SETTINGS_FAILURE = 'RESET_NYLAS_SETTINGS_FAILURE',
  GET_CUSTOMER_PROPERTIES_REQUEST = 'GET_CUSTOMER_PROPERTIES_REQUEST',
  GET_CUSTOMER_PROPERTIES_SUCCESS = 'GET_CUSTOMER_PROPERTIES_SUCCESS',
  GET_CUSTOMER_PROPERTIES_FAILURE = 'GET_CUSTOMER_PROPERTIES_FAILURE',
  SET_POLLING_EMAIL_SYNC = 'SET_POLLING_EMAIL_SYNC',
  SET_IS_TOKEN_OBTAINED = 'SET_IS_TOKEN_OBTAINED',
  SET_IS_TOKEN_SETTED = 'SET_IS_TOKEN_SETTED',
  RELOAD_PROPERTIES = 'RELOAD_PROPERTIES',

  UPDATE_COMMUNITY_DETAILS_AMENITY_REQUEST = 'UPDATE_COMMUNITY_DETAILS_AMENITY_REQUEST',
  UPDATE_COMMUNITY_DETAILS_AMENITY_SUCCESS = 'UPDATE_COMMUNITY_DETAILS_AMENITY_SUCCESS',
  UPDATE_COMMUNITY_DETAILS_AMENITY_FAILURE = 'UPDATE_COMMUNITY_DETAILS_AMENITY_FAILURE',
}

export interface PropertyProps {
  id?: number;
  adapted_logo?: string;
  agent_chat_enabled?: boolean;
  allow_bulk_sign?: boolean;
  bathroom_types?: string[];
  bedroom_types?: string[];
  city?: string;
  client_id?: string;
  competitor_property?: number;
  competitors?: number[];
  customer?: number;
  description?: string;
  domain?: string;
  external_id?: string;
  floor_plans?: FloorPlanProps[];
  has_ivr_setup?: boolean;
  has_voice_setup?: boolean;
  has_scored_calls_today?: boolean;
  is_booking_enabled?: boolean;
  is_call_rescore_required_today?: boolean;
  is_call_scoring_submitted_today?: boolean;
  is_email_blast_disabled?: boolean;
  is_using_leasing_application?: boolean;
  ivr_community_location_and_bussines_hours_message?: string;
  logo?: string;
  market?: number;
  name?: string;
  neighborhood?: string;
  not_scored_calls_count?: number;
  nylas_status?: string;
  phone_number?: string;
  // platform?: string;
  self_guided_tour_vendor?: string;
  sent_email_count?: number;
  shared_email?: string;
  status?: string;
  submarket?: number;
  target_number?: string;
  template_placeholders?: TemplatePlaceholders;
  tour_types?: string[];
  town?: string;
  tracking_number?: string;
  union_ivr_enabled?: boolean;
  units?: UnitProps[];
  unread_count?: number;
  useExisting?: string; // it's used for onboard FE only
  users?: UserProps[];
  website_link?: string;
  task_workflow?: string;
  max_concurrent_tours?: number;
  timezone?: string;
  is_by_appointment_only?: boolean;
  business_hours?: BusinessHoursProps[];
  new_pipeline_enabled?: boolean;
  platforms?: string[];
  mark_taylor_base_url?: string;
  is_yardi_property?: boolean;
  community_details?: CommunityDetails;
  reason_for_moving?: MovingReason[];
  polices?: PropertyPolicyProps,
  lease_default?: LeaseDefaultProps,
  usd_fee?: number;
}

interface GetPropertiesAction {
  type: ActionType.GET_PROPERTY_REQUEST | ActionType.GET_PROPERTY_SUCCESS | ActionType.GET_PROPERTY_FAILURE;

  payload: {
    paginated: boolean;
    page: number;
  };

  result: {
    data: { results: PropertyProps[]; count: number; previous: string };
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertiesPaginationAction {
  type: ActionType.GET_PROPERTY_PAGINATION_REQUEST | ActionType.GET_PROPERTY_PAGINATION_SUCCESS | ActionType.GET_PROPERTY_PAGINATION_FAILURE;

  result: {
    data: { results: PropertyProps[]; count: number; count_all: number; count_active: number; count_inactive: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertiesLazyAction {
  type: ActionType.GET_PROPERTY_LAZY_REQUEST | ActionType.GET_PROPERTY_LAZY_SUCCESS | ActionType.GET_PROPERTY_LAZY_FAILURE;

  result: {
    data: { results: PropertyProps[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface ClearPropertiesLazyAction {
  type: ActionType.CLEAR_PROPERTY_LAZY;
}

interface UpdatePropertyNylasSettingsAction {
  type: ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_REQUEST | ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_SUCCESS | ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_FAILURE;

  result: {
    data: PropertyProps;
  };
  error: {
    response: { status: string };
  };
}

export interface SubmitCallsScoreStateAction {
  type: ActionType.SUBMIT_CALLS_SCORE_STATE_REQUEST | ActionType.SUBMIT_CALLS_SCORE_STATE_SUCCESS | ActionType.SUBMIT_CALLS_SCORE_STATE_FAILURE;
}

interface GetCurrentPropertyAction {
  type: ActionType.GET_CURRENT_PROPERTY_REQUEST | ActionType.GET_CURRENT_PROPERTY_SUCCESS | ActionType.GET_CURRENT_PROPERTY_FAILURE;

  result: {
    data: PropertyProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertyDetailsAction {
  type: ActionType.GET_PROPERTY_DETAILS_REQUEST | ActionType.GET_PROPERTY_DETAILS_SUCCESS | ActionType.GET_PROPERTY_DETAILS_FAILURE;

  result: {
    data: PropertyProps;
  };
  error: {
    response: { status: string };
  };
}

interface ClearAllPropertiesScoredAction {
  type: ActionType.CLEAR_ALL_PROPERTIES_SCORED;
}

interface SetPropertyAsScoredAction {
  type: ActionType.SET_SCORED_PROPERTY;
  id: string;
}

interface UpdatePropertyAction {
  type: ActionType.UPDATE_PROPERTY_REQUEST | ActionType.UPDATE_PROPERTY_SUCCESS | ActionType.UPDATE_PROPERTY_FAILURE;

  result: {
    data: PropertyProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateTourOptionsAction {
  type: ActionType.UPDATE_TOUR_OPTIONS_REQUEST | ActionType.UPDATE_TOUR_OPTIONS_SUCCESS | ActionType.UPDATE_TOUR_OPTIONS_FAILURE;

  result: {
    data: PropertyProps;
  };
  error: {
    response: { status: string };
  };
}

interface CreatePropertyAction {
  type: ActionType.PROPERTY_CREATE_REQUEST | ActionType.PROPERTY_CREATE_SUCCESS | ActionType.PROPERTY_CREATE_FAILURE;
}

interface DeletePropertyAction {
  type: ActionType.PROPERTY_DELETE_REQUEST | ActionType.PROPERTY_DELETE_SUCCESS | ActionType.PROPERTY_DELETE_FAILURE;
}

export interface SetSelectedPropertiesAction {
  type: ActionType.SET_SELECTED_PROPERTIES;
  properties: PropertyProps[];
}

interface SetSelectedPropertyAction {
  type: ActionType.SET_SELECTED_PROPERTY;
  property: PropertyProps;
}

interface SetSelectedPropertyXnameAction {
  type: ActionType.SET_SELECTED_PROPERTY_XNAME;
  property: PropertyProps;
}

interface SetSelectedPropertySettingsAction {
  type: ActionType.SET_SELECTED_PROPERTY_SETTINGS;
  property: PropertyProps;
}

export interface GetSelectedPropertiesAction {
  type: ActionType.GET_SELECTED_PROPERTIES_REQUEST | ActionType.GET_SELECTED_PROPERTIES_SUCCESS | ActionType.GET_SELECTED_PROPERTIES_FAILURE;

  result: {
    data: PropertyProps[];
  };
  error: {
    response: { status: string };
  };
}
interface GetSelectedPropertiesDetailsAction {
  type: ActionType.GET_SELECTED_PROPERTIES_DETAILS_REQUEST | ActionType.GET_SELECTED_PROPERTIES_DETAILS_SUCCESS | ActionType.GET_SELECTED_PROPERTIES_DETAILS_FAILURE;

  result: {
    data: PropertyProps[];
  };
  error: {
    response: { status: string };
  };
}
export interface GetSelectedPropertiesFloorPlansAction {
  type: ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_REQUEST | ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_SUCCESS | ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_FAILURE;

  result: {
    data: PropertyProps[];
  };
  error: {
    response: { status: string };
  };
}

interface UnitReleaseHoldAction {
  type: LeasingActionType.UPDATE_UNIT_RELEASE_HOLD_SUCCESS;
  id: number;
  newHoldId: number;
}

interface ResetNylasSettingsAction {
  type: ActionType.RESET_NYLAS_SETTINGS_REQUEST | ActionType.RESET_NYLAS_SETTINGS_SUCCESS | ActionType.RESET_NYLAS_SETTINGS_FAILURE;

  result: {
    data: PropertyProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetCustomerPropertiesAction {
  type: ActionType.GET_CUSTOMER_PROPERTIES_REQUEST | ActionType.GET_CUSTOMER_PROPERTIES_SUCCESS | ActionType.GET_CUSTOMER_PROPERTIES_FAILURE;

  result: {
    data: { results: PropertyProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface SetPollingEmailSyncAction {
  type: ActionType.SET_POLLING_EMAIL_SYNC;
  status: boolean;
}

interface SetIsTokenObtained {
  type: ActionType.SET_IS_TOKEN_OBTAINED;
  status: boolean;
}

interface SetIsTokenSetted {
  type: ActionType.SET_IS_TOKEN_SETTED;
  status: boolean;
}

interface ReloadPropertiesAction {
  type: ActionType.RELOAD_PROPERTIES;
}

interface UpdateCommunityDetailsAmenity {
  type: ActionType.UPDATE_COMMUNITY_DETAILS_AMENITY_REQUEST | ActionType.UPDATE_COMMUNITY_DETAILS_AMENITY_SUCCESS | ActionType.UPDATE_COMMUNITY_DETAILS_AMENITY_FAILURE;
  result: { data: Amenity };
  error: { response: { status: string } };
}

export type Action =
  | PromiseActionType
  | GetPropertiesAction
  | GetPropertiesPaginationAction
  | GetPropertiesLazyAction
  | ClearPropertiesLazyAction
  | UpdatePropertyNylasSettingsAction
  | SubmitCallsScoreStateAction
  | GetCurrentPropertyAction
  | GetPropertyDetailsAction
  | ClearAllPropertiesScoredAction
  | SetPropertyAsScoredAction
  | UpdatePropertyAction
  | UpdateTourOptionsAction
  | CreatePropertyAction
  | DeletePropertyAction
  | SetSelectedPropertiesAction
  | SetSelectedPropertyAction
  | SetSelectedPropertyXnameAction
  | GetSelectedPropertiesAction
  | GetSelectedPropertiesDetailsAction
  | GetSelectedPropertiesFloorPlansAction
  | UnitReleaseHoldAction
  | ResetNylasSettingsAction
  | GetCustomerPropertiesAction
  | RequireRescoreCallAction
  | SetSelectedPropertySettingsAction
  | SetPollingEmailSyncAction
  | SetIsTokenObtained
  | SetIsTokenSetted
  | ReloadPropertiesAction
  | UpdateCommunityDetailsAmenity;
