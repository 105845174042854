import React, { FC, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Call as TwilioCall } from '@twilio/voice-sdk';
import voiceActions from 'dwell/store/voice/action-creator';
import { selectIncomingCall, selectTimeShowingIncomingCallNotification } from 'dwell/store/voice/reducers';
import { WhiteButton, PrimaryButton } from 'styles/common';

import { getCallParams } from '../voice_centre/utils';
import {
  CallNotificationWrapper,
  CallNotificationHeader,
  CallNotificationBody,
  CallNotificationFooter,
} from './styles';

interface InboundCallNotificationProps {
  acceptCall: (call: TwilioCall) => void,
  rejectCall: (call: TwilioCall) => void,
}

const InboundCallNotification: FC<InboundCallNotificationProps> = ({ acceptCall, rejectCall }) => {
  const dispatch = useDispatch();
  const call = useSelector(selectIncomingCall);
  const timeShowingIncomingCallNotification = useSelector(selectTimeShowingIncomingCallNotification);
  const intervalRef = useRef(null);
  const notificationRef = useRef(null);

  const closeNotification = () => {
    rejectCall(call);
    dispatch(voiceActions.closeCallNotification());
  };
  const { prospectName, prospectType, propertyName, sourceName } = getCallParams(call);

  const connectAndAccept = () => {
    acceptCall(call);
  };

  useEffect(() => {
    // If agent did not accept the call in 16 seconds, reject the call to avoid conflicts showing call window
    // and the call not connected
    if (timeShowingIncomingCallNotification === 16) {
      rejectCall(call);
      if (notificationRef.current) notificationRef.current.close();
    }
    intervalRef.current = setInterval(() => {
      dispatch(voiceActions.setTimeShowingIncomingCallNotification(timeShowingIncomingCallNotification + 1));
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [timeShowingIncomingCallNotification]);

  useEffect(() => {
    if (document.visibilityState === 'visible') return;
    if (!('Notification' in window)) return;
    if (Notification.permission === 'granted') {
      const options = {
        body: `Call from ${prospectName}.\n${prospectType} ${propertyName} (${sourceName})`,
        requireInteraction: true,
      };
      notificationRef.current = new Notification('Incoming call', options);
      notificationRef.current.onclick = () => {
        window.focus();
      };
    }
  }, []);

  return !isEmpty(call) && (
    <CallNotificationWrapper>
      <CallNotificationHeader>
        <div className="avatar"><i className="ri-phone-fill" /></div>
        <h6>Incoming Call</h6>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="close call-close" onClick={closeNotification}><i className="ri-close-line" /></a>
      </CallNotificationHeader>
      <CallNotificationBody>
        <p className="mg-b-5">Call from <strong>{prospectName}</strong></p>
        <p className="mg-b-0"><span>{prospectType}</span><span>{`${propertyName} (${sourceName})`}</span></p>
      </CallNotificationBody>
      <CallNotificationFooter>
        <>
          <PrimaryButton className="btn btn-primary" onClick={connectAndAccept}>Accept</PrimaryButton>
          <WhiteButton className="btn btn-white call-close" onClick={() => rejectCall(call)}>Decline</WhiteButton>
        </>
      </CallNotificationFooter>
    </CallNotificationWrapper>
  );
};

export default InboundCallNotification;
