import styled, { css, keyframes } from 'styled-components';
import { Input } from 'reactstrap';

import { FormCheckBox, btnFlex, SecondaryBtn, ButtonLikeAnchor } from 'styles/common';
import { parseColor } from 'site/components/common/mixin';
import { NavLink } from 'containers/default_header/styles';

export const PropertyMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 340px;
  min-height: 200px;
  background-color: ${props => props.theme.colors.white};
  z-index: 1300;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  -webkit-transform: ${props => (props.show ? 'translate3d(0%, 0, 0)' : 'translate3d(-100%, 0, 0)')};
  transform: ${props => (props.show ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)')};
  transition: all 0.25s;
  overflow: hidden;
`;

export const BackDrop = styled.div`
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(${props => parseColor(props.theme.colors.gray700).toString()}, 0.35);
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  opacity: ${props => (props.show ? '1' : '0')};
  transition: visibility 0.25s;
`;

export const PropertyMenuGroup = styled.div`
  position: absolute;
  overflow-y: scroll;
  top: 0;
  left: ${props => props.step * 340}px;
  width: 340px;
  height: calc(100% - 60px);
  transition: all 0.25s;
`;

export const PmLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  padding: 20px 20px 10px;
`;

export const PmLabelLabel = styled.label`
  display: block;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: ${props => props.theme.colors.colortx02};
  & > span {
    font-weight: 400;
    color: ${props => props.theme.colors.gray600};
  }
`;

export const PmLabelAnchor = styled(ButtonLikeAnchor)`
  display: block;
  color: #0168fa;
  &:focus,
  &:hover {
    color: ${props => props.theme.colors.colorui01};
  }
`;

export const PropertyMenuSearch = styled.div`
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${props => props.theme.colors.colorbg01};
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 12px;
  padding: 0 10px;

  & .form-control {
    flex: 1;
    padding: 0;
    padding-left: 3px;
    height: auto;
    border-radius: 0;
    border-width: 0;
    margin-bottom: 2px;
    text-shadow: none;
    background-color: transparent;
    text-shadow: none;
    font-weight: 500;
  }

  & .form-control:focus {
    box-shadow: none;
  }

  & .form-control::placeholder {
    color: ${props => props.theme.colors.colortx03};
    font-weight: 400;
  }

  & svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.5px;
    margin-bottom: 2px;
    color: ${props => props.theme.colors.colortx03};
    transition: all 0.15s;
  }

  @media (prefers-reduced-motion: reduce) {
    .& svg {
      transition: none;
    }
  }

  &.onfocus {
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.input.borderColor};
    box-shadow: 0 1px 1px rgba(240, 242, 249, 0.08), 0 2px 2px rgba(240, 242, 249, 0.12), 0 4px 4px rgba(240, 242, 249, 0.16), 0 8px 8px rgba(240, 242, 249, 0.2);
  }
`;

export const NewPropertyMenuSearch = styled.div`
  border-top: 1px solid #e1e6f7;
  border-bottom: 1px solid #e1e6f7;
  padding: 0 15px;
  background-color: #f7f8fc;
  transition: all 0.25s;

  & .form-control {
    border-radius: 0;
    padding-left: 0;
    background-color: transparent;
    border-width: 0;
    height: 40px;
    font-weight: 500;
  }

  & .form-control:focus {
    box-shadow: none;
  }

  & .form-control::placeholder {
    font-weight: 400;
  }

  &.onfocus {
    background-color: ${props => props.theme.colors.white};
  }
`;

export const FormPropertySearch = styled(Input)`
  height: 42px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.gray300};
  padding: 0 10px;
  margin: 0;
  & svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px;
    color: #a0a9bd;
  }
  & input {
    flex: 1;
    border: 0;
    padding: 0;
    margin-left: 8px;
    text-shadow: none;
  }
  & input:focus {
    box-shadow: none;
    outline: none;
  }
  & input:placeholder {
    color: ${props => props.theme.colors.gray600};
  }
`;

export const PMGroup = styled.div``;

export const PMItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-top: 1px solid ${props => props.theme.colors.colorbd01};

  &:hover {
    background-color: #f7f8fc;
  }

  &.mg-r-10 {
    margin-right: 10px;
  }
`;

export const PMFormCheckbox = styled(FormCheckBox)`
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  display: ${props => (props.hide ? 'none' : 'block')};
  &:hover {
    cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  }
`;

export const PropertyItemLogo = styled.div`
  position: ${props => (props.active ? 'static' : 'relative')};
  width: 40px;
  height: 40px;
  color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.colortx02)};
  margin-right: 15px;
  flex-shrink: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.active ? props.theme.colors.colorui01 : props.theme.colors.white)};
  border: 1.5px solid ${props => props.theme.colors.colorbg03};
  i {
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
  }
  .badge {
    min-width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #f3505c;
    color: #fff;
    font-weight: 400;
    font-size: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    border-radius: 20px;
  }
  ${props =>
    props.active &&
    css`
      &:after {
        content: '\EB7B';
        font-family: 'remixicon';
        position: absolute;
        top: 4px;
        right: 1px;
        color: ${props.theme.colors.white};
        font-size: 12px;
        line-height: 0.5;
      }
    `}
`;

export const PropertyItemLogoImg = styled.img`
  width: 24px;
`;

export const PMLogo = styled(PropertyItemLogo)`
  margin-right: 10px;
`;

const scrollText = keyframes`
0% {
  transform: translateX(0%); }
90% {
  transform: translateX(-100%); }
95% {
  transform: translateX(0%); }
100% {
  transform: translateX(0%); }
`;

export const PropertyItemBody = styled.div`
  flex: 1;

  h6 {
    margin-bottom: 2px;
    font-weight: 500;
    color: ${props => props.theme.colors.colortx01};
    line-height: 1.3;
    width: 190px;
  }

  h6.overflow {
    white-space: nowrap;
    overflow: hidden;
  }

  h6.overflow:not(:hover) {
    text-overflow: ellipsis;
  }

  h6.overflow:hover span {
    display: inline-block;
    animation: ${scrollText} 8s ease 0s 2 normal;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: ${props => props.theme.colors.colortx03};
  }
`;

export const PMBody = styled(PropertyItemBody)``;

export const BtnOnly = styled(SecondaryBtn)`
  position: absolute;
  top: calc(50% - 15px);
  left: 220px;
  height: 30px;
  min-height: inherit;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 0 12px;
  border-radius: 4px;
  display: ${props => (props.show ? 'flex' : 'none')};
  z-index: 1000;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.input.borderColor};
  color: ${props => props.theme.colors.colortx02};
  box-shadow: 0 1px 1px rgba(213, 220, 244, 0.25), 0 2px 2px rgba(213, 220, 244, 0.2), 0 4px 4px rgba(213, 220, 244, 0.15), 0 8px 8px rgba(213, 220, 244, 0.1), 0 16px 16px rgba(213, 220, 244, 0.05);

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.white};
    box-shadow: none;
    border-color: ${props => props.theme.colors.colortx02};
    color: ${props => props.theme.colors.colortx02};
  }
`;

const NavCore = css`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;

export const NavPropertyItem = styled.div`
  ${NavCore};
  position: absolute;
  top: calc(50% - 12px);
  right: 15px;
  display: ${props => (props.show ? 'flex' : 'none')};
`;

export const NavPI = styled.div`
  ${NavCore};
  position: absolute;
  top: calc(50% - 12px);
  right: 15px;
  display: ${props => (props.show ? 'flex' : 'none')};
`;

export const NewBtnOnly = styled(NavLink)`
  height: 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d5dcf4;
  border-radius: 3px;
  color: #657697;
  font-size: 10px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-transform: uppercase;
  padding: 0 5px;

  &:hover,
  &:focus {
    color: #0168fa;
    border-color: #0168fa;
  }
`;

export const NBOnly = styled(NavLink)`
  height: 24px;
  display: ${props => (props.show ? 'flex' : 'none')};
  align-items: center;
  background-color: #fff;
  border: 1px solid #d5dcf4;
  border-radius: 3px;
  color: #657697;
  font-size: 10px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-transform: uppercase;
  padding: 0 5px;

  &:hover,
  &:focus {
    color: #0168fa;
    border-color: #0168fa;
  }
`;

export const PropertyItemLink = styled.a`
  font-size: 16px;
  color: ${props => props.theme.colors.colortx03};
  opacity: 0.75;
  outline: none;
  transition: all 0.25s;
  &:hover,
  &:focus {
    opacity: 1;
    color: ${props => props.theme.colors.colortx02};
    cursor: pointer;
  }
`;

export const NewPropertyItemLink = styled.a`
  height: 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d5dcf4;
  border-radius: 3px;
  color: #657697;
  padding: 0;
  width: 24px;
  justify-content: center;
  margin-left: 3px;

  &:hover,
  &:focus {
    color: #0168fa;
    border-color: #0168fa;
    text-decoration: none;
  }
`;

export const NPILink = styled.a`
  height: 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d5dcf4;
  border-radius: 3px;
  color: #657697;
  padding: 0;
  width: 24px;
  justify-content: center;
  margin-left: 3px;

  &:hover,
  &:focus {
    color: #0168fa;
    border-color: #0168fa;
    text-decoration: none;
  }
`;

export const PMLink = styled(PropertyItemLink)``;

export const PropertyMenuBody = styled.div`
  padding-bottom: ${props => (props.active ? '60px' : '0px')};
`;

export const PropertyItem = styled.div`
  padding: 12px 15px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${props => (props.inactive ? props.theme.colors.colorbg02 : props.theme.colors.white)};

  &:hover {
    background-color: #f7f8fc;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid ${props => props.theme.colors.colorbg01};
  }
  &:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    border-top: 1px solid ${props => props.theme.colors.colorbg01};
  }

  ${props =>
    props.customers &&
    css`
      &:hover,
      &:focus {
        background-color: ${props.theme.colors.bodyBg};
        cursor: pointer;
      }

      &:hover h6,
      &:focus h6 {
        color: ${props.theme.colors.colorui01};
      }
    `}

  ${props =>
    props.active &&
    css`
      margin-bottom: 15px;
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.colorui01};
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      margin-left: 25px;
      margin-right: 25px;
      padding-left: 10px;
      padding-right: 10px;

      &:before {
        content: '';
        position: absolute;
        top: -18px;
        right: -18px;
        background-color: ${props.theme.colors.colorui01};
        color: ${props.theme.colors.white};
        width: 36px;
        height: 36px;
        transform: rotate(45deg);
      }
    `}
`;

export const PmCustomer = styled.div`
  height: 64px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.colorbg02};
  box-shadow: 0 1px 1px rgba(240, 242, 249, 0.25), 0 2px 2px rgba(240, 242, 249, 0.2), 0 4px 4px rgba(240, 242, 249, 0.15), 0 8px 8px rgba(240, 242, 249, 0.1), 0 16px 16px rgba(240, 242, 249, 0.05);

  h6 {
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.colors.colortx01};
    letter-spacing: -0.2px;
  }

  button {
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: ${props => props.theme.colors.colortx03};
    letter-spacing: -0.2px;
    display: flex;
    align-items: center;
  }

  button:hover,
  button:focus {
    color: ${props => props.theme.colors.colorui01};
  }

  button i {
    font-size: 16px;
    line-height: 1;
    position: relative;
    top: 1px;
    margin-left: 2px;
  }
`;

export const PmApply = styled.div`
  width: inherit;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  background-color: ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.colorbd02};
  z-index: 100;
  display: ${props => (props.hide ? 'none' : 'block')} !important;
`;

export const PmApplyBtn = styled(btnFlex)`
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.5px;
  height: 40px;
`;

export const SPCountSpan = styled.span`
  color: ${props => props.theme.colors.gray600} !important;
`;

export const PropertyDefaultLogoIcon = styled.i`
  font-size: 20px;
`;

export const PropertiesList = styled.div`
  max-height: 300px;
  position: relative;
  overflow-y: auto;
`;

export const SPropertiesList = styled.div`
  height: 150px;
  position: relative;
  overflow-y: auto;
`;

export const SwitchTab = styled(ButtonLikeAnchor)`
  display: flex !important;
  align-items: center !important;
  color: #0168fa !important;
  font-size: 13px !important;

  a i {
    line-height: 1 !important;
    margin-left: 3px !important;
    font-size: 16px !important;
  }
`;

export const Badge = styled.span`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 6px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none;
    }
  }
  a.badge:hover,
  a.badge:focus {
    text-decoration: none;
  }
  .badge:empty {
    display: none;
  }
`;

export const SiteItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
`;
