import React, { FC } from 'react';

import { Key as KeyWrapper } from './styles';

interface KeyProps {
  phoneKey: string,
  letters?: string,
  pressed: (phoneKey: string) => void,
}

const Key: FC<KeyProps> = ({ phoneKey, letters, pressed }) => (
  <div className="col-4">
    <KeyWrapper className="dial-link" onClick={() => pressed(phoneKey)}>
      <h6>{phoneKey}</h6>
      {letters && <span>{letters}</span>}
    </KeyWrapper>
  </div>
);

export default Key;
