declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    crmApp: any;
  }
}

export default {
  tokenTimeOut: 32,
};
