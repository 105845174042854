export const AUTO_CREATED = ['CALL_BACK', 'FIRST_CONTACT', 'REPLY_BACK'];

export const TOUR_TYPES = {
  IN_PERSON: 'In-Person',
  FACETIME: 'Facetime',
  GUIDED_VIRTUAL_TOUR: 'Guided Virtual Tour',
  VIRTUAL_TOUR: 'Virtual Tour',
  SELF_GUIDED_TOUR: 'Self-Guided Tour',
  TOUR: 'Tour',
};

export const TOUR_REPORT_TYPES = {
  VIRTUAL_TOUR: 'virtual_tour',
  GUIDED_VIRTUAL_TOUR: 'guided_virtual_tour',
  SELF_GUIDED_TOUR: 'self_guided_tour',
  IN_PERSON: 'in_person',
  FACETIME: 'facetime',
};
