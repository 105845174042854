import { paths } from 'dwell/constants';
import { ActionType, Action, AssistBotConversationTypes, SuggestedTopicType } from './action-types';
import { OffsetPaginationParamTypes } from '../types';

export default {
  getAssistBotConversations: (property: number, params: OffsetPaginationParamTypes): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ASSIST_BOT_CONVERSATIONS_REQUEST,
        ActionType.GET_ASSIST_BOT_CONVERSATIONS_SUCCESS,
        ActionType.GET_ASSIST_BOT_CONVERSATIONS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.ASSIST_BOT_CONVERSATION, property), { params }),
    },
  }),

  createAssistBotConversation: (property: number, conv: AssistBotConversationTypes): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_ASSIST_BOT_CONVERSATION_REQUEST,
        ActionType.CREATE_ASSIST_BOT_CONVERSATION_SUCCESS,
        ActionType.CREATE_ASSIST_BOT_CONVERSATION_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.ASSIST_BOT_CONVERSATION, property), conv),
    },
  }),

  clearAssistBotConversations: (property: number): Action => ({
    CALL_API: {
      types: [
        ActionType.CLEAR_ASSIST_BOT_CONVERSATIONS_REQUEST,
        ActionType.CLEAR_ASSIST_BOT_CONVERSATIONS_SUCCESS,
        ActionType.CLEAR_ASSIST_BOT_CONVERSATIONS_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.CLEAR_ASSIST_BOT_CONVERSATION, property)),
    },
  }),

  setSuggestedTopics: (topics: SuggestedTopicType[]): Action => ({ type: ActionType.SET_SUGGESTED_TOPICS, topics }),
};
