
export default {
  CUSTOMER: {
    INFORMATION: '/api/v1/corporate/customer/',
    CREATE_AMENITY: '/api/v1/amenity/',
    UPDATE_AMENITY: '/api/v1/amenity/:id/',
    DELETE_AMENITY: '/api/v1/amenity/:id/',
  },
  EDIT_PAGE_SETTINGS: {
    SITE_PAGE_SETTINGS: '/api/v1/corporate/site_page_settings/:id/',
    SUB_SITE_PAGE_SETTINGS: '/api/v1/corporate/sub_site_page_settings/:id/',
  },
  EDIT_CORPORATE_SITE: {
    PAGES_AND_SECTIONS: '/api/v1/corporate/corporate_site/',
    NEW_SECTION: '/api/v1/corporate/corporate_site/add_section/',
    DELETE_SECTION: '/api/v1/corporate/corporate_site/delete_section/',
    CHANGE_ORDER: '/api/v1/corporate/corporate_site/change_order/',
    PUBLISH: '/api/v1/corporate/corporate_site/publish/',
    UPDATE_SECTION: '/api/v1/corporate/corporate_section/:id/',
  },
  EDIT_SUBSITE: {
    PAGES_AND_SECTIONS: '/api/v1/corporate/sub_site/',
    NEW_SECTION: '/api/v1/corporate/sub_site/add_section/',
    DELETE_SECTION: '/api/v1/corporate/sub_site/delete_section/',
    CHANGE_ORDER: '/api/v1/corporate/sub_site/change_order/',
    PUBLISH: '/api/v1/corporate/sub_site/publish/',
    UPDATE_SECTION: '/api/v1/corporate/sub_site_section/:id/',
  },
  MEDIA_LIBRARY: {
    PROPERTY_MEDIA_LIBRARY: '/api/v1/corporate/property_media_library/',
    PROPERTY_MEDIA_LIBRARY_LIST_LABELS: '/api/v1/corporate/property_media_library/list_labels/',
    PROPERTY_MEDIA_LIBRARY_ID: '/api/v1/corporate/property_media_library/:id/',
    CUSTOMER_MEDIA_LIBRARY: '/api/v1/corporate/site_media_library/',
    CUSTOMER_MEDIA_LIBRARY_LIST_LABELS: '/api/v1/corporate/site_media_library/list_labels/',
    CUSTOMER_MEDIA_LIBRARY_ID: '/api/v1/corporate/site_media_library/:id/',
  },
  SITE_SETTINGS: {
    PROPERTY_SEO: 'api/v1/corporate/property_seo/',
    PROPERTY_SEO_ID: 'api/v1/corporate/property_seo/:id/',
    SITE_SEO: 'api/v1/corporate/site_seo/',
    SITE_SEO_ID: 'api/v1/corporate/site_seo/:id/',
  },
};
