import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import Description from './_description';
import ObjectSelector from './_object_selector';
import OwnerSelector from './_owner_selector';
import { InputProps } from '../utils';

const CommonInputs: FC<InputProps> = (props) => {
  const { type } = props;
  return (
    <>
      <Row className="mt-2">
        <Col xs={12} className="mg-t-10">
          <ObjectSelector {...props} />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs={12} className="mg-t-10">
          <OwnerSelector {...props} />
        </Col>
      </Row>

      {type !== 'SELF_GUIDED_TOUR' && (
        <Row className="mt-2">
          <Col xs={12} className="mg-t-10">
            <Description {...props} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default CommonInputs;
