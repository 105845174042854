import { paths } from 'dwell/constants';
import { ManageRequestProps } from 'src/interfaces';
import { PromiseActionType } from 'dwell/store/types';
import { ActionType } from './action-types';

export default {
  getCallScoringQuestions: (param?: ManageRequestProps): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.GET_CALL_SCORING_QUESTIONS_REQUEST,
        ActionType.GET_CALL_SCORING_QUESTIONS_SUCCESS,
        ActionType.GET_CALL_SCORING_QUESTIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CALL_SCORING_QUESTIONS, { params: param }),
    },
  }),
};
