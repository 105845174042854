import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_OTHER_RENT_ITEM_REQUEST = 'CREATE_OTHER_RENT_ITEM_REQUEST',
  CREATE_OTHER_RENT_ITEM_SUCCESS = 'CREATE_OTHER_RENT_ITEM_SUCCESS',
  CREATE_OTHER_RENT_ITEM_FAILURE = 'CREATE_OTHER_RENT_ITEM_FAILURE',

  DELETE_OTHER_RENT_ITEM_REQUEST = 'DELETE_OTHER_RENT_ITEM_REQUEST',
  DELETE_OTHER_RENT_ITEM_SUCCESS = 'DELETE_OTHER_RENT_ITEM_SUCCESS',
  DELETE_OTHER_RENT_ITEM_FAILURE = 'DELETE_OTHER_RENT_ITEM_FAILURE',

  UPDATE_OTHER_RENT_ITEM_REQUEST = 'UPDATE_OTHER_RENT_ITEM_REQUEST',
  UPDATE_OTHER_RENT_ITEM_SUCCESS = 'UPDATE_OTHER_RENT_ITEM_SUCCESS',
  UPDATE_OTHER_RENT_ITEM_FAILURE = 'UPDATE_OTHER_RENT_ITEM_FAILURE',
}

export interface OtherRentItemState extends CommonStateType {
  otherRentItems: OtherRentItemProps[]
}

export interface OtherRentItemProps {
  id?: number,
  description: string,
  rent: number,
  fee: number,
  deposit: number,
}

/*
 * Actions
 */

interface CreateOtherRentItemAction extends CommonActionResponse {
  type: ActionType.CREATE_OTHER_RENT_ITEM_REQUEST
  | ActionType.CREATE_OTHER_RENT_ITEM_SUCCESS
  | ActionType.CREATE_OTHER_RENT_ITEM_FAILURE;
  result: { data: OtherRentItemProps };
}

interface DeleteOtherRentItemAction extends CommonActionResponse {
  type: ActionType.DELETE_OTHER_RENT_ITEM_REQUEST
  | ActionType.DELETE_OTHER_RENT_ITEM_SUCCESS
  | ActionType.DELETE_OTHER_RENT_ITEM_FAILURE;
  result: { data: OtherRentItemProps };
}

interface UpdateOtherRentItemAction extends CommonActionResponse {
  type: ActionType.UPDATE_OTHER_RENT_ITEM_REQUEST
  | ActionType.UPDATE_OTHER_RENT_ITEM_REQUEST
  | ActionType.UPDATE_OTHER_RENT_ITEM_FAILURE;
  result: { data: OtherRentItemProps };
}

export type Action =
  PromiseActionType
  | CreateOtherRentItemAction
  | DeleteOtherRentItemAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdateOtherRentItemAction;
