import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_ASSIST_BOT_CONVERSATIONS_REQUEST = 'GET_ASSIST_BOT_CONVERSATIONS_REQUEST',
  GET_ASSIST_BOT_CONVERSATIONS_SUCCESS = 'GET_ASSIST_BOT_CONVERSATIONS_SUCCESS',
  GET_ASSIST_BOT_CONVERSATIONS_FAILURE = 'GET_ASSIST_BOT_CONVERSATIONS_FAILURE',

  CREATE_ASSIST_BOT_CONVERSATION_REQUEST = 'CREATE_ASSIST_BOT_CONVERSATION_REQUEST',
  CREATE_ASSIST_BOT_CONVERSATION_SUCCESS = 'CREATE_ASSIST_BOT_CONVERSATION_SUCCESS',
  CREATE_ASSIST_BOT_CONVERSATION_FAILURE = 'CREATE_ASSIST_BOT_CONVERSATION_FAILURE',

  CLEAR_ASSIST_BOT_CONVERSATIONS_REQUEST = 'CLEAR_ASSIST_BOT_CONVERSATIONS_REQUEST',
  CLEAR_ASSIST_BOT_CONVERSATIONS_SUCCESS = 'CLEAR_ASSIST_BOT_CONVERSATIONS_SUCCESS',
  CLEAR_ASSIST_BOT_CONVERSATIONS_FAILURE = 'CLEAR_ASSIST_BOT_CONVERSATIONS_FAILURE',

  SET_SUGGESTED_TOPICS = 'SET_SUGGESTED_TOPICS',
}

export interface AssistBotConversationTypes {
  date: string,
  type: string,
  message: string,
  response_type?: string,
  is_suggested?: boolean,
  no_need_clipboard?: boolean,
}

export interface SuggestedTopicType {
  content: string,
  intent: string,
}

export interface AnswerAssistTopicType {
  content: string,
  intent: string,
  is_default: boolean,
}

interface GetAssistBotConversationsAction {
  type: ActionType.GET_ASSIST_BOT_CONVERSATIONS_REQUEST
  | ActionType.GET_ASSIST_BOT_CONVERSATIONS_SUCCESS
  | ActionType.GET_ASSIST_BOT_CONVERSATIONS_FAILURE;

  result: {
    data: { results: AssistBotConversationTypes[], count: number }
  };
  error: {
    response: { status: string }
  };
}

interface CreateAssistBotConversationAction {
  type: ActionType.CREATE_ASSIST_BOT_CONVERSATION_REQUEST
  | ActionType.CREATE_ASSIST_BOT_CONVERSATION_SUCCESS
  | ActionType.CREATE_ASSIST_BOT_CONVERSATION_FAILURE;

  result: {
    data: AssistBotConversationTypes
  };
  error: {
    response: { status: string }
  };
}

interface SetAnswerAssistTopicsAction {
  type: ActionType.SET_SUGGESTED_TOPICS;
  topics: SuggestedTopicType[],
}

export type Action =
  | GetAssistBotConversationsAction
  | CreateAssistBotConversationAction
  | SetAnswerAssistTopicsAction
  | PromiseActionType
