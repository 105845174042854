import produce from 'immer';
import { ActionType, GetLeavingReasonsAction, LeavingReasonProps, LeavingReasonsState } from './action-types';

const initialState: LeavingReasonsState = {
  isLoaded: false,
  leavingReasons: [],
};

export const selectIsLoaded = (state: { leavingReasons: LeavingReasonsState }): boolean => state.leavingReasons.isLoaded;
export const selectLeavingReasons = (state: { leavingReasons: LeavingReasonsState }): LeavingReasonProps[] => state.leavingReasons.leavingReasons;

export default produce((state: LeavingReasonsState = initialState, action: GetLeavingReasonsAction) => {
  switch (action.type) {
    case ActionType.GET_LEAVING_REASONS_REQUEST:
      state.isLoaded = false;
      break;

    case ActionType.GET_LEAVING_REASONS_SUCCESS:
      state.isLoaded = true;
      state.leavingReasons = action.result.data;
      break;
  }

  return state;
});
