import { PromiseActionType } from 'dwell/store/types';
import { OwnerData, Communication, LeadLeaseDetails, MovingReason } from 'src/interfaces';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { TaskProps } from '../task/action-types';
import { Prospect } from '../chat/action-types';
import { CallProps } from '../call/action-types';
import { EmailMessageProps } from '../email_message/action-types';
import { NoteProps } from '../note/action-types';
import { RoommateProps } from '../roommate/action-types';

export enum ActionType {
  GET_LEAD_REQUEST = 'GET_LEAD_REQUEST',
  GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS',
  GET_LEAD_FAILURE = 'GET_LEAD_FAILURE',
  GET_LEAD_BY_ID_REQUEST = 'GET_LEAD_BY_ID_REQUEST',
  GET_LEAD_BY_ID_SUCCESS = 'GET_LEAD_BY_ID_SUCCESS',
  GET_LEAD_BY_ID_FAILURE = 'GET_LEAD_BY_ID_FAILURE',
  UPDATE_LEAD_REQUEST = 'UPDATE_LEAD_REQUEST',
  UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS',
  UPDATE_LEAD_FAILURE = 'UPDATE_LEAD_FAILURE',
  DELETE_LEADS_REQUEST = 'DELETE_LEADS_REQUEST',
  DELETE_LEADS_SUCCESS = 'DELETE_LEADS_SUCCESS',
  DELETE_LEADS_FAILURE = 'DELETE_LEADS_FAILURE',
  CLEAR_LEADS = 'CLEAR_LEADS',
  UPDATE_LEADS_REQUEST = 'UPDATE_LEADS_REQUEST',
  UPDATE_LEADS_SUCCESS = 'UPDATE_LEADS_SUCCESS',
  UPDATE_LEADS_FAILURE = 'UPDATE_LEADS_FAILURE',
  CREATE_LEAD_REQUEST = 'CREATE_LEAD_REQUEST',
  CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS',
  CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE',
  GET_LEAD_NAMES_REQUEST = 'GET_LEAD_NAMES_REQUEST',
  GET_LEAD_NAMES_SUCCESS = 'GET_LEAD_NAMES_SUCCESS',
  GET_LEAD_NAMES_FAILURE = 'GET_LEAD_NAMES_FAILURE',
  TEST_RESMAN_REQUEST = 'TEST_RESMAN_REQUEST',
  TEST_RESMAN_SUCCESS = 'TEST_RESMAN_SUCCESS',
  TEST_RESMAN_FAILURE = 'TEST_RESMAN_FAILURE',
  GET_FILTERED_LEADS_COUNT_REQUEST = 'GET_FILTERED_LEADS_COUNT_REQUEST',
  GET_FILTERED_LEADS_COUNT_SUCCESS = 'GET_FILTERED_LEADS_COUNT_SUCCESS',
  GET_FILTERED_LEADS_COUNT_FAILURE = 'GET_FILTERED_LEADS_COUNT_FAILURE',
  MERGE_LEADS_REQUEST = 'MERGE_LEADS_REQUEST',
  MERGE_LEADS_SUCCESS = 'MERGE_LEADS_SUCCESS',
  MERGE_LEADS_FAILURE = 'MERGE_LEADS_FAILURE',
  SHARE_LEAD_REQUEST = 'SHARE_LEAD_REQUEST',
  SHARE_LEAD_SUCCESS = 'SHARE_LEAD_SUCCESS',
  SHARE_LEAD_FAILURE = 'SHARE_LEAD_FAILURE',
  TRANSFER_LEAD_REQUEST = 'TRANSFER_LEAD_REQUEST',
  TRANSFER_LEAD_SUCCESS = 'TRANSFER_LEAD_SUCCESS',
  TRANSFER_LEAD_FAILURE = 'TRANSFER_LEAD_FAILURE',
  GET_PMS_SYNC_STATUS_BY_ID_REQUEST = 'GET_PMS_SYNC_STATUS_BY_ID_REQUEST',
  GET_PMS_SYNC_STATUS_BY_ID_SUCCESS = 'GET_PMS_SYNC_STATUS_BY_ID_SUCCESS',
  GET_PMS_SYNC_STATUS_BY_ID_FAILURE = 'GET_PMS_SYNC_STATUS_BY_ID_FAILURE',
  GET_LEAD_FOR_PROSPECT_REQUEST = 'GET_LEAD_FOR_PROSPECT_REQUEST',
  GET_LEAD_FOR_PROSPECT_SUCCESS = 'GET_LEAD_FOR_PROSPECT_SUCCESS',
  GET_LEAD_FOR_PROSPECT_FAILURE = 'GET_LEAD_FOR_PROSPECT_FAILURE',
  PUSHER_DELETE_RECORD = 'PUSHER_DELETE_RECORD',
  PUSHER_CREATE_RECORD = 'PUSHER_CREATE_RECORD',
  PUSHER_UPDATE_RECORD = 'PUSHER_UPDATE_RECORD',
  CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS',
  UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS',
  DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS',
  CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS',
  UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS',
  DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS',
  COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  SEND_TEXT_TO_LEAD_SUCCESS = 'SEND_TEXT_TO_LEAD_SUCCESS',
  SEND_TEXT_TO_PROSPECT_SUCCESS = 'SEND_TEXT_TO_PROSPECT_SUCCESS',
  DELETE_ROOMMATE_SUCCESS = 'DELETE_ROOMMATE_SUCCESS',
  UPDATE_ROOMMATES_SUCCESS = 'UPDATE_ROOMMATES_SUCCESS',
  UNENROLL_LEAD_REQUEST = 'UNENROLL_LEAD_REQUEST',
  UNENROLL_LEAD_SUCCESS = 'UNENROLL_LEAD_SUCCESS',
  UNENROLL_LEAD_FAILURE = 'UNENROLL_LEAD_FAILURE',
  TRANSFER_TO_APPLICATION_REQUEST = 'TRANSFER_TO_APPLICATION_REQUEST',
  TRANSFER_TO_APPLICATION_SUCCESS = 'TRANSFER_TO_APPLICATION_SUCCESS',
  TRANSFER_TO_APPLICATION_FAILURE = 'TRANSFER_TO_APPLICATION_FAILURE',
}

export interface CreateLeadType {
  first_name: string | null;
  last_name: string | null;
  source: string | null;
  email: string | null;
  phone_number: string | null;
  allow_duplication: boolean;
  floor_plan: number[] | [];
  move_in_date: string | null;
  has_followup: boolean;
  owner?: number;
}

export interface LeadData {
  chat_prospects?: Prospect[];
  created?: string;
  move_in_date?: string;
  name?: string;
  next_task?: string;
  next_task_date?: string;
  page?: number;
  source?: string;
  stage?: string;
  desired_unit?: string;
  email?: string;
  first_name?: string;
  firstName?: string;
  id?: number;
  lastName?: string;
  last_name?: string;
  moveInDate?: string;
  owner?: OwnerData;
  property?: number;
  property_name?: string;
  property_external_id?: string;
  property_can_sms?: boolean;
  phone_number?: string;
  status?: string;
  tasks?: TaskProps[];
  sms_opt_in_status?: string,
  unit_type?: string,
  floor_plan?: number[],
  units?: number[],
  calls?: CallProps[];
  email_messages?: EmailMessageProps[];
  notes?: NoteProps[];
  active_nurture?: string,
  is_shared?: boolean;
  roommates?: RoommateProps[];
  secondary_phone_number?: string;
}

export interface BELeadData extends LeadData {
  all_leads_count: number;
  active_leads_count: number;
  my_leads_count: number;
  pms_sync_date: string;
  pms_sync_status: string;
  pms_sync_condition_lack_reason: string;
}

export interface LeadNamesProps {
  id?: number;
  property: number;
  property_external_id: string;
}

export interface LeadState {
  isSubmitting: boolean;
  isLoaded: boolean;
  areLeadNamesLoaded: boolean;
  errorMessage: string;
  leads: LeadData[];
  lead: LeadData;
  leadProspect: LeadData;
  leadNames: LeadNamesProps[];
  count: number;
  filteredCount: number;
  pmsData: {
    pms_sync_status: string;
    pms_sync_date: string;
    pms_sync_condition_lack_reason: string;
  };
  totalLeadsCount: number;
  activeLeadsCount: number;
  myLeadsCount: number;
  isCommunicationLoaded: boolean;
  isRemainingCommunicationsLoaded: boolean;
  communications: Communication[];
  communicationCount: number;
  isCommunicationUpdate: boolean;
  isChatPusher: boolean;
  communicationSearchKeyword: string;
  communicationFilterType: string;
}

export interface Lead {
  id: number,
  application_sent_time?: string,
  email: string,
  secondary_email: string,
  phone_number: string,
  secondary_phone_number: string,
  best_contact_method: string,
  moving_reason: string,
  moving_reasons?: MovingReason[],
  best_contact_time: string,
  source: string,
  occupants: number,
  move_in_date: Date,
  days_to_move_in: number,
  desired_rent: number,
  origin: string,
  acquisition_date: string,
  acquisition_history: { date: string, source: string }[],
  lease_term: number,
  beds: number,
  baths: number,
  pets: number,
  pet_type: string,
  vehicles: number,
  washer_dryer_method: string,
  res_man_pet_weight: number,
  real_page_pet_weight: number,
  price_range: string,
  floor_plan: number[],
  units: number[],
  first_name: string,
  last_name: string,
  stage: string,
  owner: number,
  status: string,
  last_followup_date: string,
  last_activity_date: string,
  created: string,
  next_lead: Lead,
  prev_lead: Lead,
  chat_prospects: { id: number, is_online: boolean, source?: string }[],
  lease?: number,
  external_id?: string,
  tour_date?: string,
  lease_details?: LeadLeaseDetails,
  units_selected?: string[],
  tasks?: TaskProps[],
  sms_opt_in_status?: string,
  unit_type?: number,
  selected_unit_type_details?: {
    unit_type: string,
    highest_price: number,
    lowest_price: number,
  },
  selected_floorplan_details?: {
    floorplan: string,
    highest_price: number,
    lowest_price: number,
  },
  selected_unit_details?: {
    unit: string,
    price: number,
  },
  active_nurture?: string,
  is_prospect_source_mt?: boolean,
}

interface GetLeadsAction {
  type: ActionType.GET_LEAD_REQUEST | ActionType.GET_LEAD_SUCCESS | ActionType.GET_LEAD_FAILURE;

  result: {
    data: { results: LeadData[]; count: number; all_leads_count: number; active_leads_count: number; my_leads_count: number };
  };
  error: {
    response: { status: string };
  };
}

export interface GetLeadByIdAction {
  type: ActionType.GET_LEAD_BY_ID_REQUEST | ActionType.GET_LEAD_BY_ID_SUCCESS | ActionType.GET_LEAD_BY_ID_FAILURE;

  result: {
    data: BELeadData;
  };
  error: {
    response: { status: string };
  };
}

export interface UpdateLeadByIdAction {
  type: ActionType.UPDATE_LEAD_REQUEST | ActionType.UPDATE_LEAD_SUCCESS | ActionType.UPDATE_LEAD_FAILURE;

  result: {
    data: BELeadData;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteLeadsAction {
  type: ActionType.DELETE_LEADS_REQUEST | ActionType.DELETE_LEADS_SUCCESS | ActionType.DELETE_LEADS_FAILURE;

  error: {
    response: { status: string };
  };
}

interface ClearLeadsAction {
  type: ActionType.CLEAR_LEADS;
}

interface UpdateLeadsAction {
  type: ActionType.UPDATE_LEADS_REQUEST | ActionType.UPDATE_LEADS_SUCCESS | ActionType.UPDATE_LEADS_FAILURE;
  error: {
    response: { status: string };
  };
}

export interface CreateLeadAction {
  type: ActionType.CREATE_LEAD_REQUEST | ActionType.CREATE_LEAD_SUCCESS | ActionType.CREATE_LEAD_FAILURE;

  result: {
    data: BELeadData;
  };
  error: {
    response: { status: string };
  };
}

interface GetLeadNamesAction {
  type: ActionType.GET_LEAD_NAMES_REQUEST | ActionType.GET_LEAD_NAMES_SUCCESS | ActionType.GET_LEAD_NAMES_FAILURE;

  result: {
    data: LeadNamesProps[];
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertyLeadsNamesAction {
  type: ActionType.GET_LEAD_NAMES_REQUEST | ActionType.GET_LEAD_NAMES_SUCCESS | ActionType.GET_LEAD_NAMES_FAILURE;

  result: {
    data: LeadNamesProps[];
  };
  error: {
    response: { status: string };
  };
}

interface TestResmanAction {
  type: ActionType.TEST_RESMAN_REQUEST | ActionType.TEST_RESMAN_SUCCESS | ActionType.TEST_RESMAN_FAILURE;
}

interface GetFilteredLeadsCountAction {
  type: ActionType.GET_FILTERED_LEADS_COUNT_REQUEST | ActionType.GET_FILTERED_LEADS_COUNT_SUCCESS | ActionType.GET_FILTERED_LEADS_COUNT_FAILURE;

  result: {
    data: { count: number; first_lead: Lead };
  };
  error: {
    response: { status: string };
  };
}

interface MergeLeadsAction {
  type: ActionType.MERGE_LEADS_REQUEST | ActionType.MERGE_LEADS_SUCCESS | ActionType.MERGE_LEADS_FAILURE;
}

interface ShareLeadAction {
  type: ActionType.SHARE_LEAD_REQUEST | ActionType.SHARE_LEAD_SUCCESS | ActionType.SHARE_LEAD_FAILURE;
}

interface TransferLeadAction {
  type: ActionType.TRANSFER_LEAD_REQUEST | ActionType.TRANSFER_LEAD_SUCCESS | ActionType.TRANSFER_LEAD_FAILURE;
}

interface GetPMSSyncStatusByIdAction {
  type: ActionType.GET_PMS_SYNC_STATUS_BY_ID_REQUEST | ActionType.GET_PMS_SYNC_STATUS_BY_ID_SUCCESS | ActionType.GET_PMS_SYNC_STATUS_BY_ID_FAILURE;

  result: {
    data: { pms_sync_status: string; pms_sync_date: string; pms_sync_condition_lack_reason: string };
  };
  error: {
    response: { status: string };
  };
}

interface GetLeadForProspectAction {
  type: ActionType.GET_LEAD_FOR_PROSPECT_REQUEST | ActionType.GET_LEAD_FOR_PROSPECT_SUCCESS | ActionType.GET_LEAD_FOR_PROSPECT_FAILURE;

  result: {
    data: LeadData;
  };
  error: {
    response: { status: string };
  };
}

interface UnenrollLeadAction {
  type: ActionType.UNENROLL_LEAD_REQUEST | ActionType.UNENROLL_LEAD_SUCCESS | ActionType.UNENROLL_LEAD_FAILURE;

  result: {
    data: string;
  };
}

interface TransferToApplicationAction {
  type: ActionType.TRANSFER_TO_APPLICATION_REQUEST | ActionType.TRANSFER_TO_APPLICATION_SUCCESS | ActionType.TRANSFER_TO_APPLICATION_FAILURE;
}

export interface LeadAction {
  type:
  | ActionType.CREATE_NOTE_SUCCESS
  | ActionType.UPDATE_NOTE_SUCCESS
  | ActionType.DELETE_NOTE_SUCCESS
  | ActionType.CREATE_TASK_SUCCESS
  | ActionType.UPDATE_TASK_SUCCESS
  | ActionType.DELETE_TASK_SUCCESS
  | ActionType.COMPLETE_TASK_SUCCESS
  | ActionType.SEND_MESSAGE_SUCCESS
  | ActionType.SEND_TEXT_TO_LEAD_SUCCESS
  | ActionType.SEND_TEXT_TO_PROSPECT_SUCCESS
  | ActionType.DELETE_ROOMMATE_SUCCESS
  | ActionType.UPDATE_ROOMMATES_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: { data: any };
  error: { response: { status: string } };
  row: LeadData;
  pusherModel: string;
}

export type Action =
  | PromiseActionType
  | LeadAction
  | GetLeadsAction
  | GetLeadByIdAction
  | UpdateLeadByIdAction
  | DeleteLeadsAction
  | ClearLeadsAction
  | UpdateLeadsAction
  | CreateLeadAction
  | GetLeadNamesAction
  | GetPropertyLeadsNamesAction
  | TestResmanAction
  | GetFilteredLeadsCountAction
  | MergeLeadsAction
  | ShareLeadAction
  | TransferLeadAction
  | GetPMSSyncStatusByIdAction
  | GetLeadForProspectAction
  | PusherAction
  | UnenrollLeadAction
  | TransferToApplicationAction;
