import { paths } from 'site/constants';
import { getPropertyId } from 'src/utils';
import { CallBackFunction, CustomBlob, FloorPlan } from 'src/interfaces';
import { successCallback, failureCallback } from 'site/common';
import { ActionType, Action, Domains, PageData } from './action-types';

export default {
  getPageData: (sections: string): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PAGE_DATA_REQUEST,
        ActionType.GET_PAGE_DATA_SUCCESS,
        ActionType.GET_PAGE_DATA_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.PAGE_DATA, getPropertyId(), sections)),
      sections,
    },
  }),

  updatePageData: (sections: string, pageData: PageData, successCB: CallBackFunction = successCallback, failureCB: CallBackFunction = failureCallback): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PAGE_DATA_REQUEST,
        ActionType.UPDATE_PAGE_DATA_SUCCESS,
        ActionType.UPDATE_PAGE_DATA_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.PAGE_DATA_UPDATE, getPropertyId(), sections), pageData),
      successCB,
      failureCB,
      sections,
    },
  }),

  uploadImage: (file: CustomBlob, successCB: CallBackFunction = successCallback, failureCB: CallBackFunction = failureCallback): Action => {
    const data = new FormData();
    data.append('file', file, file.name);

    return {
      CALL_API: {
        types: [
          ActionType.PAGES_UPLOAD_IMAGE_REQUEST,
          ActionType.PAGES_UPLOAD_IMAGE_SUCCESS,
          ActionType.PAGES_UPLOAD_IMAGE_FAILURE,
        ],
        promise: client => client.post(paths.api.v1.UPLOAD_IMAGE, data),
        successCB,
        failureCB,
      },
    };
  },

  createPageData: (domain: string): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_PAGE_DATA_REQUEST,
        ActionType.CREATE_PAGE_DATA_SUCCESS,
        ActionType.CREATE_PAGE_DATA_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.AUTH_PAGE_DATA, { domain }),
    },
  }),

  updatePageDataDomain: ({ oldDomain, domain }: Domains, successCB: CallBackFunction = successCallback, failureCB: CallBackFunction = failureCallback): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PAGE_DATA_DOMAIN_REQUEST,
        ActionType.UPDATE_PAGE_DATA_DOMAIN_SUCCESS,
        ActionType.UPDATE_PAGE_DATA_DOMAIN_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.AUTH_PAGE_DATA_DOMAIN, oldDomain), { domain }),
      successCB,
      failureCB,
    },
  }),

  getFloorPlans: (feedSource: string): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_FLOOR_PLANS_REQUEST,
        ActionType.GET_FLOOR_PLANS_SUCCESS,
        ActionType.GET_FLOOR_PLANS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.FLOOR_PLANS, getPropertyId(), feedSource)),
    },
  }),

  createFloorPlan: (data: FloorPlan): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_FLOOR_PLAN_REQUEST,
        ActionType.CREATE_FLOOR_PLAN_SUCCESS,
        ActionType.CREATE_FLOOR_PLAN_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.FLOOR_PLANS, getPropertyId()), data),
    },
  }),

  updateFloorPlan: (data: FloorPlan): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_FLOOR_PLAN_REQUEST,
        ActionType.UPDATE_FLOOR_PLAN_SUCCESS,
        ActionType.UPDATE_FLOOR_PLAN_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.FLOOR_PLAN_DETAILS, getPropertyId(), data.id), data),
    },
  }),

  deleteFloorPlan: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_FLOOR_PLAN_REQUEST,
        ActionType.DELETE_FLOOR_PLAN_SUCCESS,
        ActionType.DELETE_FLOOR_PLAN_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.FLOOR_PLAN_DETAILS, getPropertyId(), id)),
    },
  }),

  setChangedState: (changed: boolean): Action => ({ type: ActionType.SET_PAGE_DATA_CHANGED_STATE, changed }),
  setSubmissionState: (clicked: boolean): Action => ({ type: ActionType.SET_SUBMISSION_STATE, clicked }),
  resetPageData: (): Action => ({ type: ActionType.RESET_PAGE_DATA }),
};
