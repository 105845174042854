import { paths } from 'site/constants';
import { CallBackFunction, ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, CustomerProps, OnboardData } from './action-types';

export default {
  getCustomers: (params: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CUSTOMERS_REQUEST,
        ActionType.GET_CUSTOMERS_SUCCESS,
        ActionType.GET_CUSTOMERS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CUSTOMERS, { params }),
    },
  }),
  getCustomersPagination: (params: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CUSTOMERS_PAGINATION_REQUEST,
        ActionType.GET_CUSTOMERS_PAGINATION_SUCCESS,
        ActionType.GET_CUSTOMERS_PAGINATION_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CUSTOMERS, { params }),
    },
  }),

  createCustomer: (customer: CustomerProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_CUSTOMER_REQUEST,
        ActionType.CREATE_CUSTOMER_SUCCESS,
        ActionType.CREATE_CUSTOMER_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CUSTOMERS, customer),
    },
  }),

  deleteCustomer: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_CUSTOMER_REQUEST,
        ActionType.DELETE_CUSTOMER_SUCCESS,
        ActionType.DELETE_CUSTOMER_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.CUSTOMERS_ID, id)),
    },
  }),

  getCustomerDetails: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.CUSTOMER_DETAILS_GET_REQUEST,
        ActionType.CUSTOMER_DETAILS_GET_SUCCESS,
        ActionType.CUSTOMER_DETAILS_GET_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.CUSTOMER_DETAILS, id)),
    },
  }),

  updateCustomer: (id: number, customer: CustomerProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CUSTOMER_REQUEST,
        ActionType.UPDATE_CUSTOMER_SUCCESS,
        ActionType.UPDATE_CUSTOMER_FAILURE,
      ],
      promise: client => client.put(paths.build(paths.api.v1.CUSTOMERS_ID, id), customer),
      successCB,
    },
  }),

  updateCustomerLogo: (id: number, customer: CustomerProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CUSTOMER_LOGO_UPDATE_REQUEST,
        ActionType.CUSTOMER_LOGO_UPDATE_SUCCESS,
        ActionType.CUSTOMER_LOGO_UPDATE_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.CUSTOMERS_ID, id), customer),
    },
  }),

  onboard: (data: OnboardData): Action => ({
    CALL_API: {
      types: [
        ActionType.ONBOARD_REQUEST,
        ActionType.ONBOARD_SUCCESS,
        ActionType.ONBOARD_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CUSTOMER_ONBOARD, data),
    },
  }),

  getSiteEnabledProperties: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CUSTOMER_SITE_ENABLED_PROPERTIES_REQUEST,
        ActionType.GET_CUSTOMER_SITE_ENABLED_PROPERTIES_SUCCESS,
        ActionType.GET_CUSTOMER_SITE_ENABLED_PROPERTIES_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.CUSTOMER_SITE_ENABLED_PROPERTIES, id)),
    },
  }),
};
