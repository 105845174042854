interface SMSVariables {
  VARIABLES: {
    tour_time: {
      name: string,
      hide?: boolean,
    }
  }
}

const variables = {
  VARIABLES: {
    applicant_name: { name: 'Applicant name' },
    applicant_first_name: { name: 'Applicant first name' },
    co_applicant_name: { name: 'Co-applicant name' },
    co_applicant_first_name: { name: 'Co-applicant first name' },
    guarantor_name: { name: 'Guarantor name' },
    guarantor_first_name: { name: 'Guarantor first name' },
    lease_owner: { name: 'Lease owner name' },
    lead_full_name: { name: 'Lead full name' },
    lead_first_name: { name: 'Lead first name' },
    lead_owner: { name: 'Lead owner' },
    lead_owner_first_name: { name: 'Lead owner first name' },
    property_address: { name: 'Property address' },
    property_name: { name: 'Property name' },
    property_email: { name: 'Property email' },
    property_phone_number: { name: 'Property phone number' },
    property_website: { name: 'Property website' },
    property_description: { name: 'Property description' },
    tour_time: { name: 'Tour time' },
    tour_type: { name: 'Tour type' },
    virtual_tour_link: { name: 'Virtual tour link' },
    tour_link: { name: 'Tour link' },
    unit_number: { name: 'Unit number' },
  },
} as SMSVariables;

export default variables;
