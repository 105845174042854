import React, { useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectHasMicrophoneAccess, selectShowAskForMicrophoneAccess } from 'dwell/store/voice/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import voiceActions from 'dwell/store/voice/action-creator';
import { PermsContainer, Perms, PermsMessage, PermsCloseIcon } from './styles';

const isFirefox = (): boolean => {
  const { userAgent } = navigator;
  return !!userAgent.match(/firefox|fxios/i);
};

const Microphone: FC = () => {
  const hasMicrophoneEnabled = useSelector(selectHasMicrophoneAccess);
  const showAskForMicrophoneAccess = useSelector(selectShowAskForMicrophoneAccess);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const { setHasMicrophoneAccess, setShowAskForMicrophoneAccess } = voiceActions;

  useEffect(() => {
    if (currentUser && currentUser.has_voice_setup && !isFirefox() && navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'microphone' as PermissionName })
        .then((perm) => {
          if (perm.state === 'granted') {
            dispatch(setHasMicrophoneAccess(true));
          } else {
            dispatch(setHasMicrophoneAccess(false));
          }
        })
        .catch(() => dispatch(setHasMicrophoneAccess(false)));
    }
  }, [currentUser]);

  const enableMicrophone = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) return;
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => dispatch(setHasMicrophoneAccess(true)))
      .catch(() => dispatch(setHasMicrophoneAccess(false)));
  };

  const closePerm = (event) => {
    event.stopPropagation(); // do not trigger enableMicrophone
    dispatch(setShowAskForMicrophoneAccess(false));
  };

  return currentUser && currentUser.has_voice_setup && !hasMicrophoneEnabled && showAskForMicrophoneAccess ? (
    <PermsContainer >
      <Perms onClick={enableMicrophone}>
        <PermsMessage>
          Click to enable incoming calls sounds and microphone access
        </PermsMessage>
        <PermsCloseIcon onClick={closePerm}>
          <i className="icons fa fa-times-circle" />
        </PermsCloseIcon>
      </Perms>
    </PermsContainer>
  ) : null;
};

export default Microphone;
