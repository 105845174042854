import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { getPropertyId } from 'src/utils';
import { GoBackWrapper, GoBackButton } from '../styles';

const GoBack: FC = () => {
  const { push } = useHistory();

  const onGoBack = () => {
    push(`/${getPropertyId()}/credit-builder/leases`);
  };

  return (
    <GoBackWrapper>
      <GoBackButton
        onClick={onGoBack}
        className="btn btn-icon btn-light"
      >
        <i className="ri-arrow-left-line" />
      </GoBackButton>
    </GoBackWrapper>
  );
};

export default GoBack;
