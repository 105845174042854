import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'dwell/actions/index';
import emailPopoutActions from 'src/dwell/store/email_popout/action-creator';
import { useInterval } from 'dwell/components';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { getAvatarColor, getInitials, isMostRecentTab } from 'dwell/views/chat/common/utils';
import { isEmpty } from 'lodash';
import newMessageSound from 'src/assets/audio/new-chat-message.mp3';
import { EmailPopout } from 'dwell/store/email_popout/action-types';
import { selectCircledPopouts } from 'dwell/store/email_popout/reducers';
import CircleHobbes from './CircleHobbes';
import { MinChatGroup, MinChatLink, MinChatItem, UnreadCount, Avatar, CloseIcon, CommunicationsTypeIcon } from './styles';

const NewMessageSoundPlayer = new Audio(newMessageSound);

const CircledChat: FC = () => {
  const isChatMinimized = useSelector(state => state.prospectChat.isChatMinimized);
  const activeChats = useSelector(state => state.prospectChat.activeChats);
  const prospects = useSelector(state => state.prospectChat.prospects.filter(p => p.should_display_in_chat));
  const contacts = useSelector(state => state.smsMessage.contacts);
  const newMessage = useSelector(state => state.prospectChat.newMessage);
  const chatType = useSelector(state => state.prospectChat.chatType);
  const availableAgentsCount = useSelector(state => state.prospectChat.availableAgentsCount);
  const currentUser = useSelector(state => state.user.currentUser);
  const currentTab = useSelector(state => state.prospectChat.currentTab);
  const isOpenProfileMenu = useSelector(state => state.propertyProfile.isOpen);
  const agentQuestionEnabled = useSelector(state => state.agentQuestion.agentQuestionEnabled);
  const [isBlinking, setIsBlinking] = useState(false);
  const selectedProperties = useSelector(selectSelectedProperties);
  const circledEmailPopouts = useSelector(selectCircledPopouts);

  const dispatch = useDispatch();
  const { setChatRenderedStatus, setChatMinimiseStatus, reorderActiveChats, removeFromActiveChats, clearNewMessageAlert } = actions.prospectChat;
  const { closeEmailPopout, activeCircledEmailPopout } = emailPopoutActions;

  const prospectUnread = prospects.reduce((acc, crr) => {
    if ((crr.active_agent === currentUser.id || crr.joined_agents.includes(currentUser.id)) && !crr.is_mute && !crr.is_archived) {
      return acc + Number(crr.unread_count);
    }
    return acc;
  }, 0);

  const smsUnread = contacts.reduce((acc, crr) => (acc + Number(crr.unread_count)), 0);

  const isMultiplePropertiesOrOneWAgent = (selectedProperties.length > 1) || (selectedProperties.length === 1 && selectedProperties[0].agent_chat_enabled);

  // Notifications
  const blinkTabMessage = () => {
    document.title = 'Prospect says...';
    setTimeout(() => {
      document.title = 'CRM';
    }, 3000);
  };

  const blinkBlue = () => {
    if (!isMultiplePropertiesOrOneWAgent) return;
    setIsBlinking(true);
    setTimeout(() => {
      setIsBlinking(false);
    }, 2000);
  };

  const shouldShowBlueBlink = (smsUnread && isChatMinimized) || (currentUser.is_available &&
      prospects.some(prospect => (prospect.active_agent === currentUser.id || prospect.joined_agents.includes(currentUser.id)) && prospect.unread_count > 0) &&
      (isChatMinimized || chatType === 'sms')
  );

  useInterval(() => {
    blinkBlue();
  }, shouldShowBlueBlink ? 4000 : null);

  useInterval(() => {
    blinkTabMessage();
  }, currentUser.is_available &&
      prospects.some(prospect => (prospect.active_agent === currentUser.id || prospect.joined_agents.includes(currentUser.id)) && prospect.unread_count > 0) ? 6000 : null);

  useEffect(() => {
    if (currentUser.is_available) {
      if (!isEmpty(newMessage)) {
        const prospect = prospects.find(p => p.id === newMessage.prospect);
        if (prospect && (prospect.active_agent === currentUser.id || prospect.joined_agents.includes(currentUser.id)) && !prospect.is_mute) {
          if (isMostRecentTab(currentTab)) {
            NewMessageSoundPlayer.play();
          }
          blinkBlue();
          blinkTabMessage();
        }
        dispatch(clearNewMessageAlert());
      }
    } else if (!availableAgentsCount) {
      if (!isEmpty(newMessage)) {
        blinkBlue();
        dispatch(clearNewMessageAlert());
      }
    }
  }, [newMessage, currentUser]);

  const totalUnreadCount = smsUnread + (isMultiplePropertiesOrOneWAgent ? prospectUnread : 0);

  const changeChatMinimiseStatus = () => {
    if (isChatMinimized) {
      dispatch(setChatRenderedStatus(true));
    }
    dispatch(setChatMinimiseStatus(!isChatMinimized));
  };

  const renderChatItem = (contactId) => {
    let contact;
    if (contactId.isSMS) {
      contact = contacts.find(i => i.id === contactId.id);
      if (typeof contact === 'undefined') {
        contact = {
          id: contactId.id,
          name: contactId.name,
          is_online: false,
          unread_count: contactId.unread_count,
        };
      }
    } else {
      contact = prospects.find(i => i.id === contactId.id);
      if (typeof contact === 'undefined') {
        contact = {
          id: contactId.id,
          name: contactId.name,
          is_online: contactId.is_online,
          unread_count: contactId.unread_count,
        };
      }
    }

    return (
      <MinChatItem onClick={() => dispatch(reorderActiveChats(contactId))} key={`ci-${contactId}`}>
        <Avatar className={getAvatarColor(contact.name, contact.id, contactId.isSMS)} online={contact.is_online} hideOnlineIcon={contactId.isSMS}>
          <i>{getInitials(contact.name, contactId.iSMS)}</i>
        </Avatar>
        <UnreadCount show={contact.unread_count}>
          {contact.unread_count}
        </UnreadCount>
        {!contactId.isSMS && (
          <CommunicationsTypeIcon show>
            <i className="ri-chat-3-fill" />
          </CommunicationsTypeIcon>)}
        {contactId.isSMS && (
          <CommunicationsTypeIcon show>
            <i className="ri-cellphone-fill" />
          </CommunicationsTypeIcon>)}
        <CloseIcon
          className="min-chat-close"
          onClick={(event) => {
            event.stopPropagation();
            dispatch(removeFromActiveChats(contactId));
          }}
        />
      </MinChatItem>
    );
  };

  const renderEmailItem = (popout: EmailPopout) => (
    <MinChatItem onClick={() => dispatch(activeCircledEmailPopout(popout.popoutId))} key={`ep-${popout.popoutId}`}>
      <Avatar className={popout.lead ? getAvatarColor(popout.lead.first_name, popout.lead.id) : 'bg-dark'} hideOnlineIcon>
        <i>{popout.lead ? `${popout.lead.first_name[0]}${popout.lead.last_name[0]}` : getInitials(popout?.emailMessage?.sender_name)}</i>
      </Avatar>
      <CommunicationsTypeIcon show>
        <i className="ri-mail-fill" />
      </CommunicationsTypeIcon>
      <CloseIcon
        className="min-chat-close"
        onClick={(event) => {
          event.stopPropagation();
          dispatch(closeEmailPopout(popout.popoutId));
        }}
      />
    </MinChatItem>
  );

  return (
    <MinChatGroup isOpenProfileMenu={isOpenProfileMenu}>
      <MinChatLink id="chat-circle" onClick={() => changeChatMinimiseStatus()} blinking={isBlinking}>
        <i id="chat-circle-icon" className="ri-question-answer-fill" />
        <UnreadCount show={totalUnreadCount}>
          {totalUnreadCount > 99 ? '99+' : totalUnreadCount}
        </UnreadCount>
      </MinChatLink>
      {agentQuestionEnabled && <CircleHobbes />}
      {activeChats.filter(contact => contact.circled).map(contact => renderChatItem(contact))}
      {circledEmailPopouts.map(popout => renderEmailItem(popout))}
    </MinChatGroup>);
};

export default CircledChat;
