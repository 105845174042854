import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, RunScreeningParams } from './action-types';

export default {
  getScreeningResult: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SCREENING_RESULT_REQUEST,
        ActionType.GET_SCREENING_RESULT_SUCCESS,
        ActionType.GET_SCREENING_RESULT_FAILURE,
      ],
      promise: client => client.get(build(api.v1.SCREENING_RESULT, leaseId), null),
    },
  }),

  runScreening: (leaseId: number, data?: RunScreeningParams): Action => ({
    CALL_API: {
      types: [
        ActionType.RUN_SCREENING_REQUEST,
        ActionType.RUN_SCREENING_SUCCESS,
        ActionType.RUN_SCREENING_FAILURE,
      ],
      promise: client => client.post(build(api.v1.RUN_SCREENING, leaseId), data),
    },
  }),
};
