import React, { useEffect, FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardText, Modal, ModalBody, ModalFooter, ModalHeader, Label } from 'reactstrap';
import moment from 'moment';

import actions from 'dwell/actions';
import 'src/scss/pages/_lead_creation.scss';
import { isEmpty } from 'lodash';
import { PropertyProps } from 'dwell/store/property/action-types';
import { LeadData } from 'dwell/store/lead/action-types';
import focusBarActions from 'dwell/store/focus_bar/action-creators';

interface LeadCreationDialogProps {
  show: boolean;
  handleClose: (isClose?: boolean, closeModal?: boolean) => void;
  errorData: { id: number; message: string };
  getLeadById: (id: number) => void;
  leadData: LeadData;
  newLeadData: LeadData;
  property?: PropertyProps;
}

const LeadCreationDialog: FC<LeadCreationDialogProps> = (props) => {
  const {
    getLeadById,
    errorData,
    show,
    handleClose,
    property,
    leadData: { created, desired_unit, first_name: firstName, last_name: lastName, email, phone_number },
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const closeBtn = (
    <button className="close" onClick={() => handleClose()}>
      <i className="ri-close-line" />
    </button>
  );

  useEffect(() => {
    if (!isEmpty(errorData) && errorData.id) {
      getLeadById(errorData.id);
    }
  }, [errorData]);

  return (
    <div>
      <Modal isOpen={show} centered aria-labelledby="example-custom-modal-styling-title" className="lead-creation-dialog">
        <ModalHeader close={closeBtn}>Duplicate Lead</ModalHeader>
        <ModalBody>
          <p>You cannot add a new lead record for this person.</p>

          <p>
            <b>{firstName} {lastName}</b> already exists in Union. You need to go to the prospect details page to make updates to this person's record.
          </p>

          <div>
            <div className="card-container">
              <Label>Existing record in Union:</Label>
              <Card body style={{ backgroundColor: 'white', borderColor: '#ccced9' }}>
                <CardText tag="div">
                  <div className="lead-info">
                    <h4>
                      {firstName} {lastName}
                    </h4>

                    <div className="lead-item mt-4">
                      <i className="ri-mail-fill" />
                      <p className="ml-1">
                        <b>Email</b>
                        <br />
                        <span>{email}</span>
                      </p>
                    </div>

                    <div className="lead-item mt-1">
                      <i className="ri-cellphone-fill" />
                      <p className="ml-1">
                        <b>Phone number</b>
                        <br />
                        <span>{phone_number || 'None'}</span>
                      </p>
                    </div>

                    <div className="lead-item mt-1">
                      <i className="ri-community-fill" />
                      <p className="ml-1">
                        <b>Desired unit</b>
                        <br />
                        <span>{desired_unit || 'None'}</span>
                      </p>
                    </div>

                    <div className="lead-item mt-1">
                      <i className="ri-calendar-check-fill" />
                      <p className="ml-1">
                        <b>Create date</b>
                        <br />
                        <span>{moment(created).format('ll LT')}</span>
                      </p>
                    </div>
                  </div>
                </CardText>
              </Card>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="white" onClick={() => handleClose(false, true)}>
            Cancel
          </Button>
          <button
            className="btn btn-primary ml-2"
            onClick={() => {
              dispatch(focusBarActions.resetFocusBar());
              history.push(`/${property.external_id}/leads/${errorData.id}/overview`);
            }}
            type="button"
          >
            Go to prospect details page
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  leadData: state.lead.lead,
});

export default connect(mapStateToProps, {
  ...actions.lead,
})(LeadCreationDialog);
