import { ActionType, Action, PusherRow } from './action-types';

export default {
  pusherCreateRecord: (pusherModel: string, row: PusherRow): Action => ({
    type: ActionType.PUSHER_CREATE_RECORD,
    row,
    pusherModel,
  }),
  pusherUpdateRecord: (pusherModel: string, row: PusherRow): Action => ({
    type: ActionType.PUSHER_UPDATE_RECORD,
    row,
    pusherModel,
  }),
  pusherDeleteRecord: (pusherModel: string, row: PusherRow): Action => ({
    type: ActionType.PUSHER_DELETE_RECORD,
    row,
    pusherModel,
  }),
  pusherDesktopPhoneCall: (pusherModel: string, row: PusherRow): Action => ({
    type: ActionType.PUSHER_DESKTOP_PHONE_CALL_ANSWERED,
    row,
    pusherModel,
  }),
  pusherDesktopPhoneCallCompleted: (pusherModel: string): Action => ({
    type: ActionType.PUSHER_DESKTOP_PHONE_CALL_COMPLETED,
    row: null,
    pusherModel,
  }),
  pusherBrowserPhoneCallAnswered: (pusherModel: string): Action => ({
    type: ActionType.PUSHER_BROWSER_PHONE_CALL_ANSWERED,
    row: null,
    pusherModel,
  }),
  pusherOutboundCallStatus: (pusherModel: string, row: PusherRow): Action => ({
    type: ActionType.PUSHER_OUTBOUND_CALL_STATUS,
    row,
    pusherModel,
  }),
  pusherClear: (): Action => ({
    type: ActionType.PUSHER_CLEAR,
  }),
  pusherConferenceStarted: (pusherModel: string, row: PusherRow): Action => ({
    type: ActionType.PUSHER_CONFERENCE_STARTED,
    row,
    pusherModel,
  }),
};
