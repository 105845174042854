import React, { FC, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'dwell/actions';
import ImagePreview, { ImageType } from './preview';
import { Link } from './styles';
import { Content, ImagePreview as ImagePreviewStyle } from '../styles';

const Gallery: FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const { openGalleryFullView } = actions.propertyProfile;
  const { community_map: communityMap, floor_plans: floorPlans } = useSelector(state => state.propertyProfile.gallery);
  const isGalleryPreviewOpen = useSelector(state => state.propertyProfile.isGalleryPreviewOpen);

  const galleryData = useMemo(() => {
    const data = [{ image: communityMap, label: '# Community Map', plan: 'Community Map' }] as ImageType[];
    floorPlans?.forEach((plan) => {
      plan.images.forEach((image, index) => {
        const bed = plan.bedrooms === 0 ? 'Studio' : `${plan.bedrooms} Bed`;
        data.push({
          image,
          label: index === 0 && `# ${plan.plan} | ${bed}`,
          plan: plan.plan,
          bedrooms: plan.bedrooms,
          bathrooms: plan.bathrooms,
          square_footage: plan.square_footage,
          isPlan: true,
        } as ImageType);
      });
    });
    return data;
  }, []);

  const clickPreview = (index) => {
    setActiveIndex(index);
    dispatch(openGalleryFullView(true));
  };

  const close = () => {
    dispatch(openGalleryFullView(false));
  };

  return (
    <Content>
      {galleryData.map((item, index) => (
        <section key={index}>
          {item.label && <h6 className="tx-16 text-dark">{item.label}</h6>}
          <ImagePreviewStyle onClick={() => clickPreview(index)}>
            <img src={item.image} data-testid={`gallery-image-${index}`} className="img-fluid" alt="" />
            <Link><i className="ri-code-view" /></Link>
          </ImagePreviewStyle>
          <br /><br />
        </section>
      ))}

      {isGalleryPreviewOpen && (
        <ImagePreview images={galleryData} activeIndex={activeIndex} onClose={close} />
      )}
    </Content>
  );
};

export default Gallery;
