import React, { useEffect, useRef, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import voiceActions from 'dwell/store/voice/action-creator';
import { selectCallTime, selectCallInProgress, selectCallConnected } from 'dwell/store/voice/reducers';
import { CallDirectionType } from 'dwell/store/voice/action-types';
import { CallTimeContainer } from './styles';

interface CallTimeProps {
  stop: boolean,
}

const CallTime: FC<CallTimeProps> = ({ stop }) => {
  const dispatch = useDispatch();
  const { setCallTime } = voiceActions;
  const callTime = useSelector(selectCallTime);
  const callConnected = useSelector(selectCallConnected);
  const { direction } = useSelector(selectCallInProgress);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (direction === CallDirectionType.INBOUND || callConnected) {
        dispatch(setCallTime(callTime + 1));
      }
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [callTime, direction, callConnected]);

  useEffect(() => {
    if (stop) {
      clearInterval(intervalRef.current);
    }
  }, [stop]);

  return (
    <CallTimeContainer>{moment.utc(callTime * 1000).format('m:ss')}</CallTimeContainer>
  );
};

export default CallTime;
