import React, { FC, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { PropertyProps } from 'dwell/store/property/action-types';
import { paths } from 'dwell/constants';
import { getPropertyId } from 'src/utils';

import { selectProperties, selectSelectedProperty } from 'dwell/store/property/reducers';
import { selectUserRole } from 'dwell/store/user/reducers';
import { PropertyGroupUlV2 } from 'containers/default_header/styles';
import { roleTypes } from 'site/constants';
import HeaderProperty from './_header_property';
import HeaderPropertyDropdown from './_header_property_dropdown';
import PropertyItemAll from './_property_item_all';

interface PropertyGroupProps {
  selectedProperties: PropertyProps[];
  propertiesCount: number;
}

const PropertyGroup: FC<PropertyGroupProps> = ({ selectedProperties, propertiesCount }) => {
  const location = useLocation();
  const [showPropertyItemAll, setShowPropertyItemAll] = useState<boolean>(true);
  const allUserProperties = useSelector(selectProperties);
  const selectedProperty = useSelector(selectSelectedProperty);
  const userRole = useSelector(selectUserRole);

  const [diplayedSelectedProperties, setDisplayedSelectedProperties] = useState<PropertyProps[]>([]);

  useEffect(() => {
    if (!isEmpty(selectedProperties)) {
      if (isEmpty(selectedProperty)) {
        setDisplayedSelectedProperties(selectedProperties);
      } else {
        setDisplayedSelectedProperties([selectedProperty]);
      }
    }
  }, [selectedProperties, selectedProperty]);

  let first3Properties: PropertyProps[] = [];
  let restOfProperties: PropertyProps[] = [];
  if (diplayedSelectedProperties.length >= 4) {
    first3Properties = diplayedSelectedProperties.slice(0, 3);
    restOfProperties = diplayedSelectedProperties.slice(3);
  }

  useEffect(() => {
    const propertyId = getPropertyId();
    const onePropertyPages = ['/leads/', '/leases/', '/automation/'];
    const emailURLs = [paths.client.BULK_EMAIL, paths.client.FOLLOWUPS.VIEW, paths.client.FOLLOWUPS.DETAILS].map(r => paths.build(r, propertyId));
    const competeURLS = [
      paths.client.COMPETE.HOME,
      paths.client.COMPETE.ALERTS_LOG,
      paths.client.COMPETE.ALERTS_SUBSCRIPTIONS,
      paths.client.COMPETE.ALERTS_SUMMARY,
      paths.client.COMPETE.COMPARISON_REPORT,
      paths.client.COMPETE.COMPARISON_REPORT_ID,
      paths.client.COMPETE.MARKET_REPORT,
      paths.client.COMPETE.PROPERTY_REPORT,
      paths.client.COMPETE.SUBMARKET_REPORT,
    ].map(r => paths.build(r, propertyId));
    const AllOnePropertyPages = onePropertyPages.concat(emailURLs, competeURLS);

    if (AllOnePropertyPages.some(str => location.pathname.includes(str))) {
      setShowPropertyItemAll(false);
    } else {
      setShowPropertyItemAll(true);
    }
  }, [location.pathname]);

  const getProperties = () => (
    <Fragment>
      {first3Properties.map(property => (
        <HeaderProperty key={property.id} property={property} />
      ))}
      <HeaderPropertyDropdown properties={restOfProperties} removable />
    </Fragment>
  );

  const areDisplayedPropertiesLessThanCount = diplayedSelectedProperties.length < propertiesCount;
  const areDisplayedPropertiesMoreThanCount = diplayedSelectedProperties.length >= propertiesCount;
  const TWO_PROPERTIES = 2;
  const THREE_PROPERTIES = 3;
  const areSelectedProperties = selectedProperties.length > 0;
  const oneSelectedProperty = selectedProperties.length === 1;
  const onlyOneDisplayedProperty = diplayedSelectedProperties.length === 1;
  const onlyOneDownloadedProperty = allUserProperties.length === 1;
  const userIsLiftLyticsAdmin = userRole === roleTypes.LIFT_LYTICS_ADMIN;
  const twoThreePropertiesAreDisplayed = [TWO_PROPERTIES, THREE_PROPERTIES].includes(diplayedSelectedProperties.length);
  const twoThreePropertiesAreDownloaded = [TWO_PROPERTIES, THREE_PROPERTIES].includes(allUserProperties.length);
  const areMoreThanCountAndMoreThanOne = areDisplayedPropertiesMoreThanCount && diplayedSelectedProperties.length > 1;
  const onePropertyHeaderCondition = areSelectedProperties && onlyOneDisplayedProperty && (areDisplayedPropertiesLessThanCount || (areDisplayedPropertiesMoreThanCount && oneSelectedProperty));
  const twoThreePropertyHeaderCondition = areSelectedProperties && twoThreePropertiesAreDisplayed && (areDisplayedPropertiesLessThanCount || twoThreePropertiesAreDownloaded);
  const multiPropertiesHeaderCondition = areSelectedProperties && diplayedSelectedProperties.length >= 4 && (areDisplayedPropertiesLessThanCount || (!userIsLiftLyticsAdmin && areDisplayedPropertiesMoreThanCount));
  const propertyDropdownCondition = areSelectedProperties && userIsLiftLyticsAdmin && areMoreThanCountAndMoreThanOne;
  const allPropertyCondition = !((onlyOneDownloadedProperty || !allUserProperties.length) && onlyOneDisplayedProperty);

  return (
    <PropertyGroupUlV2>
      {onePropertyHeaderCondition && <HeaderProperty isOneDisplayed property={diplayedSelectedProperties[0]} />}
      {twoThreePropertyHeaderCondition && diplayedSelectedProperties.map(property => <HeaderProperty key={property.id} property={property} />)}
      {multiPropertiesHeaderCondition && getProperties()}
      {propertyDropdownCondition && <HeaderPropertyDropdown properties={diplayedSelectedProperties} removable />}
      {allPropertyCondition && showPropertyItemAll && <PropertyItemAll />}
    </PropertyGroupUlV2>
  );
};

export default PropertyGroup;
