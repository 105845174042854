import React, { FC, ReactNode } from 'react';
import { CallBodyItemBaseProps } from '.';

import {
  CallBodyItemHeader,
  CallBodyItemBody,
  CallBodyItemClose,
} from './styles';

interface CallBodyItemProps extends CallBodyItemBaseProps {
  itemTitle: string;
  children: ReactNode;
  bodyClassNames?: string;
  useHeader?: boolean;
}

const CallBodyItem: FC<CallBodyItemProps> = ({ itemTitle, bodyClassNames, children, hide, onClose, useHeader = true }) => {
  const hideStyle = hide ? { display: 'none' } : {};
  return (
    <div style={hideStyle}>
      {useHeader && (
        <CallBodyItemHeader>
          <h6>{itemTitle}</h6>
          {onClose && (
            <CallBodyItemClose>
              <i className="ri-close-circle-fill" onClick={() => onClose()} />
            </CallBodyItemClose>
          )}
        </CallBodyItemHeader>
      )}
      <CallBodyItemBody className={bodyClassNames}>
        {children}
      </CallBodyItemBody>
    </div>
  );
};

export default CallBodyItem;
