import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import callActions from 'dwell/store/voice/action-creator';
import { PrimaryButton } from 'styles/common';
import { selectCallingFromDesktopProspectName } from 'dwell/store/voice/reducers';
import {
  CallNotificationWrapper,
  CallNotificationHeader,
  CallNotificationBody,
  CallNotificationFooter,
} from './styles';

const OutboundCallNotification: FC = () => {
  const dispatch = useDispatch();
  const prospectName = useSelector(selectCallingFromDesktopProspectName);
  const closeNotification = () => {
    dispatch(callActions.closeCallNotification());
  };

  return (
    <CallNotificationWrapper>
      <CallNotificationHeader>
        <div className="avatar"><i className="ri-phone-fill" /></div>
        <h6>Outbound Call</h6>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="close call-close" onClick={closeNotification}><i className="ri-close-line" /></a>
      </CallNotificationHeader>
      <CallNotificationBody>
        <p className="mg-b-5">Initiating call to: <strong className="ml-1">{prospectName}</strong></p>
      </CallNotificationBody>
      <CallNotificationFooter>
        <PrimaryButton className="btn btn-primary btn-connecting"><i className="ri-refresh-line lh-1" />Connecting you to caller...</PrimaryButton>
      </CallNotificationFooter>
    </CallNotificationWrapper>
  );
};

export default OutboundCallNotification;
