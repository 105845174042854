import { UnitReleaseProps } from 'src/interfaces';
import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, LeaseUpdateParam } from './action-types';

export default {
  collapseStepper: (value: boolean): Action => ({
    type: ActionType.COLLAPSE_STEPPER,
    value,
  }),

  getLeaseAddenda: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEASE_ADDENDA_REQUEST,
        ActionType.GET_LEASE_ADDENDA_SUCCESS,
        ActionType.GET_LEASE_ADDENDA_FAILURE,
      ],
      promise: client => client.get(build(api.v1.LEASE_DETAILS, leaseId), { params: { query: '{id, addenda}' } }),
    },
  }),

  getLeaseById: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEASE_BY_ID_REQUEST,
        ActionType.GET_LEASE_BY_ID_SUCCESS,
        ActionType.GET_LEASE_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(api.v1.LEASE_DETAILS, leaseId)),
    },
  }),

  resetLease: (): { type: string } => ({ type: ActionType.RESET_LEASE }),

  unitReleaseHold: (unitId: number, params?: UnitReleaseProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_UNIT_RELEASE_HOLD_REQUEST,
        ActionType.UPDATE_UNIT_RELEASE_HOLD_SUCCESS,
        ActionType.UPDATE_UNIT_RELEASE_HOLD_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.UNIT_RELEASE_HOLD, unitId), params),
      id: unitId,
      newHoldId: params && params.id,
    },
  }),

  updateApplicationStatus: (leaseId: number, params: LeaseUpdateParam): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_APPLICATION_STATUS_REQUEST,
        ActionType.UPDATE_APPLICATION_STATUS_SUCCESS,
        ActionType.UPDATE_APPLICATION_STATUS_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.LEASE_DETAILS, leaseId), params),
    },
  }),

  updateLeaseById: (leaseId: number, params: LeaseUpdateParam): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_LEASE_REQUEST,
        ActionType.UPDATE_LEASE_SUCCESS,
        ActionType.UPDATE_LEASE_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.LEASE_DETAILS, leaseId), params),
    },
  }),

  getLeaseAvailableUnits: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEASE_AVAILABLE_UNITS_REQUEST,
        ActionType.GET_LEASE_AVAILABLE_UNITS_SUCCESS,
        ActionType.GET_LEASE_AVAILABLE_UNITS_FAILURE,
      ],
      promise: client => client.get(api.v1.LEASE_AVAILABLE_UNITS),
    },
  }),

  transferUnit: (leaseId: number, data: { unit: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.TRANSFER_UNIT_REQUEST,
        ActionType.TRANSFER_UNIT_SUCCESS,
        ActionType.TRANSFER_UNIT_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEASE_TRANSFER_UNIT, leaseId), data),
    },
  }),

  releaseUnit: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.RELEASE_UNIT_REQUEST,
        ActionType.RELEASE_UNIT_SUCCESS,
        ActionType.RELEASE_UNIT_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEASE_RELEASE_UNIT, leaseId)),
    },
  }),

  bulkUpdateLeases: (property: number, params: LeaseUpdateParam): Action => ({
    CALL_API: {
      types: [
        ActionType.BULK_UPDATE_LEASES_REQUEST,
        ActionType.BULK_UPDATE_LEASES_SUCCESS,
        ActionType.BULK_UPDATE_LEASES_FAILURE,
      ],
      promise: client => client.put(api.v1.LEASE_BULK_UPDATE, params, { params: { property } }),
    },
  }),

  setLeaseData: (field: string, value: string | number): Action => ({ type: ActionType.SET_LEASE_DATA, field, value }),
};
