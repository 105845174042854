import React, { useEffect, useState } from 'react';
import { Row, Col, Input, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import cn from 'classnames';
import { CheckBox, ImageUpload } from 'site/components';
import { PrimaryButton, WhiteButton } from 'styles/common';
import { isEmpty } from 'lodash';
import { FloorPlanCard } from '../common';
import { ImageGalleryItem, ButtonAction, ButtonContainer, Gallery, ListGroup, ListGroupItem, UnavailablePlan, AddFloorPlanCardBody } from './styles';
import ImageCarousel from './_carousel';

interface ErrorProps {
  available: boolean,
  plan: boolean,
  bedrooms: boolean,
  bathrooms: boolean,
}

const defaultFormValues = {
  images: [], available: null, bathrooms: null, bedrooms: null, plan: null, square_footage: null, min_rent: null, max_rent: null, active: true, show_square_footage: false, isNew: false };
const FloorPlanItem = ({ onRemoveClick, indexToChange, plan, onDropAccepted, deleteFloorPlan, saveFloorPlan, cancelFloorPlan }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEditing, setEditingState] = useState(false);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [errors, setErrors] = useState({} as ErrorProps);
  const unitString = formValues.available <= 1 ? 'unit' : 'units';
  const available = !formValues.available ? 'Join Waitlist' : `${formValues.available} ${unitString} available`;
  const images = formValues.images?.length ? formValues.images.map(image => ({ src: image })) : [{ src: '/static/images/no-image.jpg' }];

  const onChange = ({ target: { id, value } }) => {
    let newValue = value;
    if (newValue && ['available', 'bedrooms'].some(i => id.includes(i))) newValue = parseInt(newValue, 10);
    if (['min_rent', 'max_rent', 'square_footage'].some(i => id.includes(i))) {
      newValue = parseFloat(newValue);
      newValue = Number.isNaN(newValue) ? null : newValue;
    }
    if (Number.isNaN(newValue)) newValue = null;
    let showSqft = formValues.show_square_footage;
    if (plan.isNew && id === 'square_footage' && value) {
      showSqft = true;
    }
    setFormValues({ ...formValues, [id]: newValue, show_square_footage: showSqft });
  };

  const onKeyPress = (event) => {
    const regex = event.target.id === 'bathrooms' ? /\d|\./ : /\d/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const onCancel = () => {
    cancelFloorPlan();
    setEditingState(false);
    setFormValues(plan);
  };

  useEffect(() => {
    if (plan) {
      const values = { ...plan };
      if (plan.isNew) {
        setEditingState(true);
        delete values.isNew;
      }
      setFormValues(values);
    }
  }, [plan]);

  useEffect(() => {
    if (plan) {
      const values = { ...plan };
      setFormValues(values);
    }
  }, []);

  const validate = () => {
    const error = {} as ErrorProps;
    if (formValues.available !== 0 && !formValues.available) error.available = true;
    if (formValues.bedrooms === null || formValues.bedrooms < 0 || formValues.bedrooms > 5) error.bedrooms = true;
    if (formValues.bedrooms === null || formValues.bathrooms < 1 || formValues.bathrooms > 5) error.bathrooms = true;
    if (!formValues.plan) error.plan = true;
    setErrors(error);
    return isEmpty(error);
  };

  const onSave = () => {
    if (validate()) {
      setEditingState(false);
      saveFloorPlan(formValues);
    }
  };

  const onCheckboxSave = (id, value) => {
    if (isEditing) {
      setFormValues({ ...formValues, [id]: value });
    } else {
      setFormValues({ ...formValues, [id]: value });
      saveFloorPlan({ ...formValues, [id]: value });
    }
  };

  const removeImage = () => {
    onRemoveClick(indexToChange, activeIndex);
  };

  const getPlanPrice = () => {
    let price = {} as { minRent: number, maxRent: number };

    if (formValues.min_rent > 0 && formValues.max_rent > 0) {
      if (formValues.min_rent === formValues.max_rent) {
        price.minRent = formValues.min_rent;
      } else {
        const minRent = Math.min(formValues.min_rent, formValues.max_rent);
        const maxRent = Math.max(formValues.min_rent, formValues.max_rent);
        price = { minRent, maxRent };
      }
    } else if (formValues.min_rent > 0) {
      price.minRent = formValues.min_rent;
    } else if (formValues.max_rent > 0) {
      price.maxRent = formValues.max_rent;
    }

    return price;
  };

  const { minRent, maxRent } = getPlanPrice();

  return (
    <React.Fragment>
      <ImageGalleryItem>
        <ImageCarousel images={images} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        <ButtonContainer data-testid="upload-floorplan">
          <ImageUpload
            onDropAccepted={(e) => {
              onDropAccepted(e, plan.id, true).then(() => {
                setActiveIndex(formValues.images?.length - 1);
              });
            }}
            title="Upload image"
            dropzoneContainer={() => <ButtonAction><i className="ri-edit-2-fill" /></ButtonAction>}
          />
          {!!formValues.images?.length && <ButtonAction onClick={() => removeImage()} data-testid="remove-image"><i className="ri-delete-bin-line" /></ButtonAction>}
        </ButtonContainer>
      </ImageGalleryItem>
      <CardBody data-testid="plan-body">
        <ListGroup>
          <ListGroupItem>
            <span>Model</span>
            <span className={cn({ editing: isEditing })}>
              {isEditing ?
                <Input id="plan" value={formValues.plan} onChange={onChange} invalid={errors.plan} />
                : formValues.plan}
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span>Units Available</span>
            <span className={cn({ editing: isEditing })}>
              {isEditing ?
                <Input id="available" onKeyPress={onKeyPress} value={formValues.available} onChange={onChange} invalid={errors.available} />
                : available}
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span>Bed/Bath</span>
            {isEditing ? (
              <span className="beds-and-baths">
                <Input id="bedrooms" onKeyPress={onKeyPress} value={formValues.bedrooms} onChange={onChange} invalid={errors.bedrooms} />
                <span className="ml-1 mr-1">/</span>
                <Input id="bathrooms" onKeyPress={onKeyPress} value={formValues.bathrooms} onChange={onChange} invalid={errors.bathrooms} />
              </span>
            ) : <span>{formValues.bedrooms}/{formValues.bathrooms}</span>}
          </ListGroupItem>
          <ListGroupItem>
            <span>Sq./Ft.</span>
            <span className={cn({ editing: isEditing })}>
              {isEditing ?
                <Input id="square_footage" onKeyPress={onKeyPress} value={formValues.square_footage} onChange={onChange} />
                : formValues.square_footage}
            </span>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
      <CardFooter className="text-center">
        {isEditing ? (
          <>
            <FormGroup>
              <span>$</span>
              <Input
                id="min_rent"
                name="search"
                onKeyPress={onKeyPress}
                value={formValues.min_rent}
                placeholder="Min"
                className="mr-2 mb-0"
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <span>$</span>
              <Input
                id="max_rent"
                name="search"
                onKeyPress={onKeyPress}
                className="mb-0"
                value={formValues.max_rent}
                placeholder="Max"
                onChange={onChange}
              />
            </FormGroup>
          </>
        ) : (
          <>
            {formValues.available && (minRent || maxRent) ?
              <>
                {minRent && <FormGroup>${minRent}</FormGroup>}
                {maxRent && <FormGroup>${maxRent}</FormGroup>}
              </> :
              <UnavailablePlan>Call for pricing</UnavailablePlan>}
          </>
        )}
      </CardFooter>
      { !isEditing && (
        <CardFooter>
          <Row>
            <Col xs="6 mt-10">
              <CheckBox
                id={`allPlans[${indexToChange}].active`}
                label="Active"
                checked={formValues.active}
                onChange={() => onCheckboxSave('active', !formValues.active)}
                labelClassName="label-checkbox"
              />
            </Col>
            <Col xs="6 mt-10">
              <CheckBox
                id={`allPlans[${indexToChange}].show_square_footage`}
                label="Show Square Footage"
                checked={formValues.show_square_footage}
                onChange={() => onCheckboxSave('show_square_footage', !formValues.show_square_footage)}
                labelClassName="label-checkbox"
              />
            </Col>
          </Row>
        </CardFooter>
      )
      }
      <CardFooter className="justify-content-end">
        {isEditing ? (
          <>
            <PrimaryButton className="mr-2" onClick={onSave}>Save</PrimaryButton>
            <WhiteButton onClick={onCancel}>Cancel</WhiteButton>
          </>
        ) :
          <>
            <WhiteButton className="mr-2" onClick={() => deleteFloorPlan(plan?.id)}>Delete</WhiteButton>
            <WhiteButton onClick={() => setEditingState(true)}>Edit</WhiteButton>
          </>}
      </CardFooter>
    </React.Fragment>
  );
};

const SortableItem = SortableElement(props => <FloorPlanItem {...props} />);

const SortableFloorPlanList = SortableContainer(({ plans, onRemoveClick, onDropAccepted, addFloorPlan, deleteFloorPlan, saveFloorPlan, cancelFloorPlan }) => (
  <Gallery>
    {plans.map((plan, index) => (
      <FloorPlanCard key={index} data-testid="floor-plan-card">
        <SortableItem
          index={index}
          indexToChange={index}
          plan={plan}
          refreshImages={{}}
          onRemoveClick={onRemoveClick}
          onDropAccepted={onDropAccepted}
          deleteFloorPlan={deleteFloorPlan}
          saveFloorPlan={saveFloorPlan}
          cancelFloorPlan={cancelFloorPlan}
        />
      </FloorPlanCard>

    ))}

    {!plans.some(plan => plan.isNew) && (
      <FloorPlanCard>
        <AddFloorPlanCardBody>
          <PrimaryButton onClick={() => addFloorPlan()}>
            <span className="mr-2">+</span> Add Floor Plan
          </PrimaryButton>
        </AddFloorPlanCardBody>
      </FloorPlanCard>
    )}
  </Gallery>
));

SortableFloorPlanList.defaultProps = {
};

export default SortableFloorPlanList;
