import styled from 'styled-components';
import {
  ChatItemContainer,
  ChatItemHeader,
} from 'src/dwell/views/chat/single_chat/window/styles';

import { ChatHeaderLink } from 'src/dwell/views/chat/single_chat/contact/styles';

// qc-item-email
export const EmailItemContainer = styled(ChatItemContainer)`
  display: block;
  width: 600px;
  max-height: 784 px;
  &:first-child {
    display: block;
  }
  &.minimize {
    width: 280px;
    .qc-item-body, .qc-item-footer { display: none; }
  }
  &::before {
    content: 'EMAIL';
    color: #15274d;
    background-color: #F7F8FC;
  }
  .qc-item-header {
    background-color: #F7F8FC;
    border-top-right-radius: inherit;
    .avatar::before { display: none; }
    .qc-link-close {
      background-color: transparent;
      &:hover, &:focus {
        background-color: rgba(225, 230, 247, 0.75);
      }
    }
    .media-body {
      align-self: stretch;
      padding-top: 10px;
      align-items: center;
    }
  }
  .cke_chrome {
    .cke_top {
      border-bottom: 1px solid #e4e7ea !important;
      padding: 6px 15px 2px;
      @include border-top-radius(4px);
    }
  }

  .qc-item-body {
    position: relative;
    max-height: none;
    height: auto;
    @media (min-height: 580px) {
      max-height: calc(100vh - 210px);
      overflow-y: auto;
    }

    @media (min-height: 900px) {
      max-height: 668px;
      overflow-y: auto;
    }
  }


  .qc-item-footer {
    border-top: 1px solid #e1e6f7;
    display: flex;
    align-items: center;
    grid-gap: 5px;

    .custom-select {
      width: 150px;
      height: 40px;
      border-radius: 4px;
    }
    .btn {
      min-height: 0;
      height: 40px;
      border-radius: 4px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .btn-icon {
      border: 1px solid #e1e6f7;
      width: 35px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4a5e8a;
    }
  }
`;

// qc-item-header
export const EmailItemHeader = styled(ChatItemHeader)`
  height: 54px;
`;

export const EmailItemHeaderLink = styled(ChatHeaderLink)`
  background-color: transparent;
  color: #929eb9;
`;

// qc-item-body
export const EmailItemBody = styled.div`
  height: 300px;
  height: calc(100% - 116px);

  max-height: calc(100vh - ${props => props.theme.templates.headerHeight} + 146px);
  position: relative;
  overflow: hidden;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .avatar {
    background-color: ${props => props.theme.colors.colorbg03};
    width: 26px;
    height: 26px;

    &::before {
      display: none;
    }

    i {
      font-size: 10px;
      font-family: ${props => props.theme.fonts.numeric};
      font-style: normal;
    }

    span {
      font-size: 11px;
    }

    img {
      width: 26px;
      height: 26px;
      border-radius: 100%;

      &.bot {
          width: 18px;
          height: 18px;
      }
    }
  }

  small {
    font-size: 10px;
    color: ${props => props.theme.colors.colortx03};
    display: block;
  }

  overflow: hidden;
  overflow-y: auto;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.colors.colorbg03};
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

// qc-email-recipient
export const EmailItemRecipient = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e1e6f7;
  height: 42px;

  label {
    margin-bottom: 0;
    font-weight: 500;
  }
  .btn:focus { box-shadow: none; }
  .form-control {
    flex: 1;
    margin: 0 10px;
    border-radius: 0;
    border-width: 0;
    padding: 0;
    height: auto;
    background-color: white;
    &:focus { box-shadow: none; }
    a { font-size: 13px; }
    & + a { text-decoration: none }
  }
  .popout-subject {
    display: flex;
    width: 100% !important;
    .subject-variable {
      background-color: #ffeec2;
    }

    .subject-variable-empty {
      background-color: #ff8787;
    }
    .popout-subject__input {
      border: none;
      width: 100% !important;
      outline: none;
    }
  }

  .popout-subject__input:focus {
    color: #5c6873;
    background-color: #fff;
    border-color: #8ad4ee;
    outline: 0;
  }

  .popout-subject__input::placeholder {
    color: #73818f;
  }

  .popout-subject__suggestions {
    margin-top: 2.3rem !important;
    background-color: black !important;

    .popout-subject__suggestions__list {
      background-color: #fff;
      font-size: 14px;
      max-height: 200px;
      overflow: auto;
      border-radius: 0.25rem;
      border: 1px solid #e4e7ea;

      .popout-subject__suggestions__item {
        padding: 5px 15px;
        border-bottom: 1px solid #e4e7ea;
      }

      .popout-subject__suggestions__item--focused{
        background-color: #daf4fa;
      }

      .popout-subject__suggestions__item:focus {
        background-color: #daf4fa;
      }

      .popout-subject__suggestions__item:hover {
        background-color: #daf4fa;
      }
    }
  }
`;

// qc-email-body
export const EmailItemEmailBody = styled.div`
  padding: 10px;
  height: 200px;
  textarea {
    height: 100%;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    -moz-appearance: none;
    appearance: none;
    &:hover, &:focus { box-shadow: none; }
  }
`;

// qc-item-footer
export const EmailItemFooter = styled.div`
  padding: 10px;
  .msg-input {
    display: flex;
    align-items: center;
    height: 38px;
    background-color: #f0f2f9;
    padding-left: 12px;
    padding-right: 8px;
    border-radius: 6px;
  }
  .form-control {
    flex: 1;
    height: auto;
    padding: 0;
    background-color: transparent;
    border-width: 0 !important;
    border-radius: 0;
    font-size: 13px;
  }
  .form-control:focus { box-shadow: none; }
  .dropdown {
    margin-left: 5px;
    margin-top: 4px;
  }
  a {
    font-size: 20px;
    color: #0168fa;
  }
  .msg-send {
    margin-top: 3px;
  }
  .dropdown-link {
    font-size: 24px;
  }
  .btn-join {
    justify-content: center;
    display: none;
  }
`;

// qc-file-name
export const EmailFile = styled.div`
  margin: 0 10px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #e1e6f7;
  & span { flex: 1; }
  & a {
    color: #657697;
    font-size: 18px;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  & + & { border-top-style: dotted; }
`;

export const AlertEmailPreview = styled.div`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 0;
  padding-left: 10px;
  padding-right: 10px;
  color: #0d1a38;
  i {
    font-size: 18px;
    line-height: 1;
    margin-right: 5px;
  }
`;
