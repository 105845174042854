export enum ActionType {
  GET_CALL_SCORING_QUESTIONS_REQUEST = 'GET_CALL_SCORING_QUESTIONS_REQUEST',
  GET_CALL_SCORING_QUESTIONS_SUCCESS = 'GET_CALL_SCORING_QUESTIONS_SUCCESS',
  GET_CALL_SCORING_QUESTIONS_FAILURE = 'GET_CALL_SCORING_QUESTIONS_FAILURE',
}

export interface CallScoringQuestionsProps {
  id: number;
  created: string;
  updated: string;
  category: string;
  question: string;
  status: string;
  weight: number;
  order: number;
  is_not_omitting: boolean;
}

interface GetCallScoringQuestionsAction {
  type: ActionType.GET_CALL_SCORING_QUESTIONS_REQUEST | ActionType.GET_CALL_SCORING_QUESTIONS_SUCCESS | ActionType.GET_CALL_SCORING_QUESTIONS_FAILURE;

  result: {
    data: { results: CallScoringQuestionsProps[] };
  };
  error: {
    response: { status: string };
  };
}

export type Action = | GetCallScoringQuestionsAction;
