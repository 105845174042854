import moment from 'moment';
import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { unionBy } from 'lodash';
import { loadLocalStorage } from 'src/utils';
import { menuItems } from 'dwell/views/pipeline_new/filter/utils';
import { Action, ActionType, ProspectType, ApplicationType, LeaseType, FilterType, ColumnType, SearchResultType, SearchedObjectType } from './action-types';
import { CommonStateType } from '../types';

export interface PipelineState extends CommonStateType {
  prospects: ProspectType[];
  applications: ApplicationType[];
  leases: LeaseType[];
  searchResult: SearchResultType[];
  filters: FilterType[],
  columns: ColumnType[],
  contextMenuInfo: null | [number, number, { [key: string]: string }], // x, y, raw id
  isTableLoaded: { general: boolean, combined: boolean };
  page: { general: number, combined: number };
  sizePerPage: { general: number, combined: number };
  totalSize: { general: number, combined: number };
  selected: number[];
  sortField: { general: string, combined: string },
  sortOrder: { general: string, combined: string },
  filter: string | number,
  keyword: string,
  category: string,
  shouldReload: boolean,
  filterFormData: FilterType,
  activeTable: string,
  searchedObjects: SearchedObjectType;
}

export const defaultFormData = {
  name: '',
  items: [{ field: 'created', operator: 'IS_ON', value: [moment().format('YYYY-MM-DD')] }],
  condition: 'ALL',
  category: '',
  id: 0,
};

const defaultSortOrder = (loadLocalStorage('pipeline_sort_order') || { general: null, combined: null }) as { general: string, combined: string };
const defaultSortField = (loadLocalStorage('pipeline_sort_field') || { general: null, combined: null }) as { general: string, combined: string };
const defaultSizePerPage = (loadLocalStorage('pipeline_size_per_page') || { general: 10, combined: 10 }) as { general: number, combined: number };

export const initialState: PipelineState = {
  isSubmitting: false,
  isLoaded: true,
  isTableLoaded: { general: false, combined: false },
  errorMessage: null,
  prospects: [],
  applications: [],
  leases: [],
  searchResult: [],
  filters: [],
  columns: [],
  contextMenuInfo: null,
  page: { general: 1, combined: 1 },
  sizePerPage: defaultSizePerPage,
  totalSize: { general: 0, combined: 0 },
  selected: [],
  sortField: defaultSortField,
  sortOrder: defaultSortOrder,
  filter: 'ACTIVE',
  keyword: null,
  category: 'PROSPECT',
  shouldReload: false,
  filterFormData: defaultFormData as FilterType,
  activeTable: null,
  searchedObjects: { leads: [], leases: [], residents: [], applications: [], current_residents: [] },
};

export default produce((state: PipelineState = initialState, action: Action): PipelineState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_PROSPECTS_REQUEST:
    case ActionType.GET_APPLICATIONS_REQUEST:
    case ActionType.GET_LEASES_REQUEST:
    case ActionType.GET_PROSPECTS_FAILURE:
    case ActionType.GET_APPLICATIONS_FAILURE:
    case ActionType.GET_LEASES_FAILURE:
      state.isTableLoaded.general = false;
      state.prospects = [];
      state.leases = [];
      state.applications = [];
      state.totalSize.general = 0;
      break;

    case ActionType.ALL_SEARCH_REQUEST:
    case ActionType.ALL_SEARCH_FAILURE:
      state.isTableLoaded.combined = false;
      break;

    case ActionType.GET_PROSPECTS_SUCCESS:
      state.prospects = action.result.data.results;
      state.totalSize.general = action.result.data.count;
      state.isTableLoaded.general = true;
      break;

    case ActionType.GET_APPLICATIONS_SUCCESS:
      state.applications = action.result.data.results;
      state.totalSize.general = action.result.data.count;
      state.isTableLoaded.general = true;
      break;

    case ActionType.GET_LEASES_SUCCESS:
      state.leases = action.result.data.results;
      state.totalSize.general = action.result.data.count;
      state.isTableLoaded.general = true;
      break;

    case ActionType.ALL_SEARCH_SUCCESS:
      state.searchResult = action.result.data.results;
      state.totalSize.combined = action.result.data.count;
      state.isTableLoaded.combined = true;
      break;

    case ActionType.GET_FILTERS_SUCCESS:
      state.filters = action.result.data.results;
      break;

    case ActionType.CREATE_FILTER_SUCCESS:
      state.filters = unionBy([action.result.data], state.filters, 'id');
      break;

    case ActionType.UPDATE_FILTER_SUCCESS:
      state.filters = unionBy([action.result.data], state.filters, 'id');
      break;

    case ActionType.DELETE_FILTER_SUCCESS:
      state.filters = state.filters.filter(i => i.id !== action.result.data.id);
      break;

    case ActionType.GET_COLUMNS_SUCCESS:
      state.columns = action.result.data.results;
      break;

    case ActionType.UPDATE_COLUMNS_SUCCESS:
      state.columns = unionBy([action.result.data], state.columns, 'id');
      break;

    case ActionType.SEARCH_OBJECT_SUCCESS:
      state.searchedObjects = action.result.data;
      break;

    case ActionType.SET_CONTEXT_MENU_INFO:
      state.contextMenuInfo = action.info;
      break;

    case ActionType.SET_PAGE:
      state.page = action.page;
      break;

    case ActionType.SET_SIZE_PER_PAGE:
      state.sizePerPage = action.sizePerPage;
      break;

    case ActionType.SET_SELECTED:
      state.selected = action.selected;
      break;

    case ActionType.SET_SORT_FIELD:
      state.sortField = action.sortField;
      break;

    case ActionType.SET_SORT_ORDER:
      state.sortOrder = action.sortOrder;
      break;

    case ActionType.SET_FILTER:
      state.filter = action.filter;
      break;

    case ActionType.SET_RELOAD_STATE:
      state.shouldReload = action.shouldReload;
      break;

    case ActionType.SET_KEYWORD:
      state.keyword = action.keyword;
      break;

    case ActionType.SET_CATEGORY:
      state.category = action.category;
      break;

    case ActionType.SET_FILTER_FORM_DATA:
      state.filterFormData = action.formData;
      break;

    case ActionType.SET_ACTIVE_TABLE:
      state.activeTable = action.activeTable;
      break;

    case ActionType.RESET_PAGINATION:
      state.page = { general: 1, combined: 1 };
      state.sizePerPage = defaultSizePerPage;
      break;
  }
  return state;
});

export const selectFilterName = (state: { pipeline: PipelineState }): string => {
  const { category, filters, filter } = state.pipeline;
  const { items } = menuItems[category];
  const currentFilter = filters.find(i => i.category === category && i.id.toString() === filter.toString());
  return currentFilter ? currentFilter.name : items[filter];
};
