import produce from 'immer';
import { Action, ActionType, InsiderQuestionWithAnswer, InsiderQuestionsPercentageByStatus } from './action-types';
import { preFillState } from '../utils';

export interface InsiderQuestionsWithAnswersState {
  insiderQuestionsPercentageByStatus: InsiderQuestionsPercentageByStatus;
  insiderQuestionsWithAnswers: InsiderQuestionWithAnswer[];
  insiderQuestionsWithAnswersCount: number
}

export const initialState: InsiderQuestionsWithAnswersState = {
  insiderQuestionsPercentageByStatus: {},
  insiderQuestionsWithAnswers: [],
  insiderQuestionsWithAnswersCount: 0,
};

export const selectInsiderInfoState = (state: {insiderInfo: InsiderQuestionsWithAnswersState}): InsiderQuestionsWithAnswersState => state.insiderInfo;

export default produce((state: InsiderQuestionsWithAnswersState = initialState, action: Action): InsiderQuestionsWithAnswersState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_QUESTIONS_PERCENTAGE_BY_STATUS_SUCCESS:
      state.insiderQuestionsPercentageByStatus = action.result.data;
      break;
    case ActionType.GET_QUESTIONS_WITH_ANSWERS_SUCCESS:
      state.insiderQuestionsWithAnswers = action.result.data.results;
      state.insiderQuestionsWithAnswersCount = action.result.data.count;
      break;
    case ActionType.CREATE_QUESTION_ANSWER_SUCCESS:
    case ActionType.UPDATE_QUESTION_ANSWER_SUCCESS:
    case ActionType.CONFIRM_QUESTION_ANSWER_SUCCESS:
      state.insiderQuestionsWithAnswers = state.insiderQuestionsWithAnswers.map(questionWithAnswer =>
        (questionWithAnswer.id === action.result.data.questionId ? { ...questionWithAnswer, ...action.result.data } : questionWithAnswer));
      break;
  }

  return state;
});
