/* eslint-disable no-nested-ternary */
import styled, { css, keyframes } from 'styled-components';
import { Button, ToastBody } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ButtonLikeAnchor } from 'styles/common';

export const ChatBox = styled.div`
  width: 220px;
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 1001;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  ${props =>
    props.active &&
    css`
        width: 700px;
        & > div:first-child {
            height: 50px;
            &::after {
                content: '${props.active ? '\\F1AE' : '\\ED9B'}';
                font-size: ${props.active ? '16px' : '14px'};
            }
        }
        & > div:last-child {
            display: flex;
        }
    `}
`;

export const blinker = keyframes`
  0% {
    background: #f7f8fc;
  }
  50% {
    background: #0168fa;
  }
  100% {
    background: #f7f8fc;
  }
`;

const blinkerIcon = keyframes`
  0% {
    color: #8392a5;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #8392a5;
  }
`;

const blinkerAfter = keyframes`
  0% {
        color: #1b2e4b;
  }
  50% {
        color: #fff;
  }
  100% {
        color: #1b2e4b;
  }
`;

const blinkerText = keyframes`
  0% {
        color: #0b2151;
  }
  50% {
        color: #fff;
  }
  100% {
        color: #0b2151;
  }
`;

export const ChatHeader = styled.div`
  border: 1px solid ${props => props.theme.input.borderColor};
  border-bottom-width: 0;
  background-color: ${props => props.theme.colors.gray100};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  position: relative;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &::after {
    content: '\\ED9B';
    font-family: 'remixicon';
    position: absolute;
    top: 50%;
    right: 13px;
    line-height: 0;
    color: ${props => props.theme.colors.colortx02};
  }

  i {
    font-size: 18px;
    line-height: 0.7;
    color: ${props => props.theme.colors.colortx03};
    margin-right: 5px;
  }

  h6 {
    margin-bottom: 0;
    color: ${props => props.theme.colors.colortx01};
  }

  ${props =>
    props.isBlinking &&
    css`
      animation: ${blinker} 1s linear;

      &::after {
        animation: ${blinkerAfter} 1s linear;
      }

      i {
        animation: ${blinkerIcon} 1s linear;
      }

      h6 {
        animation: ${blinkerText} 1s linear;
      }
    `}
`;

export const ChatBody = styled.div`
  height: calc(100% - (62px + 43px));
  overflow: hidden;
  position: relative;
`;

export const ChatNavBar = styled.div`
  flex-shrink: 0;
  width: 54px;
  border-right: 1px solid ${props => props.theme.colors.colorbg02};
  nav {
    padding: 25px 0 20px;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`;

export const NavLink = styled.a`
  padding: 0;
  color: ${props => (props.active ? props.theme.colors.colorui01 : props.theme.colors.colortx03)} !important;
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    ${props =>
    !props.active &&
      css`
        color: ${props.theme.colors.colortx01} !important;
      `}
  }

  i {
    font-size: 24px;
    line-height: 1;
  }

  span {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.white};
    font-size: 9px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
    padding-bottom: 0.5px;
  }

  &:last-child {
    margin-top: 20px;
  }
`;

export const CommunicationsGroup = styled.div`
  position: fixed;
  bottom: 0;
  right: ${props => (props.isOpenProfileMenu ? '425px' : '110px')};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row-reverse;
  z-index: 1010;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  grid-gap: 10px;
`;

export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #657697;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px !important;

  i {
    font-size: 14px;
  }
`;

export const NotificationType = styled.strong`
  margin-left: 5px;
  margin-right: auto !important;
  font-size: 14px;
`;

export const NotificationTime = styled.small`
  margin-left: 5px;
  margin-right: auto !important;
`;

export const NotificationBody = styled(ToastBody)`
  padding: 0.75rem !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

export const NotificationProspect = styled.h6`
  padding: 0.75rem !important;
  background-color: white;
`;

export const NotificationButton = styled(Button)`
  width: 100%;
`;

export const WhiteButton = styled(NotificationButton)`
  background-color: #fff;
  border-color: #d5dcf4;
  color: #4a5e8a;

  &:hover {
    color: #4a5e8a;
  }
`;

export const NotificationActions = styled.div`
  width: 100%;

  .btn-primary {
    margin-right: 0.5rem;
  }
`;

export const NotificationsWrapper = styled.div`
  position: fixed;
  top: 60px;
  right: 1rem;
  z-index: 1000;
  height: calc(100vh - 60px - 5px);
  display: flex;
  max-width: 100vw;

  > div {
    direction: rtl;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const LoadingQuestion = keyframes`
  0% {
    background-color: #ffd454;
  }
  20% {
    background-color: #c79708;
  }
  40% {
    background-color: #ffd454;
  }
  60% {
    background-color: #d1bb77;
  }
  100% {
    background-color: #ffd454;
  }
`;

export const ProspectQuestion = styled.span`
  background-color: ${props => (props.status === 'OPENED' ? '#ccced9' : props.status === 'IDLE' ? '#ffd454' : 'transparent')};
  ${props =>
    props.isLoading &&
    css`
      animation: ${LoadingQuestion} 3s infinite;
    `}

  &:hover {
    cursor: pointer;
  }

  & > button {
    position: absolute;
    top: 0;
    right: -16px;
    color: #a0a9bd;
    line-height: 1;
    display: flex;
  }
`;

export const BackgroundPanel = styled.div`
  background-color: transparent;
  position: absolute;
  height: 700px;
  width: 280px;
  top: 0px;
`;

export const PanelFrame = styled.div`
  background-color: #fff;
  width: 267px;
  position: absolute;
  will-change: transform;
  top: 100px;
  left: 6px;
  display: ${props => (props.show ? 'block' : 'none')};
  border: 1px solid #d5dcf4;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(160, 169, 189, 0.08), 0 2px 2px rgba(160, 169, 189, 0.12), 0 4px 4px rgba(160, 169, 189, 0.16), 0 8px 8px rgba(160, 169, 189, 0.2);
`;

export const PanelHeader = styled.div`
  padding: 7px 8px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #657697;

  .avatar {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .avatar::before {
    display: none;
  }
`;

export const CloseButton = styled(ButtonLikeAnchor)`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 16px;
  line-height: 1;
  color: #657697;
`;

export const PanelBody = styled.div`
  padding: 0 8px 8px;
  font-size: 13px;
  line-height: 1.45;
`;

export const TextWScroll = styled(PerfectScrollbar)`
  max-height: 150px;
  .ps__thumb-y {
    width: 2px;
  }

  .ps__rail-y {
    width: 4px;
    &:hover {
      .ps__thumb-y {
        width: 4px;
      }
    }
  }
`;

export const PanelFooter = styled.div`
  padding: 8px;
  border-top: 1px solid #d5dcf4;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PanelActionButton = styled.button`
  height: 28px;
  min-height: 0;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-size: 13px;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #d5dcf4;
  color: #4a5e8a;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const TextLoader = styled.div`
  padding: 0 8px 8px;
  font-size: 13px;
  line-height: 1.45;
  padding-bottom: 1rem !important;
  padding-top: .5rem !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
`;

export const TextAnalyzing = styled.span`
  display: inline-block !important;
  margin-left: .5rem !important;
`;
