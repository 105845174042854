import produce from 'immer';
import { orderBy, unionBy } from 'lodash';
import { ActionType as LeadActionType } from 'dwell/store/lead/action-types';
import { ActionType as NylasActionType } from 'dwell/store/nylas/action-types';
import { PusherAction, ActionType as PusherActionType, AlternativePusherRow, PusherRow } from 'dwell/store/pusher/action-types';
import { CustomBlob } from 'src/interfaces';
import { Action, ActionType, EmailMessageProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState, isLeadPage, isLeadsObject } from '../utils';

export interface EmailMessageState extends CommonStateType {
  messages: EmailMessageProps[];
  message: EmailMessageProps;
  messagesCount: number;
  isFollowupMessagesLoaded: boolean;
  conversations: EmailMessageProps[];
  leadId: string;
  subject: string;
  cc: string;
  selectedTemplateId: string;
  subjectVariables: string[];
  files: CustomBlob[] | string[];
  isShowingCc: boolean;
  body: string;
}

export const initialState: EmailMessageState = {
  isSubmitting: false,
  errorMessage: null,
  messages: [],
  message: {},
  messagesCount: 0,
  isLoaded: true,
  isFollowupMessagesLoaded: true,
  conversations: [],

  leadId: null,
  body: '',
  subject: '',
  cc: '',
  selectedTemplateId: '',
  subjectVariables: [],
  files: [],
  isShowingCc: false,
};

export default produce((state: EmailMessageState = initialState, action: Action): EmailMessageState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'emailmessage') {
    return state;
  }
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_EMAIL_MESSAGES_REQUEST:
      state.isFollowupMessagesLoaded = false;
      break;
    case ActionType.GET_EMAIL_MESSAGES_SUCCESS: {
      let messages = state.messages
        .filter(p => !action.result.data.results.map(item => item.id).includes(p.id))
        .concat(action.result.data.results);
      messages = orderBy(messages, ['date'], ['desc']);
      state.isFollowupMessagesLoaded = true;
      state.messages = messages;
      state.messagesCount = action.result.data.count;
      break;
    }
    case ActionType.GET_EMAIL_MESSAGES_FAILURE:
      state.isFollowupMessagesLoaded = true;
      break;

    case ActionType.GET_EMAIL_MESSAGE_BY_ID_SUCCESS:
      state.message = action.result.data;
      break;

    case ActionType.UPDATE_EMAIL_MESSAGE_SUCCESS: {
      const updateEmailMessagesList = state.messages.map(message => (message.id === action.result.data.id ? action.result.data : message));
      state.message = action.result.data;
      state.messages = updateEmailMessagesList;
      break;
    }

    case NylasActionType.ARCHIVE_MESSAGES_SUCCESS:
      state.messages = state.messages.filter(i => !action.result.data.ids.includes(i.id));
      state.messagesCount -= action.result.data.ids.length;
      break;

    case ActionType.ARCHIVE_EMAIL_MESSAGE_SUCCESS:
      state.messages = state.messages.filter(i => i.id !== action.result.data.id);
      state.messagesCount -= 1;
      break;

    case ActionType.MARK_EMAIL_MESSAGE_AS_READ_SUCCESS: {
      const updateEmailMessagesList = state.messages.map(message => (message.id === action.result.data.id ? action.result.data : message));
      state.message = action.result.data;
      state.messages = updateEmailMessagesList;
      break;
    }

    case ActionType.GET_LEAD_CONVERSATIONS_SUCCESS:
      state.conversations = action.result.data.results;
      break;

    case LeadActionType.GET_LEAD_BY_ID_SUCCESS:
      state.conversations = orderBy(action.result.data.email_messages, ['date'], ['desc']);
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const alternativeRow = action.row as AlternativePusherRow;
      const row = action.row as PusherRow;
      const object = (alternativeRow.object as EmailMessageProps) || (row as EmailMessageProps);
      const messages = orderBy(unionBy([row], state.messages, 'id'), ['date'], ['desc']);
      let { conversations } = state;
      if ((isLeadPage() && isLeadsObject(object.lead?.toString())) || (state.message.lead && state.message.lead === object.lead)) {
        conversations = [object].concat(state.conversations.filter(t => t.id !== object.id));
      }
      state.messages = messages;
      state.conversations = conversations;
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as AlternativePusherRow;
      const object = (row.object as EmailMessageProps) || (row as EmailMessageProps);
      if ((isLeadPage() && isLeadsObject(object.lead?.toString())) || (state.message.lead && state.message.lead === object.lead)) {
        const newEmails = [object].concat(state.conversations.filter(t => t.id !== object.id));
        state.conversations = newEmails;
      }
      state.message = state.message.id === object.id ? object : state.message;
      break;
    }

    case PusherActionType.PUSHER_DELETE_RECORD: {
      const row = action.row as AlternativePusherRow;
      const object = (row.object as EmailMessageProps) || (row as EmailMessageProps);
      state.conversations = state.conversations.filter(t => t.id?.toString() !== object.id?.toString());
      break;
    }

    case ActionType.SET_EMAIL_LEAD_ID:
      state.leadId = action.leadId;
      break;

    case ActionType.SET_EMAIL_BODY:
      state.body = action.body;
      break;

    case ActionType.SET_EMAIL_SUBJECT:
      state.subject = action.subject;
      break;

    case ActionType.SET_SELECTED_EMAIL_TEMPLATE_ID:
      state.selectedTemplateId = action.selectedTemplateId;
      break;

    case ActionType.SET_EMAIL_SUBJECT_VARIABLES:
      state.subjectVariables = action.subjectVariables;
      break;

    case ActionType.SET_FILES:
      state.files = action.files;
      break;

    case ActionType.SET_IS_SHOWING_CC:
      state.isShowingCc = action.isShowingCc;
      break;

    case ActionType.SET_EMAIL_CC:
      state.cc = action.cc;
      break;

    case ActionType.CLEAR_EMAIL_CONTENT:
      state.body = '';
      state.subject = '';
      state.cc = '';
      state.selectedTemplateId = '';
      state.subjectVariables = [];
      state.files = [];
      state.isShowingCc = false;
      break;
  }

  return state;
});
