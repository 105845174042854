import paths, { build } from 'leasing/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  createApplicant: (leaseId: number, data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_APPLICANT_REQUEST,
        ActionType.CREATE_APPLICANT_SUCCESS,
        ActionType.CREATE_APPLICANT_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.APPLICANTS, leaseId), data),
    },
  }),

  deleteApplicantById: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_APPLICANT_REQUEST,
        ActionType.DELETE_APPLICANT_SUCCESS,
        ActionType.DELETE_APPLICANT_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.APPLICANT_DETAILS, leaseId, id)),
    },
  }),

  getApplicants: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_APPLICANTS_REQUEST,
        ActionType.GET_APPLICANTS_SUCCESS,
        ActionType.GET_APPLICANTS_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.APPLICANTS, leaseId)),
    },
  }),

  getApplicantById: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_APPLICANT_BY_ID_REQUEST,
        ActionType.GET_APPLICANT_BY_ID_SUCCESS,
        ActionType.GET_APPLICANT_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.APPLICANT_DETAILS, leaseId, id)),
    },
  }),

  // is this used?
  getLocationByZipcode: (zipCode: string, country: string): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LOCATION_ZIP_CODE_REQUEST,
        ActionType.GET_LOCATION_ZIP_CODE_SUCCESS,
        ActionType.GET_LOCATION_ZIP_CODE_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.LOCATION_BY_ZIP_CODE), { params: { zip_code: zipCode, country } }),
    },
  }),

  getScreeningStatus: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SCREENING_STATUS_REQUEST,
        ActionType.GET_SCREENING_STATUS_SUCCESS,
        ActionType.GET_SCREENING_STATUS_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.SCREENING_STATUS, leaseId, id)),
    },
  }),

  // is this used?
  resendApplicantInvite: (leaseId: number, applicantId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.RESEND_APPLICATION_INVITE_REQUEST,
        ActionType.RESEND_APPLICATION_INVITE_SUCCESS,
        ActionType.RESEND_APPLICATION_INVITE_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.RESEND_APPLICATION_INVITE, leaseId, applicantId)),
    },
  }),

  resetApplicant: (): Action => ({ type: ActionType.RESET_APPLICANT }),

  updateApplicantById: (leaseId: number, id: number, params: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_APPLICANT_REQUEST,
        ActionType.UPDATE_APPLICANT_SUCCESS,
        ActionType.UPDATE_APPLICANT_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.APPLICANT_DETAILS, leaseId, id), params),
    },
  }),
};
