export default {
  APPLICANT_CREATED: 'ri-user-add-line',
  APPLICANT_DELETED: 'ri-user-unfollow-line',
  APPLICANT_UPDATED: 'ri-user-follow-line',
  COUNTERSIGN_LEASE_COMPLETED: 'ri-checkbox-multiple-line',
  LEASING_USER_ACCOUNT_CREATED: 'ri-mail-send-line',
  APPLICATION_INVITE_SENT: 'ri-mail-send-line',
  APPLICATION_PAYMENT_MADE: 'ri-bank-line',
  APPLICATION_STARTED: 'ri-file-add-line',
  APPLICATION_STATUS_CHANGED: 'ri-file-edit-line',
  APPLICATION_SUBMITTED: 'ri-file-upload-line',
  APPLICATION_CANCELLED: 'ri-file-close-line',
  CALL: 'ri-phone-line',
  CHATS: 'ri-rocket-fill',
  CO_APPLICANT_CREATED: 'ri-user-add-line',
  CO_APPLICANT_DELETED: 'ri-user-unfollow-line',
  CO_APPLICANT_INVITE_SENT: 'ri-user-shared-line',
  CO_APPLICANT_UPDATED: 'ri-user-follow-line',
  DEPOSIT_AGREEMENT_SENT: 'ri-mail-send-line',
  DEPOSIT_AGREEMENT_SIGNED: 'ri-checkbox-multiple-line',
  EMAIL: 'ri-mail-line',
  EMAIL_CREATED: 'ri-mail-send-line',
  GUARANTOR_CREATED: 'ri-user-add-line',
  GUARANTOR_DELETED: 'ri-user-unfollow-line',
  GUARANTOR_INVITE_SENT: 'ri-user-shared-line',
  GUARANTOR_UPDATED: 'ri-user-follow-line',
  INCOME_ADDED: 'ri-bill-line',
  INCOME_REMOVED: 'ri-bill-line',
  INCOME_UPDATED: 'ri-bill-line',
  LEAD_CHAT_HOBBES: 'ri-rocket-2-fill',
  LEAD_CREATED: 'ri-user-add-line',
  LEAD_MERGED: 'ri-group-line',
  LEAD_SHARED: 'ri-share-line',
  LEAD_UPDATED: 'ri-contacts-line',
  LEASE_AGREEMENT_SENT: 'ri-mail-send-line',
  RENEWAL_LEASE_AGREEMENT_SENT: 'ri-mail-send-line',
  LEASE_AGREEMENT_SIGNED: 'ri-checkbox-multiple-line',
  LEASE_STEP_CHANGED: 'ri-arrow-right-line',
  LEASE_CREATED: 'ri-file-user-line',
  LEASE_UPDATED: 'ri-file-user-line',
  APPLICATION_UPDATED: 'ri-file-list-3-line',
  LEASE_MOVE_DATE_ADDED: 'ri-calendar-event-line',
  LEASE_MOVE_DATE_UPDATE: 'ri-calendar-event-line',
  RESIDENT_UPDATED: 'ri-file-user-line',
  NOTE: 'ri-sticky-note-line',
  NOTE_CREATED: 'ri-sticky-note-line',
  NOTE_DELETED: 'ri-delete-bin-5-line',
  NOTE_UPDATED: 'ri-sticky-note-line',
  PET_ADDED: 'ri-home-heart-line',
  PET_REMOVED: 'ri-home-heart-line',
  PET_UPDATED: 'ri-home-heart-line',
  RENTABLE_ITEM_ADDED: 'ri-add-box-line',
  RENTABLE_ITEM_REMOVED: 'ri-checkbox-indeterminate-line',
  ROOMMATE_CREATED: 'ri-group-fill',
  ROOMMATE_DELETED: 'ri-user-unfollow-fill',
  ROOMMATE_UPDATED: 'ri-user-follow-fill',
  SCREENING_CHECK_DONE: 'ri-check-double-line',
  SMS: 'ri-chat-3-line',
  SMS_CREATED: 'ri-message-2-line',
  TASK_COMPLETED: 'ri-user-follow-line',
  TASK_CREATED: 'ri-task-line',
  TASK_DELETED: 'ri-delete-bin-5-line',
  TASK_ARCHIVED: 'ri-delete-bin-5-line',
  TASK_UPDATED: 'ri-restart-line',
  TOUR_CANCELLED: 'ri-user-unfollow-line',
  TOUR_ARCHIVED: 'ri-user-unfollow-line',
  TOUR_COMPLETED: 'ri-user-follow-line',
  TOUR_CREATED: 'ri-calendar-event-line',
  TOUR_UPDATED: 'ri-calendar-event-line',
  TOUR_NO_SHOW: 'ri-user-unfollow-line',
  UNIT_ADDED: 'ri-home-5-line',
  UNIT_REMOVED: 'ri-home-5-line',
  VEHICLE_ADDED: 'ri-car-line',
  VEHICLE_REMOVED: 'ri-car-line',
  VEHICLE_UPDATED: 'ri-car-line',
  PRICE_QUOTE_SENT: 'ri-price-tag-2-line',
  NURTURE_ENROLLMENT: 'ri-send-plane-fill',
  NURTURE_UNENROLLMENT: 'ri-send-plane-fill',
  CB_SUBSCRIPTION_UPDATED: 'ri-coupon-line',
  CB_SUBSCRIPTION_REFUNDED: 'ri-vip-crown-2-line',
  TRANSFERRED_TO_APPLICATION: 'ri-user-shared-line',
  RENEWAL_OFFER_CREATED: 'ri-file-upload-line',
  RENEWAL_OFFER_UPDATED: 'ri-file-upload-line',
  PROFILE_REFRESH_REQUEST_SENT: 'ri-user-follow-line',
  NOTICE_TO_VACATE_SENT: 'ri-mail-line',
};
