import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Conversation, AgentQuestion } from 'dwell/store/agent_question/action-types';
import { HobbesQuestion, HobbesQuestionBody } from 'dwell/views/chat/single_chat/window/styles';
import { getPropertiesJoinedText, getCustomersJoinedText } from '../utils';

interface HobbesQuestionMessageProps {
  conversation: Conversation;
}

const QuestionMessage: FC<HobbesQuestionMessageProps> = ({ conversation }) => {
  const currentUser = useSelector(state => state.user.currentUser);
  const { first_name } = currentUser;
  const { date, show_greeting } = conversation;
  const question = conversation.question as AgentQuestion;
  const { question_text, properties, customers, settings_page } = question;
  const isCustomerQuestion = ['PAGE_HOBBES_SETTINGS'].includes(settings_page);
  let hobbesMessage = '';
  if (properties && properties.length && !isCustomerQuestion) {
    const propertiesText = getPropertiesJoinedText(properties);
    hobbesMessage = `At ${propertiesText}, ${question_text}`;
  } else if (customers && customers.length && isCustomerQuestion) {
    const customersText = getCustomersJoinedText(customers);
    hobbesMessage = `For customer${customers.length > 1 ? 's' : ''} ${customersText}, ${question_text}`;
  } else {
    hobbesMessage = question_text;
  }
  const question_link = question.link;

  const re = /\s[A-Za-z]\).+?((?= or [A-Za-z]\))|(?= OR [A-Za-z]\))|(?=\?)|(?=, [A-Za-z]\)))/gs;
  const numberedList = hobbesMessage.match(re);
  let questionBeginning = null;
  if (numberedList && numberedList.length) {
    questionBeginning = hobbesMessage.match(/^.+?:/gs);
    questionBeginning = questionBeginning ? questionBeginning[0] : '';
  }
  return (
    <HobbesQuestion>
      <HobbesQuestionBody>
        {question_link && (
          <a href={question_link} target="_blank">
            <i className="ri-external-link-line" />
          </a>
        )}
        {show_greeting && <p>{`Hi ${first_name}, I have a question:`}</p>}
        {!numberedList ? (
          <p>{hobbesMessage}</p>
        ) : (
          <>
            <p>{questionBeginning}</p>
            <p>
              {numberedList.map((item, idx) => (
                <span key={`span-1-${idx}`} className="d-flex">
                  <span key={`span-2-${idx}`}>{item.trim()}</span>
                </span>
              ))}
            </p>
          </>
        )}
      </HobbesQuestionBody>
      <small>{moment(date).format('MMM DD, hh:mma')}</small>
    </HobbesQuestion>
  );
};

export default QuestionMessage;
