import produce from 'immer';
import { Action, ActionType, EmailTemplateProps } from './action-types';

export interface EmailTemplateState {
  emailTemplates: EmailTemplateProps[];
  emailTemplate: EmailTemplateProps;
}

export const initialState: EmailTemplateState = {
  emailTemplates: [],
  emailTemplate: {} as EmailTemplateProps,
};

export default produce((state: EmailTemplateState = initialState, action: Action): EmailTemplateState => {
  switch (action.type) {
    case ActionType.GET_EMAIL_TEMPLATES_SUCCESS:
      state.emailTemplates = action.result.data.results;
      break;

    case ActionType.UPDATE_EMAIL_TEMPLATE_SUCCESS: {
      const updateEmailTemplatesList = state.emailTemplates.map(template => (template.id === action.result.data.id ? action.result.data : template));
      state.emailTemplates = updateEmailTemplatesList;
      break;
    }

    case ActionType.CREATE_EMAIL_TEMPLATE_SUCCESS: {
      const updateEmailTemplatesList = [...state.emailTemplates];
      updateEmailTemplatesList.push(action.result.data);
      state.emailTemplates = updateEmailTemplatesList;
      break;
    }

    case ActionType.DELETE_EMAIL_TEMPLATE_SUCCESS: {
      const updateEmailTemplatesList = state.emailTemplates.filter(template => template.id !== action.result.data.id);
      state.emailTemplates = updateEmailTemplatesList;
      break;
    }
  }

  return state;
});
