import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_SURVEYS_REQUEST = 'GET_SURVEYS_REQUEST',
  GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS',
  GET_SURVEYS_FAILURE = 'GET_SURVEYS_FAILURE',

  UPDATE_SURVEYS_REQUEST = 'UPDATE_SURVEYS_REQUEST',
  UPDATE_SURVEYS_SUCCESS = 'UPDATE_SURVEYS_SUCCESS',
  UPDATE_SURVEYS_FAILURE = 'UPDATE_SURVEYS_FAILURE',
}

export interface SurveyProps {
  surveyId: number;
  id: number;
  market_rent: number;
  effective_rent: number;
  concession_amount: number;
  competitor: string;
  isTemplate: boolean;
  is_first: boolean;
  date: Date;
}

export interface updateSurveysProps {
  updated_surveys: { date: string }[];
  created_surveys: { date: string }[];
  deleted_surveys: { date: string }[];
  date: Date | string;
}

interface GetSurveysAction {
  type: ActionType.GET_SURVEYS_REQUEST | ActionType.GET_SURVEYS_SUCCESS | ActionType.GET_SURVEYS_FAILURE;

  result: {
    data: { results: SurveyProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateSurveysAction {
  type: ActionType.UPDATE_SURVEYS_REQUEST | ActionType.UPDATE_SURVEYS_SUCCESS | ActionType.UPDATE_SURVEYS_FAILURE;

  result: {
    data: SurveyProps[];
  };
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetSurveysAction | UpdateSurveysAction;
