import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { Action, ActionType, ExploreMarketsList } from './action-types';

export interface ExploreMarketsState extends CommonStateType {
  isExploreMarketsLoaded: boolean;
  exploreMarketsList: ExploreMarketsList;
}

const initialState: ExploreMarketsState = {
  isExploreMarketsLoaded: false,
  exploreMarketsList: null,
};

export default produce((state: ExploreMarketsState = initialState, action: Action): ExploreMarketsState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_EXPLORE_MARKETS_REQUEST:
      state.isExploreMarketsLoaded = false;
      break;

    case ActionType.GET_EXPLORE_MARKETS_SUCCESS:
      state.exploreMarketsList = action.result.data;
      state.isExploreMarketsLoaded = true;
      break;

    case ActionType.GET_EXPLORE_MARKETS_FAILURE:
      state.isExploreMarketsLoaded = false;
      break;
  }

  return state;
});
