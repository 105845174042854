import React from 'react';
import ReportLink from 'dwell/components/focus_bar/report_link';
import { formatPriceValue, formatStringOrOneDecimal } from 'dwell/views/reports/utils';
import moment from 'moment-timezone';
import callsChoices from 'dwell/constants/calls';
import { getCallResult } from 'dwell/views/calls/communications/voice_centre/utils';
import { ReportLink as Link } from 'dwell/components/focus_bar/styles';
import { Event } from 'dwell/store/call/action-types';

interface HeaderStyle {
  width?: string,
  whiteSpace?: string,
  textAlign?: string,
}

interface style {
  backgroundColor?: string,
  fontWeight?: number,
}

interface LeadRow {
  id?: number,
  first_name?: string,
  last_name?: string,
  is_deleted_by_merging?: boolean,
  status?: string,
  owner_name?: string,
  property__external_id?: string,
  property_external_id?: string,
  lease__id?: string,
  timezone?: string,
}

interface CallRow {
  prospect_phone_number: string,
  duration: number,
  direction: string,
  call_category: string,
  topic: string,
  property_name: string,
  call_result: string,
  date: string,
  transfer_events: Event[];
}

export interface TourDataType {
  [tour_type: string]: { name: string, value: number, percent: number },
}

export default {
  REPORT_BLOCK_TYPES: {
    OVERVIEW_REPORTS: {
      LEAD_TO_LEASE: {
        id: 'lead_to_lease',
        name: 'Lead-to-lease Report',
        tooltip: 'Understanding how your team\'s lead-to-lease pipeline is progressing in the selected time range.',
        defaultSortField: 'leads',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'leads',
            text: 'Leads',
            sort: true,
          },
          {
            dataField: 'tours',
            text: 'Tours',
            sort: true,
          },
          {
            dataField: 'leases',
            text: 'Leases',
            sort: true,
          },
          {
            dataField: 'lead_to_tour',
            text: 'Lead-to-Tour rate',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'tour_to_lease',
            text: 'Tour-to-Lease rate',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'leased_rate',
            text: 'Leased rate',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
        ],
      },
      ACTIVITY: {
        id: 'activity',
        name: 'Activity Report',
        tooltip: 'Understanding how productive your team is with their lead activities in the selected time period.',
        defaultSortField: 'activities',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'activities',
            text: 'Total Activity',
            sort: true,
          },
          {
            dataField: 'emails',
            text: 'Emails',
            sort: true,
          },
          {
            dataField: 'calls',
            text: 'Phone calls',
            sort: true,
          },
          {
            dataField: 'tasks',
            text: 'Tasks',
            sort: true,
          },
          {
            dataField: 'notes',
            text: 'Notes',
            sort: true,
          },
        ],
      },
      ENGAGEMENT: {
        id: 'engagement',
        name: 'Engagement Report',
        tooltip: 'See how fast your team is following up with leads and signing leases.',
        defaultSortField: 'average_response_time',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'average_response_time',
            text: 'Avg. Lead Response time (hrs)',
            sort: true,
          },
          {
            dataField: 'average_sign_lease_time',
            text: 'Avg. Time Sign Lease (days)',
            sort: true,
          },
          {
            dataField: 'average_followups_number',
            text: 'Avg. Number of Followups Sign Lease',
            sort: true,
          },
          {
            dataField: 'followups_2_hours',
            text: 'Followup within 2 hrs',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'followups_24_hours',
            text: 'Followup within 24 hrs',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'followups_48_hours',
            text: 'Followup within 48 hrs',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'followups_more_48_hours',
            text: 'Followup more than 48 hrs',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'no_followups',
            text: 'No Followups',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
        ],
      },
      CALLS: {
        id: 'calls',
        name: 'Calls Report',
        tooltip: 'Understanding how your team is managing phone call leads in the selected time range.',
        defaultSortField: 'prospect_calls',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'prospect_calls',
            text: 'Total prospect calls',
            sort: true,
          },
          {
            dataField: 'average_call_time',
            text: 'Avg. call time (min)',
            sort: true,
          },
          {
            dataField: 'call_answered',
            text: 'Call answered',
            sort: true,
            formatter: (cell: {percents: string}): string => `${cell.percents}%`,
          },
          {
            dataField: 'call_missed',
            text: 'Call missed',
            sort: true,
            formatter: (cell: {percents: string}): string => `${cell.percents}%`,
          },
          {
            dataField: 'call_busy',
            text: 'Call busy',
            sort: true,
            formatter: (cell: {percents: string}): string => `${cell.percents}%`,
          },
          {
            dataField: 'call_failed',
            text: 'Call failed',
            sort: true,
            formatter: (cell: {percents: string}): string => `${cell.percents}%`,
          },
        ],
      },
      TOURS: {
        id: 'tours',
        name: 'Tours Report',
        tooltip: 'Understanding which tour types are requested the most by prospects and how the different tour types convert to leases for a selected time period.',
        defaultSortField: 'total_tours',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'total_tours',
            text: 'Total Tours',
            sort: true,
          },
          {
            dataField: 'total_leases',
            text: 'Total Leases',
            sort: true,
          },
        ],
      },
      CALL_SCORING: {
        id: 'calls_scoring_report',
        name: 'Call Scoring Report',
        tooltip: 'Understanding how productive your team is with their lead activities in the selected time period.',
        defaultSortField: 'property',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            headerStyle: (): HeaderStyle => ({ width: '14%', whiteSpace: 'break-spaces', textAlign: 'left' }),
            sort: true,
          },
          {
            dataField: 'average_call_score',
            text: 'Avg. Call Score',
            sort: true,
            headerStyle: (): HeaderStyle => ({ width: '14%', whiteSpace: 'break-spaces' }),
            formatter: (cell: string): string => (cell === null ? '-' : `${cell}%`),
          },
          {
            dataField: 'introduction',
            text: 'Introduction and Lead Information',
            sort: true,
            headerStyle: (): HeaderStyle => ({ width: '15%', whiteSpace: 'break-spaces' }),
            formatter: (cell: string): string => (cell === null ? '-' : `${cell}%`),
          },
          {
            dataField: 'qualifying',
            text: 'Qualifying Questions',
            sort: true,
            headerStyle: (): HeaderStyle => ({ width: '13%', whiteSpace: 'break-spaces' }),
            formatter: (cell: string): string => (cell === null ? '-' : `${cell}%`),
          },
          {
            dataField: 'amenities',
            text: 'Amenities and Benefits',
            sort: true,
            headerStyle: (): HeaderStyle => ({ width: '14%', whiteSpace: 'break-spaces' }),
            formatter: (cell: string): string => (cell === null ? '-' : `${cell}%`),
          },
          {
            dataField: 'closing',
            text: 'Closing',
            sort: true,
            headerStyle: (): HeaderStyle => ({ width: '13%', whiteSpace: 'break-spaces' }),
            formatter: (cell: string): string => (cell === null ? '-' : `${cell}%`),
          },
          {
            dataField: 'overall',
            text: 'Overall Impression',
            sort: true,
            headerStyle: (): HeaderStyle => ({ width: '15%', whiteSpace: 'break-spaces' }),
            formatter: (cell: string): string => (cell === null ? '-' : `${cell}%`),
          },
        ],
      },
    },
    MARKETING_REPORTS: {
      LEAD_SOURCE: {
        id: 'lead_source',
        name: 'Lead source Report',
        tooltip: 'Get a sense of how each lead source is performing for your team and generating key business outcomes.',
        defaultSortField: 'leads',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            sort: true,
            attrs: (cell: string, row: { rowspan: string }): { rowSpan: string } => ({
              rowSpan: row.rowspan,
            }),
            style: (cell: string, row: { rowspan: number }): { display: string } => ({
              display: row.rowspan === 0 ? 'none' : 'table-cell',
            }),
          },
          {
            dataField: 'source',
            text: 'Source',
            sort: true,
          },
          {
            dataField: 'type',
            text: 'Type',
            sort: true,
          },
          {
            dataField: 'leads',
            text: 'Leads',
            sort: true,
          },
          {
            dataField: 'tours',
            text: 'Tours',
            sort: true,
          },
          {
            dataField: 'leases',
            text: 'Leases',
            sort: true,
          },
          {
            dataField: 'calls',
            text: 'Calls',
            sort: true,
          },
          {
            dataField: 'leased_rate',
            text: 'Leased rate (%)',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'tour_completed_rate',
            text: 'Tour completed rate (%)',
            sort: true,
            formatter: (cell: string): string => `${cell}%`,
          },
          {
            dataField: 'spend',
            text: 'Spend',
            sort: true,
            formatter: (cell: number): string => formatPriceValue(cell),
          },
          {
            dataField: 'cost_per_lead',
            text: 'Cost per lead',
            sort: true,
            formatter: (cell: number): string => formatPriceValue(cell),
          },
          {
            dataField: 'cost_per_tour',
            text: 'Cost per tour',
            sort: true,
            formatter: (cell: number): string => formatPriceValue(cell),
          },
          {
            dataField: 'cost_per_lease',
            text: 'Cost per lease',
            sort: true,
            formatter: (cell: number): string => formatPriceValue(cell),
          },
        ],
      },
      LEAD_LOST: {
        id: 'lead_lost',
        name: 'Lead lost Report',
        tooltip: 'Use this report to better understand why leads are not leasing units at your property.',
        defaultSortField: 'lost_leads',
        columns: [
          {
            dataField: 'property',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'lost_leads',
            text: 'Total Leads Lost',
            sort: true,
          },
        ],
      },
    },
    AGENT_AND_OFFICE_REPORTS: {
      AGENT: {
        id: 'agent',
        name: 'Agent Overview Report',
        tooltip: '',
      },
      OFFICE: {
        id: 'office',
        name: 'Office Overview Report',
        tooltip: '',
      },
    },
    TEAM_REPORTS: {
      TEAM_MEMBER: {
        id: 'team_member_report',
        name: 'Team member report',
        tooltip: '',
      },
      TEAM_MEMBER_ACTIVITY: {
        id: 'team_member_activity_report',
        name: 'Team member activity report',
        tooltip: '',
      },
    },
    LEADERBOARD_REPORTS: {
      LEASED_LEADERBOARD: {
        id: 'leased_leaderboard_report',
        name: 'Leased leaderboard',
        tooltip: '',
      },
      ACTIVITY_LEADERBOARD: {
        id: 'activity_leaderboard_report',
        name: 'Activity leaderboard',
        tooltip: '',
      },
      LEAD_FOLLOWUP_LEADERBOARD: {
        id: 'lead_followup_leaderboard_report',
        name: 'Lead followup leaderboard',
        tooltip: '',
      },
    },
    LEADS: {
      name: 'Leads',
      defaultSortField: 'first_name',
      columns: [
        {
          dataField: 'first_name',
          text: 'Name',
          sort: true,
          formatter: (name: string, row: LeadRow): JSX.Element => <ReportLink lead={row} type="leads" />,
          style: (cell: string, row: LeadRow): style => ({ backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff' }),
        },
        {
          dataField: 'email',
          text: 'Email',
          sort: true,
          style: (cell: string, row: LeadRow): style => ({ backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff' }),
        },
        {
          dataField: 'property__name',
          text: 'Property',
          sort: true,
          style: (cell: string, row: LeadRow): style => ({ backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff' }),
        },
        {
          dataField: 'source__name',
          text: 'Source',
          sort: true,
          style: (cell: string, row: LeadRow): style => ({ backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff' }),
        },
        {
          dataField: 'created',
          text: 'Created',
          sort: true,
          formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          style: (cell: string, row: LeadRow): style => ({ backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff' }),
        },
        {
          dataField: 'acquisition_date',
          text: 'Acquisition Date',
          sort: true,
          formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          style: (cell: string, row: LeadRow): style => ({ backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff' }),
        },
        {
          dataField: 'last_followup_date',
          text: 'Engagement Date',
          sort: true,
          formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          style: (cell: string, row: LeadRow): style => ({ backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff' }),
        },
        {
          dataField: 'owner_name',
          text: 'Property Team Owner',
          sort: true,
          formatter: (cell: string, row: LeadRow): string => {
            if (row.status === 'DELETED') {
              if (row.is_deleted_by_merging) {
                return 'MERGED';
              }
              return 'DELETED';
            }
            return row.owner_name;
          },
          style: (cell: string, row: LeadRow): style => ({
            backgroundColor: row.status === 'DELETED' ? '#f7f8fc' : '#ffffff',
            fontWeight: row.status === 'DELETED' ? 500 : 400 }),
        },
      ],
    },
    TOURS: {
      name: 'Tours',
      defaultSortField: 'first_name',
      columns: [
        {
          dataField: 'first_name',
          text: 'Name',
          sort: true,
          formatter: (name: string, row: LeadRow): JSX.Element => <ReportLink lead={row} type="tours" />,
        },
        {
          dataField: 'email',
          text: 'Email',
          sort: true,
        },
        {
          dataField: 'property__name',
          text: 'Property',
          sort: true,
        },
        {
          dataField: 'source__name',
          text: 'Source',
          sort: true,
        },
        {
          dataField: 'tour_completed_date',
          text: 'Tour Date',
          sort: true,
          formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
        },
        {
          dataField: 'owner_name',
          text: 'Property Team Owner',
          sort: true,
        },
        {
          dataField: 'tour_type',
          text: 'Tour type',
          sort: true,
        },
        {
          dataField: 'floor_plan_type',
          text: 'Floor plan type',
          sort: true,
        },
      ],
    },
    LEASES: {
      name: 'Leases',
      defaultSortField: 'first_name',
      columns: [
        {
          dataField: 'first_name',
          text: 'Name',
          sort: true,
          formatter: (name: string, row: LeadRow): JSX.Element => <ReportLink lead={row} type="leases" />,
        },
        {
          dataField: 'email',
          text: 'Email',
          sort: true,
        },
        {
          dataField: 'property__name',
          text: 'Property',
          sort: true,
        },
        {
          dataField: 'source__name',
          text: 'Source',
          sort: true,
        },
        {
          dataField: 'acquisition_date',
          text: 'Acquisition Date',
          sort: true,
          formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
        },
        {
          dataField: 'closed_status_date',
          text: 'Lease Date',
          sort: true,
          formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
        },
        {
          dataField: 'owner_name',
          text: 'Property Team Owner',
          sort: true,
        },
      ],
    },
    RESPONSES: {
      name: 'Engagement report',
      defaultSortField: 'acquisition_date',
      columns: [
        {
          dataField: 'first_name',
          text: 'Name',
          sort: true,
          formatter: (name: string, row: LeadRow): JSX.Element => <ReportLink lead={row} type="responses" />,
          headerStyle: (): HeaderStyle => ({ width: '15%', whiteSpace: 'pre' }),
        },
        {
          dataField: 'email',
          text: 'Email',
          sort: true,
          headerStyle: (): HeaderStyle => ({ width: '15%' }),
        },
        {
          dataField: 'property__name',
          text: 'Property',
          sort: true,
          headerStyle: (): HeaderStyle => ({ width: '15%' }),
        },
        {
          dataField: 'created',
          text: 'Created',
          sort: true,
          formatter: (date: string, row: LeadRow): string => (date ? moment(date).tz(row.timezone ? row.timezone : 'America/Phoenix').format('lll') : ''),
          headerStyle: (): HeaderStyle => ({ width: '10%' }),
        },
        {
          dataField: 'acquisition_date',
          text: 'Acquisition Date',
          sort: true,
          formatter: (date: string, row: LeadRow): string => (date ? moment(date).tz(row.timezone ? row.timezone : 'America/Phoenix').format('lll') : ''),
          headerStyle: (): HeaderStyle => ({ width: '10%' }),
        },
        {
          dataField: 'first_followup_date',
          text: 'First Engagement Date',
          sort: true,
          formatter: (date: string, row: LeadRow): string => (date ? moment(date).tz(row.timezone ? row.timezone : 'America/Phoenix').format('lll') : ''),
          headerStyle: (): HeaderStyle => ({ width: '10%' }),
        },
        {
          dataField: 'first_followup_type',
          text: 'Source',
          sort: true,
          headerStyle: (): HeaderStyle => ({ width: '10%' }),
        },
        {
          dataField: 'last_followup_date',
          text: 'Last Engagement Date',
          sort: true,
          formatter: (date: string, row: LeadRow): string => (date ? moment(date).tz(row.timezone ? row.timezone : 'America/Phoenix').format('lll') : ''),
          headerStyle: (): HeaderStyle => ({ width: '10%' }),
        },
        {
          dataField: 'response_time_business',
          text: 'Response Time (HRS)\n(business hours)',
          sort: true,
          formatter: (cell: number): string | number => formatStringOrOneDecimal(cell),
          headerStyle: (): HeaderStyle => ({ width: '5%', whiteSpace: 'pre' }),
        },
        {
          dataField: 'response_time_overall',
          text: 'Response Time (HRS)\n(overall)',
          sort: true,
          formatter: (cell: number | string): string | number => formatStringOrOneDecimal(cell),
          headerStyle: (): HeaderStyle => ({ width: '5%', whiteSpace: 'pre' }),
        },
      ],
    },
    RAW_DATA: {
      LEADS: {
        name: 'Leads',
        defaultSortField: 'first_name',
        columns: [
          {
            dataField: 'first_name',
            text: 'Name',
            sort: true,
            formatter: (name: string, row: LeadRow): JSX.Element => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link onClick={() => window.open(`/${row.property_external_id}/leads/${row.id}`, '_blank')}>{row.first_name} {row.last_name}</Link>
            ),
          },
          {
            dataField: 'email',
            text: 'Email',
            sort: true,
          },
          {
            dataField: 'property_name',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'source_name',
            text: 'Source',
            sort: true,
          },
          {
            dataField: 'created',
            text: 'Created',
            sort: true,
            formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          },
          {
            dataField: 'acquisition_date',
            text: 'Acquisition Date',
            sort: true,
            formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          },
          {
            dataField: 'owner_name',
            text: 'Property Team Owner',
            sort: true,
          },
        ],
      },
      CALLS: {
        name: 'Calls',
        defaultSortField: 'prospect_phone_number',
        columns: [
          {
            dataField: 'prospect_phone_number',
            text: 'Phone Number',
            sort: true,
          },
          {
            dataField: 'duration',
            text: 'Duration',
            sort: true,
          },
          {
            dataField: 'direction',
            text: 'Direction',
            sort: true,
            formatter: (cell: string): string => callsChoices.CALL_DIRECTION_CHOICES[cell],
          },
          {
            dataField: 'call_category',
            text: 'Category',
            sort: true,
            formatter: (cell: string): string => callsChoices.CATEGORY_VALUE[cell],
          },
          {
            dataField: 'topic',
            text: 'Topic',
            sort: true,
            formatter: (cell: string): string => callsChoices.TOPIC_VALUE[cell],
          },
          {
            dataField: 'property_name',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'call_result',
            text: 'Call Result',
            sort: true,
            formatter: (cell: string, row: CallRow): string => getCallResult(row.direction, cell, row.duration, row.transfer_events.length),
          },
          {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          },
        ],
      },
      EMAILS: {
        name: 'Emails',
        defaultSortField: 'date',
        columns: [
          {
            dataField: 'sender_email',
            text: 'Sender Email',
            sort: true,
          },
          {
            dataField: 'receiver_email',
            text: 'Receiver email',
            sort: true,
          },
          {
            dataField: 'subject',
            text: 'Subject',
            sort: true,
          },
          {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          },
          {
            dataField: 'property_name',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'agent_name',
            text: 'Agent Name',
            sort: true,
          },
        ],
      },
      SMS: {
        name: 'SMS',
        defaultSortField: 'date',
        columns: [
          {
            dataField: 'sender_number',
            text: 'Sender Number',
            sort: true,
          },
          {
            dataField: 'receiver_number',
            text: 'Receiver Number',
            sort: true,
          },
          {
            dataField: 'message',
            text: 'Message',
            sort: true,
          },
          {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (date: string): string => (date ? moment(date).tz('America/Phoenix').format('lll') : ''),
          },
          {
            dataField: 'property_name',
            text: 'Property',
            sort: true,
          },
          {
            dataField: 'agent_name',
            text: 'Agent Name',
            sort: true,
          },
        ],
      },
    },
  },
};
