import React, { FC } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface AttentionModalProps {
  isOpen: boolean;
  toggle: () => void;
  bodyText: string;
  handleAccept: () => void;
}

const AttentionModal: FC<AttentionModalProps> = ({ isOpen, toggle, bodyText, handleAccept }) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    centered
    aria-labelledby="example-custom-modal-styling-title"
    className="lead-share-modal"
    size="lg"
  >
    <ModalHeader toggle={toggle}>Attention!</ModalHeader>
    <ModalBody>
      {bodyText}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>
        Cancel
      </Button>
      <Button color="danger" onClick={handleAccept}>
        Accept
      </Button>
    </ModalFooter>
  </Modal>);

export default AttentionModal;
