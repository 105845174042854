import { paths } from 'dwell/constants';
import { PromiseActionType } from 'dwell/store/types';
import { build } from 'dwell/constants/paths';
import { ActionType } from './action-types';

export default {
  getHolidays: (): PromiseActionType => ({
    CALL_API: {
      types: [ActionType.GET_HOLIDAYS_REQUEST, ActionType.GET_HOLIDAYS_SUCCESS, ActionType.GET_HOLIDAYS_FAILURE],
      promise: client => client.get(paths.api.v1.HOLIDAYS),
    },
  }),
  updateHolidayById: (id: number, data: { status: boolean }): PromiseActionType => ({
    CALL_API: {
      types: [ActionType.UPDATE_HOLIDAY_REQUEST, ActionType.UPDATE_HOLIDAY_SUCCESS, ActionType.UPDATE_HOLIDAY_FAILURE],
      promise: client => client.patch(build(paths.api.v1.HOLIDAYS_DETAILS, id), data),
    },
  }),
};
