import alert from './alert/reducers';
import comparison from './comparison/reducers';
import exploreMarkets from './explore_markets/reducers';
import historicalReport from './historical_report/reducers';
import market from './market/reducers';
import properties from './properties/reducers';
import submarket from './submarket/reducers';
import watchlist from './watchlist/reducers';

export type CompeteStateType = {
  alert: ReturnType<typeof alert>,
  comparison: ReturnType<typeof comparison>,
  exploreMarkets: ReturnType<typeof exploreMarkets>,
  historicalReport: ReturnType<typeof historicalReport>,
  market: ReturnType<typeof market>,
  properties: ReturnType<typeof properties>,
  submarket: ReturnType<typeof submarket>,
  watchlist: ReturnType<typeof watchlist>,
};

export default {
  alert,
  comparison,
  exploreMarkets,
  historicalReport,
  market,
  properties,
  submarket,
  watchlist,
};
