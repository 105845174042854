export enum ActionType {
  GET_PORTFOLIO_REQUEST = 'GET_PORTFOLIO_REQUEST',
  GET_PORTFOLIO_SUCCESS = 'GET_PORTFOLIO_SUCCESS',
  GET_PORTFOLIO_FAILURE = 'GET_PORTFOLIO_FAILURE',
}

export interface Portfolio {
  id: number,
  name: string,
  type: string
}

interface GetPortfolioAction {
  type: ActionType.GET_PORTFOLIO_REQUEST | ActionType.GET_PORTFOLIO_SUCCESS | ActionType.GET_PORTFOLIO_FAILURE;

  result: {
    data: {
      results: Portfolio[],
    };
  };
  error: {
    response: { status: string };
  };
}

export type Action = GetPortfolioAction
