import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, VehicleProps, VehicleState } from './action-types';

export const initialState: VehicleState = {
  errorMessage: null,
  vehicles: [] as VehicleProps[],
  vehicleYears: [],
  vehicleMakes: [],
  vehicleModels: [],
  isLoaded: false,
};

export const selectVehicles = (state : { vehicle: VehicleState }) : VehicleProps[] => state.vehicle.vehicles;
export const selectVehicleIsLoaded = (state : { vehicle: VehicleState }) : boolean => state.vehicle.isLoaded;

export const selectVehicleYears = (state : { vehicle: VehicleState }) : number[] => state.vehicle.vehicleYears;
export const selectVehicleMakes = (state : { vehicle: VehicleState }) : string[] => state.vehicle.vehicleMakes;
export const selectVehicleModels = (state : { vehicle: VehicleState }) : string[] => state.vehicle.vehicleModels;

export default produce((state: VehicleState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_LEAD_VEHICLES_SUCCESS:
      state.vehicles = action.result.data;
      break;

    case ActionType.CREATE_VEHICLE_SUCCESS:
    case ActionType.UPDATE_LEAD_VEHICLE_SUCCESS:
    case ActionType.UPDATE_VEHICLE_SUCCESS:
      state.vehicles = unionBy([action.result.data], state.vehicles, 'id');
      break;

    case ActionType.DELETE_LEAD_VEHICLE_SUCCESS:
    case ActionType.DELETE_VEHICLE_SUCCESS:
      state.vehicles = state.vehicles.filter(i => i.id !== action.result.data.id);
      break;

    case ActionType.GET_LEAD_VEHICLES_SUCCESS:
      state.vehicles = action.result.data.results;
      break;

    case ActionType.GET_VEHICLE_MAKES_SUCCESS:
      state.vehicleMakes = action.result.data;
      break;

    case ActionType.GET_VEHICLE_MODELS_SUCCESS:
      state.vehicleModels = action.result.data;
      break;

    case ActionType.GET_VEHICLE_YEARS_SUCCESS:
      state.vehicleYears = action.result.data;
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.vehicles = action.result.data.vehicles;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
