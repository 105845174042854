import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card as BaseCard, Table as CommonTable, CardBody as BaseCardBody } from 'reactstrap';

export const Panel = styled.div`
  display: block;
  width: 350px;
  top: calc(${props => props.theme.templates.headerHeight} - 1px);
  position: fixed;
  right: ${props => (props.isOpen ? '0' : '-350')}px;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};;
  bottom: 0;
  z-index: ${props => (props.isGalleryPreviewOpen ? '1000' : '300')};
  background-color: #fff;
  box-shadow: 2px 5px 45px rgb(36 55 130 / 12%),
   0 1px 2px rgb(225 230 247 / 7%),
    0 2px 4px rgb(225 230 247 / 7%),
    0 4px 8px rgb(225 230 247 / 7%),
     0 8px 16px rgb(225 230 247 / 7%),
     0 16px 32px rgb(225 230 247 / 7%),
      0 32px 64px rgb(225 230 247 / 7%);
  transition: all 0.25s;
`;

export const Header = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #4A5E8A;
  color: #fff;
`;

//  @include hover-focus() {
//     opacity: 1;
//     color: #fff;
//   }
export const Close = styled.div`
  display: flex;
  align-items: center;
  font-size: 21px;
  line-height: 1;
  color: inherit;
  margin-left: auto;
  margin-right: -3px;
  opacity: .6;
  text-indent: 3px;
  cursor: pointer;
`;

export const Dropdown = styled.div`
  position: relative;
`;

export const DropdownLink = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 15px;
  cursor: pointer;

  &[aria-expanded="true"]::after {
    transform: rotate(180deg);
  }

  &::after {
    content: '\\EA4E';
    font-family: 'remixicon';
    font-size: 16px;
    font-weight: 400;
    opacity: 0.6;
    margin-top: 4px;
    margin-left: 3px;
  }

  i {
    line-height: 1;
    font-size: 18px;
    margin-right: 5px;
  }
`;

export const Back = styled.a`
  display: flex !important;
  cursor: pointer;
  width: 10px;
  display: block;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 1;
  color: inherit;
  margin-right: 15px;

  &:hover, &:focus {
    color: inherit;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  transform: translate3d(0px, 28px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
  border: none !important;

  width: 320px;
  margin-top: 5px;
  border-radius: 4px;
  box-shadow: 2px 5px 45px rgb(36 55 130 / 12%),
  0 1px 2px rgb(225 230 247 / 7%), 0 2px 4px rgb(225 230 247 / 7%),
   0 4px 8px rgb(225 230 247 / 7%), 0 8px 16px rgb(225 230 247 / 7%),
    0 16px 32px rgb(225 230 247 / 7%), 0 32px 64px rgb(225 230 247 / 7%);

  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: .875rem;
  color: #344563;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
`;

export const DropdownMenuHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${props => props.theme.colors.secondary};
  padding: 5px 10px;

  i {
    font-size: 14px;
    line-height: 1;
    margin-right: 5px;
  }
`;

export const DropdownSearch = styled.div`
  padding: 8px 10px;
  border-top: 1px solid ${props => props.theme.colors.colorbg02};
  border-bottom: 1px solid ${props => props.theme.colors.colorbg02};
  display: flex;
  align-items: center;

  i {
    line-height: 1;
    font-size: 16px;
    margin-right: 4px;
    opacity: .6;
  }

  .form-control {
    flex: 1;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    height: auto;
    margin-bottom: 0;

    &:focus { box-shadow: none; }
  }
`;

export const DropdownItemContainer = styled(PerfectScrollbar)`
  height: 180px;
  overflow: auto;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.colortx02};
  padding: 8px 12px;
  cursor: pointer;

  &::before {
    content: '\\F050';
    font-family: 'remixicon';
    font-size: 12px;
    margin-right: 5px;
    margin-top: 1px;
    opacity: .75;
  }

  &:hover {
    background-color: ${props => props.theme.colors.bodyBg};
  }

  ${props => props.active && `background-color: ${props.theme.colors.bodyBg};`}
`;

export const SubTitle = styled.div`
  h6 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
  }

  span {
    display: block;
    font-size: 12px;
    opacity: .6;
  }
`;

export const Body = styled.div`
  height: calc(100% - 56px);
  overflow: auto;
  position: relative;
  padding: 15px;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const Home = styled.div`
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(193,200,222,0.2);
`;

export const Label = styled.label`
  display: block;
  font-weight: 500;
  color: ${props => props.theme.colors.dark};
`;

//  @include hover-focus() {
//     color: $color-tx-02;
//     border-color: darken($color-bg-02, 5%);
//     background-color: $body-bg;
//   }
//
//   + .pp-item { margin-top: 8px; }

export const Item = styled.div`
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.colorbg02};
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.colortx02};
  cursor: pointer;
  margin-top: 8px;

  span {
    margin-left: auto;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #98A4C1;
    font-size: 10px;
    font-family: $font-family-sans-serif;
  }

  &:hover {
    color: ${props => props.theme.colors.colortx02};
    border-color: #cdd5f2;
    background-color: ${props => props.theme.colors.gray100};
  }
`;

export const Content = styled.div`
  p { margin-bottom: 0; }
  strong { font-weight: 500; }
  img { cursor: pointer; }
  .form-control {
    border-radius: 4px;
    padding: 8px 10px;
    height: auto;

    color: ${props => props.theme.colors.gray700};
    &::placeholder {
      color: ${props => props.theme.colors.colortx03};
    }

    &.is-invalid {
      border-color: #e4e7ea;
      background-image: none;
    }
  }

  .select__single-value {
    color: ${props => props.theme.colors.gray700};;
    &::placeholder {
      color: ${props => props.theme.colors.colortx03};
    }
  }

  .react-datepicker__input-container > input {
    color: ${props => props.theme.colors.gray700};;
    &::placeholder {
      color: ${props => props.theme.colors.colortx03};
    }

    height: 35px !important;
    border: 1px solid #e4e7ea !important;
    font-family: inherit;
  }

  .DateInput_input {
    color: ${props => props.theme.colors.gray700};
    line-height: 1.5;
    font-size: 0.875rem;
    letter-spacing: .2px;
    font-weight: 400;
    height: 35px !important;
    border: 1px solid #e4e7ea !important;
    font-family: inherit;

    &::placeholder {
      color: ${props => props.theme.colors.colortx03} !important;
      font-weight: 400;
    }
  }

  .select__control {
    border: 1px solid #e4e7ea !important;
    min-height: 35px !important;
  }

  .select__placeholder {
    color: ${props => props.theme.colors.colortx03} !important;
  }

  .custom-control-label {
    font-weight: 400;
  }
`;

export const Card = styled(BaseCard)`
  border-color: ${props => props.theme.input.borderColor};
  border-radius: 4px;
  margin-bottom: 0px;
`;

export const CardBody = styled(BaseCardBody)`
  padding: 15px;

  & ~ & {
    border-top: 1px solid #D5DCF4;
  }
`;

export const Table = styled(CommonTable)`
  margin-bottom: 0;
  color: ${props => props.theme.colors.bodyColor};

  thead th {
    font-weight: 500;
    border-bottom-width: 0;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  tbody th { font-weight: 500; }
  tbody td { vertical-align: middle; }

  tbody tr:hover th,
  tbody tr:hover td {
    background-color: rgba(240, 242, 249, 0.6);
    cursor: pointer;
  }

  &.table-bd { border: 1px solid #D5DCF4; }

  .highlight {
    background-color: #def7ec;
    padding: 4px;
    border-radius: 4px;
  }

  .active {
    border-left: 2px solid ${props => props.theme.colors.primary};
  }

  tr th {
   max-width: 60px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
  }

  tr:hover {
    .ri-arrow-right-circle-line {
      background: lightgray;
      border-radius: 50% 50%;
    }
  }

  .available-date {
    min-width: 90px;
  }
`;

export const ImagePreview = styled.div`
  display: block;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  margin-top: 12px;
  img { border-radius: 3px; }

  &:hover, &:focus {
    &::before { opacity: 0.6; }
    a {
      opacity: 1;
      visibility: visible;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background-color: #001129;
    opacity: 0;
    transition: all 0.25s;
  }

  a {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.85);
    color: ${props => props.theme.colors.secondary};
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;

    &:hover, &:focus {
      background-color: #fff;
      color: ${props => props.theme.colors.dark};
    }

    i {
      font-size: 18px;
      position: relative;
      transform: rotate(45deg);
      font-weight: 700;
    }
  }
`;
