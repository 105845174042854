import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  // API REQUESTS
  LIST_PROPERTY_MEDIA_REQUEST = 'LIST_PROPERTY_MEDIA_REQUEST',
  LIST_PROPERTY_MEDIA_SUCCESS = 'LIST_PROPERTY_MEDIA_SUCCESS',
  LIST_PROPERTY_MEDIA_FAILURE = 'LIST_PROPERTY_MEDIA_FAILURE',

  LIST_PROPERTY_MEDIA_LABELS_REQUEST = 'LIST_PROPERTY_MEDIA_LABELS_REQUEST',
  LIST_PROPERTY_MEDIA_LABELS_SUCCESS = 'LIST_PROPERTY_MEDIA_LABELS_SUCCESS',
  LIST_PROPERTY_MEDIA_LABELS_FAILURE = 'LIST_PROPERTY_MEDIA_LABELS_FAILURE',

  ADD_PROPERTY_MEDIA_FILE_REQUEST = 'ADD_PROPERTY_MEDIA_FILE_REQUEST',
  ADD_PROPERTY_MEDIA_FILE_SUCCESS = 'ADD_PROPERTY_MEDIA_FILE_SUCCESS',
  ADD_PROPERTY_MEDIA_FILE_FAILURE = 'ADD_PROPERTY_MEDIA_FILE_FAILURE',

  UPDATE_PROPERTY_MEDIA_FILE_REQUEST = 'UPDATE_PROPERTY_MEDIA_FILE_REQUEST',
  UPDATE_PROPERTY_MEDIA_FILE_SUCCESS = 'UPDATE_PROPERTY_MEDIA_FILE_SUCCESS',
  UPDATE_PROPERTY_MEDIA_FILE_FAILURE = 'UPDATE_PROPERTY_MEDIA_FILE_FAILURE',

  LIST_CUSTOMER_MEDIA_REQUEST = 'LIST_CUSTOMER_MEDIA_REQUEST',
  LIST_CUSTOMER_MEDIA_SUCCESS = 'LIST_CUSTOMER_MEDIA_SUCCESS',
  LIST_CUSTOMER_MEDIA_FAILURE = 'LIST_CUSTOMER_MEDIA_FAILURE',

  LIST_CUSTOMER_MEDIA_LABELS_REQUEST = 'LIST_CUSTOMER_MEDIA_LABELS_REQUEST',
  LIST_CUSTOMER_MEDIA_LABELS_SUCCESS = 'LIST_CUSTOMER_MEDIA_LABELS_SUCCESS',
  LIST_CUSTOMER_MEDIA_LABELS_FAILURE = 'LIST_CUSTOMER_MEDIA_LABELS_FAILURE',

  ADD_CUSTOMER_MEDIA_FILE_REQUEST = 'ADD_CUSTOMER_MEDIA_FILE_REQUEST',
  ADD_CUSTOMER_MEDIA_FILE_SUCCESS = 'ADD_CUSTOMER_MEDIA_FILE_SUCCESS',
  ADD_CUSTOMER_MEDIA_FILE_FAILURE = 'ADD_CUSTOMER_MEDIA_FILE_FAILURE',

  UPDATE_CUSTOMER_MEDIA_FILE_REQUEST = 'UPDATE_CUSTOMER_MEDIA_FILE_REQUEST',
  UPDATE_CUSTOMER_MEDIA_FILE_SUCCESS = 'UPDATE_CUSTOMER_MEDIA_FILE_SUCCESS',
  UPDATE_CUSTOMER_MEDIA_FILE_FAILURE = 'UPDATE_CUSTOMER_MEDIA_FILE_FAILURE',

  SET_TOOLBAR_NAV_TAB_MEDIA = 'SET_TOOLBAR_NAV_TAB_MEDIA',

  SET_SHOW_FORMAT = 'SET_SHOW_FORMAT',
  SET_CHECKED_FILES = 'SET_CHECKED_FILES',
  RESET_MEDIA_LIST = 'RESET_MEDIA_LIST',
  SET_LIST_FILTERS = 'SET_LIST_FILTERS',
}

export interface UpdateMediaParams {
  labels?: string[];
  in_trash?: boolean;
  is_favorite?: boolean;
}

export interface MediaParams {
  id: number;
  created: string;
  updated: string;
  file: string;
  in_trash: boolean;
  is_favorite: boolean;
  type: 'image'|'video'|'audio'|'document';
  labels: string[];
}

export interface PaginatedParams {
  count: number,
  next: string,
  previous: string,
  results: MediaParams[],
  times?: string[],
}

export interface ListMediaParams {
  trash?: boolean;
  favorites?: boolean;
  recently_added?: boolean;
  label?: string;
  type?: string;
}

export interface ListFilters {
  trash?: boolean;
  favorites?: boolean;
  type?: string;
  label?: string;
  recently_added?: boolean;
}

interface SetCheckedFilesAction {
  type: ActionType.SET_CHECKED_FILES;
  checkedFiles: MediaParams[];
}

interface SetMediaListAction {
  type: ActionType.RESET_MEDIA_LIST;
  mediaObjects: [];
}

interface SetListFiltersAction {
  type: ActionType.SET_LIST_FILTERS;
  filters: ListFilters;
}

interface SetToolbarNavTabMediaAction {
  type: ActionType.SET_TOOLBAR_NAV_TAB_MEDIA;
  nav: 'ALL_MEDIA'|'IMAGES'|'VIDEO'|'AUDIO'|'DOCUMENTS';
}

interface SetShowFormatAction {
  type: ActionType.SET_SHOW_FORMAT;
  format: 'MASONRY'|'GRID'|'LIST';
}

interface ListMediaAction {
  type: ActionType.LIST_PROPERTY_MEDIA_REQUEST | ActionType.LIST_PROPERTY_MEDIA_SUCCESS | ActionType.LIST_PROPERTY_MEDIA_FAILURE
  | ActionType.LIST_CUSTOMER_MEDIA_REQUEST | ActionType.LIST_CUSTOMER_MEDIA_SUCCESS | ActionType.LIST_CUSTOMER_MEDIA_FAILURE;
  result: {
    data: PaginatedParams
  };
  error: {
    response: { status: string };
  };
}

interface ListMediaLabelsAction {
  type: ActionType.LIST_PROPERTY_MEDIA_LABELS_REQUEST | ActionType.LIST_PROPERTY_MEDIA_LABELS_SUCCESS | ActionType.LIST_PROPERTY_MEDIA_LABELS_FAILURE;
  result: {
    data: { labels: string[] }
  };
  error: {
    response: { status: string };
  };
}

interface CreateMediaAction {
  type: ActionType.ADD_PROPERTY_MEDIA_FILE_REQUEST | ActionType.ADD_PROPERTY_MEDIA_FILE_SUCCESS | ActionType.ADD_PROPERTY_MEDIA_FILE_FAILURE;
  result: {
    data: MediaParams
  };
  error: {
    response: { status: string };
  };
}

interface UpdateMediaAction {
  type: ActionType.UPDATE_PROPERTY_MEDIA_FILE_REQUEST | ActionType.UPDATE_PROPERTY_MEDIA_FILE_SUCCESS | ActionType.UPDATE_PROPERTY_MEDIA_FILE_FAILURE;
  result: {
    data: MediaParams
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | SetToolbarNavTabMediaAction
  | ListMediaAction
  | ListMediaLabelsAction
  | SetShowFormatAction
  | SetCheckedFilesAction
  | CreateMediaAction
  | UpdateMediaAction
  | SetMediaListAction
  | SetListFiltersAction;
