import React, { FC } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FormFeedback, Input } from 'reactstrap';
import { DatePickerWrapper } from './styles';

interface DatePickerProps {
  id?: string,
  value: string,
  onChange: (id: string, value: string) => void,
  name?: string,
  placeholder?: string,
  className?: string,
  invalid?: boolean,
  error?: string,
  touched?: boolean,
  disableFutureDate?: boolean,
  startDate?: Date,
  minDate?: Date,
}

const YearDatePicker: FC<DatePickerProps> = ({ id, value, name, placeholder, onChange, startDate, className, error, touched, disableFutureDate, minDate }) => (
  <DatePickerWrapper className={className} $invalid={error && touched}>
    <DatePicker
      id={id}
      name={name}
      showPopperArrow={false}
      className="ml-2"
      calendarClassName="leasing-datepicker"
      selected={value ? moment(value).toDate() : null}
      onChange={(date: Date) => onChange(id, date ? moment(date).format('YYYY-MM-DD') : null)}
      dateFormat="MM/dd/yyyy"
      placeholderText={placeholder}
      todayButton="Today"
      maxDate={disableFutureDate && moment().toDate()}
      minDate={minDate}
      yearDropdownItemNumber={50}
      showYearDropdown
      scrollableYearDropdown
      openToDate={value ? null : startDate}
    />
    <Input hidden invalid={error && touched} />
    <FormFeedback>{error}</FormFeedback>
  </DatePickerWrapper>
);

export default YearDatePicker;
