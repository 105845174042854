import { AudioProcessor } from '@twilio/voice-sdk';
import KrispSDK from 'krisp/krispsdk';

export default class BackgroundAudioProcessor implements AudioProcessor {
  private audioContext: AudioContext;
  private destination: MediaStreamAudioDestinationNode;
  private source: MediaStreamAudioSourceNode;
  private filterNode;
  private sdk: KrispSDK;
  private filterReady = false;

  async createProcessedStream(stream: MediaStream): Promise<MediaStream> {
    this.audioContext = new AudioContext();
    this.sdk = new KrispSDK({
      params: {
        debugLogs: false,
        logProcessStats: false,
        useSharedArrayBuffer: false,
        models: {
          model8: '/static/krisp/dist/models/model_8.kw',
          model16: '/static/krisp/dist/models/model_16.kw',
          model32: '/static/krisp/dist/models/model_32.kw',
        },
      },
    });
    await this.audioContext.resume();
    await this.sdk.init();

    this.source = this.audioContext.createMediaStreamSource(stream);
    this.destination = this.audioContext.createMediaStreamDestination();

    this.filterNode = await this.sdk.createNoiseFilter(
      {
        audioContext: this.audioContext,
        stream,
      },
      () => {
        this.filterNode.enable();
        this.filterReady = true;
      },
      () => {
        this.filterNode.disable();
      },
    );

    this.source.connect(this.filterNode);
    this.filterNode.connect(this.destination);
    // Return the resulting MediaStream
    return this.destination.stream;
  }
  enableFilter(): void {
    try {
      if (this.filterReady) {
        this.filterNode.enable();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  disableFilter(): void {
    try {
      if (this.filterReady) {
        this.filterNode.disable();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  async destroyProcessedStream(stream: MediaStream): Promise<void> {
    try {
      this.source.disconnect();
      this.destination.disconnect();
      stream.getTracks().forEach(track => track.stop());
      this.filterNode.disconnect();
      await this.filterNode.dispose();
      await this.audioContext.suspend();
      this.sdk.dispose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
