import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'dwell/actions/index';
import { useInterval } from 'dwell/components';
import { MinChatItem, DarkBlueAvatar, CloseIcon, CommunicationsTypeIcon, AvatarImg, UnreadCount } from './styles';

const CircleHobbes: FC = () => {
  const [isHobbesBlinking, setIsHobbesBlinking] = useState(false);
  const dispatch = useDispatch();
  const { closeAgentQuestionWindow, manageAgentQuestion } = actions.agentQuestion;
  const unreadCount = useSelector(state => state.agentQuestion.unreadCount);

  const closeChat = () => {
    dispatch(manageAgentQuestion(false));
  };

  const blinkBlue = () => {
    setIsHobbesBlinking(true);
    setTimeout(() => {
      setIsHobbesBlinking(false);
    }, 2000);
  };

  useInterval(
    () => {
      blinkBlue();
    },
    unreadCount ? 4000 : null,
  );

  return (
    <MinChatItem onClick={() => dispatch(closeAgentQuestionWindow(false))} blinking={isHobbesBlinking}>
      <DarkBlueAvatar className="bg-dark" online>
        <AvatarImg src="https://liftlytics.imgix.net/union-main/img/chatbot.png?auto=format&fit=crop&fm=webp&w=0.5" alt="bot-icon" className="bot" />
      </DarkBlueAvatar>
      <UnreadCount show={unreadCount}>{unreadCount}</UnreadCount>
      <CommunicationsTypeIcon show>
        <i className="ri-chat-3-fill" />
      </CommunicationsTypeIcon>
      <CloseIcon
        className="min-chat-close"
        onClick={(event) => {
          event.stopPropagation();
          closeChat();
        }}
      />
    </MinChatItem>
  );
};

export default CircleHobbes;
