export default {
  DATE_RANGE_CHOICES: {
    TODAY: 'Today',
    THIS_WEEK: 'This week',
    THIS_MONTH: 'This month',
    THIS_QUARTER: 'This quarter',
    THIS_YEAR: 'This year',

    LAST_WEEK: 'Last week',
    LAST_MONTH: 'Last month',
    LAST_QUARTER: 'Last quarter',
    LAST_YEAR: 'Last year',
    ALL_TIME: 'All time',

    CUSTOM_RANGE: 'Custom range',
  },

  COMPARE_CHOICES: {
    PREVIOUS_PERIOD: 'Previous period',
    COMPANY_WIDE_AVERAGES: 'Company-wide averages',
  },

  REPORT_TYPE_CHOICES: {
    OVERVIEW_REPORTS: 'Overview Reports',
    MARKETING_REPORTS: 'Marketing Reports',
    AGENT_AND_OFFICE_REPORTS: 'Agent & Office Reports',
    CORPORATE_PPC: 'Corporate PPC',
    STANDALONE_PPC: 'Standalone PPC',
    CB_SUMMARY_REPORTS: 'Credit Builder Summary Dashboard',
    CB_ENROLLMENT_REPORTS: 'Credit Builder Enrollment Report',
    CB_CANCELLATION_REPORTS: 'Credit Builder Cancellation Report',
    CB_PAYMENT_REPORTS: 'Credit Builder Payment Report',
    CB_NET_ENROLLMENT_REPORTS: 'Credit Builder Net Enrollment Report',
    CB_BILLING_REPORTS: 'Credit Builder Billing Report',
    WORKFORCE_REPORTS: 'Workforce Reports',
  },

  ATTRIBUTION_CHOICES: {
    OVERALL: 'Overall',
    PERFORMANCE: 'Performance',
  },
};
