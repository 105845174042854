import { paths } from 'dwell/constants';
import { PromiseActionType } from 'dwell/store/types';
import { ActionType, CommunityDetails } from './action-types';

export default {
  getCommunityDetails: (propertyId: number): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.GET_COMMUNITY_DETAILS_REQUEST,
        ActionType.GET_COMMUNITY_DETAILS_SUCCESS,
        ActionType.GET_COMMUNITY_DETAILS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.COMMUNITY_DETAILS, propertyId)),
    },
  }),

  updateCommunityDetails: (propertyId: number, data: CommunityDetails): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_COMMUNITY_DETAILS_REQUEST,
        ActionType.UPDATE_COMMUNITY_DETAILS_SUCCESS,
        ActionType.UPDATE_COMMUNITY_DETAILS_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.COMMUNITY_DETAILS, propertyId), data),
    },
  }),
};
