import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';

export const FormLabel = styled.label`
  font-weight: 400;
`;

export const Link = styled.a``;

export const StyledDatePicker = styled(DatePicker)`
    ${props => props.disabled && css`
        background-color: #e4e7ea !important;
        cursor: wait !important;
    `}
`;
