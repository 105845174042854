import { paths } from 'dwell/constants';
import { CallBackFunction } from 'src/interfaces';
import { build } from 'dwell/constants/paths';
import { ActionType, Action } from './action-types';

export default {
  getSources: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SOURCES_REQUEST,
        ActionType.GET_SOURCES_SUCCESS,
        ActionType.GET_SOURCES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.SOURCE, { params: { show_all: true } }),
    },
  }),
  updateSourceById: (id: number, params: { is_paid: boolean }, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SOURCES_REQUEST,
        ActionType.UPDATE_SOURCES_SUCCESS,
        ActionType.UPDATE_SOURCES_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.SOURCE_DETAILS, id), params),
      successCB,
    },
  }),
  updateSpends: (data: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SPENDS_REQUEST,
        ActionType.UPDATE_SPENDS_SUCCESS,
        ActionType.UPDATE_SPENDS_FAILURE,
      ],
      promise: client => client.put(paths.api.v1.SPENDS_UPDATE, data),
      successCB,
    },
  }),
};
