import React, { useState, useEffect, FC, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';

import { selectSelectedProperties, selectIsTheFirstPropertyCBPlatform } from 'dwell/store/property/reducers';
import { FlexCenter } from 'styles/common';
import { paths } from 'dwell/constants';
import { checkIfAllPropertiesAreInactive, getPropertyId } from 'src/utils';
import { platformTypes, roleTypes } from 'site/constants';
import { UserProps } from 'dwell/store/user/action-types';

const Leftbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.theme.templates.leftbarWidth};
  background-color: #fff;
  border-right: 1px solid ${props => props.theme.colors.colorbg02};

  ${props => (props.hiddenSideBar ? css`display: none;` : '')}
`;

const LeftbarBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 20px;
`;

const Nav = styled.nav`
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;

const NavItem = styled.div`
  margin-bottom: 15px;
  position: relative;
  outline: none;
  padding: 0;
  font-size: 22px;
  font-weight: 400;
  width: ${props => props.theme.templates.heightBase};
  height: ${props => props.theme.templates.heightBase};
  ${FlexCenter}
  cursor: pointer;
  color: ${props => (props.active ? props.theme.colors.colorui01 : 'rgba(74,94,138,0.75)')};

  i:hover {
    color: ${props => props.theme.colors.colorui01};
  }

  .ri-group-fill {
    font-size: 21px;
  }
}
`;

const NavItemTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 3px;
    white-space: nowrap;
    padding: 0 12px;
    height: 34px;
    display: flex;
    align-items: center;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.95);
  }
`;

const getTabFromRoute = (route) => {
  const propertyId = getPropertyId();
  const routesSiteContent = [
    paths.client.SITE_CONTENT.HOME,
    paths.client.SITE_CONTENT.GALLERY,
    paths.client.SITE_CONTENT.FLOOR_PLANS,
    paths.client.SITE_CONTENT.AMENITIES,
    paths.client.SITE_CONTENT.NEIGHBORHOOD,
    paths.client.SITE_CONTENT.VIRTUAL_TOUR,
    paths.client.SITE_CONTENT.CONTACT,
    paths.client.SITE_CONTENT.FOOTER,
    paths.client.SITE_CONTENT.DESIGN,
    paths.client.SITE_CONTENT.MISC,
    paths.client.SITE_CONTENT.PROMOTIONS,
  ].map(r => paths.build(r, propertyId));

  const routesApartmentAvailability = paths.build(paths.client.APARTMENT_AVAILABILITY, propertyId);

  if (routesSiteContent.includes(route)) return 'Site Contents';

  if (route.includes('/automations')) return 'Automation';

  if (routesApartmentAvailability === route) return 'Apartment Availability';

  if (paths.client.HOBBES_SETTINGS === route) return 'Hobbes Settings';

  if (paths.client.MANAGE_USERS === route) return 'Users';

  if (paths.client.MANAGE_CUSTOMERS === route) return 'Customers';

  if (paths.client.MANAGE_CLIENTS === route) return 'Clients';

  if (paths.client.MANAGE_PROPERTIES === route) return 'Properties';

  return null;
};

interface DefaultAsideProps {
  currentUser: UserProps;
  isViewSideBar: boolean;
}

const DefaultAside: FC<DefaultAsideProps> = ({ currentUser, isViewSideBar }) => {
  const { push } = useHistory();
  const { state, pathname } = useLocation();

  const [active, setActive] = useState(getTabFromRoute(pathname));
  const [tooltipOpen, setTooltipOpen] = useState(Array(5).fill(false));
  const selectedProperties = useSelector(selectSelectedProperties);
  const isFirstPropertyCBPlatform = useSelector(selectIsTheFirstPropertyCBPlatform);

  const arePropertiesInactive = useMemo(() => checkIfAllPropertiesAreInactive(selectedProperties), [selectedProperties]);

  let navItems = [
    { name: 'Properties', icon: 'ri-home-7-fill', path: paths.client.MANAGE_PROPERTIES },
    { name: 'Clients', icon: 'ri-home-smile-2-fill', path: paths.client.MANAGE_CLIENTS },
    { name: 'Customers', icon: 'ri-team-fill', path: paths.client.MANAGE_CUSTOMERS },
    { name: 'Users', icon: 'ri-group-fill', path: paths.client.MANAGE_USERS },
    { name: 'Site Contents', icon: 'ri-settings-fill', path: paths.client.SITE_CONTENT.HOME },
    { name: 'Apartment Availability', icon: 'ri-hotel-bed-fill', path: paths.client.APARTMENT_AVAILABILITY },
    { name: 'Automation', icon: 'ri-send-plane-fill', path: paths.client.NURTURE_AUTOMATIONS.VIEW },
    { name: 'Hobbes Settings', icon: 'ri-rocket-fill', path: paths.client.HOBBES_SETTINGS },
  ];

  const toggle = (id) => {
    const tooltipState = [...tooltipOpen];
    tooltipState[id] = !tooltipState[id];
    setTooltipOpen(tooltipState);
  };

  const toggleSideBar = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (state && state.sideTab !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toggleSideBar(state.sideTab);
    } else {
      toggleSideBar(getTabFromRoute(pathname));
    }
  }, [pathname]);

  const redirect = (tab) => {
    if (['Site Contents', 'Apartment Availability', 'Automation'].includes(tab.name)) {
      const currentProperty = selectedProperties[0];
      push({ pathname: paths.build(tab.path, currentProperty?.external_id), state: { sideTab: tab.name } });
    } else {
      push({ pathname: tab.path, state: { sideTab: tab.name } });
    }
    toggleSideBar(tab.name);
  };

  if (selectedProperties.length > 1 || (selectedProperties.length === 1 && (!platformTypes.hasPlatforms(selectedProperties[0].platforms) || platformTypes.isDwellOnly(selectedProperties[0].platforms)))) {
    navItems = navItems.filter(item => item.name !== 'Site Contents');
  }

  if (currentUser.role !== roleTypes.LIFT_LYTICS_ADMIN) {
    navItems = navItems.filter(item => item.name !== 'Customers');
  }

  if (currentUser.role === roleTypes.PROPERTY_ADMIN) {
    navItems = navItems.filter(item => item.name !== 'Hobbes Settings');
  }

  if (currentUser.role === roleTypes.GENERIC_ADMIN) {
    navItems = [{ name: 'Apartment Availability', icon: 'ri-hotel-bed-fill', path: paths.client.APARTMENT_AVAILABILITY }];
  }

  if (selectedProperties.length > 1) {
    navItems = navItems.filter(item => !['Automation', 'Apartment Availability'].includes(item.name));
  } else if (selectedProperties.length && !selectedProperties[0].nurture_enabled) {
    navItems = navItems.filter(item => item.name !== 'Automation');
  }

  if (currentUser.is_call_scorer) {
    navItems = [];
  }

  if (currentUser.corporate_site_enabled) {
    navItems = [
      ...navItems,
      { name: 'Corporate', icon: 'ri-building-fill', path: paths.client.CORPORATE },
    ];
  }

  if (arePropertiesInactive || isFirstPropertyCBPlatform) {
    const baseList = ['Properties', 'Clients', 'Users'];
    const fullList = baseList.concat(['Customers']);
    navItems = navItems.filter(item => fullList.includes(item.name));
    if (isFirstPropertyCBPlatform) {
      navItems = navItems.filter(item => baseList.includes(item.name));
    }
  }

  return (
    <Leftbar hiddenSideBar={!isViewSideBar}>
      <LeftbarBody>
        <Nav>
          {navItems.map((item, index) => {
            const value = item.name.replace(/ /g, '');
            return (
              <React.Fragment key={index}>
                <NavItem onClick={() => redirect(item)} active={active === item.name} id={value}>
                  <i className={item.icon} />
                </NavItem>
                <NavItemTooltip placement="top" isOpen={tooltipOpen[index]} target={value} toggle={() => toggle(index)}>
                  {item.name}
                </NavItemTooltip>
              </React.Fragment>
            );
          })}
        </Nav>
      </LeftbarBody>
    </Leftbar>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(DefaultAside);
