import React from 'react';
import NumberFormat from 'react-number-format';
import { CustomFormInput } from 'leasing/views/applicants/styles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CurrencyInput = ({ onChange, isInlineInput = false, ...rest }) => (
  <NumberFormat
    onValueChange={values => onChange(values.floatValue)}
    customInput={CustomFormInput}
    inputMode="numeric"
    fixedDecimalScale
    decimalScale={2}
    thousandSeparator
    prefix="$"
    style={isInlineInput ? { width: '120px', height: '36px' } : { height: '36px' }}
    {...rest}
  />
);

export default CurrencyInput;
