import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_PROMOTIONS_REQUEST = 'GET_PROMOTIONS_REQUEST',
  GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS',
  GET_PROMOTIONS_FAILURE = 'GET_PROMOTIONS_FAILURE',

  CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST',
  CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS',
  CREATE_PROMOTION_FAILURE = 'CREATE_PROMOTION_FAILURE',

  UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST',
  UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS',
  UPDATE_PROMOTION_FAILURE = 'UPDATE_PROMOTION_FAILURE',

  DELETE_PROMOTION_REQUEST = 'DELETE_PROMOTION_REQUEST',
  DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS',
  DELETE_PROMOTION_FAILURE = 'DELETE_PROMOTION_FAILURE',
}

export interface PromotionProps {
  id?: number;
  name?: string;
  promotion_text?: string;
  promotion_html?: string;
  button_label?: string;
  is_active?: boolean;
  image?: string;
  lease_duration?: number;
  lease_duration_modifier?: string;
  dollar_value?: number;
  seo_title?: string;
  seo_description?: string;
  floor_plans?: { id: number | string; plan: string }[];
  restriction?: string;
  promotion_title?: string;
  unit_types?: string[] | { label: string; value: string }[];
}

interface GetPromotionsAction {
  type: ActionType.GET_PROMOTIONS_REQUEST | ActionType.GET_PROMOTIONS_SUCCESS | ActionType.GET_PROMOTIONS_FAILURE;

  result: {
    data: { results: PromotionProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface CreatePromotionAction {
  type: ActionType.CREATE_PROMOTION_REQUEST | ActionType.CREATE_PROMOTION_SUCCESS | ActionType.CREATE_PROMOTION_FAILURE;
  error: {
    response: { status: string };
  };
}

interface UpdatePromotionAction {
  type: ActionType.UPDATE_PROMOTION_REQUEST | ActionType.UPDATE_PROMOTION_SUCCESS | ActionType.UPDATE_PROMOTION_FAILURE;
  error: {
    response: { status: string };
  };
}

interface DeletePromotionAction {
  type: ActionType.DELETE_PROMOTION_REQUEST | ActionType.DELETE_PROMOTION_SUCCESS | ActionType.DELETE_PROMOTION_FAILURE;
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetPromotionsAction | CreatePromotionAction | UpdatePromotionAction | DeletePromotionAction;
