import produce from 'immer';
import { CommonStateType } from 'dwell/store/types';
import { ResidentGroupProps } from 'resident/store/lease/action-types';
import { Action, ActionType } from './action-types';

export interface ResidentState extends CommonStateType {
  resident: ResidentGroupProps,
}

const residentDefault = {
  id: null,
  first_name: null,
};

export const initialState: ResidentState = {
  isSubmitting: false,
  isLoaded: true,
  resident: residentDefault as ResidentGroupProps,
};

export default produce((state: ResidentState = initialState, action: Action): ResidentState => {
  switch (action.type) {
    case ActionType.SET_RESIDENT:
      state.resident = action.resident;
      break;

    case ActionType.SUBSCRIBE_RESIDENT_SUCCESS:
    case ActionType.UNSUBSCRIBE_RESIDENT_SUCCESS:
    case ActionType.REFUND_RESIDENT_SUCCESS:
      state.isLoaded = true;
      state.resident = { ...state.resident, ...action.result.data };
      break;
  }

  return state;
});
