import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_MARKET_DETAIL_REQUEST = 'GET_MARKET_DETAIL_REQUEST',
  GET_MARKET_DETAIL_SUCCESS = 'GET_MARKET_DETAIL_SUCCESS',
  GET_MARKET_DETAIL_FAILURE = 'GET_MARKET_DETAIL_FAILURE',
  GET_MARKET_PROPERTIES_REQUEST = 'GET_MARKET_PROPERTIES_REQUEST',
  GET_MARKET_PROPERTIES_SUCCESS = 'GET_MARKET_PROPERTIES_SUCCESS',
  GET_MARKET_PROPERTIES_FAILURE = 'GET_MARKET_PROPERTIES_FAILURE',
  GET_MARKET_SUBMARKETS_REQUEST = 'GET_MARKET_SUBMARKETS_REQUEST',
  GET_MARKET_SUBMARKETS_SUCCESS = 'GET_MARKET_SUBMARKETS_SUCCESS',
  GET_MARKET_SUBMARKETS_FAILURE = 'GET_MARKET_SUBMARKETS_FAILURE',
  GET_MARKET_RENT_COMPS_REQUEST = 'GET_MARKET_RENT_COMPS_REQUEST',
  GET_MARKET_RENT_COMPS_SUCCESS = 'GET_MARKET_RENT_COMPS_SUCCESS',
  GET_MARKET_RENT_COMPS_FAILURE = 'GET_MARKET_RENT_COMPS_FAILURE',
  GET_MTR_GROUP_BREAKDOWN_REQUEST = 'GET_MTR_GROUP_BREAKDOWN_REQUEST',
  GET_MTR_GROUP_BREAKDOWN_SUCCESS = 'GET_MTR_GROUP_BREAKDOWN_SUCCESS',
  GET_MTR_GROUP_BREAKDOWN_FAILURE = 'GET_MTR_GROUP_BREAKDOWN_FAILURE',
}

export interface PropertiesBreakdown {
  id?: number;
  name?: string;
  units_count?: number;
  min_unit_size?: number;
  avg_unit_size?: number;
  avg_rent?: number;
}

export interface UnitType {
  id?: number;
  unit_type?: string;
  beds?: number;
  min_baths?: number;
  available_units_count?: number;
  units_count?: number;
  unit_occupancy?: number;
  avg_size?: number;
  min_size?: number;
  max_size?: number;
  avg_rent?: number;
  avg_rent_sqft?: number;
  min_rent?: number;
  max_rent?: number;
  name?: string;
  baths?: number;
  average_size?: number;
  average_rent?: number;
  property?: string;
  market?: string;
  rank?: number;
}

export interface RentComps {
  property?: string;
  market?: string;
  name?: string;
  average_rent?: number;
  average_size?: number;
  average_rent_sqft?: number;
  available_units_count?: number;
  units_count?: number;
  unit_occupancy?: number;
  rank?: number;
  id: number;
}

export interface SubmarketDetail {
  id: number;
  name?: string;
  unit_types?: UnitType[];
  properties_count?: number;
  units_count?: number;
  available_units_count?: number;
  avg_occupancy?: number;
  avg_rent?: number;
  properties_offering_concession?: number;
  avg_concession?: number;
  min_concession?: number;
  max_concession?: number;
  submarkets_count?: number;
  ltn_occupancy?: number;
  is_mtr_group?: boolean;
}

interface GetMarketDetailAction {
  type: ActionType.GET_MARKET_DETAIL_REQUEST | ActionType.GET_MARKET_DETAIL_SUCCESS | ActionType.GET_MARKET_DETAIL_FAILURE;
  result: {
    data: SubmarketDetail;
  };
  error: {
    response: { status: string };
  };
}

interface GetMarketPropertiesAction {
  type: ActionType.GET_MARKET_PROPERTIES_REQUEST | ActionType.GET_MARKET_PROPERTIES_SUCCESS | ActionType.GET_MARKET_PROPERTIES_FAILURE;
  result: {
    data: { results: PropertiesBreakdown; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetMarketSubmarketsAction {
  type: ActionType.GET_MARKET_SUBMARKETS_REQUEST | ActionType.GET_MARKET_SUBMARKETS_SUCCESS | ActionType.GET_MARKET_SUBMARKETS_FAILURE;
  result: {
    data: { results: PropertiesBreakdown; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetMarketRentCompAction {
  type: ActionType.GET_MARKET_RENT_COMPS_REQUEST | ActionType.GET_MARKET_RENT_COMPS_SUCCESS | ActionType.GET_MARKET_RENT_COMPS_FAILURE;
  result: {
    data: { results: RentComps; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetMTRGroupBreakdownAction {
  type: ActionType.GET_MTR_GROUP_BREAKDOWN_REQUEST | ActionType.GET_MTR_GROUP_BREAKDOWN_SUCCESS | ActionType.GET_MTR_GROUP_BREAKDOWN_FAILURE;
  result: {
    data: { results: PropertiesBreakdown; count: number };
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetMarketDetailAction
  | GetMarketPropertiesAction
  | GetMarketSubmarketsAction
  | GetMarketRentCompAction
  | GetMTRGroupBreakdownAction;
