import produce from 'immer';
import { unionBy } from 'lodash';
import { ApplicantProps } from 'leasing/store/applicant/action-types';
import { ActionType as LeasingStateType } from 'leasing/store/lease/action-types';
import { Action, ActionType, GuarantorState } from './action-types';

export const initialState: GuarantorState = {
  errorMessage: null,
  guarantor: {} as ApplicantProps,
  guarantors: [] as ApplicantProps[],
  isGuarantorsLoaded: false,
};

export const selectGuarantors = (state: { guarantor: GuarantorState }) : ApplicantProps[] => state.guarantor.guarantors;
export const selectGuarantor = (state: { guarantor: GuarantorState }) : ApplicantProps => state.guarantor.guarantor;
export const selectHasGuarantor = (state: { guarantor: GuarantorState }) : boolean => !!state.guarantor.guarantors.length;
export const selectIsGuarantorsLoaded = (state: { guarantor: GuarantorState }): boolean => state.guarantor.isGuarantorsLoaded;
export const selectGuarantorsIncome = (state:{ guarantor: GuarantorState }): number =>
  state.guarantor.guarantors.reduce((sum, item) => (item.income_verified ? (item.total_income || 0) : 0) + sum, 0);

export default produce((state: GuarantorState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.CREATE_GUARANTOR_SUCCESS:
      state.guarantor = action.result.data;
      state.guarantors = [...state.guarantors, action.result.data];
      break;

    case ActionType.DELETE_GUARANTOR_SUCCESS:
      state.guarantor = {} as ApplicantProps;
      state.guarantors = state.guarantors.filter(i => i.id !== action.result.data.id);
      break;

    case ActionType.GET_GUARANTORS_REQUEST:
      state.isGuarantorsLoaded = false;
      break;
    case ActionType.GET_GUARANTORS_SUCCESS:
      state.isGuarantorsLoaded = true;
      state.guarantor = action.result.data.results[0] || {} as ApplicantProps;
      state.guarantors = action.result.data.results;
      break;

    case ActionType.GET_GUARANTOR_BY_ID_SUCCESS:
      state.guarantor = action.result.data;
      break;

    case ActionType.RESET_GUARANTOR:
      state.guarantor = {} as ApplicantProps;
      break;

    case ActionType.UPDATE_GUARANTOR_SUCCESS:
      state.guarantor = action.result.data;
      state.guarantors = unionBy([action.result.data], state.guarantors, 'id');
      break;

    case LeasingStateType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
