import produce from 'immer';
import { ActionType as UserActionType } from 'dwell/store/user/action-types';
import { Action, ActionType, Conversation, ConversationType, FormStructure, QuestionFormData, QuestionOption } from './action-types';
import { CommonStateType } from '../types';

export interface AgentQuestionState extends CommonStateType {
  isWindowClosed: boolean;
  isWindowMinimized: boolean;
  agentQuestionEnabled: boolean;
  conversations: Conversation[];
  areConversationsLoaded: boolean;
  formData: QuestionFormData;
  unreadCount: number;
  propertyToGetQuestions: { id: number; name: string };
  globalForm: FormStructure[];
  totalConversationCount: number;
  areQuestionOptionsLoaded: boolean;
  questionOptions: QuestionOption;
  isSendingMessage: boolean;
  isFormDisplayed: boolean;
  isConfirmationFormDisplayed: boolean;
  currentIdx: number;
  lastConversationId: number;
  conversationDiff: number;
  isFirstOpenWindow: boolean;
}

const initialState: AgentQuestionState = {
  isWindowClosed: true,
  isWindowMinimized: false,
  agentQuestionEnabled: false,
  conversations: [],
  areConversationsLoaded: false,
  errorMessage: null,
  isSubmitting: false,
  isLoaded: false,
  formData: null,
  unreadCount: 0,
  propertyToGetQuestions: null,
  globalForm: [],
  totalConversationCount: 0,
  areQuestionOptionsLoaded: false,
  questionOptions: { properties: [], users: [], submarkets: [], competitors: [] },
  isSendingMessage: false,
  isFormDisplayed: false,
  isConfirmationFormDisplayed: false,
  currentIdx: 0,
  lastConversationId: null,
  conversationDiff: 0,
  isFirstOpenWindow: false,
};

export const selectConversations = (state: { agentQuestion: AgentQuestionState }): Conversation[] => state.agentQuestion.conversations;

export default produce((state: AgentQuestionState = initialState, action: Action): AgentQuestionState => {
  switch (action.type) {
    case ActionType.MINIMIZE_AGENT_QUESTION_WINDOW:
      state.isWindowMinimized = action.isMinimized;
      state.isFormDisplayed = false;
      state.isConfirmationFormDisplayed = false;
      break;

    case ActionType.CLOSE_AGENT_QUESTION_WINDOW:
      state.isWindowClosed = action.closedWindow;
      state.isFormDisplayed = false;
      state.isConfirmationFormDisplayed = false;
      if (!action.closedWindow) {
        state.unreadCount = 0;
      }
      break;

    case ActionType.ENABLE_AGENT_QUESTION:
      state.agentQuestionEnabled = action.agentQuestionEnabled;
      break;

    case ActionType.PULL_QUESTION_REQUEST:
    case ActionType.PROCESS_ANSWERS_REQUEST:
    case ActionType.GET_CONVERSATIONS_REQUEST:
      state.isSubmitting = true;
      state.isSendingMessage = true;
      state.areConversationsLoaded = false;
      break;
    case ActionType.PULL_QUESTION_SUCCESS:
    case ActionType.PROCESS_ANSWERS_SUCCESS:
    case ActionType.GET_CONVERSATIONS_SUCCESS: {
      const { conversations } = state;
      const nConversations = action.result.data.results.filter(i => !conversations.find(j => j.id === i.id)).concat(state.conversations);
      const orderedConversations = [...nConversations.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())];
      state.isSubmitting = false;
      state.isSendingMessage = false;
      state.conversations = orderedConversations;
      state.totalConversationCount = action.result.data.count;
      if (orderedConversations.length) {
        const lastIndex = orderedConversations.length - 1;
        const lastConversation = orderedConversations[lastIndex];
        if (action.type === ActionType.PROCESS_ANSWERS_SUCCESS && state.lastConversationId) {
          const prevConversationPosition = orderedConversations.map(c => c.id).indexOf(state.lastConversationId);
          state.conversationDiff = lastIndex - prevConversationPosition;
        }
        if (state.lastConversationId !== lastConversation.id) {
          state.lastConversationId = lastConversation.id;
        }
        if (state.isWindowClosed && ([ConversationType.BOT_QUESTION, ConversationType.BOT_CONFIRMATION] as string[]).includes(lastConversation.type)) {
          const newCount = state.unreadCount + 1;
          state.unreadCount = newCount;
        }
      }
      state.areConversationsLoaded = true;
      break;
    }
    case ActionType.PULL_QUESTION_FAILURE:
    case ActionType.PROCESS_ANSWERS_FAILURE:
    case ActionType.GET_CONVERSATIONS_FAILURE:
      state.isSubmitting = false;
      state.isSendingMessage = false;
      state.errorMessage = action.error.response?.status;
      state.areConversationsLoaded = true;
      break;

    case ActionType.SET_FORM_DATA:
      state.formData = action.formData;
      break;

    case ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_SUCCESS:
      state.isSubmitting = false;
      state.propertyToGetQuestions = action.result.data;
      break;
    case ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      state.propertyToGetQuestions = null;
      break;

    case ActionType.GET_QUESTION_OPTIONS_REQUEST:
      state.areQuestionOptionsLoaded = false;
      state.questionOptions = { properties: [], users: [], submarkets: [], competitors: [] };
      break;

    case ActionType.GET_QUESTION_OPTIONS_SUCCESS:
      state.areQuestionOptionsLoaded = true;
      state.questionOptions = action.result.data;
      break;

    case ActionType.GET_QUESTION_OPTIONS_FAILURE:
      state.areQuestionOptionsLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.SET_GLOBAL_FORM:
      state.globalForm = action.form;
      break;

    case ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_REQUEST:
      state.isSubmitting = true;
      break;

    case UserActionType.GET_CURRENT_USER_SUCCESS:
      state.propertyToGetQuestions = action.result.data.property_to_get_questions;
      break;

    case ActionType.SET_IS_FORM_DISPLAYED:
      state.isFormDisplayed = action.formState;
      if (action.formState) {
        state.isConfirmationFormDisplayed = false;
      }
      break;

    case ActionType.SET_IS_CONFIRMATION_FORM_DISPLAYED:
      state.isConfirmationFormDisplayed = action.confirmationFormState;
      if (action.confirmationFormState) {
        state.isFormDisplayed = false;
      }
      break;

    case ActionType.SET_CURRENT_IDX:
      state.currentIdx = action.idx;
      break;

    case ActionType.RESET_CONVERSATION_DIFF:
      state.conversationDiff = 0;
      break;

    case ActionType.SET_IS_FIRST_OPEN:
      state.isFirstOpenWindow = true;
      break;
  }

  return state;
});
