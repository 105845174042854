import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormFeedback } from 'reactstrap';
import { CurrencyInput } from 'leasing/components/index';
import { CustomFormInput } from 'leasing/views/agreement/styles';
import { highlightElement, cancelHighlight } from 'leasing/views/agreement/utils/highlight_element';
import { selectHighlightFields } from 'leasing/store/lease_document/reducers';
import { getMaskingPhoneNumber, getMaskingIdentification, getMaskingManyPhoneNumbers } from 'leasing/views/applicants/_utils';
import NumberInput from './number_input';

export interface FormInputProps {
  className?: string,
  disabled?: boolean,
  error?: string,
  id?: string,
  invalid?: boolean,
  isCurrency?: boolean,
  isDelay?: boolean,
  isIdentification?: boolean,
  isMany?: boolean,
  isNumber?: boolean,
  isPhoneNumber?: boolean,
  name?: string,
  needFocus?: boolean,
  onChange: (id: string, value: string | number) => void,
  placeholder?: string,
  readOnly?: boolean,
  rows?: number,
  touched?: boolean,
  type?: string,
  value: string | number,
  style?: React.CSSProperties,
  maxLength?: string | number,
}

const FormInput: FC<FormInputProps> = ({ id, value: initialValue, name, placeholder, onChange, isCurrency, isMany, isNumber, isPhoneNumber, isIdentification, needFocus, isDelay, error, touched, ...rest }) => {
  let value = initialValue;
  let setValue = null;
  const [changed, setChanged] = useState(false);
  if (isDelay) {
    [value, setValue] = useState(initialValue);
  }

  let fields = [];
  if (needFocus) {
    fields = useSelector(selectHighlightFields(name));
  }

  useEffect(() => {
    if (value !== initialValue && isDelay) {
      setValue(initialValue || '');
    }
  }, [initialValue]);

  const onFocus = () => {
    if (!needFocus) return;
    cancelHighlight();
    fields.forEach((i, index) => {
      highlightElement(i.name, i.document, index === 0);
    });
  };

  const onBlur = () => {
    if (!needFocus) return;
    cancelHighlight();
  };

  const handleChange = (v) => {
    if (isDelay) {
      setValue(v);
    } else {
      onChange(id, v);
    }
    setChanged(true);
  };

  useEffect(() => {
    if (changed && isDelay) {
      const timeoutAmount = isDelay ? 700 : 0;
      const timeOutId = setTimeout(() => onChange(id, value), timeoutAmount);
      return () => clearTimeout(timeOutId);
    }
    return () => null;
  }, [value]);

  let element = (
    <CustomFormInput
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value: v } }) => handleChange(v)}
      onClick={onFocus}
      onBlur={onBlur}
      invalid={error && touched}
      {...rest}
    />
  );
  if (isCurrency) {
    element = (
      <CurrencyInput
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={v => handleChange(v)}
        onClick={onFocus}
        onBlur={onBlur}
        invalid={error && touched}
        {...rest}
      />
    );
  }
  if (isNumber) {
    element = (
      <NumberInput
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={v => handleChange(v)}
        onClick={onFocus}
        onBlur={onBlur}
        invalid={error && touched}
        {...rest}
      />
    );
  }
  if (isMany && isPhoneNumber) {
    element = (
      <CustomFormInput
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={({ target: { value: v } }) => handleChange(getMaskingManyPhoneNumbers(v))}
        onClick={onFocus}
        onBlur={onBlur}
        invalid={error && touched}
        {...rest}
      />
    );
  } else if (isPhoneNumber) {
    element = (
      <CustomFormInput
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={({ target: { value: v } }) => handleChange(getMaskingPhoneNumber(v))}
        onClick={onFocus}
        onBlur={onBlur}
        invalid={error && touched}
        {...rest}
      />
    );
  }
  if (isIdentification) {
    element = (
      <CustomFormInput
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={({ target: { value: v } }) => handleChange(getMaskingIdentification(v))}
        onClick={onFocus}
        onBlur={onBlur}
        invalid={error && touched}
        {...rest}
      />
    );
  }
  return (
    <>
      {element}
      <FormFeedback>{error}</FormFeedback>
    </>
  );
};

export default FormInput;
