class ImageCarousel {
  container: HTMLDivElement;
  index: number;
  content: HTMLDivElement;
  slides: HTMLDivElement[];
  dotsContainer: HTMLDivElement;
  previousButton: HTMLButtonElement;
  nextButton: HTMLButtonElement;
  constructor(container: HTMLDivElement) {
    this.container = container;
    this.index = 0;
    this.content = this.container.querySelector('.hobbes_image-carousel-content');
    this.slides = Array.from(this.container.querySelectorAll('.hobbes_image-carousel-slide'));
    this.dotsContainer = this.container.querySelector('.hobbes_image-carousel-dots');
    this.previousButton = this.container.querySelector('.hobbes_image-carousel_previous');
    this.nextButton = this.container.querySelector('.hobbes_image-carousel_next');

    this.slides.forEach((slide, i) => {
      const dot = document.createElement('span');
      dot.classList.add('hobbes_image-carousel_dot');
      dot.addEventListener('click', () => this.moveToSlide(i));
      this.dotsContainer.appendChild(dot);
    });

    this.previousButton.addEventListener('click', () => {
      this.index = this.index > 0 ? this.index - 1 : 0;
      this.updateCarousel();
    });

    this.nextButton.addEventListener('click', () => {
      this.index = this.index < this.slides.length - 1 ? this.index + 1 : this.slides.length - 1;
      this.updateCarousel();
    });

    this.updateCarousel();
  }

  updateCarousel(): void {
    const transformValue = -(this.index * 100);
    this.content.style.transform = `translateX(${transformValue}%)`;

    const dots = this.dotsContainer.querySelectorAll('.hobbes_image-carousel_dot');
    dots.forEach((dot, i) => {
      dot.classList.toggle('active', i === this.index);
    });

    this.previousButton.style.display = this.index === 0 ? 'none' : '';
    this.nextButton.style.display = this.index === this.slides.length - 1 ? 'none' : '';
  }

  moveToSlide(i: number): void {
    this.index = i;
    this.updateCarousel();
  }
}

export default ImageCarousel;
