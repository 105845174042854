import { paths } from 'dwell/constants';
import { PromiseActionType } from 'dwell/store/types';
import { ActionType } from './action-types';

export default {
  getLabels: (): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.GET_EMAIL_LABELS_REQUEST,
        ActionType.GET_EMAIL_LABELS_SUCCESS,
        ActionType.GET_EMAIL_LABELS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.EMAIL_LABELS),
    },
  }),
};
