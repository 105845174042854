import produce from 'immer';
import { Action, ActionType, ScoredCallProps, CallRescoresMeta } from './action-types';
import { ActionType as PropertyActionType } from '../property/action-types';
import { CommonStateType } from '../types';

export interface ScoredCallsState extends CommonStateType {
  scoredCalls: ScoredCallProps[];
  scoredCall: ScoredCallProps;
  callRescoresMeta: CallRescoresMeta;
}

export const initialState: ScoredCallsState = {
  isSubmitting: false,
  errorMessage: null,
  scoredCalls: [],
  scoredCall: {} as ScoredCallProps,
  isLoaded: true,
  callRescoresMeta: {} as CallRescoresMeta,
};

export const selectCallRescoresMeta = (state: {scoredCalls: ScoredCallsState}): CallRescoresMeta => state.scoredCalls.callRescoresMeta;

export default produce((state: ScoredCallsState = initialState, action: Action): ScoredCallsState => {
  switch (action.type) {
    case ActionType.GET_SCORED_CALLS_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_SCORED_CALLS_SUCCESS:
      state.scoredCalls = action.result.data.results;
      state.isLoaded = true;
      break;
    case ActionType.GET_SCORED_CALLS_FAILURE:
      state.isLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.CREATE_SCORED_CALL_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_SCORED_CALL_SUCCESS:
      state.isSubmitting = false;
      break;
    case ActionType.CREATE_SCORED_CALL_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.UPDATE_SCORED_CALL_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_SCORED_CALL_SUCCESS:
      state.scoredCall = action.result.data;
      state.isSubmitting = false;
      break;
    case ActionType.UPDATE_SCORED_CALL_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.GET_CALL_RESCORES_META_SUCCESS:
      state.callRescoresMeta = action.result.data;
      break;
    case ActionType.GET_CALL_RESCORES_META_FAILURE:
      state.errorMessage = action.error.response?.status;
      break;

    case PropertyActionType.SUBMIT_CALLS_SCORE_STATE_SUCCESS:
      state.isSubmitting = false;
      state.callRescoresMeta = { ...state.callRescoresMeta, is_call_scoring_submitted_today: true };
      break;
  }

  return state;
});
