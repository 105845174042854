import { paths } from 'corporate/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, ListMediaParams, UpdateMediaParams, ListFilters, MediaParams } from './action-types';

export default {
  getPropertyListMedia: (params: ListMediaParams): Action => ({
    CALL_API: {
      types: [
        ActionType.LIST_PROPERTY_MEDIA_REQUEST,
        ActionType.LIST_PROPERTY_MEDIA_SUCCESS,
        ActionType.LIST_PROPERTY_MEDIA_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.MEDIA_LIBRARY.PROPERTY_MEDIA_LIBRARY, { params }),
    },
  }),
  getPropertyListLabels: (): Action => ({
    CALL_API: {
      types: [
        ActionType.LIST_PROPERTY_MEDIA_LABELS_REQUEST,
        ActionType.LIST_PROPERTY_MEDIA_LABELS_SUCCESS,
        ActionType.LIST_PROPERTY_MEDIA_LABELS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.MEDIA_LIBRARY.PROPERTY_MEDIA_LIBRARY_LIST_LABELS),
    },
  }),
  propertyCreateMedia: (params: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.ADD_PROPERTY_MEDIA_FILE_REQUEST,
        ActionType.ADD_PROPERTY_MEDIA_FILE_SUCCESS,
        ActionType.ADD_PROPERTY_MEDIA_FILE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.MEDIA_LIBRARY.PROPERTY_MEDIA_LIBRARY, params),
    },
  }),
  propertyUpdateMedia: (mediaId: number, params: UpdateMediaParams): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PROPERTY_MEDIA_FILE_REQUEST,
        ActionType.UPDATE_PROPERTY_MEDIA_FILE_SUCCESS,
        ActionType.UPDATE_PROPERTY_MEDIA_FILE_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.MEDIA_LIBRARY.PROPERTY_MEDIA_LIBRARY_ID, mediaId), params),
    },
  }),
  getCustomerListMedia: (params: ListMediaParams): Action => ({
    CALL_API: {
      types: [
        ActionType.LIST_CUSTOMER_MEDIA_REQUEST,
        ActionType.LIST_CUSTOMER_MEDIA_SUCCESS,
        ActionType.LIST_CUSTOMER_MEDIA_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.MEDIA_LIBRARY.CUSTOMER_MEDIA_LIBRARY, { params }),
    },
  }),
  getCustomerListLabels: (): Action => ({
    CALL_API: {
      types: [
        ActionType.LIST_CUSTOMER_MEDIA_LABELS_REQUEST,
        ActionType.LIST_CUSTOMER_MEDIA_LABELS_SUCCESS,
        ActionType.LIST_CUSTOMER_MEDIA_LABELS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.MEDIA_LIBRARY.CUSTOMER_MEDIA_LIBRARY_LIST_LABELS),
    },
  }),
  customerCreateMedia: (params: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.ADD_CUSTOMER_MEDIA_FILE_REQUEST,
        ActionType.ADD_CUSTOMER_MEDIA_FILE_SUCCESS,
        ActionType.ADD_CUSTOMER_MEDIA_FILE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.MEDIA_LIBRARY.CUSTOMER_MEDIA_LIBRARY, params),
    },
  }),
  customerUpdateMedia: (mediaId: number, params: UpdateMediaParams): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CUSTOMER_MEDIA_FILE_REQUEST,
        ActionType.UPDATE_CUSTOMER_MEDIA_FILE_SUCCESS,
        ActionType.UPDATE_CUSTOMER_MEDIA_FILE_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.MEDIA_LIBRARY.CUSTOMER_MEDIA_LIBRARY_ID, mediaId), params),
    },
  }),
  setToolbarNavTabMedia: (nav: 'ALL_MEDIA' | 'IMAGES' | 'VIDEO' | 'AUDIO' | 'DOCUMENTS'): Action => ({
    type: ActionType.SET_TOOLBAR_NAV_TAB_MEDIA,
    nav,
  }),
  setShowFormatTab: (format: 'MASONRY' | 'GRID' | 'LIST'): Action => ({
    type: ActionType.SET_SHOW_FORMAT,
    format,
  }),
  setCheckedFiles: (checkedFiles: MediaParams[]): Action => ({
    type: ActionType.SET_CHECKED_FILES,
    checkedFiles,
  }),
  resetMediaList: (mediaObjects: []): Action => ({
    type: ActionType.RESET_MEDIA_LIST,
    mediaObjects,
  }),
  setListFilters: (filters: ListFilters): Action => ({
    type: ActionType.SET_LIST_FILTERS,
    filters,
  }),
};
