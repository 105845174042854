import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, BulkDocuments, DocumentProps, DocumentState } from './action-types';

export const initialState: DocumentState = {
  errorMessage: null,
  documents: [] as DocumentProps[],
  bulkDocuments: { documents: [] as DocumentProps[], count: 0 } as BulkDocuments,
  isLoaded: false,
  isSubmitting: false,
  activeDocument: {},
};

export const selectBulkDocuments = (state: { document: DocumentState }): BulkDocuments => state.document.bulkDocuments;
export const selectDocuments = (state : { document: DocumentState }) : DocumentProps[] => state.document.documents;
export const selectDocumentIsLoaded = (state : { document: DocumentState }) : boolean => state.document.isLoaded;
export const selectDocumentIsSubmitting = (state : { document: DocumentState }) : boolean => state.document.isSubmitting;
export const selectActiveDocument = (state : { document: DocumentState }) : DocumentProps => state.document.activeDocument;

export default produce((state: DocumentState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_DOCUMENT_SUCCESS:
      state.documents = state.documents.concat([action.result.data]);
      break;

    case ActionType.GET_BULK_COUNTERSIGN_DOCUMENTS_SUCCESS:
      state.bulkDocuments = { count: action.result.data.count, documents: action.result.data.results };
      break;

    case ActionType.GET_DOCUMENTS_SUCCESS:
      state.documents = action.result.data.results;
      break;

    case ActionType.GET_ALL_DOCUMENTS_SUCCESS:
      state.documents = action.result.data.results;
      break;

    case ActionType.UPLOAD_DOCUMENTS_SUCCESS:
      state.documents = action.result.data;
      break;

    case ActionType.SET_ACTIVE_DOCUMENT:
      state.activeDocument = action.value;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
