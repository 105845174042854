import styled from 'styled-components';

export const Label = styled.p`
  color: ${props => props.theme.colors.secondary};
  // margin-bottom: 10px !important;
`;

export const InfoContainer = styled.div`
  color: ${props => props.theme.colors.secondary};
`;

export const Link = styled.a`
  color: ${props => props.theme.colors.secondary};
`;
