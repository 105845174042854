import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, EmployerState, EmployerProps } from './action-types';

export const defaultFormValue = {
  employer_name: 'Employer',
  monthly_income: null,
  position: '',
  employment_start_date: null,
  supervisor_phone_type: 'MOBILE',
  supervisor_name: '',
  supervisor_email: '',
  pay_stub1: null,
  pay_stub1_name: null,
  pay_stub2: null,
  pay_stub2_name: null,
  contact_info_type: 'EMAIL',
  country: 'UNITED_STATES',
  income_type: 'GROSS',
};

export const initialState: EmployerState = {
  employers: [defaultFormValue] as EmployerProps[],
  errorMessage: null,
  incomeSectionEmployers: [] as EmployerProps[],
  isLoaded: false,
};

const formatFormValues = (employers: EmployerProps[]): EmployerProps[] => employers.map((item) => {
  let contactInfoType = 'EMAIL';
  if (item.supervisor_phone_number) contactInfoType = 'PHONE';
  if (item.supervisor_email) contactInfoType = 'EMAIL';
  return { ...item, contact_info_type: contactInfoType };
});

export const selectEmployers = (state: { employer: EmployerState }) : EmployerProps[] => state.employer.employers;
export const selectIncomeSectionEmployers = (state: { employer: EmployerState }) : EmployerProps[] => state.employer.incomeSectionEmployers;
export const selectIsLoaded = (state: { employer: EmployerState}): boolean => state.employer.isLoaded;

export default produce((state: EmployerState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_EMPLOYERS_SUCCESS:
      state.employers = formatFormValues(action.result.data.results);
      break;

    case ActionType.GET_INCOME_SECTION_EMPLOYERS_FAILURE:
      state.incomeSectionEmployers = formatFormValues(action.result.data.results);
      break;

    case ActionType.RESET_EMPLOYERS:
      state.employers = [];
      break;

    case ActionType.UPDATE_EMPLOYERS_SUCCESS:
      state.employers = formatFormValues(action.result.data);
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
