import application from './application/reducers';
import screening from './screening/reducers';
import people from './people/reducers';

export type ApplicationStateType = {
  application: ReturnType<typeof application>,
  screening: ReturnType<typeof screening>,
  people: ReturnType<typeof people>,
};

export default {
  application,
  screening,
  people,
};
