import React, { FC } from 'react';

import { CustomerProps } from 'site/store/customer/action-types';

import { PropertyItem, PropertyItemBody } from 'containers/multi_property_selector/styles';

interface CustomerItemProps {
  customer: CustomerProps;
  setCurrentCustomer: React.Dispatch<React.SetStateAction<CustomerProps>>;
  setToggleTab: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerItem: FC<CustomerItemProps> = ({ customer, setCurrentCustomer, setToggleTab }) => {
  const selectCustomer = () => {
    setCurrentCustomer(customer);
    setToggleTab(false);
  };

  return (
    <PropertyItem customers onClick={selectCustomer}>
      <PropertyItemBody>
        <h6>{customer.customer_name}</h6>
        <p>{customer.active_properties} Properties</p>
      </PropertyItemBody>
    </PropertyItem>
  );
};

export default CustomerItem;
