import { paths } from 'site/constants';
import { ActionType, Action, TooltipData } from './action-types';

export default {
  getTooltipItems: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_TOOLTIP_ITEMS_REQUEST,
        ActionType.GET_TOOLTIP_ITEMS_SUCCESS,
        ActionType.GET_TOOLTIP_ITEMS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.TOOLTIP_ITEMS),
    },
  }),

  updateTooltipItem: (data: TooltipData): Action => ({
    CALL_API: {
      types: [
        ActionType.TOOLTIP_ITEM_UPDATE_REQUEST,
        ActionType.TOOLTIP_ITEM_UPDATE_SUCCESS,
        ActionType.TOOLTIP_ITEM_UPDATE_FAILURE,
      ],
      promise: client => client.put(paths.api.v1.TOOLTIP_ITEMS, data),
    },
  }),
};
