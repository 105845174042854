import { paths } from 'dwell/constants';
import { CallBackFunction } from 'src/interfaces';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, SMSTemplateProps } from './action-types';

export default {
  getSMSTemplates: (property?: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SMS_TEMPLATES_REQUEST,
        ActionType.GET_SMS_TEMPLATES_SUCCESS,
        ActionType.GET_SMS_TEMPLATES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.SMS_TEMPLATES, { params: { property, show_all: true } }),
    },
  }),
  createSMSTemplate: (data: SMSTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_SMS_TEMPLATE_REQUEST,
        ActionType.CREATE_SMS_TEMPLATE_SUCCESS,
        ActionType.CREATE_SMS_TEMPLATE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.SMS_TEMPLATES, data),
      successCB,
    },
  }),
  updateSMSTemplateById: (id: number, data: SMSTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SMS_TEMPLATE_REQUEST,
        ActionType.UPDATE_SMS_TEMPLATE_SUCCESS,
        ActionType.UPDATE_SMS_TEMPLATE_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.SMS_TEMPLATE_DETAILS, id), data),
      successCB,
    },
  }),
  deleteSMSTemplateById: (id: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_SMS_TEMPLATE_REQUEST,
        ActionType.DELETE_SMS_TEMPLATE_SUCCESS,
        ActionType.DELETE_SMS_TEMPLATE_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.SMS_TEMPLATE_DETAILS, id)),
      successCB,
    },
  }),
};
