import produce from 'immer';
import { Action, ActionType, ChatReportStats, ChatReportConversation, ChatReportConversationData, ChatReportConversationMessageWithStatus } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface ChatReportState extends CommonStateType {
  chats_list: ChatReportStats[];
  chats: ChatReportConversation[];
  chat: ChatReportConversation;
  current_messages: ChatReportConversationData[];
  data: ChatReportStats;
  isExported: boolean;
  isChatLoaded: boolean;
  selected_chat_messages: ChatReportConversationMessageWithStatus[];
}

export const initialState: ChatReportState = {
  isSubmitting: false,
  errorMessage: '',
  chats_list: [],
  chats: [],
  current_messages: [],
  data: {} as ChatReportStats,
  chat: { id: 0, reviewed: false },
  isLoaded: false,
  isExported: true,
  isChatLoaded: true,
  selected_chat_messages: [],
};

export default produce((state: ChatReportState = initialState, action: Action): ChatReportState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_CHAT_REPORTS_SUCCESS:
      state.chats_list = action.result.data.results;
      break;

    case ActionType.GET_SINGLE_CHAT_REPORT_SUCCESS:
      state.data = action.result.data;
      break;

    case ActionType.GET_CHAT_REPORT_BY_ID_SUCCESS:
      state.chat = action.result.data;
      break;

    case ActionType.EXPORT_CHAT_REPORT_BY_ID_REQUEST:
      state.isExported = false;
      break;
    case ActionType.EXPORT_CHAT_REPORT_BY_ID_SUCCESS:
      state.isExported = true;
      state.chats = action.result.data.results;
      break;
    case ActionType.EXPORT_CHAT_REPORT_BY_ID_FAILURE:
      state.isExported = false;
      break;

    case ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_REQUEST:
      state.chats = [];
      state.data = null;
      break;
    case ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS:
      state.chats = action.result.data.results;
      break;

    case ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_REQUEST:
      state.chat = {} as ChatReportConversation;
      state.isChatLoaded = false;
      break;
    case ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS:
      state.isChatLoaded = true;
      state.selected_chat_messages = action.result.data;
      break;
    case ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_FAILURE:
      state.isChatLoaded = true;
      break;
  }

  return state;
});
