import { api, build } from 'leasing/constants/paths';
import { PetProps } from 'leasing/store/pet/action-types';
import { Action, ActionType, VehicleProps } from './action-types';

export default {
  createLeadVehicles: (leadId: number, params: VehicleProps[], removeIds = []): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_LEAD_VEHICLES_REQUEST,
        ActionType.CREATE_LEAD_VEHICLES_SUCCESS,
        ActionType.CREATE_LEAD_VEHICLES_FAILURE,
      ],
      promise: client => client.post(build(api.v1.LEAD_VEHICLES_SAVE, leadId), { vehicles: params, removeIds }),
    },
  }),

  createVehicle: (leaseId: number, body: VehicleProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_VEHICLE_REQUEST,
        ActionType.CREATE_VEHICLE_SUCCESS,
        ActionType.CREATE_VEHICLE_FAILURE,
      ],
      promise: client => client.post(build(api.v1.VEHICLES, leaseId), body),
    },
  }),

  deleteLeadVehicle: (leadId: number, vehicleId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_LEAD_VEHICLE_REQUEST,
        ActionType.DELETE_LEAD_VEHICLE_SUCCESS,
        ActionType.DELETE_LEAD_VEHICLE_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.LEAD_VEHICLE_DETAILS, leadId, vehicleId)),
    },
  }),

  deleteVehicle: (leaseId: number, vehicleId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_VEHICLE_REQUEST,
        ActionType.DELETE_VEHICLE_SUCCESS,
        ActionType.DELETE_VEHICLE_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.VEHICLE_DETAILS, leaseId, vehicleId)),
    },
  }),

  getLeadVehicles: (leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_VEHICLES_REQUEST,
        ActionType.GET_LEAD_VEHICLES_SUCCESS,
        ActionType.GET_LEAD_VEHICLES_FAILURE,
      ],
      promise: client => client.get(build(api.v1.LEAD_VEHICLES, leadId)),
    },
  }),

  getVehicleMakes: (params: { year: number }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_VEHICLE_MAKES_REQUEST,
        ActionType.GET_VEHICLE_MAKES_SUCCESS,
        ActionType.GET_VEHICLE_MAKES_FAILURE,
      ],
      promise: client => client.get(api.v1.VEHICLE_MAKES, { params }),
    },
  }),

  getVehicleModels: (params: { year: number, make: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_VEHICLE_MODELS_REQUEST,
        ActionType.GET_VEHICLE_MODELS_SUCCESS,
        ActionType.GET_VEHICLE_MODELS_FAILURE,
      ],
      promise: client => client.get(api.v1.VEHICLE_MODELS, { params }),
    },
  }),

  getVehicleYears: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_VEHICLE_YEARS_REQUEST,
        ActionType.GET_VEHICLE_YEARS_SUCCESS,
        ActionType.GET_VEHICLE_YEARS_FAILURE,
      ],
      promise: client => client.get(api.v1.VEHICLE_YEARS),
    },
  }),

  updateLeadVehicle: (leadId: number, vehicleId: number, body: PetProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_LEAD_VEHICLE_REQUEST,
        ActionType.UPDATE_LEAD_VEHICLE_SUCCESS,
        ActionType.UPDATE_LEAD_VEHICLE_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.LEAD_VEHICLE_DETAILS, leadId, vehicleId), body),
    },
  }),

  updateVehicle: (leaseId: number, vehicleId: number, params: VehicleProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_VEHICLE_REQUEST,
        ActionType.UPDATE_VEHICLE_SUCCESS,
        ActionType.UPDATE_VEHICLE_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.VEHICLE_DETAILS, leaseId, vehicleId), params),
    },
  }),
};
