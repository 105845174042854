import isEqual from 'lodash/isEqual';

export default {
  SITE_ONLY: 'SITE_ONLY',
  DWELL_ONLY: 'DWELL_ONLY',
  BOTH: 'BOTH', // we can delete this once the "platform" field is removed and we're only using "platforms"
  CREDIT_BUILDER: 'CREDIT_BUILDER',
  isSiteOnly: (platforms: string[]): boolean => isEqual(platforms, ['SITE']),
  isDwellOnly: (platforms: string[]): boolean => isEqual(platforms, ['DWELL']),
  isCreditBuilder: (platforms: string[]): boolean => isEqual(platforms, ['CREDIT_BUILDER']),
  isBoth: (platforms: string[]): boolean => isEqual(platforms, ['SITE', 'DWELL'] || isEqual(platforms, ['DWELL', 'SITE'])),
  hasPlatforms: (platforms: string[]): boolean => platforms.length > 0,
};
