import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  CREATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST = 'CREATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST',
  CREATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS = 'CREATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS',
  CREATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE = 'CREATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE',

  CREATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST = 'CREATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST',
  CREATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS = 'CREATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS',
  CREATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE = 'CREATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE',

  CREATE_RENTABLE_ITEM_REQUEST = 'CREATE_RENTABLE_ITEM_REQUEST',
  CREATE_RENTABLE_ITEM_SUCCESS = 'CREATE_RENTABLE_ITEM_SUCCESS',
  CREATE_RENTABLE_ITEM_FAILURE = 'CREATE_RENTABLE_ITEM_FAILURE',

  GET_RENTABLE_ITEMS_REQUEST = 'GET_RENTABLE_ITEMS_REQUEST',
  GET_RENTABLE_ITEMS_SUCCESS = 'GET_RENTABLE_ITEMS_SUCCESS',
  GET_RENTABLE_ITEMS_FAILURE = 'GET_RENTABLE_ITEMS_FAILURE',

  DELETE_OTHER_FEE_ITEM_TEMPLATE_REQUEST = 'DELETE_OTHER_FEE_ITEM_TEMPLATE_REQUEST',
  DELETE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS = 'DELETE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS',
  DELETE_OTHER_FEE_ITEM_TEMPLATE_FAILURE = 'DELETE_OTHER_FEE_ITEM_TEMPLATE_FAILURE',

  DELETE_OTHER_RENT_ITEM_TEMPLATE_REQUEST = 'DELETE_OTHER_RENT_ITEM_TEMPLATE_REQUEST',
  DELETE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS = 'DELETE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS',
  DELETE_OTHER_RENT_ITEM_TEMPLATE_FAILURE = 'DELETE_OTHER_RENT_ITEM_TEMPLATE_FAILURE',

  DELETE_RENTABLE_ITEM_REQUEST = 'DELETE_RENTABLE_ITEM_REQUEST',
  DELETE_RENTABLE_ITEM_SUCCESS = 'DELETE_RENTABLE_ITEM_SUCCESS',
  DELETE_RENTABLE_ITEM_FAILURE = 'DELETE_RENTABLE_ITEM_FAILURE',

  GET_OTHER_FEE_ITEM_TEMPLATE_REQUEST = 'GET_OTHER_FEE_ITEM_TEMPLATE_REQUEST',
  GET_OTHER_FEE_ITEM_TEMPLATE_SUCCESS = 'GET_OTHER_FEE_ITEM_TEMPLATE_SUCCESS',
  GET_OTHER_FEE_ITEM_TEMPLATE_FAILURE = 'GET_OTHER_FEE_ITEM_TEMPLATE_REQUEST',

  GET_OTHER_RENT_ITEM_TEMPLATE_REQUEST = 'GET_OTHER_RENT_ITEM_TEMPLATE_REQUEST',
  GET_OTHER_RENT_ITEM_TEMPLATE_SUCCESS = 'GET_OTHER_RENT_ITEM_TEMPLATE_SUCCESS',
  GET_OTHER_RENT_ITEM_TEMPLATE_FAILURE = 'GET_OTHER_RENT_ITEM_TEMPLATE_REQUEST',

  GET_ADDENDA_FIELDS_REQUEST = 'GET_ADDENDA_FIELDS_REQUEST',
  GET_ADDENDA_FIELDS_SUCCESS = 'GET_ADDENDA_FIELDS_SUCCESS',
  GET_ADDENDA_FIELDS_FAILURE = 'GET_ADDENDA_FIELDS_FAILURE',

  GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS',

  SAVE_ADDENDA_FIELDS_REQUEST = 'SAVE_ADDENDA_FIELDS_REQUEST',
  SAVE_ADDENDA_FIELDS_SUCCESS = 'SAVE_ADDENDA_FIELDS_SUCCESS',
  SAVE_ADDENDA_FIELDS_FAILURE = 'SAVE_ADDENDA_FIELDS_FAILURE',

  SAVE_DURATION_PRICING_REQUEST = 'SAVE_DURATION_PRICING_REQUEST',
  SAVE_DURATION_PRICING_SUCCESS = 'SAVE_DURATION_PRICING_SUCCESS',
  SAVE_DURATION_PRICING_FAILURE = 'SAVE_DURATION_PRICING_FAILURE',

  SAVE_LEASE_DEFAULT_REQUEST = 'SAVE_LEASE_DEFAULT_REQUEST',
  SAVE_LEASE_DEFAULT_SUCCESS = 'SAVE_LEASE_DEFAULT_SUCCESS',
  SAVE_LEASE_DEFAULT_FAILURE = 'SAVE_LEASE_DEFAULT_FAILURE',

  SAVE_PROPERTY_POLICY_REQUEST = 'SAVE_PROPERTY_POLICY_REQUEST',
  SAVE_PROPERTY_POLICY_SUCCESS = 'SAVE_PROPERTY_POLICY_SUCCESS',
  SAVE_PROPERTY_POLICY_FAILURE = 'SAVE_PROPERTY_POLICY_FAILURE',

  SAVE_UTILITY_POLICY_REQUEST = 'SAVE_UTILITY_POLICY_REQUEST',
  SAVE_UTILITY_POLICY_SUCCESS = 'SAVE_UTILITY_POLICY_SUCCESS',
  SAVE_UTILITY_POLICY_FAILURE = 'SAVE_UTILITY_POLICY_FAILURE',

  UPDATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST = 'UPDATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST',
  UPDATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS = 'UPDATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS',
  UPDATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE = 'UPDATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE',

  UPDATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST = 'UPDATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST',
  UPDATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS = 'UPDATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS',
  UPDATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE = 'UPDATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE',

  UPDATE_RENTABLE_ITEM_REQUEST = 'UPDATE_RENTABLE_ITEM_REQUEST',
  UPDATE_RENTABLE_ITEM_SUCCESS = 'UPDATE_RENTABLE_ITEM_SUCCESS',
  UPDATE_RENTABLE_ITEM_FAILURE = 'UPDATE_RENTABLE_ITEM_FAILURE',

  SAVE_CLEANING_CHARGES_REQUEST = 'SAVE_CLEANING_CHARGES_REQUEST',
  SAVE_CLEANING_CHARGES_SUCCESS = 'SAVE_CLEANING_CHARGES_SUCCESS',
  SAVE_CLEANING_CHARGES_FAILURE = 'SAVE_CLEANING_CHARGES_FAILURE',
}

export interface AddendaFieldProps {
  business_association_name: string,
  business_association_state: string,
  bicycle_rent_amount: number,
  bicycle_rent_period: string,
  bicycle_maximum_rental_period: string,
  bicycle_daily_late_return_fee: number,
  bicycle_damage_or_lost_fee: number,
  sustainability_manager_name: string,
  sustainability_manager_email_address: string,
  sustainability_contact_number: string,
  city_association_name: string,
  homeowner_association_name: string,
  max_number_of_movies_checked_out: number,
  return_movies_within_days: number,
  late_charges_start_after_days: number,
  movie_late_fee_per_day: number,
  movie_considered_non_returned_days: number,
  non_returned_movie_fee: number,
  damaged_movie_fee: number,
  notice_of_new_sales_tax_days: number,
  pet_waste_test_n_collection_fee: number,
  property_damage_liability_rent: number,
  property_damage_liability_coverage: number,
  direct_payment_exceeds_amount: number,
  terminate_ach_notice_period_days: number,
  bike_storage_rent: number,
  bike_storage_late_fee: number,
  late_fee_imposed_on: number,
  wifi_internet_service_included: boolean,
  internet_vendor: string,
  other_internet_vendor: string,
  modem_replacement_cost: number,
  smart_home_technology_included: boolean,
  smart_home_vendor: string,
  other_smart_home_vendor: string,
  trash_service_included: boolean,
  trash_vendor: string,
  other_trash_vendor: string,
  package_service_included: boolean,
  package_vendor: string,
  other_package_vendor: string,
  group_fitness_classes_included: boolean,
  fitness_vendor: string,
  other_fitness_vendor: string,
  parking_reservation_services_included: boolean,
  parking_vendor: string,
  other_parking_vendor: string,
  tv_services_included: boolean,
  tv_vendor: string,
  other_tv_vendor: string,
  trash_service_days: string[],
  bagged_trash_outside_allowable_hours_start: string,
  bagged_trash_outside_allowable_hours_end: string,
  trash_service_begins_time: string,
  trash_container_inside_by_time: string,
  credit_builder_program_cost: number,
}

export interface DurationPricingProps {
  id?: number,
  active_lease_terms: LeaseTermsOptions[] | number[],
  avg_turnover_costs: number,
  avg_turnover_time: number,
  base_rent_measurement: 'FIXED' | 'PERCENT',
  base_rent_type: 'PLUS' | 'NO_PREMIUM' | 'MINUS',
  base_rent: number,
  is_offer_discounts: boolean,
  is_offer_month_to_month: boolean,
  pricing_term: 'DYNAMIC' | 'MANUAL',
  term_premiums: TermPremiumProps[],
}

export interface LeaseDefaultProps {
  id: number,
  apartment_transfer_fee_mode?: string,
  apartment_transfer_fee?: number,
  approved_non_refundable_premium_fee?: number,
  approved_security_deposit: string,
  community_manager_name?: string,
  conditionally_approved_non_refundable_premium_fee?: number,
  conditionally_approved_security_deposit?: number,
  dishonored_funds_charge?: number,
  document_update_change?: number,
  early_termination_fee_mode?: string,
  early_termination_fee?: number,
  electric_company_name?: string,
  electric_company_phone_number?: string,
  electric_company_website?: string,
  facilities_fee?: number,
  facilities_late_fee?: number,
  fob_replacement_fee?: number,
  gas_company_name?: string,
  gas_company_phone_number?: string,
  gas_company_website?: string,
  insurance_coverage_minimum?: number,
  insurance_violation_fee?: number,
  late_charges_after_days?: number,
  late_charges_mode?: string,
  late_charges_per_day?: number,
  late_charges?: number,
  lease_breach_fee?: number,
  management_fax_number?: string,
  management_office_address?: string,
  management_office_phone?: string,
  month_to_month_fee_mode?: string,
  month_to_month_fee?: number,
  no_lease_first_violation_fee?: number,
  no_lease_subsequent_violation_fee?: number,
  non_refundable_administration_fee?: string,
  pet_waste_first_violation_fee?: number,
  pet_waste_subsequent_violation_fee?: number,
  replacement_key_fee?: number,
  sales_tax?: number,
  towing_company_name?: string,
  towing_company_phone_number?: string,
  towing_company_website?: string,
  trash_container_replacement_fee?: number,
  trash_left_out_fee?: number,
  unauthorized_pet_fee?: number,
  unlock_after_hours_fee?: number,
  usd_fee?: number,
}

export interface LeaseTermsOptions {
  name: string,
  value: number,
}

export interface OtherFeeItemTemplateProps {
  id?: number,
  fee: number,
  name: string,
}

export interface OtherRentItemTemplateProps {
  id?: number,
  deposit: number,
  fee: number,
  name: string,
  rent: number,
}

export interface PropertyPolicyProps {
  acceptable_forms_of_payment?: string[],
  additional_fob_refundable_deposit?: string,
  apartment_ceiling_height?: string,
  apartment_hold_expiration?: string,
  bird_breed_restrictions?: string,
  cat_breed_restrictions?: string,
  checks_paid_to?: string,
  club_house_alarm_fee?: string,
  club_house_hours_24_hr?: boolean,
  club_house_hours_end_time?: string,
  club_house_hours_start_time?: string,
  community_quiet_hours_24_hr?: boolean,
  community_quiet_hours_end_time?: string,
  community_quiet_hours_start_time?: string,
  dog_breed_restrictions?: string,
  elevator_violation_fee?: number,
  fitness_center_hours_24_hr?: boolean,
  fitness_center_hours_end_time?: string,
  fitness_center_hours_start_time?: string,
  fitness_center_key_deposit?: string,
  garage_door_opener_replacement_fee?: string,
  garage_door_reprogramming_fee?: string,
  guest_parking_is_allowed?: boolean,
  guest_parking_type?: string,
  household_income_times?: string,
  ideal_credit_score?: number,
  is_birds_acceptable?: boolean,
  is_cats_acceptable?: boolean,
  is_cosigners_allowed?: boolean,
  is_dogs_acceptable?: boolean,
  lease_break_days?: number,
  max_guarantors_allowed?: string | number,
  max_one_bedroom_occupants?: string,
  max_pets_for_one_leaseholder?: string,
  max_pets_for_two_leaseholders?: number,
  max_pets_for_three_leaseholders?: number,
  max_pets_for_four_leaseholders?: number,
  max_pets_per_unit?: number,
  max_pets_policy_mode?: string,
  max_studio_occupants?: string,
  max_three_bedrooms_occupants?: string,
  max_two_bedrooms_occupants?: string,
  max_vehicles_for_one_bedroom?: string,
  max_vehicles_for_studio?: string,
  max_vehicles_for_three_bedrooms?: string,
  max_vehicles_for_two_bedrooms?: string,
  max_vehicles_policy_mode?: string,
  minimum_credit_score?: number,
  moving_hours_24_hr?: boolean,
  moving_hours_end_time?: string,
  moving_hours_start_time?: string,
  mt_program_cost_monthly?: number,
  mt_program_opt_out?: boolean,
  notice_to_vacate_month_to_month_days?: string,
  notice_to_vacate_prior_days?: string,
  overnight_guest_stay_limit?: number,
  parking_id_replacement_fee?: string,
  parking_permit_rent?: string,
  parking_rent?: string,
  past_allowable_days_applicants_move_in?: string,
  pest_control_service_day?: string,
  pool_hours_24_hr?: boolean,
  pool_hours_end_time?: string,
  pool_hours_start_time?: string,
  prorated_after_day_of_month?: string,
  renewal_period?: string,
  requirements_to_hold_unit?: string,
  resident_parking_type?: string,
  smoking_allowed?: boolean,
  smoking_policy_disclaimer?: string,
  utilities?: string[],
  waitlist_fee?: string,
}

export interface RentableItemProps {
  id?: number,
  deposit?: string,
  description?: string,
  fee?: string,
  image?: string,
  monthly_rent?: string,
  name?: string,
  rent_available?: boolean,
  type?: string,
}

export interface TermPremiumProps {
  base_rent_measurement: 'FIXED' | 'PERCENT',
  base_rent_type: 'PLUS' | 'NO_PREMIUM' | 'MINUS',
  base_rent: number,
  month: number,
}

export interface UtilityPoliciesProps {
  apartment_electric_billing_description?: string,
  apartment_electric_billing_method?: string,
  apartment_electric_charged?: boolean,
  apartment_gas_billing_description?: string,
  apartment_gas_billing_method?: string,
  apartment_gas_charged?: boolean,
  avg_monthly_utility_bill?: number,
  common_electric_billing_description?: string,
  common_electric_billing_method?: string,
  common_electric_charged?: boolean,
  common_gas_billing_description?: string,
  common_gas_billing_method?: string,
  common_gas_charged?: boolean,
  common_sewer_billing_description?: string,
  common_sewer_billing_method?: string,
  common_sewer_charged?: boolean,
  common_utilities_billing_description?: string,
  common_utilities_billing_method?: string,
  common_utilities_charged?: boolean,
  common_water_billing_description?: string,
  common_water_billing_method?: string,
  common_water_charged?: boolean,
  pest_control_billing_description?: string,
  pest_control_billing_method?: string,
  pest_control_charged?: boolean,
  resident_portal_url?: string,
  sewer_billing_description?: string,
  sewer_billing_method?: string,
  sewer_charged?: boolean,
  storm_water_billing_description?: string,
  storm_water_billing_method?: string,
  storm_water_charged?: boolean,
  trash_billing_description?: string,
  trash_billing_method?: string,
  trash_charged?: boolean,
  utilities?: string[],
  utility_billing_account_lapsed_fee?: number,
  utility_billing_account_setup_fee?: number,
  utility_billing_company_full_address?: string,
  utility_billing_company_phone_number?: string,
  utility_billing_company_questions_email?: string,
  utility_billing_company?: string,
  utility_billing_service_fee_monthly?: number,
  utility_policy_changes_notice_days?: number,
  water_billing_description?: string,
  water_billing_method?: string,
  water_charged?: boolean,
}

export interface CleaningChargeItem {
  id?: number,
  category: string,
  name: string,
  light: number | string,
  heavy: number | string,
  billed_as_hourly_rate: boolean,
}

export interface CleaningChargesProps {
  [key: string]: CleaningChargeItem[],
}

export interface SettingState extends CommonStateType {
  addendaFields: AddendaFieldProps,
  durationPricing: DurationPricingProps,
  leaseDefault: LeaseDefaultProps,
  otherFeeItems: OtherFeeItemTemplateProps[],
  otherRentItems: OtherRentItemTemplateProps[],
  propertyPolicy: PropertyPolicyProps,
  rentableItems: RentableItemProps[],
  utilityPolicies: UtilityPoliciesProps,
  cleaningCharges: CleaningChargesProps,
}

/*
 * Actions
 */

interface CreateOtherFeeItemAction extends CommonActionResponse {
  type: ActionType.CREATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST
  | ActionType.CREATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS
  | ActionType.CREATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
  result: { data: OtherFeeItemTemplateProps },
}

interface CreateOtherRentItemAction extends CommonActionResponse {
  type: ActionType.CREATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST
  | ActionType.CREATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS
  | ActionType.CREATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
  result: { data: OtherRentItemTemplateProps },
}

interface CreateRentableItemAction extends CommonActionResponse {
  type: ActionType.CREATE_RENTABLE_ITEM_REQUEST
  | ActionType.CREATE_RENTABLE_ITEM_SUCCESS
  | ActionType.CREATE_RENTABLE_ITEM_FAILURE;
  result: { data: RentableItemProps };
}

interface DeleteOtherFeeItemAction extends CommonActionResponse {
  payload: number,
  type: ActionType.DELETE_OTHER_FEE_ITEM_TEMPLATE_REQUEST
  | ActionType.DELETE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS
  | ActionType.DELETE_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
  result: { data: OtherFeeItemTemplateProps },
}

interface DeleteOtherRentItemAction extends CommonActionResponse {
  payload: number,
  type: ActionType.DELETE_OTHER_RENT_ITEM_TEMPLATE_REQUEST
  | ActionType.DELETE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS
  | ActionType.DELETE_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
  result: { data: OtherRentItemTemplateProps },
}

interface DeleteRentableItemAction extends CommonActionResponse {
  type: ActionType.DELETE_RENTABLE_ITEM_REQUEST
  | ActionType.DELETE_RENTABLE_ITEM_SUCCESS
  | ActionType.DELETE_RENTABLE_ITEM_FAILURE;
  result: { data: RentableItemProps };
}

interface GetOtherFeeItemsAction extends CommonActionResponse {
  type: ActionType.GET_OTHER_FEE_ITEM_TEMPLATE_REQUEST
  | ActionType.GET_OTHER_FEE_ITEM_TEMPLATE_SUCCESS
  | ActionType.GET_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
  result: { data: { results: OtherFeeItemTemplateProps[] } },
}

interface GetOtherRentItemsAction extends CommonActionResponse {
  type: ActionType.GET_OTHER_RENT_ITEM_TEMPLATE_REQUEST
  | ActionType.GET_OTHER_RENT_ITEM_TEMPLATE_SUCCESS
  | ActionType.GET_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
  result: { data: { results: OtherRentItemTemplateProps[] } },
}

interface GetAddendaFieldsAction extends CommonActionResponse {
  type: ActionType.GET_ADDENDA_FIELDS_REQUEST
  | ActionType.GET_ADDENDA_FIELDS_SUCCESS
  | ActionType.GET_ADDENDA_FIELDS_FAILURE,
  result: { data: AddendaFieldProps },
}

interface GetPropertyDetailsAction extends CommonActionResponse {
  type: ActionType.GET_PROPERTY_DETAILS_SUCCESS;
  result: {
    data: {
      duration_pricing: DurationPricingProps,
      lease_default: LeaseDefaultProps,
      polices: PropertyPolicyProps,
      rentable_items: RentableItemProps[],
      utility_policies: UtilityPoliciesProps,
      cleaning_charges: CleaningChargeItem[],
    }
  };
}

interface GetRentableItemsAction extends CommonActionResponse {
  type: ActionType.GET_RENTABLE_ITEMS_REQUEST
  | ActionType.GET_RENTABLE_ITEMS_SUCCESS
  | ActionType.GET_RENTABLE_ITEMS_FAILURE;
  result: { data: { results: RentableItemProps[] } };
}

interface SaveAddendaFields extends CommonActionResponse {
  type: ActionType.SAVE_ADDENDA_FIELDS_REQUEST
  | ActionType.SAVE_ADDENDA_FIELDS_SUCCESS
  | ActionType.SAVE_ADDENDA_FIELDS_FAILURE,
  result: { data: AddendaFieldProps },
}

interface SaveDurationPricingAction extends CommonActionResponse {
  type: ActionType.SAVE_DURATION_PRICING_REQUEST
  | ActionType.SAVE_DURATION_PRICING_SUCCESS
  | ActionType.SAVE_DURATION_PRICING_FAILURE;
  result: { data: DurationPricingProps };
}

interface SaveLeaseDefaultAction extends CommonActionResponse {
  type: ActionType.SAVE_LEASE_DEFAULT_REQUEST
  | ActionType.SAVE_LEASE_DEFAULT_SUCCESS
  | ActionType.SAVE_LEASE_DEFAULT_FAILURE;
  result: { data: LeaseDefaultProps };
}

interface SavePropertyPolicyAction extends CommonActionResponse {
  type: ActionType.SAVE_PROPERTY_POLICY_REQUEST
  | ActionType.SAVE_PROPERTY_POLICY_SUCCESS
  | ActionType.SAVE_PROPERTY_POLICY_FAILURE;
  result: { data: PropertyPolicyProps };
}

interface SaveUtilityPolicyAction extends CommonActionResponse {
  type: ActionType.SAVE_UTILITY_POLICY_REQUEST
  | ActionType.SAVE_UTILITY_POLICY_SUCCESS
  | ActionType.SAVE_UTILITY_POLICY_FAILURE;
  result: { data: UtilityPoliciesProps };
}

interface UpdateOtherFeeItemAction extends CommonActionResponse {
  type: ActionType.UPDATE_OTHER_FEE_ITEM_TEMPLATE_REQUEST
  | ActionType.UPDATE_OTHER_FEE_ITEM_TEMPLATE_SUCCESS
  | ActionType.UPDATE_OTHER_FEE_ITEM_TEMPLATE_FAILURE,
  result: { data: OtherFeeItemTemplateProps },
}

interface UpdateOtherRentItemAction extends CommonActionResponse {
  type: ActionType.UPDATE_OTHER_RENT_ITEM_TEMPLATE_REQUEST
  | ActionType.UPDATE_OTHER_RENT_ITEM_TEMPLATE_SUCCESS
  | ActionType.UPDATE_OTHER_RENT_ITEM_TEMPLATE_FAILURE,
  result: { data: OtherRentItemTemplateProps },
}

interface UpdateRentableItemAction extends CommonActionResponse {
  type: ActionType.UPDATE_RENTABLE_ITEM_REQUEST
  | ActionType.UPDATE_RENTABLE_ITEM_SUCCESS
  | ActionType.UPDATE_RENTABLE_ITEM_FAILURE;
  result: { data: RentableItemProps };
}
interface SaveCleaningChargesAction extends CommonActionResponse {
  type: ActionType.SAVE_CLEANING_CHARGES_REQUEST
  | ActionType.SAVE_CLEANING_CHARGES_SUCCESS
  | ActionType.SAVE_CLEANING_CHARGES_FAILURE;
  result: { data: CleaningChargeItem[] };
}

export type Action =
  PromiseActionType
  | CreateOtherFeeItemAction
  | CreateOtherRentItemAction
  | CreateRentableItemAction
  | DeleteOtherFeeItemAction
  | DeleteOtherRentItemAction
  | DeleteRentableItemAction
  | GetAddendaFieldsAction
  | GetOtherFeeItemsAction
  | GetOtherRentItemsAction
  | GetPropertyDetailsAction
  | GetRentableItemsAction
  | SaveAddendaFields
  | SaveCleaningChargesAction
  | SaveDurationPricingAction
  | SaveLeaseDefaultAction
  | SavePropertyPolicyAction
  | SaveUtilityPolicyAction
  | UpdateOtherFeeItemAction
  | UpdateOtherRentItemAction
  | UpdateRentableItemAction;
