import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { Action, ActionType, Alert, AlertLogs } from './action-types';

export interface AlertState extends CommonStateType {
  isAlertSubscriptionsLoaded: boolean;
  isAlertLoaded: boolean;
  countAlerts: number;
  alertLogCount: number;
  alertSubscriptions: Alert[];
  alert: Alert;
  isAlertLogLoaded: boolean;
  alertLog: AlertLogs;
  isUnitTypesLogLoaded: {
    [unit: string]: boolean;
  };
  unitTypesLog: {
    [unit: string]: AlertLogs;
  };
  unitTypesLogCount: {
    [unit: string]: number;
  };
}

const initialState: AlertState = {
  isSubmitting: false,
  errorMessage: null,
  isAlertSubscriptionsLoaded: false,
  isAlertLoaded: false,
  countAlerts: 0,
  alertLogCount: 0,
  alertSubscriptions: null,
  alert: null,
  isAlertLogLoaded: false,
  alertLog: null,
  isUnitTypesLogLoaded: {},
  unitTypesLog: {},
  unitTypesLogCount: {},
};

export default produce((state: AlertState = initialState, action: Action): AlertState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_ALERTS_SUBSCRIPTIONS_REQUEST:
      state.isAlertSubscriptionsLoaded = false;
      break;

    case ActionType.GET_ALERTS_SUBSCRIPTIONS_SUCCESS:
      state.alertSubscriptions = action.result.data.results;
      state.isAlertSubscriptionsLoaded = true;
      state.countAlerts = action.result.data.count;
      state.isAlertLogLoaded = false;
      break;

    case ActionType.GET_ALERTS_SUBSCRIPTIONS_FAILURE:
      state.isAlertSubscriptionsLoaded = false;
      break;

    case ActionType.GET_ALERTS_BY_ID_REQUEST:
      state.isAlertLoaded = false;
      break;

    case ActionType.GET_ALERTS_BY_ID_SUCCESS:
      state.alert = action.result.data;
      state.isAlertLoaded = true;
      break;

    case ActionType.GET_ALERTS_BY_ID_FAILURE:
      state.isAlertLoaded = false;
      break;

    case ActionType.GET_ALERT_LOG_DETAIL_REQUEST:
      state.isAlertLogLoaded = false;
      break;

    case ActionType.GET_ALERT_LOG_DETAIL_SUCCESS:
      state.alertLog = action.result.data;
      state.isAlertLogLoaded = true;
      state.alertLogCount = action.result.data.count;
      break;

    case ActionType.GET_ALERT_LOG_DETAIL_FAILURE:
      state.isAlertLogLoaded = false;
      break;

    case ActionType.CREATE_ALERT_SUCCESS:
      state.isAlertSubscriptionsLoaded = false;
      break;

    case ActionType.UPDATE_ALERT_SUCCESS:
      state.alert = action.result.data;
      break;

    case ActionType.GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_REQUEST:
      state.isUnitTypesLogLoaded = { ...state.isUnitTypesLogLoaded, [action.unitType]: false };
      break;

    case ActionType.GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_SUCCESS:
      state.unitTypesLog = { ...state.unitTypesLog, [action.unitType]: action.result.data.results };
      state.isUnitTypesLogLoaded = { ...state.isUnitTypesLogLoaded, [action.unitType]: true };
      state.unitTypesLogCount = { ...state.unitTypesLogCount, [action.unitType]: action.result.data.count };
      break;

    case ActionType.GET_ALERT_LOG_DETAIL_BY_UNIT_TYPE_FAILURE:
      state.isUnitTypesLogLoaded = { ...state.isUnitTypesLogLoaded, [action.unitType]: false };
      break;
  }

  return state;
});
