import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import DueDateSelector from './_due_date';

import { InputProps } from '../utils';

const TaskInputs: FC<InputProps> = props => (
  <Row className="mt-2">
    <Col xs={6} className="mg-t-10">
      <DueDateSelector {...props} />
    </Col>
  </Row>
);

export default TaskInputs;
