import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { CallBackFunction } from 'src/interfaces';
import { ActionType, Action, EmailTemplateProps } from './action-types';

export default {
  getEmailTemplates: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_EMAIL_TEMPLATES_REQUEST,
        ActionType.GET_EMAIL_TEMPLATES_SUCCESS,
        ActionType.GET_EMAIL_TEMPLATES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.EMAIL_TEMPLATES, { params: { show_all: true } }),
    },
  }),

  createEmailTemplate: (data: EmailTemplateProps, successCB: CallBackFunction = null): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.CREATE_EMAIL_TEMPLATE_REQUEST,
          ActionType.CREATE_EMAIL_TEMPLATE_SUCCESS,
          ActionType.CREATE_EMAIL_TEMPLATE_FAILURE,
        ],
        promise: client => client.post(paths.api.v1.EMAIL_TEMPLATES, data),
        successCB,
      },
    }),

  updateEmailTemplateById: (id: number, data: EmailTemplateProps, successCB: CallBackFunction = null): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.UPDATE_EMAIL_TEMPLATE_REQUEST,
          ActionType.UPDATE_EMAIL_TEMPLATE_SUCCESS,
          ActionType.UPDATE_EMAIL_TEMPLATE_FAILURE,
        ],
        promise: client => client.put(build(paths.api.v1.EMAIL_TEMPLATE_DETAILS, id), data),
        successCB,
      },
    }),

  deleteEmailTemplateById: (id: number, successCB: CallBackFunction = null): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.DELETE_EMAIL_TEMPLATE_REQUEST,
          ActionType.DELETE_EMAIL_TEMPLATE_SUCCESS,
          ActionType.DELETE_EMAIL_TEMPLATE_FAILURE,
        ],
        promise: client => client.delete(build(paths.api.v1.EMAIL_TEMPLATE_DETAILS, id)),
        successCB,
      },
    }),
};
