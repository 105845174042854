import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, ScreeningReportProps, ScreeningReportState } from './action-types';

export const initialState: ScreeningReportState = {
  errorMessage: null,
  incompleteFields: [],
  isLoaded: false,
  screeningReports: [] as ScreeningReportProps[],
};

export const selectScreeningReports = (state : { screeningReport: ScreeningReportState }) : ScreeningReportProps[] => state.screeningReport.screeningReports;
export const selectIsScreeningReportsLoaded = (state : { screeningReport: ScreeningReportState }) : boolean => state.screeningReport.isLoaded;
export const selectIncompleteFields = (state : { screeningReport: ScreeningReportState }) : string[] => state.screeningReport.incompleteFields;
export const selectIsIncompleteFieldsLoaded = (state : { screeningReport: ScreeningReportState }) : boolean => state.screeningReport.isLoaded;

export default produce((state: ScreeningReportState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_SCREENING_INCOMPLETE_FIELDS_SUCCESS:
      state.incompleteFields = action.result.data;
      break;

    case ActionType.GET_SCREENING_REPORTS_SUCCESS:
    case ActionType.INITIATE_SCREENING_SUCCESS:
      state.screeningReports = action.result.data.results;
      break;

    case LeaseActionType.UPDATE_UNIT_RELEASE_HOLD_SUCCESS:
      state = initialState;
      break;
  }

  return state;
});
