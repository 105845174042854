import { PromiseActionType } from 'dwell/store/types';
import { SubmitCallsScoreStateAction } from 'dwell/store/property/action-types';

export enum ActionType {
  GET_SCORED_CALLS_REQUEST = 'GET_SCORED_CALLS_REQUEST',
  GET_SCORED_CALLS_SUCCESS = 'GET_SCORED_CALLS_SUCCESS',
  GET_SCORED_CALLS_FAILURE = 'GET_SCORED_CALLS_FAILURE',
  UPDATE_SCORED_CALL_REQUEST = 'UPDATE_SCORED_CALL_REQUEST',
  UPDATE_SCORED_CALL_SUCCESS = 'UPDATE_SCORED_CALL_SUCCESS',
  UPDATE_SCORED_CALL_FAILURE = 'UPDATE_SCORED_CALL_FAILURE',
  CREATE_SCORED_CALL_REQUEST = 'CREATE_SCORED_CALL_REQUEST',
  CREATE_SCORED_CALL_SUCCESS = 'CREATE_SCORED_CALL_SUCCESS',
  CREATE_SCORED_CALL_FAILURE = 'CREATE_SCORED_CALL_FAILURE',
  REQUIRE_RESCORE_CALL_REQUEST = 'REQUIRE_RESCORE_CALL_REQUEST',
  REQUIRE_RESCORE_CALL_SUCCESS = 'REQUIRE_RESCORE_CALL_SUCCESS',
  REQUIRE_RESCORE_CALL_FAILURE = 'REQUIRE_RESCORE_CALL_FAILURE',
  GET_CALL_RESCORES_META_REQUEST = 'GET_CALL_RESCORES_META_REQUEST',
  GET_CALL_RESCORES_META_SUCCESS = 'GET_CALL_RESCORES_META_SUCCESS',
  GET_CALL_RESCORES_META_FAILURE = 'GET_CALL_RESCORES_META_FAILURE',
}

export interface ScoredCallProps {
  id: number;
  call: number;
  questions: number[];
  omitted_questions: number[];
  agent: number;
  property: number;
}

export interface CallRescoresMeta {
  required_call_rescores_count: number;
  has_scored_calls_today: number;
  is_call_scoring_submitted_today?: boolean;
}

export interface ScoredCallsState {
  isSubmitting: boolean;
  errorMessage: string;
  scoredCalls: ScoredCallProps[];
  scoredCall: ScoredCallProps;
  isLoaded: boolean;
  callRescoresMeta: CallRescoresMeta;
}

interface GetScoredCallsAction {
  type: ActionType.GET_SCORED_CALLS_REQUEST | ActionType.GET_SCORED_CALLS_SUCCESS | ActionType.GET_SCORED_CALLS_FAILURE;

  result: {
    data: { results: ScoredCallProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateScoredCallByIdAction {
  type: ActionType.UPDATE_SCORED_CALL_REQUEST | ActionType.UPDATE_SCORED_CALL_SUCCESS | ActionType.UPDATE_SCORED_CALL_FAILURE;

  result: {
    data: ScoredCallProps;
  };
  error: {
    response: { status: string };
  };
}

interface CreateScoredCallAction {
  type: ActionType.CREATE_SCORED_CALL_REQUEST | ActionType.CREATE_SCORED_CALL_SUCCESS | ActionType.CREATE_SCORED_CALL_FAILURE;

  error: {
    response: { status: string };
  };
}

export interface RequireRescoreCallAction {
  type: ActionType.REQUIRE_RESCORE_CALL_REQUEST | ActionType.REQUIRE_RESCORE_CALL_SUCCESS | ActionType.REQUIRE_RESCORE_CALL_FAILURE;

  error: {
    response: { status: string };
  };
}

interface GetCallRescoresMetaAction {
  type: ActionType.GET_CALL_RESCORES_META_REQUEST | ActionType.GET_CALL_RESCORES_META_SUCCESS | ActionType.GET_CALL_RESCORES_META_FAILURE;

  result: {
    data: CallRescoresMeta;
  };
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetScoredCallsAction | UpdateScoredCallByIdAction | CreateScoredCallAction | RequireRescoreCallAction | GetCallRescoresMetaAction | SubmitCallsScoreStateAction;
