import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_LEAD_VEHICLES_REQUEST = 'CREATE_LEAD_VEHICLES_REQUEST',
  CREATE_LEAD_VEHICLES_SUCCESS = 'CREATE_LEAD_VEHICLES_SUCCESS',
  CREATE_LEAD_VEHICLES_FAILURE = 'CREATE_LEAD_VEHICLES_FAILURE',

  CREATE_VEHICLE_REQUEST = 'CREATE_VEHICLE_REQUEST',
  CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS',
  CREATE_VEHICLE_FAILURE = 'CREATE_VEHICLE_FAILURE',

  DELETE_LEAD_VEHICLE_REQUEST = 'DELETE_LEAD_VEHICLE_REQUEST',
  DELETE_LEAD_VEHICLE_SUCCESS = 'DELETE_LEAD_VEHICLE_SUCCESS',
  DELETE_LEAD_VEHICLE_FAILURE = 'DELETE_LEAD_VEHICLE_FAILURE',

  DELETE_VEHICLE_REQUEST = 'DELETE_VEHICLE_REQUEST',
  DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_FAILURE = 'DELETE_VEHICLE_FAILURE',

  GET_LEAD_VEHICLES_REQUEST = 'GET_LEAD_VEHICLES_REQUEST',
  GET_LEAD_VEHICLES_SUCCESS = 'GET_LEAD_VEHICLES_SUCCESS',
  GET_LEAD_VEHICLES_FAILURE = 'GET_LEAD_VEHICLES_FAILURE',

  GET_VEHICLE_MAKES_REQUEST = 'GET_VEHICLE_MAKES_REQUEST',
  GET_VEHICLE_MAKES_SUCCESS = 'GET_VEHICLE_MAKES_SUCCESS',
  GET_VEHICLE_MAKES_FAILURE = 'GET_VEHICLE_MAKES_FAILURE',

  GET_VEHICLE_MODELS_REQUEST = 'GET_VEHICLE_MODELS_REQUEST',
  GET_VEHICLE_MODELS_SUCCESS = 'GET_VEHICLE_MODELS_SUCCESS',
  GET_VEHICLE_MODELS_FAILURE = 'GET_VEHICLE_MODELS_FAILURE',

  GET_VEHICLE_YEARS_REQUEST = 'GET_VEHICLE_YEARS_REQUEST',
  GET_VEHICLE_YEARS_SUCCESS = 'GET_VEHICLE_YEARS_SUCCESS',
  GET_VEHICLE_YEARS_FAILURE = 'GET_VEHICLE_YEARS_FAILURE',

  UPDATE_LEAD_VEHICLE_REQUEST = 'UPDATE_LEAD_VEHICLE_REQUEST',
  UPDATE_LEAD_VEHICLE_SUCCESS = 'UPDATE_LEAD_VEHICLE_SUCCESS',
  UPDATE_LEAD_VEHICLE_FAILURE = 'UPDATE_LEAD_VEHICLE_FAILURE',

  UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST',
  UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_FAILURE = 'UPDATE_VEHICLE_FAILURE',
}

export interface VehicleState extends CommonStateType {
  vehicleMakes: string[],
  vehicleModels: string[]
  vehicles: VehicleProps[],
  vehicleYears: number[],
}

export interface VehicleProps {
  id?: number,
  color: string,
  license_plate_number: string,
  license_plate_state: string,
  make: string,
  model: string,
  year: number,
  user?: number,

  // used for bulk save
  makeChoices?: string[],
  modelChoices?: string[],
}

/*
 * Actions
 */

interface CreateLeadVehiclesAction extends CommonActionResponse {
  type: ActionType.CREATE_LEAD_VEHICLES_REQUEST
  | ActionType.CREATE_LEAD_VEHICLES_SUCCESS
  | ActionType.CREATE_LEAD_VEHICLES_FAILURE;
  result: { data: VehicleProps[] };
}

interface CreateVehicleAction extends CommonActionResponse {
  type: ActionType.CREATE_VEHICLE_REQUEST
  | ActionType.CREATE_VEHICLE_SUCCESS
  | ActionType.CREATE_VEHICLE_FAILURE;
  result: { data: VehicleProps };
}

interface DeleteLeadVehicleAction extends CommonActionResponse {
  type: ActionType.DELETE_LEAD_VEHICLE_REQUEST
  | ActionType.DELETE_LEAD_VEHICLE_SUCCESS
  | ActionType.DELETE_LEAD_VEHICLE_FAILURE;
  result: { data: VehicleProps };
}

interface DeleteVehicleAction extends CommonActionResponse {
  type: ActionType.DELETE_VEHICLE_REQUEST
  | ActionType.DELETE_VEHICLE_SUCCESS
  | ActionType.DELETE_VEHICLE_FAILURE;
  result: { data: VehicleProps };
}

interface GetLeadVehiclesAction extends CommonActionResponse {
  type: ActionType.GET_LEAD_VEHICLES_REQUEST
  | ActionType.GET_LEAD_VEHICLES_SUCCESS
  | ActionType.GET_LEAD_VEHICLES_FAILURE;
  result: { data: { results: VehicleProps[] } };
}

interface GetVehicleMakesAction extends CommonActionResponse {
  type: ActionType.GET_VEHICLE_MAKES_REQUEST
  | ActionType.GET_VEHICLE_MAKES_SUCCESS
  | ActionType.GET_VEHICLE_MAKES_FAILURE;
  result: { data: string[] };
}

interface GetVehicleModelsAction extends CommonActionResponse {
  type: ActionType.GET_VEHICLE_MODELS_REQUEST
  | ActionType.GET_VEHICLE_MODELS_SUCCESS
  | ActionType.GET_VEHICLE_MODELS_FAILURE;
  result: { data: string[] };
}

interface GetVehicleYearsAction extends CommonActionResponse {
  type: ActionType.GET_VEHICLE_YEARS_REQUEST
  | ActionType.GET_VEHICLE_YEARS_SUCCESS
  | ActionType.GET_VEHICLE_YEARS_FAILURE;
  result: { data: number[] };
}

interface UpdateLeadVehicleAction extends CommonActionResponse {
  type: ActionType.UPDATE_LEAD_VEHICLE_REQUEST
  | ActionType.UPDATE_LEAD_VEHICLE_SUCCESS
  | ActionType.UPDATE_LEAD_VEHICLE_FAILURE;
  result: { data: VehicleProps };
}

interface UpdateVehicleAction extends CommonActionResponse {
  type: ActionType.UPDATE_VEHICLE_REQUEST
  | ActionType.UPDATE_VEHICLE_SUCCESS
  | ActionType.UPDATE_VEHICLE_FAILURE;
  result: { data: VehicleProps };
}

export type Action =
  PromiseActionType
  | CreateLeadVehiclesAction
  | CreateVehicleAction
  | DeleteLeadVehicleAction
  | DeleteVehicleAction
  | GetLeadVehiclesAction
  | GetLeaseByIdAction
  | GetVehicleMakesAction
  | GetVehicleModelsAction
  | GetVehicleYearsAction
  | ResetLeaseAction
  | UpdateLeadVehicleAction
  | UpdateVehicleAction;
