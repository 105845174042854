import pageData from './page_data/reducers';
import client from './client/reducers';
import customer from './customer/reducers';
import tooltips from './tooltips/reducers';
import promotion from './promotion/reducers';
import hobbes from './hobbes/reducers';

export type SiteStateType = {
  client: ReturnType<typeof client>,
  customer: ReturnType<typeof customer>,
  hobbes: ReturnType<typeof hobbes>,
  pageData: ReturnType<typeof pageData>,
  promotion: ReturnType<typeof promotion>,
  tooltips: ReturnType<typeof tooltips>,
};

export default {
  client,
  customer,
  hobbes,
  pageData,
  promotion,
  tooltips,
};
