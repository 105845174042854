import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label, FormText } from 'reactstrap';

import { selectProperty } from 'dwell/store/property/reducers';

import { InputProps, mapFloorPlans } from '../utils';

const UnitSelector: FC<InputProps> = ({ control, setValue, task, type }) => {
  const property = useSelector(selectProperty);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (type) {
      setOptions(mapFloorPlans(property.floor_plans || [], type));
    }
  }, [property, type]);

  useEffect(() => {
    if (task.units && options.length) {
      const units = [];
      options.forEach(item => item.options.forEach((option) => {
        if (task.units.includes(option.value)) units.push(option);
      }));

      setValue('units', units);
    } else {
      setValue('units', null);
    }
  }, [task.units, options]);

  return (
    <FormGroup>
      <Label htmlFor="units">Showing units:</Label>
      <Controller
        control={control}
        name="units"
        rules={{ required: type === 'SELF_GUIDED_TOUR' }}
        render={({ field: { value, ...params }, fieldState: { error } }) => (
          <>
            <Select
              {...params}
              className="basic-multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              formatOptionLabel={({ label, value: id }) => (
                <div className="d-flex">
                  <div className="inputCheck">
                    <input type="checkbox" checked={value && value.find(i => i.value === id)} />
                  </div>
                  <div>{label}</div>
                </div>
              )}
              hideSelectedOptions={false}
              inputId="units"
              isMulti
              options={options}
              value={value}
            />
            {error &&
                  <FormText color="danger">Please select some units</FormText>}
          </>
        )}
      />
    </FormGroup>
  );
};

export default UnitSelector;
