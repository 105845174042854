import produce from 'immer';
import { unionBy } from 'lodash';
import { Action, ActionType, Holiday } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface HolidayState extends CommonStateType {
  areHolidaysLoaded: boolean;
  holidays: Holiday[];
  holiday: Holiday;
}

const initialState: HolidayState = {
  isSubmitting: false,
  errorMessage: null,
  areHolidaysLoaded: false,
  holidays: [],
  holiday: {} as Holiday,
};

export default produce((state: HolidayState = initialState, action: Action): HolidayState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_HOLIDAYS_REQUEST:
      state.areHolidaysLoaded = false;
      break;
    case ActionType.GET_HOLIDAYS_SUCCESS:
      state.areHolidaysLoaded = true;
      state.holidays = action.result.data.results;
      break;
    case ActionType.GET_HOLIDAYS_FAILURE:
      state.areHolidaysLoaded = false;
      break;

    case ActionType.UPDATE_HOLIDAY_REQUEST:
      state.isSubmitting = true;
      state.areHolidaysLoaded = false;
      break;
    case ActionType.UPDATE_HOLIDAY_SUCCESS:
      state.holiday = action.result.data;
      state.holidays = unionBy([action.result.data], state.holidays, 'id');
      state.areHolidaysLoaded = true;
      state.isSubmitting = false;
      break;
    case ActionType.UPDATE_HOLIDAY_FAILURE:
      state.isSubmitting = false;
      state.areHolidaysLoaded = false;
      state.errorMessage = action.error.response?.status;
      break;
  }

  return state;
});
