import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import FollowUp from './_tour_followup';
import TourDate from './_tour_date';
import UnitSelector from './_units_selector';
import { InputProps } from '../utils';

const TourInputs: FC<InputProps> = props => (
  <>
    <Row className="mt-2">
      <Col xs={6} className="mg-t-10">
        <UnitSelector {...props} />
      </Col>

      {props.type !== 'VIRTUAL_TOUR' && (
        <Col xs={6} className="mg-t-10">
          <TourDate {...props} />
        </Col>)}
    </Row>

    <Row className="mt-2">
      <Col xs={12} className="mg-t-10">
        <FollowUp {...props} />
      </Col>
    </Row>

  </>
);

export default TourInputs;
