import { PropertyProps } from '../property/action-types';

export enum ActionType {
  SET_CHECK_ALL_PROPERTIES = 'SET_CHECK_ALL_PROPERTIES',
  SET_SHOW_APPLY_BUTTON = 'SET_SHOW_APPLY_BUTTON',
  SET_PS_PROPERTIES_COUNT = 'SET_PS_PROPERTIES_COUNT',
  SET_SHOW_SELECTOR = 'SET_SHOW_SELECTOR',
  SET_PRESELECTED_PROPERTIES = 'SET_PRESELECTED_PROPERTIES',
  SET_POSTSELECTED_PROPERTIES = 'SET_POSTSELECTED_PROPERTIES',
  SET_AVAILABLE_PROPERTIES = 'SET_AVAILABLE_PROPERTIES',
  SET_PROPERTY_KEYWORD = 'SET_PROPERTY_KEYWORD',
}

interface SetCheckedAllPropertiesAction {
  type: ActionType.SET_CHECK_ALL_PROPERTIES;
  checked: number;
}

interface SetShowApplyButtonAction {
  type: ActionType.SET_SHOW_APPLY_BUTTON;
  showApply: boolean;
}

interface SetPropertiesCountAction {
  type: ActionType.SET_PS_PROPERTIES_COUNT;
  count: number;
}

interface SetShowSelectorAction {
  type: ActionType.SET_SHOW_SELECTOR;
  show: boolean;
}

interface SetPreSelectedPropertiesAction {
  type: ActionType.SET_PRESELECTED_PROPERTIES;
  properties: PropertyProps[];
}

interface SetPostSelectedPropertiesAction {
  type: ActionType.SET_POSTSELECTED_PROPERTIES;
  properties: PropertyProps[];
}

interface SetAvailablePropertiesAction {
  type: ActionType.SET_AVAILABLE_PROPERTIES;
  properties: PropertyProps[];
}

interface SetPropertyKeywordAction {
  type: ActionType.SET_PROPERTY_KEYWORD;
  keyword: string;
}

export type Action =
  | SetCheckedAllPropertiesAction
  | SetShowApplyButtonAction
  | SetPropertiesCountAction
  | SetShowSelectorAction
  | SetPreSelectedPropertiesAction
  | SetPostSelectedPropertiesAction
  | SetAvailablePropertiesAction
  | SetPropertyKeywordAction;
