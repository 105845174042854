import produce from 'immer';
import { orderBy, unionBy } from 'lodash';
import { PusherAction, ActionType as PusherActionType } from 'dwell/store/pusher/action-types';
import { ActionType as LeadActionType } from 'dwell/store/lead/action-types';
import { Action, ActionType, RoommateProps } from './action-types';
import { isLeadPage, isLeadsObject } from '../utils';

export interface RoommateState {
  roommates: RoommateProps[];
  roommate: RoommateProps;
}

export const initialState: RoommateState = {
  roommates: [],
  roommate: {} as RoommateProps,
};

export default produce((state: RoommateState = initialState, action: Action): RoommateState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'roommate') {
    return state;
  }

  switch (action.type) {
    case LeadActionType.GET_LEAD_BY_ID_SUCCESS:
      state.roommates = action.result.data.roommates;
      break;

    case ActionType.UPDATE_ROOMMATES_SUCCESS:
      state.roommates = orderBy(action.result.data, ['updated'], ['desc']);
      break;

    case ActionType.DELETE_ROOMMATE_SUCCESS:
      state.roommates = state.roommates.filter(i => i.id !== action.result.data.id);
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      let newRoommates = state.roommates;
      const row = pusherAction.row as RoommateProps;
      if (isLeadPage() && isLeadsObject(row.lead.toString())) {
        newRoommates = orderBy(unionBy([row], state.roommates, 'id'), ['updated'], ['desc']);
      }
      state.roommates = newRoommates;
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      let newRoommates = state.roommates;
      const row = pusherAction.row as RoommateProps;
      if (isLeadPage() && isLeadsObject(row.lead.toString())) {
        newRoommates = orderBy(unionBy([row], state.roommates, 'id'), ['updated'], ['desc']);
      }
      state.roommates = newRoommates;
      state.roommate = state.roommate.id === row.id ? row : state.roommate;
      break;
    }

    case PusherActionType.PUSHER_DELETE_RECORD: {
      let newRoommates = state.roommates;
      const row = pusherAction.row as RoommateProps;
      if (isLeadPage() && isLeadsObject(row.lead.toString())) {
        newRoommates = state.roommates.filter(o => o.id.toString() !== row.id.toString());
      }
      state.roommates = newRoommates;
      break;
    }
  }

  return state;
});
