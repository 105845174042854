import { paths, LOGGED_ACCOUNT, COMMUNICATION_FILTER } from 'dwell/constants';
import userManager from './user_manager';
import { ActionType, Action } from './action-types';

export default {
  login: (credentials: { email: string, password: string, last_login_property?: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.LOGIN_REQUEST,
        ActionType.LOGIN_SUCCESS,
        ActionType.LOGIN_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.LOGIN, credentials),
    },
  }),
  logout: (): Action => {
    localStorage.removeItem(LOGGED_ACCOUNT);
    localStorage.removeItem(COMMUNICATION_FILTER);
    window.location.href = '/login';
    return {
      type: ActionType.LOGOUT,
    };
  },

  checkSSO: (email: string): Action => ({
    CALL_API: {
      types: [
        ActionType.SSO_CHECK_REQUEST,
        ActionType.SSO_CHECK_SUCCESS,
        ActionType.SSO_CHECK_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CHECK_SSO, { params: { email } }),
    },
  }),

  SSOLogin: (accessToken: string): Action => ({
    CALL_API: {
      types: [
        ActionType.SSO_LOGIN_REQUEST,
        ActionType.SSO_LOGIN_SUCCESS,
        ActionType.SSO_LOGIN_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.SSO_LOGIN, { headers: { Authorization: `Bearer ${accessToken}` } }),
    },
  }),

  backchannelLogout: (logoutCompletely = false, errorMessage = ''): Action => ({
    CALL_API: {
      types: [
        ActionType.BACKCHANNEL_LOGOUT_REQUEST,
        ActionType.BACKCHANNEL_LOGOUT_SUCESS,
        ActionType.BACKCHANNEL_LOGOUT_FAILURE,
      ],
      // TODO: Check if this code works correctly, same as middleware code
      promise: async (axios) => {
        const userData = await userManager.getUser();
        return axios.post(
          paths.api.v1.BACKCHANNEL_LOGOUT,
          { refresh_token: userData.refresh_token },
          { headers: { Authorization: `Bearer ${userData.access_token}` } },
        );
      },
      payload: { logoutCompletely, errorMessage },
    },
  }),

  sessionTimeout: (): Action => ({ type: ActionType.SESSION_TIMEOUT }),
  resetLoginState: (clearAll = false): Action => ({ type: ActionType.RESET_LOGIN_STATE, clearAll }),
};
