import React, { FC, useEffect, useState } from 'react';
import { Carousel } from 'reactstrap';
import { Preview, PreviewContent, PreviewClose, CircleDivider, CarouselItem } from './styles';

export interface ImageType {
  image: string,
  label?: string,
  plan?: string,
  bedrooms?: number,
  bathrooms?: number,
  square_footage?: number,
  isPlan?: boolean,
}

interface ImagePreviewProps {
  images: ImageType[],
  activeIndex: number,
  onClose: () => void,
  showIndicator?: boolean,
}

const ImagePreview: FC<ImagePreviewProps> = ({ images, activeIndex: defaultActiveIndex, onClose, showIndicator = true }) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const previous = () => {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
  };

  const next = () => {
    setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1);
  };

  const keyDown = (e) => {
    // Esc - close preview
    if (e.keyCode === 27) {
      const element = document.querySelector('#close-plan-preview') as HTMLAnchorElement;
      if (element) element.click();
    }
    return true;
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    return () => {
      document.removeEventListener('keydown', keyDown);
    };
  });

  return (
    <Preview>
      <PreviewClose onClick={onClose} id="close-plan-preview">
        <i className="ri-close-line" /> Close
      </PreviewClose>
      <PreviewContent>
        <div id="plan_carousel" className="carousel slide" data-ride="false">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={null}
          >
            {images?.map(item => (
              <CarouselItem key={item}>
                <h6 className="text-center mg-b-5">{item.plan}</h6>
                <div className="d-flex align-items-center justify-content-center mg-b-20">
                  {item.isPlan && (
                    <>
                      {item.bedrooms === 0 ? 'Studio' : `${item.bedrooms} Bed`}
                      <CircleDivider />
                      {item.bathrooms} Bath
                      <CircleDivider />
                      {item.square_footage} SqFt
                    </>
                  )}
                </div>
                <img src={item.image} className="img-fluid" alt={item.plan} />
              </CarouselItem>
            ))}
            {showIndicator && images.length > 1 && (
              <>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" onClick={previous}>
                  <span className="carousel-control-prev-icon" aria-hidden="true">
                    <i className="ri-arrow-left-circle-line" />
                  </span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" onClick={next}>
                  <span className="carousel-control-next-icon" aria-hidden="true">
                    <i className="ri-arrow-right-circle-line" />
                  </span>
                  <span className="sr-only">Next</span>
                </a>
              </>
            )}
          </Carousel>
        </div>
      </PreviewContent>
    </Preview>
  );
};

export default ImagePreview;
