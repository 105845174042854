import styled, { css } from 'styled-components';

export const DatePickerWrapper = styled.div`
  .is-invalid input {
    background-image: none !important;
    border: 1px solid #f86c6b !important;
    padding-right: .75rem !important;
  }

  .SingleDatePicker_picker {
    right: 0px;
    left: unset! important;
  }

  ${props => props.$invalid && css`
    input {
      background-image: none !important;
      border: 1px solid #f86c6b !important;
      padding-right: .75rem !important;
    }
  `}

  .SingleDatePickerInput {
    height: unset;
  }

  .SingleDatePicker_picker {
    z-index: 10;
  }
`;

export const DropdownLinkText = styled.span`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
