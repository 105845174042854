import { Col, Input as FormInput, Row } from 'reactstrap';
import { CardWidgetButton, CardWidgetPrimaryButton } from 'dwell/views/lead/overview/styles';
import styled, { css } from 'styled-components';

export const ApplicantBtn = styled.a`
  cursor: pointer;
  height: 36px;
  border: 1px solid #d5dcf4;
  color: #4a5e8a;
  font-weight: 500;
  font-size: 13px;
  opacity: .75;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  letter-spacing: -0.2px;
  border-radius: 5px;
  outline: none;
  margin-right: 5px;

  &:hover {
    opacity: 1;
    background-color: #fff;
  }

  ${props => props.selected && css`
      opacity: 1;
      color: #0168fa !important;
      border-color: #0168fa;
      background-color: #ebf2fe !important;
  `}
`;

export const AddApplicantBtn = styled(ApplicantBtn)`
  font-weight: 400;
  color: ${props => props.theme.colors.colorui01} !important;
  border-color: ${props => props.theme.colors.colorui01};
  opacity: 1;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  &:hover, &:focus {
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    background-color: ${props => (props.disabled ? '#fff' : '#0168fa')};
    color: ${props => (props.disabled ? '#0168fa' : '#fff')} !important;
  }

  i {
      margin-right: 5px;
  }

  ${props => props.disabled && css`
      opacity: 0.5;
  `}
`;

export const ApplicantNav = styled.nav`
  flex-direction: column;
  min-width: 180px;
  margin-right: 15px;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;

export const ShowMoreEmployer = styled.div`
    color: #0168fa;
    background: white;
    padding: 5px;
    position: relative;
    cursor: pointer;
`;

export const ApplicantNavItem = styled.a`
  position: relative;
  color: #4a5e8a !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 .5rem 0;
  cursor: pointer;

  &:not(:first-child) {
      border-top: 1px solid #eef1f9;
      padding: .5rem 0;
  }

  &:hover, &:focus {
    color: ${props => (props.$disabled ? '#4a5e8a' : '#0168fa')} !important;
  }

  ${props => props.$disabled && css`
      opacity: 0.5;
      cursor: default;
  `}

  ${props => props.active && css`
      color: #0168fa !important;
  `}
`;

export const DeleteApplicant = styled(ApplicantBtn)`
  background-color: #fff;
  border-color: #d5dcf4;
  color: #4a5e8a;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    border-color: #c1c8de;
    color: #4a5e8a;
  }
`;

export const Switcher = styled.div`
  font-size: 13px;
  color: #4a5e8a !important;
  &:focus {
      color: #0168fa !important;
  }
  &:hover {
      color: #0168fa !important;
  }
`;

export const ContentCol = styled.div`
  height: calc(100vh - 425px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 15px;
  padding-left: 15px;

  &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }

    &::-webkit-scrollbar {
        width: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`;

export const CheckBox = styled.input`
  border-radius: 3px;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border: #a0a9bd solid 1px;
  transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  &:focus {
  transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  }
`;

export const Line = styled.span`
 &:before {
  content: '';
  position: absolute;
  bottom: 96%;
  left: 146px;
  right: 0;
  border-bottom: 1px solid #e2e7f4;
}
`;

export const Label = styled.span`
  display: block;
  font-size: ${props => (props.$small ? '12px' : '13px')};
  margin-bottom: 7px;
  white-space: nowrap;
  font-weight: 400;
  line-height: 1.5;
  color: #4a5e8a;

`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  &:before {
    content: '';
    position: absolute;
    bottom: calc(50% - 1px);
    left: 0;
    right: 0;
    border-bottom: 1px solid #e2e7f4;
  }
`;

export const SectionHeadWrapper = styled(TitleWrapper)`
   margin-bottom: 5px;
`;

export const Title = styled.h6`
  position: relative;
  margin-bottom: 0;
  font-size: 15px;
  background-color: #fff;
  padding-right: 10px;
  line-height: 1;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #233457;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9def0;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
`;

export const AppPanel = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PrimaryButton = styled(CardWidgetPrimaryButton)`
  font-size: 14px;
`;

export const SecondaryButton = styled(CardWidgetButton)`
  font-size: 14px;
  background-color: #fff;
  border-color: #d5dcf4 !important;
  color: #4a5e8a !important;

  &:hover, &:focus {
    border-color: #c1c8de !important;
    color: #4a5e8a !important;
  }

  &.selected {
    background-color: #ebf2fe !important;
    border-color: ${props => props.theme.colors.primary} !important;
    color: ${props => props.theme.colors.primary} !important;
    font-weight: 500;
  }
`;

export const Divider = styled.hr`
  border-color: #eaedf5;
`;

export const CheckboxWrapper = styled.div`
  margin-top: 15px;
  position: relative;
  display: block;
  min-height: 1.3125rem;

  label {
    font-weight: 400;
    font-size: 13px;
    color: #344563;
  }
`;

export const RadioWrapper = styled.div`
  margin-top: 5px;
  position: relative;
  display: block;
  min-height: 1.3125rem;

  label {
    font-weight: 400;
    font-size: 14px;
    color: #344563;
  }
`;

export const CustomRow = styled(Row)`
  margin-left: -10px !important;
  margin-right: -10px !important;
`;

export const CustomCol = styled(Col)`
  font-size: 13px;
  padding-left: 10px !important;
  padding-right: 10px !important;
`;

export const CustomFormInput = styled(FormInput)`
  height: 40px;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #233457;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5dcf4 !important;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 0.5px rgba(1,104,250,0.6);
    border-color: rgba(1,104,250,0.6) !important;
  }

  &.is-invalid {
    background-image: none !important;
    border: 1px solid #f86c6b !important;
    padding-right: .75rem !important;

    &:focus {
      box-shadow: none !important;
    }
  }
`;

export const SectionTitle = styled.h6`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #929eb9;
    text-transform: uppercase;
    position: relative;
    letter-spacing: .5px;

    span {
      background-color: #fff;
      padding-right: 10px;
      position: relative;
      z-index: 5;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      right: 0;
      border-bottom: 1px solid #e2e7f4;
    }
`;

export const LinkButton = styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 5px;
    position: relative;
    z-index: 1;
    letter-spacing: normal;
    text-transform: capitalize;
    color: #0168fa !important;
    text-decoration: none;

    &:hover {
      color: #0148ae !important;
      text-decoration: none;
    }

    i {
      font-size: 14px;
      line-height: 1;
      margin-right: 2px;
    }
`;

export const Photo = styled.div`
  border: 1.5px dashed ${props => (props.$invalid ? props.theme.colors.red : props.theme.input.borderColor)};
  border-radius: 4px;
  background-color: #f7f8fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const UploadPhotoBtn = styled.button`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: .375rem .75rem;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #d5dcf4;
  min-height: 0;
  border-radius: 4px;
  height: 34px;
  font-size: 13px;
  color: #4a5e8a;

  &:hover {
  border-color: #c1c8de;
  color: #4a5e8a;
  }
  &:focus {
  border-color: #c1c8de;
  color: #4a5e8a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(1,104,250,0.25);
  }
`;

export const Or = styled.span`
  margin-bottom: 10px;
  color: #929eb9;
  font-size: 14px;
`;

export const QuestionInfo = styled(FormInput)`
    border-color: ${props => (props.invalid ? 'red ' : '#d5dcf4')} !important;
    padding: 10px;
    border-radius: 5px;
    &:focus {
      border-color: #d5dcf4;
      box-shadow: none;
    }
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper input, .DateInput_input {
    height: 36px !important;
    font-size: .875rem;
    color: ${props => props.theme.colors.gray700};
    margin-left: 0px !important;
  }

  &.touch-left-border .SingleDatePicker_picker {
    left: 0 !important;
  }
`;

export const IncomeDocItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 13px;

  &:first-child { padding-top: 0; }
  &:last-child { padding-bottom: 0; }

  :not(:first-child) {
    border-top: 1px dashed ${props => props.theme.input.borderColor};
  }
`;

export const IncomeDocRow = styled(CustomRow)`
  border-top: ${props => props.theme.input.borderColor};
  border-top-style: ${props => (props.index > 0 ? 'dashed' : 'none')};
  border-top-width: 1px;
  box-sizing: border-box;
  padding: 5px 0;
  width: 100%;

  p {
    flex-grow: 1;
    font-size: 0.85rem;
    margin: 0;
  }
`;

export const RemoveIncomeDoc = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${props => props.theme.colors.blue};
  display: flex;
  font-size: 0.9rem;
  margin-right: 0.4rem;
  padding: 0 0.2rem;

  i {
    margin-right: 0.2rem;
  }
`;

export const IncomeVerified = styled.div`
  background: ${props => props.theme.colors.green}11;
  border: 1px solid ${props => props.theme.colors.green}66;
  display: flex;
  padding: 0.6rem;

  h6 {
    background: inherit;
    margin-bottom: 0.4rem;
  }

  i {
    color: ${props => props.theme.colors.green};
    font-size: 1.2rem;
    margin-right: 0.6rem;
    position: relative;
    transform: translateY(-0.3rem);
  }
`;
