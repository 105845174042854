import moment from 'moment';
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from 'dwell/actions';
import { FormStructure, AgentQuestion, QuestionStructureTypes, AnswerPayload, OptionalAnswerTypes } from 'dwell/store/agent_question/action-types';
import { Spinner } from 'site/components';
import { typesWithLoader } from 'dwell/views/chat/agent_question/choices';
import { AgentFormAnswered, AgentResponse, AgentResponseBody, BotResponseFooter, ChatFormButton, FormResponse, PartQuestionAnswered, QuestionsForm, ResponseLabel, SubmitBtn } from 'dwell/views/chat/single_chat/window/styles';
import { generalAnswerHtml } from 'dwell/views/chat/agent_question/utils';
import DirectForm from './_direct_form';
import OptionalForm from './_optional_form';
import BinaryForm from './_binary_form';

interface AnswerFormProps {
  question: AgentQuestion;
}
const AnswerForm: FC<AnswerFormProps> = ({ question }) => {
  const dispatch = useDispatch();
  const agentQuestion = useSelector(state => state.agentQuestion);
  const { globalForm, areQuestionOptionsLoaded, currentIdx } = agentQuestion;
  const { processAnswers, setGlobalForm, setIsFormDisplayed, setCurrentIdx } = actions.agentQuestion;

  const anyErrors = globalForm.map(f => f.error).some(b => b === true);
  const sendAnswers = (answers: AnswerPayload[], answersHTML: string) => {
    dispatch(processAnswers({ answers, answers_html: answersHTML }));
  };

  const resetForm = () => {
    dispatch(setGlobalForm([]));
    dispatch(setCurrentIdx(0));
    dispatch(setIsFormDisplayed(false));
  };

  const submitIDKHandler = () => {
    const question_id = question.id;
    dispatch(setGlobalForm([]));
    const answer: AnswerPayload = { question: question_id, value: 'IDK', action: 'IDK', is_array: false };
    sendAnswers([answer], "I don't know");
  };

  const submitHandler = (index: number, isBinary = false, form: FormStructure[] = null) => {
    const currentForm = form || globalForm;
    if (currentForm.length - 1 === index) {
      const question_id = question.id;
      const answers = currentForm.map((f) => {
        let valueToSend = '';
        if (f.multiSelectionValue) {
          valueToSend = JSON.stringify(f.multiSelectionValue);
        } else if (f.structureType === QuestionStructureTypes.QUESTION_STRUCTURE_BINARY || f.optional_answer_type === OptionalAnswerTypes.ANSWER_TIME_OPTIONS) {
          valueToSend = f.answer;
        } else {
          valueToSend = f.value;
        }
        return {
          question: question_id,
          value: valueToSend,
          action: isBinary ? 'NO' : 'GENERIC_INPUT',
          is_array: !!f.multiSelectionValue,
        };
      });
      const answerHTML = generalAnswerHtml(currentForm, isBinary);
      resetForm();
      sendAnswers(answers, answerHTML);
    } else {
      dispatch(setCurrentIdx(index + 1));
    }
  };

  const renderForm = (q, index) => {
    if (q.structureType === QuestionStructureTypes.QUESTION_STRUCTURE_BINARY) {
      return <BinaryForm resetForm={resetForm} question={question} submitHandler={submitHandler} index={index} />;
    }
    if (typesWithLoader.includes(q.optional_answer_type) && !areQuestionOptionsLoaded) {
      return <Spinner />;
    }
    return (
      <FormResponse key={`fr-${index}`}>
        {q.structureType === QuestionStructureTypes.QUESTION_STRUCTURE_DIRECT ? <DirectForm index={index} /> : <OptionalForm index={index} />}
        <SubmitBtn key={`submit-${index}`} className={(q.answer || q.multiSelectionValue?.length) && !anyErrors ? 'btn-primary' : ''} disabled={(!q.answer && !q.multiSelectionValue?.length) || anyErrors} onClick={() => submitHandler(index)}>
          <i className="ri-arrow-right-s-fill" />
        </SubmitBtn>
      </FormResponse>
    );
  };

  if (!globalForm.length) return <></>;

  return (
    <AgentResponse key="answer-form">
      <AgentResponseBody>
        <React.Fragment>
          {globalForm
            .sort((a, b) => a.order - b.order)
            .map((q, idx, arr) => {
              let questionText = q.questionText.charAt(0).toUpperCase() + q.questionText.slice(1);
              if (arr.length > 1) {
                questionText = `${idx + 1}. ${questionText}:`;
              }
              if (idx === currentIdx) {
                return (
                  <QuestionsForm key={`qf-${idx}`} isFirstQuestion={idx === 0}>
                    <ResponseLabel>{questionText}</ResponseLabel>
                    {renderForm(q, idx)}
                  </QuestionsForm>
                );
              }
              if (idx <= currentIdx && q.answer) {
                const is_hours = moment(q.answer, 'HH:mm:ss', true).isValid();
                return (
                  <QuestionsForm key={`qf-${idx}`} isFirstQuestion={idx === 0}>
                    <PartQuestionAnswered>
                      <ResponseLabel>{questionText}</ResponseLabel>
                      <AgentFormAnswered>{is_hours ? moment(q.answer, 'HH:mm:ss', true).format('hh:mm A') : q.answer}</AgentFormAnswered>
                    </PartQuestionAnswered>
                  </QuestionsForm>
                );
              }
              return <></>;
            })}
        </React.Fragment>
        <BotResponseFooter>
          <ChatFormButton className="btn-white" onClick={submitIDKHandler}>
            I don't know
          </ChatFormButton>
        </BotResponseFooter>
      </AgentResponseBody>
    </AgentResponse>
  );
};

export default AnswerForm;
