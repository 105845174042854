import { paths } from 'dwell/constants';
import { Action, ActionType, GetLeadsDataRequestsProps, FocusBarItem } from './action-types';

export default {
  getLeadData: (params: GetLeadsDataRequestsProps): Action => {
    const { after, field, filter_id, order, search, ...payload } = params;
    return {
      CALL_API: {
        types: [
          ActionType.GET_FOCUS_BAR_LEADS_REQUEST,
          ActionType.GET_FOCUS_BAR_LEADS_SUCCESS,
          ActionType.GET_FOCUS_BAR_LEADS_FAILURE,
        ],
        promise: client => client.get(paths.api.v1.LEAD_FOCUS_DATA, { params: { after, field, filter_id, order, search, show_all: true } }),
        payload: { ...payload },
      },
    };
  },

  reloadData: (): Action => ({ type: ActionType.RELOAD_FOCUS_BAR_DATA }),

  resetFocusBar: (): Action => ({ type: ActionType.RESET_FOCUS_BAR }),

  setBarData: (items: FocusBarItem[], index: number, title: string, from: string): Action => ({
    type: ActionType.SET_FOCUS_BAR_ITEMS,
    from,
    index,
    items,
    title,
  }),

  setBarIndex: (index: number): Action => ({
    type: ActionType.SET_FOCUS_BAR_INDEX,
    index,
  }),
};
