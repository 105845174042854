import { paths } from 'dwell/constants';
import { paths as leasingPaths } from 'leasing/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, SMSMessage } from './action-types';

export default {
  getSMSContacts: (offset?: number, limit?: number, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SMS_CONTACTS_REQUEST,
        ActionType.GET_SMS_CONTACTS_SUCCESS,
        ActionType.GET_SMS_CONTACTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.SMS_CONTACTS, { params: { offset, limit }, cancelToken: token }),
    },
  }),
  getConversations: (data: SMSMessage): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SMS_CONVERSATIONS_REQUEST,
        ActionType.GET_SMS_CONVERSATIONS_SUCCESS,
        ActionType.GET_SMS_CONVERSATIONS_FAILURE,
      ],
      promise: client => client.get(
        data.lease ? build(leasingPaths.api.v1.APPLICANTS_SMS, data.lease, data.leasing_user) : build(paths.api.v1.SMS_MESSAGE, data.lead),
        { params: data.params },
      ),
    },
  }),
  sendSMS: (data: SMSMessage, property: number, type = null): Action => ({
    CALL_API: {
      types: [
        ActionType.SEND_SMS_REQUEST,
        ActionType.SEND_SMS_SUCCESS,
        ActionType.SEND_SMS_FAILURE,
      ],
      promise: client => client.post(
        ['application', 'lease'].includes(type) ? build(leasingPaths.api.v1.APPLICANTS_SMS, data[type], data.leasing_user) : build(paths.api.v1.SMS_MESSAGE, data.lead),
        data,
        { params: { property } },
      ),
    },
  }),
  readAll: (data: SMSMessage, propertyId?: number): Action => ({
    CALL_API: {
      types: [
        ActionType.READ_ALL_SMS_REQUEST,
        ActionType.READ_ALL_SMS_SUCCESS,
        ActionType.READ_ALL_SMS_FAILURE,
      ],
      promise: client => client.post(data.lease ?
        build(leasingPaths.api.v1.APPLICANTS_SMS_READALL, data.lease, data.leasing_user) :
        build(paths.api.v1.SMS_CONVERSATIONS_READALL, data.lead, { params: { property: propertyId } })),
    },
  }),
  updateNotificationRedirection: (data: boolean): Action => ({
    type: ActionType.UPDATE_NOTIFICATION_REDIRECTION,
    data,
  }),
  clearContacts: (): Action => ({
    type: ActionType.CLEAR_CONTACTS,
  }),
  getSMSContactById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CONTACT_BY_ID_REQUEST,
        ActionType.GET_CONTACT_BY_ID_SUCCESS,
        ActionType.GET_CONTACT_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.SMS_CONTACT_DETAILS, id)),
    },
  }),
  removeContact: (id: number): Action => ({
    type: ActionType.REMOVE_CONTACT,
    id,
  }),
};
