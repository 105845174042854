import React, { CSSProperties, FC } from 'react';
import { isEmpty } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment-timezone';
import { LOGGED_ACCOUNT } from 'dwell/constants';
import { PropertyProps } from 'dwell/store/property/action-types';

export const getCookie = (name: string): string => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const getPropertyId = (path = '', param = 1): string => {
  const pathname = path !== '' ? path : window.location.pathname;
  if (pathname.split('/').length > 2) {
    return pathname.split('/')[param];
  }
  return '';
};

export const getLeadIdFromPath = (path = ''): number => {
  const pathname = path !== '' ? path : window.location.pathname;
  const match = pathname.match(/\/leads\/(\d+)\//);
  if (match && match.length > 1) {
    return Number(match[1]);
  }
  return null;
};

export const hex2rgb = (hex: string): number[] => {
  let r: number;
  let g: number;
  let b: number;

  if (hex.length <= 5) {
    r = Number(`0x${hex.substring(1, 2)}`);
    g = Number(`0x${hex.substring(2, 3)}`);
    b = Number(`0x${hex.substring(3, 4)}`);
  } else if (hex.length <= 9) {
    r = Number(`0x${hex.substring(1, 3)}`);
    g = Number(`0x${hex.substring(3, 5)}`);
    b = Number(`0x${hex.substring(5, 7)}`);
  }

  return [r, g, b];
};

export const rgb2hsl = (rgb: number[]): number[] => {
  let h: number;
  let s: number;
  let l: number;

  let [r, g, b] = rgb;
  r /= 255;
  g /= 255;
  b /= 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = ((b - r) / delta) + 2;
  } else {
    h = ((r - g) / delta) + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs((2 * l) - 1));

  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l];
};

export const darken = (hex: string, amount: number): string => {
  const rgb = hex2rgb(hex);
  const [h, s, l] = rgb2hsl(rgb);
  return `hsl(${h}, ${s}%, ${l - amount < 0 ? 0 : l - amount}%)`;
};

interface LineSkeletonProps {
  width?: number,
  height?: number,
  style?: CSSProperties,
  count?: number,
  circle?: boolean,
}

export const LineSkeleton: FC<LineSkeletonProps> = ({ width, height, style, count, circle }) =>
  (<Skeleton width={width} height={height} style={{ ...style, borderRadius: '6px' }} count={count} circle={circle} />);

export const CheckboxSkeleton = () : JSX.Element =>
  (<Skeleton width="16px" height="16px" style={{ borderRadius: '3px', marginRight: '8px' }} />);

export const navigateToExternalUrl = (url: string, shouldOpenNewTab = true): Window | string =>
  (shouldOpenNewTab ? window.open(url, '_blank') : window.location.href = url);

export const formatPriceValue = (value: number, fixedPoint = 2): string => (value !== null && value !== undefined ? `$${value.toFixed(fixedPoint).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '');

export const numberToLocale = (value: number, options: Intl.NumberFormatOptions = {}): string => value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, ...options });

export const formatDate = (date: string) : string => (date ? moment(date).format('LL') : null);

export const formatFileLink = (url: string) : string => {
  const token = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};
  return (url ? `${url}?token=${token.access}` : '');
};

export const getMaskingPhoneNumber = (phone: string) : string => {
  const str = phone?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !str[2] ? str[1] : `(${str[1]}) ${str[2]}${str[3] ? `-${str[3]}` : ''}`;
};

export const getMode = (arr: number[]): number => {
  const mode = {};
  let max = 0;
  let count = 0;

  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    if (mode[item]) {
      mode[item] += 1;
    } else {
      mode[item] = 1;
    }
    if (count < mode[item]) {
      max = item;
      count = mode[item];
    }
  }
  return max;
};

export const formatUrl = (website: string): string => (website.includes('http') ? website : `https://${website}`);

export const getTimezone = (): string => {
  const timezone = localStorage.getItem('timezone');
  if (moment.tz.names().includes(timezone)) return timezone;
  return 'America/Phoenix';
};

export const formatTimeByTimezone = (time: string | Date | moment.Moment = moment()): moment.Moment => moment(time).tz(getTimezone());

export const replaceTimezone = (time: string | Date | moment.Moment = moment()): moment.Moment => moment.tz(moment(time).format(), 'YYYY-MM-DDTHH:mm:ss.SSS', getTimezone());

export const convertToDateWithTimezone = (time: string | Date | moment.Moment = moment()): Date => new Date(formatTimeByTimezone(time).format('YYYY-MM-DD HH:mm'));

export const dateFilter = (referenceDate: string | Date | moment.Moment = moment(), itemDate: string | Date | moment.Moment = moment()): boolean => {
  const itemFormattedDate = formatTimeByTimezone(itemDate);
  const referenceFormattedDate = referenceDate ? moment(referenceDate) : formatTimeByTimezone();
  return itemFormattedDate.date() === referenceFormattedDate.date() && itemFormattedDate.month() === referenceFormattedDate.month();
};

export const loadLocalStorage = (key: string): { [key: string]: string | number } | null => {
  try {
    const item = localStorage.getItem(key);
    if (item === null) {
      // If the item with the specified key does not exist in localStorage, return null.
      return null;
    }
    return JSON.parse(item);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

export const pathnameMatchCheck = (pathname: string, routePath: string): boolean => {
  const regex = new RegExp(`^${routePath.replace(/:[^/]+/g, '[^/]+')}$`);
  return regex.test(pathname);
};

export const checkIfAllPropertiesAreInactive = (selectedProperties: PropertyProps[]): boolean => {
  if (isEmpty(selectedProperties)) return false;
  return selectedProperties.every(i => i.status === 'INACTIVE');
};
