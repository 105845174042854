import React, { FC, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { isEmpty } from 'lodash';

import { selectSelectedProperties } from 'dwell/store/property/reducers';
import actions from 'dwell/store/voice/action-creator';
import {
  selectCallContacts,
  selectCallContactsLoading,
  selectAllContactsCount,
  selectDownloadedCount,
} from 'dwell/store/voice/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import {
  CallsPanelFilter,
  CallsPanelBody,
  EmptyContent,
  ChatSearch as CallsFilter,
} from '../styles';
import CallProspectFilter from './call_filter';
import CallProspect from './call_prospect';

export const CONTACTS_N_PROSPECTS_OFFSET = 30;
export const INITIAL_CONTACTS_N_PROSPECTS_OFFSET = 50;

const CallContacts: FC = () => {
  const [keyword, setKeyword] = useState('');
  const [activeFilter, setActiveFilter] = useState('All');
  const [preventDownload, setPreventDownload] = useState(false);
  const [canScroll, setCanScroll] = useState(false);
  const [scrollAutomated, setScrollAutomated] = useState(false);
  const [scrollEl, setScrollEl] = useState(null);

  const dispatch = useDispatch();
  const loadingContacts = useSelector(selectCallContactsLoading);
  const contacts = useSelector(selectCallContacts);
  const totalContactsCount = useSelector(selectAllContactsCount);
  const downloadedCount = useSelector(selectDownloadedCount);
  const currentUser = useSelector(selectCurrentUser);
  const selectedProperties = useSelector(selectSelectedProperties);

  useEffect(() => {
    if (isEmpty(contacts)) dispatch(actions.getCallContacts());
  }, []);

  useEffect(() => {
    dispatch(actions.clearCallContacts());
    dispatch(actions.getCallContacts());
  }, [selectedProperties]);

  useEffect(() => {
    if (!isEmpty(contacts) && canScroll && !loadingContacts) {
      if (scrollEl && scrollEl.childElementCount > 7) {
        setScrollAutomated(true);
        const contactBar = document.getElementById('call-contact-bar');
        let offset = contacts.length - 1 - CONTACTS_N_PROSPECTS_OFFSET;
        offset = offset < 0 ? contacts.length - 3 : offset;
        const scrollHeight = document.getElementById(`call-option-${contacts[offset].id}`);
        if (scrollHeight && contactBar) contactBar.scrollTop = scrollHeight.offsetTop;
        setCanScroll(false);
      }
    }
  }, [contacts, canScroll, loadingContacts]);

  const onActiveFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const onScroll = ({ target }) => {
    if (preventDownload) {
      setPreventDownload(false);
      return;
    }
    if (totalContactsCount === downloadedCount) return;
    if (scrollAutomated) {
      setScrollAutomated(false);
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (scrollTop && scrollTop + clientHeight >= (scrollHeight - 5) && totalContactsCount && !keyword) {
      dispatch(actions.getCallContacts(downloadedCount, CONTACTS_N_PROSPECTS_OFFSET)).then((response) => {
        if (response) {
          setCanScroll(true);
        }
      });
    }
  };

  const emptyContent = (
    <EmptyContent>
      <i className="ri-phone-fill" />
      <h5><span>{keyword ? 'No Contacts Found' : ''}</span></h5>
      <p>{keyword ? 'Try adjusting your search to find what you’re looking for.' : 'You don’t have any contacts to manage right now'}</p>
    </EmptyContent>
  );

  const filteredContacts = useMemo(() => {
    let results = [];
    if (activeFilter === 'All') results = contacts;
    if (activeFilter === 'My') results = contacts.filter(contact => contact.owner === currentUser.id);
    if (keyword) results = results.filter(contact => contact.name.toLowerCase().includes(keyword.toLocaleLowerCase()));
    return results;
  }, [activeFilter, keyword, contacts]);

  return (
    <>
      <CallsPanelFilter>
        <CallsFilter className="form-search">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            className="form-control"
            style={{ marginLeft: 8 }}
            placeholder="Search all prospects"
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </CallsFilter>
        <CallProspectFilter
          activeFilter={activeFilter}
          setActiveFilter={onActiveFilterClick}
        />
      </CallsPanelFilter>
      <CallsPanelBody
        id="call-contact-bar"
        onScroll={onScroll}
        containerRef={ref => setScrollEl(ref)}
      >
        {filteredContacts && filteredContacts.length ? filteredContacts.map(contact => (
          <CallProspect
            key={`call-prospect-${contact.id}`}
            contact={contact}
          />
        )) : emptyContent}
      </CallsPanelBody>
    </>
  );
};

export default CallContacts;
