
import { paths } from 'leasing/constants';
import { Action, ActionType, LeaseUnitFilterParams } from './action-types';

export default {
  getUnits: (params?: LeaseUnitFilterParams, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_UNITS_REQUEST,
        ActionType.GET_UNITS_SUCCESS,
        ActionType.GET_UNITS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.UNITS, { params: { show_all: true, ...params }, cancelToken: token }),
    },
  }),
};
