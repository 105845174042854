import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { prepareLastDate } from 'compete/constants';
import { Action, ActionType, HistoricalChart } from './action-types';

export interface HistoricalReportState extends CommonStateType {
  isHistoricalPropertyRentLoaded: boolean;
  historicalPropertyRent: HistoricalChart;
  isHistoricalPropertyOccupancyLoaded: boolean;
  historicalPropertyOccupancy: HistoricalChart;
  isPropertyConcessionLoaded: boolean;
  propertyConcession: HistoricalChart;
  isSubmarketRentLoaded: boolean;
  submarketRent: HistoricalChart;
  isSubmarketOccupancyLoaded: boolean;
  submarketOccupancy: HistoricalChart;
  isSubmarketConcessionLoaded: boolean;
  submarketConcession: HistoricalChart;
  isMarketRentLoaded: boolean;
  marketRent: HistoricalChart;
  isMarketOccupancyLoaded: boolean;
  marketOccupancy: HistoricalChart;
  isMarketConcessionLoaded: boolean;
  marketConcession: HistoricalChart;
  isRentCompareLoaded: boolean;
  rentCompare: HistoricalChart;
}

const initialState: HistoricalReportState = {
  errorMessage: null,
  isHistoricalPropertyRentLoaded: false,
  historicalPropertyRent: null,
  isHistoricalPropertyOccupancyLoaded: false,
  historicalPropertyOccupancy: null,
  isPropertyConcessionLoaded: false,
  propertyConcession: null,
  isSubmarketRentLoaded: false,
  submarketRent: null,
  isSubmarketOccupancyLoaded: false,
  submarketOccupancy: null,
  isSubmarketConcessionLoaded: false,
  submarketConcession: null,
  isMarketRentLoaded: false,
  marketRent: null,
  isMarketOccupancyLoaded: false,
  marketOccupancy: null,
  isMarketConcessionLoaded: false,
  marketConcession: null,
  isRentCompareLoaded: false,
  rentCompare: null,
};

export default produce((state: HistoricalReportState = initialState, action: Action): HistoricalReportState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_HISTORICAL_PROPERTY_RENT_REQUEST:
      state.isHistoricalPropertyRentLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_RENT_SUCCESS:
      state.historicalPropertyRent = prepareLastDate(action.result.data);
      state.isHistoricalPropertyRentLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_RENT_FAILURE:
      state.isHistoricalPropertyRentLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_OCCUPANCY_REQUEST:
      state.isHistoricalPropertyOccupancyLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_OCCUPANCY_SUCCESS:
      state.historicalPropertyOccupancy = prepareLastDate(action.result.data);
      state.isHistoricalPropertyOccupancyLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_OCCUPANCY_FAILURE:
      state.isHistoricalPropertyOccupancyLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_CONCESSION_REQUEST:
      state.isPropertyConcessionLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_CONCESSION_SUCCESS:
      state.propertyConcession = prepareLastDate(action.result.data);
      state.isPropertyConcessionLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_PROPERTY_CONCESSION_FAILURE:
      state.isPropertyConcessionLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_RENT_REQUEST:
      state.isSubmarketRentLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_RENT_SUCCESS:
      state.submarketRent = prepareLastDate(action.result.data);
      state.isSubmarketRentLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_RENT_FAILURE:
      state.isSubmarketRentLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_OCCUPANCY_REQUEST:
      state.isSubmarketOccupancyLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_OCCUPANCY_SUCCESS:
      state.submarketOccupancy = prepareLastDate(action.result.data);
      state.isSubmarketOccupancyLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_OCCUPANCY_FAILURE:
      state.isSubmarketOccupancyLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_CONCESSION_REQUEST:
      state.isSubmarketConcessionLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_CONCESSION_SUCCESS:
      state.submarketConcession = prepareLastDate(action.result.data);
      state.isSubmarketConcessionLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_SUBMARKET_CONCESSION_FAILURE:
      state.isSubmarketConcessionLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_MARKET_RENT_REQUEST:
      state.isMarketRentLoaded = false;
      break;
    case ActionType.GET_HISTORICAL_MARKET_RENT_SUCCESS:
      state.marketRent = prepareLastDate(action.result.data);
      state.isMarketRentLoaded = true;
      break;
    case ActionType.GET_HISTORICAL_MARKET_RENT_FAILURE:
      state.isMarketRentLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_MARKET_OCCUPANCY_REQUEST:
      state.isMarketOccupancyLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_MARKET_OCCUPANCY_SUCCESS:
      state.marketOccupancy = prepareLastDate(action.result.data);
      state.isMarketOccupancyLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_MARKET_OCCUPANCY_FAILURE:
      state.isMarketOccupancyLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_MARKET_CONCESSION_REQUEST:
      state.isMarketConcessionLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_MARKET_CONCESSION_SUCCESS:
      state.marketConcession = prepareLastDate(action.result.data);
      state.isMarketConcessionLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_MARKET_CONCESSION_FAILURE:
      state.isMarketConcessionLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_UNDER_OVER_RENT_REQUEST:
      state.isRentCompareLoaded = false;
      break;

    case ActionType.GET_HISTORICAL_UNDER_OVER_RENT_SUCCESS:
      state.rentCompare = prepareLastDate(action.result.data);
      state.isRentCompareLoaded = true;
      break;

    case ActionType.GET_HISTORICAL_UNDER_OVER_RENT_FAILURE:
      state.isRentCompareLoaded = false;
      break;
  }

  return state;
});
