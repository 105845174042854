import { unionBy } from 'lodash';
import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, PetProps, PetState } from './action-types';

export const initialState: PetState = {
  errorMessage: null,
  pets: [],
  isLoaded: false,
};

export const selectPets = (state: { pet: PetState }) : PetProps[] => state.pet.pets;
export const selectPetIsLoaded = (state : { pet: PetState }) : boolean => state.pet.isLoaded;

export default produce((state: PetState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_LEAD_PETS_SUCCESS:
      state.pets = action.result.data;
      break;

    case ActionType.CREATE_PET_SUCCESS:
      state.pets = unionBy([action.result.data], state.pets, 'id');
      break;

    case ActionType.DELETE_LEAD_PET_SUCCESS:
      state.pets = state.pets.filter(p => p.id !== action.result.data.id);
      break;

    case ActionType.DELETE_PET_SUCCESS:
      state.pets = state.pets.filter(p => p.id !== action.result.data.id);
      break;

    case ActionType.GET_LEAD_PETS_SUCCESS:
      state.pets = action.result.data.results;
      break;

    case ActionType.UPDATE_LEAD_PET_SUCCESS:
      state.pets = unionBy([action.result.data], state.pets, 'id');
      break;

    case ActionType.UPDATE_PET_SUCCESS:
      state.pets = unionBy([action.result.data], state.pets, 'id');
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.pets = action.result.data.pets;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
