import { paths } from 'dwell/constants';
import { ActionType, Action, BusinessHoursProps } from './action-types';

export default {
  getBusinessHours: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_BUSINESS_HOURS_REQUEST,
        ActionType.GET_BUSINESS_HOURS_SUCCESS,
        ActionType.GET_BUSINESS_HOURS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.BUSINESS_HOURS),
    },
  }),
  getBusinessHoursAll: (userId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_BUSINESS_HOURS_ALL_REQUEST,
        ActionType.GET_BUSINESS_HOURS_ALL_SUCCESS,
        ActionType.GET_BUSINESS_HOURS_ALL_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.BUSINESS_HOURS_ALL, userId)),
    },
  }),
  createBusinessHours: (data: { business_hours: BusinessHoursProps[] }): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.CREATE_BUSINESS_HOURS_REQUEST,
          ActionType.CREATE_BUSINESS_HOURS_SUCCESS,
          ActionType.CREATE_BUSINESS_HOURS_FAILURE,
        ],
        promise: client => client.post(paths.api.v1.BUSINESS_HOURS_CREATE, data),
      },
    }),
  updateBusinessHours: (data: { business_hours: BusinessHoursProps[] }): Action =>
    ({
      CALL_API: {
        types: [
          ActionType.UPDATE_BUSINESS_HOURS_REQUEST,
          ActionType.UPDATE_BUSINESS_HOURS_SUCCESS,
          ActionType.UPDATE_BUSINESS_HOURS_FAILURE,
        ],
        promise: client => client.put(paths.api.v1.BUSINESS_HOURS_UPDATE, data),
      },
    }),
};
