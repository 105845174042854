import $ from 'jquery';
import { formatPriceValue } from 'src/utils';
import { LeaseDocumentProps, StoreValuesProps } from 'leasing/store/types';
import { LeaseDocumentType } from 'leasing/constants/field_choices';

const focusElement = (name: string) : void => {
  const element = document.getElementsByName(name)[0];
  element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  element?.focus();
};

const highlightElement = (field: string, name: string, scroll = true, container = null): void => {
  let doc: HTMLIFrameElement;
  if (container) {
    doc = (container.querySelector('#document') as HTMLIFrameElement);
  } else {
    doc = (document.getElementById('document') as HTMLIFrameElement);
  }
  if (!doc) return;

  const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
  const zoom = isFirefox ? 1 : 0.75;
  const reverseZoom = isFirefox ? 1 : 1 - zoom;

  doc.contentWindow.document.querySelectorAll(`div[name='${name}'] [data-id='${field}']`).forEach((element, index) => {
    if (element) {
      element.classList.add('highlight');

      let shouldScroll = false;
      if (index === 0) shouldScroll = true;
      else if (name === 'Deposit Agreement - A' && ['field-63', 'field-66'].includes(field) && index === 1) shouldScroll = true;

      if (scroll && shouldScroll) {
        const winRect = doc.getBoundingClientRect();
        const docRect = doc.contentDocument.documentElement.getBoundingClientRect();
        const elRect = element.getBoundingClientRect();
        const scrollTopAnimateTo = ((elRect.top - docRect.top - 50) * zoom) - ((winRect.height / 2) * reverseZoom);
        if (elRect.height && elRect.width) {
          doc.contentWindow.scrollTo({ top: scrollTopAnimateTo, behavior: 'smooth' });
        }
      }
    }
  });
};

const fillElement = (field: string, value: string, name: string, container = null): void => {
  let doc;
  if (container) {
    doc = (container.querySelector('#document') as HTMLIFrameElement).contentWindow.document;
  } else {
    doc = (document.getElementById('document') as HTMLIFrameElement).contentWindow.document;
  }
  doc.querySelectorAll(`div[name='${name}'] [data-id='${field}']`).forEach((e) => {
    if (e.closest('.row-signature')) return;
    e.innerHTML = value || '';
    if (value) {
      e.classList.add('min-width-unset');
    } else {
      e.classList.remove('min-width-unset');
    }
  });
};

const fillCheckbox = (field: string, choices: string, name: string, container = null): void => {
  if (choices == null) return;
  let doc;
  if (container) {
    doc = (container.querySelector('#document') as HTMLIFrameElement).contentWindow.document;
  } else {
    doc = (document.getElementById('document') as HTMLIFrameElement).contentWindow.document;
  }

  const isComplex = (
    (name === 'Lease Agreement - A' && field === 'field-55') ||
    (['Arizona Lease Agreement - A', 'Arizona Lease Agreement - B', 'Nevada Lease Agreement'].includes(name) && field === 'field-61')
  );
  if (isComplex) {
    doc.querySelectorAll(`div[name='${name}'] [data-id='${field}']`).forEach((e) => {
      const { amount, mode } = JSON.parse(choices);
      if (e.getAttribute('class').includes('checkbox')) {
        if (e.getAttribute('data-value') === 'PERCENT' && mode === 'PERCENT') {
          e.setAttribute('data-checked', 'true');
        }
        if (e.getAttribute('data-value') === 'FIXED' && mode === 'FIXED') {
          e.setAttribute('data-checked', 'true');
        }
      } else {
        if (e.getAttribute('data-value') === 'PERCENT' && mode === 'PERCENT') {
          e.innerHTML = `${amount}%`;
          if (amount) {
            e.classList.add('min-width-unset');
          } else {
            e.classList.remove('min-width-unset');
          }
        }
        if (e.getAttribute('data-value') === 'FIXED' && mode === 'FIXED') {
          e.innerHTML = formatPriceValue(amount);
          if (amount) {
            e.classList.add('min-width-unset');
          } else {
            e.classList.remove('min-width-unset');
          }
        }
      }
    });
    return;
  }

  doc.querySelectorAll(`div[name='${name}'] [data-id='${field}']`).forEach((e) => {
    const choicesArr = choices?.split(',').map(i => i.trim()?.toLowerCase());
    if (choicesArr.includes(e.getAttribute('data-value')?.toLowerCase())) {
      e.setAttribute('data-checked', 'true');
    } else if (e.hasAttribute('data-checked')) {
      e.removeAttribute('data-checked');
    }
  });
};

const cancelHighlight = (container = null): void => {
  let doc;
  if (container) {
    doc = (container.querySelector('#document') as HTMLIFrameElement);
  } else {
    doc = (document.getElementById('document') as HTMLIFrameElement);
  }
  if (!doc) return;
  doc.contentWindow.document.querySelectorAll('.highlight').forEach((e) => {
    e.classList.remove('highlight');
  });
};

const fillInitialsAndSignatures = (storeValues: StoreValuesProps, container = null) => {
  let doc;
  if (container) {
    doc = (container.querySelector('#document') as HTMLIFrameElement).contentWindow.document;
  } else {
    doc = (document.getElementById('document') as HTMLIFrameElement).contentWindow.document;
  }
  const { applicants, guarantors, lease } = storeValues;
  doc.querySelectorAll('[data-custom="true"]').forEach(i => i.remove());
  doc.querySelectorAll('.initials').forEach((i) => {
    const initial = i.querySelector('span');
    if (!initial) return;
    applicants.forEach((user, index) => {
      const newInitial = index === 0 ? initial : initial.cloneNode(true) as HTMLElement;
      if (newInitial.getAttribute('require-traditional') === 'true' && lease.security_deposit_type === 'TRADITIONAL') {
        newInitial.setAttribute('not-required', 'true');
      }
      newInitial.setAttribute('data-id', `initial-applicant-${user.id}`);
      if (index !== 0) {
        newInitial.setAttribute('data-custom', 'true');
        i.appendChild(newInitial);
      }
    });
  });

  doc.querySelectorAll('.row-signature').forEach((i) => {
    let isApplicant = true;
    if (i.closest('[name="Guarantor Addendum"]')) {
      isApplicant = false;
    }
    const signature = i.querySelector('div:first-child');
    if (!signature) return;
    const users = isApplicant ? applicants : guarantors;
    users.forEach((user, index) => {
      const newSignature = index === 0 ? signature : signature.cloneNode(true) as HTMLElement;
      if (newSignature.getAttribute('require-traditional') === 'true' && lease.security_deposit_type === 'TRADITIONAL') {
        newSignature.setAttribute('not-required', 'true');
      }
      newSignature.setAttribute('data-id', `signature-${isApplicant ? 'applicant' : 'guarantor'}-${user.id}`);
      const nameEl = newSignature.querySelector('div').querySelector('span') as HTMLSpanElement;
      if (nameEl) {
        nameEl.textContent = `${user.first_name} ${user.last_name}`;
      }
      if (index !== 0) {
        newSignature.setAttribute('data-custom', 'true');
        i.appendChild(newSignature);
      }
    });
  });

  doc.querySelectorAll('[contenteditable="true"]').forEach((i) => {
    i.setAttribute('contenteditable', 'false');
  });
};

const initiateDocument = (storeValues: StoreValuesProps, type: LeaseDocumentType): void => {
  const { leaseAgreementDocument, depositAgreementDocument, leaseAddendumDocuments, depositAddendumDocuments, noticeDocument, creditBuilderAddendum, existingResidentCreditBuilderAddendum, roommateAddendum } = storeValues;

  let agreementDocument: LeaseDocumentProps = null;
  let documents: LeaseDocumentProps[] = [];
  switch (type) {
    case LeaseDocumentType.DEPOSIT_AGREEMENT:
      agreementDocument = depositAgreementDocument;
      documents = depositAddendumDocuments;
      break;
    case LeaseDocumentType.LEASE_AGREEMENT:
    case LeaseDocumentType.RENEW_LEASE_AGREEMENT:
      agreementDocument = leaseAgreementDocument;
      documents = leaseAddendumDocuments;
      break;
    case LeaseDocumentType.NOTICE:
      agreementDocument = noticeDocument;
      break;
    case LeaseDocumentType.CREDIT_BUILDER_ADDENDUM:
      agreementDocument = creditBuilderAddendum;
      break;
    case LeaseDocumentType.EXISTING_RESIDENT_CREDIT_BUILDER_ADDENDUM:
      agreementDocument = existingResidentCreditBuilderAddendum;
      break;
    case LeaseDocumentType.ROOMMATE_ADDENDUM:
      agreementDocument = roommateAddendum;
      break;
  }

  const isCBAddendum = [LeaseDocumentType.CREDIT_BUILDER_ADDENDUM, LeaseDocumentType.EXISTING_RESIDENT_CREDIT_BUILDER_ADDENDUM].includes(type);
  const mainDoc = (document.getElementById('document') as HTMLIFrameElement).contentWindow.document;
  mainDoc.open();
  mainDoc.write(agreementDocument.native_html);
  mainDoc.close();
  mainDoc.body.insertAdjacentHTML(
    'beforeend',
    '<style>' +
    '.highlight { background: #b2d2fe; border-bottom: 2px solid #0168fa; }' +
    '.red { background: red; border-bottom: 2px solid red; }' +
    '.color-transparent { color: transparent; } .hide_element { display: none !important; }' +
    '.min-width-unset { min-width: unset; }' +
    'html::-webkit-scrollbar { width: 2px; background-color: transparent; }' +
    'html::-webkit-scrollbar-thumb { background-color: transparent; }' +
    'html:hover::-webkit-scrollbar-thumb { background-color: #c1c8de; }' +
    'html { -ms-zoom: 0.75; zoom: 0.75 }' +
    '.page { font-size: 16px !important; width: unset; padding-left: 48px !important; padding-right: 48px !important; }' +
    '.page-body, .page-header { padding-left: 0 !important; padding-right: 0 !important }' +
    '.list li + li { margin-left: auto; }' +
    `@media (min-width: 850px) {
      .page { font-size: inherit; margin-bottom: 20px; width: 938px !important; }
      .w-100 { width: 100px !important; }
    }` +
    '</style>',
  );
  $(mainDoc).find('script').remove();
  let documentContainer = mainDoc.createElement('div');
  documentContainer.setAttribute('name', agreementDocument?.name);
  mainDoc.querySelectorAll('.page').forEach((i) => {
    documentContainer.append(i);
  });
  mainDoc.getElementById(isCBAddendum ? 'pageGroup' : 'pageBody')?.appendChild(documentContainer);
  const addendumIFrame = document.getElementById('addenda-document') as HTMLIFrameElement;

  documents.forEach((leaseDocument) => {
    const nativeHtml = leaseDocument?.native_html;
    if (nativeHtml && addendumIFrame) {
      const addendumDoc = addendumIFrame.contentWindow.document;
      addendumDoc.open();
      addendumDoc.write(nativeHtml);
      addendumDoc.close();
      addendumDoc.querySelectorAll('link').forEach((i) => {
        const href = i.getAttribute('href');
        if (!mainDoc.querySelector(`[href="${href}"]`)) mainDoc.querySelector('head').append(i);
      });
      documentContainer = addendumDoc.createElement('div');
      documentContainer.setAttribute('name', leaseDocument?.name);
      mainDoc.getElementById('pageBody')?.appendChild(documentContainer);
      addendumDoc.querySelectorAll('.page').forEach((i) => {
        documentContainer.append(i);
      });
    }
  });
  mainDoc.querySelectorAll('[data-id][data-checked="true"]').forEach((e) => {
    e.setAttribute('data-checked', 'false');
  });

  fillInitialsAndSignatures(storeValues);
};

const initiateAddendaDocumentPreview = (addendaDocument: LeaseDocumentProps): void => {
  const mainDoc = (document.getElementById('document') as HTMLIFrameElement).contentWindow.document;
  mainDoc.open();
  mainDoc.write(addendaDocument.native_html);
  mainDoc.close();
  mainDoc.body.insertAdjacentHTML(
    'beforeend',
    '<style>' +
    '.highlight { background: #b2d2fe; border-bottom: 2px solid #0168fa; }' +
    '.red { background: red; border-bottom: 2px solid red; }' +
    '.color-transparent { color: transparent; } .hide_element { display: none !important; }' +
    '.min-width-unset { min-width: unset; }' +
    'html::-webkit-scrollbar { width: 2px; background-color: transparent; }' +
    'html::-webkit-scrollbar-thumb { background-color: transparent; }' +
    'html:hover::-webkit-scrollbar-thumb { background-color: #c1c8de; }' +
    'html { -ms-zoom: 0.75; zoom: 0.75 }' +
    '.page { font-size: 16px !important; width: unset; padding-left: 48px !important; padding-right: 48px !important; }' +
    '.page-body, .page-header { padding-left: 0 !important; padding-right: 0 !important }' +
    '.list li + li { margin-left: auto; }' +
    `@media (min-width: 850px) {
      .page { font-size: inherit; margin-bottom: 20px; width: 938px !important; }
      .w-100 { width: 100px !important; }
    }` +
    '</style>',
  );
};

const listenFieldClick = (storeValues: StoreValuesProps, documents: LeaseDocumentProps[], container = null): void => {
  let doc;
  if (container) {
    doc = (container.querySelector('#document') as HTMLIFrameElement).contentWindow.document;
  } else {
    doc = (document.getElementById('document') as HTMLIFrameElement).contentWindow.document;
  }

  $(doc).find("[data-id^='field-']").on('click', function () {
    if (!$(this).hasClass('highlight')) return;
    const name = $(this).closest('.page').parent().attr('name');
    const field = $(this).attr('data-id');
    const document = (documents.find(d => d.name === name) || {}) as LeaseDocumentProps;
    const label = document.lease_fields.find(f => f.name === field)?.field_label;
    focusElement(label);

    // $(this).attr('title', 'Testo selezionato');
    // $(this).tooltip();
  });
};

export {
  focusElement,
  highlightElement,
  fillElement,
  fillCheckbox,
  cancelHighlight,
  initiateDocument,
  listenFieldClick,
  initiateAddendaDocumentPreview,
};
