import { PromiseActionType } from 'dwell/store/types';
import { UnitProps } from 'src/interfaces';

export enum ActionType {
  GET_APARTMENTS_REQUEST = 'GET_APARTMENTS_REQUEST',
  GET_APARTMENTS_SUCCESS = 'GET_APARTMENTS_SUCCESS',
  GET_APARTMENTS_FAILURE = 'GET_APARTMENTS_FAILURE',

  BULK_SAVE_APARTMENTS_REQUEST = 'BULK_SAVE_APARTMENTS_REQUEST',
  BULK_SAVE_APARTMENTS_SUCCESS = 'BULK_SAVE_APARTMENTS_SUCCESS',
  BULK_SAVE_APARTMENTS_FAILURE = 'BULK_SAVE_APARTMENTS_FAILURE',
}

export interface UnitDescriptionRequestProps {
  ids?: number[];
  description?: string;
  is_waitlist?: boolean,
  show_in_online_application?: boolean,
}

interface GetApartmentsAction {
  type: ActionType.GET_APARTMENTS_REQUEST | ActionType.GET_APARTMENTS_SUCCESS | ActionType.GET_APARTMENTS_FAILURE;

  result: {
    data: {
      results: UnitProps[];
      count: number;
      all_apartments_count: number;
      available_apartments_count: number;
      on_hold_apartments_count: number;
      waitlist_apartments_count: number;
    };
  };
  error: {
    response: { status: string };
  };
}

interface SaveApartmentsDescriptionAction {
  type: ActionType.BULK_SAVE_APARTMENTS_REQUEST | ActionType.BULK_SAVE_APARTMENTS_SUCCESS | ActionType.BULK_SAVE_APARTMENTS_FAILURE;

  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetApartmentsAction | SaveApartmentsDescriptionAction;
