import styled from 'styled-components';
import { Card, Nav, NavLink as NavLinkBase } from 'reactstrap';
import { SelectButton as SelectButtonCommon, SelectMenu as SelectMenuCommon } from 'styles/common';
import { FormSwitch as BaseFormSwitch } from 'dwell/views/chat/multi_chat/styles';

export const Content = styled.div`
  p { margin-bottom: 0; }
  strong { font-weight: 500; }
`;

export const CardUnits = styled(Card)`
  border-color: #D5DCF4;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardHeader = styled.div`
  background-color: #F0F2F9;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  img {
    width: 85px;
    height: 45px;
    object-fit: cover;
    border-radius: 3px;
  }
`;

export const ViewAllUnits = styled.div`
  & {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    background-color: rgba(0,0,0,0);
    cursor: pointer;
  }

  &:hover {
    color: ${props => props.theme.colors.colorui04};
    text-decoration: none
  }
`;

export const LinkUnitView = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.secondary} !important;

  i {
    font-size: 18px;
    line-height: 1;
    padding: 1px;
  }
`;

export const NavLine = styled(Nav)`
  padding: 10px 15px;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: relative;
  min-width: 100%;

  &::before {
    content: '';
    position: absolute;
    bottom: .5px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.colorbg02};
  }
`;

export const NavLink = styled(NavLinkBase)`
  color: ${props => props.theme.colors.colortx02};
  cursor: pointer;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;

  & ~ & {
    margin-left: 30px;
  }

  ${props => props.active && `
    color: ${props.theme.colors.primary};
    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #0168fa;
    }
  `}
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.secondary};
`;

export const SelectButton = styled(SelectButtonCommon)`
  width: 100%;
`;

export const SelectMenu = styled(SelectMenuCommon)`
  width: 100%;
  min-width: 100%;
`;

export const FormSwitch = styled(BaseFormSwitch)`
`;

export const NoDataLabel = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
`;
