import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, NoteProps } from './action-types';

export default {
  deleteNoteById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_NOTE_REQUEST,
        ActionType.DELETE_NOTE_SUCCESS,
        ActionType.DELETE_NOTE_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.NOTE_DETAILS, id)),
    },
  }),
  updateNoteById: (id: number, params: NoteProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_NOTE_REQUEST,
        ActionType.UPDATE_NOTE_SUCCESS,
        ActionType.UPDATE_NOTE_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.NOTE_DETAILS, id), params),
    },
  }),
  createNote: (data: NoteProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_NOTE_REQUEST,
        ActionType.CREATE_NOTE_SUCCESS,
        ActionType.CREATE_NOTE_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.NOTES, data),
    },
  }),
};
