import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { CustomInput } from 'reactstrap';
import { cancelHighlight, highlightElement } from 'leasing/views/agreement/utils/highlight_element';
import { selectDepositAgreementDocument } from 'leasing/store/lease_document/reducers';

interface RadioInputProps {
  id: string,
  value: string,
  name: string,
  onChange: (id: string, value: string) => void,
  choices?: unknown,
  needFocus?: boolean,
  disabled?: boolean,
  disabledKey?: string,
}

const RadioInput: FC<RadioInputProps> = ({ id, disabled, disabledKey, value, name, choices, onChange, needFocus }) => {
  const depositAgreementDocument = useSelector(selectDepositAgreementDocument);
  const leaseFields = depositAgreementDocument?.lease_fields || [];

  const onFocus = () => {
    const field = leaseFields.find(i => i.field_label === name);
    cancelHighlight();
    highlightElement(field?.name, depositAgreementDocument?.name);
  };

  const handleEventChange = ({ target: { value: newValue } }) => {
    onChange(id, newValue);
    if (needFocus) {
      onFocus();
    }
  };

  return (
    <>
      {Object.keys(choices).map((field, key) => (
        <CustomInput
          checked={value === field}
          id={`${id}-${key}`}
          key={`${id}-${key}`}
          label={choices[field]}
          onChange={handleEventChange}
          type="radio"
          value={field}
          disabled={disabled || field === disabledKey}
        />
      ))}
    </>
  );
};

export default RadioInput;
