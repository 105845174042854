import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ManageRequestProps } from 'src/interfaces';
import { ActionType, Action } from './action-types';

export default {
  getNotifications: (queryParams: ManageRequestProps): Action => ({
    CALL_API: {
      types: [ActionType.GET_NOTIFICATIONS_REQUEST, ActionType.GET_NOTIFICATIONS_SUCCESS, ActionType.GET_NOTIFICATIONS_FAILURE],
      promise: client => client.get(build(paths.api.v1.NOTIFICATIONS), { params: queryParams }),
    },
  }),
  clearAndGetNotifications: (queryParams: ManageRequestProps): Action => ({
    CALL_API: {
      types: [ActionType.CLEAR_AND_GET_NOTIFICATIONS_REQUEST, ActionType.CLEAR_AND_GET_NOTIFICATIONS_SUCCESS, ActionType.CLEAR_AND_GET_NOTIFICATIONS_FAILURE],
      promise: client => client.get(build(paths.api.v1.NOTIFICATIONS), { params: queryParams }),
    },
  }),
  readAllNotifications: (): Action => ({
    CALL_API: {
      types: [ActionType.READ_ALL_NOTIFICATIONS_REQUEST, ActionType.READ_ALL_NOTIFICATIONS_SUCCESS, ActionType.READ_ALL_NOTIFICATIONS_FAILURE],
      promise: client => client.post(paths.api.v1.NOTIFICATION_READ_ALL),
    },
  }),
  updateNotificationById: (id: number, params: { is_read: boolean; is_display?: boolean }): Action => ({
    CALL_API: {
      types: [ActionType.UPDATE_NOTIFICATION_REQUEST, ActionType.UPDATE_NOTIFICATION_SUCCESS, ActionType.UPDATE_NOTIFICATION_FAILURE],
      promise: client => client.patch(build(paths.api.v1.NOTIFICATION_DETAILS, id), params),
    },
  }),
  bulkClearNotifications: (params: { ids: number[] }): Action => ({
    CALL_API: {
      types: [ActionType.BULK_CLEAR_NOTIFICATIONS_REQUEST, ActionType.BULK_CLEAR_NOTIFICATIONS_SUCCESS, ActionType.BULK_CLEAR_NOTIFICATIONS_FAILURE],
      promise: client => client.post(paths.api.v1.BULK_CLEAR_NOTIFICATION, params),
    },
  }),
};
