/* eslint-disable jsx-a11y/label-has-for */
import React, { FC } from 'react';

import { CallBodyItemBaseProps } from '.';
import CallBodyItem from './call_body_item';

interface NetworkQualityProps extends CallBodyItemBaseProps {
  networkIssuesDetected: boolean,
  audioIssuesDetected: boolean,
}

const NetworkQuality: FC<NetworkQualityProps> = ({ networkIssuesDetected, audioIssuesDetected, hide, onClose }) => {
  let netWorkMessage = (
    <div className="form-group">
      <label className="form-label d-flex align-items-center">
        <i className="ri-checkbox-circle-fill text-success lh-1 mg-r-5 tx-18" />
        <span className="tx-semibold text-dark">Network</span>
      </label>
      <p className="mg-b-0">No network issues detected</p>
    </div>
  );
  if (networkIssuesDetected) {
    netWorkMessage = (
      <div className="form-group">
        <label className="form-label d-flex align-items-center">
          <i className="ri-error-warning-fill text-warning lh-1 mg-r-5 tx-18" />
          <span className="tx-semibold text-dark">Network</span>
        </label>
        <p className="mg-b-0">Network issues detected that may result in poor call quality</p>
        <label className="form-label d-flex align-items-center">
          <span className="tx-semibold text-dark">Recommendation:</span>
        </label>
        <p className="mg-b-0">Check internet connection or move to an area with better WiFi reception/strenght</p>
      </div>
    );
  }

  let audioMessage = (
    <div className="form-group mg-b-0">
      <label className="form-label d-flex align-items-center">
        <i className="ri-checkbox-circle-fill text-success lh-1 mg-r-5 tx-18" />
        <span className="tx-semibold text-dark">Audio</span>
      </label>
      <p className="mg-b-0">No audio issues detected</p>
    </div>
  );

  if (audioIssuesDetected) {
    audioMessage = (
      <div className="form-group">
        <label className="form-label d-flex align-items-center">
          <i className="ri-error-warning-fill text-warning lh-1 mg-r-5 tx-18" />
          <span className="tx-semibold text-dark">Audio</span>
        </label>
        <p className="mg-b-0">Audio issues detected that may result in poor audio quality</p>
        <label className="form-label d-flex align-items-center">
          <span className="tx-semibold text-dark">Recommendation:</span>
        </label>
        <p className="mg-b-0">Make sure you are not muted or check your audio settings</p>
      </div>
    );
  }
  return (
    <CallBodyItem itemTitle="Network Quality" hide={hide} onClose={onClose}>
      {netWorkMessage}
      {audioMessage}
    </CallBodyItem>
  );
};

export default NetworkQuality;
