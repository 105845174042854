import React, { FC } from 'react';

import { Avatar } from 'styles/common';
import { getAvatarColor, getInitials } from 'dwell/views/chat/common/utils';
import { Agent as IAgent, PropertyTeam } from 'dwell/store/voice/action-types';
import {
  Agent as AgentItem,
  AgentBody,
  Radio,
} from './styles';

interface AgentProps {
  agent: IAgent | PropertyTeam,
  selected: boolean,
  onAgentSelected: (agent: IAgent | PropertyTeam) => void
}

const Agent: FC<AgentProps> = ({ agent, selected, onAgentSelected }) => (
  <AgentItem onClick={() => onAgentSelected(agent)}>
    <Avatar
      className={`avatar ${getAvatarColor(agent.name, agent.id)}`}
      online
    >
      <span>{getInitials(agent.name)}</span>
    </Avatar>
    <AgentBody>
      <h6>{agent.name}</h6>
      <p><span>{agent.role}</span>{(agent as IAgent).office && <span>{(agent as IAgent).office}</span>}</p>
    </AgentBody>
    <Radio selected={selected} />
  </AgentItem>
);

export default Agent;
