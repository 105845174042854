import React, { FC, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isEqual, noop, get } from 'lodash';

import actions from 'dwell/actions/index';
import corporateActions from 'corporate/store/actions';
import { selectProperties, selectSelectedProperties } from 'dwell/store/property/reducers';
import { selectShowSelector, selectPropertiesCount } from 'dwell/store/properties_selector/reducers';
import actionsSite from 'site/store/actions';
import { CustomerProps } from 'site/store/customer/action-types';
import { selectCustomers } from 'site/store/customer/reducers';
import { selectUserRole, selectCurrentUser } from 'dwell/store/user/reducers';
import { roleTypes, platformTypes } from 'site/constants';
import { getRandomNumber } from 'dwell/constants';

import CustomerMenu from './_customer_menu';
import PropertyMenu from './_property_menu';
import { PropertyMenu as PropertyMenuStyle, BackDrop } from './styles';

const allCustomersCustomer = { id: 0, customer_name: 'All Customers', logo: '', properties: [] } as CustomerProps;

const MultiPropertySelector: FC = () => {
  const [toggleTab, setToggleTab] = useState<boolean>(false);
  const [currentCustomer, setCurrentCustomer] = useState<CustomerProps>(allCustomersCustomer);

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const allUserProperties = useSelector(selectProperties);
  const selectedProperties = useSelector(selectSelectedProperties);
  const arePropertiesLoaded = useSelector(state => state.property.isPropertiesDataLoaded);
  const shouldReloadProperties = useSelector(state => state.property.shouldReloadProperties);
  const show = useSelector(selectShowSelector);
  const userRole = useSelector(selectUserRole);
  const propertiesCount = useSelector(selectPropertiesCount);
  const customers: CustomerProps[] = useSelector(selectCustomers);
  const { getCustomers } = actionsSite.customer;
  const { getProperties } = actions.property;
  const { setPropertiesCount, setShowSelector, setAvailableProperties } = actions.propertiesSelector;
  const { getCallRescoresMeta } = actions.scoredCalls;
  const { updateUserAccessedProperties, updateUserSelectedCustomer } = actions.user;
  const { getCustomerInformation } = corporateActions.customer;

  const isLLAdmin = userRole === roleTypes.LIFT_LYTICS_ADMIN;
  const isCallScorer = currentUser.is_call_scorer;
  const isChatReviewer = currentUser.is_chat_reviewer;
  const adminCallsAndChats = isCallScorer || isChatReviewer;

  const selectedPropertyIds = useMemo(() => selectedProperties.map(p => p.id), [selectedProperties]);

  const organizedCustomers = {};
  customers.forEach((customer) => {
    organizedCustomers[customer.id] = customer;
  });

  const fetchProperties = (page = 1, limit = 100) => {
    dispatch(getProperties({ page, limit }, true)).then(({ result: { data: { next } } }) => {
      if (next) {
        fetchProperties(page + 1, limit);
      }
    });
  };

  useEffect(() => {
    if (userRole || shouldReloadProperties) {
      fetchProperties(1, 50);
    }
  }, [userRole, shouldReloadProperties]);

  useEffect(() => {
    if (adminCallsAndChats) {
      dispatch(getCallRescoresMeta());
    }
  }, [adminCallsAndChats]);

  useEffect(() => {
    if (isLLAdmin) {
      dispatch(getCustomers({ show_all: true }));
    }
  }, [isLLAdmin]);

  const selectCustomerHandler = (customer: CustomerProps) => {
    // if customer id is 0, it means 'All Customers' is selected
    const customerId = customer.id;
    const newCustomer = customerId === 0 ? null : customerId;
    if (currentUser.current_customer === newCustomer) return;
    dispatch(updateUserSelectedCustomer(currentUser.id, { current_customer: newCustomer })).then(() => {
      // if 'All Customers' is selected and there are no properties selected, and there are properties available
      // select the first property
      dispatch(getCustomerInformation());
      if (customerId === 0 && !isEmpty(allUserProperties) && isEmpty(selectedProperties)) {
        const newProperties = [allUserProperties[0]];
        dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: newProperties }, noop));
        return;
      }

      // if 'All Customers' is selected but there are properties selected, finish the function
      if (customerId === 0) return;

      // A different customer is selected
      const ids = selectedProperties.length ? selectedPropertyIds : [];
      let newProperties = [];

      // if the selected customer has properties
      const { properties } = customer;
      if (!isEmpty(properties)) {
        // Filter the properties that are selected
        const filteredProperties = selectedProperties.filter(({ id }) => properties.includes(id));
        // If there are properties selected, use them
        if (filteredProperties.length) {
          newProperties = filteredProperties;
        } else {
          // If there are no properties selected, select a random property from downloaded properties
          // which belong to the selected customer
          const customPropertiesFromAll = allUserProperties.filter(({ id }) => properties.includes(id));
          if (customPropertiesFromAll.length) {
            // Here we prioritize properties that are not SITE_ONLY
            const nonSiteOnlyProperties = customPropertiesFromAll.filter(({ platforms }) => !platformTypes.isSiteOnly(platforms));
            let randomNumber = 0;
            if (nonSiteOnlyProperties.length) {
              randomNumber = getRandomNumber(0, nonSiteOnlyProperties.length - 1);
              newProperties = [nonSiteOnlyProperties[randomNumber]];
            } else {
              randomNumber = getRandomNumber(0, customPropertiesFromAll.length - 1);
              newProperties = [customPropertiesFromAll[randomNumber]];
            }
          }
        }
      }
      const newIds = newProperties.map(p => p.id);
      const propertiesChanged = !isEqual(ids.sort(), newIds.sort());
      // if the accessed properties are different from the selected properties, update the accessed properties
      if (propertiesChanged) {
        dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: newProperties }, noop));
      }
    });
  };

  useEffect(() => {
    if (isEmpty(currentUser)) return;
    const { current_customer } = currentUser;
    if (!current_customer) {
      setCurrentCustomer(allCustomersCustomer);
      return;
    }
    if (!customers.length) return;
    const customer = get(organizedCustomers, current_customer, null);
    if (!customer) return;
    setCurrentCustomer(customer);
  }, [currentUser, customers]);

  useEffect(() => {
    if (!isEmpty(selectedPropertyIds)) {
      const { properties, customer_name } = currentCustomer;
      if (!isEmpty(allUserProperties) && properties.length) {
        let filteredProperties = allUserProperties.filter(({ id }) => properties.includes(id));
        dispatch(setPropertiesCount(filteredProperties.length));
        filteredProperties = filteredProperties.filter(({ id }) => !selectedPropertyIds.includes(id));
        dispatch(setAvailableProperties(filteredProperties));
      } else if (!isEmpty(allUserProperties) && customer_name === 'All Customers') {
        const newState = allUserProperties.filter(({ id }) => !selectedPropertyIds.includes(id));
        dispatch(setAvailableProperties(newState));
        if (allUserProperties.length > propertiesCount) {
          dispatch(setPropertiesCount(allUserProperties.length));
        }
      } else if (!isEmpty(allUserProperties) && customer_name !== 'All Customers' && !properties.length) {
        const newState = [];
        dispatch(setAvailableProperties(newState));
        dispatch(setPropertiesCount(newState.length));
      }
    } else if (allUserProperties.length && currentCustomer.customer_name === 'All Customers') {
      dispatch(setAvailableProperties(allUserProperties));
      dispatch(setPropertiesCount(allUserProperties.length));
    } else {
      dispatch(setAvailableProperties([]));
      dispatch(setPropertiesCount(0));
    }
  }, [currentCustomer, allUserProperties, propertiesCount, selectedPropertyIds]);

  const backDropClicked = () => {
    document.body.classList.remove('scroll_locked');
    dispatch(setShowSelector(false));
  };

  return (
    <React.Fragment>
      <PropertyMenuStyle show={show}>
        <PropertyMenu
          toggleTab={toggleTab}
          setToggleTab={setToggleTab}
          propertiesLoading={!arePropertiesLoaded}
          currentCustomer={currentCustomer}
        />
        <CustomerMenu toggleTab={toggleTab} setToggleTab={setToggleTab} setCurrentCustomer={customer => selectCustomerHandler(customer as CustomerProps)} />
      </PropertyMenuStyle>
      <BackDrop show={show} onClick={backDropClicked} />
    </React.Fragment>
  );
};

export default MultiPropertySelector;
