import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useTimeout = (callback: (clear: () => void) => void, time: number, dependencies: unknown[]): MutableRefObject<number> => {
  const ref = useRef(0);
  const [timeout, setTimeoutId] = useState(0);

  const clear = () => {
    ref.current = 0;
    setTimeoutId(0);
  };

  useEffect(() => {
    if (!timeout) {
      ref.current = setTimeout(() => callback(clear), time);
      setTimeoutId(ref.current);
    }
  }, [...dependencies, timeout]);

  return ref;
};

export default useTimeout;
