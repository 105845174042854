import produce from 'immer';
import { sortBy, unionBy } from 'lodash';
import moment from 'moment';
import { ActionType as PusherActionType } from 'dwell/store/pusher/action-types';
import { ActionType as LeadActionType } from 'dwell/store/lead/action-types';
import { Action, ActionType, AgentRequest, ChatMessage, Prospect, Typing, ActiveProspect } from './action-types';

export interface ChatState {
  isSubmitting: boolean;
  isProspectsLoading: boolean;
  isProspectsLoaded: boolean;
  isRenderedByCommand: boolean;
  errorMessage: string;
  isSendingText: boolean;
  newAgentRequest: boolean;
  isChatMinimized: boolean;
  conversations: ChatMessage[];
  suggestions: [];
  prospects: Prospect[];
  leadProspects: [];
  newMessage: ChatMessage;
  prospectsRequestedAgents: AgentRequest[];
  availableAgentsCount: number;
  chatType: string;
  activeChats: ActiveProspect[];
  activeProperties: number[];
  activeSlide: number;
  currentTab: string;
  typingData: { isTyping: boolean; prospect: number };
  tour_scheduling_in_progress?: boolean;
  allProspectsCount: number;
  downloadedCount: number;
}

const initialState: ChatState = {
  isSubmitting: false,
  isProspectsLoading: false,
  isProspectsLoaded: false,
  isRenderedByCommand: false,
  errorMessage: null,
  isSendingText: false,
  newAgentRequest: false,
  isChatMinimized: true,
  conversations: [],
  suggestions: [], // keep it for the moment until we decide finally to remove it
  prospects: [],
  leadProspects: [],
  newMessage: {} as ChatMessage,
  prospectsRequestedAgents: [],
  availableAgentsCount: 0,
  chatType: 'chat', // chat or sms
  activeChats: [],
  activeProperties: [],
  activeSlide: 0,
  currentTab: '',
  typingData: { isTyping: false, prospect: null },
  tour_scheduling_in_progress: false,
  allProspectsCount: 0,
  downloadedCount: 0,
};

const sortByDate = arr => arr.sort((a, b) => b.last_message_date - a.last_message_date);

export const selectActiveProperties = (state: {prospectChat: ChatState}): number[] => state.prospectChat.activeProperties;

export default produce((state: ChatState = initialState, action: Action): ChatState => {
  switch (action.type) {
    case ActionType.GET_PROSPECTS_REQUEST:
      state.isProspectsLoading = true;
      state.isProspectsLoaded = false;
      break;
    case ActionType.GET_PROSPECTS_SUCCESS: {
      const { prospects } = state;
      const newProspects = action.result.data.results.filter(i => !prospects.find(j => j.id === i.id)).concat(state.prospects);
      state.isProspectsLoading = false;
      state.isProspectsLoaded = true;
      state.prospects = newProspects;
      state.suggestions = sortByDate(newProspects);
      state.allProspectsCount = action.result.data.count;
      state.downloadedCount += action.result.data.results.length;
      break;
    }
    case ActionType.GET_PROSPECTS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isProspectsLoading = false;
      state.isProspectsLoaded = false;
      break;

    case ActionType.JOIN_PROSPECT_CHAT_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.JOIN_PROSPECT_CHAT_SUCCESS: {
      const { prospect, agent } = action.result.data;
      const currentProspect = state.prospects.find(p => p.id === prospect);
      if (currentProspect) {
        currentProspect.active_agent = agent;
        currentProspect.joined_agents = currentProspect.joined_agents.concat([agent]);
        state.prospects = state.prospects.filter(p => p.id !== prospect).concat([currentProspect]);
      }
      // update for current prospect if it's same
      // todo should adjust logic here with new active chats logic
      if (state.activeChats.map(i => i.id).includes(prospect)) {
        const matching = state.activeChats.find(p => p.id === prospect);
        state.activeChats = state.activeChats.filter(p => p.id !== prospect).concat([matching]);
      }
      break;
    }
    case ActionType.JOIN_PROSPECT_CHAT_FAILURE:
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.CHANGE_PROSPECT_CHAT_STATUS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CHANGE_PROSPECT_CHAT_STATUS_SUCCESS: {
      const { data } = action.result;
      const updatedProspects = state.prospects.filter(p => p.id !== data.id).concat([data]);
      state.isSubmitting = false;
      state.prospects = updatedProspects;
      state.suggestions = data.is_archived ? sortByDate(updatedProspects) : state.suggestions;
      break;
    }
    case ActionType.CHANGE_PROSPECT_CHAT_STATUS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.SEND_CHAT_MESSAGE_REQUEST:
      state.isSendingText = true;
      break;
    case ActionType.SEND_CHAT_MESSAGE_SUCCESS: {
      const { data } = action.result;
      const pro = state.prospects.find(p => p.id === data.prospect);
      pro.last_message = data.message;
      pro.last_message_date = data.date;
      state.isSendingText = false;
      state.conversations = [...state.conversations.slice(), data];
      state.prospects = state.prospects.filter(prospect => prospect.id !== pro.id).concat([{ ...pro }]);
      break;
    }
    case ActionType.SEND_CHAT_MESSAGE_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSendingText = false;
      break;

    case ActionType.PROSPECT_CHAT_READ_ALL_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.PROSPECT_CHAT_READ_ALL_SUCCESS: {
      const { data } = action.result;
      state.isSubmitting = false;
      const newProspects = state.prospects.map((prospect) => {
        const updatedProspect = { ...prospect };
        if (updatedProspect.id === data.id) {
          updatedProspect.unread_count = 0;
        }
        return updatedProspect;
      });
      state.prospects = newProspects;
      break;
    }
    case ActionType.PROSPECT_CHAT_READ_ALL_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSendingText = false;
      break;

    case ActionType.DISMISS_NEW_MESSAGE_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.DISMISS_NEW_MESSAGE_SUCCESS: {
      const { data } = action.result;
      state.isSubmitting = false;
      state.prospects = [data].concat(state.prospects.filter(p => p.id !== data.id));
      break;
    }
    case ActionType.DISMISS_NEW_MESSAGE_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const { pusherModel } = action;
      switch (pusherModel) {
        case 'chatprospect': {
          const row = action.row as Prospect;
          if (state.activeProperties.includes(row.property)) {
            state.prospects = [row].concat(state.prospects);
          }
          break;
        }
        case 'chatconversation': {
          const { conversations, prospects } = state;
          const row = action.row as ChatMessage;
          if (state.activeProperties.includes(row.property)) {
            const found = conversations.filter(i => i.id === row.id);
            if (!found.length) {
              state.newAgentRequest = row.type === 'AGENT_REQUEST';
              state.availableAgentsCount = row.available_agents_count;
              state.conversations = sortBy(conversations.concat([row]), o => moment(o.date));

              if (row.type !== 'AGENT_REQUEST') {
                state.newMessage = ['AGENT', 'TEMPLATE'].includes(row.type) ? ({} as ChatMessage) : ({ prospect: row.prospect, type: row.type } as ChatMessage);
              }

              const pro = prospects.find(p => p.id === row.prospect);
              if ((['AGENT_REQUEST', 'JOINED'].includes(row.type) || row.action === 'SCHEDULE_TOUR') && pro && !pro.should_display_in_chat) {
                pro.should_display_in_chat = true;
              }
              if (row.type !== 'JOINED') {
                if (pro && !['AGENT_REQUEST'].includes(row.type)) {
                  if (!row.message.includes('unit-form') && !row.is_form_message) {
                    pro.last_message = row.message;
                    pro.last_message_date = row.date;
                  }
                  if (!['AGENT', 'TEMPLATE'].includes(row.type)) {
                    pro.unread_count += 1;
                    pro.has_not_seen_new_message = true;
                  }
                  if (row.type === 'PROSPECT' || !pro.last_prospect_message) {
                    pro.last_prospect_message = row.message;
                    pro.last_prospect_message_date = row.date;
                    if (row.is_form_message) {
                      if (row.message.includes('<div class="schedule-form">')) {
                        pro.last_prospect_formatted_message = 'Guest card completed';
                      } else if (row.message.includes('<div class="schedule-form" id="tour-card">') || row.message.includes('<div class="calendar-links">')) {
                        pro.last_prospect_formatted_message = 'Tour card completed';
                      }
                    } else {
                      pro.last_prospect_formatted_message = row.message;
                    }
                    if (pro.is_archived) {
                      pro.is_archived = false;
                    }
                    pro.is_online = true;
                  }
                  state.prospects = state.prospects.filter(prospect => prospect.id !== pro.id).concat([pro]);
                }
              }
            }
          }
          break;
        }
        case 'agentrequest': {
          const row = action.row as AgentRequest;
          if (!state.prospectsRequestedAgents.map(request => request.id).includes(row.id)) {
            const currentProspect = state.prospects.find(p => p.id === row.prospect);
            if (currentProspect) {
              if (currentProspect.is_archived) {
                currentProspect.is_archived = false;
                state.prospects = state.prospects.filter(prospect => prospect.id !== currentProspect.id).concat([{ ...currentProspect }]);
              }
            }
            state.prospectsRequestedAgents = [...state.prospectsRequestedAgents].concat([row]);
          }
          break;
        }
      }
      break;
    }
    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const { pusherModel } = action;
      switch (pusherModel) {
        case 'chatprospect': {
          const row = action.row as Prospect;
          if (state.activeProperties.includes(row.property)) {
            state.prospects = [row].concat(state.prospects.filter(p => p.id !== row.id));
          }
          break;
        }
        case 'chatconversation': {
          const row = action.row as ChatMessage;
          state.conversations = [row].concat(state.conversations.filter(c => c.id !== row.id));
          const prospect = state.prospects.find(p => p.id === row.prospect);
          if ((['AGENT_REQUEST', 'JOINED'].includes(row.type) || row.action === 'SCHEDULE_TOUR') && prospect && !prospect.should_display_in_chat) {
            state.prospects = state.prospects.filter(p => p.id !== row.prospect).concat([{ ...prospect, should_display_in_chat: true }]);
          }
          break;
        }
        case 'agentrequest': {
          const row = action.row as AgentRequest;
          state.prospectsRequestedAgents = unionBy([row], state.prospectsRequestedAgents, 'id');
          break;
        }
        case 'typing': {
          const row = action.row as Typing;
          state.typingData = { isTyping: row.is_typing, prospect: row.prospect_id };
          break;
        }
      }
      break;
    }

    case ActionType.CLEAR_NEW_MESSAGE_ALERT:
      state.newMessage = {} as ChatMessage;
      break;

    case ActionType.CLEAR_NEW_AGENT_REQUEST_ALERT:
      state.newAgentRequest = false;
      break;

    case ActionType.REMOVE_FROM_PROSPECTS_REQUESTED_AGENT:
      state.prospectsRequestedAgents = state.prospectsRequestedAgents.filter(request => request.prospect !== action.id);
      break;

    case LeadActionType.GET_LEAD_BY_ID_SUCCESS:
      state.prospects = sortByDate(unionBy(action.result.data.chat_prospects, state.prospects, 'id'));
      break;

    case ActionType.DECLINE_AGENT_REQUEST_SUCCESS:
      state.prospectsRequestedAgents = [...state.prospectsRequestedAgents.filter(request => request.id !== action.result.data.id)].concat([action.result.data]);
      break;
    case ActionType.DECLINE_AGENT_REQUEST_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.SET_PROSPECTS_OFFLINE: {
      const updatedProspects = state.prospects.map((prospect) => {
        const pro = { ...prospect };
        if (pro.is_online && action.ids.includes(pro.id)) {
          pro.is_online = false;
        }
        return pro;
      });
      state.prospects = updatedProspects;
      break;
    }

    case ActionType.SET_CHAT_MINIMISE_STATUS:
      state.isChatMinimized = action.data;
      break;

    case ActionType.SET_CHAT_IS_RENDERED:
      state.isRenderedByCommand = action.data;
      break;

    case ActionType.SET_CHAT_TYPE:
      state.chatType = action.data;
      break;

    case ActionType.SET_CHAT_AS_ACTIVE: {
      if (
        !state.activeChats
          .filter(i => i.isSMS === action.contact.isSMS)
          .map(i => i.id)
          .includes(action.contact.id)
      ) {
        const newContacts = [...state.activeChats].concat(action.contact);
        if (state.activeChats.filter(i => !i.circled).length < 3) {
          state.activeChats = newContacts;
        } else {
          const index = newContacts.findIndex(i => !i.circled);
          newContacts[index].circled = true;
          state.activeChats = newContacts;
        }
      }
      break;
    }

    case ActionType.CLEAR_ACTIVE_CHATS:
      state.activeChats = [];
      break;

    case ActionType.SET_ACTIVE_CHAT_SLIDE: {
      let newContacts = [...state.activeChats];
      newContacts = newContacts.map((i, ind) => {
        if (ind >= action.activeSlide && ind <= action.activeSlide + 2) return { ...i, circled: false };
        return { ...i, circled: true };
      });
      state.activeSlide = action.activeSlide;
      state.activeChats = newContacts;
      break;
    }

    case ActionType.SET_ACTIVE_PROPERTIES:
      state.activeProperties = action.ids;
      break;

    case ActionType.GET_PROSPECTS_BY_PROPERTY_REQUEST:
      state.isProspectsLoading = true;
      break;
    case ActionType.GET_PROSPECTS_BY_PROPERTY_SUCCESS:
      state.isProspectsLoading = false;
      state.prospects = state.prospects.filter(p => !action.result.data.results.map(item => item.id).includes(p.id)).concat(action.result.data.results);
      break;
    case ActionType.GET_PROSPECTS_BY_PROPERTY_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isProspectsLoading = false;
      break;

    case ActionType.REMOVE_FROM_ACTIVE_CHATS:
      state.activeChats = state.activeChats.filter(i => !(i.id === action.contact.id && i.isSMS === action.contact.isSMS));
      break;

    case ActionType.REMOVE_LEAD_FROM_ACTIVE_CHATS:
      state.activeChats = state.activeChats.filter(i => !(i.id === action.leadId));
      break;

    case ActionType.SET_CURRENT_TAB:
      state.currentTab = action.tabKey;
      break;

    case ActionType.GET_CHAT_CONVERSATIONS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_CHAT_CONVERSATIONS_SUCCESS: {
      const { conversations } = state;
      const newConversations = action.result.data.results.filter(i => !conversations.find(j => j.id === i.id)).concat(state.conversations);
      state.isSubmitting = false;
      state.conversations = newConversations;
      break;
    }
    case ActionType.GET_CHAT_CONVERSATIONS_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.REORDER_ACTIVE_CHATS: {
      let newContacts = [...state.activeChats];
      if (newContacts.filter(i => !i.circled).length >= 3) {
        const index = newContacts.findIndex(i => !i.circled);
        newContacts[index].circled = true;
      }
      newContacts = newContacts.filter(i => !(i.id === action.contact.id && i.isSMS === action.contact.isSMS)).concat([{ ...action.contact, circled: false, minimized: false }]);
      state.activeChats = newContacts;
      break;
    }

    case ActionType.MINIMIZE_CHAT_WINDOW: {
      const newContacts = [...state.activeChats];
      const index = newContacts.findIndex(i => i.id === action.contact.id && i.isSMS === action.contact.isSMS);
      if (index === -1) return ({ ...state });
      newContacts[index].minimized = action.contact.minimized;
      state.activeChats = newContacts;
      break;
    }

    case ActionType.CLEAR_TYPING:
      state.typingData = { isTyping: false, prospect: null };
      break;

    case ActionType.CLEAR_PROSPECTS:
      state.prospects = [];
      state.downloadedCount = 0;
      state.allProspectsCount = 0;
      break;

    case ActionType.REMOVE_PROSPECT:
      state.prospects = state.prospects.filter(p => p.lead !== action.leadId);
      break;

    case ActionType.SET_DRAFT_MESSAGE:
      if (state.activeChats.map(i => i.id).includes(action.id)) {
        const newActiveChats = [...state.activeChats];
        const objIndex = newActiveChats.findIndex((obj => obj.id === action.id));
        newActiveChats[objIndex].draft = action.text;
        state.activeChats = newActiveChats;
      }
      break;
  }

  return state;
});
