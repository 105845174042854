import { paths } from 'dwell/constants';
import { ManageRequestProps } from 'src/interfaces';
import { build } from 'dwell/constants/paths';
import { ActionType, Action } from './action-types';

export default {
  getPMSLogs: (leadId: number, params: ManageRequestProps = {}): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PMS_LOGS_REQUEST,
        ActionType.GET_PMS_LOGS_SUCCESS,
        ActionType.GET_PMS_LOGS_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.PMS_LOGS, leadId), { params }),
    },
  }),

  resetPMSLogs: (): Action => ({
    type: ActionType.RESET_PMS_LOGS,
  }),
};
