import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, OtherFeeItemState } from './action-types';

export const initialState: OtherFeeItemState = {
  otherFeeItems: [],
};

export default produce((state: OtherFeeItemState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_OTHER_FEE_ITEM_SUCCESS:
      state.otherFeeItems = [...state.otherFeeItems, action.result.data];
      break;

    case ActionType.DELETE_OTHER_FEE_ITEM_REQUEST:
      state.otherFeeItems = state.otherFeeItems.filter(i => i.id !== action.payload);
      break;

    case ActionType.DELETE_OTHER_FEE_ITEM_SUCCESS:
      state.otherFeeItems = unionBy([action.result.data], state.otherFeeItems, 'id');
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.otherFeeItems = action.result.data.other_fee_items;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
