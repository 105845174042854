// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect, useState, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { isEmpty, isEqual, debounce } from 'lodash';
import cn from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppBreadcrumb } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';

import routes, { manageRoutes, siteRoutes } from 'src/routes';

import { LOGGED_ACCOUNT, RECENT_PROPERTY_HYPHENS, REDIRECT_PATH, redirectPaths, ACTIVE_FILTER_ID, LAST_TIME_AGENT_UNAVAILABLE_TIMEOUT } from 'dwell/constants';

import 'spinkit/css/spinkit.css';
import { build, client, addQueryParamsToPath } from 'dwell/constants/paths';
import { getPropertyId, checkIfAllPropertiesAreInactive } from 'src/utils';
import { canAccessSupportPortal } from 'dwell/views/support_portal/utils';
import Chat from 'dwell/views/chat/single_chat';
import actions from 'dwell/actions';
import LeadDetailLayout from 'dwell/views/lead/layout';
import ResidentDetailLayout from 'resident/views/layout';
import ApplicationDetailLayout from 'application/views/layout';
import CBResidentDetailLayout from 'resident/views/credit_builder_overview/layout';
import DefaultAside from 'containers/default_layout/DefaultAside';
import { platformTypes, roleTypes } from 'site/constants';
import { PropertyProps } from 'dwell/store/property/action-types';
import SkeletonLoader from 'site/views/skeleton_loader';
import focusBarActions from 'dwell/store/focus_bar/action-creators';
import { selectShowFocusBar } from 'dwell/store/focus_bar/reducers';
import { selectIsTheFirstPropertyCBPlatform } from 'dwell/store/property/reducers';
import { FocusBar } from 'dwell/components';
import { useQuery } from 'src/hooks/useQuery';

import MultiPropertySelector from 'containers/multi_property_selector';
import DefaultHeader from 'containers/default_header';
import PropertyProfile from 'dwell/views/property_profile';
import MicrophonePerm from 'containers/browser_perms';
import { Main, ContainerFluid } from './styles';

interface DefaultLayoutProps {
  logout: () => void;
  setSelectedProperties: (properties: PropertyProps[]) => void;
  currentUser: {
    id: number;
    has_advanced_reports_access: boolean;
    is_call_scorer: boolean;
    is_chat_reviewer: boolean;
    role: string;
    accessed_properties: PropertyProps[];
    is_team_account: boolean;
    hobbes_agent_chat_enabled: boolean;
    is_agent_chat_available_today: boolean;
    property_to_get_questions: { id: number; name: string };
    is_available: boolean;
    corporate_site_enabled: boolean;
  };
  selectedProperties: PropertyProps[];
  isSessionTimedOut: boolean;
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ currentUser, isSessionTimedOut, logout, setSelectedProperties, selectedProperties }) => {
  const query = useQuery();
  const forcedAgentQuestion = query.get('aq');

  const [adminCallsAndChats, setAdminCallsAndChats] = useState(false);

  const { updatePropertyToGetQuestions } = actions.agentQuestion;

  const dispatch = useDispatch();
  const { getTeamMates } = actions.user;
  const teamUsersLoaded = useSelector(state => state.user.teamUsersLoaded);
  const isUnAvailableManually = useSelector(state => state.user.isUnAvailableManually);
  const isUserAvailable = useSelector(state => state.user.currentUser.is_available);
  const showFocusBar = useSelector(selectShowFocusBar);
  const isTheFirstPropertyCBPlatform = useSelector(selectIsTheFirstPropertyCBPlatform);

  const { pathname, state } = useLocation();
  const { push } = useHistory();

  const { setActiveProperties } = actions.prospectChat;
  const { updateUserAvailableStatus } = actions.user;

  const propertiesIds = selectedProperties.map(p => p.id).sort();

  const newPipelineEnabled = useMemo(() => selectedProperties.every(i => i.new_pipeline_enabled), [selectedProperties]);

  const arePropertiesInactive = useMemo(() => checkIfAllPropertiesAreInactive(selectedProperties), [selectedProperties]);

  const TIMEOUT_TIME = 5;
  const timeoutTime = TIMEOUT_TIME * 60 * 1000;
  const userId = currentUser?.id;
  const isUserReviewer = currentUser?.is_chat_reviewer || currentUser?.is_call_scorer;

  const setAgentAvailability = (availabilityStatus: boolean) => {
    if (!availabilityStatus && isUserReviewer) return;
    const lastTimeAgentUnavailable = localStorage.getItem(LAST_TIME_AGENT_UNAVAILABLE_TIMEOUT);
    const lastTimeAgentUnavailableDate = lastTimeAgentUnavailable ? moment(lastTimeAgentUnavailable) : null;
    const isAgentUnavailableTimeout = lastTimeAgentUnavailableDate && moment().diff(lastTimeAgentUnavailableDate, 'minutes') < TIMEOUT_TIME;
    if (isAgentUnavailableTimeout && !availabilityStatus) return;
    if (userId && isUserAvailable !== availabilityStatus) {
      if (!availabilityStatus) {
        localStorage.setItem(LAST_TIME_AGENT_UNAVAILABLE_TIMEOUT, moment().toDate().toString());
      }
      dispatch(updateUserAvailableStatus(userId, { is_available: availabilityStatus }));
    }
  };

  const debouncedSetAgentAvailability = useMemo(() => debounce((status: bool) => setAgentAvailability(status), 500), [isUserAvailable, userId, isUserReviewer]);

  let agentInactivityTimeout = null;

  const removeTimeOutFromList = (timeoutName: number) => {
    if (window.registeredAgentInactivityTimeout) {
      const index = window.registeredAgentInactivityTimeout.indexOf(timeoutName);
      if (index > -1) {
        window.registeredAgentInactivityTimeout.splice(index, 1);
      }
    }
  };

  const appendTimeoutToList = (timeoutName: number) => {
    if (!window.registeredAgentInactivityTimeout) {
      window.registeredAgentInactivityTimeout = [timeoutName];
    } else {
      window.registeredAgentInactivityTimeout.push(timeoutName);
    }
  };

  const cleanAllTimeoutsInList = () => {
    if (window.registeredAgentInactivityTimeout) {
      window.registeredAgentInactivityTimeout.forEach((timeout) => {
        clearTimeout(timeout);
      });
      window.registeredAgentInactivityTimeout = [];
    } else {
      window.registeredAgentInactivityTimeout = [];
    }
  };

  useEffect(() => {
    if (!isUserReviewer) {
      agentInactivityTimeout = setTimeout(() => {
        removeTimeOutFromList(agentInactivityTimeout);
        debouncedSetAgentAvailability(false);
      }, timeoutTime);
      appendTimeoutToList(agentInactivityTimeout);
    }

    return () => {
      cleanAllTimeoutsInList();
      clearTimeout(agentInactivityTimeout);
    };
  }, []);

  const restartAgentInactivityTimeout = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
    const isMouseEvent = event.type === 'click';
    if (isUserReviewer) return;
    if (isUnAvailableManually && ((isMouseEvent && !event.target.dataset.status && !['join-button', 'rejoin-button', 'join-chat-btn'].includes(event.target.id)) || !isMouseEvent)) return;
    const noneAvailableButtonsClicked = !['available-to-chat', 'available-to-chat-div', 'available-to-chat-span', 'join-button', 'rejoin-button', 'join-chat-btn'].includes(event.target.id);
    if ((!isMouseEvent || (isMouseEvent && noneAvailableButtonsClicked)) && !isUserAvailable) {
      debouncedSetAgentAvailability(true);
    }
    const availableToChatParts = ['available-to-chat', 'available-to-chat-div', 'available-to-chat-span'];
    const allowedButtonsActions = ((availableToChatParts.includes(event.target.id) && event.target.dataset.status === 'UNAVAILABLE') || event.target.id === 'join-button' || event.target.id === 'rejoin-button' || event.target.id === 'join-chat-btn');
    cleanAllTimeoutsInList();
    clearTimeout(agentInactivityTimeout);
    if (!isMouseEvent || (isMouseEvent && (noneAvailableButtonsClicked || allowedButtonsActions))) {
      agentInactivityTimeout = setTimeout(() => {
        if (window.registeredAgentInactivityTimeout) {
          const index = window.registeredAgentInactivityTimeout.indexOf(agentInactivityTimeout);
          if (index > -1) {
            window.registeredAgentInactivityTimeout.splice(index, 1);
          }
        }
        debouncedSetAgentAvailability(false);
      }, timeoutTime);
      appendTimeoutToList(agentInactivityTimeout);
    }
  };

  useEffect(() => {
    if (isUnAvailableManually) {
      cleanAllTimeoutsInList();
      clearTimeout(agentInactivityTimeout);
    }
  }, [isUnAvailableManually]);

  useEffect(() => {
    const redirectPath = localStorage.getItem(REDIRECT_PATH);
    const loggedAccount = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};

    if (loggedAccount && loggedAccount.access) {
      dispatch(focusBarActions.reloadData());
      const activeFilterId = localStorage.getItem(ACTIVE_FILTER_ID);
      if (!activeFilterId) {
        localStorage.setItem(ACTIVE_FILTER_ID, 'active_leads');
      }
    } else {
      if (pathname.includes(redirectPaths)) {
        localStorage.setItem(REDIRECT_PATH, pathname);
      }
      logout();
    }

    if (redirectPath && loggedAccount && loggedAccount.access) {
      push(redirectPath);
      localStorage.removeItem(REDIRECT_PATH);
    }
  }, []);

  useEffect(() => {
    const loggedAccount = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};
    if (loggedAccount && loggedAccount.access && !teamUsersLoaded && currentUser.id) dispatch(getTeamMates());
  }, [teamUsersLoaded, currentUser.id]);

  useEffect(() => {
    const isAdminCallsAndChats = currentUser.is_call_scorer || currentUser.is_chat_reviewer;
    if (isAdminCallsAndChats) {
      document.body.classList.add('aside-menu-show');
    }
    if (typeof currentUser.accessed_properties !== 'undefined') {
      const currentProperties = selectedProperties.map(p => p.id).sort();
      const newProperties = currentUser.accessed_properties.length ? currentUser.accessed_properties.map(p => p.id).sort() : [];
      if (!isEqual(currentProperties, newProperties)) {
        setSelectedProperties(currentUser.accessed_properties);
        if (!currentUser.property_to_get_questions) {
          dispatch(updatePropertyToGetQuestions(currentUser.id));
        }
      }
    }
    setAdminCallsAndChats(isAdminCallsAndChats);
  }, [currentUser, selectedProperties]);

  useEffect(() => {
    if (pathname === '/properties/leads' && selectedProperties.every(p => p.new_pipeline_enabled)) {
      push('/properties/pipeline');
    } else if (['/properties/pipeline', '/properties/leads'].includes(pathname) && isTheFirstPropertyCBPlatform) {
      push(build(client.CREDIT_BUILDER.VIEW, selectedProperties[0].external_id));
    } else if (pathname === '/properties/pipeline' && selectedProperties.some(p => !p.new_pipeline_enabled)) {
      if (isTheFirstPropertyCBPlatform) push(build(client.CREDIT_BUILDER.VIEW, selectedProperties[0].external_id));
      else push('/properties/leads');
    }
  }, [selectedProperties, isTheFirstPropertyCBPlatform]);

  useEffect(() => {
    if (propertiesIds.length) {
      dispatch(setActiveProperties(propertiesIds));
    }
  }, [propertiesIds]);

  const isViewSideBar = !(currentUser.role === roleTypes.GENERIC_ADMIN && selectedProperties.length > 1) && !currentUser.is_call_scorer && !currentUser.is_chat_reviewer;

  const breadcrumbRoutes = pathname === build(client.FOLLOWUPS.DETAILS, pathname.split('/')[1], pathname.split('/').pop()) && <AppBreadcrumb appRoutes={routes} />;

  const propertyId = localStorage.getItem(RECENT_PROPERTY_HYPHENS);
  const pipelinePath = newPipelineEnabled ? client.PIPELINE : client.LEADS.VIEW;
  const rootPath = arePropertiesInactive ? client.BASE : pipelinePath;
  const chatsPageOrLeadsPage = currentUser.is_chat_reviewer ? client.CHATS.OVERVIEW : pipelinePath;
  const forcedQuestionParam = forcedAgentQuestion ? { aq: forcedAgentQuestion } : {};
  const getRootComponent = () => {
    if (arePropertiesInactive) return <></>;
    return push({
      pathname: addQueryParamsToPath(
        currentUser.is_call_scorer ? client.RESCORE_CALLS :
          build(
            // eslint-disable-next-line no-nested-ternary
            currentUser.is_call_scorer ? client.CALLS : chatsPageOrLeadsPage,
            'properties',
          ),
        forcedQuestionParam,
      ),
      state,
    });
  };

  const redirectRoutes = (
    <Route
      exact
      path="/"
      render={getRootComponent}
    />
  );

  if (pathname.split('/')[1] === 'compete') {
    push(`/${propertyId}${pathname}`);
  }

  const pageRoutes = routes.map((route, idx) => {
    let ParentComp = React.Fragment;
    if (route.leadDetail) ParentComp = LeadDetailLayout;
    else if (route.residentDetail) ParentComp = ResidentDetailLayout;
    else if (route.applicationDetail) ParentComp = ApplicationDetailLayout;
    else if (route.cbResidentDetail) ParentComp = CBResidentDetailLayout;
    const checkIfPathisInRoutes = (Routes: { [s: string]: string }): boolean => Object.values(Routes).some(value => route.path.startsWith(value));
    const isPropertyIdInSelectedProperties = selectedProperties.map(p => p.external_id).includes(getPropertyId()) || false;
    const individualLeadPaths = { ...client.LEADS };
    delete individualLeadPaths.VIEW;
    let redirectPath = null;
    const property = selectedProperties.length === 1 ? selectedProperties[0] : null;
    if (currentUser.is_chat_reviewer && !currentUser.is_call_scorer && !checkIfPathisInRoutes(client.CHATS) && !arePropertiesInactive) redirectPath = build(client.CHATS.OVERVIEW, 'properties');
    else if (!currentUser.is_chat_reviewer && checkIfPathisInRoutes(client.CHATS)) redirectPath = rootPath;
    else if (currentUser.is_call_scorer && route.path !== client.CALLS && !route.path.startsWith(client.CHATS.OVERVIEW) && !arePropertiesInactive) redirectPath = client.RESCORE_CALLS;
    else if (!selectedProperties.length && route.isSite) redirectPath = rootPath;
    else if (property && platformTypes.isSiteOnly(property.platforms) && route.isDwell && currentUser.role !== roleTypes.GENERIC_ADMIN && !arePropertiesInactive) redirectPath = build(client.SITE_CONTENT.HOME, property.external_id);
    else if (property && platformTypes.isDwellOnly(property.platforms) && route.isSite) redirectPath = rootPath;
    else if (selectedProperties.length > 1 && checkIfPathisInRoutes(client.SITE_CONTENT)) redirectPath = rootPath;
    else if (selectedProperties.length && checkIfPathisInRoutes(client.SITE_CONTENT) && !isPropertyIdInSelectedProperties && !arePropertiesInactive) {
      redirectPath = build(client.SITE_CONTENT.HOME, property.external_id);
    } else if (route.path.startsWith(client.APARTMENT_AVAILABILITY) && (selectedProperties.length > 1)) {
      redirectPath = rootPath;
    } else if (selectedProperties.length && route.path.startsWith(client.APARTMENT_AVAILABILITY) && !isPropertyIdInSelectedProperties && !arePropertiesInactive) redirectPath = build(client.APARTMENT_AVAILABILITY, property.external_id);
    else if (checkIfPathisInRoutes(client.NURTURE_AUTOMATIONS) && (selectedProperties.length > 1 || [roleTypes.GENERIC_ADMIN, roleTypes.VIRTUAL_ADMIN].includes(currentUser.role) || (property && !property?.nurture_enabled))) {
      redirectPath = rootPath;
    } else if (selectedProperties.length && checkIfPathisInRoutes(client.NURTURE_AUTOMATIONS) && !isPropertyIdInSelectedProperties && !arePropertiesInactive) redirectPath = build(client.NURTURE_AUTOMATIONS.VIEW, property.external_id);
    // else if (selectedProperties.length && checkIfPathisInRoutes(individualLeadPaths) && !isPropertyIdInSelectedProperties) redirectPath = build(client.LEADS.VIEW, 'properties');
    else if (selectedProperties.length > 1 && route.path.startsWith(client.BULK_EMAIL)) redirectPath = rootPath;
    else if (selectedProperties.length && route.path.startsWith(client.BULK_EMAIL) && !isPropertyIdInSelectedProperties && !arePropertiesInactive) redirectPath = build(client.BULK_EMAIL, property.external_id);
    else if (property && !property?.allow_bulk_sign && route.path.startsWith(client.BULK_SIGN)) redirectPath = rootPath;
    else if (selectedProperties.length > 1 && route.path.startsWith(client.BULK_SIGN)) redirectPath = rootPath;
    else if (selectedProperties.length && route.path.startsWith(client.BULK_SIGN) && !isPropertyIdInSelectedProperties && !arePropertiesInactive) redirectPath = build(client.BULK_SIGN, property.external_id);
    else if (selectedProperties.length > 1 && checkIfPathisInRoutes(client.FOLLOWUPS)) redirectPath = rootPath;
    else if (selectedProperties.length && checkIfPathisInRoutes(client.FOLLOWUPS) && !isPropertyIdInSelectedProperties && !arePropertiesInactive) {
      redirectPath = build(client.FOLLOWUPS.VIEW, property.external_id);
    } else if (currentUser.role !== roleTypes.LIFT_LYTICS_ADMIN && route.path === client.MANAGE_CUSTOMERS) {
      redirectPath = rootPath;
    } else if (
      currentUser.role === roleTypes.GENERIC_ADMIN &&
      siteRoutes
        .concat(manageRoutes)
        .map(i => i.path)
        .includes(route.path)
    ) {
      redirectPath = rootPath;
    } else if ([roleTypes.GENERIC_ADMIN, roleTypes.PROPERTY_ADMIN].includes(currentUser.role) && route.path === client.HOBBES_SETTINGS) {
      redirectPath = rootPath;
    } else if (!canAccessSupportPortal(currentUser) && route.path === client.SUPPORT_PORTAL.VIEW) {
      redirectPath = rootPath;
    } else if (!currentUser.corporate_site_enabled && route.path === client.CORPORATE) {
      redirectPath = rootPath;
    } else if (arePropertiesInactive && ![client.MANAGE_CLIENTS, client.MANAGE_CUSTOMERS, client.MANAGE_PROPERTIES, client.MANAGE_USERS, client.HOBBES_SETTINGS].includes(route.path)) {
      redirectPath = client.BASE;
    }
    return (
      <Route
        key={idx}
        path={route.path}
        exact={route.exact}
        render={props =>
          (redirectPath ? (
            <Redirect to={redirectPath} />
          ) : (
            <ParentComp>
              <Switch>
                <route.component {...props} />
              </Switch>
            </ParentComp>
          ))
        }
      />
    );
  });

  return (
    <div
      className={cn('app', { 'aside-menu-show': adminCallsAndChats })}
      onClick={e => restartAgentInactivityTimeout(e)}
      onKeyDown={e => restartAgentInactivityTimeout(e)}
    >
      <MicrophonePerm />
      <MultiPropertySelector />
      <DefaultAside isViewSideBar={isViewSideBar} />
      <DefaultHeader isViewSideBar={isViewSideBar} />
      <div className="app-body">
        {!isSessionTimedOut ? (
          <Main isViewSideBar={isViewSideBar}>
            {breadcrumbRoutes}
            {!isEmpty(currentUser) && (
              <ContainerFluid fluid className={cn({ profile_page: pathname === client.PROFILE })}>
                {showFocusBar && <FocusBar />}
                <Switch>
                  {pageRoutes}
                  {redirectRoutes}
                </Switch>
              </ContainerFluid>
            )}
          </Main>
        ) : (
          <Main isViewSideBar={isViewSideBar}>
            <SkeletonLoader />
          </Main>
        )}
      </div>
      <ToastContainer
        autoClose={3000}
        position="bottom-center"
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          left: 0,
          marginLeft: 0,
          width: '100vw',
          zIndex: 1999,
        }}
      />
      {!isTheFirstPropertyCBPlatform && !adminCallsAndChats && !pathname.includes(build(client.MULTI_CHAT, getPropertyId())) && !arePropertiesInactive ? <Chat /> : null}
      {!isSessionTimedOut && !arePropertiesInactive && <PropertyProfile />}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  properties: state.property.properties,
  selectedProperties: state.property.selectedProperties,
  isSessionTimedOut: state.authentication.isSessionTimedOut,
});

export default connect(mapStateToProps, {
  ...actions.authentication,
  ...actions.property,
  ...actions.user,
})(DefaultLayout);
