import produce from 'immer';
import moment from 'moment';
import { sortBy, unionBy, isEmpty } from 'lodash';
import { PusherAction, ActionType as PusherActionType, PusherRow } from 'dwell/store/pusher/action-types';
import { ActionType as LeadActionType, BELeadData } from 'dwell/store/lead/action-types';
import { Action, ActionType, SMSMessage, SMSContact } from './action-types';
import { preFillState } from '../utils';
import { CommonStateType } from '../types';

export interface SMSState extends CommonStateType {
  isContactsLoading: boolean;
  isContactsLoaded: boolean;
  isConversationsLoaded: boolean;
  errorMessage: string;
  conversations: SMSMessage[];
  count: number;
  unread: number;
  contacts: SMSContact[];
  isSendingText: boolean;
  activeChat: SMSContact;
  isNotificationRedirection: boolean;
  allContactsCount: number;
  downloadedCount: number;
}

const initialState: SMSState = {
  isContactsLoading: false,
  isContactsLoaded: false,
  isConversationsLoaded: false,
  errorMessage: null,
  conversations: [],
  count: 0,
  unread: 0,
  contacts: [],
  isSendingText: false,
  activeChat: null,
  isNotificationRedirection: false,
  allContactsCount: 0,
  downloadedCount: 0,
};

const createContactByLead = (data: BELeadData) =>
  (data.phone_number && data.sms_opt_in_status === 'OPTIN_STATUS_OPTEDIN'
    ? [
      {
        id: data.id,
        name: `${data.first_name} ${data.last_name}`,
        phone_number: data.phone_number,
        last_message: null,
        last_message_date: null,
        property: data.property,
        unread_count: 0,
        property_can_sms: data.property_can_sms,
        property_name: data.property_name,
        property_external_id: data.property_external_id,
      } as SMSContact,
    ]
    : [] as SMSContact[]);

export const selectContacts = (state : { smsMessage: SMSState }) : SMSContact[] => state.smsMessage.contacts;
export const selectDownloadedCount = (state : { smsMessage: SMSState }) : number => state.smsMessage.downloadedCount;

export default produce((state: SMSState = initialState, action: Action): SMSState => {
  preFillState(state, action, Object.values(ActionType));

  let contact = {} as SMSContact;
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && !['lead', 'smscontent'].includes(pusherAction.pusherModel)) {
    return state;
  }
  switch (action.type) {
    case ActionType.GET_SMS_CONTACTS_REQUEST:
      state.isContactsLoading = true;
      state.isContactsLoaded = false;
      break;
    case ActionType.GET_SMS_CONTACTS_SUCCESS: {
      const { contacts } = state;
      const newContacts = action.result.data.results.filter(i => !contacts.find(j => j.id === i.id)).concat(state.contacts);
      state.isContactsLoading = false;
      state.isContactsLoaded = true;
      state.contacts = newContacts;
      state.allContactsCount = action.result.data.count;
      state.downloadedCount += action.result.data.results.length;
      break;
    }
    case ActionType.GET_SMS_CONTACTS_FAILURE:
      state.isContactsLoading = false;
      state.isContactsLoaded = false;
      break;

    case ActionType.GET_SMS_CONVERSATIONS_REQUEST:
      state.isSubmitting = true;
      state.isConversationsLoaded = false;
      break;
    case ActionType.GET_SMS_CONVERSATIONS_SUCCESS:
      state.isSubmitting = false;
      state.conversations = sortBy(unionBy(action.result.data.results, state.conversations, 'id'), (o: { date: string }) => new Date(o.date)) as SMSMessage[];
      state.count = action.result.data.count;
      state.isConversationsLoaded = true;
      break;
    case ActionType.GET_SMS_CONVERSATIONS_FAILURE:
      state.isContactsLoading = false;
      state.isContactsLoaded = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.SEND_SMS_REQUEST:
      state.isSendingText = true;
      break;
    case ActionType.SEND_SMS_SUCCESS:
      state.isSendingText = false;
      state.conversations = sortBy(unionBy([action.result.data], state.conversations, 'id'), o => moment(o.date));
      break;
    case ActionType.SEND_SMS_FAILURE:
      state.isSendingText = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.UPDATE_NOTIFICATION_REDIRECTION:
      state.isNotificationRedirection = action.data;
      break;

    case ActionType.READ_ALL_SMS_SUCCESS:
      if (!isEmpty(action.result.data)) {
        state.contacts = sortBy([action.result.data].concat(state.contacts.filter(c => c.id !== action.result.data.id)), o => moment(o.last_message_date));
      }
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = pusherAction.row as PusherRow;
      if (row.twilio_sid) {
        contact = state.contacts.find(c => c.id === row.lead);
        if (contact) {
          if (!row.is_read) contact.unread_count += 1;
          contact.last_message = row.message;
          contact.last_message_date = row.date;
          state.contacts = sortBy([contact].concat(state.contacts.filter(c => c.id !== row.lead)), o => moment(o.last_message_date));
        }
        state.conversations = sortBy([row as SMSMessage].concat(state.conversations.filter(c => c.id !== row.id)), o => moment(o.date));
      }
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = pusherAction.row as PusherRow;
      if (!row.twilio_sid) {
        contact = state.contacts.find(c => c.id === row.lead);
        if (contact) {
          contact.sms_opt_in_status = row.sms_opt_in_status;
          state.contacts = sortBy([contact].concat(state.contacts.filter(c => c.id !== row.lead)), o => moment(o.last_message_date));
        }
      }
      break;
    }

    case LeadActionType.CREATE_LEAD_SUCCESS: {
      const newContact = createContactByLead(action.result.data);
      state.contacts = newContact.concat(state.contacts);
      break;
    }

    case LeadActionType.UPDATE_LEAD_SUCCESS: {
      if (state.contacts.find(c => c.id === action.result.data.id)) break;
      const newContact = createContactByLead(action.result.data);
      state.contacts = newContact.concat(state.contacts);
      break;
    }

    case ActionType.CLEAR_CONTACTS:
      state.contacts = [];
      state.downloadedCount = 0;
      state.allContactsCount = 0;
      break;

    case ActionType.GET_CONTACT_BY_ID_REQUEST:
      state.isContactsLoading = true;
      state.isContactsLoaded = false;
      break;
    case ActionType.GET_CONTACT_BY_ID_SUCCESS: {
      state.isContactsLoading = false;
      state.isContactsLoaded = true;
      if (state.contacts.find(c => c.id === action.result.data.id)) break;
      const newSMSContact = action.result.data.phone_number ? [action.result.data] : [];
      state.contacts = newSMSContact.concat(state.contacts);
      break;
    }
    case ActionType.GET_CONTACT_BY_ID_FAILURE:
      state.isContactsLoading = false;
      state.isContactsLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.REMOVE_CONTACT: {
      const { contacts } = state;
      const newContacts = contacts.filter(c => c.id !== action.id);
      state.contacts = newContacts;
      break;
    }
  }

  return state;
});
