import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { getShortName } from 'dwell/constants';
import { CircleDivider } from 'styles/common';
import { roleChoices } from 'site/constants';
import { Content, Card, CardBody, MediaTeam, Avatar } from './styles';

const Team: FC = () => {
  const teams = useSelector(state => state.propertyProfile.teams);

  const classChoices = ['bg-indigo', 'bg-dark', 'bg-success', 'bg-orange', 'bg-secondary'];

  return (
    <Content>
      <h6 className="tx-16 text-dark"># Team Members</h6>
      {!!teams.length && (
        <Card>
          <CardBody>
            {teams.map((people, index) => (
              <MediaTeam key={index}>
                <Avatar className={cn(classChoices[index % 5])} online={people.is_online}>
                  {people.avatar ? <img src={people.avatar} alt="avatar" /> : (
                    <span>{getShortName(`${people.first_name} ${people.last_name}`)}</span>
                  )}
                </Avatar>
                <div className="media-body">
                  <h6>{people.first_name} {people.last_name}</h6>
                  <div className="d-flex align-items-center">
                    {roleChoices[people.role]} <CircleDivider /> {people.office}
                  </div>
                </div>
              </MediaTeam>
            ))}
          </CardBody>
        </Card>
      )}
    </Content>
  );
};

export default Team;
