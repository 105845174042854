import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { unionBy } from 'lodash';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, StorageProps, StorageState } from './action-types';

export const initialState: StorageState = {
  errorMessage: null,
  storages: [] as StorageProps[],
  isLoaded: false,
};

export const selectStorages = (state: { storage: StorageState }) : StorageProps[] => state.storage.storages;

export default produce((state: StorageState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_STORAGE_SUCCESS:
    case ActionType.UPDATE_STORAGE_SUCCESS:
      state.storages = unionBy([action.result.data], state.storages, 'id');
      break;

    case ActionType.DELETE_STORAGE_SUCCESS:
      state.storages = state.storages.filter(i => i.id !== action.result.data.id);
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.storages = action.result.data.storages;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
