import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  CREATE_RENEWAL_MANAGER_REQUEST = 'CREATE_RENEWAL_MANAGER_REQUEST',
  CREATE_RENEWAL_MANAGER_SUCCESS = 'CREATE_RENEWAL_MANAGER_SUCCESS',
  CREATE_RENEWAL_MANAGER_FAILURE = 'CREATE_RENEWAL_MANAGER_FAILURE',

  GET_RENEWAL_MANAGERS_REQUEST = 'GET_RENEWAL_MANAGERS_REQUEST',
  GET_RENEWAL_MANAGERS_SUCCESS = 'GET_RENEWAL_MANAGERS_SUCCESS',
  GET_RENEWAL_MANAGERS_FAILURE = 'GET_RENEWAL_MANAGERS_FAILURE',

  UPDATE_RENEWAL_MANAGER_REQUEST = 'UPDATE_RENEWAL_MANAGER_REQUEST',
  UPDATE_RENEWAL_MANAGER_SUCCESS = 'UPDATE_RENEWAL_MANAGER_SUCCESS',
  UPDATE_RENEWAL_MANAGER_FAILURE = 'UPDATE_RENEWAL_MANAGER_FAILURE',
}

export interface RenewalOfferProps {
  id?: number,
  base_rent?: number,
  comment?: string,
  decision?: 'PENDING' | 'ACCEPTED' | 'DECLINED',
  status?: 'ACTIVE' | 'COMPLETED' | 'CANCELED' | 'EXPIRED',
  type?: 'STANDARD' | 'MONTH_TO_MONTH',
  lease_term?: number,
  other_rent_items?: { rent: number, name: string }[],
}

export interface RenewalManagerProps {
  id?: number,
  comment?: string,
  decision?: 'PENDING' | 'ACCEPTED' | 'DECLINED',
  decision_date?: string,
  decision_term?: number,
  expiration_date?: string,
  sent_date?: string,
  status?: 'ACTIVE' | 'COMPLETED' | 'CANCELED' | 'EXPIRED',
  renewal_offers: RenewalOfferProps[],
}

export interface RenewalOfferState extends CommonStateType {
  renewalManagers: RenewalManagerProps[],
}

/*
 * Actions
 */

interface CreateRenewalManagerAction extends CommonActionResponse {
  type: ActionType.CREATE_RENEWAL_MANAGER_REQUEST
  | ActionType.CREATE_RENEWAL_MANAGER_SUCCESS
  | ActionType.CREATE_RENEWAL_MANAGER_FAILURE,
  result: { data: RenewalManagerProps },
}

interface GetRenewalManagersAction extends CommonActionResponse {
  type: ActionType.GET_RENEWAL_MANAGERS_REQUEST
  | ActionType.GET_RENEWAL_MANAGERS_SUCCESS
  | ActionType.GET_RENEWAL_MANAGERS_FAILURE,
  result: { data: { results: RenewalManagerProps[] } },
}

interface UpdateRenewalManagerAction extends CommonActionResponse {
  type: ActionType.UPDATE_RENEWAL_MANAGER_REQUEST
  | ActionType.UPDATE_RENEWAL_MANAGER_SUCCESS
  | ActionType.UPDATE_RENEWAL_MANAGER_FAILURE,
  result: { data: RenewalManagerProps },
}

export type Action =
  PromiseActionType
  | CreateRenewalManagerAction
  | GetRenewalManagersAction
  | UpdateRenewalManagerAction;
