import produce from 'immer';
import { Action, ActionType } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface NylasState extends CommonStateType {
  authUrl: string;
  token: string;
  isTokenObtained: boolean;
  isArchiving: boolean;
  isSendingEmail: boolean;
  isEmailComposerOpened: boolean;
  isSendApplication: boolean;
}

export const initialState: NylasState = {
  isSubmitting: false,
  errorMessage: null,
  authUrl: '',
  token: '',
  isTokenObtained: false,
  isArchiving: false,
  isSendingEmail: false,
  isEmailComposerOpened: false,
  isSendApplication: false,
};

export default produce((state: NylasState = initialState, action: Action): NylasState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_NYLAS_AUTH_SUCCESS:
      state.authUrl = action.result.data.auth_url;
      break;

    case ActionType.GET_NYLAS_TOKEN_SUCCESS:
      state.isTokenObtained = action.result.data.success;
      break;

    case ActionType.SEND_MESSAGE_REQUEST:
      state.isSendingEmail = true;
      break;
    case ActionType.SEND_MESSAGE_SUCCESS:
      state.isSendingEmail = false;
      break;
    case ActionType.GET_NYLAS_TOKEN_FAILURE:
      state.isSendingEmail = false;
      break;

    case ActionType.ARCHIVE_MESSAGES_REQUEST:
      state.isArchiving = true;
      break;
    case ActionType.ARCHIVE_MESSAGES_SUCCESS:
      state.isArchiving = false;
      break;
    case ActionType.ARCHIVE_MESSAGES_FAILURE:
      state.isArchiving = false;
      break;

    case ActionType.SET_EMAIL_OPEN_STATUS:
      state.isEmailComposerOpened = action.data.dataSetEmail;
      state.isSendApplication = action.data.isSendApplicationEmail;
      break;
  }

  return state;
});
