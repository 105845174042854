import { PromiseActionType } from 'dwell/store/types';
import { CustomBlob } from 'src/interfaces';
import { PropertyProps } from 'dwell/store/property/action-types';
import { UserProps } from 'dwell/store/user/action-types';
import { ClientProps } from '../client/action-types';

export enum ActionType {
  GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE',

  GET_CUSTOMERS_PAGINATION_REQUEST = 'GET_CUSTOMERS_PAGINATION_REQUEST',
  GET_CUSTOMERS_PAGINATION_SUCCESS = 'GET_CUSTOMERS_PAGINATION_SUCCESS',
  GET_CUSTOMERS_PAGINATION_FAILURE = 'GET_CUSTOMERS_PAGINATION_FAILURE',

  CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE',

  DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE',

  CUSTOMER_DETAILS_GET_REQUEST = 'CUSTOMER_DETAILS_GET_REQUEST',
  CUSTOMER_DETAILS_GET_SUCCESS = 'CUSTOMER_DETAILS_GET_SUCCESS',
  CUSTOMER_DETAILS_GET_FAILURE = 'CUSTOMER_DETAILS_GET_FAILURE',

  UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE',

  CUSTOMER_LOGO_UPDATE_REQUEST = 'CUSTOMER_LOGO_UPDATE_REQUEST',
  CUSTOMER_LOGO_UPDATE_SUCCESS = 'CUSTOMER_LOGO_UPDATE_SUCCESS',
  CUSTOMER_LOGO_UPDATE_FAILURE = 'CUSTOMER_LOGO_UPDATE_FAILURE',

  ONBOARD_REQUEST = 'ONBOARD_REQUEST',
  ONBOARD_SUCCESS = 'ONBOARD_SUCCESS',
  ONBOARD_FAILURE = 'ONBOARD_FAILURE',

  GET_CUSTOMER_SITE_ENABLED_PROPERTIES_REQUEST = 'GET_CUSTOMER_SITE_ENABLED_PROPERTIES_REQUEST',
  GET_CUSTOMER_SITE_ENABLED_PROPERTIES_SUCCESS = 'GET_CUSTOMER_SITE_ENABLED_PROPERTIES_SUCCESS',
  GET_CUSTOMER_SITE_ENABLED_PROPERTIES_FAILURE = 'GET_CUSTOMER_SITE_ENABLED_PROPERTIES_FAILURE',
}

export interface OnboardResponse {
  result: {
    data: { customer: CustomerProps };
  };
}

export interface OnboardData {
  customer: CustomerProps;
  user: { ids: number[] };
  property: PropertyProps;
  client: ClientProps;
  source_property_id: number;
}

export interface CustomerProps {
  id?: number;
  customer_name?: string;
  logo?: string | CustomBlob;
  logoFile?: CustomBlob;
  useExisting?: string; // it's used for onboard FE only
  user?: UserProps;
  clients?: number[];
  properties?: number[];
  admins?: UserProps[];
  employee?: UserProps[];
  active_properties?: number;
  gtm_id?: string;
}

interface GetCustomersAction {
  type: ActionType.GET_CUSTOMERS_REQUEST | ActionType.GET_CUSTOMERS_SUCCESS | ActionType.GET_CUSTOMERS_FAILURE;

  result: {
    data: { results: CustomerProps[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetCustomersPaginationAction {
  type: ActionType.GET_CUSTOMERS_PAGINATION_REQUEST | ActionType.GET_CUSTOMERS_PAGINATION_SUCCESS | ActionType.GET_CUSTOMERS_PAGINATION_FAILURE;

  result: {
    data: { results: CustomerProps[]; count: number; count_all: number; count_active: number; count_inactive: number };
  };
  error: {
    response: { status: string };
  };
}

interface CreateCustomerAction {
  type: ActionType.CREATE_CUSTOMER_REQUEST | ActionType.CREATE_CUSTOMER_SUCCESS | ActionType.CREATE_CUSTOMER_FAILURE;

  error: {
    response: { status: string };
  };
}

interface DeleteCustomerAction {
  type: ActionType.DELETE_CUSTOMER_REQUEST | ActionType.DELETE_CUSTOMER_SUCCESS | ActionType.DELETE_CUSTOMER_FAILURE;

  error: {
    response: { status: string };
  };
}

interface GetCustomerDetailsAction {
  type: ActionType.CUSTOMER_DETAILS_GET_REQUEST | ActionType.CUSTOMER_DETAILS_GET_SUCCESS | ActionType.CUSTOMER_DETAILS_GET_FAILURE;

  result: {
    data: {
      id?: number;
      employee?: { id: number; first_name: string; last_name: string; role: string }[];
    };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateCustomerAction {
  type: ActionType.UPDATE_CUSTOMER_REQUEST | ActionType.UPDATE_CUSTOMER_SUCCESS | ActionType.UPDATE_CUSTOMER_FAILURE;

  error: {
    response: { status: string };
  };
}

interface UpdateCustomerLogoAction {
  type: ActionType.CUSTOMER_LOGO_UPDATE_REQUEST | ActionType.CUSTOMER_LOGO_UPDATE_SUCCESS | ActionType.CUSTOMER_LOGO_UPDATE_FAILURE;
  error: {
    response: { status: string };
  };
}

interface OnboardAction {
  type: ActionType.ONBOARD_REQUEST | ActionType.ONBOARD_SUCCESS | ActionType.ONBOARD_FAILURE;
  error: {
    response: { status: string };
  };
}

interface GetSiteEnabledPropertiesAction {
  type: ActionType.GET_CUSTOMER_SITE_ENABLED_PROPERTIES_REQUEST | ActionType.GET_CUSTOMER_SITE_ENABLED_PROPERTIES_SUCCESS | ActionType.GET_CUSTOMER_SITE_ENABLED_PROPERTIES_FAILURE;

  result: {
    data: { id: number; name: string }[];
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetCustomersAction
  | GetCustomersPaginationAction
  | CreateCustomerAction
  | DeleteCustomerAction
  | GetCustomerDetailsAction
  | UpdateCustomerAction
  | UpdateCustomerLogoAction
  | OnboardAction
  | GetSiteEnabledPropertiesAction;
