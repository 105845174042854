import leasingActions from 'leasing/store/types';
import applicationActions from 'application/store/types';
import residentActions from 'resident/store/types';
import { ActionType } from 'dwell/store/types';
import { navType } from 'src/interfaces';
import actions from './actions';
import paths from './paths';
import configuration from './configuration';
import leadsFilterChoices from './leads_filter_choices';
import fieldChoices from './field_choices';
import notificationTypes from './notification_types';
import emailVariables from './email_variables';
import smsVariables from './sms_variables';
import chatVariables from './chat_variables';
import emailTemplateTypes from './email_template_types';
import columnsNames from './columns_names';
import roommateRelationships from './roommate_relationships';
import reportsFilterChoices from './reports_filter_choices';
import reportTypes from './report_types';
import unitTypes from './unit_types';
import { AUTO_CREATED, TOUR_TYPES } from './tasks';
import notificationIcons from './notification_icons';
import communicationIcons from './communication_icons';
import communicationTypes from './communication_types';
import leasingTypes from './leasing_types';

export {
  actions,
  configuration,
  paths,
  fieldChoices,
  leadsFilterChoices,
  notificationTypes,
  emailVariables,
  emailTemplateTypes,
  smsVariables,
  chatVariables,
  columnsNames,
  roommateRelationships,
  reportsFilterChoices,
  reportTypes,
  unitTypes,
  AUTO_CREATED,
  TOUR_TYPES,
  notificationIcons,
  communicationIcons,
  communicationTypes,
  leasingTypes,
};

export default {};

export const LAST_N_MESSAGES = 1;
export const LOGGED_ACCOUNT = 'crm-auth';
export const REDIRECT_PATH = 'redirect-path';
export const IS_BEEN_LOGGED = 'isAccountBeenLogged';
export const IS_RECENTLY_LOGGED = 'isRecentlyLogged';
export const LAST_ACTIVITY_DATE = 'last_activity_date';
export const COMMUNICATION_FILTER = 'communication-filter';
// Added to handle SSO authentication
export const USER_TO_LOGIN = 'user_to_login';
export const USER_TO_SWITCH = 'user_to_switch';
export const ACTIVE_FILTER_ID = 'active_filter_id';
// It's used for keep most recent accessed property for next login, and Nylas authentication redirect
export const RECENT_PROPERTY_HYPHENS = 'last_login_property';
export const RECENT_PROPERTY_ID = 'last_property_id';
export const IS_AGENT_QUESTION_TIMER_SETTED = 'is_agent_question_timer_setted';
export const LAST_TIME_AGENT_UNAVAILABLE_TIMEOUT = 'last_time_agent_unavailable_timeout';
export const LAST_TIME_PIPELINE_UPDATED = 'last_time_pipeline_updated';
export const IMAGE_COMPRESS_OPTIONS = {
  quality: 0.8,
  maxWidth: 1600,
  maxHeight: 1067,
};
export const MAX_IMG_SIZE = 10000000;
export const MAX_FILE_SIZE = 25000000;
export const LAST_CUSTOMER_NAME = 'last_customer_name';
export const imageCompressOption = {
  quality: 0.8,
  maxWidth: 1600,
  maxHeight: 1068,
};
export const NOTIFICATION_NOT_REQUIRED_REQUESTS = [
  actions.SEND_TEXT_TO_LEAD_REQUEST,
  actions.SEND_TEXT_TO_PROSPECT_REQUEST,
  actions.UPDATE_USER_AVAILABLE_STATUS_REQUEST,
  actions.GET_NYLAS_AUTH_REQUEST,
  actions.UPDATE_USER_LAST_PROPERTY_REQUEST,
  leasingActions.CREATE_APPLICANT_REQUEST,
  leasingActions.UPDATE_APPLICANT_REQUEST,
  leasingActions.CREATE_GUARANTOR_REQUEST,
  leasingActions.UPDATE_GUARANTOR_REQUEST,
  leasingActions.UPDATE_OTHER_INCOMES_REQUEST,
  leasingActions.UPDATE_APPLICATION_FORM_REQUEST,
  applicationActions.UPDATE_APPLICATION_REQUEST,
  ActionType.CREATE_ASSIST_BOT_CONVERSATION_REQUEST,
  ActionType.UPDATE_PROPERTY_TO_GET_QUESTIONS_REQUEST,
  ActionType.CREATE_PRICE_QUOTE_REQUEST,
  ActionType.UPDATE_PRICE_QUOTE_REQUEST,
  ActionType.TRANSFER_TO_APPLICATION_REQUEST,
  applicationActions.ADD_PEOPLES_REQUEST,
  applicationActions.REMOVE_RESIDENTS_REQUEST,
  applicationActions.REQUEST_PROFILE_REFRESH_REQUEST,
  ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_REQUEST,
  ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_SUCCESS,
  residentActions.UPDATE_LEASE_RESIDENT_REQUEST,
  ActionType.SSO_CHECK_REQUEST,
  ActionType.SSO_LOGIN_REQUEST,
  ActionType.BACKCHANNEL_LOGOUT_REQUEST,
];
export const SMS_LOAD_MORE_OFFSET = 20;
export const CONTACTS_N_PROSPECTS_OFFSET = 30;
export const INITIAL_CONTACTS_N_PROSPECTS_OFFSET = 50;
export const INITIAL_NOTIFICATIONS_OFFSET = 50;
export const NOTIFICATIONS_OFFSET = 20;
export const RECENT_NOTIFICATIONS_OFFSET = 10;
export const chatAvatarColorClasses = ['bg-teal', 'bg-indigo', 'bg-warning', 'bg-danger', 'bg-purple', 'bg-primary', 'bg-pink'];
export const MULTI_CHAT_FILTER = 'multi-chat-filter';
export const SINGLE_CHAT_FILTER = 'singe-chat-filter';

export const ALLOWED_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

export const ALLOWED_MIME_TYPES = ['application/msword', 'application/pdf', 'application/rtf', 'application/x-rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', ...ALLOWED_IMAGE_MIME_TYPES, 'text/plain',
  'text/richtext', 'text/rtf',
];

export const hexToRgb = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}` : null;
};
export const getShortName = (name: string): string => {
  try {
    const splits = name.split(' ').filter(i => !!i);
    if (splits.length === 1) return splits[0][0].toUpperCase();
    return splits.length > 1 ? `${splits[0][0]}${splits[1][0]}`.toUpperCase() : `${splits[0][0]}`.toUpperCase();
  } catch (e) {
    return 'U1';
  }
};
export const redirectPaths = ['/compete/alerts/'];

const pluralTime = {
  second: 'seconds',
  minute: 'minutes',
  hour: 'hours',
  week: 'weeks',
  month: 'months',
  year: 'years',
};

export const addendaFilterOptions = {
  NEW_LEASES: 'New Leases',
  DEPOSIT_AGREEMENTS: 'Deposit Agreements',
  RENEWAL_LEASES: 'Renewal Leases',
};

export const timeFormatter = (value: number, unit: string, suffix: string, epochMilliseconds: number): string => {
  const days = Math.floor(Math.abs(epochMilliseconds - Date.now()) / (1000 * 60 * 60 * 24));

  if (days && days < 14) return `${days} ${days === 1 ? 'day' : 'days'} ${suffix}`;

  if (unit === 'week') {
    const week = Math.floor(days / 7);
    return `${week} ${week === 1 ? unit : pluralTime[unit]} ${suffix}`;
  }

  if (unit === 'month') {
    const month = Math.floor(days / 30);
    return `${month} ${month === 1 ? unit : pluralTime[unit]} ${suffix}`;
  }

  if (unit === 'year') {
    const year = Math.floor(days / 365);
    return `${year} ${year === 1 ? unit : pluralTime[unit]} ${suffix}`;
  }

  return `${value} ${value === 1 ? unit : pluralTime[unit]} ${suffix}`;
};

export const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const tasksNav: navType = {
  name: 'tasks',
  navItems: [
    { name: 'tasks', path: '' },
    { name: 'ready_for_signature', path: '/properties/bulk_sign' },
  ],
};

export const emailNav: navType = {
  name: 'emails',
  navItems: [
    { name: 'followups', path: '' },
    { name: 'bulk_email', path: '' },
  ],
};

export const callsNav: navType = {
  name: 'calls',
  navItems: [
    { name: 'calls_board', path: '/properties/calls' },
    { name: 'calls-rescores', path: '/call-rescores/calls' },
  ],
};

export const selectedPropertiesFilterType = {
  type: 'SELECTED_PROPERTIES',
  name: 'My selected properties',
};

export const REPHRASE_RESPONSE = 'Sorry, I didn\'t understand your question. Can you rephrase and ask again?';

export const getRandomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min;

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
