import produce from 'immer';
import { Action, ActionType, ChatTemplateProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface ChatTemplateState extends CommonStateType {
  chatTemplates: ChatTemplateProps[];
  chatTemplate: ChatTemplateProps;
}

export const initialState: ChatTemplateState = {
  chatTemplates: [],
  chatTemplate: {} as ChatTemplateProps,
};

export default produce((state: ChatTemplateState = initialState, action: Action): ChatTemplateState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_CHAT_TEMPLATES_SUCCESS:
      state.chatTemplates = action.result.data.results;
      break;

    case ActionType.GET_CHAT_TEMPLATE_BY_ID_SUCCESS:
      state.chatTemplate = action.result.data;
      break;

    case ActionType.UPDATE_CHAT_TEMPLATE_SUCCESS: {
      const updateChatTemplatesList = state.chatTemplates.map(template => (template.id === action.result.data.id ? action.result.data : template));
      state.chatTemplates = updateChatTemplatesList;
      break;
    }

    case ActionType.CREATE_CHAT_TEMPLATE_SUCCESS: {
      const updateChatTemplatesList = [...state.chatTemplates];
      updateChatTemplatesList.push(action.result.data);
      state.chatTemplates = updateChatTemplatesList;
      break;
    }
  }

  return state;
});
