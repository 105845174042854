import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { highlightElement, cancelHighlight } from 'leasing/views/agreement/utils/highlight_element';
import { selectDepositAgreementDocument } from 'leasing/store/lease_document/reducers';
import { SingleDatePicker } from 'react-dates';
import { FormFeedback, Input } from 'reactstrap';
import { DatePickerWrapper } from './styles';

interface DatePickerProps {
  id?: string,
  value: string,
  onChange: (id: string, value: string) => void,
  type?: string,
  name?: string,
  placeholder?: string,
  className?: string,
  needFocus?: boolean,
  invalid?: boolean,
  error?: string,
  touched?: boolean,
  isOutsideRange?: (day: Date) => boolean,
  disableFutureDate?: boolean,
}

const DatePicker: FC<DatePickerProps> = ({
  id, value, name, placeholder, onChange, className, error, touched, needFocus, disableFutureDate, invalid,
}) => {
  const [dateFocused, setDateFocused] = useState(false);
  const depositAgreementDocument = useSelector(selectDepositAgreementDocument);
  const leaseFields = depositAgreementDocument?.lease_fields || [];

  const onFocus = (focused) => {
    setDateFocused(focused);
    if (!needFocus) {
      return;
    }
    if (focused) {
      const field = leaseFields.find(i => i.field_label === name);
      cancelHighlight();
      highlightElement(field?.name, depositAgreementDocument?.name);
    } else {
      cancelHighlight();
    }
  };

  return (
    <DatePickerWrapper className={className} $invalid={invalid || (error && touched)}>
      <SingleDatePicker
        id={`date-picker-${id}`}
        inputIconPosition="after"
        placeholder={placeholder}
        small
        block
        numberOfMonths={1}
        date={value ? moment(value) : null}
        isOutsideRange={day => (disableFutureDate ? moment().diff(day) < 0 : false)}
        onDateChange={date => onChange(id, date && date.format('YYYY-MM-DD'))}
        focused={dateFocused}
        onFocusChange={({ focused }) => onFocus(focused)}
        openDirection="down"
        hideKeyboardShortcutsPanel
        isDayHighlighted={day => day.isSame(moment(), 'd')}
      />
      <Input hidden invalid={error && touched} />
      <FormFeedback>{error}</FormFeedback>
    </DatePickerWrapper>
  );
};

export default DatePicker;
