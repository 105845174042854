import produce from 'immer';
import { CommonStateType } from 'dwell/store/types';
import { isEqual } from 'lodash';
import { Action, ActionType, SiteSettings, SiteSettingsValues } from './action-types';

export interface SiteSettingsState extends CommonStateType {
  toolbarNavTabSettings: 'GENERAL' | 'APPEARANCE' | 'ADVANCED' | 'AMENITIES';
  siteSettings: SiteSettings;
  newSiteSettingsValues: SiteSettingsValues;
  oldSiteSettingsValues: SiteSettingsValues;
  enableSaveButton: boolean;
}

export const initialState: SiteSettingsState = {
  toolbarNavTabSettings: 'GENERAL',
  siteSettings: {
    id: 0,
    created: '',
    updated: '',
    values: {
      gtmId: '',
      title: '',
      robots: {
        noindex: false,
        nofollow: false,
        noarchive: false,
        nosnippet: false,
      },
      favicon: '',
      subject: '',
      gtmScript: { body: '', head: '' },
      themeColor: '',
      description: '',
      bing_ownership: '',
      additionalScript: '',
      google_ownership: '',
      categories: [],
      markup: '',
    },
  },
  newSiteSettingsValues: {
    gtmId: '',
    title: '',
    robots: {
      noindex: false,
      nofollow: false,
      noarchive: false,
      nosnippet: false,
    },
    favicon: '',
    subject: '',
    gtmScript: { body: '', head: '' },
    themeColor: '',
    description: '',
    bing_ownership: '',
    additionalScript: '',
    google_ownership: '',
    categories: [],
    markup: '',
  },
  oldSiteSettingsValues: {
    gtmId: '',
    title: '',
    robots: {
      noindex: false,
      nofollow: false,
      noarchive: false,
      nosnippet: false,
    },
    favicon: '',
    subject: '',
    gtmScript: { body: '', head: '' },
    themeColor: '',
    description: '',
    bing_ownership: '',
    additionalScript: '',
    google_ownership: '',
    categories: [],
    markup: '',
  },
  enableSaveButton: false,
};

export const selectToolbarNavTabSettings = (state: { siteSettings: SiteSettingsState }): 'GENERAL' | 'APPEARANCE' | 'ADVANCED' | 'AMENITIES' => state.siteSettings.toolbarNavTabSettings;
export const selectSiteSeoSettings = (state: { siteSettings: SiteSettingsState }): SiteSettings => state.siteSettings.siteSettings;
export const selectPropertySeoSettings = (state: { siteSettings: SiteSettingsState }): SiteSettings => state.siteSettings.siteSettings;
export const selectOldSiteSettingsValues = (state: { siteSettings: SiteSettingsState }): SiteSettingsValues => state.siteSettings.oldSiteSettingsValues;
export const selectNewSiteSettingsValues = (state: { siteSettings: SiteSettingsState }): SiteSettingsValues => state.siteSettings.newSiteSettingsValues;
export const selectEnableSaveButton = (state: { siteSettings: SiteSettingsState }): boolean => state.siteSettings.enableSaveButton;

export default produce((state: SiteSettingsState = initialState, action: Action): SiteSettingsState => {
  switch (action.type) {
    case ActionType.SET_TOOLBAR_NAV_TAB_SETTINGS:
      state.toolbarNavTabSettings = action.nav;
      break;
    case ActionType.SET_NEW_SITE_SETTINGS_VALUES:
      state.enableSaveButton = !isEqual(action.newValues, action.oldValues);
      state.newSiteSettingsValues = action.newValues;
      break;
    // API Requests
    case ActionType.GET_SITE_SEO_SETTINGS_REQUEST:
    case ActionType.GET_PROPERTY_SEO_SETTINGS_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_SITE_SEO_SETTINGS_SUCCESS:
    case ActionType.GET_PROPERTY_SEO_SETTINGS_SUCCESS:
      [state.siteSettings] = action.result.data.results;
      state.newSiteSettingsValues = action.result.data.results[0].values;
      state.oldSiteSettingsValues = state.newSiteSettingsValues;
      state.enableSaveButton = false;
      state.isLoaded = true;
      break;
    case ActionType.UPDATE_SITE_SEO_SETTINGS_REQUEST:
    case ActionType.UPDATE_PROPERTY_SEO_SETTINGS_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.UPDATE_SITE_SEO_SETTINGS_SUCCESS:
    case ActionType.UPDATE_PROPERTY_SEO_SETTINGS_SUCCESS:
      state.siteSettings = action.result.data;
      state.newSiteSettingsValues = action.result.data.values;
      state.oldSiteSettingsValues = state.newSiteSettingsValues;
      state.enableSaveButton = false;
      break;
  }
  return state;
});
