import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_CALL_SCHEDULES_REQUEST = 'GET_CALL_SCHEDULES_REQUEST',
  GET_CALL_SCHEDULES_SUCCESS = 'GET_CALL_SCHEDULES_SUCCESS',
  GET_CALL_SCHEDULES_FAILURE = 'GET_CALL_SCHEDULES_FAILURE',

  SAVE_CALL_SCHEDULES_REQUEST = 'SAVE_CALL_SCHEDULES_REQUEST',
  SAVE_CALL_SCHEDULES_SUCCESS = 'SAVE_CALL_SCHEDULES_SUCCESS',
  SAVE_CALL_SCHEDULES_FAILURE = 'SAVE_CALL_SCHEDULES_FAILURE',
}

export interface CallScheduleProps {
  id?: number;
  weekday?: number;
  office_hours_phone_number?: number;
  after_hours_phone_number?: number;
}

export interface CallsConfiguration {
  call_schedules: CallScheduleProps[];
  ivr_message?: string;
}

interface GetCallSchedulesAction {
  type: ActionType.GET_CALL_SCHEDULES_REQUEST | ActionType.GET_CALL_SCHEDULES_SUCCESS | ActionType.GET_CALL_SCHEDULES_FAILURE;

  result: {
    data: { call_schedules: CallScheduleProps[]; ivr_message: string };
  };
  error: {
    response: { status: string };
  };
}

interface SaveCallSchedulesAction {
  type: ActionType.SAVE_CALL_SCHEDULES_REQUEST | ActionType.SAVE_CALL_SCHEDULES_SUCCESS | ActionType.SAVE_CALL_SCHEDULES_FAILURE;

  result: {
    data: { call_schedules: CallScheduleProps[]; ivr_message: string };
  };
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetCallSchedulesAction | SaveCallSchedulesAction;
