/* eslint-disable jsx-a11y/label-has-for */
import React, { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { selectDevice } from 'dwell/store/voice/reducers';

import CallBodyItem from './call_body_item';
import { CallBodyItemBaseProps } from '.';

const AudioSettings: FC<CallBodyItemBaseProps> = ({ hide, onClose }) => {
  const [inputDevices, setInputDevices] = useState(null);
  const [outputDevices, setOutputDevices] = useState(null);

  const device = useSelector(selectDevice);

  const getAudioDevices = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    if (device?.audio) {
      setInputDevices(Array.from(device.audio.availableInputDevices.values()));
      setOutputDevices(Array.from(device.audio.availableOutputDevices.values()));
    }
  };

  useEffect(() => {
    getAudioDevices();
  }, []);

  return inputDevices && outputDevices && (
    <CallBodyItem itemTitle="Audio Settings" bodyClassNames="pd-y-5" onClose={onClose} hide={hide} >
      {!isEmpty([outputDevices]) && (
        <div className="form-group">
          <label className="form-label">Speaker</label>
          <select
            className="custom-select"
            onChange={e => device.audio.speakerDevices.set(e.target.value)}
          >
            {outputDevices && outputDevices.map((outputDevice, index) => (
              <option value={outputDevice.deviceId} key={`od-${index}`}>{outputDevice.label}</option>
            ))}
          </select>
        </div>
      )}
      {!isEmpty([inputDevices]) && (
        <div className="form-group mg-b-0">
          <label className="form-label">Microphone</label>
          <select className="custom-select" onChange={e => device.audio.setInputDevice(e.target.value)}>
            {inputDevices && inputDevices.map((inputDevice, index) => (
              <option value={inputDevice.deviceId} key={`id-${index}`}>{inputDevice.label}</option>
            ))}
          </select>
        </div>
      )}
    </CallBodyItem>
  );
};

export default AudioSettings;
