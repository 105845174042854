import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { CreateLeadAction, UpdateLeadByIdAction } from 'dwell/store/lead/action-types';
import { SetSelectedPropertiesAction, GetSelectedPropertiesAction, GetSelectedPropertiesFloorPlansAction } from 'dwell/store/property/action-types';
import { UnitProps } from 'src/interfaces';
import { OwnerCategory } from 'dwell/views/tasks/category/utils';

export enum ActionType {
  BULK_SAVE_TASKS_REQUEST = 'BULK_SAVE_TASKS_REQUEST',
  BULK_SAVE_TASKS_SUCCESS = 'BULK_SAVE_TASKS_SUCCESS',
  BULK_SAVE_TASKS_FAILURE = 'BULK_SAVE_TASKS_FAILURE',
  GET_TASKS_REQUEST = 'GET_TASKS_REQUEST',
  GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS',
  GET_TASKS_FAILURE = 'GET_TASKS_FAILURE',
  GET_OBJECT_TASKS_REQUEST = 'GET_OBJECT_TASKS_REQUEST',
  GET_OBJECT_TASKS_SUCCESS = 'GET_OBJECT_TASKS_SUCCESS',
  GET_OBJECT_TASKS_FAILURE = 'GET_OBJECT_TASKS_FAILURE',
  DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST',
  DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE',
  UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST',
  UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE',
  COMPLETE_TASK_REQUEST = 'COMPLETE_TASK_REQUEST',
  COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS',
  COMPLETE_TASK_FAILURE = 'COMPLETE_TASK_FAILURE',
  CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST',
  CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS',
  CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE',
  TEST_TASK_NOTIFICATIONS_REQUEST = 'TEST_TASK_NOTIFICATIONS_REQUEST',
  TEST_TASK_NOTIFICATIONS_SUCCESS = 'TEST_TASK_NOTIFICATIONS_SUCCESS',
  TEST_TASK_NOTIFICATIONS_FAILURE = 'TEST_TASK_NOTIFICATIONS_FAILURE',
  GET_AVAILABLE_TOUR_TIMES_REQUEST = 'GET_AVAILABLE_TOUR_TIMES_REQUEST',
  GET_AVAILABLE_TOUR_TIMES_SUCCESS = 'GET_AVAILABLE_TOUR_TIMES_SUCCESS',
  GET_AVAILABLE_TOUR_TIMES_FAILURE = 'GET_AVAILABLE_TOUR_TIMES_FAILURE',
  SET_CURRENT_TASK = 'SET_CURRENT_TASK',
  CLEAR_AVAILABLE_TOUR_TIMES = 'CLEAR_AVAILABLE_TOUR_TIMES',
  SET_OWNER_FILTER = 'SET_OWNER_FILTER',
  MARK_NO_SHOW_TASK_REQUEST = 'MARK_NO_SHOW_TASK_REQUEST',
  MARK_NO_SHOW_TASK_SUCCESS = 'MARK_NO_SHOW_TASK_SUCCESS',
  MARK_NO_SHOW_TASK_FAILURE = 'MARK_NO_SHOW_TASK_FAILURE',
}

export interface TaskProps {
  id?: number;
  created?: string;
  description: string;
  owner: number;
  lead?: number;
  lease?: number;
  application?: number,
  leasing_user?: number;
  type?: string;
  units?: number[];
  tour_confirmation_reminder_enabled?: boolean;
  due_date?: Date;
  tour_date?: Date;
  showing_units?: UnitProps[];
  updated?: Date;
  status?: string;
  is_cancelled?: boolean;
  property_external_id?: string;
  property_name?: string;
  property?: number;
  became_completed?: boolean;
  message?: { content: string, type: string };
  completed_date?: string;
  lead_name?: string;
  lead_source?: string;
  lease_name?: string;
  leasing_user_info?: { name: string, lease: number };
  date?: Date;
  selected_units?: string[];
  marked_as_no_show?: boolean;
  prospect_name?: string;
}

export interface AvailableDateTimeProps {
  date: string;
  times: { available: boolean, date_time: string }[];
}

export interface TimesProps {
  times: AvailableDateTimeProps[] | string[];
}

interface GetTasksAction {
  type: ActionType.GET_TASKS_REQUEST | ActionType.GET_TASKS_SUCCESS | ActionType.GET_TASKS_FAILURE;

  payload: {
    append: boolean;
    paginated: boolean;
    page: number;
    status: string;
  }

  result: {
    data: { results: TaskProps[]; previous: string; count: number; };
  };
  error: {
    response: { status: string };
  };
}

interface GetObjectTasksAction {
  type: ActionType.GET_OBJECT_TASKS_REQUEST | ActionType.GET_OBJECT_TASKS_SUCCESS | ActionType.GET_OBJECT_TASKS_FAILURE;

  result: {
    data: { results: TaskProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface DeleteTaskByIdAction {
  type: ActionType.DELETE_TASK_REQUEST | ActionType.DELETE_TASK_SUCCESS | ActionType.DELETE_TASK_FAILURE;

  result: {
    data: TaskProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateTaskByIdAction {
  type: ActionType.UPDATE_TASK_REQUEST | ActionType.UPDATE_TASK_SUCCESS | ActionType.UPDATE_TASK_FAILURE;

  result: {
    data: TaskProps;
  };
  error: {
    response: { status: string };
  };
}

interface CompleteTaskAction {
  type: ActionType.COMPLETE_TASK_REQUEST | ActionType.COMPLETE_TASK_SUCCESS | ActionType.COMPLETE_TASK_FAILURE;

  result: {
    data: TaskProps;
  };
  error: {
    response: { status: string };
  };
}

interface MarkNoShowTaskAction {
  type: ActionType.MARK_NO_SHOW_TASK_REQUEST | ActionType.MARK_NO_SHOW_TASK_SUCCESS | ActionType.MARK_NO_SHOW_TASK_FAILURE;

  result: {
    data: TaskProps;
  };
  error: {
    response: { status: string };
  };
}

interface CreateTaskAction {
  type: ActionType.CREATE_TASK_REQUEST | ActionType.CREATE_TASK_SUCCESS | ActionType.CREATE_TASK_FAILURE;

  result: {
    data: TaskProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetAvailableTourTimeAction {
  type: ActionType.GET_AVAILABLE_TOUR_TIMES_REQUEST | ActionType.GET_AVAILABLE_TOUR_TIMES_SUCCESS | ActionType.GET_AVAILABLE_TOUR_TIMES_FAILURE;

  result: {
    data: TimesProps;
  };
  error: {
    response: { status: string };
  };
}

interface SetCurrentTaskAction {
  type: ActionType.SET_CURRENT_TASK

  result: {
    data: TaskProps;
  };
}

interface BulkTaskSaveAction {
  type: ActionType.BULK_SAVE_TASKS_REQUEST
  | ActionType.BULK_SAVE_TASKS_SUCCESS
  | ActionType.BULK_SAVE_TASKS_FAILURE;
  result: { data: TaskProps[] };
}

interface ClearAvailableTourTimesAction {
  type: ActionType.CLEAR_AVAILABLE_TOUR_TIMES;
}

interface SetOwnerFilter {
  type: ActionType.SET_OWNER_FILTER,
  filter: OwnerCategory,
}

export type Action =
  | PromiseActionType
  | BulkTaskSaveAction
  | GetTasksAction
  | GetObjectTasksAction
  | DeleteTaskByIdAction
  | UpdateTaskByIdAction
  | CompleteTaskAction
  | MarkNoShowTaskAction
  | CreateTaskAction
  | GetAvailableTourTimeAction
  | CreateLeadAction
  | SetCurrentTaskAction
  | PusherAction
  | SetSelectedPropertiesAction
  | GetSelectedPropertiesAction
  | GetSelectedPropertiesFloorPlansAction
  | ClearAvailableTourTimesAction
  | UpdateLeadByIdAction
  | SetOwnerFilter;
