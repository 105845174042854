import produce from 'immer';
import { orderBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { ExploreMarketsList } from '../explore_markets/action-types';
import { Action, ActionType } from './action-types';

export interface WatchlistState extends CommonStateType {
  isSubmitting: boolean;
  errorMessage: string;
  isWatchlistLoaded: boolean;
  watchlist: ExploreMarketsList;
}

const initialState: WatchlistState = {
  isSubmitting: false,
  errorMessage: null,
  isWatchlistLoaded: false,
  watchlist: null,
};

export default produce((state: WatchlistState = initialState, action: Action): WatchlistState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_WATCHLIST_REQUEST:
      state.isWatchlistLoaded = false;
      break;

    case ActionType.GET_WATCHLIST_SUCCESS: {
      const sortedWathlist = {};
      ['markets', 'submarkets', 'properties', 'comparisons'].forEach(key => (sortedWathlist[key] = orderBy(action.result.data[key] || [], el => (key !== 'comparisons' ? el.name : el.subject_asset_name))));
      state.watchlist = sortedWathlist;
      state.isWatchlistLoaded = true;
      break;
    }

    case ActionType.GET_WATCHLIST_FAILURE:
      state.isWatchlistLoaded = false;
      break;

    case ActionType.UPDATE_WATCHLIST_REQUEST:
      state.isSubmitting = true;
      break;

    case ActionType.UPDATE_WATCHLIST_SUCCESS:
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_WATCHLIST_FAILURE:
      state.isWatchlistLoaded = false;
      break;
  }

  return state;
});
