import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { GetLeadByIdAction } from 'dwell/store/lead/action-types';

export enum ActionType {
  GET_PROSPECTS_REQUEST = 'GET_PROSPECTS_REQUEST',
  GET_PROSPECTS_SUCCESS = 'GET_PROSPECTS_SUCCESS',
  GET_PROSPECTS_FAILURE = 'GET_PROSPECTS_FAILURE',

  JOIN_PROSPECT_CHAT_REQUEST = 'JOIN_PROSPECT_CHAT_REQUEST',
  JOIN_PROSPECT_CHAT_SUCCESS = 'JOIN_PROSPECT_CHAT_SUCCESS',
  JOIN_PROSPECT_CHAT_FAILURE = 'JOIN_PROSPECT_CHAT_FAILURE',

  REQUEST_TO_JOIN_CONVERSATION_REQUEST = 'REQUEST_TO_JOIN_CONVERSATION_REQUEST',
  REQUEST_TO_JOIN_CONVERSATION_SUCCESS = 'REQUEST_TO_JOIN_CONVERSATION_SUCCESS',
  REQUEST_TO_JOIN_CONVERSATION_FAILURE = 'REQUEST_TO_JOIN_CONVERSATION_FAILURE',

  CHANGE_PROSPECT_CHAT_STATUS_REQUEST = 'CHANGE_PROSPECT_CHAT_STATUS_REQUEST',
  CHANGE_PROSPECT_CHAT_STATUS_SUCCESS = 'CHANGE_PROSPECT_CHAT_STATUS_SUCCESS',
  CHANGE_PROSPECT_CHAT_STATUS_FAILURE = 'CHANGE_PROSPECT_CHAT_STATUS_FAILURE',

  PROSPECT_CHAT_READ_ALL_REQUEST = 'PROSPECT_CHAT_READALL_REQUEST',
  PROSPECT_CHAT_READ_ALL_SUCCESS = 'PROSPECT_CHAT_READALL_SUCCESS',
  PROSPECT_CHAT_READ_ALL_FAILURE = 'PROSPECT_CHAT_READALL_FAILURE',

  DISMISS_NEW_MESSAGE_REQUEST = 'DISMISS_NEW_MESSAGE_REQUEST',
  DISMISS_NEW_MESSAGE_SUCCESS = 'DISMISS_NEW_MESSAGE_SUCCESS',
  DISMISS_NEW_MESSAGE_FAILURE = 'DISMISS_NEW_MESSAGE_FAILURE',

  SEND_CHAT_MESSAGE_REQUEST = 'SEND_CHAT_MESSAGE_REQUEST',
  SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS',
  SEND_CHAT_MESSAGE_FAILURE = 'SEND_CHAT_MESSAGE_FAILURE',

  DECLINE_AGENT_REQUEST_REQUEST = 'DECLINE_AGENT_REQUEST_REQUEST',
  DECLINE_AGENT_REQUEST_SUCCESS = 'DECLINE_AGENT_REQUEST_SUCCESS',
  DECLINE_AGENT_REQUEST_FAILURE = 'DECLINE_AGENT_REQUEST_FAILURE',

  GET_PROSPECTS_BY_PROPERTY_REQUEST = 'GET_PROSPECTS_BY_PROPERTY_REQUEST',
  GET_PROSPECTS_BY_PROPERTY_SUCCESS = 'GET_PROSPECTS_BY_PROPERTY_SUCCESS',
  GET_PROSPECTS_BY_PROPERTY_FAILURE = 'GET_PROSPECTS_BY_PROPERTY_FAILURE',

  GET_CHAT_CONVERSATIONS_REQUEST = 'GET_CHAT_CONVERSATIONS_REQUEST',
  GET_CHAT_CONVERSATIONS_SUCCESS = 'GET_CHAT_CONVERSATIONS_SUCCESS',
  GET_CHAT_CONVERSATIONS_FAILURE = 'GET_CHAT_CONVERSATIONS_FAILURE',

  CLEAR_NEW_MESSAGE_ALERT = 'CLEAR_NEW_MESSAGE_ALERT',
  CLEAR_NEW_AGENT_REQUEST_ALERT = 'CLEAR_NEW_AGENT_REQUEST_ALERT',
  SET_PROSPECTS_OFFLINE = 'SET_PROSPECTS_OFFLINE',
  REMOVE_FROM_PROSPECTS_REQUESTED_AGENT = 'REMOVE_FROM_PROSPECTS_REQUESTED_AGENT',
  SET_CHAT_TYPE = 'SET_CHAT_TYPE',
  SET_CHAT_MINIMISE_STATUS = 'SET_CHAT_MINIMISE_STATUS',
  SET_CHAT_IS_RENDERED = 'SET_CHAT_IS_RENDERED',
  SET_CHAT_AS_ACTIVE = 'SET_CHAT_AS_ACTIVE',
  CLEAR_ACTIVE_CHATS = 'CLEAR_ACTIVE_CHATS',
  SET_ACTIVE_PROPERTIES = 'SET_ACTIVE_PROPERTIES',
  REMOVE_PROSPECTS_BY_PROPERTY = 'REMOVE_PROSPECTS_BY_PROPERTY',
  REMOVE_FROM_ACTIVE_CHATS = 'REMOVE_FROM_ACTIVE_CHATS',
  REMOVE_LEAD_FROM_ACTIVE_CHATS = 'REMOVE_LEAD_FROM_ACTIVE_CHATS',
  REORDER_ACTIVE_CHATS = 'REORDER_ACTIVE_CHATS',
  MINIMIZE_CHAT_WINDOW = 'MINIMIZE_CHAT_WINDOW',
  SET_ACTIVE_CHAT_SLIDE = 'SET_ACTIVE_CHAT_SLIDE',
  SET_CURRENT_TAB = 'SET_CURRENT_TAB',
  CLEAR_TYPING = 'CLEAR_TYPING',
  CLEAR_PROSPECTS = 'CLEAR_PROSPECTS',
  REMOVE_PROSPECT = 'REMOVE_PROSPECT',
  SET_DRAFT_MESSAGE = 'SET_DRAFT_MESSAGE',
}

export interface ActiveProspect {
  id: number;
  isSMS: boolean;
  name?: string;
  circled?: boolean;
  minimized?: boolean;
  isSingleChat?: boolean;
  draft?: string;
  unread_count?: number;
  is_online?: boolean;
  type?: string,
  property_id?: number,
  property_external_id?: string,
  property_name?: string,
  lease?: number,
}

export interface Prospect {
  id: number;
  active_agent: number;
  last_message: string;
  last_message_date: string;
  unread_count: number;
  name: string;
  has_guest_card: boolean;
  is_online: boolean;
  joined_agents: number[];
  last_prospect_message_date: string;
  last_prospect_message: string;
  last_prospect_formatted_message: string;
  last_greeting_date: string;
  has_not_seen_new_message: boolean;
  last_visit_page_name: string;
  has_active_tour: boolean;
  should_display_in_chat: boolean;
  tour_date: string;
  property_external_id: string;
  property_name: string;
  created: string;
  updated: string;
  external_id: string;
  last_visit_page: string;
  is_archived: boolean;
  is_mute: boolean;
  is_chat_open: boolean;
  is_waiting_agent: boolean;
  is_in_group: boolean;
  is_active: boolean;
  number: number;
  source: string;
  unloaded_time: string;
  tour_scheduling_in_progress: boolean;
  property: number;
  lead: number;
  guest_card: number;
  conversion: number;
  task: number;
  isSMS?: boolean;
  sms_opt_in_status?: string,
  property_id?: number,
  lease?: number,
  type?: string,
}

export interface ChatMessage {
  id?: number;
  prospect?: number;
  agent?: number;
  body?: { type: string; agent: number };
  message?: string;
  date?: string;
  property?: number;
  property_external_id?: string,
  type?: string;
  available_agents_count?: number;
  action?: string;
  is_form_message?: boolean;
  is_read?: boolean;
}

export interface AgentRequest {
  is_active?: boolean;
  created?: string;
  prospect?: number;
  is_declined?: boolean;
  property?: number;
  id?: number;
}

export interface Typing {
  is_typing: boolean;
  prospect_id: number;
}

interface GetAllProspectsAction {
  type: ActionType.GET_PROSPECTS_REQUEST | ActionType.GET_PROSPECTS_SUCCESS | ActionType.GET_PROSPECTS_FAILURE;

  result: {
    data: { results: Prospect[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface JoinProspectAction {
  type: ActionType.JOIN_PROSPECT_CHAT_REQUEST | ActionType.JOIN_PROSPECT_CHAT_SUCCESS | ActionType.JOIN_PROSPECT_CHAT_FAILURE;

  result: {
    data: ChatMessage;
  };
  error: {
    response: { status: string };
  };
}

interface RequestToJoinConversationAction {
  type: ActionType.REQUEST_TO_JOIN_CONVERSATION_REQUEST | ActionType.REQUEST_TO_JOIN_CONVERSATION_SUCCESS | ActionType.REQUEST_TO_JOIN_CONVERSATION_FAILURE;

  result: {
    data: { success: boolean };
  };
  error: {
    response: { status: string };
  };
}

interface SendSMSAction {
  type: ActionType.CHANGE_PROSPECT_CHAT_STATUS_REQUEST | ActionType.CHANGE_PROSPECT_CHAT_STATUS_SUCCESS | ActionType.CHANGE_PROSPECT_CHAT_STATUS_FAILURE;

  result: {
    data: Prospect;
  };
  error: {
    response: { status: string };
  };
}

interface ReadAllAction {
  type: ActionType.PROSPECT_CHAT_READ_ALL_REQUEST | ActionType.PROSPECT_CHAT_READ_ALL_SUCCESS | ActionType.PROSPECT_CHAT_READ_ALL_FAILURE;

  result: {
    data: Prospect;
  };
  error: {
    response: { status: string };
  };
}

interface DismissNewMessageAction {
  type: ActionType.DISMISS_NEW_MESSAGE_REQUEST | ActionType.DISMISS_NEW_MESSAGE_SUCCESS | ActionType.DISMISS_NEW_MESSAGE_FAILURE;

  result: {
    data: Prospect;
  };
  error: {
    response: { status: string };
  };
}

interface SendMessageToProspectAction {
  type: ActionType.SEND_CHAT_MESSAGE_REQUEST | ActionType.SEND_CHAT_MESSAGE_SUCCESS | ActionType.SEND_CHAT_MESSAGE_FAILURE;

  result: {
    data: ChatMessage;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateAgentRequestAction {
  type: ActionType.DECLINE_AGENT_REQUEST_REQUEST | ActionType.DECLINE_AGENT_REQUEST_SUCCESS | ActionType.DECLINE_AGENT_REQUEST_FAILURE;

  result: {
    data: AgentRequest;
  };
  error: {
    response: { status: string };
  };
}

interface GetProspectsByPropertyAction {
  type: ActionType.GET_PROSPECTS_BY_PROPERTY_REQUEST | ActionType.GET_PROSPECTS_BY_PROPERTY_SUCCESS | ActionType.GET_PROSPECTS_BY_PROPERTY_FAILURE;
  result: {
    data: {
      results: Prospect[];
    };
  };
  error: {
    response: { status: string };
  };
}

interface ClearNewMessageAlertAction {
  type: ActionType.CLEAR_NEW_MESSAGE_ALERT;
}

interface ClearAgentRequestAlertAction {
  type: ActionType.CLEAR_NEW_AGENT_REQUEST_ALERT;
}

interface SetProspectOfflineAction {
  type: ActionType.SET_PROSPECTS_OFFLINE;
  ids: number[];
}

interface RemoveFromProspectsRequestedAgentAction {
  type: ActionType.REMOVE_FROM_PROSPECTS_REQUESTED_AGENT;
  id: number;
}

interface SetChatTypeAction {
  type: ActionType.SET_CHAT_TYPE;
  data: string;
}

interface SetChatMinimiseStatusAction {
  type: ActionType.SET_CHAT_MINIMISE_STATUS;
  data: boolean;
}

interface SetChatRenderedStatusAction {
  type: ActionType.SET_CHAT_IS_RENDERED;
  data: boolean;
}

interface SetChatAsActiveAction {
  type: ActionType.SET_CHAT_AS_ACTIVE;
  contact: ActiveProspect;
}

interface ClearActiveChatsAction {
  type: ActionType.CLEAR_ACTIVE_CHATS;
}

interface SetActivePropertiesAction {
  type: ActionType.SET_ACTIVE_PROPERTIES;
  ids: number[];
}

interface RemoveProspectsByPropertyAction {
  type: ActionType.REMOVE_PROSPECTS_BY_PROPERTY;
  id: number;
}

interface RemoveFromActiveChatsAction {
  type: ActionType.REMOVE_FROM_ACTIVE_CHATS;
  contact: ActiveProspect;
}

interface RemoveLeadChatFromActiveChatsAction {
  type: ActionType.REMOVE_LEAD_FROM_ACTIVE_CHATS;
  leadId: number;
}

interface GetConversationsAction {
  type: ActionType.GET_CHAT_CONVERSATIONS_REQUEST | ActionType.GET_CHAT_CONVERSATIONS_SUCCESS | ActionType.GET_CHAT_CONVERSATIONS_FAILURE;
  result: {
    data: {
      results: ChatMessage[];
    };
  };
  error: {
    response: { status: string };
  };
}

interface ReorderActiveChatsAction {
  type: ActionType.REORDER_ACTIVE_CHATS;
  contact: ActiveProspect;
}

interface MinimizeChatWindowAction {
  type: ActionType.MINIMIZE_CHAT_WINDOW;
  contact: ActiveProspect;
}

interface SetActiveSlideAction {
  type: ActionType.SET_ACTIVE_CHAT_SLIDE;
  activeSlide: number;
}

interface SetCurrentTabAction {
  type: ActionType.SET_CURRENT_TAB;
  tabKey: string;
}

interface ClearTypingAction {
  type: ActionType.CLEAR_TYPING;
}

interface ClearProspectsAction {
  type: ActionType.CLEAR_PROSPECTS;
}

interface RemoveProspectByLeadIdAction {
  type: ActionType.REMOVE_PROSPECT;
  leadId: number;
}

interface SetDraftToActiveChatAction {
  type: ActionType.SET_DRAFT_MESSAGE;
  id: number;
  text: string;
}

export type Action =
  | PromiseActionType
  | GetAllProspectsAction
  | JoinProspectAction
  | SendSMSAction
  | ReadAllAction
  | DismissNewMessageAction
  | SendMessageToProspectAction
  | UpdateAgentRequestAction
  | GetProspectsByPropertyAction
  | ClearNewMessageAlertAction
  | ClearAgentRequestAlertAction
  | SetProspectOfflineAction
  | RemoveFromProspectsRequestedAgentAction
  | SetChatTypeAction
  | SetChatMinimiseStatusAction
  | SetChatRenderedStatusAction
  | SetChatAsActiveAction
  | ClearActiveChatsAction
  | SetActivePropertiesAction
  | RemoveProspectsByPropertyAction
  | RemoveFromActiveChatsAction
  | ReorderActiveChatsAction
  | MinimizeChatWindowAction
  | SetActiveSlideAction
  | SetCurrentTabAction
  | ClearTypingAction
  | PusherAction
  | RemoveLeadChatFromActiveChatsAction
  | GetConversationsAction
  | ClearProspectsAction
  | RemoveProspectByLeadIdAction
  | SetDraftToActiveChatAction
  | GetLeadByIdAction
  | RequestToJoinConversationAction;
