import resident from './resident/reducers';
import lease from './lease/reducers';

export type ResidentStateType = {
  resident: ReturnType<typeof resident>,
  leaseDetails: ReturnType<typeof lease>,
};

export default {
  resident,
  leaseDetails: lease,
};
