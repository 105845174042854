import { api, build } from 'leasing/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  getDependents: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_DEPENDENTS_REQUEST,
        ActionType.GET_DEPENDENTS_SUCCESS,
        ActionType.GET_DEPENDENTS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.DEPENDENTS, leaseId)),
    },
  }),
};
