import { PaginationResponse } from 'src/interfaces';
import { CommonActionResponse, PromiseActionType } from 'dwell/store/types';
import { UserProps } from '../user/action-types';

export enum ActionType {
  GET_QUESTIONS_PERCENTAGE_BY_STATUS_REQUEST = 'GET_QUESTIONS_PERCENTAGE_BY_STATUS_REQUEST',
  GET_QUESTIONS_PERCENTAGE_BY_STATUS_SUCCESS = 'GET_QUESTIONS_PERCENTAGE_BY_STATUS_SUCCESS',
  GET_QUESTIONS_PERCENTAGE_BY_STATUS_FAILURE = 'GET_QUESTIONS_PERCENTAGE_BY_STATUS_FAILURE',

  GET_QUESTIONS_WITH_ANSWERS_REQUEST = 'GET_QUESTIONS_WITH_ANSWERS_REQUEST',
  GET_QUESTIONS_WITH_ANSWERS_SUCCESS = 'GET_QUESTIONS_WITH_ANSWERS_SUCCESS',
  GET_QUESTIONS_WITH_ANSWERS_FAILURE = 'GET_QUESTIONS_WITH_ANSWERS_FAILURE',

  CREATE_QUESTION_ANSWER_REQUEST = 'CREATE_QUESTION_ANSWER_REQUEST',
  CREATE_QUESTION_ANSWER_SUCCESS = 'CREATE_QUESTION_ANSWER_SUCCESS',
  CREATE_QUESTION_ANSWER_FAILURE = 'CREATE_QUESTION_ANSWER_FAILURE',

  UPDATE_QUESTION_ANSWER_REQUEST = 'UPDATE_QUESTION_ANSWER_REQUEST',
  UPDATE_QUESTION_ANSWER_SUCCESS = 'UPDATE_QUESTION_ANSWER_SUCCESS',
  UPDATE_QUESTION_ANSWER_FAILURE = 'UPDATE_QUESTION_ANSWER_FAILURE',

  CONFIRM_QUESTION_ANSWER_REQUEST = 'CONFIRM_QUESTION_ANSWER_REQUEST',
  CONFIRM_QUESTION_ANSWER_SUCCESS = 'CONFIRM_QUESTION_ANSWER_SUCCESS',
  CONFIRM_QUESTION_ANSWER_FAILURE = 'CONFIRM_QUESTION_ANSWER_FAILURE',
}

export enum QuestionType {
  ALL='all',
  CONFIRMED='confirmed',
  UNANSWERED='unanswered',
  UNCONFIRMED='unconfirmed',
}

export type InsiderQuestionsPercentageByStatus = {
  [k in QuestionType]?: number
}

export interface InsiderInfoAnswer {
  id: number;
  confirmedBy?: UserProps;
  answer: string;
}

export interface InsiderInfoResponse {
  id: number;
  agent?: UserProps;
}

export interface AnswerDetilas {
  id: number;
  answer: string;
  imported: boolean;
  confirmed_by?: string;
  answered_by?: string;
}

export interface InsiderQuestionWithAnswer {
  id: number;
  label: string;
  category: string;
  answer_details?: AnswerDetilas;
}

export interface InsiderQuestionWithAnswerParams {
  propertyId: number;
  page: number;
  categoryId?: number;
  questionType?: string;
  searchFilter?: string;
}

export interface InsiderInfoAnswerResponse {
  questionId: number;
  answer_details: AnswerDetilas;
}

interface GetQuestionsPercentageByStatusAction extends CommonActionResponse {
  type: ActionType.GET_QUESTIONS_PERCENTAGE_BY_STATUS_REQUEST
  | ActionType.GET_QUESTIONS_PERCENTAGE_BY_STATUS_SUCCESS
  | ActionType.GET_QUESTIONS_PERCENTAGE_BY_STATUS_FAILURE;
  result: {data: InsiderQuestionsPercentageByStatus };
}

interface GetQuestionsWithAnswersAction extends CommonActionResponse, PaginationResponse<InsiderQuestionWithAnswer> {
  type: ActionType.GET_QUESTIONS_WITH_ANSWERS_REQUEST
  | ActionType.GET_QUESTIONS_WITH_ANSWERS_SUCCESS
  | ActionType.GET_QUESTIONS_WITH_ANSWERS_FAILURE;
}

interface CreateInsiderQuestionAnswerAction extends CommonActionResponse {
  type: ActionType.CREATE_QUESTION_ANSWER_REQUEST
  | ActionType.CREATE_QUESTION_ANSWER_SUCCESS
  | ActionType.CREATE_QUESTION_ANSWER_FAILURE,
  result: { data: InsiderInfoAnswerResponse },
}

interface UpdateInsiderQuestionAnswerAction extends CommonActionResponse {
  type: ActionType.UPDATE_QUESTION_ANSWER_REQUEST
  | ActionType.UPDATE_QUESTION_ANSWER_SUCCESS
  | ActionType.UPDATE_QUESTION_ANSWER_FAILURE,
  result: { data: InsiderInfoAnswerResponse },
}

interface ConfirmInsiderQuestionAnswerAction extends CommonActionResponse {
  type: ActionType.CONFIRM_QUESTION_ANSWER_REQUEST
  | ActionType.CONFIRM_QUESTION_ANSWER_SUCCESS
  | ActionType.CONFIRM_QUESTION_ANSWER_FAILURE,
  result: { data: InsiderInfoAnswerResponse },
}

export type Action =
  PromiseActionType
  | GetQuestionsPercentageByStatusAction
  | GetQuestionsWithAnswersAction
  | CreateInsiderQuestionAnswerAction
  | UpdateInsiderQuestionAnswerAction
  | ConfirmInsiderQuestionAnswerAction;
