
import { Section, Page } from 'corporate/store/page/action-types';

export type SiteType = 'corporate' | 'subsite' | 'standalone';

interface IframePayload {
  action: 'navigate' | 'scroll_to_section' | 'set_sections' | 'change_styles' | 'get_height' | 'change_zoom';
  page?: Page;
  pageId?: string;
  propertyId?: number;
  siteType?: SiteType;
  propertyExternalId?: string;
  section?: Section;
  insertIndex?: number;
  styles?: { [key: string]: string };
  zoom?: string;
}

export const IframeZoomOptions = [
  '25', '33', '50', '67', '75', '80', '90', '100', '110',
  '125', '150', '175', '200', '250', '300', '400', '500',
];

class IFrameActions {
  // eslint-disable-next-line class-methods-use-this
  getIframe() {
    return document.getElementById('the-iframe') as HTMLIFrameElement;
  }

  postMessage(data: IframePayload) {
    const iframe = this.getIframe();
    if (iframe) {
      iframe.contentWindow.postMessage(data, '*');
    }
  }

  navigateToPage(page: string, siteType: SiteType, propertyId: number) {
    setTimeout(() => this.postMessage({ action: 'navigate', pageId: page, siteType, propertyId }), 500);
    this.askForIframeHeight();
  }

  scrollToSection(section: Section) {
    setTimeout(() => this.postMessage({ action: 'scroll_to_section', section }), 500);
    this.askForIframeHeight();
  }

  setSections(page: Page, siteType: SiteType) {
    this.postMessage({ action: 'set_sections', page, siteType });
    this.askForIframeHeight();
  }

  setStyles(styles: { [key: string]: string }) {
    this.postMessage({ action: 'change_styles', styles });
  }

  setZoom(zoom: string) {
    this.postMessage({ action: 'change_zoom', zoom });
  }

  askForIframeHeight() {
    setTimeout(() => this.postMessage({ action: 'get_height' }), 500);
  }
}

const iframeApi = new IFrameActions();

export default iframeApi;
