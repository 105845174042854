import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'dwell/actions/index';
import { CONTACTS_N_PROSPECTS_OFFSET } from 'dwell/constants';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import { Avatar } from 'styles/common';
import { getAvatarColor, getInitials, formatMessageDate, getNoContentText } from 'dwell/views/chat/common/utils';
import Skeleton from 'react-loading-skeleton';
import { LineSkeleton } from 'src/utils';
import {
  ChatBody,
  ChatList,
  ChatListItem,
  ChatListItemBody,
  ChatListItemHeader,
  ChatSubHeader,
  ChatSearch,
  EmptyContent,
} from './styles';

const defaultContacts = new Array(5).fill({ name: 'Contact' }).map((item, i) => ({ ...item, id: i }));

const SMSContact: FC = () => {
  const activeChats = useSelector(state => state.prospectChat.activeChats);
  const contacts = useSelector(state => state.smsMessage.contacts);
  const downloadedCount = useSelector(state => state.smsMessage.downloadedCount);
  const totalContactsCount = useSelector(state => state.smsMessage.allContactsCount);
  const isContactsLoading = useSelector(state => state.smsMessage.isContactsLoading);
  const isContactsLoaded = useSelector(state => state.smsMessage.isContactsLoaded);
  // const isNotificationRedirection = useSelector(state => state.smsMessage.isNotificationRedirection);
  const lead = useSelector(state => state.lead.lead);

  const dispatch = useDispatch();
  const { setChatAsActive } = actions.prospectChat;
  const { updateNotificationRedirection, getSMSContacts } = actions.smsMessage;

  const [keyword, setKeyword] = useState('');
  const [searchInFocus, setSearchInFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [scrollEl, setScrollEl] = useState<any>(null);
  const [canScroll, setCanScroll] = useState(false);
  const [scrollAutomated, setScrollAutomated] = useState(false);
  const [preventDownload, setPreventDownload] = useState(false);

  const scrollToActiveContact = (id) => {
    const contactBar = document.getElementById('contact-bar');
    const scrollHeight = document.getElementById(`option-${id}`);
    if (scrollHeight && contactBar) contactBar.scrollTop = scrollHeight.offsetTop;
  };

  const onscroll = ({ target }) => {
    if (preventDownload) {
      setPreventDownload(false);
      return;
    }
    if (totalContactsCount === downloadedCount) return;
    if (scrollAutomated) {
      setScrollAutomated(false);
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (scrollTop && scrollTop + clientHeight >= (scrollHeight - 5) && totalContactsCount && !keyword) {
      setIsLoading(true);
      dispatch(getSMSContacts(downloadedCount, CONTACTS_N_PROSPECTS_OFFSET)).then((response) => {
        if (response) {
          setCanScroll(true);
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (lead.id) {
      // if (!isNotificationRedirection) {
      //   if (contacts.find(c => c.id === lead.id)) dispatch(setChatAsActive({ id: lead.id, isSMS: true, isSingleChat: true }));
      // }
      setPreventDownload(true);
      scrollToActiveContact(lead.id);
      dispatch(updateNotificationRedirection(false));
    }
  }, [lead.id]);

  const renderContact = (contact, index) => {
    const lastMessage = (contact.last_message || '').replace(/<br\s*\/?>/gi, ' ').replace(/\s\s+/g, ' ');

    return (
      <React.Fragment key={index}>
        <ChatListItem
          className={cn('active', { new: contact.unread_count, selected: activeChats.map(i => i.id).includes(contact.id) })}
          onClick={isContactsLoaded ? () => dispatch(setChatAsActive({
            id: contact.id,
            name: contact.name,
            isSMS: true,
            isSingleChat: true,
            unread_count: contact.unread_count,
            property_external_id: contact.property_external_id,
          })) : null}
          id={`option-${contact.id}`}
          hideOnlineIcon
        >
          {isContactsLoaded ?
            <Avatar className={`avatar offline ${getAvatarColor(contact.name, contact.id, true)}`}>
              <i>{getInitials(contact.name, true)}</i>
            </Avatar> : <Skeleton circle height={46} width={46} />}
          <ChatListItemBody className="body">
            <ChatListItemHeader className="header">
              <h6><span>{isContactsLoaded ? `${contact.name} | ${contact.property_name}` : <LineSkeleton width={100} height={12} /> }</span></h6>
              <small>{formatMessageDate(contact.last_message_date)}</small>
            </ChatListItemHeader>
            {isContactsLoaded ? <p dangerouslySetInnerHTML={{ __html: lastMessage }} /> : <LineSkeleton width={200} height={9} /> }
          </ChatListItemBody>
        </ChatListItem>
      </React.Fragment>
    );
  };

  const filteredContacts = contacts.filter(i => i.name.toLowerCase().includes(keyword.toLowerCase()) && i.property_can_sms);
  const contactsUnread = filteredContacts.filter(contact => contact.unread_count).sort((a, b) => (new Date(b.last_message_date).getTime() - new Date(a.last_message_date).getTime()));
  const contactsRead = filteredContacts.filter(contact => !contact.unread_count).sort((a, b) => (new Date(b.last_message_date).getTime() - new Date(a.last_message_date).getTime()));
  let resultContacts = contactsUnread.concat(contactsRead);

  useEffect(() => {
    if (!isEmpty(resultContacts) && canScroll && isContactsLoaded && !isContactsLoading) {
      if (scrollEl && scrollEl.childElementCount > 7) {
        setScrollAutomated(true);
        const contactBar = document.getElementById('contact-bar');
        let offset = resultContacts.length - 1 - CONTACTS_N_PROSPECTS_OFFSET;
        offset = offset < 0 ? resultContacts.length - 3 : offset;
        const scrollHeight = document.getElementById(`option-${resultContacts[offset].id}`);
        if (scrollHeight && contactBar) contactBar.scrollTop = scrollHeight.offsetTop;
        setCanScroll(false);
      }
    }
  }, [resultContacts, canScroll, isContactsLoaded, isContactsLoading]);

  if (isContactsLoading || isLoading) {
    resultContacts = defaultContacts;
  }

  const emptyContent = (
    <EmptyContent>
      <i className="ri-chat-1-line" />
      <h5><span>{keyword ? 'No Chats Found' : getNoContentText('SMS').label}</span></h5>
      <p>{keyword ? 'Try adjusting your search to find what you’re looking for.' : getNoContentText('SMS').text}</p>
    </EmptyContent>
  );

  const content = resultContacts.length ? resultContacts.map((prospect, index) => renderContact(prospect, index)) : emptyContent;

  return (
    <>
      <ChatSubHeader>
        <ChatSearch onfocus={searchInFocus}>
          <i className="ri-search-line" />
          <input
            type="text"
            className="form-control"
            placeholder="Search SMS contacts"
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
            onBlur={() => setSearchInFocus(false)}
            onFocus={() => setSearchInFocus(true)}
          />
        </ChatSearch>
      </ChatSubHeader>
      <ChatBody>
        <ChatList
          onScroll={onscroll}
          containerRef={(ref) => {
            setScrollEl(ref);
          }}
          tag="ul"
          id="contact-bar"
        >
          {content}
        </ChatList>;
      </ChatBody>
    </>);
};

export default SMSContact;
