import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { LeadDetailHeader as DetailHeader, LeadDetailMain as DetailMain } from 'dwell/views/lead/layout/styles';
import 'src/scss/pages/_lead_details.scss';
import Loader from 'resident/views/layout/loader';
import KeyInfo from 'resident/views/key_info';
import GoBack from '../go_back';

interface LeadDetailLayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LeadDetailLayoutProps> = ({ children }) => (
  <DetailMain className="resident-details">
    <Helmet>
      <title>Union</title>
    </Helmet>
    <DetailHeader $isCreditBuilder>
      <GoBack />
      <KeyInfo isCreditBuilder />
    </DetailHeader>
    {children}
    <Loader />
  </DetailMain>
);

export default Layout;
