import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import actions from 'dwell/actions';
import { SMS_LOAD_MORE_OFFSET } from 'dwell/constants';
import { AssistBotConversationTypes } from 'dwell/store/assist_bot/action-types';
import { FloorPlanTypes } from 'dwell/store/property_profile/action-types';
import { ChatSpinner, SpinnerBorder } from 'dwell/views/chat/single_chat/contact/styles';
import SisterCommunitiesCard from 'dwell/views/property_profile/sister_communities/sister_communities_card';
import PromotionCard from 'dwell/views/property_profile/promotion';
import UnitCard from '../available_units/unit_card';
import {
  CardAIItem, CardChat, CardChatAI, ChatAuthor, ChatFooter, ChatGroup, ChatItem, ChatWrapper, Avatar, ChatAILabel,
} from './styles';
import { getClipboard, getUnitsResponse, getFloorPlansResponse } from './utils';
import ClipboardButton from './tooltip';

const AssistBot: FC = () => {
  const dispatch = useDispatch();
  const conversations = useSelector(state => state.assistBot.conversations);
  const totalCount = useSelector(state => state.assistBot.totalCount);
  const isLoaded = useSelector(state => state.assistBot.isLoaded);
  const currentProperty = useSelector(state => state.propertyProfile.currentProperty);
  const currentUser = useSelector(state => state.user.currentUser);
  const floorPlans = useSelector(state => state.propertyProfile.floorPlans) as FloorPlanTypes[];
  let suggestedTopics = useSelector(state => state.assistBot.suggestedTopics);
  let answerAssistTopics = useSelector(state => state.assistBot.answerAssistTopics);
  const [command, setCommand] = useState(null);
  const [isShowAutoSuggestion, showAutoSuggestion] = useState(false);
  const [message, setMessage] = useState('');
  const [oldScrollPosition, setOldScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const chatWindow = useRef(null);
  const suggestionRef = useRef(null);
  const inputRef = useRef(null);
  const answerAssistShortKeys = { A: 65, S: 83, D: 68, F: 70, H: 72 };
  const suggestedTopicsShortKeys = { J: 74, K: 75, L: 76 };
  const comm = [17, 91, 93, 224];
  const { getAssistBotConversations, createAssistBotConversation, clearAssistBotConversations, setSuggestedTopics } = actions.assistBot;

  suggestedTopics = useMemo(() => {
    if (!message) return suggestedTopics.slice(0, 5);
    return suggestedTopics.filter(t => t.content.toLowerCase().includes(message.toLowerCase())).slice(0, 5);
  }, [message, suggestedTopics]);

  answerAssistTopics = useMemo(() => {
    if (!message) return answerAssistTopics.filter(t => t.is_default);
    return answerAssistTopics.filter(t => t.content.toLowerCase().includes(message.toLowerCase())).slice(0, 5);
  }, [message]);

  const loadConversations = () =>
    dispatch(getAssistBotConversations(currentProperty.id, { offset: conversations.length, limit: SMS_LOAD_MORE_OFFSET }));

  useEffect(() => {
    if (!isLoaded) {
      loadConversations();
    }
    inputRef?.current?.focus();
  }, []);

  const mouseDown = (e) => {
    if (suggestionRef?.current?.querySelector('.card-body') && !suggestionRef?.current?.contains(e.target) && !inputRef?.current?.contains(e.target)) {
      showAutoSuggestion(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', mouseDown);
    return () => document.removeEventListener('mousedown', mouseDown);
  }, []);

  useEffect(() => {
    if (!isShowAutoSuggestion && document.activeElement === inputRef?.current) showAutoSuggestion(true);
  }, [message]);

  useEffect(() => {
    if (isLoaded && !conversations.length) {
      const msg = `<strong>Good morning, ${currentUser.first_name}</strong><br />What can I help you with today?`;
      const payload = {
        message: msg,
        type: 'BOT',
        date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        response_type: 'WELCOME',
        no_need_clipboard: true,
      } as AssistBotConversationTypes;
      dispatch(createAssistBotConversation(currentProperty.id, payload));
    }
  }, [isLoaded]);

  useEffect(() => {
    if (chatWindow.current) {
      const newScroll = chatWindow.current.scrollHeight - chatWindow.current.clientHeight;
      chatWindow.current.scrollTop += (newScroll - oldScrollPosition);
    }
  }, [conversations, totalCount]);

  const onscroll = (target) => {
    if (totalCount === conversations.length) return;
    if (target && target.scrollTop === 0 && !isLoading && totalCount) {
      setOldScrollPosition(target.scrollHeight - target.clientHeight);
      setIsLoading(true);
      setTimeout(() => {
        loadConversations().then(() => setIsLoading(false));
      }, 1000);
    }
  };

  const onClick = () => showAutoSuggestion(true);
  const askHobbes = (question) => {
    if (question === '/clear') {
      dispatch(clearAssistBotConversations(currentProperty.id));
      return;
    }
    const payload = {
      sender: currentUser.id.toString(),
      message: question,
      property_name: currentProperty.domain,
      mode: 'answer_assist',
    };
    let msg = '';
    let customData = '';
    let responseType = null;
    axios.post(`${window.crmApp.config.chatbotHost}/endpoint`, payload).then((response) => {
      const { text, custom } = response.data;
      if (text) {
        text.forEach((item) => {
          if (item) {
            msg += `\n${item}`;
          }
        });
      }
      dispatch(setSuggestedTopics(custom.similar_queries.map(t => ({ content: t.text, intent: t.intent }))));
      if (custom) {
        if (custom.type && custom.type === 'show_sister_communities' && custom.sister_communitities) {
          customData = JSON.stringify(custom.sister_communitities);
          responseType = 'SISTER_COMMUNITIES';
        } else if (custom.type && custom.type === 'promotion' && custom.promotion) {
          customData = JSON.stringify(custom.promotion);
          responseType = 'PROMOTION';
        } else if (custom.type && custom.type === 'show_units' && custom.slots) {
          responseType = 'UNITS';
          const grouped = getUnitsResponse(custom.slots, floorPlans);
          customData = JSON.stringify(grouped);
        } else if (custom.type && custom.type === 'show_floor_plans' && custom.floor_plans) {
          responseType = 'UNITS';
          const grouped = getFloorPlansResponse(custom.floor_plans);
          customData = JSON.stringify(grouped);
        }
      }

      if (msg) {
        const body = {
          message: msg,
          type: 'BOT',
          date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          no_need_clipboard: !!responseType,
        } as AssistBotConversationTypes;

        dispatch(createAssistBotConversation(currentProperty.id, body));
      }
      if (customData && customData !== '{}') {
        const body = {
          message: customData,
          type: 'BOT',
          date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          response_type: responseType,
          no_need_clipboard: responseType === 'UNITS',
        } as AssistBotConversationTypes;

        dispatch(createAssistBotConversation(currentProperty.id, body));
      }
    });
  };
  const copyToClipboard = (value) => {
    const ele = document.querySelector('.tooltip-inner');
    if (ele) {
      ele.innerHTML = 'Copy';
    }
    navigator.clipboard.writeText(value);
  };

  const keyDown = (e) => {
    let shouldPreventDefault = false;
    if (comm.includes(e.keyCode)) {
      setCommand(e.keyCode);
    } else if (command) {
      const element = document.querySelector(`a[data-key="${e.keyCode}"]`) as HTMLSpanElement;
      if (element) {
        shouldPreventDefault = true;
        element.click();
      }

      if (e.keyCode === 86) {
        const conv = conversations[conversations.length - 1];
        if (conv) {
          copyToClipboard(getClipboard(conv.response_type, conv.message, conv.no_need_clipboard));
        }
      }
    }
    if (shouldPreventDefault) e.preventDefault();
  };

  const keyUp = (e) => {
    e.preventDefault();
    if (command) {
      setCommand(null);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  });

  const postMessage = (content = null, isSuggested = false) => {
    const msg = content || message;
    const payload = {
      message: msg,
      type: 'AGENT',
      date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      is_suggested: isSuggested,
    } as AssistBotConversationTypes;
    dispatch(createAssistBotConversation(currentProperty.id, payload)).then(() => {
      askHobbes(msg);
      setMessage('');
      showAutoSuggestion(false);
    });
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && message) {
      postMessage();
    }
  };
  const onChange = ({ target: { value } }) => {
    setMessage(value);
  };
  const clickTopic = (content) => {
    setMessage(content);
    postMessage(content, true);
    showAutoSuggestion(false);
  };

  return (
    <>
      <ChatWrapper ref={chatWindow} onScroll={onscroll}>
        {isLoading && (
          <ChatSpinner>
            <SpinnerBorder>
              <span className="sr-only">Loading ...</span>
            </SpinnerBorder>
          </ChatSpinner>
        )}
        <ChatGroup>
          {conversations.map((conv, index) => {
            const timeEle = (
              <ChatAuthor>
                <Avatar className="avatar"><i className="ri-rocket-fill" /></Avatar>
                <span>Hobbes {moment(conv.date).format('MMM DD, hh:mma')}</span>
              </ChatAuthor>
            );
            const clipboard = getClipboard(conv.response_type, conv.message, conv.no_need_clipboard);
            const clipboardEle = clipboard && (
              <ClipboardButton index={index} onClick={() => copyToClipboard(clipboard)} />
            );
            if (conv.type === 'AGENT') {
              return (
                <div className="d-flex flex-column" key={index}>
                  <ChatItem className="response">
                    <CardChat className="card-chat">
                      <div className="card-body" dangerouslySetInnerHTML={{ __html: conv.message }} />
                    </CardChat>
                  </ChatItem>
                  <ChatAuthor className="justify-content-end">
                    <span>{moment(conv.date).format('MMM DD, hh:mma')}</span>
                  </ChatAuthor>
                </div>
              );
            }
            if (conv.response_type === 'UNITS') {
              const un = JSON.parse(conv.message);
              return un.constructor === ({}).constructor && (
                <div className="mt-2">
                  <UnitCard grouped={JSON.parse(conv.message) || {}} activeUnit="" />
                  {timeEle}
                </div>
              );
            }
            if (conv.response_type === 'SISTER_COMMUNITIES') {
              const un = JSON.parse(conv.message);
              return un.constructor === ([]).constructor && (
                <div className="mt-2">
                  <SisterCommunitiesCard sisterCommunities={JSON.parse(conv.message) || {}} />
                  {clipboardEle}
                  {timeEle}
                </div>
              );
            }
            if (conv.response_type === 'PROMOTION') {
              const un = JSON.parse(conv.message);
              return un.constructor === ({}).constructor && (
                <div className="mt-2">
                  <PromotionCard promotion={un} clipboard={clipboardEle} />
                  {timeEle}
                </div>
              );
            }
            return (
              <ChatItem key={index}>
                <CardChat className="card-chat">
                  <div className="card-body" dangerouslySetInnerHTML={{ __html: conv.message?.replaceAll('\n', '<br />') }} />
                  {clipboardEle}
                </CardChat>
                {timeEle}
              </ChatItem>
            );
          })}
        </ChatGroup>
      </ChatWrapper>
      <div ref={suggestionRef}>
        {isShowAutoSuggestion && (
          <CardChatAI className="show">
            <div id="ask3" className="card-body">
              {!!answerAssistTopics.length && (
                <>
                  <div className="card-header">
                    <i className="ri-stack-line" />
                    Answer Assist AI
                  </div>
                  {answerAssistTopics.map((topic, index) => (
                    <CardAIItem
                      className="chat-ai-item"
                      key={index}
                      onClick={() => clickTopic(topic.content)}
                      data-key={Object.values(answerAssistShortKeys)[index]}
                    >
                      <div>{topic.content}</div>
                      <span><i className="ri-command-line" /></span><span>{Object.keys(answerAssistShortKeys)[index]}</span>
                    </CardAIItem>
                  ))}
                </>
              )}
              {!!suggestedTopics.length && (
                <>
                  <ChatAILabel>
                    <i className="ri-stack-line" /> Suggested Topics
                  </ChatAILabel>
                  {suggestedTopics.map((topic, index) => (
                    <CardAIItem
                      className="chat-ai-item"
                      key={index}
                      onClick={() => clickTopic(topic.content)}
                      data-key={Object.values(suggestedTopicsShortKeys)[index]}
                    >
                      <div>{topic.content}</div>
                      <span><i className="ri-command-line" /></span><span>{Object.keys(suggestedTopicsShortKeys)[index]}</span>
                    </CardAIItem>
                  ))}
                </>
              )}
            </div>
          </CardChatAI>
        )}
      </div>
      <ChatFooter>
        <input
          ref={inputRef}
          type="text"
          className="form-control"
          onClick={onClick}
          onKeyPress={onKeyPress}
          onChange={onChange}
          value={message}
          placeholder="Ask about property topics"
        />
      </ChatFooter>
    </>
  );
};

export default AssistBot;
