import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Mention } from 'react-mentions';
import actions from 'dwell/actions/index';
import moment from 'moment';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { isEmpty, get } from 'lodash';
import { toast, ToastOptions } from 'react-toastify';
import { Prospect as IProspect } from 'dwell/store/chat/action-types';
import { SMSMessage } from 'dwell/store/sms/action-types';
import { Avatar } from 'styles/common';
import { SMS_LOAD_MORE_OFFSET } from 'dwell/constants';
import { toastError } from 'site/constants';
import { getAvatarColor, getInitials, defaultStyle } from 'dwell/views/chat/common/utils';
import { ChatSpinner, SpinnerBorder } from 'dwell/views/chat/single_chat/contact/styles';
import { ChatItemBody, ChatItemFooter, ChatItemMessage, MessageInput, ChatItem, CustomMentionInput, ResponseTyping, MiniLoader, HiddenChatItem } from 'dwell/views/chat/single_chat/window/styles';

interface SMSChatBodyApplicationProps {
  contact: IProspect,
  minimized: boolean,
  isSingleChat: boolean,
}

// TODO if needed work more on send sms logic on next phase

const SMSChatBodyApplication: FC<SMSChatBodyApplicationProps> = ({ contact, minimized, isSingleChat }) => {
  const conversations: SMSMessage[] = useSelector(state => state.smsMessage.conversations);
  const isSendingText: boolean = useSelector(state => state.smsMessage.isSendingText);
  const notifications: { redirect_url: string, id: number, type: string }[] = useSelector(state => state.notification.notifications);
  const activeChats = useSelector(state => state.prospectChat.activeChats);

  const dispatch = useDispatch();
  const { getConversations, readAll, sendSMS } = actions.smsMessage;
  const { setDraftToActiveChat } = actions.prospectChat;
  const { bulkClearNotifications } = actions.notification;

  const [newMessage, setNewMessage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [oldScrollPosition, setOldScrollPosition] = useState(0);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const [showSending, setShowSending] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [draftRendered, setDraftRendered] = useState(false);

  const chatWindow: { current: { _container: { scrollTop: number, scrollHeight: number, clientHeight: number } }} | undefined = useRef();
  const filteredConversations = conversations.filter(c => c.leasing_user === contact.id);

  useEffect(() => {
    if (!isEmpty(activeChats) && contact.id && !newMessage && !draftRendered) {
      const currentActiveChat = activeChats.find(a => a.id === contact.id);
      if (typeof currentActiveChat !== 'undefined') {
        if (currentActiveChat.draft) {
          setNewMessage(currentActiveChat.draft);
        }
        setDraftRendered(true);
      }
    }
  }, [activeChats, contact.id, newMessage, draftRendered]);

  const scrollToLastMessage = () => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      _container.scrollTop = _container.scrollHeight;
    }
  };

  const clearNotifications = (id) => {
    const notificationArray = [];
    notifications.forEach((data) => {
      if (data.redirect_url && parseInt(data.redirect_url.split('/').pop(), 10) === id && data.type === 'NEW_SMS') notificationArray.push(data.id);
    });
    if (notificationArray.length) dispatch(bulkClearNotifications({ ids: notificationArray }));
  };

  const getSms = () => dispatch(getConversations({
    leasing_user: contact.id,
    lease: contact.lease,
    params: {
      offset: filteredConversations.length,
      limit: SMS_LOAD_MORE_OFFSET,
      property: contact.property_id,
    },
  }));

  useEffect(() => {
    if (contact.id) {
      if ((!minimized || !isSingleChat) && !totalCount) {
        getSms().then((response) => {
          if (response) {
            const { result: { data: { count, results } } } = response;
            setTotalCount(count);
            if (results.some(el => !el.is_read)) {
              dispatch(readAll({ leasing_user: contact.id, lease: contact.lease }));
            }
          }
          scrollToLastMessage();
          clearNotifications(contact.lease);
        });
      } else if (contact.unread_count) {
        // for the unread message
        dispatch(readAll({ leasing_user: contact.id, lease: contact.lease }));
      }
    }
  }, [contact.unread_count]);

  const onscroll = ({ target }) => {
    if (totalCount === filteredConversations.length) return;
    if (target && target.scrollTop === 0) {
      setIsScrollLoading(true);
      setOldScrollPosition(target.scrollHeight - target.clientHeight);
      setTimeout(() => {
        if (contact.id) {
          getSms().then((response) => {
            if (response?.result) {
              const { result: { data: { count } } } = response;
              setTotalCount(count);
            }
            setIsScrollLoading(false);
          });
        }
      }, 1000);
    }
  };

  const sendMessage = (message) => {
    if (message && !isSendDisabled) {
      if (contact.id) {
        setShowSending(true);
        dispatch(setDraftToActiveChat('', contact.id));
        setNewMessage('');
        dispatch(sendSMS({ leasing_user: contact.id, message, [contact.type]: contact.lease }, contact.property_id, contact.type))
          .then(() => {
            setShowSending(false);
            dispatch(setDraftToActiveChat('', contact.id));
            setNewMessage('');
            scrollToLastMessage();
          }).catch((error) => {
            const errorMsg = get(error, 'response.data.detail', '');
            if (errorMsg === 'Failed to send message') {
              toast.error('Phone number invalid. Please provide correct phone number for Applicant.', toastError as ToastOptions);
            }
          });
      }
    }
  };

  const replaceSubjectEmptyVariables = () => {
    const placeholders = document.getElementsByClassName('subject-variable');
    if (!isEmpty(placeholders)) {
      [...placeholders].forEach((el) => {
        el.classList.add('subject-variable-empty');
        el.classList.remove('subject-variable');
      });
    }
    setIsSendDisabled(!isEmpty([...document.getElementsByClassName('subject-variable-empty')]));
  };

  useEffect(() => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      const newScroll = _container.scrollHeight - _container.clientHeight;
      _container.scrollTop += (newScroll - oldScrollPosition);
    }
  }, [filteredConversations, totalCount, showSending]);

  useEffect(() => {
    setTimeout(() => replaceSubjectEmptyVariables(), 100);
  }, [newMessage]);

  const handleKeyUp = (e) => {
    replaceSubjectEmptyVariables();
    if (((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) && !isEmpty(newMessage) && newMessage !== '\n') {
      if (!isSendingText && !isSendDisabled) {
        sendMessage(newMessage);
      }
    } else if (((e.key === 'Enter' || e.keyCode === 13) && e.shiftKey)) {
      dispatch(setDraftToActiveChat(newMessage.concat('\n'), contact.id));
      setNewMessage(newMessage.concat('\n'));
    }
  };

  const handleKeyDown = (e) => {
    replaceSubjectEmptyVariables();
    if ((e.key === 'Enter' || e.keyCode === 13) && !isEmpty(newMessage)) {
      e.preventDefault();
    }
  };

  const handleChange = (value) => {
    dispatch(setDraftToActiveChat(value, contact.id));
    setNewMessage(value);
  };

  return (
    <>
      <ChatItemBody ref={chatWindow} onScroll={onscroll} isSingleChat={isSingleChat}>
        <ul>
          {isScrollLoading && (
            <ChatSpinner>
              <SpinnerBorder>
                <span className="sr-only">Loading ...</span>
              </SpinnerBorder>
            </ChatSpinner>
          )}
          {conversations.filter(message => message.leasing_user === contact.id)
            .map((message, index) => (
              <ChatItem reverse={message.is_team_message} isSMS key={`item-${index}`}>
                <Avatar
                  className={`avatar ${message.is_team_message ? 'bg-dark' : getAvatarColor(contact.name, contact.id, true)}`}
                >
                  {message.is_team_message && message.agent_avatar ?
                    <img src={message.agent_avatar} alt="avatar" /> :
                    <i>{getInitials(message.is_team_message ? message.agent_name : contact.name, true)}</i>}
                </Avatar>
                <ChatItemMessage key={index} className="message">
                  {message.is_team_message ? (
                    <p dangerouslySetInnerHTML={{ __html: message.message.replace(/(\r\n|\r|\n|\\r\\n|\\r|\\n)/g, '<br />') }} />
                  ) : (
                    <p>
                      <span>{message.message}</span>
                    </p>
                  )}
                  <small>{moment(message.date).format('MMM DD, hh:mma')}</small>
                </ChatItemMessage>
              </ChatItem>))}
          <HiddenChatItem reverse show={showSending}>
            <Avatar className="avatar bg-dark">
              <i>{getInitials(currentUser.first_name)}</i>
            </Avatar>
            <ResponseTyping>
              <MiniLoader />
            </ResponseTyping>
          </HiddenChatItem>
        </ul>
      </ChatItemBody>
      <ChatItemFooter>
        <MessageInput className="subject">
          <CustomMentionInput
            placeholder="Write a SMS message"
            value={newMessage}
            style={defaultStyle}
            onClick={() => replaceSubjectEmptyVariables()}
            onChange={({ target: { value } }) => handleChange(value)}
            onBlur={() => setTimeout(() => replaceSubjectEmptyVariables())}
            onKeyUp={e => handleKeyUp(e)}
            onKeyDown={e => handleKeyDown(e)}
          >
            <Mention
              appendSpaceOnAdd
              className="subject-variable"
              trigger="["
              markup="[=__display__=]"
              displayTransform={(_id, display) => `[=${display}=]`}
              data={(_matchInfo, callback) => callback([])}
              renderSuggestion={(_suggestion, _search, highlightedDisplay, _index, focused) => (<div className={`${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>)}
            />
          </CustomMentionInput>
          <div className="d-flex align-items-center justify-content-end">
            <span
              className="send"
              onClick={() => sendMessage(newMessage)}
            >
              <i className="ri-send-plane-fill" />
            </span>
          </div>
        </MessageInput>
      </ChatItemFooter>
    </>);
};

export default SMSChatBodyApplication;
