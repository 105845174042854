import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import actions from 'dwell/actions/index';
import { selectSelectedProperties, selectSomeSelectedPropertiesHaveVoiceSetup } from 'dwell/store/property/reducers';
import OutgoingCallButton from 'dwell/views/chat/single_chat/contact/call/outgoing_call_button';
import { Nav, NavLink } from 'reactstrap';
import { UserProps } from 'dwell/store/user/action-types';
import { userCanUseCallsModule } from 'dwell/views/calls/communications/voice_centre/utils';
import { ChatHeader, ChatPanelContainer, ChatHeaderLink } from './styles';
import SMSContact from './_sms';
import ProspectChat from './_prospect';
import CallContacts from './call/index';

const ContactPanel: FC = () => {
  const chatType = useSelector(state => state.prospectChat.chatType);
  const isChatMinimized = useSelector(state => state.prospectChat.isChatMinimized);
  const currentUser = useSelector(state => state.user.currentUser) as UserProps;
  const prospects = useSelector(state => state.prospectChat.prospects.filter(p => p.should_display_in_chat));
  const contacts = useSelector(state => state.smsMessage.contacts);
  const selectedProperties = useSelector(selectSelectedProperties);
  const someSelectedPropertiesHaveVoiceSetup = useSelector(selectSomeSelectedPropertiesHaveVoiceSetup);

  const dispatch = useDispatch();
  const { setChatRenderedStatus, setChatMinimiseStatus, setChatType } = actions.prospectChat;

  useEffect(() => {
    if (!isEmpty(selectedProperties) && !((selectedProperties.length > 1) || (selectedProperties.length === 1 && selectedProperties[0].agent_chat_enabled)) && chatType === 'chat') {
      dispatch(setChatType('sms'));
    }
  }, [selectedProperties]);

  const prospectUnread = prospects.reduce((acc, crr) => {
    if ((crr.active_agent === currentUser.id || crr.joined_agents.includes(currentUser.id)) && !crr.is_mute && !crr.is_archived) {
      return acc + Number(crr.unread_count);
    }
    return acc;
  }, 0);

  const smsUnread = contacts.reduce((acc, crr) => (acc + Number(crr.unread_count)), 0);

  const changeChatMinimiseStatus = () => {
    if (isChatMinimized) {
      dispatch(setChatRenderedStatus(true));
    }
    dispatch(setChatMinimiseStatus(!isChatMinimized));
  };

  const contactsSwitch = {
    chat: <ProspectChat />,
    sms: <SMSContact />,
    calls: <CallContacts />,
  };

  return (
    <ChatPanelContainer isMinmized={isChatMinimized}>
      <ChatHeader>
        <Nav className="mr-0">
          {((selectedProperties.length > 1) || (selectedProperties.length === 1 && selectedProperties[0].agent_chat_enabled)) && (
            <NavLink className="ml-0" active={chatType === 'chat'} onClick={() => dispatch(setChatType('chat'))}>Chat {!!prospectUnread && <span>{prospectUnread}</span>}</NavLink>
          )}
          <NavLink className="ml-0" active={chatType === 'sms'} onClick={() => dispatch(setChatType('sms'))}>SMS {!!smsUnread && <span>{smsUnread}</span>}</NavLink>
          {someSelectedPropertiesHaveVoiceSetup && userCanUseCallsModule(currentUser.role) && <NavLink className="ml-0" active={chatType === 'calls'} onClick={() => dispatch(setChatType('calls'))}>Calls</NavLink>}
        </Nav>
        {someSelectedPropertiesHaveVoiceSetup && currentUser.has_voice_setup && userCanUseCallsModule(currentUser.role) && <OutgoingCallButton />}
        <ChatHeaderLink className="ml-auto" onClick={() => changeChatMinimiseStatus()}>
          <i className="ri-close-fill" />
        </ChatHeaderLink>
      </ChatHeader>
      {contactsSwitch[chatType]}
    </ChatPanelContainer>
  );
};

export default ContactPanel;
