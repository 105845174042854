import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, RoommateProps } from './action-types';

export default {
  getRoommates: (leadId: number, params: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ROOMMATES_REQUEST,
        ActionType.GET_ROOMMATES_SUCCESS,
        ActionType.GET_ROOMMATES_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.ROOMMATES, leadId), { params }),
    },
  }),

  getRoommateById: (id: number, leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ROOMMATE_BY_ID_REQUEST,
        ActionType.GET_ROOMMATE_BY_ID_SUCCESS,
        ActionType.GET_ROOMMATE_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.ROOMMATE_DETAILS, leadId, id)),
    },
  }),

  deleteRoommateById: (id: number, leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_ROOMMATE_REQUEST,
        ActionType.DELETE_ROOMMATE_SUCCESS,
        ActionType.DELETE_ROOMMATE_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.ROOMMATE_DETAILS, leadId, id)),
    },
  }),

  saveRoommates: (data: RoommateProps[], leadId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_ROOMMATES_REQUEST,
        ActionType.UPDATE_ROOMMATES_SUCCESS,
        ActionType.UPDATE_ROOMMATES_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.ROOMMATES_SAVE, leadId), data),
    },
  }),
};
