import produce from 'immer';
import { CommonStateType } from 'dwell/store/types';
import { ActionType as PageActionType, Section } from 'src/corporate/store/page/action-types';
import { Action, ActionType } from './action-types';

export interface SectionState extends CommonStateType {
  sections: Section[];
  deletedSections: Section[];
  sectionSelected: Section;
  pageSelectedId: number, // Needed to validate section updates in the correct page
  showAddNewSectionPanel: boolean;
  showDeleteModal: boolean;
  sectionToDelete: Section;
  showCustomStylesSectionPanel: boolean;
}

export const initialState: SectionState = {
  sections: [],
  deletedSections: [],
  sectionSelected: null,
  pageSelectedId: null,
  showAddNewSectionPanel: false,
  showDeleteModal: false,
  sectionToDelete: null,
  showCustomStylesSectionPanel: false,
};

export const selectSections = (state: { section: SectionState }): Section[] => state.section.sections;
export const selectSectionSelected = (state: { section: SectionState }): Section => state.section.sectionSelected;
export const selectDeletedSections = (state: { section: SectionState }): Section[] => state.section.deletedSections;
export const selectShowAddNewSectionPanel = (state: { section: SectionState }): boolean => state.section.showAddNewSectionPanel;
export const selectShowDeleteModal = (state: { section: SectionState }): boolean => state.section.showDeleteModal;
export const selectSectionToDelete = (state: { section: SectionState }): Section => state.section.sectionToDelete;
export const selectShowCustomStylesSectionPanel = (state: { section: SectionState }): boolean => state.section.showCustomStylesSectionPanel;

export default produce((state: SectionState = initialState, action: Action): SectionState => {
  switch (action.type) {
    case PageActionType.GET_SITE_PAGE_DATA_REQUEST:
    case PageActionType.GET_SUBSITE_PAGE_DATA_REQUEST:
      state.isLoaded = false;
      break;
    case PageActionType.GET_SITE_PAGE_DATA_SUCCESS: {
      state.isLoaded = true;
      const homePage = action.result.data.find(page => page.page === 'HOME') || action.result.data[0];
      state.pageSelectedId = homePage.id;
      break;
    }
    case PageActionType.GET_SUBSITE_PAGE_DATA_SUCCESS: {
      state.isLoaded = true;
      const homePage = action.result.data.find(page => page.page === 'HOME') || action.result.data[0];
      state.pageSelectedId = homePage.id;
      break;
    }

    case PageActionType.CORPORATE_SET_PAGE:
      state.pageSelectedId = action.pageId;
      break;

    case PageActionType.PUBLISH_CORPORATE_SITE_SUCCESS:
      state.isSubmitting = false;
      state.deletedSections = [];
      state.pageSelectedId = action.result.data[0].id;
      break;

    case ActionType.CLOSE_ADD_NEW_SECTION_PANEL:
      state.showAddNewSectionPanel = false;
      break;

    case ActionType.SHOW_DELETE_SECTION_MODAL:
      state.showDeleteModal = true;
      break;
    case ActionType.HIDE_DELETE_SECTION_MODAL:
      state.showDeleteModal = false;
      break;

    case ActionType.SHOW_CUSTOM_STYLES_SECTION:
      state.showCustomStylesSectionPanel = !state.showCustomStylesSectionPanel;
      break;

    case ActionType.SET_SECTION_TO_DELETE:
      state.sectionToDelete = action.section;
      break;

    case ActionType.CLEAR_SECTION_TO_DELETE:
      state.sectionToDelete = null;
      break;

    case ActionType.SET_SECTIONS:
      state.sections = action.sections;
      break;

    case ActionType.SET_SECTION: {
      const section = state.sections.find(s => s.id === action.sectionId);
      if (section) state.sectionSelected = section;
      break;
    }

    case ActionType.TOGGLE_ADD_NEW_SECTION_PANEL:
      state.showAddNewSectionPanel = !state.showAddNewSectionPanel;
      break;

    case ActionType.CLEAR_SECTION:
      state.sectionSelected = null;
      break;

    case ActionType.ADD_CORPORATE_SECTION_REQUEST:
    case ActionType.ADD_SUBSITE_SECTION_REQUEST:
    case ActionType.DELETE_CORPORATE_SECTION_REQUEST:
    case ActionType.DELETE_SUBSITE_SECTION_REQUEST:
    case ActionType.CHANGE_ORDER_CORPORATE_SECTION_REQUEST:
    case ActionType.CHANGE_ORDER_SUBSITE_SECTION_REQUEST:
    case ActionType.UPDATE_CORPORATE_SECTION_REQUEST:
    case ActionType.UPDATE_SUBSITE_SECTION_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.ADD_CORPORATE_SECTION_SUCCESS:
    case ActionType.ADD_SUBSITE_SECTION_SUCCESS: {
      if (state.pageSelectedId === action.result.data.id) {
        state.sections = action.result.data.sections;
        // autoselect section at index 1 only when a new one is added
        [, state.sectionSelected] = state.sections;
        state.showAddNewSectionPanel = false;
      }
      state.isSubmitting = false;
      break;
    }
    case ActionType.DELETE_CORPORATE_SECTION_SUCCESS:
    case ActionType.DELETE_SUBSITE_SECTION_SUCCESS:
    case ActionType.CHANGE_ORDER_CORPORATE_SECTION_SUCCESS:
    case ActionType.CHANGE_ORDER_SUBSITE_SECTION_SUCCESS:
    case ActionType.UPDATE_CORPORATE_SECTION_SUCCESS:
    case ActionType.UPDATE_SUBSITE_SECTION_SUCCESS: {
      // Handle case where Header/Section is updated from other page than Home
      // not checking this will cause override sections from Home in a different page
      if (state.pageSelectedId === action.result.data.id) {
        state.sections = action.result.data.sections;
        state.showAddNewSectionPanel = false;
      }
      state.isSubmitting = false;
      break;
    }
    case ActionType.ADD_CORPORATE_SECTION_FAILURE:
    case ActionType.ADD_SUBSITE_SECTION_FAILURE:
    case ActionType.DELETE_CORPORATE_SECTION_FAILURE:
    case ActionType.DELETE_SUBSITE_SECTION_FAILURE:
    case ActionType.CHANGE_ORDER_CORPORATE_SECTION_FAILURE:
    case ActionType.CHANGE_ORDER_SUBSITE_SECTION_FAILURE:
    case ActionType.UPDATE_CORPORATE_SECTION_FAILURE:
    case ActionType.UPDATE_SUBSITE_SECTION_FAILURE:
      state.isSubmitting = false;
      break;
  }
  return state;
});
