import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { paginationData } from 'src/interfaces';
import { ActionType, Action, ChatReportConversationData, ChatReportConversationMessageWithStatus, ChatReportConversation } from './action-types';

export default {
  getChatReports: (params: paginationData): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CHAT_REPORTS_REQUEST,
        ActionType.GET_CHAT_REPORTS_SUCCESS,
        ActionType.GET_CHAT_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CHAT_REPORTS, { params }),
    },
  }),

  getSingleChatReport: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SINGLE_CHAT_REPORT_REQUEST,
        ActionType.GET_SINGLE_CHAT_REPORT_SUCCESS,
        ActionType.GET_SINGLE_CHAT_REPORT_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.CHAT_REPORT_DETAILS, id)),
    },
  }),

  getChatReportById: (id: number, support_filter: string): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CHAT_REPORT_BY_ID_REQUEST,
        ActionType.GET_CHAT_REPORT_BY_ID_SUCCESS,
        ActionType.GET_CHAT_REPORT_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.CHAT_REPORT_DETAILS, id), { params: { support_filter } }),
    },
  }),

  exportChatReportById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.EXPORT_CHAT_REPORT_BY_ID_REQUEST,
        ActionType.EXPORT_CHAT_REPORT_BY_ID_SUCCESS,
        ActionType.EXPORT_CHAT_REPORT_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.CHAT_REPORT_EVALUATION, id)),
    },
  }),

  getChatReportForEvaluationById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_REQUEST,
        ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS,
        ActionType.GET_CHAT_REPORT_EVALUATION_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.CHAT_REPORT_EVALUATION, id)),
    },
  }),

  setChatReportStatusById: (id: number, data: { status: string; type: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_EVALUATION_REPORT_REQUEST,
        ActionType.UPDATE_EVALUATION_REPORT_SUCCESS,
        ActionType.UPDATE_EVALUATION_REPORT_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.CHAT_REPORT_DETAILS, id), data),
    },
  }),

  updateChatReportStatusById: (id: number, data: { status: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.CHANGE_EVALUATION_REPORT_REQUEST,
        ActionType.CHANGE_EVALUATION_REPORT_SUCCESS,
        ActionType.CHANGE_EVALUATION_REPORT_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.CHAT_REPORT_DETAILS, id), data),
    },
  }),

  autoSaveChatReportStatusesById: (id: number, data: ChatReportConversationData): Action => ({
    CALL_API: {
      types: [
        ActionType.AUTOSAVE_EVALUATION_REPORT_REQUEST,
        ActionType.AUTOSAVE_EVALUATION_REPORT_REQUEST_SUCCESS,
        ActionType.AUTOSAVE_EVALUATION_REPORT_REQUEST_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.CHAT_REPORT_EVALUATION_SUBMIT, id), data),
    },
  }),

  getSingleConversationMessagesById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_REQUEST,
        ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS,
        ActionType.GET_CHAT_REPORT_MESSAGE_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.CHAT_REPORT_MESSAGE, id)),
    },
  }),

  updateMessageStatusesById: (id: number, message: ChatReportConversationMessageWithStatus): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CHAT_REPORT_MESSAGE_BY_ID_REQUEST,
        ActionType.UPDATE_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS,
        ActionType.UPDATE_CHAT_REPORT_MESSAGE_BY_ID_FAILURE,
      ],
      promise: client => client.patch(build(`${paths.api.v1.CHAT_REPORT_MESSAGE}${message.id}/`, id), message),
    },
  }),
  updateConversationStatusesById: (id: number, conversation: ChatReportConversation): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CHAT_REPORT_EVALUATION_BY_ID_REQUEST,
        ActionType.UPDATE_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS,
        ActionType.UPDATE_CHAT_REPORT_EVALUATION_BY_ID_FAILURE,
      ],
      promise: client => client.patch(build(`${paths.api.v1.CHAT_REPORT_EVALUATION}${conversation.id}/`, id), conversation),
    },
  }),
  updateRecentEvaluationReport: (): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_RECENT_CHAT_REPORTS_REQUEST,
        ActionType.UPDATE_RECENT_CHAT_REPORTS_SUCCESS,
        ActionType.UPDATE_RECENT_CHAT_REPORTS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.UPDATE_RECENT_CHAT_EVALUATION),
    },
  }),
};
