import React, { FC, useState } from 'react';
import { Dropdown } from 'reactstrap';

import { processLinkName } from './utils';

import { NavLinkToggle, NavDropdown, NavSubLink } from './styles';

interface NavItemDropdownProps {
  siteId: string;
  type: {
    name: string;
    navItems: {
      name: string;
      path: string;
    }[];
  };
  activeLink: string;
  setActiveAndPush: (url: string) => void;
}

const NavItemDropdown: FC<NavItemDropdownProps> = ({ siteId, type, activeLink, setActiveAndPush }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const lastPartOfPath = activeLink.split('/').pop();
  const activeLinkStatusArray = type.navItems.map(item => lastPartOfPath === item.name || lastPartOfPath === item.path.split('/').pop());
  const isActive = activeLinkStatusArray.reduce((prev, current) => prev || current, 0);

  const handleRedirect = (item) => {
    setActiveAndPush(item.path ? item.path : `/${siteId}/${item.name}`);
    setIsShown(!isShown);
  };

  return (
    <Dropdown tag="li" className="nav-item" isOpen={isShown} toggle={() => setIsShown(!isShown)}>
      <NavLinkToggle className={`nav-link with-sub ${isActive && 'active'}`}>{processLinkName(type.name)}</NavLinkToggle>
      <NavDropdown tag="nav" className="nav-sub">
        {type.navItems.map((item, index) => (
          <NavSubLink key={`NSL-${index}`} id={`${index}`} className="nav-sub-link" onClick={() => handleRedirect(item)}>
            {processLinkName(item.name)}
          </NavSubLink>
        ))}
      </NavDropdown>
    </Dropdown>
  );
};

export default NavItemDropdown;
