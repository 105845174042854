import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Col, FormGroup, FormText, Label, Row } from 'reactstrap';

import { fieldChoices, AUTO_CREATED, TOUR_TYPES } from 'dwell/constants';
import { selectProperty } from 'dwell/store/property/reducers';
import { InputProps, mapFloorPlans } from '../utils';

interface Props extends InputProps {
  isTour: boolean;
  isTaskOnly: boolean;
}

const tourTypes = Object.keys(TOUR_TYPES);

const TypeSelector: FC<Props> = ({ control, isTour, setValue, task, isTaskOnly }) => {
  const property = useSelector(selectProperty);

  const [disableSmartRent, setDisableSmartRent] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(
    () => setValue('type', (task.type && options.length) ? options.find(i => i.value === task.type) : null),
    [task.type, options],
  );

  useEffect(() => {
    if (property) {
      setOptions(Object.keys(fieldChoices.TASK_FILED_CHOICES.type)
        .filter((key) => {
          if (isTour || tourTypes.includes(key)) {
            if (isTaskOnly) return false;
            return (property.tour_types || []).includes(key) || task.type === key;
          }
          return true;
        })
        .map(key => ({ value: key, label: fieldChoices.TASK_FILED_CHOICES.type[key] }))
        .filter(i => (AUTO_CREATED.includes(i.value) ? task.id && i.value === task.type : true)));

      const hasUnits = mapFloorPlans(property.floor_plans || [], null)
        .reduce((acc, i) => i.options.length > 0 || acc, false);
      const propertyDontHaveSelfGuidedVendor = !property.self_guided_tour_vendor;
      setDisableSmartRent(propertyDontHaveSelfGuidedVendor || !hasUnits);
    } else {
      setOptions([]);
    }
  }, [property]);

  return (
    <Row className="mt-2">
      <Col xs="12" className="mg-t-10">
        <FormGroup>
          <Label htmlFor="type">{isTour ? 'Tour' : 'Task'} type:</Label>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <>
                <Select
                  {...field}
                  data-testid="type-selector"
                  inputId="type"
                  isOptionDisabled={({ value }) => (AUTO_CREATED.includes(value) || (value === 'SELF_GUIDED_TOUR' && disableSmartRent))}
                  options={options}
                  placeholder="Choose one"
                />
                {error &&
                  <FormText color="danger">Please choose a {isTour ? 'tour' : 'task'} type</FormText>}
              </>
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default TypeSelector;
