import produce from 'immer';
import { Action, ActionType } from './action-types';

export interface ResetPasswordState {
  isFormInvalid: boolean;
  isTokenInvalid: boolean;
}

export const initialState: ResetPasswordState = {
  isFormInvalid: false,
  isTokenInvalid: false,
};

export default produce((state: ResetPasswordState = initialState, action: Action): ResetPasswordState => {
  switch (action.type) {
    case ActionType.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      state.isFormInvalid = false;
      break;
    case ActionType.SEND_PASSWORD_RESET_EMAIL_FAILURE:
      state.isFormInvalid = !!action.error.response.status;
      break;

    case ActionType.RESET_PASSWORD_SUCCESS:
      state.isFormInvalid = false;
      break;
    case ActionType.RESET_PASSWORD_FAILURE:
      state.isFormInvalid = !!action.error.response.status;
      break;

    case ActionType.RESET_CHANGE_PASSWORD_STATE:
      state.isFormInvalid = false;
      state.isTokenInvalid = false;
      break;
  }

  return state;
});
