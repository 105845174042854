import { paths } from 'dwell/constants';
import { ManageRequestProps } from 'src/interfaces';
import { ActionType, Action } from './action-types';

export default {
  getCommunications: (params: ManageRequestProps, objectId = null, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_COMMUNICATIONS_REQUEST,
        ActionType.GET_COMMUNICATIONS_SUCCESS,
        ActionType.GET_COMMUNICATIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.OBJECT_COMMUNICATION, { params: { ...params }, cancelToken: token }),
      objectId: objectId || params.lead,
    },
  }),
  resetReloadState: (): Action => ({
    type: ActionType.RESET_RELOAD_STATE,
  }),
  resetCommunications: (): Action => ({
    type: ActionType.RESET_COMMUNICATIONS,
  }),
};
