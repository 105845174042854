import produce from 'immer';
import { UnitProps } from 'src/interfaces';
import { Action, ActionType } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface ApartmentState extends CommonStateType {
  apartments: UnitProps[],
  isLoaded: boolean,
  totalCount: number,
  totalCountAll: number,
  totalCountAvailable: number,
  totalCountOnHold: number,
  totalCountWaitlist: number,
}

export const initialState: ApartmentState = {
  isLoaded: false,
  apartments: [],
  totalCount: 0,
  totalCountAll: 0,
  totalCountAvailable: 0,
  totalCountOnHold: 0,
  totalCountWaitlist: 0,
};

export const selectIsApartmentsLoaded = (state: { apartment: ApartmentState }): boolean => state.apartment.isLoaded;
export const selectApartments = (state: { apartment: ApartmentState }): UnitProps[] => state.apartment.apartments;
export const selectTotalCount = (state: { apartment: ApartmentState }): number => state.apartment.totalCount;
export const selectTotalCountAll = (state: { apartment: ApartmentState }): number => state.apartment.totalCountAll;
export const selectTotalCountAvailable = (state: { apartment: ApartmentState }): number => state.apartment.totalCountAvailable;
export const selectTotalCountOnHold = (state: { apartment: ApartmentState }): number => state.apartment.totalCountOnHold;
export const selectTotalCountWaitlist = (state: { apartment: ApartmentState }): number => state.apartment.totalCountWaitlist;

export default produce((state: ApartmentState = initialState, action: Action): ApartmentState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_APARTMENTS_SUCCESS:
      state.apartments = action.result.data.results;
      state.totalCount = action.result.data.count;
      state.totalCountAll = action.result.data.all_apartments_count;
      state.totalCountAvailable = action.result.data.available_apartments_count;
      state.totalCountOnHold = action.result.data.on_hold_apartments_count;
      state.totalCountWaitlist = action.result.data.waitlist_apartments_count;
      break;
  }

  return state;
});
