import { ResetLeaseAction } from 'leasing/store/lease/action-types';
import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_SCREENING_RESULT_REQUEST = 'GET_SCREENING_RESULT_REQUEST',
  GET_SCREENING_RESULT_SUCCESS = 'GET_SCREENING_RESULT_SUCCESS',
  GET_SCREENING_RESULT_FAILURE = 'GET_SCREENING_RESULT_FAILURE',

  RUN_SCREENING_REQUEST = 'RUN_SCREENING_REQUEST',
  RUN_SCREENING_SUCCESS = 'RUN_SCREENING_SUCCESS',
  RUN_SCREENING_FAILURE = 'RUN_SCREENING_FAILURE',
}

export interface ScreeningReportProps {
  id: number,
  credit_decision: string,
  criminal_decision: string,
  error_message: string,
  report_url: string,
  status: string,
  score: number,
  date: string,
  created: string,
}

export interface ScreeningReportState extends CommonStateType {
  screeningReports: ScreeningReportProps[],
}

export interface RunScreeningParams {
  [key: number]: {
    credit: boolean;
    criminal: boolean;
    eviction: boolean;
  },
  skip?: boolean;
}

/*
 * Actions
 */

interface GetScreeningResultAction extends CommonActionResponse {
  type: ActionType.GET_SCREENING_RESULT_REQUEST
  | ActionType.GET_SCREENING_RESULT_SUCCESS
  | ActionType.GET_SCREENING_RESULT_FAILURE;
  result: { data: { results: ScreeningReportProps[] } };
}

interface RunScreeningAction extends CommonActionResponse {
  type: ActionType.RUN_SCREENING_REQUEST
  | ActionType.RUN_SCREENING_SUCCESS
  | ActionType.RUN_SCREENING_FAILURE;
  result: { data: { results: ScreeningReportProps[] } };
}

export type Action =
  PromiseActionType
  | GetScreeningResultAction
  | RunScreeningAction
  | ResetLeaseAction;
