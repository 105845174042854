import produce from 'immer';
import { Action, ActionType, CalendarProps } from './action-types';
import { CommonStateType } from '../types';

export interface CalendarState extends CommonStateType {
  isGettingCalendars: boolean;
  calendars: CalendarProps[];
}

const initialState: CalendarState = {
  isGettingCalendars: false,
  errorMessage: null,
  calendars: [],
};

export default produce((state: CalendarState = initialState, action: Action): CalendarState => {
  switch (action.type) {
    case ActionType.GET_CALENDARS_REQUEST:
      state.isGettingCalendars = true;
      break;
    case ActionType.GET_CALENDARS_SUCCESS:
      state.calendars = action.result.data.results;
      state.isGettingCalendars = false;
      break;
    case ActionType.GET_CALENDARS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isGettingCalendars = false;
      break;
  }

  return state;
});
