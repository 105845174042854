import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, OtherIncomeProps, OtherIncomeState } from './action-types';

export const initialState: OtherIncomeState = {
  errorMessage: null,
  other_incomes: [] as OtherIncomeProps[],
  isLoaded: false,
};

export const selectOtherIncomes = (state: { otherIncomes: OtherIncomeState }) : OtherIncomeProps[] =>
  state.otherIncomes?.other_incomes || [];

export default produce((state: OtherIncomeState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_OTHER_INCOMES_SUCCESS:
      state.other_incomes = action.result.data.results;
      break;

    case ActionType.UPDATE_OTHER_INCOMES_SUCCESS:
      state.other_incomes = action.result.data;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
