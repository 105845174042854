import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { cancelHighlight, highlightElement } from 'leasing/views/agreement/utils/highlight_element';
import { selectDepositAgreementDocument } from 'leasing/store/lease_document/reducers';
import { CheckBox } from 'site/components';
import { MultiSelectWrapper } from 'leasing/views/agreement/styles';

interface MultiCheckBoxProps {
  id: string,
  value: string[],
  onChange: (id: string, value: string[]) => void,
  name?: string,
  choices?: unknown,
}

const MultiCheckBox: FC<MultiCheckBoxProps> = ({ id, value, name, choices, onChange }) => {
  const depositAgreementDocument = useSelector(selectDepositAgreementDocument);
  const leaseFields = depositAgreementDocument?.lease_fields || [];

  const onFocus = () => {
    const field = leaseFields.find(i => i.field_label === name);
    cancelHighlight();
    highlightElement(field?.name, depositAgreementDocument?.name);
  };

  const handleEventChange = ({ target: { value: newValue, checked = false } }) => {
    onChange(id, checked ? (value || []).concat(newValue) : (value || []).filter(item => item !== newValue));
    onFocus();
  };

  return (
    <MultiSelectWrapper>
      {Object.keys(choices).map(key => (
        <CheckBox
          id={`${id}-${key.toLowerCase()}`}
          value={key}
          key={key}
          label={choices[key]}
          checked={(value || []).includes(key)}
          onChange={handleEventChange}
          labelClassName="label-checkbox"
        />
      ))}
    </MultiSelectWrapper>
  );
};

export default MultiCheckBox;
