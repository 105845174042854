import { PromiseActionType } from 'dwell/store/types';
import { UserProps } from '../user/action-types';

export enum ActionType {
  GET_RESCORE_USERS_REQUEST = 'GET_RESCORE_USERS_REQUEST',
  GET_RESCORE_USERS_SUCCESS = 'GET_RESCORE_USERS_SUCCESS',
  GET_RESCORE_USERS_FAILURE = 'GET_RESCORE_USERS_FAILURE',
  SET_RESCORE_USERS = 'SET_RESCORE_USERS',
}

interface GetRescoreUsersAction {
  type: ActionType.GET_RESCORE_USERS_REQUEST | ActionType.GET_RESCORE_USERS_SUCCESS | ActionType.GET_RESCORE_USERS_FAILURE;

  result: {
    data: UserProps[];
  };
  error: {
    response: { status: string };
  };
}

interface SetRescoreUsersAction {
  type: ActionType.SET_RESCORE_USERS;
  users: UserProps[];
}

export type Action = PromiseActionType | GetRescoreUsersAction | SetRescoreUsersAction;
