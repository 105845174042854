import React, { FC, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FormGroup, FormText, Label } from 'reactstrap';
import { sortBy, get } from 'lodash';

import { selectProperty } from 'dwell/store/property/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';

import { InputProps } from '../utils';

const customStyles = {
  option: (styles, { data }) => {
    const isAssignToMe = get(data, 'label') === 'Assign to Me';
    return {
      ...styles,
      fontStyle: isAssignToMe ? 'italic' : 'normal',
    };
  },
};

const OwnerSelector: FC<InputProps> = ({ control, setValue, task, watch }) => {
  const currentUser = useSelector(selectCurrentUser);
  const propertyUsers = useSelector(selectProperty).users || [];
  const owner = watch('owner');

  const users = useMemo(() => {
    const isCurrentUserOwner = get(owner, 'value') === currentUser.id;
    let usersList = propertyUsers.map((user) => {
      if (user.first_name || user.last_name) return { label: `${user.first_name} ${user.last_name}`, value: user.id };
      return { label: user.email, value: user.id };
    });
    if (!isCurrentUserOwner) {
      usersList = usersList.filter(u => u.value !== currentUser.id);
    }

    const sortedUsers = sortBy(usersList, ['label']);
    if (usersList.length !== propertyUsers.length) {
      return [{ label: 'Assign to Me', value: currentUser.id }].concat(sortedUsers);
    }

    return sortedUsers;
  }, [propertyUsers, currentUser, owner]);

  useEffect(() => {
    setValue('owner', task.owner ? users.find(u => u.value === task.owner) : null);
  }, [task.owner]);

  useEffect(() => {
    if (get(owner, 'label') === 'Assign to Me') setValue('owner', users.find(u => u.value === currentUser.id));
  }, [owner]);

  return (
    <FormGroup>
      <Label htmlFor="owner">Assigned to:</Label>
      <Controller
        control={control}
        name="owner"
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              {...field}
              inputId="owner"
              isSearchable
              options={users}
              placeholder="Choose one"
              styles={customStyles}
            />
            {error && <FormText color="danger">Owner is required</FormText>}
          </>
        )}
      />
    </FormGroup>
  );
};

export default OwnerSelector;
