import { SMSMessage } from 'src/dwell/store/sms/action-types';
import { ChatMessage, Prospect, AgentRequest, Typing } from 'src/dwell/store/chat/action-types';
import { DesktopPhoneCall, PusherOutboundCallStatus, PusherConferenceStarted } from '../voice/action-types';
import { Lead } from '../lead/action-types';
import { UserProps } from '../user/action-types';
import { TaskProps } from '../task/action-types';
import { CallProps } from '../call/action-types';
import { EmailMessageProps } from '../email_message/action-types';
import { NoteProps } from '../note/action-types';
import { ColumnProps } from '../columns_settings/action-types';
import { LeadFilterProps } from '../leads_filter/action-types';
import { RoommateProps } from '../roommate/action-types';

export enum ActionType {
  PUSHER_CREATE_RECORD = 'PUSHER_CREATE_RECORD',
  PUSHER_UPDATE_RECORD = 'PUSHER_UPDATE_RECORD',
  PUSHER_DELETE_RECORD = 'PUSHER_DELETE_RECORD',
  PUSHER_CLEAR = 'PUSHER_CLEAR',
  PUSHER_DESKTOP_PHONE_CALL_ANSWERED = 'PUSHER_DESKTOP_PHONE_CALL_ANSWERED',
  PUSHER_DESKTOP_PHONE_CALL_COMPLETED = 'PUSHER_DESKTOP_PHONE_CALL_COMPLETED',
  PUSHER_BROWSER_PHONE_CALL_ANSWERED = 'PUSHER_BROWSER_PHONE_CALL_ANSWERED',
  PUSHER_OUTBOUND_CALL_STATUS = 'PUSHER_OUTBOUND_CALL_STATUS',
  PUSHER_CONFERENCE_STARTED = 'PUSHER_CONFERENCE_STARTED',
}

export type PusherRow =
  SMSMessage &
  Lead &
  ChatMessage &
  Prospect &
  AgentRequest &
  Typing &
  UserProps &
  TaskProps &
  DesktopPhoneCall &
  PusherOutboundCallStatus &
  CallProps &
  EmailMessageProps &
  NoteProps &
  ColumnProps &
  LeadFilterProps &
  RoommateProps &
  PusherConferenceStarted;

export interface AlternativePusherRow {
  object: PusherRow;
}

export interface PusherAction {
  type: ActionType.PUSHER_CREATE_RECORD |
  ActionType.PUSHER_UPDATE_RECORD |
  ActionType.PUSHER_DELETE_RECORD |
  ActionType.PUSHER_DESKTOP_PHONE_CALL_ANSWERED |
  ActionType.PUSHER_DESKTOP_PHONE_CALL_COMPLETED |
  ActionType.PUSHER_BROWSER_PHONE_CALL_ANSWERED |
  ActionType.PUSHER_OUTBOUND_CALL_STATUS |
  ActionType.PUSHER_CONFERENCE_STARTED;
  row: PusherRow | AlternativePusherRow | ColumnProps[];
  pusherModel: string,
}

interface PusherClearAction {
  type: ActionType.PUSHER_CLEAR;
}

export type Action =
  | PusherAction
  | PusherClearAction
