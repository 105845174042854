import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormFeedback, Input } from 'reactstrap';
import { highlightElement, cancelHighlight } from 'leasing/views/agreement/utils/highlight_element';
import { selectDepositAgreementDocument } from 'leasing/store/lease_document/reducers';
import { DropdownLink, LeaseDropdown, LeaseDropdownItem, LeaseDropdownMenu } from 'dwell/views/lead/overview/styles';
import { DropdownLinkText } from './styles';

interface DropdownProps {
  arrayChoices?: (string | number)[],
  className?: string,
  choices?: unknown,
  disabled?: boolean,
  error?: string,
  id: string,
  invalid?: boolean,
  name?: string,
  needFocus?: boolean,
  onChange: (id: string, value: string | number) => void,
  touched?: boolean,
  value: string | number,
}

const Dropdown: FC<DropdownProps> = ({ id, value, name, className, choices, onChange, needFocus, invalid, error, arrayChoices, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const depositAgreementDocument = useSelector(selectDepositAgreementDocument);
  const leaseFields = depositAgreementDocument?.lease_fields || [];

  const onToggle = () => {
    if (needFocus) {
      if (isOpen) {
        cancelHighlight();
      } else {
        const field = leaseFields.find(i => i.field_label === name);
        cancelHighlight();
        highlightElement(field?.name, depositAgreementDocument?.name);
      }
    }

    setIsOpen(!isOpen);
  };

  return (
    <div id={id}>
      <LeaseDropdown isOpen={isOpen} toggle={onToggle} invalid={invalid || error} className={className} {...rest}>
        {arrayChoices ? (
          <>
            <DropdownLink id={`${id}-toggle`} caret className="d-flex justify-content-between h36" name={name}>
              <DropdownLinkText>
                {value}
              </DropdownLinkText>
            </DropdownLink>
            <LeaseDropdownMenu id={`${id}-menu`} right>
              {arrayChoices.map((field, key) => (
                <LeaseDropdownItem id={`${id}-value-${field?.toString().toLowerCase()}`} key={key} onClick={() => onChange(id, field)}>
                  {field}
                </LeaseDropdownItem>
              ))}
            </LeaseDropdownMenu>
          </>
        ) : (
          <>
            <DropdownLink id={`${id}-toggle`} caret className="d-flex justify-content-between h36" name={name}>
              <DropdownLinkText>
                {choices[value]}
              </DropdownLinkText>
            </DropdownLink>
            <LeaseDropdownMenu id={`${id}-menu`} right>
              {Object.keys(choices).map((field, key) => (
                <LeaseDropdownItem id={`${id}-value-${field?.toString().toLowerCase()}`} key={key} onClick={() => onChange(id, field)}>
                  {choices[field]}
                </LeaseDropdownItem>
              ))}
            </LeaseDropdownMenu>
          </>
        )}
      </LeaseDropdown>
      <Input hidden invalid={error} />
      <FormFeedback>{error}</FormFeedback>
    </div>
  );
};

export default Dropdown;
