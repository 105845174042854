import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import actions from 'dwell/actions/index';
import { DropdownSwitch, OnOff } from './styles';

const AvailableToChatSwitch: FC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const selectedProperties = useSelector(selectSelectedProperties);

  const { updateUserAvailableStatus, setManuallyUnavailable } = actions.user;

  const active = currentUser && currentUser.is_available;
  const agentChatEnabledArray = selectedProperties.map(p => p.agent_chat_enabled);
  const isAgentChatEnabled = agentChatEnabledArray.some(item => item === true);

  const onAvailableToChatClick = () => {
    if (currentUser.is_available) {
      dispatch(setManuallyUnavailable(true));
    } else {
      dispatch(setManuallyUnavailable(false));
    }
    dispatch(updateUserAvailableStatus(currentUser.id, { is_available: !currentUser.is_available }));
  };

  return (isAgentChatEnabled ?
    <DropdownSwitch>
      <i className="ri-chat-1-line" />
      <div>
        <p>Available to chat</p>
        <small className="inactive-text">{active ? 'Accepting' : 'Not accepting'} chat requests</small>
      </div>
      <OnOff
        active={active}
        onClick={onAvailableToChatClick}
        id="available-to-chat-div"
        data-status={currentUser.is_available ? 'AVAILABLE' : 'UNAVAILABLE'}
      >
        <span id="available-to-chat-span" data-status={currentUser.is_available ? 'AVAILABLE' : 'UNAVAILABLE'} />
      </OnOff>
    </DropdownSwitch> : null
  );
};

export default AvailableToChatSwitch;
