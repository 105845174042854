import produce from 'immer';
import { Action, ActionType } from './action-types';
import { preFillState } from '../utils';

export interface PusherState {
  pusherModel: string,
  pushNotification: {
    type: string,
    lead_owner: string,
    property?: { external_id: string },
  },
  pushLead: { id: number }[],
  pushEmail: { id: number }[],
  pushReport: { id: number }[],
}

const initialState: PusherState = {
  pusherModel: '',
  pushNotification: {} as { type: string, lead_owner: string, property?: { external_id: string } },
  pushLead: {} as { id: number }[],
  pushEmail: {} as { id: number }[],
  pushReport: {} as { id: number }[],
};

const getObjectName = (pusherModel) => {
  switch (pusherModel) {
    case 'roommate': return 'pushRoommate';
    case 'lead': return 'pushLead';
    case 'emailmessage': return 'pushEmail';
    case 'report': return 'pushReport';
    case 'notification': return 'pushNotification';
    default: return null;
  }
};

export const selectPushLead = (state: { pusher: PusherState }): { id: number }[] => state.pusher.pushLead;

export default produce((state: PusherState = initialState, action: Action): PusherState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.PUSHER_CREATE_RECORD:
    case ActionType.PUSHER_UPDATE_RECORD:
    case ActionType.PUSHER_DELETE_RECORD:
      state.pusherModel = action.pusherModel;
      state[getObjectName(action.pusherModel)] = action.row;
      break;

    case ActionType.PUSHER_CLEAR:
      state = initialState;
      break;
  }

  return state;
});
