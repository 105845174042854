import { PromiseActionType } from 'dwell/store/types';
import { Images, FloorPlan, Color } from 'src/interfaces';

export enum ActionType {
  GET_PAGE_DATA_REQUEST = 'GET_PAGE_DATA_REQUEST',
  GET_PAGE_DATA_SUCCESS = 'GET_PAGE_DATA_SUCCESS',
  GET_PAGE_DATA_FAILURE = 'GET_PAGE_DATA_FAILURE',

  UPDATE_PAGE_DATA_REQUEST = 'UPDATE_PAGE_DATA_REQUEST',
  UPDATE_PAGE_DATA_SUCCESS = 'UPDATE_PAGE_DATA_SUCCESS',
  UPDATE_PAGE_DATA_FAILURE = 'UPDATE_PAGE_DATA_FAILURE',

  PAGES_UPLOAD_IMAGE_REQUEST = 'PAGES_UPLOAD_IMAGE_REQUEST',
  PAGES_UPLOAD_IMAGE_SUCCESS = 'PAGES_UPLOAD_IMAGE_SUCCESS',
  PAGES_UPLOAD_IMAGE_FAILURE = 'PAGES_UPLOAD_IMAGE_FAILURE',

  CREATE_PAGE_DATA_REQUEST = 'CREATE_PAGE_DATA_REQUEST',
  CREATE_PAGE_DATA_SUCCESS = 'CREATE_PAGE_DATA_SUCCESS',
  CREATE_PAGE_DATA_FAILURE = 'CREATE_PAGE_DATA_FAILURE',

  UPDATE_PAGE_DATA_DOMAIN_REQUEST = 'UPDATE_PAGE_DATA_DOMAIN_REQUEST',
  UPDATE_PAGE_DATA_DOMAIN_SUCCESS = 'UPDATE_PAGE_DATA_DOMAIN_SUCCESS',
  UPDATE_PAGE_DATA_DOMAIN_FAILURE = 'UPDATE_PAGE_DATA_DOMAIN_FAILURE',

  GET_FLOOR_PLANS_REQUEST = 'GET_FLOOR_PLANS_REQUEST',
  GET_FLOOR_PLANS_SUCCESS = 'GET_FLOOR_PLANS_SUCCESS',
  GET_FLOOR_PLANS_FAILURE = 'GET_FLOOR_PLANS_FAILURE',

  CREATE_FLOOR_PLAN_REQUEST = 'CREATE_FLOOR_PLAN_REQUEST',
  CREATE_FLOOR_PLAN_SUCCESS = 'CREATE_FLOOR_PLAN_SUCCESS',
  CREATE_FLOOR_PLAN_FAILURE = 'CREATE_FLOOR_PLAN_FAILURE',

  UPDATE_FLOOR_PLAN_REQUEST = 'UPDATE_FLOOR_PLAN_REQUEST',
  UPDATE_FLOOR_PLAN_SUCCESS = 'UPDATE_FLOOR_PLAN_SUCCESS',
  UPDATE_FLOOR_PLAN_FAILURE = 'UPDATE_FLOOR_PLAN_FAILURE',

  DELETE_FLOOR_PLAN_REQUEST = 'DELETE_FLOOR_PLAN_REQUEST',
  DELETE_FLOOR_PLAN_SUCCESS = 'DELETE_FLOOR_PLAN_SUCCESS',
  DELETE_FLOOR_PLAN_FAILURE = 'DELETE_FLOOR_PLAN_FAILURE',

  SET_PAGE_DATA_CHANGED_STATE = 'SET_PAGE_DATA_CHANGED_STATE',
  SET_SUBMISSION_STATE = 'SET_SUBMISSION_STATE',
  RESET_PAGE_DATA = 'RESET_PAGE_DATA',
}

export interface Domains {
  oldDomain?: string;
  domain?: string;
}

export interface Seo {
  title?: string;
  description?: string;
}

export interface FirstRibbon {
  text: string;
  title: string;
}

export interface HomePageData {
  seo?: Seo;
  map?: { marker: { lat: number; lng: number; icon: string }; zoom: number; styles: string; address: string };
  images?: { src: string; useGradient?: boolean }[];
  logo?: { src: string };
  quote?: {
    text: string;
    author: string;
    details: string;
  };
  carousel?: {
    subTitle: string;
    mainTitle: string;
    buttonText: string;
    subTitleColor: string;
    mainTitleColor: string;
    backgroundColor?: string;
  };
  amenities?: { leftList: string[]; image: string };
  community?: { leftList: string[]; image: string };
  firstRibbon?: FirstRibbon;
  exploreImage?: string;
}

export interface GalleryPageData {
  seo?: Seo;
  pageTitle?: string;
  images?: Images[];
  imageCategories?: string[];
  firstRibbon?: FirstRibbon;
  breadcrumbsBar?: { image: string };
}

export interface FloorPlanPageData {
  seo?: Seo;
  currentPlans?: FloorPlan[];
  onSitePropertyId?: string;
  yardiPropertyId?: string;
  feedSourceType?: string;
  breadcrumbsBar?: { image?: string };
  firstRibbon?: FirstRibbon;
  pageTitle?: string;
  is_sitemap_first?: boolean;
  additionalImage?: string;
}

export interface Subcategories {
  seo?: Seo;
  path?: string;
  text?: string;
  image?: string;
  title?: string;
  pageTitle?: string;
  firstRibbon?: { tags: string[]; image: string; title: string };
}

export interface AmenitiesPageData {
  subCategories?: Subcategories[];
  showCategories?: boolean;
  amenities?: { leftColumn: string[]; middleColumn: string[]; rightColumn: string[]; image: string };
  community?: { leftColumn: string[]; middleColumn: string[]; rightColumn: string[]; image: string };
  map?: { list: { text: string; class: string }[]; image: string; title: string };
}

export interface Location {
  name?: string,
  image?: string,
  category?: number[],
  addressGeoPosition?: { lat?: number, lng?: number },
  address?: string,
  phone?: string,
  website?: string,
  isPropertyLocation?: boolean,
  showInProfile?: boolean,
}

export interface Category {
  id?: number;
  name?: string;
  iconName?: string;
  createdDate?: string;
  activeIcon?: string;
  inactiveIcon?: string;
}

export interface NeighborHoodPageData {
  seo?: Seo;
  firstRibbon?: FirstRibbon;
  pageTitle?: string;
  propertyIcon?: string;
  breadcrumbsBar?: { image: string };
  locations?: Location[];
  categories?: Category[];
  displayNeighborhoodPage?: boolean;
  isStreetViewEnabled?: boolean;
}

export interface VirtualTourPageData {
  seo?: Seo;
  firstRibbon?: FirstRibbon;
  pageTitle?: string;
  tours?: { title: string; link: string }[];
  helix_vr_tours?: { link: string }[];
  matterport_vr_tours?: { link: string }[];
  is_visible?: boolean;
  breadcrumbsBar?: { image: string };
}

export interface ContactPageData {
  pageTitle?: string;
  seo?: Seo;
  hours?: {
    sunday: string;
    saturday: string;
    mondayToFriday: string;
  };
  address?: {
    city: string;
    town: string;
    email: string;
    phone: string;
  };
  firstRibbon?: FirstRibbon;
  breadcrumbsBar?: { image: string };
}

export interface FooterPageData {
  links?: {
    twitter: string;
    youtube: string;
    facebook: string;
    instagram: string;
    propertyUrl: string;
    residentPortal: string;
  };
  rightFooter?: {
    imageUrl?: string;
    imageClickUrl?: string;
  };
}

export interface DesignPageData {
  siteTemplate?: string;
  labels?: Color[];
  customColors?: { V1: Color[]; V2: Color[] };
  customCssCodes?: { V1: Color[]; V2: Color[] };
  comingSoonImage?: string;
}

export interface SeoPageData {
  gtmId?: string;
  generalData?: {
    icon: string;
    mask: { icon: string; color: string };
    title: string;
    favicon: string;
    subject: string;
    description: string;
  };
  customColors?: Color[];
  aditionalScript?: string;
  google_ownership?: string;
  analyticsDashboard?: string;
  payPerClickDashboard?: string;
}

export type PageData = AmenitiesPageData | FloorPlanPageData | ContactPageData | DesignPageData | FooterPageData | GalleryPageData | HomePageData | NeighborHoodPageData | SeoPageData | VirtualTourPageData;

interface GetPageDataAction {
  type: ActionType.GET_PAGE_DATA_REQUEST | ActionType.GET_PAGE_DATA_SUCCESS | ActionType.GET_PAGE_DATA_FAILURE;

  result: {
    data: PageData;
  };
  sections: string;
  error: {
    response: { status: string };
  };
}

interface UpdatePageDataAction {
  type: ActionType.UPDATE_PAGE_DATA_REQUEST | ActionType.UPDATE_PAGE_DATA_SUCCESS | ActionType.UPDATE_PAGE_DATA_FAILURE;

  result: {
    data: PageData;
  };
  sections: string;
  error: {
    response: { status: string };
  };
}

interface UploadImageAction {
  type: ActionType.PAGES_UPLOAD_IMAGE_REQUEST | ActionType.PAGES_UPLOAD_IMAGE_SUCCESS | ActionType.PAGES_UPLOAD_IMAGE_FAILURE;
}

interface CreatePageDataAction {
  type: ActionType.CREATE_PAGE_DATA_REQUEST | ActionType.CREATE_PAGE_DATA_SUCCESS | ActionType.CREATE_PAGE_DATA_FAILURE;
}

interface UpdatePageDataDomainAction {
  type: ActionType.UPDATE_PAGE_DATA_DOMAIN_REQUEST | ActionType.UPDATE_PAGE_DATA_DOMAIN_SUCCESS | ActionType.UPDATE_PAGE_DATA_DOMAIN_FAILURE;
}

interface GetFloorPlansAction {
  type: ActionType.GET_FLOOR_PLANS_REQUEST | ActionType.GET_FLOOR_PLANS_SUCCESS | ActionType.GET_FLOOR_PLANS_FAILURE;

  result: {
    data: { images: { src: string }[] }[];
  };
  error: {
    response: { status: string };
  };
}

interface CreateFloorPlanAction {
  type: ActionType.CREATE_FLOOR_PLAN_REQUEST | ActionType.CREATE_FLOOR_PLAN_SUCCESS | ActionType.CREATE_FLOOR_PLAN_FAILURE;

  result: {
    data: { images: { src: string }[] };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateFloorPlanAction {
  type: ActionType.UPDATE_FLOOR_PLAN_REQUEST | ActionType.UPDATE_FLOOR_PLAN_SUCCESS | ActionType.UPDATE_FLOOR_PLAN_FAILURE;
}

interface DeleteFloorPlanAction {
  type: ActionType.DELETE_FLOOR_PLAN_REQUEST | ActionType.DELETE_FLOOR_PLAN_SUCCESS | ActionType.DELETE_FLOOR_PLAN_FAILURE;
}

interface SetChangedStateAction {
  type: ActionType.SET_PAGE_DATA_CHANGED_STATE;
  changed: boolean;
}

interface SetSubmissionStateAction {
  type: ActionType.SET_SUBMISSION_STATE;
  clicked: boolean;
}

interface ResetPageDataAction {
  type: ActionType.RESET_PAGE_DATA;
}

export type Action =
  | PromiseActionType
  | GetPageDataAction
  | UpdatePageDataAction
  | UploadImageAction
  | CreatePageDataAction
  | UpdatePageDataDomainAction
  | GetFloorPlansAction
  | CreateFloorPlanAction
  | UpdateFloorPlanAction
  | DeleteFloorPlanAction
  | SetChangedStateAction
  | SetSubmissionStateAction
  | ResetPageDataAction;
