/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { Avatar } from 'styles/common';
import {
  selectCallInProgress,
  selectCallTransferred,
  selectTransferingCall,
  selectDesktopPhoneCallCompleted,
  selectCallNotes,
  selectCallWindowBody,
  selectMuted,
  selectCallFinished,
  selectOutboundCallStatus,
  selectDevice,
  selectCallConnected,
  selectAudioProcessor,
  selectDialedPhone,
  selectDialedContact,
  selectConferenceSid,
  selectCallSid,
  selectOnHold,
  selectOnHoldLoading,
} from 'dwell/store/voice/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectProperty } from 'dwell/store/property/reducers';
import { getInitials } from 'dwell/views/chat/common/utils';
import voiceActions from 'dwell/store/voice/action-creator';
import EndCallActionButton from 'dwell/views/calls/communications/call_window/end_call_button';
import { AgentPhoneType, CallDirectionType, CallWindowBodyComponent, Agent, TransferCallToAgentParams, TransferCallToPropertyTeamParams } from 'dwell/store/voice/action-types';
import { OutboundPhone } from 'dwell/views/calls/communications/call_window/svgs';

import CallProspectSettings from './prospect_settings';
import {
  CallItem,
  CallItemHeader,
  CallerInfoContainer,
  CallerInfoBody,
  ToolBar,
  CallBody,
  OutboundCallAvatar,
  CallWindowSVG,
} from './styles';
import CallTime from './call_time';
import AudioSettings from './audio_settings';
import Keypad from './keypad';
import TransferCall from './transfer_call/index';
import NetworkQuality from './network_quality';
import Summary from './summary';
import { getCallParams, NETWORK_WARNING_GROUP, AUDIO_WARNING_GROUP, formatPhoneNumber } from '../voice_centre/utils';

export interface CallBodyItemBaseProps {
  onClose?: () => void,
  hide: boolean,
  useHeader?: boolean,
  onKeyPadClick?: (digit: string) => void,
}

const CallWindow: FC = () => {
  const [audioIssuesDetected, setAudioIssuesDetected] = useState([]);
  const [networkIssuesDetected, setNetworkIssuesDetected] = useState([]);
  const [hangingUp, setHangingUp] = useState(false);
  const [toggleNoiseSuppression, setToggleNoiseSuppression] = useState(true);

  const dispatch = useDispatch();
  const {
    collapse,
    show,
    transferToAgent,
    transferToPropertyTeam,
    hangupDesktopCall,
    setCallNotes,
    setCallWindowBody,
    toggleMute,
    setCallFinished,
    setOnHold,
    endConference,
  } = voiceActions;
  const bodyComponent = useSelector(selectCallWindowBody);
  const notes = useSelector(selectCallNotes);
  const muted = useSelector(selectMuted);
  const callFinished = useSelector(selectCallFinished);
  const outboundCallSatus = useSelector(selectOutboundCallStatus);
  const device = useSelector(selectDevice);
  const dialedPhone = useSelector(selectDialedPhone);
  const dialedContact = useSelector(selectDialedContact);

  const {
    prospectName,
    prospectType,
    applicationId,
    leaseId,
    leasingUserId,
    leadId,
    propertyId,
    propertyExternalId,
    propertyName,
    sourceName,
    topic,
    call, // TwilioCall: can be null if agent take the call on desktop phone
    callSid: desktopCallSid, // desktop phone calls will have this callSid to know which call save
    phoneType,
    direction,
    collapsed,
    areConferenceFeaturesEnabled,
    participantLabel,
  } = useSelector(selectCallInProgress);

  const callTransferred = useSelector(selectCallTransferred);
  const transfering = useSelector(selectTransferingCall);
  const desktopPhoneCallCompleted = useSelector(selectDesktopPhoneCallCompleted);
  const callConnected = useSelector(selectCallConnected);

  const isDesktopPhone = phoneType === AgentPhoneType.DESKTOP_PHONE;
  const disabled = isDesktopPhone || callTransferred;
  const transferSelected = bodyComponent === CallWindowBodyComponent.TRANSFER_CALL;
  const audioSelected = bodyComponent === CallWindowBodyComponent.AUDIO_SETTINGS;
  const keypadSelected = bodyComponent === CallWindowBodyComponent.KEYPAD;
  const isInboundCall = direction === CallDirectionType.INBOUND;
  const disabledWhileDialing = !isInboundCall && !callConnected;
  const isOutboundCall = direction === CallDirectionType.OUTBOUND;
  const audioProcessor = useSelector(selectAudioProcessor);
  const currentUser = useSelector(selectCurrentUser);
  const conferenceSid = useSelector(selectConferenceSid);
  const conferenceCallSid = useSelector(selectCallSid);
  const onHold = useSelector(selectOnHold);
  const onHoldLoading = useSelector(selectOnHoldLoading);
  const hasConferenceStarted = !areConferenceFeaturesEnabled ? true : areConferenceFeaturesEnabled && conferenceSid;
  const currentProperty = useSelector(selectProperty);
  const handleCallFinished = () => {
    dispatch(setCallFinished());
  };

  const handleCallWarningReceived = (warningName) => {
    if (NETWORK_WARNING_GROUP.includes(warningName)) {
      setNetworkIssuesDetected(networkIssuesDetected.concat(warningName));
    }
    if (AUDIO_WARNING_GROUP.includes(warningName)) {
      setAudioIssuesDetected(audioIssuesDetected.concat(warningName));
    }
  };

  const handleWarningCleared = (warningName) => {
    if (NETWORK_WARNING_GROUP.includes(warningName)) {
      setNetworkIssuesDetected(networkIssuesDetected.filter(warning => warning !== warningName));
    }
    if (AUDIO_WARNING_GROUP.includes(warningName)) {
      setAudioIssuesDetected(audioIssuesDetected.filter(warning => warning !== warningName));
    }
  };

  useEffect(() => {
    if (!isDesktopPhone) {
      call.on('disconnect', handleCallFinished);
      call.on('warning', handleCallWarningReceived);
      call.on('warning-cleared', handleWarningCleared);
    }
  }, []);

  useEffect(() => {
    // if we start the transfer proccess "See call results" button
    // is responsable of making the transition to the call summary
    // otherwise call "disconnect" event happens quicker than transfer request
    if (transfering && !isDesktopPhone) {
      // We have a call instance only if it was answered using browser phone
      call.removeAllListeners('disconnect');
    }
  }, [transfering]);

  useEffect(() => {
    // if call was answered on desktop phone and then transferred the order
    // to display call summary is dictated by "Transfer" component "See call summary" button
    if (desktopPhoneCallCompleted && !callTransferred) {
      handleCallFinished();
    }
  }, [desktopPhoneCallCompleted]);

  useEffect(() => {
    // If agent muted the call before lead answer, then mute the call when
    // status change from "ringing" to "open"
    if (call && call.isMuted() !== muted) {
      call.mute(muted);
    }
  }, [call?.status()]); // NOQA: Calls from desktop phone does not have a call instance from Twilio. Everything is handled in backend

  const muteCall = (shouldMute: boolean) => {
    dispatch(toggleMute(shouldMute));
    call.mute(shouldMute);
  };

  const setCustomerOnHold = (shouldHold: boolean) => {
    let { originalCallSid: parentCallSid } = getCallParams(call);
    if (isOutboundCall) parentCallSid = call.parameters.CallSid;
    dispatch(
      setOnHold(
        conferenceSid,
        shouldHold,
        parentCallSid,
        conferenceCallSid,
      ),
    );
  };

  const handleHangup = () => {
    if (!isDesktopPhone) {
      // Conference Flag
      if (conferenceSid) {
        dispatch(endConference(conferenceSid));
      }

      call.disconnect();
    } else {
      setHangingUp(true);
      dispatch(hangupDesktopCall(desktopCallSid, conferenceSid));
    }
  };

  const transfer = (agent: Agent) => {
    let callSid = null;
    if (isInboundCall) {
      if (isDesktopPhone) {
        callSid = desktopCallSid;
      } else {
        // Twilio creates two calls so we have two call sid, in order to transfer
        // incoming calls (client called and agent answer) we have to update
        // the proper call record in Twilio.
        // Otherwise we would finished the call for both parties
        const { originalCallSid } = getCallParams(call);
        callSid = originalCallSid;
      }
    } else if (isDesktopPhone) {
      callSid = desktopCallSid;
    } else {
      callSid = call.parameters.CallSid;
    }
    const isPropertyTeam = agent.role === 'Property Team';
    let params = { CallSid: callSid, direction, topic, ConferenceSid: conferenceSid };
    let action = null;
    if (isPropertyTeam) {
      params = { ...params, property: propertyId } as TransferCallToPropertyTeamParams;
      action = transferToPropertyTeam;
    } else {
      params = { ...params, agent_id: agent.id, participant_label: participantLabel } as TransferCallToAgentParams;
      action = transferToAgent;
    }
    dispatch(action(params)).then(() => {
      device.disconnectAll();
    });
  };

  const showNotes = () => dispatch(setCallWindowBody(CallWindowBodyComponent.NOTES));

  const toggleAudioProcessor = () => {
    setToggleNoiseSuppression((prevToggle) => {
      if (prevToggle) {
        audioProcessor.disableFilter();
      } else {
        audioProcessor.enableFilter();
      }
      return !prevToggle;
    });
  };

  const showDialedName = () => {
    if (dialedContact && !isEmpty(dialedContact.name)) {
      return dialedContact.name;
    } else if (prospectName) return prospectName;
    return formatPhoneNumber(dialedPhone);
  };

  return (
    <CallItem>
      <CallItemHeader>
        <CallerInfoContainer>
          {isEmpty(dialedPhone) ?
            <Avatar className="avatar" hideOnlineIcon>
              {/\d/.test(prospectName) ? 'U1' : getInitials(prospectName)}
            </Avatar> :
            <OutboundCallAvatar className="avatar" hideOnlineIcon>
              <CallWindowSVG
                xmlns="http://www.w3.org/2000/svg"
                data-lucide="phone-outgoing"
                className="lucide lucide-phone-outgoing"
                viewBox="0 0 24 24"
                style={{ width: '17px', height: '17px' }}
              >
                <OutboundPhone />
              </CallWindowSVG>
            </OutboundCallAvatar>
          }
          <CallerInfoBody onClick={() => dispatch(collapsed ? show() : collapse())}>
            <h6 id="callerInfoName">{isEmpty(dialedPhone) ? prospectName : showDialedName()}</h6>
            <p>
              <span>{propertyName}</span>
              {isEmpty(dialedPhone) && <span>{prospectType}</span>}
            </p>
          </CallerInfoBody>
          {!['Applicant', 'Resident'].includes(prospectType) && isEmpty(dialedPhone) &&
              <CallProspectSettings
                prospect={{ id: leadId, propertyExternalId, property: propertyId }}
                prospectType={prospectType}
              />
          }
        </CallerInfoContainer>
        <EndCallActionButton
          callFinished={callFinished}
          callTransferred={callTransferred}
          dialedPhone={dialedPhone}
          handleHangup={handleHangup}
          leadId={leadId}
          isDesktopPhone={isDesktopPhone}
          call={call}
          isInboundCall={isInboundCall}
          desktopCallSid={desktopCallSid}
          leaseId={leaseId}
          applicationId={applicationId}
          leasingUserId={leasingUserId}
          hasConferenceStarted={hasConferenceStarted}
        />
        {/* ToolBar is disabled if the call was transferred or agent called a prospect and has not answered yet */}
        <ToolBar disabled={callTransferred || disabledWhileDialing || hangingUp || !hasConferenceStarted} hide={callFinished}>
          <a
            href="#"
            className={cn('btn', 'btn-mute', { active: !isDesktopPhone, muted, disabled: disabled || disabledWhileDialing || onHold })}
            onClick={() => !isDesktopPhone && muteCall(!muted)}
          >
            <i className="ri-mic-fill" />
            <i className="ri-mic-off-fill" />
          </a>
          {currentProperty.enable_conference_features && (
            <>
              <a
                id="set-customer-on-hold"
                href="#"
                className={cn('btn', { active: onHold, disabled: disabled || disabledWhileDialing || !conferenceSid || onHoldLoading })}
                onClick={() => setCustomerOnHold(!onHold)}
              >
                <i className="ri-pause-line" />
              </a>
              <UncontrolledTooltip placement="top" target="set-customer-on-hold">
                Puts customer on hold
              </UncontrolledTooltip>
            </>
          )}
          {/* TODO: Remove or refactor after validation */}
          {currentUser.ai_noise_suppression_enabled && (
            <>
              <a
                id="toggle-noise-filer"
                href="#"
                className={cn('btn', { active: toggleNoiseSuppression, disabled: disabled || disabledWhileDialing })}
                onClick={() => toggleAudioProcessor()}
              >
                {toggleNoiseSuppression ? <i className="ri-toggle-line" /> : <i className="ri-toggle-fill" />}
              </a>
              <UncontrolledTooltip placement="top" target="toggle-noise-filer">
                AI Background Noise Suppression
              </UncontrolledTooltip>
            </>
          )}
          <a
            href="#"
            className={cn('btn', { active: transferSelected && !callTransferred, disabled: callTransferred || disabledWhileDialing })}
            onClick={() => dispatch(setCallWindowBody(CallWindowBodyComponent.TRANSFER_CALL))}
          >
            <i className="ri-arrow-left-right-fill" />
          </a>
          <a
            href="#"
            className={cn('btn', { disabled: disabled || disabledWhileDialing, active: audioSelected })}
            onClick={() => !callTransferred && !isDesktopPhone && dispatch(setCallWindowBody(CallWindowBodyComponent.AUDIO_SETTINGS))}
          >
            <i className="ri-headphone-fill" />
          </a>
          <a
            href="#"
            className={cn('btn', { disabled: disabled || disabledWhileDialing, active: keypadSelected })}
            onClick={() => !callTransferred && !isDesktopPhone && dispatch(setCallWindowBody(CallWindowBodyComponent.KEYPAD))}
          >
            <i className="ri-grid-fill" />
          </a>
          <CallTime stop={callFinished || callTransferred} />
          <a
            href="#"
            style={{ color: [...networkIssuesDetected, ...audioIssuesDetected].length ? 'red' : '' }}
            className="link"
            onClick={() => !isDesktopPhone && dispatch(setCallWindowBody(CallWindowBodyComponent.NETWORK_QUALITY))}
          >
            {!isDesktopPhone ? <i className="ri-signal-wifi-3-fill" /> : <i className="ri-phone-fill" />}
          </a>
        </ToolBar>
      </CallItemHeader>
      <CallBody collapsed={collapsed}>
        {bodyComponent === CallWindowBodyComponent.NOTES && (
          <div className="cib-item">
            <textarea
              className="form-control"
              placeholder="Add call notes..."
              value={notes}
              onChange={({ target: { value } }) => dispatch(setCallNotes(value))}
            />
          </div>
        )}
        {bodyComponent === CallWindowBodyComponent.REASON_FOR_CALL && (
          <div className="cib-item">
            <textarea
              className="form-control"
              placeholder="Reason for call"
              value={notes}
              onChange={({ target: { value } }) => dispatch(setCallNotes(value))}
            />
          </div>
        )}
        <TransferCall
          onClose={showNotes}
          hide={!transferSelected}
          onTransferClick={transfer}
          prospectName={prospectName}
          hangingUp={hangingUp}
        />
        <AudioSettings onClose={showNotes} hide={!audioSelected} />
        <Keypad onClose={showNotes} hide={!keypadSelected} />
        <NetworkQuality
          networkIssuesDetected={networkIssuesDetected.length > 0}
          audioIssuesDetected={audioIssuesDetected.length > 0}
          onClose={showNotes}
          hide={bodyComponent !== CallWindowBodyComponent.NETWORK_QUALITY}
        />
        <Summary
          hide={bodyComponent !== CallWindowBodyComponent.SUMMARY}
          callDirection={direction}
          callStatus={outboundCallSatus}
          sourceName={sourceName || ''}
          topic={topic}
        />
      </CallBody>
    </CallItem>
  );
};

export default CallWindow;
