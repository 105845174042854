import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_PARKING_REQUEST = 'CREATE_PARKING_REQUEST',
  CREATE_PARKING_SUCCESS = 'CREATE_PARKING_SUCCESS',
  CREATE_PARKING_FAILURE = 'CREATE_PARKING_FAILURE',

  DELETE_PARKING_REQUEST = 'DELETE_PARKING_REQUEST',
  DELETE_PARKING_SUCCESS = 'DELETE_PARKING_SUCCESS',
  DELETE_PARKING_FAILURE = 'DELETE_PARKING_FAILURE',

  UPDATE_PARKING_REQUEST = 'UPDATE_PARKING_REQUEST',
  UPDATE_PARKING_SUCCESS = 'UPDATE_PARKING_SUCCESS',
  UPDATE_PARKING_FAILURE = 'UPDATE_PARKING_FAILURE',
}

export interface ParkingState extends CommonStateType {
  parking: ParkingProps[]
}

export interface ParkingProps {
  id?: number,
  type: string,
  qty: number,
  numbers: string,
  rent: number,
  fee: number,
  deposit: number,
  issued_parking_permit_id: boolean,
  parking_permits_issued_qty: number,
  parking_permits_numbers: string,
}

/*
 * Actions
 */

interface CreateParkingAction extends CommonActionResponse {
  type: ActionType.CREATE_PARKING_REQUEST
  | ActionType.CREATE_PARKING_SUCCESS
  | ActionType.CREATE_PARKING_FAILURE;
  result: { data: ParkingProps };
}

interface DeleteParkingAction extends CommonActionResponse {
  type: ActionType.DELETE_PARKING_REQUEST
  | ActionType.DELETE_PARKING_SUCCESS
  | ActionType.DELETE_PARKING_FAILURE;
  result: { data: ParkingProps };
}

interface UpdateParkingAction extends CommonActionResponse {
  type: ActionType.UPDATE_PARKING_REQUEST
  | ActionType.UPDATE_PARKING_SUCCESS
  | ActionType.UPDATE_PARKING_FAILURE;
  result: { data: ParkingProps };
}

export type Action =
  PromiseActionType
  | CreateParkingAction
  | DeleteParkingAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdateParkingAction;
