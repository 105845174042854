import { PromiseActionType } from 'dwell/store/types';
import { EmergencyContactProps } from 'leasing/store/applicant/action-types';
import { OtherIncomeProps } from 'leasing/store/other_income/action-types';
import { EmployerProps } from 'leasing/store/employer/action-types';
import { DependentProps } from 'leasing/store/dependent/action-types';
import { PetProps } from 'leasing/store/pet/action-types';
import { VehicleProps } from 'leasing/store/vehicle/action-types';
import { GarageProps } from 'leasing/store/garage/action-types';
import { StorageProps } from 'leasing/store/storage/action-types';
import { GateKeyProps } from 'leasing/store/gate_key/action-types';
import { ParkingProps } from 'leasing/store/parking/action-types';
import { OtherFeeItemProps } from 'leasing/store/other_fee_item/action-types';
import { OtherRentItemProps } from 'leasing/store/other_rent_item/action-types';
import { ScreeningReportProps } from 'leasing/store/screening_report/action-types';
import { PaymentReceivedProps } from 'leasing/store/payment/action-types';
import { NoteProps } from 'dwell/store/note/action-types';
import { NoticeProps } from 'leasing/store/types';

export enum ActionType {
  GET_LEASE_DETAILS_REQUEST = 'GET_LEASE_DETAILS_REQUEST',
  GET_LEASE_DETAILS_SUCCESS = 'GET_LEASE_DETAILS_SUCCESS',
  GET_LEASE_DETAILS_FAILURE = 'GET_LEASE_DETAILS_FAILURE',

  GET_RENEW_LEASE_DETAILS_REQUEST = 'GET_RENEW_LEASE_DETAILS_REQUEST',
  GET_RENEW_LEASE_DETAILS_SUCCESS = 'GET_RENEW_LEASE_DETAILS_SUCCESS',
  GET_RENEW_LEASE_DETAILS_FAILURE = 'GET_RENEW_LEASE_DETAILS_FAILURE',

  UPDATE_LEASE_RESIDENT_REQUEST = 'UPDATE_LEASE_RESIDENT_REQUEST',
  UPDATE_LEASE_RESIDENT_SUCCESS = 'UPDATE_LEASE_RESIDENT_SUCCESS',
  UPDATE_LEASE_RESIDENT_FAILURE = 'UPDATE_LEASE_RESIDENT_FAILURE',
}

export interface UpdateDataType {
  stage?: string,
  status?: string,
  owner?: number,
  roommate_change_effective_date?: string,
}

export interface ResidentGroupProps {
  id: number;
  first_name: string;
  last_name: string;
  type?: string;
  birth_date: string;
  account_type: string;
  status: string;
  email: string;
  phone_number: string;
  phone_type: string;
  identification_type: string;
  identification_id: string;
  driver_licence_number: string;
  driver_licence_state: string;
  driver_licence_photo_front: string;
  driver_licence_photo_back: string;
  is_currently_employed: boolean;
  employers: EmployerProps[];
  other_incomes: OtherIncomeProps[];
  emergency_contact: EmergencyContactProps;
  dependents: DependentProps[];
  screening: ScreeningReportProps;
  subscription_status: string;
  next_billing_date: string;
  refund_required: boolean;
  country: string;
  first_street_address: string;
  second_street_address: string;
  zip_or_postal_code: string;
  city_name: string;
  state: string;
  current_residence_move_in_date: string;
  is_residence_owned: boolean;
  landlord_name: string;
  landlord_phone: string;
  has_vehicles: boolean;
  has_pets: boolean;
  has_dependents: boolean;
  person_type: string;
  monthly_payment: number;
  is_pending_remove?: boolean;
  is_application_completed?: boolean,
  rent_payments_reported: number;
  activities: { step: string, date: string }[];
  is_get_from_resman: boolean,
}

export interface LeaseActionsProps {
  status: string,
  steps: {
    completed_date: string,
    leasing_user: number,
    name: string,
    step_number: number,
    skipped: boolean,
  }[],
  type: string,
}

export interface LeaseDetailsProps {
  id?: number,
  floor_plan: string;
  unit: string;
  base_rent: number;
  lease_term: number;
  type: string;
  lease_start_date: string;
  lease_end_date: string;
  move_in_date: string;
  move_out_date: string;
  deposit_agreement_signed_date: string;
  lease_signed_date: string;
  building_number: string;
  security_deposit_type: string;
  security_deposit: number;
  deposit_alternative_fee: number;
  e_premium_payment_methods: string[];
  pets: PetProps[];
  vehicles: VehicleProps[];
  garages: GarageProps[];
  storages: StorageProps[];
  parking: ParkingProps[];
  other_fee_items: OtherFeeItemProps[];
  other_rent_items: OtherRentItemProps[];
  gate_keys: GateKeyProps[];
  notes: NoteProps[];
  payment_received: PaymentReceivedProps;
  application_fee: number;
  non_refundable_administration_fee: number;
  non_refundable_fee: number;
  amount_already_paid: string;
  status: string;
  status_update_date: string;
  utilities: string[];
  electric_account_number: string;
  gas_account_number: string;
  insurer_name: string;
  insurance_policy_number: string;
  insurance_liability_amount: number;
  insurance_effective_date: string;
  deposit_renters_insurance: number;
  valet_waste: number;
  mailbox_number: string;
  construction_delivery_date: string;
  balance_due_payment_methods: string[];
  applicant_is_employee: boolean;
  employee_discount: number;
  disclosed_history_of_bedbugs: boolean;
  bedbug_description: string;
  deposit_agreement_comment: string;
  special_provisions: string;
  resident_group: ResidentGroupProps[];
  paid_on_date: string;
  concessions: number;
  application_completed_date: string;
  application_submitted_date: string;
  payment_method: string;
  addenda: number[];
  addenda_names?: string[];
  screening: ScreeningReportProps;
  bicycle_storage_assigned_floor?: number,
  bicycle_description?: string,
  ach_account_type?: string;
  ach_depository_name?: string;
  ach_depository_branch?: string;
  ach_depository_city?: string;
  ach_depository_state?: string;
  ach_depository_zip_code?: number;
  ach_routing_number?: string;
  ach_account_number?: string;
  deposit_agreement_addenda?: number[],
  lease_actions: LeaseActionsProps[],
  unit_info?: { is_hold: boolean, is_waitlist: boolean, available_on: string },
  renew_lease?: number;
  stage?: string;
  note?: string;
  notice?: NoticeProps,
  term_premium?: number,
  pet_rent?: number,
  last_unit?: string,
}

export interface GetLeaseAction {
  type: ActionType.GET_LEASE_DETAILS_REQUEST | ActionType.GET_LEASE_DETAILS_SUCCESS | ActionType.GET_LEASE_DETAILS_FAILURE;

  result: {
    data: LeaseDetailsProps;
  };
  error: {
    response: { status: string };
  };
}

export interface GetRenewLeaseAction {
  type: ActionType.GET_RENEW_LEASE_DETAILS_REQUEST | ActionType.GET_RENEW_LEASE_DETAILS_SUCCESS | ActionType.GET_RENEW_LEASE_DETAILS_FAILURE;

  result: {
    data: LeaseDetailsProps;
  };
  error: {
    response: { status: string };
  };
}

export interface UpdateLeaseAction {
  type: ActionType.UPDATE_LEASE_RESIDENT_REQUEST | ActionType.UPDATE_LEASE_RESIDENT_SUCCESS | ActionType.UPDATE_LEASE_RESIDENT_FAILURE;

  result: {
    data: LeaseDetailsProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetLeaseAction
  | UpdateLeaseAction
  | GetRenewLeaseAction;
