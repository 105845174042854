import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Mention } from 'react-mentions';
import actions from 'dwell/actions/index';
import moment from 'moment';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { isEmpty, get } from 'lodash';
import { toast, ToastOptions } from 'react-toastify';
import { Prospect as IProspect } from 'dwell/store/chat/action-types';
import { SMSMessage } from 'dwell/store/sms/action-types';
import { PropertyProps } from 'dwell/store/property/action-types';
import { Avatar } from 'styles/common';
import { SMS_LOAD_MORE_OFFSET, LAST_N_MESSAGES } from 'dwell/constants';
import { toastError } from 'site/constants';
import HobbesAssit from 'dwell/views/chat/common/_hobbesAssist';
import HobbesAssitPanel from 'dwell/views/chat/common/_hobbesAssistPanel';
import MessageOptionsPanel from 'dwell/views/chat/common/_messageOptionsPanel';
import { getAvatarColor, getInitials, defaultStyle } from 'dwell/views/chat/common/utils';
import { ChatSpinner, SpinnerBorder } from 'dwell/views/chat/single_chat/contact/styles';
import { ChatItemBody, ChatItemFooter, ChatItemMessage, MessageInput, ChatItem, CustomMentionInput, ResponseTyping, MiniLoader, HiddenChatItem, TextAnalyzingContainer, MiniLoader3 } from 'dwell/views/chat/single_chat/window/styles';

interface ActiveChatPanelProps {
  contact: IProspect,
  minimized: boolean,
  isSingleChat: boolean,
}

const SMSChatBody: FC<ActiveChatPanelProps> = ({ contact, minimized, isSingleChat }) => {
  const conversations: SMSMessage[] = useSelector(state => state.smsMessage.conversations);
  const isSendingText: boolean = useSelector(state => state.smsMessage.isSendingText);
  const notifications: { redirect_url: string, id: number, type: string }[] = useSelector(state => state.notification.notifications);
  const activeChats = useSelector(state => state.prospectChat.activeChats);

  const dispatch = useDispatch();
  const { getConversations, readAll, sendSMS } = actions.smsMessage;
  const { setDraftToActiveChat } = actions.prospectChat;
  const { bulkClearNotifications } = actions.notification;

  const [newMessage, setNewMessage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [oldScrollPosition, setOldScrollPosition] = useState(0);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const [openHobbesPanel, setOpenHobbesPanel] = useState(false);
  const [answerToDisplay, setAnswerToDisplay] = useState<string[]>([]);
  const [isHobbesAnalyzing, setIsHobbesAnalyzing] = useState(false);
  const [hobbesTagAndUnitType, setHobbesTagAndUnitType] = useState({ tag: null, unitType: null });
  const [showSending, setShowSending] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const selectedProperties = useSelector(selectSelectedProperties);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [canPublish, setCanPublish] = useState(true);
  const [currentProperty, setCurrentProperty] = useState<PropertyProps>({ domain: '' });
  const [lastJoinedMessageIdx, setJoinedLastMessageIdx] = useState(null);
  const [draftRendered, setDraftRendered] = useState(false);

  const chatWindow: { current: { _container: { scrollTop: number, scrollHeight: number, clientHeight: number } }} | undefined = useRef();

  const filteredConversations = conversations.filter(c => c.lead === contact.id);

  useEffect(() => {
    if (!isEmpty(activeChats) && contact.id && !newMessage && !draftRendered) {
      const currentActiveChat = activeChats.find(a => a.id === contact.id);
      if (typeof currentActiveChat !== 'undefined') {
        if (currentActiveChat.draft) {
          setNewMessage(currentActiveChat.draft);
        }
        setDraftRendered(true);
      }
    }
  }, [activeChats, contact.id, newMessage, draftRendered]);

  useEffect(() => {
    if (!isEmpty(filteredConversations)) {
      const lastNElements = filteredConversations.length - LAST_N_MESSAGES;
      if (lastNElements < 0) {
        setJoinedLastMessageIdx(filteredConversations[0].id);
      } else {
        setJoinedLastMessageIdx(filteredConversations[lastNElements].id);
      }
    }
  }, [filteredConversations]);

  useEffect(() => {
    if (!isEmpty(selectedProperties)) {
      const tempProperty = selectedProperties.find(p => p.id === contact.property);
      if (!isEmpty(tempProperty)) {
        setCurrentProperty(tempProperty);
      }
    }
  }, [selectedProperties]);

  const scrollToLastMessage = () => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      _container.scrollTop = _container.scrollHeight;
    }
  };

  const clearLeadNotifications = (id) => {
    const notificationArray = [];
    notifications.forEach((data) => {
      if (data.redirect_url && parseInt(data.redirect_url.split('/').pop(), 10) === id && data.type === 'NEW_SMS') notificationArray.push(data.id);
    });
    if (notificationArray.length) dispatch(bulkClearNotifications({ ids: notificationArray }));
  };

  useEffect(() => {
    if (contact.id) {
      if ((!minimized || !isSingleChat) && !totalCount) {
        dispatch(getConversations({
          lead: contact.id,
          params: {
            offset: filteredConversations.length,
            limit: SMS_LOAD_MORE_OFFSET,
            property: contact.property,
          } })).then((response) => {
          if (response?.result) {
            const { result: { data: { count } } } = response;
            setTotalCount(count);
          }
          scrollToLastMessage();
          if (contact.id && contact.unread_count) {
            dispatch(readAll({ lead: contact.id }, contact.property));
          }
          clearLeadNotifications(contact.id);
        });
      } else if (contact.unread_count) {
        // for the unread message
        dispatch(readAll({ lead: contact.id }, contact.property));
      }
    }
  }, [contact.unread_count]);

  const onscroll = ({ target }) => {
    if (totalCount === filteredConversations.length) return;
    if (target && target.scrollTop === 0) {
      setIsScrollLoading(true);
      setOldScrollPosition(target.scrollHeight - target.clientHeight);
      setTimeout(() => {
        if (contact.id) {
          dispatch(getConversations({ lead: contact.id, params: { offset: filteredConversations.length, limit: SMS_LOAD_MORE_OFFSET, property: contact.property } }))
            .then((response) => {
              if (response?.result) {
                const { result: { data: { count } } } = response;
                setTotalCount(count);
              }
              setIsScrollLoading(false);
            });
        }
      }, 1000);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendMessage = (message, _type = messageType) => {
    if (message && !isSendDisabled) {
      if (contact.id) {
        setShowSending(true);
        dispatch(setDraftToActiveChat('', contact.id));
        setNewMessage('');
        dispatch(sendSMS({ lead: contact.id, message }, contact.property))
          .then(() => {
            setShowSending(false);
            dispatch(setDraftToActiveChat('', contact.id));
            setNewMessage('');
            scrollToLastMessage();
          }).catch((error) => {
            const errorMsg = get(error, 'response.data.detail', '');
            if (errorMsg === 'Failed to send message') {
              toast.error('Phone number invalid. Please provide correct phone number for Lead.', toastError as ToastOptions);
            }
          });
      }
    }
  };

  const replaceSubjectEmptyVariables = () => {
    const placeholders = document.getElementsByClassName('subject-variable');
    if (!isEmpty(placeholders)) {
      [...placeholders].forEach((el) => {
        el.classList.add('subject-variable-empty');
        el.classList.remove('subject-variable');
      });
    }
    setIsSendDisabled(!isEmpty([...document.getElementsByClassName('subject-variable-empty')]));
  };

  useEffect(() => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      const newScroll = _container.scrollHeight - _container.clientHeight;
      _container.scrollTop += (newScroll - oldScrollPosition);
    }
  }, [filteredConversations, totalCount, showSending]);

  useEffect(() => {
    setTimeout(() => replaceSubjectEmptyVariables(), 100);
  }, [newMessage]);

  const handleKeyUp = (e) => {
    replaceSubjectEmptyVariables();
    if (((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) && !isEmpty(newMessage) && newMessage !== '\n') {
      if (!isSendingText && !isSendDisabled) {
        sendMessage(newMessage);
      }
    } else if (((e.key === 'Enter' || e.keyCode === 13) && e.shiftKey)) {
      dispatch(setDraftToActiveChat(newMessage.concat('\n'), contact.id));
      setNewMessage(newMessage.concat('\n'));
      setMessageType(null);
    } else if (canPublish) {
      // todo should optimize it
      // sendTypingState(prospect.id, { is_typing: true, type: 'AGENT' });
      setCanPublish(false);
      setTimeout(() => {
        setCanPublish(true);
      }, 200);
    }
  };

  const handleKeyDown = (e) => {
    replaceSubjectEmptyVariables();
    if ((e.key === 'Enter' || e.keyCode === 13) && !isEmpty(newMessage)) {
      e.preventDefault();
    }
  };

  const handleChange = (value) => {
    dispatch(setDraftToActiveChat(value, contact.id));
    setNewMessage(value);
    setMessageType(null);
  };

  return (
    <>
      <ChatItemBody ref={chatWindow} onScroll={onscroll} isSingleChat={isSingleChat}>
        <ul>
          {isScrollLoading && (
            <ChatSpinner>
              <SpinnerBorder>
                <span className="sr-only">Loading ...</span>
              </SpinnerBorder>
            </ChatSpinner>
          )}
          {conversations.filter(message => message.lead === contact.id)
            .map((message, index) => (
              <ChatItem reverse={message.is_team_message} isSMS key={`item-${index}`}>
                <Avatar
                  className={`avatar ${message.is_team_message ? 'bg-dark' : getAvatarColor(contact.name, contact.id, true)}`}
                >
                  {message.is_team_message && message.agent_avatar ?
                    <img src={message.agent_avatar} alt="avatar" /> :
                    <i>{getInitials(message.is_team_message ? message.agent_name : contact.name, true)}</i>}
                </Avatar>
                <ChatItemMessage key={index} className="message">
                  {message.is_team_message ? (
                    <p dangerouslySetInnerHTML={{ __html: message.message.replace(/(\r\n|\r|\n|\\r\\n|\\r|\\n)/g, '<br />') }} />
                  ) : (
                    <HobbesAssit
                      isHighlightEnabled
                      message={message.message}
                      uuid={contact.id.toString()}
                      domain={currentProperty.domain}
                      setOpenHobbesPanel={setOpenHobbesPanel}
                      setAnswerToDisplay={setAnswerToDisplay}
                      lastJoinedIdx={lastJoinedMessageIdx}
                      lastMessageIdx={message.id}
                      setIsHobbesAnalyzing={setIsHobbesAnalyzing}
                      setHobbesTagAndUnitType={setHobbesTagAndUnitType}
                    />
                  )}
                  <small>{moment(message.date).format('MMM DD, hh:mma')}</small>
                </ChatItemMessage>
              </ChatItem>))}
          <HiddenChatItem reverse show={showSending}>
            <Avatar className="avatar bg-dark">
              <i>{getInitials(currentUser.first_name)}</i>
            </Avatar>
            <ResponseTyping>
              <MiniLoader />
            </ResponseTyping>
          </HiddenChatItem>
        </ul>
      </ChatItemBody>
      {isHobbesAnalyzing && (
        <TextAnalyzingContainer>
          <MiniLoader3 />
          <span>Analyzing text</span>
          <MiniLoader3 />
        </TextAnalyzingContainer>)}
      <ChatItemFooter isActive>
        {contact.sms_opt_in_status === 'OPTIN_STATUS_OPTEDIN' && (
          <MessageInput className="subject">
            <CustomMentionInput
              placeholder="Write a SMS message"
              value={newMessage}
              style={defaultStyle}
              onClick={() => replaceSubjectEmptyVariables()}
              onChange={({ target: { value } }) => handleChange(value)}
              onBlur={() => setTimeout(() => replaceSubjectEmptyVariables())}
              onKeyUp={e => handleKeyUp(e)}
              onKeyDown={e => handleKeyDown(e)}
            >
              <Mention
                appendSpaceOnAdd
                className="subject-variable"
                trigger="["
                markup="[=__display__=]"
                displayTransform={(_id, display) => `[=${display}=]`}
                data={(_matchInfo, callback) => callback([])}
                renderSuggestion={(_suggestion, _search, highlightedDisplay, _index, focused) => (<div className={`${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>)}
              />
            </CustomMentionInput>
            <div className="d-flex align-items-center justify-content-end">
              <MessageOptionsPanel
                sendMessage={(message, type) => sendMessage(message, type)}
                setNewMessage={message => setNewMessage(message)}
                setMessageType={setMessageType}
                prospect={contact}
                isSMS
                isSingleChat
              />
              <span
                className="send"
                onClick={() => sendMessage(newMessage)}
              >
                <i className="ri-send-plane-fill" />
              </span>
            </div>
          </MessageInput>)}
      </ChatItemFooter>
      <HobbesAssitPanel messages={answerToDisplay} openHobbesPanel={openHobbesPanel} setOpenHobbesPanel={setOpenHobbesPanel} sendMessage={message => sendMessage(message)} hobbesTagAndUnitType={hobbesTagAndUnitType} />
    </>);
};

export default SMSChatBody;
