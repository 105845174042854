import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { PropertyProps } from 'dwell/store/property/action-types';
import { TaskProps } from 'dwell/store/task/action-types';
import { Lead } from 'dwell/store/lead/action-types';
import { emailVariables } from 'dwell/constants';
import { TOUR_TYPES } from 'dwell/constants/tasks';
import { PriceQuote } from 'dwell/store/price_quote/action-types';
import { BusinessHoursProps } from 'dwell/store/business_hours/action-types';
import { LeadLeaseDetails } from 'src/interfaces';
import { ApplicationDetailsProps, ApplicantProps } from 'application/store/application/action-types';

interface VariableProps {
  [key: string]: string;
}

const button = (link, text = 'Accept Invitation') => `
<div style="text-align: center; width: 200px; margin: 0 auto;">
  <a
    href="${link}"
    style="display: block; color: #ffffff; background-color: #59ce9a; border: solid 1px #59ce9a;
      border-radius: 5px; text-decoration: none; font-size: 14px; margin: 0; padding: 12px 25px;
      text-transform: capitalize;"
      target="_blank"
  >
    ${text}
  </a>
</div>
`;

const hyperlink = (link, text = 'here') => `<a href="${link}">${text}</a>`;

const protocol = (url: string): string => {
  url = url.trim();
  try {
    const parsed = new URL(url);
    return parsed.href;
  } catch {
    return `http://${url}`;
  }
};

export const formatNewPlaceholderVariables = (body: string): string => {
  let replacedBody = body;
  const placeholders = Object.values(emailVariables.VARIABLES)
    .filter(item => !item.hide)
    .map(item => item.name);
  const lowerCasePlaceholders = placeholders.map(item => item.toLowerCase());
  const emailVars = body.match(/(^\[=(.*?)=\])|([^"]>\[=(.*?)=\])|([^>]\[=(.*?)=\])/g);
  if (emailVars) {
    const slicedEmailVars = emailVars.map((s) => {
      if (s.charAt(1) === '[') {
        return s.slice(1);
      } else if (s.charAt(2) === '[') {
        return s.slice(2);
      }
      return s;
    });
    // eslint-disable-next-line no-useless-escape
    const variableTitles = slicedEmailVars.map(v => v.replace(/[\[=\]]/g, ''));
    variableTitles.forEach((title, i) => {
      const idx = lowerCasePlaceholders.indexOf(title.toLowerCase());
      const pattern = `(^\\[=(.*?)=\\])|([^"]>\\[=${title}=\\])|([^>]\\[=${title}=\\])`;
      let firstCharacters = '';
      if (emailVars[i].charAt(1) === '[') {
        // eslint-disable-next-line prefer-destructuring
        firstCharacters = emailVars[i][0];
      } else if (emailVars[i].charAt(2) === '[') {
        firstCharacters = emailVars[i][0] + emailVars[i][1];
      }
      if (idx >= 0) {
        replacedBody = replacedBody.replace(new RegExp(pattern), `${firstCharacters}<span><span class="email-placeholder">[=${placeholders[idx]}=]</span>&nbsp;</span>`);
      }
    });
  }
  return replacedBody;
};

export const parseVariables = (body: string): string[] => {
  const emailVars = body.match(/\[=(.*?)=\]/g);
  let variableTitles = [];
  if (emailVars) {
    // eslint-disable-next-line no-useless-escape
    variableTitles = emailVars.map(v => v.replace(/[\[=\]]/g, '').toLowerCase());
  }
  return Object.keys(emailVariables.VARIABLES).filter(key => variableTitles.includes(emailVariables.VARIABLES[key].name.toLowerCase()));
};

export const getPreview = (text: string, variables: VariableProps, isBody: boolean): string => {
  let previewData = text;
  Object.keys(variables).forEach((key) => {
    if (variables[key]) {
      const pattern = isBody ? `<span class="email-placeholder">\\[=${emailVariables.VARIABLES[key].name}=\\]<\\/span>` : `\\[=${emailVariables.VARIABLES[key].name}=\\]`;
      previewData = previewData.replace(
        // eslint-disable-next-line no-useless-escape
        new RegExp(pattern, 'g'),
        `<b>${variables[key]}</b>`,
      );
    }
  });
  return previewData;
};

export const replaceSubjectEmptyVariables = (variables: VariableProps, bulkEmail: boolean): void => {
  if (!isEmpty(variables)) {
    [...document.getElementsByClassName('subject-variable')].forEach((el) => {
      Object.entries(emailVariables.VARIABLES).forEach(([key, value]) => {
        if (bulkEmail && (key === 'lead_full_name' || key === 'lead_first_name' || key === 'lead_owner')) return;
        if (!variables[key] && el.textContent === `[=${value.name}=]`) {
          el.classList.add('subject-variable-empty');
          el.classList.remove('subject-variable');
        }
      });
    });
  }
};

export const replaceBodyEmptyVariables = (body: string, variables: VariableProps, bulkEmail: boolean): string => {
  let replacedBody = body;
  Object.keys(variables).forEach((key) => {
    if (bulkEmail && (key === 'lead_full_name' || key === 'lead_first_name' || key === 'lead_owner')) return;
    if (!variables[key]) {
      replacedBody = replacedBody.replace(
        new RegExp(
          `<span class="email-placeholder">[=${emailVariables.VARIABLES[key].name}=]</span>`
            // eslint-disable-next-line no-useless-escape
            .replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'),
          'g',
        ),
        `<span><span class="empty">[=${emailVariables.VARIABLES[key].name}=]</span></span>`,
      );
    }
  });
  return replacedBody;
};

// eslint-disable-next-line consistent-return
export const getRecentTour = (lead: Lead): TaskProps|null => {
  if (!isEmpty(lead) && lead.tasks) {
    const tours = lead.tasks.filter(t => t.tour_date);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortedTours = tours.sort((a, b) => ((b.tour_date as any) - (a.tour_date as any)));
    const recentTour = sortedTours.slice(-1).pop();
    return recentTour;
  }
};

const weekdayMap = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

const formatBusinessHours = (businessHours: BusinessHoursProps): string | null => {
  const { weekday, start_time, end_time, is_workday } = businessHours;
  if (!is_workday) return null;
  const day = weekdayMap[weekday];
  const startTime = moment(start_time, 'HH:mm:ss').format('h:mm A');
  const endTime = moment(end_time, 'HH:mm:ss').format('h:mm A');
  return `${day}: ${startTime} - ${endTime}`;
};

export const checkVariables = (variables: string[], lead: Lead, property: PropertyProps, bulkEmail = false, priceQuote = {} as PriceQuote, lease = {} as ApplicationDetailsProps, cc = ''): VariableProps => {
  const checkedVariables = {};
  (variables || []).forEach((v) => {
    const parsedVariable = { object: v.split(/_(.+)/)[0], field: v.split(/_(.+)/)[1] };
    checkedVariables[v] = emailVariables.VARIABLES[v]?.name || undefined;

    if (!lead && isEmpty(lease)) return;
    if (!lead) lead = {} as Lead;
    const { lease_details: leaseDetails = {} as LeadLeaseDetails } = !isEmpty(lead) ? lead : lease;

    switch (parsedVariable.object) {
      case 'applicant': {
        switch (parsedVariable.field) {
          case 'portal': {
            checkedVariables[v] = hyperlink(`https://${property.domain}/applicant-portal/application-login`, 'Applicant Portal');
            break;
          }
          case 'name': {
            if (!isEmpty(leaseDetails) && leaseDetails.applicant) {
              checkedVariables[v] = `${leaseDetails.applicant.first_name} ${leaseDetails.applicant.last_name}`;
            }
            break;
          }
          case 'first_name': {
            if (!isEmpty(leaseDetails) && leaseDetails.applicant) {
              checkedVariables[v] = leaseDetails.applicant.first_name;
            }
            break;
          }
        }
        break;
      }

      case 'resident': {
        switch (parsedVariable.field) {
          case 'first_name': {
            if (!isEmpty(leaseDetails) && leaseDetails.applicant) {
              checkedVariables[v] = leaseDetails.applicant.first_name;

              if (!isEmpty(lease) && cc) {
                const applicants = lease.applicants_group || lease.resident_group || [];
                const emails = cc.split(', ');
                const applicantsCC = applicants.filter(el => emails.includes(el.email));
                if (applicantsCC.length) {
                  checkedVariables[v] += `, ${applicantsCC.map(el => el.first_name).join(', ')}`;
                }
              }
            }
            break;
          }
        }
        break;
      }

      case 'guarantor': {
        if (!leaseDetails) break;
        switch (parsedVariable.field) {
          case 'name': {
            if (leaseDetails.guarantor) {
              checkedVariables[v] = `${leaseDetails.guarantor.first_name} ${leaseDetails.guarantor.last_name}`;
            }
            break;
          }
          case 'first_name': {
            if (leaseDetails.guarantor) {
              checkedVariables[v] = leaseDetails.guarantor.first_name;
            }
            break;
          }
        }
        break;
      }

      case 'co': {
        if (!leaseDetails) break;
        switch (parsedVariable.field) {
          case 'applicant_name': {
            if (leaseDetails.co_applicant) {
              checkedVariables[v] = `${leaseDetails.co_applicant.first_name} ${leaseDetails.co_applicant.last_name}`;
            }
            break;
          }
          case 'applicant_first_name': {
            if (leaseDetails.co_applicant) {
              checkedVariables[v] = leaseDetails.co_applicant.first_name;
            }
            break;
          }
        }
        break;
      }

      case 'unit': {
        switch (parsedVariable.field) {
          case 'number': {
            if (!isEmpty(priceQuote) && priceQuote.unit) {
              checkedVariables[v] = priceQuote.unit_name;
            } else if (!isEmpty(lead.selected_unit_details) && lead.selected_unit_details.unit) {
              checkedVariables[v] = lead.selected_unit_details.unit;
            } else if (!isEmpty(leaseDetails) && leaseDetails.unit_number) {
              checkedVariables[v] = leaseDetails.unit_number;
            } else if (lead.units?.length) {
              checkedVariables[v] = lead.units_selected.join(', ');
            } else if (lead.tasks?.length) {
              const recentTour = getRecentTour(lead);
              if (recentTour && Object.keys(TOUR_TYPES).includes(recentTour.type) && recentTour.units) {
                const unit_names = [...recentTour.selected_units];
                const last_unit = unit_names.pop();
                checkedVariables[v] = unit_names.join(', ') + (unit_names.length ? ' and ' : '') + last_unit;
              }
            }
            break;
          }
          case 'number_price': {
            if (!isEmpty(priceQuote) && priceQuote.price) {
              checkedVariables[v] = `$${priceQuote.price}`;
            } else if (!isEmpty(lead.selected_unit_details) && lead.selected_unit_details.price) {
              checkedVariables[v] = `$${lead.selected_unit_details.price}`;
            }
            break;
          }
          case 'type': {
            if (!isEmpty(priceQuote) && priceQuote.unit_type) {
              checkedVariables[v] = priceQuote.unit_type;
            } else if (!isEmpty(lead.selected_unit_type_details) && lead.selected_unit_type_details.unit_type) {
              checkedVariables[v] = lead.selected_unit_type_details.unit_type;
            }
            break;
          }
          case 'type_lowest_price': {
            if (!isEmpty(priceQuote) && priceQuote.lowest_price) {
              checkedVariables[v] = `$${priceQuote.lowest_price}`;
            } else if (!isEmpty(lead.selected_unit_type_details) && lead.selected_unit_type_details.lowest_price) {
              checkedVariables[v] = `$${lead.selected_unit_type_details.lowest_price}`;
            }
            break;
          }
          case 'type_highest_price': {
            if (!isEmpty(priceQuote) && priceQuote.highest_price) {
              checkedVariables[v] = `$${priceQuote.highest_price}`;
            } else if (!isEmpty(lead.selected_unit_type_details) && lead.selected_unit_type_details.highest_price) {
              checkedVariables[v] = `$${lead.selected_unit_type_details.highest_price}`;
            }
            break;
          }
        }
        break;
      }

      case 'floor': {
        switch (parsedVariable.field) {
          case 'plan': {
            if (!isEmpty(priceQuote) && priceQuote.floor_plan) {
              checkedVariables[v] = priceQuote.floor_plan_name;
            } else if (!isEmpty(lead.selected_floorplan_details) && lead.selected_floorplan_details.floorplan) {
              checkedVariables[v] = lead.selected_floorplan_details.floorplan;
            }
            break;
          }
          case 'plan_lowest_price': {
            if (!isEmpty(priceQuote) && priceQuote.lowest_price) {
              checkedVariables[v] = `$${priceQuote.lowest_price}`;
            } else if (!isEmpty(lead.selected_floorplan_details) && lead.selected_floorplan_details.lowest_price) {
              checkedVariables[v] = `$${lead.selected_floorplan_details.lowest_price}`;
            }
            break;
          }
          case 'plan_highest_price': {
            if (!isEmpty(priceQuote) && priceQuote.highest_price) {
              checkedVariables[v] = `$${priceQuote.highest_price}`;
            } else if (!isEmpty(lead.selected_floorplan_details) && lead.selected_floorplan_details.highest_price) {
              checkedVariables[v] = `$${lead.selected_floorplan_details.highest_price}`;
            }
            break;
          }
        }
        break;
      }

      case 'application': {
        const link = `https://${property.domain}/applicant-portal/apply#${lead.external_id}`;
        switch (parsedVariable.field) {
          case 'hyperlink': {
            checkedVariables[v] = hyperlink(link);
            break;
          }
          case 'link': {
            checkedVariables[v] = link;
            break;
          }
          case 'link_button': {
            checkedVariables[v] = button(link);
            break;
          }
        }
        break;
      }

      case 'deposit': {
        const link = `https://${property.domain}/lease-application/document-agreement/deposit`;
        switch (parsedVariable.field) {
          case 'agreement_name': {
            checkedVariables[v] = 'Deposit Agreement';
            break;
          }
          case 'agreement_sign_link': {
            checkedVariables[v] = link;
            break;
          }
          case 'agreement_sign_hyperlink': {
            checkedVariables[v] = hyperlink(link);
            break;
          }
        }
        break;
      }

      case 'lead': {
        let owner = property.users.find(u => u.id === lead.owner);
        if (!owner) {
          // If we don't find an owner, we need to get the first user in the property just to display it as the owner
          // To just display something in the preview, this will not set the the owner in the email.
          owner = property.users.find(u => u.id);
        }
        const { first_name, last_name } = lead || { first_name: 'Lead', last_name: 'Name' };
        switch (parsedVariable.field) {
          case 'first_name': {
            if (first_name === '-') {
              checkedVariables[v] = 'Potential Resident';
            } else {
              checkedVariables[v] = first_name;
            }
            break;
          }
          case 'name':
          case 'full_name': {
            if (first_name && last_name) {
              if (first_name === '-' && last_name === '-') {
                checkedVariables[v] = 'potential resident';
              } else {
                checkedVariables[v] = `${first_name} ${last_name}`;
              }
            }
            break;
          }
          case 'owner': {
            if (owner) checkedVariables[v] = `${get(owner, 'first_name', '')} ${get(owner, 'last_name', '')}`;
            break;
          }
          case 'owner_first_name': {
            if (owner) checkedVariables[v] = get(owner, 'first_name', '');
            break;
          }
          default:
            checkedVariables[v] = lead[parsedVariable.field];
            break;
        }
        break;
      }

      case 'lease': {
        const link = `https://${property.domain}/lease-application/document-agreement/lease`;
        switch (parsedVariable.field) {
          case 'agreement_name': {
            checkedVariables[v] = 'Lease Agreement';
            break;
          }
          case 'agreement_sign_hyperlink': {
            checkedVariables[v] = hyperlink(link);
            break;
          }
          case 'agreement_sign_link': {
            checkedVariables[v] = link;
            break;
          }
          case 'owner': {
            const owner = property.users.find(u => u.id === lease.owner);
            if (owner) checkedVariables[v] = `${get(owner, 'first_name', '')} ${get(owner, 'last_name', '')}`;
            break;
          }
          case 'end_date': {
            checkedVariables[v] = lease.lease_end_date;
            break;
          }
          case 'base_rent': {
            checkedVariables[v] = `$${lease.base_rent}`;
            break;
          }
          case 'required_notice_to_vacate_days': {
            checkedVariables[v] = `${property?.polices?.notice_to_vacate_prior_days || 0}`;
            break;
          }
          case 'month_to_month_fee': {
            const leaseDefault = property?.lease_default;
            if (leaseDefault) {
              if (leaseDefault.month_to_month_fee_mode === 'FIXED') {
                checkedVariables[v] = `$${leaseDefault.month_to_month_fee}`;
              } else {
                checkedVariables[v] = `$${((lease.base_rent || 0) * (leaseDefault.month_to_month_fee || 0)) / 100}`;
              }
            }
            break;
          }
        }
        break;
      }

      case 'property': {
        switch (parsedVariable.field) {
          case 'address': {
            checkedVariables[v] = `${property.city}, ${property.town}`;
            break;
          }
          case 'name': {
            checkedVariables[v] = property.name;
            break;
          }
          case 'logo': {
            checkedVariables[v] = `
            <div style="text-align: center">
              <img
                alt="Property logo"
                src="${property.logo}?fit=crop&auto=format&fm=blob"
                style="padding: 10px; width: 150px; height: auto;"
                />
            </div>
            `;
            break;
          }
          case 'website': {
            if (lead.chat_prospects && lead.chat_prospects.length) {
              const prospectSource = lead.chat_prospects[0].source;
              if (prospectSource === 'MT' && property.mark_taylor_base_url) {
                checkedVariables[v] = property.mark_taylor_base_url;
              } else {
                checkedVariables[v] = property.domain;
              }
            } else if (lead.is_prospect_source_mt && property.mark_taylor_base_url) {
              checkedVariables[v] = property.mark_taylor_base_url;
            } else {
              checkedVariables[v] = property.domain;
            }
            break;
          }
          case 'website_link': {
            checkedVariables[v] = `<a href="${protocol(property.domain)}?chat_open" target="_blank">here</a>`;
            break;
          }
          default:
            checkedVariables[v] = property[parsedVariable.field];
            break;
          case 'email': {
            checkedVariables[v] = property.shared_email;
            break;
          }
          case 'phone_number': {
            checkedVariables[v] = property.tracking_number || property.target_number;
            break;
          }
          case 'description': {
            checkedVariables[v] = property.description;
            break;
          }
          case 'business_hours': {
            if (property.business_hours.length) {
              const formattedBusinessHours = property.business_hours
                .sort((a, b) => a.weekday - b.weekday)
                .map(bh => formatBusinessHours(bh))
                .filter(bh => bh)
                .join(', ');
              checkedVariables[v] = formattedBusinessHours;
            }
            break;
          }
        }
        break;
      }

      case 'tour': {
        switch (parsedVariable.field) {
          case 'link': {
            checkedVariables[v] = `https://${property.domain}?chat_open`;
            break;
          }
          case 'time': {
            if (lead.tour_date) {
              checkedVariables[v] = lead.tour_date;
            }
            break;
          }
          case 'type': {
            const recentTour = getRecentTour(lead);
            if (recentTour && Object.keys(TOUR_TYPES).includes(recentTour.type)) {
              checkedVariables[v] = TOUR_TYPES[recentTour.type];
            }
          }
        }
        break;
      }

      case 'unknown': {
        checkedVariables[v] = `https://${property.domain}/apply-now`;
        break;
      }

      case 'virtual': {
        checkedVariables[v] = `https://${property.domain}/virtual-tour`;
        break;
      }
    }
  });

  return checkedVariables;
};

export const getLeaseInfoForEmails = (lease: ApplicationDetailsProps, applicant: ApplicantProps): ApplicationDetailsProps => {
  const applicants = lease.applicants_group || lease.resident_group || [];

  return {
    ...lease,
    lease_details: {
      applicant: applicant || applicants.find(el => el.type === 'PRIMARY_APPLICANT') || null,
      co_applicant: applicants.find(el => el.type === 'CO_APPLICANT') || null,
      guarantor: applicants.find(el => el.type === 'GUARANTOR') || null,
      unit_number: lease.unit || null,
    },
  };
};
