import { PromiseActionType } from 'dwell/store/types';
import { ResidentGroupProps } from 'resident/store/lease/action-types';

export enum ActionType {
  UPDATE_RESIDENT_REQUEST = 'UPDATE_RESIDENT_REQUEST',
  UPDATE_RESIDENT_SUCCESS = 'UPDATE_RESIDENT_SUCCESS',
  UPDATE_RESIDENT_FAILURE = 'UPDATE_RESIDENT_FAILURE',

  SUBSCRIBE_RESIDENT_REQUEST = 'SUBSCRIBE_RESIDENT_REQUEST',
  SUBSCRIBE_RESIDENT_SUCCESS = 'SUBSCRIBE_RESIDENT_SUCCESS',
  SUBSCRIBE_RESIDENT_FAILURE = 'SUBSCRIBE_RESIDENT_FAILURE',

  UNSUBSCRIBE_RESIDENT_REQUEST = 'UNSUBSCRIBE_RESIDENT_REQUEST',
  UNSUBSCRIBE_RESIDENT_SUCCESS = 'UNSUBSCRIBE_RESIDENT_SUCCESS',
  UNSUBSCRIBE_RESIDENT_FAILURE = 'UNSUBSCRIBE_RESIDENT_FAILURE',

  REFUND_RESIDENT_REQUEST = 'REFUND_RESIDENT_REQUEST',
  REFUND_RESIDENT_SUCCESS = 'REFUND_RESIDENT_SUCCESS',
  REFUND_RESIDENT_FAILURE = 'REFUND_RESIDENT_FAILURE',

  SET_RESIDENT = 'SET_RESIDENT',
}

export interface UpdateResidentAction {
  type: ActionType.UPDATE_RESIDENT_REQUEST | ActionType.UPDATE_RESIDENT_SUCCESS | ActionType.UPDATE_RESIDENT_FAILURE;

  result: {
    data: ResidentGroupProps;
  };
  error: {
    response: { status: string };
  };
}

export interface SubscribeResidentAction {
  type: ActionType.SUBSCRIBE_RESIDENT_REQUEST | ActionType.SUBSCRIBE_RESIDENT_SUCCESS | ActionType.SUBSCRIBE_RESIDENT_FAILURE;

  result: {
    data: ResidentGroupProps;
  };
  error: {
    response: { status: string };
  };
}

export interface UnsubscribeResidentAction {
  type: ActionType.UNSUBSCRIBE_RESIDENT_REQUEST | ActionType.UNSUBSCRIBE_RESIDENT_SUCCESS | ActionType.UNSUBSCRIBE_RESIDENT_FAILURE;

  result: {
    data: ResidentGroupProps;
  };
  error: {
    response: { status: string };
  };
}

export interface SetResidentAction {
  type: ActionType.SET_RESIDENT;
  resident: ResidentGroupProps;
}

export type Action =
  | PromiseActionType
  | UpdateResidentAction
  | SubscribeResidentAction
  | UnsubscribeResidentAction
  | SetResidentAction;
