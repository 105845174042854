import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_STORAGE_REQUEST = 'CREATE_STORAGE_REQUEST',
  CREATE_STORAGE_SUCCESS = 'CREATE_STORAGE_SUCCESS',
  CREATE_STORAGE_FAILURE = 'CREATE_STORAGE_FAILURE',

  DELETE_STORAGE_REQUEST = 'DELETE_STORAGE_REQUEST',
  DELETE_STORAGE_SUCCESS = 'DELETE_STORAGE_SUCCESS',
  DELETE_STORAGE_FAILURE = 'DELETE_STORAGE_FAILURE',

  UPDATE_STORAGE_REQUEST = 'UPDATE_STORAGE_REQUEST',
  UPDATE_STORAGE_SUCCESS = 'UPDATE_STORAGE_SUCCESS',
  UPDATE_STORAGE_FAILURE = 'UPDATE_STORAGE_FAILURE',
}

export interface StorageState extends CommonStateType {
  storages: StorageProps[]
}

export interface StorageProps {
  id?: number,
  deposit: number,
  fee: number,
  numbers: string,
  qty: number,
  rent: number,
  term_from: string,
  term_to: string,
  term: string,
}

/*
 * Actions
 */

interface CreateStorageAction extends CommonActionResponse {
  type: ActionType.CREATE_STORAGE_REQUEST
  | ActionType.CREATE_STORAGE_SUCCESS
  | ActionType.CREATE_STORAGE_FAILURE;
  result: { data: StorageProps };
}

interface DeleteStorageAction extends CommonActionResponse {
  type: ActionType.DELETE_STORAGE_REQUEST
  | ActionType.DELETE_STORAGE_SUCCESS
  | ActionType.DELETE_STORAGE_FAILURE;
  result: { data: StorageProps };
}

interface UpdateStorageAction extends CommonActionResponse {
  type: ActionType.UPDATE_STORAGE_REQUEST
  | ActionType.UPDATE_STORAGE_SUCCESS
  | ActionType.UPDATE_STORAGE_FAILURE;
  result: { data: StorageProps };
}

export type Action =
  PromiseActionType
  | CreateStorageAction
  | DeleteStorageAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdateStorageAction;
