import React, { FC, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { isEmpty, isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'dwell/actions';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import EmailPopOut from 'dwell/components/email/popout';
import { PropertyProps } from 'dwell/store/property/action-types';
import { INITIAL_CONTACTS_N_PROSPECTS_OFFSET } from 'dwell/constants';
import { selectActivePopouts } from 'dwell/store/email_popout/reducers';
import CallWindow from 'dwell/views/calls/communications/call_window';
import VoiceCentre from 'dwell/views/calls/communications/voice_centre';
import { selectCallInProgress, selectDialWindow } from 'dwell/store/voice/reducers';
import AgentChat from 'dwell/views/chat/agent_question';
import DialWindow from 'dwell/views/calls/communications/call_window/dial_window';
import ChatNotifications from './_notifications';
import CircledChat from './circled';
import { CommunicationsGroup } from './styles';
import ContactPanel from './contact';
import ChatWindow from './window';

const Chat: FC = () => {
  const selectedProperties = useSelector(selectSelectedProperties);
  const activeChats = useSelector(state => state.prospectChat.activeChats);
  const contacts = useSelector(state => state.smsMessage.contacts);
  const prospects = useSelector(state => state.prospectChat.prospects);
  const isChatMinimized = useSelector(state => state.prospectChat.isChatMinimized);
  const isRenderedByCommand = useSelector(state => state.prospectChat.isRenderedByCommand);
  const emailPopouts = useSelector(selectActivePopouts);
  const activeCall = useSelector(selectCallInProgress);
  const openDialWindow = useSelector(selectDialWindow);
  const isOpenProfileMenu = useSelector(state => state.propertyProfile.isOpen);
  const dispatch = useDispatch();
  const { setChatRenderedStatus, getAllProspects, clearProspects } = actions.prospectChat;
  const { getSMSContacts, clearContacts } = actions.smsMessage;
  const { getLeadNames } = actions.lead;

  const [didMount, setDidMount] = useState(false);
  const [prospectsZeroDownload, setProspectsZeroDownload] = useState(false);
  const [contactsZeroDownload, setContactsZeroDownload] = useState(false);
  const cancelTokenProspects = useRef(null);
  const cancelTokenContacts = useRef(null);

  useEffect(() => {
    setDidMount(true);
  }, []);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSelectedProperties = usePrevious(selectedProperties);

  const getContactsWCancelToken = () => {
    if (cancelTokenContacts.current) {
      cancelTokenContacts.current.cancel('Contacts: Operation canceled due to new request.');
    }

    cancelTokenContacts.current = axios.CancelToken.source();
    dispatch(getSMSContacts(0, INITIAL_CONTACTS_N_PROSPECTS_OFFSET, cancelTokenContacts.current.token)).then((response) => {
      if (response) {
        const {
          result: {
            data: { results },
          },
        } = response;
        if (!results.length) {
          setProspectsZeroDownload(true);
        }
      }
    });
  };

  useEffect(() => {
    if (didMount && !isEmpty(selectedProperties)) {
      const prevIds = typeof prevSelectedProperties === 'undefined' ? [] : (prevSelectedProperties as PropertyProps[]).map(p => p.id);
      const ids = selectedProperties.map(p => p.id);
      const propertiesChanged = prevIds.length && !isEqual(prevIds.sort(), ids.sort());
      if (propertiesChanged) {
        dispatch(clearContacts());
      }
      if ((!contacts.length && !prospectsZeroDownload) || propertiesChanged) {
        getContactsWCancelToken();
      }
    }
  }, [didMount, selectedProperties, contacts.length, prospectsZeroDownload]);

  const getProspectsWCancelToken = (ids) => {
    if (cancelTokenProspects.current) {
      cancelTokenProspects.current.cancel('Prospects: Operation canceled due to new request.');
    }

    cancelTokenProspects.current = axios.CancelToken.source();
    dispatch(getAllProspects(false, ids, 0, INITIAL_CONTACTS_N_PROSPECTS_OFFSET, cancelTokenProspects.current.token)).then((response) => {
      if (response) {
        const {
          result: {
            data: { results },
          },
        } = response;
        if (!results.length) {
          setContactsZeroDownload(true);
        }
      }
    });
  };

  useEffect(() => {
    if (didMount && !isEmpty(selectedProperties)) {
      const prevIds = typeof prevSelectedProperties === 'undefined' ? [] : (prevSelectedProperties as PropertyProps[]).map(p => p.id);
      const ids = selectedProperties.map(p => p.id);
      const propertiesChanged = prevIds.length && !isEqual(prevIds.sort(), ids.sort());
      if (propertiesChanged) {
        dispatch(clearProspects());
      }
      if ((!prospects.length && !contactsZeroDownload) || propertiesChanged) {
        getProspectsWCancelToken(ids);
      }
    }
  }, [didMount, selectedProperties, prospects.length, contactsZeroDownload]);

  useEffect(() => {
    if (didMount) {
      if (!isChatMinimized && isRenderedByCommand) {
        dispatch(getLeadNames());
        dispatch(setChatRenderedStatus(false));
      }
    }
  }, [isChatMinimized, didMount, isRenderedByCommand]);

  const isAgentChatEnabled = selectedProperties.some(p => p.agent_chat_enabled);

  return (
    <div id="single-chat">
      <CircledChat />
      <CommunicationsGroup isOpenProfileMenu={isOpenProfileMenu}>
        <ContactPanel />
        <AgentChat />
        {activeChats.filter(contact => !contact.circled).map(contact => contact && <ChatWindow key={contact.id} contactId={contact} minimized={contact.minimized} />)}
        {emailPopouts.map(popout => popout && <EmailPopOut key={`popout-${popout.popoutId}`} {...popout} />)}
        {activeCall && <CallWindow />}
        {openDialWindow && <DialWindow />}
      </CommunicationsGroup>
      <VoiceCentre />
      {isAgentChatEnabled && <ChatNotifications />}
    </div>
  );
};

export default Chat;
