import styled from 'styled-components';

export const Action = styled.button`
  align-items: center;
  background: white;
  border: none;
  color: ${props => props.theme.colors.colortx02};
  display: inline-flex;
  font-size: 18px;
  height: 30px;
  justify-content: center;
  margin-left: 5px;
  width: 30px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.with-border {
    border: 1px solid ${props => props.theme.input.borderColor};
    border-radius: 3px;
  }

  &:focus {
    outline: none;
  }
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid ${props => props.theme.input.borderColor};
  padding: 10px 20px;

  span span {
    color: ${props => props.theme.colors.gray600};
  }
`;

export const ReportLink = styled.span`
  background: none;
  border: none;
  color: ${props => props.theme.colors.colorui01};

  &:hover {
    text-decoration: underline;
  }
`;
