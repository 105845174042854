import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, OtherRentItemProps } from './action-types';

export default {
  createOtherRentItem: (leaseId: number, body: OtherRentItemProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_OTHER_RENT_ITEM_REQUEST,
        ActionType.CREATE_OTHER_RENT_ITEM_SUCCESS,
        ActionType.CREATE_OTHER_RENT_ITEM_FAILURE,
      ],
      promise: client => client.post(build(api.v1.OTHER_RENT_ITEMS, leaseId), body),
    },
  }),

  deleteOtherRentItem: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_OTHER_RENT_ITEM_REQUEST,
        ActionType.DELETE_OTHER_RENT_ITEM_SUCCESS,
        ActionType.DELETE_OTHER_RENT_ITEM_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.OTHER_RENT_ITEM_DETAILS, leaseId, id)),
    },
  }),

  updateOtherRentItem: (leaseId: number, id: number, params: OtherRentItemProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_OTHER_RENT_ITEM_REQUEST,
        ActionType.UPDATE_OTHER_RENT_ITEM_SUCCESS,
        ActionType.UPDATE_OTHER_RENT_ITEM_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.OTHER_RENT_ITEM_DETAILS, leaseId, id), params),
    },
  }),
};
