import React, { FC } from 'react';

import { CustomerProps } from 'site/store/customer/action-types';

import { PropertyLogoDiv, PropertyLogoImg } from 'containers/default_header/styles';

interface PropertyLogoProps {
  customer: CustomerProps;
}

const PropertyLogo: FC<PropertyLogoProps> = ({ customer }) => <PropertyLogoDiv>{customer && customer.logo ? <PropertyLogoImg src={customer.logo} alt="Customer Logo" /> : <i className="ri-home-8-line" />}</PropertyLogoDiv>;

export default PropertyLogo;
