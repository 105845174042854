import { api, build } from 'leasing/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  createDocument: (leaseId: number, data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_DOCUMENT_REQUEST,
        ActionType.CREATE_DOCUMENT_SUCCESS,
        ActionType.CREATE_DOCUMENT_FAILURE,
      ],
      promise: client => client.post(build(api.v1.DOCUMENTS, leaseId), data),
    },
  }),

  getDocuments: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_DOCUMENTS_REQUEST,
        ActionType.GET_DOCUMENTS_SUCCESS,
        ActionType.GET_DOCUMENTS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.DOCUMENTS, leaseId)),
    },
  }),

  getLeaseDocuments: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEASE_DOCUMENTS_REQUEST,
        ActionType.GET_LEASE_DOCUMENTS_SUCCESS,
        ActionType.GET_LEASE_DOCUMENTS_FAILURE,
      ],
      promise: client => client.get(api.v1.LEASE_DOCUMENTS),
    },
  }),

  expandFrame: (value: boolean): Action => ({ type: ActionType.EXPAND_FRAME, value }),

  setIsDocumentGenerated: (value: boolean): Action => ({ type: ActionType.SET_IS_DOCUMENT_POPULATED, value }),
};
