import produce from 'immer';

import { selectBusinessHours } from 'dwell/store/business_hours/reducers';
import { PropertyState, selectProperty } from 'dwell/store/property/reducers';
import { preFillState } from 'dwell/store/utils';
import { LeaseDocumentProps, LeaseDocumentState } from 'leasing/store/lease_document/action-types';
import { selectApplicants } from 'leasing/store/applicant/reducers';
import { selectConcession } from 'leasing/store/concession/reducers';
import { selectDepositAgreementDocument, selectLeaseAgreementDocument } from 'leasing/store/lease_document/reducers';
import { selectGarages } from 'leasing/store/garage/reducers';
import { selectGateKeys } from 'leasing/store/gate_key/reducers';
import { selectGuarantors } from 'leasing/store/guarantor/reducers';
import { selectLeaseDefault, selectPropertyPolicy, selectUtilityPolicy } from 'leasing/store/settings/reducers';
import { selectOtherRentItems } from 'leasing/store/other_rent_item/reducers';
import { selectParking } from 'leasing/store/parking/reducers';
import { selectPaymentReceived } from 'leasing/store/payment/reducers';
import { selectPets } from 'leasing/store/pet/reducers';
import { selectStorages } from 'leasing/store/storage/reducers';
import { selectUnits } from 'leasing/store/unit/reducers';
import { selectVehicles } from 'leasing/store/vehicle/reducers';
import { UnitProps } from 'src/interfaces';
import { Action, ActionType, LeaseProps, LeaseState, AvailableUnitsProps } from './action-types';

export const initialState: LeaseState = {
  errorMessage: null,
  lease: {
    addenda: [],
    occupants: [],
  } as LeaseProps,
  availableUnits: { application_started: [], no_application_started: [] },
  isUnitsLoaded: false,
  isLeaseLoaded: false,
  isLoaded: false,
  isStepperCollapsed: false,
};

export const selectLease = (state: { lease: LeaseState }): LeaseProps => state.lease.lease;
export const selectLeaseIsLoaded = (state: { lease: LeaseState }): boolean => state.lease.isLeaseLoaded;
export const selectAvailableUnits = (state: { lease: LeaseState }): AvailableUnitsProps => state.lease.availableUnits;
export const selectIsUnitsLoaded = (state: { lease: LeaseState }): boolean => state.lease.isUnitsLoaded;

export const selectIsStepperCollapsed = (state: { lease: LeaseState }): boolean => state.lease.isStepperCollapsed;
export const selectIsAllApplicantsSubmitted = (state: { lease: LeaseState }): boolean => {
  const values = Object.values(state.lease.lease.application_steps?.SUBMIT_APPLICATION || {});
  return values.length && values.every(i => i);
};
export const selectIsAllApplicantsPaidFees = (state: { lease: LeaseState }): boolean => {
  const values = Object.values(state.lease.lease.application_steps?.PAY_APPLICATION_FEES || {});
  return values.length && values.every(i => i);
};
export const selectShouldShowLeaseAgreement = (state: { lease: LeaseState }): boolean =>
  !state.lease.lease?.sent_lease_agreement && ['APPROVED', 'CONDITIONALLY_APPROVED'].includes(state.lease.lease.status);
export const selectShouldShowDepositAgreement = (state: { lease: LeaseState }): boolean =>
  !state.lease.lease?.sent_deposit_agreement &&
    selectIsAllApplicantsPaidFees(state) &&
    selectIsAllApplicantsSubmitted(state);
export const selectUnit = (state: { lease: LeaseState, property: PropertyState }): UnitProps =>
  state.property.property.units.find(unit => unit.id === state.lease.lease.unit) || {} as UnitProps;
export const selectLeaseAddendumDocuments = (state: { lease: LeaseState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.lease.lease.addenda || []).includes(i.id));
export const selectDepositAddendumDocuments = (state: { lease: LeaseState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.lease.lease.deposit_agreement_addenda || []).includes(i.id));
export const selectIsLeaseSigned = (state: { lease: LeaseState }): boolean =>
  Object.values(state.lease.lease.application_steps?.SIGN_LEASE || {}).every(i => i);
export const selectIsLeaseSent = (state: { lease: LeaseState }): boolean =>
  Object.values(state.lease.lease.application_steps?.SEND_LEASE || {}).every(i => i);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectLeaseDetails = state => ({
  applicants: selectApplicants(state),
  guarantors: selectGuarantors(state),
  units: selectUnits(state),
  pets: selectPets(state),
  garages: selectGarages(state),
  storages: selectStorages(state),
  vehicles: selectVehicles(state),
  parking: selectParking(state),
  otherRentItems: selectOtherRentItems(state),
  leaseDefault: selectLeaseDefault(state),
  payment: selectPaymentReceived(state),
  concessions: selectConcession(state),
  gateKeys: selectGateKeys(state),
  propertyPolicy: selectPropertyPolicy(state),
  utilityPolicies: selectUtilityPolicy(state),
  lease: selectLease(state),
  businessHours: selectBusinessHours(state),
  property: selectProperty(state),
  isLoaded: selectLeaseIsLoaded(state),
  leaseAddendumDocuments: selectLeaseAddendumDocuments(state),
  depositAddendumDocuments: selectDepositAddendumDocuments(state),
  leaseAgreementDocument: selectLeaseAgreementDocument(state),
  depositAgreementDocument: selectDepositAgreementDocument(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectIsServiceAnimal = (state): boolean => selectPets(state).every(pet => pet.is_service_animal);

export default produce((state: LeaseState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.COLLAPSE_STEPPER:
      state.isStepperCollapsed = action.value;
      break;

    case ActionType.GET_LEASE_ADDENDA_SUCCESS:
      if (action.result.data.id === state.lease.id) {
        state.lease.addenda = action.result.data.addenda;
      }
      break;

    case ActionType.GET_LEASE_BY_ID_REQUEST:
      state.isLeaseLoaded = false;
      break;

    case ActionType.GET_LEASE_BY_ID_SUCCESS:
      state.lease = action.result.data;
      state.isLeaseLoaded = true;
      break;

    case ActionType.RESET_LEASE:
      state = initialState;
      break;

    case ActionType.SEND_DOCUMENT_SUCCESS:
      switch (action.result.data.type) {
        case 'DEPOSIT_AGREEMENT':
          state.lease.sent_deposit_agreement = true; // TODO this field should be remove in the future
          break;
        case 'LEASE_AGREEMENT':
          state.lease.sent_lease_agreement = true; // TODO this field should be remove in the future
          break;
      }
      break;

    case ActionType.UPDATE_APPLICATION_STATUS_SUCCESS:
      state.lease = action.result.data;
      break;

    case ActionType.UPDATE_LEASE_SUCCESS:
      state.lease = action.result.data;
      break;

    case ActionType.GET_LEASE_AVAILABLE_UNITS_REQUEST:
    case ActionType.GET_LEASE_AVAILABLE_UNITS_FAILURE:
      state.isUnitsLoaded = false;
      break;

    case ActionType.GET_LEASE_AVAILABLE_UNITS_SUCCESS:
      state.availableUnits = action.result.data;
      state.isUnitsLoaded = true;
      break;

    case ActionType.SET_LEASE_DATA: {
      const { field, value } = action;
      state.lease = { ...state.lease, [field]: value };
    }
  }

  return state;
});
