import { build } from 'dwell/constants/paths';
import { api } from 'resident/constants/paths';
import { ResidentGroupProps } from 'resident/store/lease/action-types';
import { ActionType, Action } from './action-types';

export default {
  subscribe: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.SUBSCRIBE_RESIDENT_REQUEST,
        ActionType.SUBSCRIBE_RESIDENT_SUCCESS,
        ActionType.SUBSCRIBE_RESIDENT_FAILURE,
      ],
      promise: client => client.put(build(api.v1.SUBSCRIBE_CB, id), { subscription_status: 'ACTIVE' }),
    },
  }),

  unsubscribe: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UNSUBSCRIBE_RESIDENT_REQUEST,
        ActionType.UNSUBSCRIBE_RESIDENT_SUCCESS,
        ActionType.UNSUBSCRIBE_RESIDENT_FAILURE,
      ],
      promise: client => client.put(build(api.v1.SUBSCRIBE_CB, id), { subscription_status: 'CANCELED' }),
    },
  }),

  refund: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.REFUND_RESIDENT_REQUEST,
        ActionType.REFUND_RESIDENT_SUCCESS,
        ActionType.REFUND_RESIDENT_FAILURE,
      ],
      promise: client => client.put(build(api.v1.SUBSCRIBE_CB, id), { refund_required: true }),
    },
  }),
  setResident: (resident: ResidentGroupProps): Action => ({ type: ActionType.SET_RESIDENT, resident }),
};
