import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_PMS_LOGS_REQUEST = 'GET_PMS_LOGS_REQUEST',
  GET_PMS_LOGS_SUCCESS = 'GET_PMS_LOGS_SUCCESS',
  GET_PMS_LOGS_FAILURE = 'GET_PMS_LOGS_FAILURE',
  RESET_PMS_LOGS = 'RESET_PMS_LOGS',
}

export interface PMSLogProps {
  id: number;
  date: string;
  method: string;
  source: string;
  status: string;
  payload?: string;
  reason?: string;
  response?: string;
}

interface GetPMSLogsAction {
  type: ActionType.GET_PMS_LOGS_REQUEST | ActionType.GET_PMS_LOGS_SUCCESS | ActionType.GET_PMS_LOGS_FAILURE;

  result: {
    data: { results: PMSLogProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface ResetPMSLogsAction {
  type: ActionType.RESET_PMS_LOGS;
}

export type Action = PromiseActionType | GetPMSLogsAction | ResetPMSLogsAction;
