import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import actions from 'dwell/actions';
import { SelectItem, SelectToggle } from 'styles/common';
import { FloorPlanTypes } from 'dwell/store/property_profile/action-types';
import UnitCard from './unit_card';
import { formatUnitFilter } from './utils';
import { Content, Label, SelectButton, SelectMenu, FormSwitch, NoDataLabel } from './styles';

const AvailableUnits: FC = () => {
  const dispatch = useDispatch();
  const { setUnitType } = actions.propertyProfile;
  const [unitFilter, setUnitFilter] = useState('All');
  const [availabilityFilter, setAvailabilityFilter] = useState('Any');
  const [promotionOnlyFilter, setPromotionOnlyFilter] = useState(false);
  const [unitFilterOpen, setUnitFilterOpen] = useState(false);
  const [availabilityFilterOpen, setAvailabilityFilterOpen] = useState(false);
  const [command, setCommand] = useState(null);
  const [activeRow, setActiveRow] = useState(0);
  const unitTypeFromHobbes = useSelector(state => state.propertyProfile.unitType) as string;
  const floorPlans = useSelector(state => state.propertyProfile.floorPlans) as FloorPlanTypes[];
  const grouped = useMemo(() => {
    const result = {};
    let filtered = floorPlans;
    if (unitFilter !== 'All') {
      filtered = filtered.filter(plan => plan.bedrooms?.toString() === unitFilter);
    }
    if (availabilityFilter === 'Now') {
      filtered = filtered.filter(plan => plan.units.some(unit => !moment(unit.available_date).isAfter(moment())));
    }
    if (promotionOnlyFilter) {
      filtered = filtered.filter(plan => plan.promotion);
    }
    filtered.forEach((plan) => {
      result[plan.bedrooms?.toString()] = (result[plan.bedrooms?.toString()] || []).concat([plan]);
    });
    return result;
  }, [floorPlans, unitFilter, availabilityFilter, promotionOnlyFilter]) as {[unit_type: string]: FloorPlanTypes[]};
  const combinedUnits = useMemo(() => {
    const combinedPlans = Object.values(grouped).reduce((prev, row) => row.concat(prev), []);
    return combinedPlans.reduce((prev, row) => row.units.concat(prev), []);
  }, [floorPlans]);
  const activeUnit = useMemo(() => combinedUnits[activeRow]?.unit, [activeRow, combinedUnits]);

  const unitTypes = ['0', '1', '2', '3', '4'];

  const resetFilters = () => {
    setUnitFilter('All');
    setAvailabilityFilter('Any');
    setPromotionOnlyFilter(false);
  };

  const comm = [17, 91, 93, 224];
  const keyDown = (e) => {
    if (comm.includes(e.keyCode)) {
      setCommand(e.keyCode);
    }

    // Arrow Up
    if (e.keyCode === 38) {
      setActiveRow(activeRow <= 0 ? activeRow : activeRow - 1);
    }

    // Arrow Down
    if (e.keyCode === 40) {
      setActiveRow(activeRow >= combinedUnits.length - 1 ? activeRow : activeRow + 1);
    }

    // Enter
    if (e.keyCode === 13) {
      const element = document.querySelector(`#unit-row-${activeUnit}`) as HTMLAnchorElement;
      if (element) element.click();
    }

    // Ctrl + R
    if (command && e.keyCode === 82) {
      resetFilters();
    }

    return true;
  };

  const keyUp = (e) => {
    if (comm.includes(e.keyCode)) {
      setCommand(null);
    }
    return true;
  };

  useEffect(() => {
    if (unitTypeFromHobbes) {
      if (unitTypes.includes(unitTypeFromHobbes)) {
        setUnitFilter(unitTypeFromHobbes);
      }
      dispatch(setUnitType(null));
    }
  }, [unitTypeFromHobbes]);

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
      dispatch(setUnitType(null));
    };
  });

  return (
    <Content>
      <div className="row row-xs mg-b-15">
        <div className="col-6">
          <Label>Unit</Label>
          <SelectButton isOpen={unitFilterOpen} toggle={() => setUnitFilterOpen(!unitFilterOpen)} data-testid="unit-filter-btn">
            <SelectToggle caret>
              {unitFilter === 'All' ? unitFilter : formatUnitFilter(unitFilter)}
            </SelectToggle>
            <SelectMenu>
              <SelectItem onClick={() => setUnitFilter('All')}>
                All
              </SelectItem>
              {unitTypes.map((bed, i) => (
                <SelectItem key={i} onClick={() => setUnitFilter(bed)} data-testid={`unit-item-${bed}`}>
                  {formatUnitFilter(bed)}
                </SelectItem>
              ))}
            </SelectMenu>
          </SelectButton>
        </div>
        <div className="col-6">
          <Label>Availability</Label>
          <SelectButton isOpen={availabilityFilterOpen} toggle={() => setAvailabilityFilterOpen(!availabilityFilterOpen)} data-testid="availability-filter-btn">
            <SelectToggle caret>
              {availabilityFilter}
            </SelectToggle>
            <SelectMenu>
              <SelectItem onClick={() => setAvailabilityFilter('Any')} data-testid="availability-any">
                Any
              </SelectItem>
              <SelectItem onClick={() => setAvailabilityFilter('Now')} data-testid="availability-now">
                Now
              </SelectItem>
            </SelectMenu>
          </SelectButton>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span>Show units with specials only</span>
        <div id="showSpecials" className="form-switcher inactive" />
        <FormSwitch checked={promotionOnlyFilter} onClick={() => setPromotionOnlyFilter(!promotionOnlyFilter)} data-testid="promotion-filter" />
      </div>
      <hr />
      <UnitCard grouped={grouped} activeUnit={activeUnit} availabilityFilter={availabilityFilter} />
      {!Object.keys(grouped).length && (
        <>
          <NoDataLabel>No Available Units meet your filter <br />criteria.</NoDataLabel>
          <button className="btn btn-primary btn-block" onClick={resetFilters}>Reset Filters</button>
        </>
      )}
    </Content>
  );
};

export default AvailableUnits;
