import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as AddendaActionType, AddendaState } from 'leasing/store/addenda/action-types';
import { ActionType as LeaseActionType, LeaseState } from 'leasing/store/lease/action-types';
import { selectLeaseAddendumDocuments, selectDepositAddendumDocuments } from 'leasing/store/lease/reducers';
import { Action, ActionType, LeaseDocumentProps, LeaseDocumentState } from './action-types';

export const initialState: LeaseDocumentState = {
  errorMessage: null,
  isDocumentPopulated: false,
  isExpanded: false,
  isLoaded: false,
  leaseDocuments: [] as LeaseDocumentProps[],
  leaseDocumentsLoaded: false,
};

export const selectLeaseAgreementDocument = (state : { addenda: AddendaState, leaseDocument: LeaseDocumentState }) : LeaseDocumentProps =>
  state.leaseDocument.leaseDocuments.find(d => d.type === 'LEASE_AGREEMENT' && d.status === 'PUBLISHED' && !!state.addenda.addendaDocuments.find(i => i.lease_document === d.id));
export const selectDepositAgreementDocument = (state : { addenda: AddendaState, leaseDocument: LeaseDocumentState }) : LeaseDocumentProps =>
  state.leaseDocument.leaseDocuments.find(d => d.type === 'DEPOSIT_AGREEMENT' && d.status === 'PUBLISHED' && !!state.addenda.addendaDocuments.find(i => i.lease_document === d.id));
export const selectDocumentIsLoaded = (state : { leaseDocument: LeaseDocumentState }) : boolean => state.leaseDocument.isLoaded;
export const selectAllLeaseDocuments = (state : { leaseDocument: LeaseDocumentState }) : LeaseDocumentProps [] => state.leaseDocument.leaseDocuments;
export const selectAllAddendumDocuments = (state : { leaseDocument: LeaseDocumentState }) : LeaseDocumentProps [] => state.leaseDocument.leaseDocuments.filter(d => d.type === 'ADDENDUM' && d.status === 'PUBLISHED');
export const selectLeaseDocumentsLoaded = (state : { leaseDocument: LeaseDocumentState }) : boolean => state.leaseDocument.leaseDocumentsLoaded;
export const selectIsDocumentPopulated = (state : { leaseDocument: LeaseDocumentState }) : boolean => state.leaseDocument.isDocumentPopulated;
export const selectIsExpanded = (state : { leaseDocument: LeaseDocumentState }) : boolean => state.leaseDocument.isExpanded;
export const selectNotice = (state: { leaseDocument: LeaseDocumentState }): LeaseDocumentProps =>
  state.leaseDocument.leaseDocuments.find(d => d.type === 'NOTICE' && d.status === 'PUBLISHED');
export const selectCreditBuilderAddendum = (state: { leaseDocument: LeaseDocumentState }): LeaseDocumentProps =>
  state.leaseDocument.leaseDocuments.find(d => d.name === 'Credit Builder Addendum');
export const selectExistingResidentCreditBuilderAddendum = (state: { leaseDocument: LeaseDocumentState }): LeaseDocumentProps =>
  state.leaseDocument.leaseDocuments.find(d => d.name === 'Existing Resident Credit Builder Addendum');

export const selectRoommateAddendum = (state: { leaseDocument: LeaseDocumentState }): LeaseDocumentProps =>
  state.leaseDocument.leaseDocuments.find(d => d.name === 'Roommate Addendum');

export const selectHighlightFields = (name: string) => (state: { addenda: AddendaState, lease: LeaseState, leaseDocument: LeaseDocumentState }): { name: string, document: string }[] => {
  // get the highlighting fields when input is focussed on
  const fields = [];
  const isLeaseAgreement = window.location.href.includes('lease_agreement');

  let documents;
  if (isLeaseAgreement) {
    documents = [selectLeaseAgreementDocument(state)].concat(selectLeaseAddendumDocuments(state));
  } else {
    documents = [selectDepositAgreementDocument(state)].concat(selectDepositAddendumDocuments(state));
  }
  documents.forEach((d) => {
    if (!d) return;
    const field = d.lease_fields.find(i => i.field_label === name);
    if (field) {
      fields.push({ name: field.name, document: d.name });
    }
  });
  return fields;
};

export default produce((state: LeaseDocumentState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.EXPAND_FRAME:
      state.isExpanded = action.value;
      break;

    case ActionType.GET_LEASE_DOCUMENTS_REQUEST:
      state.leaseDocumentsLoaded = false;
      break;

    case ActionType.GET_LEASE_DOCUMENTS_SUCCESS:
      state.leaseDocuments = action.result.data;
      state.leaseDocumentsLoaded = true;
      break;

    case ActionType.SET_IS_DOCUMENT_POPULATED:
      state.isDocumentPopulated = action.value;
      break;

    case AddendaActionType.ADD_MASTER_LEASE_DOCUMENTS_SUCCESS:
      state.leaseDocuments = [
        ...state.leaseDocuments.filter(doc => !action.result.data.find(i => i.id === doc.id)),
        ...action.result.data,
      ];
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
