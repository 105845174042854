import { paths } from 'dwell/constants';
import { ActionType, Action, ColumnProps } from './action-types';

export default {
  getColumnsSettings: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_COLUMNS_SETTINGS_REQUEST,
        ActionType.GET_COLUMNS_SETTINGS_SUCCESS,
        ActionType.GET_COLUMNS_SETTINGS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.COLUMNS),
    },
  }),
  createColumnsSettings: (data: ColumnProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_COLUMNS_SETTINGS_REQUEST,
        ActionType.CREATE_COLUMNS_SETTINGS_SUCCESS,
        ActionType.CREATE_COLUMNS_SETTINGS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.COLUMNS_CREATE, data),
    },
  }),
  updateColumnsSettings: (data: ColumnProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_COLUMNS_SETTINGS_REQUEST,
        ActionType.UPDATE_COLUMNS_SETTINGS_SUCCESS,
        ActionType.UPDATE_COLUMNS_SETTINGS_FAILURE,
      ],
      promise: client => client.put(paths.api.v1.COLUMNS_UPDATE, data),
    },
  }),
};
