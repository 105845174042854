import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, PaymentReceivedProps, PaymentReceivedState } from './action-types';

export const initialState: PaymentReceivedState = {
  errorMessage: null,
  isLoaded: false,
  payment: {} as PaymentReceivedProps,
};

export const selectPaymentReceived = (state : { payment: PaymentReceivedState }) : PaymentReceivedProps => state.payment.payment;

export default produce((state: PaymentReceivedState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_PAYMENT_RECEIVED_SUCCESS:
    case ActionType.UPDATE_PAYMENT_RECEIVED_SUCCESS:
      state.payment = action.result.data;
      break;

    case ActionType.DELETE_PAYMENT_RECEIVED_SUCCESS:
      state.payment = {} as PaymentReceivedProps;
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.payment = (action.result.data.payment_received || {}) as PaymentReceivedProps;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
