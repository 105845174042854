export default {
  HOME: 'home',
  GALLERY: 'gallery',
  FLOOR_PLANS: 'floor_plans',
  AMENITIES: 'amenities',
  CONTACT: 'contact',
  FOOTER: 'footer',
  SEO: 'seo',
  NEIGHBORHOOD: 'neighborhood',
  DESIGN: 'design',
  VIRTUAL_TOUR: 'virtual_tour',
};
