import addenda from './addenda/action-creators';
import applicant from './applicant/action-creators';
import concession from './concession/action-creators';
import dependent from './dependent/action-creators';
import document from './document/action-creators';
import employer from './employer/action-creators';
import garage from './garage/action-creators';
import gateKeys from './gate_key/action-creators';
import guarantor from './guarantor/action-creators';
import lease from './lease/action-creators';
import leaseDocument from './lease_document/action-creators';
import otherFeeItem from './other_fee_item/action-creators';
import otherIncomes from './other_income/action-creators';
import otherRentItem from './other_rent_item/action-creators';
import parking from './parking/action-creators';
import payment from './payment/action-creators';
import pet from './pet/action-creators';
import screeningReport from './screening_report/action-creators';
import settings from './settings/action-creators';
import storage from './storage/action-creators';
import unit from './unit/action-creators';
import vehicle from './vehicle/action-creators';
import notice from './notice/action-creators';
import renewalOffer from './renewal_offer/action-creators';

export default {
  addenda,
  applicant,
  concession,
  dependent,
  document,
  employer,
  garage,
  gateKeys,
  guarantor,
  lease,
  leaseDocument,
  otherFeeItem,
  otherIncomes,
  otherRentItem,
  parking,
  payment,
  pet,
  screeningReport,
  settings,
  storage,
  unit,
  vehicle,
  notice,
  renewalOffer,
};
