import { PromiseActionType } from 'dwell/store/types';
import { SSOStatus, SSOLoginResponse } from 'src/interfaces';

export enum ActionType {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT = 'LOGOUT',

  SSO_CHECK_REQUEST = 'SSO_CHECK_REQUEST',
  SSO_CHECK_SUCCESS = 'SSO_CHECK_SUCCESS',
  SSO_CHECK_FAILURE = 'SSO_CHECK_FAILURE',

  SSO_LOGIN_REQUEST = 'SSO_LOGIN_REQUEST',
  SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS',
  SSO_LOGIN_FAILURE = 'SSO_LOGIN_FAILURE',

  BACKCHANNEL_LOGOUT_REQUEST = 'BACKCHANNEL_LOGOUT_REQUEST',
  BACKCHANNEL_LOGOUT_SUCESS = 'BACKCHANNEL_LOGOUT_SUCESS',
  BACKCHANNEL_LOGOUT_FAILURE = 'BACKCHANNEL_LOGOUT_FAILURE',

  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  RESET_LOGIN_STATE = 'RESET_LOGIN_STATE',
}

export interface AuthenticationProps {
  access: string,
  is_superuser: boolean,
  role: string,
}

export interface LoginAction {
  type: ActionType.LOGIN_REQUEST
  | ActionType.LOGIN_SUCCESS
  | ActionType.LOGIN_FAILURE;

  result: {
    data: AuthenticationProps;
  };
  error: {
    response: { status: string }
  };
}

export interface LogoutAction {
  type: ActionType.LOGOUT;
}

export interface SessionTimeoutAction {
  type: ActionType.SESSION_TIMEOUT;
}

interface ResetLoginStateAction {
  type: ActionType.RESET_LOGIN_STATE;
  clearAll: boolean;
}

export interface SSOCheckAction {
  type:
  | ActionType.SSO_CHECK_REQUEST
  | ActionType.SSO_CHECK_SUCCESS
  | ActionType.SSO_CHECK_FAILURE;

  result: {
    data: SSOStatus;
  };

  error: {
    response: { status: string }
  };
}

export interface SSOLoginAction {
  type:
  | ActionType.SSO_LOGIN_REQUEST
  | ActionType.SSO_LOGIN_SUCCESS
  | ActionType.SSO_LOGIN_FAILURE;

  result: {
    data: SSOLoginResponse;
  };
  error: {
    response: { status: string };
  };
}

export interface BackchannelLogoutAction {
  type:
  | ActionType.BACKCHANNEL_LOGOUT_REQUEST
  | ActionType.BACKCHANNEL_LOGOUT_SUCESS
  | ActionType.BACKCHANNEL_LOGOUT_FAILURE;

  payload: {
    errorMessage: string;
    logoutCompletely: boolean;
  };
}

export type Action =
  | PromiseActionType
  | LoginAction
  | LogoutAction
  | SessionTimeoutAction
  | ResetLoginStateAction
  | SSOCheckAction
  | SSOLoginAction
  | BackchannelLogoutAction;
