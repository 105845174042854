import styled, { css } from 'styled-components';

import { DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

const availableGreen = '#21897E';

export const DropdownSwitch = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px;

  i {
    font-size: 20px;
    line-height: 1;
    margin-right: 15px;
    width: 18px;
  }

  p {
    color: ${props => props.theme.colors.colortx02};
    line-height: 1;
    margin-bottom: 4px;
  }

  small {
    display: block;
    font-size: 12px;
    opacity: .75;
  }

  .active-text { display: none; }

  &.active {
    .inactive-text { display: none; }
    .active-text { display: block; }
  }
`;

export const OnOff = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;

  background-color: ${props => (props.active ? '#21897E1F' : props.theme.colors.colorbg02)};
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.25s;

  &:hover {
    span {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }

  &::before,
  &::after {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-family: ${props => props.theme.fonts.secondary};
    text-transform: uppercase;
  }

  &::before {
    content: 'on';
    color: ${props => (props.active ? availableGreen : props.theme.colors.white)};
  }

  &::after {
    content: 'off';
    color: ${props => props.theme.colors.colortx03};
    justify-content: flex-start;
  }

  span {
    position: absolute;
    top: 3px;
    left: ${props => (props.active ? '25.5px' : '3px')};
    width: 18px;
    height: 18px;
    background-color: ${props => (props.active ? availableGreen : '#fff')};
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(${props => props.theme.colors.gray900}, 0.1);
    transition: left 0.25s, box-shadow 0.25s;
  }

  &:hover span {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  margin-left: auto;
  grid-gap: 3px;

  ${props => props.disabled && css`pointer-events: none;`}
`;

export const DropdownLink = styled(DropdownToggle)`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.colortx02};

  &::after {
    content: '\\ea4e';
    font-family: 'remixicon';
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    position: relative;
    top: 1px;
  }
`;

export const DropdownChoice = styled(DropdownItem)`
  outline: none;
  padding: 8px;
  color: #4a5e8a;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  &:active:not(:focus), &:active {
    background-color: #f0f3f5 !important;
    color: inherit !important;
  }
  ${props => props.active && css`
    background-color: #f0f3f5 !important;
    color: inherit !important;
  `}
`;

export const DoNotDisturbDropdownMenu = styled(DropdownMenu)`
  min-height: 200px;
  margin-top: 8px;
  padding: 10px;
  border-width: 0;
  border-radius: 4px;
  box-shadow: 2px 5px 45px rgba(36,55,130,0.12),
              0 1px 2px rgba(225,230,247,0.07),
              0 2px 4px rgba(225,230,247,0.07),
              0 4px 8px rgba(225,230,247,0.07),
              0 8px 16px rgba(225,230,247,0.07),
              0 16px 32px rgba(225,230,247,0.07),
              0 32px 64px rgba(225,230,247,0.07);
`;
