import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { GetLeaseByIdAction, ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_GARAGE_REQUEST = 'CREATE_GARAGE_REQUEST',
  CREATE_GARAGE_SUCCESS = 'CREATE_GARAGE_SUCCESS',
  CREATE_GARAGE_FAILURE = 'CREATE_GARAGE_FAILURE',

  DELETE_GARAGE_REQUEST = 'DELETE_GARAGE_REQUEST',
  DELETE_GARAGE_SUCCESS = 'DELETE_GARAGE_SUCCESS',
  DELETE_GARAGE_FAILURE = 'DELETE_GARAGE_FAILURE',

  UPDATE_GARAGE_REQUEST = 'UPDATE_GARAGE_REQUEST',
  UPDATE_GARAGE_SUCCESS = 'UPDATE_GARAGE_SUCCESS',
  UPDATE_GARAGE_FAILURE = 'UPDATE_GARAGE_FAILURE',
}

export interface GarageState extends CommonStateType {
  garages: GarageProps[]
}

export interface GarageProps {
  id?: number,
  deposit: number,
  fee: number,
  numbers: string,
  qty: number,
  rent: number,
  term_from: string,
  term_to: string,
  term: string,
  type: string,
}

/*
 * Actions
 */

interface CreateGarageAction extends CommonActionResponse {
  type: ActionType.CREATE_GARAGE_REQUEST
  | ActionType.CREATE_GARAGE_SUCCESS
  | ActionType.CREATE_GARAGE_FAILURE;
  result: { data: GarageProps };
}

interface DeleteGarageAction extends CommonActionResponse {
  type: ActionType.DELETE_GARAGE_REQUEST
  | ActionType.DELETE_GARAGE_SUCCESS
  | ActionType.DELETE_GARAGE_FAILURE;
  result: { data: GarageProps };
}

interface UpdateGarageAction extends CommonActionResponse {
  type: ActionType.UPDATE_GARAGE_REQUEST
  | ActionType.UPDATE_GARAGE_SUCCESS
  | ActionType.UPDATE_GARAGE_FAILURE;
  result: { data: GarageProps };
}

export type Action =
  PromiseActionType
  | CreateGarageAction
  | DeleteGarageAction
  | GetLeaseByIdAction
  | ResetLeaseAction
  | UpdateGarageAction;
