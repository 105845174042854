import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, ConcessionProps } from './action-types';

export default {
  createConcession: (leaseId: number, body: ConcessionProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_CONCESSION_REQUEST,
        ActionType.CREATE_CONCESSION_SUCCESS,
        ActionType.CREATE_CONCESSION_FAILURE,
      ],
      promise: client => client.post(build(api.v1.CONCESSIONS, leaseId), body),
    },
  }),

  updateConcession: (leaseId: number, concessionId: number, params: ConcessionProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CONCESSION_REQUEST,
        ActionType.UPDATE_CONCESSION_SUCCESS,
        ActionType.UPDATE_CONCESSION_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.CONCESSION_DETAILS, leaseId, concessionId), params),
    },
  }),

  deleteConcession: (leaseId: number, concessionId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_CONCESSION_REQUEST,
        ActionType.DELETE_CONCESSION_SUCCESS,
        ActionType.DELETE_CONCESSION_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.CONCESSION_DETAILS, leaseId, concessionId)),
    },
  }),
};
