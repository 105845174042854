import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  BULK_COUNTERSIGN_DOCUMENTS_REQUEST = 'BULK_COUNTERSIGN_DOCUMENTS_REQUEST',
  BULK_COUNTERSIGN_DOCUMENTS_SUCCESS = 'BULK_COUNTERSIGN_DOCUMENTS_SUCCESS',
  BULK_COUNTERSIGN_DOCUMENTS_FAILURE = 'BULK_COUNTERSIGN_DOCUMENTS_FAILURE',

  CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST',
  CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE',

  DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST',
  DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE',

  GET_BULK_COUNTERSIGN_DOCUMENTS_REQUEST = 'GET_BULK_COUNTERSIGN_DOCUMENTS_REQUEST',
  GET_BULK_COUNTERSIGN_DOCUMENTS_SUCCESS = 'GET_BULK_COUNTERSIGN_DOCUMENTS_SUCCESS',
  GET_BULK_COUNTERSIGN_DOCUMENTS_FAILURE = 'GET_BULK_COUNTERSIGN_DOCUMENTS_FAILURE',

  GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST',
  GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE',

  GET_ALL_DOCUMENTS_REQUEST = 'GET_ALL_DOCUMENTS_REQUEST',
  GET_ALL_DOCUMENTS_SUCCESS = 'GET_ALL_DOCUMENTS_SUCCESS',
  GET_ALL_DOCUMENTS_FAILURE = 'GET_ALL_DOCUMENTS_FAILURE',

  SEND_DOCUMENT_REQUEST = 'SEND_DOCUMENT_REQUEST',
  SEND_DOCUMENT_SUCCESS = 'SEND_DOCUMENT_SUCCESS',
  SEND_DOCUMENT_FAILURE = 'SEND_DOCUMENT_FAILURE',

  SIGN_DOCUMENT_REQUEST = 'SIGN_DOCUMENT_REQUEST',
  SIGN_DOCUMENT_SUCCESS = 'SIGN_DOCUMENT_SUCCESS',
  SIGN_DOCUMENT_FAILURE = 'SIGN_DOCUMENT_FAILURE',

  UPDATE_APPLICATION_FORM_REQUEST = 'UPDATE_APPLICATION_FORM_REQUEST',
  UPDATE_APPLICATION_FORM_SUCCESS = 'UPDATE_APPLICATION_FORM_SUCCESS',
  UPDATE_APPLICATION_FORM_FAILURE = 'UPDATE_APPLICATION_FORM_FAILURE',

  UPLOAD_DOCUMENTS_REQUEST = 'UPLOAD_DOCUMENTS_REQUEST',
  UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS',
  UPLOAD_DOCUMENTS_FAILURE = 'UPLOAD_DOCUMENTS_FAILURE',

  GENERATE_AGREEMENT_DOCUMENT_REQUEST = 'GENERATE_AGREEMENT_DOCUMENT_REQUEST',
  GENERATE_AGREEMENT_DOCUMENT_SUCCESS = 'GENERATE_AGREEMENT_DOCUMENT_SUCCESS',
  GENERATE_AGREEMENT_DOCUMENT_FAILURE = 'GENERATE_AGREEMENT_DOCUMENT_FAILURE',

  SET_ACTIVE_DOCUMENT = 'SET_ACTIVE_DOCUMENT',
}

export interface DocumentProps {
  id?: number,
  apartment?: string;
  application_document?: string,
  created?: string,
  file?: string,
  html?: string,
  isLink?: boolean,
  lease_document_type?: string,
  lease_end_date?: string,
  lease_start_date?: string,
  lease?: number,
  manually_uploaded?: boolean,
  name?: string,
  signature_status?: SignatureStatusProps[],
  status?: string,
  type?: string,
  updated?: string,
  users?: number[],
}

export interface BulkDocuments {
  count: number,
  documents: DocumentProps[],
}

export interface DocumentState extends CommonStateType {
  bulkDocuments?: BulkDocuments,
  documents: DocumentProps[],
  activeDocument: DocumentProps,
}

export interface SignatureStatusProps {
  agent_name?: string,
  agent?: number,
  signed_date?: number,
  status?: string,
  user_name?: string,
  user_type?: string,
  user?: number,
}

/*
 * Actions
 */

interface CreateDocumentAction extends CommonActionResponse {
  type: ActionType.CREATE_DOCUMENT_REQUEST
  | ActionType.CREATE_DOCUMENT_SUCCESS
  | ActionType.CREATE_DOCUMENT_FAILURE;
  result: { data: DocumentProps };
}

interface GetBulkCountersignDocumentsAction extends CommonActionResponse {
  type: ActionType.GET_BULK_COUNTERSIGN_DOCUMENTS_REQUEST
  | ActionType.GET_BULK_COUNTERSIGN_DOCUMENTS_SUCCESS
  | ActionType.GET_BULK_COUNTERSIGN_DOCUMENTS_FAILURE;
  result: { data: { count: number, results: DocumentProps[] } };
}

interface GetDocumentsAction extends CommonActionResponse {
  type: ActionType.GET_DOCUMENTS_REQUEST
  | ActionType.GET_DOCUMENTS_SUCCESS
  | ActionType.GET_DOCUMENTS_FAILURE;
  result: { data: { results: DocumentProps[] } };
}

interface GetAllDocumentsAction extends CommonActionResponse {
  type: ActionType.GET_ALL_DOCUMENTS_REQUEST
  | ActionType.GET_ALL_DOCUMENTS_SUCCESS
  | ActionType.GET_ALL_DOCUMENTS_FAILURE;
  result: { data: { results: DocumentProps[] } };
}

interface UploadDocumentsAction extends CommonActionResponse {
  type: ActionType.UPLOAD_DOCUMENTS_REQUEST
  | ActionType.UPLOAD_DOCUMENTS_SUCCESS
  | ActionType.UPLOAD_DOCUMENTS_FAILURE;
  result: { data: DocumentProps[] };
}

interface SetActiveDocumentActions {
  type: ActionType.SET_ACTIVE_DOCUMENT,
  value: DocumentProps,
}

export type Action =
  PromiseActionType
  | CreateDocumentAction
  | GetBulkCountersignDocumentsAction
  | GetDocumentsAction
  | GetAllDocumentsAction
  | ResetLeaseAction
  | UploadDocumentsAction
  | SetActiveDocumentActions;
