import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  CREATE_IDLE_EVENT_REQUEST = 'CREATE_IDLE_EVENT_REQUEST',
  CREATE_IDLE_EVENT_SUCCESS = 'CREATE_IDLE_EVENT_SUCCESS',
  CREATE_IDLE_EVENT_FAILURE = 'CREATE_IDLE_EVENT_FAILURE',

  UPDATE_IDLE_EVENT_REQUEST = 'UPDATE_IDLE_EVENT_REQUEST',
  UPDATE_IDLE_EVENT_SUCCESS = 'UPDATE_IDLE_EVENT_SUCCESS',
  UPDATE_IDLE_EVENT_FAILURE = 'UPDATE_IDLE_EVENT_FAILURE',

  UPDATE_USER_ACTIVITY_REQUEST = 'UPDATE_USER_ACTIVITY_REQUEST',
  UPDATE_USER_ACTIVITY_SUCCESS = 'UPDATE_USER_ACTIVITY_SUCCESS',
  UPDATE_USER_ACTIVITY_FAILURE = 'UPDATE_USER_ACTIVITY_FAILURE',
}

export interface IdleTimeState {
  current: number,
  start: number,
}

export interface CreateIdleTimeEvent {
  type: ActionType.CREATE_IDLE_EVENT_REQUEST
  | ActionType.CREATE_IDLE_EVENT_SUCCESS
  | ActionType.CREATE_IDLE_EVENT_FAILURE,
  payload: { start: number },
  result: {
    data: {
      id: number,
    },
  },
}

export interface UpdateIdleTimeEvent {
  type: ActionType.UPDATE_IDLE_EVENT_REQUEST
  | ActionType.UPDATE_IDLE_EVENT_SUCCESS
  | ActionType.UPDATE_IDLE_EVENT_FAILURE,
}

export type Action =
  PromiseActionType
  | CreateIdleTimeEvent
  | UpdateIdleTimeEvent
