import produce from 'immer';
import { PropertyProps } from '../property/action-types';
import { Action, ActionType } from './action-types';

export interface PropertiesSelectorState {
  checkedAllProperties: number;
  showApplyButton: boolean;
  propertiesCount: number;
  showSelector: boolean;
  preSelectedProperties: PropertyProps[];
  postSelectedProperties: PropertyProps[];
  availableProperties: PropertyProps[];
  propertyKeyword: string;
}

export const initialState: PropertiesSelectorState = {
  checkedAllProperties: 0,
  showApplyButton: false,
  propertiesCount: 0,
  showSelector: false,
  preSelectedProperties: [],
  postSelectedProperties: [],
  availableProperties: [],
  propertyKeyword: '',
};

export const selectCheckedAllProperties = (state: { propertiesSelector: PropertiesSelectorState }): number => state.propertiesSelector.checkedAllProperties;
export const selectShowApplyButton = (state: { propertiesSelector: PropertiesSelectorState }): boolean => state.propertiesSelector.showApplyButton;
export const selectPropertiesCount = (state: { propertiesSelector: PropertiesSelectorState }): number => state.propertiesSelector.propertiesCount;
export const selectShowSelector = (state: { propertiesSelector: PropertiesSelectorState }): boolean => state.propertiesSelector.showSelector;
export const selectPreSelectedProperties = (state: { propertiesSelector: PropertiesSelectorState }): PropertyProps[] => state.propertiesSelector.preSelectedProperties;
export const selectPostSelectedProperties = (state: { propertiesSelector: PropertiesSelectorState }): PropertyProps[] => state.propertiesSelector.postSelectedProperties;
export const selectAvailableProperties = (state: { propertiesSelector: PropertiesSelectorState }): PropertyProps[] => state.propertiesSelector.availableProperties;
export const selectPropertyKeyword = (state: { propertiesSelector: PropertiesSelectorState }): string => state.propertiesSelector.propertyKeyword;

export default produce((state: PropertiesSelectorState = initialState, action: Action): PropertiesSelectorState => {
  switch (action.type) {
    case ActionType.SET_CHECK_ALL_PROPERTIES:
      state.checkedAllProperties = action.checked;
      break;

    case ActionType.SET_SHOW_APPLY_BUTTON:
      state.showApplyButton = action.showApply;
      break;

    case ActionType.SET_PS_PROPERTIES_COUNT:
      state.propertiesCount = action.count;
      break;

    case ActionType.SET_SHOW_SELECTOR:
      state.showSelector = action.show;
      break;

    case ActionType.SET_PRESELECTED_PROPERTIES:
      state.preSelectedProperties = action.properties;
      break;

    case ActionType.SET_POSTSELECTED_PROPERTIES:
      state.postSelectedProperties = action.properties;
      break;

    case ActionType.SET_AVAILABLE_PROPERTIES:
      state.availableProperties = action.properties;
      break;

    case ActionType.SET_PROPERTY_KEYWORD:
      state.propertyKeyword = action.keyword;
      break;
  }

  return state;
});
