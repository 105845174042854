import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { CircleDivider } from 'styles/common';
import { currencyFormat } from 'compete/constants';
import actions from 'dwell/actions';
import { FloorPlanTypes, PageSection } from 'dwell/store/property_profile/action-types';
import { formatAvailableDate } from './utils';
import { CardHeader, CardUnits, LinkUnitView, ViewAllUnits } from './styles';
import { Table } from '../styles';

interface UnitCardProps {
  grouped: { [unit_type: string]: FloorPlanTypes[] },
  activeUnit: string,
  availabilityFilter?: string,
}

const UnitCard: FC<UnitCardProps> = ({ grouped, activeUnit, availabilityFilter = 'Any' }) => {
  const dispatch = useDispatch();
  const { setSection, setActiveUnit, setExpandedUnits } = actions.propertyProfile;
  const expandedUnits = useSelector(state => state.propertyProfile.expandedUnits);

  const unitIds = useSelector(state => state.propertyProfile.floorPlans?.reduce((a, b) => a.concat(b.units || []), []).map(i => i.id));

  const goToDetails = (unit) => {
    if (!unitIds.includes(unit.id)) return;
    dispatch(setSection(PageSection.UNITS_BY_FLOOR_PLAN));
    dispatch(setActiveUnit(unit));
  };

  const handleToggleShowMore = (planId) => {
    dispatch(setExpandedUnits(({
      ...expandedUnits,
      [planId]: !expandedUnits[planId],
    })));
  };

  const elements = Object.keys(grouped).map((bedrooms) => {
    const plans = grouped[bedrooms];
    const bed = bedrooms === '0' ? 'Studio' : `${bedrooms} Bed`;
    return plans.map((plan, ind) => {
      let { units } = plan;
      if (availabilityFilter === 'Now') {
        units = units.filter(unit => !moment(unit.available_date).isAfter(moment()));
      }
      const isExpanded = expandedUnits[plan.plan] || false;
      return (
        <CardUnits data-testid={`unit-type-${plan.plan}`} key={ind}>
          <CardHeader>
            <div>
              <h6>{plan.plan} | {bed} ({units.length})</h6>
              <div className="d-flex align-items-center">
                {bed} <CircleDivider /> {plan.bathrooms} Bath <CircleDivider /> {plan.square_footage} SqFt
              </div>
            </div>
            {(plan.images || [])[0] && <img src={(plan.images || [])[0]} alt="plan" />}
          </CardHeader>
          <Table>
            <thead>
              <tr>
                <th>Unit</th>
                <th>Rent</th>
                <th>Available</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {units.map((unit, index) => (
                <tr
                  id={`unit-row-${unit.unit}`}
                  data-testid={`unit-row-${unit.unit}`}
                  className={cn({
                    active: unit.unit === activeUnit,
                    'd-none': index >= 5 && !isExpanded,
                  })}
                  onClick={() => goToDetails({ ...unit, plan })}
                  key={index}
                >
                  <th>{unit.unit}</th>
                  <td><span className={cn({ highlight: plan.promotion })}>${currencyFormat(unit.market_rent)}</span></td>
                  <td className="available-date">{formatAvailableDate(unit.available_date)}</td>
                  <td><LinkUnitView><i className="ri-arrow-right-circle-line" /></LinkUnitView></td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className={cn('text-center', { 'd-none': units.length <= 5 })}>
                  <ViewAllUnits onClick={() => handleToggleShowMore(plan.plan)}>
                    {isExpanded ? 'Show Less' : 'View All'}
                  </ViewAllUnits>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardUnits>
      );
    });
  });
  return <div>{elements}</div>;
};

export default UnitCard;
