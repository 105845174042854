import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import actions from 'dwell/actions';
import { PageSection } from 'dwell/store/property_profile/action-types';
import { PanelFrame, PanelHeader, CloseButton, PanelBody, PanelFooter, PanelActionButton, TextWScroll, BackgroundPanel } from 'dwell/views/chat/single_chat/styles';
import { Avatar } from 'styles/common';
import { ButtonLikeAnchor2 } from 'dwell/views/chat/single_chat/window/styles';
import { unitTypes } from 'site/constants';

interface HobbesAssitPanelProps {
  messages: string[];
  openHobbesPanel: boolean;
  setOpenHobbesPanel: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessage: (message: string, type?: string) => void;
  hobbesTagAndUnitType: { tag: string; unitType: string };
}

const HobbesAssitPanel: FC<HobbesAssitPanelProps> = ({ messages, openHobbesPanel, setOpenHobbesPanel, sendMessage, hobbesTagAndUnitType }) => {
  const { openProfile, setSection, setUnitType } = actions.propertyProfile;
  const dispatch = useDispatch();

  const joinedMessage = messages.join('\n\n');

  const copyToClipBoardHandler = () => {
    let textToCopy = '';
    if (messages.length > 1) {
      textToCopy = joinedMessage;
    } else {
      // eslint-disable-next-line prefer-destructuring
      textToCopy = messages[0];
    }
    navigator.clipboard.writeText(textToCopy);
    setOpenHobbesPanel(false);
  };

  const handleSendMessageClick = () => {
    sendMessage(joinedMessage, 'AGENT');
    setOpenHobbesPanel(false);
  };

  const linkClickHandler = () => {
    dispatch(openProfile(true));
    dispatch(setSection(PageSection.AVAILABLE_UNITS));
    // Get the unit type from hobbesTagAndUnitType
    if (hobbesTagAndUnitType.unitType) {
      const unitType = unitTypes.find(type => type.value === hobbesTagAndUnitType.unitType);
      if (unitType) {
        dispatch(setUnitType(unitType.bedrooms.toString()));
      }
    }
  };

  const getContent = () => (
    <TextWScroll>
      <p dangerouslySetInnerHTML={{ __html: joinedMessage.replace(/\n/gi, '<br/>') }} />
      {(hobbesTagAndUnitType.tag === 'show_floor_plans' || hobbesTagAndUnitType.unitType) && (
        <>
          <br />
          <ButtonLikeAnchor2 target="_blank" onClick={linkClickHandler} id="show_floor_plans">
            View unit availability and pricing
          </ButtonLikeAnchor2>
        </>
      )}
    </TextWScroll>
  );

  return (
    <React.Fragment>
      {openHobbesPanel && <BackgroundPanel onClick={() => setOpenHobbesPanel(false)} />}
      <PanelFrame show={openHobbesPanel}>
        <PanelHeader>
          <Avatar className="avatar">
            <i className="ri-rocket-fill" />
          </Avatar>
          <span>Hobbes</span>
          <CloseButton onClick={() => setOpenHobbesPanel(false)}>
            <i className="ri-close-line" />
          </CloseButton>
        </PanelHeader>
        <PanelBody>{getContent()}</PanelBody>
        <PanelFooter>
          <PanelActionButton onClick={() => copyToClipBoardHandler()}>
            <i className="ri-file-copy-fill" />
            Copy
          </PanelActionButton>
          <PanelActionButton onClick={() => handleSendMessageClick()}>
            <i className="ri-send-plane-fill" />
            Submit
          </PanelActionButton>
        </PanelFooter>
      </PanelFrame>
    </React.Fragment>
  );
};

export default HobbesAssitPanel;
