export default {
  BASE: '/',
  LOGIN: '/login',
  CALLBACK: '/callback',
  PASSWORD_RESET: '/password-reset/:token',
  SEND_PASSWORD_RESET_EMAIL: '/password-reset-email',
  LEADS: {
    VIEW: '/properties/leads',
    DETAIL: '/:propertyHyphens/leads/:id',
    OVERVIEW: '/:propertyHyphens/leads/:id/overview',
    NOTES: '/:propertyHyphens/leads/:id/notes',
    CALLS: '/:propertyHyphens/leads/:id/calls',
    EMAILS: '/:propertyHyphens/leads/:id/emails',
    CHATS: '/:propertyHyphens/leads/:id/chats',
    SMS: '/:propertyHyphens/leads/:id/sms',
    DOCUMENTS: '/:propertyHyphens/leads/:id/documents',
    APPLICANTS: '/:propertyHyphens/leads/:id/applicants',
    APPLICANT_DETAIL: '/:propertyHyphens/leads/:id/applicants/:applicantId',
    GUARANTORS: '/:propertyHyphens/leads/:id/guarantors',
    GUARANTOR_DETAIL: '/:propertyHyphens/leads/:id/guarantors/:guarantorId',
    LEASE_AGREEMENT: '/:propertyHyphens/leads/:id/lease_agreement',
    DEPOSIT_AGREEMENT: '/:propertyHyphens/leads/:id/deposit_agreement',
  },
  LEASES: {
    DETAIL: '/:propertyHyphens/leases/:id',
    OVERVIEW: '/:propertyHyphens/leases/:id/overview',
    REFRESH_PROFILE: '/:propertyHyphens/leases/:id/overview/refresh-profile',
    REFRESH_PROFILE_PROGRESS: '/:propertyHyphens/leases/:id/refresh-profile-progress',
    RENEW: '/:propertyHyphens/leases/:id/renew',
    DOCUMENTS: '/:propertyHyphens/leases/:id/documents',
    ACTIVITY: '/:propertyHyphens/leases/:id/activity',
    DETAILS: '/:propertyHyphens/leases/:id/overview/details',
    RESIDENT_DETAILS: '/:propertyHyphens/leases/:id/overview/:userId',
    ADD_PEOPLE: '/:propertyHyphens/leases/:id/overview/add-people',
    ADD_PEOPLE_PROGRESS: '/:propertyHyphens/leases/:id/add-people-progress',
    EDIT_PEOPLE: '/:propertyHyphens/leases/:id/overview/:userId/edit-people',
    REMOVE_PEOPLE: '/:propertyHyphens/leases/:id/overview/remove-people',
    REMOVE_PEOPLE_PROGRESS: '/:propertyHyphens/leases/:id/remove-people-progress',
    LEASE_CHANGE: '/:propertyHyphens/leases/:id/overview/change',
    SCREEN_GROUP: '/:propertyHyphens/leases/:id/overview/screen-group',
    RESCREEN_GROUP: '/:propertyHyphens/leases/:id/overview/rescreen-group',
    PREVIEW_AND_SEND: '/:propertyHyphens/leases/:id/overview/preview-and-send',
    COUNTERSIGN: '/:propertyHyphens/leases/:id/overview/countersign',
    NOTICE: '/:propertyHyphens/leases/:id/notice',
  },
  APPLICATIONS: {
    DETAIL: '/:propertyHyphens/applications/:id',
    OVERVIEW: '/:propertyHyphens/applications/:id/overview',
    APPLICANT_DETAILS: '/:propertyHyphens/applications/:id/overview/:userId',
    EDIT_PEOPLE: '/:propertyHyphens/applications/:id/overview/:userId/edit-people',
    SCREEN_GROUP: '/:propertyHyphens/applications/:id/overview/screen-group',
    RESCREEN_GROUP: '/:propertyHyphens/applications/:id/overview/rescreen-group',
    ADD_PEOPLE: '/:propertyHyphens/applications/:id/overview/add-people',
    EDIT_INVITE_DETAILS: '/:propertyHyphens/applications/:id/overview/:userId/edit-invite-details',
    REMOVE_PEOPLE: '/:propertyHyphens/applications/:id/overview/remove-people',
    TRANSFER_PEOPLE: '/:propertyHyphens/applications/:id/overview/transfer-people',
    RESTORE_PEOPLE: '/:propertyHyphens/applications/:id/overview/restore-people',
    REFRESH_PROFILE: '/:propertyHyphens/applications/:id/overview/refresh-profile',
    REFRESH_PROFILE_PROGRESS: '/:propertyHyphens/applications/:id/refresh-profile-progress',
    LEASE: '/:propertyHyphens/applications/:id/lease',
    LEASE_DETAILS: '/:propertyHyphens/applications/:id/lease/details',
    LEASE_CHANGE: '/:propertyHyphens/applications/:id/lease/change',
    TRANSFER_UNIT: '/:propertyHyphens/applications/:id/lease/transfer-unit',
    RELEASE_UNIT: '/:propertyHyphens/applications/:id/lease/release-unit',
    DOCUMENTS: '/:propertyHyphens/applications/:id/documents',
    ACTIVITY: '/:propertyHyphens/applications/:id/activity',
  },
  PIPELINE: '/properties/pipeline',
  BULK_SIGN: '/:propertyHyphens/bulk_sign',
  TASKS: '/properties/tasks',
  SETTINGS: {
    VIEW: '/properties/settings',
    ASSIGNMENT_RULES: '/properties/settings/assignment_rules',

    LIST_TEMPLATE: '/properties/settings/template/list',

    CHAT_LIST_TEMPLATE: '/properties/settings/chat_template/list',

    EMAIL_SYNC: '/properties/settings/email_sync',
    PAID_SOURCES: '/properties/settings/paid_sources',
    COMPETITORS: '/properties/settings/competitors',
    BUSINESS_HOURS: '/properties/settings/business_hours',
    TOUR_OPTIONS: '/properties/settings/tour_options',
    LEASE_DEFAULTS: '/properties/settings/lease_defaults',
    PROPERTY_POLICIES: '/properties/settings/property_polices',
    UTILITY_POLICIES: '/properties/settings/utility_policies',
    OTHER_RENT_ITEMS: '/properties/settings/other_rent_items',
    OTHER_FEE_ITEMS: '/properties/settings/other_fee_items',
    CLEANING_CHARGES: '/properties/settings/cleaning_charges',
    RENTABLE_ITEMS: '/properties/settings/rentable_items',
    DURATION_PRICING: '/properties/settings/duration_pricing',
    ADDENDA: '/properties/settings/addenda',
    ADDENDA_FIELDS: '/properties/settings/addenda_fields',
    TARGET_NUMBERS: '/properties/settings/target_numbers',
    CALL_BLOCK_LIST: '/properties/settings/call_block_list',
    CALL_SCHEDULE: '/properties/settings/call_schedule',
    DETAILS: '/properties/settings/property_details',

    LIST_PAID_SOURCE_BUDGET: '/properties/settings/paid_source_budget/list',

    LIST_RENT_SURVEY: '/properties/settings/rent_survey/list',
    CALL_ROUTING: '/properties/settings/call_routing',
    INSIDER_INFO: '/properties/settings/insider_info',
  },
  PROFILE: '/profile',
  NYLAS_INTEGRATION: '/nylas_integrate',
  FOLLOWUPS: {
    VIEW: '/:propertyHyphens/followups',
    DETAILS: '/:propertyHyphens/followups/:id',
  },
  BULK_EMAIL: '/:propertyHyphens/bulk_email',
  REPORTS: {
    BASIC: '/:propertyHyphens/reports',
  },
  CALLS: '/:propertyHyphens/calls',
  RESCORE_CALLS: '/call-rescores/calls',
  CHATS: {
    OVERVIEW: '/properties/chats',
    EVALUATION: '/:propertyHyphens/chats/evaluate/:id',
    REPORT: '/:propertyHyphens/chats/report/:id',
  },
  MULTI_CHAT: '/:propertyHyphens/multi_chat',
  SITE_CONTENT: {
    HOME: '/:propertyHyphens/site-contents',
    GALLERY: '/:propertyHyphens/site-gallery',
    FLOOR_PLANS: '/:propertyHyphens/site-floor',
    AMENITIES: '/:propertyHyphens/site-amenities',
    NEIGHBORHOOD: '/:propertyHyphens/site-neighborhood',
    VIRTUAL_TOUR: '/:propertyHyphens/site-virtual',
    CONTACT: '/:propertyHyphens/site-contact',
    FOOTER: '/:propertyHyphens/site-footer',
    DESIGN: '/:propertyHyphens/site-design',
    MISC: '/:propertyHyphens/site-misc',
    PROMOTIONS: '/:propertyHyphens/site-promotions',
  },

  MANAGE_USERS: '/users',
  MANAGE_CUSTOMERS: '/customers',
  MANAGE_CLIENTS: '/clients',
  MANAGE_PROPERTIES: '/properties',
  APARTMENT_AVAILABILITY: '/:propertyHyphens/apartment-availability',
  NURTURE_AUTOMATIONS: {
    VIEW: '/:propertyHyphens/automation',
    OVERVIEW: '/:propertyHyphens/automation/:id/overview',
    INSIGHTS: '/:propertyHyphens/automation/:id/insights',
    SETTINGS: '/:propertyHyphens/automation/:id/settings',
  },
  HOBBES_SETTINGS: '/properties/hobbes',

  COMPETE: {
    HOME: '/:propertyHyphens/compete',
    PROPERTY_REPORT: '/:propertyHyphens/compete/property-report/:propertyId',
    SUBMARKET_REPORT: '/:propertyHyphens/compete/submarket-report/:submarketId',
    MARKET_REPORT: '/:propertyHyphens/compete/market-report/:marketId',
    COMPARISON_REPORT: '/:propertyHyphens/compete/comparison',
    COMPARISON_REPORT_ID: '/:propertyHyphens/compete/comparison/report/:comparisonId',
    ALERTS_SUBSCRIPTIONS: '/:propertyHyphens/compete/alerts',
    ALERTS_SUMMARY: '/:propertyHyphens/compete/alerts/:summaryId',
    ALERTS_LOG: '/:propertyHyphens/compete/alerts/:summaryId/:logId',
  },

  SUPPORT_PORTAL: {
    VIEW: '/support-portal',
  },

  CREDIT_BUILDER: {
    VIEW: '/:propertyHyphens/credit-builder/leases',
    OVERVIEW: '/:propertyHyphens/credit-builder/leases/:id/overview',
  },

  CORPORATE: '/corporate',
};
