import produce from 'immer';
import moment from 'moment';
import { Action, ActionType, NurtureList, Nurture, NurtureInsights } from './action-types';
import { CommonStateType } from '../types';

export interface NurtureState extends CommonStateType {
  nurtureList: NurtureList[],
  nurture: Nurture,
  isNurtureLoaded: boolean,

  totalCount: number,
  totalCountAll: number,
  totalCountActive: number,
  totalCountInactive: number,
  totalCountDraft: number,
  isFormChanged: boolean,

  // insights
  isInsightsLoaded: boolean,
  nurtureInsights: NurtureInsights,
  customStartDate: moment.Moment,
  customEndDate: moment.Moment,
  datePeriod: string,
}

export const initialState: NurtureState = {
  isSubmitting: false,
  errorMessage: null,
  isLoaded: false,
  isNurtureLoaded: false,
  nurture: {} as Nurture,

  nurtureList: [],
  totalCount: 0,
  totalCountAll: 0,
  totalCountActive: 0,
  totalCountInactive: 0,
  totalCountDraft: 0,
  isFormChanged: false,

  // insights
  isInsightsLoaded: false,
  nurtureInsights: {} as NurtureInsights,
  customStartDate: null,
  customEndDate: null,
  datePeriod: 'THIS_WEEK',
};

export const selectIsNurtureListLoaded = (state: { nurture: NurtureState }): boolean => state.nurture.isLoaded;
export const selectNurtureList = (state: { nurture: NurtureState }): NurtureList[] => state.nurture.nurtureList;
export const selectNurtureTotalCount = (state: { nurture: NurtureState }): number => state.nurture.totalCount;
export const selectNurtureCountAll = (state: { nurture: NurtureState }): number => state.nurture.totalCountAll;
export const selectNurtureCountActive = (state: { nurture: NurtureState }): number => state.nurture.totalCountActive;
export const selectNurtureCountInactive = (state: { nurture: NurtureState }): number => state.nurture.totalCountInactive;
export const selectNurtureCountDraft = (state: { nurture: NurtureState }): number => state.nurture.totalCountDraft;
export const selectNurtureIsSubmitting = (state: { nurture: NurtureState }): boolean => state.nurture.isSubmitting;
export const selectIsFormChanged = (state: { nurture: NurtureState }): boolean => state.nurture.isFormChanged;
export const selectIsNurtureLoaded = (state: { nurture: NurtureState }): boolean => state.nurture.isNurtureLoaded;
export const selectNurture = (state: { nurture: NurtureState }): Nurture => state.nurture.nurture;
export const selectIsInsightsLoaded = (state: { nurture: NurtureState }): boolean => state.nurture.isInsightsLoaded;
export const selectNurtureInsights = (state: { nurture: NurtureState }): NurtureInsights => state.nurture.nurtureInsights;
export const selectCustomStartDate = (state: { nurture: NurtureState }): moment.Moment => state.nurture.customStartDate;
export const selectCustomEndDate = (state: { nurture: NurtureState }): moment.Moment => state.nurture.customEndDate;
export const selectDatePeriod = (state: { nurture: NurtureState }): string => state.nurture.datePeriod;

export default produce((state: NurtureState = initialState, action: Action): NurtureState => {
  switch (action.type) {
    case ActionType.GET_NURTURE_AUTOMATIONS_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_NURTURE_AUTOMATIONS_SUCCESS:
      state.isLoaded = true;
      state.nurtureList = action.result.data.results;
      state.totalCount = action.result.data.count;
      state.totalCountAll = action.result.data.count_all;
      state.totalCountActive = action.result.data.count_active;
      state.totalCountInactive = action.result.data.count_inactive;
      state.totalCountDraft = action.result.data.count_draft;
      break;
    case ActionType.GET_NURTURE_AUTOMATIONS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = false;
      break;

    case ActionType.GET_NURTURE_DETAILS_REQUEST:
      state.isNurtureLoaded = false;
      break;
    case ActionType.GET_NURTURE_DETAILS_SUCCESS:
      state.isNurtureLoaded = true;
      state.nurture = action.result.data;
      break;
    case ActionType.GET_NURTURE_DETAILS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isNurtureLoaded = false;
      break;

    case ActionType.UPDATE_NURTURE_AUTOMATIONS_SUCCESS:
      state.isSubmitting = false;
      state.nurture = action.result.data;
      break;

    case ActionType.CREATE_NURTURE_AUTOMATIONS_REQUEST:
    case ActionType.UPDATE_NURTURE_AUTOMATIONS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_NURTURE_AUTOMATIONS_SUCCESS:
    case ActionType.CREATE_NURTURE_AUTOMATIONS_FAILURE:
    case ActionType.UPDATE_NURTURE_AUTOMATIONS_FAILURE:
      state.isSubmitting = false;
      break;

    case ActionType.SET_NURTURE_FORM_CHANGED:
      state.isFormChanged = action.changed;
      break;

    case ActionType.SET_INSIGHTS_CUSTOM_DATE:
      state.customStartDate = action.startDate;
      state.customEndDate = action.endDate;
      break;

    case ActionType.SET_INSIGHTS_DATE_PERIOD:
      state.datePeriod = action.datePeriod;
      break;

    case ActionType.GET_NURTURE_INSIGHTS_REQUEST:
      state.isInsightsLoaded = false;
      break;
    case ActionType.GET_NURTURE_INSIGHTS_SUCCESS:
      state.isInsightsLoaded = true;
      state.nurtureInsights = action.result.data;
      break;
    case ActionType.GET_NURTURE_INSIGHTS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isInsightsLoaded = false;
      break;
  }

  return state;
});
