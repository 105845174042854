import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_BUSINESS_HOURS_REQUEST = 'GET_BUSINESS_HOURS_REQUEST',
  GET_BUSINESS_HOURS_SUCCESS = 'GET_BUSINESS_HOURS_SUCCESS',
  GET_BUSINESS_HOURS_FAILURE = 'GET_BUSINESS_HOURS_FAILURE',

  CREATE_BUSINESS_HOURS_REQUEST = 'CREATE_BUSINESS_HOURS_REQUEST',
  CREATE_BUSINESS_HOURS_SUCCESS = 'CREATE_BUSINESS_HOURS_SUCCESS',
  CREATE_BUSINESS_HOURS_FAILURE = 'CREATE_BUSINESS_HOURS_FAILURE',

  UPDATE_BUSINESS_HOURS_REQUEST = 'UPDATE_BUSINESS_HOURS_REQUEST',
  UPDATE_BUSINESS_HOURS_SUCCESS = 'UPDATE_BUSINESS_HOURS_SUCCESS',
  UPDATE_BUSINESS_HOURS_FAILURE = 'UPDATE_BUSINESS_HOURS_FAILURE',

  GET_BUSINESS_HOURS_ALL_REQUEST = 'GET_BUSINESS_HOURS_ALL_REQUEST',
  GET_BUSINESS_HOURS_ALL_SUCCESS = 'GET_BUSINESS_HOURS_ALL_SUCCESS',
  GET_BUSINESS_HOURS_ALL_FAILURE = 'GET_BUSINESS_HOURS_ALL_FAILURE',
}

export interface BusinessHoursProps {
  weekday: number,
  start_time: string,
  end_time: string,
  is_workday: boolean,
  tour_types_enabled: string[],
  property?: number,
}

export interface AllBusinessHoursProps {
  [propertyId: number]: BusinessHoursProps[];
}

interface GetBusinessHoursAction {
  type: ActionType.GET_BUSINESS_HOURS_REQUEST
  | ActionType.GET_BUSINESS_HOURS_SUCCESS
  | ActionType.GET_BUSINESS_HOURS_FAILURE;

  result: {
    data: { results: BusinessHoursProps[] }
  };
  error: {
    response: { status: string }
  };
}

interface GetBusinessHoursAllAction {
  type: ActionType.GET_BUSINESS_HOURS_ALL_REQUEST
  | ActionType.GET_BUSINESS_HOURS_ALL_SUCCESS
  | ActionType.GET_BUSINESS_HOURS_ALL_FAILURE;

  result: {
    data: { results: AllBusinessHoursProps }
  };

  error: {
    response: { status: string }
  };
}

interface CreateBusinessHoursAction {
  type: ActionType.CREATE_BUSINESS_HOURS_REQUEST
  | ActionType.CREATE_BUSINESS_HOURS_SUCCESS
  | ActionType.CREATE_BUSINESS_HOURS_FAILURE;

  result: {
    data: BusinessHoursProps[]
  };
  error: {
    response: { status: string }
  };
}

interface UpdateBusinessHoursAction {
  type: ActionType.UPDATE_BUSINESS_HOURS_REQUEST
  | ActionType.UPDATE_BUSINESS_HOURS_SUCCESS
  | ActionType.UPDATE_BUSINESS_HOURS_FAILURE;

  result: {
    data: BusinessHoursProps[]
  };
  error: {
    response: { status: string }
  };
}

export type Action =
  | PromiseActionType
  | GetBusinessHoursAction
  | CreateBusinessHoursAction
  | UpdateBusinessHoursAction
  | GetBusinessHoursAllAction;
