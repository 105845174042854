import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from 'dwell/actions';
import { Conversation, ConversationType, FormStructure, AgentQuestion } from 'dwell/store/agent_question/action-types';
import { selectConversations } from 'dwell/store/agent_question/reducers';
import { ChatSpinner, SpinnerBorder } from 'dwell/views/chat/single_chat/contact/styles';
import { AgentResponse, AgentResponseBody, BotTyping, AgentQuestionItemBody, MiniLoader2, ResponseTypingBot } from 'dwell/views/chat/single_chat/window/styles';
import { SimpleMessage, QuestionMessage, ConfirmationMessage } from './common';
import ConfirmationForm from './confirmation_form';
import AnswerForm from './answer_form';
import { getFormStructures } from './utils';

interface ChatBodyProps {
  isForcedQuestion: boolean;
}

const ChatBody: FC<ChatBodyProps> = ({ isForcedQuestion }) => {
  const [oldScrollPosition, setOldScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [displayedConversations, setDisplayedConversations] = useState<Conversation[]>([]);
  const [isTyping, setIsTyping] = useState(false);

  const dispatch = useDispatch();
  const chatConversations: Conversation[] = useSelector(selectConversations);
  const agentQuestion = useSelector(state => state.agentQuestion);
  const { totalConversationCount: totalCount, isFormDisplayed, isConfirmationFormDisplayed, currentIdx, conversationDiff } = agentQuestion;
  const { getConversations, setGlobalForm, getQuestionOptions, setIsFormDisplayed, setIsConfirmationFormDisplayed, resetConversationDiff } = actions.agentQuestion;

  useEffect(() => {
    if (!isEmpty(chatConversations)) {
      if (conversationDiff > 0) {
        const lastNConversations = chatConversations.slice(-conversationDiff);
        const lastConversation = lastNConversations[lastNConversations.length - 1];
        let counter = 0;
        lastNConversations.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).forEach((conversation) => {
          if (conversation.type === ConversationType.AGENT) {
            setDisplayedConversations(prevConversations => [...prevConversations, conversation]);
          } else {
            setTimeout(() => {
              setIsTyping(true);
            }, (1000));
            setTimeout(() => {
              if (lastConversation.id === conversation.id) setIsTyping(false);
              setDisplayedConversations(prevConversations => [...prevConversations, conversation]);
            }, 2000 + (counter * 1000));
            counter += 1;
          }
        });
      } else {
        setDisplayedConversations(chatConversations);
      }
    }
  }, [chatConversations, conversationDiff]);

  const lastConversation = useMemo(() => (!isEmpty(displayedConversations) ? displayedConversations[displayedConversations.length - 1] : null), [displayedConversations]);

  const question = useMemo(() => {
    if (!isEmpty(lastConversation) && [ConversationType.BOT_QUESTION, ConversationType.BOT_CONFIRMATION].includes(lastConversation.type)) {
      return lastConversation.question as AgentQuestion;
    }
    return null;
  }, [lastConversation]);

  useEffect(() => {
    if (question && question.option_conditional_question_answer_expected_type) {
      dispatch(getQuestionOptions({ question: question.id }));
    }
  }, [question]);

  useEffect(() => {
    if (!isEmpty(question) && !isEmpty(lastConversation)) {
      dispatch(resetConversationDiff());
      if (lastConversation.type === ConversationType.BOT_QUESTION) {
        dispatch(setIsFormDisplayed(true));
      } else if (lastConversation.type === ConversationType.BOT_CONFIRMATION) {
        dispatch(setIsConfirmationFormDisplayed(true));
      }
    }
  }, [question, lastConversation]);

  const chatWindow: { current: { _container: HTMLDivElement } } | undefined = useRef();
  const scrollToLastMessage = () => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      _container.scrollTop = _container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToLastMessage();
  }, []);

  useEffect(() => {
    if (isFormDisplayed || isConfirmationFormDisplayed) {
      scrollToLastMessage();
    }
  }, [isFormDisplayed, isConfirmationFormDisplayed]);

  useEffect(() => {
    if (question) {
      const formsToDisplay: FormStructure[] = getFormStructures(question);
      dispatch(setGlobalForm(formsToDisplay));
      setTimeout(() => scrollToLastMessage(), 500);
    }
  }, [question]);

  useEffect(() => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      const newScroll = _container.scrollHeight - _container.clientHeight;
      _container.scrollTop += newScroll - oldScrollPosition;
    }
  }, [displayedConversations, totalCount, currentIdx, isTyping]);

  const onScroll = ({ target }) => {
    if (totalCount === displayedConversations.length) return;
    if (target && target.scrollTop === 0 && !isLoading && totalCount) {
      setIsLoading(true);
      setOldScrollPosition(target.scrollHeight - target.clientHeight);
      setTimeout(() => {
        dispatch(getConversations({ params: { offset: displayedConversations.length, limit: 20 } })).then(() => {
          setIsLoading(false);
        });
      }, 1000);
    }
  };

  return (
    <AgentQuestionItemBody isForcedQuestion={isForcedQuestion} ref={chatWindow} onScroll={onScroll}>
      <ul>
        {isLoading && (
          <ChatSpinner>
            <SpinnerBorder>
              <span className="sr-only">Loading ...</span>
            </SpinnerBorder>
          </ChatSpinner>
        )}
        {displayedConversations.map((conversation) => {
          if (conversation.type === ConversationType.AGENT) {
            return (
              <AgentResponse className="answered">
                <AgentResponseBody dangerouslySetInnerHTML={{ __html: conversation.text }} />
                <small>{moment(conversation.date).format('MMM DD, hh:mma')}</small>
              </AgentResponse>
            );
          } else if (conversation.type === ConversationType.BOT_QUESTION) {
            return <QuestionMessage conversation={conversation} />;
          } else if (conversation.type === ConversationType.BOT_CONFIRMATION) {
            return <ConfirmationMessage conversation={conversation} />;
          } else if (conversation.type === ConversationType.BOT_GENERIC_REPLY) {
            return <SimpleMessage text={conversation.text} date={conversation.date} />;
          }
          return <></>;
        })}
        {isTyping && (
          <BotTyping>
            <ResponseTypingBot className="response-typing">
              <MiniLoader2 />
            </ResponseTypingBot>
          </BotTyping>
        )}
        {isFormDisplayed ? <AnswerForm question={question} /> : null}
        {isConfirmationFormDisplayed ? <ConfirmationForm question={question} /> : null}
      </ul>
    </AgentQuestionItemBody>
  );
};

export default ChatBody;
