import produce from 'immer';
import { ActionType as PusherActionType, PusherAction } from 'dwell/store/pusher/action-types';
import { Action, ActionType, LeadFilterProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface LeadFilterState extends CommonStateType {
  leadsFilters: LeadFilterProps[];
  leadsFilter: LeadFilterProps;
  activeFilter: string;
}

export const initialState: LeadFilterState = {
  leadsFilters: [],
  leadsFilter: {} as LeadFilterProps,
  activeFilter: '',
};

export const selectLeadsFilters = (state: {leadsFilter: LeadFilterState}): { id: string | number; name: string; }[] => state.leadsFilter.leadsFilters;
export const selectActiveFilter = (state: {leadsFilter: LeadFilterState}): string | number => state.leadsFilter.activeFilter;

export default produce((state: LeadFilterState = initialState, action: Action): LeadFilterState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'leadsfilter') {
    return state;
  }
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = action.row as LeadFilterProps;
      state.leadsFilters = state.leadsFilters.filter(leadsFilter => leadsFilter.id !== row.id).concat(row);
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as LeadFilterProps;
      state.leadsFilters = state.leadsFilters.filter(leadsFilter => leadsFilter.id !== row.id).concat(row);
      state.leadsFilter = state.leadsFilter.id === row.id ? row : state.leadsFilter;
      break;
    }

    case PusherActionType.PUSHER_DELETE_RECORD: {
      const row = action.row as LeadFilterProps;
      state.leadsFilters = state.leadsFilters.filter(leadsFilter => leadsFilter.id.toString() !== row.id.toString());
      break;
    }

    case ActionType.GET_LEADS_FILTER_SUCCESS:
      state.leadsFilters = action.result.data.results;
      break;

    case ActionType.CREATE_LEADS_FILTER_SUCCESS:
      state.leadsFilter = action.result.data;
      break;

    case ActionType.UPDATE_LEADS_FILTER_SUCCESS:
      state.leadsFilter = action.result.data;
      break;

    case ActionType.GET_ACTIVE_FILTER_SUCCESS:
      state.activeFilter = action.result.data;
      break;

    case ActionType.SET_ACTIVE_FILTER_SUCCESS:
      state.activeFilter = action.result.data;
      break;

    case ActionType.SET_ACTIVE_FILTER:
      state.activeFilter = action.filter;
      break;
  }

  return state;
});
