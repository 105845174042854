import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ManageRequestProps, CustomBlob } from 'src/interfaces';
import { ActionType, Action, EmailMessageProps } from './action-types';

export default {
  getMessages: (param: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_EMAIL_MESSAGES_REQUEST,
        ActionType.GET_EMAIL_MESSAGES_SUCCESS,
        ActionType.GET_EMAIL_MESSAGES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.EMAIL_MESSAGES, { params: param }),
    },
  }),

  getMessageById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_EMAIL_MESSAGE_BY_ID_REQUEST,
        ActionType.GET_EMAIL_MESSAGE_BY_ID_SUCCESS,
        ActionType.GET_EMAIL_MESSAGE_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.EMAIL_MESSAGE_DETAILS, id)),
    },
  }),

  updateMessageById: (id: number, data: EmailMessageProps): Action => ({
    CALL_API: {
      types:
        typeof data.is_unread !== 'undefined'
          ? [
            ActionType.MARK_EMAIL_MESSAGE_AS_READ_REQUEST,
            ActionType.MARK_EMAIL_MESSAGE_AS_READ_SUCCESS,
            ActionType.MARK_EMAIL_MESSAGE_AS_READ_FAILURE,
          ]
          : [
            ActionType.UPDATE_EMAIL_MESSAGE_REQUEST,
            ActionType.UPDATE_EMAIL_MESSAGE_SUCCESS,
            ActionType.UPDATE_EMAIL_MESSAGE_FAILURE,
          ],
      promise: client => client.patch(build(paths.api.v1.EMAIL_MESSAGE_DETAILS, id), data),
    },
  }),

  archiveMessage: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.ARCHIVE_EMAIL_MESSAGE_REQUEST,
        ActionType.ARCHIVE_EMAIL_MESSAGE_SUCCESS,
        ActionType.ARCHIVE_EMAIL_MESSAGE_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.EMAIL_MESSAGE_DETAILS, id), { is_archived: true }),
    },
  }),

  getEmailConversations: (param: { lead_id: number }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_CONVERSATIONS_REQUEST,
        ActionType.GET_LEAD_CONVERSATIONS_SUCCESS,
        ActionType.GET_LEAD_CONVERSATIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.EMAIL_MESSAGES, { params: param }),
    },
  }),

  sendBulkEmail: (data: EmailMessageProps): Action => ({
    CALL_API: {
      types: [
        ActionType.SEND_BULK_EMAIL_REQUEST,
        ActionType.SEND_BULK_EMAIL_SUCCESS,
        ActionType.SEND_BULK_EMAIL_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.SEND_BULK_EMAIL, data),
    },
  }),

  setEmailLeadId: (leadId: number): Action => ({ type: ActionType.SET_EMAIL_LEAD_ID, leadId: leadId?.toString() }),

  setBody: (body: string): Action => ({
    type: ActionType.SET_EMAIL_BODY,
    body,
  }),

  setSubject: (subject: string): Action => ({
    type: ActionType.SET_EMAIL_SUBJECT,
    subject,
  }),

  setSelectedTemplateId: (selectedTemplateId: number): Action => ({
    type: ActionType.SET_SELECTED_EMAIL_TEMPLATE_ID,
    selectedTemplateId: selectedTemplateId?.toString(),
  }),

  setSubjectVariables: (subjectVariables: string[]): Action => ({
    type: ActionType.SET_EMAIL_SUBJECT_VARIABLES,
    subjectVariables,
  }),

  setFiles: (files: CustomBlob[] | string[]): Action => ({
    type: ActionType.SET_FILES,
    files,
  }),

  setIsShowingCc: (isShowingCc: boolean): Action => ({
    type: ActionType.SET_IS_SHOWING_CC,
    isShowingCc,
  }),

  setCc: (cc: string): Action => ({
    type: ActionType.SET_EMAIL_CC,
    cc,
  }),

  clearEmailContent: (): Action => ({ type: ActionType.CLEAR_EMAIL_CONTENT }),
};
