import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  SEND_PASSWORD_RESET_EMAIL_REQUEST = 'SEND_PASSWORD_RESET_EMAIL_REQUEST',
  SEND_PASSWORD_RESET_EMAIL_SUCCESS = 'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
  SEND_PASSWORD_RESET_EMAIL_FAILURE = 'SEND_PASSWORD_RESET_EMAIL_FAILURE',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',
  RESET_CHANGE_PASSWORD_STATE = 'RESET_CHANGE_PASSWORD_STATE',
}

export interface ResetPasswordState {
  isSubmitting: boolean;
  isFormInvalid: boolean;
  isTokenInvalid: boolean;
}

interface SendResetPasswordEmailAction {
  type: ActionType.SEND_PASSWORD_RESET_EMAIL_REQUEST | ActionType.SEND_PASSWORD_RESET_EMAIL_SUCCESS | ActionType.SEND_PASSWORD_RESET_EMAIL_FAILURE;

  error: {
    response: { status: string };
  };
}

interface ResetPasswordAction {
  type: ActionType.RESET_PASSWORD_REQUEST | ActionType.RESET_PASSWORD_SUCCESS | ActionType.RESET_PASSWORD_FAILURE;

  error: {
    response: { status: string };
  };
}

interface ResetChangePasswordStateAction {
  type: ActionType.RESET_CHANGE_PASSWORD_STATE;
}

export type Action = PromiseActionType | SendResetPasswordEmailAction | ResetPasswordAction | ResetChangePasswordStateAction;
