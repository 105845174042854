import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_LEAD_HISTORIC_PRICE_QUOTES_REQUEST = 'GET_LEAD_HISTORIC_PRICE_QUOTES_REQUEST',
  GET_LEAD_HISTORIC_PRICE_QUOTES_SUCCESS = 'GET_LEAD_HISTORIC_PRICE_QUOTES_SUCCESS',
  GET_LEAD_HISTORIC_PRICE_QUOTES_FAILURE = 'GET_LEAD_HISTORIC_PRICE_QUOTES_FAILURE',

  CREATE_PRICE_QUOTE_REQUEST = 'CREATE_PRICE_QUOTE_REQUEST',
  CREATE_PRICE_QUOTE_SUCCESS = 'CREATE_PRICE_QUOTE_SUCCESS',
  CREATE_PRICE_QUOTE_FAILURE = 'CREATE_PRICE_QUOTE_FAILURE',

  UPDATE_PRICE_QUOTE_REQUEST = 'UPDATE_PRICE_QUOTE_REQUEST',
  UPDATE_PRICE_QUOTE_SUCCESS = 'UPDATE_PRICE_QUOTE_SUCCESS',
  UPDATE_PRICE_QUOTE_FAILURE = 'UPDATE_PRICE_QUOTE_FAILURE',

  SET_PROTO_PRICE_QUOTE = 'SET_PROTO_PRICE_QUOTE',

  CLEAR_CURRENT_PRICE_QUOTE = 'CLEAR_CURRENT_PRICE_QUOTE',
}

export interface PriceQuote {
  id?: number;
  lead?: number;
  unit_type?: string;
  floor_plan?: number;
  floor_plan_name?: string;
  unit?: number;
  unit_name?: string;
  highest_price?: number;
  lowest_price?: number;
  price?: number;
  created?: string;
  is_email_sent?: boolean;
}

export interface priceQuoteSelection {
  unitTypeName: string;
  unitTypeValue: string;
  floorPlanName: string;
  floorPlanId: number;
  propertyUnitName: string;
  propertyUnitId: number;
}

interface GetLeadHistoricPriceQuotesAction {
  type: ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_REQUEST | ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_SUCCESS | ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_FAILURE;

  result: {
    data: { results: PriceQuote[] };
  };
  error: {
    response: { status: string };
  };
}

interface CreatePriceQuoteAction {
  type: ActionType.CREATE_PRICE_QUOTE_REQUEST | ActionType.CREATE_PRICE_QUOTE_SUCCESS | ActionType.CREATE_PRICE_QUOTE_FAILURE;

  result: {
    data: PriceQuote;
  };
  error: {
    response: { status: string };
  };
}

interface SetProtoPriceQuoteAction {
  type: ActionType.SET_PROTO_PRICE_QUOTE;
  selection: priceQuoteSelection;
}

interface UpdatePriceQuoteAction {
  type: ActionType.UPDATE_PRICE_QUOTE_REQUEST | ActionType.UPDATE_PRICE_QUOTE_SUCCESS | ActionType.UPDATE_PRICE_QUOTE_FAILURE;

  result: {
    data: PriceQuote;
  };
  error: {
    response: { status: string };
  };
}

interface ClearCurrentPriceQuoteAction {
  type: ActionType.CLEAR_CURRENT_PRICE_QUOTE;
}

export type Action = PromiseActionType | GetLeadHistoricPriceQuotesAction | CreatePriceQuoteAction | SetProtoPriceQuoteAction | UpdatePriceQuoteAction | ClearCurrentPriceQuoteAction;
