import produce from 'immer';
import {
  IS_BEEN_LOGGED,
  LOGGED_ACCOUNT,
  IS_RECENTLY_LOGGED,
  COMMUNICATION_FILTER,
  RECENT_PROPERTY_HYPHENS,
} from 'dwell/constants';
import userManager from './user_manager';
import { Action, ActionType } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface AuthenticationState extends CommonStateType {
  isLoggingOut: boolean;
  isFormInvalid: boolean,
  isSessionTimedOut: boolean,
  isPasswordChanged: boolean,
}

const initialState: AuthenticationState = {
  isLoggingOut: false,
  isSubmitting: false,
  isFormInvalid: false,
  isSessionTimedOut: false,
  isPasswordChanged: false,
};

export default produce((state: AuthenticationState = initialState, action: Action): AuthenticationState => {
  preFillState(state, action, Object.values(ActionType));
  const loggedAccount = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};

  switch (action.type) {
    case ActionType.SSO_CHECK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.SSO_CHECK_SUCCESS:
      state.isSubmitting = false;
      state.isFormInvalid = false;
      break;
    case ActionType.SSO_CHECK_FAILURE:
      state.isSubmitting = false;
      state.isFormInvalid = true;
      break;

    case ActionType.LOGIN_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.LOGIN_SUCCESS:
      localStorage.setItem(LOGGED_ACCOUNT, JSON.stringify(action.result.data));
      state.isFormInvalid = false;
      state.isSessionTimedOut = false;
      state.isSubmitting = false;
      break;
    case ActionType.LOGIN_FAILURE:
      state.isSubmitting = false;
      state.isFormInvalid = !!action.error.response.status;
      break;

    case ActionType.SSO_LOGIN_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.SSO_LOGIN_SUCCESS:
      state.isSessionTimedOut = false;
      state.isSubmitting = false;
      break;
    case ActionType.SSO_LOGIN_FAILURE:
      state.isSubmitting = false;
      break;

    case ActionType.LOGOUT:
      state.isSessionTimedOut = false;
      break;

    case ActionType.BACKCHANNEL_LOGOUT_REQUEST:
      state.isLoggingOut = true;
      break;
    case ActionType.BACKCHANNEL_LOGOUT_SUCESS:
      state.errorMessage = action.payload.errorMessage;
      if (action.payload.logoutCompletely) {
        state = initialState;
        // Clear user manager
        userManager.removeUser();
        userManager.clearStaleState();
        // Clear storage
        sessionStorage.clear();
        [LOGGED_ACCOUNT, IS_BEEN_LOGGED, IS_RECENTLY_LOGGED, COMMUNICATION_FILTER, RECENT_PROPERTY_HYPHENS].forEach(item => localStorage.removeItem(item));
      }
      break;
    case ActionType.BACKCHANNEL_LOGOUT_FAILURE:
      state.isLoggingOut = false;
      break;

    case ActionType.RESET_LOGIN_STATE:
      if (action.clearAll) {
        state = initialState;
      } else {
        state.isLoggingOut = false;
        state.isSubmitting = false;
        state.isPasswordChanged = false;
        state.isSessionTimedOut = false;
      }
      break;

    case ActionType.SESSION_TIMEOUT:
      loggedAccount.access = '';
      localStorage.setItem(LOGGED_ACCOUNT, JSON.stringify(loggedAccount));
      state.isSessionTimedOut = true;
      break;
  }

  return state;
});

export const selectIsFormInvalid = (state: {authentication: AuthenticationState}): boolean => state.authentication.isFormInvalid;
export const selectIsSessionTimedOut = (state: {authentication: AuthenticationState}): boolean => state.authentication.isSessionTimedOut;
export const selectAuthenticationIsSubmitting = (state: {authentication: AuthenticationState}): boolean => state.authentication.isSubmitting;
