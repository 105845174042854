export default {
  PAGE_DATA: '/api/v1/:propertyHyphens/page_data/:section/',
  FLOOR_PLANS: '/api/v1/:propertyHyphens/floor_plans/?feed_source=:feedSource',
  FLOOR_PLAN_DETAILS: '/api/v1/:propertyHyphens/floor_plans/:id/',
  PAGE_DATA_UPDATE: '/api/v1/:propertyHyphens/page_data/:section/',
  UPLOAD_IMAGE: '/api/v1/image_upload/',
  USERS: '/api/v1/users/',
  USERS_ID: '/api/v1/users/:id/',
  CLIENTS: '/api/v1/clients/',
  CUSTOMERS: '/api/v1/customers/',
  CUSTOMERS_LITE: '/api/v1/customers_lite/',
  CUSTOMERS_ID: '/api/v1/customers/:id/',
  CUSTOMER_DETAILS: '/api/v1/customers/:id/get_details/',
  CUSTOMER_COMPANY_POLICIES: '/api/v1/customers/:id/company_policies/',
  CUSTOMER_ONBOARD: '/api/v1/customers/onboard/',
  CLIENTS_ID: '/api/v1/clients/:id/',
  AUTH_PAGE_DATA: '/api/v1/auth-page-data',
  AUTH_PAGE_DATA_DOMAIN: '/api/v1/auth-page-data-domain/:domain',
  TOOLTIP_ITEMS: '/api/v1/tooltip-items',
  PROMOTIONS: '/api/v1/:propertyHyphens/promotion/',
  PROMOTION_DETAILS: '/api/v1/:propertyHyphens/promotion/:id/',
  COMPANY_POLICIES: '/api/v1/company_polices/',
  AMENITY_CATEGORIES: '/api/v1/hobbes/amenity_categories/',
  CUSTOMER_PROPERTIES: '/api/v1/agent_question/customers/:id/properties/',
  CUSTOMER_COMPETITORS: '/api/v1/agent_question/customers/:id/competitors/',
  CUSTOMER_SITE_ENABLED_PROPERTIES: '/api/v1/customers/:id/site_enabled_properties/',
  INSIDER_QUESTIONS: '/api/v1/hobbes/insider_questions/',
  ANSWERED_QUESTIONS_PERCENTAGE: '/api/v1/hobbes/insider_questions_with_answers/answered_percentage/',
  UPDATE_INSIDER_QUESTION: '/api/v1/hobbes/insider_questions/:id/',
  INSIDER_QUESTION_CATEGORIES: '/api/v1/hobbes/insider_question_categories/',
};
