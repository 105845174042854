import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, RenewalManagerProps } from './action-types';

export default {
  createRenewalManager: (leaseId: number, payload: RenewalManagerProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_RENEWAL_MANAGER_REQUEST,
        ActionType.CREATE_RENEWAL_MANAGER_SUCCESS,
        ActionType.CREATE_RENEWAL_MANAGER_FAILURE,
      ],
      promise: client => client.post(build(api.v1.RENEWAL_MANAGERS, leaseId), payload),
    },
  }),

  getRenewalManagers: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_RENEWAL_MANAGERS_REQUEST,
        ActionType.GET_RENEWAL_MANAGERS_SUCCESS,
        ActionType.GET_RENEWAL_MANAGERS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.RENEWAL_MANAGERS, leaseId)),
    },
  }),

  updateRenewalManager: (leaseId: number, payload: RenewalManagerProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_RENEWAL_MANAGER_REQUEST,
        ActionType.UPDATE_RENEWAL_MANAGER_SUCCESS,
        ActionType.UPDATE_RENEWAL_MANAGER_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.RENEWAL_MANAGERS_DETAILS, leaseId, payload.id), payload),
    },
  }),
};
