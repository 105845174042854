import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { Action, ActionType, SubmarketDetail, PropertiesBreakdown, RentComps } from './action-types';

export interface MarketState extends CommonStateType {
  isMarketDetailLoaded: boolean;
  isMarketPropertiesLoaded: boolean;
  isMarketSubmarketsLoaded: boolean;
  isRentCompsLoaded: boolean;
  isMTRBreakdownLoaded: boolean;
  countMarketProperties: number;
  countRentComps: number;
  marketDetail: SubmarketDetail;
  marketProperties: PropertiesBreakdown;
  rentComps: RentComps;
  marketSubmarkets: PropertiesBreakdown;
  mtrGroupBreakdown: PropertiesBreakdown;
  marketSubmarketsCount: number;
  mtrGroupBreakdownCount: number;
}

const initialState: MarketState = {
  isSubmitting: false,
  errorMessage: null,
  isMarketDetailLoaded: false,
  isMarketPropertiesLoaded: false,
  isRentCompsLoaded: false,
  isMarketSubmarketsLoaded: false,
  isMTRBreakdownLoaded: false,
  countMarketProperties: 0,
  countRentComps: 0,
  marketDetail: null,
  marketProperties: null,
  rentComps: null,
  marketSubmarkets: null,
  marketSubmarketsCount: 0,
  mtrGroupBreakdown: null,
  mtrGroupBreakdownCount: 0,
};

export default produce((state: MarketState = initialState, action: Action): MarketState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_MARKET_DETAIL_REQUEST:
      state.isMarketDetailLoaded = false;
      break;

    case ActionType.GET_MARKET_DETAIL_SUCCESS:
      state.marketDetail = action.result.data;
      state.isMarketDetailLoaded = true;
      break;

    case ActionType.GET_MARKET_DETAIL_FAILURE:
      state.isMarketDetailLoaded = false;
      break;

    case ActionType.GET_MARKET_PROPERTIES_REQUEST:
      state.isMarketPropertiesLoaded = false;
      break;

    case ActionType.GET_MARKET_PROPERTIES_SUCCESS:
      state.marketProperties = action.result.data.results;
      state.countMarketProperties = action.result.data.count;
      state.isMarketPropertiesLoaded = true;
      break;

    case ActionType.GET_MARKET_PROPERTIES_FAILURE:
      state.isMarketPropertiesLoaded = false;
      break;

    case ActionType.GET_MARKET_RENT_COMPS_REQUEST:
      state.isRentCompsLoaded = false;
      break;

    case ActionType.GET_MARKET_RENT_COMPS_SUCCESS:
      state.rentComps = action.result.data.results;
      state.countRentComps = action.result.data.count;
      state.isRentCompsLoaded = true;
      break;

    case ActionType.GET_MARKET_RENT_COMPS_FAILURE:
      state.isRentCompsLoaded = false;
      break;

    case ActionType.GET_MARKET_SUBMARKETS_REQUEST:
      state.isMarketSubmarketsLoaded = false;
      break;

    case ActionType.GET_MARKET_SUBMARKETS_SUCCESS:
      state.marketSubmarkets = action.result.data.results;
      state.marketSubmarketsCount = action.result.data.count;
      state.isMarketSubmarketsLoaded = true;
      break;

    case ActionType.GET_MARKET_SUBMARKETS_FAILURE:
      state.isMarketSubmarketsLoaded = false;
      break;

    case ActionType.GET_MTR_GROUP_BREAKDOWN_REQUEST:
      state.isMTRBreakdownLoaded = false;
      break;

    case ActionType.GET_MTR_GROUP_BREAKDOWN_SUCCESS:
      state.mtrGroupBreakdown = action.result.data.results;
      state.mtrGroupBreakdownCount = action.result.data.count;
      state.isMTRBreakdownLoaded = true;
      break;

    case ActionType.GET_MTR_GROUP_BREAKDOWN_FAILURE:
      state.isMTRBreakdownLoaded = false;
      break;
  }

  return state;
});
