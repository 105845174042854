import React, { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'dwell/actions/index';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import cn from 'classnames';
import { SINGLE_CHAT_FILTER, CONTACTS_N_PROSPECTS_OFFSET } from 'dwell/constants';
import { getAvatarColor, getInitials, formatMessageDate, getSearchPlaceholder, getNoContentText } from 'dwell/views/chat/common/utils';
import { Avatar } from 'styles/common';
import Skeleton from 'react-loading-skeleton';
import { LineSkeleton } from 'src/utils';
import moment from 'moment';
import { useInterval } from 'dwell/components';
import { ChatBody, ChatList, ChatListItem, ChatListItemBody, ChatListItemHeader, ChatSubHeader, ChatSearch, EmptyContent } from './styles';
import ProspectFilter from './_filter';

const defaultProspects = new Array(5).fill({ name: 'Prospect', is_mute: false }).map((item, i) => ({ ...item, id: i }));

const ProspectContacts: FC = () => {
  const currentUser = useSelector(state => state.user.currentUser);
  const isProspectsLoading = useSelector(state => state.prospectChat.isProspectsLoading);
  const isProspectsLoaded = useSelector(state => state.prospectChat.isProspectsLoaded);
  const prospects = useSelector(state => state.prospectChat.prospects.filter(p => p.should_display_in_chat));
  const prospectsRequestedAgents = useSelector(state => state.prospectChat.prospectsRequestedAgents);
  const selectedProperties = useSelector(selectSelectedProperties);
  const downloadedCount = useSelector(state => state.prospectChat.downloadedCount);
  const totalProspectsCount = useSelector(state => state.prospectChat.allProspectsCount);

  const dispatch = useDispatch();
  const { setChatAsActive, setProspectsOffline, getAllProspects } = actions.prospectChat;

  const [blinkingContacts, setBlinkingContacts] = useState([]);
  const [activeFilter, setActiveFilter] = useState(localStorage.getItem(SINGLE_CHAT_FILTER) || 'Active');
  const [keyword, setKeyword] = useState('');
  const [searchInFocus, setSearchInFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [scrollEl, setScrollEl] = useState<any>(null);
  const [canScroll, setCanScroll] = useState(false);
  const [scrollAutomated, setScrollAutomated] = useState(false);

  const onscroll = ({ target }) => {
    if (totalProspectsCount === downloadedCount) return;
    if (scrollAutomated) {
      setScrollAutomated(false);
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (scrollTop && scrollTop + clientHeight >= (scrollHeight - 5) && totalProspectsCount && !keyword) {
      setIsLoading(true);
      const ids = selectedProperties.map(p => p.id);
      dispatch(getAllProspects(false, ids, prospects.length, CONTACTS_N_PROSPECTS_OFFSET)).then((response) => {
        if (response) {
          setCanScroll(true);
          setIsLoading(false);
        }
      });
    }
  };

  const onActiveFilterClick = (filter) => {
    setActiveFilter(filter);
    localStorage.setItem(SINGLE_CHAT_FILTER, filter);
  };

  let filteredProspects = prospects;
  if (activeFilter === 'All') filteredProspects = prospects.filter(prospect => !prospect.is_archived);
  if (activeFilter === 'Archive') filteredProspects = prospects.filter(prospect => prospect.is_archived);
  if (activeFilter === 'Active') filteredProspects = prospects.filter(prospect => prospect.is_online && !prospect.is_archived);
  if (activeFilter === 'My') filteredProspects = prospects.filter(prospect => prospect.joined_agents.includes(currentUser.id) && !prospect.is_archived);
  filteredProspects = filteredProspects.filter(prospect => prospect.name.toLowerCase().includes(keyword.toLowerCase()))
    .sort((a, b) => (new Date(b.last_message_date).getTime() - new Date(a.last_message_date).getTime()));

  useEffect(() => {
    if (!isEmpty(filteredProspects) && canScroll && isProspectsLoaded && !isProspectsLoading) {
      if (scrollEl && scrollEl.childElementCount > 7) {
        setScrollAutomated(true);
        const contactBar = document.getElementById('prospect-bar');
        const scrollHeight = document.getElementById(`option-${filteredProspects[filteredProspects.length - 1 - CONTACTS_N_PROSPECTS_OFFSET].id}`);
        if (scrollHeight && contactBar) contactBar.scrollTop = scrollHeight.offsetTop;
        setCanScroll(false);
      }
    }
  }, [filteredProspects, canScroll, isProspectsLoaded, isProspectsLoading]);

  if (isProspectsLoading || isLoading) {
    filteredProspects = defaultProspects;
  }
  useEffect(() => {
    const isActiveRequests = prospectsRequestedAgents.some(request => request.is_active && !request.is_declined);
    if (isActiveRequests) {
      setBlinkingContacts([...prospectsRequestedAgents.filter(request => request.is_active && !request.is_declined).map(r => r.prospect).slice()]);

      setTimeout(() => {
        setBlinkingContacts([]);
      }, 30000);
    } else {
      setBlinkingContacts([]);
    }
  }, [prospectsRequestedAgents]);

  const checkOfflineProspects = () => {
    const prospectIds = prospects
      .filter(prospect => prospect.is_online &&
          moment().diff(moment(prospect.last_prospect_message_date), 'minutes') > 5)
      .map(prospect => prospect.id);
    if (prospectIds.length > 0) {
      dispatch(setProspectsOffline(prospectIds));
    }
  };

  useInterval(() => {
    checkOfflineProspects();
  }, 60000);

  const renderContact = (prospect, index) => {
    const lastMessage = (prospect.last_message || '').replace(/<br\s*\/?>/gi, ' ')
      .replace(/\s\s+/g, ' ').replace(/<\/?h[0-9]>/g, ' ')
      .replace(/<\/?p>/g, '');
    const contactMessage = blinkingContacts.includes(prospect.id) ? 'Prospect requesting live agent' : lastMessage;

    return (
      <ChatListItem
        className={cn('active', { new: prospect.unread_count })}
        key={index}
        id={`option-${prospect.id}`}
        onClick={isProspectsLoaded ? () => dispatch(setChatAsActive({
          id: prospect.id,
          name: prospect.name,
          isSMS: false,
          unread_count: prospect.unread_count,
          isSingleChat: true,
          is_online: prospect.is_online,
          property_external_id: prospect.property_external_id,
        })) : null}
      >
        {isProspectsLoaded ?
          <Avatar className={cn(`avatar ${getAvatarColor(prospect.name, prospect.id)}`, { offline: !prospect.is_online, online: prospect.is_online })}>
            <i>{getInitials(prospect.name)}</i>
          </Avatar> : <Skeleton circle height={46} width={46} />}
        <ChatListItemBody className="body">
          <ChatListItemHeader className="header">
            <h6><span>{isProspectsLoaded ? `${prospect.name} | ${prospect.property_name}` : <LineSkeleton width={100} height={12} /> }</span></h6>
            {prospect.is_mute ? <span className="mute-indicator"><i className="ri-notification-off-fill" /></span> : null}
            <small>{isProspectsLoaded ? formatMessageDate(prospect.last_message_date) : <LineSkeleton width={40} height={8} />}</small>
          </ChatListItemHeader>
          {isProspectsLoaded ? <p dangerouslySetInnerHTML={{ __html: contactMessage }} /> : <LineSkeleton width={200} height={9} />}
        </ChatListItemBody>
      </ChatListItem>
    );
  };

  const emptyContent = (
    <EmptyContent>
      <i className="ri-chat-1-line" />
      <h5><span>{keyword ? 'No Chats Found' : getNoContentText(activeFilter).label}</span></h5>
      <p>{keyword ? 'Try adjusting your search to find what you’re looking for.' : getNoContentText(activeFilter).text}</p>
    </EmptyContent>
  );
  const content = filteredProspects.length ? filteredProspects.map((prospect, index) => renderContact(prospect, index)) : emptyContent;

  return (
    <>
      <ChatSubHeader>
        <ChatSearch onfocus={searchInFocus}>
          <i className="ri-search-line" />
          <input
            type="text"
            className="form-control m-0"
            placeholder={getSearchPlaceholder(activeFilter)}
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
            onBlur={() => setSearchInFocus(false)}
            onFocus={() => setSearchInFocus(true)}
          />
        </ChatSearch>
        <ProspectFilter activeFilter={activeFilter} setActiveFilter={onActiveFilterClick} />
      </ChatSubHeader>
      <ChatBody>
        <ChatList
          onScroll={onscroll}
          containerRef={(ref) => {
            setScrollEl(ref);
          }}
          id="prospect-bar"
        >
          {content}
        </ChatList>
      </ChatBody>
    </>);
};

export default ProspectContacts;
