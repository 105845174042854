import React from 'react';
import loadable from '@loadable/component';
import Loader from 'dwell/components/loader';

import { paths } from 'dwell/constants';

const BulkSign = loadable(() =>
  import(/* webpackChunkName: 'LeadOverview' */ './dwell/views/bulk_sign'), {
  fallback: <Loader />,
});

const LeadsTable = loadable(() =>
  import(/* webpackChunkName: 'LeadsTable' */'./dwell/views/pipeline'), {
  fallback: <Loader />,
});

const LeadOverview = loadable(() =>
  import(/* webpackChunkName: 'LeadOverview' */'./dwell/views/lead/overview'), {
  fallback: <Loader />,
});

const LeaseOverview = loadable(() =>
  import(/* webpackChunkName: 'LeaseOverview' */'./resident/views/overview'), {
  fallback: <Loader />,
});

const ApplicationOverview = loadable(() =>
  import(/* webpackChunkName: 'ApplicationOverview' */'./application/views/overview'), {
  fallback: <Loader />,
});

const TasksList = loadable(() =>
  import(/* webpackChunkName: 'TasksList' */'./dwell/views/tasks'), {
  fallback: <Loader />,
});

const Settings = loadable(() =>
  import(/* webpackChunkName: 'Settings' */'./dwell/views/settings'), {
  fallback: <Loader />,
});

const ApartmentAvailability = loadable(() =>
  import(/* webpackChunkName: 'ApartmentAvailability' */'./dwell/views/apartment_availability'), {
  fallback: <Loader />,
});

const NurtureAutomations = loadable(() =>
  import(/* webpackChunkName: 'NurtureAutomations' */'./dwell/views/nurture_automations/nurture_list'), {
  fallback: <Loader />,
});

const NurtureDetails = loadable(() =>
  import(/* webpackChunkName: 'NurtureDetails' */'./dwell/views/nurture_automations/nurture_details'), {
  fallback: <Loader />,
});

const NylasIntegration = loadable(() =>
  import(/* webpackChunkName: 'NylasIntegration' */'./dwell/views/nylas_integration'), {
  fallback: <Loader />,
});

const Followups = loadable(() =>
  import(/* webpackChunkName: 'Followups' */'./dwell/views/followups'), {
  fallback: <Loader />,
});

const BulkEmail = loadable(() =>
  import(/* webpackChunkName: 'BulkEmail' */'./dwell/views/bulk_email'), {
  fallback: <Loader />,
});

const Reports = loadable(() =>
  import(/* webpackChunkName: 'Reports' */'./dwell/views/reports'), {
  fallback: <Loader />,
});

const Calls = loadable(() =>
  import(/* webpackChunkName: 'Calls' */'./dwell/views/calls'), {
  fallback: <Loader />,
});

const Chats = loadable(() =>
  import(/* webpackChunkName: 'Chats' */'./dwell/views/chats'), {
  fallback: <Loader />,
});

const ChatEvaluation = loadable(() =>
  import(/* webpackChunkName: 'ChatEvaluation' */'./dwell/views/chats/evaluation'), {
  fallback: <Loader />,
});

const ChatEvaluationReport = loadable(() =>
  import(/* webpackChunkName: 'ChatEvaluationReport' */'./dwell/views/chats/report'), {
  fallback: <Loader />,
});

const MultiChat = loadable(() =>
  import(/* webpackChunkName: 'MultiChat' */'./dwell/views/chat/multi_chat'), {
  fallback: <Loader />,
});

const SiteContent = loadable(() =>
  import(/* webpackChunkName: "SiteContent" */'./site/views/site_contents'), {
  fallback: <Loader />,
});

const Users = loadable(() =>
  import(/* webpackChunkName: 'Users' */'./site/views/users'), {
  fallback: <Loader />,
});

const Customers = loadable(() =>
  import(/* webpackChunkName: 'Customers' */'./site/views/customers'), {
  fallback: <Loader />,
});

const Clients = loadable(() =>
  import(/* webpackChunkName: 'Clients' */'./site/views/clients'), {
  fallback: <Loader />,
});

const Properties = loadable(() =>
  import(/* webpackChunkName: 'Properties' */'./site/views/properties'), {
  fallback: <Loader />,
});

const HobbesSettings = loadable(() =>
  import(/* webpackChunkName: 'HobbesSettings' */'./site/views/hobbes_settings'), {
  fallback: <Loader />,
});

const CompeteHome = loadable(() =>
  import(/* webpackChunkName: 'CompeteHome' */'./compete/views/home'), {
  fallback: <Loader />,
});

const CompetePropertyReport = loadable(() =>
  import(/* webpackChunkName: 'CompetePropertyReport' */'./compete/views/property_report'), {
  fallback: <Loader />,
});

const CompeteSubmarketReport = loadable(() =>
  import(/* webpackChunkName: 'CompeteSubmarketReport' */'./compete/views/submarket_report'), {
  fallback: <Loader />,
});

const CompeteMarketReport = loadable(() =>
  import(/* webpackChunkName: 'CompeteMarketReport' */'./compete/views/market_report'), {
  fallback: <Loader />,
});

const CompeteComparisonReport = loadable(() =>
  import(/* webpackChunkName: 'CompeteComparisonReport' */'./compete/views/comparison_report'), {
  fallback: <Loader />,
});

const CompeteAlertsSubscriptions = loadable(() =>
  import(/* webpackChunkName: 'CompeteAlertsSubscriptions' */'./compete/views/alerts_subscriptions'), {
  fallback: <Loader />,
});

const CompeteAlertsSummary = loadable(() =>
  import(/* webpackChunkName: 'CompeteAlertsSummary' */'./compete/views/alerts_summary'), {
  fallback: <Loader />,
});

const CompeteAlertsLog = loadable(() =>
  import(/* webpackChunkName: 'CompeteAlertsLog' */'./compete/views/alert_log'), {
  fallback: <Loader />,
});

const SupportPortal = loadable(() =>
  import(/* webpackChunkName: 'SupportPortal' */'./dwell/views/support_portal'), {
  fallback: <Loader />,
});

const Pipeline = loadable(() =>
  import(/* webpackChunkName: 'CompeteComparisonReport' */'./dwell/views/pipeline_new'), {
  fallback: <Loader />,
});

const CreditBuilder = loadable(() =>
  import(/* webpackChunkName: 'CreditBuilder' */'./dwell/views/credit_builder'), {
  fallback: <Loader />,
});

const CreditBuilderLeaseOverview = loadable(() =>
  import(/* webpackChunkName: 'CreditBuilderLeaseOverview' */'./resident/views/credit_builder_overview'), {
  fallback: <Loader />,
});

const Corporate = loadable(() =>
  import(/* webpackChunkName: 'Corporate' */'./corporate/views'), {
  fallback: <Loader />,
});

export const dwellRoutes = [
  { path: paths.client.LEADS.VIEW, name: 'Leads', component: LeadsTable, exact: true },
  { path: paths.client.LEADS.OVERVIEW, name: 'Lead Overview', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.APPLICANTS, name: 'Lead Applicants', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.APPLICANT_DETAIL, name: 'Lead Applicant Detail', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.GUARANTORS, name: 'Lead Guarantors', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.GUARANTOR_DETAIL, name: 'Lead Guarantor Detail', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.DOCUMENTS, name: 'Lead Documents', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.LEASE_AGREEMENT, name: 'Lead Lease Agreement', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.DEPOSIT_AGREEMENT, name: 'Lead Deposit Agreement', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEADS.SMS, name: 'Lead SMS', component: LeadOverview, exact: true, leadDetail: true },
  { path: paths.client.LEASES.OVERVIEW, name: 'Leases Overview', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.REFRESH_PROFILE, name: 'Refresh Profile', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.REFRESH_PROFILE_PROGRESS, name: 'Refresh Profile Progress', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.RENEW, name: 'Renew Lease', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.ACTIVITY, name: 'Leases Activity', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.DOCUMENTS, name: 'Leases Documents', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.DETAILS, name: 'Leases Details', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.RESIDENT_DETAILS, name: 'Resident Details', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.ADD_PEOPLE, name: 'Add People', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.ADD_PEOPLE_PROGRESS, name: 'Add People Progress', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.EDIT_PEOPLE, name: 'Edit People', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.REMOVE_PEOPLE, name: 'Remove People', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.REMOVE_PEOPLE_PROGRESS, name: 'Remove People Progress', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.NOTICE, name: 'Notice', component: LeaseOverview, exact: true, residentDetail: true },
  { path: paths.client.LEASES.SCREEN_GROUP, name: 'Screen Group', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.LEASES.RESCREEN_GROUP, name: 'Rescreen Group', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.OVERVIEW, name: 'Applications Overview', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.APPLICANT_DETAILS, name: 'Applicant Details', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.SCREEN_GROUP, name: 'Screen Group', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.RESCREEN_GROUP, name: 'Rescreen Group', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.ADD_PEOPLE, name: 'Add People', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.EDIT_INVITE_DETAILS, name: 'Edit Invite Details', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.REMOVE_PEOPLE, name: 'Remove People', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.TRANSFER_PEOPLE, name: 'Transfer People', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.RESTORE_PEOPLE, name: 'Restore People', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.EDIT_PEOPLE, name: 'Edit People', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.REFRESH_PROFILE, name: 'Refresh People', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.REFRESH_PROFILE_PROGRESS, name: 'Refresh Profile Progress', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.LEASE, name: 'Applications Lease', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.LEASE_DETAILS, name: 'Application Lease Details', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.TRANSFER_UNIT, name: 'Application Transfer Unit', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.LEASE_CHANGE, name: 'Application Lease Change', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.TRANSFER_UNIT, name: 'Application Transfer Unit', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.RELEASE_UNIT, name: 'Application Release Unit', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.ACTIVITY, name: 'Applications Activity', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.APPLICATIONS.DOCUMENTS, name: 'Applications Documents', component: ApplicationOverview, exact: true, applicationDetail: true },
  { path: paths.client.BULK_SIGN, name: 'Bulk Sign', component: BulkSign, exact: true },
  { path: paths.client.TASKS, name: 'Tasks', component: TasksList, exact: true },
  { path: paths.client.SETTINGS.VIEW, name: 'Settings', component: Settings, exact: true },
  { path: paths.client.SETTINGS.ASSIGNMENT_RULES, name: 'Assignment Rules', component: Settings, exact: true },
  { path: paths.client.SETTINGS.LIST_TEMPLATE, name: 'Email Templates', component: Settings, exact: true },
  { path: paths.client.SETTINGS.CHAT_LIST_TEMPLATE, name: 'Chat Templates', component: Settings, exact: true },
  { path: paths.client.SETTINGS.EMAIL_SYNC, name: 'Email Sync', component: Settings, exact: true },
  { path: paths.client.SETTINGS.PAID_SOURCES, name: 'Paid Sources', component: Settings, exact: true },
  { path: paths.client.SETTINGS.COMPETITORS, name: 'Competitors', component: Settings, exact: true },
  { path: paths.client.SETTINGS.BUSINESS_HOURS, name: 'Business Hours', component: Settings, exact: true },
  { path: paths.client.SETTINGS.LEASE_DEFAULTS, name: 'Lease Defaults', component: Settings, exact: true },
  { path: paths.client.SETTINGS.PROPERTY_POLICIES, name: 'Property Polices', component: Settings, exact: true },
  { path: paths.client.SETTINGS.UTILITY_POLICIES, name: 'Utility Policies', component: Settings, exact: true },
  { path: paths.client.SETTINGS.OTHER_RENT_ITEMS, name: 'Other Rent Items', component: Settings, exact: true },
  { path: paths.client.SETTINGS.OTHER_FEE_ITEMS, name: 'Other Fee Items', component: Settings, exact: true },
  { path: paths.client.SETTINGS.CLEANING_CHARGES, name: 'Cleaning Charges', component: Settings, exact: true },
  { path: paths.client.SETTINGS.RENTABLE_ITEMS, name: 'Rentable Items', component: Settings, exact: true },
  { path: paths.client.SETTINGS.TOUR_OPTIONS, name: 'Tour Options', component: Settings, exact: true },
  { path: paths.client.SETTINGS.DURATION_PRICING, name: 'Duration Pricing', component: Settings, exact: true },
  { path: paths.client.SETTINGS.ADDENDA, name: 'Addenda', component: Settings, exact: true },
  { path: paths.client.SETTINGS.ADDENDA_FIELDS, name: 'Addenda Fields', component: Settings, exact: true },
  { path: paths.client.SETTINGS.TARGET_NUMBERS, name: 'Target Numbers', component: Settings, exact: true },
  { path: paths.client.NYLAS_INTEGRATION, name: 'Nylas Integration', component: NylasIntegration },
  { path: paths.client.FOLLOWUPS.VIEW, name: 'Followups', component: Followups, exact: true },
  { path: paths.client.BULK_EMAIL, name: 'Bulk Email', component: BulkEmail, exact: true },
  { path: paths.client.REPORTS.BASIC, name: 'Reports', component: Reports, exact: true },
  { path: paths.client.CALLS, name: 'Calls', component: Calls, exact: true },
  { path: paths.client.SETTINGS.LIST_PAID_SOURCE_BUDGET, name: 'Paid Source Budgets', component: Settings, exact: true },
  { path: paths.client.SETTINGS.LIST_RENT_SURVEY, name: 'Rent Surveys', component: Settings, exact: true },
  { path: paths.client.MULTI_CHAT, name: 'Multi Chat', component: MultiChat, exact: true },
  { path: paths.client.SETTINGS.CALL_BLOCK_LIST, name: 'Call Block List', component: Settings, exact: true },
  { path: paths.client.SETTINGS.CALL_SCHEDULE, name: 'Call Schedule', component: Settings, exact: true },
  { path: paths.client.SETTINGS.DETAILS, name: 'Community Details', component: Settings, exact: true },
  { path: paths.client.SETTINGS.CALL_ROUTING, name: 'Call Routing', component: Settings, exact: true },
  { path: paths.client.SETTINGS.INSIDER_INFO, name: 'Insider Info', component: Settings, exact: true },
  { path: paths.client.APARTMENT_AVAILABILITY, name: 'Apartment Availability', component: ApartmentAvailability, exact: true },
  { path: paths.client.NURTURE_AUTOMATIONS.VIEW, name: 'Automation', component: NurtureAutomations, exact: true },
  { path: paths.client.NURTURE_AUTOMATIONS.OVERVIEW, name: 'Automation Overview', component: NurtureDetails, exact: true },
  { path: paths.client.NURTURE_AUTOMATIONS.INSIGHTS, name: 'Automation Insights', component: NurtureDetails, exact: true },
  { path: paths.client.NURTURE_AUTOMATIONS.SETTINGS, name: 'Automation Settings', component: NurtureDetails, exact: true },
  { path: paths.client.SUPPORT_PORTAL.VIEW, name: 'Support Portal', component: SupportPortal, exact: true },
  { path: paths.client.PIPELINE, name: 'Pipeline', component: Pipeline, exact: true },
];

export const siteRoutes = [
  { path: paths.client.SITE_CONTENT.HOME, name: 'Home', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.GALLERY, name: 'Gallery', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.FLOOR_PLANS, name: 'Floor Plans', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.AMENITIES, name: 'Amenities', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.NEIGHBORHOOD, name: 'Neighborhood', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.VIRTUAL_TOUR, name: 'Virtual Tour', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.CONTACT, name: 'Contact', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.FOOTER, name: 'Footer', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.DESIGN, name: 'Design', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.MISC, name: 'MISC', component: SiteContent, exact: true },
  { path: paths.client.SITE_CONTENT.PROMOTIONS, name: 'Promotions', component: SiteContent, exact: true },
];

export const manageRoutes = [
  { path: paths.client.MANAGE_USERS, name: 'Manage Users', component: Users, exact: true },
  { path: paths.client.MANAGE_CUSTOMERS, name: 'Manage Customers', component: Customers, exact: true },
  { path: paths.client.MANAGE_CLIENTS, name: 'Manage Clients', component: Clients, exact: true },
  { path: paths.client.MANAGE_PROPERTIES, name: 'Manage Properties', component: Properties, exact: true },
];

export const hobbesSettings = [
  { path: paths.client.HOBBES_SETTINGS, name: 'Hobbes Settings', component: HobbesSettings, exact: true },
];

export const compete = [
  { path: paths.client.COMPETE.HOME, name: 'Compete', component: CompeteHome, exact: true },
  { path: paths.client.COMPETE.PROPERTY_REPORT, name: 'Property Report', component: CompetePropertyReport, exact: true },
  { path: paths.client.COMPETE.SUBMARKET_REPORT, name: 'Submarket Report', component: CompeteSubmarketReport, exact: true },
  { path: paths.client.COMPETE.MARKET_REPORT, name: 'Market Report', component: CompeteMarketReport, exact: true },
  { path: paths.client.COMPETE.COMPARISON_REPORT, name: 'Comparison', component: CompeteComparisonReport, exact: true },
  { path: paths.client.COMPETE.COMPARISON_REPORT_ID, name: 'Report', component: CompeteComparisonReport, exact: true },
  { path: paths.client.COMPETE.ALERTS_SUBSCRIPTIONS, name: 'Alerts', component: CompeteAlertsSubscriptions, exact: true },
  { path: paths.client.COMPETE.ALERTS_SUMMARY, name: 'Summary', component: CompeteAlertsSummary, exact: true },
  { path: paths.client.COMPETE.ALERTS_LOG, name: 'Log', component: CompeteAlertsLog, exact: true },
];

export const chatsRoutes = [
  { path: paths.client.CHATS.OVERVIEW, name: 'Chats Overview', component: Chats, exact: true },
  { path: paths.client.CHATS.EVALUATION, name: 'Evaluation', component: ChatEvaluation, exact: true },
  { path: paths.client.CHATS.REPORT, name: 'Report', component: ChatEvaluationReport, exact: true },
];

export const creditBuilderRoutes = [
  { path: paths.client.CREDIT_BUILDER.VIEW, name: 'Credit Builder Leases', component: CreditBuilder, exact: true },
  { path: paths.client.CREDIT_BUILDER.OVERVIEW, name: 'Credit Builder Lease Detail', component: CreditBuilderLeaseOverview, cbResidentDetail: true, exact: true },
];

export const corporateRoutes = [
  { path: paths.client.CORPORATE, name: 'Corporate', component: Corporate, exact: true },
];

const routes = [
  ...dwellRoutes.map(i => ({ ...i, isDwell: true })),
  ...siteRoutes.map(i => ({ ...i, isSite: true })),
  ...manageRoutes,
  ...hobbesSettings,
  ...compete,
  ...chatsRoutes,
  ...creditBuilderRoutes,
  ...corporateRoutes,
];

export default routes;
