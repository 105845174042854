import produce from 'immer';
import { Action, EmailPopout, ActionType } from './action-types';

export interface EmailPopoutsState {
  emailPopouts: EmailPopout[]
}

export const initialState: EmailPopoutsState = {
  emailPopouts: [],
};

const justOneActive = (popouts: EmailPopout[], exceptIndex: number) => {
  popouts.forEach((po, index) => {
    if (index !== exceptIndex && !po.minimized) {
      po.circled = true;
    }
  });
};

export default produce((state: EmailPopoutsState = initialState, action: Action): EmailPopoutsState => {
  switch (action.type) {
    case ActionType.ADD_EMAIL_POPOUT: {
      const existingPopout = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      // there is a popout for leadId, just open it
      if (existingPopout !== -1) {
        state.emailPopouts[existingPopout].minimized = false;
        state.emailPopouts[existingPopout].circled = false;
        justOneActive(state.emailPopouts, existingPopout);
      } else {
        const newPopout: EmailPopout = {
          popoutId: action.popoutId,
          property: action.property,
          isReply: action.isReply,
          lead: action.lead,
          body: '',
          subject: '',
          cc: action.emailMessage?.cc || '',
          isPreview: false,
          files: [],
          showingCcInput: !!action.emailMessage?.cc,
          subjectVariables: [],
          emailTemplates: [],
          selectedTemplate: null,
          minimized: false,
          circled: false,
          emailMessage: action.emailMessage,
          preloadTemplate: action.preloadTemplate,
          isSettedFromPriceQuote: action.isSettedFromPriceQuote,
        };
        state.emailPopouts.push(newPopout);
        if (state.emailPopouts.filter(i => !i.circled).length > 1) {
          const notCircled = state.emailPopouts.findIndex(i => !i.circled);
          state.emailPopouts[notCircled].circled = true;
        }
      }
      break;
    }
    case ActionType.CLOSE_EMAIL_POPOUT:
      state.emailPopouts = state.emailPopouts.filter(po => po.popoutId !== action.popoutId);
      break;
    case ActionType.TOGGLE_MINIMIZE_EMAIL_POPOUT: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      if (state.emailPopouts[popoutIndex].minimized) { // is about to be opened
        justOneActive(state.emailPopouts, popoutIndex);
        state.emailPopouts[popoutIndex].minimized = false;
      } else {
        state.emailPopouts[popoutIndex].minimized = !state.emailPopouts[popoutIndex].minimized;
      }
      break;
    }
    case ActionType.ACTIVE_EMAIL_POPOUT_CIRCLED: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].circled = false;
      state.emailPopouts[popoutIndex].minimized = false;
      justOneActive(state.emailPopouts, popoutIndex);
      break;
    }
    case ActionType.SET_EMAIL_POPOUT_TEXT_VAR: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex][action.varName] = action.value;
      break;
    }
    case ActionType.SET_EMAIL_POPOUT_IS_PREVIEW: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].isPreview = action.isPreview;
      break;
    }
    case ActionType.SET_EMAIL_POPOUT_SHOWING_CC_INPUT: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].showingCcInput = action.showingCcInput;
      break;
    }
    case ActionType.SET_EMAIL_POPOUT_FILES: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].files = state.emailPopouts[popoutIndex].files.concat(action.files);
      break;
    }
    case ActionType.REMOVE_EMAIL_POPOUT_FILE: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].files = state.emailPopouts[popoutIndex].files.filter(f => f.preview !== action.preview);
      break;
    }
    case ActionType.CLEAR_EMAIL_POPOUT_FILES: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].files = [];
      break;
    }
    case ActionType.SET_EMAIL_POPOUT_SELECTED_TEMPLATE: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].selectedTemplate = action.selectedTemplate;
      break;
    }
    case ActionType.SET_EMAIL_POPOUT_SUBJECT_VARIABLES: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      state.emailPopouts[popoutIndex].subjectVariables = action.subjectVariables;
      break;
    }
    case ActionType.SET_EMAIL_POPOUT_TEMPLATES: {
      const popoutIndex = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      if (popoutIndex !== -1) {
        state.emailPopouts[popoutIndex].emailTemplates = action.emailTemplates;
        const { preloadTemplate } = state.emailPopouts[popoutIndex];
        if (preloadTemplate) {
          const template = action.emailTemplates.find(t => t.type === preloadTemplate);
          if (template) {
            state.emailPopouts[popoutIndex].body = template.text;
            state.emailPopouts[popoutIndex].selectedTemplate = template;
            state.emailPopouts[popoutIndex].subject = template.subject;
            state.emailPopouts[popoutIndex].subjectVariables = template.subject_variables;
          }
        }
      }
      break;
    }
    case ActionType.CIRCLE_EMAIL_POPOUT: {
      const existingPopout = state.emailPopouts.findIndex(po => po.popoutId === action.popoutId);
      if (existingPopout !== -1) {
        state.emailPopouts[existingPopout].circled = true;
      }
      break;
    }
  }
  return state;
});

export const selectPopout = (popoutId: string) => (state: { emailPopouts: EmailPopoutsState }) : EmailPopout => state.emailPopouts.emailPopouts.find(po => po.popoutId === popoutId);
export const selectActivePopouts = (state: { emailPopouts: EmailPopoutsState }): EmailPopout[] => state.emailPopouts.emailPopouts.filter(po => !po.circled);
export const selectCircledPopouts = (state: { emailPopouts: EmailPopoutsState }): EmailPopout[] => state.emailPopouts.emailPopouts.filter(po => po.circled);
