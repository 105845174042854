/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-for */
import React, { FC, useEffect, useState } from 'react';
import { Input, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import { AgentPhoneType } from 'dwell/store/voice/action-types';
import { selectCallInProgress, selectDevice, selectDialedPhone, selectDialedProperty, selectDialedContact } from 'dwell/store/voice/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { selectSelectedProperties, selectProperties } from 'dwell/store/property/reducers';
import voiceActions from 'dwell/store/voice/action-creator';
import { ChatHeaderLink } from 'dwell/views/chat/single_chat/contact/styles';
import { dialFormat } from 'dwell/views/calls/communications/voice_centre/utils';
import { LeadResidentSearch, SearchedObjectType } from 'dwell/store/pipeline/action-types';
import pipelineActions from 'dwell/store/pipeline/action-creators';
import { CircleCallIcon, OutboundPhone } from 'dwell/views/calls/communications/call_window/svgs';

import {
  AlertCallItem,
  ButtonWhitePrompt,
  CallBody, CallConfirmPrompt,
  CallerInfoBody,
  CallerInfoContainer,
  CallItem,
  CallItemHeader,
  CallWindowSVG, ConfirmAvatar,
  DialCallButton,
  DialDeleteButton, DialedContact,
  OutboundCallAvatar,
  PropertiesCallDropdown, PropertiesCallDropdownItem,
} from './styles';
import Keypad from './keypad';

const DialWindow: FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const selectedProperties: PropertyProps[] = useSelector(selectSelectedProperties);
  const properties: PropertyProps[] = useSelector(selectProperties);
  const device = useSelector(selectDevice);
  const callInProgress = useSelector(selectCallInProgress);
  const dialedPhone = useSelector(selectDialedPhone);
  const dialedProperty = useSelector(selectDialedProperty);
  const dialedContact = useSelector(selectDialedContact);
  const {
    callContactFromBrowserPhone,
    hideDialWindow,
    setDialedPhoneNumber,
    setDialedProperty,
    setDialedContact,
  } = voiceActions;
  const { searchObject } = pipelineActions;

  const { residents, leads } = useSelector(state => state.pipeline.searchedObjects) as SearchedObjectType;
  const [isCallBeingCreated, setIsCallBeingCreated] = useState(false);
  const [typedPhone, setTypedPhone] = useState('');
  const [dropdownOpen, setDropdownState] = useState(false);
  const [clickedProperty, setClickedProperty] = useState<null | number>(null);
  const [residentsLeads, setResidentsLeads] = useState([]);
  const [openConfirmPrompt, setOpenConfirmPrompt] = useState(false);
  const [comparedProperties, setComparedProperties] = useState<PropertyProps[]>([]);
  const [leadContact, setLeadContact] = useState<LeadResidentSearch>(undefined);
  const [showDialedContact, setShowDialedContact] = useState(false);

  useEffect(() => {
    const allAssignedProperties = true;
    const includeLeads = true;
    const includeResidents = true;
    const includeLeases = false;
    const includeApplications = false;
    dispatch(searchObject('', allAssignedProperties, includeLeads, includeResidents, includeLeases, includeApplications));
  }, []);

  useEffect(() => {
    const residents_leads = residents.concat(leads);
    const uniqueResidentsLeads = Array.from(new Set(residents_leads.map(item => item.id)))
      .map(id => residents_leads.find(item => item.id === id));
    setResidentsLeads(uniqueResidentsLeads);
  }, [residents, leads]);

  useEffect(() => {
    if (selectedProperties.length === 1) {
      dispatch(setDialedProperty(selectedProperties[0]));
      setClickedProperty(selectedProperties[0].id);
    }
  }, [selectedProperties]);

  const handleClose = (cleanDialedPhone: boolean) => {
    setIsCallBeingCreated(false);
    if (cleanDialedPhone) {
      dispatch(setDialedPhoneNumber(''));
      dispatch(setDialedContact(undefined));
    }
    dispatch(hideDialWindow());
  };

  const callFromBrowser = async () => {
    setOpenConfirmPrompt(false);
    if (isEmpty(callInProgress)) {
      setIsCallBeingCreated(true);
      const params = {
        To: dialFormat(dialedPhone),
        PropertyId: dialedProperty.id.toString(),
        LeadId: '',
      };
      const call = await device.connect({ params });
      dispatch(callContactFromBrowserPhone({
        prospectName: '',
        propertyName: dialedProperty.name,
        propertyExternalId: dialedProperty.external_id,
        propertyId: dialedProperty.id,
        leadId: null,
        phoneType: AgentPhoneType.BROWSER_PHONE,
        call,
      }));
      handleClose(false);
    }
  };

  const handleBlur = () => {
    if (leadContact && leadContact.id !== 0) setShowDialedContact(true);
  };

  useEffect(() => {
    handleBlur();
  }, [leadContact]);

  const findContact = () => {
    const foundContacts = residentsLeads.filter(contact => dialFormat(contact.phone_number) === dialFormat(dialedPhone) || dialFormat(contact.secondary_phone_number) === dialFormat(dialedPhone));
    if (foundContacts.length > 0 && clickedProperty) {
      let foundContact = foundContacts.find(contact => contact.property === clickedProperty);
      if (!foundContact) [foundContact] = foundContacts;
      setLeadContact(foundContact);
      dispatch(setDialedContact(foundContact));
    }
  };

  useEffect(() => {
    if (typedPhone) findContact();
  }, [typedPhone, clickedProperty]);

  const searchContact = () => {
    findContact();
    if (leadContact && leadContact.id !== 0) {
      if (clickedProperty !== leadContact.property) {
        const contactProperty = properties.find(property => property.id === leadContact.property);
        const selectedProperty = properties.find(property => property.id === clickedProperty);
        setComparedProperties([contactProperty, selectedProperty]);
        setOpenConfirmPrompt(true);
      } else {
        callFromBrowser();
      }
    } else {
      callFromBrowser();
    }
  };

  const handleCallButtonClick = () => {
    let errors = false;
    if (isEmpty(dialedPhone) || dialedPhone.length < 10) {
      const phoneInputDialed = document.getElementsByClassName('dial-phone-number-input')[0];
      phoneInputDialed.classList.add('required');
      errors = true;
    }
    if (isEmpty(dialedProperty) || !clickedProperty) {
      const propertyDropdown = document.getElementById('currentPropertyDropdown');
      propertyDropdown.classList.add('required');
      errors = true;
    }
    if (!errors) searchContact();
  };

  const handleChangeProperty = (property: PropertyProps) => {
    dispatch(setDialedProperty(property));
    setClickedProperty(property.id);
    setDropdownState(false);
    const propertyDropdown = document.getElementById('currentPropertyDropdown');
    propertyDropdown.classList.remove('required');
  };

  const cleanPhoneNumber = (phone: string) => phone.replace(/\D/g, '');

  const formatPhoneNumber = (phone: string) => {
    const cleaned = cleanPhoneNumber(phone);
    const match = cleaned.match(/^\+?(\d{0,3})(\d{0,3})(\d{0,5})$/);
    if (match) {
      if (phone.startsWith('+')) {
        return `+${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}`;
      }
      return `${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}`;
    }
    return phone;
  };

  const setPhoneVars = (value: string) => {
    setTypedPhone(formatPhoneNumber(value));
    dispatch(setDialedPhoneNumber(cleanPhoneNumber(value)));
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    setPhoneVars(rawValue);
  };

  const keyClicked = (digit: string) => {
    const updatedPhone = typedPhone + digit;
    if (dialedPhone.length < 11) setPhoneVars(updatedPhone);
  };

  useEffect(() => {
    setClickedProperty(dialedProperty.id);
    setPhoneVars(dialedPhone);
    setLeadContact(dialedContact);
  }, [dialedProperty, dialedPhone, dialedContact]);

  const renderPropertiesDropdown = () => {
    const activeProperties = properties.filter(property => selectedProperties.some(selected => selected.id === property.id));
    const inactiveProperties = properties.filter(property => !selectedProperties.some(selected => selected.id === property.id));

    return (
      <>
        {activeProperties.map(property => (
          <PropertiesCallDropdownItem
            key={property.id}
            className={cn({ disabled: clickedProperty === property.id })}
            onClick={() => handleChangeProperty(property)}
          >
            {property.name}
          </PropertiesCallDropdownItem>
        ))}
        {inactiveProperties.length > 0 && <DropdownItem divider />}
        {inactiveProperties.map(property => (
          <PropertiesCallDropdownItem
            className="inactive"
            key={property.id}
            disabled
          >
            {property.name}
          </PropertiesCallDropdownItem>
        ))}
      </>
    );
  };

  return (
    <React.Fragment>
      <CallItem>
        <CallItemHeader>
          <CallerInfoContainer>
            <OutboundCallAvatar className="avatar" hideOnlineIcon>
              <CallWindowSVG
                xmlns="http://www.w3.org/2000/svg"
                data-lucide="phone-outgoing"
                viewBox="0 0 24 24"
                className="lucide lucide-phone-outgoing"
                style={{ width: '17px', height: '17px' }}
              >
                <OutboundPhone />
              </CallWindowSVG>
            </OutboundCallAvatar>
            <CallerInfoBody>
              <h6>Outbound Call</h6>
              <p><span>{dialedProperty.name}</span></p>
            </CallerInfoBody>
            <ChatHeaderLink className="ml-auto" onClick={() => handleClose(true)}>
              <i className="ri-close-fill" />
            </ChatHeaderLink>
          </CallerInfoContainer>
        </CallItemHeader>
        <CallBody collapsed={false}>
          <div id="select-property-item" className="pd-b-10">
            {openConfirmPrompt ? (
              <AlertCallItem id="alertCallItem" className="alert alert-warning">
                This phone number is associated with a resident at <strong>{properties.find(property => property.id === leadContact.property).name}</strong>.
              </AlertCallItem>
            ) : (
              <PropertiesCallDropdown
                className={cn({ 'no-click': openConfirmPrompt })}
                isOpen={dropdownOpen}
                toggle={() => setDropdownState(!dropdownOpen)}
              >
                <DropdownToggle id="currentPropertyDropdown" className="dropdown-link">
                  {!clickedProperty && selectedProperties.length > 1 ? 'Select property...' : dialedProperty.name}
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu"
                  style={{
                    position: 'absolute',
                    willChange: 'transform',
                    top: '0px',
                    left: '0px',
                    transform: 'translate3d(0px, 38px, 0px)',
                  }}
                >
                  {selectedProperties.length > 1 &&
                    <PropertiesCallDropdownItem
                      className={cn({ disabled: !clickedProperty })}
                      onClick={() => setClickedProperty(null)}
                    >
                      Select property...
                    </PropertiesCallDropdownItem>}
                  {renderPropertiesDropdown()}
                </DropdownMenu>
              </PropertiesCallDropdown>
            )}
          </div>
          <div id="call-item-header" className="position-relative mg-b-15">
            {showDialedContact && !openConfirmPrompt && leadContact ? (
              <DialedContact id="knownNumber" className="dial-phone-number-input">
                <a
                  style={{ color: '#0096FF', cursor: 'pointer' }}
                  onClick={() => push({
                    pathname: `/${properties.find(property => property.id === leadContact.property).external_id}/leads/${leadContact.id}`,
                  })}
                >
                  {leadContact.name}
                </a>
              </DialedContact>
            ) : (
              <Input
                className={cn('dial-phone-number-input', { onfocus: !isEmpty(typedPhone) })}
                type="text"
                id="phoneInputDialed"
                aria-describedby="phone_number"
                value={typedPhone}
                onChange={handlePhoneChange}
                required
                maxLength={15}
                disabled={openConfirmPrompt}
                onBlur={handleBlur}
              />
            )}
            {!openConfirmPrompt &&
              <DialDeleteButton
                id="clearPhoneNumber"
                className={cn('btn', { show: !isEmpty(dialedPhone) })}
                onClick={() => {
                  dispatch(setDialedPhoneNumber(''));
                  dispatch(setDialedContact(undefined));
                }}
              >
                <i className="ri-delete-back-2-line" />
              </DialDeleteButton>
            }
          </div>
          <div id="dial-window-keypad">
            <Keypad hide={false} useHeader={false} onKeyPadClick={keyClicked} />
          </div>
          <div id="call-propmpt">
            <CallConfirmPrompt className={cn('text-centered', { show: openConfirmPrompt })}>
              {leadContact &&
                  <div className="d-flex align-items-center mb-3">
                    <ConfirmAvatar>
                      <i className="ri-user-line tx-14" />
                    </ConfirmAvatar>
                    <a
                      style={{ color: '#0096FF', cursor: 'pointer' }}
                      className="ml-1"
                      onClick={() => push({
                        pathname: `/${properties.find(property => property.id === leadContact.property).external_id}/leads/${leadContact.id}`,
                      })}
                    >
                      {leadContact.name}
                    </a>
                  </div>
              }
              <p className="tx-15">Are you certain you wish to call from <strong className="tx-medium">{dialedProperty.name}</strong>?</p>
              <div className="mb-4">
                {comparedProperties.map(property => (
                  <>
                    <div className="custom-control custom-radio mb-2">
                      <input
                        type="radio"
                        id={`propRadio${property.id}`}
                        name="prop-radio"
                        className="custom-control-input"
                        checked={property.id === clickedProperty}
                        onClick={() => {
                          setClickedProperty(property.id);
                          dispatch(setDialedProperty(property));
                        }}
                      />
                      <label className="custom-control-label" htmlFor={`propRadio${property.id}`}>{property.name}</label>
                    </div>
                  </>
                ))}
              </div>
              <DialCallButton
                id="btnCallStart"
                className={cn('btn', 'btn-primary', 'btn-block', 'btn-dial-call', {
                  disabled: isCallBeingCreated,
                  btnOutlinePrimary: isCallBeingCreated,
                  btnInitiating: isCallBeingCreated,
                })}
                onClick={() => callFromBrowser()}
              >
                <div id="call-span">
                  <CallWindowSVG
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    data-lucide="phone"
                    className="lucide lucide-phone"
                  >
                    <OutboundPhone showPolylineAndLine={false} />
                  </CallWindowSVG>
                  <span>Initiate Call</span>
                </div>
                <div id="initiating-call-span">
                  <CallWindowSVG
                    xmlns="http://www.w3.org/2000/svg"
                    data-lucide="circle-dashed"
                    viewBox="0 0 24 24"
                    className="lucide lucide-circle-dashed"
                  >
                    <CircleCallIcon />
                  </CallWindowSVG>
                  <span>Initiating Call...</span>
                </div>
              </DialCallButton>
              <ButtonWhitePrompt
                id="btnCallCancel"
                className="btn btn-white justify-content-center w-100 mt-2"
                onClick={() => setOpenConfirmPrompt(false)}
              >
                Cancel
              </ButtonWhitePrompt>
            </CallConfirmPrompt>
          </div>
          <div id="dial-call-button">
            <DialCallButton
              id="btnCallStart"
              className={cn('btn', 'btn-primary', 'btn-block', 'btn-dial-call', {
                disabled: isCallBeingCreated,
                btnOutlinePrimary: isCallBeingCreated,
                btnInitiating: isCallBeingCreated,
              })}
              onClick={() => handleCallButtonClick()}
            >
              <div id="call-span">
                <CallWindowSVG
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  data-lucide="phone"
                  className="lucide lucide-phone"
                >
                  <OutboundPhone showPolylineAndLine={false} />
                </CallWindowSVG>
                <span>
                  Call
                </span>
              </div>
              <div id="initiating-call-span">
                <CallWindowSVG
                  xmlns="http://www.w3.org/2000/svg"
                  data-lucide="circle-dashed"
                  viewBox="0 0 24 24"
                  className="lucide lucide-circle-dashed"
                >
                  <CircleCallIcon />
                </CallWindowSVG>
                <span>Initiating Call...</span>
              </div>
            </DialCallButton>
          </div>
        </CallBody>
      </CallItem>
    </React.Fragment>
  );
};

export default DialWindow;
