export default {
  CALL_API: 'CALL_API',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  CREATE_LEADS_FILTER_FAILURE: 'CREATE_LEADS_FILTER_FAILURE',
  CREATE_LEADS_FILTER_REQUEST: 'CREATE_LEADS_FILTER_REQUEST',
  CREATE_LEADS_FILTER_SUCCESS: 'CREATE_LEADS_FILTER_SUCCESS',
  GET_LEADS_FILTER_FAILURE: 'GET_LEADS_FILTER_FAILURE',
  GET_LEADS_FILTER_REQUEST: 'GET_LEADS_FILTER_REQUEST',
  GET_LEADS_FILTER_SUCCESS: 'GET_LEADS_FILTER_SUCCESS',
  GET_LEADS_FILTER_BY_ID_FAILURE: 'GET_LEADS_FILTER_BY_ID_FAILURE',
  GET_LEADS_FILTER_BY_ID_REQUEST: 'GET_LEADS_FILTER_BY_ID_REQUEST',
  GET_LEADS_FILTER_BY_ID_SUCCESS: 'GET_LEADS_FILTER_BY_ID_SUCCESS',
  UPDATE_LEADS_FILTER_FAILURE: 'UPDATE_LEADS_FILTER_FAILURE',
  UPDATE_LEADS_FILTER_REQUEST: 'UPDATE_LEADS_FILTER_REQUEST',
  UPDATE_LEADS_FILTER_SUCCESS: 'UPDATE_LEADS_FILTER_SUCCESS',
  DELETE_LEADS_FILTER_FAILURE: 'DELETE_LEADS_FILTER_FAILURE',
  DELETE_LEADS_FILTER_REQUEST: 'DELETE_LEADS_FILTER_REQUEST',
  DELETE_LEADS_FILTER_SUCCESS: 'DELETE_LEADS_FILTER_SUCCESS',
  GET_LEAD_BY_ID_SUCCESS: 'GET_LEAD_BY_ID_SUCCESS',
  GET_LEAD_NAMES_REQUEST: 'GET_LEAD_NAMES_REQUEST',
  CLEAR_LEADS: 'CLEAR_LEADS',
  GET_NOTES_FAILURE: 'GET_NOTES_FAILURE',
  GET_NOTES_REQUEST: 'GET_NOTES_REQUEST',
  GET_NOTES_SUCCESS: 'GET_NOTES_SUCCESS',
  GET_NOTE_BY_ID_FAILURE: 'GET_NOTE_BY_ID_FAILURE',
  GET_NOTE_BY_ID_REQUEST: 'GET_NOTE_BY_ID_REQUEST',
  GET_NOTE_BY_ID_SUCCESS: 'GET_NOTE_BY_ID_SUCCESS',
  DELETE_NOTE_FAILURE: 'DELETE_NOTE_FAILURE',
  DELETE_NOTE_REQUEST: 'DELETE_NOTE_REQUEST',
  DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',
  UPDATE_NOTE_FAILURE: 'UPDATE_NOTE_FAILURE',
  UPDATE_NOTE_REQUEST: 'UPDATE_NOTE_REQUEST',
  UPDATE_NOTE_SUCCESS: 'UPDATE_NOTE_SUCCESS',
  CREATE_NOTE_FAILURE: 'CREATE_NOTE_FAILURE',
  CREATE_NOTE_REQUEST: 'CREATE_NOTE_REQUEST',
  CREATE_NOTE_SUCCESS: 'CREATE_NOTE_SUCCESS',
  GET_EMAIL_TEMPLATES_FAILURE: 'GET_EMAIL_TEMPLATES_FAILURE',
  GET_EMAIL_TEMPLATES_REQUEST: 'GET_EMAIL_TEMPLATES_REQUEST',
  GET_EMAIL_TEMPLATES_SUCCESS: 'GET_EMAIL_TEMPLATES_SUCCESS',
  DELETE_EMAIL_TEMPLATE_FAILURE: 'DELETE_EMAIL_TEMPLATE_FAILURE',
  DELETE_EMAIL_TEMPLATE_REQUEST: 'DELETE_EMAIL_TEMPLATE_REQUEST',
  DELETE_EMAIL_TEMPLATE_SUCCESS: 'DELETE_EMAIL_TEMPLATE_SUCCESS',
  UPDATE_EMAIL_TEMPLATE_FAILURE: 'UPDATE_EMAIL_TEMPLATE_FAILURE',
  UPDATE_EMAIL_TEMPLATE_REQUEST: 'UPDATE_EMAIL_TEMPLATE_REQUEST',
  UPDATE_EMAIL_TEMPLATE_SUCCESS: 'UPDATE_EMAIL_TEMPLATE_SUCCESS',
  CREATE_EMAIL_TEMPLATE_FAILURE: 'CREATE_EMAIL_TEMPLATE_FAILURE',
  CREATE_EMAIL_TEMPLATE_REQUEST: 'CREATE_EMAIL_TEMPLATE_REQUEST',
  CREATE_EMAIL_TEMPLATE_SUCCESS: 'CREATE_EMAIL_TEMPLATE_SUCCESS',
  GET_EMAIL_TEMPLATE_BY_ID_FAILURE: 'GET_EMAIL_TEMPLATE_BY_ID_FAILURE',
  GET_EMAIL_TEMPLATE_BY_ID_REQUEST: 'GET_EMAIL_TEMPLATE_BY_ID_REQUEST',
  GET_EMAIL_TEMPLATE_BY_ID_SUCCESS: 'GET_EMAIL_TEMPLATE_BY_ID_SUCCESS',

  GET_SMS_TEMPLATES_FAILURE: 'GET_SMS_TEMPLATES_FAILURE',
  GET_SMS_TEMPLATES_REQUEST: 'GET_SMS_TEMPLATES_REQUEST',
  GET_SMS_TEMPLATES_SUCCESS: 'GET_SMS_TEMPLATES_SUCCESS',
  DELETE_SMS_TEMPLATE_FAILURE: 'DELETE_SMS_TEMPLATE_FAILURE',
  DELETE_SMS_TEMPLATE_REQUEST: 'DELETE_SMS_TEMPLATE_REQUEST',
  DELETE_SMS_TEMPLATE_SUCCESS: 'DELETE_SMS_TEMPLATE_SUCCESS',
  UPDATE_SMS_TEMPLATE_FAILURE: 'UPDATE_SMS_TEMPLATE_FAILURE',
  UPDATE_SMS_TEMPLATE_REQUEST: 'UPDATE_SMS_TEMPLATE_REQUEST',
  UPDATE_SMS_TEMPLATE_SUCCESS: 'UPDATE_SMS_TEMPLATE_SUCCESS',
  CREATE_SMS_TEMPLATE_FAILURE: 'CREATE_SMS_TEMPLATE_FAILURE',
  CREATE_SMS_TEMPLATE_REQUEST: 'CREATE_SMS_TEMPLATE_REQUEST',
  CREATE_SMS_TEMPLATE_SUCCESS: 'CREATE_SMS_TEMPLATE_SUCCESS',
  GET_SMS_TEMPLATE_BY_ID_FAILURE: 'GET_SMS_TEMPLATE_BY_ID_FAILURE',
  GET_SMS_TEMPLATE_BY_ID_REQUEST: 'GET_SMS_TEMPLATE_BY_ID_REQUEST',
  GET_SMS_TEMPLATE_BY_ID_SUCCESS: 'GET_SMS_TEMPLATE_BY_ID_SUCCESS',

  DELETE_CHAT_TEMPLATE_FAILURE: 'DELETE_CHAT_TEMPLATE_FAILURE',
  DELETE_CHAT_TEMPLATE_REQUEST: 'DELETE_CHAT_TEMPLATE_REQUEST',
  DELETE_CHAT_TEMPLATE_SUCCESS: 'DELETE_CHAT_TEMPLATE_SUCCESS',
  UPDATE_CHAT_TEMPLATE_FAILURE: 'UPDATE_CHAT_TEMPLATE_FAILURE',
  UPDATE_CHAT_TEMPLATE_REQUEST: 'UPDATE_CHAT_TEMPLATE_REQUEST',
  UPDATE_CHAT_TEMPLATE_SUCCESS: 'UPDATE_CHAT_TEMPLATE_SUCCESS',
  CREATE_CHAT_TEMPLATE_FAILURE: 'CREATE_CHAT_TEMPLATE_FAILURE',
  CREATE_CHAT_TEMPLATE_REQUEST: 'CREATE_CHAT_TEMPLATE_REQUEST',
  CREATE_CHAT_TEMPLATE_SUCCESS: 'CREATE_CHAT_TEMPLATE_SUCCESS',
  GET_CHAT_TEMPLATE_BY_ID_FAILURE: 'GET_CHAT_TEMPLATE_BY_ID_FAILURE',
  GET_CHAT_TEMPLATE_BY_ID_REQUEST: 'GET_CHAT_TEMPLATE_BY_ID_REQUEST',
  GET_CHAT_TEMPLATE_BY_ID_SUCCESS: 'GET_CHAT_TEMPLATE_BY_ID_SUCCESS',
  GET_CHAT_TEMPLATES_FAILURE: 'GET_CHAT_TEMPLATES_FAILURE',
  GET_CHAT_TEMPLATES_REQUEST: 'GET_CHAT_TEMPLATES_REQUEST',
  GET_CHAT_TEMPLATES_SUCCESS: 'GET_CHAT_TEMPLATES_SUCCESS',
  GET_LEAD_LEVEL_NOTES_FAILURE: 'GET_LEAD_LEVEL_NOTES_FAILURE',
  GET_LEAD_LEVEL_NOTES_REQUEST: 'GET_LEAD_LEVEL_NOTES_REQUEST',
  GET_LEAD_LEVEL_NOTES_SUCCESS: 'GET_LEAD_LEVEL_NOTES_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',
  UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  CREATE_NOTIFICATION_FAILURE: 'CREATE_NOTIFICATION_FAILURE',
  CREATE_NOTIFICATION_REQUEST: 'CREATE_NOTIFICATION_REQUEST',
  CREATE_NOTIFICATION_SUCCESS: 'CREATE_NOTIFICATION_SUCCESS',
  CLEAR_ALL_NOTIFICATIONS_REQUEST: 'CLEAR_ALL_NOTIFICATIONS_REQUEST',
  CLEAR_ALL_NOTIFICATIONS_SUCCESS: 'CLEAR_ALL_NOTIFICATIONS_SUCCESS',
  CLEAR_ALL_NOTIFICATIONS_FAILURE: 'CLEAR_ALL_NOTIFICATIONS_FAILURE',
  READ_ALL_NOTIFICATIONS_REQUEST: 'READ_ALL_NOTIFICATIONS_REQUEST',
  READ_ALL_NOTIFICATIONS_SUCCESS: 'READ_ALL_NOTIFICATIONS_SUCCESS',
  READ_ALL_NOTIFICATIONS_FAILURE: 'READ_ALL_NOTIFICATIONS_FAILURE',
  TEST_RESMAN_REQUEST: 'TEST_RESMAN_REQUEST',
  TEST_RESMAN_SUCCESS: 'TEST_RESMAN_SUCCESS',
  TEST_RESMAN_FAILURE: 'TEST_RESMAN_FAILURE',
  GET_NYLAS_AUTH_FAILURE: 'GET_NYLAS_AUTH_FAILURE',
  GET_NYLAS_AUTH_REQUEST: 'GET_NYLAS_AUTH_REQUEST',
  GET_NYLAS_AUTH_SUCCESS: 'GET_NYLAS_AUTH_SUCCESS',
  GET_NYLAS_TOKEN_REQUEST: 'GET_NYLAS_TOKEN_REQUEST',
  GET_NYLAS_TOKEN_SUCCESS: 'GET_NYLAS_TOKEN_SUCCESS',
  GET_NYLAS_TOKEN_FAILURE: 'GET_NYLAS_TOKEN_FAILURE',
  GET_EMAIL_LABELS_REQUEST: 'GET_EMAIL_LABELS_REQUEST',
  GET_EMAIL_LABELS_SUCCESS: 'GET_EMAIL_LABELS_SUCCESS',
  GET_EMAIL_LABELS_FAILURE: 'GET_EMAIL_LABELS_FAILURE',
  GET_EMAIL_MESSAGES_REQUEST: 'GET_EMAIL_MESSAGES_REQUEST',
  GET_EMAIL_MESSAGES_SUCCESS: 'GET_EMAIL_MESSAGES_SUCCESS',
  GET_EMAIL_MESSAGES_FAILURE: 'GET_EMAIL_MESSAGES_FAILURE',
  GET_EMAIL_MESSAGE_BY_ID_REQUEST: 'GET_EMAIL_MESSAGE_BY_ID_REQUEST',
  GET_EMAIL_MESSAGE_BY_ID_SUCCESS: 'GET_EMAIL_MESSAGE_BY_ID_SUCCESS',
  GET_EMAIL_MESSAGE_BY_ID_FAILURE: 'GET_EMAIL_MESSAGE_BY_ID_FAILURE',
  UPDATE_EMAIL_MESSAGE_REQUEST: 'UPDATE_EMAIL_MESSAGE_REQUEST',
  UPDATE_EMAIL_MESSAGE_SUCCESS: 'UPDATE_EMAIL_MESSAGE_SUCCESS',
  UPDATE_EMAIL_MESSAGE_FAILURE: 'UPDATE_EMAIL_MESSAGE_FAILURE',
  ARCHIVE_EMAIL_MESSAGE_REQUEST: 'ARCHIVE_EMAIL_MESSAGE_REQUEST',
  ARCHIVE_EMAIL_MESSAGE_SUCCESS: 'ARCHIVE_EMAIL_MESSAGE_SUCCESS',
  ARCHIVE_EMAIL_MESSAGE_FAILURE: 'ARCHIVE_EMAIL_MESSAGE_FAILURE',
  SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',
  ARCHIVE_MESSAGES_REQUEST: 'ARCHIVE_MESSAGES_REQUEST',
  ARCHIVE_MESSAGES_SUCCESS: 'ARCHIVE_MESSAGES_SUCCESS',
  ARCHIVE_MESSAGES_FAILURE: 'ARCHIVE_MESSAGES_FAILURE',
  GET_LEAD_CONVERSATIONS_REQUEST: 'GET_LEAD_CONVERSATIONS_REQUEST',
  GET_LEAD_CONVERSATIONS_SUCCESS: 'GET_LEAD_CONVERSATIONS_SUCCESS',
  GET_LEAD_CONVERSATIONS_FAILURE: 'GET_LEAD_CONVERSATIONS_FAILURE',
  GET_PROPERTY_DETAILS_SUCCESS: 'GET_PROPERTY_DETAILS_SUCCESS',
  SEND_BULK_EMAIL_REQUEST: 'SEND_BULK_EMAIL_REQUEST',
  SEND_BULK_EMAIL_SUCCESS: 'SEND_BULK_EMAIL_SUCCESS',
  SEND_BULK_EMAIL_FAILURE: 'SEND_BULK_EMAIL_FAILURE',
  MARK_EMAIL_MESSAGE_AS_READ_REQUEST: 'MARK_EMAIL_MESSAGE_AS_READ_REQUEST',
  MARK_EMAIL_MESSAGE_AS_READ_SUCCESS: 'MARK_EMAIL_MESSAGE_AS_READ_SUCCESS',
  MARK_EMAIL_MESSAGE_AS_READ_FAILURE: 'MARK_EMAIL_MESSAGE_AS_READ_FAILURE',
  GET_COLUMNS_SETTINGS_REQUEST: 'GET_COLUMNS_SETTINGS_REQUEST',
  GET_COLUMNS_SETTINGS_SUCCESS: 'GET_COLUMNS_SETTINGS_SUCCESS',
  GET_COLUMNS_SETTINGS_FAILURE: 'GET_COLUMNS_SETTINGS_FAILURE',
  CREATE_COLUMNS_SETTINGS_REQUEST: 'CREATE_COLUMNS_SETTINGS_REQUEST',
  CREATE_COLUMNS_SETTINGS_SUCCESS: 'CREATE_COLUMNS_SETTINGS_SUCCESS',
  CREATE_COLUMNS_SETTINGS_FAILURE: 'CREATE_COLUMNS_SETTINGS_FAILURE',
  UPDATE_COLUMNS_SETTINGS_REQUEST: 'UPDATE_COLUMNS_SETTINGS_REQUEST',
  UPDATE_COLUMNS_SETTINGS_SUCCESS: 'UPDATE_COLUMNS_SETTINGS_SUCCESS',
  UPDATE_COLUMNS_SETTINGS_FAILURE: 'UPDATE_COLUMNS_SETTINGS_FAILURE',
  GET_ROOMMATES_FAILURE: 'GET_ROOMMATES_FAILURE',
  GET_ROOMMATES_REQUEST: 'GET_ROOMMATES_REQUEST',
  GET_ROOMMATES_SUCCESS: 'GET_ROOMMATES_SUCCESS',
  GET_ROOMMATE_BY_ID_FAILURE: 'GET_ROOMMATE_BY_ID_FAILURE',
  GET_ROOMMATE_BY_ID_REQUEST: 'GET_ROOMMATE_BY_ID_REQUEST',
  GET_ROOMMATE_BY_ID_SUCCESS: 'GET_ROOMMATE_BY_ID_SUCCESS',
  DELETE_ROOMMATE_FAILURE: 'DELETE_ROOMMATE_FAILURE',
  DELETE_ROOMMATE_REQUEST: 'DELETE_ROOMMATE_REQUEST',
  DELETE_ROOMMATE_SUCCESS: 'DELETE_ROOMMATE_SUCCESS',
  UPDATE_ROOMMATES_FAILURE: 'UPDATE_ROOMMATE_FAILURE',
  UPDATE_ROOMMATES_REQUEST: 'UPDATE_ROOMMATE_REQUEST',
  UPDATE_ROOMMATES_SUCCESS: 'UPDATE_ROOMMATE_SUCCESS',
  PUSHER_UPDATE_RECORD: 'PUSHER_UPDATE_RECORD',
  PUSHER_CREATE_RECORD: 'PUSHER_CREATE_RECORD',
  PUSHER_DELETE_RECORD: 'PUSHER_DELETE_RECORD',
  GET_PORTFOLIO_REQUEST: 'GET_PORTFOLIO_REQUEST',
  GET_PORTFOLIO_SUCCESS: 'GET_PORTFOLIO_SUCCESS',
  GET_PORTFOLIO_FAILURE: 'GET_PORTFOLIO_FAILURE',
  GET_OVERVIEW_REPORTS_REQUEST: 'GET_OVERVIEW_REPORTS_REQUEST',
  GET_OVERVIEW_REPORTS_SUCCESS: 'GET_OVERVIEW_REPORTS_SUCCESS',
  GET_OVERVIEW_REPORTS_FAILURE: 'GET_OVERVIEW_REPORTS_FAILURE',
  GET_MARKETING_REPORTS_REQUEST: 'GET_MARKETING_REPORTS_REQUEST',
  GET_MARKETING_REPORTS_SUCCESS: 'GET_MARKETING_REPORTS_SUCCESS',
  GET_MARKETING_REPORTS_FAILURE: 'GET_MARKETING_REPORTS_FAILURE',
  GET_SOURCES_FAILURE: 'GET_SOURCES_FAILURE',
  GET_SOURCES_REQUEST: 'GET_SOURCES_REQUEST',
  GET_SOURCES_SUCCESS: 'GET_SOURCES_SUCCESS',
  UPDATE_SOURCES_FAILURE: 'UPDATE_SOURCES_FAILURE',
  UPDATE_SOURCES_REQUEST: 'UPDATE_SOURCES_REQUEST',
  UPDATE_SOURCES_SUCCESS: 'UPDATE_SOURCES_SUCCESS',
  TEST_TASK_NOTIFICATIONS_REQUEST: 'TEST_TASK_NOTIFICATIONS_REQUEST',
  TEST_TASK_NOTIFICATIONS_SUCCESS: 'TEST_TASK_NOTIFICATIONS_SUCCESS',
  TEST_TASK_NOTIFICATIONS_FAILURE: 'TEST_TASK_NOTIFICATIONS_FAILURE',
  GET_OPERATIONS_REPORTS_REQUEST: 'GET_OPERATIONS_REPORTS_REQUEST',
  GET_OPERATIONS_REPORTS_SUCCESS: 'GET_OPERATIONS_REPORTS_SUCCESS',
  GET_OPERATIONS_REPORTS_FAILURE: 'GET_OPERATIONS_REPORTS_FAILURE',
  CLEAR_REPORTS: 'CLEAR_REPORTS',
  GET_CALLS_REQUEST: 'GET_CALLS_REQUEST',
  GET_CALLS_SUCCESS: 'GET_CALLS_SUCCESS',
  GET_CALLS_FAILURE: 'GET_CALLS_FAILURE',
  GET_CALL_BY_ID_REQUEST: 'GET_CALL_BY_ID_REQUEST',
  GET_CALL_BY_ID_SUCCESS: 'GET_CALL_BY_ID_SUCCESS',
  GET_CALL_BY_ID_FAILURE: 'GET_CALL_BY_ID_FAILURE',
  UPDATE_CALL_REQUEST: 'UPDATE_CALL_REQUEST',
  UPDATE_CALL_SUCCESS: 'UPDATE_CALL_SUCCESS',
  UPDATE_CALL_FAILURE: 'UPDATE_CALL_FAILURE',
  ARCHIVE_CALLS_REQUEST: 'ARCHIVE_CALLS_REQUEST',
  ARCHIVE_CALLS_SUCCESS: 'ARCHIVE_CALLS_SUCCESS',
  ARCHIVE_CALLS_FAILURE: 'ARCHIVE_CALLS_FAILURE',
  ARCHIVE_CALL_REQUEST: 'ARCHIVE_CALL_REQUEST',
  ARCHIVE_CALL_SUCCESS: 'ARCHIVE_CALL_SUCCESS',
  ARCHIVE_CALL_FAILURE: 'ARCHIVE_CALL_FAILURE',

  UNARCHIVE_CALL_FAILURE: 'UNARCHIVE_CALL_FAILURE',
  UNARCHIVE_CALL_REQUEST: 'UNARCHIVE_CALL_REQUEST',
  UNARCHIVE_CALL_SUCCESS: 'UNARCHIVE_CALL_SUCCESS',

  SAVE_CALL_NOTES_REQUEST: 'SAVE_CALL_NOTES_REQUEST',
  SAVE_CALL_NOTES_SUCCESS: 'SAVE_CALL_NOTES_SUCCESS',
  SAVE_CALL_NOTES_FAILURE: 'SAVE_CALL_NOTES_FAILURE',

  GET_CHAT_REPORTS_REQUEST: 'GET_CHAT_REPORTS_REQUEST',
  GET_CHAT_REPORTS_SUCCESS: 'GET_CHAT_REPORTS_SUCCESS',
  GET_CHAT_REPORTS_FAILURE: 'GET_CHAT_REPORTS_FAILURE',
  GET_SINGLE_CHAT_REPORT_REQUEST: 'GET_SINGLE_CHAT_REPORT_REQUEST',
  GET_SINGLE_CHAT_REPORT_SUCCESS: 'GET_SINGLE_CHAT_REPORT_SUCCESS',
  GET_SINGLE_CHAT_REPORT_FAILURE: 'GET_SINGLE_CHAT_REPORT_FAILURE',
  GET_CHAT_REPORT_BY_ID_REQUEST: 'GET_CHAT_REPORT_BY_ID_REQUEST',
  GET_CHAT_REPORT_BY_ID_SUCCESS: 'GET_CHAT_REPORT_BY_ID_SUCCESS',
  GET_CHAT_REPORT_BY_ID_FAILURE: 'GET_CHAT_REPORT_BY_ID_FAILURE',
  EXPORT_CHAT_REPORT_BY_ID_REQUEST: 'EXPORT_CHAT_REPORT_BY_ID_REQUEST',
  EXPORT_CHAT_REPORT_BY_ID_SUCCESS: 'EXPORT_CHAT_REPORT_BY_ID_SUCCESS',
  EXPORT_CHAT_REPORT_BY_ID_FAILURE: 'EXPORT_CHAT_REPORT_BY_ID_FAILURE',
  GET_CHAT_REPORT_EVALUATION_BY_ID_REQUEST: 'GET_CHAT_REPORT_EVALUATION_BY_ID_REQUEST',
  GET_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS: 'GET_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS',
  GET_CHAT_REPORT_EVALUATION_BY_ID_FAILURE: 'GET_CHAT_REPORT_EVALUATION_BY_ID_FAILURE',
  UPDATE_CHAT_REPORT_EVALUATION_BY_ID_REQUEST: 'UPDATE_CHAT_REPORT_EVALUATION_BY_ID_REQUEST',
  UPDATE_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS: 'UPDATE_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS',
  UPDATE_CHAT_REPORT_EVALUATION_BY_ID_FAILURE: 'UPDATE_CHAT_REPORT_EVALUATION_BY_ID_FAILURE',
  GET_CHAT_REPORT_MESSAGE_BY_ID_FAILURE: 'GET_CHAT_REPORT_MESSAGE_BY_ID_FAILURE',
  GET_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS: 'GET_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS',
  GET_CHAT_REPORT_MESSAGE_BY_ID_REQUEST: 'GET_CHAT_REPORT_MESSAGE_BY_ID_REQUEST',
  UPDATE_CHAT_REPORT_MESSAGE_BY_ID_FAILURE: 'UPDATE_CHAT_REPORT_MESSAGE_BY_ID_FAILURE',
  UPDATE_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS: 'UPDATE_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS',
  UPDATE_CHAT_REPORT_MESSAGE_BY_ID_REQUEST: 'UPDATE_CHAT_REPORT_MESSAGE_BY_ID_REQUEST',
  UPDATE_EVALUATION_REPORT_REQUEST: 'UPDATE_EVALUATION_REPORT_REQUEST',
  UPDATE_EVALUATION_REPORT_SUCCESS: 'UPDATE_EVALUATION_REPORT_SUCCESS',
  UPDATE_EVALUATION_REPORT_FAILURE: 'UPDATE_EVALUATION_REPORT_FAILURE',
  CHANGE_EVALUATION_REPORT_SUCCESS: 'CHANGE_EVALUATION_REPORT_SUCCESS',
  CHANGE_EVALUATION_REPORT_REQUEST: 'CHANGE_EVALUATION_REPORT_REQUEST',
  CHANGE_EVALUATION_REPORT_FAILURE: 'CHANGE_EVALUATION_REPORT_FAILURE',
  AUTOSAVE_EVALUATION_REPORT_REQUEST: 'AUTOSAVE_EVALUATION_REPORT_REQUEST',
  AUTOSAVE_EVALUATION_REPORT_REQUEST_SUCCESS: 'AUTOSAVE_EVALUATION_REPORT_SUCCESS',
  AUTOSAVE_EVALUATION_REPORT_REQUEST_FAILURE: 'AUTOSAVE_EVALUATION_REPORT_FAILURE',
  GET_COMPETITORS_REQUEST: 'GET_COMPETITORS_REQUEST',
  GET_COMPETITORS_SUCCESS: 'GET_COMPETITORS_SUCCESS',
  GET_COMPETITORS_FAILURE: 'GET_COMPETITORS_FAILURE',
  UPDATE_COMPETITOR_REQUEST: 'UPDATE_COMPETITOR_REQUEST',
  UPDATE_COMPETITOR_SUCCESS: 'UPDATE_COMPETITOR_SUCCESS',
  UPDATE_COMPETITOR_FAILURE: 'UPDATE_COMPETITOR_FAILURE',
  CREATE_COMPETITOR_REQUEST: 'CREATE_COMPETITOR_REQUEST',
  CREATE_COMPETITOR_SUCCESS: 'CREATE_COMPETITOR_SUCCESS',
  CREATE_COMPETITOR_FAILURE: 'CREATE_COMPETITOR_FAILURE',
  DELETE_COMPETITOR_REQUEST: 'DELETE_COMPETITOR_REQUEST',
  DELETE_COMPETITOR_SUCCESS: 'DELETE_COMPETITOR_SUCCESS',
  DELETE_COMPETITOR_FAILURE: 'DELETE_COMPETITOR_FAILURE',
  UPDATE_SPENDS_FAILURE: 'UPDATE_SPENDS_FAILURE',
  UPDATE_SPENDS_REQUEST: 'UPDATE_SPENDS_REQUEST',
  UPDATE_SPENDS_SUCCESS: 'UPDATE_SPENDS_SUCCESS',
  GET_SURVEYS_REQUEST: 'GET_SURVEYS_REQUEST',
  GET_SURVEYS_SUCCESS: 'GET_SURVEYS_SUCCESS',
  GET_SURVEYS_FAILURE: 'GET_SURVEYS_FAILURE',
  UPDATE_SURVEYS_REQUEST: 'UPDATE_SURVEYS_REQUEST',
  UPDATE_SURVEYS_SUCCESS: 'UPDATE_SURVEYS_SUCCESS',
  UPDATE_SURVEYS_FAILURE: 'UPDATE_SURVEYS_FAILURE',
  PUSHER_CLEAR: 'PUSHER_CLEAR',
  SET_ACTIVE_FILTER_FAILURE: 'SET_ACTIVE_FILTER_FAILURE',
  SET_ACTIVE_FILTER_REQUEST: 'SET_ACTIVE_FILTER_REQUEST',
  SET_ACTIVE_FILTER_SUCCESS: 'SET_ACTIVE_FILTER_SUCCESS',
  GET_ACTIVE_FILTER_FAILURE: 'GET_ACTIVE_FILTER_FAILURE',
  GET_ACTIVE_FILTER_REQUEST: 'GET_ACTIVE_FILTER_REQUEST',
  GET_ACTIVE_FILTER_SUCCESS: 'GET_ACTIVE_FILTER_SUCCESS',
  SET_ACTIVE_FILTER: 'SET_ACTIVE_FILTER',
  MERGE_LEADS_FAILURE: 'MERGE_LEADS_FAILURE',
  MERGE_LEADS_REQUEST: 'MERGE_LEADS_REQUEST',
  MERGE_LEADS_SUCCESS: 'MERGE_LEADS_SUCCESS',
  SMS_CONTACTS_REQUEST: 'SMS_CONTACTS_REQUEST',
  SMS_CONTACTS_SUCCESS: 'SMS_CONTACTS_SUCCESS',
  SMS_CONTACTS_FAILURE: 'SMS_CONTACTS_FAILURE',
  GET_CONVERSATION_BY_ID_REQUEST: 'GET_CONVERSATION_BY_ID_REQUEST',
  GET_CONVERSATION_BY_ID_SUCCESS: 'GET_CONVERSATION_BY_ID_SUCCESS',
  GET_CONVERSATION_BY_ID_FAILURE: 'GET_CONVERSATION_BY_ID_FAILURE',
  SEND_TEXT_TO_LEAD_REQUEST: 'SEND_TEXT_TO_LEAD_REQUEST',
  SEND_TEXT_TO_LEAD_SUCCESS: 'SEND_TEXT_TO_LEAD_SUCCESS',
  SEND_TEXT_TO_LEAD_FAILURE: 'SEND_TEXT_TO_LEAD_FAILURE',
  UPDATE_CURRENT_CONTACT: 'UPDATE_CURRENT_CONTACT',
  REQUIRE_RESCORE_CALL_SUCCESS: 'REQUIRE_RESCORE_CALL_SUCCESS',
  SMS_READ_ALL_REQUEST: 'SMS_READ_ALL_REQUEST',
  SMS_READ_ALL_SUCCESS: 'SMS_READ_ALL_SUCCESS',
  SMS_READ_ALL_FAILURE: 'SMS_READ_ALL_FAILURE',
  UPDATE_OPEN_COMPOSER: 'UPDATE_OPEN_COMPOSER',
  RESET_CONVERSATION_COMPOSER: 'RESET_CONVERSATION_COMPOSER',
  SHARE_LEAD_REQUEST: 'SHARE_LEAD_REQUEST',
  SHARE_LEAD_SUCCESS: 'SHARE_LEAD_SUCCESS',
  SHARE_LEAD_FAILURE: 'SHARE_LEAD_FAILURE',
  TRANSFER_LEAD_REQUEST: 'TRANSFER_LEAD_REQUEST',
  TRANSFER_LEAD_SUCCESS: 'TRANSFER_LEAD_SUCCESS',
  TRANSFER_LEAD_FAILURE: 'TRANSFER_LEAD_FAILURE',
  GET_PROSPECTS_REQUEST: 'GET_PROSPECTS_REQUEST',
  GET_PROSPECTS_SUCCESS: 'GET_PROSPECTS_SUCCESS',
  GET_PROSPECTS_FAILURE: 'GET_PROSPECTS_FAILURE',
  SET_ACTIVE_CHAT: 'SET_ACTIVE_CHAT',
  GET_PROSPECT_CONVERSATION_BY_ID_REQUEST: 'GET_PROSPECT_CONVERSATION_BY_ID_REQUEST',
  GET_PROSPECT_CONVERSATION_BY_ID_SUCCESS: 'GET_PROSPECT_CONVERSATION_BY_ID_SUCCESS',
  GET_PROSPECT_CONVERSATION_BY_ID_FAILURE: 'GET_PROSPECT_CONVERSATION_BY_ID_FAILURE',
  SEARCH_PROSPECT_CHAT_REQUEST: 'SEARCH_PROSPECT_CHAT_REQUEST',
  SEARCH_PROSPECT_CHAT_SUCCESS: 'SEARCH_PROSPECT_CHAT_SUCCESS',
  SEARCH_PROSPECT_CHAT_FAILURE: 'SEARCH_PROSPECT_CHAT_FAILURE',
  JOIN_PROSPECT_CHAT_REQUEST: 'JOIN_PROSPECT_CHAT_REQUEST',
  JOIN_PROSPECT_CHAT_SUCCESS: 'JOIN_PROSPECT_CHAT_SUCCESS',
  JOIN_PROSPECT_CHAT_FAILURE: 'JOIN_PROSPECT_CHAT_FAILURE',
  PROSPECT_CHAT_READALL_REQUEST: 'PROSPECT_CHAT_READALL_REQUEST',
  PROSPECT_CHAT_READALL_SUCCESS: 'PROSPECT_CHAT_READALL_SUCCESS',
  PROSPECT_CHAT_READALL_FAILURE: 'PROSPECT_CHAT_READALL_FAILURE',
  SEND_TEXT_TO_PROSPECT_REQUEST: 'SEND_TEXT_TO_PROSPECT_REQUEST',
  SEND_TEXT_TO_PROSPECT_SUCCESS: 'SEND_TEXT_TO_PROSPECT_SUCCESS',
  SEND_TEXT_TO_PROSPECT_FAILURE: 'SEND_TEXT_TO_PROSPECT_FAILURE',
  CHANGE_PROSPECT_CHAT_STATUS_REQUEST: 'CHANGE_PROSPECT_CHAT_STATUS_REQUEST',
  CHANGE_PROSPECT_CHAT_STATUS_SUCCESS: 'CHANGE_PROSPECT_CHAT_STATUS_SUCCESS',
  CHANGE_PROSPECT_CHAT_STATUS_FAILURE: 'CHANGE_PROSPECT_CHAT_STATUS_FAILURE',
  CLEAR_PROSPECT_JOIN_ALERT: 'CLEAR_PROSPECT_JOIN_ALERT',
  CLEAR_NEW_MESSAGE_ALERT: 'CLEAR_NEW_MESSAGE_ALERT',
  CLEAR_NEW_AGENT_REQUEST_ALERT: 'CLEAR_NEW_AGENT_REQUEST_ALERT',
  SET_PROSPECTS_OFFLINE: 'SET_PROSPECTS_OFFLINE',
  UPDATE_USER_AVAILABLE_STATUS_REQUEST: 'UPDATE_USER_AVAILABLE_STATUS_REQUEST',
  REMOVE_FROM_PROSPECTS_REQUESTED_AGENT: 'REMOVE_FROM_PROSPECTS_REQUESTED_AGENT',
  DECLINE_AGENT_REQUEST_REQUEST: 'DECLINE_AGENT_REQUEST_REQUEST',
  DECLINE_AGENT_REQUEST_SUCCESS: 'DECLINE_AGENT_REQUEST_SUCCESS',
  DECLINE_AGENT_REQUEST_FAILURE: 'DECLINE_AGENT_REQUEST_FAILURE',
  SET_EMAIL_OPEN_STATUS: 'SET_EMAIL_OPEN_STATUS',
  SET_CHAT_MINIMISE_STATUS: 'SET_CHAT_MINIMISE_STATUS',
  SET_CHAT_IS_RENDERED: 'SET_CHAT_IS_RENDERED',
  SET_CHAT_TYPE: 'SET_CHAT_TYPE',
  BULK_CLEAR_NOTIFICATIONS_REQUEST: 'BULK_CLEAR_NOTIFICATIONS_REQUEST',
  BULK_CLEAR_NOTIFICATIONS_SUCCESS: 'BULK_CLEAR_NOTIFICATIONS_SUCCESS',
  BULK_CLEAR_NOTIFICATIONS_FAILURE: 'BULK_CLEAR_NOTIFICATIONS_FAILURE',
  UPDATE_NOTIFICATION_REDIRECTION: 'UPDATE_NOTIFICATION_REDIRECTION',
  PUSHER_CLEAR_TEXT: 'PUSHER_CLEAR_TEXT',
  SET_CHAT_AS_ACTIVE: 'SET_CHAT_AS_ACTIVE',
  CLEAR_ACTIVE_CHATS: 'CLEAR_ACTIVE_CHATS',
  SET_ACTIVE_PROPERTIES: 'SET_ACTIVE_PROPERTIES',
  GET_PROSPECTS_BY_PROPERTY_REQUEST: 'GET_PROSPECTS_BY_PROPERTY_REQUEST',
  GET_PROSPECTS_BY_PROPERTY_SUCCESS: 'GET_PROSPECTS_BY_PROPERTY_SUCCESS',
  GET_PROSPECTS_BY_PROPERTY_FAILURE: 'GET_PROSPECTS_BY_PROPERTY_FAILURE',
  REMOVE_PROSPECTS_BY_PROPERTY: 'REMOVE_PROSPECTS_BY_PROPERTY',
  REMOVE_FROM_ACTIVE_CHATS: 'REMOVE_FROM_ACTIVE_CHATS',
  REMOVE_LEAD_FROM_ACTIVE_CHATS: 'REMOVE_LEAD_FROM_ACTIVE_CHATS',
  GET_CHAT_CONVERSATIONS_REQUEST: 'GET_CHAT_CONVERSATIONS_REQUEST',
  GET_CHAT_CONVERSATIONS_SUCCESS: 'GET_CHAT_CONVERSATIONS_SUCCESS',
  GET_CHAT_CONVERSATIONS_FAILURE: 'GET_CHAT_CONVERSATIONS_FAILURE',
  SEND_PASSWORD_RESET_EMAIL_FAILURE: 'SEND_PASSWORD_RESET_EMAIL_FAILURE',
  SEND_PASSWORD_RESET_EMAIL_REQUEST: 'SEND_PASSWORD_RESET_EMAIL_REQUEST',
  SEND_PASSWORD_RESET_EMAIL_SUCCESS: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_CHANGE_PASSWORD_STATE: 'RESET_CHANGE_PASSWORD_STATE',
  MINIMIZE_CHAT_WINDOW: 'MINIMIZE_CHAT_WINDOW',
  REORDER_ACTIVE_CHATS: 'REORDER_ACTIVE_CHATS',
  PROSPECT_CHAT_DISMISS_NEW_MESSAGE_REQUEST: 'PROSPECT_CHAT_DISMISS_NEW_MESSAGE_REQUEST',
  PROSPECT_CHAT_DISMISS_NEW_MESSAGE_SUCCESS: 'PROSPECT_CHAT_DISMISS_NEW_MESSAGE_SUCCESS',
  PROSPECT_CHAT_DISMISS_NEW_MESSAGE_FAILURE: 'PROSPECT_CHAT_DISMISS_NEW_MESSAGE_FAILURE',
  PROPERTY_DELETE_REQUEST: 'PROPERTY_DELETE_REQUEST',
  PROPERTY_DELETE_SUCCESS: 'PROPERTY_DELETE_SUCCESS',
  PROPERTY_DELETE_FAILURE: 'PROPERTY_DELETE_FAILURE',
  UPDATE_COMPOSER_MINIMISE: 'UPDATE_COMPOSER_MINIMISE',
  SET_ACTIVE_CHAT_SLIDE: 'SET_ACTIVE_CHAT_SLIDE',
  SET_EMAIL_LEAD_ID: 'SET_EMAIL_LEAD_ID',
  SET_EMAIL_BODY: 'SET_EMAIL_BODY',
  SET_EMAIL_SUBJECT: 'SET_EMAIL_SUBJECT',
  SET_SELECTED_EMAIL_TEMPLATE_ID: 'SET_SELECTED_EMAIL_TEMPLATE_ID',
  SET_EMAIL_SUBJECT_VARIABLES: 'SET_EMAIL_SUBJECT_VARIABLES',
  SET_FILES: 'SET_FILES',
  SET_IS_SHOWING_CC: 'SET_IS_SHOWING_CC',
  SET_EMAIL_CC: 'SET_EMAIL_CC',
  CLEAR_EMAIl_CONTENT: 'CLEAR_EMAIl_CONTENT',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB',
  SEND_TYPING_STATE_REQUEST: 'SEND_TYPING_STATE_REQUEST',
  SEND_TYPING_STATE_SUCCESS: 'SEND_TYPING_STATE_SUCCESS',
  SEND_TYPING_STATE_FAILURE: 'SEND_TYPING_STATE_FAILURE',
  CLEAR_TYPING: 'CLEAR_TYPING',
  GET_LEAD_SOURCE_DRILLDOWN_REQUEST: 'GET_LEAD_SOURCE_DRILLDOWN_REQUEST',
  GET_LEAD_SOURCE_DRILLDOWN_SUCCESS: 'GET_LEAD_SOURCE_DRILLDOWN_SUCCESS',
  GET_LEAD_SOURCE_DRILLDOWN_FAILURE: 'GET_LEAD_SOURCE_DRILLDOWN_FAILURE',
  GET_LEAD_LOST_DRILLDOWN_REQUEST: 'GET_LEAD_LOST_DRILLDOWN_REQUEST',
  GET_LEAD_LOST_DRILLDOWN_SUCCESS: 'GET_LEAD_LOST_DRILLDOWN_SUCCESS',
  GET_LEAD_LOST_DRILLDOWN_FAILURE: 'GET_LEAD_LOST_DRILLDOWN_FAILURE',
  UPDATE_USER_LAST_PROPERTY_REQUEST: 'UPDATE_USER_LAST_PROPERTY_REQUEST',
  UPDATE_USER_LAST_PROPERTY_SUCCESS: 'UPDATE_USER_LAST_PROPERTY_SUCCESS',
  UPDATE_USER_LAST_PROPERTY_FAILURE: 'UPDATE_USER_LAST_PROPERTY_FAILURE',
  GET_LEAD_FOR_PROSPECT_REQUEST: 'GET_LEAD_FOR_PROSPECT_REQUEST',

  DELETE_TARGET_NUMBER_FAILURE: 'DELETE_TARGET_NUMBER_FAILURE',
  DELETE_TARGET_NUMBER_REQUEST: 'DELETE_TARGET_NUMBER_REQUEST',
  DELETE_TARGET_NUMBER_SUCCESS: 'DELETE_TARGET_NUMBER_SUCCESS',
  UPDATE_TARGET_NUMBER_FAILURE: 'UPDATE_TARGET_NUMBER_FAILURE',
  UPDATE_TARGET_NUMBER_REQUEST: 'UPDATE_TARGET_NUMBER_REQUEST',
  UPDATE_TARGET_NUMBER_SUCCESS: 'UPDATE_TARGET_NUMBER_SUCCESS',
  CREATE_TARGET_NUMBER_FAILURE: 'CREATE_TARGET_NUMBER_FAILURE',
  CREATE_TARGET_NUMBER_REQUEST: 'CREATE_TARGET_NUMBER_REQUEST',
  CREATE_TARGET_NUMBER_SUCCESS: 'CREATE_TARGET_NUMBER_SUCCESS',
  GET_TARGET_NUMBERS_FAILURE: 'GET_TARGET_NUMBERS_FAILURE',
  GET_TARGET_NUMBERS_REQUEST: 'GET_TARGET_NUMBERS_REQUEST',
  GET_TARGET_NUMBERS_SUCCESS: 'GET_TARGET_NUMBERS_SUCCESS',
  GET_PROPERTY_TARGET_NUMBER_REQUEST: 'GET_PROPERTY_TARGET_NUMBER_REQUEST',
  GET_PROPERTY_TARGET_NUMBER_SUCCESS: 'GET_PROPERTY_TARGET_NUMBER_SUCCESS',
  GET_PROPERTY_TARGET_NUMBER_FAILURE: 'GET_PROPERTY_TARGET_NUMBER_FAILURE',

  SAVE_CALL_SCHEDULES_FAILURE: 'SAVE_CALL_SCHEDULES_FAILURE',
  SAVE_CALL_SCHEDULES_REQUEST: 'SAVE_CALL_SCHEDULES_REQUEST',
  SAVE_CALL_SCHEDULES_SUCCESS: 'SAVE_CALL_SCHEDULES_SUCCESS',
  GET_CALL_SCHEDULES_FAILURE: 'GET_CALL_SCHEDULES_FAILURE',
  GET_CALL_SCHEDULES_REQUEST: 'GET_CALL_SCHEDULES_REQUEST',
  GET_CALL_SCHEDULES_SUCCESS: 'GET_CALL_SCHEDULES_SUCCESS',

  GET_PMS_LOGS_FAILURE: 'GET_REQUEST_LOGS_FAILURE',
  GET_PMS_LOGS_REQUEST: 'GET_REQUEST_LOGS_REQUEST',
  GET_PMS_LOGS_SUCCESS: 'GET_REQUEST_LOGS_SUCCESS',

  RESET_PMS_LOGS: 'RESET_PMS_LOGS',

  GET_APARTMENTS_REQUEST: 'GET_APARTMENTS_REQUEST',
  GET_APARTMENTS_SUCCESS: 'GET_APARTMENTS_SUCCESS',
  GET_APARTMENTS_FAILURE: 'GET_APARTMENTS_FAILURE',

  BULK_SAVE_APARTMENTS_REQUEST: 'BULK_SAVE_APARTMENTS_REQUEST',
  BULK_SAVE_APARTMENTS_SUCCESS: 'BULK_SAVE_APARTMENTS_SUCCESS',
  BULK_SAVE_APARTMENTS_FAILURE: 'BULK_SAVE_APARTMENTS_FAILURE',

  GET_HOLIDAYS_REQUEST: 'GET_HOLIDAYS_REQUEST',
  GET_HOLIDAYS_SUCCESS: 'GET_HOLIDAYS_SUCCESS',
  GET_HOLIDAYS_FAILURE: 'GET_HOLIDAYS_FAILURE',
  UPDATE_HOLIDAY_REQUEST: 'UPDATE_HOLIDAY_REQUEST',
  UPDATE_HOLIDAY_SUCCESS: 'UPDATE_HOLIDAY_SUCCESS',
  UPDATE_HOLIDAY_FAILURE: 'UPDATE_HOLIDAY_FAILURE',

  CLEAR_CONTACTS: 'CLEAR_CONTACTS',
  CLEAR_PROSPECTS: 'CLEAR_PROSPECTS',
  GET_CONTACT_BY_ID_REQUEST: 'GET_CONTACT_BY_ID_REQUEST',
  GET_CONTACT_BY_ID_SUCCESS: 'GET_CONTACT_BY_ID_SUCCESS',
  GET_CONTACT_BY_ID_FAILURE: 'GET_CONTACT_BY_ID_FAILURE',
  SET_SMS_DOWNLOAD_COUNT: 'SET_SMS_DOWNLOAD_COUNT',
  REMOVE_CONTACT: 'REMOVE_CONTACT',
  REMOVE_PROSPECT: 'REMOVE_PROSPECT',
  SET_DRAFT_MESSAGE: 'SET_DRAFT_MESSAGE',
  GET_PROPERTIES_IS_AVAILABLE_TO_RESCORE_REQUEST: 'GET_PROPERTIES_IS_AVAILABLE_TO_RESCORE_REQUEST',
  GET_PROPERTIES_IS_AVAILABLE_TO_RESCORE_SUCCESS: 'GET_PROPERTIES_IS_AVAILABLE_TO_RESCORE_SUCCESS',
  GET_PROPERTIES_IS_AVAILABLE_TO_RESCORE_FAILURE: 'GET_PROPERTIES_IS_AVAILABLE_TO_RESCORE_FAILURE',
  CHANGE_PROPERTY_IS_AVAILABLE_TO_RESCORE_STATUS: 'CHANGE_PROPERTY_IS_AVAILABLE_TO_RESCORE_STATUS',
  DISABLE_CHAT_FOR_TODAY_SUCCESS: 'DISABLE_CHAT_FOR_TODAY_SUCCESS',

  UPDATE_RECENT_CHAT_REPORTS_REQUEST: 'UPDATE_RECENT_CHAT_REPORTS_REQUEST',
  UPDATE_RECENT_CHAT_REPORTS_SUCCESS: 'UPDATE_RECENT_CHAT_REPORTS_SUCCESS',
  UPDATE_RECENT_CHAT_REPORTS_FAILURE: 'UPDATE_RECENT_CHAT_REPORTS_FAILURE',
};
