import produce from 'immer';
import { Action, PriceQuote, ActionType, priceQuoteSelection } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface PriceQuoteState extends CommonStateType {
  leadPriceQuotes: PriceQuote[];
  arePriceQuotesLoaded: boolean;
  protoPriceQuote: priceQuoteSelection;
  currentPriceQuote: PriceQuote;
}

export const initialState: PriceQuoteState = {
  leadPriceQuotes: [],
  arePriceQuotesLoaded: false,
  protoPriceQuote: {} as priceQuoteSelection,
  currentPriceQuote: {} as PriceQuote,
};

export const selectLeadPriceQuotes = (state: { priceQuote: PriceQuoteState }): PriceQuote[] => state.priceQuote.leadPriceQuotes;
export const selectProtoPriceQuote = (state: { priceQuote: PriceQuoteState }): priceQuoteSelection => state.priceQuote.protoPriceQuote;
export const selectCurrentPriceQuote = (state: { priceQuote: PriceQuoteState }): PriceQuote => state.priceQuote.currentPriceQuote;

export default produce((state: PriceQuoteState = initialState, action: Action): PriceQuoteState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_REQUEST:
      state.isSubmitting = true;
      state.arePriceQuotesLoaded = false;
      break;
    case ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_SUCCESS: {
      const newPriceQuotes = action.result.data.results.filter(p => p.is_email_sent);
      state.isSubmitting = false;
      state.arePriceQuotesLoaded = true;
      state.leadPriceQuotes = newPriceQuotes;
      break;
    }
    case ActionType.GET_LEAD_HISTORIC_PRICE_QUOTES_FAILURE:
      state.isSubmitting = false;
      state.arePriceQuotesLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.CREATE_PRICE_QUOTE_REQUEST:
      state.isSubmitting = true;
      state.arePriceQuotesLoaded = false;
      break;
    case ActionType.CREATE_PRICE_QUOTE_SUCCESS: {
      let newPriceQuotes = [...state.leadPriceQuotes, action.result.data].filter(p => p.is_email_sent);
      newPriceQuotes = newPriceQuotes.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
      state.isSubmitting = false;
      state.arePriceQuotesLoaded = true;
      state.leadPriceQuotes = newPriceQuotes;
      state.currentPriceQuote = action.result.data;
      break;
    }
    case ActionType.CREATE_PRICE_QUOTE_FAILURE:
      state.isSubmitting = false;
      state.arePriceQuotesLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.SET_PROTO_PRICE_QUOTE:
      state.protoPriceQuote = action.selection;
      break;

    case ActionType.UPDATE_PRICE_QUOTE_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_PRICE_QUOTE_SUCCESS: {
      let newPriceQuotes = [...state.leadPriceQuotes, action.result.data].filter(p => p.is_email_sent);
      newPriceQuotes = newPriceQuotes.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
      state.isSubmitting = false;
      state.currentPriceQuote = action.result.data;
      state.leadPriceQuotes = newPriceQuotes;
      break;
    }
    case ActionType.UPDATE_PRICE_QUOTE_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.CLEAR_CURRENT_PRICE_QUOTE:
      state.currentPriceQuote = {} as PriceQuote;
      break;
  }

  return state;
});
