import application from './application/action-creators';
import screening from './screening/action-creators';
import people from './people/action-creators';
import renew from './renew/action-creators';

export default {
  application,
  screening,
  people,
  renew,
};
