import React, { FC } from 'react';
import moment from 'moment';
import { HobbesQuestion, HobbesQuestionBody } from 'dwell/views/chat/single_chat/window/styles';

interface SimpleHobbesMessageProps {
  text: string;
  date: string;
}

const SimpleMessage: FC<SimpleHobbesMessageProps> = ({ text, date }) => (
  <HobbesQuestion>
    <HobbesQuestionBody>
      <p>{text}</p>
    </HobbesQuestionBody>
    <small>{moment(date).format('MMM DD, hh:mma')}</small>
  </HobbesQuestion>
);

export default SimpleMessage;
