import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { CommonStateType } from 'dwell/store/types';
import { Action, ActionType, ComparisonReport, HighestRent, HighestOccupancy } from './action-types';

export interface ComparisonState extends CommonStateType {
  isComparisonListLoaded: boolean;
  isComparisonLoaded: boolean;
  comparisonList: ComparisonReport[];
  comparison: ComparisonReport;
  isHighestRentLoaded: boolean;
  highestRent: HighestRent;
  isHighestOccupancyLoaded: boolean;
  highestOccupancy: HighestOccupancy;
  highestOccupancyCount: number;
  highestRentCount: number;
  subjectName: string;
  subjectRentRank: number;
  subjectOccupancyRank: number;
  subjectType: string;
}

const initialState: ComparisonState = {
  isSubmitting: false,
  errorMessage: null,
  isComparisonListLoaded: false,
  isComparisonLoaded: false,
  comparisonList: null,
  comparison: null,
  isHighestRentLoaded: false,
  highestRent: null,
  isHighestOccupancyLoaded: false,
  highestOccupancy: null,
  highestOccupancyCount: 0,
  highestRentCount: 0,
  subjectName: null,
  subjectRentRank: null,
  subjectOccupancyRank: null,
  subjectType: null,
};

export default produce((state: ComparisonState = initialState, action: Action): ComparisonState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_COMPARISON_LIST_REQUEST:
      state.isComparisonListLoaded = false;
      break;

    case ActionType.GET_COMPARISON_LIST_SUCCESS:
      state.comparisonList = action.result.data.results;
      state.isComparisonListLoaded = true;
      break;

    case ActionType.GET_COMPARISON_LIST_FAILURE:
      state.isComparisonListLoaded = false;
      break;

    case ActionType.CREATE_COMPARISON_REQUEST:
      state.isComparisonLoaded = false;
      break;

    case ActionType.CREATE_COMPARISON_SUCCESS:
      state.comparison = action.result.data;
      state.isComparisonLoaded = true;
      break;

    case ActionType.CREATE_COMPARISON_FAILURE:
      state.isComparisonLoaded = false;
      break;

    case ActionType.GET_HIGHEST_RENT_REQUEST:
      state.isHighestRentLoaded = false;
      break;

    case ActionType.GET_HIGHEST_RENT_SUCCESS:
      state.highestRent = action.result.data.results;
      state.isHighestRentLoaded = true;
      state.highestRentCount = action.result.data.count;
      state.subjectName = action.result.data.subject_name;
      state.subjectRentRank = action.result.data.subject_rank;
      state.subjectType = action.result.data.subject_type;
      break;

    case ActionType.GET_HIGHEST_RENT_FAILURE:
      state.isHighestRentLoaded = false;
      break;

    case ActionType.GET_HIGHEST_OCCUPANCY_REQUEST:
      state.isHighestOccupancyLoaded = false;
      break;

    case ActionType.GET_HIGHEST_OCCUPANCY_SUCCESS:
      state.highestOccupancy = action.result.data.results;
      state.isHighestOccupancyLoaded = true;
      state.highestOccupancyCount = action.result.data.count;
      state.subjectName = action.result.data.subject_name;
      state.subjectOccupancyRank = action.result.data.subject_rank;
      state.subjectType = action.result.data.subject_type;
      break;

    case ActionType.GET_HIGHEST_OCCUPANCY_FAILURE:
      state.isHighestOccupancyLoaded = false;
      break;

    case ActionType.GET_COMPARISON_BY_ID_REQUEST:
      state.isComparisonLoaded = false;
      break;

    case ActionType.GET_COMPARISON_BY_ID_SUCCESS:
      state.comparison = action.result.data;
      state.isComparisonLoaded = true;
      break;

    case ActionType.GET_COMPARISON_BY_ID_FAILURE:
      state.isComparisonLoaded = false;
      break;
  }

  return state;
});
