import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { selectCustomers } from 'site/store/customer/reducers';
import { CustomerProps } from 'site/store/customer/action-types';
import { selectProperties } from 'dwell/store/property/reducers';

import { PropertyMenuBody } from 'containers/multi_property_selector/styles';
import CustomerItem from './_customer_item';

interface CustomerListProps {
  keywordCustomer: string;
  setCurrentCustomer: React.Dispatch<React.SetStateAction<CustomerProps>>;
  setToggleTab: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerList: FC<CustomerListProps> = ({ keywordCustomer, setCurrentCustomer, setToggleTab }) => {
  const customers: CustomerProps[] = useSelector(selectCustomers);
  const allUserProperties = useSelector(selectProperties);

  const filteredCustomers = customers.filter(item => item.customer_name.toLowerCase().includes(keywordCustomer.toLowerCase()));
  // 'All Customers' is a special customer that contains all properties, it has id = 0 to be different from other stored customers
  const allCustomers = { id: 0, customer_name: 'All Customers', logo: '', properties: [], active_properties: allUserProperties.length } as CustomerProps;

  return (
    <PropertyMenuBody active>
      <CustomerItem key={0} customer={allCustomers} setCurrentCustomer={setCurrentCustomer} setToggleTab={setToggleTab} />
      {filteredCustomers.map(customer => (
        <CustomerItem key={customer.id} customer={customer} setCurrentCustomer={setCurrentCustomer} setToggleTab={setToggleTab} />
      ))}
    </PropertyMenuBody>
  );
};

export default CustomerList;
