import produce from 'immer';
import { PusherAction, ActionType as PusherActionType } from 'dwell/store/pusher/action-types';
import { Action, ActionType, UserProps, DoNotDisturbProps, AllCallRoutings } from './action-types';
import { CommonStateType } from '../types';

export interface UserState extends CommonStateType {
  currentUser: UserProps;
  users: UserProps[];
  teamUsers: UserProps[];
  isUpdatingStatus: boolean;
  isUsersLoaded: boolean;
  totalCount: number;
  totalCountAll: number;
  totalCountActive: number;
  totalCountInactive: number;
  teamUsersLoaded: boolean;
  propertyUsers: UserProps[];
  isUnAvailableManually: boolean;
  allCallRoutings: AllCallRoutings;
  isCallRoutingsLoaded: boolean;
}

export const initialState: UserState = {
  currentUser: {},
  users: [],
  totalCount: 0,
  totalCountAll: 0,
  totalCountActive: 0,
  totalCountInactive: 0,
  teamUsers: [],
  isSubmitting: false,
  isUpdatingStatus: false,
  isUsersLoaded: false,
  teamUsersLoaded: false,
  isCallRoutingsLoaded: false,
  propertyUsers: [],
  isUnAvailableManually: false,
  allCallRoutings: { call_routings: {}, hidden_call_routings: {} },
};

export const selectCurrentUser = (state: { user: UserState }): UserProps => state.user.currentUser;
export const selectIsUsersLoaded = (state: { user: UserState }): boolean => state.user.isUsersLoaded;
export const selectTeamUsers = (state: { user: UserState }): UserProps[] => state.user.teamUsers;
export const selectUserIsCallScorer = (state: { user: { currentUser: UserProps } }): boolean => state.user.currentUser.is_call_scorer;
export const selectUserRole = (state: { user: { currentUser: UserProps } }): string => state.user.currentUser.role;
export const selectUsers = (state: { user: UserState }): UserProps[] => state.user.users;
export const selectUserTotalCount = (state: { user: UserState }): number => state.user.totalCount;
export const selectUserTotalCountAll = (state: { user: UserState }): number => state.user.totalCountAll;
export const selectUserTotalCountActive = (state: { user: UserState }): number => state.user.totalCountActive;
export const selectUserTotalCountInactive = (state: { user: UserState }): number => state.user.totalCountInactive;
export const selectDoNotDisturb = (state: { user: { currentUser: UserProps } }): DoNotDisturbProps => state.user.currentUser.do_not_disturb;
export const selectHasDoNotDisturb = (state: { user: { currentUser: UserProps } }): boolean => !!state.user.currentUser.do_not_disturb;
export const selectIsChatReviewerOrCallScorer = (state: { user: { currentUser: UserProps } }): boolean => state.user.currentUser.is_chat_reviewer || state.user.currentUser.is_call_scorer;
export const selectUserCurrentCustomer = (state: { user: UserState }): number => state.user.currentUser.current_customer;

export default produce((state: UserState = initialState, action: Action): UserState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'user') {
    return state;
  }
  switch (action.type) {
    case ActionType.GET_CURRENT_USER_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_CURRENT_USER_SUCCESS:
      state.isSubmitting = false;
      state.currentUser = action.result.data;
      break;
    case ActionType.GET_CURRENT_USER_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.GET_USERS_REQUEST:
      state.isUsersLoaded = false;
      break;
    case ActionType.GET_USERS_SUCCESS:
      state.isUsersLoaded = true;
      state.users = action.result.data.results;
      state.totalCount = action.result.data.count;
      state.totalCountAll = action.result.data.count_all;
      state.totalCountActive = action.result.data.count_active;
      state.totalCountInactive = action.result.data.count_inactive;
      break;
    case ActionType.GET_USERS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isUsersLoaded = false;
      break;

    case ActionType.GET_TEAM_MATES_REQUEST:
      state.isSubmitting = true;
      state.teamUsersLoaded = false;
      break;
    case ActionType.GET_TEAM_MATES_SUCCESS:
      state.isSubmitting = false;
      state.teamUsers = action.result.data;
      state.teamUsersLoaded = true;
      break;
    case ActionType.GET_TEAM_MATES_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      state.teamUsersLoaded = true;
      break;

    case ActionType.CREATE_USER_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_USER_SUCCESS:
      state.isSubmitting = false;
      state.users = [action.result.data].concat(state.users);
      break;
    case ActionType.CREATE_USER_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.DELETE_USER_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.DELETE_USER_SUCCESS:
      state.isSubmitting = false;
      state.users = state.users.filter(i => i.id !== action.result.data.id);
      break;
    case ActionType.DELETE_USER_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_USER_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_USER_SUCCESS: {
      if (action.result.data.id === state.currentUser.id) {
        state.currentUser = action.result.data;
      }
      const index = state.users.findIndex(u => u.id === action.result.data.id);
      if (index !== -1) {
        state.users[index] = action.result.data;
      }
      state.isSubmitting = false;
      break;
    }
    case ActionType.UPDATE_USER_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_USER_ACCESSED_PROPERTIES_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_USER_ACCESSED_PROPERTIES_SUCCESS:
      state.isSubmitting = false;
      state.currentUser.accessed_properties = action.result.data;
      break;
    case ActionType.UPDATE_USER_ACCESSED_PROPERTIES_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_CURRENT_CUSTOMER_REQUEST:
    case ActionType.UPDATE_USER_AVAILABLE_STATUS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_CURRENT_CUSTOMER_SUCCESS:
    case ActionType.UPDATE_USER_AVAILABLE_STATUS_SUCCESS:
      state.isSubmitting = false;
      state.currentUser = action.result.data;
      break;
    case ActionType.UPDATE_CURRENT_CUSTOMER_FAILURE:
    case ActionType.UPDATE_USER_AVAILABLE_STATUS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as UserProps;
      state.currentUser.is_available = row.is_available;
      break;
    }

    case ActionType.GET_PROPERTY_USERS_REQUEST:
      state.isSubmitting = true;
      state.isLoaded = false;
      break;
    case ActionType.GET_PROPERTY_USERS_SUCCESS:
      state.isSubmitting = false;
      state.isLoaded = true;
      state.propertyUsers = action.result.data.results;
      break;
    case ActionType.GET_PROPERTY_USERS_FAILURE:
      state.isLoaded = true;
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.SET_UNAVAILABLE_MANUALLY:
      state.isUnAvailableManually = action.isUnAvailableManually;
      break;

    case ActionType.DO_NOT_DISTURB_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.DO_NOT_DISTURB_SUCCESS:
      state.isSubmitting = false;
      state.currentUser.do_not_disturb = action.result.data;
      break;
    case ActionType.DO_NOT_DISTURB_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.CLEAR_DO_NOT_DISTURB_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CLEAR_DO_NOT_DISTURB_SUCCESS:
      state.isSubmitting = false;
      state.currentUser.do_not_disturb = null;
      break;
    case ActionType.CLEAR_DO_NOT_DISTURB_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;
    case ActionType.GET_USER_PROPERTIES_CALL_ROUTING_REQUEST:
      state.isSubmitting = true;
      state.isCallRoutingsLoaded = false;
      break;
    case ActionType.GET_USER_PROPERTIES_CALL_ROUTING_SUCCESS:
      state.isSubmitting = false;
      state.isCallRoutingsLoaded = true;
      state.allCallRoutings = action.result.data;
      break;
    case ActionType.GET_USER_PROPERTIES_CALL_ROUTING_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      state.isCallRoutingsLoaded = true;
      break;
    case ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_SUCCESS:
      state.isSubmitting = false;
      state.allCallRoutings = action.result.data;
      break;
    case ActionType.UPDATE_USER_PROPERTIES_CALL_ROUTING_FAILURE:
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
  }

  return state;
});
