import { paths } from 'corporate/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, SiteSettingsValues, SiteSettings } from './action-types';

export default {
  setToolbarNavTabSettings: (nav: 'GENERAL'|'APPEARANCE'|'ADVANCED'|'AMENITIES'): Action => ({
    type: ActionType.SET_TOOLBAR_NAV_TAB_SETTINGS,
    nav,
  }),
  setNewSiteSettingsValues: (newValues: SiteSettingsValues, oldValues: SiteSettingsValues): Action => ({
    type: ActionType.SET_NEW_SITE_SETTINGS_VALUES,
    newValues,
    oldValues,
  }),
  getSiteSeoSettings: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_SITE_SEO_SETTINGS_REQUEST,
        ActionType.GET_SITE_SEO_SETTINGS_SUCCESS,
        ActionType.GET_SITE_SEO_SETTINGS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.SITE_SETTINGS.SITE_SEO),
    },
  }),
  updateSiteSeoSettings: (id: number, data: SiteSettings): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SITE_SEO_SETTINGS_REQUEST,
        ActionType.UPDATE_SITE_SEO_SETTINGS_SUCCESS,
        ActionType.UPDATE_SITE_SEO_SETTINGS_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.SITE_SETTINGS.SITE_SEO_ID, id), data),
    },
  }),
  getPropertySeoSettings: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTY_SEO_SETTINGS_REQUEST,
        ActionType.GET_PROPERTY_SEO_SETTINGS_SUCCESS,
        ActionType.GET_PROPERTY_SEO_SETTINGS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.SITE_SETTINGS.PROPERTY_SEO),
    },
  }),

  updatePropertySeoSettings: (id: number, data: SiteSettings): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_PROPERTY_SEO_SETTINGS_REQUEST,
        ActionType.UPDATE_PROPERTY_SEO_SETTINGS_SUCCESS,
        ActionType.UPDATE_PROPERTY_SEO_SETTINGS_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.SITE_SETTINGS.PROPERTY_SEO_ID, id), data),
    },
  }),
};
