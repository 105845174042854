import produce from 'immer';

import { SupportPortalState, ActionType, Request, Action } from './action-types';

const initialState: SupportPortalState = {
  requests: [],
  requestsCount: 0,
  requestsLoaded: true,
  errorMessage: '',
};

export const selectSupportRequests = (state: { supportPortal: SupportPortalState }): Request[] => state.supportPortal.requests;
export const selectSupportRequestsCount = (state: { supportPortal: SupportPortalState }): number => state.supportPortal.requestsCount;
export const selectSupportRequestsLoaded = (state: { supportPortal: SupportPortalState }): boolean => state.supportPortal.requestsLoaded;

export default produce((state: SupportPortalState = initialState, action: Action): SupportPortalState => {
  switch (action.type) {
    case ActionType.GET_REQUESTS_REQUEST:
      state.requestsLoaded = false;
      break;
    case ActionType.GET_REQUESTS_SUCCESS:
      state.requests = action.result.data.results;
      state.requestsLoaded = true;
      state.requestsCount = action.result.data.count;
      break;
    case ActionType.GET_REQUESTS_FAILURE:
      state.requestsLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.CREATE_REQUEST_SUCCESS: {
      const request: Request = action.result.data;
      // try to add it to the list if the new request fulfills the filter criteria
      if (state.requests.length === 0 || state.requests.every((r: Request) => r.type === request.type)) {
        state.requests = [request].concat(state.requests);
      }
      state.requestsCount += 1;
      break;
    }

    case ActionType.DELETE_REQUEST_SUCCESS: {
      const requestDeleted: Request = action.result.data;
      state.requests = state.requests.filter((request: Request) => request.id !== requestDeleted.id);
      state.requestsCount -= 1;
      break;
    }

    case ActionType.UPDATE_REQUEST_SUCCESS: {
      const requestUpdated: Request = action.result.data;
      const index = state.requests.findIndex((request: Request) => request.id === requestUpdated.id);
      state.requests[index] = requestUpdated;
      break;
    }
  }
  return state;
});
