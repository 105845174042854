import { EmailMessageProps } from 'dwell/store/email_message/action-types';
import { EmailTemplateProps } from '../email_template/action-types';
import { PropertyProps } from '../property/action-types';
import { Action, ActionType, EmailTextFields, Attachment } from './action-types';
import { Lead } from '../lead/action-types';

export default {
  addEmailPopout: (lead: Lead, property: PropertyProps, isReply: boolean, options?: { emailMessage?: EmailMessageProps, preloadTemplate?: string, isSettedFromPriceQuote?: boolean }): Action => ({
    type: ActionType.ADD_EMAIL_POPOUT,
    popoutId: lead ? `lead-${lead.id}` : `em-${options.emailMessage.id || options.emailMessage.popoutId}`,
    property,
    isReply,
    lead,
    ...options,
  }),
  closeEmailPopout: (popoutId: string): Action => ({
    type: ActionType.CLOSE_EMAIL_POPOUT,
    popoutId,
  }),
  toggleMinimizeEmailPopout: (popoutId: string): Action => ({
    type: ActionType.TOGGLE_MINIMIZE_EMAIL_POPOUT,
    popoutId,
  }),
  setEmailPopoutTextVar: (popoutId: string, varName: EmailTextFields, value: string): Action => ({
    type: ActionType.SET_EMAIL_POPOUT_TEXT_VAR,
    popoutId,
    varName,
    value,
  }),
  setEmailPopoutIsPreview: (popoutId: string, isPreview: boolean): Action => ({
    type: ActionType.SET_EMAIL_POPOUT_IS_PREVIEW,
    popoutId,
    isPreview,
  }),
  setEmailPopoutShowingCcInput: (popoutId: string, showingCcInput: boolean): Action => ({
    type: ActionType.SET_EMAIL_POPOUT_SHOWING_CC_INPUT,
    popoutId,
    showingCcInput,
  }),
  setEmailPopoutAttachment: (popoutId: string, files: Attachment[]): Action => ({
    type: ActionType.SET_EMAIL_POPOUT_FILES,
    popoutId,
    files,
  }),
  removeEmailPopoutAttachment: (popoutId: string, preview: string): Action => ({
    type: ActionType.REMOVE_EMAIL_POPOUT_FILE,
    popoutId,
    preview,
  }),
  clearEmailPopoutattachments: (popoutId: string): Action => ({
    type: ActionType.CLEAR_EMAIL_POPOUT_FILES,
    popoutId,
  }),
  setEmailPopoutSelectedTemplate: (popoutId: string, selectedTemplate: EmailTemplateProps): Action => ({
    type: ActionType.SET_EMAIL_POPOUT_SELECTED_TEMPLATE,
    popoutId,
    selectedTemplate,
  }),
  setEmailPopoutSubjectVariables: (popoutId: string, subjectVariables: string[]): Action => ({
    type: ActionType.SET_EMAIL_POPOUT_SUBJECT_VARIABLES,
    popoutId,
    subjectVariables,
  }),
  activeCircledEmailPopout: (popoutId: string): Action => ({
    type: ActionType.ACTIVE_EMAIL_POPOUT_CIRCLED,
    popoutId,
  }),
  setEmailPopoutTemplates: (popoutId: string, emailTemplates: EmailTemplateProps[]): Action => ({
    type: ActionType.SET_EMAIL_POPOUT_TEMPLATES,
    popoutId,
    emailTemplates,
  }),
  circleEmailPopout: (popoutId: string): Action => ({
    type: ActionType.CIRCLE_EMAIL_POPOUT,
    popoutId,
  }),
};
