import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_TOOLTIP_ITEMS_REQUEST = 'GET_TOOLTIP_ITEMS_REQUEST',
  GET_TOOLTIP_ITEMS_SUCCESS = 'GET_TOOLTIP_ITEMS_SUCCESS',
  GET_TOOLTIP_ITEMS_FAILURE = 'GET_TOOLTIP_ITEMS_FAILURE',

  TOOLTIP_ITEM_UPDATE_REQUEST = 'TOOLTIP_ITEM_UPDATE_REQUEST',
  TOOLTIP_ITEM_UPDATE_SUCCESS = 'TOOLTIP_ITEM_UPDATE_SUCCESS',
  TOOLTIP_ITEM_UPDATE_FAILURE = 'TOOLTIP_ITEM_UPDATE_FAILURE',
}

export interface Tooltip {
  section?: string;
  elements?: { selector: string; value: string }[];
}

export interface TooltipData {
  sectionString: string;
  selectorString: string;
  valueString: string;
}

interface getTooltipItemsAction {
  type: ActionType.GET_TOOLTIP_ITEMS_REQUEST | ActionType.GET_TOOLTIP_ITEMS_SUCCESS | ActionType.GET_TOOLTIP_ITEMS_FAILURE;

  result: {
    data: Tooltip[];
  };
  error: {
    response: { status: string };
  };
}

interface updateTooltipItemAction {
  type: ActionType.TOOLTIP_ITEM_UPDATE_REQUEST | ActionType.TOOLTIP_ITEM_UPDATE_SUCCESS | ActionType.TOOLTIP_ITEM_UPDATE_FAILURE;
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | getTooltipItemsAction | updateTooltipItemAction;
