import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedProperties } from 'dwell/store/property/reducers';

import SelectedPropertyItem from './_selected_property_item';
import { PMGroup } from './styles';

const SelectedPropertiesList: FC = () => {
  const selectedProperties = useSelector(selectSelectedProperties);

  return (
    <PMGroup id="propSelected">
      {selectedProperties.map(item => (
        <SelectedPropertyItem
          key={item.id}
          property={item}
        />
      ))}
    </PMGroup>
  );
};

export default SelectedPropertiesList;
