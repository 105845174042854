export default [
  {
    family: 'Amsterdam',
    isCustom: true,
    path: 'static/fonts/Amsterdam.ttf',
  },
  {
    family: 'Bold Gotham',
    isCustom: true,
    path: 'static/fonts/GothamBold.otf',
  },
  {
    family: 'Caston Regular',
    isCustom: true,
    path: 'static/fonts/Caston-Regular.ttf',
  },
  {
    family: 'Gotham Book Regular',
    isCustom: true,
    path: 'static/fonts/GothamBookRegular.otf',
  },
  {
    family: 'Boogy Brut Book',
    isCustom: true,
    path: 'static/fonts/BoogyBrut-Book.otf',
  },
  {
    family: 'Founders Grotesk Light',
    isCustom: true,
    path: 'static/fonts/FoundersGrotesk-Light.otf',
  },
  {
    family: 'Founders Grotesk Regular',
    isCustom: true,
    path: 'static/fonts/FoundersGrotesk-Regular.otf',
  },
  {
    family: 'Gill Sans Nova',
    isCustom: true,
    path: 'static/fonts/GillSansNova.ttf',
  },
  {
    family: 'Playfair Display Medium',
    isCustom: true,
    path: 'static/fonts/PlayfairDisplay-Medium.ttf',
  },
  {
    family: 'PP Editorial New Ultra Light Italic',
    isCustom: true,
    path: 'static/fonts/PPEditorialNew-UltralightItalic.otf',
  },
  {
    family: 'PP Eiko Light',
    isCustom: true,
    path: 'static/fonts/PPEiko-Light.otf',
  },
  {
    family: 'PP Fragment Sans Light',
    isCustom: true,
    path: 'static/fonts/PPFragment-SansLight.otf',
  },
  {
    family: 'PP Object Sans Regular',
    isCustom: true,
    path: 'static/fonts/PPObjectSans-Regular.ttf',
  },
  {
    family: 'The Billion Regular',
    isCustom: true,
    path: 'static/fonts/The-Billion-Regular-cf16.otf',
  },
  {
    family: 'Laguna Vintage Block Variable Regular',
    isCustom: true,
    path: 'static/fonts/Laguna-Vintage-Block-Variable-Regular-8b0f.otf',
  },
  {
    family: 'Verdana',
    isCustom: true,
    path: 'static/fonts/Verdana.ttf',
  },
  {
    family: 'Mundial Regular',
    isCustom: true,
    path: 'static/fonts/Mundial-Regular.otf',
  },
  {
    family: 'Mundial Light',
    isCustom: true,
    path: 'static/fonts/Mundial-Light.otf',
  },
];
