import { PromiseActionType } from 'dwell/store/types';
import { Action as PageAction, Page } from 'src/corporate/store/page/action-types';

export interface Section {
  id: number;
  page: number;
  type: string;
  order: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
}

export enum ActionType {
  UPDATE_CORPORATE_SECTION_REQUEST = 'UPDATE_CORPORATE_SECTION_REQUEST',
  UPDATE_CORPORATE_SECTION_SUCCESS = 'UPDATE_CORPORATE_SECTION_SUCCESS',
  UPDATE_CORPORATE_SECTION_FAILURE = 'UPDATE_CORPORATE_SECTION_FAILURE',

  UPDATE_SUBSITE_SECTION_REQUEST = 'UPDATE_SUBSITE_SECTION_REQUEST',
  UPDATE_SUBSITE_SECTION_SUCCESS = 'UPDATE_SUBSITE_SECTION_SUCCESS',
  UPDATE_SUBSITE_SECTION_FAILURE = 'UPDATE_SUBSITE_SECTION_FAILURE',

  SET_SECTIONS = 'SET_SECTIONS',
  CLOSE_ADD_NEW_SECTION_PANEL = 'CLOSE_ADD_NEW_SECTION_PANEL',

  TOGGLE_ADD_NEW_SECTION_PANEL = 'TOGGLE_ADD_NEW_SECTION_PANEL',
  SET_SECTION = 'SET_SECTION',
  CLEAR_SECTION = 'CLEAR_SECTION',

  SHOW_DELETE_SECTION_MODAL = 'SHOW_DELETE_SECTION_MODAL',
  HIDE_DELETE_SECTION_MODAL = 'HIDE_DELETE_SECTION_MODAL',
  SET_SECTION_TO_DELETE = 'SET_SECTION_TO_DELETE',
  CLEAR_SECTION_TO_DELETE = 'CLEAR_SECTION_TO_DELETE',
  SHOW_CUSTOM_STYLES_SECTION = 'SHOW_CUSTOM_STYLES_SECTION',

  ADD_CORPORATE_SECTION_REQUEST = 'ADD_CORPORATE_SECTION_REQUEST',
  ADD_CORPORATE_SECTION_SUCCESS = 'ADD_CORPORATE_SECTION_SUCCESS',
  ADD_CORPORATE_SECTION_FAILURE = 'ADD_CORPORATE_SECTION_FAILURE',

  ADD_SUBSITE_SECTION_REQUEST = 'ADD_SUBSITE_SECTION_REQUEST',
  ADD_SUBSITE_SECTION_SUCCESS = 'ADD_SUBSITE_SECTION_SUCCESS',
  ADD_SUBSITE_SECTION_FAILURE = 'ADD_SUBSITE_SECTION_FAILURE',

  DELETE_CORPORATE_SECTION_REQUEST = 'DELETE_CORPORATE_SECTION_REQUEST',
  DELETE_CORPORATE_SECTION_SUCCESS = 'DELETE_CORPORATE_SECTION_SUCCESS',
  DELETE_CORPORATE_SECTION_FAILURE = 'DELETE_CORPORATE_SECTION_FAILURE',

  DELETE_SUBSITE_SECTION_REQUEST = 'DELETE_SUBSITE_SECTION_REQUEST',
  DELETE_SUBSITE_SECTION_SUCCESS = 'DELETE_SUBSITE_SECTION_SUCCESS',
  DELETE_SUBSITE_SECTION_FAILURE = 'DELETE_SUBSITE_SECTION_FAILURE',

  CHANGE_ORDER_CORPORATE_SECTION_REQUEST = 'CHANGE_ORDER_CORPORATE_SECTION_REQUEST',
  CHANGE_ORDER_CORPORATE_SECTION_SUCCESS = 'CHANGE_ORDER_CORPORATE_SECTION_SUCCESS',
  CHANGE_ORDER_CORPORATE_SECTION_FAILURE = 'CHANGE_ORDER_CORPORATE_SECTION_FAILURE',

  CHANGE_ORDER_SUBSITE_SECTION_REQUEST = 'CHANGE_ORDER_SUBSITE_SECTION_REQUEST',
  CHANGE_ORDER_SUBSITE_SECTION_SUCCESS = 'CHANGE_ORDER_SUBSITE_SECTION_SUCCESS',
  CHANGE_ORDER_SUBSITE_SECTION_FAILURE = 'CHANGE_ORDER_SUBSITE_SECTION_FAILURE',
}

interface UpdateCorporateSectionAction {
  type: ActionType.UPDATE_CORPORATE_SECTION_REQUEST | ActionType.UPDATE_CORPORATE_SECTION_SUCCESS | ActionType.UPDATE_CORPORATE_SECTION_FAILURE;
  result: { data: Page };
  error: { response: { status: string }; };
}

interface UpdateSubsiteSectionAction {
  type: ActionType.UPDATE_SUBSITE_SECTION_REQUEST | ActionType.UPDATE_SUBSITE_SECTION_SUCCESS | ActionType.UPDATE_SUBSITE_SECTION_FAILURE;
  result: { data: Page };
  error: { response: { status: string }; };
}

interface SetSectionsAction {
  type: ActionType.SET_SECTIONS;
  sections: Section[];
}

interface CloseAddNewSectionPanelAction {
  type: ActionType.CLOSE_ADD_NEW_SECTION_PANEL;
}

interface SetSectionAction {
  type: ActionType.SET_SECTION;
  sectionId: number;
}

interface ToggleAddNewSectionPanelAction {
  type: ActionType.TOGGLE_ADD_NEW_SECTION_PANEL;
}

interface ClearSectionAction {
  type: ActionType.CLEAR_SECTION;
}

interface ShowDeleteSectionModalAction {
  type: ActionType.SHOW_DELETE_SECTION_MODAL;
}

interface HideDeleteSectionModalAction {
  type: ActionType.HIDE_DELETE_SECTION_MODAL;
}

interface ShowCustomStylesSectionAction {
  type: ActionType.SHOW_CUSTOM_STYLES_SECTION;
}

interface SetSectionToDeleteAction {
  type: ActionType.SET_SECTION_TO_DELETE;
  section: Section;
}

interface ClearSectionToDeleteAction {
  type: ActionType.CLEAR_SECTION_TO_DELETE;
}

interface AddCorporateSectionAction {
  type: ActionType.ADD_CORPORATE_SECTION_REQUEST | ActionType.ADD_CORPORATE_SECTION_SUCCESS | ActionType.ADD_CORPORATE_SECTION_FAILURE;
  result: { data: Page };
}

interface AddSubsiteSectionAction {
  type: ActionType.ADD_SUBSITE_SECTION_REQUEST | ActionType.ADD_SUBSITE_SECTION_SUCCESS | ActionType.ADD_SUBSITE_SECTION_FAILURE;
  result: { data: Page };
}

interface DeleteCorporateSectionAction {
  type: ActionType.DELETE_CORPORATE_SECTION_REQUEST | ActionType.DELETE_CORPORATE_SECTION_SUCCESS | ActionType.DELETE_CORPORATE_SECTION_FAILURE;
  result: { data: Page };
}

interface DeleteSubsiteSectionAction {
  type: ActionType.DELETE_SUBSITE_SECTION_REQUEST | ActionType.DELETE_SUBSITE_SECTION_SUCCESS | ActionType.DELETE_SUBSITE_SECTION_FAILURE;
  result: { data: Page };
}

interface ChangeOrderCorporateSectionAction {
  type: ActionType.CHANGE_ORDER_CORPORATE_SECTION_REQUEST | ActionType.CHANGE_ORDER_CORPORATE_SECTION_SUCCESS | ActionType.CHANGE_ORDER_CORPORATE_SECTION_FAILURE;
  result: { data: Page };
}

interface ChangeOrderSubsiteSectionAction {
  type: ActionType.CHANGE_ORDER_SUBSITE_SECTION_REQUEST | ActionType.CHANGE_ORDER_SUBSITE_SECTION_SUCCESS | ActionType.CHANGE_ORDER_SUBSITE_SECTION_FAILURE;
  result: { data: Page };
}

export type Action =
  | PromiseActionType
  | PageAction
  | UpdateCorporateSectionAction
  | UpdateSubsiteSectionAction
  | SetSectionsAction
  | CloseAddNewSectionPanelAction
  | SetSectionAction
  | ToggleAddNewSectionPanelAction
  | ClearSectionAction
  | ShowDeleteSectionModalAction
  | HideDeleteSectionModalAction
  | SetSectionToDeleteAction
  | ClearSectionToDeleteAction
  | AddCorporateSectionAction
  | AddSubsiteSectionAction
  | DeleteCorporateSectionAction
  | DeleteSubsiteSectionAction
  | ChangeOrderCorporateSectionAction
  | ChangeOrderSubsiteSectionAction
  | ShowCustomStylesSectionAction;
