import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { PmCustomer, SwitchTab } from 'containers/multi_property_selector/styles';
import { roleTypes } from 'site/constants';
import { selectUserRole } from 'dwell/store/user/reducers';

interface CustomerSectionProps {
  setToggleTab: React.Dispatch<React.SetStateAction<boolean>>;
  currentCustomerName: string;
}

const CustomerSection: FC<CustomerSectionProps> = ({ setToggleTab, currentCustomerName }) => {
  const userRole = useSelector(selectUserRole);
  const isLLAdmin = userRole === roleTypes.LIFT_LYTICS_ADMIN;

  return (
    <PmCustomer>
      <h6 id="activeCustomer" className="mg-b-0">
        {currentCustomerName}
      </h6>
      {isLLAdmin && (
        <SwitchTab onClick={() => setToggleTab(true)}>
          Switch Customer <i className="ri-arrow-right-line" />
        </SwitchTab>
      )}
    </PmCustomer>
  );
};

export default CustomerSection;
