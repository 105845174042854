import React, { FC, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import logsActions from 'dwell/store/pms_logs/action-creators';
import Loader from 'dwell/components/loader';
import { selectPMSLogs, selectPMSLogsIsLoaded } from 'dwell/store/pms_logs/reducers';
import { getLeadId } from 'dwell/views/lead/layout/utils';

interface Props {
  handleClose: () => void,
  show: boolean,
}

const columns = [
  {
    dataField: 'status',
    sort: true,
    text: 'Status',
  },
  {
    dataField: 'date',
    sort: true,
    text: 'Request Date',
  },
  {
    dataField: 'method',
    sort: true,
    text: 'Request Method',
  },
  {
    dataField: 'source',
    sort: true,
    text: 'Integration Source',
  },
  {
    dataField: 'reason',
    sort: true,
    text: 'Request Reason',
  },
];

const RequestLogs: FC<Props> = ({ handleClose, show }) => {
  const leadId = getLeadId(window.location.pathname);
  const logs = useSelector(selectPMSLogs);
  const isLoaded = useSelector(selectPMSLogsIsLoaded);
  const [tableData, setTableData] = useState(logs);
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(logsActions.resetPMSLogs()), []);

  useEffect(() => {
    if (show && !isLoaded) dispatch(logsActions.getPMSLogs(leadId, { limit: 1000, show_all: true }));
  }, [show]);

  useEffect(() => {
    if (isLoaded) setTableData(logs);
  }, [isLoaded]);

  const closeBtn = <button className="close" onClick={() => handleClose()}>&times;</button>;

  const indication = () => (
    <React.Fragment>
      <div className="empty-table">
        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
        <div>{!isLoaded ? <Loader /> : <h4>No results found</h4>}</div>
      </div>
    </React.Fragment>);

  const onTableChange = (changeType, { data, sortField, sortOrder }) => {
    if (changeType === 'sort') {
      data.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a[sortField] > b[sortField] ? -1 : 1;
        }

        return a[sortField] > b[sortField] ? 1 : -1;
      });
      setTableData(data);
    }
  };

  return (
    <Modal
      centered
      className="drilldown reports"
      isOpen={show}
      size="xl"
      toggle={() => handleClose()}
    >
      <ModalHeader close={closeBtn}>
        <span>Request Logs</span>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col xs={12} style={{ paddingBottom: '1em' }}>
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                page: 1,
                sizePerPage: 20,
                totalSize: logs.length,
                firstPageText: '<<',
                prePageText: '<',
                nextPageText: '>',
                lastPageText: '>>',
                paginationSize: 4,
                sizePerPageList: [
                  { text: 10, value: 10 },
                  { text: 20, value: 20 },
                  { text: 30, value: 30 },
                  { text: 50, value: 50 },
                  { text: 100, value: 100 },
                ],
              })}
            >
              {
                ({ paginationProps, paginationTableProps }) => (
                  <>
                    <ScrollContainer
                      hideScrollbars={false}
                      className="scroll-container"
                      style={{ maxHeight: '85vh' }}
                    >
                      <BootstrapTable
                        remote={{ sort: true }}
                        keyField="id"
                        data={tableData}
                        columns={columns}
                        wrapperClasses="drilldown-table audition table-responsive table-alpha"
                        noDataIndication={indication}
                        onTableChange={onTableChange}
                        bordered={false}
                        {...paginationTableProps}
                      />
                    </ScrollContainer>
                    <PaginationListStandalone {...paginationProps} />
                  </>
                )
              }
            </PaginationProvider>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default RequestLogs;
