import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';

export enum ActionType {
  GET_COLUMNS_SETTINGS_REQUEST = 'GET_COLUMNS_SETTINGS_REQUEST',
  GET_COLUMNS_SETTINGS_SUCCESS = 'GET_COLUMNS_SETTINGS_SUCCESS',
  GET_COLUMNS_SETTINGS_FAILURE = 'GET_COLUMNS_SETTINGS_FAILURE',

  CREATE_COLUMNS_SETTINGS_REQUEST = 'CREATE_COLUMNS_SETTINGS_REQUEST',
  CREATE_COLUMNS_SETTINGS_SUCCESS = 'CREATE_COLUMNS_SETTINGS_SUCCESS',
  CREATE_COLUMNS_SETTINGS_FAILURE = 'CREATE_COLUMNS_SETTINGS_FAILURE',

  UPDATE_COLUMNS_SETTINGS_REQUEST = 'UPDATE_COLUMNS_SETTINGS_REQUEST',
  UPDATE_COLUMNS_SETTINGS_SUCCESS = 'UPDATE_COLUMNS_SETTINGS_SUCCESS',
  UPDATE_COLUMNS_SETTINGS_FAILURE = 'UPDATE_COLUMNS_SETTINGS_FAILURE',
}

export interface ColumnProps {
  id?: number;
  position: number;
  is_visible: boolean;
  name: string;
}

interface GetColumnsSettingsAction {
  type: ActionType.GET_COLUMNS_SETTINGS_REQUEST | ActionType.GET_COLUMNS_SETTINGS_SUCCESS | ActionType.GET_COLUMNS_SETTINGS_FAILURE;

  result: {
    data: { results: ColumnProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface CreateColumnsSettingsAction {
  type: ActionType.CREATE_COLUMNS_SETTINGS_REQUEST | ActionType.CREATE_COLUMNS_SETTINGS_SUCCESS | ActionType.CREATE_COLUMNS_SETTINGS_FAILURE;

  result: {
    data: ColumnProps[];
  };
  error: {
    response: { status: string };
  };
}

interface UpdateColumnsSettingsAction {
  type: ActionType.UPDATE_COLUMNS_SETTINGS_REQUEST | ActionType.UPDATE_COLUMNS_SETTINGS_SUCCESS | ActionType.UPDATE_COLUMNS_SETTINGS_FAILURE;

  result: {
    data: ColumnProps[];
  };
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetColumnsSettingsAction | CreateColumnsSettingsAction | UpdateColumnsSettingsAction | PusherAction;
