import moment from 'moment';
import { isEmpty } from 'lodash';
import { DirectAnswerTypes, FormStructure, AgentQuestion, OptionalAnswerTypes, PropertyProps, CustomerProps } from 'dwell/store/agent_question/action-types';

const processedQuestionText = (question: AgentQuestion) => {
  let agent_question_text = '';
  if (question.question_text_agent_side) {
    agent_question_text += question.question_text_agent_side;
  } else {
    agent_question_text += question.question_text;
  }
  return agent_question_text;
};

export const getFormStructures = (question: AgentQuestion): FormStructure[] => {
  const agent_question_text = processedQuestionText(question);
  const questionStructures = [];
  questionStructures.push({
    order: 1,
    field: question.custom_field ? question.custom_field : question.field,
    type: question.type,
    structure_type: question.structure_type,
    label: agent_question_text,
    placeholder: question.placeholder,
    max_value: question.max_value,
    direct_answer_type: question.direct_question_answer_expected_type,
    optional_answer_type: question.option_conditional_question_answer_expected_type,
    user_defined_options: question.user_defined_options,
    invert_current_binary_question_behavior: question.invert_current_binary_question_behavior,
    alternative_yes_answer: question.alternative_yes_answer,
    alternative_no_answer: question.alternative_no_answer,
  });
  if (!isEmpty(question.part_questions)) {
    question.part_questions
      .sort((a, b) => a.id - b.id)
      .forEach((q, idx) => {
        questionStructures.push({
          order: idx + 2,
          field: q.field,
          type: q.type,
          structure_type: q.structure_type,
          label: q.question_text,
          placeholder: q.placeholder,
          max_value: q.max_value,
          direct_answer_type: q.direct_question_answer_expected_type,
          optional_answer_type: q.option_conditional_question_answer_expected_type,
          user_defined_options: q.user_defined_options,
          invert_current_binary_question_behavior: q.invert_current_binary_question_behavior,
          alternative_yes_answer: q.alternative_yes_answer,
          alternative_no_answer: q.alternative_no_answer,
        });
      });
  }
  return questionStructures
    .sort((a, b) => a.order - b.order)
    .map(f => ({
      order: f.order,
      field: f.field,
      structureType: f.structure_type,
      questionText: f.label,
      placeholder: f.placeholder,
      max_value: f.max_value,
      direct_answer_type: f.direct_answer_type,
      optional_answer_type: f.optional_answer_type,
      user_defined_options: f.user_defined_options,
      invert_current_binary_question_behavior: f.invert_current_binary_question_behavior,
      alternative_yes_answer: f.alternative_yes_answer,
      alternative_no_answer: f.alternative_no_answer,
      error: false,
      answer: null,
      multiSelectionAnswer: null,
      conditional_answer: null,
      value: null,
      multiSelectionValue: null,
    }));
};

export const validateForm = (value: string, formType: string, maxValue: number): boolean => {
  const valueIsNotEmpty = value !== null && value !== '';
  if ((formType === DirectAnswerTypes.ANSWER_NUMERIC || formType === DirectAnswerTypes.ANSWER_MONETARY_VALUE) && valueIsNotEmpty) {
    const num = !!Number(value);
    if (num) {
      return maxValue ? Number(value) <= maxValue : true;
    }
    return false;
  } else if (formType === DirectAnswerTypes.ANSWER_EMAIL && valueIsNotEmpty) {
    return Boolean(value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
  } else if (formType === DirectAnswerTypes.ANSWER_URL_WEBSITE && valueIsNotEmpty) {
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    return Boolean(value.match(regex));
  }
  return valueIsNotEmpty;
};

const joinMultipleAnswers = (answer: string, answers: string[] = []) => {
  let tempAnswer = '';
  if (answers.length > 1) {
    tempAnswer = `${answers.slice(0, answers.length - 1).join(', ')}, and ${answers[answers.length - 1]}`;
  } else if (answers.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    tempAnswer = answers[0];
  } else {
    tempAnswer = answer || '';
  }
  return tempAnswer;
};

const transformText = (text: string, isTimeOptions: boolean, isMonetaryValue: boolean): string => {
  let transformedText = '';
  if (isTimeOptions) {
    transformedText = moment(text, 'HH:mm:ss', true).format('hh:mm A');
  } else if (isMonetaryValue) {
    transformedText = `$${text}`;
  } else {
    transformedText = text;
  }
  return transformedText;
};

export const generalAnswerHtml = (formData: FormStructure[], isGeneral: boolean): string => {
  const main_answer = formData[0];
  const isOneQuestion = formData.length === 1;
  const mainQuestionText = main_answer.questionText;
  const mainMultiAnswer = main_answer.multiSelectionAnswer || [];
  const mainIsTimeOptions = main_answer.optional_answer_type === OptionalAnswerTypes.ANSWER_TIME_OPTIONS;
  const mainIsMonetaryValue = main_answer.direct_answer_type === DirectAnswerTypes.ANSWER_MONETARY_VALUE;
  const mainQuestionAnswer = joinMultipleAnswers(main_answer.answer, mainMultiAnswer);
  const transformedText = transformText(mainQuestionAnswer, mainIsTimeOptions, mainIsMonetaryValue);

  const firstLabel = `<label>${isOneQuestion ? `${mainQuestionText}:` : `1. ${mainQuestionText}:`}</label>`;
  let firstAnswer = `<div class="agent-form-answered"><div><span class="agent-form-answered-text">${transformedText}</span></div></div>`;
  if (isGeneral) {
    firstAnswer = `<div class="agent-form-answered"><div><span class="agent-form-answered-text">${transformedText}</span></div></div>`;
  } else {
    firstAnswer = `<div class="agent-form-answered"><div><span class="agent-form-answered-text highlighted">${transformedText}</span></div></div>`;
  }
  const partQuestionsAnswers = formData
    .filter(a => a.order !== 1)
    .sort((a, b) => a.order - b.order)
    .map((a) => {
      const partAnswer = joinMultipleAnswers(a.answer, a.multiSelectionAnswer || []);
      const isTimeOptions = a.optional_answer_type === OptionalAnswerTypes.ANSWER_TIME_OPTIONS;
      const isMonetaryValue = a.direct_answer_type === DirectAnswerTypes.ANSWER_MONETARY_VALUE;
      const processedAnswer = transformText(partAnswer, isTimeOptions, isMonetaryValue);
      const label = `<label>${a.order}. ${a.questionText}:</label>`;
      let answer = '';
      if (isGeneral) {
        answer = `<div class="agent-form-answered"><div><span class="agent-form-answered-text">${processedAnswer}</span></div></div>`;
      } else {
        const textbox = `<div class="answer-textbox"><span>${processedAnswer}</span></div>`;
        answer = `<div class="agent-form-answered">${partAnswer.length <= 18 ? processedAnswer : textbox}</div>`;
      }
      return `<div class="part-question-answered">${label}${answer}</div>`;
    });
  return firstLabel + firstAnswer + partQuestionsAnswers.join('');
};

export const getYesAnswerHtml = (questionText: string, binaryAnswer: string): string => {
  const firstLabel = `<label>1. ${questionText}</label>`;
  const firstAnswer = `<div class="agent-form-answered"><div>${binaryAnswer}</div></div>`;
  return firstLabel + firstAnswer;
};

export const randomIntFromInterval = (min: number, max: number): number => Math.floor((Math.random() * ((max - min) + 1)) + min);

export const getPropertiesJoinedText = (properties: PropertyProps[]): string => {
  let propertiesText = '';
  properties.forEach((p, i) => {
    propertiesText += p.name;
    if (i === properties.length - 1 || properties.length === 1) {
      propertiesText += '';
    } else if (i === properties.length - 2) {
      propertiesText += ' and ';
    } else {
      propertiesText += ', ';
    }
  });
  return propertiesText;
};

export const getCustomersJoinedText = (customers: CustomerProps[]): string => {
  let customersText = '';
  customers.forEach((c, i) => {
    customersText += c.customer_name;
    if (i === customers.length - 1 || customers.length === 1) {
      customersText += '';
    } else if (i === customers.length - 2) {
      customersText += ' and ';
    } else {
      customersText += ', ';
    }
  });
  return customersText;
};
