import React, { FC, useState, useMemo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

interface AutoResizeTextareaProps {
  id: string,
  content: string
  onChange: (data: { target: { id: string, value: string } })=> void
}

const AutoResizeTextarea: FC<AutoResizeTextareaProps> = ({
  id,
  content,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);
  const value = useMemo(() => {
    if (content) {
      if (focused) {
        return content;
      }
      const lines = content.split('\n');
      if (lines.length > 4) {
        return `${lines.slice(0, 3).join('\n')}\n. . .`;
      }
      return `${content.substring(0, 150)} ...`;
    }
    return '';
  }, [content, focused]);

  return (
    <TextareaAutosize
      id={id}
      value={value}
      onChange={el => onChange({ target: { id, value: el.target.value } })}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className="form-control mb-0"
    />
  );
};

export default AutoResizeTextarea;
