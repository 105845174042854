import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { DirectAnswerTypes } from 'dwell/store/agent_question/action-types';
import { validateForm } from 'dwell/views/chat/agent_question/utils';
import actions from 'dwell/actions';
import { ChatTextArea } from 'dwell/views/chat/single_chat/window/styles';

interface HobbesDirectFormProps {
  index: number;
}

const DirectForm: FC<HobbesDirectFormProps> = ({ index }) => {
  const { setGlobalForm } = actions.agentQuestion;
  const globalForm = useSelector(state => state.agentQuestion.globalForm);
  const formData = useSelector(state => state.agentQuestion.globalForm)[index];
  const dispatch = useDispatch();

  const formType = formData.direct_answer_type || DirectAnswerTypes.ANSWER_TEXT;

  const updateForm = (value) => {
    const newState = [...globalForm];
    newState[index].answer = value;
    newState[index].value = value;
    newState[index].error = !validateForm(value, formType, formData.max_value);
    dispatch(setGlobalForm(newState));
  };

  const inputCommon = {
    key: `input-${index}`, className: 'form-control', type: 'text', placeholder: formData.placeholder, onChange: e => updateForm(e.currentTarget.value),
  };

  const directForms = {
    [DirectAnswerTypes.ANSWER_TEXT]: (
      <input
        {...inputCommon}
      />
    ),
    [DirectAnswerTypes.ANSWER_MONETARY_VALUE]: (
      <input
        {...inputCommon}
        min="1"
        max={formData.max_value !== null ? formData.max_value.toString() : ''}
        type="number"
        step="0.01"
        placeholder={`$${formData.placeholder || ''}`}
      />
    ),
    [DirectAnswerTypes.ANSWER_NUMERIC]: (
      <input
        {...inputCommon}
        min="1"
        max={formData.max_value !== null ? formData.max_value.toString() : ''}
        type="number"
      />
    ),
    [DirectAnswerTypes.ANSWER_PHONE_NUMBER]: (
      <PhoneInput
        {...inputCommon}
        country="us"
        onlyCountries={['us']}
        id="phone_number"
        inputClass="phone-number-input"
        value={formData.answer || ''}
        onChange={phone => updateForm(phone)}
        disableDropdown
        disableCountryCode
      />
    ),
    [DirectAnswerTypes.ANSWER_EMAIL]: (
      <input
        {...inputCommon}
      />
    ),
    [DirectAnswerTypes.ANSWER_URL_WEBSITE]: (
      <input
        {...inputCommon}
      />
    ),
    [DirectAnswerTypes.ANSWER_LONG_TEXT]: (
      <ChatTextArea
        {...inputCommon}
        rows={3}
      />
    ),
  };

  return directForms[formType];
};

export default DirectForm;
