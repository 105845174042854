import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, CompetitorProps } from './action-types';

export default {
  getCompetitors: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_COMPETITORS_REQUEST,
        ActionType.GET_COMPETITORS_SUCCESS,
        ActionType.GET_COMPETITORS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.COMPETITOR, { params: { show_all: true } }),
    },
  }),
  updateCompetitorById: (id: number, params: CompetitorProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_COMPETITOR_REQUEST,
        ActionType.UPDATE_COMPETITOR_SUCCESS,
        ActionType.UPDATE_COMPETITOR_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.COMPETITOR_DETAILS, id), params),
    },
  }),

  createCompetitor: (data: CompetitorProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_COMPETITOR_REQUEST,
        ActionType.CREATE_COMPETITOR_SUCCESS,
        ActionType.CREATE_COMPETITOR_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.COMPETITOR, data),
    },
  }),

  deleteCompetitorRateById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_COMPETITOR_REQUEST,
        ActionType.DELETE_COMPETITOR_SUCCESS,
        ActionType.DELETE_COMPETITOR_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.COMPETITOR_DETAILS, id)),
    },
  }),
};
