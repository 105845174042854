import { paths } from 'dwell/constants';
import { CallBackFunction } from 'src/interfaces';
import { build } from 'dwell/constants/paths';
import { ActionType, Action } from './action-types';
import { ChatTemplateProps } from '../chat_template/action-types';

export default {
  getTargetNumbers: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_TARGET_NUMBERS_REQUEST,
        ActionType.GET_TARGET_NUMBERS_SUCCESS,
        ActionType.GET_TARGET_NUMBERS_FAILURE],
      promise: client => client.get(paths.api.v1.TARGET_NUMBERS, { params: { show_all: true } }),
    },
  }),
  getPropertyTargetNumber: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTY_TARGET_NUMBER_REQUEST,
        ActionType.GET_PROPERTY_TARGET_NUMBER_SUCCESS,
        ActionType.GET_PROPERTY_TARGET_NUMBER_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROPERTY_TARGET_NUMBER),
    },
  }),
  createTargetNumber: (data: ChatTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_TARGET_NUMBER_REQUEST,
        ActionType.CREATE_TARGET_NUMBER_SUCCESS,
        ActionType.CREATE_TARGET_NUMBER_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.TARGET_NUMBERS, data),
      successCB,
    },
  }),
  createTargetNumberSiteOnly: (data: ChatTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_TARGET_NUMBER_REQUEST,
        ActionType.CREATE_TARGET_NUMBER_SUCCESS,
        ActionType.CREATE_TARGET_NUMBER_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.TARGET_NUMBERS_SITE_ONLY, data),
      successCB,
    },
  }),
  updateTargetNumber: (id: number, data: ChatTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_TARGET_NUMBER_REQUEST,
        ActionType.UPDATE_TARGET_NUMBER_SUCCESS,
        ActionType.UPDATE_TARGET_NUMBER_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.TARGET_NUMBER_DETAILS, id), data),
      successCB,
    },
  }),
  updateTargetNumberSiteOnly: (id: number, data: ChatTemplateProps, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_TARGET_NUMBER_REQUEST,
        ActionType.UPDATE_TARGET_NUMBER_SUCCESS,
        ActionType.UPDATE_TARGET_NUMBER_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.TARGET_NUMBER_SITE_ONLY_DETAILS, id), data),
      successCB,
    },
  }),
  deleteTargetNumber: (id: number, data: { replace_number: number }, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_TARGET_NUMBER_REQUEST,
        ActionType.DELETE_TARGET_NUMBER_SUCCESS,
        ActionType.DELETE_TARGET_NUMBER_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.TARGET_NUMBER_DETAILS, id), { data }),
      successCB,
    },
  }),
  deleteTargetNumberSiteOnly: (id: number, successCB: CallBackFunction = null): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_TARGET_NUMBER_REQUEST,
        ActionType.DELETE_TARGET_NUMBER_SUCCESS,
        ActionType.DELETE_TARGET_NUMBER_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.TARGET_NUMBER_SITE_ONLY_DETAILS, id)),
      successCB,
    },
  }),
};
