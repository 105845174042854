import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { GetLeadByIdAction } from 'dwell/store/lead/action-types';
import { GetLeaseAction } from 'resident/store/lease/action-types';

export enum ActionType {
  DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST',
  DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE',
  UPDATE_NOTE_REQUEST = 'UPDATE_NOTE_REQUEST',
  UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE',
  CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST',
  CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS',
  CREATE_NOTE_FAILURE = 'CREATE_NOTE_FAILURE',
}

export interface NoteProps {
  id?: number;
  text?: string;
  lead?: number;
  owner?: number;
  mentions?: string[];
  is_followup_note?: boolean;
  transformed_text?: string;
  updated?: Date;
  created?: Date;
  is_follow_up?: boolean;
}

interface DeleteNoteByIdAction {
  type: ActionType.DELETE_NOTE_REQUEST | ActionType.DELETE_NOTE_SUCCESS | ActionType.DELETE_NOTE_FAILURE;

  result: {
    data: NoteProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateNoteByIdAction {
  type: ActionType.UPDATE_NOTE_REQUEST | ActionType.UPDATE_NOTE_SUCCESS | ActionType.UPDATE_NOTE_FAILURE;

  result: {
    data: NoteProps;
  };
  error: {
    response: { status: string };
  };
}

interface CreateNoteAction {
  type: ActionType.CREATE_NOTE_REQUEST | ActionType.CREATE_NOTE_SUCCESS | ActionType.CREATE_NOTE_FAILURE;

  result: {
    data: NoteProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | DeleteNoteByIdAction
  | UpdateNoteByIdAction
  | CreateNoteAction
  | PusherAction
  | GetLeadByIdAction
  | GetLeaseAction;
