import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { Action, ActionType, Tooltip } from './action-types';
import { CommonStateType } from '../types';

export interface TooltipState extends CommonStateType {
  isSubmitting: boolean;
  isTooltipItemsLoaded: boolean;
  tooltipItems: Tooltip[];
}

const initialState: TooltipState = {
  isSubmitting: false,
  isTooltipItemsLoaded: false,
  tooltipItems: [],
};

export default produce((state: TooltipState = initialState, action: Action): TooltipState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_TOOLTIP_ITEMS_REQUEST:
      state.isTooltipItemsLoaded = false;
      break;
    case ActionType.GET_TOOLTIP_ITEMS_SUCCESS:
      state.isTooltipItemsLoaded = true;
      state.tooltipItems = action.result.data;
      break;
    case ActionType.GET_TOOLTIP_ITEMS_FAILURE:
      state.isTooltipItemsLoaded = false;
      break;
  }

  return state;
});
