import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, OtherRentItemProps, OtherRentItemState } from './action-types';

export const initialState: OtherRentItemState = {
  errorMessage: null,
  otherRentItems: [] as OtherRentItemProps[],
  isLoaded: false,
};

export const selectOtherRentItems = (state: { otherRentItem: OtherRentItemState }) : OtherRentItemProps[] => state.otherRentItem.otherRentItems;

export default produce((state: OtherRentItemState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_OTHER_RENT_ITEM_SUCCESS:
    case ActionType.UPDATE_OTHER_RENT_ITEM_SUCCESS:
      state.otherRentItems = unionBy([action.result.data], state.otherRentItems, 'id');
      break;

    case ActionType.DELETE_OTHER_RENT_ITEM_SUCCESS:
      state.otherRentItems = state.otherRentItems.filter(i => i.id !== action.result.data.id);
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.otherRentItems = action.result.data.other_rent_items;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
