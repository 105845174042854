import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { LeadDetailHeader as DetailHeader, LeadDetailMain as DetailMain } from 'dwell/views/lead/layout/styles';
import 'src/scss/pages/_lead_details.scss';
import { selectLeaseDetails } from 'resident/store/lease/reducers';
import KeyInfo from '../key_info';
import Loader from './loader';

interface LeadDetailLayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LeadDetailLayoutProps> = ({ children }) => {
  const { unit } = useSelector(selectLeaseDetails);

  return (
    <DetailMain className="resident-details">
      <Helmet>
        <title>{unit ? `Union | Resident - ${unit}` : 'Union'}</title>
      </Helmet>
      <DetailHeader>
        <KeyInfo />
      </DetailHeader>
      {children}
      <Loader />
    </DetailMain>
  );
};

export default Layout;
