import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, ConcessionProps, ConcessionState } from './action-types';

export const initialState: ConcessionState = {
  errorMessage: null,
  concessions: [] as ConcessionProps[],
  isLoaded: false,
};

export const selectConcession = (state: { concession: ConcessionState }): ConcessionProps[] => state.concession.concessions;

export default produce((state: ConcessionState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_CONCESSION_SUCCESS:
      state.concessions = unionBy([action.result.data], state.concessions, 'id');
      break;

    case ActionType.DELETE_CONCESSION_SUCCESS:
      state.concessions = state.concessions.filter(i => i.id !== action.result.data.id);
      break;

    case ActionType.UPDATE_CONCESSION_SUCCESS:
      state.concessions = unionBy([action.result.data], state.concessions, 'id');
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.concessions = action.result.data.concessions;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
