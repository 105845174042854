import produce from 'immer';
import { COMMUNICATION_FILTER } from 'dwell/constants';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { ActionType as PetActionType } from 'leasing/store/pet/action-types';
import { ActionType as VehicleActionType } from 'leasing/store/vehicle/action-types';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { ActionType as ApplicationActionType } from 'application/store/application/action-types';
import { ActionType as ResidentLeaseActionType } from 'resident/store/lease/action-types';
import { ActionType as TaskActionType } from '../task/action-types';
import { ActionType as ChatActionType } from '../chat/action-types';
import { ActionType as SMSActionType } from '../sms/action-types';
import { ActionType as LeadActionType, LeadAction } from '../lead/action-types';
import { Action, ActionType, Communication } from './action-types';
import { CommonStateType } from '../types';

export interface LeadState extends CommonStateType {
  communications: Communication[];
  isCommunicationUpdate: boolean;
  filterType: string;
  shouldReload: boolean;
}

const communicationFilter = localStorage.getItem(COMMUNICATION_FILTER);

export const initialState: LeadState = {
  isSubmitting: false,
  isLoaded: false,
  communications: [],
  isCommunicationUpdate: false,
  filterType: communicationFilter || '',
  shouldReload: false,
};

const objectCreatedSignals = [
  ActionType.CREATE_NOTE_SUCCESS,
  ActionType.UPDATE_NOTE_SUCCESS,
  ActionType.DELETE_NOTE_SUCCESS,
  ApplicationActionType.UPDATE_APPLICATION_SUCCESS,
  TaskActionType.CREATE_TASK_SUCCESS,
  TaskActionType.UPDATE_TASK_SUCCESS,
  TaskActionType.DELETE_TASK_SUCCESS,
  TaskActionType.COMPLETE_TASK_SUCCESS,
  TaskActionType.MARK_NO_SHOW_TASK_SUCCESS,
  ActionType.SEND_MESSAGE_SUCCESS,
  SMSActionType.SEND_SMS_SUCCESS,
  ChatActionType.SEND_CHAT_MESSAGE_SUCCESS,
  LeadActionType.UPDATE_LEAD_SUCCESS,
  LeadActionType.SHARE_LEAD_SUCCESS,
  ActionType.DELETE_ROOMMATE_SUCCESS,
  ActionType.UPDATE_ROOMMATES_SUCCESS,
  PetActionType.CREATE_LEAD_PETS_SUCCESS,
  PetActionType.UPDATE_LEAD_PET_SUCCESS,
  PetActionType.DELETE_LEAD_PET_SUCCESS,
  VehicleActionType.CREATE_LEAD_VEHICLES_SUCCESS,
  VehicleActionType.UPDATE_LEAD_VEHICLE_SUCCESS,
  VehicleActionType.DELETE_LEAD_VEHICLE_SUCCESS,
  LeaseActionType.UPDATE_APPLICATION_STATUS_SUCCESS,
  LeaseActionType.UPDATE_LEASE_SUCCESS,
  ResidentLeaseActionType.UPDATE_LEASE_RESIDENT_SUCCESS,
];

const getObjectId = (pathname: string): number => {
  const splitStrings = pathname.split('/');
  const index = splitStrings.findIndex(i => ['leads', 'leases', 'applications'].includes(i));
  return parseInt(splitStrings[index + 1], 10);
};

export default produce((state: LeadState = initialState, action: Action): LeadState => {
  const leadAction = action as LeadAction;
  if (objectCreatedSignals.includes(leadAction.type)) {
    state.shouldReload = true;
  }

  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.type !== 'PUSHER_CREATE_RECORD' && !['lead', 'note'].includes(pusherAction.pusherModel)) {
    return state;
  }

  switch (action.type) {
    case ActionType.GET_COMMUNICATIONS_REQUEST:
      state.isLoaded = false;
      state.communications = [];
      break;

    case ActionType.GET_COMMUNICATIONS_SUCCESS: {
      const objectId = getObjectId(window.location.pathname);
      if (objectId === action.objectId) {
        state.isLoaded = true;
        state.communications = action.result.data.results;
      }
      break;
    }

    case ActionType.GET_COMMUNICATIONS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = false;
      break;

    case ActionType.RESET_RELOAD_STATE:
      state.shouldReload = false;
      break;

    case ActionType.RESET_COMMUNICATIONS:
      state.isLoaded = false;
      state.communications = [];
      break;

    // case PusherActionType.PUSHER_CREATE_RECORD: {
    //   const row = action.row as PusherRow;
    //   let isCurrentLead = true;
    //   if (action.pusherModel === 'chatconversation') {
    //     const currentLead = (state.lead.chat_prospects || []).find(el => el.id === row.prospect);
    //     isCurrentLead = !!currentLead;
    //   } else if (action.pusherModel === 'smscontent') {
    //     isCurrentLead = state.lead.id === row.lead;
    //   } else if (action.pusherModel === 'lead') {
    //     isCurrentLead = get(state, 'lead.id') === get(row, 'id', '');
    //   } else if (['emailmessage', 'note', 'task'].includes(action.pusherModel)) {
    //     isCurrentLead = get(state, 'lead.id') === get(row, 'lead', '');
    //   }
    //   if (!isCurrentLead) break;
    //   state.shouldReload = true;
    //   break;
    // }
    //
    // case PusherActionType.PUSHER_UPDATE_RECORD: {
    //   if (get(action.row, 'id', '') === get(state, 'lead.id')) {
    //     const row = action.row as PusherRow;
    //     state.shouldReload = true;
    //   }
    //   break;
    // }
  }

  return state;
});
