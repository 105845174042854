import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectSelectedProperties } from 'dwell/store/property/reducers';
import actions from 'dwell/store/properties_selector/action-creators';
import { PropertyProps } from 'dwell/store/property/action-types';

import { PmLabel, PmLabelAnchor, PmLabelLabel, SPCountSpan } from 'containers/multi_property_selector/styles';

interface SelectedPropertiesLabelProps {
  allSelected: boolean;
}

const SelectedPropertiesLabel: FC<SelectedPropertiesLabelProps> = ({ allSelected }) => {
  const selectedProperties = useSelector(selectSelectedProperties);
  const dispatch = useDispatch();

  const handler = (properties: PropertyProps[]) => {
    dispatch(actions.setPostSelectedProperties(properties));
    dispatch(actions.setShowApplyButton(true));
  };

  return (
    <PmLabel>
      <PmLabelLabel>
        Selected Properties{' '}
        <SPCountSpan className="text-secondary">
          {'('}
          <span id="pmCount">{selectedProperties.length}</span>
          {')'}
        </SPCountSpan>
      </PmLabelLabel>

      {(selectedProperties.length > 1 && allSelected) && (
        <PmLabelLabel>
          <PmLabelAnchor onClick={() => handler(selectedProperties.slice(0, 1))}>
            Deselect All
          </PmLabelAnchor>
        </PmLabelLabel>
      )}

      {(selectedProperties.length > 1 && !allSelected) && (
        <PmLabelLabel>
          <PmLabelAnchor onClick={() => handler(selectedProperties)}>
            Select All
          </PmLabelAnchor>
        </PmLabelLabel>
      )}
    </PmLabel>
  );
};

export default SelectedPropertiesLabel;
