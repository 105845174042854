import assignLeadOwners from './assignment_rules/reducers';
import assistBot from './assist_bot/reducers';
import authentication from './authentication/reducers';
import businessHours from './business_hours/reducers';
import calendar from './calendars/reducers';
import callScoringQuestions from './call_scoring_questions/reducers';
import communityDetails from './community_details/reducers';
import emailPopouts from './email_popout/reducers';
import focusBar from './focus_bar/reducers';
import holiday from './holidays/reducers';
import lead from './lead/reducers';
import notification from './notification/reducers';
import propertiesSelector from './properties_selector/reducers';
import property from './property/reducers';
import propertyProfile from './property_profile/reducers';
import prospectChat from './chat/reducers';
import pusher from './pusher/reducers';
import rescoreUsers from './rescore_users/reducers';
import scoredCalls from './scored_calls/reducers';
import smsMessage from './sms/reducers';
import task from './task/reducers';
import user from './user/reducers';
import voice from './voice/reducers';
import report from './report/reducers';
import portfolio from './portfolio/reducers';
import agentQuestion from './agent_question/reducers';
import priceQuote from './price_quote/reducers';
import call from './call/reducers';
import emailMessage from './email_message/reducers';
import nylas from './nylas/reducers';
import note from './note/reducers';
import nurture from './nurture_automations/reducers';
import nurtureStep from './nurture_step/reducers';
import supportPortal from './support_portal/reducers';
import pipeline from './pipeline/reducers';
import communication from './communication/reducers';
import apartment from './apartment/reducers';
import callSchedule from './call_schedule/reducers';
import chatTemplate from './chat_template/reducers';
import chatsEvaluation from './chat_evaluation/reducers';
import columnsSettings from './columns_settings/reducers';
import competitor from './competitor/reducers';
import emailLabel from './email_label/reducers';
import emailTemplate from './email_template/reducers';
import leadsFilter from './leads_filter/reducers';
import pmsLogs from './pms_logs/reducers';
import prospectSource from './prospect_source/reducers';
import smsTemplate from './sms_template/reducers';
import resetPassword from './reset_password/reducers';
import roommate from './roommate/reducers';
import survey from './survey/reducers';
import targetNumber from './target_number/reducers';
import leavingReasons from './leaving_reasons/reducers';
import insiderInfo from './insider_info/reducers';

export default {
  assignLeadOwners,
  assistBot,
  authentication,
  businessHours,
  calendar,
  callScoringQuestions,
  communityDetails,
  emailPopouts,
  focusBar,
  holiday,
  lead,
  notification,
  propertiesSelector,
  property,
  propertyProfile,
  prospectChat,
  pusher,
  rescoreUsers,
  scoredCalls,
  smsMessage,
  task,
  user,
  voice,
  report,
  portfolio,
  agentQuestion,
  priceQuote,
  call,
  emailMessage,
  nylas,
  note,
  nurture,
  nurtureStep,
  supportPortal,
  pipeline,
  communication,
  apartment,
  callSchedule,
  chatTemplate,
  chatsEvaluation,
  columnsSettings,
  competitor,
  emailLabel,
  emailTemplate,
  leadsFilter,
  pmsLogs,
  prospectSource,
  smsTemplate,
  resetPassword,
  roommate,
  survey,
  targetNumber,
  leavingReasons,
  insiderInfo,
};
