import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_SMS_TEMPLATES_REQUEST = 'GET_SMS_TEMPLATES_REQUEST',
  GET_SMS_TEMPLATES_SUCCESS = 'GET_SMS_TEMPLATES_SUCCESS',
  GET_SMS_TEMPLATES_FAILURE = 'GET_SMS_TEMPLATES_FAILURE',

  CREATE_SMS_TEMPLATE_REQUEST = 'CREATE_SMS_TEMPLATE_REQUEST',
  CREATE_SMS_TEMPLATE_SUCCESS = 'CREATE_SMS_TEMPLATE_SUCCESS',
  CREATE_SMS_TEMPLATE_FAILURE = 'CREATE_SMS_TEMPLATE_FAILURE',

  UPDATE_SMS_TEMPLATE_REQUEST = 'UPDATE_SMS_TEMPLATE_REQUEST',
  UPDATE_SMS_TEMPLATE_SUCCESS = 'UPDATE_SMS_TEMPLATE_SUCCESS',
  UPDATE_SMS_TEMPLATE_FAILURE = 'UPDATE_SMS_TEMPLATE_FAILURE',

  DELETE_SMS_TEMPLATE_REQUEST = 'DELETE_SMS_TEMPLATE_REQUEST',
  DELETE_SMS_TEMPLATE_SUCCESS = 'DELETE_SMS_TEMPLATE_SUCCESS',
  DELETE_SMS_TEMPLATE_FAILURE = 'DELETE_SMS_TEMPLATE_FAILURE',
}

export interface SMSTemplateProps {
  id?: number;
  text: string;
  type?: string;
  name: string;
  variables: string[];
  is_system_template?: boolean;
  property?: number;
}

interface GetSMSTemplatesAction {
  type: ActionType.GET_SMS_TEMPLATES_REQUEST | ActionType.GET_SMS_TEMPLATES_SUCCESS | ActionType.GET_SMS_TEMPLATES_FAILURE;

  result: {
    data: { results: SMSTemplateProps[] };
  };
  error: {
    response: { status: string };
  };
}

interface CreateSMSTemplateAction {
  type: ActionType.CREATE_SMS_TEMPLATE_REQUEST | ActionType.CREATE_SMS_TEMPLATE_SUCCESS | ActionType.CREATE_SMS_TEMPLATE_FAILURE;

  result: {
    data: SMSTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateSMSTemplateByIdAction {
  type: ActionType.UPDATE_SMS_TEMPLATE_REQUEST | ActionType.UPDATE_SMS_TEMPLATE_SUCCESS | ActionType.UPDATE_SMS_TEMPLATE_FAILURE;

  result: {
    data: SMSTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

interface DeleteSMSTemplateByIdAction {
  type: ActionType.DELETE_SMS_TEMPLATE_REQUEST | ActionType.DELETE_SMS_TEMPLATE_SUCCESS | ActionType.DELETE_SMS_TEMPLATE_FAILURE;

  result: {
    data: SMSTemplateProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetSMSTemplatesAction
  | CreateSMSTemplateAction
  | UpdateSMSTemplateByIdAction
  | DeleteSMSTemplateByIdAction;
