import moment from 'moment';
import React, { FC, useRef, useEffect } from 'react';

import { ChatItem, ChatItemMessage } from 'dwell/views/chat/single_chat/window/styles';
import { PropertyProps } from 'dwell/store/property/action-types';
import { Avatar } from 'styles/common';
import HobbesAssit from './_hobbesAssist';
import { getAvatarColor, getInitials, setCarousels, MessageProps } from './utils';

interface ProspectMessageProps {
  message: MessageProps;
  index: number;
  prospect: {
    name: string;
    id: number;
    external_id: string;
  };
  currentProperty: PropertyProps
  lastJoinedMessageIdx: number;
  setOpenHobbesPanel: React.Dispatch<React.SetStateAction<boolean>>;
  setAnswerToDisplay: React.Dispatch<React.SetStateAction<string[]>>;
  setIsHobbesAnalyzing: React.Dispatch<React.SetStateAction<boolean>>;
  setHobbesTagAndUnitType: React.Dispatch<React.SetStateAction<{ tag: string, unitType: string }>>;
  inActiveSession: boolean;
}

const ProspectMessage: FC<ProspectMessageProps> = ({ message, index, prospect, currentProperty, lastJoinedMessageIdx, setOpenHobbesPanel, setAnswerToDisplay, setIsHobbesAnalyzing, setHobbesTagAndUnitType, inActiveSession }) => {
  const messageContainer = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    setCarousels(messageContainer);
  }, [messageContainer]);

  const isHtmlInText = (text: string) => {
    const htmlTagRe = /<\/?[a-z][^>]*>/gi;
    const anyHtml = text.match(htmlTagRe);
    if (anyHtml) {
      return anyHtml.length > 0;
    }
    return false;
  };

  return (
    <ChatItem key={`prospect-chat-item-${index}`}>
      <Avatar className={`avatar ${getAvatarColor(prospect.name, prospect.id)}`}>
        <i>{getInitials(prospect.name)}</i>
      </Avatar>
      <ChatItemMessage key={`prospect-chat-msg-${index}`} className="message">
        {isHtmlInText(message.message) ? (
          <p ref={messageContainer} dangerouslySetInnerHTML={{ __html: message.message }} />
        ) : (
          <HobbesAssit
            isHighlightEnabled={inActiveSession}
            message={message.message}
            uuid={prospect.external_id}
            domain={currentProperty.domain}
            setOpenHobbesPanel={setOpenHobbesPanel}
            setAnswerToDisplay={setAnswerToDisplay}
            lastJoinedIdx={lastJoinedMessageIdx}
            lastMessageIdx={message.id}
            setIsHobbesAnalyzing={setIsHobbesAnalyzing}
            setHobbesTagAndUnitType={setHobbesTagAndUnitType}
          />
        )}
        <small>{moment(message.date).format('MMM DD, hh:mma')}</small>
      </ChatItemMessage>
    </ChatItem>
  );
};

export default ProspectMessage;
