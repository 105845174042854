export const menuItems = {
  PROSPECT: {
    icon: 'ri-user-star-fill',
    label: 'Prospects',
    items: {
      ACTIVE: 'Active Prospects',
      MY_ACTIVE: 'My Active Prospects',
      ALL: 'All Prospects',
    },
  },
  APPLICATION: {
    icon: 'ri-file-list-3-line',
    label: 'Applications',
    items: {
      ACTIVE: 'Active Applications',
      MY_ACTIVE: 'My Active Applications',
      ALL: 'All Applications',
    },
  },
  LEASE: {
    icon: 'ri-building-line',
    label: 'Leases',
    items: {
      ACTIVE_LEASES: 'Active Leases',
      FORMER_LEASES: 'Former Leases',
      ALL: 'All Leases',
    },
  },
};
