import React, { FC, useState } from 'react';

import { CustomerProps } from 'site/store/customer/action-types';

import { PropertyMenuGroup, PmCustomer, PmLabel, PmLabelLabel, PropertyMenuSearch, FormPropertySearch, SwitchTab } from 'containers/multi_property_selector/styles';
import CustomerList from './_customer_list';

interface MenuSwitchCustomerProps {
  toggleTab: boolean;
  setToggleTab: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentCustomer: React.Dispatch<React.SetStateAction<CustomerProps>>;
}

const CustomerMenu: FC<MenuSwitchCustomerProps> = ({ toggleTab, setToggleTab, setCurrentCustomer }) => {
  const [keywordCustomer, updateCustomerKeyword] = useState('');

  return (
    <PropertyMenuGroup step={toggleTab ? 0 : 1}>
      <PmCustomer>
        <SwitchTab onClick={() => setToggleTab(false)}>
          <i className="ri-arrow-left-line mg-r-5" /> Back
        </SwitchTab>
      </PmCustomer>
      <PmLabel>
        <PmLabelLabel>Select Customers</PmLabelLabel>
      </PmLabel>
      <PropertyMenuSearch className="mg-b-15">
        <i data-feather="search" />
        <FormPropertySearch
          type="text"
          onKeyDown={(e) => {
            if (e.keyCode === 9) e.preventDefault();
          }}
          placeholder="Search customers"
          name="search"
          value={keywordCustomer}
          onChange={e => updateCustomerKeyword(e.target.value)}
        />
      </PropertyMenuSearch>
      <CustomerList keywordCustomer={keywordCustomer} setCurrentCustomer={setCurrentCustomer} setToggleTab={setToggleTab} />
    </PropertyMenuGroup>
  );
};

export default CustomerMenu;
