import React, { FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import voiceActions from 'dwell/store/voice/action-creator';
import { DialPhoneButton } from 'dwell/views/chat/single_chat/contact/styles';
import { selectCallInProgress } from 'dwell/store/voice/reducers';

const OutgoingCallButton: FC = () => {
  const dispatch = useDispatch();
  const { showDialWindow } = voiceActions;
  const activeCall = useSelector(selectCallInProgress);

  const handleOpen = () => {
    if (!activeCall) {
      dispatch(showDialWindow());
    }
  };

  return (
    <React.Fragment>
      <DialPhoneButton
        id="btnCallOutgoing"
        onClick={handleOpen}
        className="btn btn-xs btn-icon btn-outline btn-call-dial mg-l-20"
        data-toggle="tooltip"
        data-title="Click to dial a phone number"
        data-original-title=""
        title=""
      >
        <img src="/static/images/keypad.png" className="wd-40p" alt="" />
      </DialPhoneButton>
      <UncontrolledTooltip placement="top" target="btnCallOutgoing">
        Click to dial a phone number
      </UncontrolledTooltip>
    </React.Fragment>
  );
};

export default OutgoingCallButton;
