import produce from 'immer';
import { Action, ActionType, ColumnProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';
import { PusherAction, ActionType as PusherActionType } from '../pusher/action-types';

export interface ColumnSettingsState extends CommonStateType {
  columns: ColumnProps[];
}

export const initialState: ColumnSettingsState = {
  isSubmitting: false,
  isLoaded: false,
  errorMessage: null,
  columns: [],
};

export const selectColumnsSettingsColumns = (state: { columnsSettings: ColumnSettingsState }): ColumnProps[] => state.columnsSettings.columns;

export default produce((state: ColumnSettingsState = initialState, action: Action): ColumnSettingsState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && !['column'].includes(pusherAction.pusherModel)) {
    return state;
  }

  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_COLUMNS_SETTINGS_SUCCESS:
      state.columns = action.result.data.results;
      break;

    case ActionType.UPDATE_COLUMNS_SETTINGS_SUCCESS:
      state.columns = action.result.data;
      break;

    case ActionType.CREATE_COLUMNS_SETTINGS_SUCCESS:
      state.columns = action.result.data;
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = action.row as ColumnProps[];
      state.columns = row;
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as ColumnProps;
      state.columns = [row];
      break;
    }
  }

  return state;
});
