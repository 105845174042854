const leaseStages = {
  INQUIRY: 'Inquiry',
  CONTACT_MADE: 'Contact Made',
  TOUR_SET: 'Tour Set',
  TOUR_COMPLETED: 'Tour Completed',
  TOUR_NO_SHOW: 'Tour No Show',
};

export default {
  LEAD_FILED_CHOICES: {
    status: {
      ACTIVE: { title: 'Active', text: 'Active leads impact reporting metrics, surface on the \n Pipeline page and all associated tasks are active for \n completion.', hide: false },
      CLOSED: { title: 'Leased', text: 'Leased leads impact reporting metrics and all \n associated lease related tasks are marked completed. ', hide: false },
      LOST: { title: 'Lost', text: 'Lost leads impact reporting metrics, do not surface as active on the Pipeline page and all associated tasks are deleted.', hide: false },
      TEST: { title: 'Test', text: 'Test leads do not impact reporting, do not surface on the \n Pipeline page and associated tasks are not listed as a \n part of the Tasks page or daily team tasking.', hide: false },
      DELETED: { title: 'Deleted', text: 'Previously deleted options', hide: true },
    },
    stage: {
      ...leaseStages,
      WAITLIST: 'Waitlist',
      APPLICATION_STARTED: 'Application Started',
      APPLICATION_SUBMITTED: 'Application Submitted',
      LEASE_SENT: 'Lease Sent',
      LEASE_SIGNED: 'Lease Signed',
    },
    leaseStage: leaseStages,
    otherOptions: {
      SHARE_LEAD: 'Share Lead',
      SEND_APPLICATION: 'Send Application',
    },
    contactMethod: {
      EMAIL: 'Email',
      PHONE: 'Phone',
      TEXT: 'Text',
    },
    contactTime: {
      MORNING: 'Morning',
      AFTERNOON: 'Afternoon',
      EVENING: 'Evening',
    },
    office: {
      PROPERTY: 'Leasing Office',
      VIRTUAL: 'Virtual Office',
    },
    origin: {
      WEB: 'Web',
      MOBILE: 'Mobile',
      PHONE: 'Phone',
      WALK_IN: 'Walk In',
      UNKNOWN: 'Unknown',
    },
    movingReason: {
      EMPLOYMENT: 'Employment',
      FAMILY: 'Family',
      OTHER: 'Other',
    },
    petType: {
      DOG: 'Dog',
      CAT: 'Cat',
      BIRD: 'Bird',
      OTHER: 'Other',
    },
    petSex: {
      FEMALE: 'Female',
      MALE: 'Male',
      OTHER: 'Other',
    },
    washerDryerMethod: {
      IN_UNIT: 'In Unit',
      HOOK_UP_ONLY: 'Hook up only',
      ON_PREMISE: 'On premise',
      NONE: 'None',
    },
    pmsSyncStatus: {
      SUCCESS: 'Success',
      FAILURE: 'Failure',
      NOT_STARTED: 'Not started yet',
    },
    unitType: {
      STUDIO: 'Studio',
      ONE_BEDROOM: '1 Bed',
      TWO_BEDROOM: '2 Bed',
      THREE_BEDROOM: '3 Bed',
      FOUR_BEDROOM: '4 Bed',
    },
  },
  TASK_FILED_CHOICES: {
    type: {
      FIRST_FOLLOWUP: '1st Follow-up',
      SECOND_FOLLOWUP: '2nd Follow-up',
      THIRD_FOLLOWUP: '3rd Follow-up',
      FINAL_FOLLOWUP: 'Final Follow-up',
      FUTURE_DATE_FOLLOWUP: 'Future Date Follow-up',
      CHECK_APP: 'Check for Application',
      CHECK_DOCS: 'Check for Documents',
      PRICE_AVAILABILITY: 'Send New Price/Availability',
      TOUR: 'Schedule Tour',
      VIRTUAL_TOUR: 'Schedule Virtual Tour',
      IN_PERSON: 'In-Person Tour',
      SELF_GUIDED_TOUR: 'Self-Guided Tour',
      GUIDED_VIRTUAL_TOUR: 'Guided Virtual Tour',
      FACETIME: 'Facetime Tour',
      CONFIRMATION_FOLLOWUP: 'Follow-up - Complete Application',
      SEND_SECURITY_DEPOSIT_AGREEMENT: 'Send Deposit Agreement',
      SEND_LEASE_AGREEMENT: 'Send Lease Agreement',
      VERIFY_INCOME: 'Verify Income',
      MCO_REVIEW_APPLICATION: 'MCO Review Application',
      COUNTERSIGN_LEASE: 'Countersign Lease Agreement',
      COUNTERSIGN_DEPOSIT_AGREEMENT: 'Countersign Deposit Agreement',
      VERIFY_SERVICE_ANIMAL_DOC: 'Verify Service Animal Doc',
      REQUEST_SERVICE_ANIMAL_DOC: 'Request & Verify Service Animal Doc',
      CALL_BACK: 'Call Back',
      FIRST_CONTACT: 'Needs First Contact',
      REPLY_BACK: 'Reply Back',
      SELF_GUIDED_TOUR_FOLLOWUP: 'Self-Guided Tour Follow-up',
      CREATE_AND_SEND_RENEWAL_OFFER: 'Create & Send Renewal Offer',
      START_RENEWAL_PROCESS: 'Start Renewal Process',
      START_NOTICE_TO_VACATE_PROCESS: 'Start Notice to Vacate Process',
      SEND_RENEWAL_INQUIRY_EMAIL: 'Send Renewal Inquiry Email',
      REACTIVATED_LEAD_FOLLOWUP: 'Reactivated Lead Follow-up',
      TOUR_NO_SHOW_FOLLOWUP: 'Tour No Show Follow-up',
      COUNTERSIGN_NOTICE_TO_VACATE: 'Countersign Notice to Vacate',
      COUNTERSIGN_RENEWAL_LEASE: 'Countersign Renewal Lease',
    },
  },
  PROPERTY_POLICY_FILED_CHOICES: {
    apartmentHoldExpiration: {
      MANUAL: 'Manually released',
      '24_HOURS': '24 hours',
      '48_HOURS': '48 hours',
      '72_HOURS': '72 hours',
      WEEK: '1 week',
      TWO_WEEKS: '2 weeks',
    },
    residentParkingType: {
      COVERED_PARKING: 'Assigned, covered parking',
      UNCOVERED_PARKING: 'Assigned, uncovered parking',
      BOTH_PARKING: 'Assigned, covered and uncovered parking',
      UNASSIGNED_FIRST_COME: 'Unassigned, first come/first serve',
    },
    guestParkingTypes: {
      ANY_UNASSIGNED_SPACE: 'In any unassigned space',
      SPACES_MARKED_FOR_GUEST_OR_VISITORS: 'In spaces marked for guest or visitors',
      GUEST_VEHICLE_DISPLAY_GUEST_PASS: 'When the guest vehicle is displaying a guest pass',
    },
    utilities: {
      ELECTRIC: 'electric',
      WATER: 'water',
      TRASH: 'trash',
      GAS: 'gas',
      SEWER: 'sewer',
      RENTERS_INSURANCE: 'renters insurance',
      VALET_TRASH_SERVICE: 'valet trash service',
    },
    pestControlServiceDay: {
      MONDAYS: 'Mondays',
      TUESDAYS: 'Tuesdays',
      WEDNESDAYS: 'Wednesdays',
      THURSDAYS: 'Thursdays',
      FRIDAYS: 'Fridays',
      SATURDAYS: 'Saturdays',
      SUNDAYS: 'Sundays',
    },
    paymentForms: {
      PERSONAL_CHECK: 'Personal Check',
      CASHIER_CHECK: 'Cashier\'s Check',
      ELECTRONIC_CHECK: 'Electronic Check',
      CREDIT_CARD: 'Credit Card',
      DEBIT_CARD: 'Debit Card',
      ONLINE_PAYMENT: 'Online Payment',
      MONEY_ORDER: 'Money Order',
    },
    billingMethods: {
      DIRECT_METERED: 'Direct Metered',
      EQUALLY_DISTRIBUTED: 'Equally Distributed',
      INDIVIDUALLY_METERED: 'Individually Metered',
      INDIVIDUALLY_SUBMETERED: 'Individually Submetered',
      RUBS: 'RUBS',
      RESIDENT_DIRECT_RESPONSIBILITY: 'Resident’s Direct Responsibility',
      NUMBER_OF_OCCUPANTS: 'Number of Occupants',
      CONSERVATION_FEE: 'Conservation Fee',
    },
    allowableDaysApplicantsMoveIn: {
      '7_DAYS': '7 days',
      '14_DAYS': '14 days',
      '30_DAYS': '30 days',
      '60_DAYS': '60 days',
    },
    maxGuarantorAllowed: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      UNLIMITED: 'Unlimited',
    },
    renewalPeriod: {
      '15_DAYS': '15 days',
      '30_DAYS': '30 days',
      '45_DAYS': '45 days',
      '60_DAYS': '60 days',
      '75_DAYS': '75 days',
      '90_DAYS': '90 days',
    },
  },
  INCOME_TYPES: {
    employedIncome: {
      HOURLY_WAGES: 'Hourly Wages',
      MILITARY: 'Military Income',
      SALARY: 'Salary',
      SELF_EMPLOYED: 'Self-Employed',
      TIPS_AND_COMMISSIONS: 'Tips and Commissions',
    },
    incomeType: { gross: 'Gross Income', net: 'Net Income' },
    industryType: {
      CONSTRUCTION: 'Construction',
      EDUCATION: 'Education',
      FINANCIAL_OR_BANKING: 'Financial or Banking Services',
      HEALTHCARE: 'Healthcare',
      INFORMATION_TECHNOLOGY: 'Information Technology',
      LAW_ENFORCEMENT: 'Law Enforcement',
      LEGAL: 'Legal',
      MILITARY: 'Military',
      OTHER: 'Other',
      PUBLIC_ADMINISTRATION: 'Public Administration',
      REAL_STATE: 'Real Estate',
      RETAIL: 'Retail',
      SERVICE_HOSPITALITY: 'Service/Hospitality',
      STUDENT: 'Student',
    },
    nonEmployedIncome: {
      CHILD_SUPPORT: 'Child Support/Alimony',
      DISABILITY: 'Disability',
      ESTATE_OR_TRUST_FUND: 'Estate/Trust Income',
      FINANCIAL_AID: 'Financial Aid',
      HOUSING_ASSISTANCE: 'Housing Assistance',
      RETIREMENT: 'Retirement',
      SOCIAL_SECURITY: 'Social Security',
      UNEMPLOYMENT: 'Unemployment',
    },
  },
  LEASE_FILED_CHOICES: {
    deposit_type: {
      TRADITIONAL: 'Traditional',
      E_PREMIUM: 'ePremium',
      USD: 'Union Installment Security Deposit',
    },
    renewal_offer_status: {
      ACTIVE: 'Active',
      OFFER_ACCEPTED: 'Offer Accepted',
      OFFER_DECLINED: 'Offer Declined',
      OFFER_EXPIRED: 'Offer Expired',
    },
  },
};
