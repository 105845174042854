import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'src/polyfill';
import App from 'src/App';
import PusherManager from 'src/pusher';

const { config } = window.crmApp;

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [new BrowserTracing()],
    release: process.env.SENTRY_RELEASE,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (config.pusherKey && config.pusherCluster) {
  const pusher = new PusherManager(config.pusherKey, config.pusherCluster);
  pusher.initializePusher(['smscontent', 'notification', 'lead', 'roommate', 'note', 'task', 'emailmessage',
    'call', 'leadsfilter', 'column', 'report', 'chatprospect', 'chatconversation', 'agentrequest',
    'lease', 'applicant', 'guarantor', 'document', 'user',
  ]);
}

ReactDOM.render(<App />, document.getElementById('root'));
