import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_NYLAS_AUTH_REQUEST = 'GET_NYLAS_AUTH_REQUEST',
  GET_NYLAS_AUTH_SUCCESS = 'GET_NYLAS_AUTH_SUCCESS',
  GET_NYLAS_AUTH_FAILURE = 'GET_NYLAS_AUTH_FAILURE',
  GET_NYLAS_TOKEN_REQUEST = 'GET_NYLAS_TOKEN_REQUEST',
  GET_NYLAS_TOKEN_SUCCESS = 'GET_NYLAS_TOKEN_SUCCESS',
  GET_NYLAS_TOKEN_FAILURE = 'GET_NYLAS_TOKEN_FAILURE',
  SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE',
  ARCHIVE_MESSAGES_REQUEST = 'ARCHIVE_MESSAGES_REQUEST',
  ARCHIVE_MESSAGES_SUCCESS = 'ARCHIVE_MESSAGES_SUCCESS',
  ARCHIVE_MESSAGES_FAILURE = 'ARCHIVE_MESSAGES_FAILURE',
  SET_EMAIL_OPEN_STATUS = 'SET_EMAIL_OPEN_STATUS',
}

interface AuthorizeAction {
  type: ActionType.GET_NYLAS_AUTH_REQUEST | ActionType.GET_NYLAS_AUTH_SUCCESS | ActionType.GET_NYLAS_AUTH_FAILURE;

  result: {
    data: { auth_url: string };
  };
  error: {
    response: { status: string };
  };
}

interface GetTokenAction {
  type: ActionType.GET_NYLAS_TOKEN_REQUEST | ActionType.GET_NYLAS_TOKEN_SUCCESS | ActionType.GET_NYLAS_TOKEN_FAILURE;

  result: {
    data: { success: boolean };
  };
  error: {
    response: { status: string };
  };
}

interface SendMessageAction {
  type: ActionType.SEND_MESSAGE_REQUEST | ActionType.SEND_MESSAGE_SUCCESS | ActionType.SEND_MESSAGE_FAILURE;

  error: {
    response: { status: string };
  };
}

export interface ArchiveMessagesAction {
  type: ActionType.ARCHIVE_MESSAGES_REQUEST | ActionType.ARCHIVE_MESSAGES_SUCCESS | ActionType.ARCHIVE_MESSAGES_FAILURE;

  result: {
    data: { ids: number[] }
  }

  error: {
    response: { status: string };
  };
}

interface SetEmailOpenStatusAction {
  type: ActionType.SET_EMAIL_OPEN_STATUS;
  data: { dataSetEmail: boolean, isSendApplicationEmail: boolean }
}

export type Action =
  | PromiseActionType
  | AuthorizeAction
  | GetTokenAction
  | SendMessageAction
  | ArchiveMessagesAction
  | SetEmailOpenStatusAction;
