import { BusinessHoursProps } from 'dwell/store/business_hours/action-types';
import { PropertyProps } from 'dwell/store/property/action-types';
import { ActionType as AddendaActionType, AddendaGroup, Addendum } from './addenda/action-types';
import { ActionType as ApplicantActionType, ApplicantActivityProps, ApplicantProps, ApplicantState, PermissionProps, EmergencyContactErrorProps } from './applicant/action-types';
import { ActionType as ConcessionActionType, ConcessionProps } from './concession/action-types';
import { ActionType as DependentActionType, DependentProps, DependentState } from './dependent/action-types';
import { ActionType as DocumentActionType, DocumentProps, SignatureStatusProps } from './document/action-types';
import { ActionType as EmployerActionType, EmployerProps, EmployerErrorProps } from './employer/action-types';
import { ActionType as GarageActionType, GarageProps } from './garage/action-types';
import { ActionType as GateKeyActionType, GateKeyProps } from './gate_key/action-types';
import { ActionType as GuarantorActionType, GuarantorState } from './guarantor/action-types';
import { ActionType as LeaseDocumentActionType, LeaseDocumentProps, LeaseDocumentState, LeaseFieldChoiceProps } from './lease_document/action-types';
import { ActionType as LeaseActionType, LeaseProps, LeaseState } from './lease/action-types';
import { ActionType as UnitActionType, LeaseUnitFilterParams, LeaseUnitProps } from './unit/action-types';
import { ActionType as OtherFeeItemActionType, OtherFeeItemProps } from './other_fee_item/action-types';
import { ActionType as OtherIncomeActionType, OtherIncomeProps, OtherIncomeErrorProps } from './other_income/action-types';
import { ActionType as OtherRentItemActionType, OtherRentItemProps } from './other_rent_item/action-types';
import { ActionType as ParkingActionType, ParkingProps } from './parking/action-types';
import { ActionType as PaymentActionType, PaymentReceivedProps } from './payment/action-types';
import { ActionType as PetActionType, PetProps, PetErrorProps } from './pet/action-types';
import { ScreeningReportProps } from './screening_report/action-types';
import {
  ActionType as SettingsActionType,
  AddendaFieldProps,
  CleaningChargesProps,
  DurationPricingProps,
  LeaseDefaultProps,
  LeaseTermsOptions,
  OtherFeeItemTemplateProps,
  OtherRentItemTemplateProps,
  PropertyPolicyProps,
  RentableItemProps,
  TermPremiumProps,
  UtilityPoliciesProps,
} from './settings/action-types';
import { ActionType as StorageActionType, StorageProps } from './storage/action-types';
import { ActionType as VehicleActionType, VehicleProps } from './vehicle/action-types';
import { ActionType as NoticeActionType, NoticeProps } from './notice/action-types';
import { ActionType as RenewalOfferActionType, RenewalOfferProps, RenewalManagerProps } from './renewal_offer/action-types';

export {
  AddendaGroup,
  Addendum,
  ApplicantActivityProps,
  ApplicantProps,
  ConcessionProps,
  DependentProps,
  DocumentProps,
  DurationPricingProps,
  EmployerErrorProps,
  EmployerProps,
  GarageProps,
  GateKeyProps,
  LeaseDefaultProps,
  LeaseDocumentProps,
  LeaseDocumentState,
  LeaseFieldChoiceProps,
  LeaseProps,
  LeaseTermsOptions,
  LeaseUnitFilterParams,
  LeaseUnitProps,
  OtherFeeItemProps,
  OtherFeeItemTemplateProps,
  OtherIncomeErrorProps,
  OtherIncomeProps,
  OtherRentItemProps,
  OtherRentItemTemplateProps,
  ParkingProps,
  PaymentReceivedProps,
  PermissionProps,
  PetProps,
  PetErrorProps,
  PropertyPolicyProps,
  RentableItemProps,
  ScreeningReportProps,
  SignatureStatusProps,
  StorageProps,
  TermPremiumProps,
  UtilityPoliciesProps,
  VehicleProps,
  EmergencyContactErrorProps,
  CleaningChargesProps,
  NoticeProps,
  RenewalOfferProps,
  RenewalManagerProps,
};

export interface StoreValuesProps {
  addendaFields: AddendaFieldProps,
  applicants: ApplicantProps[],
  businessHours: BusinessHoursProps[],
  cleaningCharges: CleaningChargesProps,
  concessions: ConcessionProps[],
  depositAddendumDocuments: LeaseDocumentProps[],
  depositAgreementDocument: LeaseDocumentProps,
  garages: GarageProps[],
  gateKeys: GateKeyProps[],
  guarantors: ApplicantProps[],
  lease: LeaseProps,
  leaseAddendumDocuments: LeaseDocumentProps[],
  leaseAgreementDocument: LeaseDocumentProps,
  leaseDefault: LeaseDefaultProps,
  otherRentItems: OtherRentItemProps[],
  parking: ParkingProps[],
  payment: PaymentReceivedProps,
  pets: PetProps[],
  property: PropertyProps,
  propertyPolicy: PropertyPolicyProps,
  removedApplicants?: ApplicantProps[],
  storages: StorageProps[],
  unitName: string,
  units: LeaseUnitProps[],
  utilityPolicies: UtilityPoliciesProps,
  vehicles: VehicleProps[],
  notice: NoticeProps,
  noticeDocument: LeaseDocumentProps,
  creditBuilderAddendum: LeaseDocumentProps,
  existingResidentCreditBuilderAddendum: LeaseDocumentProps,
  roommateAddendum: LeaseDocumentProps,
}

export interface LeasingStateTypes {
  applicant: ApplicantState,
  guarantor: GuarantorState,
  lease: LeaseState,
  dependent: DependentState,
}

export interface ApplicantFormikProps {
  applicant: ApplicantProps,
  dependents: DependentProps[],
  pets: PetProps[],
  vehicles: VehicleProps[],
  employedIncomes: EmployerProps[],
  otherIncomes: OtherIncomeProps[],
  permission: PermissionProps,
}

export default {
  ...AddendaActionType,
  ...ApplicantActionType,
  ...ConcessionActionType,
  ...DependentActionType,
  ...DocumentActionType,
  ...SettingsActionType,
  ...EmployerActionType,
  ...GarageActionType,
  ...GateKeyActionType,
  ...GuarantorActionType,
  ...LeaseDocumentActionType,
  ...LeaseActionType,
  ...UnitActionType,
  ...OtherFeeItemActionType,
  ...OtherIncomeActionType,
  ...OtherRentItemActionType,
  ...ParkingActionType,
  ...PaymentActionType,
  ...PetActionType,
  ...StorageActionType,
  ...VehicleActionType,
  ...NoticeActionType,
  ...RenewalOfferActionType,
};
