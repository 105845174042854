import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { Action, ActionType, ClientProps } from './action-types';
import { CommonStateType } from '../types';

export interface ClientState extends CommonStateType {
  client: ClientProps;
  isClientLoaded: boolean;
  isClientsLoaded: boolean;
  clients: ClientProps[];
  totalCount: number;
  totalCountAll: number;
  totalCountActive: number;
  totalCountInactive: number;
}

const initialState: ClientState = {
  isSubmitting: false,
  client: {},
  isClientLoaded: false,
  isClientsLoaded: false,
  clients: [],
  totalCount: 0,
  totalCountAll: 0,
  totalCountActive: 0,
  totalCountInactive: 0,
};

export const selectClient = (state: { client: ClientState }): ClientProps => state.client.client;
export const selectClients = (state: { client: ClientState }): ClientProps[] => state.client.clients;
export const selectIsClientLoaded = (state: { client: ClientState }): boolean => state.client.isClientLoaded;
export const selectIsClientsLoaded = (state: { client: ClientState }): boolean => state.client.isClientsLoaded;
export const selectClientIsSubmitting = (state: { client: ClientState }): boolean => state.client.isSubmitting;
export const selectClientTotalCount = (state: { client: ClientState }): number => state.client.totalCount;
export const selectClientTotalCountAll = (state: { client: ClientState }): number => state.client.totalCountAll;
export const selectClientTotalCountActive = (state: { client: ClientState }): number => state.client.totalCountActive;
export const selectClientTotalCountInactive = (state: { client: ClientState }): number => state.client.totalCountInactive;

export default produce((state: ClientState = initialState, action: Action): ClientState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.UPDATE_CLIENT_SUCCESS:
      state.clients = unionBy([action.result.data], state.clients, 'id');
      break;

    case ActionType.GET_CLIENTS_REQUEST:
      state.isClientsLoaded = false;
      break;
    case ActionType.GET_CLIENTS_SUCCESS:
      state.isClientsLoaded = true;
      state.clients = action.result.data.results;
      state.totalCount = action.result.data.count;
      state.totalCountAll = action.result.data.count_all;
      state.totalCountActive = action.result.data.count_active;
      state.totalCountInactive = action.result.data.count_inactive;
      break;
    case ActionType.GET_CLIENTS_FAILURE:
      state.isClientsLoaded = false;
      break;
  }

  return state;
});
