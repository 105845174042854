import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';
import { ModalHeader, ModalBody, ModalFooter, Row, FormFeedback } from 'reactstrap';
import { SelectToggle } from 'styles/common';
import { FilterDropdownMenu as FilterDropdownMenuBase, PortfolioTabContentType } from 'dwell/views/reports/styles';

export const TaskModalHeader = styled(ModalHeader)`
    border-bottom-width: 0;
    padding: 25px 30px;
    position: relative;

    .modal-title {
        font-weight: 500;
        font-size: 20px;
        color: #344563;
        margin-bottom: 0;
    }

    .close {
        font-size: 24px;
        font-weight: 400;
        line-height: 1;
        color: #657697;
        font-family: 'remixicon' !important;
        font-style: normal;
        padding: 10px 5px;
        :before {
            content: "\\eb99";
        }
    }
`;

export const TaskModalBody = styled(ModalBody)`
    padding: 0 30px 30px;

    .form-group {
        margin-bottom: 0;
    }

    .reminder-checkbox {
        padding-top: 20px;
    }

    .SingleDatePicker {
        height: 38px;
    }

    .DateInput_input {
        height: 38px !important;
        border-bottom: 1px solid ${props => props.theme.colors.gray400} !important;
        padding: 6px 12px;
    }

    .rw-input {
        padding: 0.55rem .857em;
    }

    .col-6 {
        padding-left: 10px;
        padding-right: 10px;
    }

    #description {
        border-color: hsl(0,0%,80%);
    }
`;

export const TaskModalFooter = styled(ModalFooter)`
    display: flex;
    flex-direction: row;
    border-top: 1px solid #fff;
    padding: 15px 30px 30px;
    border-top-width: 0;

    button {
        opacity: 1;
        height: 38px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
    }

    .btn-secondary {
        background-color: #fff;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        color: ${props => props.theme.colors.gray600};

        :hover {
            background-color: #fff;
            border-color: ${props => props.theme.colors.gray400};
        }
    }

    .btn-primary {
        margin-left: 10px !important;
        background-color: ${props => props.theme.colors.colorui01};
        border-color: ${props => props.theme.colors.colorui01};

        :hover {
            background-color: ${props => props.theme.colors.colorui02};
            border-color: ${props => props.theme.colors.colorui02};
        }
    }
`;

export const InlineRow = styled(Row)`
    margin-left: -10px;
    margin-right: -10px;
`;

export const DueDateFeedback = styled(FormFeedback)`
    margin-top: 0.6rem!important;
`;

export const TogglePlaceHolder = styled.span`
    color: ${props => props.theme.colors.gray600} !important;
`;

export const StyledDatePicker = styled(DatePicker)`
    ${props => props.disabled && css`
        background-color: #e4e7ea !important;
        cursor: wait !important;
    `}
`;

export const DropdownToggle = styled(SelectToggle)`
    width: 100%;
    height: 40px;
    padding: 0 28px 2px 12px;
    padding-right: 0px;
    border-radius: 5px;
    position: relative;
    outline: none;
    transition: all 0.2s;
    background-color: #fff !important;
    border-color: hsl(0,0%,80%) !important;
    color: hsl(0,0%,20%) !important;

    .default-value {
      color: hsl(0,0%,50%);
    }

    .indicator-container {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
    }

    .indicator-container-divider {
        align-self: stretch;
        background-color: hsl(0,0%,80%);
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }

    .indicator-icon-container {
        color: hsl(0, 0%, 80%);
        display: flex;
        padding: 8px;
        transition: color 150ms;
        box-sizing: border-box;

        svg {
            display: inline-block;
            fill: currentColor;
            line-height: 1;
            stroke: currentColor;
            stroke-width: 0;
        }
    }

    &:hover {
        border-color: hsl(0,0%,70%) !important;
    }

    ${props => props.$active && css`
        border-color: #2684ff !important;
        border-width: 2px;

        &:hover {
            border-color: #2684ff !important;
        }
        .indicator-icon-container {
          color: hsl(0, 0%, 40%);
        }
    `}
`;

export const FilterDropdownMenu = styled(FilterDropdownMenuBase)`
    width: 100%;

    .tab-pane {
        padding: 0 10px 10px 10px;
        display: none;
        &.active{
            display: block;
        }
    }
`;

export const SearchContainer = styled.div`
    margin: 10px;
    input {
        height: 36px !important;
    }
`;

export const ContentItem = styled(PortfolioTabContentType)`
    color: ${props => props.theme.colors.bodyColor};
    ${props => props.$label && css`
        pointer-events: none;
        font-size: 0.925rem;
    `}
`;
