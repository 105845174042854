import { paths } from 'dwell/constants';
import { PromiseActionType } from 'dwell/store/types';
import { ActionType } from './action-types';

export default {
  getCalendars: (): PromiseActionType => ({
    CALL_API: {
      types: [
        ActionType.GET_CALENDARS_REQUEST,
        ActionType.GET_CALENDARS_SUCCESS,
        ActionType.GET_CALENDARS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.CALENDARS),
    },
  }),
};
