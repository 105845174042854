import React, { FC } from 'react';
import { CustomCol, Label } from 'leasing/views/applicants/styles';
import { DropdownLink, LeaseDropdown, LeaseDropdownItem, LeaseDropdownMenu } from 'dwell/views/lead/overview/styles';

interface CustomSelectorProps {
  label: string
  dropdownFunction: ()=> void
  dropdownState: boolean
  xs: number
}

const CustomSelector: FC<CustomSelectorProps> = ({
  label,
  dropdownFunction,
  dropdownState,
  xs,
}) => (
  <>
    <CustomCol xs={xs} >
      <Label style={{ fontSize: 12 }}>{label}</Label>
      <LeaseDropdown
        style={{ height: 36, fontSize: 12 }}
        isOpen={dropdownState}
        toggle={dropdownFunction}
      >
        <DropdownLink caret style={{ height: 36, fontSize: 12 }}>
                Choose one
        </DropdownLink>
        <LeaseDropdownMenu right>
          <LeaseDropdownItem>
                  Choose one
          </LeaseDropdownItem>
        </LeaseDropdownMenu>
      </LeaseDropdown>
    </CustomCol>
  </>
);

export default CustomSelector;
