import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { LineSkeleton } from 'src/utils';

import { Avatar } from 'styles/common';
import voiceActions from 'dwell/store/voice/action-creator';
import { AgentPhoneType, CallContact } from 'dwell/store/voice/action-types';
import { dialFormat, isIonAgent, isOnSiteAgent, formatPhoneNumber } from 'dwell/views/calls/communications/voice_centre/utils';
import { selectDevice, selectCallInProgress, selectCallContactsLoading } from 'dwell/store/voice/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { getInitials } from '../../../common/utils';
import {
  CallsProspectItem,
  CallsProspectBody,
} from '../styles';

interface CallProspectProps {
  contact: CallContact
}

const CallProspect: FC<CallProspectProps> = ({ contact }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const device = useSelector(selectDevice);
  const currentUser = useSelector(selectCurrentUser);
  const callInProgress = useSelector(selectCallInProgress);
  const loadingContacts = useSelector(selectCallContactsLoading);

  const { callContactFromBrowserPhone, setCallingFromDesktopProspectName, callContactFromPropertyOfficeLine } = voiceActions;

  const {
    name: prospectName,
    id: prospectId,
    phone_number: prospectPhone,
    property_name: propertyName,
    property: propertyId,
    property_external_id: propertyExternalId,
  } = contact;

  const callFromBrowser = async () => {
    const params = {
      To: dialFormat(prospectPhone),
      PropertyId: propertyId.toString(),
      LeadId: prospectId.toString(),
    };
    const call = await device.connect({ params });

    dispatch(callContactFromBrowserPhone({ prospectName, propertyName, propertyExternalId, propertyId, leadId: prospectId, phoneType: AgentPhoneType.BROWSER_PHONE, call }));
  };

  const callFromDesktop = () => {
    dispatch(setCallingFromDesktopProspectName(prospectName));
    dispatch(callContactFromPropertyOfficeLine({ lead_id: prospectId, property_id: propertyId, prospect_phone: prospectPhone }));
  };

  const callLead = (option: string) => {
    if (isEmpty(callInProgress)) { // user can not call again if it is in another call already
      if (option === 'browser') {
        callFromBrowser();
      } else {
        callFromDesktop();
      }
    }
  };

  return (
    <CallsProspectItem id={`call-option-${prospectId}`}>
      {loadingContacts ? (
        <Skeleton circle height={46} width={46} />
      ) : (
        <Avatar
          className="avatar"
          hideOnlineIcon
          data-toggle="dropdown"
          onClick={() => setMenuOpen(true)}
        >
          <span>{getInitials(prospectName)}</span>
          <i className="ri-phone-fill" />
        </Avatar>
      )}
      <CallsProspectBody>
        {loadingContacts ? (
          <LineSkeleton width={200} height={12} />
        ) : (
          <>
            <h6>{`${prospectName} | ${propertyName}`}</h6>
            <span>{formatPhoneNumber(prospectPhone)}</span>
          </>
        )}
      </CallsProspectBody>
      <Dropdown isOpen={menuOpen} toggle={() => setMenuOpen(!menuOpen)}>
        <DropdownToggle tag="div" className="btn" />
        <DropdownMenu right>
          {isIonAgent(currentUser.role, currentUser?.browser_phone) && (
            <DropdownItem onClick={() => callLead('browser')} tag="a">
              Call from Union phone (browser)
            </DropdownItem>
          )}
          {isOnSiteAgent(currentUser.role) && (
            <DropdownItem onClick={() => callLead('desktop')} tag="a">
                Call from Mark Taylor phone (desktop)
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </CallsProspectItem>
  );
};

export default CallProspect;
