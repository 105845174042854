export const getLeaseId = (pathname: string): number => {
  const splitStrings = pathname.split('/');
  const index = splitStrings.findIndex(i => i === 'leases');
  return parseInt(splitStrings[index + 1], 10);
};

export const additionalTabByType = {
  REFRESH_PROFILE: { label: 'Refresh Profile', path: 'refresh-profile-progress' },
  REMOVE_PEOPLE: { label: 'Remove People', path: 'remove-people-progress' },
  RENEW: { label: 'Renew', path: 'renew' },
  ADD_PEOPLE: { label: 'Add People', path: 'add-people-progress' },
  NOTICE: { label: 'Notice', path: 'notice' },
};
