import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';

export enum ActionType {
  GET_PROSPECTS_REQUEST = 'GET_PIPELINE_PROSPECTS_REQUEST',
  GET_PROSPECTS_SUCCESS = 'GET_PIPELINE_PROSPECTS_SUCCESS',
  GET_PROSPECTS_FAILURE = 'GET_PIPELINE_PROSPECTS_FAILURE',

  GET_APPLICATIONS_REQUEST = 'GET_PIPELINE_APPLICATIONS_REQUEST',
  GET_APPLICATIONS_SUCCESS = 'GET_PIPELINE_APPLICATIONS_SUCCESS',
  GET_APPLICATIONS_FAILURE = 'GET_PIPELINE_APPLICATIONS_FAILURE',

  GET_LEASES_REQUEST = 'GET_PIPELINE_LEASES_REQUEST',
  GET_LEASES_SUCCESS = 'GET_PIPELINE_LEASES_SUCCESS',
  GET_LEASES_FAILURE = 'GET_PIPELINE_LEASES_FAILURE',

  ALL_SEARCH_REQUEST = 'ALL_SEARCH_REQUEST',
  ALL_SEARCH_SUCCESS = 'ALL_SEARCH_SUCCESS',
  ALL_SEARCH_FAILURE = 'ALL_SEARCH_FAILURE',

  GET_FILTERS_REQUEST = 'GET_FILTERS_REQUEST',
  GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS',
  GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE',

  CREATE_FILTER_REQUEST = 'CREATE_FILTER_REQUEST',
  CREATE_FILTER_SUCCESS = 'CREATE_FILTER_SUCCESS',
  CREATE_FILTER_FAILURE = 'CREATE_FILTER_FAILURE',

  UPDATE_FILTER_REQUEST = 'UPDATE_FILTER_REQUEST',
  UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS',
  UPDATE_FILTER_FAILURE = 'UPDATE_FILTER_FAILURE',

  DELETE_FILTER_REQUEST = 'DELETE_FILTER_REQUEST',
  DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS',
  DELETE_FILTER_FAILURE = 'DELETE_FILTER_FAILURE',

  GET_COLUMNS_REQUEST = 'GET_COLUMNS_REQUEST',
  GET_COLUMNS_SUCCESS = 'GET_COLUMNS_SUCCESS',
  GET_COLUMNS_FAILURE = 'GET_COLUMNS_FAILURE',

  UPDATE_COLUMNS_REQUEST = 'UPDATE_COLUMNS_REQUEST',
  UPDATE_COLUMNS_SUCCESS = 'UPDATE_COLUMNS_SUCCESS',
  UPDATE_COLUMNS_FAILURE = 'UPDATE_COLUMNS_FAILURE',

  SEARCH_OBJECT_REQUEST = 'SEARCH_OBJECT_REQUEST',
  SEARCH_OBJECT_SUCCESS = 'SEARCH_OBJECT_SUCCESS',
  SEARCH_OBJECT_FAILURE = 'SEARCH_OBJECT_FAILURE',

  SET_CONTEXT_MENU_INFO = 'SET_CONTEXT_MENU_INFO',
  SET_PAGE = 'SET_PAGE',
  SET_SIZE_PER_PAGE = 'SET_SIZE_PER_PAGE',
  SET_SELECTED = 'SET_SELECTED',
  SET_SORT_FIELD = 'SET_SORT_FIELD',
  SET_SORT_ORDER = 'SET_SORT_ORDER',
  SET_KEYWORD = 'SET_KEYWORD',
  SET_RELOAD_STATE = 'SET_RELOAD_STATE',
  SET_CATEGORY = 'SET_CATEGORY',
  SET_FILTER = 'SET_FILTER',
  SET_FILTER_FORM_DATA = 'SET_FILTER_FORM_DATA',
  SET_ACTIVE_TABLE = 'SET_ACTIVE_TABLE',

  RESET_PAGINATION = 'RESET_PAGINATION',
}

export interface ProspectType {
  id: number;
  name: string;
  stage: string;
  owner: { email: string, role: string, office: string };
  property: number;
  property_name: string;
  property_external_id: string;
  acquisition_date: string;
  move_in_date: string;
  days_to_move_in: number;
  next_task: string;
  next_task_date: string;
  source: string;
  floor_plan: string;
  last_followup_date: string;
  last_activity_date: string;
  created: string;
  is_shared?: boolean;
}

export interface ApplicationType {
  id: number,
  stage: string;
  status: string;
  owner: { email: string, role: string, office: string };
  property: number;
  move_in_date: string;
  days_to_move_in: number;
  next_task: string;
  next_task_date: string;
  last_followup_date: string;
  last_activity_date: string;
  unit: string;
  step: string;
  applicants: string;
  floor_plan: string;
  type: string;
  guarantors: string;
}

export interface LeaseType {
  unit: string;
  residents: string;
  available_date: string;
  lease_expire_date: string;
  days_to_lease_expire: number;
  notice_given: string;
  move_out_date: string;
  concession: string;
  market_rent: string;
  effective_rent: string;
  proposed_rent: string;
  rent_increase: string;
  building_name: string;
  floor_level: string;
  stage: string;
  status: string;
  floor_plan: string;
  type: string;
  owner: { email: string, role: string, office: string };
  property: number;
  move_in_date: string;
  next_task: string;
  next_task_due: string;
  last_followup_date: string;
  last_activity_date: string;
}

export interface SearchResultType {
  type: string;
  name: string;
  stage: string;
  owner: { email: string, role: string, office: string };
  property_name: string;
  property_external_id: string;
  floor_plan: number;
  next_task: string;
  next_task_due: string;
}

export interface FilterType {
  id: number;
  name: string;
  condition: string;
  category: string;
  items: { field: string, value: string[], operator: string }[];
}

export interface ColumnType {
  name: string;
  condition: string;
  category: string;
  fields: {
    name: string;
    is_visible: boolean;
    position: number;
  }[];
}

export interface LeadResidentSearch {
  id: number,
  name: string,
  property: number,
  phone_number: string,
  lease_lead_id: number,
  secondary_phone_number: string
}

export interface CurrentResidentSearch {
  id: number,
  property: number,
  name: string,
  email: string,
  mobile_phone: string,
}

export interface SearchedObjectType {
  leads: LeadResidentSearch[];
  leases: {
    id: number,
    name: string,
    property: number,
  }[];
  residents: LeadResidentSearch[];
  current_residents: CurrentResidentSearch[];
  applications: {
    id: number,
    name: string,
    property: number,
    leasing_users: string[],
    lease_status: string,
    application_stage: string,
  }[];
}

interface GetProspectsAction {
  type: ActionType.GET_PROSPECTS_REQUEST | ActionType.GET_PROSPECTS_SUCCESS | ActionType.GET_PROSPECTS_FAILURE;

  result: {
    data: { results: ProspectType[]; count: number; };
  };
  error: {
    response: { status: string };
  };
}

interface GetApplicationsAction {
  type: ActionType.GET_APPLICATIONS_REQUEST | ActionType.GET_APPLICATIONS_SUCCESS | ActionType.GET_APPLICATIONS_FAILURE;

  result: {
    data: { results: ApplicationType[]; count: number; };
  };
  error: {
    response: { status: string };
  };
}

interface GetLeasesAction {
  type: ActionType.GET_LEASES_REQUEST | ActionType.GET_LEASES_SUCCESS | ActionType.GET_LEASES_FAILURE;

  result: {
    data: { results: LeaseType[]; count: number; };
  };
  error: {
    response: { status: string };
  };
}

interface AllSearchAction {
  type: ActionType.ALL_SEARCH_REQUEST | ActionType.ALL_SEARCH_SUCCESS | ActionType.ALL_SEARCH_FAILURE;

  result: {
    data: { results: SearchResultType[]; count: number; };
  };
  error: {
    response: { status: string };
  };
}

interface GetFiltersAction {
  type: ActionType.GET_FILTERS_REQUEST | ActionType.GET_FILTERS_SUCCESS | ActionType.GET_FILTERS_FAILURE;

  result: {
    data: { results: FilterType[]; };
  };
  error: {
    response: { status: string };
  };
}

interface CreateFilterAction {
  type: ActionType.CREATE_FILTER_REQUEST | ActionType.CREATE_FILTER_SUCCESS | ActionType.CREATE_FILTER_FAILURE;

  result: {
    data: FilterType;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateFilterAction {
  type: ActionType.UPDATE_FILTER_REQUEST | ActionType.UPDATE_FILTER_SUCCESS | ActionType.UPDATE_FILTER_FAILURE;

  result: {
    data: FilterType;
  };

  error: {
    response: { status: string };
  };
}

interface DeleteFilterAction {
  type: ActionType.DELETE_FILTER_REQUEST | ActionType.DELETE_FILTER_SUCCESS | ActionType.DELETE_FILTER_FAILURE;

  result: {
    data: FilterType;
  };

  error: {
    response: { status: string };
  };
}

interface GetColumnsAction {
  type: ActionType.GET_COLUMNS_REQUEST | ActionType.GET_COLUMNS_SUCCESS | ActionType.GET_COLUMNS_FAILURE;

  result: {
    data: { results: ColumnType[]; };
  };
  error: {
    response: { status: string };
  };
}

interface SaveColumnsAction {
  type: ActionType.UPDATE_COLUMNS_REQUEST | ActionType.UPDATE_COLUMNS_SUCCESS | ActionType.UPDATE_COLUMNS_FAILURE;

  result: {
    data: ColumnType;
  };
  error: {
    response: { status: string };
  };
}

interface SearchObjectAction {
  type: ActionType.SEARCH_OBJECT_REQUEST | ActionType.SEARCH_OBJECT_SUCCESS | ActionType.SEARCH_OBJECT_FAILURE;

  result: {
    data: SearchedObjectType;
  };
  error: {
    response: { status: string };
  };
}

export interface RequestProps {
  limit?: number,
  offset?: number,
  order?: string,
  page?: number,
  search?: string,
  filter_id?: string | number,
  field?: string,
}

interface SetContextMenuPosAction {
  type: ActionType.SET_CONTEXT_MENU_INFO;
  info: null | [number, number, { [key: string]: string }];
}

interface SetPageAction {
  type: ActionType.SET_PAGE;
  page: { general: number, combined: number };
}

interface SetSizePerPageAction {
  type: ActionType.SET_SIZE_PER_PAGE;
  sizePerPage: { general: number, combined: number };
}

interface SetSelectedAction {
  type: ActionType.SET_SELECTED;
  selected: number[];
}

interface SetSortFieldAction {
  type: ActionType.SET_SORT_FIELD;
  sortField: { general: string, combined: string };
}

interface SetSortOrderAction {
  type: ActionType.SET_SORT_ORDER;
  sortOrder: { general: string, combined: string };
}

interface SetFilterAction {
  type: ActionType.SET_FILTER;
  filter: string | number;
}

interface SetKeywordAction {
  type: ActionType.SET_KEYWORD;
  keyword: string;
}

interface SetReloadStateAction {
  type: ActionType.SET_RELOAD_STATE;
  shouldReload: boolean;
}

interface SetCategoryAction {
  type: ActionType.SET_CATEGORY;
  category: string;
}

interface SetFilterFormDataAction {
  type: ActionType.SET_FILTER_FORM_DATA;
  formData: FilterType;
}

interface SetActiveTableAction {
  type: ActionType.SET_ACTIVE_TABLE;
  activeTable: string;
}

interface ResetPaginationAction {
  type: ActionType.RESET_PAGINATION;
}

export type Action =
  | PromiseActionType
  | GetProspectsAction
  | GetApplicationsAction
  | GetLeasesAction
  | AllSearchAction
  | GetFiltersAction
  | GetColumnsAction
  | CreateFilterAction
  | UpdateFilterAction
  | DeleteFilterAction
  | SearchObjectAction
  | SetContextMenuPosAction
  | SetPageAction
  | SetSizePerPageAction
  | SetSelectedAction
  | SetSortFieldAction
  | SetSortOrderAction
  | SetFilterAction
  | SetKeywordAction
  | SetReloadStateAction
  | SetCategoryAction
  | SetFilterFormDataAction
  | SaveColumnsAction
  | SetActiveTableAction
  | ResetPaginationAction
  | PusherAction;
