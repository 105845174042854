import moment from 'moment';
import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { DependentProps } from 'leasing/store/dependent/action-types';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { ActionType as ScreeningReportActionType } from 'leasing/store/screening_report/action-types';

export enum ActionType {
  CREATE_APPLICANT_REQUEST = 'CREATE_APPLICANT_REQUEST',
  CREATE_APPLICANT_SUCCESS = 'CREATE_APPLICANT_SUCCESS',
  CREATE_APPLICANT_FAILURE = 'CREATE_APPLICANT_FAILURE',

  DELETE_APPLICANT_REQUEST = 'DELETE_APPLICANT_REQUEST',
  DELETE_APPLICANT_SUCCESS = 'DELETE_APPLICANT_SUCCESS',
  DELETE_APPLICANT_FAILURE = 'DELETE_APPLICANT_FAILURE',

  GET_APPLICANT_BY_ID_REQUEST = 'GET_APPLICANT_BY_ID_REQUEST',
  GET_APPLICANT_BY_ID_SUCCESS = 'GET_APPLICANT_BY_ID_SUCCESS',
  GET_APPLICANT_BY_ID_FAILURE = 'GET_APPLICANT_BY_ID_FAILURE',

  GET_APPLICANTS_REQUEST = 'GET_APPLICANTS_REQUEST',
  GET_APPLICANTS_SUCCESS = 'GET_APPLICANTS_SUCCESS',
  GET_APPLICANTS_FAILURE = 'GET_APPLICANTS_FAILURE',

  GET_LOCATION_ZIP_CODE_REQUEST = 'GET_LOCATION_ZIP_CODE_REQUEST',
  GET_LOCATION_ZIP_CODE_SUCCESS = 'GET_LOCATION_ZIP_CODE_SUCCESS',
  GET_LOCATION_ZIP_CODE_FAILURE = 'GET_LOCATION_ZIP_CODE_FAILURE',

  GET_SCREENING_STATUS_REQUEST = 'GET_SCREENING_STATUS_REQUEST',
  GET_SCREENING_STATUS_SUCCESS = 'GET_SCREENING_STATUS_SUCCESS',
  GET_SCREENING_STATUS_FAILURE = 'GET_SCREENING_STATUS_FAILURE',

  RESEND_APPLICATION_INVITE_REQUEST = 'RESEND_APPLICATION_INVITE_REQUEST',
  RESEND_APPLICATION_INVITE_SUCCESS = 'RESEND_APPLICATION_INVITE_SUCCESS',
  RESEND_APPLICATION_INVITE_FAILURE = 'RESEND_APPLICATION_INVITE_FAILURE',

  RESET_APPLICANT = 'RESET_APPLICANT',

  UPDATE_APPLICANT_REQUEST = 'UPDATE_APPLICANT_REQUEST',
  UPDATE_APPLICANT_SUCCESS = 'UPDATE_APPLICANT_SUCCESS',
  UPDATE_APPLICANT_FAILURE = 'UPDATE_APPLICANT_FAILURE',
}

export interface ApplicantActivityProps {
  step: string,
  date: string,
}

export interface ApplicantProps {
  id?: number,
  activities?: ApplicantActivityProps[],
  applicant_checklist?: { [key: string]: string },
  birth_date?:string,
  city_name?: string,
  country?: string,
  current_residence_move_in_date?: string,
  driver_licence_number?: string,
  driver_licence_photo_back_preview?: string,
  driver_licence_photo_back?: string,
  driver_licence_photo_front_preview?: string,
  driver_licence_photo_front?: string,
  driver_licence_state?: string,
  email?: string,
  first_name?: string,
  first_street_address?: string,
  identification_country?: string,
  identification_id?: string,
  identification_type?: string,
  income_count?: number,
  income_not_confirmed?: boolean,
  income_type?: string,
  income_verified_agent?: { id: number, first_name: string, last_name: string },
  income_verified_date?: string | moment.Moment,
  income_verified?: boolean,
  is_application_complete?: boolean,
  is_currently_employed?: boolean,
  is_email_sent?: boolean,
  is_guarantor?: boolean,
  is_primary?: boolean,
  is_residence_owned?: boolean,
  isApplicant?: boolean,
  itin_identification_id?: string,
  landlord_name?: string,
  landlord_phone?: string,
  last_invite_date?: string,
  last_name?: string,
  middle_name?: string,
  monthly_payment?: number | string,
  occupants?: DependentProps[],
  permission?: PermissionProps,
  phone_number?: string,
  phone_type?: string,
  screening_started_time?: string,
  second_street_address?: string,
  setGuarantorsCount?: (count: number) => void,
  should_invite?: boolean,
  showAll?: boolean,
  ssn_identification_id?: string,
  state?: string,
  total_income?: number,
  type?: string
  updated_income_agent?: number,
  updated_income_date?: string | moment.Moment,
  visa_number?: string,
  zip_or_postal_code?: string,
  emergency_contact?: EmergencyContactProps,
  status?: string,
}

export interface OccupantProps {
  id?: number,
  email?: string,
  first_name?: string,
  last_name?: string,
  middle_name?: string,
}

export interface ApplicantState extends CommonStateType {
  applicant: ApplicantProps,
  applicants: ApplicantProps[],
  isApplicantsLoaded: boolean,
}

export interface PermissionProps {
  driver_licence_photos: boolean,
  first_street_address: boolean,
}

export interface EmergencyContactProps {
  first_name?: string,
  last_name?: string,
  email?: string,
  phone_number?: string,
  phone_type?: string,
  relationship?: string,
}

export interface EmergencyContactErrorProps {
  first_name?: string,
  last_name?: string,
  email?: string,
  phone_number?: string,
}

/*
 * Action types
 */

interface ListApplicantsResponse { data: { results: ApplicantProps[] } }

interface SingleApplicantResponse { data: ApplicantProps }

interface CreateApplicantAction extends CommonActionResponse {
  type: ActionType.CREATE_APPLICANT_REQUEST
  | ActionType.CREATE_APPLICANT_SUCCESS
  | ActionType.CREATE_APPLICANT_FAILURE;
  result: SingleApplicantResponse;
}

interface DeleteApplicantAction extends CommonActionResponse {
  type: ActionType.DELETE_APPLICANT_REQUEST
  | ActionType.DELETE_APPLICANT_SUCCESS
  | ActionType.DELETE_APPLICANT_FAILURE;
  result: SingleApplicantResponse;
}

interface GetApplicantByIdAction extends CommonActionResponse {
  type: ActionType.GET_APPLICANT_BY_ID_REQUEST
  | ActionType.GET_APPLICANT_BY_ID_SUCCESS
  | ActionType.GET_APPLICANT_BY_ID_FAILURE;
  result: SingleApplicantResponse;
}

interface GetApplicantsAction extends CommonActionResponse {
  type: ActionType.GET_APPLICANTS_REQUEST
  | ActionType.GET_APPLICANTS_SUCCESS
  | ActionType.GET_APPLICANTS_FAILURE;
  result: ListApplicantsResponse;
}

interface GetScreeningStatusAction {
  type: ActionType.GET_SCREENING_STATUS_SUCCESS;
  result: SingleApplicantResponse;
}

interface InitiateScreeningAction {
  type: ScreeningReportActionType.INITIATE_SCREENING_SUCCESS;
  result: { data: { applicant_id: number } };
}

interface ResetApplicantAction { type: ActionType.RESET_APPLICANT }

interface ResetLease { type: LeaseActionType.RESET_LEASE }

interface UpdateApplicantAction extends CommonActionResponse {
  type: ActionType.UPDATE_APPLICANT_REQUEST
  | ActionType.UPDATE_APPLICANT_SUCCESS
  | ActionType.UPDATE_APPLICANT_FAILURE;
  result: SingleApplicantResponse;
}

export type Action =
  PromiseActionType
  | CreateApplicantAction
  | DeleteApplicantAction
  | GetApplicantByIdAction
  | GetApplicantsAction
  | GetScreeningStatusAction
  | InitiateScreeningAction
  | ResetApplicantAction
  | ResetLease
  | UpdateApplicantAction;
