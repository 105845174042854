import React, { FC } from 'react';
import { BadgeDot } from './styles';

interface ColumnLabelProps {
  value: string,
}

const ColumnLabel: FC<ColumnLabelProps> = ({ value }) => (
  <div className="d-flex align-items-center">
    <BadgeDot className="bg-success" />
    <span className="ml-1">{value}</span>
  </div>
);

export default ColumnLabel;
