import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Mention } from 'react-mentions';
import moment from 'moment';
import he from 'he';
import actions from 'dwell/actions/index';
import { isEmpty } from 'lodash';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { SMS_LOAD_MORE_OFFSET, LAST_N_MESSAGES } from 'dwell/constants';
import { PropertyProps } from 'dwell/store/property/action-types';
import { Prospect as IProspect } from 'dwell/store/chat/action-types';
import { Avatar } from 'styles/common';
import MessageOptionsPanel from 'dwell/views/chat/common/_messageOptionsPanel';
import HobbesAssitPanel from 'dwell/views/chat/common/_hobbesAssistPanel';
import { AgentJoinedText } from 'dwell/views/chat/multi_chat/styles';
import { ChatSpinner, JoinButton, SpinnerBorder, JoinButtonWrapper } from 'dwell/views/chat/single_chat/contact/styles';
import 'src/scss/pages/_placeholders.scss';
import {
  ChatItem,
  ChatItemBody,
  ChatItemFooter,
  ChatItemMessage,
  MessageInput,
  TypingMessage,
  CustomMentionInput,
  ResponseTyping,
  MiniLoader,
  HiddenChatItem,
  TextAnalyzingContainer,
  MiniLoader3,
} from 'dwell/views/chat/single_chat/window/styles';
import { getAvatarColor, getInitials } from 'dwell/views/chat/common/utils';
import { GuestCardDataCapture, NameDataCapture, SchedulingDataCapture } from 'dwell/views/chat/common/dataCaptures';
import ProspectMessage from 'dwell/views/chat/common/_prospectMessage';

interface ActiveChatPanelProps {
  prospect: IProspect,
  minimized: boolean,
  isSingleChat: boolean,
}

const HEIGHT = 100;
const FONTSIZE = 12;

const defaultStyle = {
  '&multiLine': {
    control: {
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      fontWeight: 'normal',
      fontSize: FONTSIZE,
      border: 'none',
      overflow: 'auto',
      height: HEIGHT,
    },
    highlighter: {
      backgroundColor: 'transparent',
      fontWeight: 'normal',
      fontSize: FONTSIZE,
      border: 'none',
      overflow: 'auto',
      height: HEIGHT,
    },
    input: {
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      fontWeight: 'normal',
      fontSize: FONTSIZE,
      border: 'none',
      overflow: 'auto',
      height: HEIGHT,
    },
  },
};

const ProspectChatBody: FC<ActiveChatPanelProps> = ({ prospect, minimized, isSingleChat }) => {
  const chatConversations = useSelector(state => state.prospectChat.conversations);
  const isSendingText = useSelector(state => state.prospectChat.isSendingText);
  const currentUser = useSelector(state => state.user.currentUser);
  // const properties = useSelector(state => state.property.properties);
  const typingData = useSelector(state => state.prospectChat.typingData);
  const activeChats = useSelector(state => state.prospectChat.activeChats);

  const dispatch = useDispatch();
  // todo should revert sendTypingState
  const { getConversations, readAll, sendMessageToProspect, joinProspect, removeFromProspectsRequestedAgent, clearTyping, setDraftToActiveChat, requestToJoinConversation } = actions.prospectChat;
  const { updateUserAvailableStatus } = actions.user;

  const [newMessage, setNewMessage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [oldScrollPosition, setOldScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isJoinDisabled, setIsJoinDisabled] = useState(false);
  const [canPublish, setCanPublish] = useState(true);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [openHobbesPanel, setOpenHobbesPanel] = useState(false);
  const [answerToDisplay, setAnswerToDisplay] = useState<string[]>([]);
  const [isHobbesAnalyzing, setIsHobbesAnalyzing] = useState(false);
  const [hobbesTagAndUnitType, setHobbesTagAndUnitType] = useState({ tag: null, unitType: null });
  const [showSending, setShowSending] = useState(false);
  const [currentProperty, setCurrentProperty] = useState<PropertyProps>({ domain: '' });
  const [lastJoinedMessageIdx, setJoinedLastMessageIdx] = useState(null);
  const [draftRendered, setDraftRendered] = useState(false);
  const typingTimeoutId = useRef(null);
  const selectedProperties = useSelector(selectSelectedProperties);

  const chatWindow: { current: { _container: { scrollTop: number, scrollHeight: number, clientHeight: number } }} | undefined = useRef();

  useEffect(() => {
    if (!isEmpty(activeChats) && prospect.id && !newMessage && !draftRendered) {
      const currentActiveChat = activeChats.find(a => a.id === prospect.id);
      if (typeof currentActiveChat !== 'undefined') {
        if (currentActiveChat.draft) {
          setNewMessage(currentActiveChat.draft);
        }
        setDraftRendered(true);
      }
    }
  }, [activeChats, prospect.id, newMessage, draftRendered]);

  const prospectMessages = chatConversations
    .filter(cc => cc.prospect === prospect.id && cc.type === 'PROSPECT')
    .sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()));

  useEffect(() => {
    if (!isEmpty(prospectMessages)) {
      const lastNElements = prospectMessages.length - LAST_N_MESSAGES;
      if (lastNElements < 0) {
        setJoinedLastMessageIdx(prospectMessages[0].id);
      } else {
        setJoinedLastMessageIdx(prospectMessages[lastNElements].id);
      }
    }
  }, [prospectMessages]);

  useEffect(() => {
    if (!isEmpty(selectedProperties)) {
      const tempProperty = selectedProperties.find(p => p.id === prospect.property);
      if (!isEmpty(tempProperty)) {
        setCurrentProperty(tempProperty);
      }
    }
  }, [selectedProperties]);

  const scrollToLastMessage = () => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      _container.scrollTop = _container.scrollHeight;
    }
  };

  useEffect(() => {
    if (typingTimeoutId.current) {
      clearTimeout(typingTimeoutId.current);
    }
    typingTimeoutId.current = setTimeout(() => {
      if (typingData.isTyping) {
        dispatch(clearTyping());
      }
    }, 1700);
  }, [typingData]);

  const getDataCaptureElement = (type) => {
    switch (type) {
      case 'Name': return NameDataCapture;
      case 'Guest card': return GuestCardDataCapture;
      case 'Schedule a Tour': return SchedulingDataCapture;
      default: return NameDataCapture;
    }
  };

  useEffect(() => {
    const conversations = chatConversations.filter(message => message.prospect === prospect.id);
    if (prospect.id) {
      if ((!minimized || !isSingleChat) && !totalCount) {
        // for the first loading
        setIsLoading(true);
        dispatch(getConversations({
          prospect: prospect.id,
          params: {
            offset: conversations.length,
            limit: SMS_LOAD_MORE_OFFSET,
            property: prospect.property,
          } })).then((response) => {
          if (response?.result) {
            const { result: { data: { count } } } = response;
            setTotalCount(count);
          }
          if (prospect && prospect.unread_count) {
            dispatch(readAll(prospect.id));
          }
          setIsLoading(false);
        });
      } else if (prospect.unread_count) {
        // for the unread message
        dispatch(readAll(prospect.id));
      }
      scrollToLastMessage();
    }
  }, [prospect.unread_count]);

  const onscroll = ({ target }) => {
    const conversations = chatConversations.filter(message => message.prospect === prospect.id);
    if (totalCount === conversations.length) return;
    if (target && target.scrollTop === 0 && !isLoading && totalCount) {
      setIsLoading(true);
      setOldScrollPosition(target.scrollHeight - target.clientHeight);
      setTimeout(() => {
        if (prospect.id) {
          dispatch(getConversations({ prospect: prospect.id, params: { offset: conversations.length, limit: SMS_LOAD_MORE_OFFSET, property: prospect.property } }))
            .then((response) => {
              if (response?.result) {
                const { result: { data: { count } } } = response;
                setTotalCount(count);
              }
              setIsLoading(false);
            });
        }
      }, 1000);
    }
  };

  const sendMessage = (message, type = messageType || 'AGENT') => {
    // Message pre-processing
    const newLine = /(\r\n|\r|\n)/ig;
    const emptySpaces = /[ ](?=[^>]*?(?:<|$))/gi;
    // eslint-disable-next-line no-useless-escape
    const imageDomain = /\S*imgix\S*/gi;
    const httpStart = /(ht|f)tps?:\/\/[^"]*?(?=<|\s|$)/ig;
    let formatedMessage = message;
    const imageLinks = [...formatedMessage.matchAll(imageDomain)]
      .reduce((a, b) => a.concat(b[0]), []);
    const uniqImageLinks = [...new Set(imageLinks)];
    const urls = [...formatedMessage.matchAll(httpStart)]
      .reduce((a, b) => a.concat(b[0]), [])
      .filter(item => !uniqImageLinks.includes(item));
    const uniqUrls = [...new Set(urls)];
    uniqUrls.sort((a: string, b: string) => b.length - a.length);
    const domain = uniqUrls.pop();
    uniqImageLinks.forEach((w) => {
      formatedMessage = he.decode(formatedMessage.replaceAll(w, `<div style="text-align: center;"><img style="width: 100px; height: auto;" src="${w}" alt="Property logo"/></div>`));
    });
    uniqUrls.forEach((url) => {
      formatedMessage = he.decode(formatedMessage.replaceAll(url, `<a href="${url}" target="_blank">${url}</a>`));
    });
    if (domain) {
      const domainReg = new RegExp(`${domain}\\s`, 'gi');
      const domainUrls = [...formatedMessage.matchAll(domainReg)]
        .reduce((a, b) => a.concat(b[0]), []);
      const uniqDomainUrls = [...new Set(domainUrls)];
      uniqDomainUrls.forEach((d: string) => {
        formatedMessage = he.decode(formatedMessage.replace(d, `<a href="${domain}" target="_blank">${domain}</a>${d.slice(-1)[0]}`));
      });
    }
    if (type !== 'DATA_CAPTURE') {
      formatedMessage = he.decode(formatedMessage.replaceAll(emptySpaces, ' '));
    }
    formatedMessage = he.decode(formatedMessage.replaceAll(newLine, '<br />'));
    if (message && !isSendDisabled && !isSendingText) {
      setShowSending(true);
      setNewMessage('');
      dispatch(setDraftToActiveChat('', prospect.id));
      dispatch(sendMessageToProspect({
        prospect: prospect.id,
        type,
        is_read: true,
        agent: currentUser.id,
        message: formatedMessage,
        property: prospect.property,
        property_external_id: prospect.property_external_id,
      }, prospect.property)).then(() => {
        setShowSending(false);
        setNewMessage('');
        dispatch(setDraftToActiveChat('', prospect.id));
        setMessageType(null);
        scrollToLastMessage();
      });
    }
  };

  useEffect(() => {
    if (chatWindow.current) {
      const { _container } = chatWindow.current;
      const newScroll = _container.scrollHeight - _container.clientHeight;
      _container.scrollTop += (newScroll - oldScrollPosition);
    }
  }, [chatConversations, totalCount, showSending]);

  const onJoin = () => {
    setIsJoinDisabled(true);
    dispatch(updateUserAvailableStatus(currentUser.id, { is_available: true }))
      .then(() => dispatch(requestToJoinConversation(prospect.id, currentUser.id, prospect.property))
        .then(({ result: { data: { success } } }) => {
          if (success) {
            dispatch(joinProspect({ prospect: prospect.id, body: { type: 'JOINED', agent: currentUser.id } }, prospect.property))
              .then(() => {
                scrollToLastMessage();
                dispatch(readAll(prospect.id));
                dispatch(removeFromProspectsRequestedAgent(prospect.id));
                setIsJoinDisabled(false);
              });
          }
        }));
  };

  const replaceSubjectEmptyVariables = () => {
    const placeholders = document.getElementsByClassName('subject-variable');
    if (!isEmpty(placeholders)) {
      [...placeholders].forEach((el) => {
        el.classList.add('subject-variable-empty');
        el.classList.remove('subject-variable');
      });
    }
    setIsSendDisabled(!isEmpty([...document.getElementsByClassName('subject-variable-empty')]));
  };

  useEffect(() => {
    setTimeout(() => replaceSubjectEmptyVariables(), 100);
  }, [newMessage]);

  const handleKeyUp = (e) => {
    replaceSubjectEmptyVariables();
    if (((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) && !isEmpty(newMessage) && newMessage !== '\n') {
      if (!isSendingText && !isSendDisabled) {
        sendMessage(newMessage);
      }
    } else if (((e.key === 'Enter' || e.keyCode === 13) && e.shiftKey)) {
      dispatch(setDraftToActiveChat(newMessage.concat('\n'), prospect.id));
      setNewMessage(newMessage.concat('\n'));
      setMessageType(null);
    } else if (canPublish) {
      // todo should optimize it
      // sendTypingState(prospect.id, { is_typing: true, type: 'AGENT' });
      setCanPublish(false);
      setTimeout(() => {
        setCanPublish(true);
      }, 200);
    }
  };

  const handleKeyDown = (e) => {
    replaceSubjectEmptyVariables();
    if ((e.key === 'Enter' || e.keyCode === 13) && !isEmpty(newMessage)) {
      e.preventDefault();
    }
  };

  const handleChange = (value) => {
    setNewMessage(value);
    dispatch(setDraftToActiveChat(value, prospect.id));
    setMessageType(null);
  };

  const isResponsible = prospect.active_agent === currentUser.id;
  const isProspectNAgentOnline = prospect.is_online && currentUser.is_available;
  const inActiveSession = isResponsible && isProspectNAgentOnline;
  const shouldJoinInSession = !(prospect.joined_agents || new Array<number>()).includes(currentUser.id) && prospect.is_online
      && !prospect.active_agent;
  const shouldRejoinInSession = (prospect.joined_agents || new Array<number>()).includes(currentUser.id) && prospect.is_online
      && (!prospect.active_agent || (isResponsible && !currentUser.is_available));
  const isNotMyChat = prospect.active_agent && !isResponsible;

  return (
    <>
      <ChatItemBody ref={chatWindow} onScroll={onscroll} isSingleChat={isSingleChat}>
        <ul>
          {isLoading && (
            <ChatSpinner>
              <SpinnerBorder>
                <span className="sr-only">Loading ...</span>
              </SpinnerBorder>
            </ChatSpinner>
          )}
          {chatConversations.filter(message => message.prospect === prospect.id && !['AGENT_REQUEST'].includes(message.type))
            .sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()))
            .map((message, index, array) => {
              if (message.type === 'PROSPECT') {
                return (
                  <ProspectMessage
                    message={message}
                    index={index}
                    prospect={prospect}
                    currentProperty={currentProperty}
                    lastJoinedMessageIdx={lastJoinedMessageIdx}
                    setOpenHobbesPanel={setOpenHobbesPanel}
                    setAnswerToDisplay={setAnswerToDisplay}
                    setIsHobbesAnalyzing={setIsHobbesAnalyzing}
                    setHobbesTagAndUnitType={setHobbesTagAndUnitType}
                    inActiveSession={inActiveSession}
                  />
                );
              } else if (message.type === 'JOINED') {
                return (
                  <ChatItem agentJoined key={`joined-chat-item-${index}`}>
                    <AgentJoinedText key={`joined-chat-msg-${index}`}>
                      {message.agent_name} has entered the chat.
                      <small>&nbsp;&nbsp;({moment(message.date).format('MMM DD, hh:mma')})</small>
                    </AgentJoinedText>
                  </ChatItem>);
              } else if (message.type === 'DATA_CAPTURE' && index === array.length - 1) {
                return (
                  <ChatItem reverse key={`dc-chat-item-${index}`}>
                    <Avatar className="avatar bg-dark">
                      <i>{getInitials(message.agent_name)}</i>
                    </Avatar>
                    <ChatItemMessage key={`dc-chat-msg-${index}`} className="message" agent>
                      <p dangerouslySetInnerHTML={{ __html: getDataCaptureElement(message.message) }} />
                      <small>{moment(message.date).format('MMM DD, hh:mma')}</small>
                    </ChatItemMessage>
                  </ChatItem>);
              } else if (message.type !== 'DATA_CAPTURE') {
                const avatarColor = `avatar ${['AGENT', 'BOT', 'GREETING', 'TEMPLATE'].includes(message.type) ? 'bg-dark' : getAvatarColor(prospect.name, prospect.id)}`;
                const name = ['AGENT', 'TEMPLATE'].includes(message.type) ? getInitials(message.agent_name) : 'H';
                let avatar = (
                  <Avatar className={avatarColor}>
                    <i>{name}</i>
                  </Avatar>
                );
                if (['AGENT', 'TEMPLATE'].includes(message.type) && message.agent_avatar) {
                  avatar = (
                    <Avatar className={avatarColor}>
                      <img src={message.agent_avatar} alt="avatar" />
                    </Avatar>
                  );
                }
                if (['GREETING', 'BOT'].includes(message.type)) {
                  avatar = (
                    <Avatar className={avatarColor}>
                      <img
                        src="https://img.icons8.com/material-outlined/36/000000/bot.png"
                        alt="bot-icon"
                        className="bot"
                        style={{ filter: 'brightness(100%) invert(100%)' }}
                      />
                    </Avatar>
                  );
                }
                return (
                  <ChatItem reverse={['AGENT', 'BOT', 'GREETING', 'TEMPLATE'].includes(message.type)} key={`fallback-chat-item-${index}`}>
                    {avatar}
                    <ChatItemMessage key={`fallback-chat-msg-${index}`} className="message">
                      <p dangerouslySetInnerHTML={{ __html: message.type === 'GREETING'
                        ? message.message.replace(/<\/?h[0-9]>/g, ' ')
                          .replace(/<\/?p>/g, '')
                        : message.message }}
                      />
                      <small>{moment(message.date).format('MMM DD, hh:mma')}</small>
                    </ChatItemMessage>
                  </ChatItem>);
              }
              return <></>;
            })}
          <HiddenChatItem reverse show={showSending}>
            <Avatar className="avatar bg-dark">
              <i>{getInitials(currentUser.first_name)}</i>
            </Avatar>
            <ResponseTyping>
              <MiniLoader />
            </ResponseTyping>
          </HiddenChatItem>
        </ul>
      </ChatItemBody>
      {isHobbesAnalyzing && (
        <TextAnalyzingContainer>
          <MiniLoader3 />
          <span>Analyzing text</span>
          <MiniLoader3 />
        </TextAnalyzingContainer>)}
      <ChatItemFooter isActive={inActiveSession}>
        {inActiveSession && (
          <>
            {typingData.isTyping && prospect.id === typingData.prospect &&
            <TypingMessage>
              {`${prospect.name} is typing...`}
            </TypingMessage>}
            <MessageInput className="subject">
              <CustomMentionInput
                placeholder="Write a chat message"
                value={newMessage}
                style={defaultStyle}
                onClick={() => replaceSubjectEmptyVariables()}
                onChange={({ target: { value } }) => handleChange(value)}
                onBlur={() => setTimeout(() => replaceSubjectEmptyVariables())}
                onKeyUp={e => handleKeyUp(e)}
                onKeyDown={e => handleKeyDown(e)}
              >
                <Mention
                  appendSpaceOnAdd
                  className="subject-variable"
                  trigger="["
                  markup="[=__display__=]"
                  displayTransform={(id, display) => `[=${display}=]`}
                  data={(matchInfo, callback) => callback([])}
                  renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (<div className={`${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>)}
                />
              </CustomMentionInput>
              <div className="d-flex align-items-center justify-content-end">
                <MessageOptionsPanel
                  sendMessage={(message, type) => sendMessage(message, type)}
                  setNewMessage={message => setNewMessage(message)}
                  setMessageType={setMessageType}
                  prospect={prospect}
                  isSMS={false}
                  isSingleChat
                />
                <span
                  className="send"
                  onClick={() => sendMessage(newMessage)}
                >
                  <i className="ri-send-plane-fill" />
                </span>
              </div>
            </MessageInput>
          </>)}
        {shouldJoinInSession &&
        <JoinButtonWrapper>
          <JoinButton
            id="join-button"
            onClick={() => onJoin()}
            disabled={isJoinDisabled || isNotMyChat}
          >Join
          </JoinButton>
        </JoinButtonWrapper>}
        {shouldRejoinInSession &&
          <JoinButtonWrapper>
            <JoinButton
              id="rejoin-button"
              onClick={() => onJoin()}
              disabled={isJoinDisabled || isNotMyChat}
            >Rejoin
            </JoinButton>
          </JoinButtonWrapper>
        }

      </ChatItemFooter>
      <HobbesAssitPanel messages={answerToDisplay} openHobbesPanel={openHobbesPanel} setOpenHobbesPanel={setOpenHobbesPanel} sendMessage={(message, type) => sendMessage(message, type)} hobbesTagAndUnitType={hobbesTagAndUnitType} />
    </>);
};

export default ProspectChatBody;
