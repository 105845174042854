import React, { FC, Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import actions from 'dwell/actions';
import { selectSelectedProperties, selectSelectedProperty, selectSelectedPropertyXname, selectIsTheFirstPropertyCBPlatform } from 'dwell/store/property/reducers';
import { selectCurrentUser, selectUserRole } from 'dwell/store/user/reducers';
import { selectPropertiesCount } from 'dwell/store/properties_selector/reducers';
import { selectCustomers } from 'site/store/customer/reducers';
import { getMode, getPropertyId, checkIfAllPropertiesAreInactive } from 'src/utils';
import { paths } from 'dwell/constants';
import { PropertyProps } from 'dwell/store/property/action-types';
import { CustomerProps } from 'site/store/customer/action-types';
import DefaultHeaderDropdown from 'containers/default_layout/DefaultHeaderDropdown';
import Notifications from 'dwell/components/notifications';
import RecentMessages from 'dwell/components/messages';
import { platformTypes, roleTypes } from 'site/constants';
import PropertyLogo from './_property_logo';
import Nav from './_nav';
import PropertyGroup from './_property_group';

import { Header, HeaderBody, NavUlspace, PropertyProfileToggle } from './styles';

interface DefaultHeaderProps {
  isViewSideBar: boolean;
}

const DefaultHeader: FC<DefaultHeaderProps> = ({ isViewSideBar }) => {
  const [showPropertyGroup, setShowPropertyGroup] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const { setSelectedProperty, setSelectedPropertyXname } = actions.property;
  const { openProfile } = actions.propertyProfile;
  const currentUser = useSelector(selectCurrentUser);
  const selectedProperties: PropertyProps[] = useSelector(selectSelectedProperties);
  const selectedProperty = useSelector(selectSelectedProperty);
  const selectedPropertyXname = useSelector(selectSelectedPropertyXname);
  const propertiesCount = useSelector(selectPropertiesCount);
  const profileIsOpened = useSelector(state => state.propertyProfile.isOpen);
  const isGalleryPreviewOpen = useSelector(state => state.propertyProfile.isGalleryPreviewOpen);
  const userRole = useSelector(selectUserRole);
  const customers: CustomerProps[] = useSelector(selectCustomers);
  const isTheFirstPropertyCBPlatform = useSelector(selectIsTheFirstPropertyCBPlatform);

  const isCallScorer = currentUser.is_call_scorer;
  const isChatReviewer = currentUser.is_chat_reviewer;
  const adminCallsAndChats = isCallScorer || isChatReviewer;
  const adminIsCallsNChats = isChatReviewer && isCallScorer;

  const arePropertiesInactive = useMemo(() => checkIfAllPropertiesAreInactive(selectedProperties), [selectedProperties]);
  const propertiesPlatforms = selectedProperties.map(p => p.platforms);
  const areAllPlatformsEqual = propertiesPlatforms.every((val, i, arr) => isEqual(val, arr[0]));
  const isPropertyPlatformNotSiteOnly = !platformTypes.isSiteOnly(propertiesPlatforms[0]);
  const areAllPropertiesPlatformsNotSiteOnly = areAllPlatformsEqual && isPropertyPlatformNotSiteOnly;
  const areAllAllowedProperties = selectedProperties.length > 1 && (!areAllPlatformsEqual || areAllPropertiesPlatformsNotSiteOnly) && !arePropertiesInactive;

  const isAgentChatEnabled = selectedProperties.some(item => item.agent_chat_enabled);

  const isSinglePropertyNotSiteOnly = selectedProperties.length === 1 && platformTypes.hasPlatforms(selectedProperties[0].platforms) && !platformTypes.isSiteOnly(selectedProperties[0].platforms) && selectedProperties[0].status !== 'INACTIVE';

  useEffect(() => {
    if (location.pathname.includes('/chats/evaluate/') || location.pathname.includes('/chats/report/')) {
      setShowPropertyGroup(false);
    } else {
      setShowPropertyGroup(true);
    }
    if (!isEmpty(selectedProperty)) {
      const manageURLS = [paths.client.MANAGE_PROPERTIES, paths.client.MANAGE_USERS, paths.client.MANAGE_CUSTOMERS, paths.client.MANAGE_CLIENTS];
      const isManageURL = manageURLS.some(str => location.pathname.includes(str));
      if (getPropertyId() === 'properties' || isManageURL || (!location.pathname.includes('/leads/') && !location.pathname.includes('/leases/') && !location.pathname.includes('/applications/'))) {
        dispatch(setSelectedProperty({}));
      }
    }
  }, [location.pathname, selectedProperty]);

  useEffect(() => {
    if (!isEmpty(selectedPropertyXname)) {
      const propertyId = getPropertyId();
      const isMultiPropertyPage = propertyId === 'properties' && !location.pathname.includes('/settings/');
      const isPropertyNotMatched = propertyId !== 'properties' && selectedPropertyXname.external_id !== propertyId;
      if (isMultiPropertyPage || isPropertyNotMatched) {
        dispatch(setSelectedPropertyXname({}));
      }
    }
  }, [location.pathname]);

  const getCustomerMode = useCallback(() => {
    const propertiesWCustomer = selectedProperties.filter(p => p.customer !== null);
    const customerArr = propertiesWCustomer.map(p => p.customer);
    const customerId = getMode(customerArr);
    if (customerId) {
      return customers.find(c => c.id === customerId);
    }
    return {} as CustomerProps;
  }, [selectedProperties, customers]);

  return (
    <Header isViewSideBar={isViewSideBar} profileIsOpened={profileIsOpened} isGalleryPreviewOpen={isGalleryPreviewOpen}>
      <HeaderBody>
        {userRole === roleTypes.LIFT_LYTICS_ADMIN && isEmpty(selectedProperty) && selectedProperties.length > 1 && selectedProperties.length >= propertiesCount && <PropertyLogo customer={getCustomerMode()} />}
        {showPropertyGroup && !isCallScorer && <PropertyGroup selectedProperties={selectedProperties} propertiesCount={propertiesCount} />}
        {(!isChatReviewer || adminIsCallsNChats) && (areAllAllowedProperties || isSinglePropertyNotSiteOnly) ? (
          <Fragment>
            <Nav selectedProperties={selectedProperties} />
            {!adminCallsAndChats && !isTheFirstPropertyCBPlatform && (
              <PropertyProfileToggle onClick={() => dispatch(openProfile(!profileIsOpened))} id="profile_toggle">
                <span>
                  <i className="ri-layout-left-line" />
                </span>
              </PropertyProfileToggle>
            )}
            {!adminCallsAndChats && isAgentChatEnabled && !isTheFirstPropertyCBPlatform && !arePropertiesInactive && <RecentMessages />}
            {!adminCallsAndChats && !isTheFirstPropertyCBPlatform && !arePropertiesInactive && <Notifications />}
          </Fragment>
        ) : (
          <NavUlspace />
        )}
        <DefaultHeaderDropdown />
      </HeaderBody>
    </Header>
  );
};

export default DefaultHeader;
