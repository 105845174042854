import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_EXPLORE_MARKETS_REQUEST = 'GET_EXPLORE_MARKETS_REQUEST',
  GET_EXPLORE_MARKETS_SUCCESS = 'GET_EXPLORE_MARKETS_SUCCESS',
  GET_EXPLORE_MARKETS_FAILURE = 'GET_EXPLORE_MARKETS_FAILURE',
}

export interface ExploreMarket {
  id: number;
  name: string;
  is_stored: boolean;
}

export interface ExploreMarketsList {
  markets?: ExploreMarket[];
  submarkets?: ExploreMarket[];
  properties?: ExploreMarket[];
  comparisons?: ExploreMarket[];
}

interface GetExploreMarketsListAction {
  type?: ActionType.GET_EXPLORE_MARKETS_REQUEST | ActionType.GET_EXPLORE_MARKETS_SUCCESS | ActionType.GET_EXPLORE_MARKETS_FAILURE;
  result?: {
    data: ExploreMarketsList;
  };
  error?: {
    response: { status: string };
  };
}

export type Action = PromiseActionType & GetExploreMarketsListAction;
