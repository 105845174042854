import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action } from './action-types';

interface ActionLeadFilters {
  type: string;
  filter: string;
}

interface FilterData {
  name: string;
  filter_items: { compare_field: string; compare_operator: string; compare_value: string[] }[];
  filter_type: string;
  focused: boolean;
  id: number;
}

export default {
  getLeadsFilter: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEADS_FILTER_REQUEST,
        ActionType.GET_LEADS_FILTER_SUCCESS,
        ActionType.GET_LEADS_FILTER_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.LEADS_FILTER),
    },
  }),
  createLeadsFilter: (leadsFilter: FilterData): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_LEADS_FILTER_REQUEST,
        ActionType.CREATE_LEADS_FILTER_SUCCESS,
        ActionType.CREATE_LEADS_FILTER_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.LEADS_FILTER, leadsFilter),
    },
  }),
  getLeadsFilterById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEADS_FILTER_BY_ID_REQUEST,
        ActionType.GET_LEADS_FILTER_BY_ID_SUCCESS,
        ActionType.GET_LEADS_FILTER_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.LEADS_FILTER_DETAILS, id)),
    },
  }),
  updateLeadsFilter: (id: number, params: FilterData): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_LEADS_FILTER_REQUEST,
        ActionType.UPDATE_LEADS_FILTER_SUCCESS,
        ActionType.UPDATE_LEADS_FILTER_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.LEADS_FILTER_DETAILS, id), params),
    },
  }),
  deleteLeadsFilter: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_LEADS_FILTER_REQUEST,
        ActionType.DELETE_LEADS_FILTER_SUCCESS,
        ActionType.DELETE_LEADS_FILTER_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.LEADS_FILTER_DETAILS, id)),
    },
  }),
  getActiveFilter: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ACTIVE_FILTER_REQUEST,
        ActionType.GET_ACTIVE_FILTER_SUCCESS,
        ActionType.GET_ACTIVE_FILTER_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.GET_ACTIVE_FILTER),
    },
  }),
  setActiveFilter: (activeFilter: string): Action => ({
    CALL_API: {
      types: [
        ActionType.SET_ACTIVE_FILTER_REQUEST,
        ActionType.SET_ACTIVE_FILTER_SUCCESS,
        ActionType.SET_ACTIVE_FILTER_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.SET_ACTIVE_FILTER, activeFilter),
    },
  }),
  setActiveFilterLocally: (filter: string): ActionLeadFilters => ({
    type: ActionType.SET_ACTIVE_FILTER,
    filter,
  }),
};
