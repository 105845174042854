import { PromiseActionType } from 'dwell/store/types';
import { CommunityDetails } from 'dwell/store/community_details/action-types';
import { ExploreMarket } from '../explore_markets/action-types';
import { UnitType } from '../market/action-types';

export enum ActionType {
  GET_AVAILABLE_UNITS_REQUEST = 'GET_AVAILABLE_UNITS_REQUEST',
  GET_AVAILABLE_UNITS_SUCCESS = 'GET_AVAILABLE_UNITS_SUCCESS',
  GET_AVAILABLE_UNITS_FAILURE = 'GET_AVAILABLE_UNITS_FAILURE',
  GET_PROPERTIES_REQUEST = 'GET_PROPERTIES_REQUEST',
  GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS',
  GET_PROPERTIES_FAILURE = 'GET_PROPERTIES_FAILURE',
  GET_PROPERTIES_DETAIL_REQUEST = 'GET_PROPERTIES_DETAIL_REQUEST',
  GET_PROPERTIES_DETAIL_SUCCESS = 'GET_PROPERTIES_DETAIL_SUCCESS',
  GET_PROPERTIES_DETAIL_FAILURE = 'GET_PROPERTIES_DETAIL_FAILURE',
  GET_PROPERTIES_COMPETITORS_REQUEST = 'GET_PROPERTIES_COMPETITORS_REQUEST',
  GET_PROPERTIES_COMPETITORS_SUCCESS = 'GET_PROPERTIES_COMPETITORS_SUCCESS',
  GET_PROPERTIES_COMPETITORS_FAILURE = 'GET_PROPERTIES_COMPETITORS_FAILURE',
  ADD_MARKET_ENVIRONMENT_REQUEST = 'ADD_MARKET_ENVIRONMENT_REQUEST',
  ADD_MARKET_ENVIRONMENT_SUCCESS = 'ADD_MARKET_ENVIRONMENT_SUCCESS',
  ADD_MARKET_ENVIRONMENT_FAILURE = 'ADD_MARKET_ENVIRONMENT_FAILURE',
  GET_COMPETITOR_SET_REQUEST = 'GET_COMPETITOR_SET_REQUEST',
  GET_COMPETITOR_SET_SUCCESS = 'GET_COMPETITOR_SET_SUCCESS',
  GET_COMPETITOR_SET_FAILURE = 'GET_COMPETITOR_SET_FAILURE',
  GET_PROPERTIES_ALERT_SUBSCRIPTIONS_REQUEST = 'GET_PROPERTIES_ALERT_SUBSCRIPTIONS_REQUEST',
  GET_PROPERTIES_ALERT_SUBSCRIPTIONS_SUCCESS = 'GET_PROPERTIES_ALERT_SUBSCRIPTIONS_SUCCESS',
  GET_PROPERTIES_ALERT_SUBSCRIPTIONS_FAILURE = 'GET_PROPERTIES_ALERT_SUBSCRIPTIONS_FAILURE',
  GET_SESSION_DETAIL_BY_ID_REQUEST = 'GET_SESSION_DETAIL_BY_ID_REQUEST',
  GET_SESSION_DETAIL_BY_ID_SUCCESS = 'GET_SESSION_DETAIL_BY_ID_SUCCESS',
  GET_SESSION_DETAIL_BY_ID_FAILURE = 'GET_SESSION_DETAIL_BY_ID_FAILURE',
  SET_AVAILABLE_LOADED = 'SET_AVAILABLE_LOADED',
  SET_COMPETITORS_LOADED = 'SET_COMPETITORS_LOADED',
}

export interface MarketEnvironment {
  market: number,
  submarket: number,
  competitors: number[],
}

export interface AvailableUnit {
  id?: number,
  number?: number,
  unit_type?: string,
  floor_plan_name?: string,
  beds?: number,
  baths?: number,
  unit_size?: number,
  rent?: number,
  avg_rent_per_sqft?: number,
  available_date?: number,
}

export interface PropertyDetail {
  id: number,
  name?: string,
  unit_types?: UnitType[],
  concession_avg_rent_percent?: number,
  submarket?: string,
  market?: string,
  address?: string,
  phone_number?: string,
  website?: string,
  units_count?: number,
  concession_description?: string,
  concession_amount?: number,
  amenities?: string[],
  communities?: string[],
  average_rent?: number,
  average_rent_per_sqft?: number,
  occupancy?: number,
  competitors?: number[],
  is_lease_up?: boolean,
  community_details: CommunityDetails,
}

export interface PropertiesCompetitor {
  id?: number,
  name?: string,
  submarket?: string,
  units_count?: number,
  min_unit_size?: number,
  min_rent?: number,
  occupancy?: number,
  rent?: number,
}

export interface AlertLog {
  id?: number,
  sent_on?: string,
  start?: string,
  end?: string,
}

export interface PropertyAlert {
  id: number,
  name?: string,
  logs?: AlertLog[],
  alertId?: number,
  sent_on?: string,
}

export interface Properties {
  id?: number,
  name?: string,
}

interface GetAvailableUnitsAction {
  type: ActionType.GET_AVAILABLE_UNITS_REQUEST | ActionType.GET_AVAILABLE_UNITS_SUCCESS | ActionType.GET_AVAILABLE_UNITS_FAILURE;
  result: {
    data: { results: AvailableUnit; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertiesAction {
  type: ActionType.GET_PROPERTIES_REQUEST | ActionType.GET_PROPERTIES_SUCCESS | ActionType.GET_PROPERTIES_FAILURE;
  result: {
    data: { results: Properties[] };
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertiesDetailAction {
  type: ActionType.GET_PROPERTIES_DETAIL_REQUEST | ActionType.GET_PROPERTIES_DETAIL_SUCCESS | ActionType.GET_PROPERTIES_DETAIL_FAILURE;
  result: {
    data: PropertyDetail;
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertiesCompetitorsAction {
  type: ActionType.GET_PROPERTIES_COMPETITORS_REQUEST | ActionType.GET_PROPERTIES_COMPETITORS_SUCCESS | ActionType.GET_PROPERTIES_COMPETITORS_FAILURE;
  result: {
    data: { results: PropertiesCompetitor; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface SaveMarketEnvironmentAction {
  type: ActionType.ADD_MARKET_ENVIRONMENT_REQUEST | ActionType.ADD_MARKET_ENVIRONMENT_SUCCESS | ActionType.ADD_MARKET_ENVIRONMENT_FAILURE;
  error: {
    response: { status: string };
  };
}

interface GetCompetitorSetAction {
  type: ActionType.GET_COMPETITOR_SET_REQUEST | ActionType.GET_COMPETITOR_SET_SUCCESS | ActionType.GET_COMPETITOR_SET_FAILURE;
  result: {
    data: { results: ExploreMarket };
  };
  error: {
    response: { status: string };
  };
}

interface GetAlertSubscriptionsAction {
  type: ActionType.GET_PROPERTIES_ALERT_SUBSCRIPTIONS_REQUEST | ActionType.GET_PROPERTIES_ALERT_SUBSCRIPTIONS_SUCCESS | ActionType.GET_PROPERTIES_ALERT_SUBSCRIPTIONS_FAILURE;
  result: {
    data: PropertyAlert;
  };
  error: {
    response: { status: string };
  };
}

interface GetSessionDetailByIdAction {
  type: ActionType.GET_SESSION_DETAIL_BY_ID_REQUEST | ActionType.GET_SESSION_DETAIL_BY_ID_SUCCESS | ActionType.GET_SESSION_DETAIL_BY_ID_FAILURE;
  result: {
    data: AvailableUnit;
  };
  error: {
    response: { status: string };
  };
}

interface SetAvailableLoadedAction {
  type: ActionType.SET_AVAILABLE_LOADED;
}

interface SetCompetitorsLoadedAction {
  type: ActionType.SET_COMPETITORS_LOADED;
}

export type Action =
  | PromiseActionType
  | GetAvailableUnitsAction
  | GetPropertiesAction
  | GetPropertiesDetailAction
  | GetPropertiesCompetitorsAction
  | SaveMarketEnvironmentAction
  | GetCompetitorSetAction
  | GetAlertSubscriptionsAction
  | GetSessionDetailByIdAction
  | SetAvailableLoadedAction
  | SetCompetitorsLoadedAction;
