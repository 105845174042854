import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { AddMasterLeaseDocumentsAction } from 'leasing/store/addenda/action-types';
import { ResetLeaseAction } from 'leasing/store/lease/action-types';

export enum ActionType {
  CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST',
  CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE',

  GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST',
  GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE',

  EXPAND_FRAME = 'EXPAND_FRAME',

  GET_LEASE_DOCUMENTS_REQUEST = 'GET_LEASE_DOCUMENTS_REQUEST',
  GET_LEASE_DOCUMENTS_SUCCESS = 'GET_LEASE_DOCUMENTS_SUCCESS',
  GET_LEASE_DOCUMENTS_FAILURE = 'GET_LEASE_DOCUMENTS_FAILURE',

  SET_IS_DOCUMENT_POPULATED = 'SET_IS_DOCUMENT_POPULATED',
}

export interface LeaseDocumentState extends CommonStateType {
  isDocumentPopulated: boolean,
  isExpanded: boolean,
  leaseDocuments: LeaseDocumentProps[],
  leaseDocumentsLoaded: boolean,
}

export interface LeaseDocumentProps {
  id: number,
  native_html: string,
  type: string,
  name: string,
  status: string,
  lease_fields: LeaseFieldChoiceProps[],
  included_in_all: string[],
  category: string[],
}

export interface LeaseFieldChoiceProps {
  id: number,
  name: string,
  value: number,
  is_dynamic: boolean,
  field_label: string,
  created: string,
  updated: string,
  document: number,
}

/*
 * Actions
 */

interface ExpandFrameAction {
  type: ActionType.EXPAND_FRAME;
  value: boolean;
}

interface GetLeaseDocumentsAction extends CommonActionResponse {
  type: ActionType.GET_LEASE_DOCUMENTS_REQUEST
  | ActionType.GET_LEASE_DOCUMENTS_SUCCESS
  | ActionType.GET_LEASE_DOCUMENTS_FAILURE;
  result: { data: LeaseDocumentProps[] };
}

interface SetIsDocumentPopulated {
  type: ActionType.SET_IS_DOCUMENT_POPULATED;
  value: boolean;
}

export type Action =
  PromiseActionType
  | AddMasterLeaseDocumentsAction
  | ExpandFrameAction
  | GetLeaseDocumentsAction
  | ResetLeaseAction
  | SetIsDocumentPopulated;
