import produce from 'immer';
import { Action, ActionType, NurtureStep } from './action-types';
import { CommonStateType } from '../types';

export interface NurtureStepState extends CommonStateType {
  steps: NurtureStep[],
}

export const initialState: NurtureStepState = {
  isSubmitting: false,
  errorMessage: null,
  isLoaded: false,
  steps: [],
};

export const selectStepIsLoaded = (state: { nurtureStep: NurtureStepState }): boolean => state.nurtureStep.isLoaded;
export const selectNurtureSteps = (state: { nurtureStep: NurtureStepState }): NurtureStep[] => state.nurtureStep.steps;
export const selectIsSubmitting = (state: { nurtureStep: NurtureStepState }): boolean => state.nurtureStep.isSubmitting;

export default produce((state: NurtureStepState = initialState, action: Action): NurtureStepState => {
  switch (action.type) {
    case ActionType.GET_NURTURE_STEPS_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_NURTURE_STEPS_SUCCESS:
      state.isLoaded = true;
      state.steps = action.result.data;
      break;
    case ActionType.GET_NURTURE_STEPS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = false;
      break;

    case ActionType.UPDATE_NURTURE_STEP_SUCCESS:
      state.steps = state.steps.map(step => (step.id === action.result.data.id ? action.result.data : step));
      state.isSubmitting = false;
      break;

    case ActionType.CREATE_NURTURE_STEP_SUCCESS:
      state.steps = state.steps.concat(action.result.data);
      state.isSubmitting = false;
      break;

    case ActionType.DELETE_NURTURE_STEP_SUCCESS:
      state.steps = state.steps.filter(step => step.id !== action.id);
      state.isSubmitting = false;
      break;

    case ActionType.CREATE_NURTURE_STEP_REQUEST:
    case ActionType.UPDATE_NURTURE_STEP_REQUEST:
    case ActionType.DELETE_NURTURE_STEP_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_NURTURE_STEP_FAILURE:
    case ActionType.UPDATE_NURTURE_STEP_FAILURE:
    case ActionType.DELETE_NURTURE_STEP_FAILURE:
      state.isSubmitting = false;
      break;
  }

  return state;
});
