import { paths } from 'leasing/constants';
import { build } from 'leasing/constants/paths';
import { Action, ActionType, EmployerProps } from './action-types';

export default {
  getEmployers: (leaseId: number, applicantId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_EMPLOYERS_REQUEST,
        ActionType.GET_EMPLOYERS_SUCCESS,
        ActionType.GET_EMPLOYERS_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.EMPLOYERS, leaseId, applicantId)),
    },
  }),

  getIncomeSectionEmployers: (leaseId: number, applicantId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_INCOME_SECTION_EMPLOYERS_REQUEST,
        ActionType.GET_INCOME_SECTION_EMPLOYERS_SUCCESS,
        ActionType.GET_INCOME_SECTION_EMPLOYERS_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.EMPLOYERS, leaseId, applicantId)),
    },
  }),

  saveEmployer: (leaseId: number, applicantId: number, changedValues: EmployerProps[], removedIds: number[]): Action => {
    const formData = new FormData();
    const employers = [];
    Object.values(changedValues).forEach((item) => {
      employers.push(item);
    });
    formData.set('removeIds', JSON.stringify(removedIds));
    formData.set('data', JSON.stringify(employers));

    return ({
      CALL_API: {
        types: [
          ActionType.UPDATE_EMPLOYERS_REQUEST,
          ActionType.UPDATE_EMPLOYERS_SUCCESS,
          ActionType.UPDATE_EMPLOYERS_FAILURE,
        ],
        promise: client => client.post(build(paths.api.v1.EMPLOYERS_SAVE, leaseId, applicantId), formData),
      },
    });
  },

  resetEmployers: (): Action => ({ type: ActionType.RESET_EMPLOYERS }),
};
