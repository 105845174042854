import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  SET_TOOLBAR_NAV_TAB_SETTINGS = 'SET_TOOLBAR_NAV_TAB_SETTINGS',
  SET_NEW_SITE_SETTINGS_VALUES = 'SET_NEW_SITE_SETTINGS_VALUES',

  // API Requests
  GET_SITE_SEO_SETTINGS_REQUEST = 'GET_SITE_SEO_SETTINGS_REQUEST',
  GET_SITE_SEO_SETTINGS_SUCCESS = 'GET_SITE_SEO_SETTINGS_SUCCESS',
  GET_SITE_SEO_SETTINGS_FAILURE = 'GET_SITE_SEO_SETTINGS_FAILURE',

  UPDATE_SITE_SEO_SETTINGS_REQUEST = 'UPDATE_SITE_SEO_SETTINGS_REQUEST',
  UPDATE_SITE_SEO_SETTINGS_SUCCESS = 'UPDATE_SITE_SEO_SETTINGS_SUCCESS',
  UPDATE_SITE_SEO_SETTINGS_FAILURE = 'UPDATE_SITE_SEO_SETTINGS_FAILURE',

  GET_PROPERTY_SEO_SETTINGS_REQUEST = 'GET_PROPERTY_SEO_SETTINGS_REQUEST',
  GET_PROPERTY_SEO_SETTINGS_SUCCESS = 'GET_PROPERTY_SEO_SETTINGS_SUCCESS',
  GET_PROPERTY_SEO_SETTINGS_FAILURE = 'GET_PROPERTY_SEO_SETTINGS_FAILURE',

  UPDATE_PROPERTY_SEO_SETTINGS_REQUEST = 'UPDATE_PROPERTY_SEO_SETTINGS_REQUEST',
  UPDATE_PROPERTY_SEO_SETTINGS_SUCCESS = 'UPDATE_PROPERTY_SEO_SETTINGS_SUCCESS',
  UPDATE_PROPERTY_SEO_SETTINGS_FAILURE = 'UPDATE_PROPERTY_SEO_SETTINGS_FAILURE',
}

export interface SiteSettingsValues {
  gtmId: string;
  title: string;
  robots: {
    noindex: boolean;
    nofollow: boolean;
    noarchive: boolean;
    nosnippet: boolean;
  };
  favicon: string;
  subject: string;
  gtmScript: { body: string; head: string; };
  themeColor: string;
  description: string;
  bing_ownership: string;
  additionalScript: string;
  google_ownership: string;
  categories: { icon: string; name: string }[];
  markup: string;
}

export interface SiteSettings {
  id: number;
  created: string;
  updated: string;
  values: SiteSettingsValues;
}

interface SetToolbarNavTabSettingsAction {
  type: ActionType.SET_TOOLBAR_NAV_TAB_SETTINGS;
  nav: 'GENERAL' | 'APPEARANCE' | 'ADVANCED' | 'AMENITIES';
}

interface SetNewSiteSettingsValuesAction {
  type: ActionType.SET_NEW_SITE_SETTINGS_VALUES;
  newValues: SiteSettingsValues;
  oldValues: SiteSettingsValues;
}

interface GetSiteSeoSettingsAction {
  type: ActionType.GET_SITE_SEO_SETTINGS_REQUEST | ActionType.GET_SITE_SEO_SETTINGS_SUCCESS | ActionType.GET_SITE_SEO_SETTINGS_FAILURE;
  result: {
    data: {
      results: SiteSettings[]
    };
  };
  error: {
    response: { status: string };
  };
}

interface UpdateSiteSeoSettingsAction {
  type: ActionType.UPDATE_SITE_SEO_SETTINGS_REQUEST | ActionType.UPDATE_SITE_SEO_SETTINGS_SUCCESS | ActionType.UPDATE_SITE_SEO_SETTINGS_FAILURE;
  result: {
    data: SiteSettings;
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertySeoSettingsAction {
  type: ActionType.GET_PROPERTY_SEO_SETTINGS_REQUEST | ActionType.GET_PROPERTY_SEO_SETTINGS_SUCCESS | ActionType.GET_PROPERTY_SEO_SETTINGS_FAILURE;
  result: {
    data: {
      results: SiteSettings[]
    };
  };
  error: {
    response: { status: string };
  };
}

interface UpdatePropertySettingsAction {
  type: ActionType.UPDATE_PROPERTY_SEO_SETTINGS_REQUEST | ActionType.UPDATE_PROPERTY_SEO_SETTINGS_SUCCESS | ActionType.UPDATE_PROPERTY_SEO_SETTINGS_FAILURE;
  result: {
    data: SiteSettings;
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | SetToolbarNavTabSettingsAction
  | SetNewSiteSettingsValuesAction
  | GetSiteSeoSettingsAction
  | UpdateSiteSeoSettingsAction
  | GetPropertySeoSettingsAction
  | UpdatePropertySettingsAction;
