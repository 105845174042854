import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, OtherFeeItemProps } from './action-types';

export default {
  createOtherRentItem: (leaseId: number, payload: OtherFeeItemProps): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_OTHER_FEE_ITEM_REQUEST,
        ActionType.CREATE_OTHER_FEE_ITEM_SUCCESS,
        ActionType.CREATE_OTHER_FEE_ITEM_FAILURE,
      ],
      promise: client => client.post(build(api.v1.OTHER_FEE_ITEMS, leaseId), payload),
    },
  }),

  deleteOtherRentItem: (leaseId: number, id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_OTHER_FEE_ITEM_REQUEST,
        ActionType.DELETE_OTHER_FEE_ITEM_SUCCESS,
        ActionType.DELETE_OTHER_FEE_ITEM_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.OTHER_FEE_ITEM_DETAILS, leaseId, id)),
      payload: id,
    },
  }),

  updateOtherRentItem: (leaseId: number, id: number, payload: OtherFeeItemProps): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_OTHER_FEE_ITEM_REQUEST,
        ActionType.UPDATE_OTHER_FEE_ITEM_SUCCESS,
        ActionType.UPDATE_OTHER_FEE_ITEM_FAILURE,
      ],
      promise: client => client.put(build(api.v1.OTHER_FEE_ITEM_DETAILS, leaseId, id), payload),
    },
  }),
};
