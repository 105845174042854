export default {
  EXPLORE_MARKETS: '/api/v1/compete/explore_markets/',
  SUBMARKET_PROPERTIES_BY_ID: '/api/v1/compete/submarkets/:id/properties/',
  SUBMARKET_MTR_BREAKDOWN: '/api/v1/compete/submarkets/:id/mtr_group_submarkets/',
  SUBMARKET_DETAIL: '/api/v1/compete/submarkets/:id/',
  SUBMARKET_RENT_COMPS: '/api/v1/compete/submarkets/:id/rent_comps/',
  AVAILABLE_UNITS: '/api/v1/compete/properties/:id/units/',
  PROPERTIES: '/api/v1/compete/properties/',
  PROPERTIES_DETAIL: '/api/v1/compete/properties/:id/',
  PROPERTIES_COMPETITORS: '/api/v1/compete/properties/:id/competitors/',
  PROPERTIES_ALERT: '/api/v1/compete/properties/:id/alert_subscriptions/',
  COMPETITOR_SET: '/api/v1/compete/competitor_set/',
  MARKET_ENVIRONMENT: '/api/v1/compete/properties/:id/add_market_environment/',
  MARKET_DETAIL: '/api/v1/compete/markets/:id/',
  MARKET_PROPERTIES: '/api/v1/compete/markets/:id/properties/',
  MARKET_SUBMARKETS: '/api/v1/compete/markets/:id/submarkets/',
  MTR_BREAKDOWN: '/api/v1/compete/markets/:id/mtr_group_submarkets/',
  MARKET_RENT_COMPS: '/api/v1/compete/markets/:id/rent_comps/',
  ALERTS_SUBSCRIPTIONS: '/api/v1/compete/alerts/',
  ALERT_BY_ID: '/api/v1/compete/alerts/:id/',
  ALERT_LOG_DETAIL: '/api/v1/compete/alert_logs/:id/details/',
  WATCHLIST: '/api/v1/compete/watchlist/',
  HISTORICAL_PROPERTY_RENT: '/api/v1/compete/properties/:id/rent_history/',
  HISTORICAL_PROPERTY_OCCUPANCY: '/api/v1/compete/properties/:id/occupancy_history/',
  HISTORICAL_PROPERTY_CONCESSION: '/api/v1/compete/properties/:id/concession_history/',
  HISTORICAL_SUBMARKET_RENT: '/api/v1/compete/submarkets/:id/rent_history/',
  HISTORICAL_SUBMARKET_OCCUPANCY: '/api/v1/compete/submarkets/:id/occupancy_history/',
  HISTORICAL_SUBMARKET_CONCESSION: '/api/v1/compete/submarkets/:id/concession_history/',
  HISTORICAL_MARKET_RENT: '/api/v1/compete/markets/:id/rent_history/',
  HISTORICAL_MARKET_OCCUPANCY: '/api/v1/compete/markets/:id/occupancy_history/',
  HISTORICAL_MARKET_CONCESSION: '/api/v1/compete/markets/:id/concession_history/',
  HISTORICAL_RENT_COMPARE: '/api/v1/compete/properties/:id/rent_compare_history/',
  COMPARISON: '/api/v1/compete/comparisons/',
  COMPARISON_BY_ID: '/api/v1/compete/comparisons/:id/',
  COMPARISON_HIGHEST_RENT: '/api/v1/compete/comparisons/:id/highest_average_rents/',
  COMPARISON_HIGHEST_OCCUPANCY: '/api/v1/compete/comparisons/:id/highest_occupancy/',
  UNIT_SESSION: '/api/v1/compete/unit_sessions/:id/',
  SUBMARKET_NAMES: '/api/v1/compete/submarkets/names/',
};
