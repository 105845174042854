import produce from 'immer';
import {
  Action,
  ActionType,
  PropertyTypes,
  PropertyInfoTypes,
  PageSection,
  LeasingCriteriaTypes,
  FloorPlanTypes,
  PromotionTypes,
  AmenitiesTypes,
  TeamTypes,
  NeighborhoodTypes,
  GalleryTypes,
  LeadNameTypes,
  SisterCommunitiesTypes,
  UnitTypes,
  ExpandedUnits,
} from './action-types';
import { preFillState } from '../utils';
import { CommonStateType } from '../types';

export interface PropertyProfileState extends CommonStateType {
  propertyInfo: PropertyInfoTypes,
  leasingCriteria: LeasingCriteriaTypes,
  floorPlans: FloorPlanTypes[],
  units: UnitTypes[],
  promotion: PromotionTypes[],
  amenities: AmenitiesTypes,
  teams: TeamTypes[],
  properties: PropertyTypes[],
  section: PageSection,
  previousSection: PageSection,
  neighborhood: NeighborhoodTypes,
  gallery: GalleryTypes,
  leads: LeadNameTypes[],
  sisterCommunities: SisterCommunitiesTypes[],
  isOpen: boolean,
  currentProperty: PropertyTypes,
  activeUnit: UnitTypes,
  isGalleryPreviewOpen: boolean,
  unitType: string,
  expandedUnits: ExpandedUnits;
}

export const initialState: PropertyProfileState = {
  isLoaded: false,
  isSubmitting: false,
  errorMessage: null,
  isOpen: false,
  section: PageSection.MAIN_MENU,
  previousSection: PageSection.MAIN_MENU,
  propertyInfo: {} as PropertyInfoTypes,
  leasingCriteria: {} as LeasingCriteriaTypes,
  floorPlans: [] as FloorPlanTypes[],
  units: [] as UnitTypes[],
  promotion: [] as PromotionTypes[],
  amenities: {} as AmenitiesTypes,
  teams: [] as TeamTypes[],
  neighborhood: {} as NeighborhoodTypes,
  properties: [] as PropertyTypes[],
  gallery: {} as GalleryTypes,
  leads: [] as LeadNameTypes[],
  sisterCommunities: [] as SisterCommunitiesTypes[],
  currentProperty: {} as PropertyTypes,
  activeUnit: {} as UnitTypes,
  isGalleryPreviewOpen: false,
  unitType: null,
  expandedUnits: {} as ExpandedUnits,
};

export default produce((state: PropertyProfileState = initialState, action: Action): PropertyProfileState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_PROFILE_DETAILS_SUCCESS:
      state.propertyInfo = action.result.data.property_info;
      state.leasingCriteria = action.result.data.leasing_criteria;
      state.floorPlans = action.result.data.floor_plans;
      state.units = action.result.data.units;
      state.promotion = action.result.data.promotion;
      state.amenities = action.result.data.amenities;
      state.teams = action.result.data.teams;
      state.neighborhood = action.result.data.neighborhood;
      state.gallery = action.result.data.gallery;
      state.leads = action.result.data.leads;
      state.sisterCommunities = action.result.data.sister_communities;
      break;

    case ActionType.GET_PROFILE_PROPERTIES_SUCCESS:
      state.properties = action.result.data.results;
      break;

    case ActionType.SET_SECTION:
      state.previousSection = state.section;
      state.section = action.section;
      break;

    case ActionType.SET_PREVIOUS_SECTION:
      state.previousSection = action.section;
      break;

    case ActionType.SET_EXPANDED_UNITS:
      state.expandedUnits = action.expandedUnits;
      break;

    case ActionType.OPEN_PROFILE:
      state.isOpen = action.isOpen;
      break;

    case ActionType.SET_PROPERTY:
      state.currentProperty = action.property;
      break;

    case ActionType.SET_ACTIVE_UNIT:
      state.activeUnit = action.unit;
      break;

    case ActionType.OPEN_GALLERY_PREVIEW:
      state.isGalleryPreviewOpen = action.isOpen;
      break;

    case ActionType.SET_UNIT_TYPE:
      state.unitType = action.unitType;
      break;
  }

  return state;
});
