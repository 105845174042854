import produce from 'immer';
import { Action, ActionType, AllBusinessHoursProps, BusinessHoursProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface BusinessHoursState extends CommonStateType {
  businessHours: BusinessHoursProps[];
  allBusinessHours: AllBusinessHoursProps;
}

export const initialState: BusinessHoursState = {
  isLoaded: false,
  isSubmitting: false,
  errorMessage: null,

  businessHours: [],
  allBusinessHours: {},
};

export const selectBusinessHours = (state: { businessHours: BusinessHoursState }): BusinessHoursProps[] => state.businessHours.businessHours;

export default produce((state: BusinessHoursState = initialState, action: Action): BusinessHoursState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_BUSINESS_HOURS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_BUSINESS_HOURS_SUCCESS:
      state.businessHours = action.result.data.results;
      state.isSubmitting = false;
      break;
    case ActionType.GET_BUSINESS_HOURS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.GET_BUSINESS_HOURS_ALL_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.GET_BUSINESS_HOURS_ALL_SUCCESS:
      state.allBusinessHours = action.result.data;
      state.isSubmitting = false;
      break;
    case ActionType.GET_BUSINESS_HOURS_ALL_FAILURE:
      state.errorMessage = action.error.response.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_BUSINESS_HOURS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_BUSINESS_HOURS_SUCCESS:
      state.businessHours = action.result.data;
      state.isSubmitting = false;
      break;
    case ActionType.UPDATE_BUSINESS_HOURS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.CREATE_BUSINESS_HOURS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_BUSINESS_HOURS_SUCCESS:
      state.businessHours = action.result.data;
      state.isSubmitting = false;
      break;
    case ActionType.CREATE_BUSINESS_HOURS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;
  }

  return state;
});
