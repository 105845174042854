import styled from 'styled-components';
import { Card, CardFooter } from 'reactstrap';
import { Avatar as CommonAvatar } from 'src/styles/common';

export const ChatWrapper = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  scrollbar-width: none;

  -ms-overflow-style: none;
`;

export const ChatGroup = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ChatItem = styled.li`
  position: relative;

  margin-top: 10px;
  & ~ & { margin-top: 10px; }

  &.response {
    display: flex;
    justify-content: flex-end;

    .card-chat {
      border-width: 0;
      background-color: #4A5E8A;
      color: #fff;
    }
  }
`;

export const CardChat = styled(Card)`
  border-width: 0;
  border-radius: 4px;
  box-shadow: 0px 4px 24px rgb(11 33 81 / 12%);
  margin-bottom: 0;

  .card-body {
    padding: 12px;

    h6 {
      font-size: 15px;
      margin-bottom: 2px;
    }

    span { display: block; }
  }

  .card-footer {
    margin: 0 10px;
    padding: 10px 0;
    background-color: transparent;
    border-top-color: #E2E7F4;
  }

  .list-styled {
    list-style: disc;
    margin: 0;
    padding-left: 13px;

    strong { font-weight: 500; }
  }
`;

export const ChatAuthor = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  span {
    font-size: 12px;
    display: block;
    color: ${props => props.theme.colors.secondary};
  }
`;

// @include shadow-dropdown();
export const CardChatAI = styled(Card)`
  border-color: #E2E7F4;
  border-radius: 3px;
  position: absolute;
  bottom: 65px;
  left: 14px;
  right: 14px;
  display: none;
  margin-bottom: 0;
  transition: all .3s ease;

  &.show { display: block; }

  .card-header {
    background-color: inherit;
    border-bottom-color: #E2E7F4;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    font-size: 12px;

    i {
      font-size: 14px;
      line-height: 1;
      margin-right: 4px;
    }
  }

  .card-body { padding: 0; }
`;

export const CardAIItem = styled.a`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.bodyColor};
  position: relative;

  &:hover, &:focus {
    background-color: #F7F8FC;
    color: ${props => props.theme.colors.bodyColor};
  }

  &::before {
    content: '\\EDDD';
    font-family: 'remixicon';
    margin-right: 5px;
  }

  div {
    margin-right: auto;
    max-width: 80%;
  }

  span {
    background-color: #98A4C1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    font-size: 11px;

    + span { margin-left: 3px; }
  }
`;

export const ChatFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px;
  background-color: #F7F8FC;
  border-top: 1px solid #E2E7F4;

  input {
    margin-bottom: 0px;
  }
`;

export const Avatar = styled(CommonAvatar)`
  width: 18px;
  height: 18px;
  font-size: 11px;
  margin-right: 3px;

  i { line-height: 1; }

  &::before { content: none; }
`;

export const ChatAILabel = styled.label`
  background-color: inherit;
  border-bottom-color: #E2E7F4;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 0;
  color: ${props => props.theme.colors.secondary};
  border-top: 1px solid #E2E7F4;
  border-bottom: 1px solid #E2E7F4;

  i {
    font-size: 14px;
    line-height: 1;
    margin-right: 4px;
  }
`;

export const Clipboard = styled.a`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #E2E7F4;
  color: ${props => props.theme.colors.secondary};
  cursor: pointer;

  i {
    color: ${props => (props.copyClicked ? props.theme.colors.green : props.theme.colors.secondary)};
  }
`;

export const ClipboardContainer = styled(CardFooter)`
    background: transparent;
    border-top: 1px solid rgba(0,0,0,0.125);
    margin: 0 10px;
    padding: 10px 0;
    background-color: transparent;
    border-top-color: #E2E7F4;
`;
