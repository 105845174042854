export default {
  UNIT_TYPES: {
    STUDIO: 'Studio',
    ONE_BED: '1 bed',
    TWO_BED: '2 bed',
    THREE_BED: '3 bed',
    FOUR_BED: '4 bed',
    ONE_BED_PENTHOUSE: '1 bed Penthouse',
    TWO_BED_PENTHOUSE: '2 bed Penthouse',
    THREE_BED_PENTHOUSE: '3 bed Penthouse',
  },
  SELECT_UNIT_TYPES: {
    STUDIO: 'Studio',
    ONE_BEDROOM: '1 bedroom',
    TWO_BEDROOM: '2 bedrooms',
    THREE_BEDROOM: '3 bedrooms',
    FOUR_BEDROOM: '4 bedrooms',
    ONE_BATHROOM: '1 bathroom',
    TWO_BATHROOM: '2 bathrooms',
    THREE_BATHROOM: '3 bathrooms',
  },
  BATHROOM_TYPES: ['', 'ONE_BATHROOM', 'TWO_BATHROOM', 'THREE_BATHROOM'],
  BEDROOM_TYPES: ['STUDIO', 'ONE_BEDROOM', 'TWO_BEDROOM', 'THREE_BEDROOM', 'FOUR_BEDROOM'],
};
