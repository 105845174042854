import styled from 'styled-components';

export const Divider = styled.div`
  margin: 20px 0 10px 0;
  position: relative;
  display: flex;
  align-items: center;

  &:before, &:after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    border-top: 1px solid ${props => props.theme.input.borderColor};
  }

  span {
    display: block;
    font-size: 12px;
    color: ${props => props.theme.colors.gray500};
    padding: 0 5px;
  }
`;
