import editSite from './page/action-creators';
import siteSettings from './site_settings/action-creators';
import customer from './customer/action-creators';
import mediaLibrary from './media_library/action-creators';
import section from './section/action-creators';

export default {
  editSite,
  mediaLibrary,
  customer,
  siteSettings,
  section,
};
