import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'reactstrap';

import actions from 'dwell/actions/index';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { getInitials } from 'dwell/views/chat/common/utils';

import { PropertyActionsDropdown, PropertyActionsButtonDropdown, PropertyItemMoreV2, PIDropdownMenuV2, DropdownItemV2 } from 'containers/default_header/styles';
import { RECENT_PROPERTY_HYPHENS } from 'dwell/constants';

interface HeaderPropertyDropdownProps {
  properties: PropertyProps[];
  removable: boolean;
}

const HeaderPropertyDropdown: FC<HeaderPropertyDropdownProps> = ({ properties }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const selectedProperties = useSelector(selectSelectedProperties);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { updateUserAccessedProperties } = actions.user;
  const { setChatRenderedStatus, clearActiveChats } = actions.prospectChat;
  const { setPreSelectedProperties, setPropertyKeyword } = actions.propertiesSelector;

  const removePropertyHandler = (propertyId: number) => {
    const newState = selectedProperties.filter(item => item.id !== propertyId);
    if (newState.length === 1) {
      localStorage.setItem(RECENT_PROPERTY_HYPHENS, newState[0].external_id);
    }
    dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: newState }, () => 0)).then(() => {
      document.body.classList.remove('scroll_locked');
      dispatch(setPreSelectedProperties([]));
      dispatch(setPropertyKeyword(''));
      dispatch(clearActiveChats());
      dispatch(setChatRenderedStatus(true));
    });
  };

  const onlyPropertyHandler = (property: PropertyProps) => {
    localStorage.setItem(RECENT_PROPERTY_HYPHENS, property.external_id);
    dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: [property] }, () => 0)).then(() => {
      document.body.classList.remove('scroll_locked');
      dispatch(setPreSelectedProperties([]));
      dispatch(setPropertyKeyword(''));
      dispatch(clearActiveChats());
      dispatch(setChatRenderedStatus(true));
    });
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <PropertyItemMoreV2>
        +<span>{properties.length}</span>
      </PropertyItemMoreV2>
      <PIDropdownMenuV2>
        {properties.map(property => (
          <DropdownItemV2 key={property.id}>
            <div>
              <span>{property.name}</span>
              <span>{getInitials(property.name)}</span>
            </div>
            <PropertyActionsDropdown className="dwp-actions">
              <PropertyActionsButtonDropdown key="only-btn" onClick={() => onlyPropertyHandler(property)}>
                only
              </PropertyActionsButtonDropdown>
              <PropertyActionsButtonDropdown key="x-button" onClick={() => removePropertyHandler(property.id)}>
                &times;
              </PropertyActionsButtonDropdown>
            </PropertyActionsDropdown>
          </DropdownItemV2>
        ))}
      </PIDropdownMenuV2>
    </Dropdown>
  );
};

export default HeaderPropertyDropdown;
