import { api, build } from 'leasing/constants/paths';
import { Action, ActionType, OtherIncomeProps } from './action-types';

export default {
  getOtherIncomes: (leaseId: number, applicantId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_OTHER_INCOMES_REQUEST,
        ActionType.GET_OTHER_INCOMES_SUCCESS,
        ActionType.GET_OTHER_INCOMES_FAILURE,
      ],
      promise: client => client.get(build(api.v1.OTHER_INCOMES, leaseId, applicantId)),
    },
  }),

  saveOtherIncomes: (leaseId: number, applicantId: number, changedValues: OtherIncomeProps[], removedIds: number[]): Action => {
    const formData = new FormData();
    const otherIncomes = [];
    Object.values(changedValues).forEach((item) => {
      otherIncomes.push(item);
    });
    formData.set('data', JSON.stringify(otherIncomes));
    formData.set('removeIds', JSON.stringify(removedIds));

    return ({
      CALL_API: {
        types: [
          ActionType.UPDATE_OTHER_INCOMES_REQUEST,
          ActionType.UPDATE_OTHER_INCOMES_SUCCESS,
          ActionType.UPDATE_OTHER_INCOMES_FAILURE,
        ],
        promise: client => client.post(build(api.v1.OTHER_INCOMES_SAVE, leaseId, applicantId), formData),
      },
    });
  },
};
