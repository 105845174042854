import { CommonActionResponse, CommonStateType, PromiseActionType } from 'dwell/store/types';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';

export enum ActionType {
  GET_EMPLOYERS_REQUEST = 'GET_EMPLOYERS_REQUEST',
  GET_EMPLOYERS_SUCCESS = 'GET_EMPLOYERS_SUCCESS',
  GET_EMPLOYERS_FAILURE = 'GET_EMPLOYERS_FAILURE',

  UPDATE_EMPLOYERS_REQUEST = 'UPDATE_EMPLOYERS_REQUEST',
  UPDATE_EMPLOYERS_SUCCESS = 'UPDATE_EMPLOYERS_SUCCESS',
  UPDATE_EMPLOYERS_FAILURE = 'UPDATE_EMPLOYERS_FAILURE',

  GET_INCOME_SECTION_EMPLOYERS_REQUEST = 'GET_INCOME_SECTION_EMPLOYERS_REQUEST',
  GET_INCOME_SECTION_EMPLOYERS_SUCCESS = 'GET_INCOME_SECTION_EMPLOYERS_SUCCESS',
  GET_INCOME_SECTION_EMPLOYERS_FAILURE = 'GET_INCOME_SECTION_EMPLOYERS_FAILURE',

  RESET_EMPLOYERS = 'RESET_EMPLOYERS',
}

export interface EmployerErrorProps {
  employer_name?: string,
  position?: string,
  monthly_income?: string,
  income_category?: string,
  supervisor_name?: string,
  supervisor_email?: string,
  supervisor_phone_number?: string,
  industry?: string,
  employment_start_date?: string,
  zip_or_postal_code?: string,
  country?: string,
  state?: string,
  city_name?: string,
  first_street_address?: string,
  second_street_address?: string,
}

export interface EmployerProps {
  id?: number,
  type?: string,
  employer_name?: string,
  zip_or_postal_code?: string,
  country?: string,
  first_street_address?: string,
  second_street_address?: string,
  state?: string,
  city_name?: string,
  supervisor_name?: string,
  supervisor_email?: string,
  supervisor_phone_number?: string,
  supervisor_phone_type?: string,
  position?: string,
  monthly_income?: number,
  past_12_months_income?: number,
  employment_start_date?: string,
  is_most_recent_employer?: boolean,
  showMore?: boolean,
  income_category?: string,
  income_type?: string,
  industry?: string,
  income_status?: string,
  documents?: string[],
}

export interface EmployerState extends CommonStateType {
  employers: EmployerProps[],
  incomeSectionEmployers: EmployerProps[],
}

/*
 * Action
 */

interface ListEmployersResponse { data: { results: EmployerProps[] } }

interface GetEmployersAction extends CommonActionResponse {
  type: ActionType.GET_EMPLOYERS_REQUEST
  | ActionType.GET_EMPLOYERS_SUCCESS
  | ActionType.GET_EMPLOYERS_FAILURE;
  result: ListEmployersResponse;
}

interface GetIncomeSectionEmployersAction extends CommonActionResponse {
  type: ActionType.GET_INCOME_SECTION_EMPLOYERS_REQUEST
  | ActionType.GET_INCOME_SECTION_EMPLOYERS_SUCCESS
  | ActionType.GET_INCOME_SECTION_EMPLOYERS_FAILURE;
  result: ListEmployersResponse;
}

interface ResetEmployersAction { type: ActionType.RESET_EMPLOYERS }

interface ResetLeaseAction { type: LeaseActionType.RESET_LEASE }

interface UpdateEmployersAction extends CommonActionResponse {
  type: ActionType.UPDATE_EMPLOYERS_REQUEST
  | ActionType.UPDATE_EMPLOYERS_SUCCESS
  | ActionType.UPDATE_EMPLOYERS_FAILURE;
  result: { data: EmployerProps[] };
}

export type Action =
  PromiseActionType
  | GetEmployersAction
  | GetIncomeSectionEmployersAction
  | ResetEmployersAction
  | ResetLeaseAction
  | UpdateEmployersAction;
