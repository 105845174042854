import { paths } from 'dwell/constants';
import { ActionType, Action } from './action-types';

export default {
  getNurtureSteps: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_NURTURE_STEPS_REQUEST,
        ActionType.GET_NURTURE_STEPS_SUCCESS,
        ActionType.GET_NURTURE_STEPS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.NURTURE_STEPS_LIST, id)),
    },
  }),

  createNurtureStep: (data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_NURTURE_STEP_REQUEST,
        ActionType.CREATE_NURTURE_STEP_SUCCESS,
        ActionType.CREATE_NURTURE_STEP_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.NURTURE_STEPS, data),
    },
  }),

  updateNurtureStep: (id: number, data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_NURTURE_STEP_REQUEST,
        ActionType.UPDATE_NURTURE_STEP_SUCCESS,
        ActionType.UPDATE_NURTURE_STEP_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.NURTURE_STEPS_ID, id), data),
    },
  }),

  deleteNurtureStep: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_NURTURE_STEP_REQUEST,
        ActionType.DELETE_NURTURE_STEP_SUCCESS,
        ActionType.DELETE_NURTURE_STEP_FAILURE,
      ],
      promise: client => client.delete(paths.build(paths.api.v1.NURTURE_STEPS_ID, id)),
      id,
    },
  }),
};
