import moment from 'moment';
import { paths } from 'dwell/constants';
import { ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, Nurture } from './action-types';

export default {
  getNurtureAutomations: (props: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_NURTURE_AUTOMATIONS_REQUEST,
        ActionType.GET_NURTURE_AUTOMATIONS_SUCCESS,
        ActionType.GET_NURTURE_AUTOMATIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.NURTURE_AUTOMATIONS, { params: props }),
    },
  }),

  createNurtureAutomations: (data: Nurture): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_NURTURE_AUTOMATIONS_REQUEST,
        ActionType.CREATE_NURTURE_AUTOMATIONS_SUCCESS,
        ActionType.CREATE_NURTURE_AUTOMATIONS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.NURTURE_AUTOMATIONS, data),
    },
  }),

  getNurtureDetails: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_NURTURE_DETAILS_REQUEST,
        ActionType.GET_NURTURE_DETAILS_SUCCESS,
        ActionType.GET_NURTURE_DETAILS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.NURTURE_AUTOMATIONS_ID, id)),
    },
  }),

  updateNurtureAutomations: (id: number, data: Nurture): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_NURTURE_AUTOMATIONS_REQUEST,
        ActionType.UPDATE_NURTURE_AUTOMATIONS_SUCCESS,
        ActionType.UPDATE_NURTURE_AUTOMATIONS_FAILURE,
      ],
      promise: client => client.patch(paths.build(paths.api.v1.NURTURE_AUTOMATIONS_ID, id), data),
    },
  }),

  getNurtureInsights: (id: number, props: { date_period: string, custom_start_date: string, custom_end_date: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_NURTURE_INSIGHTS_REQUEST,
        ActionType.GET_NURTURE_INSIGHTS_SUCCESS,
        ActionType.GET_NURTURE_INSIGHTS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.NURTURE_INSIGHTS, id), { params: props }),
    },
  }),

  setFormChanged: (changed: boolean): Action => ({ type: ActionType.SET_NURTURE_FORM_CHANGED, changed }),

  setDatePeriod: (datePeriod: string): Action => ({ type: ActionType.SET_INSIGHTS_DATE_PERIOD, datePeriod }),

  setCustomDate: (startDate: moment.Moment, endDate: moment.Moment): Action => ({
    type: ActionType.SET_INSIGHTS_CUSTOM_DATE,
    startDate,
    endDate,
  }),
};
