export default {
  CALL_API: 'CALL_API',
  GET_DEMO_TOUR_BY_ID_FAILURE: 'GET_DEMO_TOUR_BY_ID_FAILURE',
  GET_DEMO_TOUR_BY_ID_REQUEST: 'GET_DEMO_TOUR_BY_ID_REQUEST',
  GET_DEMO_TOUR_BY_ID_SUCCESS: 'GET_DEMO_TOUR_BY_ID_SUCCESS',

  UPDATE_DEMO_TOUR_BY_ID_FAILURE: 'UPDATE_DEMO_TOUR_BY_ID_FAILURE',
  UPDATE_DEMO_TOUR_BY_ID_REQUEST: 'UPDATE_DEMO_TOUR_BY_ID_REQUEST',
  UPDATE_DEMO_TOUR_BY_ID_SUCCESS: 'UPDATE_DEMO_TOUR_BY_ID_SUCCESS',

  CREATE_DEMO_TOUR_FAILURE: 'CREATE_DEMO_TOUR_FAILURE',
  CREATE_DEMO_TOUR_REQUEST: 'CREATE_DEMO_TOUR__REQUEST',
  CREATE_DEMO_TOUR_SUCCESS: 'CREATE_DEMO_TOUR_SUCCESS',

  GET_DEMO_TOUR_AVAILABLE_DATES_FAILURE: 'GET_DEMO_TOUR_AVAILABLE_DATES_FAILURE',
  GET_DEMO_TOUR_AVAILABLE_DATES_REQUEST: 'GET_DEMO_TOUR_AVAILABLE_DATES_REQUEST',
  GET_DEMO_TOUR_AVAILABLE_DATES_SUCCESS: 'GET_DEMO_TOUR_AVAILABLE_DATES_SUCCESS',

  GET_DEMO_TOUR_AVAILABLE_TIMES_FAILURE: 'GET_DEMO_TOUR_AVAILABLE_TIMES_FAILURE',
  GET_DEMO_TOUR_AVAILABLE_TIMES_REQUEST: 'GET_DEMO_TOUR_AVAILABLE_TIMES_REQUEST',
  GET_DEMO_TOUR_AVAILABLE_TIMES_SUCCESS: 'GET_DEMO_TOUR_AVAILABLE_TIMES_SUCCESS',
};
