import { paths } from 'dwell/constants';
import { UserProps } from 'dwell/store/user/action-types';
import { ActionType, Action } from './action-types';

interface GetUsersByProperty {
  id: number;
}

export default {
  getRescoreUsers: (params?: GetUsersByProperty): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_RESCORE_USERS_REQUEST,
        ActionType.GET_RESCORE_USERS_SUCCESS,
        ActionType.GET_RESCORE_USERS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.RESCOREUSERS, { params }),
    },
  }),
  setRescoreUsers: (users: UserProps[]): Action => ({
    type: ActionType.SET_RESCORE_USERS,
    users,
  }),
};
