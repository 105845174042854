import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FloorPlanProps } from 'src/interfaces';

const commonFields = ['description', 'owner', 'type'];
export const taskFields = [...commonFields, 'due_date'];
export const tourFields = [...commonFields, 'tour_confirmation_reminder_enabled', 'tour_date', 'units'];

interface UnitOptions {
  label: string,
  options: { label: string, value: number }[],
}

export interface InputProps {
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  task: any;
  watch?: UseFormWatch<FieldValues>;
  type: string;
  isLeadLevel?: boolean;
  objectType?: string;
  disableObjectSelection?: boolean;
  isTour?: boolean;
}

export const mapFloorPlans = (floorPlans: FloorPlanProps[], type: string): UnitOptions[] => floorPlans
  .map((fp) => {
    if (type && type === 'SELF_GUIDED_TOUR') {
      return {
        label: fp.plan,
        options: fp.all_units.filter((u) => {
          if (u.is_waitlist) return false;
          return u.can_be_toured;
        }).map(u => ({ label: u.unit, value: u.id })),
      };
    }
    return {
      label: fp.plan,
      options: fp.units.filter((u) => {
        if (u.is_waitlist) return false;
        return true;
      }).map(u => ({ label: u.unit, value: u.id })),
    };
  });
