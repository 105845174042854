import styled from 'styled-components';
import { PropertyListCard, PropertyListLabel } from 'dwell/components/leads/lead_share_modal/styles';

export const OptionListCard = styled(PropertyListCard)`
  border-color: #d9def0;
`;

export const OptionListLabel = styled(PropertyListLabel)`
  &::before {
    border-color: #c1c8de;
  }
`;
