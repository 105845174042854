import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, CardBody, Col, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import {
  PropertyList as OptionList,
  PropertyListAction as OptionListAction,
  PropertyListCardHeader as OptionListCardHeader,
  PropertyListCardTitle as OptionListCardTitle,
  PropertyListCloseIcon as OptionListCloseIcon,
  PropertyListFormSearch as OptionListFormSearch,
  PropertyListFormSearchInput as OptionListFormSearchInput,
  PropertyListItem as OptionListItem,
} from 'dwell/components/leads/lead_share_modal/styles';
import { OptionListCard, OptionListLabel } from './styles';

interface Option {
  label: string,
  value: string,
}

interface MultiSelectorProps {
  options: Option[],
  values: Option[],
  onChange: (selected: Option[]) => void,
}

const MultiSelector: FC<MultiSelectorProps> = ({ options, values, onChange }) => {
  const [keywordAll, setKeywordAll] = useState('');
  const [keywordSelected, setKeywordSelected] = useState('');

  const handleChange = (item, isChecked) => {
    const newValue = isChecked ? values.concat(item) : values.filter(el => el.value !== item.value);
    onChange(newValue);
  };

  const selectAll = () => {
    onChange(options);
  };

  const selectNone = () => {
    onChange([]);
  };

  return (
    <div className="lead-share-modal">
      <Row>
        <Col xs={6}>
          <OptionListCard>
            <OptionListCardHeader>
              <InputGroup className="search-group justify-content-between">
                <OptionListFormSearch>
                  <i className="ri-search-line" />
                  <OptionListFormSearchInput
                    value={keywordAll}
                    onChange={({ target: { value } }) => setKeywordAll(value)}
                    placeholder="Search..."
                  />
                </OptionListFormSearch>
                {keywordAll &&
                  <InputGroupAddon addonType="append">
                    <Button color="white" className="clear-btn" onClick={() => setKeywordAll('')}>&times;</Button>
                  </InputGroupAddon>}
              </InputGroup>
            </OptionListCardHeader>
            <CardBody>
              <OptionListCardTitle className="d-flex align-items-center justify-content-end">
                <OptionListAction onClick={() => selectNone()}>Select None</OptionListAction>
                <OptionListAction onClick={() => selectAll()}>Select All</OptionListAction>
              </OptionListCardTitle>
              <OptionList>
                {options.filter(item => item.label.toLowerCase().includes(keywordAll.toLowerCase())).map((item, index) =>
                  (
                    <OptionListItem className="d-flex align-items-center" key={index}>
                      <div className="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`multi-selector-${item.value}`}
                          name={item.value}
                          checked={!isEmpty(values) && values.includes(item)}
                          onChange={({ target: { checked } }) => handleChange(item, checked)}
                        />
                        <OptionListLabel
                          htmlFor={`multi-selector-${item.value}`}
                          className="ml-1 custom-control-label"
                        >{item.label}
                        </OptionListLabel>
                      </div>
                    </OptionListItem>
                  ))}
              </OptionList>
            </CardBody>
          </OptionListCard>
        </Col>
        <Col xs={6}>
          <OptionListCard>
            <OptionListCardHeader>
              <InputGroup className="search-group justify-content-between">
                <OptionListFormSearch>
                  <i className="ri-search-line" />
                  <OptionListFormSearchInput
                    value={keywordSelected}
                    onChange={({ target: { value } }) => setKeywordSelected(value)}
                    placeholder="Search..."
                  />
                </OptionListFormSearch>
                {keywordSelected &&
                  <InputGroupAddon addonType="append">
                    <Button color="white" className="clear-btn" onClick={() => setKeywordSelected('')}>&times;</Button>
                  </InputGroupAddon>}
              </InputGroup>
            </OptionListCardHeader>
            <CardBody>
              <OptionListCardTitle className="d-flex align-items-center justify-content-between">
                <div>{values.length} selected</div>
                <OptionListAction onClick={() => selectNone()}>Clear All</OptionListAction>
              </OptionListCardTitle>
              <OptionList>
                {values.filter(item => item.label.toLowerCase().includes(keywordSelected.toLowerCase())).map(item => (
                  <OptionListItem isSelected key={`selected-${item.value}`} className="d-flex align-items-center justify-content-between">
                    <OptionListLabel>{item.label}</OptionListLabel>
                    <button
                      style={{ opacity: 1 }}
                      className="close"
                      onClick={() => handleChange(item, false)}
                    >
                      <OptionListCloseIcon className="ri-close-line" />
                    </button>
                  </OptionListItem>))}
              </OptionList>
            </CardBody>
          </OptionListCard>
        </Col>
      </Row>
    </div>
  );
};

export default MultiSelector;
