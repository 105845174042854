import produce from 'immer';
import { sortBy, unionBy } from 'lodash';
import { Action, ActionType, AnswerAssistTopicType, AssistBotConversationTypes, SuggestedTopicType } from './action-types';
import { Action as ProfileAction, ActionType as ProfileActionType } from '../property_profile/action-types';
import { preFillState } from '../utils';
import { CommonStateType } from '../types';

export interface AssistBotState extends CommonStateType {
  conversations: AssistBotConversationTypes[],
  suggestedTopics: SuggestedTopicType[],
  answerAssistTopics: AnswerAssistTopicType[],
  totalCount: number,
}

export const initialState: AssistBotState = {
  isLoaded: false,
  isSubmitting: false,
  errorMessage: null,

  conversations: [] as AssistBotConversationTypes[],
  suggestedTopics: [] as SuggestedTopicType[],
  answerAssistTopics: [] as AnswerAssistTopicType[],
  totalCount: 0,
};

export default produce((state: AssistBotState = initialState, action: Action | ProfileAction): AssistBotState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.GET_ASSIST_BOT_CONVERSATIONS_SUCCESS:
      state.conversations = sortBy(unionBy(action.result.data.results, state.conversations, 'id'), (o: { date: string }) => new Date(o.date)) as AssistBotConversationTypes[];
      state.totalCount = action.result.data.count;
      state.isLoaded = true;
      break;

    case ActionType.CREATE_ASSIST_BOT_CONVERSATION_SUCCESS:
      state.conversations = sortBy(unionBy([action.result.data], state.conversations, 'id'), (o: { date: string }) => new Date(o.date)) as AssistBotConversationTypes[];
      state.totalCount += 1;
      break;

    case ActionType.CLEAR_ASSIST_BOT_CONVERSATIONS_SUCCESS:
      state.conversations = [];
      state.totalCount = 0;
      break;

    case ProfileActionType.GET_PROFILE_DETAILS_SUCCESS:
      state.answerAssistTopics = action.result.data.answer_assist_topics;
      state.conversations = [];
      state.isLoaded = false;
      break;

    case ActionType.SET_SUGGESTED_TOPICS:
      state.suggestedTopics = action.topics;
      break;
  }

  return state;
});
