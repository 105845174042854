import React, { FC, useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { formatTimeByTimezone } from 'src/utils';
import { selectDoNotDisturb, selectHasDoNotDisturb } from 'dwell/store/user/reducers';
import { selectCallInProgress } from 'dwell/store/voice/reducers';
import { Dropdown } from 'reactstrap';

import userActions from 'dwell/store/user/action-creators';
import { DropdownSwitch, OnOff, DoNotDisturbDropdownMenu, DropdownLink, DropdownChoice } from './styles';

const choices = [
  { id: 'FIFTEEN_MINUTES', label: '15 minutes' },
  { id: 'THIRTY_MINUTES', label: '30 minutes' },
  { id: 'ONE_HOUR', label: '1 hour' },
  { id: 'TWO_HOURS', label: '2 hours' },
  { id: 'FOUR_HOURS', label: '4 hours' },
  { id: 'UNTIL_FURTHER_NOTICE', label: 'Until further notice' },
];

const lapseDurations = {
  FIFTEEN_MINUTES: 15,
  THIRTY_MINUTES: 30,
  ONE_HOUR: 60,
  TWO_HOURS: 120,
  FOUR_HOURS: 240,
  UNTIL_FURTHER_NOTICE: Infinity,
};

const DoNotDisturbSwitch: FC = () => {
  const [switchOn, setSwitchOn] = useState(false);
  const [inactiveLabel, setInactiveLabel] = useState('further notice');
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [lapse, setLapse] = useState(null);
  const [disabledChoices, setDisabledChoices] = useState<string[]>([]);

  const intervalId = useRef(null);
  const dispatch = useDispatch();
  const doNotDisturbSettings = useSelector(selectDoNotDisturb);
  const hasDoNotDisturb = useSelector(selectHasDoNotDisturb);
  const callInProgress = useSelector(selectCallInProgress);

  const { setDoNotDisturb, clearDoNotDisturb } = userActions;

  const calculateDisabledChoices = (startDatetime: string, now: moment.Moment) => choices.filter(({ id }) => {
    const lapseMinutes = lapseDurations[id];
    const potentialEndTime = formatTimeByTimezone(startDatetime).add(lapseMinutes, 'minutes');
    return potentialEndTime.isBefore(now);
  }).map(choice => choice.id);

  useEffect(() => {
    if (doNotDisturbSettings) {
      setSwitchOn(true);
      setLapse(doNotDisturbSettings.lapse);
      if (doNotDisturbSettings.end_datetime) {
        const endDatetime = formatTimeByTimezone(doNotDisturbSettings.end_datetime);
        setInactiveLabel(formatTimeByTimezone(endDatetime).format('h:mm a'));
      } else {
        setInactiveLabel('further notice');
      }
    }
  }, [doNotDisturbSettings]);

  useEffect(() => {
    if (switchOn && doNotDisturbSettings && doNotDisturbSettings.end_datetime) {
      intervalId.current = setInterval(() => {
        const endDatetime = formatTimeByTimezone(doNotDisturbSettings.end_datetime);
        const now = formatTimeByTimezone();
        setDisabledChoices(calculateDisabledChoices(doNotDisturbSettings.start_datetime, now));
        if (endDatetime.isBefore(now)) {
          setSwitchOn(false);
          setLapse(null);
          dispatch(clearDoNotDisturb(true));
        }
      }, 1000 * 60 * 1);
    } else if (!switchOn && intervalId) {
      clearInterval(intervalId.current);
    }
    if (doNotDisturbSettings) setDisabledChoices(calculateDisabledChoices(doNotDisturbSettings.start_datetime, formatTimeByTimezone()));
    return () => {
      clearInterval(intervalId.current);
    };
  }, [switchOn, doNotDisturbSettings]);

  const doNotDisturb = (_switchOn: boolean) => {
    if (_switchOn) {
      setSwitchOn(true);
      setLapse('THIRTY_MINUTES');
      dispatch(setDoNotDisturb('THIRTY_MINUTES'));
    } else {
      setSwitchOn(false);
      setLapse(null);
      dispatch(clearDoNotDisturb());
    }
  };

  const updateLapse = (_lapse: string) => {
    setLapse(_lapse);
    dispatch(setDoNotDisturb(_lapse));
  };

  return (
    <DropdownSwitch>
      <i className="ri-moon-clear-line" />
      <div>
        <p>Do not disturb</p>
        {hasDoNotDisturb ? (
          <Dropdown isOpen={dropdownOpen} toggle={() => setDropDownOpen(!dropdownOpen)}>
            <DropdownLink tag="a">
              <small>Paused until {inactiveLabel}</small>
            </DropdownLink>
            <DoNotDisturbDropdownMenu>
              {choices.map(({ id, label }) => (
                <DropdownChoice
                  key={id}
                  tag="a"
                  active={doNotDisturbSettings ? id === doNotDisturbSettings.lapse : id === lapse}
                  onClick={() => updateLapse(id)}
                  disabled={disabledChoices.includes(id)}
                >
                  {label}
                </DropdownChoice>
              ))}
            </DoNotDisturbDropdownMenu>
          </Dropdown>
        ) : <small className="inactive-text">Accepting call requests</small>}
      </div>
      <OnOff active={hasDoNotDisturb} onClick={() => doNotDisturb(!switchOn)} disabled={!isEmpty(callInProgress)}>
        <span />
      </OnOff>
    </DropdownSwitch>
  );
};

export default DoNotDisturbSwitch;
