import * as moment from 'moment';
import { paths } from 'dwell/constants';
import { Action, ActionType, ReportParam } from './action-types';

export default {
  getCorporatePPCReports: (params: ReportParam, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CORPORATE_PPC_REPORTS_REQUEST,
        ActionType.GET_CORPORATE_PPC_REPORTS_SUCCESS,
        ActionType.GET_CORPORATE_PPC_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.REPORTS.CORPORATE_PPC, { params, cancelToken: token }),
    },
  }),
  getStandalonePPCReports: (params: ReportParam, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_STANDALONE_PPC_REPORTS_REQUEST,
        ActionType.GET_STANDALONE_PPC_REPORTS_SUCCESS,
        ActionType.GET_STANDALONE_PPC_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.REPORTS.STANDALONE_PPC, { params, cancelToken: token }),
    },
  }),
  getOverviewReports: (params: ReportParam, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_OVERVIEW_REPORTS_REQUEST,
        ActionType.GET_OVERVIEW_REPORTS_SUCCESS,
        ActionType.GET_OVERVIEW_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.REPORTS.OVERVIEW_REPORTS, { params, cancelToken: token }),
    },
  }),
  getMarketingReports: (params: ReportParam, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_MARKETING_REPORTS_REQUEST,
        ActionType.GET_MARKETING_REPORTS_SUCCESS,
        ActionType.GET_MARKETING_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.REPORTS.MARKETING_REPORTS, { params, cancelToken: token }),
    },
  }),
  getAgentAndOfficeReports: (params: ReportParam, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_AGENT_AND_OFFICE_REPORTS_REQUEST,
        ActionType.GET_AGENT_AND_OFFICE_REPORTS_SUCCESS,
        ActionType.GET_AGENT_AND_OFFICE_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.REPORTS.AGENT_AND_OFFICE_REPORTS, { params, cancelToken: token }),
    },
  }),
  getCreditBuilderReports: (params: ReportParam, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_CREDIT_BUILDER_REPORTS_REQUEST,
        ActionType.GET_CREDIT_BUILDER_REPORTS_SUCCESS,
        ActionType.GET_CREDIT_BUILDER_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.REPORTS.CREDIT_BUILDER_REPORTS, { params, cancelToken: token }),
    },
  }),
  getWorkforceReports: (params: ReportParam, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_WORKFORCE_REPORTS_REQUEST,
        ActionType.GET_WORKFORCE_REPORTS_SUCCESS,
        ActionType.GET_WORKFORCE_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.REPORTS.WORKFORCE_REPORTS, { params, cancelToken: token }),
    },
  }),
  getPropertiesAvailableToRescore: (params: { portfolio: number, properties: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROPERTIES_AVAILABLE_TO_RESCORE_REQUEST,
        ActionType.GET_PROPERTIES_AVAILABLE_TO_RESCORE_SUCCESS,
        ActionType.GET_PROPERTIES_AVAILABLE_TO_RESCORE_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROPERTIES_AVAILABLE_TO_RESCORE, { params }),
    },
  }),
  refreshReports: (pk: number): Action => ({
    CALL_API: {
      types: [
        ActionType.REFRESH_REPORT_REQUEST,
        ActionType.REFRESH_REPORT_SUCCESS,
        ActionType.REFRESH_REPORT_FAILURE,
      ],
      promise: client => client.post(paths.build(paths.api.v1.REPORTS.REFRESH_REPORTS, pk)),
    },
  }),
  getReportsRawData: (pk: number, field: string, raw_id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_RAW_DATA_REPORTS_REQUEST,
        ActionType.GET_RAW_DATA_REPORTS_SUCCESS,
        ActionType.GET_RAW_DATA_REPORTS_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.REPORTS.RAW_DATA, pk), { params: { field, raw_id } }),
    },
  }),
  downloadReports: (pk: number, params: { type: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.DOWNLOAD_REPORT_REQUEST,
        ActionType.DOWNLOAD_REPORT_SUCCESS,
        ActionType.DOWNLOAD_REPORT_FAILURE,
      ],
      promise: client => client.get(paths.build(paths.api.v1.REPORTS.DOWNLOAD_REPORTS, pk), { params }),
    },
  }),
  setPropertyNotAvailableToRescore: (id: number): Action => ({
    type: ActionType.SET_PROPERTY_NOT_AVAILABLE_TO_RESCORE,
    id,
  }),
  clearReports: (): Action => ({
    type: ActionType.CLEAR_REPORTS,
  }),
  setDatePeriod: (datePeriod: string): Action => ({
    type: ActionType.SET_DATE_PERIOD,
    datePeriod,
  }),
  setAttribution: (attribution: string): Action => ({
    type: ActionType.SET_ATTRIBUTION,
    attribution,
  }),
  setCompareValue: (compareValue: string): Action => ({
    type: ActionType.SET_COMPARE_VALUE,
    compareValue,
  }),
  setQueryProperties: (properties: number[]): Action => ({
    type: ActionType.SET_QUERY_PROPERTIES,
    properties,
  }),
  setPortfolio: (portfolio: number): Action => ({
    type: ActionType.SET_PORTFOLIO,
    portfolio,
  }),
  setReportType: (reportType: string): Action => ({
    type: ActionType.SET_REPORT_TYPE,
    reportType,
  }),
  setCustomDate: (startDate: moment.Moment, endDate: moment.Moment): Action => ({
    type: ActionType.SET_CUSTOM_DATE,
    startDate,
    endDate,
  }),
  setShowPaidOnly: (showPaidOnly: boolean): Action => ({
    type: ActionType.SET_SHOW_PAID_ONLY,
    showPaidOnly,
  }),
  setReloadState: (needReload: boolean): Action => ({
    type: ActionType.SET_RELOAD_STATE,
    needReload,
  }),
  setPropertiesLabel: (label: string): Action => ({
    type: ActionType.SET_PROPERTIES_LABEL,
    label,
  }),
  setAgentFilter: (agentFilter: number): Action => ({
    type: ActionType.SET_AGENT_FILTER,
    agentFilter,
  }),
};
