import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  ActiveChatsMenuDots,
  ChatsMainMenuDropdown,
  ChatsMainMenuDropdownItem, MenuDropdownIcon,
  VerticalMenuDots,
} from 'dwell/views/chat/multi_chat/styles';
import { Dropdown } from 'reactstrap';
import LeadCreationModal from 'dwell/components/leads/lead_creation_modal';
import LeadLinkingModal from 'dwell/components/leads/lead_linking_modal';
import { selectLeadNames, selectLead } from 'dwell/store/lead/reducers';
import { CallWindowMenuOptions } from 'dwell/store/voice/action-types';
import leadActions from 'dwell/store/lead/action-creators';
import callActions from 'dwell/store/voice/action-creator';

interface CallProspectSettingsProps {
  prospect: { id: number, propertyExternalId: string, property: number },
  prospectType: string,
}

const actions = {
  ADD_NEW_LEAD: <><MenuDropdownIcon className="ri-user-add-fill" />Create New Lead</>,
  LINK_TO_EXISTING_LEAD: <><MenuDropdownIcon className="ri-user-shared-fill" />Link to Existing Lead</>,
  VIEW_PROSPECT: <><MenuDropdownIcon className="ri-user-line" />View prospect</>,
};

const CallProspectSettings: FC<CallProspectSettingsProps> = ({ prospect, prospectType }) : JSX.Element => {
  const [isShowingCreationModal, setIsShowingCreationModal] = useState(false);
  const [isShowingLinkingModal, setIsShowingLinkingModal] = useState(false);
  const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState(false);
  const [leadCreatedId, setLeadCreatedId] = useState(null);

  const leadNames = useSelector(selectLeadNames);
  const recentCreatedLead = useSelector(selectLead);

  const { getPropertyLeadsNames } = leadActions;

  const dispatch = useDispatch();
  const { push } = useHistory();

  const { updateInProgressCall } = callActions;

  useEffect(() => {
    if (isEmpty(leadNames)) {
      dispatch(getPropertyLeadsNames({ property: prospect.property }));
    }
  }, []);

  useEffect(() => {
    if (leadCreatedId) {
      const createdLead = leadNames.find(lead => lead.id === leadCreatedId);
      if (createdLead) {
        dispatch(updateInProgressCall({ prospectName: createdLead.name, prospectType: 'Prospect', leadId: leadCreatedId }));
        setIsShowingCreationModal(false);
        push(`/${prospect.propertyExternalId}/leads/${leadCreatedId}`);
      } else if (recentCreatedLead.id === leadCreatedId) {
        dispatch(updateInProgressCall({ prospectName: `${recentCreatedLead.first_name} ${recentCreatedLead.last_name}`, prospectType: 'Prospect', leadId: leadCreatedId }));
        setIsShowingCreationModal(false);
        push(`/${prospect.propertyExternalId}/leads/${leadCreatedId}`);
      }
    }
  }, [leadNames]);

  const closeLeadLinkingModal = (leadId: number) => {
    if (leadId) {
      const linkedTo = leadNames.find(lead => lead.id === leadId);
      dispatch(updateInProgressCall({ prospectName: linkedTo.name, prospectType: 'Prospect', leadId }));
    }
    setIsShowingLinkingModal(false);
  };

  const closeLeadCreationModal = (leadId: number) => {
    if (leadId) {
      setLeadCreatedId(leadId);
    } else {
      setIsShowingCreationModal(false);
    }
  };

  const runAction = (event, key) => {
    event.stopPropagation();
    switch (key) {
      case CallWindowMenuOptions.ADD_NEW_LEAD:
        setIsShowingCreationModal(true);
        break;
      case CallWindowMenuOptions.LINK_TO_EXISTING_LEAD:
        setIsShowingLinkingModal(true);
        break;
      case CallWindowMenuOptions.VIEW_PROSPECT:
        push(`/${prospect.propertyExternalId}/leads/${prospect.id}`);
        break;
      default:
        break;
    }
    setIsMenuDropdownOpen(false);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isMenuDropdownOpen}
        toggle={(e) => {
          e.stopPropagation();
          setIsMenuDropdownOpen(!isMenuDropdownOpen);
        }}
      >
        <ActiveChatsMenuDots
          tag="div"
          data-toggle="dropdown"
          aria-expanded={isMenuDropdownOpen}
        >
          <VerticalMenuDots />
        </ActiveChatsMenuDots>
        <ChatsMainMenuDropdown right>
          {prospectType === 'New Lead' && ['ADD_NEW_LEAD', 'LINK_TO_EXISTING_LEAD']
            .map(key => <ChatsMainMenuDropdownItem className="dropdown-item" onClick={event => runAction(event, key)} key={key}>{actions[key]}</ChatsMainMenuDropdownItem>)}
          {prospectType === 'Prospect' && <ChatsMainMenuDropdownItem className="dropdown-item" onClick={event => runAction(event, 'VIEW_PROSPECT')}>{actions.VIEW_PROSPECT}</ChatsMainMenuDropdownItem>}
        </ChatsMainMenuDropdown>
      </Dropdown>
      <LeadLinkingModal
        show={isShowingLinkingModal}
        property={prospect.property}
        handleClose={closeLeadLinkingModal}
      />
      <LeadCreationModal
        show={isShowingCreationModal}
        initialProperty={prospect.property}
        shouldNotRedirect
        handleClose={closeLeadCreationModal}
      />
    </React.Fragment>);
};

export default CallProspectSettings;
