import produce from 'immer';
import { Action, ActionType, CompetitorProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface CompetitorState extends CommonStateType {
  competitors: CompetitorProps[];
}

export const initialState: CompetitorState = {
  errorMessage: null,
  competitors: [],
};

export default produce((state: CompetitorState = initialState, action: Action): CompetitorState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_COMPETITORS_SUCCESS:
      state.competitors = action.result.data.results;
      break;

    case ActionType.UPDATE_COMPETITOR_SUCCESS: {
      const updateCompetitorsList = state.competitors.map(competitor => (competitor.id === action.result.data.id ? action.result.data : competitor));
      state.competitors = updateCompetitorsList;
      break;
    }

    case ActionType.DELETE_COMPETITOR_SUCCESS: {
      const updateCompetitorsList = state.competitors.filter(competitor => competitor.id !== action.result.data.id);
      state.competitors = updateCompetitorsList;
      break;
    }

    case ActionType.CREATE_COMPETITOR_SUCCESS: {
      const updateCompetitorsList = [...state.competitors];
      updateCompetitorsList.push(action.result.data);
      state.competitors = updateCompetitorsList;
      break;
    }
  }

  return state;
});
