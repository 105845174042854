import { paths } from 'corporate/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, Section } from './action-types';

export default {
  updateCorporateSection: (id: number, section: Section): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_CORPORATE_SECTION_REQUEST,
        ActionType.UPDATE_CORPORATE_SECTION_SUCCESS,
        ActionType.UPDATE_CORPORATE_SECTION_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.EDIT_CORPORATE_SITE.UPDATE_SECTION, id), section),
    },
  }),
  updateSubsiteSection: (id: number, section: Section): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_SUBSITE_SECTION_REQUEST,
        ActionType.UPDATE_SUBSITE_SECTION_SUCCESS,
        ActionType.UPDATE_SUBSITE_SECTION_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.EDIT_SUBSITE.UPDATE_SECTION, id), section),
    },
  }),
  setSections: (sections: Section[]): Action => ({
    type: ActionType.SET_SECTIONS,
    sections,
  }),
  closeAddNewSectionPanel: (): Action => ({
    type: ActionType.CLOSE_ADD_NEW_SECTION_PANEL,
  }),
  showDeleteSectionModal: (): Action => ({
    type: ActionType.SHOW_DELETE_SECTION_MODAL,
  }),
  hideDeleteSectionModal: (): Action => ({
    type: ActionType.HIDE_DELETE_SECTION_MODAL,
  }),
  toggleCustomStylesSectionPanel: (): Action => ({
    type: ActionType.SHOW_CUSTOM_STYLES_SECTION,
  }),
  setSectionToDelete: (section: Section): Action => ({
    type: ActionType.SET_SECTION_TO_DELETE,
    section,
  }),
  clearSectionToDelete: (): Action => ({
    type: ActionType.CLEAR_SECTION_TO_DELETE,
  }),
  setActiveSection: (sectionId: number): Action => ({
    type: ActionType.SET_SECTION,
    sectionId,
  }),
  toggleAddNewSectionPanel: (): Action => ({
    type: ActionType.TOGGLE_ADD_NEW_SECTION_PANEL,
  }),
  clearActiveSection: (): Action => ({
    type: ActionType.CLEAR_SECTION,
  }),
  addCorporateSection: (page: number, sectionType: string, type: string): Action => ({
    CALL_API: {
      types: [
        ActionType.ADD_CORPORATE_SECTION_REQUEST,
        ActionType.ADD_CORPORATE_SECTION_SUCCESS,
        ActionType.ADD_CORPORATE_SECTION_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_CORPORATE_SITE.NEW_SECTION, { section_type: sectionType, type, page }),
    },
  }),
  addSubSiteSection: (page: number, sectionType: string, type: string): Action => ({
    CALL_API: {
      types: [
        ActionType.ADD_SUBSITE_SECTION_REQUEST,
        ActionType.ADD_SUBSITE_SECTION_SUCCESS,
        ActionType.ADD_SUBSITE_SECTION_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_SUBSITE.NEW_SECTION, { section_type: sectionType, type, page }),
    },
  }),
  deleteCorporateSection: (section: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_CORPORATE_SECTION_REQUEST,
        ActionType.DELETE_CORPORATE_SECTION_SUCCESS,
        ActionType.DELETE_CORPORATE_SECTION_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_CORPORATE_SITE.DELETE_SECTION, { section }),
    },
  }),
  deleteSubSiteSection: (section: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_SUBSITE_SECTION_REQUEST,
        ActionType.DELETE_SUBSITE_SECTION_SUCCESS,
        ActionType.DELETE_SUBSITE_SECTION_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_SUBSITE.DELETE_SECTION, { section }),
    },
  }),
  changeOrderCorporateSection: (change_from: number, change_to: number = null, direction: string = null): Action => ({
    CALL_API: {
      types: [
        ActionType.CHANGE_ORDER_CORPORATE_SECTION_REQUEST,
        ActionType.CHANGE_ORDER_CORPORATE_SECTION_SUCCESS,
        ActionType.CHANGE_ORDER_CORPORATE_SECTION_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_CORPORATE_SITE.CHANGE_ORDER, { change_from, change_to, direction }),
    },
  }),
  changeOrderSubSiteSection: (change_from: number, change_to: number = null, direction: string = null): Action => ({
    CALL_API: {
      types: [
        ActionType.CHANGE_ORDER_SUBSITE_SECTION_REQUEST,
        ActionType.CHANGE_ORDER_SUBSITE_SECTION_SUCCESS,
        ActionType.CHANGE_ORDER_SUBSITE_SECTION_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.EDIT_SUBSITE.CHANGE_ORDER, { change_from, change_to, direction }),
    },
  }),
};
