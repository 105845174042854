import { PromiseActionType } from 'dwell/store/types';
import { LoginAction, LogoutAction, SessionTimeoutAction } from 'dwell/store/authentication/action-types';

export enum ActionType {
  GET_FOCUS_BAR_LEADS_SUCCESS = 'GET_FOCUS_BAR_LEADS_SUCCESS',
  GET_FOCUS_BAR_LEADS_REQUEST = 'GET_FOCUS_BAR_LEADS_REQUEST',
  GET_FOCUS_BAR_LEADS_FAILURE = 'GET_FOCUS_BAR_LEADS_FAILURE',

  RELOAD_FOCUS_BAR_DATA = 'RELOAD_FOCUS_BAR_DATA',
  RESET_FOCUS_BAR = 'RESET_FOCUS_BAR',
  SET_FOCUS_BAR_INDEX = 'SET_FOCUS_BAR_INDEX',
  SET_FOCUS_BAR_ITEMS = 'SET_FOCUS_BAR_ITEMS',
}

interface ParamFields {
  after?: number;
  field?: string;
  filter_id?: string;
  leadId?: number;
  order?: string;
  search?: string;
}

export interface FocusBarItem {
  id?: number;
  lead: number;
  property: string;
}

export interface FocusBarState {
  isLoaded: boolean;
  from: string;
  index: number;
  items: FocusBarItem[];
  leadQuery: ParamFields;
  title: string;
}

export interface GetLeadsDataRequestsProps extends ParamFields {
  append?: boolean;
  backupLeads: FocusBarItem[];
  from: string;
  title: string;
}

interface GetLeadsDataAction {
  type: ActionType.GET_FOCUS_BAR_LEADS_REQUEST
  | ActionType.GET_FOCUS_BAR_LEADS_SUCCESS
  | ActionType.GET_FOCUS_BAR_LEADS_FAILURE;
  result: {
    config: { params: ParamFields },
    data: FocusBarItem[],
  },
  payload: {
    append: boolean;
    from: string;
    backupLeads: FocusBarItem[],
    leadId: number,
    title: string;
  },
}

interface ReloadFocusBarData {
  type: ActionType.RELOAD_FOCUS_BAR_DATA,
}

interface ResetFocusBarAction {
  type: ActionType.RESET_FOCUS_BAR;
}

interface SetIndexAction {
  type: ActionType.SET_FOCUS_BAR_INDEX;
  index: number;
}

interface SetItemsAction {
  type: ActionType.SET_FOCUS_BAR_ITEMS;
  from: string;
  index: number;
  items: FocusBarItem[];
  title: string;
}

export type Action =
  | PromiseActionType
  | LoginAction
  | LogoutAction
  | SessionTimeoutAction
  | GetLeadsDataAction
  | ReloadFocusBarData
  | ResetFocusBarAction
  | SetIndexAction
  | SetItemsAction;
