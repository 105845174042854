import produce from 'immer';
import { Action, ActionType, TargetNumberProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface TargetNumberState extends CommonStateType {
  targetNumbers: TargetNumberProps[];
}

export const initialState: TargetNumberState = {
  isSubmitting: false,
  isLoaded: false,
  errorMessage: null,
  targetNumbers: [],
};

export const selectTargetNumbers = (state: { targetNumber: TargetNumberState }): TargetNumberProps[] => state.targetNumber.targetNumbers;
export const selectIsLoaded = (state: { targetNumber: TargetNumberState }): boolean => state.targetNumber.isLoaded;

export default produce((state: TargetNumberState = initialState, action: Action): TargetNumberState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_TARGET_NUMBERS_SUCCESS:
      state.targetNumbers = action.result.data.results;
      break;

    case ActionType.GET_PROPERTY_TARGET_NUMBER_SUCCESS:
      state.targetNumbers = action.result.data.results;
      break;

    case ActionType.UPDATE_TARGET_NUMBER_SUCCESS:
      state.targetNumbers = state.targetNumbers.map(item => (item.id === action.result.data.id ? action.result.data : item));
      break;

    case ActionType.CREATE_TARGET_NUMBER_SUCCESS:
      state.targetNumbers = state.targetNumbers.concat(action.result.data);
      break;

    case ActionType.DELETE_TARGET_NUMBER_SUCCESS:
      state.targetNumbers = state.targetNumbers.filter(item => item.id !== action.result.data.id);
      break;
  }

  return state;
});
