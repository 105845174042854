import produce from 'immer';
import { orderBy, unionBy } from 'lodash';
import { ActionType as LeadActionType } from 'dwell/store/lead/action-types';
import { ActionType as LeaseActionType } from 'resident/store/lease/action-types';
import { ActionType as PusherActionType, PusherAction } from 'dwell/store/pusher/action-types';
import { Action, ActionType, NoteProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState, isLeadPage, isLeadsObject } from '../utils';

export interface NoteState extends CommonStateType {
  notes: NoteProps[];
  note: NoteProps;
  count: number;
}

export const initialState: NoteState = {
  isSubmitting: false,
  errorMessage: null,
  notes: [],
  note: {} as NoteProps,
  count: 0,
};

export default produce((state: NoteState = initialState, action: Action): NoteState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'note') {
    return state;
  }
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case LeadActionType.GET_LEAD_BY_ID_SUCCESS:
      state.notes = action.result.data.notes;
      break;

    case LeaseActionType.GET_LEASE_DETAILS_SUCCESS:
      state.notes = action.result.data.notes;
      break;

    case ActionType.DELETE_NOTE_SUCCESS: {
      state.notes = state.notes.filter(note => note.id !== action.result.data.id);
      break;
    }

    case ActionType.UPDATE_NOTE_SUCCESS:
      state.notes = orderBy(unionBy([action.result.data], state.notes, 'id'), ['updated'], ['desc']);
      break;

    case ActionType.CREATE_NOTE_SUCCESS: {
      state.notes = [action.result.data].concat([...state.notes]);
      break;
    }

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = action.row as NoteProps;
      let newNotes = state.notes;
      if (isLeadPage() && isLeadsObject(row.lead?.toString()) && !state.notes.find(i => i.id === row.id)) {
        newNotes = orderBy(unionBy([row], state.notes, 'id'), ['updated'], ['desc']);
      }
      state.notes = newNotes;
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as NoteProps;
      let newNotes = state.notes;
      if (isLeadPage() && isLeadsObject(row.lead?.toString())) {
        newNotes = orderBy(unionBy([row], state.notes, 'id'), ['updated'], ['desc']);
      }
      state.notes = newNotes;
      state.note = state.note.id === row.id ? row : state.note;
      break;
    }

    case PusherActionType.PUSHER_DELETE_RECORD: {
      const row = action.row as NoteProps;
      let newNotes = state.notes;
      if (isLeadPage() && isLeadsObject(row.lead?.toString())) {
        newNotes = state.notes.filter(o => o.id.toString() !== row.id.toString());
      }
      state.notes = newNotes;
      break;
    }
  }

  return state;
});
