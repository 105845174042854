import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LineSkeleton } from 'src/utils';
import actions from 'resident/store/actions';
import {
  OwnerDropdownMenu, StageDropdown, DropdownLink, CustomDropdownItem,
} from 'dwell/views/lead/layout/key_info/style';

interface DropdownTypes {
  choices: { [key: string]: string },
  field: string,
}

const Dropdown: FC<DropdownTypes> = ({ choices, field }) => {
  const dispatch = useDispatch();
  const [isOpen, open] = useState(false);
  const { updateLease } = actions.leaseDetails;
  const leaseDetails = useSelector(state => state.leaseDetails.leaseDetails);

  const onSave = (value) => {
    dispatch(updateLease(leaseDetails.id, { [field]: value }));
  };
  const value = leaseDetails[field];

  return (
    <>
      {leaseDetails.id ?
        <StageDropdown isOpen={isOpen} toggle={() => open(!isOpen)}>
          <DropdownLink caret className={choices[value] ? choices[value].toLowerCase() : 'bg-white'}>
            {value ? choices[value] : `Select ${field}`}
          </DropdownLink>
          <OwnerDropdownMenu>
            {Object.keys(choices).map((key, index) => (
              <React.Fragment key={index}>
                <CustomDropdownItem onClick={() => onSave(key)} className={key.toLowerCase()}>
                  {choices[key]}
                </CustomDropdownItem>
              </React.Fragment>))}
          </OwnerDropdownMenu>
        </StageDropdown> : <LineSkeleton width={150} height={38} />}
    </>
  );
};

export default Dropdown;
