import React, { FC } from 'react';
import cn from 'classnames';
import { currencyFormat } from 'compete/constants';
import { SisterCommunitiesTypes } from 'dwell/store/property_profile/action-types';
import { Link } from 'dwell/views/property_profile/neighborhood/styles';
import { formatUrl } from 'src/utils';
import { Label, Badge } from './styles';
import { Content, Card, CardBody, Table } from '../styles';

interface SisterCommunitiesCardProps {
  sisterCommunities: SisterCommunitiesTypes[],
  clipboard?: React.ReactNode,
}

const SisterCommunitiesCard: FC<SisterCommunitiesCardProps> = ({ sisterCommunities, clipboard }) => (
  <Content>
    <h6 className="tx-16 text-dark"># Nearby</h6>
    {sisterCommunities.map((community, index) => (
      <Card className={cn({ 'mg-t-15': index !== 0 })} data-testid={`sister-community-${index}`}>
        <CardBody>
          <div className="d-flex align-items-center mg-b-5">
            <i className="ri-community-fill tx-18 mg-r-5 lh-1" />
            <h6 className="mg-b-0">{community.name}</h6>
            <Link className="d-flex mg-l-auto tx-16 lh-1" href={formatUrl(community.domain)} target="_blank">
              <i className="ri-external-link-fill" />
            </Link>
          </div>
          <Label className="tx-12" style={{ marginBottom: 10 }}>{community.community_address}</Label>
          <Label className="d-flex align-items-center tx-12">
            <div className="d-flex align-items-center">
              <i className="ri-cellphone-fill mr-1" />
              {community.phone_number}
            </div>
            <div className="d-flex align-items-center mg-l-20">
              <i className="ri-pin-distance-fill mr-1" />
              {community.distance_from_property} mi from property
            </div>
          </Label>
        </CardBody>
        <CardBody>
          <h6 className="mg-b-10">Unit Types Offered</h6>
          <div className="d-flex align-items-center">
            {Object.keys(community.unit_types).map(unit_type => <Badge>{unit_type === '0' ? 'Studio' : `${unit_type} Bed`}</Badge>)}
          </div>
        </CardBody>
        <CardBody>
          <h6 className="mg-b-10">Available Units ({Object.values(community.unit_types).reduce((prev, curr) => prev + curr.count, 0)})</h6>
          <Table className="table table-pp table-bd">
            <thead>
              <tr>
                <th>Unit Type</th>
                <th>Starting At</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(community.unit_types).map(unit_type => (
                <tr>
                  <td>{unit_type === '0' ? 'Studio' : `${unit_type} Bed`}</td>
                  <td>${currencyFormat(community.unit_types[unit_type].starting_at)}</td>
                  <td>{community.unit_types[unit_type].count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        {clipboard}
      </Card>
    ))}
  </Content>
);

export default SisterCommunitiesCard;
