import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { fieldChoices } from 'dwell/constants';
import { startCase } from 'lodash';
import { Content } from '../styles';

const Amenities: FC = () => {
  const amenities = useSelector(state => state.propertyProfile.amenities);
  const formatTimeStr = str => (str.includes('AM') || str.includes('PM') ? str.replace(/ /g, '').toLowerCase() : str);
  const utilities = amenities.utilities.map(i => startCase(fieldChoices.PROPERTY_POLICY_FILED_CHOICES.utilities[i])).join(', ');

  return (
    <Content>
      <h6 className="tx-16 text-dark"># Community Features</h6>
      {amenities.community.map((item, index) => (
        <p key={index}><strong>{item.category}:</strong> {item.name}</p>
      ))}
      <hr />

      <h6 className="tx-16 text-dark"># Apartment Features</h6>
      {amenities.apartment.map((item, index) => (
        <p key={index}><strong>{item.category}:</strong> {item.name}</p>
      ))}

      <hr />

      <h6 className="tx-16 text-dark"># Amenity Hours</h6>
      <p><strong>Clubhouse:</strong> {formatTimeStr(amenities.hours.clubhouse)}</p>
      <p><strong>Fitness Center:</strong> {formatTimeStr(amenities.hours.fitness_center)}</p>
      <p><strong>Pool:</strong> {formatTimeStr(amenities.hours.pool)}</p>
      <p><strong>Community Quiet Hours:</strong> {formatTimeStr(amenities.hours.community_quiet_hours)}</p>
      <p><strong>Moving &amp; Transfer:</strong> {formatTimeStr(amenities.hours.moving_and_transfer)}</p>

      <hr />

      <h6 className="tx-16 text-dark"># Parking</h6>
      <p><strong>Residents:</strong> {amenities.parking.residents}</p>
      <p><strong>Guests:</strong> {amenities.parking.guests}</p>

      <hr />

      <h6 className="tx-16 text-dark"># Utilities</h6>
      <p>
        <strong>Utility Billing Policy:</strong> Resident is responsible for {utilities}
      </p>
    </Content>
  );
};

export default Amenities;
