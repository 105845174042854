import { api, build } from 'leasing/constants/paths';
import { Action, ActionType } from './action-types';

export default {
  addAddenda: (data:{ids: number[], category?: string[], isMaster: boolean}): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_ADDENDA_REQUEST,
        ActionType.CREATE_ADDENDA_SUCCESS,
        ActionType.CREATE_ADDENDA_FAILURE,
      ],
      promise: client => client.post(api.v1.CREATE_ADDENDA, data),
    },
  }),

  getAddenda: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ADDENDA_REQUEST,
        ActionType.GET_ADDENDA_SUCCESS,
        ActionType.GET_ADDENDA_FAILURE,
      ],
      promise: client => client.get(api.v1.ADDENDA),
    },
  }),

  removeIncludedInAll: (data:{ids: number[], filter: string}): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_INCLUDE_IN_ALL_REQUEST,
        ActionType.DELETE_INCLUDE_IN_ALL_SUCCESS,
        ActionType.DELETE_INCLUDE_IN_ALL_FAILURE,
      ],
      promise: client => client.post(api.v1.LEASE_DOCUMENTS_DELETE_INCLUDE_IN_ALL, data),
    },
  }),

  updateMasterLeaseDocument: (id: number, data: {lease_document: number}): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_MASTER_LEASE_DOCUMENT_REQUEST,
        ActionType.UPDATE_MASTER_LEASE_DOCUMENT_SUCCESS,
        ActionType.UPDATE_MASTER_LEASE_DOCUMENT_FAILURE,
      ],
      promise: client => client.patch(build(api.v1.UPDATE_MASTER_LEASE_DOCUMENT, id), data),
    },
  }),

  getAddendaDocumentsChoices: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ADDENDA_DOCUMENTS_CHOICES_REQUEST,
        ActionType.GET_ADDENDA_DOCUMENTS_CHOICES_SUCCESS,
        ActionType.GET_ADDENDA_DOCUMENTS_CHOICES_FAILURE,
      ],
      promise: client => client.get(api.v1.ADDENDA_DOCUMENTS_CHOICES),
    },
  }),
};
