import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from 'dwell/actions/index';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectPostSelectedProperties } from 'dwell/store/properties_selector/reducers';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import { RECENT_PROPERTY_HYPHENS } from 'dwell/constants';

import { PMItem, PMFormCheckbox, PMLogo, PMBody, NBOnly, NPILink, PropertyItemLogoImg, NavPI } from 'containers/multi_property_selector/styles';

interface SelectedPropertyItemProps {
  property: PropertyProps;
}

const SelectedPropertyItem: FC<SelectedPropertyItemProps> = ({ property }) => {
  const [showBtnOnly, setShowBtnOnly] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const currentUser = useSelector(selectCurrentUser);
  const selectedProperties = useSelector(selectSelectedProperties);
  const postSelectedProperties = useSelector(selectPostSelectedProperties);
  const dispatch = useDispatch();
  const { updateUserAccessedProperties } = actions.user;
  const { setShowSelector, setPostSelectedProperties, setPropertyKeyword, setPreSelectedProperties } = actions.propertiesSelector;
  const { setChatRenderedStatus, clearActiveChats } = actions.prospectChat;

  const isSelected = postSelectedProperties.some(p => p.id === property.id);

  const btnOnlyClickHandler = (siteId) => {
    localStorage.setItem(RECENT_PROPERTY_HYPHENS, siteId);
    dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: [property] }, () => 0)).then(() => {
      document.body.classList.remove('scroll_locked');
      dispatch(setPreSelectedProperties([]));
      dispatch(clearActiveChats());
      dispatch(setChatRenderedStatus(true));
      dispatch(setShowSelector(false));
      dispatch(setPropertyKeyword(''));
    });
  };

  const selectPropertyHandler = (status: boolean) => {
    if (status) {
      if (!postSelectedProperties.includes(property)) {
        const newStateInSelection = [...postSelectedProperties, property];
        dispatch(setPostSelectedProperties(newStateInSelection));
      }
    } else if (postSelectedProperties.length !== 1) {
      const newStateInSelection = postSelectedProperties.filter(item => item.id !== property.id);
      dispatch(setPostSelectedProperties(newStateInSelection));
    }
  };

  useEffect(() => {
    if (isSelected !== isChecked) {
      setIsChecked(isSelected);
    }
  }, [isSelected, isChecked]);

  return (
    <PMItem onMouseEnter={() => setShowBtnOnly(true)} onMouseLeave={() => setShowBtnOnly(false)}>
      <PMFormCheckbox className="mg-r-10" hide={selectedProperties.length === 1} disabled={postSelectedProperties.length === 1} checked={isChecked} onClick={() => selectPropertyHandler(!isChecked)} />
      <PMLogo onClick={() => selectPropertyHandler(!isChecked)}>{property && property.logo ? <PropertyItemLogoImg src={property.logo} alt="PLogo" /> : <i className="ri-home-8-line" />}</PMLogo>
      <PMBody onClick={() => selectPropertyHandler(!isChecked)}>
        <h6 data-initial="SP">
          <span>{property.name}</span>
        </h6>
        {property.status === 'ACTIVE' ? <p>{property.town}</p> : <p>{`${property.status}`}</p> }
      </PMBody>
      <NavPI show={showBtnOnly}>
        <NBOnly show={showBtnOnly && selectedProperties.length > 1} onClick={() => btnOnlyClickHandler(property.external_id)}>
          Only
        </NBOnly>
        <NPILink id={`site-${property.id}`} href={`http://${property.domain}`} title="Visit site" target="_blank" onClick={e => e.stopPropagation()}>
          <i className="ri-external-link-line" />
        </NPILink>
      </NavPI>
    </PMItem>
  );
};

export default SelectedPropertyItem;
