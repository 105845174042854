import produce from 'immer';
import { sortBy } from 'lodash';
import { CommonStateType } from 'dwell/store/types';
import { selectBusinessHours } from 'dwell/store/business_hours/reducers';
import { selectProperty } from 'dwell/store/property/reducers';
import { LeaseDocumentProps, LeaseDocumentState } from 'leasing/store/lease_document/action-types';
import { selectAddendaFields, selectCleaningChargesItems, selectLeaseDefault, selectPropertyPolicy, selectUtilityPolicy } from 'leasing/store/settings/reducers';
import { selectDepositAgreementDocument, selectLeaseAgreementDocument } from 'leasing/store/lease_document/reducers';
import { ActionType as DocumentActionType } from 'leasing/store/document/action-types';
import { Action, ActionType, ApplicationDetailsProps, ApplicantProps } from './action-types';

export interface ApplicationState extends CommonStateType {
  applicationDetails: ApplicationDetailsProps,
  applicant: ApplicantProps,
  shouldReload: boolean,
  isExpanded: boolean,
}

const applicationDefault = {
  pets: [],
  garages: [],
  storages: [],
  parking: [],
  other_rent_items: [],
  gate_keys: [],
  applicants_group: [],
};

export const initialState: ApplicationState = {
  isSubmitting: false,
  isLoaded: false,
  shouldReload: false,
  isExpanded: false,
  applicationDetails: applicationDefault as ApplicationDetailsProps,
  applicant: {} as ApplicantProps,
};

export const selectApplicationDetails = (state: { application: ApplicationState }): ApplicationDetailsProps => state.application.applicationDetails;

export const selectApplicants = (state: { application: ApplicationState }): ApplicantProps[] => {
  const sortOrder = {
    PRIMARY_APPLICANT: 1,
    CO_APPLICANT: 2,
    GUARANTOR: 3,
    DEPENDENT: 4,
  };
  return sortBy((state.application.applicationDetails.applicants_group || []), item => sortOrder[item.type]);
};

export const selectCurrentApplicants = (state: { application: ApplicationState }): ApplicantProps[] => selectApplicants(state).filter(a => a.status !== 'LOST');

export const selectApplicationIsLoaded = (state: { application: ApplicationState }): boolean => state.application.isLoaded;
export const selectHasApplicantSubmitted = (state: { application: ApplicationState }):boolean =>
  state.application.applicationDetails.applicants_group.some(applicant => applicant.activities?.find(activity => activity?.step === 'SUBMIT_APPLICATION'));

export const selectHasAllApplicantsSubmitted = (state: { application: ApplicationState }):boolean =>
  state.application.applicationDetails.applicants_group.every(applicant => applicant.activities?.find(activity => activity?.step === 'SUBMIT_APPLICATION'));

export const selectApplication = (state: { application: ApplicationState }): ApplicationState => state.application;
export const selectShouldReload = (state: { application: ApplicationState }): boolean => state.application.shouldReload;
export const selectIsExpanded = (state: { application: ApplicationState }): boolean => state.application.isExpanded;
export const selectLeaseAddendumDocuments = (state: { application: ApplicationState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.application.applicationDetails.addenda || []).includes(i.id));
export const selectDepositAddendumDocuments = (state: { application: ApplicationState, leaseDocument: LeaseDocumentState }): LeaseDocumentProps[] =>
  state.leaseDocument.leaseDocuments.filter(i => (state.application.applicationDetails.deposit_agreement_addenda || []).includes(i.id));

const getLeasingInfo = (state) => {
  const application = state.application.applicationDetails;
  const {
    unit, pets, garages, storages, vehicles, parking, other_rent_items: otherRentItems,
    payment_received: payment, concessions, gate_keys: gateKeys,
  } = application;
  const currentApplicants = application.applicants_group
    .filter(el => !['LOST', 'FORMER'].includes(el.status))
    .filter(el => el?.activities.map(i => (i.date ? i.step : null)).includes('SUBMIT_APPLICATION'));

  return {
    applicants: currentApplicants.filter(user => ['PRIMARY_APPLICANT', 'CO_APPLICANT'].includes(user.type)),
    guarantors: currentApplicants.filter(user => user.type === 'GUARANTOR'),
    unitName: unit,
    lease: application,
    payment: payment || {},
    pets,
    garages,
    storages,
    vehicles,
    parking,
    otherRentItems,
    concessions,
    gateKeys,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectLeasingDetails = state => ({
  ...getLeasingInfo(state),
  addendaFields: selectAddendaFields(state),
  leaseDefault: selectLeaseDefault(state),
  propertyPolicy: selectPropertyPolicy(state),
  utilityPolicies: selectUtilityPolicy(state),
  businessHours: selectBusinessHours(state),
  property: selectProperty(state),
  leaseAddendumDocuments: selectLeaseAddendumDocuments(state),
  depositAddendumDocuments: selectDepositAddendumDocuments(state),
  leaseAgreementDocument: selectLeaseAgreementDocument(state),
  depositAgreementDocument: selectDepositAgreementDocument(state),
  cleaningCharges: selectCleaningChargesItems(state),
});

export default produce((state: ApplicationState = initialState, action: Action): ApplicationState => {
  switch (action.type) {
    case ActionType.GET_APPLICATION_DETAILS_REQUEST:
      state.isLoaded = false;
      break;

    case ActionType.GET_APPLICATION_DETAILS_SUCCESS:
      state.isLoaded = true;
      state.applicationDetails = action.result.data;
      break;

    case ActionType.UPDATE_APPLICATION_SUCCESS:
      if (state.applicationDetails?.id === action.result.data?.id) {
        state.isLoaded = true;
        state.applicationDetails = { ...state.applicationDetails, ...action.result.data };
      }
      break;

    case ActionType.SET_APPLICANT:
      state.applicant = action.applicant;
      break;

    case ActionType.SET_RELOAD_STATE:
      state.shouldReload = action.shouldReload;
      break;

    case DocumentActionType.GENERATE_AGREEMENT_DOCUMENT_SUCCESS:
      state.applicationDetails = { ...state.applicationDetails, is_generated_agreement_documents: true };
      break;

    case ActionType.TOGGLE_EXPAND_MODE:
      state.isExpanded = !state.isExpanded;
      break;
  }

  return state;
});
