import React, { FC } from 'react';
import { CheckBox as CheckBoxBase } from 'site/components';

interface CheckBoxProps {
  id: string,
  checked: boolean,
  onChange: (id: string, value: boolean) => void,
  name?: string,
  label: string,
  disabled?: boolean,
}

const CheckBox: FC<CheckBoxProps> = ({ id, checked, onChange, name, label, disabled }) => (
  <>
    <CheckBoxBase
      id={id}
      name={name}
      label={label}
      checked={checked}
      onChange={() => onChange(id, !checked)}
      disabled={disabled}
    />
  </>
);

export default CheckBox;
