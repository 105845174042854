import { api, build } from 'leasing/constants/paths';
import { ManageRequestProps } from 'src/interfaces';
import { Action, ActionType, DocumentProps } from './action-types';

export default {
  bulkSignDocuments: (ids: number[]): Action => ({
    CALL_API: {
      types: [
        ActionType.BULK_COUNTERSIGN_DOCUMENTS_REQUEST,
        ActionType.BULK_COUNTERSIGN_DOCUMENTS_SUCCESS,
        ActionType.BULK_COUNTERSIGN_DOCUMENTS_FAILURE,
      ],
      promise: client => client.post(api.v1.BULK_SIGN, { ids }),
    },
  }),

  deleteDocument: (leaseId: number, documentId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_DOCUMENT_REQUEST,
        ActionType.DELETE_DOCUMENT_SUCCESS,
        ActionType.DELETE_DOCUMENT_FAILURE,
      ],
      promise: client => client.delete(build(api.v1.DOCUMENT_BY_ID, leaseId, documentId)),
    },
  }),

  getBulkSignDocuments: (params: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_BULK_COUNTERSIGN_DOCUMENTS_REQUEST,
        ActionType.GET_BULK_COUNTERSIGN_DOCUMENTS_SUCCESS,
        ActionType.GET_BULK_COUNTERSIGN_DOCUMENTS_FAILURE,
      ],
      promise: client => client.get(api.v1.BULK_SIGN, { params }),
    },
  }),

  getDocuments: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_DOCUMENTS_REQUEST,
        ActionType.GET_DOCUMENTS_SUCCESS,
        ActionType.GET_DOCUMENTS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.DOCUMENTS, leaseId)),
    },
  }),

  getAllDocuments: (leaseId: number, params: { limit: string, offset: string }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_ALL_DOCUMENTS_REQUEST,
        ActionType.GET_ALL_DOCUMENTS_SUCCESS,
        ActionType.GET_ALL_DOCUMENTS_FAILURE,
      ],
      promise: client => client.get(build(api.v1.DOCUMENTS, leaseId), { params }),
    },
  }),

  sendDocument: (leaseId: number, body: DocumentProps): Action => ({
    CALL_API: {
      types: [
        ActionType.SEND_DOCUMENT_REQUEST,
        ActionType.SEND_DOCUMENT_SUCCESS,
        ActionType.SEND_DOCUMENT_FAILURE,
      ],
      promise: client => client.post(build(api.v1.SEND_DOCUMENT, leaseId), body),
    },
  }),

  signDocument: (leaseId: number, documentId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.SIGN_DOCUMENT_REQUEST,
        ActionType.SIGN_DOCUMENT_SUCCESS,
        ActionType.SIGN_DOCUMENT_FAILURE,
      ],
      promise: client => client.post(build(api.v1.SIGN_DOCUMENT, leaseId, documentId)),
    },
  }),

  updateApplicationForm: (leaseId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_APPLICATION_FORM_REQUEST,
        ActionType.UPDATE_APPLICATION_FORM_SUCCESS,
        ActionType.UPDATE_APPLICATION_FORM_FAILURE,
      ],
      promise: client => client.post(build(api.v1.UPDATE_APPLICATION_FORM, leaseId)),
    },
  }),

  uploadDocuments: (leaseId: number, data: FormData): Action => ({
    CALL_API: {
      types: [
        ActionType.UPLOAD_DOCUMENTS_REQUEST,
        ActionType.UPLOAD_DOCUMENTS_SUCCESS,
        ActionType.UPLOAD_DOCUMENTS_FAILURE,
      ],
      promise: client => client.post(build(api.v1.UPLOAD_DOCUMENTS, leaseId), data),
    },
  }),

  generateAgreements: (leaseId: number, body: { documents: DocumentProps[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.GENERATE_AGREEMENT_DOCUMENT_REQUEST,
        ActionType.GENERATE_AGREEMENT_DOCUMENT_SUCCESS,
        ActionType.GENERATE_AGREEMENT_DOCUMENT_FAILURE,
      ],
      promise: client => client.post(build(api.v1.GENERATE_AGREEMENT_DOCUMENTS, leaseId), body),
    },
  }),

  setActiveDocument: (value: DocumentProps): Action => ({ type: ActionType.SET_ACTIVE_DOCUMENT, value }),
};
