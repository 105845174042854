import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, ParkingProps, ParkingState } from './action-types';

export const initialState: ParkingState = {
  errorMessage: null,
  parking: [] as ParkingProps[],
  isLoaded: false,
};

export const selectParking = (state: { parking: ParkingState }) : ParkingProps[] => state.parking.parking;

export default produce((state: ParkingState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_PARKING_SUCCESS:
    case ActionType.UPDATE_PARKING_SUCCESS:
      state.parking = unionBy([action.result.data], state.parking, 'id');
      break;

    case ActionType.DELETE_PARKING_SUCCESS:
      state.parking = state.parking.filter(i => i.id !== action.result.data.id);
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.parking = action.result.data.parking;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
