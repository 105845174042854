import { PromiseActionType } from 'dwell/store/types';
import { PropertiesBreakdown, SubmarketDetail, RentComps } from '../market/action-types';

export enum ActionType {
  GET_SUBMARKET_PROPERTIES_REQUEST = 'GET_SUBMARKET_PROPERTIES_REQUEST',
  GET_SUBMARKET_PROPERTIES_SUCCESS = 'GET_SUBMARKET_PROPERTIES_SUCCESS',
  GET_SUBMARKET_PROPERTIES_FAILURE = 'GET_SUBMARKET_PROPERTIES_FAILURE',
  GET_SUBMARKET_DETAIL_REQUEST = 'GET_SUBMARKET_DETAIL_REQUEST',
  GET_SUBMARKET_DETAIL_SUCCESS = 'GET_SUBMARKET_DETAIL_SUCCESS',
  GET_SUBMARKET_DETAIL_FAILURE = 'GET_SUBMARKET_DETAIL_FAILURE',
  GET_SUBMARKET_RENT_COMPS_REQUEST = 'GET_SUBMARKET_RENT_COMPS_REQUEST',
  GET_SUBMARKET_RENT_COMPS_SUCCESS = 'GET_SUBMARKET_RENT_COMPS_SUCCESS',
  GET_SUBMARKET_RENT_COMPS_FAILURE = 'GET_SUBMARKET_RENT_COMPS_FAILURE',
  GET_SUBMARKET_MTR_BREAKDOWN_REQUEST = 'GET_SUBMARKET_MTR_BREAKDOWN_REQUEST',
  GET_SUBMARKET_MTR_BREAKDOWN_SUCCESS = 'GET_SUBMARKET_MTR_BREAKDOWN_SUCCESS',
  GET_SUBMARKET_MTR_BREAKDOWN_FAILURE = 'GET_SUBMARKET_MTR_BREAKDOWN_FAILURE',
  GET_SUBMARKETS_NAMES_REQUEST = 'GET_SUBMARKETS_NAMES_REQUEST',
  GET_SUBMARKETS_NAMES_SUCCESS = 'GET_SUBMARKETS_NAMES_SUCCESS',
  GET_SUBMARKETS_NAMES_FAILURE = 'GET_SUBMARKETS_NAMES_FAILURE',
}

export interface SubmarketProps {
  id: number;
  name: string;
}

interface GetSubmarketPropertiesAction {
  type: ActionType.GET_SUBMARKET_PROPERTIES_REQUEST | ActionType.GET_SUBMARKET_PROPERTIES_SUCCESS | ActionType.GET_SUBMARKET_PROPERTIES_FAILURE;
  result: {
    data: { results: PropertiesBreakdown; count: number };
  };
  error: {
    response: { status: string };
  };
}
interface GetSubmarketDetailAction {
  type: ActionType.GET_SUBMARKET_DETAIL_REQUEST | ActionType.GET_SUBMARKET_DETAIL_SUCCESS | ActionType.GET_SUBMARKET_DETAIL_FAILURE;
  result: {
    data: SubmarketDetail;
  };
  error: {
    response: { status: string };
  };
}
interface GetSubmarketRentCompAction {
  type: ActionType.GET_SUBMARKET_RENT_COMPS_REQUEST | ActionType.GET_SUBMARKET_RENT_COMPS_SUCCESS | ActionType.GET_SUBMARKET_RENT_COMPS_FAILURE;
  result: {
    data: { results: RentComps; count: number };
  };
  error: {
    response: { status: string };
  };
}
interface GetSubmarketMTRBreakdownAction {
  type: ActionType.GET_SUBMARKET_MTR_BREAKDOWN_REQUEST | ActionType.GET_SUBMARKET_MTR_BREAKDOWN_SUCCESS | ActionType.GET_SUBMARKET_MTR_BREAKDOWN_FAILURE;
  result: {
    data: { results: PropertiesBreakdown; count: number };
  };
  error: {
    response: { status: string };
  };
}
interface GetSubmarketsNamesAction {
  type: ActionType.GET_SUBMARKETS_NAMES_REQUEST | ActionType.GET_SUBMARKETS_NAMES_SUCCESS | ActionType.GET_SUBMARKETS_NAMES_FAILURE;
  result: {
    data: SubmarketProps[];
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | GetSubmarketPropertiesAction
  | GetSubmarketDetailAction
  | GetSubmarketRentCompAction
  | GetSubmarketMTRBreakdownAction
  | GetSubmarketsNamesAction;
