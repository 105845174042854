import produce from 'immer';
import moment from 'moment';
import { Action, ActionType, PMSLogProps } from './action-types';
import { CommonStateType } from '../types';
import { preFillState } from '../utils';

export interface PMSLogState extends CommonStateType {
  logs: PMSLogProps[];
}

export const initialState: PMSLogState = {
  logs: [],
};

export const selectPMSLogs = (state: { pmsLogs: PMSLogState }): PMSLogProps[] => state.pmsLogs.logs.map(log => ({ ...log, date: moment(log.date).format('MM/DD/YYYY hh:mm:ss A') }));
export const selectPMSLogsErrorMessage = (state: { pmsLogs: PMSLogState }): string => state.pmsLogs.errorMessage;
export const selectPMSLogsIsLoaded = (state: { pmsLogs: PMSLogState }): boolean => state.pmsLogs.isLoaded;

export default produce((state: PMSLogState = initialState, action: Action): PMSLogState => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.GET_PMS_LOGS_SUCCESS:
      state.logs = action.result.data.results;
      break;

    case ActionType.GET_PMS_LOGS_FAILURE:
      state.logs = [];
      break;

    case ActionType.RESET_PMS_LOGS:
      state.errorMessage = '';
      state.isLoaded = false;
      state.logs = [];
      break;
  }

  return state;
});
