import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isEmpty, isEqual } from 'lodash';

import actions from 'dwell/actions';
import { selectSelectedProperties, selectProperty, selectProperties } from 'dwell/store/property/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectPushLead } from 'dwell/store/pusher/reducers';
import { selectContacts } from 'dwell/store/sms/reducers';
import { getPropertyId } from 'src/utils';
import 'src/scss/pages/_lead_details.scss';
import { LeadDetailMain, LeadDetailHeader } from './styles';
import KeyInfo from './key_info';
import { getLeadId } from './utils';

interface UserProps {
  id: number;
  first_name: string;
  last_name: string;
}

interface PropertyProps {
  id: number;
  users: Array<UserProps>;
  external_id: string;
}

interface LeadDetailLayoutProps {
  children: React.ReactNode;
}

interface StateProps {
  alreadyLoaded: boolean;
  is_shared: boolean;
}

const initialProperty: PropertyProps = {
  id: 0,
  users: [],
  external_id: '',
};

const LeadDetailLayout: FC<LeadDetailLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname, hash, state } = useLocation();
  const selectedProperties = useSelector(selectSelectedProperties);
  const properties = useSelector(selectProperties);
  const propertyDetails = useSelector(selectProperty);
  const contacts = useSelector(selectContacts);
  const pushLead = useSelector(selectPushLead);
  const currentUser = useSelector(selectCurrentUser);
  const lead = useSelector(s => s.lead.lead);
  const { getPropertyDetails, setSelectedProperty } = actions.property;
  const { updateUserAccessedProperties } = actions.user;
  const { removeProspectByLeadId } = actions.prospectChat;
  const { removeContact, getSMSContactById } = actions.smsMessage;
  const { getObjectTasks } = actions.task;
  const [isShared, setIsShared] = useState(false);
  const [label, setLabel] = useState('shared');
  const [property, setProperty] = useState<PropertyProps>(initialProperty);
  const { getLeadById, updateLeadById } = actions.lead;
  const { push } = useHistory();

  const loadLeadDetails = () => {
    const { alreadyLoaded, is_shared } = (state || {}) as StateProps;
    const isShare = hash.includes('shared') || hash.includes('transferred') || is_shared;
    setIsShared(isShare);
    if (hash.includes('transferred')) {
      setLabel('transferred');
    }

    if (isShare && (!lead.id || !alreadyLoaded)) {
      dispatch(getLeadById(getLeadId(pathname), { shared: isShare }));
    }
  };

  useEffect(() => {
    if (propertyDetails.timezone) {
      localStorage.setItem('timezone', propertyDetails.timezone);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (!isEmpty(propertyDetails)) {
      setProperty(propertyDetails);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (lead.id && !isEmpty(contacts)) {
      if (lead.phone_number && lead.sms_opt_in_status === 'OPTIN_STATUS_OPTEDIN' && !contacts.find(contact => contact.id === lead.id)) {
        dispatch(getSMSContactById(lead.id));
      }
    }
  }, [lead.id, lead.phone_number, contacts]);

  useEffect(() => {
    if (pushLead.id && !isEmpty(contacts)) {
      if (pushLead.phone_number && pushLead.sms_opt_in_status === 'OPTIN_STATUS_OPTEDIN' && !contacts.find(contact => contact.id === lead.id)) {
        dispatch(getSMSContactById(pushLead.id));
      }
    }
  }, [pushLead]);

  useEffect(() => {
    const propertyId = getPropertyId();
    if (pathname.split('/')[1] === propertyId) {
      if (!isEmpty(selectedProperties)) {
        if (selectedProperties.map(p => p.external_id).includes(propertyId)) {
          const selectedProperty = selectedProperties.find(p => p.external_id === propertyId);
          dispatch(setSelectedProperty(selectedProperty));
          loadLeadDetails();
          if (propertyDetails.external_id === propertyId) return;
          dispatch(getPropertyDetails(selectedProperty.id));
        } else if (properties.length) {
          const nonSelected = properties.find(p => p.external_id === propertyId);
          if (nonSelected) {
            dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: [...selectedProperties, nonSelected] }));
          } else {
            push('/');
          }
        }
      }
    }
  }, [pathname, selectedProperties, properties]);

  const handleSave = (params) => {
    dispatch(updateLeadById(lead.id, params)).then(() => {
      if (isEqual(params, { status: 'TEST' })) {
        dispatch(removeProspectByLeadId(lead.id));
        dispatch(removeContact(lead.id));
      }
      dispatch(getObjectTasks(lead.id, 'lead'));
    });
  };

  return (
    <LeadDetailMain className="lead-details">
      <Helmet>
        <title>{!isEmpty(lead) ? `Union | Prospect - ${lead.first_name} ${lead.last_name}` : 'Union'}</title>
      </Helmet>
      <LeadDetailHeader>
        <KeyInfo lead={lead} onSave={handleSave} availableOwners={property.users} isShared={isShared} label={label} />
      </LeadDetailHeader>
      {children}
    </LeadDetailMain>
  );
};

export default LeadDetailLayout;
