import produce from 'immer';
import { unionBy } from 'lodash';
import { preFillState } from 'dwell/store/utils';
import { ActionType as LeaseActionType } from 'leasing/store/lease/action-types';
import { Action, ActionType, GateKeyProps, GateKeyState } from './action-types';

export const initialState: GateKeyState = {
  errorMessage: null,
  gateKeys: [] as GateKeyProps[],
  isLoaded: false,
};

export const selectGateKeys = (state: { gateKey: GateKeyState }) : GateKeyProps[] => state.gateKey.gateKeys;

export default produce((state: GateKeyState = initialState, action: Action) => {
  preFillState(state, action, Object.values(ActionType));

  switch (action.type) {
    case ActionType.CREATE_GATE_KEY_SUCCESS:
      state.gateKeys = unionBy([action.result.data], state.gateKeys, 'id');
      break;

    case ActionType.DELETE_GATE_KEY_SUCCESS:
      state.gateKeys = state.gateKeys.filter(i => i.id !== action.result.data.id);
      break;

    case ActionType.UPDATE_GATE_KEY_SUCCESS:
      state.gateKeys = unionBy([action.result.data], state.gateKeys, 'id');
      break;

    case LeaseActionType.GET_LEASE_BY_ID_SUCCESS:
      state.gateKeys = action.result.data.gate_keys;
      break;

    case LeaseActionType.RESET_LEASE:
      state = initialState;
      break;
  }

  return state;
});
